import {BookingModel} from "./booking.model";
import {IServiceFactory} from "../../service-factory.interface";

export class BookingErrorHandlingModel {
    constructor(private readonly booking: BookingModel) {
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    private async _refreshBookingData(): Promise<void> {
        const bookingData = await this.booking.session.bookingStateQueryBuilder().useBookingData().getBookingState();
        this.booking.updateBookingSessionData(bookingData);
    }

    async handleNskServerClassNotAvailable(): Promise<void> {
        await this.booking.bookingStrategyAdapter.goBackToFlightSelection();
        await this.services.loadingIndicator.execute({
            action: async () => {
                await this._refreshBookingData();
                await this.booking.flightSearchController.applySearch();
            }
        });
        this.services.alert.showError(this.services.language.translate('Unfortunately this ticket was already taken by another customer. Please start over and choose a different flight'));


    }
}