import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {IJourneyViewModel} from "../../../services/booking/models/journey/journey-view-model.interface";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../components/designator/designator.component";
import styled from "styled-components";
import {CardBox} from "../../../basic-components/card/card.box";
import {OnlinePreCheckInRestrictionsComponent} from "../passengers/components/online-check-in-validation-dialog.component";
import {CheckboxComponent} from "../../../basic-components/checkbox/checkbox.component";
import {BookingCodeComponent} from "../../../components/booking-code/booking-code.component";
import {BookingFlowPage} from "../../booking-flow/booking-flow.page";
import {VerticalSpacer} from "../../../basic-components/spacing/vertical-spacer";


const PageContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.large};
  font-size: ${props => props.theme.fontSize.medium};
`

const TitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxLarge};
    line-height: 1;
`

const JourneyForCheckInBox = styled(CardBox)<{allowClick: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: ${props => props.allowClick ? "pointer": "default"};
`

const DesignatorAndCheckInStatusBoxBox = styled.div`
    flex: 1;
`

const CheckInStatusBox = styled.div<{isCheckinOpen: boolean}>`
    color: ${props => props.isCheckinOpen ? props.theme.colors.success : props.theme.colors.danger};
    line-height: 1.1;
`

const PreCheckInRestrictionsTitleBox = styled.div`
    line-height: 1.1;
    text-align: center;
    font-weight: ${props => props.theme.fontWeight.bold};
`
const PreCheckInRestrictionsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large};
`


const JourneyCheckInStatusComponent: React.FC<{journey: IJourneyViewModel;}> = observer((props) => {
    const services = useServices();
    let text: string;
    if(props.journey.isOnlineCheckInAllowed) {
        if(props.journey.isOpenForCheckIn) {
            text = services.language.translate('Check-in open');
        } else {
            text = services.language.translate('Check-in not open');
        }
    } else {
        const blockingReason = props.journey.getFirstUserIndependentCheckInBlockingRestrictionsMessage();
        if(blockingReason) {
            text = blockingReason;
        } else {
            text = services.language.translate('Online check-in is not available for this flight');
        }

    }

    return (
        <CheckInStatusBox isCheckinOpen={props.journey.isOnlineCheckInAllowed && props.journey.isOpenForCheckIn}>
            {text}
        </CheckInStatusBox>
    )
});

const GoToBoardingPassesBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-decoration: underline;
    cursor: pointer;
`

interface JourneyForCheckInComponentProps {
    journey: IJourneyViewModel;
    shouldShowCheckBox: boolean;
}

const JourneyForCheckInComponent: React.FC<JourneyForCheckInComponentProps> = observer((props) => {

    const shouldRenderCheckbox = props.shouldShowCheckBox && props.journey.isOpenForCheckIn;

    const onClick = () => {
        if(!shouldRenderCheckbox) {
            return;
        }

        props.journey.isJourneySelected = !props.journey.isJourneySelected;
    }

    const renderCheckBox = () => {
        if(!shouldRenderCheckbox) {
            return null;
        }

        return (
            <CheckboxComponent checked={props.journey.isJourneySelected} isReadOnly={true}/>
        );
    }

    return (
        <JourneyForCheckInBox allowClick={shouldRenderCheckbox} onClick={onClick}>
            <DesignatorAndCheckInStatusBoxBox>
                <DesignatorComponent  displayMode={DesignatorDisplayMode.stationsNamesAndDateAndFlightStops}
                                      designator={props.journey.designator}/>
                <JourneyCheckInStatusComponent journey={props.journey}/>
            </DesignatorAndCheckInStatusBoxBox>
            {renderCheckBox()}
        </JourneyForCheckInBox>

    );
});

const JourneysForCheckInComponent: React.FC = observer(() => {
    const services = useServices();
    const unfilteredJourneys =  services.booking.current.unfilteredJourneys;
    const numberOfJourneysOpenForCheckIn = unfilteredJourneys.filter(j => j.isOpenForCheckIn).length;
    return (
        <>
            {
                unfilteredJourneys.filter(j => j.isFutureJourney)
                    .map((j) => {
                        return (
                            <JourneyForCheckInComponent key={j.journeyKey}
                                                        journey={j}
                                                        shouldShowCheckBox={numberOfJourneysOpenForCheckIn > 1}/>

                        );
                    })
            }
        </>
    );
});

const GoToBoardingPassesButtonComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    if(booking.balanceDue.amount > 0) {
        return null;
    }


    const checkedInPassengerSegments = booking.getAllPassengersSegments().filter(passengerSegment => passengerSegment.isCheckedIn);

    if(checkedInPassengerSegments.length === 0) {
        return null;
    }

    const goToBoardingPasses = async () => {
        await services.booking.current.showBoardingPasses();
    }

    return (
        <GoToBoardingPassesBox onClick={goToBoardingPasses}>
            {services.language.translate('Show boarding pass')}
        </GoToBoardingPassesBox>
    );
});

const TitleComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    return (
        <TitleBox>
            <BookingCodeComponent recordLocator={booking.recordLocator}/>
        </TitleBox>
    );
});

const PreCheckInRestrictionsComponent: React.FC = observer(() => {
    const services = useServices();
    const segments = services.booking.current.unfilteredJourneys.selectMany(j => j.segments);


    if(segments.all(segment => segment.passengers.all(passengerSegment => passengerSegment.getPreCheckInRestrictions().length === 0))) {
        return null;
    }

    return (
        <PreCheckInRestrictionsBox>
            <VerticalSpacer/>
            <PreCheckInRestrictionsTitleBox>
                {services.language.translate('The following passengers cannot perform online check-in')}
            </PreCheckInRestrictionsTitleBox>
            {segments.map(segment => <OnlinePreCheckInRestrictionsComponent key={segment.segmentKey} segment={segment} onBeforeRestrictionActionClick={() => {}}/>)}
        </PreCheckInRestrictionsBox>
    )
});


const CheckInFlightSelectionPageContent: React.FC = observer(() => {
    return (
        <PageContentBox>
            <TitleComponent/>
            <JourneysForCheckInComponent/>
            <GoToBoardingPassesButtonComponent/>
            <PreCheckInRestrictionsComponent/>
        </PageContentBox>);
});

export const CheckInFlightSelectionPage: React.FC = observer(() => {
    const services = useServices();
    return (
        <BookingFlowPage title={services.language.translate('Your flight')}
                                    renderContent={() => (<CheckInFlightSelectionPageContent/>)}
                                    hideShoppingCart={true}
                                    showPromotionCode={false}>

        </BookingFlowPage>
    );
});
