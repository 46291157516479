import React, {ChangeEvent} from "react";
import {observer} from "mobx-react";
import {IStandardDropDownListViewModel} from "../../models/list/standard-drop-down-list-view-model.interface";
import styled from "styled-components";
import {InputBaseBox} from "../../../../../../basic-components/input/input-base.box";

const InputBox = styled(InputBaseBox)`
`


interface StandardDropDownInputLargeScreenComponentProps<TFieldValue> {
    listViewModel: IStandardDropDownListViewModel<TFieldValue>
    captureInputRef: (element: HTMLInputElement) => void;
    placeHolder?: string;
}

export const StandardDropDownInputLargeScreenComponent = observer(<TFieldValue extends any>(props: StandardDropDownInputLargeScreenComponentProps<TFieldValue>) => {

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.listViewModel.searchText = event.target.value ?? "";
    }

    const getInputPlaceHolder = () => {
        if(props.listViewModel.isDropDownOpen) {
            return props.listViewModel.selectedItem?.getDisplayText() ?? props.placeHolder ?? "";
        } else {
            return props.placeHolder ?? "";
        }
    }

    return <InputBox ref={props.captureInputRef}
                     autoComplete="off"
                     value={props.listViewModel.searchText}
                     placeholder={getInputPlaceHolder()}
                     onChange={onInputChange} />
});
