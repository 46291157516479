import React from "react";
import {CabinBagsDisclaimerForAtrAircraftComponent} from "../../../bags-selection/components/cabin-bags-disclaimer-for-atr-aircraft.component";
import {PassengerCabinBagsComponent} from "../../../bags-selection/components/cabin-bag-selection.component";
import {ExtrasSingleSsrSectionRenderer} from "../../base-renderers/extras-single-ssr-section-renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {IPassengerCustomSsrEditorRenderer} from "../../../common-components/ssrs/ssrs-selection.component.props";

export class CabinBagExtrasSectionRenderer extends ExtrasSingleSsrSectionRenderer {

    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.PBRD, booking);
    }

    shouldRender(): boolean {
        return true;
    }

    protected _getPassengerCustomSsrRenderer(): IPassengerCustomSsrEditorRenderer {
        return {
            render: passengerSsrEditor => (<PassengerCabinBagsComponent ssrEditor={passengerSsrEditor}/>)
        };
    }

    protected _renderDisclaimer(): string | React.ReactElement | undefined {
        return (
            <CabinBagsDisclaimerForAtrAircraftComponent/>
        );
    }

}
