import {IBundleTypesService, IBundleUpgradeInfo} from "./bundle-types.service.interface";
import {ServiceBase} from "../service-base";
import {IBundleType} from "./bundle-type.interface";
import {IServiceFactory} from "../service-factory.interface";
import {
    BundleCodes,
    IBundleTypesConfiguration
} from "../bundle-types-configuration/bundle-types-configuration.service.interface";
import {OldBundleType} from "./old-bundle-type";
import {IBundleViewModel} from "../booking/models/bundle/bundle-view-model.interface";

export class BundleTypesService extends ServiceBase implements IBundleTypesService {
    constructor(services: IServiceFactory) {
        super(services);
        this._bundlesDisplayIndex = this._configuration.bundlesDisplayOrder.reduce((accumulator: Record<string, number>, bundleType, index) => {
            accumulator[bundleType.code] = index;
            return accumulator
        }, {});
    }

    private readonly _bundlesDisplayIndex: Record<string, number> = {};

    private get _configuration(): IBundleTypesConfiguration {
        return this.services.bundleTypesConfiguration.getConfiguration();
    }

    getBundleType(bundleCode: string): IBundleType {
        for(let bundleType of this._configuration.bundlesDisplayOrder) {
            if(bundleType.code === bundleCode) {
                return bundleType;
            }
        }

        return new OldBundleType(bundleCode,
                                 this.services.configuration.noBundleEarliestCheckIn,
                                 this.getLowestBundleType().earliestCheckInWhenHasSeat,
                                 this.services);
    }

    getLowestBundleType(): IBundleType {
        return this.getBundleType(BundleCodes.ABAS);
    }

    sortBundlesByDisplayOrder(bundles: IBundleViewModel[]): IBundleViewModel[] {
        return bundles.sort((b1, b2) => {
            if(this._bundlesDisplayIndex[b1.bundleCode] < this._bundlesDisplayIndex[b2.bundleCode]) {
                return -1
            } else {
                return 1;
            }
        });
    }



    getBundleUpgradeInfo(upgradeFromBundle: IBundleType, allAvailableBundles: IBundleType[]): IBundleUpgradeInfo | null {

        const bundlesUpgrades: Record<string, Record<string, IBundleUpgradeInfo>> = {
            [BundleCodes.ABAS]: {
                [BundleCodes.BPLU]: this._getAbasToBpluUpgradeInfo()
            },
            [BundleCodes.BPLU]: {
                [BundleCodes.CBIZ]: this._getBpluToBizUpgradeInfo(),
                [BundleCodes.CBCM]: this._getBpluToCbcmUpgradeInfo()
            }
        }


        const possibleUpgrades = bundlesUpgrades[upgradeFromBundle.code];
        if(!possibleUpgrades) {
            return null;
        }

        for(let availableBundle of allAvailableBundles) {
            if(possibleUpgrades[availableBundle.code]) {
                return possibleUpgrades[availableBundle.code];
            }
        }

        return null;
    }

    private _getAbasToBpluUpgradeInfo(): IBundleUpgradeInfo {
        return {
            upgradeTo: this.getBundleType(BundleCodes.BPLU),
            benefits: [
                {
                    description: this.services.ssrTypes.STST.name,
                    details: this.services.ssrTypes.STST.details,
                    icon: this.services.ssrTypes.STST.smallIcon,
                    from: false,
                    to: true
                },
                {
                    description: this.services.ssrTypes.SCBG.name,
                    details: this.services.ssrTypes.SCBG.details,
                    icon: this.services.ssrTypes.SCBG.smallIcon,
                    from: true,
                    to: true
                },
                {
                    description: this.services.ssrTypes.CKN.name,
                    details: null,
                    icon: this.services.ssrTypes.CKN.smallIcon,
                    from: false,
                    to: true
                },
                {
                    description: this.services.ssrTypes.SBAG.name,
                    details: this.services.ssrTypes.SBAG.details,
                    icon: this.services.ssrTypes.SBAG.smallIcon,
                    from: false,
                    to: true
                },
            ]
        }
    }


    private _getBpluToBizUpgradeInfo(): IBundleUpgradeInfo {
        return {
            upgradeTo: this.getBundleType(BundleCodes.CBIZ),
            benefits: [
                {
                    description: this.services.ssrTypes.STEX.name,
                    details: this.services.ssrTypes.STEX.details,
                    icon: this.services.ssrTypes.STEX.smallIcon,
                    from: false,
                    to: true
                },
                {
                    description:  this.services.language.translationFor('Extra {bagType}').withParams({
                        bagType: this.services.ssrTypes.SBAG.name
                    }),
                    details: this.services.ssrTypes.SBAG.details,
                    icon: this.services.ssrTypes.SBAG.smallIcon,
                    from: false,
                    to: true
                },
                {
                    description: this.services.ssrTypes.PBRD.name,
                    details: this.services.ssrTypes.PBRD.details,
                    icon: this.services.ssrTypes.PBRD.smallIcon,
                    from: false,
                    to: true
                }
            ]
        }
    }

    private _getBpluToCbcmUpgradeInfo(): IBundleUpgradeInfo {
        const toBizUpgradeInfo = this._getBpluToBizUpgradeInfo();
        return {
            ...toBizUpgradeInfo,
            upgradeTo: this.getBundleType(BundleCodes.CBCM),
            benefits: [
                ...toBizUpgradeInfo.benefits,
                {
                    description: this.services.ssrTypes.FAST.name,
                    details: this.services.ssrTypes.FAST.details,
                    icon: this.services.ssrTypes.FAST.smallIcon,
                    from: false,
                    to: true
                },
                {
                    description: this.services.ssrTypes.LOU.name,
                    details: this.services.ssrTypes.LOU.details,
                    icon: this.services.ssrTypes.LOU.smallIcon,
                    from: false,
                    to: true
                },
                {
                    description: this.services.ssrTypes.SBUS.name,
                    details: this.services.ssrTypes.SBUS.details,
                    icon: this.services.ssrTypes.SBUS.smallIcon,
                    from: false,
                    to: true
                }
            ]
        }
    }
}
