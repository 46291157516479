import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {BookingFlowSmallScreenPage} from "../../../booking-flow/booking-flow.small-screen.page";
import {JourneySelectorComponent} from "../../common/components/journey-selector.component";
import {IJourneySnapshotViewModel} from "../../../../services/booking/models/snapshots/journey/journey-snapshot-view-model.interface";
import {ManageMyBookingFlightsSelectionPageHeaderTextBox} from "../../common/flights-selection/boxes/manage-my-booking-flights-selection-page-header-text.box";

const JourneyToRefundSelectorComponent: React.FC<{journey: IJourneySnapshotViewModel}> = observer((props) => {
    const services = useServices();

    const onSelectHandler = () => {
        props.journey.isSelectedForRefund = !props.journey.isSelectedForRefund;
    }

    return (
        <JourneySelectorComponent designator={props.journey.designator}
                                  canBeUnselected={!props.journey.isCanceled}
                                  onSelect={onSelectHandler}
                                  isSelected={() => props.journey.isSelectedForRefund}
                                  isJourneyCanceled={props.journey.isCanceled}
                                  changeBlockingReason={props.journey.refundBlockingReason}
                                  changeBlockingReasonTitle={services.language.translate('Not eligible for refund')}>

        </JourneySelectorComponent>
    )
});

const RefundSummaryComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    if(!booking.manageMyBooking.hasJourneysThatAllowRefund) {
        return null;
    }
    return (
        <div>
            {booking.manageMyBooking.totalToRefund.toString()}
        </div>
    )
})

const ManageMyBookingFlightsToRefundSelectionPageContent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    return (
        <>
            <ManageMyBookingFlightsSelectionPageHeaderTextBox>
                {services.language.translate('Select the flight(s) that you want to be refunded into your wallet')}
            </ManageMyBookingFlightsSelectionPageHeaderTextBox>
            {
                booking.manageMyBooking.availableJourneysForRefund.map(j => <JourneyToRefundSelectorComponent key={j.journeyKey} journey={j}/>)
            }

            <RefundSummaryComponent/>

        </>
    );
})


export const ManageMyBookingFlightsToRefundSelectionPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowSmallScreenPage title={services.language.translate('Refund to wallet')}
                                    showPromotionCode={false}
                                    hideShoppingCart={true}
                                    hideFooter={ services.booking.isBookingRestoreInProgress || !services.booking.current.manageMyBooking.hasJourneysThatAllowRefund}
                                    renderContent={() => <ManageMyBookingFlightsToRefundSelectionPageContent/>}
        />
    );
})
