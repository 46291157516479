import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import styled from "styled-components";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../../../components/designator/designator.component";
import {ExclamationTriangle} from "../../../../../../assets/svg/exclamation-triangle.icon";
import {IPassengerSegmentViewModel} from "../../../../../../services/booking/models/passenger-segment/passenger-segment-view-model.interface";

const SeatsSummarySmallScreenBox = styled.div<{columns: number}>`
    display: grid;
    grid-template-columns: 3fr repeat(${props => props.columns - 1}, 1fr);
    column-gap: ${props => props.theme.spacing.medium};
    row-gap: ${props => props.theme.spacing.large};
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      grid-template-columns: repeat(${props => props.columns}, 1fr);
    }
  
`

const PassengerNameHeaderBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-transform: uppercase;
`

const PassengerNameBox = styled.div`
    
`

const SeatBox = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
`


export const ExtrasSeatsSummarySmallScreenComponent: React.FC = observer(() => {
    const services = useServices();

    const booking = services.booking.current;
    const passengersSegments = booking.getAllPassengersSegments().sort((p1, p2) => p1.passengerIndex - p2.passengerIndex);
    const numberOfSegments = booking.getAllSegments().length;

    const renderSeatNumber = (passengerSegment: IPassengerSegmentViewModel) => {
        if(passengerSegment.assignedSeat) {
            return (<SeatBox>{passengerSegment.assignedSeat.seatNumber}</SeatBox>);
        } else {
            return (
                <SeatBox>
                    <ExclamationTriangle size={1.8} color={services.theme.currentTheme.colors.warningShade}/>
                </SeatBox>
            );
        }
    }

    return (
        <SeatsSummarySmallScreenBox columns={numberOfSegments + 1}>
            <PassengerNameHeaderBox>
                {services.language.translate('Passenger')}
            </PassengerNameHeaderBox>
            {booking.getAllSegments().map(s => <DesignatorComponent key={s.segmentKey} displayMode={DesignatorDisplayMode.stationsCodeOnly} designator={s.designator}/>)}
            {
                passengersSegments.map((p, index) => {
                    const key = p.segmentKey + p.passengerKey;
                    if(index % numberOfSegments === 0) {
                        return (
                            <React.Fragment key={key}>
                                <PassengerNameBox>{p.passengerFirstName}</PassengerNameBox>
                                {renderSeatNumber(p)}
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={key}>
                                {renderSeatNumber(p)}
                            </React.Fragment>
                        );
                    }
                })
            }
        </SeatsSummarySmallScreenBox>
    )

})
