import {FinalPriceBaseStep} from "../common/final-price-base-step";
import {IBookingRoutes} from "../../../navigation/routes/booking/booking.routes.interface";
import {applySpecialPriceMarketDiscount} from "./apply-special-price-market-discount";
import {IWizardStep} from "../../../../models/wizard/wizard.step.interface";

export class BookingFlowFinalPriceStep extends FinalPriceBaseStep<IBookingRoutes> {

    protected get routes(): IBookingRoutes {
        return this.services.navigator.routes.booking;
    }

    get route() {
        return this.routes.finalPrice;
    }


    protected async _goToPaymentStep(): Promise<void> {
        this._activateStepByRoute(this.routes.payment);
    }

    protected async _applyDiscounts(): Promise<void> {
        await super._applyDiscounts();
        await applySpecialPriceMarketDiscount(this.booking);
    }

    protected async _onActivated(previousStep: IWizardStep | null): Promise<void> {
        await super._onActivated(previousStep);
        //Razvan
        this.services.analytics.paymentEvents.logViewCart(this.booking);
    }
}
