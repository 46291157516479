import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {IBundleViewModel} from "../../../../services/booking/models/bundle/bundle-view-model.interface";
import {JourneyBundlesComponent} from "../components/bundle/journey-bundles.component";

export const DepartingFlightBundlesComponent: React.FC = observer(() => {
    const services = useServices();

    const journey = services.booking.current.departureJourney;
    if(!journey) {
        return null;
    }

    const onSellBundle = async (bundle: IBundleViewModel) => {
        await services.booking.current.sellDepartureJourneyBundle(bundle.bundleCode);
    };

    return (
        <JourneyBundlesComponent journeyDesignator={journey.designator}
                                 availableBundles={services.bundleTypes.sortBundlesByDisplayOrder(journey.bundlesAvailability)}
                                 disableNotSelected={false}
                                 onSellBundleClick={onSellBundle}/>
    )
});
