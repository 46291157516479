import {BookingFlowStepBase} from "../common/booking-flow-step.base";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {IWizardStep} from "../../../../models/wizard/wizard.step.interface";

export class BookingFlowExtrasSelectionStep extends BookingFlowStepBase {

    get title(): string {
        return this.services.language.translate('Extras');
    }

    async next(): Promise<void> {
        await waitForBookingMutations(this.booking);
        this._activateStepByRoute(this.routes.finalPrice);
    }

    get route() {
        return this.routes.extras;
    }

    protected async _onActivated(previousStep: IWizardStep | null): Promise<void> {
        await super._onActivated(previousStep);
        this.services.analytics.extrasSelectionEvents.logExtrasViewItemList(this.booking);
    }
}
