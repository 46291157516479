import {IDotRezAvailableFare, IDotRezAvailableJourney} from "../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import {IServiceFactory} from "../../service-factory.interface";
import {AvailableDepartureTripModel, AvailableReturnTripModel, AvailableTripModel} from "./available-trip.model";
import {AvailableSegmentModel} from "./available-segment.model";
import {AvailableFareWithDiscountModel} from "./fares/available-fare-with-discount.model";
import {FlightDesignatorModel} from "../../booking/models/designator/flight-designator.model";
import {AvailabilityLegModel} from "./availability-leg.model";
import {AvailableFareStandardModel} from "./fares/available-fare-standard.model";
import {IAvailableJourneyViewModel} from "./available-journey-view-model.interface";
import {AvailableFareModel} from "./fares/available-fare.model";
import {IJourneyFareReducer} from "./fare-reducer.interface";
import {Price} from "../../currency/price";
import {NullableString} from "../../../types/nullable-types";
import {IFlightSearchControllerViewModel} from "../flight-search-controller/flight-search-controller-view-model.interface";

/**
 * A journey represents a flight in a specific date with a specific departure and arrival time
 */
export abstract class AvailableJourneyModel<TTrip extends AvailableTripModel = AvailableTripModel> implements IAvailableJourneyViewModel, IJourneyFareReducer {
    constructor(protected readonly trip: TTrip,
                protected readonly journeyDotRezData: IDotRezAvailableJourney,
                protected readonly services: IServiceFactory,
                faresAvailable: Record<string, IDotRezAvailableFare>) {
        this.segments = this.journeyDotRezData.segments.map(segment => new AvailableSegmentModel(services, segment));
        this._fares = [];
        this.journeyDotRezData.fares.forEach(fare => {
            const availableFareData = faresAvailable[fare.fareAvailabilityKey];
            const fareWithDiscount = new AvailableFareWithDiscountModel(this, availableFareData, this.services);
            const standardFare = new AvailableFareStandardModel(this, availableFareData, this.services);

            if(this.services.user.profile.blueBenefitsSubscription.isValidSubscription) {
                if (this.trip.isAvailabilitySearchWithBlueBenefits) {
                    // it means the user has a valid BB subscription and he wants to use it in case BB is available on the searched route
                    this._fares.push(fareWithDiscount);
                } else if (fareWithDiscount.hasBlueBenefits) {
                    // it means the user has a valid BB subscription but he doesn't want to make use of it
                    this._fares.push(standardFare);
                } else {
                    // it means that blue benefits is not available so we will let the current promo or no promo to apply
                    this._fares.push(fareWithDiscount);
                }
            } else {
                if(fareWithDiscount.hasBlueBenefits && this.trip.showStandardAndBlueBenefitsPrice) {
                    // if the fare is with blue benefits and current booking flow allows blue benefits then we show also the price without BB
                    this._fares.push(standardFare);
                    this._fares.push(fareWithDiscount);
                } else {
                    this._fares.push(fareWithDiscount);
                }
            }
        });
    }

    abstract get fares(): AvailableFareModel[];
    get searchController(): IFlightSearchControllerViewModel {
        return this.trip.searchController;
    }

    get bundleNameToShowOnFare(): NullableString {
        return this.trip.bundleNameToShowOnFare;
    }

    reduceFare(price: Price): Price {
        return this.trip.reduceFare(price);
    }

    get date(): Date {
        return this.trip.date;
    }

    get journeyKey(): string {
        return this.journeyDotRezData.journeyKey;
    }

    get designator(): FlightDesignatorModel {

        const allLegs = this.journeyDotRezData.segments.selectMany(s => s.legs);
        return new FlightDesignatorModel(
            this.services,
            this.services.stations.getStation(this.journeyDotRezData.designator.origin),
            this.services.stations.getStation(this.journeyDotRezData.designator.destination),
            this.journeyDotRezData.designator.departure,
            this.journeyDotRezData.designator.arrival,
            allLegs[0].legInfo.departureTimeUtc,
            allLegs[allLegs.length - 1].legInfo.arrivalTimeUtc,
            this.segments.map(s => s.designator),
            null)
    }

    getAllLegs(): AvailabilityLegModel[] {
        const legs: AvailabilityLegModel[] = [];
        this.segments.forEach(segment => {
            segment.legs.forEach(leg => {
                legs.push(leg);
            })
        });

        return legs;
    }

    get departureDate(): Date {
        return this.services.time.parseIsoDate(this.journeyDotRezData.designator.departure);
    }

    get departureTime(): string {
        return this.designator.formatDepartureTime();
    }

    readonly segments: AvailableSegmentModel[];

    protected readonly _fares: AvailableFareModel[] = [];

    sell(fare: AvailableFareModel): Promise<void> {
        return this.trip.sell(fare);
    }

    get hasFareSelected() {
        return this.fares.some(f => f.isSelected);
    }
}

export class AvailableDepartureJourneyModel extends AvailableJourneyModel<AvailableDepartureTripModel> {
    public get fares(): AvailableFareModel[] {
        /*
        if(!Check.isNullOrUndefined(this.searchController.departureMinPrice)) {
            return this._fares.filter(f => f.discount.discountedPrice.amount <= this.searchController.departureMinPrice!);
        }
         */
        return this._fares;
    }
}

export class AvailableReturnJourneyModel extends AvailableJourneyModel<AvailableReturnTripModel> {
    get fares(): AvailableFareModel[] {

        const selectedDepartureJourney = this.services.booking.current.departureJourney;
        let fares: AvailableFareModel[];
        if(selectedDepartureJourney && this._fares.length > 1) {
            // It means that we also have both standard and blue benefits prices but for return journey we want to show the same price type as the one selected for departure journey
            fares = this._fares.filter(fare => fare.hasBlueBenefits === selectedDepartureJourney.hasBlueBenefits);
        } else {
            fares =  this._fares;
        }
        /*
        if(!Check.isNullOrUndefined(this.searchController.returnMinPrice)) {
            return fares.filter(f => f.discount.discountedPrice.amount <= this.searchController.returnMinPrice!);
        }
         */
        return fares;

    }
}
