import {
    ILowFareResult,
    IOneWayLowFareReader,
    IRoundTripLowFareReader,
    LowFareStatusEnum
} from "./low-fare-reader.interface";

export class VoidOneWayLowFareReader implements IOneWayLowFareReader {

    getLowFare(date: Date): ILowFareResult {
        return {
            status: LowFareStatusEnum.NoFare
        };
    }

    async getLowFareAsync(date: Date): Promise<ILowFareResult> {
        return this.getLowFare(date);
    }
}


export class VoidRoundTripLowFareReader implements IRoundTripLowFareReader {
    getDepartureLowFare(date: Date): ILowFareResult {
        return {
            status: LowFareStatusEnum.NoFare
        };
    }

    getReturnLowFare(date: Date): ILowFareResult {
        return {
            status: LowFareStatusEnum.NoFare
        };
    }

    async getDepartureLowFareAsync(date: Date): Promise<ILowFareResult> {
        return this.getDepartureLowFare(date);
    }
    async getReturnLowFareAsync(date: Date): Promise<ILowFareResult> {
        return this.getReturnLowFare(date);
    }

}
