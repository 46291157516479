import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {Price} from "../../../currency/price";
import {IBlueBenefitDiscountInfo} from "../packs/blue-benefits-package.interface";

export enum  BlueBenefitsSubscriptionStatus {
    noSubscription,
    Expired,
    Valid
}

export interface IBlueBenefitsSubscription {
    readonly packageName: string;
    readonly isValidSubscription: boolean;
    readonly expirationDate: Date;
    readonly isExpired: boolean;
    readonly subscriptionStatus: BlueBenefitsSubscriptionStatus;
    getFormattedExpirationDate(): string;
    readonly maxNumberOfPassengers: number;
    readonly partnersCountDescription: string;
    getBenefits(): IBlueBenefitDiscountInfo[];
    shouldLockFirstPassengerOnBooking(): boolean;
    computeSsrStandardPrice(ssrType: ISsrType, discountedPrice: Price): Price;
    createBookingCommentText(): string;
}
