import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {globeOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {ExternalLinkComponent} from "../external-link/external-link.component";
import {useServices} from "../../hooks/use-services.hook";

const WebContactFormBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
`

export const WebContactFormComponent: React.FC = observer(() => {
    const services = useServices();

    return (
        <WebContactFormBox>
            <IonIcon md={globeOutline} ios={globeOutline}/>
            <ExternalLinkComponent innerHtml={services.externalLinks.contactForm}/>
        </WebContactFormBox>
    );
})