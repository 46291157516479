import React from "react";
import {Station} from "../../../../../../../services/stations/station.service.interface";
import {StationDropDownItemComponent} from "./station-drop-down-item.component";
import {IStandardDropDownItemViewModel} from "../../../../../../../components/forms/standard-drop-down/list-view/models/item/standard-drop-down-list-item-view-model.interface";

export class StationDropDownItemModel implements IStandardDropDownItemViewModel<Station> {
    constructor(public readonly station: Station) {
    }

    get key(): string {
        return this.station.stationCode;
    }


    getFieldValue(): Station {
        return this.station;
    }

    isSameAs(fieldValue: Station | null): boolean {
        return this.station.stationCode === fieldValue?.stationCode;
    }

    applyFilter(filter: string): boolean {
        return 0 <= this.station.stationName.toLowerCase().indexOf(filter)
                || 0 <= this.station.stationCode.toLowerCase().indexOf(filter)
                || 0 <= this.station.countryName.toLowerCase().indexOf(filter);
    }

    renderItem(): React.ReactElement {
        return (
            <StationDropDownItemComponent station={this.station}/>
        );
    }
    getDisplayText(): string {
        return `${this.station.stationName} (${this.station.stationCode})`
    }
}
