import React from "react";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../components/modal/footer/standard-dialog-footer.component";
import {SaveButtonBox} from "../../../components/forms/buttons/save-button.box";
import {useServices} from "../../../hooks/use-services.hook";
import {CompanionEditorComponent} from "./companion-editor.component";
import {ValidationResultEnum} from "../../../types/validation-result.enum";
import {ICompanionViewModel} from "../../../services/user/models/companion/companion-view-model.interface";
import {DeleteButtonBox} from "../../../components/forms/buttons/delete-button.box";

interface CompanionEditDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    companion: ICompanionViewModel;
}
export const CompanionEditorDialogComponent: React.FC<CompanionEditDialogComponentProps> = observer((props) => {
    const services = useServices();
    const onSave = async () => {
        const result = await props.companion.save();
        if(result === ValidationResultEnum.Success) {
            props.dialogHandler.accept();
        }
    }

    const onDelete = async () => {
        const result = await props.companion.delete();
        if(result === ValidationResultEnum.Success) {
            props.dialogHandler.accept();
        }
    }

    const renderDeleteButton = () => {
        if(props.companion.isNew) {
            return null;
        }
        return (<DeleteButtonBox onClick={onDelete}>
            {services.language.translate('Delete companion')}
        </DeleteButtonBox>);
    }

    const dialogTitle = () => {
        if(props.companion.isNew) {
            return services.language.translate('Add companion');
        }

        return services.language.translate('Edit companion');
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={dialogTitle()} dialogHandler={props.dialogHandler} />
            <StandardDialogContentComponent>
                <CompanionEditorComponent companion={props.companion}/>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <SaveButtonBox onClick={onSave}>
                    {services.language.translate('Save')}
                </SaveButtonBox>
                {renderDeleteButton()}
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
});
