import React from "react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableString} from "../../../types/nullable-types";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {StandardDropDownComponent} from "../standard-drop-down/standard-drop-down.component";
import {StandardDropDownListItemModel} from "../standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";

export const TravelDocumentTypeFieldEditorComponent: React.FC<{field: IFormField<NullableString>; onlyDocumentsForSpecialPriceMarket?: boolean}> = observer((props) => {
    const services = useServices();
    let items = services.travelDocumentType.getAllDocumentTypes().map(d => new StandardDropDownListItemModel(d.value, d.text));
    if(props.onlyDocumentsForSpecialPriceMarket) {
        items = services.travelDocumentType.getDocumentTypesForSpecialPriceMarket().map(d => new StandardDropDownListItemModel(d.value, d.text));
    }
    return (
        <StandardDropDownComponent field={props.field} items={items}/>
    )
});
