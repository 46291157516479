import React from 'react';
import { observer } from "mobx-react"
import { DesignatorComponent, DesignatorDisplayMode } from "../../../../../components/designator/designator.component"
import { IJourneyViewModel } from "../../../../../services/booking/models/journey/journey-view-model.interface"
import styled from "styled-components";
import { useServices } from "../../../../../hooks/use-services.hook";

const FlightItineraryDesignatorHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    line-height: 1;
`;

const DesignatorBox = styled(DesignatorComponent)`
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: 0 0 0 ${props => props.theme.spacing.medium};
  line-height: 1;
`

const TitleBox = styled.div`
    padding: 0;
`

interface FlightItineraryDesignatorComponentProps {
    journey: IJourneyViewModel;
}

export const FlightItineraryExtrasDesignatorComponent: React.FC<FlightItineraryDesignatorComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <FlightItineraryDesignatorHeader>
            <TitleBox>
                {services.language.translate('Extras')}
            </TitleBox>

            <DesignatorBox displayMode={DesignatorDisplayMode.stationsCodeOnly}
                                 designator={props.journey.designator} />
        </FlightItineraryDesignatorHeader>
    )
})
