import {IPassengerSegmentShoppingCartModel} from "./passenger-segment-shopping-cart-model.interface";
import {ShoppingCartModeEnum} from "../../../booking-strategies/booking-strategy.interface";
import {NullablePrice, Price} from "../../../../currency/price";
import {IFeeModel} from "../fees/fee-model.interface";
import {FeeDecorator} from "./fee-decorator";
import {computed, makeObservable} from "mobx";
import {ISeatFeeAnalyticsData} from "../../analytics/google-analytics.intefaces";

export class PassengerSegmentShoppingCartDecorator implements IPassengerSegmentShoppingCartModel {
    constructor(private readonly originalShoppingCart: IPassengerSegmentShoppingCartModel,
                private readonly shoppingCartMode: ShoppingCartModeEnum) {
        makeObservable(this, {
            seatFees: computed,
            ssrFees: computed,
            otherFees: computed
        })
    }

    get description(): string {
        return this.originalShoppingCart.description;
    }

    get initialFare(): NullablePrice {
        return this.originalShoppingCart.initialFare;
    }

    get currentFare(): Price {
        return this.originalShoppingCart.currentFare;
    }

    get seatFees(): IFeeModel[] {
        return this._filterFees(this.originalShoppingCart.seatFees);
    }

    get otherFees(): IFeeModel[] {
        return this._filterFees(this.originalShoppingCart.otherFees);
    }

    get ssrFees(): IFeeModel[] {
        return this._filterFees(this.originalShoppingCart.ssrFees);
    }

    private _filterFees(fees: IFeeModel[]): IFeeModel[] {
        if(this.shoppingCartMode === ShoppingCartModeEnum.ShowAllPurchases) {
            return fees.map(f => new FeeDecorator(f, this.shoppingCartMode));
        } else {
            return fees.filter(f => f.isPurchasedOnCurrentSession)
                       .map(f => new FeeDecorator(f, this.shoppingCartMode));
        }
    }

    createDecorator(shoppingCartMode: ShoppingCartModeEnum): IPassengerSegmentShoppingCartModel {
        return this;
    }

    getSeatFeeAnalyticsData(): ISeatFeeAnalyticsData | null {
        return this.originalShoppingCart.getSeatFeeAnalyticsData();
    }

    getSsrsFeesForId(ids: string[]): IFeeModel[]{
        return this.originalShoppingCart.getSsrsFeesForId(ids);
    }

    getSsrsFeesGroupedByAnalyticsName(): Record<string, IFeeModel[]> | null {
        return this.originalShoppingCart.getSsrsFeesGroupedByAnalyticsName();
    }
}
