import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {NullableString} from "../../../../../types/nullable-types";
import {InputContainerComponent} from "../../../input-container.component";
import {fieldInputCommonCssProps} from "../../../field-common-css-props";
import {DropDownChevronComponent} from "../indicator/drop-down-chevron.component";

const InputFieldContentBox = styled.div<{hasError: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    ${props => fieldInputCommonCssProps(props.theme, props.hasError)}
`



interface DropDownInputContainerComponentProps extends PropsWithChildren {
    label?: string | React.ReactElement,
    errorMessage: NullableString;
    hideErrorText?: boolean;
    icon?: React.ReactElement | null;
    onClick?: () => void;
    captureInputRef: (element: HTMLDivElement) => void;
    captureInputContainerRef: (element: HTMLDivElement) => void;
    showBorders?: boolean;
}

export const DropDownInputContainerComponent: React.FC<DropDownInputContainerComponentProps> = observer((props) => {

    const renderInput = () => {
        return (
            <InputFieldContentBox hasError={Boolean(props.errorMessage)}
                                  ref={props.captureInputRef}
                                  onClick={props.onClick}>
                {props.children}
                <DropDownChevronComponent/>

            </InputFieldContentBox>
        );
    }

    const renderIcon = () => {
        if(props.icon) {
            return props.icon;
        }
        return null;
    }

    return (
        <InputContainerComponent renderInput={renderInput}
                                 captureElementRef={props.captureInputContainerRef}
                                 label={props.label}
                                 errorMessage={props.errorMessage}
                                 hideErrorText={props.hideErrorText}
                                 renderIcon={renderIcon}
                                 showBorders={props.showBorders}/>
    );
});
