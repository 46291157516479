import {IShoppingCartModel} from "../../shopping-cart/shopping-cart-model.interface";
import {NullablePrice, Price} from "../../../../currency/price";
import {IFeeModel} from "../fees/fee-model.interface";
import {ISeatFeeModel} from "../fees/seat-fees/seat-fee-model.interface";
import {computed, makeObservable} from "mobx";
import {IPassengerSegmentShoppingCartAdapter} from "./passenger-segment-shopping-cart-adapter.interface";

export class InfantSegmentShoppingCartModel implements IShoppingCartModel {
    constructor(private readonly passengerSegment: IPassengerSegmentShoppingCartAdapter) {
        makeObservable(this, {
            fees: computed
        });
    }

    get fees(): IFeeModel[] {
        return this.passengerSegment.infantFees;
    }

    createPrice(amount: number): Price {
        return this.passengerSegment.createPrice(amount)
    }

    get description(): string {
        return this.passengerSegment.infantFullName || this.passengerSegment.passengerFullName;
    }

    get initialFare(): NullablePrice {
        return this.passengerSegment.getInitialInfantPrice();
    }

    get currentFare(): Price {
        return Price.sumAll(this.fees.map(f => f.currentPrice),
            this.createPrice(0));
    }

    get seatFees(): ISeatFeeModel[] {
        return [];
    }

    get ssrFees(): IFeeModel[] {
        return [];
    }

    get otherFees(): IFeeModel[] {
        return [];
    }
}
