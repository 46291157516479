export enum DeeplinkSearchQueryParamsEnum {
    IsRoundTrip = "r",
    Origin1 = "o1",
    Destination1 = "d1",
    DepartureDate1 = "dd1",
    ReturnDate1 = "dd2",
    Culture = "culture",
    Currency = "bc",
    Agent = "agent",
    OriginalAgent = "originalAgent",
    SpecialPriceUserOption = "spuo",
    SpecialPriceUserAgreement = "spua"
}
