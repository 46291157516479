import React from "react";
import {observer} from "mobx-react";
import { IonContent, IonPage } from "@ionic/react";
import styled from "styled-components";

const LogoBox = styled.div`
    height: 100%;
    width: 100%;
    background: url("${props => props.theme.assets.images.splash_png.toString()}") no-repeat center/cover fixed;
    box-sizing: border-box;
`

export const SplashComponent: React.FC = observer(() => {
    return (
        <IonPage>
            <IonContent>
                <LogoBox/>
            </IonContent>
        </IonPage>
    );
});
