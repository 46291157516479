import {IDotRezSsrToAdd} from "../../data-contracts/requests/booking/update-ssrs.request";
import {DotRezGraphQLMutationBuilder} from "../dot-rez-query-builder";


export interface IDotRezSsrsAddMutationResponseData {

    ssrsAddv2: Array<{
        ssrKey: string;
    }>
}

export function addSsrsGraphQLMutation(ssrsToAdd: IDotRezSsrToAdd[], queryBuilder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    if (ssrsToAdd.length === 0) {
        return queryBuilder;
    }

    const variableName = 'sellSsrsRequest';
    queryBuilder.addMutation(`
                mutation sellSsr($${variableName}: Input_SsrByKeysRequestv2) {
                    ssrsAddv2(request: $${variableName}){
                        ssrKey
                    }
                }
            `, {
        name: variableName,
        value: {
            keys: ssrsToAdd
        }
    });

    return queryBuilder;
}
