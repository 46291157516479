import React from "react";
import {HomePageTabBase} from "../../../services/home-page/tabs/home-page-tab-base";
import {MoreOptionsTabComponent} from "../components/more-options/more-options-tab.component";
import {HomePageHeaderComponent} from "../components/home-page-header/home-page-header.component";
import { HorizontalDotsIcon } from "../../../assets/svg/horizontal-dots.icon";

export class MoreOptionsTab extends HomePageTabBase {

    get text(): string {
        return this.services.language.translate('More');
    }

    renderContent(): React.ReactElement {
        return (
            <MoreOptionsTabComponent/>
        );
    }

    renderIcon(): React.ReactElement {
        return (<HorizontalDotsIcon />);
    }

    renderHeader(): React.ReactElement | null {
        return (
            <HomePageHeaderComponent title={this.services.language.translate('More options')}/>
        );
    }
}
