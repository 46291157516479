import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import styled, {css} from "styled-components";
import {IonHeader, IonToolbar} from "@ionic/react";
import {DialogCloseButtonComponent} from "../close-button/dialog-close-button.component";
import { HeaderTitleContainerBox } from "../../../basic-components/header/title/header-title-container.box";
import { HeaderTopSectionContentBox } from "../../../basic-components/header/top-section-content/header-top-section-content.box";
import {DialogCloseButtonBehavior} from "../../../services/dialog/dialog-enums";
import {useServices} from "../../../hooks/use-services.hook";

const DialogHeaderContainerBox = styled(IonHeader)<{hasModalSheetHandle: boolean}>`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    ${
    props => props.hasModalSheetHandle
        ? css`
             padding-top: ${props => props.theme.spacing.medium};
             `
        : ``
}
`

export const ToolbarSectionBoxInFullScreen = styled(IonToolbar)`
    --border-style: none;
    --padding-top: ${props => props.theme.spacing.medium};
    --padding-bottom: ${props => props.theme.spacing.medium};
    --padding-start: ${props => props.theme.spacing.medium};
    --padding-end: ${props => props.theme.spacing.medium};
`

const TopSectionBoxInNonFullScreen = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primaryContrast};
  border-style: none;
  padding: ${props => props.theme.spacing.medium};
`

const BottomSectionBox = styled.div`
    width: 100%;
`

const HeaderTopSectionContentForWindowBox = styled(HeaderTopSectionContentBox)`
    flex-direction: row-reverse;
`

const CloseButtonMirrorBox = styled.div`
    width: 28px;
    height: 28px;
`

interface StandardDialogHeaderComponentProps extends PropsWithChildren {
    title?: string | React.ReactElement;
    dialogHandler: IDialogConfirmationHandler;
    customCloseButtonHandler?: () => void;
    hideCloseButton?: boolean;
    dontMirrorCloseButton?: boolean;
}
export const StandardDialogHeaderComponent: React.FC<StandardDialogHeaderComponentProps> = observer((props) => {
    const services = useServices();

    const TopSectionBox: React.ComponentType<PropsWithChildren> = (props.dialogHandler.isFullScreenDialog && services.layout.isInSmallScreenView
                                                                    ? ToolbarSectionBoxInFullScreen
                                                                    : TopSectionBoxInNonFullScreen);

    const TopSectionContentBox = services.device.isWindows
                                ? HeaderTopSectionContentForWindowBox
                                : HeaderTopSectionContentBox;


    const isCloseButtonHidden = Boolean(props.hideCloseButton) ||
                                    (props.dialogHandler.closeButtonBehavior === DialogCloseButtonBehavior.None && !props.customCloseButtonHandler);

    const renderCloseButtonMirror = () => {
        if(isCloseButtonHidden) {
            return null;
        }

        if(props.dontMirrorCloseButton) {
            return null;
        }

        return (<CloseButtonMirrorBox/>);
    }

    return (
        <DialogHeaderContainerBox hasModalSheetHandle={props.dialogHandler.hasModalSheetHandle}>
            <TopSectionBox>
                <TopSectionContentBox>
                    <DialogCloseButtonComponent dialogHandler={props.dialogHandler}
                                                onClick={props.customCloseButtonHandler}
                                                isHidden={isCloseButtonHidden}/>
                    <HeaderTitleContainerBox>
                        {props.title}
                    </HeaderTitleContainerBox>

                    {renderCloseButtonMirror()}
                </TopSectionContentBox>
            </TopSectionBox>
            <BottomSectionBox>
                {props.children}
            </BottomSectionBox>
        </DialogHeaderContainerBox>
    );
});
