import {IDeepLinkHandler} from "../deep-link-handler.interface";
import {IServiceFactory} from "../../service-factory.interface";

export class ResetPasswordDeepLinkHandler implements IDeepLinkHandler {
    constructor(private readonly services: IServiceFactory) {
    }

    canHandler(link: string): boolean {
        return 0 <= link.toLowerCase().indexOf('/membership/resetpassword/')

    }
    async execute(link: string): Promise<void> {
        const url = new URL(link);
        const urlParts = url.pathname.split('/');
        const token = urlParts[urlParts.length - 1];
        await this.services.user.resetPassword(token);

    }
}
