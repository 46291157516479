import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const WheelchairIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} sizeIsHeight {...props} viewBox="0 0 22.214 24">
            <g transform="translate(-0.893)" fill={props.color? props.color: "currentColor"}>
                <g transform="translate(0.893)">
                    <path
                        d="M13.275,10.207a6.1,6.1,0,0,1-2.25,2.393,5.975,5.975,0,0,1-3.17.886,5.883,5.883,0,0,1-4.316-1.793A5.884,5.884,0,0,1,1.745,7.377a5.964,5.964,0,0,1,1-3.334A6.033,6.033,0,0,1,5.4,1.786L5.168,0A7.692,7.692,0,0,0,1.425,2.877,7.662,7.662,0,0,0,0,7.377a7.654,7.654,0,0,0,1.057,3.934A7.864,7.864,0,0,0,3.92,14.175a7.656,7.656,0,0,0,3.934,1.057,7.652,7.652,0,0,0,4.623-1.514,7.731,7.731,0,0,0,2.864-3.955L13.95,6.982A5.867,5.867,0,0,1,13.275,10.207Z"
                        transform="translate(0 8.768)" />
                    <path
                        d="M15.756,17.114l-2.7,1.35-3.109-6.2a.8.8,0,0,0-.777-.477h-6.2l-.218-1.745H8.3V8.291H2.528l-.5-3.941A2,2,0,0,0,3.66,3.784a2.111,2.111,0,0,0,.709-1.6A2.1,2.1,0,0,0,3.728.641,2.1,2.1,0,0,0,2.187,0,2.183,2.183,0,0,0,.865.429,2.021,2.021,0,0,0,.087,1.554a1.432,1.432,0,0,0-.082.573L1.314,12.75a.863.863,0,0,0,.293.553.847.847,0,0,0,.58.225H8.624l3.26,6.5a.8.8,0,0,0,.778.477.82.82,0,0,0,.4-.1l3.49-1.746Z"
                        transform="translate(5.667)"/>
                </g>
            </g>
        </SvgIcon>
    )
}
