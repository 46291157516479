import React, {useState, forwardRef, useRef, useImperativeHandle} from 'react';
import {Swiper, SwiperProps, SwiperRef} from "swiper/react";
import {useServices} from "../../hooks/use-services.hook";
import {observer} from "mobx-react-lite";
import {Check} from "../../types/type-checking";
import {
    createSwiperNextButtonSelector,
    createSwiperPrevButtonSelector,
    SwiperNavigationBackButtonComponent,
    SwiperNavigationButtonSize, SwiperNavigationNextButtonComponent
} from "./swiper-navigation-button.component";
import styled from "styled-components";

const SwiperBox = styled(Swiper)`
  width: 100%;
  
  .swiper-pagination-bullet {
    &.swiper-pagination-bullet-active {
      background: ${props => props.theme.colors.primary};
      opacity: 1;
    }
  }
`


export interface ISwiperButtonsOptions {
    size?: SwiperNavigationButtonSize;
    customPrevButtonSelector?: string;
    customNextButtonSelector?: string;
    hideButtons?: boolean
}

interface SwiperComponentProps extends SwiperProps {
    navigationButtons?: ISwiperButtonsOptions;
}

export const SwiperComponent = observer(forwardRef<SwiperRef | null, SwiperComponentProps>((props, ref) => {
    const services = useServices();
    const internalSwiperRef = useRef<SwiperRef | null>(null);
    const [prevButtonSelector] = useState(props.navigationButtons?.customPrevButtonSelector ?? createSwiperPrevButtonSelector());
    const [nextButtonSelector] = useState(props.navigationButtons?.customNextButtonSelector ?? createSwiperNextButtonSelector());


    useImperativeHandle(ref, () => internalSwiperRef.current as SwiperRef, [internalSwiperRef])

    props = {
        spaceBetween: services.theme.currentTheme.spacing.space(3),
        ...props
    }

    let {navigationButtons, ...swiperProps} = props;

    swiperProps = {
        resistanceRatio: 0,
        ...swiperProps
    };

    let previousButton: React.ReactElement | null = null;
    let nextButton: React.ReactElement | null = null;

    if(navigationButtons) {
        if(!Check.isObject(swiperProps.navigation)) {
            swiperProps.navigation = {};
        }

        swiperProps.navigation = {
            ...swiperProps.navigation,
            enabled: true,
            prevEl: `.${prevButtonSelector}`,
            nextEl: `.${nextButtonSelector}`,
        };

        if(!navigationButtons.customPrevButtonSelector) {
            previousButton = (<SwiperNavigationBackButtonComponent selector={prevButtonSelector}
                                                                   isHidden={navigationButtons.hideButtons}
                                                                   size={navigationButtons?.size ?? "medium"}/>);
        }

        if(!navigationButtons.customNextButtonSelector) {
            nextButton = <SwiperNavigationNextButtonComponent selector={nextButtonSelector}
                                                              isHidden={navigationButtons.hideButtons}
                                                              size={navigationButtons?.size  ?? "medium"}/>
        }
    }

    return (
        <>
            {previousButton}
            <SwiperBox {...swiperProps} className={props.className} ref={internalSwiperRef}>
                {props.children}
            </SwiperBox>
            {nextButton}
        </>
    )
}))


