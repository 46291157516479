import {SsrsSelectionComponent} from "../../../common-components/ssrs/ssrs-selection.component";
import React from "react";
import {observer} from "mobx-react-lite";
import {IBookingSsrsAggregatorViewModel} from "../../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {
    ExtrasSectionLargeScreenComponent
} from "./extras-section.large-screen.component";
import {
    IJourneyCustomSsrsEditorRenderer,
    IPassengerCustomSsrEditorRenderer, SsrsSelectionPresentationMode
} from "../../../common-components/ssrs/ssrs-selection.component.props";
import {ExtrasSectionLargeScreenComponentCommonProps} from "./extras-section.large-screen-component-common.props";
import {NullableString} from "../../../../../types/nullable-types";
import {ExtrasSsrsSectionPurchaseSummaryLargeScreenComponent} from "./summary/extras-ssrs-section-purchase-summary.large-screen.component";

interface ExtrasSsrsSectionLargeScreenComponentProps extends ExtrasSectionLargeScreenComponentCommonProps {
    ssrsAggregator: IBookingSsrsAggregatorViewModel;
    sectionTitle: string | React.ReactElement;
    sectionDetails?: NullableString | React.ReactElement;
    renderIcon: () => React.ReactElement | null;
    journeyCustomSsrsEditorRenderer?: IJourneyCustomSsrsEditorRenderer;
    passengerCustomSsrEditorRenderer?: IPassengerCustomSsrEditorRenderer;
    disclaimer?: string | React.ReactElement;
    presentationMode?: SsrsSelectionPresentationMode;
    topBanner?: string | React.ReactElement | null;
}

export const ExtrasSsrsSectionLargeScreenComponent: React.FC<ExtrasSsrsSectionLargeScreenComponentProps> = observer((props) => {


    return (
        <ExtrasSectionLargeScreenComponent sectionTitle={props.sectionTitle}
                                           sectionDetails={props.sectionDetails}
                                           hasPurchases={props.ssrsAggregator.hasPurchases}
                                           minDiscountedPrice={props.ssrsAggregator.discountedMinPrice}
                                           minStandardPrice={props.ssrsAggregator.standardMinPrice}
                                           modificationBlockingReason={props.ssrsAggregator.modificationBlockingReason}
                                           renderIcon={props.renderIcon}
                                           renderPurchaseSummary={() => (<ExtrasSsrsSectionPurchaseSummaryLargeScreenComponent ssrsAggregator={props.ssrsAggregator}/>)}
                                           topBanner={props.topBanner}
                                           onExpand={props.onExpand}
                                           currentExpandedSection={props.currentExpandedSection}
                                           renderContent={(headerElementRef) => <SsrsSelectionComponent ssrsAggregator={props.ssrsAggregator}
                                                                                        passengerCustomSsrEditorRenderer={props.passengerCustomSsrEditorRenderer}
                                                                                        journeyCustomSsrsEditorRenderer={props.journeyCustomSsrsEditorRenderer}
                                                                                        disclaimer={props.disclaimer}
                                                                                        presentationMode={props.presentationMode} stickyTopRef={headerElementRef}/>} />
    );

});
