import {MyAccountDashboardItemBase} from "../my-account-dashboard-item-base";
import React from "react";
import { BlueBenefitsIcon } from "../../../assets/svg/blue-benefits.icon";

export class BlueBenefitsDashboardItem extends MyAccountDashboardItemBase {
    get text(): string {
        return this.services.language.translate('Blue Benefits');
    }

    renderIcon(): React.ReactElement {
        return <BlueBenefitsIcon/>;
    }

    protected _requiresLogin(): boolean {
        return false;
    }

    protected async _onExecute(): Promise<void> {
        this.services.navigator.routes.user.blueBenefits.activate();
    }
}
