import {Station} from "../../stations/station.service.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {makeObservable, observable, runInAction} from "mobx";
import {
    IDotRezAvailabilityLowFarePassengers,
    IDotRezAvailabilityLowFareRequest
} from "../../dot-rez-api/data-contracts/requests/booking/lowfare-search.request";
import {Check} from "../../../types/type-checking";
import {MonthModel} from "../../time/month.model";
import {ILowFareResult, IOneWayLowFareReader, LowFareStatusEnum} from "./low-fare-reader.interface";
import {NullableNumber, NullableUndefinedNumber} from "../../../types/nullable-types";
import {IFlightScheduleFilter} from "../../flight-search/flights-schedule/flight-schedule-filter.interface";
import {IDotRezBookingSession} from "../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";


export class OneWayLowFareReader implements IOneWayLowFareReader {
    constructor(private readonly originStation: Station,
                private readonly destinationStation: Station,
                private readonly getBookingSession: () => Promise<IDotRezBookingSession>,
                private readonly getClassOfServices: () => string[],
                private readonly getMinPriceAmount: () => NullableNumber,
                public readonly getCurrency: () => string,
                private readonly scheduleFilter: IFlightScheduleFilter,
                private readonly services: IServiceFactory,
                private readonly passengersCount: () => IDotRezAvailabilityLowFarePassengers) {

        makeObservable<this, '_monthlyLowFares'>(this, {
           _monthlyLowFares: observable.ref
        });

    }

    private _monthlyLowFares: Record<string, MonthLowFaresReader> = {};

    private _createMonthLowFaresReaderFromDate(date: Date) {
        const month = new MonthModel(date.getMonth(), date.getFullYear(), this.services.time);
        if(!this._monthlyLowFares[month.key]) {
            this._monthlyLowFares[month.key] = new MonthLowFaresReader(month,
                this.originStation,
                this.destinationStation,
                this.getBookingSession,
                this.getClassOfServices,
                this.getMinPriceAmount,
                this.getCurrency,
                this.scheduleFilter,
                this.services,
                this.passengersCount());
        }

        return this._monthlyLowFares[month.key];
    }

    getLowFare(date: Date): ILowFareResult {
        return this._createMonthLowFaresReaderFromDate(date).getLowFare(date);
    }

    getLowFareAsync(date: Date): Promise<ILowFareResult> {
        return this._createMonthLowFaresReaderFromDate(date).getLowFareAsync(date);
    }


}


class MonthLowFaresReader {
    constructor(private readonly month: MonthModel,
                private readonly originStation: Station,
                private readonly destinationStation: Station,
                private readonly getBookingSession: () => Promise<IDotRezBookingSession>,
                private readonly getClassOfServices: () => string[],
                private readonly getMinPriceAmount: () => NullableNumber,
                private readonly getCurrency: () => string,
                private readonly scheduleFilter: IFlightScheduleFilter,
                private readonly services: IServiceFactory,
                private readonly passengersCount: IDotRezAvailabilityLowFarePassengers) {

        makeObservable<this, '_dailyLowFares'>(this, {
            _dailyLowFares: observable.ref
        });

        this._loadLowFaresPromise = this._loadLowFares();
    }


    private _dailyLowFares: Record<string, ILowFareResult> | null = null;
    private _readLowFaresFailed = false;

    getLowFare(date: Date): ILowFareResult {

        if(date.getTime() < this.services.time.makeShortDate(this.services.time.currentDate).getTime()) {
            return {
                status: LowFareStatusEnum.NoFare
            };
        }

        if(this.scheduleFilter.shouldFilterOutDate(date)) {
            return {
                status: LowFareStatusEnum.NoFare
            };
        }

        if(!this._dailyLowFares) {
            return {
                status: LowFareStatusEnum.Reading
            };
        }

        if(this._readLowFaresFailed) {
            return {
                status: LowFareStatusEnum.Error
            };
        }

        const formattedDate = this.services.time.formatYYY_MM_DD(date);
        const lowFare = this._dailyLowFares[formattedDate];

        if(lowFare) {
           return lowFare;
        }

        return {
            status: LowFareStatusEnum.SoldOut
        };
    }

    private readonly _loadLowFaresPromise: Promise<void>;
    async getLowFareAsync(date: Date): Promise<ILowFareResult> {
        await this._loadLowFaresPromise;
        return this.getLowFare(date);
    }

    private async _getSession(): Promise<IDotRezBookingSession> {
        return await this.getBookingSession();
    }

    private _createLowFareResult(amount: NullableUndefinedNumber): ILowFareResult {
        if(Check.isNullOrUndefined(amount)) {
            return {
                status: LowFareStatusEnum.NoFare
            }
        }

        const minPrice = this.getMinPriceAmount();
        if(!Check.isNullOrUndefined(minPrice) && amount < minPrice) {
            return {
                status: LowFareStatusEnum.NoFare
            }
        }

        return {
            status: LowFareStatusEnum.Loaded,
            price: this.services.currency.createPrice(amount, this.getCurrency())
        };
    }

    private async _loadLowFares(): Promise<void> {
        try {
            const lowFareRequest = this._createLowFareRequest();
            const session = await this._getSession();
            let lowFareDateMarkets = (await session.availabilityLowFare(lowFareRequest)).lowFareDateMarkets;
            runInAction(() => {

                const temp: Record<string, NullableUndefinedNumber> = {};
                lowFareDateMarkets.forEach(record => {
                    const key = this.services.time.formatYYY_MM_DD(record.departureDate);
                    const newVal = record.lowestFareAmount?.fareAmount;
                    const existingVal = temp[key];
                    if(Check.isNullOrUndefined(existingVal)) {
                        temp[key] = newVal;
                    } else if(!Check.isNullOrUndefined(newVal)) {
                        temp[key] = Math.min(existingVal, newVal);
                    }
                });

                this._dailyLowFares = Object.keys(temp).toDictionaryOfType(key => key,
                                                                           key => this._createLowFareResult(temp[key]));

            });
        } catch (err) {
            this.services.logger.error(`Failed to read low fares for ${this.originStation.stationMacCode} | ${this.destinationStation.stationMacCode}`, err);
            this._readLowFaresFailed = true;
        }

    }

    private _createLowFareRequest() : IDotRezAvailabilityLowFareRequest {
        const time = this.services.time;
        const numberOfDays = time.differenceInCalendarDays(this.month.firstDate, this.month.lastDate);

        const daysToLeft = Math.round(numberOfDays / 2)
        const daysToRight = numberOfDays - daysToLeft;


        return {
            origin: this.originStation.stationMacCode,
            destination: this.destinationStation.stationMacCode,
            beginDate: time.formatYYY_MM_DD(time.addDays(this.month.firstDate, daysToLeft)),
            currencyCode: this.getCurrency(),
            loyaltyFilter: 'MonetaryOnly',
            daysToLeft: daysToLeft,
            daysToRight: daysToRight,
            passengers: this.passengersCount,
            classesOfService: this.getClassOfServices()
        };
    }
}

