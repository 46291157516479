import {IServiceFactory} from "../../service-factory.interface";
import {BookingHistoryModel} from "../models/booking-history.model";
import {
    IDotRezBookingData,
    IDotRezJourney
} from "../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {
    IPersistedBookingHistory,
    IPersistedJourneyHistoryDesignator
} from "../models/persisted-booking-history.interface";
import {BoardingPassesStorage} from "../models/boarding-passes-storage";
import {IDotRezBookingSession} from "../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {IPassengerSegmentBoardingPassViewModel} from "../../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {
    computed,
    IReactionDisposer,
    makeObservable,
    observable,
    reaction,
    runInAction
} from "mobx";
import {JourneyHistoryModel} from "../models/journey-history.model";
import {IBookingHistoryStrategy} from "./booking-history-strategy.interface";
import {BookingModel} from "../../booking/models/booking.model";
import {MAXIMUM_NUMBER_OF_FLIGHTS_TO_SHOW_ON_HOME_PAGE} from "../booking-history.service.interface";
import {TimeSpan} from "../../../types/time-span";
import {NullableNumber} from "../../../types/nullable-types";
import {LocalStorageKeys} from "../../storage/local-storage-keys";
import {ISearchBookingByEmailParams, ISearchBookingByLastNameParams} from "../../booking/booking.service.interface";

export const ANONYMOUS_USER_TRIPS_STORAGE_KEY: LocalStorageKeys = 'booking.myTrips';
const TRIP_SYNC_DELAY_TIME = TimeSpan.fromMinutes(1);

export abstract class BookingHistoryStrategyBase implements IBookingHistoryStrategy {

    private _lastTripSyncTime: NullableNumber = null;

    constructor(protected readonly services: IServiceFactory) {
        makeObservable<this, '_myTrips' | '_boardingPassesStorage' | '_isLoadingHistoryInProgress'>(this, {
            _isLoadingHistoryInProgress: observable.ref,
            _myTrips: observable,
            myFutureFlights: computed,
            myPastFlights: computed,
            _boardingPassesStorage: computed
        });

        this._loadMyTripsFromLocalStorage();

        this._reactions.push(reaction(() => this.services.application.isActive,
            async (isActive: boolean) => {

                if(isActive) {
                    // The setTimeout here is for the following scenario:
                    // On the manage my booking flow when the user comes back from the payment page (which opens in another tab or in the InAppBrowser)
                    // the isActive reaction is triggered. But if the user changed the passenger name and e-mail address then
                    // when the trips synchronization is started it will fail because the manage my booking flow
                    // didn't saved yet the booking in the history and we still have in the session storage the old passenger name and e-mail address.
                    // For this reason we delay a little bit the synchronization to give the manage my booking flow the chance to save modified booking in history.
                    setTimeout(async () => {
                        const now = this.services.time.currentDate.getTime();
                        if(!this._lastTripSyncTime || (now - this._lastTripSyncTime > TRIP_SYNC_DELAY_TIME.totalMilliseconds)) {
                            this._lastTripSyncTime = now; // We put it before so in case the user switches back and forth to the application we will not issue a lot of request
                            await this._startTripsSynchronization();
                        }
                    })

                }
            }, {
                fireImmediately: true
            }));
    }

    private _reactions: IReactionDisposer[] = [];


    protected abstract _getTripsStorageKey(): string;
    protected abstract _createBoardingPassesStorage(): BoardingPassesStorage;
    protected abstract _syncTrips(): Promise<void>;
    protected abstract _loadMyTripsFromLocalStorage(): void;
    protected get _boardingPassesStorage(): BoardingPassesStorage {
        return this._createBoardingPassesStorage()
    }

    protected _getAnonymousTripsStorageKey(): string {
        return ANONYMOUS_USER_TRIPS_STORAGE_KEY;
    }

    private async _startTripsSynchronization(): Promise<void> {
        runInAction(() => {
            this._isLoadingHistoryInProgress = true;
        });

        try {
            await this._syncTrips();
            this.myFutureFlights.distinct(f => f.recordLocator, f => f.trip).slice(0, 10).forEach(b => b.refreshBookingData());

        } finally {
            runInAction(() => {
                this._isLoadingHistoryInProgress = false;
            });
        }
    }

    private _myTrips: BookingHistoryModel[] = [];
    protected _setMyTrips(trips: BookingHistoryModel[], persistToStorage: boolean = true): void {
        runInAction(() => {
            this._myTrips = trips;
        });

        if(persistToStorage) {
            this.services.localStorage.setJson(this._getTripsStorageKey(), trips.map(t => t.tripInfo));
        }

        //just make it async
        setTimeout(() => {
            this._clearOrphanBoardingPasses();
        });
    }

    protected _appendToMyTrips(trips: BookingHistoryModel[]): void {
        this._setMyTrips([
            ...this._myTrips,
            ...trips
        ]);
    }

    get myTripsCount(): number {
        return this._myTrips.length;
    }

    private _isLoadingHistoryInProgress = false;
    get isLoadingHistoryInProgress(): boolean {
        return (this.myTripsCount === 0 && this._isLoadingHistoryInProgress)
            || !this.services.user.profile.isProfileInitialized;
    }

    get myFutureFlights(): JourneyHistoryModel[] {
        return this._sortFlightsAscending(this._getMyFlights(flight => flight.isFutureFlight));
    }

    getNextFlights(): JourneyHistoryModel[] {
        // Take only flights that are within 30 days from now
        return this.myFutureFlights.filter(f => this.services.time.currentDate.getTime() >= f.departureDate.getTime() - TimeSpan.fromDays(30).totalMilliseconds)
            .slice(0, MAXIMUM_NUMBER_OF_FLIGHTS_TO_SHOW_ON_HOME_PAGE);
    }


    get myPastFlights(): JourneyHistoryModel[] {
        return this._sortFlightsDescending(this._getMyFlights(flight => !flight.isFutureFlight));
    }

    protected _getMyFlights(flightSelector: (flight: JourneyHistoryModel) => boolean): JourneyHistoryModel[] {
        const result: JourneyHistoryModel[] = [];

        for(let trip of this._myTrips) {
            for(let flight of trip.flights) {
                if(flightSelector(flight)) {
                    result.push(flight);
                }
            }
        }

        return result;
    }


    saveToMyTrips(bookingModel: BookingModel): void {

        const tripInfo = this._convertBookingDataToPersistedHistoryData(bookingModel.bookingData);

        let bookingHistoryModel = new BookingHistoryModel(tripInfo, this.services, bookingModel);

        this._insertBookingIntoMyTrips(bookingHistoryModel);
    }

    private _insertBookingIntoMyTrips(bookingHistoryModel: BookingHistoryModel): void {
        const index = this._myTrips.findIndex(trip => trip.isMatch(bookingHistoryModel))

        if(index >= 0) {
            const newTrips = [...this._myTrips];
            newTrips[index] = bookingHistoryModel;
            this._setMyTrips(newTrips);
        } else {
            this._setMyTrips([
                ...this._myTrips,
                bookingHistoryModel
            ]);
        }
    }

    protected _sortFlightsAscending(flights: JourneyHistoryModel[]): JourneyHistoryModel[] {
        return flights.sort((f1, f2) => this.services.time.parseIsoDate(f1.designator.departureDate).getTime() - this.services.time.parseIsoDate(f2.designator.departureDate).getTime());
    }

    protected _sortFlightsDescending(flights: JourneyHistoryModel[]): JourneyHistoryModel[] {
        return flights.sort((f1, f2) => this.services.time.parseIsoDate(f2.designator.departureDate).getTime() - this.services.time.parseIsoDate(f1.designator.departureDate).getTime());
    }

    protected _convertBookingDataToPersistedHistoryData(dotRezBookingData: IDotRezBookingData): IPersistedBookingHistory {
        if(!dotRezBookingData.recordLocator) {
            throw new Error('Cannot save a booking without record locator');
        }
        const bookingContact = dotRezBookingData.contacts[0].value;
        const departureJourneyDesignator = dotRezBookingData.journeys[0].designator;

        const createPersistedJourneyDesignator = (dotRezJourney: IDotRezJourney): IPersistedJourneyHistoryDesignator => {
            return {
                departure: dotRezJourney.designator.departure,
                arrival: dotRezJourney.designator.arrival,
                stops: dotRezJourney.segments.sum(segment => segment.legs.length - 1)
            }
        }

        const result: IPersistedBookingHistory = {
            environmentType: this.services.configuration.currentEnvironmentType,
            recordLocator: dotRezBookingData.recordLocator,
            bookingKey: dotRezBookingData.bookingKey!,
            email: bookingContact.emailAddress!,
            firstName: bookingContact.name.first!,
            lastName: bookingContact.name.last!,
            origin: departureJourneyDesignator.origin,
            originName: this.services.stations.tryGetStation(departureJourneyDesignator.origin)?.stationName || '',
            destination: departureJourneyDesignator.destination,
            destinationName: this.services.stations.tryGetStation(departureJourneyDesignator.destination)?.stationName || '',
            outbound: createPersistedJourneyDesignator(dotRezBookingData.journeys[0])
        };

        const returnJourney = dotRezBookingData.journeys[1];
        if(returnJourney) {
            result.inbound = createPersistedJourneyDesignator(returnJourney)
        }
        return result;
    }

    private _clearOrphanBoardingPasses() {

        try {
            const allFlightsByTripReferenceKey: Record<string, JourneyHistoryModel> = {};
            for(let trip of this._myTrips) {
                for(let flight of trip.flights) {
                    allFlightsByTripReferenceKey[flight.tripReferenceKey] = flight;
                }
            }

            const boardingPassesKeysToRemove: string[] = [];

            for(let tripReferenceKey of Object.keys(this._boardingPassesStorage.boardingPasses)) {
                if(!allFlightsByTripReferenceKey[tripReferenceKey]) {
                    boardingPassesKeysToRemove.push(tripReferenceKey);
                }
            }

            if(boardingPassesKeysToRemove.length > 0) {
                this._boardingPassesStorage.removeBoardingPasses(boardingPassesKeysToRemove);
            }
        } catch (err) {
            this.services.logger.error('Failed to clear orphan boarding passes', err);
        }

    }

    saveBoardingPasses(boardingPasses: IPassengerSegmentBoardingPassViewModel[]): IPassengerSegmentBoardingPassViewModel[] {
        return this._boardingPassesStorage.saveBoardingPasses(boardingPasses);
    }

    getSavedBoardingPasses(tripReferenceKey: string): IPassengerSegmentBoardingPassViewModel[]
    {
        return this._boardingPassesStorage.getBoardingPasses(tripReferenceKey);
    }


    async retrieveBookingToHistoryByEmail(findByEmailRequest: ISearchBookingByEmailParams): Promise<void> {
        await this._retrieveBookingToHistory(async (session) => await session.bringBookingInStateByEmail(findByEmailRequest));
    }

    async retrieveBookingToHistoryByLastName(findByLastNameRequest: ISearchBookingByLastNameParams): Promise<void> {
        await this._retrieveBookingToHistory(async (session) => await session.bringBookingInStateByLastName(findByLastNameRequest));
    }

    private async _retrieveBookingToHistory(retrieveBooking: (session: IDotRezBookingSession) => Promise<IDotRezBookingData>): Promise<void> {
        const session = await this.services.user.createTransientBookingSession();
        const bookingData = await retrieveBooking(session);

        if(bookingData.journeys.length === 0) {
            this.services.alert.showError(this.services.language.translate('This booking was canceled'));
            return;
        }

        const bookingHistoryModel = new BookingHistoryModel(this._convertBookingDataToPersistedHistoryData(bookingData), this.services);
        this._insertBookingIntoMyTrips(bookingHistoryModel);

        try {
            await bookingHistoryModel.refreshBookingData();
        } catch (err) {
            this.services.logger.error(`Failed to refresh booking history model after booking retrieve ${bookingData.recordLocator}`, err);
        }

        await this._onAfterBookingRetrieved(bookingData, session);
    }

    protected async _onAfterBookingRetrieved(bookingData: IDotRezBookingData, session: IDotRezBookingSession): Promise<void> {

    }

    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }

    removeBooking(recordLocator: string): void {
        const allTheOtherTrips = this._myTrips.filter(t => t.tripInfo.recordLocator !== recordLocator);
        this._setMyTrips(allTheOtherTrips);
    }
}
