import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react-lite";
import {StandardDialogPageComponent} from "../../../modal/page/standard-dialog-page.component";
import {StandardDialogContentComponent} from "../../../modal/content/standard-dialog-content.component";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import { StandardDialogHeaderComponent } from "../../../modal/header/standard-dialog-header.component";

interface ShoppingCartPriceDetailsDialogComponentProps extends PropsWithChildren {
    dialogHandler: IDialogConfirmationHandler;
    title: string;
}

export const ShoppingCartPriceDetailsDialogComponent: React.FC<ShoppingCartPriceDetailsDialogComponentProps> = observer((props) => {


    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={props.title} dialogHandler={props.dialogHandler} />
            <StandardDialogContentComponent>
                {props.children}
            </StandardDialogContentComponent>
        </StandardDialogPageComponent>
    );
});
