import React from "react";
import {observer} from "mobx-react";
import {ActionSheetButton, IonActionSheet} from "@ionic/react";
import {IStandardDropDownListViewModel} from "../list-view/models/list/standard-drop-down-list-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {IDropDownController} from "../../drop-down/models/drop-down-controller.interface";


interface StandardDropDownActionSheetViewComponentProps<TFieldValue> {
    listViewModel: IStandardDropDownListViewModel<TFieldValue>;
    dropDownController: IDropDownController;
    label?: string;
}

export const StandardDropDownActionSheetComponent = observer(<TFieldValue extends any>(props: StandardDropDownActionSheetViewComponentProps<TFieldValue>) => {
    const services = useServices();

    const actionSheetButtons: ActionSheetButton[] = [];
    for(let i of props.listViewModel.filteredItems) {
        actionSheetButtons.push({
            text: i.getDisplayText(),
            data: i,
            handler: () => {
                props.listViewModel.setSelectedItem(i)
            }
        });
    }

    if(actionSheetButtons.length === 0) {
        return null;
    }

    actionSheetButtons.push({
        text: services.language.translate('Cancel'),
        role: "cancel",
        handler: () => {
            props.dropDownController.closeDropDown();
        }
    });

    return (
        <IonActionSheet isOpen={true} mode={"ios"} buttons={actionSheetButtons} header={props.label}>

        </IonActionSheet>
    );
});

