import {AirlineWebapiErrorCodesEnum} from "./airline-webapi-error-codes.enum";

export class AirlineWebapiResponse<TData> {
    constructor(public readonly errorCode: AirlineWebapiErrorCodesEnum,
                private readonly _data?: TData) {
    }

    get hasError(): boolean {
        return this.errorCode !== AirlineWebapiErrorCodesEnum.Success;
    }

    get data(): TData {
        if(!this._data) {
            throw new Error('Attempt to access api response data on error response');
        }

        return this._data;
    }


    ensureSuccess(): void {
        if(this.hasError) {
            throw new Error(`Airline webapi failed with error code ${this.errorCode}`);
        }
    }
}
