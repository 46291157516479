import React, {useCallback, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {ISeatFilterViewModel} from "../../../../services/booking/models/seat-maps/filters/seat-filter.view-model.interface";
import styled, {css} from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {createPopper, Instance as PopperInstance} from "@popperjs/core";
import {ZIndex} from "../../../../types/z-index";
import {PopperArrowBox} from "../../../../components/popper-arrow/popper-arrow.box";

const SeatFiltersBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
    gap: ${props => props.theme.spacing.large};
    padding-top: ${props => props.theme.spacing.small};
    font-size: ${props => props.theme.fontSize.large};
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      font-size: ${props => props.theme.fontSize.medium};
    }
`

const SeatFilterBox = styled.div<{isApplied: boolean}>`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    gap: ${props => props.theme.spacing.small};
    cursor: pointer;
    padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
    border-radius: ${props => props.theme.border.defaultRadius};
    width: 40px;
    ${props => props.isApplied 
                ? css`
                  background-color: ${props => props.theme.colors.primary};
                  color: ${props => props.theme.colors.primaryContrast};
                `
                : css`
                  background-color: ${props => props.theme.colors.lightShade};
                  color: ${props => props.theme.colors.primary};
                  &:hover {
                    background-color:${props => props.theme.colors.mediumTint};
                    color: ${props => props.theme.colors.mediumContrast};
                  }
                `
    }
`

const SeatFilterIconBox = styled.div`
    
`

const SeatFilterTitleBox = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primaryContrast};
  text-align: center;
  padding: ${props => props.theme.spacing.medium};
  border-radius: ${props => props.theme.border.defaultRadius};
  z-index: ${ZIndex.DropDownPopper};
  max-width: 150px;
  min-width: 150px;
 
`

const LabelBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: ${props => props.theme.fontWeight.bold};
`

interface SeatFilterComponentProps {
    filter: ISeatFilterViewModel;
}
const SeatFilterComponent: React.FC<SeatFilterComponentProps> = observer((props) => {

    const [showTitle, setShowTitle] = useState(false);
    const seatFilterElementRef = useRef<HTMLDivElement | null>(null);
    const titleElementRef = useRef<HTMLDivElement | null>(null);
    const popperArrowRef = useRef<HTMLDivElement | null>(null);
    const popperRef = useRef<PopperInstance | null>(null);

    const onClick = () => {
        props.filter.isApplied = !props.filter.isApplied;
    }

    const onMouseEnter = () => {
        setShowTitle(true);
    }

    const onMouseLeave = () => {
        setShowTitle(false);
    }

    const createPopperInstance = useCallback(() => {
        if(seatFilterElementRef.current && titleElementRef.current && popperArrowRef.current) {
            return createPopper(seatFilterElementRef.current, titleElementRef.current, {
                placement: 'bottom',
                strategy: "absolute",
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 5]
                        }
                    },
                    {
                        name: 'arrow',
                        options: {
                            element: popperArrowRef.current
                        }
                    }
                ]
            });
        } else {
            return null;
        }
    }, []);

    useEffect(() => {

        if(showTitle && !popperRef.current) {
            popperRef.current = createPopperInstance();
        }

        return () => {
            if(popperRef.current) {
                popperRef.current.destroy();
                popperRef.current = null;
            }
        };
    }, [createPopperInstance, showTitle]);

    const renderDescription = () => {
        if(showTitle) {
            return (
                <SeatFilterTitleBox ref={titleElementRef}>
                    <PopperArrowBox ref={popperArrowRef}/>
                    {showTitle && props.filter.description}
                </SeatFilterTitleBox>
            );
        }

        return null;
    }

    return (
        <SeatFilterBox isApplied={props.filter.isApplied}
                       ref={seatFilterElementRef}
                       onClick={onClick}
                       onMouseEnter={onMouseEnter}
                       onMouseLeave={onMouseLeave}>
            <SeatFilterIconBox>
                {props.filter.renderIcon()}
            </SeatFilterIconBox>
            {renderDescription()}
        </SeatFilterBox>

    )
});

export const SeatFiltersComponent: React.FC = observer(() => {
    const services = useServices();
    const filters = services.booking.current.seatsMapsEditors.seatFilters;
    return (
        <SeatFiltersBox>
            <LabelBox>{services.language.translate('Show only seats suitable for:')}</LabelBox>
            {filters.map(f => <SeatFilterComponent key={f.description} filter={f}/>)}
        </SeatFiltersBox>
    )
})
