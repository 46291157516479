import React from "react";
import styled from "styled-components";
import {WizardProgressIndicatorComponent} from "../../components/wizard-progress-indicator/wizard-progress-indicator.component";
import {useServices} from "../../hooks/use-services.hook";
import {observer} from "mobx-react";

const TitleContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${props => props.theme.spacing.medium};
`

const TitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
`

interface BookingFlowTitleComponentProps {
    title: string | React.ReactElement;
    hideWizardProgressIndicator?: boolean;
}
export const BookingFlowTitleComponent: React.FC<BookingFlowTitleComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <TitleContainerBox>
            <TitleBox>
                {props.title}
            </TitleBox>
            {!props.hideWizardProgressIndicator && <WizardProgressIndicatorComponent getWizard={() => services.booking.steps} />}
        </TitleContainerBox>
    )
});
