import {IServiceFactory} from "../../service-factory.interface";
import React from "react";
import {HomePageService} from "../home-page.service";
import {IHomePageTab} from "./home-page.tab.interface";

export abstract class HomePageTabBase implements IHomePageTab {
    constructor(protected readonly services: IServiceFactory, protected readonly homePageService: HomePageService) {
    }

    abstract get text(): string;
    abstract renderIcon(): React.ReactElement;
    abstract renderContent(): React.ReactElement;

    get isVisible(): boolean {
        return true;
    }

    renderHeader(): React.ReactElement | null {
        return null;
    }
    renderFooter(): React.ReactElement | null {
        return null;
    }

    open(): void {
        this.services.navigator.goHome().then(() => {
            if(this.homePageService.currentTab !== this) {
                this.homePageService.setCurrentTab(this);
            }
        });

    }
    deactivate(): void {

    }
}
