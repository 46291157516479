import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PersonCircleFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 24 24">
            <g fill={props.color? props.color: "currentColor"}>
                <circle id="Ellipse_63" data-name="Ellipse 63" cx="12" cy="12" r="12"/>
                <g id="group" transform="translate(-441.15 -466.58)">
                    <circle id="Ellipse_32" data-name="Ellipse 32" cx="2.061" cy="2.061" r="2.061" transform="translate(451.174 473.58)" fill="#fff"/>
                    <path id="Path_299" data-name="Path 299" d="M456.821,547.136a1.352,1.352,0,0,0-1.346-1.346H451a1.352,1.352,0,0,0-1.346,1.346v1.779h7.171Z" transform="translate(0 -67.072)" fill="#fff"/>
                    <circle id="Ellipse_33" data-name="Ellipse 33" cx="2.061" cy="2.061" r="2.061" transform="translate(451.174 473.58)" fill="#fff"/>
                    <path id="Path_301" data-name="Path 301" d="M456.821,547.136a1.352,1.352,0,0,0-1.346-1.346H451a1.352,1.352,0,0,0-1.346,1.346v1.779h7.171Z" transform="translate(0 -67.072)" fill="#fff"/>
                    <circle id="Ellipse_34" data-name="Ellipse 34" cx="2.061" cy="2.061" r="2.061" transform="translate(451.174 473.58)" fill="#fff"/>
                    <path id="Path_303" data-name="Path 303" d="M456.821,547.136a1.352,1.352,0,0,0-1.346-1.346H451a1.352,1.352,0,0,0-1.346,1.346v1.779h7.171Z" transform="translate(0 -67.072)" fill="#fff"/>
                    <circle id="Ellipse_35" data-name="Ellipse 35" cx="2.061" cy="2.061" r="2.061" transform="translate(451.174 473.58)" fill="#fff"/>
                    <path id="Path_305" data-name="Path 305" d="M456.821,547.136a1.352,1.352,0,0,0-1.346-1.346H451a1.352,1.352,0,0,0-1.346,1.346v1.779h7.171Z" transform="translate(0 -67.072)" fill="#fff"/>
                    <circle id="Ellipse_36" data-name="Ellipse 36" cx="2.061" cy="2.061" r="2.061" transform="translate(451.174 473.58)" fill="#fff"/>
                    <path id="Path_307" data-name="Path 307" d="M456.821,547.136a1.352,1.352,0,0,0-1.346-1.346H451a1.352,1.352,0,0,0-1.346,1.346v1.779h7.171Z" transform="translate(0 -67.072)" fill="#fff"/>
                </g>
            </g>
        </SvgIcon>
    )
}
