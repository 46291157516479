export class SeatProperty {
    constructor(public readonly code: string,
                public readonly value: string) {
    }

    isMatch(code: string, value: string): boolean {
        return this.code === code && this.value === value;
    }
}

export class ExitRowProperty extends SeatProperty {
    constructor(value: string) {
        super('EXITROW', value);
    }
    static get True(): ExitRowProperty {
        return new ExitRowProperty('True');
    }
}

export class LegRoomProperty extends SeatProperty {
    constructor(value: string) {
        super('LEGROOM', value);
    }
    static get True(): LegRoomProperty {
        return new LegRoomProperty('True');
    }
}
export class LocationProperty extends SeatProperty {
    constructor(value: string) {
        super('LOCATION', value);
    }

    static get Front(): LocationProperty {
        return new LocationProperty('FRONT');
    }
    static get Rear(): LocationProperty {
        return new LocationProperty('REAR');
    }
}

export class NChildProperty extends SeatProperty {
    constructor(value: string) {
        super('NCHILD', value);
    }

    static get True(): PETProperty {
        return new NChildProperty('True');
    }
}

export class TCCProperty extends SeatProperty {
    constructor(value: string) {
        super('TCC', value);
    }
}

export class DisabilProperty extends SeatProperty {
    constructor(value: string) {
        super('DISABIL', value);
    }

    static get True(): PETProperty {
        return new DisabilProperty('True');
    }
}


export class AISLEProperty extends SeatProperty {
    constructor(value: string) {
        super('AISLE', value);
    }
}


export class PETProperty extends SeatProperty {
    constructor(value: string) {
        super('PET', value);
    }

    static get True(): PETProperty {
        return new PETProperty('True');
    }
}
