import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {SsrsSelectionComponent} from "./ssrs-selection.component";
import {SsrsSelectionComponentProps} from "./ssrs-selection.component.props";
import styled from "styled-components";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../../../../components/continue-button/continue-button.component";

const StandardDialogContentBox = styled(StandardDialogContentComponent)`
  --padding-top: ${props => props.theme.spacing.pageDefaultPaddingPx};
`


export interface SsrsSelectionDialogComponentProps extends SsrsSelectionComponentProps {
    title: string | React.ReactElement;
    dialogHandler: IDialogConfirmationHandler;
}

export const SsrsSelectionDialogComponent: React.FC<SsrsSelectionDialogComponentProps> = observer((props) => {
    const {title, dialogHandler, ...ssrsSelectionComponentProps} = props;
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={title} dialogHandler={dialogHandler}/>
            <StandardDialogContentBox>
                <SsrsSelectionComponent {...ssrsSelectionComponentProps} />
            </StandardDialogContentBox>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ContinueButtonComponent onClick={() => props.dialogHandler.accept()} fullWidth={true}/>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
});
