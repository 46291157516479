import {TimeSpan} from "../../../../types/time-span";
import {IPaymentStatusResponse} from "../../../airline-webapi/responses/payment-status.response";
import {IServiceFactory} from "../../../service-factory.interface";
import {IPaymentStatusProvider} from "./payment-status-provider.interface";
import {NullableUndefinedNumber} from "../../../../types/nullable-types";
import {PaymentTransactionStatusEnum} from "../../../airline-webapi/enums/payment-transaction-status.enum";

export class PaymentStatusObserver {
    constructor(private readonly services: IServiceFactory,
                private readonly paymentStatusProvider: IPaymentStatusProvider) {
    }

    async start(timeout?: TimeSpan): Promise<IPaymentStatusResponse> {
        let timeoutExpirationDate: NullableUndefinedNumber = undefined;
        if(timeout) {
            timeoutExpirationDate = Date.now() + timeout.totalMilliseconds;
        }
        let response = await this._queryPaymentStatus();
        if(this._shouldFinalize(response)) {
            return response;
        }

        return await this._waitForFinalize(timeoutExpirationDate);
    }

    private _shouldFinalize(paymentStatus: IPaymentStatusResponse, timeoutExpirationDate?: NullableUndefinedNumber): boolean {

        if(paymentStatus.status !== PaymentTransactionStatusEnum.NotFinalized) {
            return true;
        }

        if(timeoutExpirationDate) {
            return timeoutExpirationDate < Date.now();
        }

        return false;
    }


    private _waitForFinalize(timeoutExpirationDate: NullableUndefinedNumber): Promise<IPaymentStatusResponse> {
        return new Promise<IPaymentStatusResponse>((resolve) => {
            const intervalRef = setInterval(async () => {
                const response = await this._queryPaymentStatus();

                if (this._shouldFinalize(response, timeoutExpirationDate)) {
                    clearInterval(intervalRef);
                    resolve(response);
                }
            }, TimeSpan.fromSeconds(1).totalMilliseconds);
        });
    }



    private async _queryPaymentStatus(): Promise<IPaymentStatusResponse> {

        try {
            return await this.paymentStatusProvider.getPaymentStatus();
        } catch (err) {
            this.services.logger.error('_getPaymentStatus failed', err);
            return {
                status: PaymentTransactionStatusEnum.NotFinalized,
                apiErrorCode: null,
                pspErrorDetails: null
            };
        }
    }
}
