import {IServiceFactory} from "../../../../../../services/service-factory.interface";

export function computeExtrasLargeScreenSummaryGridColumns(services: IServiceFactory, numberOfItems: number): number {
    let gridColumns = 4;

    if(services.mediaQuery.xlAndBelow) {
        gridColumns = 3;

        if(numberOfItems % 2 === 0) {
            gridColumns = 2;
        }
    }

    if(services.mediaQuery.mAndBelow) {
        gridColumns = 1;
    }

    return Math.min(numberOfItems, gridColumns);
}
