import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PlaneCircleFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 24 24">
            <g fill={props.color? props.color: "currentColor"}>
                <circle id="Ellipse_63" data-name="Ellipse 63" cx="12" cy="12" r="12" />
                <path id="Path_590" data-name="Path 590" d="M615,645.194a9.751,9.751,0,0,0,2.814.644l6.69-1.793a1.13,1.13,0,0,0,.867-1.121,1.111,1.111,0,0,0-1.309-.539l-2.772.74-4.611-2.95a.19.19,0,0,0-.208.021l-.446.437a.146.146,0,0,0-.02.19l2.334,3.088-1.87.5-1.972-1.223a.164.164,0,0,0-.193.021l-.293.286a.162.162,0,0,0-.023.192A10.666,10.666,0,0,0,615,645.194Z" transform="translate(-606.963 -631.153)" fill="#fff"/>
            </g>
        </SvgIcon>
    )
}
