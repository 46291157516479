import React from 'react';
import { ISvgIconProps, SvgIcon } from "./svg-icon.component";


export const ExclamationTriangle: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={10} sizeIsHeight {...props} viewBox="0 0 104 100" ratio={104/100}>
            <g fill={props.color? props.color: "currentColor"} ><path d="M99.059,97H4.941L52,6.5Z" /><path d="M52,13.005,9.883,94H94.117ZM52,0l52,100H0Z" /><path d="M51.559,67.457a3.814,3.814,0,0,1-2.095-.41,1.479,1.479,0,0,1-.571-1.289V44.108a1.373,1.373,0,0,1,.762-1.274,5.065,5.065,0,0,1,2.373-.425h2.227a5.435,5.435,0,0,1,2.329.4,1.213,1.213,0,0,1,.806,1.1l-1.025,8.643Q56.1,54.83,55.7,58.126t-.923,7.632a1.866,1.866,0,0,1-.63,1.3,3.53,3.53,0,0,1-2.007.4ZM51,79.088q-3.164,0-3.164-1.641v-4.8q0-1.67,3.164-1.67h1.643q3.135,0,3.135,1.67v4.8q0,1.641-3.135,1.641Z" fill="#fff"/></g>
        </SvgIcon>
    )
}
