import {getPlatforms, isPlatform} from "@ionic/react";
import {DeviceType} from "./device.service.interface";
import { Device } from '@capacitor/device';
import {OperatingSystem} from "@capacitor/device/dist/esm/definitions";

export class PlatformDetection {

    async getDeviceId(): Promise<string> {
        const deviceId = await Device.getId();
        return deviceId.identifier;
    }

    get isIOS(): boolean {
        return isPlatform('ios');
    }

    get isAndroid(): boolean {
        return isPlatform('android');
    }

    get isHybrid(): boolean {
        return isPlatform('hybrid');
    }

    get isMobile(): boolean {
        return isPlatform('mobile');
    }

    get isWeb(): boolean {
        return !this.isHybrid;
    }

    async getOperatingSystem(): Promise<OperatingSystem> {
        return (await Device.getInfo()).operatingSystem;
    }

    get deviceType(): DeviceType {
        if(this.isIOS) {
            return DeviceType.iOS;
        } else if(this.isAndroid){
            return DeviceType.Android;
        } else {
            return DeviceType.other
        }
    }

    get isAndroidMobileHybrid(): boolean {
        const platforms = getPlatforms();
        return platforms.includes('android') && platforms.includes('mobile') && platforms.includes('hybrid');
    }
}
