import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const WeaponIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.6} {...props} viewBox="0 0 26 15.959">
            <g fill={props.color? props.color: "currentColor"}>
                <path d="M349.678,417a24.613,24.613,0,0,1,2.049-4.253h-.51v-.21s1.321-1.776,1.038-2.732-.674-.956-.756-1a2.863,2.863,0,0,0-.61-.018v-.091s-.938-.082-1.02-.082-.246-.137-.246-.437.009-.419.528-.556.829-.255.865-.255.31-.018.392-.018a1.374,1.374,0,0,0,.92-.792,2.19,2.19,0,0,0-.137-1.466,10.232,10.232,0,0,0-.874-.738c-.018-.036-.219-.182.055-.373s.856.346.874.455h.565l.091-.2a3.257,3.257,0,0,1,.465-.72h.829s.064-.319.51-.31.328.337.328.337l6.585-.055,7.3.018,4.645-.018.364-.282h.455l.31.31h.264l.155.209.209-.009a.273.273,0,0,1,.173.273s.082,1.567.064,2.013a1.671,1.671,0,0,1-.064.537h-.073v.638s-.091.2-.2.2l-.082.173s.009.2-.537.191-4.281,0-4.281,0v.291l-.118.128h-4.162a2.22,2.22,0,0,0-1.685.546c-.628.665.237,1.375-.738,2.277s-3.352.583-3.671.583a1.921,1.921,0,0,0-1.193.346,1.946,1.946,0,0,0-.774,1c-.228.674-1.731,5.847-1.731,5.847l.246.082-.018.219h-.7l-1.011-.064s-3.261-.455-3.452-.455h-.41s0,.656-.419.619-.137-.6-.1-.656l-.519-.1S349.341,418.226,349.678,417Zm11.2-5.938a1.012,1.012,0,0,1-.273.118,7.964,7.964,0,0,0,1.357.073c.583.009,1.667-.264,1.794-1.266a1.844,1.844,0,0,0-.82-1.612h-1.8c-.264,0-.3.1-.3.1l.064.091a2.037,2.037,0,0,0-.246,1.858C360.69,410.576,360.936,410.995,360.881,411.058Z" transform="translate(-349.566 -403.198)" />
            </g>
        </SvgIcon>
    )
}