import React from "react";
import {observer} from "mobx-react";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageHeaderComponent} from "../../basic-components/page/standard-page-header/standard-page-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {CompanionsListComponent} from "./components/companions-list.component";
import {StandardPageFooterComponent} from "../../basic-components/page/standard-page-footer/standard-page-footer.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";

export const CompanionsPage: React.FC = observer(() => {
    const services = useServices();

    const renderPageContent = () => {
        if(!services.user.profile.isCompanionsListInitialized) {
            return (<SpinnerComponent/>);
        }

        return (<CompanionsListComponent/>);
    }

    const renderPageFooter = () => {
        if(!services.user.profile.isCompanionsListInitialized) {
            return null;
        }
        const onAddCompanion = async () => {
            await services.user.profile.addCompanion();
        }

        return (
            <StandardPageFooterComponent>
                <PrimaryButton onClick={onAddCompanion}>
                    {services.language.translate('Add companion')}
                </PrimaryButton>
            </StandardPageFooterComponent>
        );
    }

    return (
        <RoutedPageComponent>
            <StandardPageHeaderComponent title={services.language.translate('My Companions')} hideLogoButton={true}/>
            <StandardPageContentComponent>
                {renderPageContent()}
            </StandardPageContentComponent>
            {renderPageFooter()}
        </RoutedPageComponent>
    )
});
