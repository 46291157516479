import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";

export const TicketPrintDateComponent: React.FC = observer(() => {
    const services = useServices();

    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "flex-end",
        gap: services.theme.currentTheme.spacing.small,
        width: '100%',
        fontSize: services.theme.currentTheme.fontSize.small
    }

    return (
        <div style={styles}>
            <div>{services.language.translationFor('Printed at: {time}').withParams({time: services.time.currentDate.toString()})}</div>
        </div>
    )
})