import React from "react";
import {observer} from "mobx-react";
import {FormComponent} from "../forms/form.component";
import {TravelDocumentTypeFieldEditorComponent} from "../forms/travel-document-type/travel-document-type-field-editor.component";
import {IPersonTravelDocumentViewModel} from "../../services/user/models/person/person-travel-document-view-model.interface";
import {CountryPickerComponent} from "../country-picker/country-picker.component";
import {DateFieldEditorComponent} from "../forms/date-time/date-field-editor.component";
import {useServices} from "../../hooks/use-services.hook";
import {UndefinedDate} from "../../types/nullable-types";
import { DocumentNumberEditorComponent } from "../forms/document-number-field/document-number-field-editor.component";
export const PersonTravelDocumentEditorComponent: React.FC<{travelDocument: IPersonTravelDocumentViewModel}> = observer((props) => {
    const services = useServices();
    const fields = props.travelDocument.fields;
    const time = services.time;
    const currentDate = time.makeShortDate(time.currentDate);
    const maxIssuedDate: UndefinedDate = props.travelDocument.isNew ? currentDate : undefined;
    const minExpirationDate: UndefinedDate = props.travelDocument.isNew ? time.addDays(currentDate, 1) : undefined;
    const maxExpirationDate = minExpirationDate ? services.time.addYears(minExpirationDate, 100)
                                                : services.time.addYears(services.time.currentDate, 100);

    return (
        <FormComponent title={props.travelDocument.getOwnerFullName()}>
            <TravelDocumentTypeFieldEditorComponent field={fields.documentTypeCode}/>
            <DocumentNumberEditorComponent field={fields.number}/>
            <CountryPickerComponent field={fields.issuedByCode}/>
            <DateFieldEditorComponent field={fields.issuedDate} maximumValue={maxIssuedDate}/>
            <DateFieldEditorComponent field={fields.expirationDate} minimumValue={minExpirationDate} maximumValue={maxExpirationDate}/>
            <CountryPickerComponent field={fields.nationality}/>
        </FormComponent>
    );
});
