import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {IInvoiceViewModel} from "../../../services/booking/models/invoice/invoice-view-model.interface";
import {CheckboxComponent} from "../../../basic-components/checkbox/checkbox.component";

const RequestInvoiceToggleBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.small};
`

const RequestInvoiceCheckBox = styled(CheckboxComponent)`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    width: 100%;
`

const RequestInvoiceNoteBox = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    line-height: 1.1;
    @media ${props => props.theme.mediaQuery.sAndBelow} {
      font-size: ${props => props.theme.fontSize.xxxSmall};
    }
`

export const RequestInvoiceToggleComponent: React.FC<{invoice: IInvoiceViewModel}> = observer((props) => {
    const services = useServices();
    return (
        <RequestInvoiceToggleBox className={'tst-invoice-toggle'}>
            <RequestInvoiceCheckBox checked={props.invoice.invoiceRequested}
                                    onChange={(isChecked) => props.invoice.invoiceRequested = isChecked}
                                    label={services.language.translate('Request invoice')}
                                    labelPlacement={'start'}/>
            <RequestInvoiceNoteBox>
                {services.language.translate('THE INVOICE MUST BE REQUESTED AT THE SAME TIME AS THE PURCHASE')}
            </RequestInvoiceNoteBox>
        </RequestInvoiceToggleBox>
    );
});
