import { ServiceBase } from '../service-base';
import {IPdfExporterService, IPrintPdfOptions, ISharePdfOptions} from './pdf-exporter.service.interface';
import {IServiceFactory} from "../service-factory.interface";
import {IPdfExporterStrategy} from "./strategies/pdf-exporter.strategy.interface";
import {PdfExporterWebappStrategy} from "./strategies/pdf-exporter-webapp.strategy";
import {PdfExporterHybridStrategy} from "./strategies/pdf-exporter-hybrid.strategy";

export class PdfExporterService extends ServiceBase implements IPdfExporterService {

    constructor(services: IServiceFactory) {
        super(services);

        if(services.device.isHybrid) {
            this._strategy = new PdfExporterHybridStrategy(services);
        } else {
            this._strategy = new PdfExporterWebappStrategy(services);
        }
    }

    private readonly _strategy: IPdfExporterStrategy;


    async printPdf(options: IPrintPdfOptions): Promise<void> {
        await this._strategy.printPdf(options);
    }

    get supportsSharing(): boolean {
        return this._strategy.supportsSharing;
    }
    async sharePdf(options: ISharePdfOptions): Promise<void> {
        await this._strategy.sharePdf(options);
    }
}
