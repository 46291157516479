import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../components/designator/designator.component";
import {FlightDesignatorModel} from "../../../../services/booking/models/designator/flight-designator.model";
import {FlightsDatesSelector} from "../../../../services/flight-search/dates-selection/flights-dates-selector";
import {IDateSelectionStrategy} from "../../../../services/flight-search/dates-selection/strategy/date-selection-strategy.interface";
import {ReadonlyCheckboxComponent} from "../../../../basic-components/checkbox/readonly-checkbox.component";

const SelectedDateDetailsBox = styled.div<{isCurrent: boolean}>`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.spacing.medium};
    min-height: 45px;
    cursor: pointer;
    background-color: ${props => props.theme.colors.lightShade};
    color: ${props => props.theme.colors.medium};
    ${props => props.isCurrent 
        ? css`
            background-color: ${props => props.theme.colors.tertiary};
            color: ${props => props.theme.colors.tertiaryContrast};
            `
        : ''} 
`

const SelectedDateValueBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
`

const DesignatorComponentBox = styled(DesignatorComponent)`
    font-weight: ${props => props.theme.fontWeight.bold};
`

const TripTypeContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: ${props => props.theme.colors.primaryContrast};
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.large};
    padding: ${props => props.theme.spacing.medium};
`



const FlightsDatesSelectionHeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const RoutesContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    width: 100%;
    min-height: 52px;
`


interface SelectedDateComponentProps {
    strategy: IDateSelectionStrategy;
}

const FlightDateSelectionStrategyComponent: React.FC<SelectedDateComponentProps> = observer((props) => {
    const services = useServices();
    const strategy = props.strategy;

    const currentDate = services.time.currentDate.toISOString();
    return (
        <SelectedDateDetailsBox isCurrent={strategy.isCurrentStrategy} onClick={() => strategy.setAsCurrentStrategy()}>
            <DesignatorComponentBox displayMode={DesignatorDisplayMode.stationsNamesOnly}
                                    designator={new FlightDesignatorModel(services, strategy.origin, strategy.destination, currentDate, currentDate, currentDate, currentDate, [], null)}
                                    useMac={true}/>
            <SelectedDateValueBox>
                {strategy.getCurrentDate() && services.time.formatUserFriendlyDate(strategy.getCurrentDate())}
            </SelectedDateValueBox>
        </SelectedDateDetailsBox>
    );
});



interface FlightsDatesSelectionHeaderComponentProps {
    flightsDatesSelector: FlightsDatesSelector;
    hideTripTypeSelector?: boolean;
}

export const FlightsDatesSelectionHeaderComponent: React.FC<FlightsDatesSelectionHeaderComponentProps> = observer((props) => {
    const services = useServices();

    const renderTripType = () => {
        if(props.hideTripTypeSelector) {
            return null;
        }

        return (
            <TripTypeContainerBox>
                <ReadonlyCheckboxComponent checked={!props.flightsDatesSelector.flightSearchController.isOneWayDepartureTrip}
                                           label={services.language.translate('Round Trip')}
                                           onClick={() => props.flightsDatesSelector.flightSearchController.isOneWayDepartureTrip = false}/>

                <ReadonlyCheckboxComponent checked={props.flightsDatesSelector.flightSearchController.isOneWayDepartureTrip}
                                           label={services.language.translate('One Way')}
                                           onClick={() => props.flightsDatesSelector.flightSearchController.isOneWayDepartureTrip = true}/>
            </TripTypeContainerBox>
        );
    }

    return (
        <FlightsDatesSelectionHeaderBox>

            {renderTripType()}

            <RoutesContainerBox>
                {
                    props.flightsDatesSelector.strategies.map(strategy => <FlightDateSelectionStrategyComponent key={strategy.uniqueKey} strategy={strategy}/>)
                }
            </RoutesContainerBox>



        </FlightsDatesSelectionHeaderBox>
    )
});
