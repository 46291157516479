import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {BookingFlowPage} from "../booking-flow.page";
import {BagsSelectionPageContentComponent} from "./bags-selection-page-content.component";

export const BagsSelectionPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowPage title={services.language.translate('Select bags')}
                         renderContent={() => <BagsSelectionPageContentComponent/>}
                         showPromotionCode={true}>

        </BookingFlowPage>
    )
});
