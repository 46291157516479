import React from "react";
import {observer} from "mobx-react";
import {SearchMaskTripTypeWebappComponent} from "./trip-type/search-mask-trip-type.webapp.component";
import styled from "styled-components";
import {SearchMaskStationsSelectorWebappComponent} from "./stations-selector/search-mask-stations-selector.webapp.component";
import {SearchMaskBookNowButtonComponent} from "../../../../../components/common/search-mask/search-mask-book-now-button.component";
import {SearchMaskFlightDatesSelectionWebappComponent} from "./flight-dates-drop-down/search-mask-flight-dates-selection.webapp.component";
import {SearchMaskPassengersTypesDropDownWebappComponent} from "./passengers-types-drop-down/search-mask-passengers-types-drop-down.webapp.component";
import {SearchMaskSpecialPriceMarketWebappComponent} from "./special-price-market/search-mask-special-price-market.webapp.component";
import {useServices} from "../../../../../hooks/use-services.hook";

export const SearchMaxBox = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${props => props.theme.fontSize.large};
    width: 100%;
    gap: ${props => props.theme.spacing.large};
`

export const SearchMaskWebappComponent: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;
    const renderSpecialPriceMarketCheckbox = () => {
        const specialPriceMarket = services.stations.getSpecialPriceMarket(searchController.departureOrigin, searchController.departureDestination);
        //TODO - here I should display also the season checkbox.
        if(!specialPriceMarket) {
            return null;
        }

        return (
            <SearchMaskSpecialPriceMarketWebappComponent/>
        );

    }

    return (
        <SearchMaxBox>
            <SearchMaskTripTypeWebappComponent/>
            <SearchMaskStationsSelectorWebappComponent/>
            {renderSpecialPriceMarketCheckbox()}
            <SearchMaskFlightDatesSelectionWebappComponent/>
            <SearchMaskPassengersTypesDropDownWebappComponent/>
            <SearchMaskBookNowButtonComponent onClick={async () => await services.flightSearch.startBooking()}/>
        </SearchMaxBox>
    );
})
