import React, {useRef} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import styled, { css } from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {BundleHeaderComponent} from "./bundle-header.component";
import {BundleContentComponent} from "./bundle-content.component";
import { BundleFooterComponent } from "./bundle-footer.component";
import classNames from "classnames";


const BundleBox = styled(CardBox)<{isSelected: boolean, disableNotSelected: boolean;}>`
    display: flex;
    flex-direction: column;
    opacity: ${props => !props.isSelected && props.disableNotSelected ? '0.5' : '1'};
    padding: 0;
    width: 100%;
    overflow: hidden;
    cursor: ${props => props.isSelected ? "default" : (props.disableNotSelected ? "not-allowed" : "pointer")};
    ${props => props.isSelected ? 
        css`
            border: 1px solid ${props => props.theme.colors.primary};
        `
        : css`
            border: 1px solid ${props => props.theme.border.mainColor};
        `
    }

  
`

interface BundleComponentProps {
    bundle: IBundleViewModel;
    disableNotSelected: boolean;
    onSellBundleClick?: (bundle: IBundleViewModel) => void;
}

export const BundleComponent: React.FC<BundleComponentProps> = observer((props) => {
    const services = useServices();
    const elementRef = useRef<any>(null);
    const bundle = props.bundle;
    const backgroundColor = bundle.bundleType.getBackgroundColor(services.theme.currentTheme);
    const textColor= bundle.bundleType.getTextColor(services.theme.currentTheme);
    const footerBackgroundColor = services.theme.currentTheme.colors.success;
    const footerTextColor = services.theme.currentTheme.colors.successContrast;

    const scrollElementIntoView = () => {
        elementRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"
        });
    }

    const onClick = async () => {
        if(props.onSellBundleClick) {
            props.onSellBundleClick(props.bundle);
        }

        setTimeout(() => {
            scrollElementIntoView();
        }, 250);

    }


    const renderFooter = () => {
        if(bundle.isSelected){
            return(
                <BundleFooterComponent backgroundColor={footerBackgroundColor}
                                       textColor={footerTextColor}
                                       bundle={bundle}/>
            )
        }

        return null;
    }

    return (
        <BundleBox isSelected={bundle.isSelected}
                   disableNotSelected={props.disableNotSelected}
                   ref={elementRef}
                   onClick={onClick}
                   className={classNames('tst-bundle', 'tst-bundle-' + bundle.bundleCode.toLowerCase())}>
            <BundleHeaderComponent bundle={bundle}
                                   price={bundle.pricePerPassenger}
                                   backgroundColor={backgroundColor}
                                   textColor={textColor}/>

            <BundleContentComponent bundle={bundle}/>
            {renderFooter()}
        </BundleBox>
    );
});
