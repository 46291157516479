import React, {useEffect, useRef} from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled from "styled-components";
import {RequestInvoiceToggleComponent} from "../invoice/request-invoice-toggle.component";
import {VoucherCodeComponent} from "./components/voucher-code/voucher-code.component";
import {CreditCardPaymentMethodsComponent} from "./components/credit-card/credit-card-payment-methods.component";
import {PrimaryButtonInverted} from "../../../basic-components/buttons/primary-button-inverted";
import {BookingFlowPage} from "../booking-flow.page";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import {CardBox} from "../../../basic-components/card/card.box";
import {
    BookingPageContentCentralizationComponent
} from "../common-components/booking-page-content-centralization.component";
import {PriceComponent} from "../../../components/price/price.component";
import {HorizontalSpacer} from "../../../basic-components/spacing/horizontal-spacer";
import {reaction} from "mobx";
import {scrollElementIntoViewLazySmooth} from "../../../utils/scroll-element-into-view";
import {ApplicationEnvironmentTypeEnum} from "../../../services/configuration/configuration-object.interface";
import {PaypalButtonComponent} from "./paypal/paypal-button.component";


const PaymentMethodGroupsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.large};
`
const HowWouldYouLikeToPayBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colors.dark};
    line-height: 1;
    @media ${props => props.theme.mediaQuery.sAndBelow} {
      padding-top: ${props => props.theme.spacing.large};
    }
`


const TitleBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const InvoiceFormContainerBox = styled.div`
    width: 100%;
`

const TestEnvIndicatorBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.danger};
    width: 100%;
    text-align: center;
`
const StageEnvIndicatorBox = styled(TestEnvIndicatorBox)`
    color: ${props => props.theme.colors.warning};
    line-height: 1.1;
`

const StageEnvIndicatorDetailsBox = styled(StageEnvIndicatorBox)`
    font-size: ${props => props.theme.fontSize.medium};
`


const PaymentPageContentComponent: React.FC = observer(() => {
    const services = useServices();
    const invoiceFormContainerRef = useRef<HTMLDivElement | null>(null);
    const booking = services.booking.current;
    const paymentHandler = booking.paymentHandler;
    const creditCardPaymentMethods = paymentHandler.creditCardPaymentMethods;


    useEffect(() => {
        const r = reaction(() => booking.invoice.invoiceRequested,
            (invoiceRequested) => {
                if(invoiceRequested) {
                    scrollElementIntoViewLazySmooth(invoiceFormContainerRef);
                }
            })

        return () => r()
    }, [booking.invoice.invoiceRequested, invoiceFormContainerRef]);

    /**
     *  !!!!!!! ONLY FOR TESTING PURPOSE !!!!!!!
     */
    const renderPayCashTestButton = () => {
        if (process.env.REACT_APP_ENABLE_PAY_CASH_BUTTON !== 'true') {
            return null;
        }
        const onClickHandler = async () => {
            await services.loadingIndicator.execute({
                action: async () => {
                    await services.booking.current.payCash()
                }
            });
        }

        return (
            <PrimaryButtonInverted key="test_pay_cash" onClick={onClickHandler}>
                Test Pay Cash
            </PrimaryButtonInverted>

        )
    }


    const renderInvoiceToggle = () => {
        if(booking.balanceDue.amount <= 0) {
            return  null;
        }

        if(!booking.invoice.isInvoiceRequestAllowed) {
            return null;
        }

        return (
            <CardBox>
                <RequestInvoiceToggleComponent invoice={booking.invoice} />
            </CardBox>
        )
    }

    const renderInvoiceForm = () => {
        if(booking.invoice.invoiceRequested) {
            return (
                <InvoiceFormContainerBox ref={invoiceFormContainerRef}>
                    {booking.invoice.renderInvoiceForm()}
                </InvoiceFormContainerBox>
            )

        }
        return null;
    }

    const renderTestEnvIndicator = () => {
        if(services.configuration.isProduction) {
            return null;
        }


        if(services.configuration.envConfigs.currentEnvironmentType === ApplicationEnvironmentTypeEnum.Stage) {
            return (
                <StageEnvIndicatorBox>
                    <div>
                        STAGE ENVIRONMENT
                    </div>
                    <StageEnvIndicatorDetailsBox>
                        Attention! This environment works with Navitaire from production. Any booking created in this environment goes to production.
                    </StageEnvIndicatorDetailsBox>

                </StageEnvIndicatorBox>
            );
        }

        return (
            <TestEnvIndicatorBox>
                TEST ENVIRONMENT
            </TestEnvIndicatorBox>
        );
    }

    return (
        <PaymentMethodGroupsContainerBox>

            {renderTestEnvIndicator()}

            <HowWouldYouLikeToPayBox>
                {services.language.translate('How would you like to pay?')}
            </HowWouldYouLikeToPayBox>

            <VoucherCodeComponent voucher={booking.voucher} />

            <CreditCardPaymentMethodsComponent paymentMethods={creditCardPaymentMethods}
                                               paymentHandler={paymentHandler} />

            <PaypalButtonComponent/>

            {renderPayCashTestButton()}

            {renderInvoiceToggle()}

            {renderInvoiceForm()}

        </PaymentMethodGroupsContainerBox>
    );
});


export const PaymentPage: React.FC = observer(() => {
    const services = useServices();

    const renderTitle = () => {
        return (
            <TitleBox>
                {services.language.translate('Payment')}
            </TitleBox>
        )
    }

    const renderContent = () => {
        return (
            <PaymentPageContentComponent />
        );
    }

    const onPayButtonClick = async () => {
        await services.booking.steps.nextStep();
    }

    const renderFooter = () => {
        return (
            <BookingPageContentCentralizationComponent shoppingCartIsHidden={true}>
                <PrimaryButton onClick={onPayButtonClick} fontSize={"extraLarge"}>
                    {services.language.translate('Pay')}
                    <HorizontalSpacer size={"small"}/>
                    <PriceComponent price={ services.booking.current.balanceDue}/>
                </PrimaryButton>
            </BookingPageContentCentralizationComponent>
        )
    }


    return (
        <BookingFlowPage title={renderTitle()}
                         renderContent={renderContent}
                         renderExtraFooterContent={renderFooter}
                         hideShoppingCart={true}
                         hideContinueButton={true}
                         showPromotionCode={false}
                         disableSwipeForwardNavigation={true}>
        </BookingFlowPage>
    )
});
