import {ILoginAndRegistrationHandlerViewModel} from "./login-and-registration-handler-view-model.interface";
import {LoginFormModel} from "../login/login-form.model";
import {RegistrationFormModel} from "../registration/registration-form.model";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {IServiceFactory} from "../../../service-factory.interface";
import {AuthorizedUserModel} from "../authorized-user/authorized-user.model";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {ILoginFormFields} from "../login/login-form-fields.interface";
import {IRegistrationFormFields} from "../registration/registration-form-fields.interface";
import {AirlineWebapiErrorCodesEnum} from "../../../airline-webapi/airline-webapi-error-codes.enum";

export class LoginAndRegistrationHandler implements ILoginAndRegistrationHandlerViewModel {
    constructor(private readonly services: IServiceFactory) {
        this._loginForm = new LoginFormModel(services);
        this._registrationForm = new RegistrationFormModel(services);
    }

    private readonly _loginForm: LoginFormModel;
    private readonly _registrationForm: RegistrationFormModel;
    public authorizedUser: AuthorizedUserModel | null = null;
    private _registrationSuccessful = false;

    get isRegistrationSuccessful(): boolean {
        return this._registrationSuccessful;
    }

    get loginFormFields(): FormFields<ILoginFormFields> {
        return this._loginForm.fields;
    }

    get registrationFormFields(): FormFields<IRegistrationFormFields> {
        return this._registrationForm.fields;
    }

    get shouldShowContinueAsAGuestButton(): boolean {
        if(this.services.configuration.data.enforceLoginOnBookingFlow) {
            return false;
        }

        return this.services.navigator.routes.booking.hasActiveChildRoute;
    }

    async executeLogin(): Promise<ValidationResultEnum> {
        this._loginForm.activateErrorsValidation();
        if (this._loginForm.hasErrors()) {
            return ValidationResultEnum.Failure;
        }

        const fields = this._loginForm.fields;

        try {
            const errorCode = await this.services.loadingIndicator.execute({
                action: async () => {
                    const loginResponse = await this.services.airlineWebapi.login({
                        userName: fields.userName.value!,
                        password: fields.password.value!
                    });

                    if(!loginResponse.hasError) {
                        this.services.analytics.logLoginEvent();
                        this.services.localStorage.setItem("user.refreshToken", loginResponse.data.refreshToken);
                        this.authorizedUser = new AuthorizedUserModel(this.services, loginResponse.data.refreshToken, loginResponse.data.dotRezToken);
                        await this.authorizedUser.profile.waitForProfileInitialization();
                    }

                    return loginResponse.errorCode;
                }
            });

            if(errorCode !== AirlineWebapiErrorCodesEnum.Success) {
                this.services.alert.showError(this.services.language.translateApiError(errorCode))
                return ValidationResultEnum.Failure;
            }

            return ValidationResultEnum.Success;

        } catch (err) {
            this.services.logger.error(`Login failed for user ${fields.userName}`, err);
            this.services.alert.showError(this.services.language.genericApiErrorMessage());
            return ValidationResultEnum.Failure;
        }
    }

    async executeRegistration(): Promise<ValidationResultEnum> {
        this._registrationForm.activateErrorsValidation();
        if (this._registrationForm.hasErrors()) {
            return ValidationResultEnum.Failure;
        }

        const fields = this._registrationForm.fields;


        try {
            const apiResponse = await this.services.loadingIndicator.execute({
                action: async () => {
                    return await this.services.airlineWebapi.registerUser({
                        firstName: fields.firstName.value!,
                        lastName: fields.lastName.value!,
                        email: fields.emailAddress.value!,
                        password: fields.password.value!,
                        dateOfBirth: this.services.time.formatBirthDate(fields.dateOfBirth.value!),
                        gender: fields.gender.value!,
                        acceptsPromotionalMessages: fields.acceptsPromotionalMessages.value!
                    });
                }
            });

            this._registrationSuccessful = true;

            if(apiResponse.hasError) {
                this.services.alert.showError(this.services.language.translateApiError(apiResponse.errorCode));
                return ValidationResultEnum.Failure;
            }

            this.services.analytics.logSignUpEvent();
            return ValidationResultEnum.Success;

        } catch (err) {
            this.services.logger.error(`Registration failed for ${fields.firstName} ${fields.lastName} | ${fields.emailAddress}`);
            this.services.alert.showError(this.services.language.genericApiErrorMessage());
            return ValidationResultEnum.Failure;
        }
    }
}
