import React from 'react';
import {observer} from "mobx-react-lite";
import {ContinueButtonComponent} from "../../../../components/continue-button/continue-button.component";

interface BookingFlowContinueButtonSmallScreenComponentProps {
    isDisplayedInHeader: boolean;
    onClick: () => void;
    fullWidth?: boolean;
}
export const BookingFlowContinueButtonSmallScreenComponent: React.FC<BookingFlowContinueButtonSmallScreenComponentProps> = observer((props) => {
    return (
        <ContinueButtonComponent onClick={props.onClick}
                                 useInvertedButton={props.isDisplayedInHeader}
                                 fullWidth={props.fullWidth}/>
    );
});
