import React, {CSSProperties, PropsWithChildren} from "react";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {BoardingPassFieldLabelComponent} from "../label/boarding-pass-field-label.component";
import {BoardingPassFieldValueComponent} from "../value/boarding-pass-field-value.component";

const FlightDetailsContainerBox: React.FC<PropsWithChildren> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        lineHeight: '1',
        marginBottom: services.theme.currentTheme.spacing.extraLarge
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
});

interface SectionBoxProps {
    label: string;
    value: string;
    styles: CSSProperties;
}

const SectionBox: React.FC<SectionBoxProps> = (props) => {
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: "column",
        ...props.styles
    }
    return (
        <div style={styles}>
            <BoardingPassFieldLabelComponent>
                {props.label}
            </BoardingPassFieldLabelComponent>
            <BoardingPassFieldValueComponent>
                {props.value}
            </BoardingPassFieldValueComponent>
        </div>
    )
}

const BoardingTimeSectionBox: React.FC<{boardingTime: string}> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        textAlign: "left",
        width: '28%',
        justifyContent: "flex-start"
    };

    return (
        <SectionBox label={services.language.translate('Boarding')}
                    value={services.time.formatHHmm(props.boardingTime)}
                    styles={styles}/>
    );
}

const CenterSectionBox: React.FC<{label: string, value: string}> = (props) => {
    const styles: CSSProperties = {
        flex: '1',
        alignItems: "center",
        justifyContent: "center"
    };

    return (
        <SectionBox label={props.label} value={props.value} styles={styles}/>
    );
};

const DepartureDateSectionBox: React.FC<{departureDate: string}> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        width: '37%',
        alignItems: "flex-end",
        justifyContent: "flex-end"
    };

    return (
        <SectionBox label={services.language.translate('Departure date')}
                    value={`${services.time.formatUserFriendlyDate(props.departureDate)}  ${services.time.formatHHmm(props.departureDate)}`}
                    styles={styles}/>
    );
});

const BoardingSequenceSectionBox: React.FC<{boardingSequence: string}> = (props) => {
    const services = useServices();
    if(!props.boardingSequence) {
        return null;
    }

    return (
        <CenterSectionBox label={services.language.translate('Sequence')} value={props.boardingSequence}/>
    );
}

const SeatSectionBox: React.FC<{seat: string}> = (props) => {
    const services = useServices();
    return (
        <CenterSectionBox label={services.language.translate('Seat')} value={props.seat}/>
    );
}



interface BoardingPassFlightDetailsComponentProps {
    boardingPass: IPassengerSegmentBoardingPassViewModel
}

export const BoardingPassFlightDetailsComponent: React.FC<BoardingPassFlightDetailsComponentProps> = (props) => {

    return (
        <FlightDetailsContainerBox>
            <BoardingTimeSectionBox boardingTime={props.boardingPass.boardingTime}/>

            <BoardingSequenceSectionBox boardingSequence={props.boardingPass.boardingSequence}/>

            <SeatSectionBox seat={props.boardingPass.seat}/>

            <DepartureDateSectionBox departureDate={props.boardingPass.designator.departureDate}/>

        </FlightDetailsContainerBox>
    )
}
