import {IServiceFactory} from "../../../service-factory.interface";
import {SeatSsrTypeBase} from "./seat-ssr-type-base";

export default class StstSsrType extends SeatSsrTypeBase {
    constructor(services: IServiceFactory) {
        super('STST', services);
    }


}
