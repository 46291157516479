import { makeObservable, observable, reaction } from "mobx";
import { IMaintenanceConfiguration } from "../configuration/objects/maintenance-configuration.interface";
import { ServiceBase } from "../service-base";
import { IServiceFactory } from "../service-factory.interface";
import { IMaintenanceService } from "./maintenance.service.interface";

export class MaintenanceService extends ServiceBase implements IMaintenanceService {
    constructor(services: IServiceFactory) {
        super(services);

        makeObservable(this, {
            data: observable
        });

        this._loadMaintenancePromise = this._loadMaintenance()

        reaction(() => this.services.application.isActive,
            (isActive) => {
                if(isActive) {
                    this._loadMaintenancePromise = this._loadMaintenance();
                }
        });
    }

    private _loadMaintenancePromise: Promise<void> = Promise.resolve();

    waitForMaintenance(): Promise<void> {
        return this._loadMaintenancePromise;
    }

    data: IMaintenanceConfiguration = {
        enableMaintenanceAnnouncement: false,
        startDateMaintenanceAnnouncement: new Date().toDateString(),
        endDateMaintenanceAnnouncement: new Date().toDateString(),

        enableMaintenancePage: false,
        startDateMaintenancePage: new Date().toDateString(),
        endDateMaintenancePage: new Date().toDateString()
    }

    private async _loadMaintenance(): Promise<void> {
        /*
        try {
            const response = await this.services.airlineWebapi.getMaintenance();

            runInAction(() => {
                this.data = response;
            });
        } catch (err) {
            this.services.logger.error('Failed to load maintenance', err);
        }
         */
    }
}
