import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';

export const SbusSsrSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} sizeIsHeight {...props} viewBox="0 0 94.5 64" ratio={94.5/64}>
            <g fill={props.color? props.color: "currentColor"}>
                <circle cx="22.3" cy="41.7" r="3.2"/>
                <circle cx="47.7" cy="41.7" r="3.2"/>
                <path
                    d="M52.6 5H17.4C13.9 5 11 7.8 11 11.3v35.3c0 2.5 1.5 4.7 3.7 5.8v2c0 2.7 2.2 4.8 4.8 4.8 2.7 0 4.8-2.2 4.8-4.8v-1.5h21.1v1.5c0 2.7 2.2 4.8 4.8 4.8S55 57 55 54.4v-2c2.3-1 3.7-3.3 3.7-5.8V11.3c.2-3.5-2.6-6.3-6.1-6.3zm-35.2 5.3h35.3c.6 0 1.1.5 1.1 1.1v19H16.3v-19c0-.6.5-1.1 1.1-1.1zm36.3 36.3c0 .6-.5 1.1-1.1 1.1H17.4c-.6 0-1.1-.5-1.1-1.1v-11h37.4v11zm8.9-24.9c-1.5 0-2.6 1.2-2.6 2.6V31c0 1.5 1.2 2.6 2.6 2.6s2.6-1.2 2.6-2.6v-6.7c.1-1.4-1.1-2.6-2.6-2.6zm-55.3 0c-1.5 0-2.6 1.2-2.6 2.6V31c0 1.5 1.2 2.6 2.6 2.6S10 32.5 10 31v-6.7c0-1.4-1.2-2.6-2.7-2.6zm82.5 1.9v-8.1c0-3.5-2.8-6.3-6.3-6.3h-4.8c-3.5 0-6.3 2.8-6.3 6.3v8.1c0 3.4 2.7 6.2 6.1 6.3v24H75c-1.5 0-2.6 1.2-2.6 2.6 0 1.5 1.2 2.6 2.6 2.6h12.3c1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6h-3.5v-24c3.3-.1 6-2.9 6-6.3zm-12.3 0v-8.1c0-.6.5-1.1 1.1-1.1h4.8c.6 0 1.1.5 1.1 1.1v8.1c0 .6-.5 1.1-1.1 1.1h-4.8c-.6 0-1.1-.5-1.1-1.1z"
                />
            </g>

        </SvgIcon>
    )
}
