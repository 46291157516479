import {CreditCardPaymentModel} from "./credit-card-payment.model";
import React from "react";
import {LogoVisaIcon} from "../../../../../../assets/svg/logo-visa.icon";

export class VisaPaymentMethodModel extends CreditCardPaymentModel {
    get name(): string {
        return "Visa";
    }

    renderIcon(): React.ReactElement | null {
        return (<LogoVisaIcon />);
    }
}