import React from "react";
import {observer} from "mobx-react-lite";
import {NullablePrice, Price} from "../../../../services/currency/price";
import {useServices} from "../../../../hooks/use-services.hook";
import {ShoppingCartPriceDetailsButtonComponent} from "../details-button/shopping-cart-price-details-button.component";
import {
    ShoppingCartFeeDetailsDialogComponent,
    ShoppingCartFeeDetailsDialogComponentProps
} from "./shopping-cart-fee-details-dialog.component";
import {DialogCloseButtonBehavior} from "../../../../services/dialog/dialog-enums";

export interface ShoppingCartFeeDetailsButtonComponentProps {
    description: string;
    initialPrice: NullablePrice;
    currentPrice: Price;
}

export const ShoppingCartFeeDetailsButtonComponent: React.FC<ShoppingCartFeeDetailsButtonComponentProps> = observer((props) => {
    const services = useServices();

    if(!services.booking.current.shoppingCart.shouldShowPriceDifferences) {
        return null;
    }

    if(!props.initialPrice) {
        return null;
    }

    const onClickHandler = async () => {

        await services.dialog.showSheetModalDialog({
            breakpoints: [0.25],
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            render: dialogHandler => {

                const dialogProps: ShoppingCartFeeDetailsDialogComponentProps = {
                    ...props,
                    dialogHandler: dialogHandler
                }


                return (<ShoppingCartFeeDetailsDialogComponent {...dialogProps}/>)
            }
        })
    }

    return (
        <ShoppingCartPriceDetailsButtonComponent onClick={onClickHandler}/>
    );
});
