import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {BookingFlowPage} from "../../../booking-flow/booking-flow.page";
import {BagsSelectionPageContentComponent} from "../../../booking-flow/bags-selection/bags-selection-page-content.component";


export const ManageMyBookingBagsSelectionPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowPage title={services.language.translate('Select bags')}
                         renderContent={() => <BagsSelectionPageContentComponent/>}
                         showPromotionCode={true}>

        </BookingFlowPage>
    );
});
