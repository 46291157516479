export enum DialogCloseButtonBehavior {
    Accept = 1,
    Reject = 2,
    None = 3
}

export enum DialogResult {
    Accepted = 1,
    Rejected = 2
}
