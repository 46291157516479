import React from "react";
import {observer} from "mobx-react";
import {RoutedPageComponent} from "../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../basic-components/page/standard-page-content/standard-page-content.component";

/**
 * This page is here for demonstrating the usage of routes with params
 */
export const SampleRouteWithParamsPage: React.FC<{paramFromRoute: string}> = observer((props) => {
    return (
        <RoutedPageComponent>
            <StandardPageContentComponent>
                <div>
                    {props.paramFromRoute}
                </div>
            </StandardPageContentComponent>
        </RoutedPageComponent>
    )
});
