import {IFieldValidator} from "../field-validator.interface";
import {IServiceFactory} from "../../../services/service-factory.interface";
import {NullableString} from "../../../types/nullable-types";

export class PhoneValidator implements IFieldValidator {
    constructor(private readonly services: IServiceFactory) {
    }

    validate(fieldValue: any): NullableString {
        if(!fieldValue) {
            return null;
        }

        const phoneRegularExpression = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
        if(phoneRegularExpression.test(fieldValue)) {
            return null;
        }

        return this.services.language.translate('Invalid phone number');
    }
}
