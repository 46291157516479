import React from "react";
import {observer} from "mobx-react";
import {IBookingViewModel} from "../../services/booking/models/booking-view-model.interface";
import {TicketDialogComponent} from "./ticket-dialog.component";
import {useServices} from "../../hooks/use-services.hook";

export const ShowTicketDialogButtonComponent: React.FC<{booking: IBookingViewModel}> = observer((props) => {
    const services = useServices();
    const onClickHandler = async () => {
        await services.dialog.showStandardDialog({
            render: (dialogHandler) => (<TicketDialogComponent booking={props.booking} dialogHandler={dialogHandler}/>),
            forceFullScreenOnLargeScreens: true
        })
    }
    return (
        <div onClick={onClickHandler} style={{cursor: 'pointer', fontSize: services.theme.currentTheme.fontSize.large}}>
            Show ticket
        </div>
    )
})
