import {IShoppingCartNotificationMessageViewModel} from "./shopping-cart-notification-message-view-model.interface";
import { v4 as uuidv4 } from 'uuid';
import {IShoppingCartNotificationOptions} from "../booking/shopping-cart-notification-options.interface";
import {IReactionDisposer, reaction} from "mobx";
import {BookingShoppingCartNotificationsModel} from "../booking/booking-shopping-cart-notifications.model";

export class ShoppingCartNotificationMessageModel implements IShoppingCartNotificationMessageViewModel {
    constructor(private readonly notifications: BookingShoppingCartNotificationsModel,
                public readonly options: IShoppingCartNotificationOptions) {
        this.id = uuidv4();

        if(options.timeout) {
            this._reactions.push(reaction(() => this.notifications.messagesQueue,
                (notificationMessages) => {
                    // the condition notificationMessages.length === 0 is here because if this is the first notification that we put in the queue
                    // without this condition nothing will happen because this code is in the constructor and basically is executed before this instance to be actually added to the notifications queue
                    if(notificationMessages.length === 0 || notificationMessages[0] === this) {
                        this._dismissTimeout = setTimeout(() => {
                            this.dismiss();
                        }, options.timeout!.totalMilliseconds!)
                    }
                }, {
                    fireImmediately: true
                }));
        }
    }



    readonly id: string;

    private _reactions: IReactionDisposer[] = [];
    private _dismissTimeout: any = null;

    get message(): string {
        return this.options.message;
    }

    dismiss(): void {
        this.dispose();
        this.notifications.remove(this);
    }

    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
        if(this._dismissTimeout) {
            clearTimeout(this._dismissTimeout);
        }
    }
}
