import React, {useEffect} from "react";
import { useServices } from "../../../../hooks/use-services.hook";
import { observer } from "mobx-react";
import { IonCol} from "@ionic/react";
import { ISeatViewModel } from "../../../../services/booking/models/seat-maps/seat-view-model.interface";
import {SeatComponent} from "./seat.component";
import {SeatMapComponent} from "./seat-map.component";
import { SeatsBox } from "./seats.box";
import {ISegmentSeatMapEditorViewModel} from "../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {SwiperRef} from "swiper/react";
import {SeatMapHeaderComponent} from "./seat-map-header.component";
import {reaction} from "mobx";
import {
    SynchronizedSwipersComponent
} from "../../../../components/synchronized-swipers/synchronized-swipers.component";
import {SeatFiltersComponent} from "./seat-filters.component";


interface SeatsComponentProps {
    seats: Array<ISeatViewModel | null>;
}

export const SeatsComponent: React.FC<SeatsComponentProps> = observer((props) => {
    if (props.seats.length === 0) {
        return null;
    }

    return (
        <IonCol size="5" className="ion-align-self-end">
            <SeatsBox>
                {props.seats.map((seat, index) => <SeatComponent key={seat?.seatKey + index.toString()} seat={seat} />)}
            </SeatsBox>
        </IonCol>
    );
});

interface SeatMapsComponentProps {
    stickyTopRef?: React.MutableRefObject<HTMLDivElement | null>;
    headerBackgroundColor?: string;
}

export const SeatMapsComponent: React.FC<SeatMapsComponentProps> = observer((props) => {
    const services = useServices();
    const bookingSeatsMaps = services.booking.current.seatsMapsEditors;
    let headerSwiperRef: SwiperRef | null = null;

    const seatMaps = bookingSeatsMaps.seatMaps;
    const hasSeatMaps = seatMaps.length > 0;

    const captureHeaderSwiper = (swiperRef: SwiperRef | null) => {
        headerSwiperRef = swiperRef;
    }

    useEffect(() => {
        const reactionDisposer = reaction(() => bookingSeatsMaps.currentSeatMap,
            () => {
                const seatMapIndex = seatMaps.findIndex(sm => sm.segmentKey === bookingSeatsMaps.currentSeatMap?.segmentKey);
                headerSwiperRef?.swiper.slideTo(Math.max(0, seatMapIndex));
            }, {
                fireImmediately: true
            });

        return () => {
            reactionDisposer();
        }
    }, [headerSwiperRef, bookingSeatsMaps.currentSeatMap, seatMaps]);


    if (!hasSeatMaps) {
        return null;
    }

    const renderHeaderItem = (seatMap: ISegmentSeatMapEditorViewModel) => {
        return (
            <SeatMapHeaderComponent seatMap={seatMap}/>
        );
    }

    const renderContentItem = (seatMap: ISegmentSeatMapEditorViewModel) => {
        return (
            <SeatMapComponent seatMap={seatMap} />
        );
    }

    const renderExtraHeader = () => {
        //the empty div is to create a gap on the top so when you scroll and sticky position activates seat filters to not be so close to the page header
        return (
            <>
                <div/>
                <SeatFiltersComponent/>
            </>
        )
    }

    const SyncSwipers = SynchronizedSwipersComponent<ISegmentSeatMapEditorViewModel>;

    return (
        <SyncSwipers items={seatMaps}
                     getItemKey={seatMap => seatMap.segmentKey}
                     stickyTopRef={props.stickyTopRef}
                     renderHeaderItem={renderHeaderItem}
                     renderExtraHeader={renderExtraHeader}
                     renderContentItem={renderContentItem}
                     captureHeaderSwiper={captureHeaderSwiper}
                     headerBackgroundColor={props.headerBackgroundColor}/>
    );

})
