import {IBundleType} from "../bundle-types/bundle-type.interface";

export enum BundleCodes {
    ABAS = 'ABAS',
    CBIZ = 'CBIZ',
    CBCM = 'CBCM',
    ABPS = 'ABPS',
    BPLU = 'BPLU',
    CORR = 'CORR',
    ABMR = 'ABMR',
    ABWK = 'ABWK'
}

export interface IBundleTypesConfiguration {
    bundlesDisplayOrder: IBundleType[];
}

export interface IBundleTypesConfigurationService {
    getConfiguration(): IBundleTypesConfiguration;
}
