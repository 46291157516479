import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';

export const SeatSsrLargeIcon: React.FC<ISvgIconProps> = (props) => {
     return (
        <SvgIcon size={3} sizeIsHeight {...props} viewBox="0 0 13.936 19">
            <g fill={props.color? props.color: "currentColor"} transform="translate(-0.007 -0.385)"><path d="M1.646.376A1.381,1.381,0,0,0,1.06.449a1.542,1.542,0,0,0-.988,1.9L3.366,13a3,3,0,0,0,2.855,2.079h6.222a1.46,1.46,0,1,0,0-2.918H6.66a.45.45,0,0,1-.4-.328L3,1.47A1.562,1.562,0,0,0,1.646.376Z" transform="translate(0 0.015)" fillRule="evenodd"/><path d="M4.781,7.219l.667,2.111H9.78a.489.489,0,0,0,.333-.111.4.4,0,0,0,.111-.3V8.589a1.438,1.438,0,0,0-.407-1,1.318,1.318,0,0,0-.963-.37Z" transform="translate(0.776 1.175)" fillRule="evenodd"/><path d="M4.554,13.344,3.665,15.9a.987.987,0,0,0,.111.889,1.02,1.02,0,0,0,.815.407h7.443a.95.95,0,0,0,.778-.407,1,1,0,0,0,.148-.889l-.926-2.555h-.889l1,2.851c.013.036.021.043,0,.074s-.075.075-.111.074H4.591c-.036,0-.088-.047-.111-.074s-.013-.039,0-.074l1-2.851Z" transform="translate(0.502 2.19)" fillRule="evenodd"/></g>
        </SvgIcon>
    )
}
