import styled from "styled-components";

export const DesignatorBox = styled.div`
  line-height: 1;
  font-size: ${props => props.theme.fontSize.xxLarge};
  font-weight: ${props => props.theme.fontWeight.bold};
  @media ${(props) => props.theme.mediaQuery.sAndBelow} {
    font-size: ${props => props.theme.fontSize.large};
  }
`
