import {PluginListenerHandle} from "@capacitor/core";

export class PluginListenerHandlerSubscription {

    constructor(handler: Promise<PluginListenerHandle>) {
        handler.then(s => this._subscription = s);
    }

    private _subscription: PluginListenerHandle | null = null;

    remove(): void {
        this._subscription?.remove();
    }

}
