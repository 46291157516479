import React from "react";
import { ISvgIconProps, SvgIcon } from "./svg-icon.component";

export const LogoGooglePayIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.2} sizeIsHeight {...props} viewBox="0 0 61.584 24" ratio={61.584 / 24}>
            <g fill={props.color ? props.color : "currentColor"}>
                <path d="M185.451,40.673v7.076H183.19V30.275h5.994a5.426,5.426,0,0,1,3.874,1.509,5.015,5.015,0,0,1,0,7.392,5.416,5.416,0,0,1-3.874,1.485h-3.733Zm0-8.246V38.52h3.78a3.028,3.028,0,0,0,2.237-.9,2.934,2.934,0,0,0,.907-2.14,2.9,2.9,0,0,0-.907-2.117,2.942,2.942,0,0,0-2.237-.924h-3.78Z" transform="translate(-156.708 -29)" fill="#5f6368" />
                <path d="M200.594,35.4a5.615,5.615,0,0,1,3.956,1.333A4.713,4.713,0,0,1,206,40.38v7.368h-2.155V46.088h-.094a4.309,4.309,0,0,1-3.733,2.047,4.863,4.863,0,0,1-3.332-1.17,3.724,3.724,0,0,1-1.343-2.924,3.523,3.523,0,0,1,1.413-2.947,5.956,5.956,0,0,1,3.768-1.1,6.622,6.622,0,0,1,3.309.737v-.515a2.542,2.542,0,0,0-.93-1.988,3.2,3.2,0,0,0-2.19-.819A3.441,3.441,0,0,0,197.721,39l-1.99-1.24A5.534,5.534,0,0,1,200.594,35.4Zm-2.92,8.678a1.767,1.767,0,0,0,.753,1.462,2.783,2.783,0,0,0,1.755.585,3.6,3.6,0,0,0,2.543-1.053,3.293,3.293,0,0,0,1.119-2.468,4.681,4.681,0,0,0-2.944-.83,3.882,3.882,0,0,0-2.3.655A1.994,1.994,0,0,0,197.674,44.076Z" transform="translate(-156.708 -29)" fill="#5f6368" /><path d="M218.292,35.784,210.756,53h-2.332l2.8-6.023-4.969-11.193h2.461l3.579,8.585h.047l3.486-8.585Z" transform="translate(-156.708 -29)" fill="#5f6368" />
                <path d="M176.459,39.246a11.691,11.691,0,0,0-.189-2.106h-9.476V41h5.457a4.638,4.638,0,0,1-2.025,3.109v2.5h3.249A9.775,9.775,0,0,0,176.459,39.246Z" transform="translate(-156.708 -29)" fill="#4285f4" /><path d="M170.227,44.109a6.1,6.1,0,0,1-3.431.96,6.031,6.031,0,0,1-5.664-4.136h-3.351v2.583a10.1,10.1,0,0,0,9.015,5.518,9.659,9.659,0,0,0,6.68-2.422Z" transform="translate(-156.708 -29)" fill="#34a853" />
                <path d="M160.817,39.018a6,6,0,0,1,.315-1.917V34.518h-3.351a9.97,9.97,0,0,0,0,9l3.351-2.582A6,6,0,0,1,160.817,39.018Z" transform="translate(-156.708 -29)" fill="#fabb05" />
                <path d="M166.8,32.965a5.5,5.5,0,0,1,3.87,1.5l2.879-2.857A9.725,9.725,0,0,0,166.8,29a10.1,10.1,0,0,0-9.015,5.518l3.351,2.583A6.031,6.031,0,0,1,166.8,32.965Z" transform="translate(-156.708 -29)" fill="#e94235" />
            </g>
        </SvgIcon>
    )
}
