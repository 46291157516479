import {ExtrasSectionRendererBase} from "./extras-section-renderer.base";
import {ExtrasSectionLargeScreenComponentCommonProps} from "../base-component/large-screen/extras-section.large-screen-component-common.props";
import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {ISsrType} from "../../../../services/ssr-types/ssr-types.service.interface";
import {ExtrasSsrsSectionLargeScreenComponent} from "../base-component/large-screen/extras-ssrs-section.large-screen.component";
import React from "react";
import {ExtrasSsrsSectionSmallScreenComponent} from "../base-component/small-screen/extras-ssrs-section.small-screen.component";
import {NullableString, NullableUndefinedString} from "../../../../types/nullable-types";
import {IBookingSsrsAggregatorViewModel} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {
    IJourneyCustomSsrsEditorRenderer,
    IPassengerCustomSsrEditorRenderer, SsrsSelectionPresentationMode
} from "../../common-components/ssrs/ssrs-selection.component.props";

export abstract class ExtrasSsrsSectionRenderer extends ExtrasSectionRendererBase {
    constructor(public readonly ssrTypes: ISsrType[], booking: IBookingViewModel) {
        super(booking);
        this._ssrsAggregator = this._createSsrsAggregator();
    }

    protected abstract _renderSectionTitle(): string | React.ReactElement;

    protected readonly _ssrsAggregator: IBookingSsrsAggregatorViewModel;

    shouldRender(): boolean {
        if(!this.ssrTypes.some(ssrType => this.booking.shouldShowSsrOnCurrentFlow(ssrType))) {
            return false;
        }

        return this.ssrTypes.some(ssrType => this.booking.hasAvailabilityForSSR(ssrType))
                || this.ssrTypes.some(ssrType => this.booking.hasPurchasesForSSR(ssrType));
    }

    protected _createSsrsAggregator(): IBookingSsrsAggregatorViewModel {
        return this.booking.createSsrsAggregator({
            ssrTypes: this.ssrTypes
        });
    }

    protected _renderLargeScreenIcon(): React.ReactElement | null {
        return this.ssrTypes.find(ssrType => ssrType.iconInExtras)?.iconInExtras ?? null;
    }
    protected _renderSmallScreenIcon(): React.ReactElement | null {
        return this._renderLargeScreenIcon();
    }

    protected _getJourneyCustomSsrsEditorRenderer(): IJourneyCustomSsrsEditorRenderer | undefined {
        return undefined;
    }

    protected _getPassengerCustomSsrRenderer(): IPassengerCustomSsrEditorRenderer | undefined {
        return undefined;
    }

    protected _renderDisclaimer(): string | React.ReactElement | undefined {
        return undefined;
    }

    protected _getPresentationMode(): SsrsSelectionPresentationMode | undefined {
        return undefined;
    }

    protected _renderDialogTitle(): string | React.ReactElement | undefined {
        return undefined;
    }

    protected _renderTopBanner(): React.ReactElement | NullableString {
        return null;
    }

    protected _renderSectionDetails(): NullableUndefinedString | React.ReactElement {
        return undefined;
    }


    renderForLargeScreen(props: ExtrasSectionLargeScreenComponentCommonProps): React.ReactElement {
        return (
            <ExtrasSsrsSectionLargeScreenComponent ssrsAggregator={this._ssrsAggregator}
                                                   sectionTitle={this._renderSectionTitle()}
                                                   sectionDetails={this._renderSectionDetails()}
                                                   renderIcon={() => this._renderLargeScreenIcon()}
                                                   journeyCustomSsrsEditorRenderer={this._getJourneyCustomSsrsEditorRenderer()}
                                                   passengerCustomSsrEditorRenderer={this._getPassengerCustomSsrRenderer()}
                                                   disclaimer={this._renderDisclaimer()}
                                                   presentationMode={this._getPresentationMode()}
                                                   topBanner={this._renderTopBanner()}
                                                   {...props} />
        );
    }

    renderForSmallScreen(): React.ReactElement {
        return (
            <ExtrasSsrsSectionSmallScreenComponent sectionTitle={this._renderSectionTitle()}
                                                   sectionDetails={this._renderSectionDetails()}
                                                   renderIcon={() => this._renderSmallScreenIcon()}
                                                   ssrsAggregator={this._ssrsAggregator}
                                                   passengerCustomSsrEditorRenderer={this._getPassengerCustomSsrRenderer()}
                                                   journeyCustomSsrsEditorRenderer={this._getJourneyCustomSsrsEditorRenderer()}
                                                   disclaimer={this._renderDisclaimer()}
                                                   dialogTitle={this._renderDialogTitle()}
                                                   renderTopBanner={() => this._renderTopBanner()}/>
        );
    }



}
