import React from "react";
import {observer} from "mobx-react";
import {
    IBlueBenefitDiscountInfo,
    IBlueBenefitsPackage
} from "../../../../services/blue-benefits/models/packs/blue-benefits-package.interface";
import styled from "styled-components";
import {PriceComponent} from "../../../../components/price/price.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {IonIcon} from "@ionic/react";
import {checkmark, checkmarkOutline} from "ionicons/icons";

const PackagePresentationBox = styled.div`
  display: flex;   
  flex-direction: column;
  padding: 15px 15px 10px;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.lightTint};
  border: 1px solid ${props => props.theme.colors.lightShade};
`

const PackageIconBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 5px;
    flex: 0 0 40px;

    svg {
        g {
            fill: ${props => props.theme.colors.secondary};
        }
    }
`

const PackageHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
`

const PackageDetailsBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    line-height: 1;
`

const CheckmarkBox = styled(IonIcon)`
    margin-right: 3px;
`

const PackageNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.secondary};
    text-transform: uppercase;
`

const PartnersCountDescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.medium};
    margin-top: 2px;
`

const PackagePriceContainerBox = styled.div`
    min-width: 85px;
    font-size: ${props => props.theme.fontSize.small};
    line-height: 1;
    margin-left: 5px;
    text-align: center;
    color: ${props => props.theme.colors.secondary};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const PriceBox = styled(PriceComponent)`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.secondary};
    display: inline;
`

const PriceDescription = styled.div`

`

const PriceConcatenatedBox = styled.div`

`

const PackageDiscountsInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    color: ${props => props.theme.colors.secondary};
`

const DiscountBox = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${props => props.theme.fontSize.medium};
    margin-right: 4px;
    align-items: center;
    line-height: 1;
`

const DiscountValue = styled.span`
    min-width: 50px;
    
`
const DiscountDescription = styled.span`
`

const DiscountInfoComponent: React.FC<{discountInfo: IBlueBenefitDiscountInfo}> = observer((props) => {
    return (
        <DiscountBox>
            <CheckmarkBox ios={checkmarkOutline} md={checkmark}/>
            <DiscountValue>{props.discountInfo.value}</DiscountValue>
            <DiscountDescription>{props.discountInfo.description}</DiscountDescription>
        </DiscountBox>
    );
});

interface BlueBenefitsPackagePresentationComponentProps {
    bbPackage: IBlueBenefitsPackage;
    className?: string;
    displayPackagePrice: boolean;
}

export const BlueBenefitsPackagePresentationComponent: React.FC<BlueBenefitsPackagePresentationComponentProps> = observer((props) => {
    const services = useServices();
    
    const RenderPackagePriceContainer  = () => {    
        if(!props.displayPackagePrice) {
            return null
        }
        return(
            <PackagePriceContainerBox>
                <PriceDescription>{services.language.translate('For only')}</PriceDescription>
                <PriceConcatenatedBox><PriceBox price={props.bbPackage.price}/> {'/'} {services.language.translate('year')}</PriceConcatenatedBox>
            </PackagePriceContainerBox>
        ) 
    }

    return (
        <PackagePresentationBox className={props.className}>
            <PackageHeaderBox>
                <PackageIconBox>
                    {props.bbPackage.ssrType.largeIcon}
                </PackageIconBox>
                <PackageDetailsBox>
                    <PackageNameBox>
                        {props.bbPackage.shortName}
                    </PackageNameBox>
                    <PartnersCountDescriptionBox>
                        {props.bbPackage.partnersCountDescription}
                    </PartnersCountDescriptionBox>
                </PackageDetailsBox>
                {RenderPackagePriceContainer()}
            </PackageHeaderBox>

            <PackageDiscountsInfoBox>
                {props.bbPackage.getDiscountsInfo().map(discountInfo => <DiscountInfoComponent key={discountInfo.description} discountInfo={discountInfo}/>)}
            </PackageDiscountsInfoBox>


        </PackagePresentationBox>
    )
});
