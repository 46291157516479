import React from 'react';
import styled from 'styled-components';
import { useServices } from '../../../../hooks/use-services.hook';
import {observer} from "mobx-react";
import { SwiperSlide } from 'swiper/react'
import {Autoplay,  Pagination} from 'swiper';
import {SwiperComponent} from "../../../../basic-components/swiper/swiper.component";



const PromotionalBannersFeedsBox = styled.div`
    width: 100%;
`

const BannerImgBox = styled.img`
    padding-bottom: 35px;
    height: 200px;
    width: 100%; 
`

export const PromotionalBannersFeedsComponent: React.FC = observer(() => {
    const services = useServices();
    const homePageBanners = services.configuration.data.marketing.homePageBanners || [];

    return (
        <PromotionalBannersFeedsBox>
            <SwiperComponent modules={[Pagination, Autoplay]}
                             pagination={true}
                             loop={true}
                             autoplay={{delay: 3000, disableOnInteraction: false}}
                             slidesPerView={1}
                             autoHeight={true}>
                {homePageBanners.map(banner => (
                    <SwiperSlide key={banner.image}>
                        <BannerImgBox src={`${banner.image}?antiCacheKey=${Date.now()}`}/>
                    </SwiperSlide>
                ))}
            </SwiperComponent>
        </PromotionalBannersFeedsBox>
    )
 });
