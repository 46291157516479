import React from "react";
import {observer} from "mobx-react-lite";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {IAeroitaliaInvoiceViewModel} from "../../../../services/booking/models/invoice/aeroitalia/aeroitalia.invoice-view-model.interface";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {ContinueButtonComponent} from "../../../../components/continue-button/continue-button.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {AeroitaliaInvoiceFormComponent} from "./aeroitalia-invoice-form.component";

interface AeroitaliaInvoiceDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    invoice: IAeroitaliaInvoiceViewModel;
}
export const AeroitaliaInvoiceDialogComponent: React.FC<AeroitaliaInvoiceDialogComponentProps> = observer((props) => {
    const services = useServices();

    const onContinueClick = async () => {
        if (ValidationResultEnum.Success === await props.invoice.validate()) {
            props.dialogHandler.accept();
        }
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Invoice')} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <AeroitaliaInvoiceFormComponent invoice={props.invoice} onSubmit={onContinueClick}/>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ContinueButtonComponent onClick={onContinueClick} fullWidth={true}>
                    {services.language.translate('Continue')}
                </ContinueButtonComponent>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>

    );
});
