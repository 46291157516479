
export class TravelDocumentTypeModel {
    constructor(public readonly value: string,
                private readonly _getText: () => string) {
    }

    get text(): string {
        return this._getText();
    }
}
