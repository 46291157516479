import {IServiceFactory} from "../../../../service-factory.interface";
import React from "react";
import {SpecialEquipmentSsrTypeBase} from "../special-equipment-ssr-type-base";
import { BikeIcon } from "../../../../../assets/svg/bike.icon";


export default class BikeSsrType extends SpecialEquipmentSsrTypeBase {
    constructor(services: IServiceFactory) {
        super('BIKE', services);
    }

    get smallIcon(): React.ReactElement | null {
        return <BikeIcon/>;
    }

    get largeIcon(): React.ReactElement | null {
        return <BikeIcon/>;
    }

}
