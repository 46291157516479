import {IDotRezSessionTimerModel} from "./dot-rez-session-timer.interface";

export class DotRezTransientSessionTimer implements IDotRezSessionTimerModel {

    dispose(): void {
    }

    extendSessionLifetime(): void {
    }

    async setExpired(): Promise<void> {
    }

    isExpired(): Promise<boolean> {
        return Promise.resolve(false);
    }

    pause(): void {

    }
    resume(): Promise<void> {
        return Promise.resolve();
    }

}
