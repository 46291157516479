import React from 'react';
import {AvailableFareComponent} from "./available-fare.component";
import {observer} from "mobx-react";
import {IAvailableFareViewModel} from "../../../../../services/flight-search/models/fares/available-fare-view-model.interface";
import styled from "styled-components";

const AvailableFareListBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
    width: 100%;
`

interface AvailableFareListComponentProps {
    fares: IAvailableFareViewModel[];
}

export const AvailableFareListComponent: React.FC<AvailableFareListComponentProps> = observer((props) => {
    return (
        <AvailableFareListBox>
            {props.fares.map(fare => (<AvailableFareComponent key={fare.fareUniqueId} fare={fare} />))}
        </AvailableFareListBox>
    );
});
