import React from "react";
import {observer} from "mobx-react";
import {FlightItineraryContactDetailsContentComponent} from "../../../../booking-flow/flight-itinerary/components/contact-details/flight-itinerary-contact-details-content.component";
import styled from "styled-components";

const BookingContactBox = styled.div`
    width: 100%;
`

export const ManageMyBookingContactComponent: React.FC = observer(() => {
    return (
        <BookingContactBox>
            <FlightItineraryContactDetailsContentComponent/>
        </BookingContactBox>
    );
});
