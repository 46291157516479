import {BookingModel} from "../booking.model";

export async function waitForBookingMutations(booking: BookingModel): Promise<void> {
    const services = booking.services;
    const mutationsManager = booking.mutationsManager;

    if(mutationsManager.isMutationInProgress) {
        await services.loadingIndicator.execute({
            action: async () => {
                await mutationsManager.waitForMutations();
            }
        });
    }
}
