import React from "react";
import { DocumentRowsCircleFilledIcon } from "../../../../../assets/svg/document-rows-circle-filled.icon";
import { useServices } from "../../../../../hooks/use-services.hook";
import { FlightItineraryAccordionHeaderComponent } from "../flight-itinerary-accordion-header.component";
import { FlightItineraryAccordionComponent } from "../flight-itinerary-accordion.component";
import {FlightItineraryContactDetailsContentComponent} from "./flight-itinerary-contact-details-content.component";


export const FlightItineraryContactDetailsComponent: React.FC = () => {
    const services = useServices();

    const renderAccordionHeader = () => {
        return (
            <FlightItineraryAccordionHeaderComponent renderIcon={() => <DocumentRowsCircleFilledIcon/>}
                                                     title={services.language.translate('Contact Details')}/>
        )
    }

    const renderAccordionContent = () => {
        return (
            <FlightItineraryContactDetailsContentComponent/>
        );

    }

    return (
        <FlightItineraryAccordionComponent renderHeader={renderAccordionHeader}
                                           renderContent={renderAccordionContent}/>
    );
}
