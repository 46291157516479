import React from 'react';
import {useServices} from "../../hooks/use-services.hook";
import styled from 'styled-components';
import {Station} from "../../services/stations/station.service.interface";
import {ListBox} from "../../basic-components/list/list.box";
import {MediumLabelBox} from "../../basic-components/label/medium-label.box";
import {observer} from "mobx-react-lite";

const StationsGroupHeaderBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.xLarge};
    line-height: 1;
    padding: 14px 18px;
    background-color: ${props => props.theme.colors.light};
`

const StationLetterBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.xLarge};
    line-height: 1;
    padding: 14px 18px;
    background-color: ${props => props.theme.colors.light};    
`

const StationElementBox = styled.div`
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme.colors.light};
`

const StationDetailsBox = styled.div`
    line-height: 1;
`

const StationNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    line-height: 1;
    margin-bottom: 4px;
`

const StationCountryBox = styled(MediumLabelBox)`
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1;
`

const StationCodeBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    line-height: 1;
    display: flex;
    align-items: center;
`

const StationContainerBox = styled.div`
    cursor: pointer;
`

interface StationComponentProps {
    station: Station;
    onSelect: (station: Station) => void;
}

const StationComponent: React.FC<StationComponentProps> = observer((props) => {
    const onClickHandler = () => {
        props.onSelect(props.station)
    }
    return (
        <StationContainerBox onClick={onClickHandler}>
            <StationElementBox>
                <StationDetailsBox>
                    <StationNameBox>
                        {props.station.stationName}
                    </StationNameBox>
                    <StationCountryBox>
                        {props.station.countryName}
                    </StationCountryBox>
                </StationDetailsBox>
                <StationCodeBox>
                    {props.station.stationCode}
                </StationCodeBox>            
            </StationElementBox>
        </StationContainerBox>
    )
})

interface StationsByLetterComponentProps {
    letter: string;
    stations: Station[];
    onSelect: (station: Station) => void;
}

const StationsByLetterComponent: React.FC<StationsByLetterComponentProps> = observer((props) => {
    return (
        <div>
            <StationLetterBox>
                {props.letter}
            </StationLetterBox>
            <div>
                {props.stations.map(station => 
                    <StationComponent 
                        key={station.stationCode} 
                        station={station} 
                        onSelect={props.onSelect}
                    />)}
            </div>
        </div>
    )
});

interface RecentlySearchedStationsListProps {
    onSelect: (station: Station) => void;
    recentlySearchedStations: Station[];
}

const RecentlySearchedStationsListComponent: React.FC<RecentlySearchedStationsListProps> = observer((props) => {
    const services = useServices();
    
    if (props.recentlySearchedStations.length === 0) {
        return null;
    }

    return (
        <div>
            <StationsGroupHeaderBox>
                {services.language.translate('Recent Searches')}                
            </StationsGroupHeaderBox>
            <div>
                {props.recentlySearchedStations.map(recentlySearchedStation => 
                    <StationComponent 
                        key={recentlySearchedStation.stationCode} 
                        station={recentlySearchedStation} 
                        onSelect={props.onSelect}
                    />)}
            </div>
        </div>
    );
})

export interface StationsListComponentProps {
    stations: Station[];
    recentlySearchedStations: Station[];
    onSelect: (station: Station) => void;
}


export const StationsListComponent: React.FC<StationsListComponentProps> = observer((props) => {
    const services = useServices();

    const stationsAlphabetically = services.stations.groupStationsAlphabetically(props.stations);

    return (
        <ListBox>
            <RecentlySearchedStationsListComponent
                onSelect={props.onSelect}
                recentlySearchedStations={props.recentlySearchedStations}
            />
            {Object.keys(stationsAlphabetically).map(letter =>
                <StationsByLetterComponent
                    key={letter}
                    letter={letter}
                    stations={stationsAlphabetically[letter]}
                    onSelect={props.onSelect}
                />)}
        </ListBox>
    );
})
