import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const StarCircleFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 24 24">
            <g fill={props.color? props.color: "currentColor"}>
                <circle id="Ellipse_63" data-name="Ellipse 63" cx="12" cy="12" r="12" />
                <path id="Path_154" data-name="Path 154" d="M530.084,419.988a.444.444,0,0,0-.422-.307h-3.376l-1.043-3.21a.444.444,0,0,0-.846,0l-1.043,3.21H519.98a.444.444,0,0,0-.261.8l2.73,1.983-1.043,3.21a.444.444,0,0,0,.685.5l2.73-1.984,2.73,1.984a.444.444,0,0,0,.684-.5l-1.043-3.21,2.729-1.99A.444.444,0,0,0,530.084,419.988Z" transform="translate(-512.719 -409.425)" fill="#fff"/>
            </g>
        </SvgIcon>
    )
}
