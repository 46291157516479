import React, {KeyboardEventHandler} from "react";
import {observer} from "mobx-react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableString} from "../../../types/nullable-types";
import {StringFieldEditorComponent} from "../string-field/string-field-editor.component";
import {PersonNameValidator} from "../../../models/forms/field-validators/person-name.validator";
import {FieldEditorCommonProps} from "../field-editor-common.props";


interface PersonNameFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<NullableString>;
    placeholder?: NullableString;
    className?: string;
}


export const PersonNameFieldEditorComponent: React.FC<PersonNameFieldEditorComponentProps> = observer((props) => {

    const onKeyPressHandler: KeyboardEventHandler = (event) => {
        if(!PersonNameValidator.regularExpression.test(event.key)) {
            event.preventDefault();
        }
    }

    return (

        <StringFieldEditorComponent {...props} onKeyPress={onKeyPressHandler}/>
    )
});


