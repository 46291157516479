import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import styled from "styled-components";
import { PlaneHorizontalFilledIcon } from "../../assets/svg/plane-horizontal-filled.icon";
import { IFlightDesignatorViewModel } from "../../services/booking/models/designator/flight-designator-view-model.interface";
import {CarriersComponent} from "../designator/carriers.component";
import {
    IFlightIdentifierViewModel
} from "../../services/booking/models/identifier/flight-identifier-view-model.interface";

const DepartureAndArrivalBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`
const FlightsDetailsBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const DepartureBox = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: ${props => props.theme.fontSize.xxxxxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    color: ${props => props.theme.colors.primary};
    width: 50%;
`

const ArrivalBox = styled(DepartureBox)``

const DetailsBox = styled.div<{isInSmallScreenView: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: ${props => props.isInSmallScreenView ? props.theme.fontSize.small : props.theme.fontSize.medium};
    
`

const IconWithHorizontalLineBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${props => props.theme.spacing.small} ${props => props.theme.spacing.large};
    &:before {
        content: "";
        height: 1px;
        background-color: ${props => props.theme.colors.medium};
        width: 28px;
        margin-right: ${props => props.theme.spacing.large};
    }
    &:after {
        content: "";
        height: 1px;
        background-color: ${props => props.theme.colors.medium};
        width: 28px;
        margin-left: ${props => props.theme.spacing.large};
    }
`
const TravellingTimeBox = styled.div`
    text-align: center;
    width: 100%;
    color: ${props => props.theme.colors.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
`

const DepartureStationNameBox = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    flex-basis: 100%;
    font-size: ${props => props.theme.fontSize.medium};
    padding: 2px 0;
    
`

const DepartureStationCodeBox = styled(DepartureStationNameBox)`
    padding-bottom: ${props => props.theme.spacing.small};
    flex-shrink: 2;
`

const ArrivalStationNameBox = styled(DepartureStationNameBox)``

const ArrivalStationCodeBox = styled(DepartureStationCodeBox)``

const StationInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  
`

const DepartureTimeBox = styled.div`
    line-height:1;
    text-align: center;
    flex: 1;
`

const NumberOfStopsComponentBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

interface NumberOfStopsComponentProps {
    designator: IFlightDesignatorViewModel
}

const NumberOfStopsComponent: React.FC<NumberOfStopsComponentProps> = observer((props) => {

    return (
        <NumberOfStopsComponentBox>
            {props.designator.formatNumberOfStops()}
        </NumberOfStopsComponentBox>
    );
});

const FlightIdentifierBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
`


const ArrivalTimeBox = styled(DepartureTimeBox)``

interface DepartureAndArrivalTimeComponentProps {
    designator: IFlightDesignatorViewModel;
    flightIdentifier?: IFlightIdentifierViewModel;
    displayDate: boolean;
    displayNumberOfStops: boolean;
    isStationCodeFirst?: boolean;
    className?: string;
}
export const DepartureAndArrivalTimeComponent: React.FC<DepartureAndArrivalTimeComponentProps> = observer((props) => {
    const services = useServices();
    const renderFlightIdentifier = () => {
        if(!props.flightIdentifier) {
            return null;
        }

        return (
            <FlightIdentifierBox>
                {props.flightIdentifier.fullIdentifierCode()}
            </FlightIdentifierBox>
        )
    }

    return (
        <DepartureAndArrivalBox>
            <FlightsDetailsBox className={props.className}>
                <DepartureBox>
                    <DepartureTimeBox>
                        {props.isStationCodeFirst ? props.designator.origin.stationCode : props.designator.formatDepartureTime()}
                    </DepartureTimeBox>
                    <StationInfoBox>
                        <DepartureStationNameBox>
                            {props.designator.origin.stationName}
                        </DepartureStationNameBox>
                        <DepartureStationCodeBox>{props.isStationCodeFirst ? props.designator.formatDepartureTime() : props.designator.origin.stationCode}</DepartureStationCodeBox>
                    </StationInfoBox>
                </DepartureBox>
                <DetailsBox isInSmallScreenView={services.layout.isInSmallScreenView}>
                    {renderFlightIdentifier()}
                    {props.displayNumberOfStops && <NumberOfStopsComponent designator={props.designator}/>}
                    {
                        props.displayDate &&
                        <TravellingTimeBox>
                            {services.time.formatUserFriendlyDate(props.designator.departureDate)}
                        </TravellingTimeBox>
                    }
                    <IconWithHorizontalLineBox>
                        <PlaneHorizontalFilledIcon color={services.theme.currentTheme.colors.medium}/>
                    </IconWithHorizontalLineBox>
                    <TravellingTimeBox className={'tst-travel-time'}>
                        {services.time.formatTravelTime(props.designator.travelTime)}
                    </TravellingTimeBox>
                </DetailsBox>
                <ArrivalBox>
                    <ArrivalTimeBox>
                        {props.isStationCodeFirst ? props.designator.destination.stationCode : props.designator.formatArrivalTime()}
                    </ArrivalTimeBox>
                    <StationInfoBox>
                        <ArrivalStationNameBox>
                            {props.designator.destination.stationName}
                        </ArrivalStationNameBox>
                        <ArrivalStationCodeBox>{props.isStationCodeFirst ? props.designator.formatArrivalTime() : props.designator.destination.stationCode}</ArrivalStationCodeBox>
                    </StationInfoBox>

                </ArrivalBox>
            </FlightsDetailsBox>
            <CarriersComponent carriers={props.designator.operatingCarriers}/>
        </DepartureAndArrivalBox>

    )
});
