import React, {PropsWithChildren} from "react";
import {StandardPageHeaderComponent} from "../../basic-components/page/standard-page-header/standard-page-header.component";
import {observer} from "mobx-react";
import {BookingFlowTitleComponent} from "./booking-flow-title.component";
import {useServices} from "../../hooks/use-services.hook";
import {handleBookingFlowLogoClick} from "./booking-flow-logo-click-handler";

interface BookingFlowPageHeaderSmallScreenComponentProps extends PropsWithChildren {
    title: string | React.ReactElement;
    hideWizardProgressIndicator?: boolean;
    onBackButtonClick?: () => void;
    dontAsForConfirmationOnLogoClick?: boolean;
}

export const BookingFlowPageHeaderSmallScreenComponent: React.FC<BookingFlowPageHeaderSmallScreenComponentProps> = observer((props) => {
    const services = useServices();
    if(services.booking.isBookingRestoreInProgress) {
        return null;
    }

    const onLogoClick = async () => {
        await handleBookingFlowLogoClick(services, props.dontAsForConfirmationOnLogoClick);
    }

    const title = (<BookingFlowTitleComponent title={props.title} hideWizardProgressIndicator={props.hideWizardProgressIndicator}/>)
    return (
        <StandardPageHeaderComponent title={title}
                                     hideLogoButton={false}
                                     onBackButtonClick={props.onBackButtonClick}
                                     onLogoButtonClick={onLogoClick}>
            {props.children}
        </StandardPageHeaderComponent>
    )
});
