import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import styled from "styled-components";

import {LOADING_INDICATOR_CONTAINER_ELEMENT_ID} from "../../services/loading-indicator/loading-indicator.service.interface";
import {SpinnerComponent, SpinnerStyle} from "../spinner/spinner.component";
import {ZIndex} from "../../types/z-index";

const LoadingIndicatorBox = styled.div<{shouldDisplay: boolean}>`
  position: fixed;
  display: ${props => props.shouldDisplay ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(${props => props.theme.colors.lightRgb}, 0.7);
  color: ${props => props.theme.colors.primary};
  z-index: ${ZIndex.LoadingIndicator};
`

const TitleBox = styled.div`
  color: ${props => props.theme.colors.primaryContrast};
  font-size: ${props => props.theme.fontSize.xxxxLarge};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 1;
  padding: 20px 10px 0;
  text-align: center;
`

const SubTitleBox = styled.div`
  color: ${props => props.theme.colors.primaryContrast};
  font-size: ${props => props.theme.fontSize.xxLarge};
  text-align: center;
  line-height: 1;
  padding: 20px 30px 0;
`

export const LoadingIndicatorComponent: React.FC = observer(() => {
    const services = useServices();
    const indicator = services.loadingIndicator.current;


    const renderPromotionalMessageTitle = () => {
        if(!indicator?.showPromotionalMessage) {
            return null;
        }
        const title = services.configuration.data.marketing.promotionalMessages.loadingIndicatorTitle;
        if(!title) {
            return null;
        }
        return (
            <TitleBox>{title}</TitleBox>
        );
    }

    const renderPromotionalMessage = () => {
        if(!indicator?.showPromotionalMessage) {
            return null;
        }
        const message = services.configuration.data.marketing.promotionalMessages.loadingIndicatorMessage;
        if(!message) {
            return null;
        }
        return (
            <SubTitleBox>{message}</SubTitleBox>
        )
    }

    return (
        <LoadingIndicatorBox key={LOADING_INDICATOR_CONTAINER_ELEMENT_ID}
                             id={LOADING_INDICATOR_CONTAINER_ELEMENT_ID}
                             shouldDisplay={Boolean(indicator)}>
            <SpinnerComponent style={SpinnerStyle.LoadingIndicator}
                              promotionalMessageTitle={renderPromotionalMessageTitle()}
                              promotionalMessage={renderPromotionalMessage()}/>

        </LoadingIndicatorBox>
    )
});
