import {IDotRezGraphQLQuery} from "../dot-rez-graph-ql-query.interface";
import {IDotRezJourneyBoardingPassesRequest} from "../../data-contracts/requests/booking/boarding-passes.request";

export function journeyBoardingPassesQuery(journey: IDotRezJourneyBoardingPassesRequest): IDotRezGraphQLQuery {
    const query = `
        query journeyBoardingPass($journeyKey: String, 
                                  $request: Input_BoardingPassPassengerFilterRequest) {
            boardingPassByJourneyForPassengerS2D(journeyKey: $journeyKey
                                                 request: $request) {
              boardingPasses {
                segments {
                    barCode,
                    international,
                    hasInfant,
                    boardingTime,
                    departureGate,
                    designator {
                        origin,
                        destination,
                        departure,
                        arrival
                    },
                    identifier {
                        identifier,
                        carrierCode,
                        opSuffix
                    },
                    legs {
                        ssrs {
                            ssrCode
                        }
                    }
                }
                passenger {
                    name {
                        first,
                        last,
                        title
                    },
                    dateOfBirth,
                    gender,
                    documents {
                        number
                    },
                    infant {
                        name {
                            first,
                            last
                        },
                        dateOfBirth,
                        gender,
                        documents {
                            number
                        }
                    }
                    
                    
                }
              }
            }
        }
    `;

    return {
        queryName: "boardingPasses",
        request: {
            query: query,
            variables: {
                journeyKey: journey.journeyKey,
                request: {
                    passengerKeys: journey.passengersKeys
                }
            }
        }
    }
}
