import {IPassengerAttachedCompanionViewModel} from "./passenger-attached-companion-view-model.interface";
import {PassengerModelBase} from "./passenger-model-base";
import {IServiceFactory} from "../../../service-factory.interface";
import {computed, IReactionDisposer, makeObservable, observable, reaction, runInAction} from "mobx";
import {IPersonViewModel} from "../../../user/models/person/person-view-model.interface";
import {genderToString} from "../../../dot-rez-api/data-contracts/enums/gender.enum";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {IPassengerInfoFields} from "./passenger-info-fields.interface";
import {IUserProfileViewModel} from "../../../user/models/profile/user-profile-view-model.interface";
import { NullableString } from "../../../../types/nullable-types";
import {IPersonTravelDocumentViewModel} from "../../../user/models/person/person-travel-document-view-model.interface";

export class PassengerAttachedCompanionModel implements IPassengerAttachedCompanionViewModel {
    constructor(private readonly passenger: PassengerModelBase) {
        makeObservable<this, '_companion'>(this, {
            _showAvailableCompanions: observable.ref,
            _shouldSaveAsCompanion: observable.ref,
            _companion: computed,
            availableTravelDocuments: computed
        });

        this._reactions.push(reaction(() => this.passenger.isPrimaryContact,
            (isPrimaryContact) => {
                if (isPrimaryContact && this._companion) {
                    this._copyCompanionFieldsToBookingContactFields(this._companion)
                }
            }));

        this._reactions.push(reaction(
            () => this.services.navigator.currentRoute,
            () => {
                this.showAvailableCompanions = false
            }));
    }

    private _reactions: IReactionDisposer[] = [];


    get isAttached(): boolean {
        return Boolean(this._companion);
    }

    get canBeModified(): boolean {
        if (this.passenger.booking.blueBenefits.shouldLockFirstPassengerOnBooking
            && this._companion?.customerNumber === this.services.user.profile.customerNumber) {
            return false;
        }
        return true;
    }

    getCompanionFullName(): string {
        return this._companion?.getFullName() || "";
    }

    get companionTitle(): NullableString {
        return this._companion?.title || null;
    }

    get companionDateOfBirth(): NullableString {
        const dateOfBirth = this._companion?.fields.dateOfBirth.value;
        if(dateOfBirth) {
            return this.services.time.formatUserFriendlyDate(dateOfBirth)
        }
        return null;
    }

    private get services(): IServiceFactory {
        return this.passenger.booking.services;
    }

    private get passengerFields(): FormFields<IPassengerInfoFields> {
        return this.passenger.fields;
    }

    private get userProfile(): IUserProfileViewModel {
        return this.services.user.profile;
    }

    get canBeSavedAsCompanion(): boolean {
        if(this.passenger.customerNumber) {
            return false;
        }
        if(this.passenger.hasSameNameAsOneOfTheCompanions) {
            return false;
        }

        // At this point a passenger can be saved as companion
        // if the number of the existing companions + the number of passengers marked to be saved as companions
        // don't exceed the maximum number of allowed companions.
        const totalCompanions = this.userProfile.companions.length
                                + this.passenger.booking.passengers.getAllPersonsInTheBooking()
                                                                   .filter(p => p.companion.shouldSaveAsCompanion)
                                                                   .length;

        // but if this passenger was already marked as shouldSaveAsCompanion it means that was counted in the totalCompanions
        // so he canBeSavedAsCompanion
        return totalCompanions < this.userProfile.companionsCountLimit || this.shouldSaveAsCompanion;
    }




    _shouldSaveAsCompanion: boolean = false;
    get shouldSaveAsCompanion(): boolean {
        return this._shouldSaveAsCompanion;
    }

    set shouldSaveAsCompanion(value: boolean) {
        runInAction(() => {
            this._shouldSaveAsCompanion = value;
        });
    }

    async saveAsCompanion(): Promise<void> {
        try {
            if(this.canBeSavedAsCompanion && this.shouldSaveAsCompanion) {
                const companion = await this.userProfile.createCompanionFromPassenger(this.passenger);
                this._copyCompanionFieldsToPassengerFields(companion);
                this._copyCompanionFieldsToBookingContactFields(companion, true);
                this.shouldSaveAsCompanion = false;

            }
        } catch (err) {
            this.services.logger.error('Failed to save passenger as travel companion', err);
        }
    }

    private get _companion(): IPersonViewModel | null {
        if(!this.passenger.customerNumber) {
            return null;
        }

        return this.userProfile.getCompanionsForBooking().find(c => c.customerNumber === this.passenger.customerNumber) || null;
    }

    private _copyCompanionFieldsToPassengerFields(companion: IPersonViewModel): void {
        this.passengerFields.firstName.setValue(companion.fields.firstName.value);
        this.passengerFields.lastName.setValue(companion.fields.lastName.value);
        this.passengerFields.customerNumber.setValue(companion.customerNumber);
        this.passengerFields.gender.setValue(genderToString(companion.fields.gender.value));
        this.passengerFields.dateOfBirth.setValue(companion.fields.dateOfBirth.value);
        this.passengerFields.nationality.setValue(companion.fields.nationality.value);
    }

    attachCompanion(companion: IPersonViewModel): void {
        this._copyCompanionFieldsToPassengerFields(companion);
        if(this.passenger.isPrimaryContact) {
            this._copyCompanionFieldsToBookingContactFields(companion);
        }

        this.shouldSaveAsCompanion = false;
    }

    protected _copyCompanionFieldsToBookingContactFields(companion: IPersonViewModel, ignoreEmptyContactFields: boolean = false) {
        const contactFields = this.passenger.booking.contact.fields;
        contactFields.firstName.setValue(companion.fields.firstName.value);
        contactFields.lastName.setValue(companion.fields.lastName.value);
        if(ignoreEmptyContactFields) {
            companion.emailAddress && contactFields.emailAddress.setValue(companion.emailAddress);
            companion.fields.nationality.value && contactFields.countryCode.setValue(companion.fields.nationality.value);
            companion.fields.phoneNumber.value && contactFields.phoneNumber.setValue(companion.fields.phoneNumber.value);
        } else {
            contactFields.emailAddress.setValue(companion.emailAddress);
            contactFields.countryCode.setValue(companion.fields.nationality.value);
            contactFields.phoneNumber.setValue(companion.fields.phoneNumber.value);
        }

    }

    detachCompanion(): void {
        if(!this._companion) {
            return;
        }
        //this.passengerFields.firstName.setValue(null);
        //this.passengerFields.lastName.setValue(null);
        this.passengerFields.customerNumber.setValue(null);
        this.passengerFields.gender.setValue(null);
        this.passengerFields.dateOfBirth.setValue(null);
    }

    _showAvailableCompanions: boolean = false;
    get showAvailableCompanions(): boolean {
        return this._showAvailableCompanions;
    }

    set showAvailableCompanions(value: boolean) {
        runInAction(() => {
            this._showAvailableCompanions = value
        });
    }
    get availableCompanions(): IPersonViewModel[] {
        if(this._companion) {
            return [];
        }
        const maxBirthDate = this.passenger.computeMaximumBirthDate();
        const minBirthDate = this.passenger.computeMinimumBirthDate();
        return this.passenger.booking.getAvailableCompanions().filter(companion => {
            if(companion.fields.dateOfBirth.value) {
                if(companion.fields.dateOfBirth.value.getTime() > maxBirthDate.getTime()) {
                    return false;
                }

                if(minBirthDate && companion.fields.dateOfBirth.value.getTime() < minBirthDate.getTime()) {
                    return false;
                }
            }

            return true;
        });
    }

    get availableTravelDocuments(): IPersonTravelDocumentViewModel[] {

        let companion = this._companion;

        if(!companion) {
            companion = this.userProfile.companions.find(c =>
                c.fields.dateOfBirth.value
                && c.fields.firstName.value === this.passengerFields.firstName.value
                && c.fields.lastName.value === this.passengerFields.lastName.value
                && c.fields.dateOfBirth.value.getTime() === this.passenger.fields.dateOfBirth.value?.getTime()
            ) || null;
        }

        const travelDocuments = companion?.travelDocuments || [];
        const minDocumentExpirationDate = this.passenger.travelDocument.computeMinimumTravelDocumentExpirationDate();
        return travelDocuments.filter(doc => doc.fields.expirationDate.value && doc.fields.expirationDate.value.getTime() >= minDocumentExpirationDate.getTime());

    }

    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }
}
