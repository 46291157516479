import {CreditCardPaymentModel} from "./credit-card-payment.model";
import React from "react";
import {LogoMastercardIcon} from "../../../../../../assets/svg/logo-mastercard.icon";

export class MastercardPaymentMethodModel extends CreditCardPaymentModel {
    get name(): string {
        return "Mastercard";
    }

    renderIcon(): React.ReactElement | null {
        return (<LogoMastercardIcon />);
    }
}