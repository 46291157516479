import React, {PropsWithChildren} from 'react';
import { ExclamationCircle } from "../../assets/svg/exclamation-circle.icon";
import { MaintenanceMessageBox } from './box/maintenance-message.box';

interface MaintenanceAnnouncementComponentProps extends PropsWithChildren {
    className?: string;
}
export const MaintenanceAnnouncementComponent: React.FC<MaintenanceAnnouncementComponentProps> = (props) => {

    return (
        <MaintenanceMessageBox className={props.className}>
            <ExclamationCircle size={1.6} />
            {props.children}
        </MaintenanceMessageBox>
    );
}
