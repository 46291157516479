import React from "react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {IPassengerTypeSelector} from "../../../../../services/passenger-types/passengers-types.service.interface";
import styled from "styled-components";
import {PersonGroupIcon} from "../../../../../assets/svg/person-group.icon";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {searchMaskIconSpacing} from "./search-mask-hybrid-css-constants";

const SearchMaskPassengersContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const PassengersContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
`

const SelectedPassengersTypesBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
`

const LabelBox = styled.div`
    padding-bottom: ${props => props.theme.spacing.small};
    line-height: 1;
    font-size: ${props => props.theme.fontSize.large};
`

const SelectedPassengerTypeBox = styled.div`
    line-height: 1;
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const PassengerTypesSeparatorBox = styled.div`
    width: 3px;
    height: 14px;
    margin-left: ${props => props.theme.spacing.medium};
    margin-right: ${props => props.theme.spacing.medium};
    background-color: ${props => props.theme.border.mainColor};
`


const PassengersIconContainerBox = styled.div`
    padding-right: ${props => searchMaskIconSpacing(props.theme)};
`

interface SelectedPassengerTypeComponentProps {
    passengerType: IPassengerTypeSelector;
}

const SelectedPassengerTypeComponent: React.FC<SelectedPassengerTypeComponentProps> = observer((props) => {
    return (
        <SelectedPassengerTypeBox>
            {props.passengerType.summary}
        </SelectedPassengerTypeBox>
    );
});

const SelectedPassengersTypesComponent: React.FC = observer(() => {
    const services = useServices();
    const selectedPassengerTypes = services.flightSearch.searchController.passengers.getAll().filter(p => p.count > 0);

    return (
        <SelectedPassengersTypesBox>
            {selectedPassengerTypes.map((passengerType, index) => {
                return (
                    <React.Fragment key={passengerType.getCurrentCode()}>
                        <SelectedPassengerTypeComponent passengerType={passengerType}/>
                        {index < selectedPassengerTypes.length - 1 && <PassengerTypesSeparatorBox/>}
                    </React.Fragment>

                )
            })}
        </SelectedPassengersTypesBox>
    )
});


export const SearchMaskPassengersHybridComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <SearchMaskPassengersContainerBox onClick={() => services.navigator.routes.flightSearch.passengers.activate()}>
            <PassengersIconContainerBox>
                <PersonGroupIcon size={1.4}/>
            </PassengersIconContainerBox>
            <PassengersContentBox>
                <LabelBox>
                    {services.language.translate('Passengers')}
                </LabelBox>
                <SelectedPassengersTypesComponent/>
            </PassengersContentBox>
        </SearchMaskPassengersContainerBox>
    );
});
