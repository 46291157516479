import React from 'react';
import {observer} from "mobx-react-lite";
import {IFlightDesignatorViewModel} from "../../../services/booking/models/designator/flight-designator-view-model.interface";
import {DesignatorStationNameComponent} from "../common/components/designator-station-name.component";
import {DesignatorPlaneIconComponent} from "../common/components/designator-plane-icon.component";
import styled from "styled-components";
import {DesignatorBox} from "../common/boxes/designator.box";

const DesignatorStationsNamesBox = styled(DesignatorBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

interface DesignatorStationsNamesComponentProps {
    designator: IFlightDesignatorViewModel;
    className?: string;
    useMac?: boolean;
}

export const DesignatorStationsNamesComponent: React.FC<DesignatorStationsNamesComponentProps> = observer((props) => {
    const designator = props.designator;
    return (
        <DesignatorStationsNamesBox className={props.className}>
            <DesignatorStationNameComponent station={designator.origin} useMac={props.useMac}/>
            <DesignatorPlaneIconComponent />
            <DesignatorStationNameComponent station={designator.destination} useMac={props.useMac}/>
        </DesignatorStationsNamesBox>
    );
});
