import {BookingModel} from "../booking.model";
import {Check} from "../../../../types/type-checking";
import {SessionStorageKeys} from "../../../storage/session-storage-keys";
import {IBookingStorage} from "./booking-storage.interface";

export class BookingEntityStorage implements IBookingStorage {
    constructor(private readonly booking: BookingModel, private readonly storageKey: string) {
        const storageData = booking.storage.getItem(storageKey);
        if(storageData) {
            try {
                this._storageData = JSON.parse(storageData);
            } catch (err) {
                booking.services.logger.error(`Failed to parse storage data for entity ${this.constructor?.name}`);
            }

        }
    }

    private _storageData: Record<string, string> = {};

    private _persistData() {
        this.booking.storage.setItem(this.storageKey, JSON.stringify(this._storageData));
    }

    setItem(key: string, value: string): void {
        this._storageData[key] = value;
        this._persistData();
    }
    getItem(key: string): any {
        return this._storageData[key];
    }
    removeItem(key: string): void {
        if(this._storageData[key]) {
            delete this._storageData[key];
            this._persistData();
        }
    }

    setJson(key: string, value: object): void {
        if(Check.isNullOrUndefined(value)) {
            this.removeItem(key);
        } else {
            this.setItem(key, JSON.stringify(value));
        }
    }

    getJson<TValue = any>(key: SessionStorageKeys): TValue | null {
        const value = this.getItem(key);

        if (Check.isNullOrUndefined(value)) {
            return null;
        } else {
            return JSON.parse(value) as TValue;
        }
    }

    reset(): void {
        this._storageData = {};
        this._persistData();
    }

    dispose(): void {
        this.booking.storage.removeItem(this.storageKey)
    }
}
