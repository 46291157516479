import React from "react";
import {observer} from "mobx-react-lite";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import styled from "styled-components";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";

const DialogTextBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1.4;
`


interface SimpleMessageDialogComponentProps {
    title?: string;
    message: string;
    dialogHandler: IDialogConfirmationHandler;
    buttonText: string;
}

export const SimpleMessageDialogComponent: React.FC<SimpleMessageDialogComponentProps> = observer((props) => {
    const renderHeader = () => {
        if(props.title) {
            return (<StandardDialogHeaderComponent title={props.title} dialogHandler={props.dialogHandler}/>);
        }

        return null;
    }
    return (
        <StandardDialogPageComponent>
            {renderHeader()}
            <StandardDialogContentComponent>
                <DialogTextBox>
                    {props.message}
                </DialogTextBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={() => props.dialogHandler.accept()}>
                    {props.buttonText}
                </PrimaryButton>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
