import {FlightSearchStepBase} from "./flight-search-step.base";

export class SpecialPriceMarketUserAgreementStep extends FlightSearchStepBase {


    get title(): string {
        return 'T&C';
    }

    async next(): Promise<void> {
        this._activateStepByRoute(this.routes.flightDates);
    }

    get route() {
        return this.services.navigator.routes.flightSearch.specialPriceMarketUserAgreement;
    }
}
