import React from 'react';
import {observer} from "mobx-react-lite";
import {StandardPageHeaderComponent} from "../../basic-components/page/standard-page-header/standard-page-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {handleBookingFlowLogoClick} from "./booking-flow-logo-click-handler";


interface BookingFlowPageHeaderLargeScreenComponentProps {
    renderExtraHeader?: () => React.ReactElement | null;
    dontAsForConfirmationOnLogoClick?: boolean;
}
export const BookingFlowPageHeaderLargeScreenComponent: React.FC<BookingFlowPageHeaderLargeScreenComponentProps> = observer((props) => {
    const services = useServices();
    const onLogoClick = async () => {
        await handleBookingFlowLogoClick(services, props.dontAsForConfirmationOnLogoClick);
    }

    return (
        <StandardPageHeaderComponent onLogoButtonClick={onLogoClick}>
            {props.renderExtraHeader && props.renderExtraHeader()}
        </StandardPageHeaderComponent>
    );
});
