import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';

export const Ins1SsrLargeIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={3} {...props} viewBox="0 0 24.667 33.625">
            <g transform="translate(-8.167 -3.688)"><path d="M9.167,10.593a12.627,12.627,0,0,0,6-1.093C17.833,8.125,20.5,4.688,20.5,4.688S23.167,8.125,25.833,9.5a12.471,12.471,0,0,0,6,1.093v8.339c0,8.264-4.667,13.942-11.333,17.38C13.833,32.875,9.167,27.265,9.167,19Z" fill="none" stroke="#acb5bf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/><path d="M15.167,22.563l4,3.438,6.667-9.625" fill="none" stroke="#ffd200" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/></g>
        </SvgIcon>
    )
}
