import {NullableString} from "../../../types/nullable-types";
import {MonthModel} from "../../time/month.model";
import {IFlightsScheduleViewModel} from "./flights-schedule-view-model.interface";
import {Station} from "../../stations/station.service.interface";

export class VoidFlightsScheduleModel implements IFlightsScheduleViewModel {
    readonly isLoading = false;
    readonly errorMessage: NullableString = null;
    getFlightsScheduledMonths(): MonthModel[] {
        return [];
    }

    get availableDates(): Date[] {
        return [];
    }

    isDateAvailable(date: Date): boolean {
        return false;
    }

    get origin(): Station {
        throw new Error(`VoidFlightsScheduleModel doesn't support origin property`);
    }

    get destination(): Station {
        throw new Error(`VoidFlightsScheduleModel doesn't support destination property`);
    }

    waitForSchedule(): Promise<void> {
        return Promise.resolve();
    }
}
