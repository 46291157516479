import {BookingModel} from "../../booking.model";
import {JourneyModel} from "../../journey/journey.model";
import {BundleModel} from "../../bundle/bundle.model";
import {BookingMutationActionBase} from "../booking-mutation-action-base";

export abstract class SellBundleMutation extends BookingMutationActionBase {
    constructor(booking: BookingModel,
                bundleCodeToSell: string) {
        super(booking)

        if(!booking.departureJourney) {
            throw new Error('You cannot sell a bundle before selling the departure journey')
        }

        this.departureJourney = booking.departureJourney;
        const bundle = this.departureJourney.bundlesAvailability.find(b => b.bundleCode === bundleCodeToSell);
        if(!bundle) {
            throw new Error(`There is no available bundle with code ${bundleCodeToSell}`);
        }

        this.bundleToSell = bundle;
    }

    protected readonly departureJourney: JourneyModel;
    protected readonly bundleToSell: BundleModel

    protected get returnJourney(): JourneyModel | null {
        return this.booking.returnJourney;
    }

    async onAfterBookingSessionDataUpdated(): Promise<void> {
        this.departureJourney.onBundleUpdated();
        this.returnJourney?.onBundleUpdated();
        await super.onAfterBookingSessionDataUpdated();


    }


}
