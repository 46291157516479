import React from 'react';
import {SsrTypeBase} from "../../ssr-type-base";
import {IServiceFactory} from "../../../service-factory.interface";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import { CheckInOnlineSmallIcon } from './check-in-online.small-icon';
import {SSR_STANDARD_LARGE_ICON_SIZE} from "../../ssr-types.service.interface";

export default class LcknSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('LCKN', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.FLEX
    }

}
