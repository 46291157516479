import React from "react";
import {IRenderStandardDropDownItemOptions, IStandardDropDownItemViewModel} from "./standard-drop-down-list-item-view-model.interface";
import styled from "styled-components";

const DropDownItemBox = styled.div`
    width: 100%;
    line-height: 1.1;
`

const InsideModalDialogDropDownItemBox = styled(DropDownItemBox)`
    text-align: center;
    font-size: ${props => props.theme.fontSize.xxxLarge};
    padding: ${props => props.theme.spacing.medium} 0;
`


export class StandardDropDownListItemModel<TFieldValue> implements IStandardDropDownItemViewModel<TFieldValue> {
    constructor(private readonly id: TFieldValue, private readonly text: string) {
    }

    getFieldValue(): TFieldValue {
        return this.id;
    }

    isSameAs(fieldValue: TFieldValue | null) {
        return this.id === fieldValue;
    }

    get key(): string {
        return this.text;
    }

    applyFilter(filter: string): boolean {
        try {
            if(filter) {
                const regEx = new RegExp(filter, "gi");
                return Boolean(this.text.match(regEx));
            } else {
                return false;
            }

        } catch (err) {
            console.error(`StandardDropDownListItemModel.applyFilter for ${filter} failed!`, err);
            return false;
        }

    }

    getDisplayText(): string {
        return this.text;
    }



    renderItem(options?: IRenderStandardDropDownItemOptions): React.ReactElement {

        let Box = DropDownItemBox;
        if(options?.isRenderedInModalDialog) {
            Box = InsideModalDialogDropDownItemBox;
        }

        return (
            <Box>
                {this.text}
            </Box>
        )
    }
}
