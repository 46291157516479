import React from 'react';
import styled from "styled-components";
import {computeSpacerSize, SpacerSize} from "./spacer-size";
import {useServices} from "../../hooks/use-services.hook";
import {observer} from "mobx-react-lite";

const VerticalSpacerBox = styled.div<{height: string}>`
    height: ${props => props.height};
`
interface VerticalSpacerProps {
    isVisible?: boolean;
    size?: SpacerSize;
    className?: string;
}
export const VerticalSpacer: React.FC<VerticalSpacerProps> = observer((props) => {
    const services = useServices();
    const isVisible = props.isVisible ?? true;
    if(!isVisible) {
        return null;
    }
    return (
        <VerticalSpacerBox height={computeSpacerSize(props.size, services.theme.currentTheme)} className={props.className}/>
    );
});
