import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {MaturePassengerModel} from "../../passenger/mature-passenger.model";
import {NullableString} from "../../../../../types/nullable-types";
import {InfantPassengerModel} from "../../passenger/infant-passenger.model";
import {isDotRezSessionExpiredError} from "../../../../dot-rez-api/session/dot-rez-exception";

export class SavePassengersDetailsMutation extends BookingMutationActionBase {


    private async _savePassengerDetails(passenger: MaturePassengerModel): Promise<NullableString> {
        try {
            await passenger.savePassengerDetails();
            await passenger.saveAsCompanion();
        } catch (err) {
            if(isDotRezSessionExpiredError(err)) {
                return null;
            }
            this.services.logger.error(`Failed to save details for passenger ${passenger.getFullName()}`, err);
            return passenger.getFullName();
        }

        return null;
    }

    private async _saveInfantDetails(infant: InfantPassengerModel): Promise<NullableString> {
        try {
            await infant.saveInfantDetails();
            await infant.saveAsCompanion();
        } catch (err) {
            if(isDotRezSessionExpiredError(err)) {
                return null;
            }
            this.services.logger.error(`Failed to save details for infant ${infant.getFullName()}`, err);
            return infant.getFullName();
        }

        return null;
    }

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {

        let errorMessages: string[] = [];

        try {
            await this.booking.contact.save();
        } catch (err) {
            if(isDotRezSessionExpiredError(err)) {
                return {};
            }
            errorMessages.push(this.services.language.translate('Failed to save booking contact details.'));
        }

        let promises = this.booking.passengers.map(passenger => this._savePassengerDetails(passenger));
        const passengersResults = await Promise.all(promises);

        promises = this.booking.passengers.filter(p => p.infant).map(passenger => this._saveInfantDetails(passenger.infant!));
        const infantsResults = await Promise.all(promises);

        const failedPassengersNames = [
            ...passengersResults,
            ...infantsResults
        ].filter(name => name)
         .map(name => name as string);


        if(failedPassengersNames.length === 1) {
            errorMessages.push(
                this.services.language.translationFor('Failed to save details for passenger {passengerName}').withParams({
                    passengerName: failedPassengersNames[0]
                }));
        } else if(failedPassengersNames.length > 1) {
            errorMessages.push(
                this.services.language.translationFor('Failed to save details for the following passengers: {passengersNames}').withParams({
                    passengersNames: failedPassengersNames.join(', ')
                }));
        }

        if(errorMessages.length > 0) {
            this.services.alert.showError(errorMessages.join('\n'));
        }

        return {};
    }

    get willAffectTotal(): boolean {
        return false;
    }

}
