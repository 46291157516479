import {FormModel} from "../../../../models/forms/form.model";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {EMailValidator} from "../../../../models/forms/field-validators/e-mail.validator";

export interface IForgotPasswordFields {
    emailAddress: string;
}

export class ForgotPasswordFormModel extends FormModel<IForgotPasswordFields> {
    protected _createFields(): FormFields<IForgotPasswordFields> {
        return {
            emailAddress: this._createField<string>({
                fieldName: () => this.services.language.translate('E-mail'),
                maxLength: 250,
                validators: [
                    new EMailValidator(this.services)
                ]
            }),
        };
    }
}
