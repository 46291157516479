import React from "react";
import {observer} from "mobx-react";
import {InputContainerComponent, InputContainerComponentProps} from "./input-container.component";
import {IFormField} from "../../models/forms/form-field.interface";

export interface FieldEditorContainerComponentProps extends Omit<InputContainerComponentProps, 'label' | 'errorMessage' | 'captureElementRef'> {
    field: IFormField;
    hideLabel?: boolean;
    customLabel?: string | React.ReactElement;
    hideErrorText?: boolean;

}

export const FieldEditorContainerComponent: React.FC<FieldEditorContainerComponentProps> = observer(props => {

    if(props.field.isHidden) {
        return null;
    }

    const captureElementRef = (element: HTMLDivElement) => {
        props.field.attachContainerDOMElement(element);
    }

    const renderLabel = () => {
        if(props.hideLabel) {
            return null;
        }

        return props.customLabel ?? props.field.fieldName;
    }

    return (
        <InputContainerComponent renderInput={props.renderInput}
                                 label={renderLabel()}
                                 errorMessage={props.field.error}
                                 captureElementRef={captureElementRef}
                                 onClick={props.onClick}
                                 renderIcon={props.renderIcon}
                                 showBorders={props.showBorders}
                                 className={props.className}
                                 hideErrorText={props.hideErrorText}/>
    )
});
