import React from "react";
import styled from "styled-components";
import { ArrowForwardIcon } from "../../../../../assets/svg/arrow-forward.icon";
import { ArrowUpIcon } from "../../../../../assets/svg/arrow-up.icon";
import { AccordionComponent } from "../../../../../components/accordion/accordion.component";
import { useServices } from "../../../../../hooks/use-services.hook";


export const PaymentAccordionComponentBox = styled(AccordionComponent)`
    
`;

const PaymentAccordionArrowBox = styled.div`
    display: flex;
    align-items: center;
`;


interface PaymentAccordionComponentProps {
    renderHeader: () => React.ReactElement;
    renderContent: () => React.ReactElement;
    isExpanded?: boolean;
}

export const PaymentAccordionComponent: React.FC<PaymentAccordionComponentProps> = (props) => {
    const services = useServices();

    const isExpanded = props.isExpanded ?? false;

    const PaymentArrowDownContent = () => {
        return(
            <PaymentAccordionArrowBox>
                <ArrowForwardIcon color={services.theme.currentTheme.colors.dark} size={1.6}/>
            </PaymentAccordionArrowBox>
        )
    }

    const PaymentArrowUpContent = () => {
        return(
            <ArrowUpIcon color={services.theme.currentTheme.colors.dark} size={1}/>
        )
    }

    return(
        <PaymentAccordionComponentBox renderHeader={props.renderHeader} 
                                              renderContent={props.renderContent}
                                              renderArrowDown={PaymentArrowDownContent}
                                              renderArrowUp={PaymentArrowUpContent}
                                              isExpanded={isExpanded}/>
    )
}

