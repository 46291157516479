import { SsrCategoryEnum } from "../../ssr-category.enum";
import {SsrTypeBase} from "../../ssr-type-base";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {TimeSpan} from "../../../../types/time-span";

export abstract class MealSsrBase extends SsrTypeBase {
    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.MEALS;
    }

    get shouldLockCommittedQuantity(): boolean {
        return false;
    }

    get shouldBeSoldPerSegment(): boolean {
        return true;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.Meals;
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(24);
    }
}
