import React from "react";
import {observer} from "mobx-react";
import {
    IPassengerSegmentBoardingPassViewModel
} from "../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {StandardDialogPageComponent} from "../../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {BoardingPassesToWalletSelector} from "./models/boarding-passes-to-wallet-selector";
import {StandardDialogContentComponent} from "../../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../modal/footer/standard-dialog-footer.component";
import styled from "styled-components";
import {
    PassengersBoardingPassesGroupSelectorComponent
} from "./components/passengers-boarding-passes-group-selector.component";
import {PassengerBoardingPassesSelectorComponent} from "./components/passenger-boarding-passes-selector.component";
import {useServices} from "../../../hooks/use-services.hook";
import {WalletBatchSizeWarningDialogComponent} from "./wallet-batch-size-warning-dialog.component";
import {InformationCircleIcon} from "../../../assets/svg/information-circle.icon";


const GroupsContainerBox = styled.div<{hasSingleGroup: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.hasSingleGroup ? props.theme.spacing.large : props.theme.spacing.extraLarge};
`



const DialogTitleContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.medium};
    width: 100%;
`

const DialogTitleBox = styled.div`
    flex-grow: 1;
`

const BatchLimitInfoButtonBox = styled.div`
`

interface AddToWalletBoardingPassesSelectionDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    title: string;
    selector: BoardingPassesToWalletSelector;
    renderAddToWalletButton: (selectedBoardingPasses: IPassengerSegmentBoardingPassViewModel[]) => React.ReactElement;
}

export const AddToWalletBoardingPassesSelectionDialogComponent: React.FC<AddToWalletBoardingPassesSelectionDialogComponentProps> = observer((props) => {
    const services = useServices();
    const renderSingleGroup = () => {
        return (
            <>
                {props.selector.groups[0].passengersSelectors.map(ps => <PassengerBoardingPassesSelectorComponent key={ps.uniqueKey} passengerSelector={ps}/>)}
            </>
        )
    }

    const renderMultipleGroups = () => {
        return (
            <>
                {props.selector.groups.map(g => <PassengersBoardingPassesGroupSelectorComponent key={g.groupNumber} group={g} renderAddToWalletButton={props.renderAddToWalletButton}/>)}
            </>
        )
    }

    const renderGroupSelector = () => {
        if(props.selector.groups.length === 1) {
            return renderSingleGroup();
        } else {
            return renderMultipleGroups();
        }
    }


    const renderFooterAddToWalletButton = () => {
        if(props.selector.groups.length === 1) {
            const selectedPasses = props.selector.groups[0].getSelectedBoardingPasses();
            if(selectedPasses.length === 0) {
                return null;
            }
            return props.renderAddToWalletButton(props.selector.groups[0].getSelectedBoardingPasses());
        }

        return null;
    }


    const maxPassesBatchSizeUserMessage = props.selector.maxPassesBatchSizeUserMessage;

    const onBatchLimitInfoButtonClick = async () => {
        if(!maxPassesBatchSizeUserMessage) {
            return;
        }
        await services.dialog.showSheetModalDialog({
            breakpoints: [0.2],
            render: (dialogHandler) =>  <WalletBatchSizeWarningDialogComponent message={maxPassesBatchSizeUserMessage} dialogHandler={dialogHandler}/>
        })
    }
    const renderDialogTitle = () => {
        return (
            <DialogTitleContainerBox>
                <DialogTitleBox>
                    {props.title}
                </DialogTitleBox>
                {maxPassesBatchSizeUserMessage && (
                    <BatchLimitInfoButtonBox onClick={onBatchLimitInfoButtonClick}>
                        <InformationCircleIcon size={2.2}/>
                    </BatchLimitInfoButtonBox>
                )}

            </DialogTitleContainerBox>
        )
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler} title={renderDialogTitle()}/>

            <StandardDialogContentComponent>
                <GroupsContainerBox hasSingleGroup={props.selector.groups.length === 1}>
                    {renderGroupSelector()}
                </GroupsContainerBox>

            </StandardDialogContentComponent>

            <StandardDialogFooterComponent key={"WithButton"} dialogHandler={props.dialogHandler} className={'some-test'}>
                {renderFooterAddToWalletButton()}
            </StandardDialogFooterComponent>


        </StandardDialogPageComponent>
    )
});