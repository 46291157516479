import {IOnBeforeActivationContext, IOnBeforeDeactivationContext, IWizardStep} from "./wizard.step.interface";
import {IServiceFactory} from "../../services/service-factory.interface";
import {IRoute} from "../../services/navigation/models/route.interface";
import {computed, IReactionDisposer, makeObservable, reaction} from "mobx";
import {DialogResult} from "../../services/dialog/dialog-enums";
import {Wizard} from "./wizard";
import {ValidationResultEnum, validationResultToDialogResult} from "../../types/validation-result.enum";
import {IRouteActivationOptions} from "../../services/navigation/navigator.service.interface";

export abstract class WizardBaseStep implements IWizardStep {
    constructor(protected readonly services: IServiceFactory, protected readonly wizard: Wizard) {

        makeObservable(this, {
            index: computed,
            wasVisited: computed
        });

        this._reactions.push(reaction(() => this.services.navigator.currentRoute, (route) => {
            if(this.route.equals(route)) {

                //setTimeout because _setActivated calls this.index which in turn calls this.wizard.getStepIndex, but since we are in the step constructor it means that this step wasn't yet added to the wizard
                setTimeout(() => this._setActivated(this.wizard.previousStep));
            }
        }, {
            fireImmediately: true
        }));
    }

    abstract next(): Promise<void>;
    abstract get route(): IRoute;
    abstract get title(): string;

    get index(): number {
        return this.wizard.findStepIndex(this);
    }

    protected _activateStepByRoute(route: IRoute, activateOptions?: IRouteActivationOptions): void {
        this.wizard.activateStepByRoute(route, activateOptions);
    }

    get wasVisited(): boolean {
        return this.wizard.getStepVisitedStatus(this.index);
    }

    private _reactions: IReactionDisposer[] = [];

    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }

    get isActive(): boolean {
        return this.route.equals(this.services.navigator.currentRoute);
    }

    get isVisible(): boolean {
        return this._shouldBeDisplayed();
    }

    get allowSwipeForward(): boolean {
        return true;
    }

    protected _shouldBeDisplayed(): boolean {
        return true;
    }

    acceptActivation(): boolean {
        return true;
    }

    private async _setActivated(previousStep: IWizardStep | null) {
        this.wizard.setStepWasVisited(this.index);
        await this._onActivated(previousStep);
    }

    protected async _onActivated(previousStep: IWizardStep | null): Promise<void> {

    }

    async onBeforeActivation(context:IOnBeforeActivationContext): Promise<DialogResult> {

        return await this._onBeforeActivation(context);
    }

    protected async _onBeforeActivation(context:IOnBeforeActivationContext): Promise<DialogResult> {
        return DialogResult.Accepted;
    }


    async onBeforeDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        if(context.routingGuardContext.isForwardNavigation && !context.routingGuardContext.targetLocationIsHomePage) {
            const result = await this._onBeforeForwardDeactivation(context);
            if(result !== DialogResult.Accepted) {
                return result;
            }
        }
        return await this._onBeforeDeactivation(context);
    }

    protected async _onBeforeDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        return DialogResult.Accepted;
    }

    protected async _onBeforeForwardDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        return validationResultToDialogResult(await this.validate())
    }

    async validate(): Promise<ValidationResultEnum> {
        return ValidationResultEnum.Success;
    }

    get allowNavigateToPreviousSteps(): boolean {
        return true;
    }

}
