import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";

const MessageContainerBox = styled.div`
    width: 100%;
    text-align: center;
`

export const CheckedInSeatChangeConfirmationComponent: React.FC<{seatNumber: string}> = observer((props) => {
    const services = useServices();

    return (
        <MessageContainerBox>
            {services.language.translationFor('You already checked-in seat {seatNumber}. If you change a checked-in seat, you cannot change it back unless you resume the check-in flow.')
                .withParams({seatNumber: props.seatNumber})}
            <br/>
            <br/>
            {services.language.translate('Are you sure you want to change your seat ?')}
        </MessageContainerBox>
    )
});
