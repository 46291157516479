import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';

export const FastSsrSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} sizeIsHeight {...props} viewBox="0 0 83 65" ratio={83/65}>
            <g fill={props.color? props.color: "currentColor"}>
                <circle cx="65.88" cy="6.42" r="5.92"/>
                <path d="M49.42 60.71a2.85 2.85 0 0 0 5.39 1.83.56.56 0 0 1 0-.12l4.26-13.33-4.21-5.39z"/>
                <path
                    d="M80.61 32.18l-8.43-7.41-3.38-6.93c-.39-.8-1.25-1.63-3.33-2.18L62 14.88c-3.08-.63-4.83-1-6-.24L49 19a3.08 3.08 0 0 0-.81.76l-6.88 9.58a2.85 2.85 0 0 0 .41 3.78L38 40.85a3.64 3.64 0 0 0-1-.69l-4.26-2A3.59 3.59 0 0 0 28 39.87l-7.27 15.44a3.59 3.59 0 0 0 1.71 4.78l2.62 1.23a4.7 4.7 0 0 1 5.14-4.18 4.65 4.65 0 0 1 2.36.94l1.31-2.79a.65.65 0 0 0 .09-.16L44 33.81a2.84 2.84 0 0 0 1.88-1.15l6.55-9.12L57 20.7 53.83 35c-.68 2.73.78 4.38 2.17 6.12l7.25 9.17 3.84 12.2a2.84 2.84 0 1 0 5.46-1.59v-.12l-4-12.69a2.82 2.82 0 0 0-.49-.9L63.81 40l2.9-13.44.61 1.26a2.71 2.71 0 0 0 .68.9l8.85 7.78a2.84 2.84 0 1 0 3.76-4.27z"/>
                <circle cx="29.73" cy="61.82" r="2.58"/>
                <path
                    d="M5.32 20.35h16.76a1.2 1.2 0 0 0 0-2.39H5.32a1.2 1.2 0 1 0 0 2.39zm14.06 17.19H2.62a1.2 1.2 0 1 0 0 2.39h16.76a1.2 1.2 0 1 0 0-2.39zM11.19 29a1.19 1.19 0 0 0 1.2 1.19h16.76a1.2 1.2 0 1 0 0-2.39H12.39a1.2 1.2 0 0 0-1.2 1.2z"/>
            </g>

        </SvgIcon>
    )
}
