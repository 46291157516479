import {PassengerSegmentModel} from "../../passenger-segment/passenger-segment.model";
import {PassengerSegmentShoppingCartBaseModel} from "../../base-models/shopping-cart/passenger-segment-shopping-cart-base.model";
import {ISeatFeeAnalyticsData} from "../../analytics/google-analytics.intefaces";
import {JourneyModel} from "../../journey/journey.model";
import {Check} from "../../../../../types/type-checking";
import {IFeeModel} from "../../base-models/fees/fee-model.interface";
import {IBookingAnalyticsHandlerViewModel} from "../../analytics/booking-analytics-handler.model";


export class PassengerSegmentShoppingCartModel extends PassengerSegmentShoppingCartBaseModel<PassengerSegmentModel> {

    private get journey(): JourneyModel {
        return this.passengerSegment.segment.journey;
    }

    private get analyticsHandler(): IBookingAnalyticsHandlerViewModel {
        return this.services.booking.current.analyticsHandler;
    }

    getSeatFeeAnalyticsData(): ISeatFeeAnalyticsData | null {
        if(Check.isNullOrUndefined(this.passengerSegment.assignedSeat)) return null;
        const  {seatNumber, rowNumber, seatLetter, hasExtraLegroom, seatKey, price} = this.passengerSegment.assignedSeat;
        return {
            id: seatKey,
            rowNumber,
            seatNumber,
            seatLetter,
            hasExtraLegroom,
            analyticsName: this.analyticsHandler.getAnalyticsNameByKey(seatKey),
            price: price.amount
        }
    }

    getSsrsFeesForId(ids: string[]): IFeeModel[]  {
        return this.ssrFees.filter(fee => ids.find( id => fee.id === id)) ;
    }

    getSsrsFeesGroupedByAnalyticsName(): Record<string, IFeeModel[]> {
     
        const groupedFees : Record<string, IFeeModel[]> = {}
        this.ssrFees.forEach(fee => {
            const analyticsName = this.analyticsHandler.getAnalyticsNameByKey(fee.id);
            if(!groupedFees[analyticsName]) {
                groupedFees[analyticsName] = [];
            }
            groupedFees[analyticsName].push(fee);
        })
        return groupedFees;
    }

}
