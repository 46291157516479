import React from 'react';
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {WizardProgressIndicatorComponentProps} from "./wizard-progress-indicator.component.props";
import {IWizard} from "../../models/wizard/wizard.interface";
import {IWizardStepViewModel} from "../../models/wizard/wizard-step.view-model.interface";

const WizardContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    line-height: 1;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.mediumTint};
    height: 55px;
    font-size: ${props => props.theme.fontSize.xxLarge};
    padding: 0 ${props => props.theme.spacing.large};
    gap: ${props => props.theme.spacing.large};

    @media ${(props) => props.theme.mediaQuery.lAndBelow} {
      font-size: ${props => props.theme.fontSize.medium};
      gap: ${props => props.theme.spacing.small};
    }
`

const StepContainerBox = styled.div<{isSelected: boolean; stepsCount: number; canClick: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    height: 100%;
    width: ${props => `calc(100%/${props.stepsCount})`};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: ${props => props.theme.spacing.small};
    cursor: ${props => (props.canClick ? "pointer" : "not-allowed")};
    ${
        props => props.isSelected 
                ? css`
                  color: ${props => props.theme.colors.primaryContrast};
                  border-bottom: 4px solid ${props => props.theme.colors.tertiary};
                  cursor: default;
                `
                : css`
                  border-bottom: 4px solid ${props => props.theme.colors.primary};
                `
                
    }
`

interface WizardStepComponentProps {
    getWizard: () => IWizard;
    step: IWizardStepViewModel;
}

const WizardStepComponent: React.FC<WizardStepComponentProps> = observer((props) => {
    const wizard = props.getWizard();
    const step = props.step;

    if(!step.isVisible) {
        return null;
    }

    const canClick = wizard.canGoToStep(step.index);

    const onClickHandler = async () => {
        if(canClick) {
            await wizard.goToStepByIndex(step.index);
        }
    }
    return (
        <StepContainerBox isSelected={step.isActive}
                          stepsCount={wizard.steps.length}
                          canClick={canClick}
                          onClick={onClickHandler}>
            {step.title}
        </StepContainerBox>
    );
});

export const WizardProgressIndicatorLargeScreenComponent: React.FC<WizardProgressIndicatorComponentProps> = observer((props) => {
    const wizard = props.getWizard();
    const steps = wizard.steps;
    return (
        <WizardContainerBox>
            {steps.map((s) => {
                return (
                    <WizardStepComponent key={s.title} step={s} getWizard={props.getWizard}/>
                );
            })}
        </WizardContainerBox>
    )
});
