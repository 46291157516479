import styled from "styled-components";
import React from "react";
import {observer} from "mobx-react";
import {chevronForward, chevronForwardOutline, chevronDown, chevronDownOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";

export const PassengerNameContainerBox = styled.div<{hasChevron: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.theme.colors.lightShade};
    color: ${props => props.theme.colors.primaryShade};
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.large};
    border-radius: ${props => props.theme.border.defaultRadius} ${props => props.theme.border.defaultRadius} 0 0;
    width: 100%;
    cursor: ${props => props.hasChevron ? "pointer": "default"};
`

const PassengerNameBox = styled.div`
  width: 100%;
  text-align: left;
  flex-grow: 1;
`

interface PassengerNameComponentProps {
    passengerName: string;
    showChevron: boolean;
    isSelected: boolean;
    className?: string;
    onClick?: () => void;
}
export const PassengerNameComponent: React.FC<PassengerNameComponentProps> = observer((props) => {

    const renderChevron = () => {
        if(!props.showChevron) {
            return null;
        }

        if(props.isSelected) {
            return (<IonIcon ios={chevronDownOutline} md={chevronDown}/>);
        }

        return (<IonIcon ios={chevronForwardOutline} md={chevronForward}/>);
    }
    return (
        <PassengerNameContainerBox className={props.className} onClick={props.onClick} hasChevron={props.showChevron}>
            <PassengerNameBox>
                {props.passengerName}
            </PassengerNameBox>
            {renderChevron()}
        </PassengerNameContainerBox>

    )
});

