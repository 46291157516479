import {BookingShoppingCartModel} from "./booking-shopping-cart.model";
import {makeObservable, observable, runInAction} from "mobx";
import {ShoppingCartNotificationMessageModel} from "../notifications/shopping-cart-notification-message.model";
import {IShoppingCartNotificationOptions} from "./shopping-cart-notification-options.interface";
import {NullablePrice} from "../../../../currency/price";
import {TimeSpan} from "../../../../../types/time-span";
import {IFeeModel} from "../../base-models/fees/fee-model.interface";
import {FeeCodeEnum, FeeTypeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {IBookingShoppingCartNotificationsViewModel} from "./booking-shopping-cart-notifications-view-model.interface";
import {IServiceFactory} from "../../../../service-factory.interface";

export class BookingShoppingCartNotificationsModel implements IBookingShoppingCartNotificationsViewModel {
    constructor(private readonly shoppingCart: BookingShoppingCartModel) {
        makeObservable(this, {
            messagesQueue: observable
        });
    }

    private get services(): IServiceFactory {
        return this.shoppingCart.services;
    }

    messagesQueue: ShoppingCartNotificationMessageModel[] = [];
    push(options: IShoppingCartNotificationOptions): void {
        runInAction(() => {
            this.messagesQueue.push(new ShoppingCartNotificationMessageModel(this, options));
        })
    }

    private _previousDateChangeFees: Record<string, NullablePrice> = {};

    pushDateChangeNotification(initialJourneyKey: string, currentJourneyKey: string): void {
        const previousDateChangePrice = this._previousDateChangeFees[initialJourneyKey] || null;
        const currentDateChangePrice = this._getDateChangeFeePrice(currentJourneyKey);
        if(previousDateChangePrice?.amount !== currentDateChangePrice?.amount) {
            this.push({
                message: this.services.language.translate('Your shopping cart was updated with the date change fee.'),
                timeout: TimeSpan.fromSeconds(5)
            });

            this._previousDateChangeFees[initialJourneyKey] = currentDateChangePrice;
        }

    }

    clear(): void {
        runInAction(() => {
            this.messagesQueue.forEach(msg => msg.dispose());
            this.messagesQueue = [];
            this._previousDateChangeFees = {};
        })
    }

    private _getDateChangeFeePrice(journeyKey: string): NullablePrice {
        const dateChangeFeePredicate = (fee: IFeeModel): boolean => {
            return (fee.feeType === FeeTypeEnum.PenaltyFee && fee.feeCode === FeeCodeEnum.DateChangeFee);
        }
        let price =  this.shoppingCart.journeysAllPurchases.find(j => j.journeyKey === journeyKey)?.otherFees.find(dateChangeFeePredicate)?.currentPrice;
        if(price) {
            return price;
        }

        price = this.shoppingCart.allBookingLevelFees.find(dateChangeFeePredicate)?.currentPrice;

        return price || null;
    }

    remove(message: ShoppingCartNotificationMessageModel): void {
        const index = this.messagesQueue.findIndex(msg => msg.id === message.id);
        if(index >= 0) {
            runInAction(() => {
                this.messagesQueue.splice(index, 1);
            })
        }
    }

    get current(): ShoppingCartNotificationMessageModel | null {
        if(this.messagesQueue.length === 0) {
            return null;
        }

        return this.messagesQueue[0];
    }
}
