import React, {PropsWithChildren} from 'react';
import {observer} from "mobx-react";
import {NumberOfPassengersBox} from "./boxes/number-of-passengers.box";
import {IPassengerTypeSelector} from "../../services/passenger-types/passengers-types.service.interface";
import styled from 'styled-components';
import {CardBox} from "../../basic-components/card/card.box";
import {MinusCircleIcon} from "../../assets/svg/minus-circle.icon";
import {PlusCircleIcon} from "../../assets/svg/plus-circle.icon";

const PassengerTypeBox = styled(CardBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const PassengerTypeDetailsBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    line-height: 1;
`

const PassengerTypeDescriptionBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: bold;
    color: ${props => props.theme.colors.secondary};
`

const PassengerTypeAgeLimitDescriptionBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.medium};
    color: rgba(${props => props.theme.colors.mediumRgb}, 0.75);
`

const ButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    justify-items: space-between;
    gap: ${props => props.theme.spacing.large};
`

const ChangeButtonBox = styled.div<{isEnabled: boolean}>`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    border-radius: 50%;
    cursor: ${props => props.isEnabled ? 'pointer' : 'not-allowed'};
    opacity: ${props => props.isEnabled ? '1' : '0.5'};
`
interface PasserTypeCountModifyButtonComponentProps extends PropsWithChildren {
    onClick: () => void;
    isEnabled: boolean;
}

const PasserTypeCountModifyButtonComponent: React.FC<PasserTypeCountModifyButtonComponentProps> = observer((props) => {
    const onClickHandler = () => {
        if(props.isEnabled) {
            props.onClick();
        }
    }
    return (
        <ChangeButtonBox onClick={onClickHandler} isEnabled={props.isEnabled}>
            {props.children}
        </ChangeButtonBox>
    )
});


interface PassengerCountSelectorComponentProps {
    passengerType: IPassengerTypeSelector;
}

export const PassengerTypeSelectorComponent: React.FC<PassengerCountSelectorComponentProps> = observer((props) => {

    const increment = () => {
        props.passengerType.count++;
    }

    const decrement = () => {
        props.passengerType.count--;
    }

    return (
        <PassengerTypeBox className={'tst-passenger-type-' + props.passengerType.getCodeForRegularSell().toLowerCase()}>
            <PassengerTypeDetailsBox>
                <PassengerTypeDescriptionBox>
                    {props.passengerType.description}
                </PassengerTypeDescriptionBox>
                <PassengerTypeAgeLimitDescriptionBox>
                    {props.passengerType.ageLimitsDescription}
                </PassengerTypeAgeLimitDescriptionBox>
            </PassengerTypeDetailsBox>

            <ButtonsContainerBox>
                <PasserTypeCountModifyButtonComponent isEnabled={props.passengerType.allowDecrement}
                                                      onClick={decrement}>
                    <MinusCircleIcon size={3}/>
                </PasserTypeCountModifyButtonComponent>

                <NumberOfPassengersBox className={'tst-passenger-type-count'}>{props.passengerType.count}</NumberOfPassengersBox>

                <PasserTypeCountModifyButtonComponent isEnabled={props.passengerType.allowIncrement}
                                                      onClick={increment}>
                    <PlusCircleIcon size={3}/>
                </PasserTypeCountModifyButtonComponent>
            </ButtonsContainerBox>


        </PassengerTypeBox>
    )
});
