import {IServiceFactory} from "../../../../service-factory.interface";
import React from "react";
import {SpecialEquipmentSsrTypeBase} from "../special-equipment-ssr-type-base";
import { WeaponIcon } from "../../../../../assets/svg/weapon.icon";


//Weapon
export default class WeapSsrType extends SpecialEquipmentSsrTypeBase {
    constructor(services: IServiceFactory) {
        super('WEAP', services);
    }

    get smallIcon(): React.ReactElement | null {
        return <WeaponIcon/>;
    }

    get largeIcon(): React.ReactElement | null {
        return <WeaponIcon/>;
    }
}
