import React from "react";
import {observer} from "mobx-react-lite";
import {StandardDialogPageComponent} from "../../../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../../../../../../components/modal/content/standard-dialog-content.component";
import {InsurancePageContentComponent} from "../../../../insurance/insurance.page";

interface ExtrasInsuranceDialogComponentProps{
    dialogHandler: IDialogConfirmationHandler;
}

export const ExtrasInsuranceDialogSmallScreenComponent: React.FC<ExtrasInsuranceDialogComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Travel insurance')}
                                           dialogHandler={props.dialogHandler} />
            <StandardDialogContentComponent>
                <InsurancePageContentComponent/>
            </StandardDialogContentComponent>

        </StandardDialogPageComponent>
    )
});
