import {
    IPassengerSegmentBoardingPassViewModel
} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {PassengerBoardingPassesGroupSelector} from "./passengers-boarding-passes-group-selector";
import {IServiceFactory} from "../../../../services/service-factory.interface";
import {NullableString} from "../../../../types/nullable-types";

// Apple .pkpasses bundle are limited to maximum 10 passes
// https://developer.apple.com/documentation/walletpasses/distributing_and_updating_a_pass  -> "You can have up to 10 passes or 150 MB for a bundle of passes."

export class BoardingPassesToWalletSelector {
    constructor(private readonly services: IServiceFactory,
                boardingPasses: IPassengerSegmentBoardingPassViewModel[],
                private readonly _maxGroupSize: number,
                private readonly _walletProviderName: string) {
        this.groups = this._createGroups(boardingPasses)
    }


    public readonly groups: PassengerBoardingPassesGroupSelector[];

    get maxPassesBatchSizeUserMessage(): NullableString {
        if(this.groups.length <= 1) {
            return null;
        }

        return this.services.language.translationFor('{walletProvider} allows maximum of {maxPasses} boarding passes in a pass file. Please use the Add to {walletProvider} button for each group in order to add the boarding passes to your wallet.')
            .withParams({
                walletProvider: this._walletProviderName,
                maxPasses: this._maxGroupSize.toString()
            })
    }

    private _createGroups(boardingPasses: IPassengerSegmentBoardingPassViewModel[]): PassengerBoardingPassesGroupSelector[] {

        const result: PassengerBoardingPassesGroupSelector[] = [];

        const passesGroupedByPassenger = boardingPasses.groupByKey(bp => bp.passengerKey + bp.isInfant);

        let group = new PassengerBoardingPassesGroupSelector(this.services, 1);
        result.push(group);

        for(let passengerKey of Object.keys(passesGroupedByPassenger)) {
            const passengerBoardingPasses = passesGroupedByPassenger[passengerKey];

            if(group.boardingPassesCount + passengerBoardingPasses.length > this._maxGroupSize) {
                group = new PassengerBoardingPassesGroupSelector(this.services, result.length + 1);
                result.push(group);
            }

            group.addPassengerSelector(passengerBoardingPasses);
        }

        return result;

    }
}

