import React, {useEffect, useRef} from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {ISwiperButtonsOptions, SwiperComponent} from "../../basic-components/swiper/swiper.component";
import {Navigation, Pagination} from "swiper";
import {useServices} from "../../hooks/use-services.hook";
import {SwiperRef, SwiperSlide} from "swiper/react";
import {Swiper as SwiperClass} from "swiper/types";
import {IServiceFactory} from "../../services/service-factory.interface";
import {ZIndex} from "../../types/z-index";
import {SwiperModule} from "swiper/types/shared";

const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.large};
`

const HeaderSlideContentBox = styled.div<{usesPagination: boolean}>`
    margin-bottom: ${props => props.usesPagination ? "15px" : "0"};
`

const HeaderBox = styled.div<{stickyTop: number, backgroundColor?: string}>`
    position: sticky;
    top: ${props => `calc(${props.stickyTop}px - var(--page-content-padding))`};
    z-index: ${ZIndex.StickyHeaders - 1};
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.medium};
    width: 100%;
    background-color: ${props => props.backgroundColor ?? props.theme.colors.primaryContrast};
    line-height: 1;
    
`


const HeaderSwiperContainerBox = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: ${props => props.theme.border.defaultRadius} ${props => props.theme.border.defaultRadius} 0 0;
`


export function computeSwiperSlidesPerView(services: IServiceFactory): number {
    if(services.mediaQuery.lAndBelow) {
        return 1;
    } else {
        return 2;
    }
}


interface SynchronizedSwipersComponentProps<TItem> {
    items: TItem[];
    getItemKey: (item: TItem) => string;
    renderHeaderItem?: (item: TItem, index: number) => React.ReactElement;
    renderContentItem: (item: TItem, index: number) => React.ReactElement;
    renderExtraHeader?: () => React.ReactElement | null;
    captureHeaderSwiper?: (swiperRef: SwiperRef | null) => void;
    captureContentSwiper?: (swiperRef: SwiperRef | null) => void;
    stickyTopRef?: React.MutableRefObject<HTMLDivElement | null>;
    headerBackgroundColor?: string;
}

export const SynchronizedSwipersComponent = observer(<TItem extends any>(props: SynchronizedSwipersComponentProps<TItem>) => {
    const services = useServices();
    const headerSwiperRef = useRef<SwiperRef | null>(null);
    const contentSwiperRef = useRef<SwiperRef | null>(null);

    useEffect(() => {
        if(props.captureHeaderSwiper) {
            props.captureHeaderSwiper(headerSwiperRef.current);
        }

        if(props.captureContentSwiper) {
            props.captureContentSwiper(contentSwiperRef.current);
        }
    })

    const spaceBetweenSlide = services.theme.currentTheme.spacing.extraLarge;

    const slidesPerView = Math.min((props.items.length || 1), computeSwiperSlidesPerView(services));

    const swiperButtonsOptions: ISwiperButtonsOptions = {
        size: services.layout.isInSmallScreenView ? "medium" : "large",
        hideButtons: (props.items.length / slidesPerView) <= 1

    };

    const onHeaderSlideChange = (swiper: SwiperClass) => {
        contentSwiperRef.current?.swiper.slideTo(swiper.activeIndex, undefined, false);
    }

    const onContentSlideChange = (swiper: SwiperClass) => {
        headerSwiperRef.current?.swiper.slideTo(swiper.activeIndex, undefined, false);
    }

    const renderHeaderSlide = (item: TItem, index: number, usesPagination: boolean) => {

        if(!props.renderHeaderItem) {
            return null;
        }

        return (
            <SwiperSlide key={props.getItemKey(item)}>
                <HeaderSlideContentBox usesPagination={usesPagination}>
                    {props.renderHeaderItem(item, index)}
                </HeaderSlideContentBox>
            </SwiperSlide>

        )
    }

    const renderContentSlide = (item: TItem, index: number) => {
        return (
            <SwiperSlide key={props.getItemKey(item)}>
                {props.renderContentItem(item, index)}
            </SwiperSlide>

        )
    }

    const renderExtraHeader = () => {
        if(props.renderExtraHeader) {
            return props.renderExtraHeader();
        }

        return null;
    }

    const renderHeaderSwiper = () => {
        if(!props.renderHeaderItem) {
            return null;
        }

        const modules: SwiperModule[] = [Navigation];

        const usePagination = props.items.length > 1;

        if(usePagination) {
            modules.push(Pagination);
        }

        return (
            <HeaderSwiperContainerBox>
                <SwiperComponent ref={headerSwiperRef}
                                 modules={modules}
                                 pagination={{
                                     enabled: usePagination,
                                 }}
                                 autoHeight={true}
                                 spaceBetween={spaceBetweenSlide}
                                 navigationButtons={swiperButtonsOptions}
                                 speed={500}
                                 slidesPerView={slidesPerView}
                                 slidesPerGroup={slidesPerView}
                                 onSlideChange={onHeaderSlideChange}>
                    {props.items.map((item, index) => renderHeaderSlide(item, index, usePagination))}
                </SwiperComponent>
            </HeaderSwiperContainerBox>
        )
    }

    const stickyTop = props.stickyTopRef?.current?.getBoundingClientRect().height ?? 0;

    return (
        <ContainerBox>
            <HeaderBox stickyTop={stickyTop} backgroundColor={props.headerBackgroundColor}>
                {renderExtraHeader()}
                {renderHeaderSwiper()}
            </HeaderBox>

            <SwiperComponent ref={contentSwiperRef}
                             modules={[Navigation]}
                             spaceBetween={spaceBetweenSlide}
                             speed={500}
                             slidesPerView={slidesPerView}
                             slidesPerGroup={slidesPerView}
                             onSlideChange={onContentSlideChange}>
                {props.items.map(renderContentSlide)}
            </SwiperComponent>
        </ContainerBox>
    )
});
