import { Price } from "../../../currency/price";
import {SeatsRowGroupType} from "./seats-row-group-type.enum";
import {SeatsRowModel} from "./seats-row.model";
import {ISeatsRowsGroupViewModel} from "./seats-rows-group-view-model.interface";

export class SeatsRowsGroupModel implements ISeatsRowsGroupViewModel {
    constructor(public readonly groupType: SeatsRowGroupType,
                public readonly description: string) {
    }

    readonly rows: SeatsRowModel[] = [];
    public get minPrice(): Price {
        return Price.min(this.rows.map(r => r.minPrice));
    }
}
