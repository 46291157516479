import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {useServices} from "../../hooks/use-services.hook";


const BodyTextBox = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  font-size: ${props => props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
  line-height: 1.5;
  height: 100%;
  width: 100%;
`

export const TermsAndConditionDoNotAgreeDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate("You cannot continue")}
                                           dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <BodyTextBox>
                    {services.language.translate('Please agree with the Privacy Policy, Cookies Policy and Mobile app T&Cs in order to continue or close the application.')}
                </BodyTextBox>
            </StandardDialogContentComponent>

        </StandardDialogPageComponent>
    );
});
