import React from "react";
import {IHomePageTabViewModel} from "../../../services/home-page/tabs/home-page.tab.interface";
import {MyTripsComponent} from "../components/my-trips/my-trips.component";
import {HomePageTabBase} from "../../../services/home-page/tabs/home-page-tab-base";
import { observer } from "mobx-react";
import { useServices } from "../../../hooks/use-services.hook";
import {IJourneyHistoryViewModel} from "../../../services/booking-history/models/journey-history-view-model.interface";
import {BookingSearchComponent} from "../components/booking-search/booking-search.component";
import {HomePageHeaderComponent} from "../components/home-page-header/home-page-header.component";
import { DocumentIcon } from "../../../assets/svg/document.icon";
import {SpinnerComponent} from "../../../components/spinner/spinner.component";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import styled from "styled-components";

const MyTripsTabHeaderComponent: React.FC<{tab: MyTripsTab}> = observer(() => {
    const services = useServices();
    return (
        <HomePageHeaderComponent title={services.language.translate('My trips')}/>
    );
});

const RetrieveABookingButton = styled(PrimaryButton)`
    margin-bottom: ${props => props.theme.spacing.large};
`

const MyTripsTabContentComponent: React.FC<{tab: MyTripsTab}> = observer((props) => {
    const services = useServices();
    if(services.bookingHistory.isLoadingHistoryInProgress) {
        return (<SpinnerComponent/>);
    }

    if(props.tab.isEmpty) {
        return (
            <BookingSearchComponent retrieveBookingButtonText={services.language.translate('Retrieve my booking')}
                                    onRetrieveBookingButtonClick={async (searchParams) => {
                                        await searchParams.retrieveBookingToHistory();
                                    }}/>
        );
    }
    return (
        <MyTripsComponent futureFlights={props.tab.futureFlights} pastFlights={props.tab.pastFlights}/>
    );
});

const MyTripsTabFooterComponent: React.FC<{tab: MyTripsTab}> = observer((props) => {
    const services = useServices();
    if(props.tab.isEmpty) {
        return null;
    }
    return (
        <RetrieveABookingButton onClick={() => services.navigator.routes.bookingRetrieve.activate()}>
            {services.language.translate('Retrieve a booking')}
        </RetrieveABookingButton>
    );
});

export class MyTripsTab extends HomePageTabBase implements IHomePageTabViewModel {

    get isEmpty(): boolean {
        return this.futureFlights.length === 0 && this.pastFlights.length === 0;
    }

    get futureFlights(): IJourneyHistoryViewModel[] {
        return this.services.bookingHistory.myFutureFlights;
    }

    get pastFlights(): IJourneyHistoryViewModel[] {
        return this.services.bookingHistory.myPastFlights;
    }

    get text(): string {
        return this.services.language.translate('My trips');
    }

    renderIcon(): React.ReactElement {
        return (
            <DocumentIcon/>
        );
    }

    renderHeader(): React.ReactElement | null {
        return <MyTripsTabHeaderComponent tab={this}/>;
    }

    renderContent(): React.ReactElement {
        return (<MyTripsTabContentComponent tab={this}/>);
    }

    renderFooter(): React.ReactElement | null {
        return (<MyTripsTabFooterComponent tab={this}/>)
    }
}


