import React from 'react';
import {SeatFilterBaseModel} from "./seat-filter-base.model";
import {SeatModel} from "../seat.model";
import {IServiceFactory} from "../../../../service-factory.interface";
import {ChildFaceIcon} from "../../../../../assets/svg/child-face.icon";

export class ChildSeatFilterModel extends SeatFilterBaseModel {
    constructor(services: IServiceFactory) {
        super(services.language.translate('Seats for children'), services);
    }
    shouldAllowSeat(seat: SeatModel): boolean {
        return seat.allowChild;
    }

    renderIcon(): React.ReactElement {
        return (<ChildFaceIcon/>);
    }
}
