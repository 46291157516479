import React from 'react';
import {IonLabel, IonSegment, IonSegmentButton} from "@ionic/react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {observer} from "mobx-react";
import {IHomePageTabViewModel} from "../../../../services/home-page/tabs/home-page.tab.interface";

const HomePageNavbarItemBox = styled(IonSegmentButton)<{isSelected: boolean}>`
    --color: ${props => props.theme.colors.medium};
    --color-checked: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.xSmall};
    --border-style: none;
    --border-radius: 7px;
    --indicator-box-shadow: none;
    --indicator-color: none;

    div.segment-button-indicator {
        left: 5%;
        width: 90%;
    }
`

const HomePageNavbarComponentBox = styled(IonSegment)`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    --background: none;
    border-radius: 0;
`

const IonLabelBox = styled(IonLabel)`
    margin: 10px 0 0;
    line-height: 1;
`

const HomePageNavBarButtonComponent: React.FC<{tab: IHomePageTabViewModel}> = observer((props) => {
    const services = useServices();
    const isSelected = services.homePage.currentTab.text === props.tab.text;
    
    return (
        <HomePageNavbarItemBox isSelected={isSelected} value={props.tab.text} className={'tst-tab'} onClick={() => props.tab.open()}>
            {props.tab.renderIcon()}
            <IonLabelBox>{props.tab.text}</IonLabelBox>
        </HomePageNavbarItemBox>
    )
});


export const HomePageNavbarComponent: React.FC = observer(() => {
    const services = useServices();


    return (
        <HomePageNavbarComponentBox mode="ios" value={services.homePage.currentTab.text}>
            {services.homePage.tabs.filter(tab => tab.isVisible).map(tab => <HomePageNavBarButtonComponent tab={tab} key={tab.text}/>)}
        </HomePageNavbarComponentBox>
    );
});


