import React from 'react';
import styled from "styled-components";
import { PersonIcon } from "../../../../assets/svg/person.icon";
import { useServices } from "../../../../hooks/use-services.hook";
import { IMaturePassengerViewModel } from "../../../../services/booking/models/passenger/mature-passenger-view-model.interface";

const FlightItineraryPassengerAccordionContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FlightItineraryPassengerAccordionContentFullName = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.primaryTint};
    margin-left: 8px;
`;

const FlightItineraryPassengerAccordionContentSeatsNumbers = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.secondary};
    margin: 0 ${props => props.theme.spacing.medium};
`;

const FlightItineraryPassengerAccordionContentIconBox = styled.div``

interface FlightItineraryPassengerAccordionHeaderComponentProps {
    passenger: IMaturePassengerViewModel;
    showSeats?: boolean;
}

export const FlightItineraryPassengerAccordionHeaderComponent: React.FC<FlightItineraryPassengerAccordionHeaderComponentProps> = (props) => {
    const services = useServices();
    const booking = services.booking.current;
    const passengersSegments = booking.getAllPassengersSegments();

    const renderSeats = () => {
        if(props.showSeats) {
            return  (<FlightItineraryPassengerAccordionContentSeatsNumbers>
                {passengersSegments.filter(ps => ps.assignedSeat && ps.passengerKey === props.passenger.passengerKey)
                                    .map(ps => ps.assignedSeat?.seatNumber).join(', ')}
            </FlightItineraryPassengerAccordionContentSeatsNumbers>);
        }
        return null;
    }

    return(
        <FlightItineraryPassengerAccordionContentBox>
            <FlightItineraryPassengerAccordionContentIconBox>
                <PersonIcon size={2.4}/>
            </FlightItineraryPassengerAccordionContentIconBox>
            <FlightItineraryPassengerAccordionContentFullName>
                {props.passenger.getFullName()}
            </FlightItineraryPassengerAccordionContentFullName>
            {renderSeats()}
        </FlightItineraryPassengerAccordionContentBox>
    )
}
