import React from "react";
import {WheelchairIcon} from "../../../../assets/svg/wheelchair.icon";
import {OnlineCheckInBlockerSsr} from "../online-check-in-blocker-ssr";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {SSR_STANDARD_LARGE_ICON_SIZE, SsrQuantityEditorStyle} from "../../ssr-types.service.interface";
import { SsrCategoryEnum } from "../../ssr-category.enum";
import {TimeSpan} from "../../../../types/time-span";

export abstract class SpecialAssistanceSsrBase extends OnlineCheckInBlockerSsr {
    get smallIcon(): React.ReactElement | null {
        return <WheelchairIcon/>;
    }

    get largeIcon(): React.ReactElement | null {
        return <WheelchairIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>;
    }

    get preferredQuantityEditorStyle(): SsrQuantityEditorStyle {
        return SsrQuantityEditorStyle.checkBox;
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SPECIAL_ASSISTANCE;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.SpecialAssistance;
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(3);
    }
}
