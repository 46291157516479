import {IDotRezCheckInSegmentPassengersRequest} from "../../data-contracts/requests/booking/check-in-segment-passengers.request";
import {DotRezGraphQLMutationBuilder} from "../dot-rez-query-builder";
import {commitBookingGraphqlMutation} from "./commit-booking-graphql.mutation";


export function checkInGraphqlMutation(segments: IDotRezCheckInSegmentPassengersRequest[], builder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    builder = autoAssignSeatsMutation(segments, builder);
    builder = commitBookingGraphqlMutation(builder);
    builder = checkInPassengersMutation(segments, builder);
    return builder;
}




function checkInPassengersMutation(segments: IDotRezCheckInSegmentPassengersRequest[], builder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    for(let i = 0; i < segments.length; i++ ) {
        builder = checkInSegmentPassengersMutation(i, segments[i], builder);
    }
    return builder;
}

function checkInSegmentPassengersMutation(index: number, segment: IDotRezCheckInSegmentPassengersRequest, builder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    const segmentKeyParamName = `checkInSegmentKey${index}`;
    const requestParamName = `checkInRequest${index}`;

    builder.addMutation(`
        mutation checkInSegmentPassengers${index}($${segmentKeyParamName}: String 
                                  $${requestParamName}: Input_CheckinPassengersRequestv3) {
            checkinBySegmentAddv3(segmentKey: $${segmentKeyParamName}
                                  request: $${requestParamName})
        }
        `, {
        name: segmentKeyParamName,
        value: segment.segmentKey
    }, {
        name: requestParamName,
        value: segment.request
    });

    return builder;
}

export function autoAssignSeatsMutation(segments: IDotRezCheckInSegmentPassengersRequest[], builder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    for(let i = 0; i < segments.length; i++) {
        if(segments[i].shouldAssignSeats) {
            builder = autoAssignSegmentSeatsMutation(i, segments[i], builder);
        }
    }
    return builder;
}

function autoAssignSegmentSeatsMutation(index: number, segment: IDotRezCheckInSegmentPassengersRequest, builder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    const primaryPassengerKeyVarName = `seatsAutoAssignPrimaryPassengerKey${index}`;
    const segmentKeyVarName = `seatsAutoAssignSegmentKey${index}`;
    const requestVarName = `seatsAutoAssignRequest${index}`;

    builder.addMutation(`
        mutation checkInAutoAssignSegmentSeats${index}($${primaryPassengerKeyVarName}: String
                                    $${segmentKeyVarName}: String 
                                    $${requestVarName}: Input_AutoAssignRequest) {
            seatAutoAssignSegment(primaryPassengerKey: $${primaryPassengerKeyVarName}
                              segmentKey: $${segmentKeyVarName}
                              request: $${requestVarName})
        }
    `,
        {
            name: primaryPassengerKeyVarName,
            value: segment.request.passengers[0].passengerKey
        },
        {
            name: segmentKeyVarName,
            value: segment.segmentKey
        },
        {
            name: requestVarName,
            value: {
                seatAssignmentMode: "PreSeatAssignment",
                waiveFee: true
            }
        });

    return builder;
}
