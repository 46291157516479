import {IDotRezSellBundleRequest} from "../../data-contracts/requests/booking/sell-bundle.request";
import {DotRezGraphQLMutationBuilder} from "../dot-rez-query-builder";

export function sellBundleGraphqlMutation(journeyKeys: string[], request: IDotRezSellBundleRequest, builder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    journeyKeys.forEach((journeyKey, index) => {
        const journeyKeyVarName = "sellBundleJourneyKey" + index.toString();
        const requestVarName = "sellBundleRequest" + index.toString();
        builder.addMutation(`
                mutation sellBundle${index}($${journeyKeyVarName}: String 
                                    $${requestVarName}: Input_BundleSellRequest) {
                    bundleSell(journeyKey: $${journeyKeyVarName}
                               request: $${requestVarName}) {
                        ssrKey
                    }
                }
        `, {
            name: journeyKeyVarName,
            value: journeyKey
        }, {
            name: requestVarName,
            value: request
        });
    });

    return builder;
}
