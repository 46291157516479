import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const DownloadIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={4.5} {...props} sizeIsHeight viewBox="0 0 45.773 44.264" ratio={45.773/44.264}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(-345.561 -126.061)"><path d="M380.987,141.68h-8.269V129.186a2.933,2.933,0,0,0-2.689-3.125h-8.022a2.931,2.931,0,0,0-2.689,3.125V141.68H351.05c-2.485,0-3.146,1.5-1.462,3.33l3.633,3.966c1.68,1.834,4.426,4.832,6.108,6.662l3.635,3.967a4.081,4.081,0,0,0,6.108,0l3.633-3.967c1.678-1.83,4.428-4.828,6.108-6.662l3.635-3.966C384.129,143.177,383.469,141.68,380.987,141.68Z" transform="translate(2.43 0)" /><path d="M391.334,146.208a3.109,3.109,0,0,0-6.217,0v4.516H351.78v-4.516a3.109,3.109,0,0,0-6.219,0v7.54c0,.014.005.027.005.041s-.005.027-.005.041a3.111,3.111,0,0,0,3.112,3.109h39.536a3.1,3.1,0,0,0,3.053-2.555,2.794,2.794,0,0,0,.071-.636Z" transform="translate(0 13.386)" /></g>
        </SvgIcon>
    )
}
