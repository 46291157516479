import {IUserProfileViewModel} from "../../profile/user-profile-view-model.interface";
import {IUserProfileEditorViewModel} from "../../profile/user-profile-editor-view-model.interface";
import {AnonymousUserModel} from "../anonymous-user.model";
import {ICompanionViewModel} from "../../companion/companion-view-model.interface";
import {IPersonViewModel} from "../../person/person-view-model.interface";
import {IPassengerViewModel} from "../../../../booking/models/passenger/passenger-view-model.interface";
import { IPersonTravelDocumentViewModel } from "../../person/person-travel-document-view-model.interface";
import {TimeOfTheDay} from "../../../../time/time.service.interface";
import {IServiceFactory} from "../../../../service-factory.interface";
import {NullableString} from "../../../../../types/nullable-types";
import {IBlueBenefitsSubscription} from "../../../../blue-benefits/models/subscriptions/blue-benefits-subscription.interface";

export class AnonymousUserProfileModel implements IUserProfileViewModel {
    constructor(private readonly user: AnonymousUserModel) {
    }

    readonly isProfileInitialized = true;
    readonly isCompanionsListInitialized = true;
    readonly customerNumber: string = "";
    readonly emailAddress: string = "";
    readonly title: NullableString = null;

    private get services(): IServiceFactory {
        return this.user.services;
    }

    waitForProfileInitialization(): Promise<void> {
        return Promise.resolve();
    }

    getEditor(): IUserProfileEditorViewModel {
        throw new Error('Profile edit is not allowed for anonymous user');
    }

    get blueBenefitsSubscription(): IBlueBenefitsSubscription {
        return this.user.services.blueBenefits.noSubscription;
    }

    getGreetingMessage(): string {
        const language = this.services.language;
        switch (this.services.time.getTimeOfTheDay()) {
            case TimeOfTheDay.Morning:
                return language.translate('Good morning!');
            case TimeOfTheDay.Evening:
                return language.translate('Good evening!');
            default:
                return language.translate('Hi!');
        }
    }

    readonly companions: ICompanionViewModel[] = [];
    readonly travelDocuments: IPersonTravelDocumentViewModel[] = [];

    async createCompanionFromPassenger(passenger: IPassengerViewModel): Promise<IPersonViewModel> {
        throw new Error(`Cannot create companion from passenger for anonymous user`);
    }

    getCompanionsForBooking(): IPersonViewModel[] {
        return [];
    }

    asCompanion(): IPersonViewModel {
        throw new Error('Anonymous user cannot be converted to companion');
    }

    async addCompanion(): Promise<void> {
        throw new Error('Adding companion for anonymous user is not allowed');
    }

    get companionsCountLimit(): number {
        return 0;
    }

    async addTravelDocument(): Promise<void> {
        throw new Error('Adding travel document for anonymous user is not allowed'); 
    }
}
