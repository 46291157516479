import {ManageSsrsBaseModel} from "./manage-ssrs-base.model";
import {BookingModel} from "../../booking.model";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {IManageSingleSsrsEditOptions, IManageSingleSsrViewModel} from "./manage-ssrs-view-model.interface";
import {DialogResult} from "../../../../dialog/dialog-enums";
import {NullableString} from "../../../../../types/nullable-types";
import {IBookingSsrsAggregatorViewModel} from "../../ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";

export class ManageSingleSsrModel extends ManageSsrsBaseModel implements IManageSingleSsrViewModel {   
    constructor(booking: BookingModel,
                protected readonly ssrsAggregator: IBookingSsrsAggregatorViewModel) {
        super(ssrsAggregator.ssrTypes[0].name, booking)
    }

    get ssrTypes(): ISsrType[] {
        return [this.ssrType];
    }

    get ssrType(): ISsrType {
        return this.ssrsAggregator.ssrTypes[0];
    }

    get allowEdit(): boolean {
        return this.ssrsAggregator.canBeModified;
    }

    get modificationBlockingReason(): NullableString {
        return this.ssrsAggregator.modificationBlockingReason;
    }

    async edit(options?: IManageSingleSsrsEditOptions): Promise<void> {
        const dialogResult = await this.services.dialogFactory.showSsrsSelector({
            title: this.ssrsAggregator.ssrTypes[0].name,
            ssrsAggregator: this.ssrsAggregator,
            disclaimer: options?.disclaimer,
            journeyCustomSsrsEditorRenderer: options?.journeyCustomSsrsEditorRenderer,
            passengerCustomSsrEditorRenderer: options?.passengerCustomSsrsEditorRenderer,
        });

        if(dialogResult === DialogResult.Accepted) {
            await this.booking.sellSsrs();
        }
    }
}
