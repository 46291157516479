import React from "react";
import { useServices } from "../../../../../hooks/use-services.hook";
import { observer } from "mobx-react-lite";
import { PaymentAccordionHeaderComponent } from "../payment-accordion/payment-accordion-header.component";
import { PaymentAccordionComponent } from "../payment-accordion/payment-accordion.component";
import { PaymentBlueCodeIcon } from "../../../../../assets/svg/payment-blue-code.icon";
import styled from 'styled-components';
import { PromotionalCodeInputComponent } from "../../../../../components/promotionals/common/promotional-code-input.component";
import { setElementFocus } from "../../../../../utils/set-element-focus";
import { IVoucherViewModel } from "../../../../../services/booking/models/promotions/voucher/voucher-view-model.interface";
import {PrimaryButtonInverted} from "../../../../../basic-components/buttons/primary-button-inverted";


const VoucherAccordionDetailContentBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`
const VoucherAccordionContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
`

const ApplyVoucherButtonBox = styled(PrimaryButtonInverted)`
    margin-left: ${props => props.theme.spacing.large};
    padding-bottom: ${props => props.theme.spacing.spacePx(1.5)};
    padding-top: ${props => props.theme.spacing.spacePx(1.5)};
`
interface VoucherCodeComponentProps {
    voucher: IVoucherViewModel;
}
export const VoucherCodeComponent: React.FC<VoucherCodeComponentProps> = observer((props) => {
    const services = useServices();
    if(!props.voucher.shouldBeVisible) {
        return null;
    }


    const VoucherCodeHeader = () => {
        return (
            <PaymentAccordionHeaderComponent renderIcon={() => <PaymentBlueCodeIcon color={services.theme.currentTheme.colors.secondary} />} title={services.language.translate('Voucher code')} />
        )
    }

    const captureInputElementRef = (element: any) =>  {
        setElementFocus(element);
    }

    const onApplyVoucher = async () => {
        await props.voucher.apply();
    }


    const renderAccordionContent = () => {
        return (
            <VoucherAccordionDetailContentBox className={'VoucherAccordionDetailContentBox'}>
                <VoucherAccordionContentBox className={'VoucherAccordionContentBox'}>
                    <PromotionalCodeInputComponent promotional={props.voucher}
                                                   placeHolder={services.language.translate('Add voucher here')}
                                                   captureInputElementRef={captureInputElementRef} className={'tst-voucher-code'}/>
                    <ApplyVoucherButtonBox onClick={onApplyVoucher}
                                           disabled={!Boolean(props.voucher.code.value) || props.voucher.isApplied || !props.voucher.isAllowed}
                                           className={'tst-apply-voucher'}
                                           fontSize={"small"}
                                           fullWidth={false}>
                        {services.language.translate('Apply')}
                    </ApplyVoucherButtonBox>
                </VoucherAccordionContentBox>
                {props.voucher.getVoucherNotAllowedMessage()}
            </VoucherAccordionDetailContentBox>
        )
    }

    return (
        <PaymentAccordionComponent renderHeader={VoucherCodeHeader}
                                   renderContent={renderAccordionContent}
                                   isExpanded={props.voucher.isApplied} />
    )
});
