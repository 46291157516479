import {SsrTypeBase} from "../../ssr-type-base";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import React from "react";
import {CknSsrSmallIcon} from "./ckn-ssr.small-icon";
import {SSR_STANDARD_LARGE_ICON_SIZE} from "../../ssr-types.service.interface";

export abstract class CknSsrBase extends SsrTypeBase {

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.CKN
    }

    get smallIcon(): React.ReactElement | null {
        return (<CknSsrSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<CknSsrSmallIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>);
    }

}
