import {DotRezGraphQLMutationBuilder} from "../dot-rez-query-builder";
import {ICommitBookingOptions} from "../../session/booking-session/dot-rez-booking.session.interface";
import {IDotRezBookingComment} from "../../data-contracts/booking/booking-state/booking-state.data-contracts";


export function commitBookingGraphqlMutation(builder: DotRezGraphQLMutationBuilder, options?: ICommitBookingOptions): DotRezGraphQLMutationBuilder {
    options = {
        notifyContacts: true,
        channel: "",
        ...options
    };

    const comments: IDotRezBookingComment[] = options.comments || [];

    const commitBookingRequestVarName = 'commitBookingRequest';
    builder.addMutation(`
            mutation commitBooking($${commitBookingRequestVarName}: Input_CommitRequestv2) {
                bookingCommitv3(request: $${commitBookingRequestVarName}) {
                    recordLocator
                }
            }
    `,
        {
            name: commitBookingRequestVarName,
            value: {
                notifyContacts: options.notifyContacts,
                restrictionOverride: false,
                receivedBy: options.channel,
                comments: comments
            }
        });

    return builder;
}
