import { DotRezSessionBase } from "../dot-rez-session-base";
import {IDotRezUserSession} from "./dot-rez-user.session.interface";
import { ITripScheduleRequest } from "../../data-contracts/requests/booking/trip-schedule.request";
import { ITripScheduleResponse } from "../../data-contracts/booking/trips/trip-schedule.data-contract";
import { IServiceFactory } from "../../../service-factory.interface";
import { DotRezTransientSessionTimer } from "../session-timer/dot-rez-transient-session-timer";
import {
    IDotRezPerson,
    IDotRezPersonPhoneNumber,
    IDotRezPersonTravelDocument
} from "../../data-contracts/user/person.data-contracts";
import { IDotRezAddPhoneRequest } from "../../data-contracts/requests/user/add-phone.request";
import { IDotRezUpdatePersonDetailsRequest } from "../../data-contracts/requests/user/update-person-details.request";
import { IUpdateUserTravelDocumentRequest } from "../../../airline-webapi/requests/update-user-travel-document.request";
import { IAddUserTravelDocumentRequest } from "../../../airline-webapi/requests/add-user-travel-document.request";
import { Check } from "../../../../types/type-checking";
import {IDotRezRetrieveBookingByLastNameRequest} from "../../data-contracts/requests/booking/retrieve-booking-by-last-name.request";
import {IDotRezBookingData} from "../../data-contracts/booking/booking-state/booking-state.data-contracts";
import {findBookingByLastNameQuery} from "../../graph-ql/queries/booking-data-graphql.query";
import {BookingsReader} from "./bookings-reader";
import {IDotRezCurrencyConversionRequest} from "../../data-contracts/requests/user/currency-conversion.request";
import {ICurrencyConversionResponse} from "../../data-contracts/responses/user/currency-conversion.response";
import {IPersistedBookingHistory} from "../../../booking-history/models/persisted-booking-history.interface";

export class DotRezUserSession extends DotRezSessionBase implements IDotRezUserSession {
    constructor(token: string,
                services: IServiceFactory) {
        super(token, services, new DotRezTransientSessionTimer())
        this._pastBookingsReader = new BookingsReader(this);
    }

    private _pastBookingsReader: BookingsReader;

    async tripSchedule(request: ITripScheduleRequest): Promise<ITripScheduleResponse> {
        const response = await this.get<ITripScheduleResponse>(`/api/nsk/v1/trip/schedule?Origin=${request.origin}&Destination=${request.destination}&BeginDate=${request.beginDate}&EndDate=${request.endDate}`);
        return response.data;
    }


    async getCurrentUserPerson(): Promise<IDotRezPerson> {
        const response = await this.get<IDotRezPerson>(`/api/nsk/v1/user/person`);
        return response.data;
    }

    async updatePersonDetails(request: IDotRezUpdatePersonDetailsRequest): Promise<void> {
        await this.patch(`/api/nsk/v1/user/person`, {
            body: request
        });
    }

    async addPhone(request: IDotRezAddPhoneRequest): Promise<void> {
        await this.post(`/api/nsk/v1/user/person/phoneNumbers`, {
            body: request
        })
    }

    async updatePhone(request: IDotRezPersonPhoneNumber): Promise<void> {
        const { personPhoneNumberKey, ...body } = request;
        await this.patch(`/api/nsk/v1/user/person/phoneNumbers/${personPhoneNumberKey}`, {
            body: body
        });
    }

    async deleteTravelDocument(travelDocumentKey: string): Promise<void> {
        await this.delete(`/api/nsk/v1/user/person/travelDocuments/${travelDocumentKey}`);
    }

    async updateTravelDocument(request: IUpdateUserTravelDocumentRequest): Promise<IDotRezPersonTravelDocument> {
        const { travelDocumentKey, ...body } = request;
        await this.deleteTravelDocument(travelDocumentKey);
        return await this.addTravelDocument(body);
    }


    async addTravelDocument(request: IAddUserTravelDocumentRequest): Promise<IDotRezPersonTravelDocument> {
        const response = await this.post<IDotRezPersonTravelDocument>(`/api/nsk/v1/user/person/travelDocuments`, {
            body: request
        });

        const url = response.getLocationHeader();
        if (Check.isNullOrUndefined(url)) {
            throw new Error(`url cannot be null or undefined`);
        }
        const newTravelDocument = await this.get<IDotRezPersonTravelDocument>(url);
        return newTravelDocument.data;
    }


    async findBookingByLastName(request: IDotRezRetrieveBookingByLastNameRequest): Promise<IDotRezBookingData> {
        const response = await this.executeGraphQLQuery<{bookingsv2: IDotRezBookingData}>(findBookingByLastNameQuery(request));
        return response.data.bookingsv2;
    }

    async getBookingsHistory(): Promise<IPersistedBookingHistory[]> {
        return await this._pastBookingsReader.getBookings();
    }

    async convertCurrency(request: IDotRezCurrencyConversionRequest): Promise<ICurrencyConversionResponse> {
        const response = await this.get<ICurrencyConversionResponse>(`/api/nsk/v1/currency/converter?FromCurrencyCode=${request.fromCurrencyCode}&ToCurrencyCode=${request.toCurrencyCode}&Amount=${request.amount}`);
        return response.data;
    }
}
