import {DotRezUserSession} from "./dot-rez-user.session";
import {IServiceFactory} from "../../../service-factory.interface";
import {IPersistedBookingHistory} from "../../../booking-history/models/persisted-booking-history.interface";
import {
    IDotRezBookingByCustomerNumber,
    IDotRezBookingByPassenger
} from "../../data-contracts/user/find-bookings.data-contracts";
import {IDotRezGraphQLQuery} from "../../graph-ql/dot-rez-graph-ql-query.interface";
import {IDotRezDesignator} from "../../data-contracts/common/designator";
import {searchCurrentUserBookingsQuery} from "../../graph-ql/queries/search-current-user-bookings.query";

type DesignatorWithNumberOfStops = {designator: IDotRezDesignator, numberOfStops: number};


export class BookingsReader {
    constructor(private readonly session: DotRezUserSession) {

    }

    private get services(): IServiceFactory {
        return this.session.services;
    }

    private _getBookingsWithDistinctSegments(bookings: IDotRezBookingByPassenger[]): IDotRezBookingByPassenger[] {
        const result: IDotRezBookingByPassenger[] = [];
        for(let b of bookings) {
            let distinctSegments = b.segments.distinct(s => s.identifier.identifier + s.identifier.carrierCode, s => s);
            distinctSegments = distinctSegments.sort((s1, s2) => {
                const d1 = this.services.time.parseIsoDate(s1.designator.departure);
                const d2 = this.services.time.parseIsoDate(s2.designator.departure);
                return d1.getTime() - d2.getTime();
            });

            result.push({
                ...b,
                segments: distinctSegments
            });
        }

        return result;
    }


    private _createBookingHistoryRecord(booking: IDotRezBookingByPassenger,
                                        trip: IDotRezBookingByCustomerNumber): IPersistedBookingHistory | null {

        const departureJourneyDesignator = this._getDepartureJourneyDesignator(booking, trip);
        if(!departureJourneyDesignator) {
            return null;
        }

        const returnJourneyDesignator = this._getReturnJourneyDesignator(booking, trip);

        const historyRecord: IPersistedBookingHistory = {
            environmentType: this.services.configuration.currentEnvironmentType,
            bookingKey: trip.bookingKey,
            recordLocator: trip.recordLocator,
            firstName: booking.firstName,
            lastName: booking.lastName,
            origin: trip.origin,
            destination: trip.destination,
            outbound: {
                departure: departureJourneyDesignator.designator.departure,
                arrival: departureJourneyDesignator.designator.arrival,
                stops: departureJourneyDesignator.numberOfStops
            },
            originName: this.services.stations.tryGetStation(trip.origin)?.stationName || "",
            destinationName: this.services.stations.tryGetStation(trip.destination)?.stationName || ""
        }

        if(returnJourneyDesignator) {
            historyRecord.inbound = {
                departure: returnJourneyDesignator.designator.departure,
                arrival: returnJourneyDesignator.designator.arrival,
                stops: returnJourneyDesignator.numberOfStops
            }
        }

        return historyRecord;
    }

    private _findJourneyDesignator(booking: IDotRezBookingByPassenger, origin: string, destination: string): DesignatorWithNumberOfStops | null  {
        const firstSegmentIndex = booking.segments.findIndex(s => s.designator.origin === origin);

        if(firstSegmentIndex < 0) {
            return null;
        }

        const lastSegmentIndex = booking.segments.findIndex(s => s.designator.destination === destination);

        if(lastSegmentIndex < 0) {
            return null;
        }

        return {
            numberOfStops: lastSegmentIndex - firstSegmentIndex,
            designator: {
                origin: origin,
                destination: destination,
                departure: booking.segments[firstSegmentIndex].designator.departure,
                arrival: booking.segments[lastSegmentIndex].designator.arrival,
            }
        };
    }

    private _getDepartureJourneyDesignator(booking: IDotRezBookingByPassenger,
                                           trip: IDotRezBookingByCustomerNumber): DesignatorWithNumberOfStops | null  {

        return this._findJourneyDesignator(booking, trip.origin, trip.destination);
    }

    private _getReturnJourneyDesignator(booking: IDotRezBookingByPassenger,
                                        trip: IDotRezBookingByCustomerNumber): DesignatorWithNumberOfStops | null  {

        return this._findJourneyDesignator(booking, trip.destination, trip.origin);
    }

    private async _findCurrentUserBookingsByCustomerNumber(): Promise<Record<string, IDotRezBookingByCustomerNumber>> {
        const bookingsByCustomerNumber = await this.services.airlineWebapi.getBookingsByCustomerNumber(this.session.token);
        return bookingsByCustomerNumber.bookings.toDictionary(b => b.recordLocator);
    }

    private _createBookingsGraphQLQuery(): IDotRezGraphQLQuery {
        const time = this.services.time;
        const currentDate = time.currentDate;
        // user/bookingsByPassenger API limits the search to 731 days
        const startDate = time.formatYYY_MM_DD(time.addDays(currentDate, -180)); // ~6 months in the past
        const endDate = time.formatUtc(time.addDays(currentDate, 550)); // ~1 year and a half in the future
        return searchCurrentUserBookingsQuery(startDate, endDate);
    }

    private async _executeBookingSearchGraphQLQuery(tripsByRecordLocator: Record<string, IDotRezBookingByCustomerNumber>): Promise<IPersistedBookingHistory[]> {
        const response = await this.session.executeGraphQLQuery<{userBookingsByPassenger: IDotRezBookingByPassenger[]}>(this._createBookingsGraphQLQuery());
        const bookings = this._getBookingsWithDistinctSegments(response.data.userBookingsByPassenger || []);
        return this._createBookingHistoryRecords(tripsByRecordLocator, bookings);
    }

    private _createBookingHistoryRecords(tripsByRecordLocator: Record<string, IDotRezBookingByCustomerNumber>,
                                         bookingsWithSegments: IDotRezBookingByPassenger[]): IPersistedBookingHistory[] {
        const result: IPersistedBookingHistory[] = [];
        for(let booking of bookingsWithSegments) {
            const trip = tripsByRecordLocator[booking.recordLocator];
            if(trip) {
                const historyRecord = this._createBookingHistoryRecord(booking, trip);
                if(historyRecord) {
                    result.push(historyRecord);
                }
            }
        }

        return result;
    }

    async getBookings(): Promise<IPersistedBookingHistory[]> {
        const tripsByRecordLocator = await this._findCurrentUserBookingsByCustomerNumber();
        //we make separate calls for future and past bookings because there is a limit of 731 days (or something like that) for bookingsByPassenger API
        return await this._executeBookingSearchGraphQLQuery(tripsByRecordLocator);
    }
}
