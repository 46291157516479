import {IServiceFactory} from "../../service-factory.interface";
import {FlightDesignatorModel} from "../../booking/models/designator/flight-designator.model";
import {IDotRezAvailableLeg} from "../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";

export class AvailabilityLegModel {
    constructor(private readonly services: IServiceFactory, private readonly _leg: IDotRezAvailableLeg) {
    }

    get designator(): FlightDesignatorModel {
        return new FlightDesignatorModel(
            this.services,
            this.services.stations.getStation(this._leg.designator.origin),
            this.services.stations.getStation(this._leg.designator.destination),
            this._leg.designator.departure,
            this._leg.designator.arrival,
            this._leg.legInfo.departureTimeUtc,
            this._leg.legInfo.arrivalTimeUtc,
            [],
            this._leg.legInfo.operatingCarrier);
    }
}
