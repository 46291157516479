import React from 'react';
import {ISeatsRowViewModel} from "../../../../services/booking/models/seat-maps/seats-row-view-model.interface";
import {observer} from "mobx-react";
import {IonCol, IonRow} from "@ionic/react";
import styled, {css} from "styled-components";
import {SeatsComponent} from "./seat-maps.component";

const SeatRowBox = styled(IonRow) <{ isEmergencyRow: boolean }>`
    ${props => renderEmergencyRowMarkers(props.isEmergencyRow)}
`


const RowNumberBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: ${props => props.theme.fontSize.medium};
`

function renderEmergencyRowMarkers(isEmergencyRow: boolean) {
    if (!isEmergencyRow) {
        return null;
    }

    return css`
          position: relative;
          &:before {
              content: '';
              position: absolute;
              left: -15px;
              top: calc(50% - 19px);
              height: 38px;
              width: 8px;
              background-color: ${props => props.theme.colors.medium};
              border-top-right-radius: 34px;
            border-bottom-right-radius: 34px;
            }
          &:after {
            content: '';
            position: absolute;
            right: -15px;
            top: calc(50% - 19px);
            height: 38px;
            width: 8px;
            background-color: ${props => props.theme.colors.medium};
            border-top-left-radius: 34px;
            border-bottom-left-radius: 34px
          }
        `
}


interface SeatRowComponentProps {
    row: ISeatsRowViewModel;
}


export const SeatsRowComponent: React.FC<SeatRowComponentProps> = observer((props) => {

    return (
        <SeatRowBox isEmergencyRow={props.row.isMiddleEmergencyRow}>
            <SeatsComponent seats={props.row.leftSeats} />
            <IonCol size="2" className="ion-align-self-center">
                <RowNumberBox id={`ROW_NUMBER_${props.row.rowNumber.toString()}`}>
                    {props.row.rowNumber}
                </RowNumberBox>
            </IonCol>
            <SeatsComponent seats={props.row.rightSeats} />
        </SeatRowBox>
    )
});

