import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {SellBundleMutation} from "./sell-bundle.mutation";
import {BundleModel} from "../../bundle/bundle.model";

export class SellReturnJourneyBundleMutation extends SellBundleMutation {

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {

        if (!this.returnJourney) {
            throw new Error('You must first sell return journey before selling a bundle for it');
        }

        const ssrKeysToRemove = this.returnJourney.getSsrKeysToRemoveOnBundleChange(this.bundleToSell as BundleModel);

        await this.session.sellBundle([this.returnJourney.journeyKey], ssrKeysToRemove, {
            bundleCode: this.bundleToSell.bundleCode
        });

        return await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();
    }

    async onAfterBookingSessionDataUpdated(): Promise<void> {
        await super.onAfterBookingSessionDataUpdated();
        this.services.analytics.flightSelectionEvents.logAddToCartForReturn(this.booking);
    }
}
