import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../../../../../components/modal/content/standard-dialog-content.component";
import {BookingContactFieldsComponent} from "../../../../booking-flow/contact/components/booking-contact-fields.component";
import {StandardDialogFooterComponent} from "../../../../../components/modal/footer/standard-dialog-footer.component";
import {ManageMyBookingApplyChangesButtonComponent} from "../manage-my-booking-apply-changes-button.component";

export const ManageMyBookingContactDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    const contact = services.booking.current.contact;
    const onChangeContact = async () => {
        if(contact.hasChanges()) {
            if(contact.activateErrorsValidation().length > 0) {
                return;
            }
            props.dialogHandler.accept();
        } else{
            props.dialogHandler.reject();
        }
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler} title={services.language.translate('Contact Details')}/>
            <StandardDialogContentComponent>
                <BookingContactFieldsComponent isInHeadlessMode={false} />
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ManageMyBookingApplyChangesButtonComponent onClick={onChangeContact} disabled={!contact.hasChanges()}/>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
