import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PaymentCreditCardIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.74} sizeIsHeight {...props} viewBox="0 0 80.5 58" ratio={80.5 / 58}>
            <g fill={props.color ? props.color : "currentColor"} transform="translate(-31.5 -197.9)">
                <path d="M105.3,255.9H38.2a6.7,6.7,0,0,1-6.7-6.7V204.6a6.7,6.7,0,0,1,6.7-6.7h67.1a6.7,6.7,0,0,1,6.7,6.7v44.6A6.7,6.7,0,0,1,105.3,255.9Z" />
                <rect width="16.8" height="6" transform="translate(35.6 237)" fill="#fff" />
                <rect width="16.8" height="6" transform="translate(54.1 237)" fill="#fff" /><rect width="16.8" height="6" transform="translate(72.6 237)" fill="#fff" />
                <rect width="16.8" height="6" transform="translate(91.2 237)" fill="#fff" /><rect width="36" height="2" transform="translate(72 246)" fill="#fff" />
                <circle cx="7.5" cy="7.5" r="7.5" transform="translate(89 206)" fill="#fff" />
            </g>
        </SvgIcon>
    )
}