import { observer } from "mobx-react";
import React from "react";
import { StandardDialogHeaderComponent } from "../../../../components/modal/header/standard-dialog-header.component";
import { StandardDialogPageComponent } from "../../../../components/modal/page/standard-dialog-page.component";
import { useServices } from "../../../../hooks/use-services.hook";
import { IDialogConfirmationHandler } from "../../../../services/dialog/dialog.service.interface";
import { SeatMapsComponent } from "../components/seat-maps.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../../../../components/continue-button/continue-button.component";
import styled from "styled-components";

const FooterContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ContinueButton = styled(ContinueButtonComponent)`
    min-width: 250px;
`

interface SeatSelectionDialogComponentProps{
    dialogHandler: IDialogConfirmationHandler;
}
export const SeatSelectionDialogComponent: React.FC<SeatSelectionDialogComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Seat selection')}
                                           dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <SeatMapsComponent />
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <FooterContentBox>
                    <ContinueButton onClick={() => props.dialogHandler.accept()} fullWidth={services.mediaQuery.sAndBelow}/>
                </FooterContentBox>

            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});

