import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {CountryPickerComponent} from "../../../../components/country-picker/country-picker.component";
import {PersonNameFieldEditorComponent} from "../../../../components/forms/person/person-name-field-editor.component";
import {PhoneFieldEditorComponent} from "../../../../components/forms/contact/phone-field-editor.component";
import {EmailFieldEditorComponent} from "../../../../components/forms/contact/email-field-editor.component";
import styled from "styled-components";


const ContactFieldsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large};
`

interface BookingContactFieldsComponentProps {
    isInHeadlessMode: boolean;
}
export const BookingContactFieldsComponent: React.FC<BookingContactFieldsComponentProps> = observer((props) => {
    const services = useServices();

    const fields = services.booking.current.contact?.fields;
    if(!fields) {
        return null;
    }

    return (
        <ContactFieldsContainerBox className={'tst-booking-contact-fields'}>
            {
                !props.isInHeadlessMode && (
                    <>
                        <PersonNameFieldEditorComponent key={fields.firstName.fieldName}
                                                    field={fields.firstName} className={'tst-contact-first-name'}/>
                        <PersonNameFieldEditorComponent key={fields.lastName.fieldName}
                                                    field={fields.lastName} className={'tst-contact-last-name'}/>
                    </>
                )

            }

            <EmailFieldEditorComponent key={fields.emailAddress.fieldName} field={fields.emailAddress} className={'tst-contact-email'}/>

            <PhoneFieldEditorComponent key={fields.phoneNumber.fieldName}
                                        field={fields.phoneNumber} className={'tst-contact-phone'}/>
            <CountryPickerComponent key={fields.countryCode.fieldName}
                                    field={fields.countryCode} className={'tst-contact-country'}/>


        </ContactFieldsContainerBox>
    );
});
