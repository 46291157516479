import {ILanguageService} from "../../../services/language/language.service.interface";
import {IServiceFactory} from "../../../services/service-factory.interface";
import {makeObservable, observable, runInAction} from "mobx";

export function translateIncludedBags(language: ILanguageService, numberOfIncludedBags: number): string {
    if(numberOfIncludedBags === 1) {
        return language.translate('ONE BAG INCLUDED');
    } else {
        return language.translationFor('{numberOfBags} BAGS INCLUDED').withParams({
            numberOfBags: numberOfIncludedBags
        });
    }
}

export class BagsSelectionUISynchronizer {
    constructor(private readonly services: IServiceFactory) {
        this._currentPassengerKey = this.services.booking.current.passengers[0].passengerKey;

        makeObservable<this, '_currentPassengerKey' | '_currentPassengerIsExpanded'>(this, {
            _currentPassengerKey: observable.ref,
            _currentPassengerIsExpanded: observable.ref
        });
    }

    private _currentPassengerKey: string;

    get currentPassengerKey(): string {
        return this._currentPassengerKey;
    }

    set currentPassengerKey(value: string) {
        runInAction(() => this._currentPassengerKey = value);
    }

    private _currentPassengerIsExpanded: boolean = true;
    get currentPassengerIsExpanded(): boolean {
        return this._currentPassengerIsExpanded;
    }

    set currentPassengerIsExpanded(value: boolean) {
        runInAction(() => this._currentPassengerIsExpanded = value);
    }
}
