import React from "react";
import {observer} from "mobx-react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableString} from "../../../types/nullable-types";
import {FieldViewComponent} from "../field-view.component";
import {useServices} from "../../../hooks/use-services.hook";

interface CountryFieldViewComponentProps {
    field: IFormField<NullableString>;
}
export const CountryFieldViewComponent: React.FC<CountryFieldViewComponentProps> = observer((props) => {
    const services = useServices();
    let countryName: NullableString = null;
    if(props.field.value) {
        const country = services.country.tryGetCountry(props.field.value);
        if(country) {
            countryName = country.name;
        }
    }
    return (
        <FieldViewComponent label={props.field.fieldName} value={countryName}/>
    )
});
