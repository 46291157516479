import {IFieldValidator} from "../field-validator.interface";
import {IServiceFactory} from "../../../services/service-factory.interface";
import {NullableString} from "../../../types/nullable-types";
import {Check} from "../../../types/type-checking";

export class BirthdateValidator implements IFieldValidator {
    constructor(private readonly services: IServiceFactory) {
    }

    validate(fieldValue: any): NullableString {
        if(!fieldValue) {
            return null;
        }

        if(!Check.isDate(fieldValue)) {
            return this.services.language.translate('Incorrect birthdate');
        }

        const year = fieldValue.getFullYear();
        const currentYear = this.services.time.currentDate.getFullYear();

        if(year < currentYear - 120 || year > currentYear) {
            return this.services.language.translate('Incorrect birthdate year');
        }

        return null;
    }

}
