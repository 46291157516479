import {IUserModel} from "../user-model.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {IDotRezUserSession} from "../../../dot-rez-api/session/user-session/dot-rez-user.session.interface";
import {DotRezUserSession} from "../../../dot-rez-api/session/user-session/dot-rez-user.session";
import {IReactionDisposer, makeObservable, observable, runInAction} from "mobx";
import {IDotRezBookingSession} from "../../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {LoginAndRegistrationHandler} from "../login-and-registration-handler/login-and-registration-handler";
import {ILoginOptions} from "../../user.service.interface";
import {AnonymousUserProfileModel} from "./profile/anonymous-user-profile.model";
import {LoginAndRegistrationDialogTabEnum} from "../login-and-registration-dialog-tab.enum";
import {Check} from "../../../../types/type-checking";
import {IDialogConfirmationHandler} from "../../../dialog/dialog.service.interface";

export class AnonymousUserModel implements IUserModel {
    constructor(public readonly services: IServiceFactory) {
        this._getSessionPromise = this._createUserSession();
        makeObservable<this, '_getSessionPromise'>(this, {
            _getSessionPromise: observable.ref
        });

        this.profile = new AnonymousUserProfileModel(this);
    }

    private _reactions: IReactionDisposer[] = [];

    get isAuthorized(): boolean {
        return false;
    }

    readonly profile: AnonymousUserProfileModel;

    private _getSessionPromise: Promise<IDotRezUserSession>;
    getSession(): Promise<IDotRezUserSession> {
        return this._getSessionPromise;
    }


    private _registrationInstructionsDialogHandler: IDialogConfirmationHandler | null = null;

    registrationConfirmed(): void {
        if(this._registrationInstructionsDialogHandler) {
            this._registrationInstructionsDialogHandler.accept();
        }
    }

    async login(options: ILoginOptions): Promise<IUserModel> {
        const loginAndRegistrationHandler = new LoginAndRegistrationHandler(this.services);

        await this.services.dialogFactory.showLoginAndRegistration({
            defaultTab: options.defaultTab,
            hideRegistrationTab: Check.isNullOrUndefined(options.hideRegistrationTab) ? false : options.hideRegistrationTab,
            loginAndRegistrationHandler: loginAndRegistrationHandler
        });

        if(loginAndRegistrationHandler.isRegistrationSuccessful) {
            await this.services.dialogFactory.showRegistrationInstructions({
                captureDialogHandler: dialogHandler => this._registrationInstructionsDialogHandler = dialogHandler
            });

            this._registrationInstructionsDialogHandler = null;
            return await this.login({
                hideRegistrationTab: true,
                defaultTab: LoginAndRegistrationDialogTabEnum.Login
            });
        } else if(loginAndRegistrationHandler.authorizedUser) {
            const loginForm = loginAndRegistrationHandler.loginFormFields;

            try {
                await this.services.booking.upgradeCurrentBookingSessionToAuthorizedUser(loginForm.userName.value!, loginForm.password.value!);
            } catch (err) {
                this.services.logger.error('Failed to upgrade current booking session to authorized user', err);
            }

            return loginAndRegistrationHandler.authorizedUser;
        }

        return this;

    }

    async logout(): Promise<IUserModel> {
        return this;
    }


    private async _createDotRezToken(): Promise<string> {
        return await this.services.dotRezApi.createAnonymousToken();
    }

    private async _createUserSession(): Promise<IDotRezUserSession> {
        const token = await this._createDotRezToken();
        return new DotRezUserSession(token, this.services);
    }

    reCreateSession(): Promise<IDotRezUserSession> {
        const promise = this._createUserSession();
        runInAction(() => {
            this._getSessionPromise = promise;
        });

        return promise;
    }

    async createBookingSession(): Promise<IDotRezBookingSession> {
        const token = await this._createDotRezToken();
        return this.services.dotRezApi.createBookingSession(token);
    }

    async createFlightSearchSession(): Promise<IDotRezBookingSession> {
        const token = await this._createDotRezToken();
        return this.services.dotRezApi.createFlightSearchSession(token);
    }
    async createTransientBookingSession(): Promise<IDotRezBookingSession> {
        const token = await this._createDotRezToken();
        return this.services.dotRezApi.createTransientBookingSession(token);
    }

    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }
}
