import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PlaneHorizontalFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.4} sizeIsHeight {...props} viewBox="0 0 14 12" ratio={14/12}>
            <g fill={props.color? props.color: "currentColor"}><path d="M11.7632 5.44417L9.4742 5.37716L5.44056 1.15829C4.54171 0.216302 3.63387 1.44736 4.21566 2.35923L6.22816 5.28033L2.18936 5.16663L1.65332 4.57456C0.599736 3.38711 0.513097 4.6178 0.501092 5.53923L0.470516 6.42565C0.43658 7.36709 1.42658 7.41301 1.92099 7.44202L6.12141 7.56695L3.92446 10.3234C3.27569 11.1984 4.09232 12.4818 5.06393 11.596L9.34792 7.67098L11.8495 7.74839C14.9339 7.83399 13.3648 5.49149 11.7632 5.44417Z"/></g>
        </SvgIcon>
    )
}
