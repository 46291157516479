import {FormModel} from "../../../../models/forms/form.model";
import {IPersonFormFields} from "./person-form-fields.interface";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {IDotRezPerson} from "../../../dot-rez-api/data-contracts/user/person.data-contracts";
import {NullableDate, NullableString} from "../../../../types/nullable-types";
import {IPersonViewModel} from "./person-view-model.interface";
import {IPersonTravelDocumentViewModel} from "./person-travel-document-view-model.interface";
import {Check} from "../../../../types/type-checking";
import {BirthdateValidator} from "../../../../models/forms/field-validators/birthdate.validator";

const MAX_TRAVEL_DOCUMENTS_COUNT = 4;
export abstract class PersonModel extends FormModel<IPersonFormFields> implements IPersonViewModel {

    abstract get personData(): IDotRezPerson;
    protected abstract get isPhoneNumberRequired(): boolean;
    protected abstract _createNewTravelDocument(): IPersonTravelDocumentViewModel;
    protected abstract _getPersonDetailsNotCompletedErrorMessage(): string;
    abstract get travelDocuments(): IPersonTravelDocumentViewModel[];

    protected _createFields(): FormFields<IPersonFormFields> {
        const language = this.services.language;

        return {
            firstName: this._createFirstNameField({
                initialValue: () => this.personData.name.first
            }),
            lastName: this._createLastNameField({
                initialValue: () => this.personData.name.last
            }),
            dateOfBirth: this._createField<NullableDate>({
                fieldName: () => language.translate('Date of birth'),
                initialValue: () => this.personData.details.dateOfBirth ? this.services.time.parseIsoDate(this.personData.details.dateOfBirth) : null,
                validators: [
                    new BirthdateValidator(this.services)
                ]
            }),
            gender: this._createGenderField({
                //When a person is created in dotREZ without specifying the gender then that person gets by default Male as gender
                //We don't want to use the default value Male for any person.
                //So here if the person has dateOfBirth filled in it means that also gender was filled in by the user.
                //If the dateOfBirth is missing it means that this.personData.details.gender got the default Male value and we want in this case the gender to be null
                initialValue: () => this.personData.details.dateOfBirth ? this.personData.details.gender : null
            }),
            nationality: this._createField<string>({
                fieldName: () => language.translate('Citizenship'),
                initialValue: () => this.personData.details.nationality,
                maxLength: 2
            }),
            phoneNumber: this._createPhoneField({
                initialValue: () => this.personData.phoneNumbers[0]?.number,
                isRequired: this.isPhoneNumberRequired
            })
        };
    }


    get customerNumber(): string {
        return this.personData.customerNumber;
    }

    get emailAddress(): string {
        return this.personData.emailAddresses[0]?.email || "";
    }

    getFullName(): string {
        return `${this.fields.firstName.value} ${this.fields.lastName.value}`;
    }

    getInitials(): string {
        const firstName = this.fields.firstName.value || "?";
        const lastName = this.fields.lastName.value || "?";

        return firstName[0].toUpperCase() + lastName[0].toUpperCase();
    }

    get title(): NullableString {
        if(Check.isUndefined(this.personData.name.title)) {
            return null;
        }
        return this.personData.name.title;
    }

    async addTravelDocument(): Promise<void> {
        if(this.travelDocuments.length >= MAX_TRAVEL_DOCUMENTS_COUNT) {
            this.services.alert.showError(this.services.language.translationFor('Maximum number of allowed documents is {maxNumberOfDocuments}. You have reached this number.')
                .withParams({maxNumberOfDocuments: MAX_TRAVEL_DOCUMENTS_COUNT}))
            return;
        }
        if(this.hasErrors()) {
            this.services.alert.showError(this._getPersonDetailsNotCompletedErrorMessage())
            return;
        }
        const travelDocument = this._createNewTravelDocument();
        await travelDocument.edit();
    }
}
