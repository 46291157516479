import {ISeatFeeModel} from "./seat-fee-model.interface";
import {ISsrType} from "../../../../../ssr-types/ssr-types.service.interface";
import {IServiceFactory} from "../../../../../service-factory.interface";
import {FeeTypeEnum} from "../../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {NullableNumber, NullableString} from "../../../../../../types/nullable-types";
import {Price} from "../../../../../currency/price";
import {IPassengerSegmentShoppingCartAdapter} from "../../shopping-cart/passenger-segment-shopping-cart-adapter.interface";
import {ShoppingCartFeeBaseModel} from "../shopping-cart-fee-base.model";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../../../ssr-types/ssrs-preferred-orders-in-shopping-cart";

export class SeatFeeModel extends ShoppingCartFeeBaseModel implements ISeatFeeModel {
    constructor(public readonly seatNumber: string,
                protected readonly passengerSegment: IPassengerSegmentShoppingCartAdapter,
                public readonly feeCode: NullableString,
                public readonly createdDate: Date,
                public readonly initialPrice: Price | null,
                public readonly currentPrice: Price) {
        super();
    }


    protected _isPurchasedOnCurrentSession(): boolean {
        if(super._isPurchasedOnCurrentSession()) {
            return true;
        }

        return this.seatNumber !== this.passengerSegment.getInitialSeatNumber();

    }


    get quantity(): number {
        return 1;
    }

    get seatKey(): NullableString {
        return this.passengerSegment.assignedSeat?.seatKey || null;
    }

    get rowNumber(): NullableNumber {
        return this.passengerSegment.assignedSeat?.rowNumber || null;
    }

    get hasExtraLegRoom(): boolean {
        return this.passengerSegment.assignedSeat?.hasExtraLegroom ?? false;
    }

    readonly ssrType: ISsrType | null = null;

    readonly ssrNumber = 0;

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SEATS;
    }

    protected get services(): IServiceFactory {
        return this.passengerSegment.services;
    }

    get feeGroupKey(): string {
        return `${this.flightReference}_${this.seatNumber}_${this.initialPrice?.toString()}_${this.currentPrice.toString()}`;
    }

    get feeType(): FeeTypeEnum {
        return FeeTypeEnum.SeatFee;
    }

    get description(): string {
        return this.services.language.translationFor('Seat {seatNumber}').withParams({seatNumber: this.seatNumber});
    }

    get details(): NullableString {
        return null;
    }

    get flightReference(): string {
        return this.passengerSegment.flightReference;
    }

    get shouldBeDisplayedInShoppingCart(): boolean {
        return true;
    }

}
