import React from 'react';
import {observer} from "mobx-react-lite";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {useServices} from "../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../continue-button/continue-button.component";
import styled from "styled-components";
import {ExclamationTriangle} from "../../assets/svg/exclamation-triangle.icon";

const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.xxLarge};
    gap: ${props => props.theme.spacing.large};
    width: 100%;
    height: 100%;
`

const MessageBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    line-height: 1.1;
`


interface ErrorMessageDialogComponentProps {
    message: string;
    dialogHandler: IDialogConfirmationHandler;
}

export const ErrorMessageDialogComponent: React.FC<ErrorMessageDialogComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler} title={services.language.translate('Error')}/>
            <StandardDialogContentComponent>
                <DialogContentBox>
                    <ExclamationTriangle size={4} color={services.theme.currentTheme.colors.danger}/>
                    <MessageBox>
                        {props.message}
                    </MessageBox>
                </DialogContentBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ContinueButtonComponent onClick={() => props.dialogHandler.accept()} fullWidth={true}/>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
})
