import {FormModel} from "../../../../models/forms/form.model";
import {IRegistrationFormFields} from "./registration-form-fields.interface";
import {FormFields} from "../../../../models/forms/form-field.interface";

export class RegistrationFormModel extends FormModel<IRegistrationFormFields> {
    protected _createFields(): FormFields<IRegistrationFormFields> {
        const language = this.services.language;
        return {
            firstName: this._createFirstNameField(),
            lastName: this._createLastNameField(),
            emailAddress: this._createEmailField(),
            password: this._createPasswordField(),
            dateOfBirth: this._createField<Date>({
                fieldName: () => language.translate('Date of birth')
            }),
            gender: this._createGenderField(),
            acceptsPromotionalMessages: this._createField<boolean>({
                fieldName: () => language.translate(`I want to receive emails and notifications about Aeroitalia offers and products`),
                defaultValue: false
            }),
            acceptsTermsAndConditions: this._createField<boolean>({
                fieldName: () => "",
                defaultValue: false,
                validate: (field) => {
                    if(field.value !== true) {
                        return this.services.language.translate('You must accept the terms and conditions before proceed with registration');
                    }
                    return null;
                }
            })
        };
    }
}
