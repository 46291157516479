import styled from "styled-components";

export const CardBox = styled.div`
    border: 1px solid ${props => props.theme.border.mainColor};
    border-radius: ${props => props.theme.border.defaultRadius};
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.lightTint};
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
    
`
