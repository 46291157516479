import {Check} from "../../../../types/type-checking";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../../dialog-factory/yes-no-dialog.enums";
import {BookingSessionStorageKeys} from "../../models/storage/booking-storage.interface";
import {CheckInFlowStepBase} from "../common/check-in-flow-step.base";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";

export class CheckInFlowFlightSelectionStep extends CheckInFlowStepBase {

    get title(): string {
        return this.services.language.translate('Flights');
    }

    get route() {
        return this.routes.flightSelection;
    }

    private get _previousSelectedJourneys(): string[] | null {
        return this.booking.storage.getJson<string[]>(BookingSessionStorageKeys.checkInPreviousSelectedJourneys) ?? null;
    }

    private set _previousSelectedJourneys(value: string[] | null) {
        if(value) {
            this.booking.storage.setJson(BookingSessionStorageKeys.checkInPreviousSelectedJourneys, value);
        } else {
            this.booking.storage.removeItem(BookingSessionStorageKeys.checkInPreviousSelectedJourneys);
        }
    }

    async validate(): Promise<ValidationResultEnum> {
        const journeys = this.booking.unfilteredJourneys;

        if(journeys.all(j => !j.isOnlineCheckInAllowed)) {
            if(journeys.length === 1) {
                this.services.alert.showError(this.services.language.translate('Online check in is not available for this flight'));
            } else {
                this.services.alert.showError(this.services.language.translate('Online check in is not available for these flights'));
            }
            return ValidationResultEnum.Failure;
        }

        if(journeys.all(j => !j.isOpenForCheckIn)) {
            this.services.alert.showError(this.services.language.translate('No flight is open for check-in. Please come back later'));
            return ValidationResultEnum.Failure;
        }

        if(journeys.all(j => !j.isJourneySelected)) {
            this.services.alert.showError(this.services.language.translate('Please select a flight'));
            return ValidationResultEnum.Failure;
        }

        return await this._discardChangesIfJourneysSelectionChanged();
    }

    async next(): Promise<void> {
        if(ValidationResultEnum.Success === await this.validate()) {
            this._activateStepByRoute(this.routes.seatsSelection);
        }
    }


    private async _discardChangesIfJourneysSelectionChanged(): Promise<ValidationResultEnum> {
        const journeys = this.booking.unfilteredJourneys;
        let currentSelectedJourneysKeys = journeys.filter(j => j.isJourneySelected).map(j => j.journeyKey);
        const booking = this.booking;

        if(!Check.isNullOrUndefined(this._previousSelectedJourneys)) {
            if(currentSelectedJourneysKeys.length !== this._previousSelectedJourneys.length && booking.hasPurchasesOnCurrentSession) {
                const dialogResult = await this.services.dialogFactory.showYesNoDialog({
                    title: this.services.language.translate('Warning'),
                    message: this.services.language.translate('Changing flights selection will result in discarding your purchases on the current session. Are you sure you want to do this ?'),
                    primaryButton: YesNoDialogPrimaryButton.PrimaryButtonNo
                });

                if(dialogResult === YesNoDialogResult.No) {
                    return ValidationResultEnum.Failure;
                }

                await this.services.loadingIndicator.execute({
                    action: async () => {
                        await booking.discardChanges();
                    }
                });

                //after we discard changes also the storage of the booking is reset so we need to put back the selection
                booking.unfilteredJourneys.forEach(j => {
                    j.isJourneySelected = currentSelectedJourneysKeys.includes(j.journeyKey);
                });
            }
        }

        this._previousSelectedJourneys = currentSelectedJourneysKeys;

        return ValidationResultEnum.Success;
    }
}
