import {IDotRezBookingPassenger} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {BookingSnapshotModel} from "../booking/booking-snapshot.model";
import {PassengerFeeSnapshotModel} from "../fees/passenger-fee-snapshot.model";
import {computed, makeObservable} from "mobx";
import {InfantSnapshotModel} from "../infant/infant-snapshot.model";
import {IPassengerType} from "../../../../passenger-types/passengers-types.service.interface";
import {IServiceFactory} from "../../../../service-factory.interface";
import {Price} from "../../../../currency/price";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {IFeeOwner} from "../../base-models/fees/fee-owner.interface";

export class PassengerSnapshotModel implements IFeeOwner {
    constructor(private readonly passengerDotRezData: IDotRezBookingPassenger,
                private readonly booking: BookingSnapshotModel) {

        if(passengerDotRezData.infant) {
            this.infant = new InfantSnapshotModel(passengerDotRezData.infant, booking);
        }

        makeObservable(this, {
            fees: computed
        });
    }

    get services(): IServiceFactory {
        return this.booking.services;
    }

    get passengerKey(): string {
        return this.passengerDotRezData.passengerKey;
    }

    getFullName(): string {
        if(!this.passengerDotRezData.name) {
            return '';
        }

        return `${this.passengerDotRezData.name.first} ${this.passengerDotRezData.name.last}`
    }

    readonly infant: InfantSnapshotModel | null = null;

    get fees(): PassengerFeeSnapshotModel[] {
        return this.passengerDotRezData.fees.map(f => new PassengerFeeSnapshotModel(f, this, this.booking));
    }

    createPrice(amount: number): Price {
        return this.booking.createPrice(amount);
    }

    get passengerDiscountCode(): string {
        return this.passengerDotRezData.discountCode;
    }

    get passengerType(): IPassengerType {
        return this.services.passengerTypes.getPassengerType(this.passengerDotRezData.passengerTypeCode);
    }

    getFlightTotalFees(flightReference: string): Price {
        return Price.sumAll(this.fees.filter(f => f.flightReference === flightReference).map(f => f.currentPrice), this.createPrice(0))
    }

    getTotalSsrFees(flightReference: string, ssrType: ISsrType) {
        return Price.sumAll(this.fees.filter(f => f.flightReference === flightReference && f.ssrType?.ssrCode === ssrType.ssrCode)
                                     .map(f => f.currentPrice),
                            this.createPrice(0));

    }


    /**
     * IFeeOwner.passengerTypeCode implementation
     */
    get passengerTypeCode(): string {
        return this.passengerType.code;
    }
}
