import React, {PropsWithChildren} from 'react';
import styled from "styled-components";
import {observer} from "mobx-react-lite";

const ContainerBoxWithShoppingCartBox = styled.div`
  padding: 0 10%;
  @media ${(props) => props.theme.mediaQuery.lAndBelow} {
    padding: 0 6%;
  }

  @media ${(props) => props.theme.mediaQuery.mAndBelow} {
    padding: 0 3%;
  }

  @media ${(props) => props.theme.mediaQuery.sAndBelow} {
    padding: 0;
  }
`

const ContainerWithoutShoppingCartBox = styled.div`
  padding: 0 25%;
  @media ${(props) => props.theme.mediaQuery.lAndBelow} {
    padding: 0 20%;
  }

  @media ${(props) => props.theme.mediaQuery.mAndBelow} {
    padding: 0 15%;
  }

  @media ${(props) => props.theme.mediaQuery.sAndBelow} {
    padding: 0;
  }
`

const ContainerWithoutShoppingWithSmallerMarginsCartBox = styled.div`
  padding: 0 15%;
  @media ${(props) => props.theme.mediaQuery.lAndBelow} {
    padding: 0 7%;
  }

  @media ${(props) => props.theme.mediaQuery.mAndBelow} {
    padding: 0 3%;
  }

  @media ${(props) => props.theme.mediaQuery.sAndBelow} {
    padding: 0;
  }
`

interface BookingPageContentCentralizationComponentProps extends PropsWithChildren {
    shoppingCartIsHidden: boolean;
    className?: string;
    useSmallerMarginsWhenShoppingCartIsHidden?: boolean;
}

export const BookingPageContentCentralizationComponent: React.FC<BookingPageContentCentralizationComponentProps> = observer((props) => {
    let Box = ContainerBoxWithShoppingCartBox;

    if(props.shoppingCartIsHidden) {
        if(props.useSmallerMarginsWhenShoppingCartIsHidden) {
            Box = ContainerWithoutShoppingWithSmallerMarginsCartBox;
        } else {
            Box = ContainerWithoutShoppingCartBox;
        }

    }

    return (
        <Box className={props.className}>
            {props.children}
        </Box>
    )
});

