import React from "react";
import {FlightSearchPage} from "../flight-search.page";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {FlightsDatesSelectionHeaderComponent} from "./components/flights-dates-selection-header.component";
import styled from "styled-components";
import {useIonViewDidEnter} from "@ionic/react";
import {FlightSearchPricingInformationDialogComponent} from "./components/flight-search-pricing-information-dialog.component";
import {DialogCloseButtonBehavior} from "../../../services/dialog/dialog-enums";
import {FlightDatesSelectionPageContent} from "./components/flight-dates-selection-page-content.component";
import {FlightsDatesSelector} from "../../../services/flight-search/dates-selection/flights-dates-selector";
import { InformationCircleIcon } from "../../../assets/svg/information-circle.icon";
import {ContinueButtonComponent} from "../../../components/continue-button/continue-button.component";
import {FlightSearchTitleComponent} from "../components/flight-search-title.component";
import {CalendarIcon} from "../../../assets/svg/calendar.icon";

const FooterContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const FooterLeftPanelBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`



const PricingInformationContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1;
    font-size: ${props => props.theme.fontSize.small};
  
    svg {
        margin-right: 4px;
    }
`

const FlightDatesSelectionPageFooter: React.FC<{flightsDatesSelector: FlightsDatesSelector}> = observer((props) => {
    const services = useServices();

    if(props.flightsDatesSelector.scheduleLoadingError || props.flightsDatesSelector.isLoadingSchedule) {
        return null;
    }

    const onClick = async () => {
        await services.flightSearch.steps.nextStep();
    }

    const showPricingInfo = async () =>  {
        await services.dialog.showSheetModalDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            breakpoints: [0.25],
            render: (dialogHandler) =>  <FlightSearchPricingInformationDialogComponent dialogHandler={dialogHandler}/>
        });
    }

    const renderPricingInformationBox = () => {
        return (
            <PricingInformationContainerBox onClick={showPricingInfo}>
                <InformationCircleIcon size={1.7}/>
                <div>{services.language.translate('Pricing information')}</div>
            </PricingInformationContainerBox>
        );
    }


   return (
       <FooterContentBox>
           <FooterLeftPanelBox>
               {renderPricingInformationBox()}
           </FooterLeftPanelBox>

           <ContinueButtonComponent onClick={onClick} className={'tst-search-continue'}/>
       </FooterContentBox>
   );
});

export const FlightSearchDatesSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const flightSearchController = services.flightSearch.searchController;
    const flightsDatesSelector =flightSearchController.datesSelector;


    let selectedDepartureDateElement: HTMLElement | null = null;

    const captureSelectedDepartureDateElementRef = (date: Date, element: HTMLElement | null) => {
        if(flightsDatesSelector.isDepartureDate(date)) {
            selectedDepartureDateElement = element;
        }
    }

    const getTitle = () => {
        if(flightSearchController.isOneWayDepartureTrip) {
            return services.language.translate('Flight date');
        } else {
            return services.language.translate('Flights dates');
        }
    }


    useIonViewDidEnter(() => {
        if(!services.navigator.routes.flightSearch.flightDates.isActive) {
            return;
        }

        if(selectedDepartureDateElement) {
            selectedDepartureDateElement.scrollIntoView({
                block: "center"
            });
        }
    });

    return (
        <FlightSearchPage title={<FlightSearchTitleComponent title={getTitle()} icon={<CalendarIcon/>}/>}
                          renderToolbar={() => <FlightsDatesSelectionHeaderComponent flightsDatesSelector={flightsDatesSelector}/>}
                          renderFooter={() => <FlightDatesSelectionPageFooter flightsDatesSelector={flightsDatesSelector}/>}>
            <FlightDatesSelectionPageContent captureSelectedDateElementRef={captureSelectedDepartureDateElementRef}
                                             flightsDatesSelector={flightsDatesSelector}
                                             useSwiper={false}/>
        </FlightSearchPage>
    );
});
