import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {OriginAndDestinationStationsComponent} from "../origin-and-destination-stations.component";
import {FlightDatesAvailabilityComponent} from "../availability/flight-dates-availability.component";

export const ReturnFlightSelectionExtraHeaderComponent: React.FC = observer(() => {
    const services = useServices();

    const availability = services.booking.availability;

    if(!availability.returnOrigin || !availability.returnDestination || !availability.returnDate) {
        return null;
    }

    const onDateChanged = async (date: Date) => {
        await availability.changeReturnDate(date);
    }

    return (
        <>
            <OriginAndDestinationStationsComponent origin={availability.returnOrigin}
                                                   destination={availability.returnDestination}/>
            <FlightDatesAvailabilityComponent flightSchedule={availability.returnFlightSchedule}
                                              currentScheduleSelectedDate={availability.returnDate}
                                              onDateSelected={onDateChanged}
                                              getLowFare={(date) => availability.getReturnLowFare(date)}/>
        </>
    );
});
