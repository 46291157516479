import {IBundleViewModel} from "./bundle-view-model.interface";
import {IBundleType} from "../../../bundle-types/bundle-type.interface";
import {Price} from "../../../currency/price";
import {BookingModel} from "../booking.model";
import {BundleIncludedSsrModel} from "./bundle-included-ssr.model";
import {BundlePriceModel} from "./bundle-price.model";
import {JourneyModel} from "../journey/journey.model";

export class BundleModel implements IBundleViewModel {
    constructor( public readonly bundleType: IBundleType,
                 private readonly journey: JourneyModel,
                 private readonly _prices: BundlePriceModel[]) {
    }

    get bundleCode(): string {
        return this.bundleType.code;
    }

    private get booking(): BookingModel {
        return this.journey.booking;
    }

    get isSelected(): boolean {
        if(!this.booking.departureJourney) {
            return false
        }
        if(this.booking.departureJourney.selectedBundle) {
            return this.bundleCode === this.booking.departureJourney.selectedBundle.bundleCode;
        } else {
            return false;//Boolean(this.bundleType.isDefaultBundle);
        }
    }

    get pricePerPassenger(): Price {
        //This will work as long as the bundle has the same price for all passenger types;
        return this.createPrice(Math.max(0, this._prices[0].price.amount - (this.journey.initialBundlePrice?.amount || 0)));
    }


    get includedSsrs(): BundleIncludedSsrModel[] {
        return this._prices[0].includedSsrs;
    }

    createPrice(amount: number): Price {
        return this.booking.createPrice(amount);
    }

    get hasSeatIncluded(): boolean {
        return this.includedSsrs.some(ssr => this.booking.services.ssrTypes.isSeat(ssr.ssrType));
    }
}




