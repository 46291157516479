import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {PassengerTravelDocumentComponent} from "./components/passenger-travel-document.component";
import {BookingFlowPage} from "../../booking-flow/booking-flow.page";
import {FormComponent, FormComponentType} from "../../../components/forms/form.component";

const CheckInPassengersDetailsPageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.extraLarge};
`

const CheckInPassengersDetailsPageContent: React.FC = observer(() => {
    const services = useServices();
    const passengers = services.booking.current.passengers.getAllPersonsInTheBooking();


    return (
        <FormComponent type={FormComponentType.Flat}>
            <CheckInPassengersDetailsPageContentBox>
                {passengers.map(p => <PassengerTravelDocumentComponent key={p.passengerKey} passenger={p}/>)}
            </CheckInPassengersDetailsPageContentBox>
        </FormComponent>
    );
});


export const CheckInPassengersDetailsPage: React.FC = observer(() => {
    const services = useServices();
    return (
        <BookingFlowPage title={services.language.translate('Passengers Details')}
                         renderContent={() => (<CheckInPassengersDetailsPageContent/>)}
                         showPromotionCode={false}
                         hideShoppingCart={true}>
        </BookingFlowPage>
    );
});
