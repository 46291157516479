import React from "react";
import {observer} from "mobx-react";
import {FormComponent} from "../../../components/forms/form.component";
import {PersonNameFieldEditorComponent} from "../../../components/forms/person/person-name-field-editor.component";
import {DateFieldEditorComponent} from "../../../components/forms/date-time/date-field-editor.component";
import {GenderFieldEditorComponent} from "../../../components/forms/gender/gender-field-editor.component";
import {CountryPickerComponent} from "../../../components/country-picker/country-picker.component";
import {ICompanionViewModel} from "../../../services/user/models/companion/companion-view-model.interface";
import {useServices} from "../../../hooks/use-services.hook";

interface CompanionEditorComponentProps {
    companion: ICompanionViewModel;
}

export const CompanionEditorComponent: React.FC<CompanionEditorComponentProps> = observer((props) => {
    const services = useServices();
    const time = services.time;
    const companion = props.companion;
    const maxDateOfBirth =  time.makeShortDate(time.currentDate);
    return (
        <FormComponent>
            <PersonNameFieldEditorComponent field={companion.fields.firstName}/>
            <PersonNameFieldEditorComponent field={companion.fields.lastName}/>
            <DateFieldEditorComponent field={companion.fields.dateOfBirth} maximumValue={maxDateOfBirth}/>
            <GenderFieldEditorComponent field={companion.fields.gender} useGenderAsNumber={true}/>
            <CountryPickerComponent field={companion.fields.nationality}/>
        </FormComponent>
    );
});
