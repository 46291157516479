import {IBundleType} from "./bundle-type.interface";
import {TimeSpan} from "../../types/time-span";
import {DefaultTheme} from "styled-components";
import {IServiceFactory} from "../service-factory.interface";

export class OldBundleType implements IBundleType {
    constructor(public readonly code: string,
                public readonly earliestCheckIn: TimeSpan,
                public readonly earliestCheckInWhenHasSeat: TimeSpan,
                private readonly services: IServiceFactory) {
    }

    get fullName(): string {
        return this.code;
    }
    get shortName(): string {
        return this.code
    }

    getBackgroundColor(theme: DefaultTheme): string {
        return theme.colors.primaryContrast;
    }

    getTextColor(theme: DefaultTheme): string {
        return theme.colors.primary;
    }

    get isBasicBundle(): boolean {
        return false;
    }
    get isDefaultBundle(): boolean {
        return false;
    }
    get prefix(): string {
        return "";
    }

    getUpgradeMessage(toBundle: IBundleType): string {
        return '';
    }

    appendVirtualSsrs(realSsrsIncludedInTheBundle: string[]): string[] {
        return realSsrsIncludedInTheBundle;
    }

    get requireBookingImpersonation(): boolean {
        return false;
    }

    get minTimeToChangeDateBeforeFlight(): TimeSpan {
        return TimeSpan.parse(this.services.configuration.data.minTimeToChangeDateBeforeFlight);
    }
}
