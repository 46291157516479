import {SegmentBaseModel} from "../segment/segment-base.model";
import {LegBaseModel} from "../leg/leg-base.model";
import {
    IDotRezJourney,
    IDotRezJourneyBreakdown
} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IServiceFactory} from "../../../../service-factory.interface";
import {FlightDesignatorModel} from "../../designator/flight-designator.model";
import {computed, makeObservable} from "mobx";
import {NullableString} from "../../../../../types/nullable-types";
import {IPassengerSsrTypesCount, ISsrTypeCount} from "../../ssrs/ssr-type-count.interface";
import {IPassengerJourneySeat} from "./pasenger-journey-seat.interface";
import {IBookingModel} from "../../booking-model.interface";
import {FeeTypeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {sumOfNonTaxesServiceCharges} from "../fees/service-charges.helpers";


export abstract class JourneyBaseModel<TLeg extends LegBaseModel, TSegment extends SegmentBaseModel<TLeg>> {

    constructor() {
        makeObservable(this, {
            designator: computed
        });
    }
    abstract get services(): IServiceFactory;
    abstract get segments(): TSegment[];
    abstract get journeyDotRezData(): IDotRezJourney;
    abstract get journeyDotRezBreakdown(): IDotRezJourneyBreakdown;
    abstract get booking(): IBookingModel;

    get designator(): FlightDesignatorModel {
        return FlightDesignatorModel.createJourneyDesignator(this.journeyDotRezData, this.services);
    }

    get standardDepartureTime(): Date {
        return this.segments[0].standardDepartureTime;
    }

    get isCanceled(): boolean {
        return this.segments.some(segment => segment.isCanceled);
    }

    get fareAmount(): number {
        const fare = this.journeyDotRezBreakdown.totalAmount + this.journeyDotRezBreakdown.totalTax;
        return Math.round(fare * 100) / 100; // because javascript is stupid and sometime the sum above sometimes returns xxx.yy99999999999
    }

    get totalDiscount(): number {
        return this.journeyDotRezBreakdown.totalDiscount;
    }

    get currentBundleCode(): NullableString {
        return this.journeyDotRezData.segments[0]?.passengerSegment[0]?.value?.bundleCode
    }

    get currentBundleAmount(): number {
        if(!this.currentBundleCode) {
            return 0;
        }

        const segmentsFlightsReferences = this.segments.map(s => s.flightReference);

        const bundleFee = this.booking.bookingData.passengers[0].value.fees.find(f => f.type === FeeTypeEnum.ServiceBundle && f.flightReference && segmentsFlightsReferences.includes(f.flightReference));

        if(!bundleFee) {
            return 0;
        }

        return sumOfNonTaxesServiceCharges(bundleFee.serviceCharges);
    }

    get isFutureJourney(): boolean {
        return this.designator.departureDate.getTime() > this.services.time.currentDate.getTime();
    }

    get hasCheckedInPassengers(): boolean {
        return this.segments.some(s => s.hasCheckedInPassengers);
    }

    getAllLegs(): TLeg[] {
        let legs: TLeg[] = [];
        this.segments.forEach(s => {
            legs = [
                ...legs,
                ...s.legs
            ];
        });

        return legs;
    }


    getPassengersSeats(): IPassengerJourneySeat[] {
        const result: IPassengerJourneySeat[] = [];
        this.journeyDotRezData.segments.forEach((segment, segmentIndex) => {
            segment.passengerSegment.forEach(passengerSegment => {
                const seats =  passengerSegment.value.seats || [];

                seats.forEach(seat => {
                    result.push({
                        passengerKey: passengerSegment.key,
                        seatNumber: seat.unitDesignator,
                        segmentIndex: segmentIndex
                    });
                });
            })
        });

        return result;
    }

    private _getJourneySsrsCount(includeBundle: boolean): Array<{segmentKey: string; passengerKey: string; ssrCode: string; count: number}> {
        const result: Array<{segmentKey: string; passengerKey: string; ssrCode: string; count: number}> = [];
        for(let segment of this.journeyDotRezData.segments) {
            for(let passengerSegment of segment.passengerSegment) {
                const ssrsGroupedByCode = (passengerSegment.value.ssrs || [])
                                            .filter(ssr => includeBundle || !ssr.inBundle)
                                            .groupByKey(ssr => ssr.ssrCode);

                Object.values(ssrsGroupedByCode).forEach(ssrCount => {
                    result.push({
                        segmentKey: segment.segmentKey,
                        passengerKey: passengerSegment.key,
                        ssrCode: ssrCount[0].ssrCode,
                        count: ssrCount.sum(s => s.count)
                    });
                });

            }
        }

        return result;
    }

    countPassengersSsrs(includeBundle: boolean): IPassengerSsrTypesCount[] {
        const result: IPassengerSsrTypesCount[] = [];

        // grouping ssrs by passenger key
        const ssrsByPassengerKey = this._getJourneySsrsCount(includeBundle).groupByKey(ssr => ssr.passengerKey);

        Object.keys(ssrsByPassengerKey).forEach(passengerKey => {
            // here we have all ssrs for this passengerKey
            const passengerSsrsCount: IPassengerSsrTypesCount = {
                passengerKey: passengerKey,
                ssrsCount: []
            };

            // grouping all the ssrs for this passengerKey by ssrCode
            const ssrsByCode = ssrsByPassengerKey[passengerKey].groupByKey(ssr => ssr.ssrCode);
            Object.values(ssrsByCode).forEach(ssrsPerSegments => {

                let ssrCount = 0;
                const ssrType = this.services.ssrTypes.getSsrType(ssrsPerSegments[0].ssrCode);
                // grouping by this ssr code to obtain counting for this ssr per each segment
                Object.values(ssrsPerSegments.groupByKey(ssr => ssr.segmentKey)).forEach(ssrPerSegmentCount => {
                    if(ssrType.shouldBeSoldPerSegment) {
                        // if the SSR is the kind that can be sold per segment like meals then we sum them
                        ssrCount += ssrPerSegmentCount.sum(ssr => ssr.count);
                    } else {
                        // otherwise we pick the maximum among all segments. This is because for example a bag is the same bag on all segment. We should not sum the same bag over all segment.
                        ssrCount = Math.max(ssrCount, ssrPerSegmentCount.sum(ssr => ssr.count));
                    }

                });
                passengerSsrsCount.ssrsCount.push({
                    count: ssrCount,
                    ssrType: this.services.ssrTypes.getSsrType(ssrsPerSegments[0].ssrCode)
                });
            });

            result.push(passengerSsrsCount);

        })

        return result;

    }

    countAllSsrs(): ISsrTypeCount[] {
        const result: Record<string, ISsrTypeCount> = {};
        for(let passengerSsrCount of this.countPassengersSsrs(true)) {
            for(let ssrCount of passengerSsrCount.ssrsCount) {
                if(!result[ssrCount.ssrType.ssrCode]) {
                    result[ssrCount.ssrType.ssrCode] = {
                        ssrType: ssrCount.ssrType,
                        count: 0
                    }
                }

                result[ssrCount.ssrType.ssrCode].count += ssrCount.count;
            }
        }

        return Object.values(result);
    }

}
