import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {IExtrasSectionRenderer} from "./extras-section-renderer.interface";
import {SeatsExtrasSectionRenderer} from "../sections/seats/seats.extras-section.renderer";
import {CabinBagExtrasSectionRenderer} from "../sections/cabin-bag/cabin-bag.extras-section.renderer";
import {CheckInBagsExtrasSectionRenderer} from "../sections/check-in-bags/check-in-bags.extras-section.renderer";
import {FlexExtrasSectionRenderer} from "../sections/flex/flex.extras-section.renderer";
import {InsuranceExtrasSectionRenderer} from "../sections/insurance/insurance.extras-section.renderer";
import {AirportCheckInExtrasSectionRenderer} from "../sections/airport-check-in/airport-check-in.extras-section.renderer";
import {EarlyCheckInExtrasSectionRenderer} from "../sections/early-check-in/early-check-in.extras-section.renderer";
import {BusTransferExtrasSectionRenderer} from "../sections/bus-transfer/bus-transfer.extras-section.renderer";
import {BusinessLoungeExtrasSectionRenderer} from "../sections/business-lounge/business-lounge.extras-section.renderer";
import {FastTrackExtrasSectionRenderer} from "../sections/fast-track/fast-track.extras-section.renderer";
import {PetsExtrasSectionRenderer} from "../sections/pets/pets.extras-section.renderer";
import {SpecialEquipmentExtrasSectionRenderer} from "../sections/special-equipment/special-equipment.extras-section.renderer";
import {SpecialAssistanceExtrasSectionRenderer} from "../sections/special-assistance/special-assistance.extras-section.renderer";
import {IncludedScbgExtrasSectionRenderer} from "../sections/included-scbg/included-scbg.extras-section.renderer";
import {IExtrasSectionsRenderersFactory} from "./extras-sections-renderers-factory.interface";

export class ExtrasSectionsRenderersFactory implements IExtrasSectionsRenderersFactory{
    constructor(private readonly booking: IBookingViewModel) {
        this._sections = [
            new SeatsExtrasSectionRenderer(booking),
            new CabinBagExtrasSectionRenderer(booking),
            new CheckInBagsExtrasSectionRenderer(booking),
            new FlexExtrasSectionRenderer(booking),
            new InsuranceExtrasSectionRenderer(booking),
            new AirportCheckInExtrasSectionRenderer(booking),
            new EarlyCheckInExtrasSectionRenderer(booking),
            new BusTransferExtrasSectionRenderer(booking),
            new BusinessLoungeExtrasSectionRenderer(booking),
            new FastTrackExtrasSectionRenderer(booking),
            new PetsExtrasSectionRenderer(booking),
            new SpecialEquipmentExtrasSectionRenderer(booking),
            new SpecialAssistanceExtrasSectionRenderer(booking),
            new IncludedScbgExtrasSectionRenderer(booking)
        ];
    }

    private readonly _sections: IExtrasSectionRenderer[];
    getExtrasSections(): IExtrasSectionRenderer[] {
        return this._sections;
    }
}

