import React from 'react';
import {Station} from "../../services/stations/station.service.interface";
import {Check} from "../../types/type-checking";
import { useServices } from '../../hooks/use-services.hook';
import {SearchbarComponent} from "../../basic-components/searchbar/searchbar.component";

interface StationsSearchComponentProps {
    stations: Station[];
    onSearch: (result: Station[]) => void;
    className?: string;
}

export const StationsSearchComponent: React.FC<StationsSearchComponentProps> = (props) => {
    const filterStations = (text: string | number | null | undefined) => {
        const textToSearch = (text?.toString() || '').trim().toLowerCase();
        if(Check.isEmpty(textToSearch)) {
            props.onSearch(props.stations);
        } else {
            props.onSearch(props.stations.filter(s => {
                if(0 <= s.stationName.toLowerCase().indexOf(textToSearch!)) {
                    return true;
                }

                if(0 <= s.stationCode.toLowerCase().indexOf(textToSearch!)) {
                    return true;
                }

                if(0 <= s.countryName.toLowerCase().indexOf(textToSearch!)) {
                    return true;
                }

                return false;
            }));
        }

    }

    const services = useServices();
    
    return (
        <SearchbarComponent className={props.className}
                            onChange={text => filterStations(text)}
                            placeholder={services.language.translate('Search by country or city')}/>
    )
};
