import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const ArrowsRightLeftIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.6} {...props} viewBox="0 0 16 16" ratio={1}>
            <g fill={props.color? props.color: "currentColor"}>
                <path fillRule="evenodd" d="M 1 4.5 C 1 4.224 1.224 4 1.5 4 L 13.293 4 L 10.146 0.854 C 9.873 0.581 9.998 0.116 10.37 0.016 C 10.543 -0.03 10.728 0.019 10.854 0.146 L 14.854 4.146 C 15.05 4.341 15.05 4.658 14.854 4.854 L 10.854 8.854 C 10.581 9.126 10.116 9.002 10.016 8.629 C 9.97 8.457 10.02 8.272 10.146 8.146 L 13.293 5 L 1.5 5 C 1.224 5 1 4.776 1 4.5 Z M 15 11.5 C 15 11.224 14.776 11 14.5 11 L 2.707 11 L 5.854 7.854 C 6.127 7.581 6.002 7.116 5.63 7.016 C 5.457 6.97 5.272 7.019 5.146 7.146 L 1.146 11.146 C 0.95 11.341 0.95 11.658 1.146 11.854 L 5.146 15.854 C 5.419 16.126 5.884 16.002 5.984 15.629 C 6.03 15.457 5.98 15.272 5.854 15.146 L 2.707 12 L 14.5 12 C 14.776 12 15 11.776 15 11.5 Z" transform="matrix(-1, 0, 0, -1, 16.000001, 15.999777)"/>
            </g>

        </SvgIcon>
    );
}
