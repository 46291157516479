import React from "react";
import {observer} from "mobx-react";
import {Station} from "../../../../services/stations/station.service.interface";
import styled from "styled-components";
import {PlaneHorizontalFilledIcon} from "../../../../assets/svg/plane-horizontal-filled.icon";
import {useServices} from "../../../../hooks/use-services.hook";

const OriginAndDestinationStationsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: ${props => props.theme.fontSize.xLarge};
    line-height: 1.1;
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: 0 ${props => props.theme.spacing.medium} ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
`

const StationDetailsBoxBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
`

const StationNameBox = styled.div`
  text-align: center;
`
/*
const StationCodeBox = styled.div`
  text-align: center;
`
 */

const StationComponent: React.FC<{station: Station; useMac: boolean}> = (props) => {
    return (
        <StationDetailsBoxBox>
            <StationNameBox>
                {props.useMac ? props.station.stationMacName : props.station.stationName}
            </StationNameBox>
            {
                /*
                <StationCodeBox>
                    {props.useMac ? props.station.stationMacCode : props.station.stationCode}
                </StationCodeBox>
                 */
            }
        </StationDetailsBoxBox>
    )
}

const PlaneBox = styled.div`
    display: flex;
    justify-content: center;
`


interface OriginAndDestinationStationsComponentProps {
    origin: Station;
    destination: Station;
}
export const OriginAndDestinationStationsComponent: React.FC<OriginAndDestinationStationsComponentProps> = observer((props) => {
    const services = useServices();
    if(!services.layout.isInSmallScreenView) {
        return null;
    }
    return (
        <OriginAndDestinationStationsBox>
            <StationComponent station={props.origin} useMac={true}/>

            <PlaneBox>
                <PlaneHorizontalFilledIcon size={1.6}/>
            </PlaneBox>

            <StationComponent station={props.destination} useMac={true}/>
        </OriginAndDestinationStationsBox>
    )
});
