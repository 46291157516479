import {ICountryService} from "./country.service.interface";
import {ServiceBase} from "../service-base";
import {ICountryConfiguration} from "../configuration/objects/country-configuration.interface";
import {IServiceFactory} from "../service-factory.interface";
import {makeObservable, observable, reaction} from "mobx";
import {Check} from "../../types/type-checking";

export class CountryService extends ServiceBase implements ICountryService {

    constructor(services: IServiceFactory) {
        super(services);
        makeObservable(this, {
            _countriesMap: observable.ref
        });

        reaction(() => this.services.configuration.data,
            (data) => {
                this._countriesMap = data.countries
                                     .sort((c1, c2) => {
                                         if(c1.code === this.services.configuration.defaultCountryCode) {
                                             return -1;
                                         } else if(c2.code === this.services.configuration.defaultCountryCode) {
                                             return 1;
                                         }
                                         return c1.name.localeCompare(c2.name);
                                     })
                                    .reduce((accumulator: Record<string, ICountryConfiguration>, current) => {
                    accumulator[current.code] = current;
                    return accumulator;
                }, {});
            }, {
                fireImmediately: true
            });
    }

    _countriesMap: Record<string, ICountryConfiguration> = {};

    tryGetCountry(countryCode: string): ICountryConfiguration | null {
        return this._countriesMap[countryCode] || null;
    }

    get countries(): ICountryConfiguration[] {
        return Object.values(this._countriesMap);
    }

    getCountry(code: string): ICountryConfiguration {
        const country = this._countriesMap[code];
        if(!country) {
            throw new Error(`There is no country with code ${code}`);
        }

        return country;
    }

    get countryTypesAreMissing(): boolean {
        return this.countries.all(c => Check.isNullOrUndefined(c.countryType));
    }
}
