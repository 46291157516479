import React, {PropsWithChildren} from 'react';
import { ExclamationCircle } from "../../assets/svg/exclamation-circle.icon";
import { InfoMessageBox } from './boxes/info-message.box';

interface InfoMessageComponentProps extends PropsWithChildren {
    className?: string
}

export const InfoMessageComponent: React.FC<InfoMessageComponentProps> = (props) => {
    return (
        <InfoMessageBox className={props.className}>
            <ExclamationCircle size={1.8}/>
            {props.children}
        </InfoMessageBox>
    );
}
