import {IJourneySsrsBucketViewModel} from "../../../../services/booking/models/ssrs/journey-ssrs-bucket-view-model.interface";
import React from "react";
import {observer} from "mobx-react";
import {IPassengerSsrEditorViewModel} from "../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {SameForAllPassengersToggleComponent} from "../same-for-all-passengers-toggle.component";
import styled from "styled-components";
import {PassengerSsrsSelectionComponent} from "./passenger-ssrs-selection.component";
import {IJourneyCustomSsrsEditorRenderer, IPassengerCustomSsrEditorRenderer} from "./ssrs-selection.component.props";

const JourneySsrsSelectionComponentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.large};
`

interface JourneySsrsSelectionComponentProps {
    journeyBucket: IJourneySsrsBucketViewModel;
    journeyCustomSsrsEditorRenderer?: IJourneyCustomSsrsEditorRenderer;
    passengerCustomSsrEditorRenderer?: IPassengerCustomSsrEditorRenderer;
}

export const JourneySsrsSelectionComponent: React.FC<JourneySsrsSelectionComponentProps> = observer((props) => {

    const getSsrsEditorsForEachPassenger = () => {

        const passengersSsrsEditorsMap: Record<string, IPassengerSsrEditorViewModel[]> = {};

        for(let ssrInTheBucket of props.journeyBucket.getAllSsrsInTheBucket()) {
            for(let passengerSsrEditor of ssrInTheBucket.passengersSsrEditors) {
                if(!passengersSsrsEditorsMap[passengerSsrEditor.passengerKey]) {
                    passengersSsrsEditorsMap[passengerSsrEditor.passengerKey] = [];
                }

                passengersSsrsEditorsMap[passengerSsrEditor.passengerKey].push(passengerSsrEditor);
            }
        }
        return Object.values(passengersSsrsEditorsMap);
    }

    const renderSameForAllPassengersToggle = (passengerIndex: number) => {
        if(passengerIndex > 0) {
            return null;
        }

        if(!props.journeyBucket.allowUseSameOptionsForAllPassengers) {
            return null;
        }

        return (
            <SameForAllPassengersToggleComponent ssrsBucket={props.journeyBucket}/>
        );
    }

    const renderJourneySsrsEditor = () => {
        if(props.journeyCustomSsrsEditorRenderer) {
            return props.journeyCustomSsrsEditorRenderer.render(props.journeyBucket);
        } else {
            const allPassengersSsrsEditors = getSsrsEditorsForEachPassenger();
            return allPassengersSsrsEditors.map((passengerSsrsEditors, index) => {
                return (
                    <React.Fragment key={passengerSsrsEditors[0].passengerKey}>
                        <PassengerSsrsSelectionComponent passengerSsrsEditors={passengerSsrsEditors}
                                                         passengerCustomSsrEditorRenderer={props.passengerCustomSsrEditorRenderer}/>
                        {renderSameForAllPassengersToggle(index)}
                    </React.Fragment>
                );
            })
        }
    }


    return (
        <JourneySsrsSelectionComponentBox>
            {renderJourneySsrsEditor()}
        </JourneySsrsSelectionComponentBox>
    );
});
