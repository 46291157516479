import {IDropDownController, IDropDownEventListenerSubscription} from "./drop-down-controller.interface";
import {IServiceFactory} from "../../../../services/service-factory.interface";
import {makeObservable, observable, runInAction} from "mobx";

export class DropDownControllerModel implements IDropDownController {
    constructor(private readonly services: IServiceFactory) {
        makeObservable<this, '_isOpen'>(this, {
            _isOpen: observable.ref
        });
    }

    private _onOpenSubscriptions: Array<() => void> = [];
    private _onCloseSubscriptions: Array<() => void> = [];

    private _isOpen = false;

    get isOpen(): boolean {
        return this._isOpen;
    }

    setIsOpen(value: boolean): void {
        runInAction(() => {
            this._isOpen = value;
        })
    }

    openDropDown(): void {
        if(this.isOpen) {
            return;
        }
        this.setIsOpen(true);

        this._onOpenSubscriptions.forEach(callback => {
            callback();
        })
    }

    closeDropDown(): void {
        if(!this.isOpen) {
            return;
        }
        this.setIsOpen(false);

        this._onCloseSubscriptions.forEach(callback => {
            callback();
        })
    }

    get isInsideModalDialog(): boolean {
        return this.services.layout.isInSmallScreenView
    }

    onClosed(listener: () => void): IDropDownEventListenerSubscription {
        this._onCloseSubscriptions.push(listener);
        return {
            unsubscribe: () => {
                const index = this._onOpenSubscriptions.findIndex(l => l === listener);
                if(index >= 0) {
                    this._onCloseSubscriptions.splice(index, 1);
                }
            }
        }
    }

    onOpen(listener: () => void): IDropDownEventListenerSubscription {
        this._onOpenSubscriptions.push(listener);
        return {
            unsubscribe: () => {
                const index = this._onOpenSubscriptions.findIndex(l => l === listener);
                if(index >= 0) {
                    this._onOpenSubscriptions.splice(index, 1);
                }
            }
        }
    }
}