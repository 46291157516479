import React from 'react';
import { useServices } from "../../../hooks/use-services.hook";
import { observer } from "mobx-react";
import {PromotionalCodeInputComponent} from "../common/promotional-code-input.component";
import {IPromotionalViewModel} from "../../../services/booking/models/promotions/promotional-view-model.interface";

interface PromoCodeComponentProps {
    promoCode: IPromotionalViewModel;
}

export const PromoCodeComponent: React.FC<PromoCodeComponentProps> = observer((props) => {
    const services = useServices();

    return (
        <PromotionalCodeInputComponent promotional={props.promoCode}
                                       placeHolder={services.language.translate('Do you have a promo code?')}
                                       className={'tst-promo-code'}/>
    );
})
