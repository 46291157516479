import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {IExtrasSectionRenderer} from "../../../booking-flow/extras-selection/base-renderers/extras-section-renderer.interface";
import {SeatsExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/seats/seats.extras-section.renderer";
import {CabinBagExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/cabin-bag/cabin-bag.extras-section.renderer";
import {CheckInBagsExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/check-in-bags/check-in-bags.extras-section.renderer";
import {AirportCheckInExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/airport-check-in/airport-check-in.extras-section.renderer";
import {EarlyCheckInExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/early-check-in/early-check-in.extras-section.renderer";
import {BusTransferExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/bus-transfer/bus-transfer.extras-section.renderer";
import {BusinessLoungeExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/business-lounge/business-lounge.extras-section.renderer";
import {FastTrackExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/fast-track/fast-track.extras-section.renderer";
import {PetsExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/pets/pets.extras-section.renderer";
import {SpecialEquipmentExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/special-equipment/special-equipment.extras-section.renderer";
import {SpecialAssistanceExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/special-assistance/special-assistance.extras-section.renderer";
import {IExtrasSectionsRenderersFactory} from "../../../booking-flow/extras-selection/base-renderers/extras-sections-renderers-factory.interface";

export class ManageMyBookingServicesSectionFactory implements IExtrasSectionsRenderersFactory {
    constructor(private readonly booking: IBookingViewModel) {
        this._sections = [
            new SeatsExtrasSectionRenderer(booking),
            new CheckInBagsExtrasSectionRenderer(booking),
            new CabinBagExtrasSectionRenderer(booking),
            new AirportCheckInExtrasSectionRenderer(booking),
            new EarlyCheckInExtrasSectionRenderer(booking),
            new BusTransferExtrasSectionRenderer(booking),
            new BusinessLoungeExtrasSectionRenderer(booking),
            new FastTrackExtrasSectionRenderer(booking),
            new PetsExtrasSectionRenderer(booking),
            new SpecialEquipmentExtrasSectionRenderer(booking),
            new SpecialAssistanceExtrasSectionRenderer(booking)
        ];
    }

    private readonly _sections: IExtrasSectionRenderer[];
    getExtrasSections(): IExtrasSectionRenderer[] {
        return this._sections;
    }
}
