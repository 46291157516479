import {IServiceFactory} from "../../../service-factory.interface";
import React from "react";
import {RootRouteModel} from "../../models/root-route.model";
import {HomePage} from "../../../../pages/home/home.page";

export class HomepageRoute extends RootRouteModel {
    constructor(services: IServiceFactory) {
        super('/', services, () => <HomePage/>, {allowBack: false});
    }

    get isHome(): boolean {
        return true;
    }
}
