import React from "react";
import {IFlightDesignatorViewModel} from "../../services/booking/models/designator/flight-designator-view-model.interface";
import {DesignatorComponent, DesignatorDisplayMode} from "./designator.component";
import styled from "styled-components";
import {IFlightIdentifierViewModel} from "../../services/booking/models/identifier/flight-identifier-view-model.interface";
import {VerticalSpacer} from "../../basic-components/spacing/vertical-spacer";
import {observer} from "mobx-react";


const RowStyleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    line-height: 1;
`

export const ColumnStyleBox = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1;
`

const VerticalPipeSeparatorBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0 ${props => props.theme.spacing.large};
`

interface DesignatorsComponentProps {
    designators: IFlightDesignatorViewModel[];
    displayMode: DesignatorDisplayMode;
    identifiers?: IFlightIdentifierViewModel[];
    className?: string;
}

export const DesignatorsComponent: React.FC<DesignatorsComponentProps> = observer((props) => {

    const useRowStyle = props.displayMode === DesignatorDisplayMode.stationsCodeOnly;

    const selectBox = () => {
        if(useRowStyle) {
            return RowStyleBox;
        }
        return ColumnStyleBox;
    }

    const renderSpacer = (index: number) => {

        if(index >= props.designators.length - 1) {
            return null;
        }

        if(useRowStyle) {
            return (<VerticalPipeSeparatorBox>|</VerticalPipeSeparatorBox>);
        }

        return (<VerticalSpacer size="small"/>);
    }

    const renderOneDesignator = (designator: IFlightDesignatorViewModel, index: number) => {
        return (
            <React.Fragment key={designator.origin.stationCode + designator.destination.stationCode}>
                <DesignatorComponent identifier={props.identifiers ? props.identifiers[index] : undefined}
                                            displayMode={props.displayMode}
                                            designator={designator}/>
                {renderSpacer(index)}
            </React.Fragment>
        );
    }

    const Box = selectBox();

    return (
        <Box className={props.className}>
            {props.designators.map(renderOneDesignator)}
        </Box>
    );
});
