export enum  DeviceType {
    Android = 1,
    iOS = 2,
    other = 3
}

export interface IDeviceService {
    getDeviceId(): Promise<string>;
    readonly deviceType: DeviceType;
    readonly isHybrid: boolean;
    readonly isAndroidMobileHybrid: boolean;
    readonly isIOS: boolean;
    readonly isAndroid: boolean;
    readonly isWeb: boolean;
    readonly isWindows: boolean;
    readonly isMac: boolean;
    readonly isMobile: boolean;
    getOperatingSystemName(): string;


}
