import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IFlightSearchControllerViewModel} from "../../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {CmsPageComponent} from "../../../../components/cms/cms-page.component";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";

const ContentBox = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
`


interface SpecialPriceUserAgreementPageContentComponentProps {
    searchController: IFlightSearchControllerViewModel;
}

export const SpecialPriceUserAgreementPageContentComponent: React.FC<SpecialPriceUserAgreementPageContentComponentProps> = observer((props) => {
    const marketName = props.searchController.getSpecialPriceMarket()?.marketName;

    const renderContent = () => {
        if(marketName) {
            return (<CmsPageComponent pageKey={'pso'} urlBuilderParams={{market: marketName}}/>);
        } else {
            return (<SpinnerComponent/>);
        }
    }

    return (
        <ContentBox>
            {renderContent()}
        </ContentBox>
    );
})
