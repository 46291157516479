import { ServiceBase } from "../service-base";
import { IServiceFactory } from "../service-factory.interface";
import {IAnalyticsService, IAnalyticsStrategy} from "./analytics.service.interface";
import {GoogleAnalyticsStrategy} from "./strategies/google-analytics.strategy";
import {NoAnalyticsStrategy} from "./strategies/no-analytics.strategy";
import {IAnalyticsFlightSearchEvent} from "../booking/models/analytics/everyMundo-analytics-flight-search.interface";
import {
  IAnalyticsExtrasSelectionEvents,
  IAnalyticsFlightSelectionEvents,
  IAnalyticsPassengerDetailsEvents, IAnalyticsPaymentEvents, IAnalyticsSeatSelectionEvents
} from "../booking/models/analytics/google-analytics.intefaces";
import {IAnalyticsPurchaseEvent} from "../booking/models/analytics/google-analytics-purchase.interface";

export class AnalyticsService extends ServiceBase implements IAnalyticsService {
  constructor(services: IServiceFactory) {
    super(services);
    if(this.services.configuration.isAnalyticsEnabled) {
      this._currentStrategy = new GoogleAnalyticsStrategy(services);
    } else {
      this._currentStrategy = new NoAnalyticsStrategy();
    }
  }

  private _mapPurchasedSsrWithAnalyticsName: Record<string, string> = {}

  private readonly _currentStrategy: IAnalyticsStrategy;

  get flightSelectionEvents(): IAnalyticsFlightSelectionEvents  {
    return this._currentStrategy.flightSelectionEvents;
  }

  get passengerDetailsEvents(): IAnalyticsPassengerDetailsEvents  {
    return this._currentStrategy.passengerDetailsEvents;
  }

  get seatSelectionEvents(): IAnalyticsSeatSelectionEvents  {
    return this._currentStrategy.seatSelectionEvents;
  }

  get extrasSelectionEvents(): IAnalyticsExtrasSelectionEvents {
    return this._currentStrategy.extrasSelectionEvents;
  }

  get paymentEvents(): IAnalyticsPaymentEvents {
    return this._currentStrategy.paymentEvents;
  }

  logLoginEvent(): void {
    this._currentStrategy.logLoginEvent();
  }

  logPurchaseEvent(recordLocator: string, event: IAnalyticsPurchaseEvent): void {
    this._currentStrategy.logPurchaseEvent(recordLocator, event);
  }

  logSignUpEvent(): void {
    this._currentStrategy.logSignUpEvent();
  }

  logFlightSearchForEveryMundo(event: IAnalyticsFlightSearchEvent): void {
    this.sendEvent(event)
  }

  sendEvent(event: any): void {
     this._currentStrategy.sendEvent(event)
  }

  mapSsrIdAWithAnalyticsName(ssrId: string, analyticsName: string): void {
    this._mapPurchasedSsrWithAnalyticsName[ssrId] = analyticsName;
  }

  removeSsrMappingWithAnalytics(ssrId: string){
    if (this._mapPurchasedSsrWithAnalyticsName.hasOwnProperty(ssrId)) {
      delete this._mapPurchasedSsrWithAnalyticsName[ssrId];
    }
  }

  getAnalyticsNameForSsrId(ssrId: string): string | undefined {
    return this._mapPurchasedSsrWithAnalyticsName[ssrId];
  }

  getSsrIdsForAnalyticsName(analyticsName: string): string[] {
    let properties = [];
    for (let key in this._mapPurchasedSsrWithAnalyticsName) {
      if (this._mapPurchasedSsrWithAnalyticsName.hasOwnProperty(key) && this._mapPurchasedSsrWithAnalyticsName[key] === analyticsName) {
        properties.push(key);
      }
    }
    return properties;
  }
}


