import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {FlightDatesDropDownContentComponent} from "./content/flight-dates-drop-down-content.component";
import {IDropDownController} from "../../../../../../components/forms/drop-down/models/drop-down-controller.interface";
import {CalendarIcon} from "../../../../../../assets/svg/calendar.icon";
import {DropDownComponent} from "../../../../../../components/forms/drop-down/drop-down.component";
import {FlightsDatesSelectionHeaderComponent} from "../../../../../flight-search/flight-dates-selection/components/flights-dates-selection-header.component";
import {FlightDatesDropDownInputComponent} from "./input/flight-dates-drop-down-input.component";
import {FlightDatesDropDownLowFareInfoComponent} from "./flight-dates-drop-down-low-fare-info.component";


export const SearchMaskFlightDatesSelectionWebappComponent: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;
    const datesSelector = searchController.datesSelector;

    const renderDropDownInput = () => {
        return (
            <FlightDatesDropDownInputComponent searchController={searchController}/>
        );
    }

    const renderDropDownContent = (dropDownController: IDropDownController) => {
        return (
            <FlightDatesDropDownContentComponent datesSelector={datesSelector} dropDownController={dropDownController}/>
        )
    }

    const label = searchController.isOneWay
                        ? services.language.translate('Departure date')
                        : services.language.translate('Flights dates')



    const renderDialogHeader = () => {
        if(!(searchController.departureOrigin && searchController.departureDestination)) {
            return (<></>);
        }
        return (
            <FlightsDatesSelectionHeaderComponent flightsDatesSelector={datesSelector}/>
        )
    }

    const renderDialogFooter = () => {
        return (
            <FlightDatesDropDownLowFareInfoComponent datesSelector={datesSelector}/>
        )
    }

    return (
        <DropDownComponent label={label}
                           icon={<CalendarIcon size={2}/>}
                           errorMessage={searchController.departureDateError || searchController.returnDateError}
                           renderDropDownInput={renderDropDownInput}
                           renderDropDownContent={renderDropDownContent}
                           showBorders={true}
                           modalDialogOptions={{
                               renderHeader: renderDialogHeader,
                               renderFooter: renderDialogFooter
                           }}
                           />
    )
});
