import {IDotRezBookingData} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IBookingModel} from "../../booking-model.interface";
import {IServiceFactory} from "../../../../service-factory.interface";
import {Price} from "../../../../currency/price";
import {PassengerFeeBaseModel} from "../fees/passenger/passenger-fee-base.model";
import {FeeTypeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";

export abstract class BookingBaseModel implements IBookingModel {

    abstract get bookingData(): IDotRezBookingData;

    abstract get services(): IServiceFactory;
    protected abstract _getAllPassengersFees(): PassengerFeeBaseModel[];

    get currency(): string {
        return this.bookingData.currencyCode;
    }

    get bookingCreationDate(): Date {
        if (this.bookingData.info.createdDate) {
            return this.services.time.parseIsoDate(this.bookingData.info.createdDate);
        }

        return this.services.time.parseIsoDate(this.bookingData.info.bookedDate);
    }

    isInQueue(queueCode: string): boolean {
        queueCode = queueCode.toUpperCase()
        return this.bookingData.queues.some(q => q.code?.toUpperCase() === queueCode);
    }

    createPrice(amount: number): Price {
        return this.services.currency.createPrice(amount, this.currency);
    }

    getAllFlightsReferences(): string[] {
        const flightReferences: string[] = [];
        this.bookingData.journeys.forEach(j => {
            j.segments.forEach(s => {
                flightReferences.push(s.flightReference);
            })
        });

        return flightReferences;

    }

    getBookingLevelFees(): PassengerFeeBaseModel[] {

        const allFlightReferences = this.getAllFlightsReferences();

        //There might be fees that have a flightReference that is not anymore in the booking. This happens on flight change when a PenaltyFee (date change fee) is applied for the old journey which is not anymore in the booking.
        //We exclude ServiceBundle because this one is sum up in the fare amount
        return this._getAllPassengersFees().filter(f => f.feeType !== FeeTypeEnum.ServiceBundle
                                                        && f.currentPrice.amount !== 0
                                                        && (!f.flightReference || !allFlightReferences.includes(f.flightReference)));
    }

}
