import {ICheckInRestriction} from "./check-in-restriction.interface";
import {IServiceFactory} from "../../../service-factory.interface";

export class UnaccompaniedMinorCheckInRestriction implements ICheckInRestriction {
    constructor(private readonly services: IServiceFactory) {
    }

    get description(): string {
        return this.services.language.translate('Minor passengers traveling without an adult over 18 years cannot perform online check-in');
    }

    get shouldBlockSavingTravelDocuments(): boolean {
        return false;
    }
}
