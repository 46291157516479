import {BlueBenefitsSsr} from "./blue-benefits-ssr";
import {IServiceFactory} from "../../../service-factory.interface";
import React from "react";
import {Pas3SsrLargeIcon} from "./pas3-ssr.large-icon";

export default  class Pas3Ssr extends BlueBenefitsSsr {
    constructor(services: IServiceFactory) {
        super('PAS3', services);
    }

    get largeIcon(): React.ReactElement | null {
        return <Pas3SsrLargeIcon/>
    }
}
