import React, {CSSProperties, PropsWithChildren} from "react";
import {
    IPassengerSegmentBoardingPassViewModel
} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassCabinBagsComponent} from "./cabin-bags/boarding-pass-cabin-bags.component";
import {BoardingPassCheckinBagsComponent} from "./check-in-bags/boarding-pass-checkin-bags.component";
import {BoardingPassPriorityBoardingStatusComponent} from "./priority-boarding/boarding-pass-priority-boarding-status.component";
import {BoardingPassFieldLabelComponent} from "../label/boarding-pass-field-label.component";

const BagsBox: React.FC<PropsWithChildren> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginBottom: services.theme.currentTheme.spacing.medium
    }
    return (
        <div style={styles}>
            {props.children}
        </div>
    )
}

const BagsSeparator: React.FC<PropsWithChildren> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        flexBasis: '1px',
        backgroundColor: theme.colors.secondary,
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
}

export const BoardingPassBagsComponent: React.FC<{boardingPass: IPassengerSegmentBoardingPassViewModel}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const hasPriority = props.boardingPass.priorityBoarding.count > 0;
    let checkinBags = props.boardingPass.checkInBags;



    const renderTitle = () => {
        if(!hasPriority && checkinBags.length === 0) {
            return null;
        }

        const titleStyles: CSSProperties = {
            marginBottom: theme.spacing.medium
        }

        return (
            <div style={titleStyles}>
                <BoardingPassFieldLabelComponent styles={{textAlign: 'center', fontSize: theme.fontSize.medium}}>
                    {services.language.translate('Boarding group & bags')}
                </BoardingPassFieldLabelComponent>
            </div>
        )
    }

    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        lineHeight: 1,
        marginBottom: theme.spacing.large
    }

    const hasCheckInBags = checkinBags.length > 0

    return (
        <div style={styles}>

            {renderTitle()}

            <BagsBox>
                <BoardingPassCabinBagsComponent boardingPass={props.boardingPass} hasCheckInBags={hasCheckInBags}/>
                {hasCheckInBags && (<BagsSeparator />)}
                <BoardingPassCheckinBagsComponent bags={checkinBags}/>
            </BagsBox>

            <BoardingPassPriorityBoardingStatusComponent hasPriority={hasPriority}/>

        </div>
    )
}
