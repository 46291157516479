import {ITravelDocumentViewModel} from "./passenger-travel-document-view-model";
import {IPassengerTravelDocumentFields} from "./passenger-travel-document-fields.interface";
import {IDotRezPassengerTravelDocument} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {NullableDate, NullableString, NullableUndefinedString} from "../../../../types/nullable-types";
import {IServiceFactory} from "../../../service-factory.interface";
import {FormModel} from "../../../../models/forms/form.model";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {NullableGender} from "../../../dot-rez-api/data-contracts/enums/gender.enum";
import {IPersonTravelDocumentViewModel} from "../../../user/models/person/person-travel-document-view-model.interface";
import {DocumentNumberValidator} from "../../../../models/forms/field-validators/document-number.validator";
import {PassengerModelBase} from "./passenger-model-base";
import {BookingModel} from "../booking.model";
import {computed, makeObservable, runInAction} from "mobx";
import {BirthdateValidator} from "../../../../models/forms/field-validators/birthdate.validator";


export class PassengerTravelDocumentModel extends FormModel<IPassengerTravelDocumentFields> implements ITravelDocumentViewModel {
    constructor(private readonly documentOwner: PassengerModelBase) {
        super(documentOwner.booking.services);
        makeObservable(this, {
            data: computed
        });
    }

    private get booking(): BookingModel {
        return this.documentOwner.booking;
    }

    get travelDocumentKey(): NullableUndefinedString {
        return this.data.passengerTravelDocumentKey;
    }

    get isTitleRequired(): boolean {
        return this.documentOwner.isTitleRequiredForCheckIn;
    }

    private get shouldCollectAllDocumentDetails(): boolean {
        return this.documentOwner.hasSpecialPriceMarketDiscount || !this.documentOwner.isOnDomesticFlight;
    }

    protected _createFields(): FormFields<IPassengerTravelDocumentFields> {
        const language = this.services.language;
        return {
            gender: this._createField<NullableGender>({
                fieldName: () => language.translate('Gender'),
                //When a passenger is created in dotREZ it gets by default Male as gender
                //We don't want to use the default value Male for any person.
                //So here if the passenger has nationality filled in it means that also gender was filled in by the user.
                initialValue: () => this.documentOwner.info.nationality ? this.documentOwner.info.gender : null
            }),
            dateOfBirth: this._createField<NullableDate>({
                fieldName: () => language.translate('Date of birth'),
                initialValue: () => this.services.time.tryConvertToDate(this.documentOwner.info.dateOfBirth),
                validators: [
                    new BirthdateValidator(this.services)
                ]
            }),
            documentTypeCode: this._createField<NullableString>({
                fieldName: () => language.translate('Document Type'),
                initialValue: () => this.data.documentTypeCode,
                isHidden: () => !this.shouldCollectAllDocumentDetails,
                defaultValue: this.documentOwner.hasSpecialPriceMarketDiscount ? this.services.travelDocumentType.identityCardCode : null,
                isReadOnly: () => this.documentOwner.hasSpecialPriceMarketDiscount,
                maxLength: 4
            }),
            number: this._createField<NullableString>({
                fieldName: () => language.translate('Document number'),
                initialValue: () => this.data.number,
                isHidden: () => !this.shouldCollectAllDocumentDetails,
                maxLength: 35,
                validators: [
                    new DocumentNumberValidator(language.translate('Invalid document number'))
                ]
            }),
            issuedByCode: this._createField<NullableString>({
                fieldName: () => language.translate('Issue Country'),
                initialValue: () => this.data.issuedByCode,
                isHidden: () => !this.shouldCollectAllDocumentDetails,
                defaultValue: this.documentOwner.hasSpecialPriceMarketDiscount ? this.services.configuration.defaultCountryCode : null,
                maxLength: 2
            }),
            issuedDate: this._createField<NullableDate>({
                fieldName: () => language.translate('Issued Date'),
                initialValue: () => this.services.time.tryConvertToDate(this.data.issuedDate),
                isHidden: () => !this.shouldCollectAllDocumentDetails,
                validate: () => this._validateIssueDate()
            }),
            expirationDate: this._createField<NullableDate>({
                fieldName: () => language.translate('Expiration Date'),
                initialValue: () => this.services.time.tryConvertToDate(this.data.expirationDate),
                isHidden: () => !this.shouldCollectAllDocumentDetails,
                validate: () => this._validateExpirationDate()
            }),
            nationality: this._createField<NullableString>({
                fieldName: () => language.translate('Citizenship'),
                initialValue: () => this.documentOwner.info.nationality,
                defaultValue: this.documentOwner.hasSpecialPriceMarketDiscount ? this.services.configuration.defaultCountryCode : null,
                maxLength: 2
            })
        };
    }

    protected _onFieldsCreated(fields: FormFields<IPassengerTravelDocumentFields>) {
        super._onFieldsCreated(fields);

        fields.dateOfBirth.onChange(value => this.documentOwner.fields.dateOfBirth.setValue(value));
        fields.gender.onChange(value => this.documentOwner.fields.gender.setValue(value));
        fields.nationality.onChange(value => this.documentOwner.fields.nationality.setValue(value));
    }

    private get preferredTravelDocumentStorageKey(): string {
        return `TravelDoc_${this.documentOwner.passengerKey}`;
    }


    private _createEmptyTravelDocument(): IDotRezPassengerTravelDocument {
        return {
            passengerTravelDocumentKey: null,
            documentTypeCode:  null,
            number:  null,
            name: null,
            issuedByCode:  null,
            nationality: null,
            issuedDate:  null,
            expirationDate:  null,
            gender: null,
            dateOfBirth: null,
        }
    }

    get data(): IDotRezPassengerTravelDocument {
        const preferredTravelDocumentKey = this.booking.storage.getItem(this.preferredTravelDocumentStorageKey);
        let travelDocumentData = this.documentOwner.travelDocuments.find(td => td.passengerTravelDocumentKey === preferredTravelDocumentKey);
        if(travelDocumentData) {
            return travelDocumentData;
        }

        const flightDate = this.booking.filteredJourneys[0]?.designator.departureDate || this.services.time.currentDate;
        travelDocumentData = getFirstValidTravelDocument(this.services, this.documentOwner.travelDocuments, flightDate);
        if(travelDocumentData) {
            return travelDocumentData;
        }
        return this._createEmptyTravelDocument();
    }


    private _createSaveRequest(): IDotRezPassengerTravelDocument {
        const time = this.services.time;
        const newDocumentData: IDotRezPassengerTravelDocument  = {
            name: {
                first: this.documentOwner.fields.firstName.value,
                last: this.documentOwner.fields.lastName.value,
                title: this.documentOwner.computeTitle()
            },
            documentTypeCode: this.fields.documentTypeCode.value!,
            dateOfBirth: time.formatBirthDate(this.fields.dateOfBirth.value),
            gender: this.fields.gender.value,
            number: this.fields.number.value,
            issuedByCode: this.fields.issuedByCode.value,
            issuedDate: time.formatYYY_MM_DD(this.fields.issuedDate.value) || null,
            expirationDate: time.formatYYY_MM_DD(this.fields.expirationDate.value) || null,
            nationality: this.fields.nationality.value,
        };

        const existingDocumentData = this.documentOwner.travelDocuments.find(doc => doc.documentTypeCode === newDocumentData.documentTypeCode
                                                                                && doc.issuedByCode === newDocumentData.issuedByCode);

        if(existingDocumentData) {
            newDocumentData.passengerTravelDocumentKey = existingDocumentData.passengerTravelDocumentKey;
        }

        return newDocumentData;
    }


    async saveTravelDocument(): Promise<void> {
        if(!this.hasChanges()) {
            return;
        }

        const travelDocument = this._createSaveRequest();
        const travelDocumentKey = await this.documentOwner.saveTravelDocument(travelDocument);
        if(travelDocumentKey) {
            travelDocument.passengerTravelDocumentKey = travelDocumentKey;
            this.booking.storage.setItem(this.preferredTravelDocumentStorageKey, travelDocumentKey);

            const docIndex = this.documentOwner.travelDocuments.findIndex(td => td.passengerTravelDocumentKey === travelDocumentKey);
            runInAction(() => {
                if(docIndex >= 0) {
                    this.documentOwner.travelDocuments[docIndex] = travelDocument;
                } else {
                    this.documentOwner.travelDocuments.push(travelDocument);
                }
            });
        }

        this.commitChanges();
    }

    private _validateIssueDate(): NullableString {
        if(!this.fields.issuedDate.value) {
            return null;
        }

        if(this.fields.dateOfBirth.value) {
            if(this.fields.issuedDate.value.getTime() < this.fields.dateOfBirth.value.getTime()) {
                return this.services.language.translate('Cannot be before birth date');
            }
        }

        return null;
    }

    private _validateExpirationDate(): NullableString {
        if(!this.fields.expirationDate.value) {
            return null;
        }

        if(this.fields.issuedDate.value) {
            if(this.fields.expirationDate.value.getTime() < this.fields.issuedDate.value.getTime()) {
                return this.services.language.translate('Cannot be before Issued Date');
            }
        }

        if(this.fields.dateOfBirth.value) {
            if(this.fields.expirationDate.value.getTime() < this.fields.dateOfBirth.value.getTime()) {
                return this.services.language.translate('Cannot be before birth date');
            }
        }

        return null;
    }

    computeMinimumTravelDocumentExpirationDate(): Date {
        let minimumExpirationDate = this.services.time.currentDate;
        this.documentOwner.booking.filteredJourneys.forEach(journey => {
            if(minimumExpirationDate.getTime() < journey.designator.arrivalDate.getTime()) {
                minimumExpirationDate = journey.designator.arrivalDate
            }
        });

        return this.services.time.makeShortDate(minimumExpirationDate);
    }

    computeMaximumTravelDocumentIssuedDate(): Date {
        const journeys = this.documentOwner.booking.filteredJourneys;
        let maximumIssuedDate = journeys[0].designator.departureDate;

        for(let i = 1; i < journeys.length; i++) {
            const journey = journeys[i];
            if(maximumIssuedDate > journey.designator.departureDate) {
                maximumIssuedDate = journey.designator.departureDate
            }
        }

        return this.services.time.makeShortDate(maximumIssuedDate);
    }

    copyFrom(travelDocument: IPersonTravelDocumentViewModel): void {
        const docFields = travelDocument.fields;
        this.fields.number.setValue(docFields.number.value);
        this.fields.documentTypeCode.setValue(docFields.documentTypeCode.value);
        this.fields.issuedByCode.setValue(docFields.issuedByCode.value);
        this.fields.issuedDate.setValue(docFields.issuedDate.value);
        this.fields.expirationDate.setValue(docFields.expirationDate.value);
        this.fields.nationality.setValue(docFields.nationality.value);
    }
}

export function getFirstValidTravelDocument(services: IServiceFactory, travelDocuments: IDotRezPassengerTravelDocument[] | null, flightDate: Date): IDotRezPassengerTravelDocument | undefined {
    if(!travelDocuments || travelDocuments.length === 0) {
        return undefined;
    }


    const time = services.time;

    //sort descending by expiration date so we return the document that expires last.
    travelDocuments = [...travelDocuments].sort((doc1, doc2) => {
        const d1 = doc1.expirationDate ?  time.parseIsoDate(doc1.expirationDate).getTime() : 0;
        const d2 = doc2.expirationDate ?  time.parseIsoDate(doc2.expirationDate).getTime() : 0;
        return d2 - d1;
    });

    const findFirstValidDoc = (docs: IDotRezPassengerTravelDocument[]) => {
        for(let doc of docs) {
            if(doc.expirationDate) {
                if(flightDate.getTime() <= time.parseIsoDate(doc.expirationDate).getTime()) {
                    return doc;
                }
            }
        }

        return undefined;
    }

    // first we try to find a valid passport
    let foundDocument = findFirstValidDoc(travelDocuments.filter(d => d.documentTypeCode === services.travelDocumentType.passportCode));
    if(foundDocument) {
        return foundDocument;
    }

    // if there are no valid passport we try to find any other valid document type
    foundDocument = findFirstValidDoc(travelDocuments.filter(d => d.documentTypeCode !== services.travelDocumentType.passportCode));
    if(foundDocument) {
        return foundDocument;
    }

    // lastly we return the first document even if it is expired
    return travelDocuments[0];
}
