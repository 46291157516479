import React from "react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";
import {IDotRezSessionCountDownTimerViewModel} from "../../services/dot-rez-api/session/session-timer/dot-rez-session-timer-view-model.interface";
import { AlarmClockIcon } from "../../assets/svg/alarm-clock.icon";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";

const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.large};
    width: 100%;
    height: 100%;
    font-size: ${props => props.theme.fontSize.large};
`

const SessionCountDownIconBox = styled.div`
    display: flex;
    justify-content: center;
`

const SessionCountDownTextLine = styled.div`
    line-height: 1;
    text-align: center;
`
const TimeLeftBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
`

interface SessionCountdownDialogComponentProps {
    countDownTimer: IDotRezSessionCountDownTimerViewModel;
    dialogHandler: IDialogConfirmationHandler;
    onButtonClick: () => Promise<void>;
}

export const SessionCountDownDialogComponent: React.FC<SessionCountdownDialogComponentProps> = observer((props) => {
    const services = useServices();
    const timeLeft = props.countDownTimer.sessionTimeLeft;
    if(!timeLeft) {
        props.dialogHandler.reject();
        return null;
    }

    const isExpired = timeLeft.totalMilliseconds <= 0;

    const renderContent = () => {
        if(isExpired) {
            return (
                <SessionCountDownTextLine>
                    {services.language.translate('Your session has expired')}
                </SessionCountDownTextLine>
            )
        } else {
            return (
                <>
                    <div>
                        {services.language.translate('Due to inactivity, your session will expire in')}
                    </div>
                    <TimeLeftBox>
                        {services.language.translationFor('{seconds} seconds').withParams({
                            seconds: Math.round(timeLeft.totalSeconds)
                        })}
                    </TimeLeftBox>
                    <SessionCountDownTextLine>
                        {services.language.translate('Would you like to continue your session?')}
                    </SessionCountDownTextLine>
                </>
            );
        }
    }

    const getButtonText = () => {
        if(isExpired) {
            return services.language.translate('Start over');
        } else {
            return services.language.translate('Yes, I need more time to decide');
        }
    }

    const onButtonClick = async () => {
        props.dialogHandler.accept();
        await props.onButtonClick();
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Session Timeout')} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent >
                <DialogContentBox>
                    <SessionCountDownIconBox>
                        <AlarmClockIcon size={9} color={services.theme.currentTheme.colors.secondary}/>
                    </SessionCountDownIconBox>
                    {renderContent()}
                </DialogContentBox>

            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={onButtonClick}>
                    {getButtonText()}
                </PrimaryButton>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
});
