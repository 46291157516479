import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {ExtrasSelectionPageContentComponent} from "../../../booking-flow/extras-selection/extras-selection-page-content.component";
import {BookingFlowPage} from "../../../booking-flow/booking-flow.page";




export const ManageMyBookingExtrasSelectionPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowPage title={services.language.translate('Extras')}
                                    renderContent={() => <ExtrasSelectionPageContentComponent/>}
                                    showPromotionCode={false}>

        </BookingFlowPage>
    )
})
