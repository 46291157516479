import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";

export class RetrieveSeatsMapMutation extends BookingMutationActionBase {

    _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        return this.session.bookingStateQueryBuilder().useSeatMaps().getBookingState();
    }
    get willAffectTotal(): boolean {
        return false;
    }
}
