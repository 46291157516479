import React from "react";
import { observer } from "mobx-react";
import { useServices } from "../../../hooks/use-services.hook";
import { FlightItineraryBookingReferenceComponent } from "./components/flight-itinerary-booking-reference.component";
import { FlightItineraryFlightsComponent } from "./components/flights/flight-itinerary-flights.component";
import { FlightItineraryPassengersComponent } from "./components/passengers/flight-itinerary-passengers.component";
import { FlightItinerarySeatsComponent } from "./components/seats/flight-itinerary-seats.component";
import { FlightItineraryContactDetailsComponent } from "./components/contact-details/flight-itinerary-contact-details.component";
import { FlightItineraryPaymentComponent } from "./components/payment/flight-itinerary-payment.component";
import {StandardPageHeaderComponent} from "../../../basic-components/page/standard-page-header/standard-page-header.component";
import {RoutedPageComponent} from "../../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../../basic-components/page/standard-page-content/standard-page-content.component";
import {FlightItineraryExtrasComponent} from "./components/extras/flight-itinerary-extras.component";
//import {FlightItineraryBlueBenefitsActivationComponent} from "./components/blue-benefits/flight-itinerary-blue-benefits-activation.component";
//import {FlightItineraryInsuranceComponent} from "./components/insurance/flight-itinerary-insurance.component";
import {VerticalSpacer} from "../../../basic-components/spacing/vertical-spacer";

export const FlightItineraryPage: React.FC = observer(() => {
    const services = useServices();


    if(services.booking.isBookingRestoreInProgress) {
        return null;
    }

    const booking = services.booking.current;

    const passengersSegments = booking.getAllPassengersSegments();
    const passengerWithSeatsCount = passengersSegments.filter(ps => ps.hasSeat).length;
    
    const spacer = <VerticalSpacer/>

    const seatsAccordion = () => {
        if(passengerWithSeatsCount > 0){
            return(
                <>
                    <FlightItinerarySeatsComponent/>
                    {spacer}
                </>
            );
        }
        return <></>;
    }

    const onBackButtonClickHandler = async () => {
        await services.navigator.goHome();
    }

    return(
        <RoutedPageComponent disableSwipeNavigation={true}>
            <StandardPageHeaderComponent title={services.language.translate('Flight Itinerary')}
                                         onBackButtonClick={onBackButtonClickHandler}
                                         useCloseButtonInsteadOfBackButton={true}/>

            <StandardPageContentComponent>
                <FlightItineraryBookingReferenceComponent/>
                {spacer}
                {/*<FlightItineraryBlueBenefitsActivationComponent/>*/}
                <FlightItineraryFlightsComponent/>
                {spacer}
                <FlightItineraryPassengersComponent/>
                {spacer}
                {seatsAccordion()}
                {
                    booking.filteredJourneys.map(journey => {
                        return (
                            <React.Fragment key={journey.journeyKey}>
                                <FlightItineraryExtrasComponent journey={journey}/>
                                {spacer}
                            </React.Fragment>
                        )
                    })
                }
                {/*<FlightItineraryInsuranceComponent/>*/}
                <FlightItineraryContactDetailsComponent/>
                {!booking.isGDSBooking && (
                    <>
                        {spacer}
                        <FlightItineraryPaymentComponent/>
                    </>
                )}
                
            </StandardPageContentComponent>
        </RoutedPageComponent>
    )
})
