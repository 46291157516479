import React from "react";
import {observer} from "mobx-react";
import {FlightsDatesSelector} from "../../../../../../../services/flight-search/dates-selection/flights-dates-selector";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import {FlightDatesDropDownListViewComponent} from "./flight-dates-drop-down-list-view.component";
import {FlightDatesDropDownSwiperViewComponent} from "./flight-dates-drop-down-swiper-view.component";
import {IDropDownController} from "../../../../../../../components/forms/drop-down/models/drop-down-controller.interface";
import {EmptyDropDownMessageComponent} from "../../../../../../../components/forms/drop-down/empty-drop-down-message.component";

interface FlightDatesDropDownContentComponentProps {
    datesSelector: FlightsDatesSelector;
    dropDownController: IDropDownController;
}

export const FlightDatesDropDownContentComponent: React.FC<FlightDatesDropDownContentComponentProps> = observer((props) => {
    const services = useServices();
    const datesSelector = props.datesSelector;
    const searchController = datesSelector.flightSearchController;


    if(!searchController.departureOrigin || !searchController.departureDestination) {
        return (
            <EmptyDropDownMessageComponent message={services.language.translate('Please select departure and destination first')}/>
        )
    }

    const onDateSelected = () => {

        if(searchController.isOneWay) {
            if(searchController.departureDate) {
                props.dropDownController.closeDropDown();
            }
        } else {
            if(searchController.departureDate && searchController.returnDate) {
                props.dropDownController.closeDropDown();
            }
        }
    }

    const captureSelectedDepartureDateElementRef = (date: Date, element: HTMLElement | null) => {
        if(services.layout.isInSmallScreenView && datesSelector.isDepartureDate(date)) {
            //we don't do this scroll into view when we are in swiper
            //because it will mess up the swiper
            //scrollElementIntoViewLazySmooth(element); //TODO - this one needs to be done only the first time drop dowin is open.
        }
    }

    if(services.layout.isInSmallScreenView) {
        return (
            <FlightDatesDropDownListViewComponent flightsDatesSelector={datesSelector}
                                                  dropDownController={props.dropDownController}
                                                  onDateSelected={onDateSelected}
                                                  captureSelectedDateElementRef={captureSelectedDepartureDateElementRef}/>

        );
    }

    return (
        <FlightDatesDropDownSwiperViewComponent datesSelector={datesSelector}
                                                onDateSelected={onDateSelected}
                                                captureSelectedDateElementRef={captureSelectedDepartureDateElementRef}/>
    );
});
