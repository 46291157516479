import React, {CSSProperties, PropsWithChildren} from 'react';
import {observer} from "mobx-react-lite";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassComponent} from "../boarding-pass/boarding-pass.component";

interface BoardingPassesChunkComponentProps extends PropsWithChildren {
    chunkSize: number;
    bookingKey: string;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
    allBoardingPassesByPassengerKey: Record<string, IPassengerSegmentBoardingPassViewModel[]>;
    hideExportButtons: boolean;
    pageBreak: boolean;
}

export const BoardingPassesChunkComponent: React.FC<BoardingPassesChunkComponentProps> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: `repeat(${props.chunkSize}, 1fr)`,
        gap: services.theme.currentTheme.spacing.extraLarge
    };

    if(props.pageBreak) {
        styles.pageBreakAfter = 'always';
        styles.breakAfter = 'always';
    }

    const renderDummyElements = () => {
        const count = props.chunkSize - props.boardingPasses.length;
        let result: React.ReactElement[] = [];
        for(let i = 1; i<= count; i++) {
            result.push((<div key={`dummy_${i}`}/>));
        }

        return result;
    }

    return (
        <div style={styles}>
            {props.boardingPasses.map((bPass) => {
                return (
                    <BoardingPassComponent key={bPass.barCode}
                                           bookingKey={props.bookingKey}
                                           currentBoardingPass={bPass}
                                           allBoardingPassesForCurrentPassenger={props.allBoardingPassesByPassengerKey[bPass.passengerKey]}
                                           hideExportButtons={props.hideExportButtons} />
                )
            })}

            {renderDummyElements()}

        </div>
    )
});
