import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import styled from "styled-components";
import {InputContainerComponent} from "../../../../../../components/forms/input-container.component";
import {SearchMaskSpecialPriceMarketUserOptionsComponent} from "../../common/search-mask-special-price-market-user-options.component";

const OptionsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.spacing.small};
    min-height: 79px;
`

export const SearchMaskSpecialPriceMarketWebappComponent: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;

    if(!searchController.getSpecialPriceMarket()) {
        return null;
    }

    const renderInput = () => {
        return (
            <OptionsContainerBox>
              <SearchMaskSpecialPriceMarketUserOptionsComponent searchController={searchController} />
            </OptionsContainerBox>
        )
    }

    const errorMessage = searchController.fields.specialPriceMarketUserOption.error
                         || searchController.fields.specialPriceMarketUserAgreement.error;

    return (
        <InputContainerComponent renderInput={renderInput} showBorders={true} errorMessage={errorMessage}/>
    );

});
