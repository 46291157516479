import {IServiceFactory} from "../../../service-factory.interface";
import {ISsrsRawConfiguration} from "./ssrs-raw-configuration.interface";
import {SsrConfiguration} from "./ssr.configuration";

export class SsrsConfiguration {
    constructor(private readonly ssrsRawConfigs: ISsrsRawConfiguration, services: IServiceFactory) {
        this.flex = new SsrConfiguration(ssrsRawConfigs.flex, services);
        this.eckn = new SsrConfiguration(ssrsRawConfigs.eckn, services);
    }

    readonly flex: SsrConfiguration;
    readonly eckn: SsrConfiguration;

}
