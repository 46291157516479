import React from 'react';
import {ExtrasSectionLargeScreenComponentCommonProps} from "../../base-component/large-screen/extras-section.large-screen-component-common.props";
import {ExtrasSingleSsrSectionRenderer} from "../../base-renderers/extras-single-ssr-section-renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {ExtrasSectionSmallScreenComponent} from "../../base-component/small-screen/extras-section.small-screen.component";
import {ExtrasSsrsSectionPurchaseSummarySmallScreenComponent} from "../../base-component/small-screen/summary/extras-ssrs-section-purchase-summary.small-screen.component";

export class IncludedScbgExtrasSectionRenderer extends ExtrasSingleSsrSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.SCBG, booking);
    }
    shouldRender(): boolean {
        return this.booking.services.layout.isInSmallScreenView;
    }

    protected _renderSectionTitle(): string {
        return this.services.language.translate('Small Bag Included');
    }

    renderForLargeScreen(props: ExtrasSectionLargeScreenComponentCommonProps): React.ReactElement {
        return (<></>);
    }

    renderForSmallScreen(): React.ReactElement {
        return (
            <ExtrasSectionSmallScreenComponent sectionTitle={this._renderSectionTitle()}
                                               minDiscountedPrice={null}
                                               minStandardPrice={null}
                                               hasPurchases={true}
                                               showButton={false}
                                               modificationBlockingReason={null}
                                               renderIcon={() => this._renderSmallScreenIcon()}
                                               renderPurchaseSummary={() => <ExtrasSsrsSectionPurchaseSummarySmallScreenComponent ssrsAggregator={this._ssrsAggregator}/>}
            />
        );
    }



}
