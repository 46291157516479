import React, {CSSProperties} from "react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {BoardingPassFieldValueComponent} from "../../value/boarding-pass-field-value.component";
import {BoardingPassFieldLabelComponent} from "../../label/boarding-pass-field-label.component";

export const BoardingPassPriorityBoardingStatusComponent: React.FC<{hasPriority: boolean}> = (props) => {
    const services = useServices();

    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center"
    }
    return (
        <div style={styles}>
            <BoardingPassFieldLabelComponent styles={{paddingRight: services.theme.currentTheme.spacing.small}}>
                Priority:
            </BoardingPassFieldLabelComponent>
            <BoardingPassFieldValueComponent styles={{paddingTop: '0'}}>
                {props.hasPriority ? 'Yes' : 'No'}
            </BoardingPassFieldValueComponent>
        </div>
    );
}
