import {IServiceFactory} from "../../../service-factory.interface";
import {TimePeriod} from "../../../../types/time-period";
import {TimeSpan} from "../../../../types/time-span";
import {ISsrRawBundleConfiguration} from "./ssrs-raw-configuration.interface";
import {SsrConfigurationPeriod} from "./ssr-configuration-period";
import {NullableNumber} from "../../../../types/nullable-types";

export class SsrBundleCutOffConfiguration extends SsrConfigurationPeriod {
    constructor( minHoursToPurchaseBeforeFlight: number,
                 minHoursToUseBeforeFlight: number,
                 maxHoursToUseBeforeFlight: NullableNumber,
                 services: IServiceFactory) {
        super(minHoursToUseBeforeFlight, maxHoursToUseBeforeFlight, services)
        this.timeToPurchaseBeforeFlight = TimeSpan.fromMinutes(minHoursToPurchaseBeforeFlight * 60);
    }

    public readonly timeToPurchaseBeforeFlight: TimeSpan;

    canBePurchasedSeparately(flightDate: Date): boolean {
        return this.services.time.currentDate.getTime() < flightDate.getTime() - this.timeToPurchaseBeforeFlight.totalMilliseconds;
    }
}


export class SsrBundlesCutOffPeriod {
    constructor(startDate: string,
                endDate: string,
                bundles: Record<string, ISsrRawBundleConfiguration>,
                private readonly services: IServiceFactory) {
        this.period = new TimePeriod(this.services.time.parseIsoDate(startDate), this.services.time.parseIsoDate(endDate));
        Object.keys(bundles).forEach(bundleCode => {
            this.bundles[bundleCode] = new SsrBundleCutOffConfiguration(bundles[bundleCode].minHoursToPurchaseBeforeFlight,
                                                                  bundles[bundleCode].minHoursToUseBeforeFlight,
                                                                  bundles[bundleCode].maxHoursToUseBeforeFlight,
                                                                  services);
        });
    }

    private readonly period: TimePeriod;
    private bundles: Record<string, SsrBundleCutOffConfiguration> = {};

    canBePurchasedSeparately(flightDate: Date, bundleCode: string): boolean {
        const bundle = this.bundles[bundleCode];
        if(bundle) {
            return bundle.canBePurchasedSeparately(flightDate);
        }
        return false;
    }

    canBeUsed(flightDate: Date, bundleCode: string) {
        const bundle = this.bundles[bundleCode];
        if(bundle) {
            return bundle.canBeUsed(flightDate);
        }
        return false;
    }

    isInTheCutOfPeriod(date: Date, bundleCode?: string): boolean {
        if(bundleCode) {
            return this.period.contains(date) && Boolean(this.bundles[bundleCode]);
        } else {
            return this.period.contains(date);
        }

    }

    getMinimumTimeToPurchaseBeforeFlight(bundleCode: string): TimeSpan | null {
        const bundle = this.bundles[bundleCode];
        if(bundle) {
            return bundle.timeToPurchaseBeforeFlight;
        }

        return null;
    }

    getMinimumTimeToUseBeforeFlight(bundleCode: string): TimeSpan | null {
        const bundle = this.bundles[bundleCode];
        if(bundle) {
            return bundle.minTimeToUseBeforeFlight;
        }

        return null;
    }

    getMaximumTimeToUseBeforeFlight(bundleCode: string): TimeSpan | null {
        const bundle = this.bundles[bundleCode];
        if(bundle) {
            return bundle.maxTimeToUseBeforeFlight;
        }

        return null;
    }
}
