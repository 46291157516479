import {ISsrAvailability} from "./ssr-availability.iterface";
import {
    IDotRezAvailableSsr,
    IDotRezPassengerSsrAvailability
} from "../../../../dot-rez-api/data-contracts/booking/ssrs-availability/ssr-availability.data-contracts";
import {Check} from "../../../../../types/type-checking";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {SegmentModel} from "../../segment/segment.model";

export class RealSsrAvailabilityModel implements ISsrAvailability {
    constructor(private readonly ssrType: ISsrType,
                private readonly segment: SegmentModel,
                private readonly dotRezSsrAvailability: IDotRezAvailableSsr) {
    }

    get limitPerPassenger(): number {
        return this.dotRezSsrAvailability.limitPerPassenger;
    }
    get availableQuantity(): number {
        if(Check.isNullOrUndefined(this.dotRezSsrAvailability.available)) {
            return 9999;
        }

        if(this.segment.journey.booking.isCommitted) {
            // If the booking is committed when we add/remove SSRs to the booking in state the inventory is updated,
            // but this happens after we make the request to the booking/ssrs API.
            // When the user press +/- buttons the change happens only in memory so we need also to update the in memory availability until the request will be issued.
            return Math.max(0,
                this.dotRezSsrAvailability.available
                - this.segment.passengers.sum(passengerSegment => {
                    const ssr = passengerSegment.getSsr(this.ssrType);
                    return ssr.newQuantity - ssr.currentQuantity;
                }));
        } else {
            // If the booking is not committed yet, when we add/remove SSRs to the booking in state the inventory is not updated.
            // So we need to maintain the availability by ourselves.
            return Math.max(0,
                this.dotRezSsrAvailability.available
                - this.segment.passengers.sum(passengerSegment => passengerSegment.getSsr(this.ssrType).newQuantity));
        }

    }

    getPassengerAvailability(passengerKey: string): IDotRezPassengerSsrAvailability | null {
        return this.dotRezSsrAvailability.passengersAvailability.find(item => item.key === passengerKey)?.value || null;
    }
}


