import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {SectionSeparator} from "./section-separator";


const SectionContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
`

const SectionTitleBox =  styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding-bottom: 15px;
    line-height: 1;
`


interface FieldsSectionComponentProps extends PropsWithChildren {
    title: string | React.ReactElement;
}

export const FieldsSectionComponent: React.FC<FieldsSectionComponentProps> = observer((props) => {
    return (
        <SectionContainerBox>
            <SectionTitleBox>
                {props.title}
            </SectionTitleBox>
            {props.children}
            <SectionSeparator/>
        </SectionContainerBox>
    )
});
