import React from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";

import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";

import {VerticalSpacer} from "../../../../basic-components/spacing/vertical-spacer";


const DetailsBox = styled.div`
    padding: 0 ${props => props.theme.spacing.large};
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.xLarge};
    line-height: 1.3;
    text-align: justify;
`

const ChairImageBox = styled.img`
    margin: ${props => props.theme.spacing.large};
    max-height: 40%;
`

const ImageDescriptionBox = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.primary};
`

const TitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxLarge};
    text-align: center;
`

const DimensionsBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    text-align: center;
`

const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`


export enum PriorityBoardingSuggestionChoice {
    ChoosePriority = 1,
    ChooseSmallCabinBag = 2,
}

interface PriorityBoardingSuggestionDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}


export const PriorityBoardingSuggestionDialogComponent: React.FC<PriorityBoardingSuggestionDialogComponentProps> = (props) => {
    const services = useServices();

    const onChoosePriority = () => {
        props.dialogHandler.accept(PriorityBoardingSuggestionChoice.ChoosePriority);
    };

    const onChooseSmallBag = () => {
        props.dialogHandler.accept(PriorityBoardingSuggestionChoice.ChooseSmallCabinBag);
    };


    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Just one small cabin bag ?')}
                                           dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <DialogContentBox>
                    <DetailsBox>
                        {services.language.translate('If you bring a second bag or if your bag is too big to fit under the seat you will be charged an additional fee at the boarding gate.')}
                    </DetailsBox>
                    <ChairImageBox src={services.theme.currentTheme.assets.images.under_chair_bag_animation_gif.toString()}/>
                    <ImageDescriptionBox>
                        <TitleBox>{services.language.translate('Small bag under the seat')}</TitleBox>
                        <DimensionsBox>{services.language.translate('40 cm x 30 cm x 20 cm; max 10 kg')}</DimensionsBox>
                    </ImageDescriptionBox>
                </DialogContentBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={onChoosePriority}>
                    {services.language.translationFor('I prefer {ssrName}').withParams({
                        ssrName: services.ssrTypes.PBRD.name
                    })}
                </PrimaryButton>
                <VerticalSpacer/>
                <PrimaryButtonInverted onClick={onChooseSmallBag}>
                    {services.language.translate('Small bag is sufficient for my trip')}
                </PrimaryButtonInverted>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
}
