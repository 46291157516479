import React from "react";
import {useServices} from "../../hooks/use-services.hook";
import {observer} from "mobx-react";

export const OpenDialogsContainerComponent: React.FC = observer(() => {
    const services = useServices();

    return (
        <>
            {services.dialog.currentOpenDialogs.map(modal => modal.render())}
        </>
    );
});
