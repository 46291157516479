import {IBookingInsuranceQuoteViewModel} from "./booking-insurance-quote-view-model.interface";
import {
    ITravelInsuranceQuote,
    ITravelInsuranceQuotePackage
} from "../../../airline-webapi/responses/travel-insurance-quote.response";
import {BookingModel} from "../booking.model";
import {IInsurancePricePerPassengerType} from "./insurance-price-per-passenger-type.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {Price} from "../../../currency/price";
import {IPassengerType} from "../../../passenger-types/passengers-types.service.interface";
import {NullableString} from "../../../../types/nullable-types";

export class BookingInsuranceQuoteModel implements IBookingInsuranceQuoteViewModel {
    constructor(private readonly insurancePackage: ITravelInsuranceQuotePackage,
                private readonly booking: BookingModel) {
    }


    private get quote(): ITravelInsuranceQuote {
        return this.insurancePackage.quotes[0];
    }

    get packageId(): string {
        return this.insurancePackage.packageId;
    }

    get quoteId(): string {
        return this.quote.quoteId;
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    get policyTitle(): string {
        //return this.quote.policy.content.title;
        return this.services.language.translate('Travel Protection with COVID-19 Cover');
    }

    get totalPrice(): Price {
        return this.booking.createPrice(this.insurancePackage.total);
    }

    get policyInfoDocumentUrl(): NullableString {
        return this.quote.policyInfoDocumentUrl;
    }


    get pdsUrl(): string {
        return this.quote.pdsUrl;
    }

    get underwriterDisclaimer(): string {
        return this.quote.policy.underwriter.disclaimer;
    }


    get disclaimer(): string {
        return this.quote.policy.content.disclaimer;
    }

    get benefits(): string[] {
        //return (this.quote.benefits ?? []).map(b => b.description);
        return [
            this.services.language.translate('Trip interruption and cancellation'),
            this.services.language.translate('COVID-19-related issues'),
            this.services.language.translate('Emergency medical and dental cover'),
            this.services.language.translate('24/7 medical treatment and assistance'),
            this.services.language.translate('Missed or delayed flights'),
            this.services.language.translate('Baggage loss, delay or theft'),
        ]
    }

    get trustPilotLogoUrl(): string {
        return this.quote.trustPilotLogoUrl;
    }

    get protectionPeriodDescription(): NullableString {
        if(this.booking.flightSearchController.isOneWay) {
            return this.services.language.translationFor('You will be protected for {numberOfDays} days')
                .withParams({
                    numberOfDays: this.services.configuration.data.insurance.insuranceDaysForOneWayFlight
                });
        }

        return null;
    }
    get pricesPerPassengersDescription(): string {

        const pricesPerPassengerType = this.getPricesForAllPassengerTypes();

        let pricesDescriptions: string = '';
        if(pricesPerPassengerType.length === 0) {
            pricesDescriptions = this.services.language.translationFor('{price} per {passengerType}').withParams({
                price: pricesPerPassengerType[0].price.toString(),
                passengerType: pricesPerPassengerType[0].passengerType.description.toLowerCase()
            });
        } else {
            for(let i = 0; i < pricesPerPassengerType.length; i++) {
                const currentPrice = pricesPerPassengerType[i];

                const description = this.services.language.translationFor('{price} per {passengerType}')
                    .withParams({
                        price: currentPrice.price.toString(),
                        passengerType: currentPrice.passengerType.description.toLowerCase()
                });

                if(i === 0) {
                    pricesDescriptions = description;
                } else if(i === pricesPerPassengerType.length - 1) {
                    pricesDescriptions += ` ${this.services.language.translate('and')} ` + description
                } else {
                    pricesDescriptions += `, ` + description
                }
            }
        }

        return this.services.language.translationFor('Protect yourself from unexpected events before or during your trip. Add travel insurance for only {pricePerPassenger}, so you are covered for:')
            .withParams({
                pricePerPassenger: pricesDescriptions
            });
    }

    private getPricesForAllPassengerTypes(): IInsurancePricePerPassengerType[] {
        const result: IInsurancePricePerPassengerType[] = [];
        const distinctPassengerTypes = this.booking.passengers.getAllPersonsInTheBooking().distinct(p => p.passengerType.code, p => p.passengerType)
            .sort((t1, t2) => t2.getMinimumAge() - t1.getMinimumAge()); //sort descending

        for(let passengerType of distinctPassengerTypes) {
            if(passengerType.isAdult) {
                result.push({
                    passengerType: passengerType,
                    price: this.booking.createPrice(this.quote.adultPrice)
                })
            } else if(passengerType.isChild) {
                result.push({
                    passengerType: passengerType,
                    price: this.booking.createPrice(this.quote.childPrice)
                })
            } else {
                result.push({
                    passengerType: passengerType,
                    price: this.booking.createPrice(this.quote.infantPrice)
                })
            }
        }

        return result;

    }

    getPassengerTypePrice(passengerType: IPassengerType): Price {
        const price = this.getPricesForAllPassengerTypes().find(pt => pt.passengerType.code === passengerType.code)?.price;
        if(price) {
            return price;
        }

        return this.booking.createPrice(0);
    }

    getInfantPrice(): Price {
        return this.booking.createPrice(this.quote.infantPrice);
    }
}
