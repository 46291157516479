import React from "react";
import {observer} from "mobx-react";
import {IPassengerSsrEditorViewModel} from "../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {BagContainerBox} from "./bag-container.box";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {BagIconComponent} from "./bag-icon.component";
import {PriceWithDiscountComponent} from "../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../components/price/standard-price-label.component";
import {IncludedBagsBox} from "./included-bags.box";
import {BagsSectionTitleComponent} from "./bags-section-title.component";
import {ErrorMessageTextBoxComponent} from "../../../../components/error-message/error-message-text-box.component";
import {IPassengerAllBagsViewModel} from "../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";
import {SsrModificationBlockingReasonComponent} from "../../common-components/ssr-modification-blocking-reason.component";

const BagTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.primary};
    text-align: center;
    line-height: 1;
`

const SmallBagComponent: React.FC<{ssrEditor: IPassengerSsrEditorViewModel;}> = observer((props) => {
    const services = useServices();
    const isSelected = props.ssrEditor.newQuantity === 0 && props.ssrEditor.newQuantityWasSet;
    const onClick = async () => {
        props.ssrEditor.newQuantity = 0;
    }
    return (
        <BagContainerBox isSelected={isSelected} onClick={onClick} className={'tst-small-bag'}>
            <BagTitleBox>
                {services.language.translate('One small bag only')}
            </BagTitleBox>
            <BagIconComponent ssrType={services.ssrTypes.SCBG} isSelected={isSelected}/>
        </BagContainerBox>
    );
});

const PriorityIconBox = styled(BagIconComponent)`
    flex-grow: 1;
`

const PriorityBoardingPriceBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const PriorityBoardingComponent: React.FC<{ssrEditor: IPassengerSsrEditorViewModel;}> = observer((props) => {
    const services = useServices();
    const isSelected = props.ssrEditor.newQuantity > 0;
    const isIncluded = props.ssrEditor.quantityIncludedInBundle > 0;
    const onClick = async () => {
        props.ssrEditor.newQuantity = 1;
    }

    const renderIncluded = () => {
        if(!isIncluded) {
            return null;
        }
        return (
            <IncludedBagsBox>
                {services.language.translate('Included')}
            </IncludedBagsBox>
        )
    }

    return (
        <BagContainerBox isSelected={isSelected} onClick={onClick} className={'tst-priority'}>
            <BagTitleBox>
                {props.ssrEditor.ssrType.name}
            </BagTitleBox>
            <PriorityIconBox ssrType={props.ssrEditor.ssrType} isSelected={isSelected}/>
            {
                !isIncluded && (
                    <PriorityBoardingPriceBox>
                        <PriceWithDiscountComponent discountedPrice={props.ssrEditor.discountedMinPrice}
                                                    standardPrice={props.ssrEditor.standardMinPrice}
                                                    label={<StandardPriceLabelComponent/>} decimalPartFontSize={services.theme.currentTheme.fontSize.xxSmall}/>
                    </PriorityBoardingPriceBox>
                )
            }

            {renderIncluded()}
        </BagContainerBox>
    );
});

const CabinBagsBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
`

const CabinBagsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.medium};
`

interface PassengerCabinBagsComponentProps {
    ssrEditor: IPassengerSsrEditorViewModel;
    className?: string;
}

export const PassengerCabinBagsComponent: React.FC<PassengerCabinBagsComponentProps> = observer((props) => {

    const renderSmallCabinBag = () => {
        if(props.ssrEditor.quantityIncludedInBundle > 0) {
            return null;
        }

        if(!props.ssrEditor.canBeModifiedForAtLeastOneSegment) {
            return null;
        }

        return (
            <SmallBagComponent ssrEditor={props.ssrEditor}/>
        );
    }

    return (
        <CabinBagsBox className={props.className}>
            {renderSmallCabinBag()}
            <PriorityBoardingComponent ssrEditor={props.ssrEditor}/>

        </CabinBagsBox>
    );
});

interface PassengerCabinBagSelectionComponentProps {
    passengerBags: IPassengerAllBagsViewModel;
}

export const PassengerCabinBagSelectionComponent: React.FC<PassengerCabinBagSelectionComponentProps> = observer((props) => {
    const services = useServices();
    const cabinBag = props.passengerBags.cabinBag;

    const isIncludedInBundle = cabinBag.quantityIncludedInBundle > 0;

    let ssrTypesToInfo = [services.ssrTypes.SCBG, cabinBag.ssrType];
    if(isIncludedInBundle) {
        ssrTypesToInfo = [cabinBag.ssrType]
    }

    const captureElementRef = (el: any) => {
        props.passengerBags.attachCabinBagsContainerDOMElement(el);
    }

    return (
        <CabinBagsContainerBox className='tst-cabin-bags' ref={captureElementRef}>
            <BagsSectionTitleComponent title={services.language.translate('Cabin baggage')}
                                       promotionalMessage={isIncludedInBundle ? null : services.language.translate('Limited offer')}
                                       showDisclaimer={true}
                                       ssrsTypes={ssrTypesToInfo}/>
            <PassengerCabinBagsComponent ssrEditor={cabinBag}/>
            <ErrorMessageTextBoxComponent errorMessage={props.passengerBags.cabinBagErrorMessage}/>
            <SsrModificationBlockingReasonComponent reason={props.passengerBags.cabinBag.modificationBlockingReason}/>
        </CabinBagsContainerBox>
    );
});
