import {IServiceFactory} from "../service-factory.interface";
import {IOneWayLowFareReader, IRoundTripLowFareReader} from "./low-fare-readers/low-fare-reader.interface";
import {NullableStation} from "../stations/station.service.interface";
import {VoidOneWayLowFareReader} from "./low-fare-readers/void-low-fare-reader";
import {RoundTripLowFareReader} from "./low-fare-readers/round-trip-low-fare-reader";
import {IFlightSearchControllerViewModel} from "../flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {makeObservable, observable, reaction, runInAction, IReactionDisposer} from "mobx";
import {NullableNumber} from "../../types/nullable-types";
import {IFlightScheduleFilter} from "../flight-search/flights-schedule/flight-schedule-filter.interface";


export class RoundTripLowFareReaderFactory {
    constructor(private readonly services: IServiceFactory,
                private readonly searchController: IFlightSearchControllerViewModel,
                private readonly useActualPassengersCount: boolean,
                private readonly getCurrency: () => string) {
        makeObservable<this, '_lowFareReader'>(this, {
            _lowFareReader: observable.ref
        });

        this._reactions.push(reaction(() => [
            this.searchController.isOneWayDepartureTrip,
            this.searchController.isOneWayReturnTrip,
            this.searchController.departureOrigin,
            this.searchController.departureDestination,
            this.searchController.returnOrigin,
            this.searchController.returnDestination,
            this.searchController.withBlueBenefits,
            this.searchController.departureClassOfServices,
            this.searchController.returnClassOfServices,
            this.searchController.departureMinPrice,
            this.searchController.returnMinPrice,
            this.searchController.departureFlightScheduleFilter,
            this.searchController.returnFlightScheduleFilter,
            this.searchController.specialPriceMarketUserOption,
            this.searchController.specialPriceMarketUserAgreement
        ], () => {
            this._lowFareReader = null;
        }));

        this._reactions.push(reaction(() => this.services.application.isActive,
            (isActive) => {
                if (isActive) {
                    this._lowFareReader = null;
                }
            }
        ))
    }

    private _reactions: IReactionDisposer[] = [];


    private _lowFareReader: IRoundTripLowFareReader | null = null;

    private _createOneWayLowFareReader(origin: NullableStation,
                                       destination: NullableStation,
                                       getClassOfServices: () => string[],
                                       scheduleFilter: IFlightScheduleFilter | null,
                                       getMinPriceAmount: () => NullableNumber): IOneWayLowFareReader {
        if(!origin || !destination) {
            return new VoidOneWayLowFareReader();
        }

        if(origin.stationCode === destination.stationCode) {
            return new VoidOneWayLowFareReader();
        }

        scheduleFilter = scheduleFilter || {
            shouldFilterOutDate: (): boolean => {
                return false;
            }
        };

        return this.services.lowFare.createLowFareReader(
            origin,
            destination,
            () => this.searchController.getFlightSearchSession(),
            getClassOfServices,
            getMinPriceAmount,
            this.getCurrency,
            scheduleFilter,
            () => {
                if(this.useActualPassengersCount) {
                    return this.searchController.passengers.createPassengerTypesForLowFareSearch();
                } else {
                    return [
                        {
                            type: this.services.passengerTypes.getPassengerTypeCodeForLowFareSearch(this.searchController.withBlueBenefits),
                            count: 1
                        }
                    ]
                }
            });
    }

    get lowFareReader(): IRoundTripLowFareReader {
        if(this._lowFareReader) {
            return this._lowFareReader;
        }

        const departureLowFareReader = this._createOneWayLowFareReader(this.searchController.departureOrigin,
                                                                       this.searchController.departureDestination,
                                                                       () => this.searchController.departureClassOfServices,
                                                                       this.searchController.departureFlightScheduleFilter,
                                                                       ()=> this.searchController.departureMinPrice);

        const returnLowFareReader = this._createOneWayLowFareReader(this.searchController.returnOrigin,
                                                                    this.searchController.returnDestination,
                                                                    () => this.searchController.returnClassOfServices,
                                                                    this.searchController.returnFlightScheduleFilter,
                                                                    () => this.searchController.returnMinPrice);

        const newLowFareReader = new RoundTripLowFareReader(departureLowFareReader, returnLowFareReader);
        runInAction(() => {
            this._lowFareReader = newLowFareReader;
        });

        return newLowFareReader;
    }

    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }
}
