import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../hooks/use-services.hook";
import {NullableString} from "../../../types/nullable-types";
import styled from "styled-components";
import { ExclamationTriangle } from "../../../assets/svg/exclamation-triangle.icon";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../components/modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../../../components/continue-button/continue-button.component";
import {IPspError} from "../../../services/airline-webapi/responses/psp-error";
import {PspErrorDetailsComponent} from "./psp-error-details.component";


const PaymentFailedContent = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px 40px;
`

const ErrorMessageTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxxLarge};
    color: ${props => props.theme.colors.secondary};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    text-align: center;
    padding: 18px 0 24px;
`;


export const PaymentFailedPage: React.FC<{dialogHandler: IDialogConfirmationHandler; errorMessage: NullableString; pspErrorDetails: IPspError[] | null}> = observer((props) => {
    const services = useServices();

    let errorMessage = props.errorMessage;
    if(!errorMessage || errorMessage === '0000') { //sometimes payment hub returns this stupid error code
        errorMessage = services.language.translate('There was an error processing your payment.')
    }


    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Payment failed!')} dialogHandler={props.dialogHandler}/>

            <StandardDialogContentComponent>
                <PaymentFailedContent>
                    <ExclamationTriangle size={6} color={services.theme.currentTheme.colors.secondary}/>
                    <ErrorMessageTitleBox>
                        {errorMessage}
                    </ErrorMessageTitleBox>
                    <PspErrorDetailsComponent pspErrorDetails={props.pspErrorDetails}/>
                </PaymentFailedContent>
            </StandardDialogContentComponent>

            <StandardDialogFooterComponent dialogHandler={props.dialogHandler} horizontallyCentered={true}>
                <ContinueButtonComponent onClick={() => props.dialogHandler.accept()} fullWidth={true} />
            </StandardDialogFooterComponent>

        </StandardDialogPageComponent>
    )
});
