import React from "react";
import styled , {css} from "styled-components";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {Price} from "../../../../../services/currency/price";
import {observer} from "mobx-react";
import {PriceComponent} from "../../../../../components/price/price.component";
import {useServices} from "../../../../../hooks/use-services.hook";

const BundleHeaderBox = styled.div<{backgroundColor: string, textColor: string, isSelected: boolean, isBasicBundle?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
   
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    ${
        props => props.isSelected 
                ? css`
                  border-top-left-radius: ${props => props.theme.border.radius(0.5)}px;
                  border-top-right-radius: ${props => props.theme.border.radius(0.5)}px;
                `
                : css`
                  border-top-left-radius: ${props => props.theme.border.defaultRadius};
                  border-top-right-radius: ${props => props.theme.border.defaultRadius};
                `
    }
`

const BundleNameBox = styled.span`
    flex-grow: 1;
`
const BundlePrefixBox = styled.span`
    font-weight: ${props => props.theme.fontWeight.normal};
`

interface BundleHeaderComponentProps {
    bundle: IBundleViewModel;
    price: Price;
    backgroundColor: string;
    textColor: string;
}

export const BundleHeaderComponent: React.FC<BundleHeaderComponentProps> = observer((props) => {
    const services = useServices();
    const bundle = props.bundle;

    return (
        <BundleHeaderBox isSelected={bundle.isSelected}
                         backgroundColor={props.backgroundColor}
                         textColor={props.textColor}
                         isBasicBundle={bundle.bundleType.isBasicBundle}>
            <BundlePrefixBox>{bundle.bundleType.prefix}</BundlePrefixBox>
            <BundleNameBox>
                {bundle.bundleType.shortName}
            </BundleNameBox>
            <PriceComponent price={props.price} label="+" decimalPartFontSize={services.theme.currentTheme.fontSize.xLarge} />
        </BundleHeaderBox>)
});
