import {ILocalStorageService} from "./local-storage.service.interface";
import {NullableString} from "../../types/nullable-types";
import {Check} from "../../types/type-checking";
import {LocalStorageKeys} from "./local-storage-keys";
import {ServiceBase} from "../service-base";

export class LocalStorageService extends ServiceBase implements ILocalStorageService {

    private _composeEnvKey(key: string): string {
        return `${key}.${this.services.configuration.currentEnvironmentType}`;
    }

    setItem(key: string, value: string): void {
        localStorage.setItem(this._composeEnvKey(key), value);
    }

    getItem(key: string): NullableString {

        const envKey = this._composeEnvKey(key);
        const existingData = localStorage.getItem(key);
        if(existingData) {
            localStorage.removeItem(key);
            localStorage.setItem(envKey, existingData);
            return existingData;
        } else {
            return localStorage.getItem(envKey)
        }
    }

    setJson(key: string, value: object): void {
        this.setItem(key, JSON.stringify(value));
    }

    getJson<TValue = any>(key: LocalStorageKeys): TValue | null {
        const value = this.getItem(key);

        if (Check.isNullOrUndefined(value)) {
            return null;
        } else {
            return JSON.parse(value) as TValue;
        }        
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
        localStorage.removeItem(this._composeEnvKey(key));
    }
}
