import {ICheckInRestriction} from "./check-in-restriction.interface";
import {IServiceFactory} from "../../../service-factory.interface";

export class GroupBookingOnlineCheckInRestriction implements ICheckInRestriction {
    constructor(private readonly services: IServiceFactory) {
    }

    get description(): string {
        return this.services.language.translate('Web Check-in restricted. Your ticket allows airport check-in free of charge.');
    }
    get shouldBlockSavingTravelDocuments(): boolean {
        return false;
    }
}
