import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';


export const LouSsrSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} sizeIsHeight {...props} viewBox="0 0 20 20" ratio={20/20}>
            <g fill={props.color? props.color: "currentColor"}>
                <path d="M19.7,4c0.4-0.4,0.4-0.9,0-1.3c-0.2-0.2-0.4-0.3-0.6-0.3h-2.5l0.9-0.9c0.2-0.2,0.2-0.4,0-0.6
		c-0.2-0.2-0.4-0.2-0.6,0l0,0l-1.5,1.5H0.9C0.4,2.5,0,2.9,0,3.4C0,3.7,0.1,3.9,0.3,4l8.8,8.8v4.4H6.6c-0.5,0-0.9,0.4-0.9,0.9
		c0,0.5,0.4,0.9,0.9,0.9h6.7c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-2.5v-4.4L19.7,4z M13.6,4.3l-2.1,2.1
		C10.7,6,9.8,6.2,9.4,7c-0.3,0.5-0.3,1,0,1.5L8.9,8.9c-0.2,0.2-0.2,0.4,0,0.6c0.2,0.2,0.4,0.2,0.6,0L10,9.1c0.7,0.4,1.7,0.1,2.1-0.6
		c0.3-0.5,0.3-1,0-1.5l2.7-2.7h2.2L10,11.2L3.1,4.3L13.6,4.3z M10.3,8.2L10.3,8.2L10.3,8.2c-0.3-0.3-0.3-0.7,0-1
		c0.3-0.3,0.7-0.3,1,0c0.3,0.3,0.3,0.7,0,1C10.9,8.5,10.5,8.5,10.3,8.2L10.3,8.2z"/>
            </g>

        </SvgIcon>
    )
}
