import React from "react";
import {observer} from "mobx-react-lite";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {ShoppingCartPassengerTypeFareDetailsButtonComponentProps} from "./shopping-cart-passenger-type-fare-details-button.component";
import {ShoppingCartPriceDetailsSectionComponent} from "../section/shopping-cart-price-details-section.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {ShoppingCartPriceDetailsDialogComponent} from "../price-details-dialog/shopping-cart-price-details-dialog.component";


export interface ShoppingCartPassengerTypeFareDetailsDialogComponentProps extends ShoppingCartPassengerTypeFareDetailsButtonComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}

export const ShoppingCartPassengerTypeFareDetailsDialogComponent: React.FC<ShoppingCartPassengerTypeFareDetailsDialogComponentProps> = observer((props) => {
    const services = useServices();

    const renderBundlePriceDetails = () => {
        if(!props.currentBundlePrice) {
            return null;
        }

        return (
            <ShoppingCartPriceDetailsSectionComponent title={services.language.translate('Bundle')}
                                                      initialPrice={props.initialBundlePrice || null}
                                                      currentPrice={props.currentBundlePrice}/>
        );
    }

    return (
        <ShoppingCartPriceDetailsDialogComponent title={props.description} dialogHandler={props.dialogHandler}>
            <ShoppingCartPriceDetailsSectionComponent title={services.language.translate('Fare')}
                                                      initialPrice={props.initialFare}
                                                      currentPrice={props.currentFare}/>

            {renderBundlePriceDetails()}
        </ShoppingCartPriceDetailsDialogComponent>

    );
});
