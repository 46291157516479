import {IFeeCodesService} from "./fee-codes.service.interface";
import {IFeeCode} from "./fee-code.interface";
import {FlexFeeCode} from "./codes/flex/flex.fee-code";
import {FeeCodeBase} from "./codes/fee-code-base";

export class FeeCodesService implements IFeeCodesService {

    constructor() {
        this._addWellKnownFeeCode(new FlexFeeCode());
    }

    private _addWellKnownFeeCode(feeCode: IFeeCode) {
        this._wellKnownFeeCodes[feeCode.code] = feeCode;
    }

    private _wellKnownFeeCodes: Record<string, IFeeCode> = {};

    getFeeCode(code: string): IFeeCode {
        if(this._wellKnownFeeCodes[code]) {
            return this._wellKnownFeeCodes[code];
        }
        return new FeeCodeBase(code);
    }
}
