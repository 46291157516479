import {
    IPassengerSegmentBoardingPassViewModel
} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {PassengerBoardingPassesSelector} from "./passenger-boarding-passes-selector";
import {IServiceFactory} from "../../../../services/service-factory.interface";

export class PassengerBoardingPassesGroupSelector {

    constructor(private readonly services: IServiceFactory, public readonly groupNumber: number) {
    }

    public readonly passengersSelectors: PassengerBoardingPassesSelector[] = [];

    get title(): string {
        return this.services.language.translationFor('Group {groupNumber}').withParams({
            groupNumber: this.groupNumber.toString()
        });
    }

    addPassengerSelector(passengerBoardingPasses: IPassengerSegmentBoardingPassViewModel[]): void {
        this.passengersSelectors.push(new  PassengerBoardingPassesSelector(this.groupNumber, passengerBoardingPasses));
    }

    get boardingPassesCount(): number {
        return this.passengersSelectors.sum(ps => ps.passengerBoardingPasses.length);
    }

    get selectors(): PassengerBoardingPassesSelector[] {
        return this.passengersSelectors;
    }

    getSelectedBoardingPasses(): IPassengerSegmentBoardingPassViewModel[] {
        return this.passengersSelectors.filter(ps => ps.isSelected)
            .selectMany(ps => ps.passengerBoardingPasses);
    }
}