import {ITimeService, TimeOfTheDay} from "./time.service.interface";
import {
    format,
    addDays,
    addYears,
    addHours,
    addMinutes,
    addSeconds,
    addMilliseconds,
    addMonths,
    eachDayOfInterval,
    max,
    min,
    differenceInCalendarDays,
    differenceInCalendarYears,
    lastDayOfMonth,
    eachWeekOfInterval,
    Locale,
    parseISO as dateFnsParseIso,
    differenceInHours
} from "date-fns";
import { Check } from "../../types/type-checking";
import { ServiceBase } from "../service-base";
import { TimeSpan } from "../../types/time-span";
import {NullableDate, NullableString, NullableUndefinedDate, NullableUndefinedString} from "../../types/nullable-types";
import {MonthModel} from "./month.model";

export class TimeService extends ServiceBase implements ITimeService {
    get currentDate(): Date {
        return new Date();
    }

    parseIsoDate(dateAsString: string): Date {
        const d = dateFnsParseIso(dateAsString);
        if(!Check.isDate(d)) {
            throw new Error(`Failed to parse date ${dateAsString}`);
        }

        return d;
    }

    convertToDate(date: Date | string): Date {
        if (Check.isString(date)) {
            return this.parseIsoDate(date);
        } else {
            return date;
        }
    }

    customFormat(date: Date, formatString: string): string {
        return format(date, formatString);
    }

    tryConvertToDate(date: NullableUndefinedDate | NullableUndefinedString): NullableDate {
        if (!date) {
            return null;
        }

        try {
            return this.convertToDate(date);
        } catch (err) {
            this.services.logger.error(`Failed to parse date ${date}`, err);
            return null;
        }

    }

    formatHHmm(date: NullableUndefinedDate | NullableUndefinedString): string {
        if (!date) {
            return '';
        }
        return format(this.convertToDate(date), 'HH:mm', {
            locale: this.services.language.currentLocale
        });
    }

    formatHHmmss(date: NullableUndefinedDate | NullableUndefinedString): string {
        if (!date) {
            return '';
        }
        return format(this.convertToDate(date), 'HH:mm:ss', {
            locale: this.services.language.currentLocale
        });
    }

    formatYYY_MM_DD(date: NullableDate | NullableString): string {
        if (!date) {
            return "";
        }
        return format(this.convertToDate(date), 'yyyy-MM-dd');
    }

    formatDD_MM_YYY(date: NullableDate | NullableString): string {
        if (!date) {
            return "";
        }
        return format(this.convertToDate(date), 'dd-MM-yyyy');
    }

    formatMM_DD_YYYY_withSlash(date: NullableDate | NullableString): string {
        if (!date) {
            return "";
        }
        return format(this.convertToDate(date), 'MM/dd/yyyy');
    }

    formatYYYMMDD(date: Date | string): string {
        if (!date) {
            return "";
        } 
        return format(this.convertToDate(date), 'yyyyMMdd');
    }

    formatUtc(date: Date | string): string {
        return this.convertToDate(date).toISOString();
    }

    formatUserFriendlyDate(date: NullableUndefinedDate | NullableUndefinedString): string {
        if(!date) {
            return "";
        }

        return format(this.convertToDate(date), 'PP', {
            locale: this.services.language.currentLocale
        });
    }

    formatUserFriendlyDayNameDayMonth(date: NullableUndefinedDate | NullableUndefinedString): string {
        if(!date) {
            return "";
        }

        return format(this.convertToDate(date), 'EEE dd MMM', {
            locale: this.services.language.currentLocale
        });
    }

    shortDateInCurrentLocale(date: Date | string): string {
        return format(this.convertToDate(date), 'P', {
            locale: this.services.language.currentLocale
        });
    }

    addDays(toDate: Date | string, days: number): Date {
        return addDays(this.convertToDate(toDate), days);
    }

    addMonths(toDate: Date | string, months: number): Date {
        return addMonths(this.convertToDate(toDate), months);
    }

    addYears(toDate: Date | string, years: number): Date {
        return addYears(this.convertToDate(toDate), years);
    }

    addHours(toDate: Date | string, hours: number): Date {
        return addHours(this.convertToDate(toDate), hours);
    }

    addMinutes(toDate: Date | string, minutes: number): Date {
        return addMinutes(this.convertToDate(toDate), minutes);
    }
    addSeconds(toDate: Date | string, seconds: number): Date {
        return addSeconds(this.convertToDate(toDate), seconds);
    }
    addMilliseconds(toDate: Date | string, milliseconds: number): Date {
        return addMilliseconds(this.convertToDate(toDate), milliseconds);
    }
    addTimeSpan(toDate: Date | string, timeSpan: TimeSpan): Date {
        return addMilliseconds(this.convertToDate(toDate), timeSpan.totalMilliseconds);
    }

    formatBirthDate(date: Date | NullableUndefinedDate | NullableUndefinedString): string {
        if (!date) {
            return '';
        }

        return this.formatYYY_MM_DD(this.convertToDate(date));
    }


    /**
     * Return the last date of the month
     * @param year
     * @param month is 1 based
     */
    lastDateOfTheMonth(year: number, month: number): Date {
        return addDays(addMonths(new Date(year, month - 1, 1), 1), -1);
    }

    getDateRange(startDate: Date | string, endDate: Date | string): Date[] {
        return eachDayOfInterval({
            start: this.convertToDate(startDate),
            end: this.convertToDate(endDate)
        })
    }

    makeShortDate(date: Date | string): Date {
        return this.parseIsoDate(this.formatYYY_MM_DD(date));
    }

    minDate(range: Date[]): Date {
        return min(range);
    }

    maxDate(range: Date[]): Date {
        return max(range);
    }

    differenceInHours(dateLeft: Date, dateRight: Date): number {
        return differenceInHours(dateRight, dateLeft);
    }
    
    differenceInCalendarDays(dateLeft: Date, dateRight: Date): number {
        return differenceInCalendarDays(dateRight, dateLeft);
    }

    differenceInCalendarYears(dateLeft: Date, dateRight: Date): number {
        return differenceInCalendarYears(dateRight, dateLeft);
    }

    formatTravelTime(travelTime: TimeSpan): string {
        return travelTime.toUserFriendlyString(this.services.language, {
            ignoreSeconds: true,
            useShortFormat: travelTime.minutes > 0 && travelTime.hours > 0
        });
    }

    computeAgeInYears(dateOfBirth: Date, referenceDate: Date): number {
        referenceDate = this.addDays(referenceDate, -1);
        dateOfBirth = this.convertToDate(this.formatYYY_MM_DD(dateOfBirth));
        const ageInMilliseconds = referenceDate.getTime() - dateOfBirth.getTime();
        const ageAsDateTime = new Date(ageInMilliseconds);
        const year = ageAsDateTime.getUTCFullYear();
        return Math.abs(year - 1970);
    }

    get locale(): Locale {
        return this.services.language.currentLocale;
    }

    getMonthFullNameFromIndex(monthIndex: number): string {
        return this.locale.localize?.month(monthIndex)
                || ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][monthIndex];
    }

    getMonthFullNameFromMonthNumber(monthNumber: number): string {
        return this.getMonthFullNameFromIndex(monthNumber - 1);
    }

    private _getDayNameNarrow(day: number): string {
        return this.locale.localize?.day(day, {width: 'narrow'})
            || ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day];
    }

    getWeekDaysNarrowNames(): string[] {
        return this.getWeekDaysOrder().map(day => this._getDayNameNarrow(day).toUpperCase());
    }

    getDayNameAbbreviation(day: number): string {
        return this.locale.localize?.day(day, {width: 'abbreviated'})
            || ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day];
    }

    getDayFullName(day: number): string {
        return this.locale.localize?.day(day)
            || ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day];
    }

    getWeekDaysAbbreviatedNames(): string[] {
        return this.getWeekDaysOrder().map(day => this.getDayNameAbbreviation(day).toUpperCase());
    }

    get firstDayOfTheWeek(): number {
        return this.locale.options?.weekStartsOn || 1;
    }

    getWeekDaysOrder(): number[] {
        const orderedWeekDays: number[] = [];
        for(let i = this.firstDayOfTheWeek; i <=6; i++) {
            orderedWeekDays.push(i);
        }

        for(let i = 0; i < this.firstDayOfTheWeek; i++) {
            orderedWeekDays.push(i);
        }

        return orderedWeekDays;

    }

    getMonthCalendarWeeks(month: number, year: number): Array<Date[]> {
        const weeks: Array<Date[]> = [];
        const firstDateOfTheMonth = new Date(year, month, 1);
        const lastDateOfTheMonth = lastDayOfMonth(firstDateOfTheMonth);
        const weeksStartingDates = eachWeekOfInterval({
            start: firstDateOfTheMonth,
            end: lastDateOfTheMonth
        }, {
            locale: this.locale
        });

        for(let weekFirstDate of weeksStartingDates) {
            const week: Date[] = [];
            const weekLastDate = addDays(weekFirstDate, 6);
            for(let d = weekFirstDate; d <= weekLastDate; d = addDays(d, 1)) {
                week.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            }

            weeks.push(week);
        }

        return weeks;
    }

    getMonthsInRange(from: Date, to: Date): Array<MonthModel> {
        const result: Array<MonthModel> = [];
        for(let d = new Date(from.getFullYear(), from.getMonth(), 1); d <= to; d = addMonths(d, 1)) {
            result.push(new MonthModel(d.getMonth(), d.getFullYear(), this));
        }
        return result;
    }


    areDatesEqual(date1: NullableUndefinedDate, date2: NullableUndefinedDate): boolean {
        return date1?.getTime() === date2?.getTime();
    }

    getTimeOfTheDay(): TimeOfTheDay {
        const hour = this.currentDate.getHours();
        if(hour >= 5 && hour <= 11){
            return TimeOfTheDay.Morning;
        } else if(hour >= 12 && hour <= 18){
            return TimeOfTheDay.Noon
        } else{
            return TimeOfTheDay.Evening;
        }
    }
}
