import {IPersistedBookingHistory} from "./persisted-booking-history.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {IBookingHistoryViewModel} from "./booking-history-view-model.interface";
import {JourneyHistoryModel} from "./journey-history.model";
import {IStation} from "../../stations/station.interface";
import {IDotRezBookingData} from "../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IDotRezBookingSession} from "../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {BoardingPassesReader} from "../../booking/boarding-pass/boarding-passes-reader";
import {makeObservable, observable, runInAction} from "mobx";
import {NullableUndefinedString} from "../../../types/nullable-types";
import {BookingModel} from "../../booking/models/booking.model";
import {Check} from "../../../types/type-checking";


export class BookingHistoryModel implements IBookingHistoryViewModel {
    constructor(public readonly tripInfo: IPersistedBookingHistory,
                public readonly services: IServiceFactory,
                bookingModel?: BookingModel) {
        this.departureDate = this.services.time.convertToDate(this.tripInfo.outbound.departure);
        if(bookingModel) {
            this.bookingModel = bookingModel;
        }
        this.flights.push(
            new JourneyHistoryModel(
                this,
                this.origin,
                this.destination,
                this.tripInfo.outbound));

        if(this.tripInfo.inbound) {
            this.flights.push(
                new JourneyHistoryModel(
                    this,
                    this.destination,
                    this.origin,
                    this.tripInfo.inbound));
        }

        makeObservable(this, {
            bookingModel: observable.ref,
            flights: observable.ref
        });
    }

    bookingModel: BookingModel | null = null;

    isMatch(otherBookingHistoryModel: BookingHistoryModel): boolean {
        if(Check.isEmpty(this.bookingKey) || Check.isEmpty(otherBookingHistoryModel.bookingKey)) {
            return this.recordLocator === otherBookingHistoryModel.recordLocator;
        }

        return this.bookingKey === otherBookingHistoryModel.bookingKey;
    }

    get bookingKey(): string {
        return this.tripInfo.bookingKey;
    }

    get recordLocator(): string {
        return this.tripInfo.recordLocator;
    }

    get firstName(): string {
        return this.tripInfo.firstName;
    }

    get lastName(): string {
        return this.tripInfo.lastName;
    }

    get email(): NullableUndefinedString {
        return this.tripInfo.email;
    }

    get origin(): IStation {
        const station = this.services.stations.tryGetStation(this.tripInfo.origin);
        if(station) {
            return station;
        }

        return {
            stationCode: this.tripInfo.origin,
            stationName: this.tripInfo.originName || this.tripInfo.origin
        };
    }

    get destination(): IStation {
        const station = this.services.stations.tryGetStation(this.tripInfo.destination);

        if(station) {
            return station;
        }

        return {
            stationCode: this.tripInfo.destination,
            stationName: this.tripInfo.destinationName || this.tripInfo.destination,
        };
    }

    readonly departureDate: Date;
    flights: JourneyHistoryModel[] = [];

    get hasFutureFlights(): boolean {
        return this.flights.some(f => f.isFutureFlight);
    }

    async startManageMyBooking(): Promise<void> {
        if(this.email) {
            await this.services.booking.startManageMyBookingWithFallback({
                lastName: this.lastName,
                emailAddress: this.email,
                recordLocator: this.recordLocator
            });
        } else {
            await this.services.booking.startManageMyBookingByLastName({
                lastName: this.lastName,
                recordLocator: this.recordLocator
            });
        }
    }

    async startCheckIn(): Promise<void> {
        if(this.email) {
            await this.services.booking.startCheckInWithFallback({
                lastName: this.lastName,
                emailAddress: this.email,
                recordLocator: this.recordLocator
            });
        } else {
            await this.services.booking.startCheckInByLastName({
                lastName: this.lastName,
                recordLocator: this.recordLocator
            });
        }

    }

    async showDetails(): Promise<void> {
        await this.services.booking.showFlightItinerary({
            emailAddress: this.email,
            lastName: this.lastName,
            recordLocator: this.recordLocator
        });
    }

    async refreshBookingData(): Promise<void> {
        if(!this.hasFutureFlights) {
            return;
        }

        let {session, bookingData} = await this._tryBringBookingInState();

        if(!bookingData) {
            return;
        }

        if(bookingData.journeys.length === 0) {
            runInAction(() => {
                this.flights = [];
            });
            this.services.bookingHistory.removeBooking(this.recordLocator);
            return;
        }

        const bookingStrategy = await this.services.booking.createTransientBookingStrategy(session, bookingData);

        this.services.bookingHistory.saveToMyTrips(bookingStrategy.booking);

        runInAction(() => {
            this.bookingModel = bookingStrategy.booking;
        });
        await this._updateBoardingPasses(session);
    }

    private async _tryBringBookingInState(): Promise<{session: IDotRezBookingSession, bookingData: IDotRezBookingData | null}> {
        let session = await this.services.user.createTransientBookingSession();
        let bookingData: IDotRezBookingData | null = null;
        try {
            bookingData = await session.bringBookingInStateByLastName({
                recordLocator: this.recordLocator,
                lastName: this.lastName
            });

        } catch (errFindByLastName) {
            this.services.logger.error(`Failed to retrieve booking ${this.recordLocator} by last name ${this.lastName}`, errFindByLastName);
            if(this.email) {
                try {
                    bookingData = await session.bringBookingInStateByEmail({
                        recordLocator: this.recordLocator,
                        emailAddress: this.email
                    });
                } catch (errFindByLastName) {
                    this.services.logger.error(`Failed to retrieve booking ${this.recordLocator} by email ${this.email}`, errFindByLastName);
                }
            }

        }

        return {
            session: session,
            bookingData: bookingData
        };
    }

    private get _hasAnyCheckedInPassengers(): boolean {
        return Boolean(this.bookingModel?.getAllPassengersSegments().some(ps => ps.isCheckedIn));
    }

    private get _shouldRefreshBoardingPasses(): boolean {
        if(!this.bookingModel) {
            return false;
        }

        return this.hasFutureFlights
            && !this.bookingModel.allJourneysAreCanceled
            && this.bookingModel.balanceDue.amount <= 0
            && this._hasAnyCheckedInPassengers;
    }


    private async _updateBoardingPasses(session: IDotRezBookingSession): Promise<void> {
        if(!this.bookingModel) {
            return;
        }

        if(!this._shouldRefreshBoardingPasses) {
            return;
        }

        try {
            const boardingPassesReader = new BoardingPassesReader(this.bookingModel.bookingData, session, this.services);
            const boardingPasses = await boardingPassesReader.getBoardingPasses();
            this.services.bookingHistory.saveBoardingPasses(boardingPasses);
        } catch (err) {
            this.services.logger.error(`Failed update boarding passes for ${this.bookingModel.recordLocator}`);
        }

    }
}
