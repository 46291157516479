import React, {PropsWithChildren} from 'react';
import {FootNoteBox} from "./boxes/foot-note.box";

interface FootNoteComponentProps extends PropsWithChildren {
    className?: string
}
export const FootNoteComponent: React.FC<FootNoteComponentProps> = (props) => {
    return (
        <FootNoteBox className={props.className}>
            {props.children}
        </FootNoteBox>
    );
}
