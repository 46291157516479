import React from "react";
import {observer} from "mobx-react";
import {IonSkeletonText} from "@ionic/react";
import styled from "styled-components";


const ButtonSkeletonBox = styled(IonSkeletonText)`
    width: 30%;
    height: auto;
    border-radius: 8px;
    margin: 0;
    flex: 0 0 calc(50% - 16px);
    min-height: 30px;
    border: 1px solid rgba(${props => props.theme.colors.mediumRgb}, 0.75);
`

export const NextFlightActionButtonsSkeletonsComponent: React.FC = observer(() => {
    return (
        <>
            <ButtonSkeletonBox animated/>
            <ButtonSkeletonBox animated/>

        </>
    );
});
