export class PromiseWaiter {
    constructor() {
        this._promise = new Promise<void>(resolve => {
            this._promiseResolver = resolve;
        })
    }

    private readonly _promise: Promise<void>;
    private _promiseResolver: null | ((value: void | PromiseLike<void>) => void) = null;

    resolve() {
        if(this._promiseResolver) {
            this._promiseResolver();
        }
    }

    wait(): Promise<void> {
        return this._promise;
    }
}
