import React from 'react';
import {observer} from "mobx-react-lite";
import {ISegmentViewModel} from "../../../../../../services/booking/models/segment/segment-view-model.interface";
import styled from "styled-components";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../../../components/designator/designator.component";
import {IPassengerSegmentViewModel} from "../../../../../../services/booking/models/passenger-segment/passenger-segment-view-model.interface";
import {ExclamationTriangle} from "../../../../../../assets/svg/exclamation-triangle.icon";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {CardBox} from "../../../../../../basic-components/card/card.box";
import {computeExtrasLargeScreenSummaryGridColumns} from "../../../base-component/large-screen/summary/compute-extras-large-screen-summary-grid-columns";

const PassengerSegmentBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${props => props.theme.fontSize.large};
`

const PassengerNameBox = styled.div`
    text-transform: uppercase;
`

const SeatNumberBox = styled.div`
    
`



const PassengerSeatComponent: React.FC<{passengerSegment: IPassengerSegmentViewModel}> = observer((props) => {
    const services = useServices();
    const renderSeatNumber = () => {
        if(props.passengerSegment.assignedSeat) {
            return props.passengerSegment.assignedSeat.seatNumber;
        } else {
            return (<ExclamationTriangle size={1.8} color={services.theme.currentTheme.colors.warningShade}/>)
        }
    }

    return (
        <PassengerSegmentBox>
            <PassengerNameBox>
                {props.passengerSegment.passengerFullName}
            </PassengerNameBox>
            <SeatNumberBox>
                {renderSeatNumber()}
            </SeatNumberBox>
        </PassengerSegmentBox>
    )
})

const DesignatorWrapper = styled(DesignatorComponent)`
    justify-content: flex-start;
`

const SegmentSeatsBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const SegmentSeatsComponent: React.FC<{segment: ISegmentViewModel}> = observer((props) => {
    return (
        <SegmentSeatsBox>
            <DesignatorWrapper designator={props.segment.designator} displayMode={DesignatorDisplayMode.stationsNamesOnly}/>
            {
                props.segment.passengers.map(ps => <PassengerSeatComponent key={ps.passengerKey} passengerSegment={ps}/>)
            }
        </SegmentSeatsBox>
    )
})

const SeatsSummaryBox = styled.div<{columnsCount: number}>`
    display: grid;
    grid-template-columns: repeat(${props => props.columnsCount}, 1fr);
    gap: ${props => props.theme.spacing.large};
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
`

export const ExtrasSeatsSummaryLargeScreenComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    let segments = booking.getAllSegments();

    let gridColumns = computeExtrasLargeScreenSummaryGridColumns(services, segments.length);


    return (
        <SeatsSummaryBox columnsCount={gridColumns}>
            {segments.map(segment => <SegmentSeatsComponent key={segment.segmentKey} segment={segment}/>)}
        </SeatsSummaryBox>
    )
})
