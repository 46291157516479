import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {FlightDatesSelectionPageContent} from "../../../flight-search/flight-dates-selection/components/flight-dates-selection-page-content.component";
import {FlightsDatesSelectionHeaderComponent} from "../../../flight-search/flight-dates-selection/components/flights-dates-selection-header.component";
import {FlightsDatesSelector} from "../../../../services/flight-search/dates-selection/flights-dates-selector";
import {BookingFlowPage} from "../../../booking-flow/booking-flow.page";
import {scrollElementIntoViewLazySmooth} from "../../../../utils/scroll-element-into-view";
import styled from "styled-components";
import {ZIndex} from "../../../../types/z-index";

const ManageMyBookingNewFlightsDatesSelectionPageContent: React.FC<{flightsDatesSelector: FlightsDatesSelector;}> = observer((props) => {
    const services = useServices();
    const [selectedDepartureDateElement, setSelectedDepartureDateElement] = useState<HTMLElement | null>(null);

    const captureSelectedDepartureDateElementRef = (date: Date, element: HTMLElement | null) => {
        if(props.flightsDatesSelector.isDepartureDate(date)) {
            setSelectedDepartureDateElement(element);
        }
    }

    useEffect(() => {
        if(selectedDepartureDateElement) {
            const timeoutRef = scrollElementIntoViewLazySmooth(selectedDepartureDateElement, {
                block: "nearest"
            });

            return () => {
                clearTimeout(timeoutRef);
            }
        }

        return () => {};

    }, [selectedDepartureDateElement, services.navigator.routes.manageMyBooking.selectNewFlightsDates.isActive]);




    return (
        <FlightDatesSelectionPageContent captureSelectedDateElementRef={captureSelectedDepartureDateElementRef}
                                         flightsDatesSelector={props.flightsDatesSelector}
                                         useSwiper={false}/>

    );
})

const HeaderForLargeScreenBox = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: ${ZIndex.StickyHeaders};
`

export const ManageMyBookingNewFlightsDatesSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const flightSearchController = services.booking.searchController;
    const flightsDatesSelector = flightSearchController.datesSelector;

    const getTitle = () => {
        if(flightSearchController.isOneWay) {
            return services.language.translate('New flight date');

        } else {
            return services.language.translate('New flights dates');
        }
    }

    const renderHeader = () => {
        if(services.layout.isInSmallScreenView) {
            return (
                <FlightsDatesSelectionHeaderComponent flightsDatesSelector={flightsDatesSelector} hideTripTypeSelector={true} />
            );
        }
        return null;
    }

    const renderContent = () => {
        if(services.layout.isInSmallScreenView) {
            return (
                <ManageMyBookingNewFlightsDatesSelectionPageContent flightsDatesSelector={flightsDatesSelector} />
            );
        }

        return (
            <>
                <HeaderForLargeScreenBox>
                    <FlightsDatesSelectionHeaderComponent flightsDatesSelector={flightsDatesSelector} hideTripTypeSelector={true} />
                </HeaderForLargeScreenBox>
                <ManageMyBookingNewFlightsDatesSelectionPageContent flightsDatesSelector={flightsDatesSelector} />
            </>
        )

    }

    return (
        <BookingFlowPage title={getTitle()}
                         renderExtraHeaderContent={renderHeader}
                         hideFooter={Boolean(services.booking.isBookingRestoreInProgress || flightsDatesSelector.isLoadingSchedule || flightsDatesSelector.scheduleLoadingError)}
                         renderContent={renderContent}
                         showPromotionCode={false}
                         hideShoppingCart={!services.layout.isInSmallScreenView}/>
    );
});
