import {IBookingHistoryService} from "./booking-history.service.interface";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {AnonymousUserBookingHistoryStrategy} from "./strategies/anonymous/anonymous-user-booking-history.strategy";
import {makeObservable, observable, reaction} from "mobx";
import {IJourneyHistoryViewModel} from "./models/journey-history-view-model.interface";
import {IPassengerSegmentBoardingPassViewModel} from "../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {IBookingHistoryStrategy} from "./strategies/booking-history-strategy.interface";
import {AuthorizedUserBookingHistoryStrategy} from "./strategies/authorized/authorized-user-booking-history.strategy";
import {BookingModel} from "../booking/models/booking.model";
import {VoidBookingHistoryStrategy} from "./strategies/void-strategy/void-booking-history.strategy";
import {ISearchBookingByEmailParams, ISearchBookingByLastNameParams} from "../booking/booking.service.interface";

export class BookingHistoryService extends ServiceBase implements IBookingHistoryService {
    constructor(services: IServiceFactory) {
        super(services);

        this._currentStrategy = new VoidBookingHistoryStrategy();

        makeObservable<this, '_currentStrategy'>(this, {
            _currentStrategy: observable.ref
        });

        reaction(() => this.services.user.isAuthorized,
            (isAuthorized) => {
                this._currentStrategy.dispose();
                if (isAuthorized) {
                    this._currentStrategy = new AuthorizedUserBookingHistoryStrategy(this.services);
                } else {
                    this._currentStrategy = new AnonymousUserBookingHistoryStrategy(this.services);
                }
            }, {
                fireImmediately: true
            });
    }

    private _currentStrategy: IBookingHistoryStrategy;

    get isLoadingHistoryInProgress(): boolean {
        return this._currentStrategy.isLoadingHistoryInProgress;
    }

    get myFutureFlights(): IJourneyHistoryViewModel[] {
        return this._currentStrategy.myFutureFlights;
    }

    get myPastFlights(): IJourneyHistoryViewModel[] {
        return this._currentStrategy.myPastFlights;
    }

    getSavedBoardingPasses(tripReferenceKey: string): IPassengerSegmentBoardingPassViewModel[] {
        return this._currentStrategy.getSavedBoardingPasses(tripReferenceKey);
    }

    retrieveBookingToHistoryByEmail(findByEmailRequest: ISearchBookingByEmailParams): Promise<void> {
        return this._currentStrategy.retrieveBookingToHistoryByEmail(findByEmailRequest);
    }

    retrieveBookingToHistoryByLastName(findByLastNameRequest: ISearchBookingByLastNameParams): Promise<void> {
        return this._currentStrategy.retrieveBookingToHistoryByLastName(findByLastNameRequest);
    }

    saveBoardingPasses(boardingPasses: IPassengerSegmentBoardingPassViewModel[]): IPassengerSegmentBoardingPassViewModel[] {
        return this._currentStrategy.saveBoardingPasses(boardingPasses);
    }

    saveToMyTrips(bookingModel: BookingModel): void {
        if(!this.services.layout.shouldUseWebappLayout) {
            this._currentStrategy.saveToMyTrips(bookingModel);
        }

    }

    getNextFlights(): IJourneyHistoryViewModel[] {
        return this._currentStrategy.getNextFlights();
    }

    removeBooking(recordLocator: string): void {
        return this._currentStrategy.removeBooking(recordLocator)
    }
}
