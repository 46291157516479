export enum AirlineWebapiErrorCodesEnum {
    Success = 0,
    GeneralError = 1,
    UserAlreadyExists = 2,
    InvalidCredentials = 3,
    AccountLockedOut = 4,
    UserDoesntExist = 5,
    PasswordAlreadyUsedInTheLast5Password = 6,
    CompanionAlreadyExist = 7,
    SessionTimeout = 8,
    MaximumCompanionLimitReached = 9,
    BookingNotEligibleForRefund = 10,
    BoardingPassToAppleWalletCountExceeded = 11,
    NskServerClassNotAvailable = 12,
    MissingClientPaymentCallbackUrls = 13,
    MissingBookingContact = 14,
    NotAllowedFee = 15,
    PassengersNotEligibleForDiscountFee = 16,
    RouteNotEligibleForDiscountFee = 17,
    BookingNotEligibleForFlightMove = 18,
    NskServerBookingWasModified = 19,
}
