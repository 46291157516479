import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {ManageMyBookingStationSelectionPage} from "./manage-my-booking-station-selection.page";
import {PlainLandingLineIcon} from "../../../../assets/svg/plane-landing-line";

export const ManageMyBookingDestinationSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.booking.searchController;

    return (<ManageMyBookingStationSelectionPage titleText={services.language.translate('Going to')}
                                                 plainIcon={<PlainLandingLineIcon color={services.theme.currentTheme.colors.secondary}/>}
                                                 stations={searchController.availableReturnStations}
                                                 recentlySearchedStations={searchController.recentlySearchedDestinations}
                                                 onStationSelected={async (station) => {
                                                     searchController.departureDate = null;
                                                     searchController.returnDate = null;
                                                     if(searchController.isOneWayReturnTrip) {
                                                         searchController.returnDestination = station;
                                                     } else {
                                                         searchController.departureDestination = station;
                                                     }

                                                     await services.booking.steps.nextStep();
                                                 }
                                                 } /> );
});
