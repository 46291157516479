import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {CheckInFlowStepBase} from "../common/check-in-flow-step.base";
import {IOnBeforeDeactivationContext} from "../../../../models/wizard/wizard.step.interface";
import {DialogResult} from "../../../dialog/dialog-enums";

export class CheckInFlowExtrasSelectionStep extends CheckInFlowStepBase {
    get title(): string {
        return this.services.language.translate('Extras');
    }

    get route() {
        return this.routes.extras;
    }

    protected async _onBeforeForwardDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        const result =  await super._onBeforeForwardDeactivation(context);
        if(result !== DialogResult.Accepted) {
            return result;
        }

        await waitForBookingMutations(this.booking);

        return DialogResult.Accepted;
    }

    async next(): Promise<void> {
        this._activateStepByRoute(this.routes.passengersDetails);
    }


}
