import React from "react";
import {WizardProgressIndicatorComponentProps} from "./wizard-progress-indicator.component.props";
import {observer} from "mobx-react-lite";
import {ScreenSizeBasedComponent} from "../../basic-components/layout/screen-size-based.component";
import {WizardProgressIndicatorSmallScreenComponent} from "./wizard-progress-indicator.small-screen.component";
import {WizardProgressIndicatorLargeScreenComponent} from "./wizard-progress-indicator.large-screen.component";


export const WizardProgressIndicatorComponent: React.FC<WizardProgressIndicatorComponentProps> = observer((props) => {
    return (
        <ScreenSizeBasedComponent renderForSmallScreen={() => (<WizardProgressIndicatorSmallScreenComponent {...props}/>)}
                                  renderForLargeScreen={() => (<WizardProgressIndicatorLargeScreenComponent {...props}/>)}/>
    )
});
