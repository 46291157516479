import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableDate} from "../../../types/nullable-types";
import React from "react";
import {observer} from "mobx-react";
import {DateValueViewComponent} from "./date-value-view.component";

interface DateFieldViewComponentProps {
    field: IFormField<NullableDate>;
}
export const DateFieldViewComponent: React.FC<DateFieldViewComponentProps> = observer((props) => {
    return (
        <DateValueViewComponent label={props.field.fieldName}
                                value={props.field.value}/>
    );
});
