import React from 'react';
import { useServices } from "../../hooks/use-services.hook";
import { observer } from 'mobx-react';
import { StandardDropDownComponent } from '../forms/standard-drop-down/standard-drop-down.component';
import {IFormField} from "../../models/forms/form-field.interface";
import { NullableString } from '../../types/nullable-types';
import {StandardDropDownListItemModel} from "../forms/standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";

export const LangSelectionComponent: React.FC<{field: IFormField<NullableString>; hideLabel?: boolean}> = observer((props) => {
    const services = useServices();
    const languages = services.language.supportedLanguages;

    const items = languages.map(c => new StandardDropDownListItemModel(c.code, c.name));
    
    return (
        <StandardDropDownComponent field={props.field} items={items} hideLabel={props.hideLabel}/>
    )
});
