import {
    IPassengersTypesService, IPassengerType,
    IPassengerTypeSelector,
    IPassengerTypeSelectorsList,
} from "./passengers-types.service.interface";
import { makeObservable, observable, runInAction } from "mobx";
import { IServiceFactory } from "../service-factory.interface";
import { ServiceBase } from "../service-base";
import { IDotRezAvailabilitySimpleRequestPassengers } from "../dot-rez-api/data-contracts/requests/booking/search-simple.request";
import { NullableDate, NullableNumber, NullableString } from "../../types/nullable-types";
import {IDotRezAvailabilityLowFarePassengers} from "../dot-rez-api/data-contracts/requests/booking/lowfare-search.request";

export class PassengersTypesService extends ServiceBase implements IPassengersTypesService {

    getPassengersSelectors(withBlueBenefits: () => boolean, initialCountValues?: Record<string, number>): IPassengerTypeSelectorsList {
        initialCountValues = initialCountValues || {};
        const passengerTypesSelectors = new PassengerTypesSelectors(this.services, withBlueBenefits);
        const adultSelector = new AdultSelector(this.services, passengerTypesSelectors, initialCountValues['ADT'] || initialCountValues['ADTA'] || 1);
        passengerTypesSelectors.push(adultSelector);
        passengerTypesSelectors.push(new ChildSelector(this.services, passengerTypesSelectors, initialCountValues['CHD'] || initialCountValues['CHDA'] || 0));
        passengerTypesSelectors.push(new InfantSelector(this.services, adultSelector, passengerTypesSelectors, initialCountValues['INF'] || 0));
        return passengerTypesSelectors;
    }

    getPassengerType(code: string): IPassengerType {
        const passengerType = this.tryGetPassengerType(code);
        if(passengerType) {
            return passengerType;
        }
        throw new Error(`Unsupported passenger type code ${code}`);
    }

    tryGetPassengerType(code: string): IPassengerType | null {
        switch (code) {
            case 'ADT':
                return new Adult(this.services);
            case 'CHD':
                return new Child(this.services);
            case 'INF':
                return new Infant(this.services);
            case 'ADTA':
                return new AdultBlueBenefits(this.services);
            case 'CHDA':
                return new ChildBlueBenefits(this.services);
            default:
                return null;
        }
    }

    getPassengerTypeCodeForLowFareSearch(withBlueBenefits: boolean): string {
        if(withBlueBenefits) {
            return 'ADTA';
        } else {
            return 'ADT';
        }
    }

    getInfantPassengerType(): IPassengerType {
        return this.getPassengerType('INF');
    }
}

class PassengerTypesSelectors extends Array<IPassengerTypeSelector> implements IPassengerTypeSelectorsList {

    constructor(private readonly services: IServiceFactory, public withBlueBenefits: () => boolean) {
        super();
    }

    get canAddMorePassengers(): boolean {
        return this.getSelectedOnly().sum(item => item.count) - this.countInfants() < this.maximumNumberOfPassengersAllowedOnBooking;
    }

    get maximumNumberOfPassengersAllowedOnBooking(): number {
        if(this.withBlueBenefits()) {
            return this.services.user.profile.blueBenefitsSubscription.maxNumberOfPassengers;
        }

        return this.services.configuration.data.maxAllowedPassengersNumberOnBooking;
    }

    get passengersMaximumLimitReachedErrorMessage(): NullableString {
        if(this.canAddMorePassengers) {
            return null;
        }

        return this.services.language.translationFor(`Maximum number of passengers allowed on a booking is {numberOfPassengers}`).withParams({
            numberOfPassengers: this.maximumNumberOfPassengersAllowedOnBooking
        });
    }

    getAll(): IPassengerTypeSelector[] {
        return this;
    }

    getSelectedOnly(): IPassengerTypeSelector[] {
        return this.filter(p => p.count > 0);
    }
    countAdults(): number {
        return this._countPassengerType(AdultSelector);
    }
    countChildren(): number {
        return this._countPassengerType(ChildSelector);
    }

    countAllWithoutInfants(): number {
        return this.countAdults() + this.countChildren();
    }

    findByCode(code: string): IPassengerTypeSelector {
        const index = this.findIndex(pTypeSelector => pTypeSelector.hasCode(code));
        if (index < 0) {
            throw new Error(`Could not find passenger type with code ${code}`);
        }
        return this[index];
    }

    countInfants(): number {
        return this._countPassengerType(InfantSelector);
    }

    private _countPassengerType(passengerType: any): number {
        return this.filter(selector => (selector instanceof passengerType))
            .reduce((count, selector) => count + selector.count, 0);
    }

    private _getSelectedMaturePassengerTypesSelectors(): IPassengerTypeSelector[] {
        return this.filter(selector => selector.count > 0 && !(selector instanceof InfantSelector));
    }

    createPassengerTypesForLowFareSearch(): IDotRezAvailabilityLowFarePassengers {
        return this.createPassengerTypesForAvailabilitySearch();
    }
    createPassengerTypesForAvailabilitySearch(): IDotRezAvailabilitySimpleRequestPassengers {
        return this._getSelectedMaturePassengerTypesSelectors()
            .map(selector => {
                return {
                    type: selector.getCodeForAvailabilitySearch(),
                    count: selector.count
                }
            });
    }

    createPassengerTypesForRegularSell(): IDotRezAvailabilitySimpleRequestPassengers {
        return this._getSelectedMaturePassengerTypesSelectors()
            .map(selector => {
                return {
                    type: selector.getCodeForRegularSell(),
                    count: selector.count
                }
            });
    }
    createPassengerTypesForBlueBenefitsSell(): IDotRezAvailabilitySimpleRequestPassengers {
        return this._getSelectedMaturePassengerTypesSelectors()
            .map(selector => {
                return {
                    type: selector.getCodeForBlueBenefitsSell(),
                    count: selector.count
                }
            });
    }

    limit(maxNumberOfMaturePassengers: number): void {
        let diff = this.countAllWithoutInfants() - maxNumberOfMaturePassengers;
        if(diff <= 0) {
            return;
        }

        const sortedByMinAgeAsc = this.filter(p => !(p instanceof InfantSelector) && p.count > 0)
                                   .sort((p1, p2) => p1.minimumAge - p2.minimumAge);

        for(let pSelector of sortedByMinAgeAsc) {
            const toReduce = Math.min(diff, pSelector.count);
            pSelector.count -= toReduce;
            diff -= toReduce;
            if(diff === 0) {
                return;
            }
        }
    }
}

abstract class PassengerType implements IPassengerType {
    protected constructor(
        protected readonly services: IServiceFactory,
        public readonly code: string
    ) {
    }

    public abstract get implicitTitleValue(): NullableString;
    abstract getMinimumAge(): number;
    protected abstract _getMaximumAge(): NullableNumber;
    abstract get description(): string;
    abstract get canBeMadePrimaryContact(): boolean;
    abstract get isAdult(): boolean;
    abstract get isChild(): boolean;

    get isBlueBenefits(): boolean {
        return false;
    }

    computeMinimumBirthDate(referenceDate: Date): NullableDate {
        const max = this._getMaximumAge();
        if (max) {
            const time = this.services.time;
            return time.addDays(time.addYears(time.makeShortDate(referenceDate), -max), 1);
        }
        return null;

    }
    computeMaximumBirthDate(referenceDate: Date): Date {
        const min = this.getMinimumAge();
        const time = this.services.time;
        return time.addYears(time.makeShortDate(referenceDate), -min);
    }

    get ageLimitsDescription(): string {
        const min = this.getMinimumAge();
        const max = this._getMaximumAge();

        if (min && max) {
            return this.services.language.translationFor('{min}-{max} YEARS').withParams({ min: min, max: max });
        }
        if (min) {
            return this.services.language.translationFor('{min}+ YEARS').withParams({ min: min });
        }

        if (max) {
            return this.services.language.translationFor('Under {max} YEARS').withParams({ max: max });
        }

        throw new Error(`Incorrect age limits specified for passenger type ${this.code}`);
    }
}


abstract class AdultBase extends PassengerType {
    get description(): string {
        return this.services.language.translate('Adult');
    }
    get implicitTitleValue(): NullableString {
        return null;
    }
    getMinimumAge(): number {
        return 12;
    }

    get isAdult(): boolean {
        return true;
    }

    get isChild(): boolean {
        return false;
    }

    protected _getMaximumAge(): NullableNumber {
        return null;
    }

    get canBeMadePrimaryContact(): boolean {
        return true;
    }
}

class Adult extends AdultBase {
    constructor(services: IServiceFactory) {
        super(services, 'ADT');
    }
}

class AdultBlueBenefits extends AdultBase {
    constructor(services: IServiceFactory) {
        super(services, 'ADTA');
    }

    get isBlueBenefits(): boolean {
        return true;
    }
}


abstract class ChildBase extends PassengerType {

    get description(): string {
        return this.services.language.translate('Child');
    }

    get implicitTitleValue(): NullableString {
        return 'CHD';
    }

    get isAdult(): boolean {
        return false;
    }

    get isChild(): boolean {
        return true;
    }

    getMinimumAge(): number {
        return 2;
    }

    protected _getMaximumAge(): NullableNumber {
        return 12;
    }

    get canBeMadePrimaryContact(): boolean {
        return false;
    }
}

class Child extends ChildBase {
    constructor(services: IServiceFactory) {
        super(
            services,
            'CHD',
        );
    }
}

class ChildBlueBenefits extends ChildBase {
    constructor(services: IServiceFactory) {
        super(
            services,
            'CHDA',
        );
    }

    get isBlueBenefits(): boolean {
        return true;
    }
}


class Infant extends PassengerType {
    constructor(services: IServiceFactory) {
        super(
            services,
            'INF'
        );
    }

    get description(): string {
        return this.services.language.translate('Infant');
    }

    getMinimumAge(): number {
        return 0;
    }

    protected _getMaximumAge(): NullableNumber {
        return 2;
    }

    get isAdult(): boolean {
        return false;
    }

    get isChild(): boolean {
        return false;
    }

    get canBeMadePrimaryContact(): boolean {
        return false;
    }

    get implicitTitleValue(): NullableString {
        return 'CHD';
    }
}

abstract class PassengerTypeSelectorBase implements IPassengerTypeSelector {
    protected constructor(protected regularPassengerType: PassengerType,
                          protected blueBenefitsPassengerType: PassengerType,
                          protected services: IServiceFactory,
                          protected passengersList: IPassengerTypeSelectorsList,
                          public _count: number = 0) {

        makeObservable(this, {
            _count: observable.ref
        });
    }

    hasCode(code: string): boolean {
        return this.regularPassengerType.code === code || this.blueBenefitsPassengerType.code === code;
    }

    getCodeForAvailabilitySearch(): string {
        if(this.passengersList.withBlueBenefits()) {
            return this.blueBenefitsPassengerType.code;
        } else {
            return this.regularPassengerType.code;
        }
    }

    getCurrentCode(): string {
        if(this.passengersList.withBlueBenefits()) {
            return this.blueBenefitsPassengerType.code;
        } else {
            return this.regularPassengerType.code
        }
    }
    getCodeForRegularSell(): string {
        return this.regularPassengerType.code;
    }

    getCodeForBlueBenefitsSell(): string {
        return this.blueBenefitsPassengerType.code;
    }

    getCurrentCodeForWebsiteSearch(): string {
        return this.getCurrentCode();
    }

    getAllCodes(): string[] {
        return [
            this.getCodeForRegularSell(),
            this.getCodeForBlueBenefitsSell(),
            this.getCurrentCodeForWebsiteSearch()
        ].distinct(item => item, item => item);
    }

    get description(): string {
        return this.regularPassengerType.description;
    }

    get ageLimitsDescription(): string {
        return this.regularPassengerType.ageLimitsDescription;
    }

    get minimumAge(): number {
        return this.regularPassengerType.getMinimumAge();
    }

    get count(): number {
        return this._count;
    }

    set count(value: number) {
        this._setCount(value);
    }

    get allowDecrement(): boolean {
        return this.count > this._getMinimumCount();
    }

    get allowIncrement(): boolean {
        return this.passengersList.canAddMorePassengers;
    }

    protected _setCount(value: number): void {
        if (value > this._count && !this.passengersList.canAddMorePassengers) {
            if(this.passengersList.withBlueBenefits()) {
                this.services.alert.showError(this.services.language.translationFor(`Your Blue Benefits subscription allows maximum {numberOfPassengers} passengers on a booking`).withParams({
                    numberOfPassengers: this.services.user.profile.blueBenefitsSubscription.maxNumberOfPassengers
                }));
            } else {
                this.services.alert.showError(this.services.language.translationFor(`Maximum number of passengers allowed on a booking is {numberOfPassengers}`).withParams({
                    numberOfPassengers: this.passengersList.maximumNumberOfPassengersAllowedOnBooking
                }));
            }

            return;
        }

        if (value < this._getMinimumCount()) {
            return;
        }

        runInAction(() => {
            this._count = Math.max(0, value);
        });
    }

    get summary(): string {
        return `${this.count} x ${this.description}`;
    }

    protected _getMinimumCount(): number {
        return 0;
    }
}

class AdultSelector extends PassengerTypeSelectorBase {
    constructor(service: IServiceFactory, passengersList: IPassengerTypeSelectorsList, count: number = 1) {
        super(new Adult(service), new AdultBlueBenefits(service),  service, passengersList, count);
    }

    protected _getMinimumCount() {
        return 1;
    }

    private _infantSelector: InfantSelector | null  = null;
    attachInfantSelector(infantSelector: InfantSelector): void {
        this._infantSelector = infantSelector;
    }

    protected _setCount(value: number) {
        super._setCount(value);

        if(!this._infantSelector) {
            return;
        }

        if(this._infantSelector.count > this.count) {
            this._infantSelector.count = this.count;
        }
    }

}

class ChildSelector extends PassengerTypeSelectorBase {
    constructor(services: IServiceFactory, passengersList: IPassengerTypeSelectorsList, count: number = 0) {
        super(new Child(services), new ChildBlueBenefits(services), services, passengersList, count);
    }
}

class InfantSelector extends PassengerTypeSelectorBase {
    constructor(services: IServiceFactory,
                private readonly adultSelector: AdultSelector,
                passengersList: IPassengerTypeSelectorsList, count: number = 0) {
        super(new Infant(services), new Infant(services), services, passengersList, count);
        adultSelector.attachInfantSelector(this);
    }
    get count(): number {
        return this._count;
    }

    set count(value) {
        if (value > this.adultSelector.count) {
            this.services.alert.showError(this.services.language.translate('Only one infant per adult is allowed'));
        } else {
            runInAction(() => {
                this._count = Math.max(0, value);
            });
        }
    }

    get allowIncrement(): boolean {
        return this.count < this.adultSelector.count;
    }

    getCurrentCodeForWebsiteSearch(): string {
        return "INL";
    }
}

