import {JourneyModel} from "../journey/journey.model";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {SsrModel} from "./ssr.model";
import {Check} from "../../../../types/type-checking";
import {MaturePassengerModel} from "../passenger/mature-passenger.model";
import {Price} from "../../../currency/price";
import {ISsrType, ISsrTypesService} from "../../../ssr-types/ssr-types.service.interface";
import {IJourneySsrsBucket, IJourneySsrsBucketOptions} from "./journey-ssrs-bucket.interface";
import {BookingModel} from "../booking.model";
import {NullableString} from "../../../../types/nullable-types";
import {IServiceFactory} from "../../../service-factory.interface";


export function composeJourneySsrsBucketIdentifier(ssrTypes: ISsrType[], passengersKeys: string[]): string {
    if(passengersKeys.length === 0) {
        return ssrTypes.map(ssrType => ssrType.ssrCode).join("_");
    } else {
        return [
            ...ssrTypes.map(ssrType => ssrType.ssrCode),
            ...passengersKeys
        ].join("_");
    }

}

/**
 * Base class for a group of SSRs for a specific journey
 * It also implements the base logic for the "Use same option for all flights" toggle
 */
export class JourneySsrsBucketModel implements IJourneySsrsBucket {
    constructor(public readonly parentJourney: JourneyModel,
                private readonly options: IJourneySsrsBucketOptions) {
        this.bucketIdentifier = composeJourneySsrsBucketIdentifier(this.ssrTypes, options.onlyForPassengersKeys);
        this._allSsrsInTheBucket = this.ssrTypes.map(ssrType => this._createSsrModel(ssrType));

        if(!this.booking.sameForAllFlightsStorage.isSet(this.bucketIdentifier)) {
            this.booking.sameForAllFlightsStorage.setValue(this.bucketIdentifier, this.passengersHaveTheSameQuantityOnAllFlights);
        }

        const useSameOptionsForAllPassengersStoredValue = parentJourney.storage.getItem(this._sameOptionsForAllPassengersStorageKey);
        if(Check.isNullOrUndefined(useSameOptionsForAllPassengersStoredValue)) {
            this._useSameOptionsForAllPassengers = options.useSameOptionsForAllPassengersDefaultValue ?? false;
        } else {
            this._useSameOptionsForAllPassengers = useSameOptionsForAllPassengersStoredValue === 'true';
        }

        makeObservable<this, '_useSameOptionsForAllPassengers'>(this, {
            _useSameOptionsForAllPassengers: observable.ref,
            canBeModified: computed,
            modificationBlockingReason: computed,
            standardMinPrice: computed,
            discountedMinPrice: computed,
            availableSsrsInTheBucket: computed,
            isCompletelyIncludedInBundle: computed,
            allowUseSameOptionsForAllFlights: computed,
            allowUseSameOptionsForAllPassengers: computed,
            passengersHaveTheSameQuantityOnAllFlights: computed
        });


    }

    public get ssrTypes(): ISsrType[] {
        return this.options.ssrTypes;
    }

    get booking(): BookingModel {
        return this.parentJourney.booking;
    }

    protected get services(): IServiceFactory {
        return this.booking.services;
    }

    get ssrTypesService(): ISsrTypesService {
        return this.booking.services.ssrTypes;
    }

    private readonly _allSsrsInTheBucket: SsrModel[];
    public getAllSsrsInTheBucket(): SsrModel[] {
        return this._allSsrsInTheBucket;
    }

    protected _createSsrModel(ssrType: ISsrType): SsrModel {
        return new SsrModel(ssrType, this)
    }

    readonly bucketIdentifier: string;

    private get _sameOptionsForAllPassengersStorageKey(): string {
        return `SameAllPassengers_${this.bucketIdentifier}`;
    }


    get uniqueKey(): string {
        return `${this.parentJourney.journeyKey}_${this.bucketIdentifier}`;
    }

    get countPassengers(): number {
        return this.getPassengers().length;
    }
    
    getPassengers(): MaturePassengerModel[] {

        if(this.options.onlyForPassengersKeys.length === 0) {
            return this.booking.passengers;
        } else {
            return this.booking.passengers.filter(p => this.options.onlyForPassengersKeys.includes(p.passengerKey));
        }
    }

    get passengersHaveTheSameQuantityOnAllFlights(): boolean {
        return this.getAllSsrsInTheBucket().all(ssr => ssr.passengersHaveTheSameQuantityOnAllFlights);
    }

    get availableSsrsInTheBucket(): SsrModel[] {
        return this.getAllSsrsInTheBucket().filter(ssr => this.booking.allAvailableSsrsCodes[ssr.ssrType.ssrCode]);
    }

    public get discountedMinPrice(): Price {
        return Price.min(this.availableSsrsInTheBucket.map(ssr => ssr.discountedMinPrice),
                        this.booking.createPrice(0));
    }

    public get standardMinPrice(): Price {
        return Price.min(this.availableSsrsInTheBucket.map(ssr => ssr.standardMinPrice),
            this.booking.createPrice(0));
    }

    get canBeModified(): boolean {
        return this.getAllSsrsInTheBucket().some(ssr => ssr.canBeModifiedForAtLeastOnePassenger);
    }

    get modificationBlockingReason(): NullableString {
        if(this.canBeModified) {
            return null;
        }

        for(let ssrInTheBucket of this.getAllSsrsInTheBucket()) {
            if(ssrInTheBucket.modificationBlockingReason) {
                return ssrInTheBucket.modificationBlockingReason;
            }
        }

        return null;
    }

    get isCompletelyIncludedInBundle(): boolean {
        return this.getAllSsrsInTheBucket().all(ssr => ssr.isCompletelyIncludedInBundle);
    }

    public get allowUseSameOptionsForAllFlights(): boolean {
        return this.booking.filteredJourneys.length > 1 && this.getAllSsrsInTheBucket().all(ssr => ssr.allowUseSameOptionsForAllFlights);
    }

    get useSameOptionsForAllFlights(): boolean {
        if(!this.allowUseSameOptionsForAllFlights) {
            return false;
        }

        return this.booking.sameForAllFlightsStorage.getValue(this.bucketIdentifier);

    }

    set useSameOptionsForAllFlights(value: boolean) {
        this._setUseSameOptionsForAllFlights(value);
    }

    protected _setUseSameOptionsForAllFlights(value: boolean) {
        if(value === this.useSameOptionsForAllFlights) {
            return;
        }

        if(!this.allowUseSameOptionsForAllFlights) {
            return;
        }


        runInAction(() => {
            this.booking.sameForAllFlightsStorage.setValue(this.bucketIdentifier, value);

            if(value) {
                const otherJourneysPassengersSsrEditors = this.getOtherJourneysBuckets().selectMany(bucket => bucket.getAllSsrsInTheBucket()
                                                                                                    .selectMany(ssr => ssr.passengersSsrEditors.map(passengerSsrEditor => {
                                                                                                                                                    return {
                                                                                                                                                        ssrCode: ssr.ssrType.ssrCode,
                                                                                                                                                        passengerSsrEditor: passengerSsrEditor
                                                                                                                                                    }})
                                                                                                    )).groupByKeyAndMapToType(item => item.ssrCode + item.passengerSsrEditor.passengerKey, item => item.passengerSsrEditor);

                for(let currentJourneySsr of this.getAllSsrsInTheBucket()) {
                    for(let currentJourneyPassengerSsrEditor of currentJourneySsr.passengersSsrEditors) {
                        for(let otherJourneyPassengerSsrEditor of otherJourneysPassengersSsrEditors[currentJourneySsr.ssrType.ssrCode + currentJourneyPassengerSsrEditor.passengerKey]) {
                            otherJourneyPassengerSsrEditor.applyNewQuantity(currentJourneyPassengerSsrEditor.newQuantity);
                        }
                    }
                }

                this._setUseSameOptionsForAllPassengersForOtherJourneys();
                this.booking.sellSsrs();
            }
        });
    }

    get allowUseSameOptionsForAllPassengers(): boolean {
        return !this.isCompletelyIncludedInBundle && this.getPassengers().length > 1 && this.getAllSsrsInTheBucket().all(ssr => ssr.allowUseSameOptionsForAllPassengers);
    }

    getOtherJourneysBuckets(): IJourneySsrsBucket[] {
        return this.booking.filteredJourneys.filter(j => j.journeyKey !== this.parentJourney.journeyKey)
                                            .selectMany(j => {
                                                return j.allSsrsBuckets.filter(b => b.bucketIdentifier === this.bucketIdentifier);
                                            });
    }

    getOtherBucketsContainingSsrType(ssrType: ISsrType): IJourneySsrsBucket[] {
        return this.booking.filteredJourneys.selectMany(j => j.allSsrsBuckets.filter(b => b.bucketIdentifier !== this.bucketIdentifier
                                                                                    && b.getAllSsrsInTheBucket().map(ssr => ssr.ssrType.ssrCode).includes(ssrType.ssrCode)))
    }

    private _setUseSameOptionsForAllPassengersForOtherJourneys(): void {
        this.getOtherJourneysBuckets().forEach(bucket => {
            bucket.useSameOptionsForAllPassengers = this.useSameOptionsForAllPassengers
        });
    }

    private _useSameOptionsForAllPassengers: boolean = false;
    get useSameOptionsForAllPassengers(): boolean {
        if(this.allowUseSameOptionsForAllPassengers) {
            return this._useSameOptionsForAllPassengers;
        } else {
            return false;
        }

    }

    set useSameOptionsForAllPassengers(value: boolean) {
        if(value === this.useSameOptionsForAllPassengers) {
            return;
        }

        if(!this.allowUseSameOptionsForAllPassengers) {
            return;
        }

        this._setUseSameOptionsForAllPassengers(value);
    }

    protected _setUseSameOptionsForAllPassengers(value: boolean): void {
        runInAction(() => {
            this._useSameOptionsForAllPassengers = value;
            this.parentJourney.storage.setItem(this._sameOptionsForAllPassengersStorageKey, value.toString());

            if(value) {
                this.getAllSsrsInTheBucket().forEach(ssr => {
                    ssr.applySameQuantityForAllPassengers(ssr.passengersSsrEditors[0].newQuantity);
                });

            }

            if(this.useSameOptionsForAllFlights) {
                this._setUseSameOptionsForAllPassengersForOtherJourneys();
            }
        });
    }
}
