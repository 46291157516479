import {AnalyticsBaseEvents} from "./analytics-base.events";
import {
    ExtrasPromotionVariant,
    IAnalyticsEvent,
    IAnalyticsExtrasSelectionEvents
} from "../../../booking/models/analytics/google-analytics.intefaces";
import {BookingModel} from "../../../booking/models/booking.model";
import {
    ExtrasSectionsRenderersFactory
} from "../../../../pages/booking-flow/extras-selection/base-renderers/extras-sections-renderers-factory";
import {IBookingViewModel} from "../../../booking/models/booking-view-model.interface";

export class AnalyticsExtrasSelectionEvents extends AnalyticsBaseEvents implements IAnalyticsExtrasSelectionEvents {
    logMandatoryNotice(): void {
        try {
            const data:  unknown = {
                event: 'mandatory_notice',
                notification: {
                    slug: this.services.navigator.currentRoute.path
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('Extras: logMandatoryNotice', err);
        }
    }

    logViewOrSelectPromotion(booking: BookingModel, variant: ExtrasPromotionVariant) {
        try {
            const data:  unknown = {
                event: variant === "Proposal" ? 'view_promotion': 'select_promotion',
                ecommerce: {
                    creative_slot: 'extras_selection',
                    promotion_id: `SelectPriority|${booking.departureJourney?.analyticsFormattedStationCodes}`,
                    promotion_name: 'Promote Priority Selection',
                    items: [{
                        item_name: 'PrioritySelection',
                        item_id: `ExtrasUpsell|${booking.departureJourney?.analyticsFormattedStationCodes}`,
                        item_variant: variant
                    }]
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('Extras: logMandatoryNotice', err);
        }
    }

    logAddToCart(booking: BookingModel, ssrKeys: string[]): void {
        if(ssrKeys.length === 0)  return;
        try {
            const items = this._getAnalyticsSsrFeesItemsFiltered(booking, ssrKeys);
            const data:  IAnalyticsEvent = {
                event: 'add_to_cart',
                ecommerce: {
                    currency: booking.currency,
                    value: items.sum(item => item.price * item.quantity),
                    coupon: booking.promoCode.code.value ?? "",
                    customer_type: this._getCustomerTypeFromBooking(booking),
                    items: [
                        ...items
                    ]
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('Extras: logAddToCart failed', err);
        }
    }

    logRemoveFromCart(booking: BookingModel, ssrKeys: string[]): void {
        if(ssrKeys.length === 0)  return;
        try {
            const items = this._getAnalyticsSsrFeesItemsFiltered(booking, ssrKeys);
            const data:  IAnalyticsEvent = {
                event: 'remove_from_cart',
                ecommerce: {
                    currency: booking.currency,
                    value: items.sum( item => item.price * item.quantity),
                    coupon: booking.promoCode.code.value ?? "",
                    customer_type: this._getCustomerTypeFromBooking(booking),
                    items: [
                        ...items
                    ]
                }
            }
            this.analyticsActionHandler.sendEvent(data)
          } catch (err) {
            this.services.logger.error('logRemoveFromCart failed', err);
        }
    }

    logSelectItem(booking: IBookingViewModel, ssrTypes: string[]): void {
        try {
            const extrasRenderersFactory = new ExtrasSectionsRenderersFactory(booking);
            const allSsrTypes = extrasRenderersFactory.getExtrasSections()
                .filter(s =>  s.shouldRender())
                .flatMap(s => s.ssrTypes);

            booking.createSsrsAggregator({
                ssrTypes: allSsrTypes
            }).journeysSsrsBuckets
                .forEach( journey => {
                    // we need to send one event for each journey
                    const data: unknown = {
                        event: 'select_item',
                        ecommerce: this._mapSsrViewModelToEcommerceData(booking, journey, ssrTypes)
                    }

                    this.analyticsActionHandler.sendEvent(data)
                })
        } catch (err) {
            this.services.logger.error('log select item failed', err);
        }
    }

    logBagsViewItemList(booking: BookingModel): void {
        try {
            booking.createBagsSsrsAggregator()
                .journeysSsrsBuckets
                .forEach( journey => {
                    // we need to send one event for each journey
                    const data: unknown = {
                        event: 'view_item_list',
                        ecommerce: this._mapSsrViewModelToEcommerceData(booking, journey)
                    }

                    this.analyticsActionHandler.sendEvent(data)
                })
        } catch (err) {
            this.services.logger.error('logAddBagToCart failed', err);
        }
    }

    logExtrasViewItemList(booking: BookingModel): void {
        try {
            const extrasRenderersFactory = new ExtrasSectionsRenderersFactory(booking);
            const allSsrTypes = extrasRenderersFactory.getExtrasSections()
                .filter(s =>  s.shouldRender())
                .flatMap(s => s.ssrTypes);

            booking.createSsrsAggregator({
                ssrTypes: allSsrTypes
            }).journeysSsrsBuckets
                .forEach( journey => {
                    // we need to send one event for each journey
                    const data: unknown = {
                        event: 'view_item_list',
                        ecommerce: this._mapSsrViewModelToEcommerceData(booking, journey)
                    }

                    this.analyticsActionHandler.sendEvent(data)
                })

        } catch (err) {
            this.services.logger.error('logAddBagToCart failed', err);
        }
    }

}