import {IJourneyViewModel} from "../../models/journey/journey-view-model.interface";
import {ValidationResultEnum, validationResultToDialogResult} from "../../../../types/validation-result.enum";
import {PriorityBoardingSuggestionChoice} from "../../../../pages/booking-flow/bags-selection/components/priority-boarding-suggestion-dialog.component";
import {DialogResult} from "../../../dialog/dialog-enums";

import {BookingFlowStepBase} from "../common/booking-flow-step.base";
import {IOnBeforeDeactivationContext, IWizardStep} from "../../../../models/wizard/wizard.step.interface";

export class BookingFlowBagsSelectionStep extends BookingFlowStepBase {

    get title(): string {
        return this.services.language.translate('Bags');
    }

    async validate(): Promise<ValidationResultEnum> {
        const journeys = this.booking.filteredJourneys;
        let hasErrors = false;
        for(let j of journeys) {
            j.allBags.activateValidations();
            if(j.allBags.hasErrors) {
                hasErrors = true;
            }
        }

        if(hasErrors) {
            this.services.alert.showError(this.services.language.translate('Please select your luggage'));
            this.services.analytics.extrasSelectionEvents.logMandatoryNotice();
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;


    }

    protected async _onBeforeForwardDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        const result = await super._onBeforeForwardDeactivation(context);
        if(result !== DialogResult.Accepted) {
            return result;
        }

        return validationResultToDialogResult(await this._suggestPriority(this.booking.filteredJourneys));

    }

    async next(): Promise<void> {
        if(ValidationResultEnum.Success === await this.validate()) {

            await this._goToNextStep();
        }

    }

    private async _suggestPriority(journeys: IJourneyViewModel[]): Promise<ValidationResultEnum> {

        const services = this.services;

        const bucketsWithoutPriorityBoarding = journeys.map(j => j.allBags).filter(b => b.priorityBoarding.passengersSsrEditors.some(p => p.newQuantity === 0));

        if (bucketsWithoutPriorityBoarding.length === 0) {
            return ValidationResultEnum.Success;
        }

        this.services.analytics.extrasSelectionEvents.logViewOrSelectPromotion(this.booking, 'Proposal');
        const dialogResult = await services.dialogFactory.showPriorityBoardingSuggestion({
            onAccept: async (choice) => {
                if(choice === PriorityBoardingSuggestionChoice.ChoosePriority) {
                    bucketsWithoutPriorityBoarding.forEach(bucket => {
                        bucket.priorityBoarding.passengersSsrEditors.forEach(p => p.newQuantity = 1);
                    })

                    await this.booking.sellSsrs({skipDebounce: true});
                }
            }
        });

        if(dialogResult === DialogResult.Accepted) {
            this.services.analytics.extrasSelectionEvents.logViewOrSelectPromotion(this.booking, 'Accepted')
            return ValidationResultEnum.Success
        }
        this.services.analytics.extrasSelectionEvents.logViewOrSelectPromotion(this.booking, 'Declined')
        return ValidationResultEnum.Failure;
    }


    protected async _goToNextStep(): Promise<void> {
        this._activateStepByRoute(this.routes.extras);
    }

    get route() {
        return this.routes.bags;
    }


    protected async _onActivated(previousStep: IWizardStep | null): Promise<void> {
        await super._onActivated(previousStep);
        this.services.analytics.extrasSelectionEvents.logBagsViewItemList(this.booking);
    }
}
