import {IDotRezAvailableFare, IDotRezPassengerFare} from "../../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import {IServiceFactory} from "../../../service-factory.interface";
import {Price} from "../../../currency/price";
import {IDotRezSellTripPassengerType} from "../../../dot-rez-api/data-contracts/requests/booking/sell-trip.request";
import {ServiceChargeTypeDotRezApiEnum} from "../../../dot-rez-api/data-contracts/enums/service-charge-type.enum";
import {IPassengerTypeSelectorsList} from "../../../passenger-types/passengers-types.service.interface";
import {IAvailableFareToDiscountAdapter} from "../fares/available-fare-to-discount-adater.interface";

export abstract class FareDiscountTypeBase {
    constructor(protected readonly fareAdapter: IAvailableFareToDiscountAdapter) {
    }

    protected get dotRezFare(): IDotRezAvailableFare {
        return this.fareAdapter.dotRezFare;
    }

    protected get services(): IServiceFactory {
        return this.fareAdapter.services;
    }

    get passengerDotRezFares(): IDotRezPassengerFare[] {
        return this.dotRezFare.fares[0].passengerFares;
    }

    protected _hasServiceCharge(serviceChargeType: ServiceChargeTypeDotRezApiEnum): boolean {
        return this.dotRezFare.fares.some(fare =>
            fare.passengerFares.some(passengerFare =>
                passengerFare.serviceCharges.some(sc => sc.type === serviceChargeType)));
    }

    protected _computePrice(extractPassengerFareAmount: (passengerFare: IDotRezPassengerFare) => number): Price {
        if(this.dotRezFare.isSumOfSector) {
            return this.services.booking.current.createPrice(this.dotRezFare.fares.sum(fare =>  extractPassengerFareAmount(fare.passengerFares[0])));
        } else {
            return this.services.booking.current.createPrice(extractPassengerFareAmount(this.dotRezFare.fares[0].passengerFares[0]));
        }
    }

    get totalPrice(): Price {
        return this._computePrice(passengerFare => passengerFare.publishedFare);
    }

    get discountedPrice(): Price {
        return this._computePrice(passengerFare => passengerFare.revenueFare);
    }

    createPassengerTypesForTripSell(passengerTypes: IPassengerTypeSelectorsList): IDotRezSellTripPassengerType[] {
        return passengerTypes.createPassengerTypesForRegularSell();
    }
}
