import {IBrowserTab} from "./browser-tab.interface";
import {makeObservable, observable, runInAction} from "mobx";

export class ExternalBrowserTab implements IBrowserTab {
    constructor(private readonly win: Window | null) {
        makeObservable(this, {
            isOpen: observable.ref
        });
    }

    async close(): Promise<void> {
        this.win?.close();
        runInAction(() => {
            this.isOpen = false;
        });

    }

    isOpen = true
}


