import {FlightSearchStepBase} from "./flight-search-step.base";

export class PassengersSelectionStep extends FlightSearchStepBase {
    get title(): string {
        return this.services.language.translate('Passengers');
    }

    async next(): Promise<void> {
        await this.services.navigator.goHome();
    }

    get route() {
        return this.routes.passengers;
    }

}
