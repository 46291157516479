import React from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassExportButtonsComponent} from "../export-buttons/boarding-pass-export-buttons.component";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";

interface BoardingPassExportAllButtonComponentProps {
    bookingKey: string;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
}


export const BoardingPassExportAllButtonComponent: React.FC<BoardingPassExportAllButtonComponentProps> = observer((props) => {
    const services = useServices();
    const boardingPasses = props.boardingPasses;

    if(boardingPasses.length <= 1) {
        return null;
    }

    const origin = props.boardingPasses[0].designator.origin.name;
    const destination = props.boardingPasses[0].designator.destination.name;
    return (
        <BoardingPassExportButtonsComponent bookingKey={props.bookingKey}
                                            boardingPasses={boardingPasses}
                                            fileName={`Boarding pass ${origin}_${destination}.pdf`}
                                            emailSubject={services.language.translate('Boarding pass')}
                                            emailBody={services.language.translationFor('Boarding pass for flight {origin} - {destination}').withParams({
                                                origin: origin,
                                                destination: destination
                                            })}/>
    )
})
