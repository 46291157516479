import React from "react";
import {IServiceFactory} from "../../../service-factory.interface";
import {ParentRouteModel} from "../../models/parent-route.model";
import {IBookingRoutes} from "./booking.routes.interface";
import {ChildRouteModel} from "../../models/child-route.model";
import {RouteBaseModel} from "../../models/route-base.model";
import {BlueBenefitsPurchasePage} from "../../../../pages/booking-flow/blue-benefits-purchase/blue-benefits-purchase.page";
import {InsurancePage} from "../../../../pages/booking-flow/insurance/insurance.page";
import {SeatSelectionPage} from "../../../../pages/booking-flow/seat-selection/seat-selection.page";
import {DepartingFlightSelectionPage} from "../../../../pages/booking-flow/fare-selection/departure/departing-flight-selection.page";
import {ReturnFlightSelectionPage} from "../../../../pages/booking-flow/fare-selection/return/return-flight-selection.page";
import {BookingPassengersDetailsPage} from "../../../../pages/booking-flow/passengers-details/booking-passengers-details.page";
import {ExtrasSelectionPage} from "../../../../pages/booking-flow/extras-selection/extras-selection.page";
import {FinalPricePage} from "../../../../pages/booking-flow/final-price/final-price.page";
import {PaymentPage} from "../../../../pages/booking-flow/payment/payment.page";
import {BagsSelectionPage} from "../../../../pages/booking-flow/bags-selection/bags-selection.page";
import {FinalizePaymentPage} from "../../../../pages/finalize-payment/finalize-payment.page";


export class BookingRoutes extends ParentRouteModel implements IBookingRoutes {
    constructor(services: IServiceFactory) {
        super('/booking', services);
    }
    departingFlight = new ChildRouteModel('/departing-flight', this,() => <DepartingFlightSelectionPage/>);
    returnFlight = new ChildRouteModel('/return-flight', this,() => <ReturnFlightSelectionPage/>);
    passengersDetails = new ChildRouteModel('/passengers-details', this, () => <BookingPassengersDetailsPage/>);
    seats = new ChildRouteModel('/seats', this, () => <SeatSelectionPage/>);
    bags = new ChildRouteModel('/bags', this, () => <BagsSelectionPage/>);
    insurance = new ChildRouteModel('/insurance', this, () => <InsurancePage/>);
    extras = new ChildRouteModel('/extras', this, () => <ExtrasSelectionPage/>);
    finalPrice = new ChildRouteModel('/final-price', this, () => <FinalPricePage/>);
    payment = new ChildRouteModel('/payment', this, () => <PaymentPage/>);
    blueBenefitsPurchase = new ChildRouteModel('/blue-benefits-purchase', this, () => <BlueBenefitsPurchasePage/>);
    finalizePayment = new ChildRouteModel('/finalize-payment', this, () => <FinalizePaymentPage/>);

    protected _getDefaultChildRoute(): RouteBaseModel | null {
        return this.departingFlight;
    }
}
