import React from 'react';
import {IServiceFactory} from "../../../../service-factory.interface";
import {SbagSsrLargeIcon} from "./sbag.ssr.large-icon";
import {SbagSsrSmallIcon} from "./sbag.ssr.small-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../ssrs-preferred-orders-in-shopping-cart";
import {CheckInBagsSsrBase} from "../check-in-bags-ssr-base";
import { SbagSsrLargeIconDisabled } from './sbag.ssr.large-icon-disabled';

export default class SbagSsrType extends CheckInBagsSsrBase {
    constructor(services: IServiceFactory) {
        super('SBAG', services);
    }

    get isAvailable(): boolean {
        return true;
    }

    get bagWeightKg(): number {
        return 23;
    }
    get smallIcon() {
        return (<SbagSsrSmallIcon />);
    }

    get largeIcon() {
        return (<SbagSsrLargeIcon/>);
    }

    get largeIconDisabled() {
        return (<SbagSsrLargeIconDisabled/>);
    }

    protected get _iconInExtrasSmallScreen(): React.ReactElement | null {
        return (<SbagSsrLargeIcon size={2.5}/>);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SBAG;
    }

}
