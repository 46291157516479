import React from 'react';
import {IServiceFactory} from "../../../service-factory.interface";
import {FastSsrSmallIcon} from "./fast-ssr.small-icon";
import {SsrTypeBase} from "../../ssr-type-base";
import {SSR_STANDARD_LARGE_ICON_SIZE} from "../../ssr-types.service.interface";

export default class FastSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('FAST', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<FastSsrSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<FastSsrSmallIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>);
    }
}
