import React, {CSSProperties} from "react";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {BoardingPassFieldLabelComponent} from "../label/boarding-pass-field-label.component";
import {useServices} from "../../../../hooks/use-services.hook";

function otherSsrsToString(boardingPass: IPassengerSegmentBoardingPassViewModel): string {
    const otherSsrs = [...boardingPass.otherSsrs];
    if(boardingPass.hasInfant) {
        otherSsrs.push({
            ssrCode: "INFT",
            count: 1
        });
    }

    return otherSsrs.map(ssr => `${ssr.count}x${ssr.ssrCode}`).join(', ');
}

export const BoardingPassOtherSsrsComponent: React.FC<{boardingPass: IPassengerSegmentBoardingPassViewModel;}> = (props) => {
    const services = useServices();
    const otherSsrs = otherSsrsToString(props.boardingPass);
    if(!otherSsrs) {
        return null;
    }

    const styles: CSSProperties = {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: services.theme.currentTheme.spacing.large
    }
    return (
        <BoardingPassFieldLabelComponent styles={styles}>
            {`SSRS: ${otherSsrs}`}
        </BoardingPassFieldLabelComponent>
    )
}
