import React from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {OriginAndDestinationStationsComponent} from "../origin-and-destination-stations.component";
import {FlightDatesAvailabilityComponent} from "../availability/flight-dates-availability.component";

export const DepartingFlightSelectionExtraHeaderComponent: React.FC = observer(() => {
    const services = useServices();
    const availability = services.booking.availability;

    if(!availability.departureOrigin || !availability.departureDestination || !availability.departureDate) {
        return null;
    }

    const onDateChanged = async (date: Date) => {
        await availability.changeDepartingDate(date);
    }

    return (
        <>
            <OriginAndDestinationStationsComponent origin={availability.departureOrigin}
                                                   destination={availability.departureDestination}/>

            <FlightDatesAvailabilityComponent flightSchedule={availability.departureFlightSchedule}
                                              currentScheduleSelectedDate={availability.departureDate}
                                              onDateSelected={onDateChanged}
                                              getLowFare={(date) => availability.getDepartureLowFare(date)}/>
        </>

    );
});
