import {InfantFeeBaseModel} from "../../base-models/fees/infant/infant-fee-base.model";
import {IDotRezInfantFee} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {BookingSnapshotModel} from "../booking/booking-snapshot.model";
import {Price} from "../../../../currency/price";

export class InfantFeeSnapshotModel extends InfantFeeBaseModel {
    constructor(feeData: IDotRezInfantFee,
                private readonly booking: BookingSnapshotModel) {
        super(feeData, booking.services);
    }


    createPrice(amount: number): Price {
        return this.booking.createPrice(amount);
    }
}
