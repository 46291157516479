import {ParentRouteModel} from "../../models/parent-route.model";
import {IDeepLinkRoutes} from "./deep-link.routes.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {ChildRouteModel} from "../../models/child-route.model";
import React from "react";
import {DeepLinkSearchPage} from "../../../../pages/deep-link/deep-link-search.page";
import {RouteBaseModel} from "../../models/route-base.model";

export class DeepLinkRoutes extends ParentRouteModel implements IDeepLinkRoutes {
    constructor(services: IServiceFactory) {
        super('/deeplink', services);
    }

    search = new ChildRouteModel('/search', this, () => <DeepLinkSearchPage/>);

    protected _getDefaultChildRoute(): RouteBaseModel | null {
        return null;
    }
}
