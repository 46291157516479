import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import {NullableString} from "../../../types/nullable-types";

const ContainerBox = styled.div`
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: ${props => props.theme.spacing.small};
    background-color: ${props => props.theme.colors.warning};
    color: ${props => props.theme.colors.warningContrast};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.normal};
    border-radius: ${props => `calc(${props.theme.border.defaultRadius}/2)`};
    
`

export const SsrModificationBlockingReasonComponent:React.FC<{reason: NullableString}> = observer((props) => {

    if(!props.reason) {
        return null;
    }

    return (
        <ContainerBox>
            {props.reason}
        </ContainerBox>
    );
});
