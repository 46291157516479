import React from "react";
import {MyAccountDashboardItemBase} from "../my-account-dashboard-item-base";
import { PersonNoCircleIcon } from "../../../assets/svg/person-no-circle.icon";

export class MyProfileDashboardItem extends MyAccountDashboardItemBase {

    get text(): string {
        return this.services.language.translate('My Profile');
    }

    renderIcon(): React.ReactElement {
        return <PersonNoCircleIcon/>;
    }

    protected async _onExecute(): Promise<void> {
        await this.services.user.showProfile();
    }

}
