import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {StandardPageContentComponent} from "../../../basic-components/page/standard-page-content/standard-page-content.component";

interface StandardDialogContentComponentProps extends PropsWithChildren {
    className?: string;
}
export const StandardDialogContentComponent: React.FC<StandardDialogContentComponentProps> = observer((props) => {
    return (
        <StandardPageContentComponent className={props.className}>
            {props.children}
        </StandardPageContentComponent>
    )
});
