import React, {CSSProperties, PropsWithChildren} from "react";
import {PlaneDepartNoLineIcon} from "../../../../assets/svg/plane-depart-no-line.icon";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassFieldLabelComponent} from "../label/boarding-pass-field-label.component";
import {BoardingPassFieldValueComponent} from "../value/boarding-pass-field-value.component";


const BoardingPassDesignatorBox: React.FC<PropsWithChildren> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: '1',
        width: '100%',
        marginBottom: services.theme.currentTheme.spacing.extraLarge
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}

const BoardingPassCarrierBox: React.FC<PropsWithChildren> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        fontSize: services.theme.currentTheme.fontSize.small,
        fontWeight: services.theme.currentTheme.fontWeight.bold
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}


const StationsAndFlightNumberBox: React.FC<PropsWithChildren> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        lineHeight: '1',
        width: '100%',
        marginBottom: services.theme.currentTheme.spacing.small
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}

const StationCodeBox: React.FC<{stationCode: string}> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        fontSize: services.theme.currentTheme.fontSize.xxxxLarge
    };

    return (
        <BoardingPassFieldValueComponent styles={styles}>
            {props.stationCode}
        </BoardingPassFieldValueComponent>
    );
}

interface SectionBoxProps extends PropsWithChildren {
    styles: CSSProperties;
}
const SectionBox: React.FC<SectionBoxProps> = (props) => {
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        ...props.styles
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
}

interface StationSectionBoxProps extends PropsWithChildren {
    stationCode: string;
    stationName: string;
    styles: CSSProperties;
}

const StationSectionBox: React.FC<StationSectionBoxProps> = (props) => {
    const styles: CSSProperties = {
        width: '45%',
        ...props.styles
    }
    return (
        <SectionBox styles={styles}>
            <StationCodeBox stationCode={props.stationCode}/>
            <BoardingPassFieldLabelComponent>
                {props.stationName}
            </BoardingPassFieldLabelComponent>
        </SectionBox>
    );
}

const DepartureSectionBox: React.FC<Omit<StationSectionBoxProps, 'styles'>> = (props) => {
    const styles: CSSProperties = {
        alignItems: 'flex-start',
        textAlign: "start",
    };

    return (
        <StationSectionBox styles={styles} {...props}>
            {props.children}
        </StationSectionBox>
    )
}

const ReturnStationSectionBox: React.FC<Omit<StationSectionBoxProps, 'styles'>> = (props) => {
    const styles: CSSProperties = {
        alignItems: 'flex-end',
        textAlign: "end",
    };

    return (
        <StationSectionBox styles={styles} {...props}>
            {props.children}
        </StationSectionBox>
    )
}

const FlightNumberBox: React.FC<PropsWithChildren> = observer((props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        fontSize: theme.fontSize.xxLarge,
    };

    return (
        <BoardingPassFieldValueComponent styles={styles}>
            {props.children}
        </BoardingPassFieldValueComponent>
    )
});


const FlightNumberSectionBox: React.FC<{flightNumber: string}> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        alignItems: 'center',
        flexGrow: 1
    };

    return (
        <SectionBox styles={styles}>
            <PlaneDepartNoLineIcon color={services.theme.currentTheme.colors.primary} size={1.2} />
            <FlightNumberBox>{props.flightNumber}</FlightNumberBox>
        </SectionBox>
    )
});


interface BoardingPassDesignatorComponentProps {
    boardingPass: IPassengerSegmentBoardingPassViewModel
}
export const BoardingPassDesignatorComponent: React.FC<BoardingPassDesignatorComponentProps> = (props) => {
    const services = useServices();
    const designator = props.boardingPass.designator;


    const renderCarrier = () => {
        if(props.boardingPass.designator.carrier) {
            return (
                <BoardingPassCarrierBox>
                    {services.language.translationFor('Operated by {carrier}').withParams({carrier: props.boardingPass.designator.carrier})}
                </BoardingPassCarrierBox>
            );
        }
        return null;
    }
    return (
        <BoardingPassDesignatorBox>
            <StationsAndFlightNumberBox>
                <DepartureSectionBox stationCode={designator.origin.code} stationName={designator.origin.name}/>
                <FlightNumberSectionBox flightNumber={props.boardingPass.fullFlightIdentifier}/>
                <ReturnStationSectionBox stationCode={designator.destination.code} stationName={designator.destination.name}/>
            </StationsAndFlightNumberBox>
            {renderCarrier()}
        </BoardingPassDesignatorBox>

    );
}
