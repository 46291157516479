import {IServiceFactory} from "../../../service-factory.interface";
import {SsrTypeBase} from "../../ssr-type-base";
import React from "react";

export default class PsoSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('PSO', services);
    }

    renderInBundle(): React.ReactElement {
        return (<React.Fragment key={this.ssrCode}/>);
    }

    get shouldShowBundleIncludedInShoppingCart(): boolean {
        return false;
    }

    get shouldShowInFlightItineraryExtras(): boolean {
        return false;
    }

    get shouldShowInShoppingCart(): boolean {
        return false;
    }
}
