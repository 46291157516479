import {IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import {
    SeatsSuggestionDialogComponent,
    SeatsSuggestionResponse
} from "../../../../pages/booking-flow/seat-selection/dialogs/seats-suggestion-dialog.component";
import React from "react";
import {DialogCloseButtonBehavior} from "../../../dialog/dialog-enums";
import {RandomSeatAllocationDialogComponent} from "../../../../pages/booking-flow/seat-selection/dialogs/random-seat-allocation-dialog.component";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {BookingStepBase} from "./booking-step.base";

export abstract class SeatsSelectionBaseStep<TRoutes> extends BookingStepBase {

    protected abstract _shouldSuggestSeats(previousStep: IWizardStep | null): boolean;
    protected abstract _goToNextStep(): Promise<void>;
    protected abstract get routes(): TRoutes;

    private _seatsAlreadySuggested = false;
    private _randomSeatAllocationDialogWasShown = false;

    get title(): string {
        return this.services.language.translate('Seats');
    }

    private async _moveToNextStep(): Promise<void> {
        if(this.booking.mutationsManager.retrieveSsrsAvailabilityMutation.isMutationInProgress) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    await this.booking.mutationsManager.retrieveSsrsAvailabilityMutation.waitForMutation();
                }
            });
        }
        await this._goToNextStep();
    }

    async next(): Promise<void> {
        if(this._randomSeatAllocationDialogWasShown) {
            await this._moveToNextStep();
        } else {
            await waitForBookingMutations(this.booking);
            if(this._allPassengersHaveSeats()) {
                await this._moveToNextStep();
            } else {
                await this._showRandomSeatAllocationDialog();
            }
        }

    }

    async _onActivated(previousStep: IWizardStep | null): Promise<void> {
        await super._onActivated(previousStep);
        if(!this._seatsAlreadySuggested && this._shouldSuggestSeats(previousStep)) {
            this._seatsAlreadySuggested = true;
            //await this._suggestSeats();
        }
    }

    private _allPassengersHaveSeats(): boolean {
        return this.booking.filteredJourneys.all(journey => journey.allPassengersHaveSeats);
    }

    private async _suggestSeats(): Promise<void> {

        if(this._allPassengersHaveSeats()) {
            return;
        }

        await this.services.dialog.showSheetModalDialog<SeatsSuggestionResponse>({
            render: (dialogHandler) => (<SeatsSuggestionDialogComponent dialogHandler={dialogHandler}/>),
            onAccept: async (response) => {
                switch (response) {
                    case SeatsSuggestionResponse.ChooseYourOwnSeats:
                        break;
                    case SeatsSuggestionResponse.ContinueWithoutSeat:
                        await this._showRandomSeatAllocationDialog();
                        break;
                    default:
                        //nothing to do here
                }
            }
        });
    }

    private async _showRandomSeatAllocationDialog(): Promise<void> {
        this.services.analytics.seatSelectionEvents.logViewPromotion(this.booking);
        this._randomSeatAllocationDialogWasShown = true;
        await this.services.dialog.showStandardDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            render: modalHandler => (<RandomSeatAllocationDialogComponent dialogHandler={modalHandler} />),
            onAccept: async () => {
                this.services.analytics.seatSelectionEvents.logSelectPromotion(this.booking, true)
            },
            onReject: async () => {
                this.services.analytics.seatSelectionEvents.logSelectPromotion(this.booking, false)
                //await this._autoAssignSeats();
                await this._moveToNextStep();
            }
        });
    }
}
