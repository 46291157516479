import {MobileWalletPaymentMethodModel} from "./mobile-wallet-payment-method.model";
import React from "react";
import {LogoGooglePayIcon} from "../../../../../../assets/svg/logo-google-pay.icon";


export class GooglePayModel extends MobileWalletPaymentMethodModel {

    get name(): string {
        return "Google Pay";
    }



    renderIcon(): React.ReactElement | null {
        return (<LogoGooglePayIcon />);
    }

    get isSupportedOnCurrentPlatform(): boolean {
        const config = this.services.configuration.data.payments.googlePay;
        return (this.services.device.isAndroid && config.enableOnAndroid)
                || (this.services.device.isWindows && config.enableOnWindows);
    }
}