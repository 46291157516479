import {FormModel} from "../../../../models/forms/form.model";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {NullableDate, NullableString} from "../../../../types/nullable-types";
import {IDotRezPersonTravelDocument} from "../../../dot-rez-api/data-contracts/user/person.data-contracts";
import {IPersonTravelDocumentViewModel} from "./person-travel-document-view-model.interface";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {DialogResult} from "../../../dialog/dialog-enums";
import {PersonModel} from "./person.model";
import {IPersonTravelDocumentFields} from "./person-travel-document-fields.interface";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../../dialog-factory/yes-no-dialog.enums";
import { DocumentNumberValidator } from "../../../../models/forms/field-validators/document-number.validator";

export abstract class PersonTravelDocumentModel<TOwner extends PersonModel> extends FormModel<IPersonTravelDocumentFields> implements IPersonTravelDocumentViewModel {
    constructor(protected readonly owner: TOwner, public travelDocumentKey: string = "") {
        super(owner.services);
        makeObservable(this, {
            travelDocumentKey: observable,
            documentData: computed
        });
    }
    protected abstract _addTravelDocument(): Promise<IDotRezPersonTravelDocument>;
    protected abstract _updateTravelDocument(): Promise<IDotRezPersonTravelDocument>;
    protected abstract _deleteTravelDocument(): Promise<void>;
    protected abstract _getUniqueDocumentErrorMessage(): string;

    getOwnerFullName(): string {
        return this.owner.getFullName();
    }

    get documentData(): IDotRezPersonTravelDocument {
        if(this.travelDocumentKey) {
            const travelDocument = this.owner.personData.travelDocuments.find(td => td.personTravelDocumentKey === this.travelDocumentKey)
            if(!travelDocument) {
                throw new Error(`There is no travel document with key ${this.travelDocumentKey}`);
            }
            return travelDocument;
        }

        return {
            personTravelDocumentKey: "",
            issuedByCode: null,
            documentTypeCode: null,
            number: null,
            gender: null,
            dateOfBirth: null,
            expirationDate: null,
            name: {
                title: null,
                first: null,
                last: null
            },
            nationality: null,
            issuedDate: null,
            default: false
        }
    }

    get isNew(): boolean {
        return !Boolean(this.travelDocumentKey);
    }

    get isExpired(): boolean {
        if(this.fields.expirationDate.value) {
            return this.fields.expirationDate.value.getTime() < this.services.time.currentDate.getTime();
        }
        return false;
    }

    protected _createFields(): FormFields<IPersonTravelDocumentFields> {
        const language = this.services.language;
        return {
            documentTypeCode: this._createField<NullableString>({
                fieldName: () => language.translate('Document Type'),
                initialValue: () => this.documentData.documentTypeCode,
                maxLength: 4
            }),
            number: this._createField<NullableString>({
                fieldName: () => language.translate('Document number'),
                initialValue: () => this.documentData.number,
                maxLength: 35,
                validators: [
                    new DocumentNumberValidator(language.translate('Invalid document number'))
                ]
            }),
            issuedByCode: this._createField<NullableString>({
                fieldName: () => language.translate('Issue Country'),
                initialValue: () => this.documentData.issuedByCode,
                maxLength: 2
            }),
            issuedDate: this._createField<NullableDate>({
                fieldName: () => language.translate('Issued Date'),
                initialValue: () => this.services.time.tryConvertToDate(this.documentData.issuedDate),
                validate: () => this._validateIssueDate()
            }),
            expirationDate: this._createField<NullableDate>({
                fieldName: () => language.translate('Expiration Date'),
                initialValue: () => this.services.time.tryConvertToDate(this.documentData.expirationDate),
                validate: () => this._validateExpirationDate()
            }),
            nationality: this._createField<NullableString>({
                fieldName: () => language.translate('Citizenship'),
                initialValue: () => this.documentData.nationality,
                defaultValue: this.owner.fields.nationality.value,
                maxLength: 2
            })
        }
    }

    async edit(): Promise<void> {
        const dialogResult = await this.services.dialogFactory.showPersonTravelDocumentEditor({
            travelDocument: this
        });

        if(dialogResult === DialogResult.Rejected) {
            this.cancelChanges();
        }
    }

    protected _getUpdatedTravelDocumentFields() {
        return {
            title: this.services.personTitle.personTitleFromGender(this.owner.fields.gender.value) || '',
            firstName: this.owner.fields.firstName.value!,
            lastName: this.owner.fields.lastName.value!,
            nationality: this.fields.nationality.value!,
            documentTypeCode: this.fields.documentTypeCode.value!,
            number: this.fields.number.value!,
            dateOfBirth: this.services.time.formatYYY_MM_DD(this.owner.fields.dateOfBirth.value!),
            gender: this.owner.fields.gender.value!,
            issuedByCode: this.fields.issuedByCode.value!,
            issuedDate: this.services.time.formatYYY_MM_DD(this.fields.issuedDate.value!),
            expirationDate: this.services.time.formatYYY_MM_DD(this.fields.expirationDate.value!)
        }
    }

    private _validateUniqueDocument(): ValidationResultEnum {
        const duplicatedDocuments = this.owner.personData.travelDocuments
                                                        .filter(td => td.personTravelDocumentKey !== this.travelDocumentKey
                                                                && td.issuedByCode === this.fields.issuedByCode.value
                                                                && td.documentTypeCode === this.fields.documentTypeCode.value);
        if(duplicatedDocuments.length > 0) {
            this.services.alert.showError(this._getUniqueDocumentErrorMessage());
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    private get _personDetailsHasChanged(): boolean {
        return (this.documentData.gender !== this.owner.fields.gender.value
            || this.services.time.tryConvertToDate(this.documentData.dateOfBirth)?.getTime() !== this.owner.fields.dateOfBirth.value?.getTime()
            || this.documentData.name?.first !== this.owner.fields.firstName.value
            || this.documentData.name?.last !== this.owner.fields.lastName.value)
    }

    async save(): Promise<ValidationResultEnum> {

        this.activateErrorsValidation();
        if(this.hasErrors()) {
            return ValidationResultEnum.Failure;
        }


        if(ValidationResultEnum.Failure === this._validateUniqueDocument()) {
            return ValidationResultEnum.Failure;
        }

        if(this.isNew) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                   const travelDocumentData = await this._addTravelDocument();
                    runInAction(() => {
                        this.owner.personData.travelDocuments.push(travelDocumentData);
                        this.travelDocumentKey = travelDocumentData.personTravelDocumentKey;
                    });
                }
            });
        } else if(this.hasChanges() || this._personDetailsHasChanged) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    const updatedTravelDocumentData = await this._updateTravelDocument();
                    runInAction(() => {
                        const index = this.owner.personData.travelDocuments.findIndex(td => td.personTravelDocumentKey === this.travelDocumentKey);
                        //replace the old document with the new one
                        this.owner.personData.travelDocuments.splice(index, 1, updatedTravelDocumentData);
                        this.travelDocumentKey = updatedTravelDocumentData.personTravelDocumentKey;
                    });
                }
            });
        }

        this.commitChanges();

        return ValidationResultEnum.Success;
    }

    async delete(): Promise<ValidationResultEnum> {
        const dialogResult = await this.services.dialogFactory.showYesNoDialog({
            title: this.services.language.translate('Confirmation'),
            message: this.services.language.translate('Are you sure you want to delete this document ?'),
            primaryButton: YesNoDialogPrimaryButton.PrimaryButtonNo
        });

        if(dialogResult !== YesNoDialogResult.Yes) {
            return ValidationResultEnum.Failure;
        }

        await this.services.loadingIndicator.execute({
            action: async () => {
                await this._deleteTravelDocument();
                const index = this.owner.personData.travelDocuments.findIndex(td => td.personTravelDocumentKey === this.travelDocumentKey);
                runInAction(() => {
                    this.owner.personData.travelDocuments.splice(index, 1);
                });
            }
        });

        return ValidationResultEnum.Success;
    }

    private _validateIssueDate(): NullableString {
        if(!this.fields.issuedDate.value) {
            return null;
        }

        if(this.owner.fields.dateOfBirth.value) {
            if(this.fields.issuedDate.value.getTime() < this.owner.fields.dateOfBirth.value.getTime()) {
                return this.services.language.translate('Cannot be before birth date');
            }
        }

        if(this.fields.issuedDate.value.getTime() > this.services.time.currentDate.getTime()) {
            return this.services.language.translate('Cannot be in the future');
        }

        return null;
    }
    private _validateExpirationDate(): NullableString {
        if(!this.fields.expirationDate.value) {
            return null;
        }

        if(this.fields.issuedDate.value) {
            if(this.fields.expirationDate.value.getTime() < this.fields.issuedDate.value.getTime()) {
                return this.services.language.translate('Cannot be before Issued Date');
            }
        }

        if(this.owner.fields.dateOfBirth.value) {
            if(this.fields.expirationDate.value.getTime() < this.owner.fields.dateOfBirth.value.getTime()) {
                return this.services.language.translate('Cannot be before birth date');
            }
        }

        if(this.isNew && this.fields.expirationDate.value.getTime() < this.services.time.currentDate.getTime()) {
            return this.services.language.translate('You try to add an expired document');
        }

        return null;
    }

}
