import {DefaultTheme} from "styled-components";

export enum PreferredColorScheme {
    Dark = "dark",
    Light = "light",
    System = "system"
}


export interface IThemeService {
    readonly currentTheme: DefaultTheme;
    readonly isInDarkMode: boolean;
    setColorScheme(colorScheme: PreferredColorScheme): void;

}
