import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import styled from "styled-components";
import {PassengerSelectedSpecialAssistanceSsrsComponent} from "./special-assistance/passenger-selected-special-assistance-ssrs.component";
import {PrimaryContactToggleComponent} from "./primary-contact-toggle.component";
import { PersonIcon } from "../../../../assets/svg/person.icon";
import { CardBox } from "../../../../basic-components/card/card.box";
import {PersonNameFieldEditorComponent} from "../../../../components/forms/person/person-name-field-editor.component";
import {SaveAsCompanionToggleComponent} from "./save-as-companion-toggle.component";
import {AvailableBookingCompanionsComponent} from "./available-booking-companions.component";
import {AttachedCompanionComponent} from "./attached-companion.component";
import {DateFieldEditorComponent} from "../../../../components/forms/date-time/date-field-editor.component";
import {StringFieldEditorComponent} from "../../../../components/forms/string-field/string-field-editor.component";
import {CountryPickerComponent} from "../../../../components/country-picker/country-picker.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {TravelDocumentTypeFieldEditorComponent} from "../../../../components/forms/travel-document-type/travel-document-type-field-editor.component";
import {GenderFieldEditorComponent} from "../../../../components/forms/gender/gender-field-editor.component";
import {EmailFieldEditorComponent} from "../../../../components/forms/contact/email-field-editor.component";
import {PhoneFieldEditorComponent} from "../../../../components/forms/contact/phone-field-editor.component";

const PassengerCardBox = styled(CardBox)`
    
`

const DescriptionBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.large};
    margin-bottom: ${props => props.theme.spacing.large};
`

const DescriptionTitleBox = styled.div`
    margin: 0;
    padding-left: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
`;


const PassengerDetailsBox = styled.div`
    display: flex;
    flex-direction: row;
`

const FieldsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large};
    width: 100%;
`

const LineBox = styled.hr`
    background-color: ${props => props.theme.colors.primary};
    width: 100%;
`


interface PassengerDetailsEditorComponentProps {
    passenger: IPassengerViewModel;
    onEnterPassengerDetails: (passenger: IPassengerViewModel) => void;
}

export const PassengerDetailsEditorComponent: React.FC<PassengerDetailsEditorComponentProps> = observer((props) => {
    const services = useServices();
    const passengerFields = props.passenger.fields;
    const contactFields = services.booking.current.contact.fields;

    const onFocusHandler = () => {
        props.onEnterPassengerDetails(props.passenger);
    }

    const renderPassengerFields = () => {
        if(props.passenger.companion.isAttached) {
            return (
                <AttachedCompanionComponent passenger={props.passenger}/>
            )
        }

        if(props.passenger.hasSpecialPriceMarketDiscount) {

            const travelDocumentFields = props.passenger.travelDocument.fields;
            let minimumTravelDocumentIssuedDate = passengerFields.dateOfBirth.value;
            let maximumTravelDocumentIssuedDate = props.passenger.travelDocument.computeMaximumTravelDocumentIssuedDate();
            let minimumTravelDocumentExpiration = props.passenger.travelDocument.computeMinimumTravelDocumentExpirationDate();
            let maximumTravelDocumentExpiration = services.time.addYears(services.time.currentDate, 100);

            return (
                <>

                    <PersonNameFieldEditorComponent key={passengerFields.firstName.fieldName}
                                                    field={passengerFields.firstName}/>
                    <PersonNameFieldEditorComponent key={passengerFields.lastName.fieldName}
                                                    field={passengerFields.lastName}/>

                    <GenderFieldEditorComponent key={travelDocumentFields.gender.fieldName}
                                                field={travelDocumentFields.gender}
                                                useGenderAsNumber={false}/>

                    <DateFieldEditorComponent key={travelDocumentFields.dateOfBirth.fieldName}
                                              field={travelDocumentFields.dateOfBirth}
                                              minimumValue={props.passenger.computeMinimumBirthDate()}
                                              maximumValue={props.passenger.computeMaximumBirthDate()}/>

                    {props.passenger.isPrimaryContact && (<CountryPickerComponent key={contactFields.countryCode.fieldName}
                                                                                  field={contactFields.countryCode}/>)}


                    <StringFieldEditorComponent key={passengerFields.cityOfResidence.fieldName}
                                                field={passengerFields.cityOfResidence}/>

                    <CountryPickerComponent key={travelDocumentFields.nationality.fieldName}
                                            field={travelDocumentFields.nationality}
                                            popupHeaderTitle={services.language.translate('Search citizenship')}/>

                    {props.passenger.isPrimaryContact && (<EmailFieldEditorComponent key={contactFields.emailAddress.fieldName} field={contactFields.emailAddress}/>)}
                    {props.passenger.isPrimaryContact && (<PhoneFieldEditorComponent key={contactFields.phoneNumber.fieldName}
                                                                                     field={contactFields.phoneNumber}/>)}


                    <TravelDocumentTypeFieldEditorComponent key={travelDocumentFields.documentTypeCode.fieldName}
                                                            field={travelDocumentFields.documentTypeCode}
                                                            onlyDocumentsForSpecialPriceMarket={true}/>

                    <StringFieldEditorComponent key={travelDocumentFields.number.fieldName}
                                                field={travelDocumentFields.number}/>
                    <CountryPickerComponent key={travelDocumentFields.issuedByCode.fieldName}
                                            field={travelDocumentFields.issuedByCode}/>
                    <DateFieldEditorComponent key={travelDocumentFields.issuedDate.fieldName}
                                              field={travelDocumentFields.issuedDate}
                                              minimumValue={minimumTravelDocumentIssuedDate}
                                              maximumValue={maximumTravelDocumentIssuedDate}/>
                    <DateFieldEditorComponent key={travelDocumentFields.expirationDate.fieldName}
                                              field={travelDocumentFields.expirationDate}
                                              minimumValue={minimumTravelDocumentExpiration}
                                              maximumValue={maximumTravelDocumentExpiration}/>
                </>
            );
        }

        return (<>
            <PersonNameFieldEditorComponent key={passengerFields.firstName.fieldName}
                                            field={passengerFields.firstName}/>
            <PersonNameFieldEditorComponent key={passengerFields.lastName.fieldName}
                                            field={passengerFields.lastName}/>
            <DateFieldEditorComponent key={passengerFields.dateOfBirth.fieldName}
                                      field={passengerFields.dateOfBirth}
                                      minimumValue={props.passenger.computeMinimumBirthDate()}
                                      maximumValue={props.passenger.computeMaximumBirthDate()}/>
            {props.passenger.isPrimaryContact && (<EmailFieldEditorComponent key={contactFields.emailAddress.fieldName} field={contactFields.emailAddress}/>)}
            {props.passenger.isPrimaryContact && (<PhoneFieldEditorComponent key={contactFields.phoneNumber.fieldName}
                                                                             field={contactFields.phoneNumber}/>)}
            {props.passenger.isPrimaryContact && (<CountryPickerComponent key={contactFields.countryCode.fieldName}
                                                                          field={contactFields.countryCode}/>)}

        </>);
    }




    const testClassName = [
        'tst',
        props.passenger.passengerType.description.toLowerCase(),
        (props.passenger.passengerTypeIndex + 1).toString()
    ].join('-');

    return (
        <PassengerCardBox className={testClassName}>
            <PassengerDetailsBox onFocus={onFocusHandler}>
                <FieldsContainerBox>
                    <DescriptionBox>
                        <PersonIcon size={2}/>
                        <DescriptionTitleBox>
                            {`${props.passenger.passengerType.description} ${props.passenger.passengerTypeIndex + 1}`}
                        </DescriptionTitleBox>
                    </DescriptionBox>

                    {renderPassengerFields()}

                    {props.passenger.companion.canBeSavedAsCompanion ? (<LineBox/>) : null}
                    <SaveAsCompanionToggleComponent passenger={props.passenger}/>
                    {(props.passenger.companion.canBeSavedAsCompanion || props.passenger.passengerType.canBeMadePrimaryContact) && <LineBox/>}
                    <PrimaryContactToggleComponent passenger={props.passenger}/>
                    {props.passenger.passengerType.canBeMadePrimaryContact && <LineBox/>}
                    <PassengerSelectedSpecialAssistanceSsrsComponent passenger={props.passenger}/>
                </FieldsContainerBox>

                <AvailableBookingCompanionsComponent passenger={props.passenger}/>
            </PassengerDetailsBox>
        </PassengerCardBox>
    )
});
