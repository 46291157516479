import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {useServices} from "../../../../hooks/use-services.hook";
import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";
import {
    IFlightSearchControllerViewModel,
    SpecialPriceMarketUserAgreement
} from "../../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";

const AgreeButton = styled(PrimaryButton)`
    min-width: 150px;
`

const DontAgreeButton = styled(PrimaryButtonInverted)`
    min-width: 160px;
`

const SpecialPriceMarketFooterBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${props => props.theme.spacing.large};
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      flex-direction: column;
    }
`

interface SpecialPriceMarketUserAgreementPageFooterComponentProps {
    searchController: IFlightSearchControllerViewModel;
    dialogHandler?: IDialogConfirmationHandler;
}

export const SpecialPriceMarketUserAgreementFooterComponent: React.FC<SpecialPriceMarketUserAgreementPageFooterComponentProps> = observer((props) => {
    const services = useServices();
    const searchController = props.searchController;

    const moveNext = async () => {
        if(props.dialogHandler) {
            props.dialogHandler.accept();
        } else {
            await services.flightSearch.steps.nextStep();
        }

    }

    const onAgreeClick = async () => {
        searchController.specialPriceMarketUserAgreement = SpecialPriceMarketUserAgreement.Agree;
        await moveNext();
    }

    const onDoNotAgreeClick = async () => {
        searchController.specialPriceMarketUserAgreement = SpecialPriceMarketUserAgreement.DontAgree;
        await moveNext();
    }

    return (
        <SpecialPriceMarketFooterBox>
            <AgreeButton onClick={onAgreeClick} fullWidth={services.mediaQuery.sAndBelow}>
                {services.language.translate('I agree')}
            </AgreeButton>
            <DontAgreeButton onClick={onDoNotAgreeClick} fullWidth={services.mediaQuery.sAndBelow}>
                {services.language.translate('I do not agree')}
            </DontAgreeButton>
        </SpecialPriceMarketFooterBox>
    )
});
