import React from 'react';
import {IServiceFactory} from "../../../service-factory.interface";
import {LouSsrSmallIcon} from "./lou-ssr.small-icon";
import {SsrTypeBase} from "../../ssr-type-base";
import {SSR_STANDARD_LARGE_ICON_SIZE} from "../../ssr-types.service.interface";

export default class LouSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('LOU', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<LouSsrSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<LouSsrSmallIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>);
    }
}
