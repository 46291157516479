import {ISessionStorageService} from "./session-storage.service.interface";
import { v4 as uuidv4 } from 'uuid';
import {NullableString} from "../../types/nullable-types";
import {Check} from "../../types/type-checking";
import {SessionStorageKeys} from "./session-storage-keys";

export class SessionStorageService implements ISessionStorageService {
    constructor() {

        let sessionStorageKey  = sessionStorage.getItem('SESSION_STORAGE_KEY');
        if(!sessionStorageKey) {
            sessionStorageKey = uuidv4();
            sessionStorage.setItem('SESSION_STORAGE_KEY', sessionStorageKey)
        }

        this._sessionStorageKey = sessionStorageKey;

        const currentData = sessionStorage[this._sessionStorageKey];
        if (currentData) {
            this._currentData = JSON.parse(currentData);
        }
    }

    private readonly  _sessionStorageKey: string;

    private readonly _currentData: Record<string, string> = {};

    private _persistCurrentData() {
        sessionStorage[this._sessionStorageKey] = JSON.stringify(this._currentData);
    }

    setItem(key: string, value: string): void {
        this._currentData[key] = value;
        this._persistCurrentData();
    }

    getItem(key: string): NullableString {
        return this._currentData[key] || null;
    }

    setJson(key: string, value: object): void {
        this.setItem(key, JSON.stringify(value));
    }

    getJson<TValue = any>(key: SessionStorageKeys): TValue | null {
        const value = this.getItem(key);

        if (Check.isNullOrUndefined(value)) {
            return null;
        } else {
            return JSON.parse(value) as TValue;
        }
    }

    removeItem(key: string): void {
        if (!Check.isNullOrUndefined(this._currentData[key])) {
            delete this._currentData[key];
            this._persistCurrentData();
        }
    }
}
