import React from "react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {IonToggle} from "@ionic/react";

const PrimaryContactToggleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
    @media ${(props) => props.theme.mediaQuery.xs} {
      font-size: ${props => props.theme.fontSize.medium};
    }  
`

const LabelBox = styled.div`
    flex-grow: 1;
`

const IonToggleBox = styled(IonToggle)`
    height: 20px;
`

export const PrimaryContactToggleComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const services = useServices();
    if (!props.passenger.passengerType.canBeMadePrimaryContact) {
        return null;
    }

    const onLabelClick = () => {
        props.passenger.isPrimaryContact = !props.passenger.isPrimaryContact;
    }

    return (
        <PrimaryContactToggleBox>
            <LabelBox onClick={onLabelClick}>
                {services.language.translate('Make this passenger the default contact')}
            </LabelBox>
            <IonToggleBox checked={props.passenger.isPrimaryContact}
                       className={'tst-save-as-companion-toggle'}
                       onIonChange={(e) => props.passenger.isPrimaryContact = e.detail.checked}/>
        </PrimaryContactToggleBox>
    );
});
