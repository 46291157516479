import React from 'react';
import {SsrTypeBase} from "../../ssr-type-base";
import {IServiceFactory} from "../../../service-factory.interface";
import {FlexSsrSmallIcon} from './flex-ssr.small-icon';
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {NullableString} from "../../../../types/nullable-types";
import {SsrConfiguration} from "../../../configuration/objects/ssrs/ssr.configuration";
import {IBookingViewModel} from "../../../booking/models/booking-view-model.interface";
import {IJourneyViewModel} from "../../../booking/models/journey/journey-view-model.interface";
import {SSR_STANDARD_LARGE_ICON_SIZE} from "../../ssr-types.service.interface";

export default class FlexSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('FLX', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<FlexSsrSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<FlexSsrSmallIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.FLEX
    }

    private get flexConfig(): SsrConfiguration {
        return this.services.configuration.ssrs.flex;
    }

    getPurchaseBlockingMessage(journey: IJourneyViewModel): NullableString {
        const bundleCode = journey.selectedBundle?.bundleCode ?? this.services.bundleTypes.getLowestBundleType().code;

        if(this.flexConfig.canBePurchasedSeparately(journey.booking.bookingCreationDate, journey.designator.departureDate, bundleCode)) {
           return null;
        }

        const minTimeBeforeFlight = this.flexConfig.getMinimumTimeToPurchaseBeforeFlight(journey.booking.bookingCreationDate, bundleCode);
        if(minTimeBeforeFlight) {
            return this._getCannotPurchaseMessage(minTimeBeforeFlight);
        }

        return null;
    }

    canBeShownOnBookingFlow(booking: IBookingViewModel): boolean {
        return this.flexConfig.isInTheCutOffPeriod(booking.bookingCreationDate);
    }

    canBeShownOnCheckInFlow(booking: IBookingViewModel): boolean {
        return false;
    }

    canBeShownOnManageMyBookingFlow(booking: IBookingViewModel): boolean {
        return false;
    }

}
