import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {ScreenSizeBasedComponent} from "../../../../basic-components/layout/screen-size-based.component";
import {ExtrasSelectionPageSmallScreenContent} from "../../../booking-flow/extras-selection/extras-selection-page-content.small-screen.component";
import {ExtrasSelectionPageLargeScreenContent} from "../../../booking-flow/extras-selection/extras-selection-page-content.large-screen.component";
import {ManageMyBookingServicesSectionFactory} from "./manage-my-booking-services-section-factory";

export const ManageMyBookingServicesComponent: React.FC = observer(() => {
    const services = useServices();
    const [sectionsFactory] = useState(new ManageMyBookingServicesSectionFactory(services.booking.current));
    return (
        <ScreenSizeBasedComponent renderForSmallScreen={() => <ExtrasSelectionPageSmallScreenContent sectionsFactory={sectionsFactory}/>}
                                  renderForLargeScreen={() => <ExtrasSelectionPageLargeScreenContent sectionsFactory={sectionsFactory}/>}/>
    )
});
