import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../hooks/use-services.hook";
import {BookingFlowSmallScreenPage} from "../booking-flow.small-screen.page";
import styled from "styled-components";
import {CardBox} from "../../../basic-components/card/card.box";
import {InsuranceBenefitsComponent} from "./components/insurance-benefits.component";
import {InsuranceSelectionComponent} from "./components/insurance-selection.component";
import {InsuranceRemovalComponent} from "./components/insurance-removal.component";
import {InsuranceDisclaimerComponent} from "./components/insurance-disclaimer.component";
import {ErrorMessageTextBoxComponent} from "../../../components/error-message/error-message-text-box.component";

const PolicyTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-align: center;
`

const InsuranceCardBox = styled(CardBox)`
    padding: ${props => props.theme.spacing.medium};
    margin-top: ${props => props.theme.spacing.medium};
    
`

const InsurancePageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${props => props.theme.fontSize.mediumLarge};
`

const PricePerPassengerDescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1.2;
`

const HorizontalLine = styled.div`
    height: 1px;
    margin: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.large} ${props => props.theme.spacing.large};
    background-color: ${props => props.theme.colors.mediumTint};
`

const LogoTrustpilotBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0 12px;
`

export const InsurancePageContentComponent: React.FC = observer(() => {
    const services = useServices();
    const insurance = services.booking.current.insurance;



    return (
        <InsurancePageContentBox>
            <InsuranceCardBox>
                <PolicyTitleBox>
                    {insurance.quote.policyTitle}
                </PolicyTitleBox>
                <LogoTrustpilotBox>
                    <img src={insurance.quote.trustPilotLogoUrl} alt={"Trust pilot logo"}/>
                </LogoTrustpilotBox>
                <PricePerPassengerDescriptionBox>
                    {insurance.quote.pricesPerPassengersDescription}
                </PricePerPassengerDescriptionBox>

                <InsuranceBenefitsComponent insurance={insurance}/>
                <div ref={(element) => insurance.attachValidatedDOMElement(element)}>
                    <ErrorMessageTextBoxComponent errorMessage={insurance.validationError}/>
                    <InsuranceSelectionComponent insurance={insurance}/>
                    <InsuranceRemovalComponent insurance={insurance}/>
                </div>

                <HorizontalLine/>
                <InsuranceDisclaimerComponent insurance={insurance}/>
            </InsuranceCardBox>

        </InsurancePageContentBox>
    );

});

export const InsurancePage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowSmallScreenPage title={services.language.translate('Travel insurance')}
                                    renderContent={() => <InsurancePageContentComponent/>}
                                    showPromotionCode={false}>

        </BookingFlowSmallScreenPage>
    );
});
