import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import styled from "styled-components";
import {IPassengerTypeSelector} from "../../../../../../../services/passenger-types/passengers-types.service.interface";

const DropDownInputBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-basis: 100%;
`

const PassengerTypeCountBox = styled.div`
    line-height: 1;
`

const PassengerTypeSeparator = styled.div`
    width: 2px;
    height: 18px;
    margin: 0 ${props => props.theme.spacing.large} 0 ${props => props.theme.spacing.large};
    background-color: ${props => props.theme.colors.primary};
`


export const PassengersTypesDropDownInputComponent: React.FC = observer(() => {
   const services = useServices();
   const passengerTypes = services.flightSearch.searchController.passengers.getAll().filter(p => p.count > 0);

    const renderOnePassengerType = (passengerType: IPassengerTypeSelector) => {
        return (
            <PassengerTypeCountBox key={passengerType.description}>
                {`${passengerType.count} x ${passengerType.description}`}
            </PassengerTypeCountBox>
        )
    }

   const renderPassengerTypes = () => {
       const components: React.ReactElement[] = [];
       for(let i = 0; i < passengerTypes.length; i++) {
           components.push(renderOnePassengerType(passengerTypes[i]));
           if(i < passengerTypes.length - 1) {
               components.push(
                   <PassengerTypeSeparator key={`separator_${passengerTypes[i].description}`}/>
               );
           }
       }

       return components;
   }


   return (
       <DropDownInputBox>
           {renderPassengerTypes()}
       </DropDownInputBox>
   )

});
