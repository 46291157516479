import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {BookingStepBase} from "./booking-step.base";
import {
    IOnBeforeActivationContext
} from "../../../../models/wizard/wizard.step.interface";
import {
    LoadPaymentMethodsMutation
} from "../../models/mutation-actions/load-payment-methods/load-payment-methods.mutation";
import {
    UpdateBookingDataMutation
} from "../../models/mutation-actions/update-booking-data/update-booking-data.mutation";
import {DialogResult} from "../../../dialog/dialog-enums";


export abstract class FinalPriceBaseStep<TRoutes> extends BookingStepBase {
    protected abstract _goToPaymentStep(): Promise<void>;
    protected abstract get routes(): TRoutes;

    get title(): string {
        return this.services.language.translate('Price');
    }

    async validate(): Promise<ValidationResultEnum> {
        if(!this.booking.travelConditionsAccepted) {
            this.services.alert.showError('Please accept the Travel Conditions');
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    async next(): Promise<void> {
        const booking = this.booking;

        if(ValidationResultEnum.Success !== await this.validate()) {
            return;
        }

        await waitForBookingMutations(this.booking);

        if(booking.balanceDue.amount > 0) {
            await this._goToPaymentStep();
        } else {
            await booking.paymentHandler.payBooking();
        }
    }

    protected async _applyDiscounts(): Promise<void> {

    }


    protected async _onBeforeActivation(context: IOnBeforeActivationContext): Promise<DialogResult> {
        if(context.routingGuardContext.isForwardNavigation) {
            await waitForBookingMutations(this.booking);
            this.booking.mutationsManager.startMutation(new UpdateBookingDataMutation(this.booking));
            await waitForBookingMutations(this.booking);
            await this._applyDiscounts();
            this.booking.mutationsManager.startMutation(new LoadPaymentMethodsMutation(this.booking));
        }
        return super._onBeforeActivation(context);
    }
}
