import {ServiceBase} from "../service-base";
import {ISsrType, ISsrTypesService} from "./ssr-types.service.interface";
import {SsrTypeBase} from "./ssr-type-base";
import PbrdSsrType from "./ssrs/pbrd/pbrd.ssr";
import BagSsrType from "./ssrs/bags-ssrs/bag/bag.ssr";
import SbagSsrType from "./ssrs/bags-ssrs/sbag/sbag.ssr";
import ScbgSsrType from "./ssrs/scbg/scbg.ssr";
import StstSsrType from "./ssrs/seats/stst.ssr";
import FlexSsrType from "./ssrs/flex/flex.ssr";
import CknSsrType from "./ssrs/ckn/ckn.ssr";
import PetcSsrType from "./ssrs/pets/petc/petc.ssr";
import AvihSsrType from "./ssrs/pets/avih/avih.ssr";
import UmSsrType from "./ssrs/um/um.ssr";
import StexSsrType from "./ssrs/seats/stex.ssr";
import {Check} from "../../types/type-checking";
import BcbgSsrType from "./ssrs/bcbg/bcbg.ssr";
import {SeatSsrTypeBase} from "./ssrs/seats/seat-ssr-type-base";
import {SpecialAssistanceSsrBase} from "./ssrs/special-assitance/special-assistance-ssr-base";
import {SpecialEquipmentSsrTypeBase} from "./ssrs/special-equipment/special-equipment-ssr-type-base";
import {MealSsrBase} from "./ssrs/meals/meal-ssr-base";
import {PetsSsrTypeBase} from "./ssrs/pets/pets-ssr-type-base";
import enGbSsrs from '../language/translations/ssrs/ssrs.en-gb';
import {IServiceFactory} from "../service-factory.interface";
import {makeObservable, observable, reaction, runInAction} from "mobx";
import XsbgSsrType from "./ssrs/bags-ssrs/xsbg/xsbg.ssr";
import {CheckInBagsSsrBase} from "./ssrs/bags-ssrs/check-in-bags-ssr-base";
import Ins1Ssr from "./ssrs/insurance/ins1.ssr";
import EcknSsrType from "./ssrs/ckn/eckn.ssr";
import {TranslationWithParams} from "../language/translation-with-params";
import SbusSsrType from "./ssrs/sbus/sbus.ssr";
import LouSsrType from "./ssrs/lou/lou.ssr";
import FastSsrType from "./ssrs/fast/fast.ssr";
import PsoSsrType from "./ssrs/pso/pso.ssr";


export class SsrTypesService extends ServiceBase implements ISsrTypesService {

    constructor(services: IServiceFactory) {
        super(services);
        makeObservable(this, {
            _ssrsTranslations: observable.ref
        });

        reaction(() => this.services.language.currentLanguage,
            async () => {
                await this._loadSsrsTranslations();
            }, {
                fireImmediately: true
            });
    }


    private async _loadSsrsTranslations(): Promise<void> {
        const language = this.services.language.currentLanguage;
        try {
            const module = await import(`../language/translations/ssrs/ssrs.${language}.ts`);
            this._setSsrsTranslations(module.default);
        } catch (err) {
            this.services.logger.error(`Failed to load SSRS translations for language ${language}`, err);
            this._setSsrsTranslations(enGbSsrs);
        }
    }

    private _ssrsTypesMap: Record<string, ISsrType> | null = null;
    _ssrsTranslations: Record<string, string> = enGbSsrs;
    private _setSsrsTranslations(translations: Record<string, string>) {
        runInAction(() => {
            this._ssrsTranslations = translations
        });
    }

    get ssrsTypesMap(): Record<string, ISsrType> {
        if (!this._ssrsTypesMap) {
            const ssrs: Record<string, ISsrType> = {};
            const modulesResolver = require.context('./ssrs/', true, /^.*(\.ssr.tsx)/); // searches for all files in ssrs models that ends with .ssr.tsx
            modulesResolver.keys().map(modulesResolver).forEach((module: any) => {
                const ssrRenderer = new module.default(this.services) as ISsrType;
                ssrs[ssrRenderer.ssrCode] = ssrRenderer;
            });
            this._ssrsTypesMap = ssrs;
        }

        return this._ssrsTypesMap;
    }

    private _ssrsTypeLists: ISsrType[] | null = null;
    get ssrsTypeList(): ISsrType[] {
        if(!this._ssrsTypeLists) {
            this._ssrsTypeLists = Object.values(this.ssrsTypesMap).sort((s1, s2) => {
                if(s1.preferredOrderInLists < s2.preferredOrderInLists) {
                    return -1;
                }
                return 1
            })
        }
        return this._ssrsTypeLists;
    }

    getSsrType(ssrCode: string): ISsrType {
        const ssrType: ISsrType = this.ssrsTypesMap[ssrCode];
        if (ssrType) {
            return ssrType;
        }
        return new SsrTypeBase(ssrCode, this.services);
    }

    translateSsr(key: string): string {

        let translation = this._ssrsTranslations[key];
        if(translation) {
            return translation;
        }
        return key;
    }

    translationForSsr(key: string): TranslationWithParams {
        let template = this._ssrsTranslations[key];
        if(template) {
            return new TranslationWithParams(template);
        }

        return new TranslationWithParams(key);
    }

    get PBRD(): ISsrType {
        return new PbrdSsrType(this.services);
    }

    get BAG(): ISsrType {
        return new BagSsrType(this.services);
    }

    get XSBG(): ISsrType {
        return new XsbgSsrType(this.services);
    }

    get SBAG(): ISsrType {
        return new SbagSsrType(this.services);
    }

    get SCBG(): ISsrType {
        return new ScbgSsrType(this.services);
    }

    get BCBG(): ISsrType {
        return new BcbgSsrType(this.services);
    }

    get STST(): ISsrType {
        return new StstSsrType(this.services);
    }

    get STEX(): ISsrType {
        return new StexSsrType(this.services);
    }

    get FLX(): ISsrType {
        return new FlexSsrType(this.services);
    }

    get CKN(): ISsrType {
        return new CknSsrType(this.services);
    }

    get ECKN(): ISsrType {
        return new EcknSsrType(this.services);
    }


    get PETC(): ISsrType {
        return new PetcSsrType(this.services);
    }

    get AVIH(): ISsrType {
        return new AvihSsrType(this.services);
    }

    get UM(): ISsrType {
        return new UmSsrType(this.services);
    }

    get LCKN(): ISsrType {
        return new ScbgSsrType(this.services);
    }

    get INS1(): ISsrType {
        return new Ins1Ssr(this.services);
    }

    get SBUS(): ISsrType {
        return new SbusSsrType(this.services);
    }

    get LOU(): ISsrType {
        return new LouSsrType(this.services);
    }

    get FAST(): ISsrType {
        return new FastSsrType(this.services);
    }

    get PSO(): ISsrType {
        return new PsoSsrType(this.services);
    }

    getSpecialAssistanceSsrTypes(): ISsrType[] {
        return this.ssrsTypeList.filter(ssrType => ssrType instanceof SpecialAssistanceSsrBase);
    }

    getSpecialEquipmentSsrTypes(): ISsrType[] {
        return this.ssrsTypeList.filter(ssrType => ssrType instanceof SpecialEquipmentSsrTypeBase);
    }

    getMealsSsrTypes(): ISsrType[] {
        return this.ssrsTypeList.filter(ssrType => ssrType instanceof MealSsrBase);
    }

    getPetsSsrTypes(): ISsrType[] {
        return this.ssrsTypeList.filter(ssrType => ssrType instanceof PetsSsrTypeBase);
    }

    getAllCheckInBags(): ISsrType[] {
        return this.ssrsTypeList.filter(ssrType => (ssrType instanceof CheckInBagsSsrBase) && ssrType.isAvailable)
            .sort((s1, s2) => {
                const b1 = s1 as CheckInBagsSsrBase;
                const b2 = s2 as CheckInBagsSsrBase;
                return b1.bagWeightKg - b2.bagWeightKg;
            });
    }
    getCheckInBagsThatCanBeSoldIndividually(): ISsrType[] {
        return this.getAllCheckInBags().filter(b => !b.isSoldOnlyInBundle);
    }

    isSeat(ssrType: ISsrType): boolean {
        return ssrType instanceof SeatSsrTypeBase;
    }

    filterVisibleSsrs<TSsrCodeContainer extends string | ISsrType | {ssrType: ISsrType}>(ssrsCodesContainers: TSsrCodeContainer[]): TSsrCodeContainer[] {
        const hasPriorityBoarding: boolean = ssrsCodesContainers.filter(ssrType => this._extractSsrCode(ssrType) === this.PBRD.ssrCode).length > 0;
        const ssrsToBeHiddenWhenPriorityIsSelected = [this.SCBG.ssrCode, this.BCBG.ssrCode];
        return ssrsCodesContainers.filter(ssrType => {
            const ssrCode = this._extractSsrCode(ssrType);
            return !(ssrsToBeHiddenWhenPriorityIsSelected.includes(ssrCode) && hasPriorityBoarding)
                  && ssrCode !== this.INS1.ssrCode;
        });
    }

    private _extractSsrCode(ssrCodeContainer:  string | ISsrType | {ssrType: ISsrType}): string {
        if(this._isSsrType(ssrCodeContainer)) {
            return ssrCodeContainer.ssrCode;
        }
        if(Check.isString(ssrCodeContainer)) {
            return ssrCodeContainer;
        }
        return ssrCodeContainer.ssrType.ssrCode;
    }

    private _isSsrType(input: unknown): input is ISsrType {
        return (input instanceof SsrTypeBase);
    }

    private _aliasesMap: Record<string, ISsrType[]> | null = null;
    private get aliasesMap(): Record<string, ISsrType[]> {
        if(!this._aliasesMap) {
            this._aliasesMap = {};

            for(let ssrType of Object.values(this.ssrsTypesMap)) {
                if(ssrType.aliasFor) {
                    if(!this._aliasesMap[ssrType.aliasFor.ssrCode]) {
                        this._aliasesMap[ssrType.aliasFor.ssrCode] = [];
                    }

                    this._aliasesMap[ssrType.aliasFor.ssrCode].push(ssrType);
                }
            }
        }

        return this._aliasesMap;
    }


    getAliases(ssrType: ISsrType): ISsrType[] {
        return this.aliasesMap[ssrType.ssrCode] ?? [];
    }
}
