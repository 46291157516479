import {ILoggerService} from "./logger.service.interface";
import {Check} from "../../types/type-checking";

export class ConsoleLoggerService implements ILoggerService {

    debug(message: string, err?: any): void {
        console.debug(this._formatMessage(message, err), err);
    }

    info(message: string, err?: any): void {
        console.info(this._formatMessage(message, err), err);
    }

    warning(message: string, err?: any): void {
        console.warn(this._formatMessage(message, err), err);
    }

    error(message: string, err?: any): void {
        console.error(this._formatMessage(message, err), err);
    }

    private _formatMessage(message: string, error?: any): string {
        if(!error) {
            return message;
        }

        if(Check.isObject(error) || Check.isArray(error)) {
            return `${message}; Error details: ${JSON.stringify(error)}`;
        } else if(!Check.isNullOrUndefined(error)){
            return `Error details: ${error}`;
        } else {
            return message;
        }
    }


}
