import {BlueBenefitsSsr} from "./blue-benefits-ssr";
import {IServiceFactory} from "../../../service-factory.interface";
import React from "react";
import {Pas2SsrLargeIcon} from "./pas2-ssr.large-icon";

export default  class Pas2Ssr extends BlueBenefitsSsr {
    constructor(services: IServiceFactory) {
        super('PAS2', services);
    }

    get largeIcon(): React.ReactElement | null {
        return <Pas2SsrLargeIcon/>
    }
}
