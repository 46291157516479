import {AvailabilityModel} from "../../../flight-search/models/availability.model";
import {
    FlightSearchControllerModel
} from "../../../flight-search/flight-search-controller/flight-search-controller.model";
import {NullableString} from "../../../../types/nullable-types";
import {JourneyModel} from "../journey/journey.model";
import {BookingModel} from "../booking.model";
import {IBundleViewModel} from "../bundle/bundle-view-model.interface";
import {IBookingViewModel} from "../booking-view-model.interface";

export const AnalyticsConstants = {
    brandName: 'AeroItalia',
    flightCategory: 'Flight',
    flightListCategory: 'Flight_List',
    seatCategory: 'Seat',
    ssrCategory: 'Ssr',
    otherCategory: 'Other',
    bookingFees: 'BookingFees',
    extraCategory: 'Extra',
    creativeSlot: 'seat_selection',
    selectSeat: 'SelectSeat',
    defaultAgentName: 'public',
}

export const AnalyticsEvents = {
    adsRemarketingHome: 'ads_remarketing_home',
    adsRemarketingSearchResults: 'ads_remarketing_searchresults',
    search: 'search',
    addToCart: 'add_to_cart',
    removeFromCart: 'remove_from_cart',
    selectPromotion: 'select_promotion',
    viewPromotion: 'view_promotion',
    selectItem: 'select_item',
    mandatoryNotice: 'mandatory_notice',
}

export type ExtrasPromotionVariant =  "Proposal" |"Accepted"|"Declined";

export interface IAnalyticsPassengerDetailsEvents {
    logContactPresent(booking: BookingModel): void;
    logBeginCheckout(booking: BookingModel): void;
}

export interface IAnalyticsPaymentEvents {
    logViewCart(booking: BookingModel): void;
    logAddPaymentInfo(booking: BookingModel): void;
    logPurchase(booking: BookingModel): void;
    logAddRemarketingPurchase(booking: BookingModel): void;
}

export interface IAnalyticsExtrasSelectionEvents {

    logBagsViewItemList(booking: BookingModel): void;
    logExtrasViewItemList(booking: BookingModel): void;
    logSelectItem(booking: IBookingViewModel, ssrTypes: string[]):void;
    logAddToCart(booking: BookingModel, ssrKeys: string[]): void;
    logRemoveFromCart(booking: BookingModel, ssrKeys: string[]): void;
    logViewOrSelectPromotion(booking: BookingModel, variant: ExtrasPromotionVariant ): void;
    logMandatoryNotice(): void;
}

export interface IAnalyticsSeatSelectionEvents {

    logViewPromotion(booking: BookingModel): void;
    logSelectPromotion(booking: BookingModel, acceptedPromotion: boolean): void;
    logAddToCart(booking: BookingModel, journeyKey: string, passengerKey: string, addedSeatKey: string): void;
    logRemoveFromCart(booking: BookingModel, journeyKey: string, passengerKey: string): void;
}


export interface IAnalyticsFlightSelectionEvents {
    // mandatory
    logAdsRemarketingHome(searchController: FlightSearchControllerModel): void;
    logFlightSearchEvent(searchController: FlightSearchControllerModel): void;
    logAdsRemarketingSearchResults(booking: BookingModel): void;

    logViewItemListForDeparture(availability: AvailabilityModel): void;
    logViewItemListForReturn(availability: AvailabilityModel): void;

    logSelectItemForDeparture(journey: JourneyModel): void;
    logSelectItemForReturn(journey: JourneyModel): void;

    logAddToCartForDeparture(booking: BookingModel): void;
    logAddToCartForReturn(booking: BookingModel): void;//the return does not have remove_from_cart event

    logSelectPromotion(booking: BookingModel, fromBundle: IBundleViewModel, toBundle: IBundleViewModel): void;
    logViewPromotion(booking: BookingModel, fromBundle: IBundleViewModel, toBundle: IBundleViewModel): void;

    logMandatoryNotice(): void;

}

export interface IAnalyticsSeatSelectionPromotion {
    creative_slot: string;
    promotion_id: string;
    promotion_name: string;
    items: { item_id: string; item_name: string; item_variant: string }[];
}

export interface IFlightSelectionAddsRemarketingHomeEvent extends IAnalyticsBaseEvent{
    flight_originid: string;
    flight_destid: string;
    flight_pagetype: string;
    flight_startdate: string;
    flight_enddate?: string;
}

export interface IFlightSelectionAddsRemarketingSearchEvent extends IFlightSelectionAddsRemarketingHomeEvent{
    flight_totalvalue: number;
}

export interface IAnalyticsViewItemListEcommerce  {
    item_list_name: NullableString,
    item_list_id: NullableString,
    customer_type: NullableString,
    items: IAnalyticsItem[]
    bundles?: unknown[];
}

export interface IAnalyticsEvent<T extends IAnalyticsEcommerceModel = IAnalyticsEcommerceModel> {
    event: string,
    ecommerce: T
}

export interface IAnalyticsEcommerceModel {
    currency: string,
    value: number,
    coupon?: NullableString,
    customer_type: string,
    items: IAnalyticsItem[]
}

export interface IAnalyticsPaymentEcommerceModel extends IAnalyticsEcommerceModel {
    payment_type: string;
    tax?: number;
    transaction_id?: NullableString;
}

export interface IAnalyticsItem {
    item_name: string;
    item_id: string;
    price: number;
    item_brand: string;
    item_category: string;
    item_category2?: string;
    item_category3?: string;
    item_variant?: string;
    is_direct?: boolean;
    item_list_name?: string;
    item_list_id?: string;
    index?: number;
    item_coupon?: string;
    discount?: number;
    quantity: number;
}

export interface IAnalyticsBaseEvent {
    event: string;
}
export interface IAnalyticsEcommerceEvent<T = unknown> extends IAnalyticsBaseEvent {
    ecommerce: T;
}

export interface IPassengerAnalyticsData {
    passengerTypeCode: string;
    totalAmount: number,
    quantity: number,
    discountCode?: NullableString
}


export interface  ISeatFeeAnalyticsData {
    id: string,
    rowNumber: number,
    seatNumber: string;
    seatLetter: string;
    hasExtraLegroom: boolean;
    price: number;
    analyticsName: string;
}