import {FormModel} from "../../../../models/forms/form.model";
import {ILoginFormFields} from "./login-form-fields.interface";
import {FormFields} from "../../../../models/forms/form-field.interface";

export class LoginFormModel extends FormModel<ILoginFormFields> {

    protected _createFields(): FormFields<ILoginFormFields> {
        const language = this.services.language;
        return {
            userName: this._createField({
               fieldName: () => 'E-mail',
               defaultValue: this.services.user.lastLoggedInUser
            }),
            password: this._createField({
                fieldName: () => language.translate('Password')
            })
        };
    }
}
