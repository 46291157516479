import React from "react";
import {observer} from "mobx-react-lite";
import {ICarrierConfiguration} from "../../services/configuration/objects/carrier-configuration.interface";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";

const CarriersBox = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1;
`

const CarrierBox = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.bold};
`

export const CarriersComponent: React.FC<{carriers: ICarrierConfiguration[]; className?: string}> = observer((props) => {
    const services = useServices();
    if(props.carriers.length === 0) {
        return null;
    }
    return (
        <CarriersBox className={props.className}>
            {props.carriers.map(carrier => (
                <CarrierBox key={carrier.code}>
                    {services.language.translationFor('Operated by {carrier}').withParams({carrier: carrier.name})}
                </CarrierBox>
            ))}
        </CarriersBox>
    )
});
