import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {FlightsDatesSelector} from "../../../../../../services/flight-search/dates-selection/flights-dates-selector";
import {useServices} from "../../../../../../hooks/use-services.hook";

const LowFareInfoBox = styled.div`
    color: ${props => props.theme.colors.mediumShade};
    font-size: ${props => props.theme.fontSize.small};
    line-height: 1;
    padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium} ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
`

interface FlightDatesDropDownLowFareInfoComponentProps {
    datesSelector: FlightsDatesSelector;
}
export const FlightDatesDropDownLowFareInfoComponent: React.FC<FlightDatesDropDownLowFareInfoComponentProps> = observer((props) => {
    const services = useServices();
    if(props.datesSelector.getFlightsScheduledMonths().length === 0) {
        return null;
    }

    return (
        <LowFareInfoBox>
            {services.language.translate('The price shown is the lowest available price for 1 person. It may differ if the booking is done for more passengers, based on availability.')}
        </LowFareInfoBox>
    );
});
