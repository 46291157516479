import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IonIcon} from "@ionic/react";
import {useServices} from "../../../../hooks/use-services.hook";
import {PersonNoCircleIcon} from "../../../../assets/svg/person-no-circle.icon";
import {GearIcon} from "../../../../assets/svg/gear.icon";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../../../services/dialog-factory/yes-no-dialog.enums";
import {search, searchOutline} from "ionicons/icons";

import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {SecondaryButton} from "../../../../basic-components/buttons/secondary-button";
import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";

const MoreOptionsTabBox = styled.div`
    display: flex;
    flex-direction: column;
`

export const MoreOptionsTabComponent: React.FC = observer(() => {
    const services = useServices();
    const onSettingsClick = async () => {
        services.navigator.routes.settings.activate();
    }

    const onBookAFlightClick = async () => {
        services.navigator.routes.flightSearch.originStation.activate();
    }

    const myAccountClick = () => {
        services.navigator.routes.myAccount.activate();
    }


    const onLogoutClick = async (): Promise<void> => {
        const dialogResult = await services.dialogFactory.showYesNoDialog({
            title: services.language.translate('Important information'),
            message: services.language.translate('Are you sure you want to logout?'),
            primaryButton: YesNoDialogPrimaryButton.PrimaryButtonNo
        });

        if(dialogResult === YesNoDialogResult.Yes) {
            await services.user.logout();
        }
    }
    return (
        <MoreOptionsTabBox>
            <PrimaryButton onClick={onBookAFlightClick}>
                <IonIcon ios={searchOutline} md={search}/>
                {services.language.translate('Book a flight')}
            </PrimaryButton>

            <SecondaryButton onClick={myAccountClick}>
                <PersonNoCircleIcon />
                {services.language.translate('My account')}
            </SecondaryButton>


            <SecondaryButton onClick={onSettingsClick}>
                <GearIcon />
                {services.language.translate('Settings')}
            </SecondaryButton>


            {
                services.user.isAuthorized &&
                (
                    <PrimaryButtonInverted onClick={onLogoutClick}>
                        {services.language.translate('Logout')}
                    </PrimaryButtonInverted>
                )
            }

        </MoreOptionsTabBox>
    );
});
