import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../../services/booking/models/passenger/passenger-view-model.interface";
import {PersonNameFieldEditorComponent} from "../../../../../components/forms/person/person-name-field-editor.component";

export const ManageMyBookingPassengerNameEditorComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const fields = props.passenger.fields;

    return (
        <>
            <PersonNameFieldEditorComponent key={fields.firstName.fieldName}
                                            field={fields.firstName}
                                            className={'tst-first-name'}/>
            <PersonNameFieldEditorComponent key={fields.lastName.fieldName}
                                            field={fields.lastName}
                                            className={'tst-last-name'}/>
        </>
    );
});
