import React, {useRef, useState} from "react";
import {observer} from "mobx-react";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageHeaderComponent} from "../../basic-components/page/standard-page-header/standard-page-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import styled from "styled-components";
import {LangSelectionComponent} from "../../components/lang-selection/lang-selection.component";
import {CurrencySelectionComponent} from "../../components/currency-selection/currency-selection.component";
import {StandardPageFooterComponent} from "../../basic-components/page/standard-page-footer/standard-page-footer.component";
import {APP_VERSION} from "../../version";
import {UserSettingsFormModel} from "./user-settings-form";
import { FormComponent } from "../../components/forms/form.component";
import {AirlineCompanyName} from "../../global-constants";
import { IonToast } from "@ionic/react";
import {PasswordFieldEditorComponent} from "../../components/forms/password/password-field-editor.component";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";

const VersionBox = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.medium};
`


const EnableTestModeBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.large};
`

const ToastBox = styled(IonToast)`
    font-size: ${props => props.theme.fontSize.large};
    --border-radius: ${props => props.theme.border.defaultRadius};
    --background: ${props => props.theme.colors.primary};
    --color: ${props => props.theme.colors.primaryContrast};
    text-align: center;
`

const MAX_VERSION_TAP_COUNT = 7;


export const SettingsPage: React.FC = observer(() => {
    const services = useServices();
    const formRef = useRef(new UserSettingsFormModel(services));
    const form = formRef.current;

    const [showTestModeEnabledToast, setShowTestModeEnabledToast] = useState(false);
    const [versionTapCount, setVersionTapCount] = useState(0);

    form.fields.currency.onChange(() => {
        if(form.fields.currency.value) {
            services.currency.setCurrentCurrency(form.fields.currency.value);
        }
    });

    form.fields.language.onChange(() => {
        if(form.fields.language.value) {
            services.language.changeCurrentLanguage(form.fields.language.value);
        }
    });

    const onVersionClick = () => {

        if(services.configuration.isTestModeEnabled) {
            return;
        }

        if(versionTapCount < MAX_VERSION_TAP_COUNT) {
            setVersionTapCount(versionTapCount + 1);
        } else {
            form.fields.enableTestModePassword.setValue(true);
        }
    }

    const onEnableTestModeClick = async () => {
        if(form.enableTestMode()) {
            form.fields.enableTestModePassword.setValue(false);
            setShowTestModeEnabledToast(true);
        }

    }

    const renderEnableTestModeSection = () => {

        if(!services.device.isHybrid) {
            return null;
        }


        if(!form.fields.enableTestModePassword.value) {
            return null;
        }

        if(versionTapCount < MAX_VERSION_TAP_COUNT) {
            return null;
        }

        return (
            <EnableTestModeBox>
                <PasswordFieldEditorComponent field={form.fields.testModePassword}/>
                <PrimaryButton onClick={onEnableTestModeClick} fullWidth={true}>
                    Enable test mode
                </PrimaryButton>
            </EnableTestModeBox>
        );
    }

    return (
        <RoutedPageComponent disableSwipeForwardNavigation={true}>
            <StandardPageHeaderComponent title={services.language.translate('Settings')} hideLogoButton={true}/>
            <StandardPageContentComponent>
                <FormComponent>
                    <LangSelectionComponent field={form.fields.language}/>
                    <CurrencySelectionComponent field={form.fields.currency}/>
                    {renderEnableTestModeSection()}
                    <ToastBox isOpen={showTestModeEnabledToast}
                              onDidDismiss={() => setShowTestModeEnabledToast(false)}
                              message={'Test mode is enabled now'}
                              duration={1500}
                              position="bottom"/>
                    {/*<DarkModeSwitcherComponent/>*/}
                </FormComponent>
            </StandardPageContentComponent>
            <StandardPageFooterComponent>
                <VersionBox onClick={onVersionClick}>{`${AirlineCompanyName} ${APP_VERSION}`}</VersionBox>
            </StandardPageFooterComponent>
        </RoutedPageComponent>
    );
});
