import styled from "styled-components";

export const InputBaseBox = styled.input`
    border: none;
    width: 100%;
    background: transparent;
    outline: none;
    padding: 0;
    &:focus {
        border: none;
    }
    &::selection {
        background-color: Highlight;
        color: HighlightText;
    }
    &::placeholder {
        color: ${props => props.theme.colors.medium};
        font-weight: ${props => props.theme.fontWeight.normal};
    }
`
