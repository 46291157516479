import React from "react";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {observer} from "mobx-react";

import styled from "styled-components";

const BundleContentBox = styled.div`
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
    flex-grow: 1;
`

export const BundleContentComponent: React.FC<{bundle: IBundleViewModel}> = observer((props) => {
    return (
        <BundleContentBox>
            {props.bundle.includedSsrs.filter(ssr => ssr.quantityToDisplay > 0)
                                      .map(ssr => ssr.ssrType.renderInBundle(ssr.quantityToDisplay))}
        </BundleContentBox>
    )
});
