import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import {ContinueButtonComponent} from "../continue-button/continue-button.component";

const RegistrationInstructionsDialogTextBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1.4;
    margin: 10px 0 0;
    width: 100%;
    height: 100%;
`

export const RegistrationInstructionsDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    return (
      <StandardDialogPageComponent>
          <StandardDialogHeaderComponent title={services.language.translate('Important information')}
                                         dialogHandler={props.dialogHandler}/>
          <StandardDialogContentComponent>
              <RegistrationInstructionsDialogTextBox>
                {services.language.translate('Please access your email to activate your account.')}
              </RegistrationInstructionsDialogTextBox>
          </StandardDialogContentComponent>
          <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
              <ContinueButtonComponent onClick={() => props.dialogHandler.accept()}/>

          </StandardDialogFooterComponent>
      </StandardDialogPageComponent>
    );
});
