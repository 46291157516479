import React from 'react';
import {observer} from "mobx-react-lite";
import {IFlightDesignatorViewModel} from "../../../services/booking/models/designator/flight-designator-view-model.interface";
import styled from "styled-components";
import {DesignatorStationNameComponent} from "../common/components/designator-station-name.component";
import {DesignatorPlaneIconComponent} from "../common/components/designator-plane-icon.component";
import {DesignatorStationCodeComponent} from "../common/components/designator-station-code.component";
import {DesignatorBox} from "../common/boxes/designator.box";

const StationsNamesAndCodesBox = styled(DesignatorBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
`


const StationBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`


interface DesignatorStationsNamesAndCodesComponentProps {
    designator: IFlightDesignatorViewModel;
    useMac?: boolean;
    className?: string;
}
export const DesignatorStationsNamesAndCodesComponent: React.FC<DesignatorStationsNamesAndCodesComponentProps> = observer((props) => {
    const designator = props.designator;
    return (
        <StationsNamesAndCodesBox className={props.className}>
            <StationBox>
                <DesignatorStationNameComponent station={designator.origin} useMac={props.useMac}/>
                <DesignatorStationCodeComponent station={designator.origin} useMac={props.useMac}/>
            </StationBox>

            <DesignatorPlaneIconComponent/>

            <StationBox>
                <DesignatorStationNameComponent station={designator.destination} useMac={props.useMac}/>
                <DesignatorStationCodeComponent station={designator.destination} useMac={props.useMac}/>
            </StationBox>
        </StationsNamesAndCodesBox>
    );
});
