import React from "react";
import {BookingFlowPageFooterSmallScreenComponent} from "./booking-flow-page-footer.small-screen.component";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import {BookingFlowPageHeaderSmallScreenComponent} from "./booking-flow-page-header.small-screen.component";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {BookingFlowPageProps} from "./booking-flow-page.props";
import {VerticalSpacer} from "../../basic-components/spacing/vertical-spacer";
import {AirlineCompanyInfoComponent} from "../../components/airline-company-info/airline-company-info.component";


export const BookingFlowSmallScreenPage: React.FC<BookingFlowPageProps> = observer((props) => {
    const services = useServices();

    const canRenderPage = !services.booking.isBookingRestoreInProgress;

    const renderFooter = () => {
        if (props.hideFooter) {
            return null;
        }

        if(!canRenderPage) {
            return null;
        }

        return (
            <BookingFlowPageFooterSmallScreenComponent hideContinueButton={props.hideContinueButton}
                                                       hideShoppingCart={props.hideShoppingCart}
                                                       hideShoppingCartExpander={props.hideShoppingCartExpander}
                                                       replaceShoppingCart={props.replaceShoppingCart}
                                                       showPromotionCode={props.showPromotionCode}>
                {props.renderExtraFooterContent && props.renderExtraFooterContent()}
            </BookingFlowPageFooterSmallScreenComponent>
        );
    }


    const goToNextStep = async () => {
        if(services.booking.steps.currentStep?.allowSwipeForward) {
            await services.booking.steps.nextStep();
        }

    }

    return (
        <RoutedPageComponent swipeForwardHandler={goToNextStep}
                             disableSwipeForwardNavigation={props.disableSwipeForwardNavigation}
                             disableSwipeNavigation={props.disableSwipeNavigation}>
            <BookingFlowPageHeaderSmallScreenComponent title={props.title}
                                                       hideWizardProgressIndicator={props.hideWizardProgressIndicator}
                                                       onBackButtonClick={props.onBackButtonClick}
                                                       dontAsForConfirmationOnLogoClick={props.dontAskForConfirmationOnLogoClick}>
                {props.renderExtraHeaderContent && props.renderExtraHeaderContent()}
            </BookingFlowPageHeaderSmallScreenComponent>
            <StandardPageContentComponent>
                {props.renderContent()}
                <VerticalSpacer/>
                <AirlineCompanyInfoComponent/>
            </StandardPageContentComponent>
            {renderFooter()}
        </RoutedPageComponent>
    );
});
