import React from 'react';
import {observer} from "mobx-react";
import {DepartureAndArrivalTimeComponent} from "../../../../../components/departure-and-arrival-time/departure-and-arival-time.component";
import {AvailableFareListComponent} from "./available-fare-list.component";
import styled from 'styled-components';
import { CardBox } from '../../../../../basic-components/card/card.box';
import {IAvailableJourneyViewModel} from "../../../../../services/flight-search/models/available-journey-view-model.interface";
import { useServices } from '../../../../../hooks/use-services.hook';

export const AvailableJourneyBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.medium};
`
const ArrivesNexDayBox = styled.div`
    margin: 2px 0 12px;
    font-style: italic;
    color: ${props => props.theme.colors.medium};
    text-align: center;
    line-height: 1;
`

const SegmentsDeparturesAndArrivalsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.medium};
    width: 100%;
`

interface JourneyComponentProps {
    journey: IAvailableJourneyViewModel;
}

export const AvailableJourneyComponent: React.FC<JourneyComponentProps> = observer((props) => {
    const services = useServices();
    
    return (
        <AvailableJourneyBox className={'tst-available-journey'}>
            <SegmentsDeparturesAndArrivalsBox>
                {props.journey.segments.map((segment) => (
                    <DepartureAndArrivalTimeComponent
                        key={segment.key}
                        designator={segment.designator}
                        flightIdentifier={segment.identifier}
                        displayDate={true}
                        displayNumberOfStops={true}
                    />
                ))}
            </SegmentsDeparturesAndArrivalsBox>

            {props.journey.designator.arrivalOnNextDay() && <ArrivesNexDayBox>{services.language.translate("Arrives next day!")}</ArrivesNexDayBox>}
            <AvailableFareListComponent fares={props.journey.fares}/>
        </AvailableJourneyBox>
    );
});


