import React from "react";
import { IFlightDesignatorViewModel } from "../../services/booking/models/designator/flight-designator-view-model.interface";
import { IFlightIdentifierViewModel } from "../../services/booking/models/identifier/flight-identifier-view-model.interface";
import {DesignatorStationsCodesComponent} from "./stations-codes/designator-stations-codes.component";
import {DesignatorStationsNamesComponent} from "./stations-names/designator-stations-names.component";
import {DesignatorStationsNamesAndCodesComponent} from "./stations-names-and-codes/designator-stations-names-and-codes.component";
import {StationsNamesAndDateComponent} from "./stations-names-and-date/stations-names-and-date.component";
import {SegmentDesignatorComponent} from "./segment-designator/segment-designator.component";
import {DesignatorStationsNamesDateAndStopsComponent} from "./stations-names-date-and-stops/designator-stations-names-date-and-stops.component";
import {JourneyDesignatorComponent} from "./journey-designator/journey-designator.component";

export enum DesignatorDisplayMode {
    stationsCodeOnly,
    stationsNamesOnly,
    stationsNamesAndCodes,
    stationsNamesAndDate,
    stationsNamesAndDateAndFlightStops,
    journeyDesignator,
    segmentDesignator
}

export interface DesignatorComponentProps {
    className?: string;
    displayMode: DesignatorDisplayMode,
    designator: IFlightDesignatorViewModel;
    identifier?: IFlightIdentifierViewModel;
    useMac?: boolean;
}

export const DesignatorComponent: React.FC<DesignatorComponentProps> = (props) => {
    const designator = props.designator;

    switch (props.displayMode) {
        case DesignatorDisplayMode.stationsCodeOnly:
            return (<DesignatorStationsCodesComponent designator={designator} className={props.className} useMac={props.useMac}/>);
        case DesignatorDisplayMode.stationsNamesOnly:
            return (<DesignatorStationsNamesComponent designator={designator} className={props.className} useMac={props.useMac}/>);
        case DesignatorDisplayMode.stationsNamesAndCodes:
            return (<DesignatorStationsNamesAndCodesComponent designator={designator} className={props.className} useMac={props.useMac} />);
        case DesignatorDisplayMode.stationsNamesAndDateAndFlightStops:
            return (<DesignatorStationsNamesDateAndStopsComponent designator={designator} className={props.className} useMac={props.useMac}/>);
        case DesignatorDisplayMode.stationsNamesAndDate:
            return (<StationsNamesAndDateComponent designator={designator} className={props.className} useMac={props.useMac}/>);
        case DesignatorDisplayMode.segmentDesignator:
            return (<SegmentDesignatorComponent designator={designator} identifier={props.identifier} className={props.className} />);
        case DesignatorDisplayMode.journeyDesignator:
            return (<JourneyDesignatorComponent designator={designator} className={props.className} />);
        default:
            return (<DesignatorStationsNamesComponent designator={designator} className={props.className} useMac={props.useMac} />);
    }
}
