import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {ISegmentViewModel} from "../../../services/booking/models/segment/segment-view-model.interface";
import {TicketSegmentDetailsComponent} from "./ticket-segment-details.component";
import {TicketSegmentPassengersComponent} from "./ticket-segment-passengers.component";
import {TicketAirlineLogoComponent} from "./ticket-airline-logo.component";
import {TicketRecordLocatorComponent} from "./ticket-record-locator.component";
import {TicketPrintDateComponent} from "./ticket-print-date.component";
import {TicketBookingContactComponent} from "./ticket-booking-contact.component";
import {IBookingViewModel} from "../../../services/booking/models/booking-view-model.interface";

interface TicketSegmentComponentProps {
    booking: IBookingViewModel;
    segment: ISegmentViewModel;
    isLastOne: boolean;
}
export const TicketSegmentComponent: React.FC<TicketSegmentComponentProps> = observer((props) => {
    const styles: CSSProperties = {
        width: '100%'
    };

    if(!props.isLastOne) {
        styles.pageBreakAfter = 'always';
        styles.breakAfter = "always";
    }

    return (
        <div style={styles}>
            <TicketAirlineLogoComponent/>
            <TicketRecordLocatorComponent recordLocator={props.booking.recordLocator ?? ""}/>
            <TicketPrintDateComponent/>
            <TicketBookingContactComponent bookingContact={props.booking.contact}/>
            <TicketSegmentDetailsComponent segment={props.segment}/>
            <TicketSegmentPassengersComponent segment={props.segment}/>
        </div>
    );
})