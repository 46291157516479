import React from "react";
import {observer} from "mobx-react-lite";
import {FlightSearchPage} from "../flight-search.page";
import {useServices} from "../../../hooks/use-services.hook";
import {SpecialPriceMarketUserAgreementFooterComponent} from "./components/special-price-market-user-agreement-footer.component";
import {SpecialPriceUserAgreementPageContentComponent} from "./components/special-price-user-agreement-page-content.component";

export const FlightSearchSpecialPriceMarketUserAgreementPage: React.FC = observer(() => {
    const services = useServices();
    return (
        <FlightSearchPage title={services.language.translate('TERRITORIAL CONTINUITY REGIME')}
                          renderFooter={() => <SpecialPriceMarketUserAgreementFooterComponent searchController={services.flightSearch.searchController}/>}  >
            <SpecialPriceUserAgreementPageContentComponent searchController={services.flightSearch.searchController}/>
        </FlightSearchPage>
    );
});
