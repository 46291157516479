import {IBlueBenefitsPackageToPurchaseViewModel} from "./blue-benefits-package-to-purchase-view-model.interface";
import {IBlueBenefitsPackage} from "../../../blue-benefits/models/packs/blue-benefits-package.interface";
import {BookingBlueBenefitsModel} from "./booking-blue-benefits.model";

export class BlueBenefitsPackageToPurchaseModel implements IBlueBenefitsPackageToPurchaseViewModel {
    constructor(public readonly blueBenefitsPackage: IBlueBenefitsPackage,
                private readonly bookingBlueBenefits: BookingBlueBenefitsModel) {
    }

    get isSelected(): boolean {
        if(this.bookingBlueBenefits.inProgressPurchasePackage) {
            return this.bookingBlueBenefits.inProgressPurchasePackage.packageCode === this.blueBenefitsPackage.packageCode;
        }
        return this.blueBenefitsPackage.packageCode === this.bookingBlueBenefits.currentPurchasedPackage?.packageCode;
    }


    async purchase(): Promise<void> {
        await this.bookingBlueBenefits.purchase(this.blueBenefitsPackage);
    }

}
