import {ICheckInRestriction} from "./check-in-restriction.interface";

export class TravelDocumentValidationRestriction implements ICheckInRestriction {
    constructor(private readonly message: string) {
    }

    get description(): string {
        return this.message;
    }

    get shouldBlockSavingTravelDocuments(): boolean {
        return true;
    }

}
