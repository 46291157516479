export class TimePeriod {
    constructor(public readonly startDate: Date, public readonly endDate: Date) {
    }

    contains(date: Date): boolean {
        return this.startDate.getTime() <= date.getTime() && date.getTime() <= this.endDate.getTime();
    }

    intersect(otherPeriod: TimePeriod): boolean {
        return (otherPeriod.startDate.getTime() <= this.startDate.getTime() && this.startDate.getTime() <= otherPeriod.endDate.getTime())
                || (otherPeriod.startDate.getTime() <= this.endDate.getTime() && this.endDate.getTime() <= otherPeriod.endDate.getTime())
    }
}
