import {BookingModel} from "../models/booking.model";
import {AvailabilityModel} from "../../flight-search/models/availability.model";
import {IRoundTripLowFareReader} from "../../low-fare/low-fare-readers/low-fare-reader.interface";
import {IFlightSearchControllerViewModel} from "../../flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {IWizard} from "../../../models/wizard/wizard.interface";

export const BOOKING_NOT_INITIALIZED_MSG = 'Current booking was not initialized'

export enum ShoppingCartModeEnum {
    ShowAllPurchases,
    ShowNewPurchasesOnly
}

export interface IBookingStrategy {
    readonly booking: BookingModel;
    readonly activeCurrency: string;
    readonly flightSearchController: IFlightSearchControllerViewModel;
    readonly steps: IWizard;
    readonly availability: AvailabilityModel;
    dispose(): Promise<void>;
    upgradeCurrentBookingSessionToAuthorizedUser(userName: string, password: string): Promise<void>;
    getLowFaresReader(): IRoundTripLowFareReader;
}
