import React, {PropsWithChildren} from "react";
import {ShoppingCartButtonComponent} from "../../components/shopping-cart/shopping-cart-button.component";
import {useServices} from "../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {PromoCodeComponent} from "../../components/promotionals/promo-code/promo-code.component";
import {StandardPageFooterComponent} from "../../basic-components/page/standard-page-footer/standard-page-footer.component";
import styled from "styled-components";
import {BookingFlowContinueButtonComponent} from "./common-components/continue-button/booking-flow-continue-button.component";

const BookingFlowPageFooterContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const TotalAndPromoCodeContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    gap: ${props => props.theme.spacing.small};
    
`

const ContainerWhenDisplayedInHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

interface BookingFlowPageFooterComponentProps extends PropsWithChildren {
    hideContinueButton?: boolean;
    hideShoppingCart?: boolean;
    hideShoppingCartExpander?: boolean;
    replaceShoppingCart?: () => React.ReactElement;
    modalHandler?: IDialogConfirmationHandler;
    isDisplayedInHeader?: boolean;
    showPromotionCode?: boolean;
}

export const BookingFlowPageFooterSmallScreenComponent: React.FC<BookingFlowPageFooterComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const shouldShowPromoCode = Boolean(props.showPromotionCode) && booking.allowPromoCode;

    const renderPromotionCode = () => {
        if(!shouldShowPromoCode){
            return null;
        }

        return (
            <PromoCodeComponent promoCode={booking.promoCode}/>
        );
    }

    const renderContinueButton = (fullWidth: boolean) => {
        if(props.hideContinueButton) {
            return null;
        }
        return (
            <BookingFlowContinueButtonComponent isDisplayedInHeader={props.isDisplayedInHeader}
                                                isPromotionAllowed={shouldShowPromoCode}
                                                modalHandler={props.modalHandler}
                                                fullWidth={fullWidth ? true : undefined}/>
        );
    }

    const renderShoppingCart = () => {
        if(props.replaceShoppingCart) {
            return props.replaceShoppingCart();
        }

        if(props.hideShoppingCart) {
            return null;
        }

        return (
            <ShoppingCartButtonComponent isDisplayedInHeader={props.isDisplayedInHeader}
                                         hideExpandButton={props.hideShoppingCartExpander}
                                         modalHandler={props.modalHandler} />
        );

    }

    const renderTotalAndPromoCode = () => {
        const elements: React.ReactElement[] = [];

        let el = renderShoppingCart();
        if(el) {
            elements.push((
                <React.Fragment key={"SHOPPING_CART"}>
                    {el}
                </React.Fragment>
            ))
        }

        el = renderPromotionCode();
        if(el) {
            elements.push((
                <React.Fragment key={"PROMO_CODE"}>
                    {el}
                </React.Fragment>
            ))
        }

        return elements;

    }

    const renderMainFooter = () => {
        if(props.hideShoppingCart && props.hideContinueButton) {
            return null;
        }

        const totalAndPromoCode = renderTotalAndPromoCode();

        return (
            <BookingFlowPageFooterContentBox>
                {
                    totalAndPromoCode.length > 0
                    && (<TotalAndPromoCodeContainerBox>
                            {totalAndPromoCode}
                        </TotalAndPromoCodeContainerBox>)
                }
                {renderContinueButton(totalAndPromoCode.length === 0)}
            </BookingFlowPageFooterContentBox>
        );
    }

    if(props.isDisplayedInHeader) {
        return (
            <ContainerWhenDisplayedInHeader>
                {renderMainFooter()}
                {props.children}
            </ContainerWhenDisplayedInHeader>
        )
    }

    return (
        <StandardPageFooterComponent>
            {props.children}
            {renderMainFooter()}
        </StandardPageFooterComponent>
    );
});
