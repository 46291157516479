import {ICheckInRestriction} from "./check-in-restriction.interface";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {IServiceFactory} from "../../../service-factory.interface";

export class SsrOnlineCheckInRestriction implements ICheckInRestriction {
    constructor(private readonly ssrType: ISsrType, private readonly services: IServiceFactory) {
    }

    get description(): string {
        return this.services.language.translationFor(`Cannot perform online check-in because this passenger acquired '{ssrName}' service`)
            .withParams({ssrName: this.ssrType.name});
    }

    get shouldBlockSavingTravelDocuments(): boolean {
        return false;
    }

}
