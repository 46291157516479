import React from "react";
import {observer} from "mobx-react-lite";
import {NullableString} from "../../../../types/nullable-types";
import styled from "styled-components";
import {XCircleFilledIcon} from "../../../../assets/svg/x-circle-filled.icon";
import {ExternalLinkComponent} from "../../../../components/external-link/external-link.component";


const DateChangeBlockingReasonDescriptionBox = styled.div`
    display: flex;
    flex-direction: column;   
`

const TitleBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
`


const DateChangeBlockingReasonBox = styled.div`
    display: flex;
    padding-left: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.dark};
    line-height: 1.4;
    align-items: flex-start;

    svg {
        margin-right: 8px;
        flex-shrink: 0;
    }
`

interface FlightChangeBlockingReasonComponentProps {
    blockingReason: NullableString;
    title: string;

}

export const FlightChangeBlockingReasonComponent: React.FC<FlightChangeBlockingReasonComponentProps> = observer((props) => {
    if(!props.blockingReason) {
        return null;
    }
    return (
        <DateChangeBlockingReasonBox >
            <XCircleFilledIcon size={2}/>
            <DateChangeBlockingReasonDescriptionBox>
                <TitleBox>
                    {props.title}
                </TitleBox>
                <ExternalLinkComponent innerHtml={props.blockingReason}/>
            </DateChangeBlockingReasonDescriptionBox>

        </DateChangeBlockingReasonBox>
    );
});
