import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {PlaneTicketsIcon} from "../../assets/svg/plane-tickets.icon";
import {useServices} from "../../hooks/use-services.hook";
import {NullableString} from "../../types/nullable-types";

const BookingCodeBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const BookingCodeTextBox = styled.span`
    text-transform: none;
    padding-left: ${props => props.theme.spacing.small};
`

export const BookingCodeComponent: React.FC<{recordLocator: NullableString; className?: string}> = observer((props) => {
    const services = useServices();
    if(!props.recordLocator) {
        return null;
    }

    return (
        <BookingCodeBox className={props.className}>
            <PlaneTicketsIcon size={2}/>
            <BookingCodeTextBox>
                {services.language.translate('Booking code:') + ' ' + props.recordLocator}
            </BookingCodeTextBox>
        </BookingCodeBox>
    )
});
