import React from 'react';
import {IServiceFactory} from "../../../service-factory.interface";
import {SbusSsrSmallIcon} from "./sbus-ssr.small-icon";
import {SsrTypeBase} from "../../ssr-type-base";
import {SSR_STANDARD_LARGE_ICON_SIZE} from "../../ssr-types.service.interface";

export default class SbusSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('SBUS', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<SbusSsrSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<SbusSsrSmallIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>);
    }
}
