import {ILanguageService, ISupportedLanguageViewModel} from "./language.service.interface";
import {makeObservable, observable, reaction, runInAction} from "mobx";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {Locale} from "date-fns";
//import {de, el, enGB, es, fr, it, ro} from 'date-fns/locale';
import {enGB, it, ro} from 'date-fns/locale';
import {TranslationWithParams} from "./translation-with-params";
import {AirlineWebapiErrorCodesEnum} from "../airline-webapi/airline-webapi-error-codes.enum";
import {NonEnvLocalStorageKeys} from "../storage/local-storage-keys";
import {DeeplinkSearchQueryParamsEnum} from "../deep-links/deeplink-search-query-params.enum";


const INITIAL_LANGUAGE_CODE = 'it-it';
const FALLBACK_LANGUAGE_CODE = 'en-gb';

interface ISupportedLocale {
    locale: Locale;
    iso2: string;
    iso3: string;
    name: string;
}

export class LanguageService extends ServiceBase implements ILanguageService {
    constructor(services: IServiceFactory) {
        super(services);
        this.changeCurrentLanguage(this._readInitialLanguageCode());
        makeObservable<this, '_selectedLocale' | '_texts'>(this, {
            _selectedLocale: observable.ref,
            _texts: observable.ref
        });

        reaction(() => this._selectedLocale,
            async () => {
                await this.services.configuration.loadConfigurations();
            });
    }

    private static _supportedLocales: ISupportedLocale[] = [
        {
            locale: enGB,
            iso2: 'en',
            iso3: 'en-gb',
            name: "English"
        },
        {
            locale: it,
            iso2: 'it',
            iso3: 'it-it',
            name: "Italiano"
        },
        {
            locale: ro,
            iso2: 'ro',
            iso3: 'ro-ro',
            name: "Română"

        }
    ];

    private _selectedLocale: ISupportedLocale = LanguageService._supportedLocales[0];

    private _findSupportedLocaleByCode(languageCode: string): ISupportedLocale | undefined {
        languageCode = languageCode?.toLowerCase();
        return LanguageService._supportedLocales.find(l => l.iso3 === languageCode || l.iso2 === languageCode);
    }

    changeCurrentLanguage(languageCode: string){
        languageCode = languageCode.toLowerCase();
        runInAction(() => {
            const supportedLocale = this._findSupportedLocaleByCode(languageCode);
            if(supportedLocale) {
                this._selectedLocale = supportedLocale;
            } else {
                this.services.logger.error(`Attempt to set unsupported language ${languageCode}`);
                this._selectedLocale = this._findSupportedLocaleByCode(INITIAL_LANGUAGE_CODE) ?? LanguageService._supportedLocales[0];
            }
            localStorage.setItem(NonEnvLocalStorageKeys.userProfileLanguage, this._selectedLocale.iso3);
        })
        this._loadTexts();
    }

    get currentLanguage(): string {
        return this._selectedLocale.iso3;
    }

    get currentLocale(): Locale {
        return this._selectedLocale.locale;
    }

    get currentLanguageIso2(): string {
        return this._selectedLocale.iso2;
    }

    get supportedLanguages(): ISupportedLanguageViewModel[] {
        return LanguageService._supportedLocales.map<ISupportedLanguageViewModel>(sl => {
            return {
                name: sl.name,
                code: sl.iso3
            }
        }).sort((l1, l2) => l1.name.localeCompare(l2.name));
    }

    private _readInitialLanguageCode(): string {
        const queryParams = this.services.navigator.getQueryParamsValues(DeeplinkSearchQueryParamsEnum.Culture);
        if(queryParams[DeeplinkSearchQueryParamsEnum.Culture]) {
            return queryParams[DeeplinkSearchQueryParamsEnum.Culture].toLowerCase();
        } else {
            return (localStorage.getItem(NonEnvLocalStorageKeys.userProfileLanguage) || INITIAL_LANGUAGE_CODE).toLowerCase();
        }
    }

    private _loadTexts(): void {
        if(this.currentLanguage === FALLBACK_LANGUAGE_CODE) {
            this.texts = {};
            return;
        }

        import(`./translations/texts/texts.${this.currentLanguage}`).then(module => {
            this.texts = module.default;
        }).catch((err) => {
            this.services.logger.error(`Failed to load texts for language ${this.currentLanguage}`, err);
            this.texts = {};
        });
    }



    private _texts: Record<string, string> = {};
    get texts(): Record<string, string> {
        return this._texts;
    }

    set texts(value: Record<string, string>) {
        runInAction(() => {
            this._texts = value;
        });
    }

    translate(template: string): string {
        if(this.texts[template]) {
            return this.texts[template];
        } else {
            return template;
        }
    }

    translationFor(template: string): TranslationWithParams {
        if(this.texts[template]) {
            template = this.texts[template];
        }
        return new TranslationWithParams(template);
    }

    translateApiError(errorCode: AirlineWebapiErrorCodesEnum): string {
        switch (errorCode) {
            case AirlineWebapiErrorCodesEnum.InvalidCredentials:
                return this.translate('Invalid user name or password');
            case AirlineWebapiErrorCodesEnum.AccountLockedOut:
                return this.translate('Your account is locked. Please check your e-mail and confirm your registration before proceed with login');
            case AirlineWebapiErrorCodesEnum.UserAlreadyExists:
                return this.translate('User already exists');
            case AirlineWebapiErrorCodesEnum.PasswordAlreadyUsedInTheLast5Password:
                return this.translate('The password requested was used in the last 5 passwords.Please select a different password');
            case AirlineWebapiErrorCodesEnum.UserDoesntExist:
                return this.translate('This user name does not exist');
            default:
                return this.genericApiErrorMessage();
        }
    }

    genericApiErrorMessage(): string {
        return this.translate('There was an error processing your request');
    }
}
