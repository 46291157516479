import {IServiceFactory} from "../../../../service-factory.interface";
import {SpecialAssistanceSsrBase} from "../special-assistance-ssr-base";

//Wheelchair required to/from cabin seat - passenger immobile
export default class WchcSsrType extends SpecialAssistanceSsrBase {
    constructor(services: IServiceFactory) {
        super('WCHC', services);
    }

}
