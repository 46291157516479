import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";

export const PriceLabelBox = styled.span`
    font-size: ${props => props.theme.fontSize.xSmall};
    color: ${props => props.theme.colors.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
`

interface StandardPriceLabelComponentProps {
    className?: string;
}

export const StandardPriceLabelComponent: React.FC<StandardPriceLabelComponentProps> = observer((props) => {
    const services = useServices();

    return (
        <PriceLabelBox className={props.className}>
            {services.language.translate('From')}
        </PriceLabelBox>
    )
});
