import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {FieldViewComponent} from "../field-view.component";
import {NullableDate} from "../../../types/nullable-types";

export const DateValueViewComponent: React.FC<{value: NullableDate; label: string}> = observer((props) => {
    const services = useServices();
    return (
        <FieldViewComponent label={props.label}
                            value={props.value && services.time.formatUserFriendlyDate(props.value)}/>
    );
});
