import React from "react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {StationDropDownComponent} from "./station-drop-down/station-drop-down.component";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PlaneDepartLineIcon} from "../../../../../../assets/svg/plane-depart-line.icon";
import {PlainLandingLineIcon} from "../../../../../../assets/svg/plane-landing-line";
import {SwapIcon} from "../../../../../../assets/svg/swap.icon";
import {VerticalSpacer} from "../../../../../../basic-components/spacing/vertical-spacer";
import {HorizontalSpacer} from "../../../../../../basic-components/spacing/horizontal-spacer";

const StationsSelectionContainerBox = styled.div<{isMobileView: boolean}>`
    display: flex;
    flex-direction: ${props => props.isMobileView ? 'column' : 'row'};
    align-items: center;
    width: 100%;
  
`

const SwapIconBox = styled.div<{isMobileView: boolean}>`
  display: flex;
  flex-direction: ${props => props.isMobileView ? 'column' : 'row'};
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
`

const SwapStationsComponent: React.FC = observer(() => {
    const services = useServices();
    const onClick = () => {
        services.flightSearch.switchStations();
    }
    const renderSeparator = () => {
        if(services.layout.isInSmallScreenView) {
            return (
                <VerticalSpacer/>
            );
        } else {
            return (
                <HorizontalSpacer/>
            );
        }
    }

    const renderContent = () => {
        if(services.layout.isInSmallScreenView) {
            return renderSeparator();
        } else {
            return (
                <>
                    {renderSeparator()}
                    <SwapIcon size={4}/>
                    {renderSeparator()}
                </>
            );
        }
    }

    return (
        <SwapIconBox onClick={onClick} isMobileView={services.layout.isInSmallScreenView}>
            {renderContent()}
        </SwapIconBox>
    )
});

export const SearchMaskStationsSelectorWebappComponent: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;
    const getOriginStations = () => {
        return searchController.availableDepartureStations;
    }

    const getDestinationStations = () => {
        return searchController.availableReturnStations;
    }



    const renderDepartureNoStationsMessage = () => {
        return services.language.translate('No departure available');
    }

    const renderDestinationNoStationMessage = () => {
        if(!searchController.departureOrigin) {
            return services.language.translate('Select departure first');
        }

        return services.language.translate('No destination available');
    }

    return (
        <StationsSelectionContainerBox isMobileView={services.layout.isInSmallScreenView}>
            <StationDropDownComponent field={searchController.fields.departureOrigin}
                                      customLabel={services.language.translate('Departure')}
                                      icon={<PlaneDepartLineIcon size={2}/>}
                                      getStations={getOriginStations}
                                      placeholder={services.language.translate('Select departure')}
                                      noStationsMessage={renderDepartureNoStationsMessage}
                                      showBorders={true}/>
            <SwapStationsComponent/>
            <StationDropDownComponent field={searchController.fields.departureDestination}
                                      customLabel={services.language.translate('Destination')}
                                      icon={<PlainLandingLineIcon size={2}/>}
                                      getStations={getDestinationStations}
                                      placeholder={services.language.translate('Select destination')}
                                      noStationsMessage={renderDestinationNoStationMessage}
                                      showBorders={true}/>
        </StationsSelectionContainerBox>
    );
});
