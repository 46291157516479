import React from "react";
import {observer} from "mobx-react-lite";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {
    BookingSearchComponent,
    BookingSearchComponentProps
} from "../home/components/booking-search/booking-search.component";
import {StandardPageHeaderLargeScreenComponent} from "../../basic-components/page/standard-page-header/standard-page-header.large-screen.component";
import styled from "styled-components";
import {AirlineCompanyInfoComponent} from "../../components/airline-company-info/airline-company-info.component";
import {
    StandardPageFooterComponent
} from "../../basic-components/page/standard-page-footer/standard-page-footer.component";

const StandardPageContentBox = styled(StandardPageContentComponent)`
  --padding-top: 2px;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
`

const PageContentBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const SearchContainerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;

  background-image: url("${props => props.theme.assets.images.booking_search_background_jpg.toString()}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primaryContrast};
 
  padding: 0 25%;
  @media ${(props) => props.theme.mediaQuery.lAndBelow} {
    padding: 0 20%;
  }

  @media ${(props) => props.theme.mediaQuery.mAndBelow} {
    padding: 0 15%;
  }

  @media ${(props) => props.theme.mediaQuery.sAndBelow} {
    padding: 60px ${props => props.theme.spacing.pageDefaultPaddingPx};
    align-items: flex-start;
  }
`

export const SearchBookingBasePage: React.FC<BookingSearchComponentProps> = observer((props) => {

    return (
        <RoutedPageComponent>
            <StandardPageHeaderLargeScreenComponent />
            <StandardPageContentBox>
                <PageContentBox>
                    <SearchContainerBox>
                        <BookingSearchComponent {...props}/>
                    </SearchContainerBox>

                </PageContentBox>
            </StandardPageContentBox>
            <StandardPageFooterComponent>
                <AirlineCompanyInfoComponent/>
            </StandardPageFooterComponent>
        </RoutedPageComponent>
    );
})
