import {ServiceFactoryBase} from "./service-factory-base";
import {Lazy} from "../utils/lazy";
import {IFlightSearchService} from "./flight-search/flight-search.service.interface";
import {FlightSearchWebappService} from "./flight-search/flight-search.webapp.service";

export class WebappServiceFactory extends ServiceFactoryBase {
    protected async _onInitialize(): Promise<void> {
        await super._onInitialize();
        this._flightSearch.forceInit();
    }

    protected async _waitForMaintenance(): Promise<void> {
        //no need
    }
    protected async _showCookiesPolicy(): Promise<void> {
        //no need
    }

    private _flightSearch: Lazy<IFlightSearchService> = new Lazy<IFlightSearchService>(() => new FlightSearchWebappService(this));
    get flightSearch(): IFlightSearchService {
        return this._flightSearch.value;
    }
}
