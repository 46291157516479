import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {ReturnFlightSelectionPageContent} from "./return-flight-selection-page-content.component";
import {ReturnFlightSelectionExtraHeaderComponent} from "../components/extra-header/return-flight-selection-extra-header.component";
import {BookingFlowPage} from "../../booking-flow.page";

export const ReturnFlightSelectionPage: React.FC = observer(() => {
    const services = useServices();

    const renderExtraHeader = () => {
        return (<ReturnFlightSelectionExtraHeaderComponent/>)
    }

    return (
        <BookingFlowPage title={services.language.translate('Return Flight')}
                               renderContent={() => (<ReturnFlightSelectionPageContent allowBundleSelection={true}/>)}
                               renderExtraHeaderContent={renderExtraHeader}
                               showPromotionCode={false}/>
    );
});
