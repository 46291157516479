import {BookingModel} from "../../booking.model";
import {IPaymentMethodModel} from "./payment-method.model.interface";
import {PaymentMethodCodesEnum} from "./payment-method-codes.enum";
import {NullableUndefinedNumber} from "../../../../../types/nullable-types";
import {VisaPaymentMethodModel} from "./card/visa-payment-method.model";
import {MastercardPaymentMethodModel} from "./card/mastercard-payment-method.model";
import {MaestroPaymentMethodModel} from "./card/maestro-payment-method.model";
import {ApplePayModel} from "./mobile-wallet/apple-pay.model";
import {GooglePayModel} from "./mobile-wallet/google-pay.model";
import {PaypalPaymentMethodModel} from "./card/paypal-payment-method.model";

export class PaymentMethodFactory {
    public static createPaymentMethod(booking: BookingModel, code: string, availableAmountInBookingCode: NullableUndefinedNumber): IPaymentMethodModel | null {
        switch (code) {
            case PaymentMethodCodesEnum.VisaPaymentHub:
            case PaymentMethodCodesEnum.VisaNewsky:
                return new VisaPaymentMethodModel(booking, code);
            case PaymentMethodCodesEnum.MasterCardPaymentHub:
            case PaymentMethodCodesEnum.MastercardNewsky:
                return new MastercardPaymentMethodModel(booking, code);
            case PaymentMethodCodesEnum.MaestroPaymentHub:
                return new MaestroPaymentMethodModel(booking, code);
            case PaymentMethodCodesEnum.ApplePay:
                return new ApplePayModel(booking, code);
            case PaymentMethodCodesEnum.GooglePay:
                return new GooglePayModel(booking, code);
            case PaymentMethodCodesEnum.PayPal:
                return new PaypalPaymentMethodModel(booking, code);
            default:
                return null;

        }
    }
}