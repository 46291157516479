export function isHttpErrorStatusCode(statusCode: number): boolean {
    return statusCode >= 400;
}

export function buildFullUrl(origin: string, path: string): string {
    origin = origin.trim();

    if(!origin.endsWith('/')) {
        origin = origin + '/';
    }

    if(path.startsWith('/')) {
        path = path.substring(1);
    }

    return origin + path;
}
