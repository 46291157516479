import {ValidationResultEnum} from "../../../../../types/validation-result.enum";
import {PhoneTypeEnum} from "../../../../dot-rez-api/data-contracts/enums/phone-type.enum";
import {AuthorizedUserModel} from "../authorized-user.model";
import {
    IDotRezPerson,
    IDotRezPersonComment,
    IDotRezPersonDetails
} from "../../../../dot-rez-api/data-contracts/user/person.data-contracts";
import {computed, makeObservable, observable, reaction, runInAction} from "mobx";
import {IUserProfileEditorViewModel} from "../../profile/user-profile-editor-view-model.interface";
import {DialogResult} from "../../../../dialog/dialog-enums";
import {IReactionDisposer} from "mobx";
import {IUserProfileViewModel} from "../../profile/user-profile-view-model.interface";
import {PersonModel} from "../../person/person.model";
import {CompanionModel} from "../../companion/companion.model";
import {IPersonViewModel} from "../../person/person-view-model.interface";
import {IDotRezUserSession} from "../../../../dot-rez-api/session/user-session/dot-rez-user.session.interface";
import {IPersonTravelDocumentViewModel} from "../../person/person-travel-document-view-model.interface";
import {IPassengerViewModel} from "../../../../booking/models/passenger/passenger-view-model.interface";
import { UserTravelDocumentModel } from "../../travel-documents/user-travel-document.model";
import {PromiseWaiter} from "../../../../../utils/promise-waiter";
import {TimeOfTheDay} from "../../../../time/time.service.interface";
import {IBlueBenefitsSubscription} from "../../../../blue-benefits/models/subscriptions/blue-benefits-subscription.interface";

export class AuthorizedUserProfileModel extends PersonModel implements IUserProfileViewModel, IUserProfileEditorViewModel {
    constructor(private readonly user: AuthorizedUserModel) {
        super(user.services);
        makeObservable<this, '_personData' | '_companions'>(this, {
            _personData: observable,
            _companions: observable,
            travelDocuments: computed,
            allBlueBenefitsSubscriptions: observable.ref
        });

        this._reactions.push(reaction(() => this.services.application.isActive,
            async (isActive) => {
                if (isActive) {
                    await this.loadProfile();
                }
            }, {
                fireImmediately: true
            }));

        this._reactions.push(reaction(() => this.services.configuration.data,
            async () => {
                if(!this.isProfileInitialized) {
                    //This is a recovery fallback in case the configs failed to initialize on app startup
                    await this.loadProfile();
                }
            }));
    }

    allBlueBenefitsSubscriptions: IBlueBenefitsSubscription[] = [];

    get blueBenefitsSubscription(): IBlueBenefitsSubscription {
        const subscription = this.allBlueBenefitsSubscriptions.find(bbs => bbs.isValidSubscription);
        if(subscription) {
            return subscription;
        }

        return this.allBlueBenefitsSubscriptions[0] || this.services.blueBenefits.noSubscription;
    }

    protected get isPhoneNumberRequired(): boolean {
        return true;
    }

    private _companions: CompanionModel[] | null = null;
    get companions(): CompanionModel[] {
        return this._companions || [];
    }

    get isCompanionsListInitialized(): boolean {
        return Boolean(this._companions);
    }

    private _setCompanions(newCompanions: CompanionModel[]): void {
        runInAction(() => {
            this._companions = this._sortCompanions(newCompanions);
        });
    }

    private _reactions: IReactionDisposer[] = [];

    private _personData: IDotRezPerson | null = null;
    get personData(): IDotRezPerson {
        if(!this._personData) {
            throw new Error('Person data was not initialized');
        }
        return this._personData;
    }

    get isProfileInitialized(): boolean {
        return Boolean(this._personData);
    }

    private _loadUserProfileWaiter: PromiseWaiter = new PromiseWaiter();
    waitForProfileInitialization(): Promise<void> {
        return this._loadUserProfileWaiter.wait();
    }

    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }

    async loadProfile(): Promise<void> {
        await this._loadPersonData();

        if(this.isProfileInitialized) {
            if(this.personData.affiliates.length > 0) {
                this._loadCompanions(); // we don't use await here because we don't want to wait until the companions are loaded                
            } else {
                this._setCompanions([]);
            }
        }
    }

    async getSession(): Promise<IDotRezUserSession> {
        return await this.user.getSession();
    }

    private async _loadPersonData(): Promise<void> {
        try {
            const session = await this.getSession();
            const data = await session.getCurrentUserPerson();
            runInAction(() => {
                this._personData = data;
                this.services.localStorage.setJson('authorizedUser.personData', data);
            });
            await this._loadBlueBenefitsSubscriptions(data.comments);

        } catch (err) {
            this.services.logger.error('Failed to load user profile', err);
            runInAction(() => {
                //fallback: load user person data from local storage
                this._personData = this.services.localStorage.getJson<IDotRezPerson>('authorizedUser.personData') || null
            });
        } finally {
            this._loadUserProfileWaiter.resolve();
        }
    }


    private async _loadBlueBenefitsSubscriptions(personComments: IDotRezPersonComment[]): Promise<void> {
        const subscriptions = await this.services.blueBenefits.createSubscriptionsFromPersonComments(personComments || []);

        runInAction(() => {
            this.allBlueBenefitsSubscriptions = subscriptions.sort((s1, s2) => s2.expirationDate.getTime() - s1.expirationDate.getTime());
        });
    }

    async edit(): Promise<void> {
        const dialogResult = await this.services.dialogFactory.showAuthorizedUserProfileEditor(this);
        if(dialogResult === DialogResult.Rejected) {
            this.cancelChanges();
        }
    }

    async save(): Promise<ValidationResultEnum> {
        if(!this.hasChanges()) {
            return ValidationResultEnum.Success;
        }

        this.activateErrorsValidation();
        if(this.hasErrors()) {
            return ValidationResultEnum.Failure;
        }

        await this.services.loadingIndicator.execute({
            action: async () => {
                try {
                    await this._saveUserPersonDetails();
                    await this._savePhoneNumber();
                    this.commitChanges();
                } finally {
                    await this._loadPersonData();
                }
            }
        });

        return ValidationResultEnum.Success;
    }

    private async _saveUserPersonDetails(): Promise<void> {
        const personDetails: IDotRezPersonDetails = {
            dateOfBirth: this.services.time.formatBirthDate(this.fields.dateOfBirth.value),
            gender: this.fields.gender.value!,
            nationality: this.fields.nationality.value!,
            preferredCultureCode: this.services.language.currentLanguage,
            preferredCurrencyCode: this.services.currency.current
        }

        const session = await this.user.getSession();
        await session.updatePersonDetails({
            name: {
                title: this.services.personTitle.personTitleFromGender(this.fields.gender.value),
                first: this.personData.name.first,
                last: this.personData.name.last,
            },
            details: personDetails
        });
    }

    private async _savePhoneNumber(): Promise<void> {
        const session = await this.user.getSession();
        const currentPhoneNumber = this.personData.phoneNumbers[0];
        if(currentPhoneNumber) {
            if(currentPhoneNumber.number !== this.fields.phoneNumber.value) {

                await session.updatePhone({
                    ...currentPhoneNumber,
                    number: this.fields.phoneNumber.value  || ''
                });
            }
        } else {

            await session.addPhone({
                number: this.fields.phoneNumber.value,
                type: PhoneTypeEnum.Home,
                default: true
            });
        }
    }

    getEditor(): IUserProfileEditorViewModel {
        return this;
    }

    private async _loadCompanions(): Promise<void> {
        try {
            const session = await this.user.getSession();
            const companionsData = await this.services.airlineWebapi.getCompanions(session.token)
            const companions = companionsData.map(data => new CompanionModel(this, data));
            this._setCompanions(companions);
        } catch (err) {
            this.services.logger.error('Failed to load user companions', err);
        }
    }

    getGreetingMessage(): string {
        const language = this.services.language;
        const translationParams = {userName: this._personData?.name.first || ''}

        switch (this.services.time.getTimeOfTheDay()) {
            case TimeOfTheDay.Morning:
                return language.translationFor('Good morning {userName}!').withParams(translationParams);
            case TimeOfTheDay.Evening:
                return language.translationFor('Good evening {userName}!').withParams(translationParams);
            default:
                return language.translationFor('Hi {userName}!').withParams(translationParams);
        }
    }

    async createCompanionFromPassenger(passenger: IPassengerViewModel): Promise<IPersonViewModel> {
        if(!passenger.fields.firstName.value || !passenger.fields.lastName.value) {
            throw new Error('firstName and lastName are required in order to create a companion');
        }
        const userSession = await this.user.getSession();
        const companionData = await this.services.airlineWebapi.addCompanion({
            dotRezToken: userSession.token,
            firstName: passenger.fields.firstName.value,
            lastName: passenger.fields.lastName.value
        });

        const companion = new CompanionModel(this, companionData);
        this._setCompanions( [...this.companions, companion]);
        return companion;
    }

    private _sortCompanions(companions: CompanionModel[]): CompanionModel[] {
        return companions.sort((c1, c2) => c1.getFullName().localeCompare(c2.getFullName()));
    }

    getCompanionsForBooking(): IPersonViewModel[] {
        return [
            this.asCompanion(),
            ...this.companions
        ];
    }
    asCompanion(): IPersonViewModel {
        return this;
    }
    protected _createNewTravelDocument(): IPersonTravelDocumentViewModel {
        return new UserTravelDocumentModel(this);
    }

    protected _getPersonDetailsNotCompletedErrorMessage(): string {
        return this.services.language.translate('Please complete your personal details and then you can add a travel document.');
    }

    get canAddMoreCompanions(): boolean {
        return this.companions.length < this.services.configuration.data.maxAllowedPassengersNumberOnBooking;
    }

    get companionsCountLimit(): number {
        return this.services.configuration.data.maxAllowedPassengersNumberOnBooking;
    }

    async addCompanion(): Promise<void> {
        if(!this.canAddMoreCompanions) {
            this.services.alert.showError(this.services.language.translate('You cannot add more companions. You have reached you companions limit number'));
            return;
        }

        const companion = new CompanionModel(this);
        await companion.edit();
        if(!companion.isNew) {
            this._setCompanions([...this.companions, companion]);
        }
    }
    
    get travelDocuments(): IPersonTravelDocumentViewModel[] {
        if(!this._personData) {
            return [];
        }
        return this._personData.travelDocuments.map(td => new UserTravelDocumentModel(this, td.personTravelDocumentKey));
    }
}
