import React from "react";
import styled from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../../../../services/dialog/dialog.service.interface";
import {DialogCloseButtonBehavior} from "../../../../../services/dialog/dialog-enums";
import {ExtrasMinPriceSmallScreenComponent} from "./summary/extras-min-price.small-screen.component";
import {SsrModificationBlockingReasonComponent} from "../../../common-components/ssr-modification-blocking-reason.component";
import {PrimaryButtonInverted} from "../../../../../basic-components/buttons/primary-button-inverted";
import {FootNoteComponent} from "../../../../../components/foot-note/foot-note.component";
import {ExtrasSectionComponentCommonProps} from "../extras-section.component.common-props";
import {CardBox} from "../../../../../basic-components/card/card.box";

export const ExtrasSectionSmallScreenBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:${props => props.theme.spacing.large};
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
    width: 100%;
    height: 100%;
    padding: ${props => props.theme.spacing.large};
    @media ${(props) => props.theme.mediaQuery.xs} {
      padding: ${props => props.theme.spacing.medium};
    }
`;

const TopSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.spacing.small};
    min-height: 100px;
`

const MiddleSectionBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const BottomSectionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${props => props.theme.spacing.small};
  min-height: 60px;
  margin-top: ${props => props.theme.spacing.medium};
`

const RowBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const IconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: ${props => props.theme.colors.primaryShade};
`;


const TitleBox = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const DetailsBox = styled(FootNoteComponent)`
    text-align: center;
`

const ChangeButton = styled(PrimaryButtonInverted)`
  min-width: 110px;
  min-height: 36px;
  padding-bottom: ${props => props.theme.spacing.small};
  padding-top: ${props => props.theme.spacing.small};
`



interface ExtrasSectionSmallScreenComponentProps extends ExtrasSectionComponentCommonProps {
    showButton: boolean;
    renderDialog?: (dialogHandler: IDialogConfirmationHandler) => React.ReactElement;
    onAddButtonClick?: () => void;
}

export const ExtrasSectionSmallScreenComponent: React.FC<ExtrasSectionSmallScreenComponentProps> = (props) => {
    const services = useServices();

    const onRenderModal = async () => {
        if(props.renderDialog){
            await services.dialog.showStandardDialog({
                closeButtonBehavior: DialogCloseButtonBehavior.Accept,
                render: (modalHandler) => props.renderDialog!(modalHandler)
            });

        }
    }

    const renderAddButton = () => {
        if(!props.showButton){
            return (
                <SsrModificationBlockingReasonComponent reason={props.modificationBlockingReason} />
            );
        }

        return (
            <RowBox>
                <ChangeButton fullWidth={false} onClick={onRenderModal}>
                    {
                        props.hasPurchases
                            ? services.language.translate('Modify')
                            : services.language.translate('Add')
                    }
                </ChangeButton>
            </RowBox>
        );
    }

    const renderDetails = () => {
        if(props.sectionDetails) {
            return (
                <DetailsBox>
                    {props.sectionDetails}
                </DetailsBox>
            )
        }

        return null;
    }

    return (
        <ExtrasSectionSmallScreenBox>
            {props.topBanner}
            <TopSectionBox>
                <IconBox>
                    {props.renderIcon && props.renderIcon()}
                </IconBox>
                <RowBox>
                    <TitleBox>
                        {props.sectionTitle}
                    </TitleBox>
                    {renderDetails()}
                </RowBox>
            </TopSectionBox>
            <MiddleSectionBox>
                {props.renderPurchaseSummary && props.renderPurchaseSummary()}
            </MiddleSectionBox>
            <BottomSectionBox>
                {props.minDiscountedPrice && props.minStandardPrice && <ExtrasMinPriceSmallScreenComponent minDiscountedPrice={props.minDiscountedPrice} minStandardPrice={props.minStandardPrice}/>}
                {renderAddButton()}
            </BottomSectionBox>
        </ExtrasSectionSmallScreenBox>
    )
}
