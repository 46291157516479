import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {ScreenSizeBasedComponent} from "../../../basic-components/layout/screen-size-based.component";
import {ExtrasSelectionPageSmallScreenContent} from "./extras-selection-page-content.small-screen.component";
import {ExtrasSelectionPageLargeScreenContent} from "./extras-selection-page-content.large-screen.component";
import {ExtrasSectionsRenderersFactory} from "./base-renderers/extras-sections-renderers-factory";
import {useServices} from "../../../hooks/use-services.hook";

export const ExtrasSelectionPageContentComponent: React.FC = observer(() => {
    const services = useServices();
    const [sectionsFactory] = useState(new ExtrasSectionsRenderersFactory(services.booking.current));
    return (
        <ScreenSizeBasedComponent renderForSmallScreen={() => <ExtrasSelectionPageSmallScreenContent sectionsFactory={sectionsFactory}/>}
                                  renderForLargeScreen={() => <ExtrasSelectionPageLargeScreenContent sectionsFactory={sectionsFactory}/>}/>
    )
})
