export enum CheckInRestrictionsGraphQLEnum {
    Closed = "Closed",
    NotOpened = "NotOpened",
    PnrLocked = "PnrLocked",
    MarketingCodeShareNotAllowed = "MarketingCodeShareNotAllowed",
    NotPaidInFull = "NotPaidInFull",
    PaymentsPending = "PaymentsPending",
    PaymentsPendingCustomerAction = "PaymentsPendingCustomerAction",
    RestrictedOnAnotherSystem = "RestrictedOnAnotherSystem",
    NotAllowed = "NotAllowed",
    ApisNotAllowed = "ApisNotAllowed",
    AppsNotAllowed = "AppsNotAllowed",
    SegmentNotFound = "SegmentNotFound",
    InvalidPrice = "InvalidPrice",
    MissingEMDCoupon = "MissingEMDCoupon"
}

export enum CheckInRestrictionsEnumApi {
    Closed = 0,
    NotOpened = 1,
    PnrLocked = 2,
    MarketingCodeShareNotAllowed = 3,
    NotPaidInFull = 4,
    PaymentsPending = 5,
    PaymentsPendingCustomerAction = 6,
    RestrictedOnAnotherSystem = 7,
    NotAllowed = 8,
    ApisNotAllowed = 9,
    AppsNotAllowed = 10,
    SegmentNotFound = 11,
    InvalidPrice = 12,
    MissingEMDCoupon = 13
}

