import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PassengerCabinBagSelectionComponent} from "./cabin-bag-selection.component";
import {PassengerCheckInBagsSelectionComponent} from "./check-in-bag-selection.component";
import {IPassengerAllBagsViewModel} from "../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";


const PassengerBagsContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
    gap: ${props => props.theme.spacing.large};
`

interface PassengerBagsSelectionComponentProps {
    passengerBags: IPassengerAllBagsViewModel;
}


export const PassengerBagsSelectionComponent: React.FC<PassengerBagsSelectionComponentProps> = observer((props) => {

    return (
        <PassengerBagsContentBox>

            <PassengerCabinBagSelectionComponent passengerBags={props.passengerBags}/>


            <PassengerCheckInBagsSelectionComponent passengerBags={props.passengerBags}/>

        </PassengerBagsContentBox>
    );
});

