import {IExternalLinksConfiguration} from "./external-links-configuration.interface";
import {IServiceFactory} from "../service-factory.interface";
import {makeObservable, observable, reaction, runInAction} from "mobx";
import enGbLinks from './links/links.en-gb';

export class ExternalLinksConfiguration implements IExternalLinksConfiguration {
    constructor(private readonly services: IServiceFactory) {
        makeObservable(this, {
            _currentLinksConfigurations: observable.ref
        });

        reaction(() => this.services.language.currentLanguage,
            async () => {
                await this._loadLinksConfigurations();
            }, {
                fireImmediately: true
            });
    }

    _currentLinksConfigurations: IExternalLinksConfiguration = enGbLinks;
    private _setCurrentLinksConfigurations(config: IExternalLinksConfiguration) {
        runInAction(() => {
            this._currentLinksConfigurations = config;
        });
    }

    private async _loadLinksConfigurations(): Promise<void> {
        const language = this.services.language.currentLanguage;
        try {
            const module = await import(`./links/links.${language}.ts`);
            this._setCurrentLinksConfigurations(module.default);
        } catch (err) {
            this.services.logger.error(`Failed to read external links configurations for language ${language}`);
            this._setCurrentLinksConfigurations(enGbLinks);
        }
    }

    get travelConditions(): string {
        return this._currentLinksConfigurations.travelConditions || enGbLinks.travelConditions;
    }
    get dangerousGoods(): string {
        return this._currentLinksConfigurations.dangerousGoods || enGbLinks.dangerousGoods;
    }

    get groupBooking(): string {
        return this._currentLinksConfigurations.groupBooking || enGbLinks.groupBooking;
    }
    get  contactUs(): string {
        return this._currentLinksConfigurations.contactUs || enGbLinks.contactUs;
    }

    get contactForm(): string {
        return this._currentLinksConfigurations.contactForm || enGbLinks.contactForm;
    }

    get cookiesPolicy(): string {
        return this._currentLinksConfigurations.cookiesPolicy || enGbLinks.cookiesPolicy;
    }
    get mobileAppPolicy(): string {
        return this._currentLinksConfigurations.mobileAppPolicy || enGbLinks.mobileAppPolicy;
    }
    get privacyPolicy(): string {
        return this._currentLinksConfigurations.privacyPolicy || enGbLinks.privacyPolicy;
    }

    get flightCanceled(): string {
        return this._currentLinksConfigurations.flightCanceled || enGbLinks.flightCanceled;
    }

    get notAllowFlightChangeForCheckedInPassengers(): string {
        return this._currentLinksConfigurations.notAllowFlightChangeForCheckedInPassengers || enGbLinks.notAllowFlightChangeForCheckedInPassengers;
    }

    get accountDeletion(): string {
        return this._currentLinksConfigurations.accountDeletion || enGbLinks.flightCanceled;
    }

}
