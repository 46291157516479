import React from 'react';
import {observer} from "mobx-react-lite";
import {IAeroitaliaInvoiceViewModel} from "../../../../services/booking/models/invoice/aeroitalia/aeroitalia.invoice-view-model.interface";
import {CountryPickerComponent} from "../../../../components/country-picker/country-picker.component";
import {ItalyCustomerTypeEnum} from "../../../../services/booking/models/invoice/aeroitalia/italy-customer-type.enum";
import {useServices} from "../../../../hooks/use-services.hook";

interface AeroitaliaInvoiceCountryPickerComponentProps {
    invoice: IAeroitaliaInvoiceViewModel;
}
export const AeroitaliaInvoiceCountryPickerComponent: React.FC<AeroitaliaInvoiceCountryPickerComponentProps> = observer((props) => {
    const services = useServices();
    const fields = props.invoice.fields;

    const excludedCountries = [];

    if(fields.customerType.value === ItalyCustomerTypeEnum.NonItalianCustomer) {
        excludedCountries.push(services.configuration.defaultCountryCode);
    }

    return (
        <CountryPickerComponent key={fields.countryCode.fieldName} field={fields.countryCode} excludeCountries={excludedCountries}/>
    )
});
