import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const ArrowDownIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} sizeIsHeight viewBox="0 0 17.602 10.834" ratio={17.602/10.834}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(-0.538 -0.508)"><path d="M10.263,10.262c.684-.684.936-1.55.561-1.925L3.169.682C2.794.307,1.928.56,1.244,1.244S.373,2.729.683,3.168l7.655,7.655C8.719,11.193,9.584,10.941,10.263,10.262Z" /><path d="M17.418,1.243c-.684-.684-1.562-.924-1.95-.536L7.5,8.678c-.39.39-.008.7.457,1.722a1.422,1.422,0,0,0,2.029.764l7.971-7.971C18.344,2.8,18.1,1.927,17.418,1.243Z" /></g>
        </SvgIcon>
    )
}
