import styled from "styled-components";

export const MaintenanceMessageBox = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.secondary};
    padding: 10px;
    background-color: ${props => props.theme.colors.warning}30;
    margin: 0 5px 8px;
    border-radius: 8px;
    line-height: 1;
    svg {
        flex-shrink: 0;
        margin-right: 8px;

    }
`