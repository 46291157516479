export enum PaymentStatusQueryParamEnum {
    Status = "status",
}

export enum PaymentStatusQueryParamValuesEnum {
    Success = "success",
    Failed = "failed",
    Canceled = "canceled"
}

export function buildPaymentSuccessUrl(path: string): string {
    return `${path}?${PaymentStatusQueryParamEnum.Status}=${PaymentStatusQueryParamValuesEnum.Success}`;
}

export function buildPaymentCanceledUrl(path: string): string {
    return `${path}?${PaymentStatusQueryParamEnum.Status}=${PaymentStatusQueryParamValuesEnum.Canceled}`;
}

export function buildPaymentFailedUrl(path: string): string {
    return `${path}?${PaymentStatusQueryParamEnum.Status}=${PaymentStatusQueryParamValuesEnum.Failed}`;
}

