import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {StandardPageFooterComponent} from "../../../basic-components/page/standard-page-footer/standard-page-footer.component";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";

interface StandardDialogFooterComponentProps extends PropsWithChildren {
    className?: string;
    dialogHandler: IDialogConfirmationHandler;
    horizontallyCentered?: boolean;
}


export const StandardDialogFooterComponent: React.FC<StandardDialogFooterComponentProps> = observer((props) => {

    return (
        <StandardPageFooterComponent className={props.className}
                                     horizontallyCentered={props.horizontallyCentered}
                                     shouldPreserveBottomSafeArea={props.dialogHandler.shouldPreserveBottomSafeArea}>
            {props.children}
        </StandardPageFooterComponent>
    );


});
