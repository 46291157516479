import {FieldEditorContainerComponentProps} from "./field-editor-container.component";

export interface FieldEditorCommonProps extends Pick<FieldEditorContainerComponentProps, 'customLabel' | 'hideLabel' | 'showBorders' | 'onClick' | 'className' | 'hideErrorText'> {
}

export function extractFieldEditorCommonProps(props: FieldEditorCommonProps): FieldEditorCommonProps {
    return {
        customLabel: props.customLabel,
        hideLabel: props.hideLabel,
        onClick: props.onClick,
        showBorders: props.showBorders,
        className: props.className,
        hideErrorText: props.hideErrorText
    };
}
