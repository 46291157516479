import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {FieldLabelBox} from "./field-label.box";
import {NullableString} from "../../types/nullable-types";


const InputContainerBox = styled.div<{showBorders?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    ${
          props => props.showBorders
                  ? css`
                  border: 1px solid ${props => props.theme.colors.primary};
                  border-radius: ${props => props.theme.border.defaultRadius};
                  padding: 4px 12px 4px 4px;
                  `
                  : ``
    }
`

const ContentSectionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`


const InputErrorBox =  styled.div`
    width: 100%;
    color: ${props => props.theme.colors.danger};
    line-height: 1;
    font-size: ${props => props.theme.fontSize.small};
    margin-top: 2px;
`

const IconBox = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 ${props => props.theme.spacing.medium} 0 0;
    color: ${props => props.theme.colors.primary};
`

export interface InputContainerComponentProps {
    renderInput: (hasError: boolean) => React.ReactElement;
    label?: string | React.ReactElement | null;
    errorMessage: NullableString;
    renderIcon?: () => (React.ReactElement | null);
    captureElementRef?: (element: HTMLDivElement) => void;
    onClick?: () => void;
    showBorders?: boolean;
    className?: string;
    hideErrorText?: boolean;
}

export const InputContainerComponent: React.FC<InputContainerComponentProps> = observer((props) => {
    const hasError = Boolean(props.errorMessage);
    const renderError = () => {
        if(!hasError || props.hideErrorText) {
           return null;
        }
        return (
            <InputErrorBox>
                {props.errorMessage}
            </InputErrorBox>
        );
    }

    const renderLabel = () => {
        if(!props.label) {
            return null;
        }

        return (
            <FieldLabelBox hasError={Boolean(props.errorMessage)}>
                {props.label}
            </FieldLabelBox>
        );
    }

    const renderIcon = () => {
        const icon = props.renderIcon && props.renderIcon();
        if(icon) {
            return (
                <IconBox>
                    {icon}
                </IconBox>
            );
        }
        return null;
    }

    return (
        <InputContainerBox ref={props.captureElementRef}
                           onClick={props.onClick}
                           showBorders={props.showBorders}
                           className={props.className}>
            {renderIcon()}
            <ContentSectionBox>
                {renderLabel()}
                {props.renderInput(hasError)}
                {renderError()}
            </ContentSectionBox>

        </InputContainerBox>
    );
})
