import React from 'react';
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {ContinueButtonComponent} from "../../../../components/continue-button/continue-button.component";
import styled from "styled-components";
import {VerticalSpacer} from "../../../../basic-components/spacing/vertical-spacer";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {SpecialPriceMarketUserOptionComponent} from "./special-price-market-user-option.component";
import {
    IFlightSearchControllerViewModel,
    SpecialPriceMarketUserOptions
} from "../../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";

const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large};
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
    height: 100%;
    width: 100%;
    padding-top: ${props => props.theme.spacing.large};
`

const SubtitleBox = styled.div`
    width: 100%;
    text-align: center;
`

interface SpecialPriceMarketUserOptionWithSeasonDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    searchController: IFlightSearchControllerViewModel;
}

export const SpecialPriceMarketUserOptionWithSeasonDialogComponent: React.FC<SpecialPriceMarketUserOptionWithSeasonDialogComponentProps> = observer((props) => {
    const services = useServices();
    const searchController = props.searchController;
    const specialPriceMarket = searchController.getSpecialPriceMarket();

    if(!specialPriceMarket) {
        return null;
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}
                                           title={services.language.translate('Resident and equivalent')}
                                           hideCloseButton={true}>

            </StandardDialogHeaderComponent>
            <StandardDialogContentComponent>

                <DialogContentBox>
                    <SubtitleBox>
                        {services.language.translate('Check if any of the below apply to you.')}
                    </SubtitleBox>
                    <VerticalSpacer />
                    <SpecialPriceMarketUserOptionComponent optionValue={SpecialPriceMarketUserOptions.Regular} searchController={searchController}/>
                    <SpecialPriceMarketUserOptionComponent optionValue={SpecialPriceMarketUserOptions.Season} searchController={searchController}/>

                </DialogContentBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ContinueButtonComponent fullWidth={true}
                                         onClick={() => props.dialogHandler.accept(searchController.specialPriceMarketUserOption)}/>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
});
