import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import {IonIcon} from "@ionic/react";
import {search, searchOutline} from "ionicons/icons";
import {useServices} from "../../../hooks/use-services.hook";
import {searchMaskIconSpacing} from "../../../pages/home/components/search-mask/hybrid/search-mask-hybrid-css-constants";

const BookNowButtonBox = styled(PrimaryButton) `
  min-height: 50px;
  font-size: ${props => props.theme.fontSize.xxLarge};  
`

const SearchIconBox = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: ${props => searchMaskIconSpacing(props.theme)};
`

interface SearchMaskBookNowButtonComponentProps {
    onClick: () => void;
}

export const SearchMaskBookNowButtonComponent: React.FC<SearchMaskBookNowButtonComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <BookNowButtonBox className={'tst-book-now'} onClick={props.onClick} >
            <SearchIconBox>
                <IonIcon ios={searchOutline} md={search}/>
            </SearchIconBox>

            {services.language.translate('Book now')}
        </BookNowButtonBox>
    )
});
