import {IServiceFactory} from "../../../service-factory.interface";
import {CknSsrBase} from "./ckn-ssr-base";

export default class AcknSsrType extends CknSsrBase {
    constructor(services: IServiceFactory) {
        super('ACKN', services);
    }



}
