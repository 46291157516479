import {IConfigurationObject} from "../../configuration/configuration-object.interface";

export enum AppVersionUpgradeModeEnum
{
    None = 0,
    Mandatory = 1,
    Optional = 2
}

export interface IGetConfigurationsResponse {
    appVersionUpgradeMode: AppVersionUpgradeModeEnum,
    configurations: IConfigurationObject;
}
