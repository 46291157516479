import React from 'react';
import {observer} from "mobx-react-lite";
import {IFlightDesignatorViewModel} from "../../../services/booking/models/designator/flight-designator-view-model.interface";
import {DesignatorStationCodeComponent} from "../common/components/designator-station-code.component";
import {DesignatorPlaneIconComponent} from "../common/components/designator-plane-icon.component";
import styled from "styled-components";
import {DesignatorBox} from "../common/boxes/designator.box";

const DesignatorStationsCodesBox = styled(DesignatorBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

interface DesignatorStationsCodesOnlyComponentProps {
    designator: IFlightDesignatorViewModel;
    className?: string;
    useMac?: boolean;
}

export const DesignatorStationsCodesComponent: React.FC<DesignatorStationsCodesOnlyComponentProps> = observer((props) => {
    const designator = props.designator;
    return (
        <DesignatorStationsCodesBox className={props.className}>
            <DesignatorStationCodeComponent station={designator.origin} useMac={props.useMac}/>
            <DesignatorPlaneIconComponent />
            <DesignatorStationCodeComponent station={designator.destination} useMac={props.useMac}/>
        </DesignatorStationsCodesBox>
    );
});
