import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {BlueBenefitsPackagePresentationComponent} from "./blue-benefits-package-presentation.component";

const PackagesPresentationBox = styled.div`
    display: flex;
    flex-direction: column;
`

//background-color: ${props => props.theme.colors.light};
const GeneralDescriptionBox = styled.div`
  font-size: ${props => props.theme.fontSize.large};
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 15px 15px 10px;
  line-height: 1.1;
  color: ${props => props.theme.colors.medium};
`

const VerticalSpacerBox = styled.div`
    line-height: 0;
    height: 15px;
`;

//background-color: ${props => props.theme.colors.light};
const BlueBenefitsPackagePresentationBox = styled(BlueBenefitsPackagePresentationComponent)`
    margin-bottom: 15px;
    border: solid 1px rgba(${props => props.theme.colors.mediumRgb}, 0.75);
    
`


export const BlueBenefitsPackagesPresentationComponent: React.FC = observer(() => {
    const services = useServices();
    const packages = services.blueBenefits.availableBlueBenefitsPackages;
    return (
        <PackagesPresentationBox>
            <GeneralDescriptionBox>
                {services.language.translationFor('{blueBenefits}, the best friend of Aeroitalia travelers, is an online subscription that offers discounts for airline tickets and luggage for a year, for you and your family and friends.').withParams({blueBenefits: 'Blue Benefits'})}
                <VerticalSpacerBox/>
                {services.language.translate('This flight pass can be purchased only by buying a plane ticket and ensures instant access to activated discounts.')}
            </GeneralDescriptionBox>
            {packages.map(p => <BlueBenefitsPackagePresentationBox key={p.packageCode} bbPackage={p} displayPackagePrice={true}/>)}
        </PackagesPresentationBox>
    )
});
