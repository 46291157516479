import React from "react";
import {observer} from "mobx-react";
import {FlightsDatesSelector} from "../../../../../../../services/flight-search/dates-selection/flights-dates-selector";
import styled from "styled-components";
import {IDropDownController} from "../../../../../../../components/forms/drop-down/models/drop-down-controller.interface";
import {FlightDatesSelectionPageContent} from "../../../../../../flight-search/flight-dates-selection/components/flight-dates-selection-page-content.component";

const ListViewBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`


interface FlightDatesDropDownListViewComponentProps {
    flightsDatesSelector: FlightsDatesSelector;
    dropDownController: IDropDownController;
    onDateSelected: (date: Date) => void;
    captureSelectedDateElementRef: (date: Date, element: HTMLElement | null) => void;
}
export const FlightDatesDropDownListViewComponent: React.FC<FlightDatesDropDownListViewComponentProps> = observer((props) => {
    return (
        <ListViewBox>
            <FlightDatesSelectionPageContent flightsDatesSelector={props.flightsDatesSelector}
                                             onDateSelected={props.onDateSelected}
                                             captureSelectedDateElementRef={props.captureSelectedDateElementRef}
                                             useSwiper={false}/>
        </ListViewBox>
    );
});
