import {IServiceFactory} from "../../../service-factory.interface";
import {JourneyModel} from "../../../booking/models/journey/journey.model";
import {BundleModel} from "../../../booking/models/bundle/bundle.model";
import {BookingModel} from "../../../booking/models/booking.model";
import {
    AnalyticsConstants, IAnalyticsItem, IAnalyticsViewItemListEcommerce,
    ISeatFeeAnalyticsData
} from "../../../booking/models/analytics/google-analytics.intefaces";
import {SsrCategoryEnum} from "../../../ssr-types/ssr-category.enum";
import {IAnalyticsActionHandler} from "../../analytics.service.interface";
import {JourneyShoppingCartModel} from "../../../booking/models/shopping-cart/journey/journey-shopping-cart.model";
import {NullableString} from "../../../../types/nullable-types";
import {IBookingAnalyticsHandlerViewModel} from "../../../booking/models/analytics/booking-analytics-handler.model";
import {IFeeModel} from "../../../booking/models/base-models/fees/fee-model.interface";
import {IJourneyViewModel} from "../../../booking/models/journey/journey-view-model.interface";
import {IJourneySsrsBucketViewModel} from "../../../booking/models/ssrs/journey-ssrs-bucket-view-model.interface";
import {Check} from "../../../../types/type-checking";
import {IBookingViewModel} from "../../../booking/models/booking-view-model.interface";


export interface IExtractedJourneyDataForAnalyticsItem {
    index: number,
    selectedBundle: BundleModel|null,
    journey: JourneyModel,
    formattedDepartureArrivalStationCodes: string,
    formattedDate: string,
    item_list_id: string,
    item_list_name: string,
    fareClassesOfService: string,
    totalPrice: number,
    discountAmount: number

}

export class AnalyticsBaseEvents {

    protected readonly BrandName: string = 'AeroItalia';
    constructor(protected readonly services: IServiceFactory,
                protected readonly analyticsActionHandler: IAnalyticsActionHandler) {
    }

    protected _extractAnalyticsDataFromJourney(journey: JourneyModel | null): IExtractedJourneyDataForAnalyticsItem | null{

        if(!journey) return null;
        const {index, selectedBundle} = this._extractSelectedBundleAndIndexFromJourneyModel(journey);

        const formattedDate = journey.analyticsFormattedDepartureDate;
        const formattedDepartureArrivalStationCodes =  journey.analyticsFormattedStationCodes;
        const item_list_id = `Flight|${formattedDepartureArrivalStationCodes}|${formattedDate}`;
        const item_list_name = `Flight_List ${formattedDepartureArrivalStationCodes}`;
        const fareClassesOfService = journey.booking.flightSearchController.departureClassOfServices.join('|');
        const totalPrice = journey.segments.sum(segment => segment.fareAmount);
        const discountAmount = journey.segments.sum(segment => segment.discountAmount);
        return {
            index,
            journey,
            selectedBundle,
            formattedDepartureArrivalStationCodes,
            formattedDate,
            item_list_id,
            item_list_name,
            fareClassesOfService,
            totalPrice,
            discountAmount
        };
    }

    protected _extractSelectedBundleAndIndexFromJourneyModel(journey: JourneyModel) {
        const index = journey.bundlesAvailability.findIndex(f=>f.bundleCode ===  journey.selectedBundle?.bundleCode);
        return {
            index,
            selectedBundle: index !== -1 ? journey.bundlesAvailability[index]: journey.bundlesAvailability[0]//journey.selectedBundle
        }
    }

    protected _getFormatStationCodesFromJourneyForAnalyticsEvent(journey: JourneyModel) {
       return journey.analyticsFormattedStationCodes;
    }

    protected _formatDepartureArrivalStationCodesForAnalyticsEvent(
        departureStation: string,
        destinationStation: string) {
        return  `${departureStation}:${destinationStation}`
    }

    protected   _formatGoogleAnalyticsDates(date: Date): string {
        return this.services.time.formatYYY_MM_DD(date);
    }

    protected  _extractAnalyticsJourneysDataFromBooking(booking: BookingModel) :IExtractedJourneyDataForAnalyticsItem[]  {
        return  [
            this._extractAnalyticsDataFromJourney(booking.departureJourney),
            this._extractAnalyticsDataFromJourney(booking.returnJourney)
        ].filter( value => value !== null) as IExtractedJourneyDataForAnalyticsItem[];
    }

    protected _getCustomerTypeFromBooking(booking: IBookingViewModel) {
        return booking.flightSearchController.agent ?? AnalyticsConstants.defaultAgentName; //default customer type is public
    }

    protected _getAnalyticsSeatFeeItems(booking: BookingModel, filterBySeatKey: NullableString = null): IAnalyticsItem[] {
        return booking.shoppingCart.journeysNewPurchases
            .flatMap(jsc =>
                jsc.passengerJourneysShoppingCarts
                    .flatMap( pjsc =>
                        pjsc.getSeatFeeAnalyticsData()
                            .filter( seat => filterBySeatKey == null || seat.id === filterBySeatKey  )
                            .flatMap( seatFee => this._mapSeatItemAnalyticsData(seatFee, jsc.journey))))
    }

    protected _mapSeatItemAnalyticsData(seatData: ISeatFeeAnalyticsData, journey: JourneyModel) {
        return {
            item_name: `${AnalyticsConstants.seatCategory}|${journey.analyticsFormattedStationCodes}`,
            item_id: `${AnalyticsConstants.seatCategory}|${journey.analyticsFlightId}|${seatData.seatNumber}`,
            price: seatData.price,
            item_brand: journey.booking.analyticsConfiguration.itemBrand,
            item_category: `${AnalyticsConstants.seatCategory}`,
            item_category2: `${AnalyticsConstants.seatCategory}|Row|${seatData.rowNumber}`,
            item_category3: `${AnalyticsConstants.seatCategory}|Seat|${seatData.seatLetter}`,
            // item_category4: `${this._getAnalyticsNameForKeyId(seatData.id)}`,
            item_variant: `${AnalyticsConstants.seatCategory}|${seatData.hasExtraLegroom ? 'EXTRA-LEGROOM':'LEGROOM'}`,
            is_direct: journey.designator.isDirectFlight,
            discount: 0,
            quantity: 1
        }
    }

    protected _getAnalyticsSsrFeeItems(booking: BookingModel): IAnalyticsItem[] {
        return booking.shoppingCart.journeysNewPurchases
            .flatMap(jsc => {

                let result: IAnalyticsItem[] = [];
                const groupedAnalytics = jsc.analyticsSsrFeesGroupedByAnalyticsName;
                const journey = jsc.journey;
                for (const analyticsName in groupedAnalytics) {
                    const ssrsFees = groupedAnalytics[analyticsName];

                    result = [...result, ...this._mapFeeModelToAnalyticsData(journey, ssrsFees, analyticsName)];
                }
                return result;
            });
    }

    protected _getAnalyticsSsrFeesItemsFiltered(booking: BookingModel, ssrKeys: string[]): IAnalyticsItem[] {
        return booking.shoppingCart.journeysNewPurchases
            .flatMap(jsc =>
                this._mapFeeModelToAnalyticsData(jsc.journey, jsc.getSsrsFeesForId(ssrKeys), booking.analyticsHandler.currentAnalyticsName));
    }

    private _mapFeeModelToAnalyticsData(journey: IJourneyViewModel, ssrsFees: IFeeModel[], analyticsName: string): IAnalyticsItem[] {
        const result: IAnalyticsItem[] = [];

        ssrsFees.forEach(ssrFee => {
            if (!ssrFee) return;
            // if(ssrFee.priceToDisplay.amount === 0) return;

            result.push({
                item_name: `${AnalyticsConstants.ssrCategory}|${ssrFee.ssrType?.ssrCode}`,
                item_id: `${AnalyticsConstants.ssrCategory}|${journey.analyticsFlightId}|${ssrFee.ssrType?.ssrCode}`,
                item_brand: journey.booking.analyticsConfiguration.itemBrand,
                item_category: `${AnalyticsConstants.extraCategory}`,
                item_category2: `${AnalyticsConstants.extraCategory}|${ssrFee.ssrType?.category}`,
                is_direct: journey.designator.isDirectFlight, //boolean true or false. If the journey only has 1 segment is_direct = true
                item_list_name: `${AnalyticsConstants.ssrCategory} ${journey.analyticsFormattedStationCodes}`,
                item_list_id: `${AnalyticsConstants.ssrCategory}|${journey.analyticsFormattedStationCodes}|${journey.analyticsFlightId}`,
                price: ssrFee.priceToDisplay.amount,
                discount: ssrFee.initialPrice ? ssrFee.initialPrice?.amount - ssrFee.priceToDisplay.amount : 0,
                index: ssrFee.ssrNumber,//get the index here
                quantity: ssrFee.quantity
            })

        });
        return result;
    }

    protected _mapSsrViewModelToEcommerceData(
        booking: IBookingViewModel,
        journeySsrBucket: IJourneySsrsBucketViewModel,
        filterBySsrTypes?: string[] ): IAnalyticsViewItemListEcommerce {

        const journey = journeySsrBucket.parentJourney;
        const item_list_name = `${AnalyticsConstants.ssrCategory} ${journey.analyticsFormattedStationCodes}`;
        const item_list_id =  `${AnalyticsConstants.ssrCategory}|${journey.analyticsFormattedStationCodes}|${journey.analyticsFlightId}`;
        return  {
            customer_type: this._getCustomerTypeFromBooking(booking),
            item_list_name,
            item_list_id,
            items: journeySsrBucket.getAllSsrsInTheBucket()
                .filter( ssr => Check.isUndefined(filterBySsrTypes) || filterBySsrTypes.indexOf(ssr.ssrType?.ssrCode) !== -1 )
                .map(ssr =>  {
                    return {
                        item_name: `${AnalyticsConstants.ssrCategory}|${ssr.ssrType?.ssrCode}`,
                        item_id: `${AnalyticsConstants.ssrCategory}|${journey.analyticsFlightId}|${ssr.ssrType?.ssrCode}`,
                        item_brand: journey.booking.analyticsConfiguration.itemBrand,
                        item_category: `${AnalyticsConstants.extraCategory}`,
                        item_category2: `${AnalyticsConstants.extraCategory}|${ssr.ssrType?.category}`,
                        is_direct: journey.designator.isDirectFlight,
                        item_list_name,
                        item_list_id,
                        price: ssr.standardMinPrice?.amount ?? 0,
                        quantity: ssr.totalQuantity ?? 1
                    }
                })
        }
    }

    protected _getAnalyticsOtherFeeItems(booking: BookingModel): IAnalyticsItem[] {
        return booking.shoppingCart.journeysNewPurchases
            .flatMap(jsc =>
                jsc.otherFees.filter(ssrFee => ssrFee.priceToDisplay.amount > 0)
                    .flatMap(otherFee => {
                        const journey = jsc.journey;
                        return {
                            item_name: `${AnalyticsConstants.ssrCategory}|${otherFee.feeCode}`,
                            item_id: `${AnalyticsConstants.ssrCategory}|${journey.analyticsFlightId}|${otherFee.feeCode}`,
                            item_brand: booking.analyticsConfiguration.itemBrand,
                            item_category: `${otherFee.feeType}`,
                            is_direct: journey.designator.isDirectFlight, //boolean true or false. If the journey only has 1 segment is_direct = true
                            item_list_name: `${AnalyticsConstants.ssrCategory} ${journey.analyticsFormattedStationCodes}`,
                            item_list_id: `${AnalyticsConstants.ssrCategory}|${journey.analyticsFormattedStationCodes}|${journey.analyticsFlightId}`,
                            price: otherFee.priceToDisplay.amount,
                            discount: otherFee.initialPrice?  otherFee.initialPrice?.amount - otherFee.priceToDisplay.amount: 0,
                            index: otherFee.ssrNumber,//get the index here
                            quantity: otherFee.quantity
                        }
                    }));
    }

    protected _getBookingLevelFeesAnalyticsPurchaseItems(booking: BookingModel): IAnalyticsItem[] {
        return booking.getBookingLevelFees()
            .filter(fee => fee.isPurchasedOnCurrentSession)
            .map(fee => {
                return {
                    item_id: fee.feeKey,
                    item_name: fee.feeCode,
                    affiliation: booking.analyticsConfiguration.affiliation,
                    currency: fee.currentPrice.currency,
                    item_brand: booking.analyticsConfiguration.itemBrand,
                    item_category: SsrCategoryEnum.Other,
                    item_category2: fee.passengerTypeCode,
                    item_variant: fee.feeCode,
                    price: fee.currentPrice.amount,
                    quantity: fee.quantity,
                }
            });
    }

    protected _getBookingFaresAnalyticsItems(booking: BookingModel): IAnalyticsItem[] {
        return booking.shoppingCart
            .journeysNewPurchases
            .flatMap( jnp =>
                           this._getFareAnalyticsData(jnp))
    }

    protected _getFareAnalyticsData(journeyShoppingCartModel: JourneyShoppingCartModel): IAnalyticsItem[] {
        return  journeyShoppingCartModel.getPassengersAnalyticsData().map(
            s => {
                const journey = journeyShoppingCartModel.journey;
                const {index, selectedBundle} = this._extractSelectedBundleAndIndexFromJourneyModel(journey);
                return {
                    item_name: `${AnalyticsConstants.flightCategory}|${journey.analyticsFormattedStationCodes}|${journey.selectedBundle?.bundleCode}`,
                    item_id: `${AnalyticsConstants.flightCategory}|${journey.analyticsFlightId}|${s.passengerTypeCode}`,
                    item_brand: journey.booking.analyticsConfiguration.itemBrand,
                    item_category: `${AnalyticsConstants.flightCategory}`,
                    item_category2: `${AnalyticsConstants.flightCategory}|PTC|${s.passengerTypeCode}`,
                    item_category3: `${AnalyticsConstants.flightCategory}|${journey.analyticsFormattedDepartureDate}`,
                    item_variant: `${AnalyticsConstants.flightCategory}|${selectedBundle?.bundleCode??""}`,
                    is_direct: journey.designator.isDirectFlight, //boolean true or false. If the journey only has 1 segment is_direct = true
                    item_list_name: `${AnalyticsConstants.flightListCategory} ${journey.analyticsFormattedStationCodes}`,
                    item_list_id: `${AnalyticsConstants.flightCategory}|${journey.analyticsFormattedStationCodes}|${journey.analyticsFormattedDepartureDate}`,
                    price: s.totalAmount, //s.totalAmount,
                    discount: journey.totalDiscount, //s.initialFare ?  s.initialFare.amount - s.fareToDisplay.amount : 0,
                    index,//get the index here
                    item_coupon: s.discountCode ?? "",
                    quantity: s.quantity
                }
            }
        )
    }

    private _getAnalyticsNameForKeyId(key: string ): string {
        return this.analyticsHandler.getAnalyticsNameByKey(key);
    }

    private get analyticsHandler(): IBookingAnalyticsHandlerViewModel {
        return this.services.booking.current.analyticsHandler;
    }

    protected _saveAddToCartItemsForKey(key: string, items: IAnalyticsItem[]){
        this.analyticsHandler.saveDataToSession(key, items);
    }
    protected _removeAddToCartItemsForKey(key: string){
        this.analyticsHandler.removeItemsFromCart(key);
    }
    protected _getAddToCartItemsForKey(key: string): IAnalyticsItem[]{
        return this.analyticsHandler.getDataForKey(key) ?? [];
    }


}
