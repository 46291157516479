import {
    IDotRezPassengerSegment,
    IDotRezPassengerSegmentSsr,
    IDotRezSegmentFare
} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {SegmentSnapshotModel} from "../segment/segment-snapshot.model";
import {IPassengerSegmentShoppingCartAdapter} from "../../base-models/shopping-cart/passenger-segment-shopping-cart-adapter.interface";
import {IFeeModel} from "../../base-models/fees/fee-model.interface";
import {PassengerSnapshotModel} from "../passenger/passenger-snapshot.model";
import {NullableString} from "../../../../../types/nullable-types";
import {computed, makeObservable} from "mobx";
import {IPassengerType} from "../../../../passenger-types/passengers-types.service.interface";
import {IServiceFactory} from "../../../../service-factory.interface";
import {NullablePrice, Price} from "../../../../currency/price";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {PassengerSegmentShoppingCartSnapshotModel} from "../shopping-cart/passenger-segment-shopping-cart-snapshot.model";
import {InfantSegmentShoppingCartModel} from "../../base-models/shopping-cart/infant-segment-shopping-cart.model";
import {FeeTypeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {ISeatModel} from "../../seat-maps/seat.model.interface";

export class PassengerSegmentSnapshotModel implements IPassengerSegmentShoppingCartAdapter {
    constructor(readonly passengerKey: string,
                private readonly passengerSegmentDotRezData: IDotRezPassengerSegment,
                private readonly segment: SegmentSnapshotModel) {
        makeObservable(this, {
            passenger: computed,
            infantFees: computed,
            infantShoppingCart: computed
        });

        this.shoppingCart = new PassengerSegmentShoppingCartSnapshotModel(this);
    }

    readonly shoppingCart: PassengerSegmentShoppingCartSnapshotModel;

    get bookingCreateDate(): Date {
        return this.segment.journey.booking.bookingCreationDate;
    }

    get infantShoppingCart(): InfantSegmentShoppingCartModel | null {
        if(!this.passenger.infant) {
            return null;
        }

        return new InfantSegmentShoppingCartModel(this);
    }

    get services(): IServiceFactory {
        return this.segment.services;
    }
    get passenger(): PassengerSnapshotModel {
        return this.segment.journey.booking.getPassengerByKey(this.passengerKey);
    }

    get allServiceFees(): IFeeModel[] {
        return this.passenger.fees.filter(f => f.flightReference === this.segment.flightReference);
    }
    get flightReference(): string {
        return this.segment.flightReference;
    }

    get infantFees(): IFeeModel[] {
        if(!this.passenger.infant) {
            return [];
        }
        return this.passenger.infant.fees.filter(f => f.flightReference === this.flightReference);
    }
    get infantFullName(): NullableString {
        return this.passenger?.infant?.getFullName() || null;
    }
    get passengerDiscountCode(): string {
        return this.passenger.passengerDiscountCode;
    }
    get passengerFullName(): string {
        return this.passenger.getFullName();
    }
    get passengerType(): IPassengerType {
        return this.passenger.passengerType;
    }
    get seatNumber(): NullableString {
        const seats = this.passengerSegmentDotRezData.seats || [];
        if(seats.length === 0) {
            return null;
        }

        return seats[0].unitDesignator;
    }
    get segmentFares(): IDotRezSegmentFare[] {
        return this.segment.segmentDotRezData.fares;
    }

    get ssrs(): IDotRezPassengerSegmentSsr[] {
        return this.passengerSegmentDotRezData.ssrs || [];
    }

    createPrice(amount: number): Price {
        return this.segment.journey.booking.createPrice(amount);
    }

    getInitialFare(): NullablePrice {
        return null;
    }

    getInitialSeatPrice(): NullablePrice {
        return null;
    }

    getInitialSeatNumber(): NullableString {
        return null;
    }

    getInitialInBundleSsrPrice(ssrType: ISsrType, index: number): NullablePrice {
        return null;
    }

    getInitialPurchasedSsPrice(ssrType: ISsrType, index: number): NullablePrice {
        return null;
    }

    getInitialOtherFeePrice(feeType: FeeTypeEnum, index: number): NullablePrice {
        return null;
    }
    
    getInitialInfantPrice(): NullablePrice {
        return null;
    }

    get unrefundableSsrs(): ISsrType[] {
        const ssrs = this.passengerSegmentDotRezData.ssrs;
        if(!ssrs) {
            return [];
        }

        return ssrs.distinct(ssr => ssr.ssrCode, ssr => ssr.ssrCode)
                    .map(ssrCode => this.services.ssrTypes.getSsrType(ssrCode))
                    .filter(ssrType => !ssrType.canBeRefunded);
    }

    get unrefundableAmount(): Price {
        return Price.sumAll(this.unrefundableSsrs.map(ssrType => this.segment.getTotalSsrFees(this.passengerKey, ssrType)), this.createPrice(0));
    }

    getVirtualSsrsIncludedInBundle(): ISsrType[] {
        return [];
    }

    get assignedSeat(): ISeatModel | null {
        return null;
    }
}
