import React from "react";
import styled from "styled-components";
import {Price} from "../../../../../../services/currency/price";
import {observer} from "mobx-react";
import {StandardPriceLabelComponent} from "../../../../../../components/price/standard-price-label.component";
import {PriceWithDiscountComponent} from "../../../../../../components/price/price-with-discount.component";

export const ExtrasMinPriceBox = styled.div`
    display: flex;
    justify-content: center;
    font-weight: ${props => props.theme.fontWeight.bold};
`

interface ExtrasMinPriceSmallScreenComponentProps {
    minDiscountedPrice: Price;
    minStandardPrice: Price;
}

export const ExtrasMinPriceSmallScreenComponent: React.FC<ExtrasMinPriceSmallScreenComponentProps> = observer((props) => {
    return (
        <ExtrasMinPriceBox>
            <PriceWithDiscountComponent discountedPrice={props.minDiscountedPrice} standardPrice={props.minStandardPrice} label={<StandardPriceLabelComponent/>}/>
        </ExtrasMinPriceBox>
    );
});
