import {ISeatsRowsGroupViewModel} from "../../../../services/booking/models/seat-maps/seats-rows-group-view-model.interface";
import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {IonCol, IonRow} from "@ionic/react";
import {InformationCircleIcon} from "../../../../assets/svg/information-circle.icon";
import styled from "styled-components";
import {SeatsRowComponent} from "./seats-row.component";

const GroupTitleBox = styled.div`
    background-color: ${props => props.theme.colors.light};
    font-size: ${props => props.theme.fontSize.medium};
    text-transform: uppercase;
    color: ${props => props.theme.colors.secondary};
    line-height: 1;
    margin: 22px 5px 12px;
    display: flex;
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const GroupPriceBox = styled(GroupTitleBox)`
    text-transform: none;
    justify-content: flex-end;
    gap: 6px;
`
const GroupPriceTitleBox = styled.div`
    color: ${props => props.theme.colors.medium};
`
const GroupIconBox = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
`
const GroupPriceValueBox = styled.div`
    
`

const SeatMapGroupBox = styled(IonRow)`
    position: relative;
    
`


interface SeatMapGroupComponentProps {
    title: string;
    group: ISeatsRowsGroupViewModel;
}

export const SeatMapGroupComponent: React.FC<SeatMapGroupComponentProps> = observer((props) => {
    const services = useServices();

    const showSeatMapGroupDetails = async () => {
        await services.dialogFactory.showSeatDescriptionDialog({
            groupType: props.group.groupType
        });
    }
    props.group.rows.find(row => row.leftSeats)
    return (
        <React.Fragment>
            <SeatMapGroupBox>
                <IonCol size="5" className="ion-align-self-center">
                    <GroupTitleBox>{props.title} </GroupTitleBox>
                </IonCol>
                <IonCol size="2" className="ion-align-self-center">
                    <GroupIconBox onClick={showSeatMapGroupDetails}>
                        <InformationCircleIcon size={1.8} color={services.theme.currentTheme.colors.secondary} />
                    </GroupIconBox>
                </IonCol>
                <IonCol size="5" className="ion-align-self-center">
                    <GroupPriceBox>
                        <GroupPriceTitleBox>{services.language.translate('From')}</GroupPriceTitleBox>
                        <GroupPriceValueBox>{props.group.minPrice.toString()}</GroupPriceValueBox>
                    </GroupPriceBox>
                </IonCol>
            </SeatMapGroupBox>

            {props.group.rows.map(row => <SeatsRowComponent key={row.rowNumber} row={row} />)}
        </React.Fragment>

    )
});

