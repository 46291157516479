import React from "react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled from "styled-components";
import {NullableUndefinedNumber, NullableUndefinedString} from "../../../../../types/nullable-types";
import { PlaneFilledIcon } from "../../../../../assets/svg/plane-filled.icon";
import {PrimarySegmentComponent} from "../../../../../basic-components/segment/primary/primary-segment.component";
import {PrimarySegmentButtonBox} from "../../../../../basic-components/segment/primary/primary-segment-button.box";

const SearchMaskTripTypeSelectionBox = styled.div`
`

const TripTypeButtonBox = styled(PrimarySegmentButtonBox)`
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const TripTypeNameBox = styled.div`
    margin-left: ${props => props.theme.spacing.medium};
`

export const SearchMaskTripTypeHybridComponent: React.FC = observer(() => {
    const services = useServices();
    const onChange = (value:NullableUndefinedString | NullableUndefinedNumber) => {
        services.flightSearch.searchController.isOneWayDepartureTrip = (value === 'one-way')

    }
    const getCurrentValue = () => {
        if(services.flightSearch.searchController.isOneWayDepartureTrip) {
            return 'one-way';
        }
        else {
            return 'round-trip' ;
        }
    }
    return (
        <SearchMaskTripTypeSelectionBox>
            <PrimarySegmentComponent value={getCurrentValue()} onChange={(value) => onChange(value)}>
                <TripTypeButtonBox value="round-trip" layout="icon-start" className={'tst-round-trip'}>
                    <PlaneFilledIcon color={services.theme.currentTheme.colors.secondaryContrast}/>
                    <TripTypeNameBox>
                        {services.language.translate('Round Trip')}
                    </TripTypeNameBox>
                </TripTypeButtonBox>

                <TripTypeButtonBox value="one-way" layout="icon-start" className={'tst-one-way'}>
                    <PlaneFilledIcon color={services.theme.currentTheme.colors.secondaryContrast}/>
                    <TripTypeNameBox>
                        {services.language.translate('One Way')}
                    </TripTypeNameBox>
                </TripTypeButtonBox>

            </PrimarySegmentComponent>
        </SearchMaskTripTypeSelectionBox>
    );
});
