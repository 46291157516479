import {IDotRezBookingData} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {JourneySnapshotModel} from "../journey/journey-snapshot.model";
import {IServiceFactory} from "../../../../service-factory.interface";
import {IBookingSnapshotViewModel} from "./booking-snapshot-view-model.interface";
import {BookingBaseModel} from "../../base-models/booking/booking-base.model";
import {DisruptionType} from "../../base-models/disruption-type";
import {NullableString} from "../../../../../types/nullable-types";
import {IDotRezInitialBookingData} from "../../../booking-strategies/booking-initial-data.interface";
import {SystemCodes} from "../../../../configuration/configuration.service.interface";
import {computed, makeObservable} from "mobx";
import {TimeSpan} from "../../../../../types/time-span";
import {PassengerSnapshotModel} from "../passenger/passenger-snapshot.model";
import {Price} from "../../../../currency/price";
import {OtherFeeModel} from "../../base-models/fees/other/other-fee.model";
import {PassengerFeeSnapshotModel} from "../fees/passenger-fee-snapshot.model";


export class BookingSnapshotModel extends BookingBaseModel implements IBookingSnapshotViewModel {
    constructor(private readonly _initialBookingData: IDotRezInitialBookingData,
                public readonly services: IServiceFactory) {
        super();
        this.passengers = this.bookingData.passengers.map(p => new PassengerSnapshotModel(p.value, this));
        this.journeys = this.bookingData.journeys.map((j, index) => new JourneySnapshotModel(index, this));

        makeObservable(this, {
            flightsChangeBlockingReason: computed,
            bookingLevelFeesGroupedByFeeKey: computed,
            owningSystemCodes: computed
        });
    }

    readonly journeys: JourneySnapshotModel[];
    readonly passengers: PassengerSnapshotModel[];

    get bookingData(): IDotRezBookingData {
        return this._initialBookingData.bookingData;
    }


    get bookingCreationDate(): Date {
        if(this.bookingData.info.createdDate) {
            return this.services.time.parseIsoDate(this.bookingData.info.createdDate);
        }

        return this.services.time.parseIsoDate(this.bookingData.info.bookedDate);
    }

    get departureJourney(): JourneySnapshotModel | null {
        if(this.journeys.length > 0) {
            return this.journeys[0];
        }

        return null;
    }

    get returnJourney(): JourneySnapshotModel | null {
        if(this.journeys.length > 1) {
            return this.journeys[1];
        }

        return null;
    }

    getPassengerByKey(passengerKey: string): PassengerSnapshotModel {
        const passenger = this.passengers.find(p => p.passengerKey === passengerKey);
        if(!passenger) {
            throw new Error(`There is no passenger with ${passengerKey} in the booking snapshot`);
        }
        return passenger;
    }


    getSegmentDelay(journeyIndex: number, segmentIndex: number): TimeSpan {
        const delay = this._initialBookingData.segmentsDelays.find(segmentDelay => segmentDelay.journeyIndex === journeyIndex && segmentDelay.segmentIndex === segmentIndex);
        if(delay) {
            return TimeSpan.parse(delay.delayTime);
        }
        return TimeSpan.Zero;
    }

    /*
    var canceledJourneys = booking.Journeys.Where(j =>
        (
        // any journey with a canceled leg
        j.Segments.SelectMany(s => s.Legs).Any(l => l.LegInfo.Status == LegStatus.Canceled)
        // any journey without NoShows or Boarded
        && j.Segments.SelectMany(s => s.PaxSegments).All(ps => ps.LiftStatus != LiftStatus.Boarded && ps.LiftStatus != LiftStatus.NoShow)) ||
        // any journey with schedule change with reaccommodation
        j.Segments.Any(s => s.ChangeReasonCode == "SCHG" && Math.Abs((s.OriginalDepartureTime - s.STD).TotalHours) >= 3) ||
        // any journey with schedule time change
        j.Segments.Any(s => s.ChangeReasonCode != "SCHG" && Math.Abs((s.OriginalDepartureTime - s.STD).TotalHours) >= 3 && s.PaxSegments.Any(p => p.TimeChanged))
        ).ToList();
     */

    get canMoveDisruptedFlights(): boolean {
        return this.services.configuration.data.moveFlight.enabled && this.journeys.some(j => j.canMoveDisruptedFlight);
    }

    get disruptions(): DisruptionType[] {
        let result: DisruptionType[] = [];
        this.journeys.forEach(j => {
            if(j.disruptionType) {
                result.push(j.disruptionType);
            }
        });

        return result.distinct(d => d.bookingQueue, d => d);
    }

    getAnyContactEmail(): NullableString {
        for(let contact of this.bookingData.contacts) {
            if(contact.value.emailAddress) {
                return contact.value.emailAddress;
            }
        }

        return null;
    }

    get recordLocator(): string {
        return this.bookingData.recordLocator!;
    }

    get passengersNamesChangeBlockingReason(): NullableString {
        if(this.flightsChangeBlockingReason) {
            return this.flightsChangeBlockingReason;
        }

        if(this.journeys.some(j => j.designator.departureDate.getTime() < this.services.time.currentDate.getTime())) {
            return this.services.language.translate('Passenger name change is not allowed for a booking that has a flight in the past');
        }

        return null;
    }

    get owningSystemCodes(): string[] {
        const codes: string[] = [];
        (this.bookingData.locators.recordLocators || []).forEach(rLocator => {
            if(rLocator.owningSystemCode) {
                codes.push(rLocator.owningSystemCode);
            }
        });

        return codes;
    }

    private get gdsOwningSystemCodes(): string[] {
        return this.owningSystemCodes.filter(code => this.services.configuration.data.systemCodesBlockedForFlightChange.includes(code));
    }

    get isGDSBooking(): boolean {
        return this.gdsOwningSystemCodes.length > 0;
    }

    get flightsChangeBlockingReason(): NullableString {

        if(!this.isGDSBooking) {
            return null;
        }

        if(this.gdsOwningSystemCodes.includes(SystemCodes.ACE)) {
            return this.services.language.translate('This booking does not allow changes through the mobile app since there are other carriers involved in the itinerary. Please call to the phone provided in the booking confirmation.');
        }

        return this.services.language.translate('This booking does not allow changes through the mobile app. Please contact your Travel Agency. Note that any additional services added on our mobile app should be booked again after changing your flight.');
    }

    protected _getAllPassengersFees(): PassengerFeeSnapshotModel[] {
        return this.passengers.selectMany(p => p.fees);
    }

    get bookingLevelFeesGroupedByFeeKey(): Record<string, OtherFeeModel> {

        let result: Record<string, OtherFeeModel> = {};

        this.getBookingLevelFees().forEach(fee => {
            result[fee.feeKey] = new OtherFeeModel(fee, null);
        });

        return result;
    }

    getInitialBookingLevelFeePrice(feeKey: string): Price | null {
        const fee = this.bookingLevelFeesGroupedByFeeKey[feeKey];
        if(!fee) {
            return null;
        }

        return fee.currentPrice;
    }

    countPassengerType(passengerTypeCode: string, discountCode: NullableString) {
        return this.passengers.filter(p => p.passengerType.code === passengerTypeCode && p.passengerDiscountCode === discountCode).length;
    }

    get allJourneysAreSelectedForRefund(): boolean {
        const journeysSelectedForRefund = this.journeys.filter(j => j.isSelectedForRefund).map(j => j.journeyKey);

        return this.journeys.all(j => journeysSelectedForRefund.includes(j.journeyKey));
    }

    get refundableBookingLevelAmount(): Price {
        const bookingLevelFees = this.getBookingLevelFees();

        return Price.sumAll(bookingLevelFees.filter(f => f.feeCode && this.services.feeCodes.getFeeCode(f.feeCode).isRefundable)
                                            .map(f => f.currentPrice), this.createPrice(0));
    }

    get totalToRefund(): Price {
        const refundableServicesAmount = Price.sumAll(this.journeys.filter(j => j.isSelectedForRefund && j.canBeRefunded)
                                         .map(j => j.refundableAmount), this.createPrice(0));

        if(this.allJourneysAreSelectedForRefund) {
            return refundableServicesAmount.sum(this.refundableBookingLevelAmount);
        } else {
            return refundableServicesAmount;
        }

    }
}
