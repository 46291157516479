import {IDotRezServiceCharge} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {ServiceChargeTypeGraphQLEnum} from "../../../../dot-rez-api/data-contracts/enums/service-charge-type.enum";
export const TAXES_SERVICE_CHARGES: ServiceChargeTypeGraphQLEnum[] = [
    ServiceChargeTypeGraphQLEnum.Tax,
    ServiceChargeTypeGraphQLEnum.IncludedTax,
    ServiceChargeTypeGraphQLEnum.IncludedTravelFee
];

export function isDiscountServiceCharge(serviceChargeType: ServiceChargeTypeGraphQLEnum) {
    return serviceChargeType === ServiceChargeTypeGraphQLEnum.Discount || serviceChargeType === ServiceChargeTypeGraphQLEnum.PromotionDiscount;
}
export function sumOfNonTaxesServiceCharges(serviceCharges: IDotRezServiceCharge[]): number {
    return serviceCharges
        .filter(sc => !TAXES_SERVICE_CHARGES.includes(sc.type))
        .sum(sc =>
            isDiscountServiceCharge(sc.type)
                    ? -1 * sc.amount
                    : sc.amount);
}

export function sumOfTaxesServiceCharges(serviceCharges: IDotRezServiceCharge[]): number {
    return serviceCharges
        .filter(sc => TAXES_SERVICE_CHARGES.includes(sc.type))
        .sum(sc => sc.amount);
}


export function sumOfDiscountServiceCharges(serviceCharges: IDotRezServiceCharge[]): number {
    return serviceCharges
        .filter(sc =>  isDiscountServiceCharge(sc.type))
        .sum( sc => sc.amount);
}
