import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";
import {CmsPageKey, CmsPageUrlBuilderParams} from "../../services/cms/cms.service.interface";


const IframeBox = styled.iframe`
    width: 100%;
    height: 100%;
`

/*<iframe sandbox="allow-scripts allow-top-navigation allow-pointer-lock allow-same-origin allow-popups" onload="$(this).contents().find('a').on('click', function(e) {e.preventDefault(); var url = e.target.href; window.open(url, '_system', 'location=yes'); });"></iframe>*/

interface CmsPageComponentProps {
    pageKey: CmsPageKey;
    className?: string;
    urlBuilderParams?: Record<CmsPageUrlBuilderParams, string>;
}
export const CmsPageComponent: React.FC<CmsPageComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <IframeBox className={props.className} src={services.cms.getPageUrl(props.pageKey, props.urlBuilderParams)} frameBorder={0}/>
    );
});
