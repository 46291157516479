import React from 'react';
import {observer} from "mobx-react";
import {IFlightDesignatorViewModel} from "../../../services/booking/models/designator/flight-designator-view-model.interface";
import styled from "styled-components";
import {useServices} from "../../../hooks/use-services.hook";
import {IFlightIdentifierViewModel} from "../../../services/booking/models/identifier/flight-identifier-view-model.interface";
import {DesignatorPlaneIconComponent} from "../common/components/designator-plane-icon.component";
import {HorizontalSpacer} from "../../../basic-components/spacing/horizontal-spacer";
import {CarriersComponent} from "../carriers.component";

const SegmentDesignatorBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const FlightDetailsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1;
  font-size: ${props => props.theme.fontSize.medium};
`

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: ${props => props.theme.fontSize.medium};
`

const DepartureDateBox = styled.div`
`

const FlightIdentifierBox = styled.div`
`

const FlightTimeBox = styled.div`
`

const TravelTimeBox = styled.div`
`

const NumberOfStopsBox = styled.div`
`


interface DetailedSegmentDesignatorComponentProps {
    designator: IFlightDesignatorViewModel;
    identifier?: IFlightIdentifierViewModel;
    className?: string;
}
export const SegmentDesignatorComponent: React.FC<DetailedSegmentDesignatorComponentProps> = observer((props) => {
    const services = useServices();
    const designator = props.designator;
    const columnSeparator = (<HorizontalSpacer/>);
    return (
        <SegmentDesignatorBox>
            <FlightDetailsBox className={props.className}>
                <ColumnBox>
                    <DepartureDateBox>
                        {designator.formatDepartureDate()}
                    </DepartureDateBox>
                    <FlightIdentifierBox>
                        {props.identifier?.fullIdentifierCode()}
                    </FlightIdentifierBox>
                </ColumnBox>
                {columnSeparator}
                <ColumnBox>
                    <FlightTimeBox>
                        {designator.formatDepartureTime()} {designator.origin.stationCode}
                    </FlightTimeBox>
                    <TravelTimeBox>
                        {services.time.formatTravelTime(designator.travelTime)}
                    </TravelTimeBox>
                </ColumnBox>
                <DesignatorPlaneIconComponent/>
                <ColumnBox>
                    <FlightTimeBox>
                        {designator.formatArrivalTime()} {designator.destination.stationCode}
                    </FlightTimeBox>
                    <NumberOfStopsBox>
                        {designator.formatNumberOfStops()}
                    </NumberOfStopsBox>
                </ColumnBox>
            </FlightDetailsBox>
            <CarriersComponent carriers={designator.operatingCarriers}/>
        </SegmentDesignatorBox>
    );
});
