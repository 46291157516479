import React from "react";
import {observer} from "mobx-react-lite";
import {ShoppingCartFeeDetailsButtonComponentProps} from "./shopping-cart-fee-details-button.component";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {ShoppingCartPriceDetailsDialogComponent} from "../price-details-dialog/shopping-cart-price-details-dialog.component";
import {ShoppingCartPriceDetailsSectionComponent} from "../section/shopping-cart-price-details-section.component";


export interface ShoppingCartFeeDetailsDialogComponentProps extends ShoppingCartFeeDetailsButtonComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}

export const ShoppingCartFeeDetailsDialogComponent: React.FC<ShoppingCartFeeDetailsDialogComponentProps> = observer((props) => {
    return (
        <ShoppingCartPriceDetailsDialogComponent title={props.description} dialogHandler={props.dialogHandler} >
            <ShoppingCartPriceDetailsSectionComponent initialPrice={props.initialPrice}
                                                      currentPrice={props.currentPrice}/>
        </ShoppingCartPriceDetailsDialogComponent>
    );
});
