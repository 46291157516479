import { observer } from "mobx-react";
import React from "react";
import { IJourneyHistoryViewModel } from "../../../../services/booking-history/models/journey-history-view-model.interface";
import styled from 'styled-components';
import { NextFlightHeaderComponent } from "./next-flight-header.component";
import { useServices } from "../../../../hooks/use-services.hook";
import { ActionSheetButton } from "@ionic/react";
import { PlaneTicketsIcon } from "../../../../assets/svg/plane-tickets.icon";
import { NextFlightActionButtonsSkeletonsComponent } from "./next-flight-action-buttons-skeletons.component";

import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {CardBox} from "../../../../basic-components/card/card.box";

const NextFlightBox = styled(CardBox)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
    min-height: 189px;
`

const NextFlightStatusBox = styled.div`
    display: flex;
    justify-content: space-between;
    line-height: 1;
`
const DepartureLabelBox = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    display: flex;
    align-items: center;
`

const FlightCanceledLabelBox = styled(DepartureLabelBox)`
  color: ${props => props.theme.colors.danger};
`



const NextFlightDetailsTimeBox = styled.div`
    display: flex;
    flex-direction: column;
`

const TimeToFlightBox = styled.div`
    display: flex;
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.medium};
    align-items: center;
`

const NextFlightActionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    margin-top: 16px;
`

export const BoardingPassButtonBox = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fontSize.large};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-right: 0;
  svg {
      margin-right: 8px;
  }
`

interface FutureFlightComponentProps {
    futureFlight: IJourneyHistoryViewModel
}
export const NextFlightComponent: React.FC<FutureFlightComponentProps> = observer((props) => {
    const services = useServices();

    const actions = props.futureFlight.getNextFlightActions();
    const actionSheetButtons = actions.map(action => {
        const button: ActionSheetButton = {
            text: action.text,
            handler: action.execute
        }
        return button;
    });

    const boardingPasses = props.futureFlight.boardingPasses;

    const viewBoardingPasses = async () => {
        await services.dialogFactory.showBoardingPasses({
            bookingKey: props.futureFlight.bookingKey,
            boardingPasses: boardingPasses
        });
    }

    const renderBoardingPassButton = () => {
        if (boardingPasses.length === 0) {
            return null
        }

        return (

            <BoardingPassButtonBox onClick={viewBoardingPasses}>
                <PlaneTicketsIcon size={1.4} />
                {services.language.translate('Boarding pass')}
            </BoardingPassButtonBox>
        );
    }

    const renderButtons = () => {

        if (props.futureFlight.isCanceled) {
            return null;
        }

        if (actionSheetButtons.length === 0) {
            return (
                <NextFlightActionButtonsSkeletonsComponent />
            );
        }
        return (
            <>
                {
                    actionSheetButtons.map(item => (<PrimaryButton key={item.text} onClick={item.handler}>{item.text}</PrimaryButton>))
                }
            </>
        );
    }

    const renderDepartureLabel = () => {
        if (props.futureFlight.isCanceled) {
            return (<>
                <FlightCanceledLabelBox>
                    {services.language.translate('Your flight has been canceled')}
                </FlightCanceledLabelBox>
                <PrimaryButton onClick={() => props.futureFlight.startManageMyBooking()}>
                    {services.language.translate('Modify')}
                </PrimaryButton>
            </>)
        }
        return (<NextFlightStatusBox>
            <NextFlightDetailsTimeBox>
                <DepartureLabelBox>
                    {services.language.translate('Departure')}
                </DepartureLabelBox>
                <TimeToFlightBox>
                    {props.futureFlight.timeToFlight}
                </TimeToFlightBox>
            </NextFlightDetailsTimeBox>
            {renderBoardingPassButton()}
        </NextFlightStatusBox>)
    }

    return (
        <NextFlightBox>
            <NextFlightHeaderComponent futureFlight={props.futureFlight} />
            {renderDepartureLabel()}
            {!services.maintenance.data.enableMaintenancePage && <>
                <NextFlightActionButtonsContainer>
                    {renderButtons()}
                </NextFlightActionButtonsContainer>
            </>}

        </NextFlightBox>);
})
