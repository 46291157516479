import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {
    IPassengerSegmentViewModel
} from "../../../services/booking/models/passenger-segment/passenger-segment-view-model.interface";
import {useServices} from "../../../hooks/use-services.hook";
import {
    IPassengerSsrEditorViewModel
} from "../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {ISsrType} from "../../../services/ssr-types/ssr-types.service.interface";

const SsrIconComponent: React.FC<{ssrType: ISsrType}> = observer((props) => {
    const styles: CSSProperties = {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignSelf: "center",
        justifySelf: "center"
    };

    return (
        <div style={styles}>
            {props.ssrType.smallIcon}
        </div>
    )
})



const PurchasedSsrComponent: React.FC<{ssrEditor: IPassengerSsrEditorViewModel}> = observer((props) => {
    const services = useServices();

    const rootStyles: CSSProperties = {
        display: "grid",
        gridTemplateColumns: '24px 1fr',
        gap: services.theme.currentTheme.spacing.small
    }

    const ssrInfoStyles: CSSProperties = {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        height: '100%'
    }

    const ssrDetailsStyles: CSSProperties = {
        fontSize: services.theme.currentTheme.fontSize.medium,
        color: services.theme.currentTheme.colors.darkShade
    }

    const renderSsrDetails = () => {
        if(!props.ssrEditor.ssrType.details) {
            return null;
        }

        return (
            <div style={ssrDetailsStyles}>{props.ssrEditor.ssrType.details}</div>
        )
    }


    return (
        <div style={rootStyles}>
            <SsrIconComponent ssrType={props.ssrEditor.ssrType}/>

            <div style={ssrInfoStyles}>
                <div>
                    {`${props.ssrEditor.newQuantity} x ${props.ssrEditor.ssrType.name}`}
                </div>
                {renderSsrDetails()}

            </div>
        </div>
    )
})



export const TicketPassengerSegmentSsrsComponent: React.FC<{passengerSegment: IPassengerSegmentViewModel}> = observer((props) => {
    const services = useServices();

    const gridStyles: CSSProperties = {
        display: "grid",
        gridTemplateColumns: '1fr 1fr',
        columnGap: services.theme.currentTheme.spacing.extraLarge,
        rowGap: services.theme.currentTheme.spacing.medium,
        fontSize: services.theme.currentTheme.fontSize.large,
        lineHeight: 1
    };

    if(services.layout.isInSmallScreenView) {
        gridStyles.gridTemplateColumns = '1fr';
    }


    const soldSsrs = services.ssrTypes.filterVisibleSsrs(props.passengerSegment.getSoldSsrsEditors())
                                                .filter(s => s.ssrType.shouldShowInShoppingCart && s.ssrType.shouldShowInFlightItineraryExtras)
                                                .sort((s1, s2) => s1.ssrType.preferredOrderInLists - s2.ssrType.preferredOrderInLists);
    return (
        <div style={gridStyles}>
            {soldSsrs.map(soldSsr => (<PurchasedSsrComponent key={soldSsr.ssrType.ssrCode} ssrEditor={soldSsr}/>))}
        </div>
    )
})