import {AirlineTheme} from "../../services/theme/interfaces/airline.theme";
import {css} from "styled-components";

export const FIELD_MIN_HEIGHT = "45px";

export function fieldDefaultPadding(theme: AirlineTheme) {
    return theme.spacing.medium;
}


export function fieldInputCommonCssProps(theme: AirlineTheme, hasError: boolean) {
    const commonProps = css`
      width: 100%;
      min-height: ${FIELD_MIN_HEIGHT};
      line-height: 1;
      font-size: ${props => props.theme.fontSize.large};
      border-radius: ${props => props.theme.border.defaultRadius};
      padding: ${props => fieldDefaultPadding(props.theme)};
    `;
    if(hasError) {
        return  css`
            background: rgba(${theme.colors.dangerRgb}, 0.15);
            color: ${theme.colors.danger};
            ${commonProps}
        `
    } else {
        return css`
            background: rgba(${theme.colors.secondaryRgb}, 0.1);
            color: ${theme.colors.primaryShade};
            ${commonProps}
        `
    }
}
