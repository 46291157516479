import React from "react";
import {observer} from "mobx-react";
import {ManageMyBookingSectionTitleBox} from "../manage-my-booking-section.component";
import {useServices} from "../../../../../hooks/use-services.hook";
import {ManageMyBookingContactComponent} from "./manage-my-booking-contact.component";
import styled from "styled-components";
import {ManageMyBookingEditButtonBox} from "../../boxes/manage-my-booking-edit-button.box";
import {EditPencilIcon} from "../../../../../assets/svg/edit-pencil.icon";
import {DialogCloseButtonBehavior} from "../../../../../services/dialog/dialog-enums";
import {ManageMyBookingContactDialogComponent} from "./manage-my-booking-contact-dialog.component";
import {CardBox} from "../../../../../basic-components/card/card.box";

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
`

const TitleBox = styled(ManageMyBookingSectionTitleBox)`
    font-size: ${props => props.theme.fontSize.xLarge};
    flex-grow: 1;
    text-transform: capitalize;
    margin-left: 0;
`

const ManageMyBookingContactSectionComponentBox = styled(CardBox)`
    width: 100%;
`

export const ManageMyBookingContactSectionComponent: React.FC = observer(() => {
    const services = useServices();

    const onClickHandler = async () => {
        await services.dialog.showStandardDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            render: dialogHandler => (<ManageMyBookingContactDialogComponent dialogHandler={dialogHandler}/>),
            onAccept: async () => {
                await services.booking.current.contact.save();
            },
            onReject: async () => {
                services.booking.current.contact.cancelChanges();
            }
        });
    }


    return (
        <ManageMyBookingContactSectionComponentBox>
            <HeaderBox>
                <TitleBox>
                    {services.language.translate('Contact Details')}
                </TitleBox>
                <ManageMyBookingEditButtonBox onClick={onClickHandler}>
                    {services.language.translate('Edit')} <EditPencilIcon size={1.2}/>
                </ManageMyBookingEditButtonBox>
            </HeaderBox>

            <ManageMyBookingContactComponent/>
        </ManageMyBookingContactSectionComponentBox>
    );
});
