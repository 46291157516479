import React from "react";
import {observer} from "mobx-react-lite";
import {IFormField} from "../../../../models/forms/form-field.interface";
import {ItalyCustomerTypeEnum} from "../../../../services/booking/models/invoice/aeroitalia/italy-customer-type.enum";
import {StandardDropDownComponent} from "../../../../components/forms/standard-drop-down/standard-drop-down.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {StandardDropDownListItemModel} from "../../../../components/forms/standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";

export const ItalyCompanyTypeDropDownComponent: React.FC<{field: IFormField<ItalyCustomerTypeEnum>}> = observer((props) => {
    const services = useServices();
    const items: StandardDropDownListItemModel<ItalyCustomerTypeEnum>[] = [
        new StandardDropDownListItemModel(ItalyCustomerTypeEnum.ItalianCompany, services.language.translate('Italian Company')),
        new StandardDropDownListItemModel(ItalyCustomerTypeEnum.ItalianPhysicalPerson, services.language.translate('Italian Physical Person')),
        new StandardDropDownListItemModel(ItalyCustomerTypeEnum.NonItalianCustomer, services.language.translate('Non Italian Customer'))
    ];

    return (
        <StandardDropDownComponent field={props.field} items={items}/>
    )
});
