import React from "react";
import {IonIcon} from "@ionic/react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {checkmark, checkmarkOutline} from "ionicons/icons";
import styled, {css} from "styled-components";
import {IAvailableFareViewModel} from "../../../../../services/flight-search/models/fares/available-fare-view-model.interface";
import {
    PriceWithDiscountComponent,
    PriceWithDiscountOrientation
} from "../../../../../components/price/price-with-discount.component";
import classNames from "classnames";
import {DiscountDiagonalTagIcon} from "../../../../../assets/svg/discount-diagonal-tag.icon";
import {PriceLabelPositionEnum} from "../../../../../components/price/price.component";
import {AirlineTheme} from "../../../../../services/theme/interfaces/airline.theme";

const FareContainerButtonBox = styled.div<{hasPromotionalMessage: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    z-index: 0;
    border-radius: ${props => props.theme.border.defaultRadius};;
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.medium};
    background-color: ${props => props.hasPromotionalMessage ? props.theme.colors.warning : props.theme.colors.primaryContrast};
    color: ${props => props.theme.colors.primaryContrast};
`;

function computeFareButtonColors(theme: AirlineTheme, isSelected: boolean, hasSubscription: boolean) {
    if(hasSubscription) {
        if(isSelected) {
            return css`
              background-color: ${theme.colors.secondary};
              color: ${theme.colors.secondaryContrast};
              border: 4px solid ${theme.colors.warning};
            `
        } else {
            return css`
              background-color: ${theme.colors.secondary};
              color: ${theme.colors.secondaryContrast};
            `
        }
    }

    if(isSelected) {
        return css`
              background-color: ${theme.colors.secondary};
              color: ${theme.colors.secondaryContrast};
              border: 2px solid ${theme.colors.secondary}
            `
    } else {
        return css`
          background-color: ${theme.colors.secondaryContrast};
          color: ${theme.colors.secondary};
          border: 2px solid ${theme.colors.secondary}
            `
    }
}

const FareButtonBox = styled.div<{isSelected: boolean, hasBlueBenefits: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-top: ${props => props.theme.spacing.small};
    padding-bottom: ${props => props.theme.spacing.small};
    margin-top: 0;
    border-radius: ${props => props.theme.border.defaultRadius};
    cursor: ${props => props.isSelected ? "default" : "pointer"};
    ${props => computeFareButtonColors(props.theme, props.isSelected, props.hasBlueBenefits)}
    
    
`;

const PriceWithDiscountBox = styled(PriceWithDiscountComponent)`
    font-size: ${props => props.theme.fontSize.xxxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
`;

const FarePriceDescriptionBox = styled.div<{hasBlueBenefits: boolean}>`
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1;
    padding-bottom: 0;
    display: flex;
    align-items: center;   
    svg {
        margin-right: 3px;
        margin-bottom: 2px;
    }
`


const CheckmarkBox = styled(IonIcon)`
    margin-left: ${props => props.theme.spacing.small};
`

const PromotionalMessageBox = styled.div`
    text-align: center;
`

const PriceLabelBox = styled.div`
    display: flex;
    flex-direction: row;
    
`

interface JourneyFareComponentProps {
    fare: IAvailableFareViewModel;
}

export const AvailableFareComponent: React.FC<JourneyFareComponentProps> = observer((props) => {

    const services = useServices();

    const renderPriceSign = () => {
        if(props.fare.isPriceReduced) {
            return <span>+</span>
        }
        return null;
    }

    const renderPriceLabel = () => {
        if(props.fare.isSelected) {
            return (
                <PriceLabelBox>
                    <CheckmarkBox ios={checkmarkOutline} md={checkmark}/>
                    {renderPriceSign()}
                </PriceLabelBox>
            )
        }
        return renderPriceSign();
    }

    const sellFare = async () => {
        if(props.fare.isSelected) {
            return;
        }
        await props.fare.sell();
    }

    const renderPromotionalMessage = () => {
        if(!props.fare.promotionalMessage) {
            return null;
        }

        return (
            <PromotionalMessageBox>
                {props.fare.promotionalMessage}
            </PromotionalMessageBox>
        );
    }

    return (
        <FareContainerButtonBox hasPromotionalMessage={Boolean(props.fare.promotionalMessage)}
                                onClick={sellFare}
                                className={classNames('tst-fare', {
                                    'tst-fare-standard': !props.fare.hasBlueBenefits,
                                    'tst-fare-blue-benefits': props.fare.hasBlueBenefits
                                })}>
            <FareButtonBox isSelected={props.fare.isSelected} hasBlueBenefits={props.fare.hasBlueBenefits}>
                <FarePriceDescriptionBox hasBlueBenefits={props.fare.hasBlueBenefits} className={'tst-price-name'}>
                    {props.fare.hasBlueBenefits && <DiscountDiagonalTagIcon color={services.theme.currentTheme.colors.warning} />}
                    {props.fare.discountTypeName}
                </FarePriceDescriptionBox>
                <PriceWithDiscountBox discountedPrice={props.fare.discountedPrice}
                                      standardPrice=
                                            {
                                                /*
                                                   if the price is reduced we will show the + sign
                                                   but we don't want to show both standard price and the discounted price.
                                                   This happens on manage my booking flow when we show the difference between the initial journey fare and the new journey fare
                                                   but if the new journey fare has a discount then we would show the + sign and standard fare and discounted fare
                                                   and this will look strange.
                                                   So this is the reason for the condition here.
                                                */
                                                props.fare.isPriceReduced ? props.fare.discountedPrice : props.fare.totalPrice
                                            }
                                      label={renderPriceLabel()}
                                      labelPosition={PriceLabelPositionEnum.Start}
                                      orientation={PriceWithDiscountOrientation.Horizontal}>
                </PriceWithDiscountBox>
            </FareButtonBox>
            {renderPromotionalMessage()}
        </FareContainerButtonBox>
    );
});
