import {ILowFareService} from "./low-fare.service.interface";
import {Station} from "../stations/station.service.interface";
import {ServiceBase} from "../service-base";
import {IDotRezAvailabilityLowFarePassengers} from "../dot-rez-api/data-contracts/requests/booking/lowfare-search.request";
import {IOneWayLowFareReader} from "./low-fare-readers/low-fare-reader.interface";
import {VoidOneWayLowFareReader} from "./low-fare-readers/void-low-fare-reader";
import {OneWayLowFareReader} from "./low-fare-readers/one-way-low-fare-reader";
import {NullableNumber} from "../../types/nullable-types";
import {IFlightScheduleFilter} from "../flight-search/flights-schedule/flight-schedule-filter.interface";
import {IDotRezBookingSession} from "../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";

export class LowFareService extends ServiceBase implements ILowFareService {

    createLowFareReader(originStation: Station,
                        destinationStation: Station,
                        getBookingSession: () => Promise<IDotRezBookingSession>,
                        getClassOfServices: () => string[],
                        getMinPriceAmount: () => NullableNumber,
                        getCurrency: () => string,
                        scheduleFilter: IFlightScheduleFilter,
                        passengersCount: () => IDotRezAvailabilityLowFarePassengers): IOneWayLowFareReader {

        if (!this.services.configuration.data.marketing.enableLowFare) {
            return new VoidOneWayLowFareReader();
        }

        return new OneWayLowFareReader(
            originStation,
            destinationStation,
            getBookingSession,
            getClassOfServices,
            getMinPriceAmount,
            getCurrency,
            scheduleFilter,
            this.services,
            passengersCount);
    }
}
