import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {
    DotRezUpdateSsrsRequestBuilder
} from "../../../../dot-rez-api/data-contracts/requests/booking/update-ssrs.request";
import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {isDotRezSessionExpiredError} from "../../../../dot-rez-api/session/dot-rez-exception";


export class SellSsrsMutation extends BookingMutationActionBase {

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        const builder = new DotRezUpdateSsrsRequestBuilder();
        this.booking.getAllPassengersSegments().forEach(ps => {
            ps.getAllSsrsEditors().forEach(ssr => {
                const sellSsrResult = ssr.buildUpdateSsrsRequest();
                builder.append(sellSsrResult);
            });
        });

        const request = builder.build();
        if(request.ssrsToAdd.length === 0 && request.ssrKeysToRemove.length === 0) {
            return {};
        }

        const getBookingState = async () => {
            try {
                return await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();
            } catch (err) {
                this.services.logger.error('Failed to get booking state after sell ssrs', err);
                return {};
            }

        }

        try {
            const result = await this.session.updateSsrs(request);
            this.booking.analyticsHandler.removeKeyMappings(result.removedSsrsKeys);
            this.booking.analyticsHandler.addKeyMappings(result.addedSsrsKeys);

            this.services.analytics.extrasSelectionEvents.logRemoveFromCart(this.booking, result.removedSsrsKeys)

            const bookingState = await getBookingState();

            setTimeout(() =>{
                this.services.analytics.extrasSelectionEvents.logAddToCart(this.booking, result.addedSsrsKeys)
            })

            return bookingState;
        } catch (err) {
            this.services.logger.error('Failed to sell ssrs', err);
            this.booking.revertSsrsQuantity();
            if(!isDotRezSessionExpiredError(err)) {
                this.services.alert.showError(this.services.language.translate('There was an error trying to save your selection'));
                return await getBookingState();
            }

            return {};
        }



    }


    async onAfterBookingSessionDataUpdated(): Promise<void> {
           await super.onAfterBookingSessionDataUpdated();
    }

}
