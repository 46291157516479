import {MobileWalletPaymentMethodModel} from "./mobile-wallet-payment-method.model";
import React from "react";
import {LogoApplePayIcon} from "../../../../../../assets/svg/logo-apple-pay.icon";

export class ApplePayModel extends MobileWalletPaymentMethodModel {

    get name(): string {
        return "Apple Pay";
    }

    renderIcon(): React.ReactElement | null {
        return (<LogoApplePayIcon />);
    }

    get isSupportedOnCurrentPlatform(): boolean {
        const config = this.services.configuration.data.payments.applePay;
        return (this.services.device.isIOS && config.enableOnIOS)
            || (this.services.device.isMac && config.enableOnMac);
    }
}