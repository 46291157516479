import React, {useRef} from "react";
import {useServices} from "../../hooks/use-services.hook";
import styled from "styled-components";
import {observer} from "mobx-react";
import {NullableNumber} from "../../types/nullable-types";
import {Check} from "../../types/type-checking";
import useIsInViewPort from "../../hooks/use-is-in-view-port.hook";

const CalendarBox = styled.div`
    display: flex;
    flex-direction: column;
`

const MonthNameBox = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.primaryShade};
`

const CalendarHeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.theme.colors.primaryContrast};
    z-index: 1;
`

const DayBox = styled.span`
    display: flex;
    width: calc(100%/7);
    justify-content: center;
    align-items: center;
    line-height: 1;
    min-height: 40px;
    margin: 2px 0;
`

const WeekBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const WeekHeaderBox = styled(WeekBox)`
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.large};
    background-color: ${props => props.theme.colors.lightShade};
    color: ${props => props.theme.colors.primaryShade};
    border-radius: ${props => props.theme.border.defaultRadius};
    margin-bottom: ${props => props.theme.spacing.medium};
`

interface MonthCalendarHeaderComponentProps {
    month: number;
    year: number;
}



const CalendarHeaderComponent: React.FC<MonthCalendarHeaderComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <CalendarHeaderBox>
            <MonthNameBox>
                {`${services.time.getMonthFullNameFromIndex(props.month).toUpperCase()} ${props.year}`}
            </MonthNameBox>

            <DayNamesHeaderComponent/>
        </CalendarHeaderBox>
    );
});

const DayNamesHeaderComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <WeekHeaderBox>
            {services.time.getWeekDaysAbbreviatedNames().map((dayName, index) => <DayBox key={`${dayName}_${index}`}>{dayName}</DayBox>)}
        </WeekHeaderBox>
    );
});

const WeeksContainerBox = styled.div`
    display: flex;
    flex-direction: column;
`

const WeekContainerBox = styled.div`
    display: flex;
    flex-direction: row;
`

interface WeekComponentProps {
    month: number;
    year: number;
    dates: Date[];
    onClick?: (date: Date) => void;
    renderDate?: (date: Date) => React.ReactElement;
}

const WeekDatesComponent: React.FC<WeekComponentProps> = observer((props) => {
    const renderDate = (date: Date) => {
        let day: NullableNumber = date.getDate();

        if(date.getMonth() !== props.month || date.getFullYear() !== props.year) {
            day = null;
        }

        const onClickHandler = () => {
            if(!props.onClick) {
                return;
            }
            if(Check.isNullOrUndefined(day)) {
                return;
            }


            props.onClick(date);
        }

        const getDayContent = () => {
            if(Check.isNullOrUndefined(day)) {
                return null;
            }

            if(props.renderDate) {
                return props.renderDate(date);
            }

            return day;
        }

        return (
            <DayBox key={date.toDateString()} onClick={onClickHandler}>
                {getDayContent()}
            </DayBox>
        );
    }
    return (
        <WeekContainerBox>
            {props.dates.map(date => renderDate(date))}
        </WeekContainerBox>
    )
})

interface CalendarContentComponentProps {
    month: number;
    year: number;
    onClick?: (date: Date) => void;
    renderDate?: (date: Date) => React.ReactElement;
}

const CalendarContentComponent: React.FC<CalendarContentComponentProps> = observer((props) => {
    const services = useServices();
    const weeks = services.time.getMonthCalendarWeeks(props.month, props.year);
    return (
        <WeeksContainerBox>
            {weeks.map((weekDates, index) => <WeekDatesComponent key={`${props.year}_${props.month}_${index}`}
                                                                               month={props.month}
                                                                               year={props.year}
                                                                               dates={weekDates}
                                                                               onClick={props.onClick}
                                                                               renderDate={props.renderDate}/>)}
        </WeeksContainerBox>
    );
});

interface CalendarComponentProps {
    month: number;
    year: number;
    onClick?: (date: Date) => void;
    renderDate?: (date: Date, isInViewPort: boolean) => React.ReactElement;
}

export const CalendarComponent: React.FC<CalendarComponentProps> = observer((props) => {
    let elementRef = useRef<HTMLDivElement | null>(null)
    let isInViewPort = useIsInViewPort(elementRef)


    const contentProps: CalendarContentComponentProps = {
        month: props.month,
        year: props.year,
        onClick: props.onClick
    }

    if(props.renderDate) {
        contentProps.renderDate = (date) =>  (props.renderDate && props.renderDate(date, isInViewPort)) || (<></>);
    }

    return (
        <CalendarBox ref={elementRef}>
            <CalendarHeaderComponent month={props.month} year={props.year}/>
            <CalendarContentComponent {...contentProps}/>
        </CalendarBox>
    );
});
