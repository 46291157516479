import React from "react";
import {
    IDialogConfirmationHandler,
    IDialogOptions,
    IDialogRenderer
} from "../dialog.service.interface";
import {v4 as uuidv4} from "uuid";
import {DialogCloseButtonBehavior} from "../dialog-enums";
import {Check} from "../../../types/type-checking";
import {makeObservable, observable, runInAction} from "mobx";

export abstract class DialogBaseRenderer<TAcceptData = any, TOptions extends IDialogOptions<TAcceptData> = IDialogOptions<TAcceptData>> implements IDialogRenderer, IDialogConfirmationHandler<TAcceptData> {
    constructor(protected readonly _dialogOptions: TOptions,
                private readonly _onUserAccept: (data?: TAcceptData) => void,
                private readonly _onUserReject: () => void,
                private readonly _onHardReject: () => void) {

        this.id = uuidv4();
        makeObservable(this, {
            isOpen: observable.ref
        });
    }

    readonly id: string;

    abstract render(): React.ReactElement;
    abstract get isFullScreenDialog(): boolean;
    abstract get shouldPreserveBottomSafeArea(): boolean;
    get hasModalSheetHandle(): boolean {
        return false;
    }


    isOpen: boolean = true;
    private _setIsOpen(value: boolean): void {
        runInAction(() => {
            this.isOpen = value;
        })
    }

    accept(data?: TAcceptData) {
        this._onUserAccept(data);
        this._setIsOpen(false);
    }

    reject() {
        this._onUserReject();
        this._setIsOpen(false);
    }

    hardReject() {
        this._onHardReject();
        this._setIsOpen(false);
    }


    protected abstract _getDefaultBackButtonBehavior(): DialogCloseButtonBehavior;

    get closeButtonBehavior(): DialogCloseButtonBehavior {
        if(Check.isUndefined(this._dialogOptions.closeButtonBehavior)) {
            return this._getDefaultBackButtonBehavior();
        }

        return this._dialogOptions.closeButtonBehavior;
    }

    onBackButtonClick(): void {

        switch (this.closeButtonBehavior) {
            case DialogCloseButtonBehavior.Accept:
                this.accept();
                break;
            case DialogCloseButtonBehavior.Reject:
                this.reject();
                break;
        }
    }

    onBackdropDismiss(): void {
        this.reject();
    }

    private _elementRef: HTMLElement | undefined = undefined;

    attachElementRef(element: HTMLElement | undefined): void {
        this._elementRef = element;
    }

    getAttachedElementRef(): HTMLElement | undefined {
        return this._elementRef;
    }

}
