import {Check} from "../../../../types/type-checking";

export type Gender = 'Male' | 'Female' | 1 | 2;
export type NullableGender = Gender | null;

export function genderToNumber(gender: NullableGender): NullableGender {
    if(Check.isNullOrUndefined(gender)) {
        return null;
    }

    switch (gender) {
        case "Male":
            return 1;
        case "Female":
            return 2;
        default:
            return gender;
    }
}

export function genderToString(gender: NullableGender | undefined): NullableGender {
    if(Check.isNullOrUndefined(gender)) {
        return null;
    }

    switch (gender) {
        case 1:
            return "Male";
        case 2:
            return "Female";
        default:
            return gender;
    }
}
