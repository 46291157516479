import React from 'react';
import {SsrTypeBase} from "../../ssr-type-base";
import {IServiceFactory} from "../../../service-factory.interface";
import {ScbgSsrSmallIcon} from "./scbg.ssr.small-icon";
import {ScbgSsrLargeIcon} from "./scbg.ssr.large-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import { ScbgSsrLargeIconDisabled } from './scbg.ssr.large-icon-disabled';
import { SsrCategoryEnum } from '../../ssr-category.enum';

export default class ScbgSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('SCBG', services);
    }

    get smallIcon() {
        return (<ScbgSsrSmallIcon/>);
    }
    get largeIcon() {
        return (<ScbgSsrLargeIcon/>)
    }

    get largeIconDisabled() {
        return (<ScbgSsrLargeIconDisabled/>);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SCBG;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.Baggage;
    }
}
