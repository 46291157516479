import {IRoute} from "../../../navigation/models/route.interface";
import {ManageMyBookingStepBase} from "../common/manage-my-booking-step.base";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";

export class ManageMyBookingExtrasStep extends ManageMyBookingStepBase {

    get title(): string {
        return this.services.language.translate('Extras');
    }

    get route(): IRoute {
        return this.routes.extras;
    }

    async next(): Promise<void> {
        await waitForBookingMutations(this.booking);
        this._activateStepByRoute(this.routes.finalPrice);
    }
}
