import React from "react";
import {observer} from "mobx-react";
import {IPersonTravelDocumentViewModel} from "../../services/user/models/person/person-travel-document-view-model.interface";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";
import {IonItem, IonItemOption, IonItemOptions, IonItemSliding} from "@ionic/react";
import { RecicleBinIcon } from "../../assets/svg/recicle-bin.icon";
import { EditPencilIcon } from "../../assets/svg/edit-pencil.icon";
import {SlideDeleteButtonBox} from "../forms/buttons/slide-delete-button.box";


const TravelDocumentBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-bottom: 4px;
    margin-bottom: 14px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0;
        background-color: ${props => props.theme.colors.medium};
    }

    &:last-of-type {
        margin-bottom: 0;

        &:after {
            display: none;
        }
    }
`

const TravelDocumentDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const EditTravelDocumentButton = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fontSize.large};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;

  svg {
      margin-left: 5px;
  }
`

const IonItemBox = styled(IonItem)`
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --background: none;
`
const IonItemOptionBox = styled(IonItemOption)`
`

const TravelDocumentDetailsInfoBox = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
    margin-bottom: 8px;
    display: flex;
`
const ExpiredDocumentBox = styled.div`
    color: ${props => props.theme.colors.danger};
    position: relative;
    display: flex;
    align-items: center;
    font-weight: ${props => props.theme.fontWeight.bold};

    &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${props => props.theme.colors.danger};
        margin: 0 5px;
    }
`

const TravelDocumentDetailsTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 10px;
    font-weight: ${props => props.theme.fontWeight.bold};
`



const IonItemOptionsBox = styled(IonItemOptions)`
    border: none;
`

const DeleteButtonComponent: React.FC<{travelDocument: IPersonTravelDocumentViewModel}> = observer((props) => {
    const services = useServices();
    const onClickHandler = async () => {
        await props.travelDocument.delete();
    }
    return (
        <SlideDeleteButtonBox onClick={onClickHandler}>
            {services.language.translate('Delete')} <RecicleBinIcon size={1.4}/>
        </SlideDeleteButtonBox>
    )
});

export const PersonTravelDocumentViewComponent: React.FC<{travelDocument: IPersonTravelDocumentViewModel}> = observer((props) => {
    const services = useServices();
    const onEdit = async () => {
        await props.travelDocument.edit();
    }

    const getIssueCountryName = () => {
        const countryCode = props.travelDocument.fields.issuedByCode.value;
        if(!countryCode) {
            return '';
        }

        return services.country.tryGetCountry(countryCode)?.name || '';
    }



    return (
        <TravelDocumentBox>
            <IonItemSliding>
                <IonItemOptionsBox>
                    <IonItemOptionBox>
                        <DeleteButtonComponent travelDocument={props.travelDocument}/>
                    </IonItemOptionBox>
                </IonItemOptionsBox>
                <IonItemBox lines="none">
                    <TravelDocumentDetailsBox>
                        <TravelDocumentDetailsTitleBox>
                            {services.travelDocumentType.tryGetDocumentTypeName(props.travelDocument.fields.documentTypeCode.value)}
                        </TravelDocumentDetailsTitleBox>
                        <TravelDocumentDetailsInfoBox>
                            {services.language.translate('Document Number')} {props.travelDocument.fields.number.value}
                        </TravelDocumentDetailsInfoBox>
                        <TravelDocumentDetailsInfoBox>
                            {services.language.translationFor('Issued by {countryName}').withParams({countryName: getIssueCountryName()})}
                        </TravelDocumentDetailsInfoBox>
                        <TravelDocumentDetailsInfoBox>
                            {services.language.translationFor('Issue date {issueDate}').withParams({issueDate: services.time.formatUserFriendlyDate(props.travelDocument.fields.issuedDate.value)})}
                        </TravelDocumentDetailsInfoBox>
                        <TravelDocumentDetailsInfoBox>
                            {services.language.translationFor('Valid until {expirationDate}').withParams({expirationDate: services.time.formatUserFriendlyDate(props.travelDocument.fields.expirationDate.value)})}
                            {props.travelDocument.isExpired ? <ExpiredDocumentBox>{services.language.translate('Expired')}</ExpiredDocumentBox> : ''}
                        </TravelDocumentDetailsInfoBox>
                    </TravelDocumentDetailsBox>
                </IonItemBox>
            </IonItemSliding>

            <EditTravelDocumentButton onClick={onEdit}>
                {services.language.translate('Edit')} <EditPencilIcon size={1.4}/>
            </EditTravelDocumentButton>

        </TravelDocumentBox>
    );
});
