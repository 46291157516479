import {IFeeCode} from "../fee-code.interface";

export class FeeCodeBase implements IFeeCode {
    constructor(public readonly code: string) {
    }

    get isRefundable(): boolean {
        return true;
    }
}
