import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";


interface ManageMyBookingApplyChangesButtonComponentProps {
    onClick: () => void;
    disabled: boolean;

}
export const ManageMyBookingApplyChangesButtonComponent: React.FC<ManageMyBookingApplyChangesButtonComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <PrimaryButton onClick={props.onClick} disabled={props.disabled}>
            {services.language.translate('Apply changes')}
        </PrimaryButton>
    )
});
