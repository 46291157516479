import React from "react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";


const AttachedCompanionBox = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${props => props.theme.spacing.large};
`

const AttachedCompanionDetailsBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

const PassengerFullNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
`

const DateOfBirthBox = styled.div`
    
`

const ChangeButtonBox = styled.button`
    color: ${props => props.theme.colors.secondary};
    background-color: transparent;
    font-size: ${props => props.theme.fontSize.large};
`

export const AttachedCompanionComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const services = useServices();
    const companionHandler = props.passenger.companion;
    if(!companionHandler.isAttached) {
        return null;
    }

    const onClickHandler = () => {
        companionHandler.detachCompanion();
    }

    const renderChangeButton = () => {
        if(!companionHandler.canBeModified) {
            return null;
        }
        return (
            <ChangeButtonBox onClick={onClickHandler}>
                {services.language.translate('Change')}
            </ChangeButtonBox>
        );
    }

    return (
        <AttachedCompanionBox>
            <AttachedCompanionDetailsBox>
                <PassengerFullNameBox>
                    {companionHandler.getCompanionFullName()}
                </PassengerFullNameBox>
                <DateOfBirthBox>
                    {companionHandler.companionDateOfBirth}
                </DateOfBirthBox>
            </AttachedCompanionDetailsBox>
            {renderChangeButton()}
        </AttachedCompanionBox>
    )
});
