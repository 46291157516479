import React, {useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {v4 as uuidv4} from "uuid";
import {
    scrollElementIntoViewLazySmooth
} from "../../../../../utils/scroll-element-into-view";
import {HorizontalSpacer} from "../../../../../basic-components/spacing/horizontal-spacer";
import {ExtrasSectionLargeScreenComponentCommonProps} from "./extras-section.large-screen-component-common.props";
import {CardBox} from "../../../../../basic-components/card/card.box";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {ExtrasSectionComponentCommonProps} from "../extras-section.component.common-props";
import {PriceWithDiscountComponent} from "../../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../../components/price/standard-price-label.component";
import {ZIndex} from "../../../../../types/z-index";
//import {SsrModificationBlockingReasonComponent} from "../../../common-components/ssr-modification-blocking-reason.component";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";
import {PrimaryButton} from "../../../../../basic-components/buttons/primary-button";
import {useServices} from "../../../../../hooks/use-services.hook";


const SectionBox = styled(CardBox)`
    padding: 0;
    width: 100%;
`

const SectionHeaderBox = styled.div`
  position: sticky;
  top: 0;
  z-index: ${ZIndex.StickyHeaders};
  display: flex;
  flex-direction: column;
  width: 100%;
  
  cursor: pointer;
  line-height: 1;
`

const HeaderContentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: ${ZIndex.StickyHeaders};
  font-size: ${props => props.theme.fontSize.xxxLarge};
  font-weight: ${props => props.theme.fontWeight.bold};
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.secondaryContrast};
  border-radius: ${props => props.theme.border.defaultRadius} ${props => props.theme.border.defaultRadius} 0 0;
  padding: ${props => props.theme.spacing.medium};
`

const PriceLabelBox = styled(StandardPriceLabelComponent)`
    color: ${props => props.theme.colors.primaryContrast};
    font-size: ${props => props.theme.fontSize.small};
`

const SectionContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.large};
  padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
`

const TitleBox = styled.div`
  flex-grow: 1;
`

const IconBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
`

const PurchaseSummaryContainerBox = styled.div`
    width: 100%;
    cursor: pointer;
`

const CollapsedBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: ${props => props.theme.spacing.cardDefaultPaddingPx};
`

const AddModifyButton = styled(PrimaryButton)`
    width: 220px;
`

export interface ExtrasLargeScreenSectionComponentProps extends ExtrasSectionComponentCommonProps, ExtrasSectionLargeScreenComponentCommonProps {
    renderContent: (headerElementRef: React.MutableRefObject<HTMLDivElement | null>) => React.ReactElement;
}

export const ExtrasSectionLargeScreenComponent: React.FC<ExtrasLargeScreenSectionComponentProps> = observer((props) => {
    const services = useServices();
    const [id] = useState(uuidv4());
    const sectionElementRef = useRef<HTMLDivElement | null>(null);
    const headerElementRef = useRef<HTMLDivElement | null>(null);
    const isExpanded = props.currentExpandedSection === id;

    const onClickHandler = () => {

        if(isExpanded) {
            props.onExpand("");
        } else {
            props.onExpand(id);
            scrollElementIntoViewLazySmooth(sectionElementRef.current, {
                block: "start",
                inline: "start"
            });
        }

    }

    const renderChangeButton = () => {
        if(props.modificationBlockingReason) {
            return null;
        }
        return (
            <AddModifyButton onClick={onClickHandler}>
                {props.hasPurchases ? services.language.translate('Modify') : services.language.translate('Add')}
            </AddModifyButton>
        )
    }


    const renderContent = () => {
        if(isExpanded) {
            return (
                <SectionContentBox>
                    {props.renderContent(headerElementRef)}
                    {/*<SsrModificationBlockingReasonComponent reason={props.modificationBlockingReason}/>*/}
                </SectionContentBox>
            )
        } else if(props.renderPurchaseSummary) {
            return (
                <CollapsedBox>
                    <PurchaseSummaryContainerBox onClick={onClickHandler}>
                        {props.renderPurchaseSummary()}
                    </PurchaseSummaryContainerBox>
                    {renderChangeButton()}
                </CollapsedBox>

            )

        }

        return null;
    }

    const renderChevron = () => {
        if(isExpanded) {
            return (
                <IonIcon icon={chevronUpOutline}/>
            )
        } else {
            return (
                <IonIcon icon={chevronDownOutline}/>
            )
        }
    }

    const renderPrice = () => {
        if(!props.modificationBlockingReason && props.minDiscountedPrice && props.minStandardPrice) {
            return (
                <PriceWithDiscountComponent label={<PriceLabelBox/>}
                                            discountedPrice={props.minDiscountedPrice}
                                            standardPrice={props.minStandardPrice}/>
            );
        }

        return null;
    }

    return (
        <SectionBox ref={sectionElementRef}>
            <SectionHeaderBox onClick={onClickHandler} ref={headerElementRef}>
                <HeaderContentBox>
                    <IconBox>
                        {props.renderIcon()}
                    </IconBox>
                    <HorizontalSpacer size={"medium"}/>
                    <TitleBox>
                        {props.sectionTitle}
                    </TitleBox>
                    {renderPrice()}
                    <HorizontalSpacer size={"large"}/>
                    {renderChevron()}
                </HeaderContentBox>
                <VerticalSpacer/>
            </SectionHeaderBox>
            {renderContent()}

        </SectionBox>
    );
});
