import React, {PropsWithChildren} from "react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {VerticalSpacer} from "../../../basic-components/spacing/vertical-spacer";
import {StandardPageHeaderSmallScreenComponent} from "../../../basic-components/page/standard-page-header/standard-page-header.small-screen.component";
import {WizardProgressIndicatorSmallScreenComponent} from "../../../components/wizard-progress-indicator/wizard-progress-indicator.small-screen.component";

const WizardIndicatorBox = styled(WizardProgressIndicatorSmallScreenComponent)`
  padding-bottom: ${props => props.theme.spacing.large};
`

interface FlightSearchPageHeaderComponentProps extends PropsWithChildren {
    title: string | React.ReactElement
}

export const FlightSearchPageHeaderComponent: React.FC<FlightSearchPageHeaderComponentProps> = (props) => {
    const services = useServices();
    return (
        <StandardPageHeaderSmallScreenComponent title={props.title}>
            <WizardIndicatorBox getWizard={() => services.flightSearch.steps} />
            <VerticalSpacer size={"medium"}/>
            {props.children}
        </StandardPageHeaderSmallScreenComponent>
    )
}
