import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {AirlineLogoMulticolorIcon} from "../../../assets/svg/airline-logo-multicolor.icon";
import {useServices} from "../../../hooks/use-services.hook";
import {IonHeader, IonToolbar} from "@ionic/react";
import {StandardPageHeaderComponentProps} from "./standard-page-header.component.props";
import {TestModeMenuComponent} from "../../test-mode/test-mode-menu.component";
import {ZIndex} from "../../../types/z-index";
import {PageBackButtonComponent} from "../page-back-button/page-back-button.component";

const HeaderContainerBox = styled(IonHeader)`
    background-color: ${props => props.theme.colors.primaryContrast};
    color: ${props => props.theme.colors.primary};
    padding: ${props => props.theme.spacing.pageDefaultPaddingPx} 0 0 0;
    z-index: ${ZIndex.MainHeader};
`

const StandardPageWebappToolbarBox = styled(IonToolbar)`
  --border-style: none;
  --background: ${props => props.theme.colors.primaryContrast};
  --color: ${props => props.theme.colors.primary};
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: ${props => props.theme.spacing.medium};
  --padding-bottom: 0;
  
`

const HeaderContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const AirlineLogoBox = styled.div`
    cursor: pointer;
`

const HeaderTopBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${props => props.theme.spacing.pageDefaultPaddingPx};
  margin-bottom: ${props => props.theme.spacing.medium};
`

const HeaderTopLeftBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: ${props => props.theme.spacing.large};
`

const HeaderTopRightBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: ${props => props.theme.spacing.large};
`

const HeaderSeparatorLine = styled.div`
  height: 2px;
  background-color: ${props => props.theme.colors.primary};
`

export const StandardPageHeaderLargeScreenComponent: React.FC<StandardPageHeaderComponentProps> = observer((props) => {
    const services = useServices();


    const onLogoButtonClick = async () => {
        if(props.onLogoButtonClick) {
            props.onLogoButtonClick();
        } else {
            await services.navigator.goHome();
        }
    }

    const renderChildren = () => {
        if(props.children) {
            return props.children;
        }

        return (<HeaderSeparatorLine/>);

    }

    return (
        <HeaderContainerBox>
            <StandardPageWebappToolbarBox>
                <HeaderContentBox>
                    <HeaderTopBox>
                        <HeaderTopLeftBox>
                            <PageBackButtonComponent isHidden={props.hideBackButton}/>
                        </HeaderTopLeftBox>

                        <HeaderTopRightBox>
                            <AirlineLogoBox onClick={onLogoButtonClick}>
                                <AirlineLogoMulticolorIcon/>
                            </AirlineLogoBox>
                            <TestModeMenuComponent/>
                        </HeaderTopRightBox>

                    </HeaderTopBox>

                    {renderChildren()}
                </HeaderContentBox>


            </StandardPageWebappToolbarBox>

        </HeaderContainerBox>
    )
});
