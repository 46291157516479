import React from 'react';
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const PlaneTicketsIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} sizeIsHeight viewBox="0 0 18.617 14.393" ratio={18.617/14.393}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(-370.712 -400.094)"><path d="M473.349,493.2l-1.086.255L469.826,492c-.545-.325-.815.36-.426.711l1.32,1.109-1.919.45-.327-.209c-.647-.422-.533.161-.422.591l.1.416c.1.442.576.34.812.291l2-.463-.686,1.556c-.194.488.352.983.7.45l1.527-2.365,1.189-.276C475.146,493.924,474.109,493.027,473.349,493.2Z" transform="translate(-90.257 -85.215)" /><path d="M444.139,456.23h-11.3a1.476,1.476,0,0,0-1.475,1.475v7.4a1.476,1.476,0,0,0,1.475,1.475h11.3a1.476,1.476,0,0,0,1.475-1.475v-7.4A1.476,1.476,0,0,0,444.139,456.23Zm.252,8.879a.255.255,0,0,1-.252.252h-1.715a.288.288,0,0,0-.529,0h-9.061a.255.255,0,0,1-.252-.252v-7.4a.255.255,0,0,1,.252-.252h9.037v.252a.288.288,0,0,0,.576,0v-.252h1.692a.255.255,0,0,1,.252.252Z" transform="translate(-56.285 -52.097)" /><path d="M373.827,411.511a.222.222,0,0,1-.261-.166l-1.624-7.292a.222.222,0,0,1,.166-.262l11.1-2.468a.222.222,0,0,1,.261.166l.381,1.716a.611.611,0,1,0,1.193-.265l-.381-1.716a1.445,1.445,0,0,0-1.721-1.095l-11.1,2.469a1.444,1.444,0,0,0-1.095,1.721l1.622,7.292a1.439,1.439,0,0,0,1.721,1.095.611.611,0,1,0-.263-1.194Z" transform="translate(0 0)" /><path d="M577.768,490.73a.288.288,0,0,0-.288.288v.863a.288.288,0,0,0,.576,0v-.863A.288.288,0,0,0,577.768,490.73Z" transform="translate(-191.893 -84.115)"/><path d="M577.768,550.73a.288.288,0,0,0-.288.288v.863a.288.288,0,0,0,.576,0v-.863A.288.288,0,0,0,577.768,550.73Z" transform="translate(-191.893 -139.799)" /><path d="M577.768,520.73a.288.288,0,0,0-.288.288v.863a.288.288,0,0,0,.576,0v-.863A.288.288,0,0,0,577.768,520.73Z" transform="translate(-191.893 -111.957)" /></g>
        </SvgIcon>
    )
}
