export enum DistributionOptionsEnum {
    None= "None",
    Mail = "Mail",
    Email = "Email",
    Fax = "Fax",
    MailFax = "MailFax",
    Airport = "Airport",
    Hold = "Hold",
    Print = "Print"
}
