import React from "react";
import {observer} from "mobx-react";
import {SsrsSelectionComponentProps} from "./ssrs-selection.component.props";
import {SsrsSelectionWithSlidesComponent} from "./ssrs-selection-with-slides.component";

export const SsrsSelectionComponent: React.FC<SsrsSelectionComponentProps> = observer((props) => {

    return (<SsrsSelectionWithSlidesComponent {...props}/>);

    /*

    const presentationMode = props.journeyCustomSsrsEditorRenderer?.presentationMode
                             ?? props.presentationMode
                             ?? (props.ssrsAggregator.countPassengers > 1 ? SsrsSelectionPresentationMode.UseSlides : SsrsSelectionPresentationMode.SingleScreen);


    if(presentationMode === SsrsSelectionPresentationMode.UseSlides) {
        return (
            <SsrsSelectionWithSlidesComponent {...props}/>
        );
    } else {
        return (
            <SsrsSelectionSingleScreenComponent {...props}/>
        )
    }
*/
});
