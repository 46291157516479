import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IonFooter} from "@ionic/react";
import {AirlineTheme} from "../../../services/theme/interfaces/airline.theme";

function computePaddings(theme: AirlineTheme, shouldPreserveBottomSafeArea: boolean, hasChildren: boolean) {
    if(shouldPreserveBottomSafeArea) {
        if(hasChildren) {
            return css`
              padding: ${theme.spacing.pageDefaultPaddingPx} ${theme.spacing.pageDefaultPaddingPx} calc(${theme.spacing.pageDefaultPaddingPx} + env(safe-area-inset-bottom)) ${theme.spacing.pageDefaultPaddingPx};
              border-top: 1px solid ${props => props.theme.border.mainColor};
            `
        } else {
            return css`
              padding: 0 0 env(safe-area-inset-bottom) 0;
              
            `
        }

    }

    if(hasChildren) {
        return css`
              padding: ${props => props.theme.spacing.pageDefaultPaddingPx};
        `
    }

    return (
        css`
            padding: 0;
        `
    )
}

const FooterBox = styled(IonFooter)<{shouldPreserveBottomSafeArea: boolean; horizontallyCentered?: boolean; hasChildren: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    ${props => computePaddings(props.theme, props.shouldPreserveBottomSafeArea, props.hasChildren)}
    
    ${
        props => props.horizontallyCentered
               ? css`
                  align-items: center;
                `
               : ''
    }
    
    background-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.lightContrast};
`


interface StandardPageFooterComponentProps extends PropsWithChildren {
    className?: string;
    shouldPreserveBottomSafeArea?: boolean;
    horizontallyCentered?: boolean;
}

export const StandardPageFooterComponent: React.FC<StandardPageFooterComponentProps> = observer((props) => {

    const hasChildren = React.Children.count(props.children) > 0;

    const shouldPreserveBottomSafeArea = props.shouldPreserveBottomSafeArea ?? true;

    return (
        <FooterBox className={props.className}
                   hasChildren={hasChildren}
                   shouldPreserveBottomSafeArea={shouldPreserveBottomSafeArea}
                   horizontallyCentered={props.horizontallyCentered}>
            {props.children}
        </FooterBox>
    )



});
