import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../../../../../services/dialog/dialog.service.interface";
import {Check} from "../../../../../../types/type-checking";
import {ExtrasSectionSmallScreenComponent} from "../../../base-component/small-screen/extras-section.small-screen.component";
import {ExtrasSeatsSummarySmallScreenComponent} from "./extras-seats-summary.small-screen.component";
import { SeatSelectionDialogComponent } from "../../../../seat-selection/dialogs/seat-selection-dialog.component";

export const ExtrasSeatsSmallScreenComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    const renderSeatSelection = (dialogHandler: IDialogConfirmationHandler) => {
            return(<SeatSelectionDialogComponent dialogHandler={dialogHandler} />)
    }

    const hasSomeSeats = services.booking.current.getAllPassengersSegments().some(s => s.hasSeat);

    const onAddButtonClick = () => {
        const firstPassengerSegmentWithoutSeat = booking.getAllPassengersSegments().findFirst(s => Check.isNullOrUndefined(s.assignedSeat));

        if(firstPassengerSegmentWithoutSeat){
            booking.seatsMapsEditors.setCurrentPassengerSegment(firstPassengerSegmentWithoutSeat);
        }
    }


    return(
        <ExtrasSectionSmallScreenComponent sectionTitle={services.language.translate('Seats')}
                                           showButton={true}
                                           modificationBlockingReason={null}
                                           hasPurchases={hasSomeSeats}
                                           minDiscountedPrice={booking.minSeatsFee}
                                           minStandardPrice={booking.minSeatsFee}
                                           renderIcon={() => services.ssrTypes.STST.iconInExtras}
                                           renderDialog={(modalHandler) => renderSeatSelection(modalHandler)}
                                           onAddButtonClick={onAddButtonClick}
                                           renderPurchaseSummary={() => (<ExtrasSeatsSummarySmallScreenComponent/>)} />
    )
})
