import {IFareDiscountType} from "../discount-types/fare-discount-type.interface";
import {BogoFareDiscount} from "../discount-types/bogo-fare-discount";
import {GeneralFareDiscount} from "../discount-types/general-fare-discount";
import {PromoFareDiscount} from "../discount-types/promo-fare-discount";
import {AvailableFareModel} from "./available-fare.model";
import {BlueBenefitsDiscount} from "../discount-types/blue-benefits-discount";

/**
 * A fare represent the price for a specific journey
 */
export class AvailableFareWithDiscountModel extends AvailableFareModel {
    protected _getAvailableDiscountTypes(): IFareDiscountType[] {
        const bogoDiscount = new BogoFareDiscount(this)
        return [
            bogoDiscount,
            new GeneralFareDiscount(this, bogoDiscount),
            new PromoFareDiscount(this, bogoDiscount),
            new BlueBenefitsDiscount(this, bogoDiscount)
        ];

    }


}
