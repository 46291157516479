import React from "react";
import {ISsrType, SsrQuantityEditorStyle} from "./ssr-types.service.interface";
import {IServiceFactory} from "../service-factory.interface";
import styled from "styled-components";
import {NullableNumber, NullableString} from "../../types/nullable-types";
import {FootNoteComponent} from "../../components/foot-note/foot-note.component";
import {ICheckInRestriction} from "../booking/models/check-in-restrictions/check-in-restriction.interface";
import {SsrOnlineCheckInRestriction} from "../booking/models/check-in-restrictions/ssr-online-check-in-restriction";
import {PassengerSegmentSsrEditorModel} from "../booking/models/ssrs/passenger-segment-ssr-editor.model";
import {ISeatViewModel} from "../booking/models/seat-maps/seat-view-model.interface";
import { SsrCategoryEnum } from "./ssr-category.enum";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "./ssrs-preferred-orders-in-shopping-cart";
import {Check} from "../../types/type-checking";
import {TimeSpan} from "../../types/time-span";
import {IBookingViewModel} from "../booking/models/booking-view-model.interface";
import {IJourneyViewModel} from "../booking/models/journey/journey-view-model.interface";

const SsrTypeInBundleContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    &:last-of-type{
        margin-bottom: 0;
    }
`

const SsrInBundleIconBox = styled.div`
    flex-basis: 28px;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.primary};
    display: flex;
    flex-shrink: 0;
`

const SsrInformationBox = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 8px;
`

const SsrDescriptionBox = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};;
    line-height: 1;
    padding-bottom: 4px;
`

export class SsrTypeBase implements ISsrType {
    constructor(public readonly ssrCode: string, protected services: IServiceFactory) {
    }

    get name(): string {
        return this.services.ssrTypes.translateSsr(this.ssrCode);
    }

    get isAvailable(): boolean {
        return true;
    }

    private get inBundleNameTranslationKey() {
        return this.ssrCode + '_IN_BUNDLE';
    }

    private get _hasInBundleSpecificName(): boolean {
        const translationKey = this.inBundleNameTranslationKey;
        const translation = this.services.ssrTypes.translateSsr(translationKey);
        return translation !== translationKey;
    }

    get inBundleName(): string {
        const translationKey = this.inBundleNameTranslationKey;
        const translation = this.services.ssrTypes.translateSsr(translationKey);
        if(translation === translationKey) { //it means there is no in bundle specific translation for this SSR so we fallback to the description
            return this.name;
        }

        return translation;
    }

    protected get detailsTranslationKey(): string {
        return this.ssrCode + '_DETAILS';
    }

    get details(): NullableString {
        const translationKey = this.detailsTranslationKey;
        const translation = this.services.ssrTypes.translateSsr(translationKey);
        if(translation === translationKey) {
            return null;
        }
        return translation;
    }

    protected get inBundleDetailsTranslationKey(): string {
        return this.ssrCode + '_IN_BUNDLE_DETAILS';
    }

    get inBundleDetails(): NullableString {
        const translationKey = this.inBundleDetailsTranslationKey;
        const translation = this.services.ssrTypes.translateSsr(translationKey);
        // If there is no in bundle specific details we fall back to the standard details but only if there is no in bundle specific name.
        // For example PBRD has a specific in bundle name but no specific in bundle details.
        // However the regular details of the PBRD are not appropriate for in bundle display of the PBRD.
        // So for PBRD translation === translationKey will be true but !this._hasInBundleSpecificName will be false so,
        // we will not enter into this if but we will enter the next one down below.
        if(translation === translationKey && !this._hasInBundleSpecificName) {
            return this.details;
        }

        // If the bundle name is the same as the in bundle details or there is no translation for the in bundle details there is no point to display anything
        // The first condition translation === this.inBundleName is more like a safety guard,
        // just in case somebody puts for the in bundle details the same text as the bundle name. It would be weird to display the same text twice, once for the name and once for the details.
        // In the PBRD example translation === this.inBundleName is false but translation === translationKey will be true.
        if(translation === this.inBundleName || translation === translationKey ) {
            return null;
        }

        return translation;
    }


    get smallIcon(): React.ReactElement | null {
        return null;
    }

    get largeIcon(): React.ReactElement | null {
        return null
    }

    get largeIconDisabled(): React.ReactElement | null {
        return this.largeIcon;
    }

    protected get _iconInExtrasSmallScreen(): React.ReactElement | null {
        return this.largeIcon;
    }

    protected get _iconInExtrasForLargeScreen(): React.ReactElement | null {
        return this.largeIcon;
    }

    get iconInExtras(): React.ReactElement | null {
        if(this.services.layout.isInSmallScreenView) {
            return this._iconInExtrasSmallScreen;
        } else {
            return this._iconInExtrasForLargeScreen;
        }
    }

    get shouldBlockOnlineCheckIn(): boolean {
        return false;
    }

    get shouldLockCommittedQuantity(): boolean {
        return true;
    }

    get preferredQuantityEditorStyle(): SsrQuantityEditorStyle {
        return SsrQuantityEditorStyle.plusMinusButtons;
    }

    getOnlineCheckInRestrictions(): ICheckInRestriction[] {
        if(this.shouldBlockOnlineCheckIn) {
            return [
                new SsrOnlineCheckInRestriction(this, this.services)
            ];
        }
        return [];
    }

    get shouldShowBundleIncludedInShoppingCart(): boolean {
        return true;
    }

    get shouldShowInShoppingCart(): boolean {
        return true;
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.OTHER;
    }

    isSeatRestricted(seat: ISeatViewModel): boolean {
        return false;
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return null;
    }

    getPurchaseBlockingMessage(journey: IJourneyViewModel): NullableString {
        const minTime = this._getPurchaseMinimumTimeBeforeFlight();

        if(!minTime) {
            return null;
        }

        if(journey.designator.departureDate.getTime() - this.services.time.currentDate.getTime() >= minTime.totalMilliseconds) {
            return null;
        }

        return this._getCannotPurchaseMessage(minTime);

    }


    protected _getCannotPurchaseMessage(minTime: TimeSpan): string {
        return this.services.language.translationFor('Cannot be purchased within less than {time} before flight')
            .withParams({
                time: minTime.toUserFriendlyString(this.services.language, {convertDaysToHoursIfLessThan: 3})
            });
    }


    canBeShownOnBookingFlow(booking: IBookingViewModel): boolean {
        return true;
    }

    canBeShownOnCheckInFlow(booking: IBookingViewModel): boolean {
        return true;
    }

    canBeShownOnManageMyBookingFlow(booking: IBookingViewModel): boolean {
        return true;
    }

    protected _renderIconInBundle() {
        return (
            <SsrInBundleIconBox>
                {this.smallIcon}
            </SsrInBundleIconBox>
        );
    }

    protected _renderNameInBundle(quantity: number) {
        return (
            <SsrDescriptionBox>
                {quantity > 1 ? `${quantity} x ${this.inBundleName}` : this.inBundleName}
            </SsrDescriptionBox>
        );
    }

    protected _renderDetailsInBundle() {
        return (
            <FootNoteComponent>
                {this.inBundleDetails}
            </FootNoteComponent>
        );
    }

    renderInBundle(quantity: number): React.ReactElement {
        return (
            <SsrTypeInBundleContainerBox key={this.ssrCode}>
                {this._renderIconInBundle()}
                <SsrInformationBox>
                    {this._renderNameInBundle(quantity)}
                    {this._renderDetailsInBundle()}
                </SsrInformationBox>
            </SsrTypeInBundleContainerBox>
        );
    }

    computeLimitPerPassengerQuantity(passengerSegmentSsr: PassengerSegmentSsrEditorModel): number {

        if(Check.isNullOrUndefined(this.sharedMaxQuantity)) {
            return passengerSegmentSsr.ssrAvailability.limitPerPassenger;
        }
        const relatedSsrsQuantity = this.getRelatedSsrsThatShareTheSameMaxQuantity().sum(ssr => passengerSegmentSsr.passengerSegment.getSsr(ssr).newQuantity);
        const minQuantityBasedOnOtherBags = Math.max(0, this.sharedMaxQuantity - relatedSsrsQuantity);
        return Math.min(minQuantityBasedOnOtherBags, passengerSegmentSsr.ssrAvailability.limitPerPassenger);
    }

    get shouldBeSoldPerSegment(): boolean {
        return false;
    }

    get shouldShowInFlightItineraryExtras(): boolean {
        return true;
    }

    getBookingNote(booking: IBookingViewModel): NullableString {
        return null;
    }

    get shouldWriteQuantityChangeStatusInSessionStorage(): boolean {
        return false;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.Other;
    }

    getRelatedSsrsThatShareTheSameMaxQuantity(): ISsrType[] {
        return [];
    }

    get sharedMaxQuantity(): NullableNumber {
        return null;
    }

    getCustomMessageForMaxLimitPerPassengerReached(): NullableString {
        return null;
    }

    get canBeRefunded(): boolean {
        return true;
    }

    get isSoldOnlyInBundle(): boolean {
        return false;
    }

    get aliasFor(): ISsrType | null {
        return null;
    }
}
