import { ISvgIconProps, SvgIcon } from "./svg-icon.component";


export const SadFaceIcon:React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 24 24" ratio={1}>
            <g fill={props.color? props.color: "currentColor"}>
            <path d="M20.49,3.511a12.006,12.006,0,1,0,0,16.977A12.019,12.019,0,0,0,20.49,3.511ZM19.18,19.18a10.154,10.154,0,1,1,0-14.359A10.165,10.165,0,0,1,19.18,19.18Zm-1.751-2.434a.679.679,0,0,1-1.246.539,4.246,4.246,0,0,0-3.942-2.492,4.211,4.211,0,0,0-3.965,2.49.679.679,0,1,1-1.254-.52,5.564,5.564,0,0,1,5.219-3.327A5.6,5.6,0,0,1,17.428,16.746ZM7.522,8.751a1.4,1.4,0,1,1,1.4,1.4A1.4,1.4,0,0,1,7.522,8.751Zm6.363,0a1.4,1.4,0,1,1,1.4,1.4A1.4,1.4,0,0,1,13.885,8.751Z" transform="translate(0 0)"/>
                </g>
        </SvgIcon>
    )
}