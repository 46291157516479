import React from 'react';
import {observer} from "mobx-react";
import styled from "styled-components";
import {SpecialPriceMarketUserOptionComponent} from "../../../../flight-search/special-price-market/components/special-price-market-user-option.component";
import {
    IFlightSearchControllerViewModel,
    SpecialPriceMarketUserOptions
} from "../../../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";

const UserOptionBox = styled(SpecialPriceMarketUserOptionComponent)`
    background-color: inherit;
    border: none;
    padding: 0;
    width: fit-content;
`

interface SpecialPriceMarketUserOptionsComponentProps {
    searchController: IFlightSearchControllerViewModel;
}
export const SearchMaskSpecialPriceMarketUserOptionsComponent: React.FC<SpecialPriceMarketUserOptionsComponentProps> = observer((props) => {

    const searchController = props.searchController;

    const specialPriceMarket = searchController.getSpecialPriceMarket();
    if(!specialPriceMarket) {
        return null;
    }

    const renderSeasonOption = () => {
        if(!specialPriceMarket.activeSeason) {
            return null;
        }

        return (
            <UserOptionBox optionValue={SpecialPriceMarketUserOptions.Season} searchController={searchController}/>
        );
    }

    return (
        <>
            <UserOptionBox optionValue={SpecialPriceMarketUserOptions.Regular} searchController={searchController}/>
            {renderSeasonOption()}
        </>
    )
})
