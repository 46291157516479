import {IServiceFactory} from "../../../service-factory.interface";
import {OnlineCheckInBlockerSsr} from "../online-check-in-blocker-ssr";
import {TimeSpan} from "../../../../types/time-span";

export default class ExstSsrType extends OnlineCheckInBlockerSsr {
    constructor(services: IServiceFactory) {
        super('EXST', services);
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(24);
    }
}
