import {SellJourneyMutation} from "./sell-journey.mutation";
import {
    IDotRezPartialBookingSessionData
} from "../../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";

export class SellReturnJourneyMutation extends SellJourneyMutation {

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {


        let journeysToCancel: string[] = [];
        let ssrsKeysToRemove: string[] = [];

        if(this.returnJourney) {
            journeysToCancel = [this.returnJourney.journeyKey];

            if(this.departureJourney) {

                if(0 < this.returnJourney.getAllSoldSsrKeysWithoutBundleIncluded().length) {
                    //The SSRs for the existing return journey will be removed because we cancel the journey in order to replace it with another one.
                    //But in case there are some SSRs for the return journey we need to remove also all SSRs for the departing journey
                    //otherwise all the screens where we have the "Same for all flights" toggle will remain in an inconsistent state.
                    ssrsKeysToRemove = this.departureJourney.getAllSoldSsrKeysWithoutBundleIncluded();
                }
            }
        }

        await this._sellTrip(journeysToCancel, ssrsKeysToRemove);

        return {};
    }

    protected async onAfterSell(bookingSessionData: IDotRezPartialBookingSessionData): Promise<void> {

        await super.onAfterSell(bookingSessionData);

        const availableBundles = this.booking.returnJourney?.bundlesAvailability ?? [];

        if(availableBundles.length === 1) {
            await this.booking.sellReturnJourneyBundle(availableBundles[0].bundleCode);
        } else {
            const selectedBundle = availableBundles.find(b => b.bundleCode === this.booking.departureJourney?.selectedBundle?.bundleCode);
            if(selectedBundle) {
                await this.booking.sellReturnJourneyBundle(selectedBundle.bundleCode);
            }
        }

    }
}
