import React from 'react';
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const PlaneDepartNoLineIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.4} {...props} sizeIsHeight viewBox="0 0 14.829 7.41" ratio={14.829/7.41}>
            <g fill={props.color? props.color: "currentColor"}><path d="M405.305,436.7s2.9,1.038,3.654.836l8.689-2.329a1.467,1.467,0,0,0,1.126-1.456,1.443,1.443,0,0,0-1.7-.7l-3.6.961-5.989-3.831a.247.247,0,0,0-.271.027l-.579.567a.189.189,0,0,0-.025.247l3.031,4.011-2.429.65-2.561-1.589a.213.213,0,0,0-.25.027l-.38.371a.21.21,0,0,0-.03.249A13.864,13.864,0,0,0,405.305,436.7Z" transform="translate(-403.963 -430.153)"/></g>
        </SvgIcon>
    )
}
