import React from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {IJourneyViewModel} from "../../../../services/booking/models/journey/journey-view-model.interface";
import {ManageMyBookingFlightsSelectionPageHeaderTextBox} from "../../common/flights-selection/boxes/manage-my-booking-flights-selection-page-header-text.box";
import {JourneySelectorComponent} from "../../common/components/journey-selector.component";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../../../../components/continue-button/continue-button.component";

const FlexUsageBlockingReasonBox = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.primaryShade};
    padding: 0 16px 12px;
    line-height: 1;
`

const JourneyToModifySelectorComponent: React.FC<{journey: IJourneyViewModel}> = observer((props) => {
    const services = useServices();
    const renderFlexUsageBlockingReason = () => {
        if(props.journey.initialJourneyDateChangeBlockingReason) {
            return null;
        }

        if(!props.journey.flexUsageBlockingReason) {
            return null;
        }

        return (
            <FlexUsageBlockingReasonBox>
                {props.journey.flexUsageBlockingReason}
            </FlexUsageBlockingReasonBox>
        )
    }

    const onSelectHandler = () => {
        props.journey.isJourneySelected = !props.journey.isJourneySelected;
    }

    return (
        <JourneySelectorComponent designator={props.journey.designator}
                                  canBeUnselected={!props.journey.isCanceled}
                                  onSelect={onSelectHandler}
                                  isSelected={() => props.journey.isJourneySelected}
                                  isJourneyCanceled={props.journey.isCanceled}
                                  changeBlockingReason={props.journey.initialJourneyDateChangeBlockingReason}
                                  changeBlockingReasonTitle={services.language.translate('Not eligible for flight change.')}>
            {renderFlexUsageBlockingReason()}
        </JourneySelectorComponent>
    )
});

const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large};
    
`

const DialogContentComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    const availableJourneysForDateChange = booking.manageMyBooking.availableJourneysForDateChange;

    const renderTitle = () => {
        if(booking.unfilteredJourneys.all(j => j.isCanceled)) {
            return null;
        }

        return (
            <ManageMyBookingFlightsSelectionPageHeaderTextBox>
                {services.language.translate('Select the flight(s) that you want to change')}
            </ManageMyBookingFlightsSelectionPageHeaderTextBox>
        );

    }
    return (
        <DialogContentBox>
            {renderTitle()}
            {
                availableJourneysForDateChange.map(j => (
                    <JourneyToModifySelectorComponent key={j.journeyKey} journey={j}/>
                ))
            }
        </DialogContentBox>
    );
})


export const ManageMyBookingFlightsToModifySelectionDialog: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

  
    const onContinueClick = async () => {
        if(!booking.manageMyBooking.hasSelectedJourneysForDateChange) {
            services.alert.showError(services.language.translate('Please select a journey to change'));
            return;
        }

        if(booking.insurance.hasPurchasedInsurance) {
            await services.dialogFactory.showSimpleMessageDialog({
                title: services.language.translate('Important information about Travel Insurance'),
                message: services.language.translate(`Your booking contains Travel Protection with COVID19 Cover. Please note that, if the dates of your trip change, your policy will not cover those days outside of the originally booked travel period`),
                buttonText: services.language.translate('I acknowledge')
            });
        }

        props.dialogHandler.accept();
    }
    
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Select flights')} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <DialogContentComponent/>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ContinueButtonComponent onClick={onContinueClick} fullWidth={true}/>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )

   
});
