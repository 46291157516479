import React, {useEffect, useRef} from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {Check} from "../../../types/type-checking";
import {StandardDropDownActionSheetComponent} from "./action-sheet-view/standard-drop-down-action-sheet.component";
import {StandardDropDownListModel} from "./list-view/models/list/standard-drop-down-list.model";
import {IDropDownController} from "../drop-down/models/drop-down-controller.interface";
import {StandardDropDownInputLargeScreenComponent} from "./list-view/components/input/standard-drop-down-input.large-screen.component";
import {StandardDropDownListComponent} from "./list-view/components/list/standard-drop-down-list.component";
import {DropDownComponent} from "../drop-down/drop-down.component";
import styled from "styled-components";
import {setElementFocus} from "../../../utils/set-element-focus";
import {SearchbarComponent} from "../../../basic-components/searchbar/searchbar.component";
import {FieldEditorCommonProps} from "../field-editor-common.props";
import {IFormField} from "../../../models/forms/form-field.interface";
import {IStandardDropDownItemViewModel} from "./list-view/models/item/standard-drop-down-list-item-view-model.interface";
import {FieldInputWrapperBox} from "../field-input-wrapper.box";
import {InputBaseBox} from "../../../basic-components/input/input-base.box";
import {InputContainerComponent} from "../input-container.component";
import {
    StandardDropDownInputSmallScreenComponent
} from "./list-view/components/input/standard-drop-down-input.small-screen.component";


interface DialogHeaderComponentProps<TFieldValue> {
    listModel: StandardDropDownListModel<TFieldValue>;
    hideSearchBar?: boolean;
}

const SearchBarBox = styled(SearchbarComponent)`
    margin: 0 ${props => props.theme.spacing.large} ${props => props.theme.spacing.medium} ${props => props.theme.spacing.large};
`

const DialogHeaderComponent = observer(<TFieldValue extends any>(props: DialogHeaderComponentProps<TFieldValue>) => {

    const captureInputElementRef = (el: HTMLInputElement | null) => {
        if(el) {
            setElementFocus(el);
        }
    }

    if(props.hideSearchBar) {
        return null;
    }

    return (
        <SearchBarBox captureInputElementRef={captureInputElementRef}
                      onChange={text => props.listModel.searchText = text ?? ""} />

    );
});

interface StandardDropDownModalDialogOptions {
    title?: string;
    hideSearchBar?: boolean;
}

interface StandardDropDownComponentProps<TFieldValue> extends FieldEditorCommonProps {
    field: IFormField<TFieldValue>;
    items: IStandardDropDownItemViewModel<TFieldValue>[];
    className?: string;
    placeholder?: string;
    icon?: React.ReactElement;
    noItemsMessage?: () => React.ReactElement | string;
    modalDialogOptions?: StandardDropDownModalDialogOptions;
}


export const StandardDropDownComponent = observer(<TFieldValue extends any>(props: StandardDropDownComponentProps<TFieldValue>) => {
    const services = useServices();
    let inputElementRef = useRef<HTMLElement | null>(null);
    let inputContainerElementRef = useRef<HTMLElement | null>(null);
    let listModelRef = useRef( new StandardDropDownListModel<TFieldValue>({
        field: props.field,
        services: services,
        initialItems: props.items,
        inputElementRef: inputElementRef,
        inputContainerElementRef: inputContainerElementRef
    }))

    const listModel = listModelRef.current;

    useEffect(() => {
        listModel.updateItems(props.items)
    }, [props.items, listModel]);


    if(props.field.isHidden) {
        return null;
    }

    const shouldUseActionSheet = () => {
        if(services.layout.isInSmallScreenView) {
            return props.items.length <= 5;
        }
        
        return false;
    }

    const captureInputContainerRef = (element: HTMLDivElement) => {
        props.field.attachContainerDOMElement(element);
        inputContainerElementRef.current = element;
    }

    const captureInputElementRef = (element: any) => {
        inputElementRef.current = element;
        props.field.attachInputDOMElement(element);
    }

    const renderLabel = () => {
        if(props.hideLabel) {
            return undefined;
        }
        return props.customLabel || props.field.fieldName;
    }


    const renderDropDownInput = (controller: IDropDownController) => {
        listModel.setDropDownController(controller);

        if(services.layout.isInSmallScreenView) {
            return (
                <StandardDropDownInputSmallScreenComponent captureInputRef={captureInputElementRef}
                                                           listViewModel={listModel}
                                                           placeHolder={props.placeholder}/>
            );
        }

        return (
            <StandardDropDownInputLargeScreenComponent captureInputRef={captureInputElementRef}
                                                       listViewModel={listModel}
                                                       placeHolder={props.placeholder}/>
        );
    }


    const renderDropDownContent = (controller: IDropDownController) => {
        listModel.setDropDownController(controller);
        if(shouldUseActionSheet()) {
            const ActionSheet = StandardDropDownActionSheetComponent<TFieldValue>;

            const label = renderLabel();
            return (
                <ActionSheet listViewModel={listModel} dropDownController={controller} label={Check.isString(label) ? label : undefined}/>
            );
        } else {
            const DropDownList = StandardDropDownListComponent<TFieldValue>;
            return (
                <DropDownList listViewModel={listModel}
                              renderEmptyListMessage={props.noItemsMessage}/>
            );
        }

    }

    const renderDialogHeader = () => {
        const Component = DialogHeaderComponent<TFieldValue>;
        return (
            <Component listModel={listModel} hideSearchBar={props.modalDialogOptions?.hideSearchBar}/>
        );
    }

    const renderReadonlyInput = (hasError: boolean) => {
        const selectedItemText = listModel.selectedItem?.getDisplayText() ?? "";
        return (
            <FieldInputWrapperBox hasError={hasError}>
                <InputBaseBox
                           autoComplete="off"
                           autoCorrect="off"
                           spellCheck={false}
                           ref={props.field.attachInputDOMElement}
                           placeholder={props.placeholder}
                           value={selectedItemText}
                           maxLength={props.field.maxLength}
                           readOnly={true}
                           className={props.className}/>
            </FieldInputWrapperBox>
        );
    }

    if(props.field.isReadOnly) {
        return (
            <InputContainerComponent renderInput={renderReadonlyInput}
                                     label={renderLabel()}
                                     errorMessage={props.field.error}
                                     captureElementRef={props.field.attachInputDOMElement}
                                     onClick={props.onClick}
                                     showBorders={props.showBorders}
                                     className={props.className}
                                     hideErrorText={props.hideErrorText}/>
        );
    }


    return (
        <DropDownComponent label={renderLabel()}
                           errorMessage={props.field.error}
                           hideErrorText={props.hideErrorText}
                           renderDropDownInput={renderDropDownInput}
                           renderDropDownContent={renderDropDownContent}
                           dropDownContentIsInActionSheet={shouldUseActionSheet()}
                           icon={props.icon}
                           captureInputContainerElementRef={captureInputContainerRef}
                           showBorders={props.showBorders}
                           modalDialogOptions={{
                               renderHeader: renderDialogHeader,
                               preferredTitle: props.modalDialogOptions?.title
                           }}/>
    )
});
