import {ServiceBase} from "../service-base";
import {ICarriersService} from "./carriers.service.interface";
import {ICarrierConfiguration} from "../configuration/objects/carrier-configuration.interface";
import {IServiceFactory} from "../service-factory.interface";
import {makeObservable, observable, reaction} from "mobx";
import {NullableUndefinedString} from "../../types/nullable-types";

export class CarriersService extends ServiceBase implements ICarriersService {
    constructor(services: IServiceFactory) {
        super(services);
        makeObservable(this, {
            _carriersMap: observable.ref
        });
        reaction(() => this.services.configuration.data,
            () => {
                this._carriersMap = (this.services.configuration.data.carriers ?? []).toDictionary(c => c.code.toUpperCase());
        }, {
            fireImmediately: true
        });
    }

    _carriersMap: Record<string, ICarrierConfiguration> = {};

    tryGetCarrier(carrierCode: NullableUndefinedString): ICarrierConfiguration | null {
        if(carrierCode) {
            return this._carriersMap[carrierCode.toUpperCase()] ?? null;
        }

        return null;
    }



}
