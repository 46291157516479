import {VoluntaryJourneyChangeMutation} from "./voluntary-journey-change.mutation";
import {IDotRezPartialBookingSessionData} from "../../../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {JourneyModel} from "../../../../journey/journey.model";
import {JourneySnapshotModel} from "../../../../snapshots/journey/journey-snapshot.model";

export class VoluntaryDepartureJourneyChangeMutation extends VoluntaryJourneyChangeMutation {

    protected get currentJourneyModel(): JourneyModel | null {
        return this.booking.unfilteredJourneys[0] || null;
    }

    protected get initialJourneySnapshot(): JourneySnapshotModel | null {
        return this.booking.initialBookingSnapshot.departureJourney;
    }


    protected async onAfterSell(bookingSessionData: IDotRezPartialBookingSessionData): Promise<void> {
        await super.onAfterSell(bookingSessionData);
        if(this.fareToSell.withBlueBenefits && this.booking.blueBenefits.shouldLockFirstPassengerOnBooking) {
            this.booking.passengers[0].markAsBlueBenefitsPassenger();
        }
    }



}
