import {ICheckInRestriction} from "./check-in-restriction.interface";
import {IServiceFactory} from "../../../service-factory.interface";

export class SpecialSsrsAndNotAllPassengersHaveSeatsRestriction implements ICheckInRestriction {
    constructor(private readonly services: IServiceFactory) {
    }

    get description(): string {
        return this.services.language.translate(`For this booking your are not allowed to access online check-in. Please report to the check-in desk 2 hours before your flight`);
    }
    get shouldBlockSavingTravelDocuments(): boolean {
        return false;
    }
}
