import {PaymentStepBase} from "../common/payment-step-base";
import {IBookingRoutes} from "../../../navigation/routes/booking/booking.routes.interface";

export class BookingFlowPaymentStep extends PaymentStepBase<IBookingRoutes> {


    protected get routes(): IBookingRoutes {
        return this.services.navigator.routes.booking;
    }

    get route() {
        return this.services.navigator.routes.booking.payment;
    }
}
