import {JourneyModel} from "../../journey/journey.model";
import {PassengerTypeShoppingCartModel} from "../passenger-type/passenger-type-shopping-cart.model";
import {PassengerJourneyShoppingCartModel} from "../passenger/passenger-journey-shopping-cart.model";
import {mergeFeesCollections} from "../shopping-cart.helpers";
import {JourneyInfantsShoppingCartModel} from "../infant/journey-infants-shopping-cart.model";
import {Price} from "../../../../currency/price";
import {IFeeModel} from "../../base-models/fees/fee-model.interface";
import {IJourneyShoppingCartViewModel} from "./journey-shopping-cart-view-model.interface";
import {computed, makeObservable} from "mobx";
import {FlightDesignatorModel} from "../../designator/flight-designator.model";
import {ShoppingCartModeEnum} from "../../../booking-strategies/booking-strategy.interface";
import {IPassengerJourneyShoppingCartModel} from "../passenger/passenger-journey-shopping-cart-model.interface";
import {IPassengerTypeShoppingCartModel} from "../passenger-type/passenger-type-shopping-cart-model.interface";
import {IPassengerAnalyticsData} from "../../analytics/google-analytics.intefaces";
import {Check} from "../../../../../types/type-checking";

export class JourneyShoppingCartModel implements IJourneyShoppingCartViewModel {
    constructor(private readonly _getJourney: () => JourneyModel,
                private readonly shoppingCartMode: ShoppingCartModeEnum) {
        makeObservable(this, {
            passengerTypesShoppingCarts: computed
        });
    }

    public get journey(): JourneyModel {
        return this._getJourney();
    }

    get journeyKey(): string {
        return this.journey.journeyKey;
    }

    get designator(): FlightDesignatorModel {
        return this.journey.designator;
    }

    get passengerJourneysShoppingCarts(): IPassengerJourneyShoppingCartModel[] {
        return this.journey.booking.passengers.map(passenger => new PassengerJourneyShoppingCartModel(passenger, this.journey, this.shoppingCartMode));
    }

    get passengerTypesShoppingCarts(): IPassengerTypeShoppingCartModel[] {
        const passengersShoppingCartsByPassengerType: Record<string, IPassengerJourneyShoppingCartModel[]> = {};
        for (let passengerJourneyShoppingCart of this.passengerJourneysShoppingCarts) {

            const key = passengerJourneyShoppingCart.passengerType.code
                        + '_'
                        + passengerJourneyShoppingCart.discountCode
                        + '_'
                        + passengerJourneyShoppingCart.initialFare?.toString()
                        + '_'
                        + passengerJourneyShoppingCart.currentFare.toString();
            if (!passengersShoppingCartsByPassengerType[key]) {
                passengersShoppingCartsByPassengerType[key] = [];
            }

            passengersShoppingCartsByPassengerType[key].push(passengerJourneyShoppingCart);
        }

        return Object.values(passengersShoppingCartsByPassengerType)
            .map(shoppingCarts => new PassengerTypeShoppingCartModel(this.journey, shoppingCarts))
            .sort((c1, c2) => {
                if (c1.passengerType.getMinimumAge() < c2.passengerType.getMinimumAge()) {
                    return 1;
                }
                return -1;
            });
    }

    get hasFareChangesOnCurrentSession(): boolean {
        return this.journey.hasChangedSegments || this.passengerJourneysShoppingCarts.some(pjsc => pjsc.hasFareChangeOnCurrentSession);
    }

    get seatsFees(): IFeeModel[] {
        return mergeFeesCollections(this.passengerJourneysShoppingCarts.map(p => p.seatFees));
    }

    get ssrsFees(): IFeeModel[] {
        return mergeFeesCollections(this.passengerJourneysShoppingCarts.map(p => p.ssrFees));
    }

    get otherFees(): IFeeModel[] {
        return mergeFeesCollections(this.passengerJourneysShoppingCarts.map(p => p.otherFees));
    }

    get journeyInfantsShoppingCartModel(): JourneyInfantsShoppingCartModel | null {
        if(this.journey.booking.passengers.some(p => p.infant)) {
            return new JourneyInfantsShoppingCartModel(this.journey, this.shoppingCartMode);
        }

        return null;
    }

    get faresTotalToDisplay(): Price {

        let totals: Price[] = [
            ...this.passengerTypesShoppingCarts.map(sc => sc.totalFareToDisplay),
        ];

        if(this.journeyInfantsShoppingCartModel) {
            totals.push(this.journeyInfantsShoppingCartModel.totalFareToDisplay)
        }

        return Price.sumAll(totals, this.journey.createPrice(0));
    }

    private get _allFees(): IFeeModel[] {
        return [
            ...this.seatsFees,
            ...this.ssrsFees,
            ...this.otherFees
        ];
    }

    get extrasTotalToDisplay(): Price {
        return Price.sumAll(this._allFees.map(f => f.totalToDisplay), this.journey.createPrice(0));
    }

    get grandTotalToDisplay(): Price {
        return this.faresTotalToDisplay.sum(this.extrasTotalToDisplay);
    }

    get shouldDisplayFares(): boolean {
        return this.hasFareChangesOnCurrentSession || this.shoppingCartMode === ShoppingCartModeEnum.ShowAllPurchases;
    }

    get shouldDisplayExtras(): boolean {
        return this._allFees.some(f => f.isPurchasedOnCurrentSession) || this.shoppingCartMode === ShoppingCartModeEnum.ShowAllPurchases;
    }

    get hasChangesOnCurrentSession(): boolean {
        return this.hasFareChangesOnCurrentSession
               || this._allFees.some(f => f.isPurchasedOnCurrentSession);
    }

    // Todo: refactor this
    get analyticsSsrFeesGroupedByAnalyticsName(): Record<string, IFeeModel[]>{

        const groupedData: Record<string, IFeeModel[][]> = {};

        this.passengerJourneysShoppingCarts.forEach(pjsc => {
            const feeAnalyticsData = pjsc.analyticsSsrFeesGroupedByAnalyticsName;
            if(Check.isNull(feeAnalyticsData)) return;
            // get everything, from all passengers grouped by analytics name
            for (const key in feeAnalyticsData) {
                if(!groupedData[key]){
                    groupedData[key] = [];
                }
                groupedData[key].push(feeAnalyticsData[key]);
            }
        });

        const result: Record<string, IFeeModel[]> = {}
        for(const key in groupedData) {
            result[key] = mergeFeesCollections(groupedData[key]);
        }
        return result;
    }

    getSsrsFeesForId(ids: string[]): IFeeModel[]{
        return mergeFeesCollections(this.passengerJourneysShoppingCarts.map(p => p.getSsrsFeesForId(ids)));
    }

    getPassengersAnalyticsData(): IPassengerAnalyticsData[] {
        let result: IPassengerAnalyticsData[] = [];
        if(this.journeyInfantsShoppingCartModel){
            result.push(this.journeyInfantsShoppingCartModel.getAnalyticsData())
        }

        this.passengerTypesShoppingCarts.forEach(p => {
                    result = [
                        ...result,
                        ...p.getAnalyticsData(),
                    ];
                });

        return result;
    }
}

