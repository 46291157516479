import {IFieldValidator} from "../field-validator.interface";
import {NullableString} from "../../../types/nullable-types";
import {IServiceFactory} from "../../../services/service-factory.interface";

const NAVITAIRE_PASSWORD_PATTERN = /^(?!.*[\.,~]).{8,32}$/; //Password must be at least 8 characters, no more than 32 characters. The password cannot contain a period(.) comma(,) or tilde(~).
// min 8 chars at least one letter at least one number
//const Min8Chars_AtLeastOneLetter_AtLeastOneNumber_Without3SpecialCharacter = /^(?!.*[\.,~])(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,32}$/;
//const Min8Chars_AtLeastOneLetter_AtLeastOneNumber = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
//const Min8Chars_AtLeastOneLetter_AtLeastOneNumber_AtLeastOneSpecialChar = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
//const Min8Chars_AtLeastOneUpperLetter_AtLeastOneLowerLetter_AtLeastOneNumber = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
//const Min8Chars_AtLeastOneUpperLetter_AtLeastOneLowerLetter_AtLeastOneNumber_AtLeastOneSpecialChar = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;


export class PasswordValidator implements IFieldValidator {
    constructor(private readonly services: IServiceFactory) {
    }



    validate(fieldValue: string): NullableString {
        if(!fieldValue) {
            return null;
        }

        if(NAVITAIRE_PASSWORD_PATTERN.test(fieldValue)) {
            return null;
        }

        return this.services.language.translate('Password must be at least 8 characters, no more than 32 characters. The password cannot contain a period(.) comma(,) or tilde(~)');
    }
}
