import React from 'react';
import {observer} from "mobx-react-lite";
import {ContinueButtonComponent} from "../../../../components/continue-button/continue-button.component";


interface BookingFlowContinueButtonSmallScreenComponentProps {
    onClick: () => void;
}

export const BookingFlowContinueButtonLargeScreenComponent: React.FC<BookingFlowContinueButtonSmallScreenComponentProps> = observer((props) => {
    return (
        <ContinueButtonComponent onClick={props.onClick} fullWidth={true}/>
    );
});
