import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {FormTitleComponent} from "./form-title.component";
import { SectionSeparator } from "./section-separator";

const FormContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    >div {
        &:last-of-type {
            ${SectionSeparator}{
                display: none;
            }
        }
    }
`

interface FormViewComponentProps extends PropsWithChildren {
    title?: string;
}

export const FormViewComponent: React.FC<FormViewComponentProps> = observer((props) => {
    return (
        <FormContainerBox>
            {props.title && <FormTitleComponent title={props.title}/>}
            {props.children}
        </FormContainerBox>
    )
});
