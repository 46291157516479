import React from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {SeatMapsComponent} from "./components/seat-maps.component";
import {BookingFlowPage} from "../booking-flow.page";

export const SeatSelectionPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowPage title={services.language.translate('Seat selection')}
                         renderContent={() => (<SeatMapsComponent headerBackgroundColor={services.theme.currentTheme.colors.primaryContrast}/>)}
                         showPromotionCode={true}>

        </BookingFlowPage>
    );
});
