import {Station} from "../../../stations/station.service.interface";
import {IDateSelectionStrategy} from "./date-selection-strategy.interface";
import {IFlightsScheduleViewModel} from "../../flights-schedule/flights-schedule-view-model.interface";
import {IFlightSearchControllerViewModel} from "../../flight-search-controller/flight-search-controller-view-model.interface";
import {ILowFareResult, IRoundTripLowFareReader} from "../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {NullableDate} from "../../../../types/nullable-types";
import {IServiceFactory} from "../../../service-factory.interface";

export abstract class DateSelectionStrategyBase implements IDateSelectionStrategy {
    constructor(protected readonly services: IServiceFactory,
                protected readonly flightSearchController: IFlightSearchControllerViewModel,
                private getCurrentStrategy: () => IDateSelectionStrategy,
                private setCurrentStrategy: (strategy: IDateSelectionStrategy) => void) {

    }

    protected abstract get flightSchedule(): IFlightsScheduleViewModel;
    abstract getCurrentDate(): NullableDate;
    abstract setCurrentDate(date: Date): void;
    abstract getLowFare(date: Date): ILowFareResult;

    protected get lowFareReader(): IRoundTripLowFareReader {
        return this.services.booking.getLowFareReader();
    }

    get origin(): Station {
        return this.flightSchedule.origin;
    }

    get destination(): Station {
        return this.flightSchedule.destination;
    }

    get uniqueKey(): string {
        return this.origin.stationCode + this.destination.stationCode;
    }

    get isCurrentStrategy(): boolean {
        return this.uniqueKey === this.getCurrentStrategy().uniqueKey;
    }

    setAsCurrentStrategy(): void {
        if(!this.isCurrentStrategy) {
            this.setCurrentStrategy(this);
        }
    }

    isDateAvailable(date: Date): boolean {
        return this.flightSchedule.isDateAvailable(date);
    }


}
