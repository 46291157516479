import {ExtrasSsrsSectionRenderer} from "../../base-renderers/extras-ssrs-section.renderer";
import styled from "styled-components";
import React from "react";
import {observer} from "mobx-react";
import {ISsrType} from "../../../../../services/ssr-types/ssr-types.service.interface";
import {CheckInBagSelectionComponent} from "../../../bags-selection/components/check-in-bag-selection.component";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {BaggageIcon} from "../../../../../assets/svg/baggage.icon";
import {
    IPassengerCustomSsrEditorRenderer,
    PassengerSsrsRenderingFlexDirection
} from "../../../common-components/ssrs/ssrs-selection.component.props";
import {IBookingSsrsAggregatorViewModel} from "../../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";

const CheckInBagsSmallScreenIconsBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: ${props => props.theme.spacing.large};
`

interface CheckInBagsSmallScreenIconsComponentProps {
    ssrsAggregator: IBookingSsrsAggregatorViewModel;
}

const CheckInBagsSmallScreenIconsComponent: React.FC<CheckInBagsSmallScreenIconsComponentProps> = observer((props) => {
    const bagsSsrs: Record<string, ISsrType> = {};
    for(let j of props.ssrsAggregator.journeysSsrsBuckets) {
        for(let ssr of j.getAllSsrsInTheBucket()) {
            for(let p of ssr.passengersSsrEditors) {
                if(p.shouldBeDisplayedInSsrSelection) {
                    bagsSsrs[ssr.ssrType.ssrCode] = ssr.ssrType;
                }
            }
        }
    }

    return (
        <CheckInBagsSmallScreenIconsBox>
            {Object.values(bagsSsrs).map(bagSsrType => {
                return (
                    <React.Fragment key={bagSsrType.ssrCode}>
                        {bagSsrType.iconInExtras}
                    </React.Fragment>
                )
            })}
        </CheckInBagsSmallScreenIconsBox>
    )
})

export class CheckInBagsExtrasSectionRenderer extends ExtrasSsrsSectionRenderer {

    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.getCheckInBagsThatCanBeSoldIndividually(), booking);
    }

    protected _getPassengerCustomSsrRenderer(): IPassengerCustomSsrEditorRenderer | undefined {
        return {
            flexDirection: PassengerSsrsRenderingFlexDirection.Row,
            render: (passengerSsrEditor) => (<CheckInBagSelectionComponent bagSsrEditor={passengerSsrEditor}/>)
        };
    }

    protected _renderLargeScreenIcon(): React.ReactElement | null {
        return (<BaggageIcon size={3}/>)
    }

    protected _renderSmallScreenIcon(): React.ReactElement | null {
        return (
            <CheckInBagsSmallScreenIconsComponent ssrsAggregator={this._ssrsAggregator}/>
        );
    }

    protected _renderSectionTitle(): string {
        return this.services.language.translate(`Check-in bags`);
    }


}
