import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PersonNoCircleIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.6} {...props} viewBox="0 0 12.434 12.434" ratio={12.434/12.434}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(0 0)"><path d="M176.355,1615.822c-.136-2.831-1.67-4.769-4.086-5.2a3.571,3.571,0,1,0-4.2,0c-2.416.426-3.95,2.365-4.086,5.2h-.03v.793h12.434v-.793Z" transform="translate(-163.951 -1604.181)"/></g>
        </SvgIcon>
    )
}
