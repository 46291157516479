import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {CompanionViewComponent} from "./companion-view.component";


export const CompanionsListComponent: React.FC = observer(() => {
    const services = useServices();
    const companions = services.user.profile.companions;
    return (
        <>
            {companions.map(c => <CompanionViewComponent key={c.customerNumber} companion={c}/>)}
        </>
    )
});
