import React from 'react';
import {ExtrasSectionRendererBase} from "../../base-renderers/extras-section-renderer.base";
import {ExtrasSectionLargeScreenComponentCommonProps} from "../../base-component/large-screen/extras-section.large-screen-component-common.props";
import {ExtrasSeatsLargeScreenComponent} from "./large-screen/extras-seats.large-screen.component";
import {ExtrasSeatsSmallScreenComponent} from "./small-screen/extras-seats.small-screen.component";
import { ISsrType } from '../../../../../services/ssr-types/ssr-types.service.interface';

export class SeatsExtrasSectionRenderer extends ExtrasSectionRendererBase {

    ssrTypes: ISsrType[] = [];

    shouldRender(): boolean {
        return true;
    }

    renderForLargeScreen(props: ExtrasSectionLargeScreenComponentCommonProps): React.ReactElement {
        return (
            <ExtrasSeatsLargeScreenComponent {...props}/>
        )
    }

    get useWideCellForSmallScreen(): boolean {
        return true;
    }

    renderForSmallScreen(): React.ReactElement {
        return (
            <ExtrasSeatsSmallScreenComponent/>
        )
    }




}
