import {
    IManageSeatsViewModel,
    IPassengerSegmentSeatViewModel,
    ISegmentSeatViewModel
} from "./manage-seats-view-model.interface";
import {BookingModel} from "../../booking.model";
import {SegmentModel} from "../../segment/segment.model";
import { IServiceFactory } from "../../../../service-factory.interface";

export class ManageSeatsModel implements IManageSeatsViewModel {
    constructor(private readonly booking: BookingModel) {
    }


    get segmentSeats(): ISegmentSeatViewModel[] {
        const result: ISegmentSeatViewModel[] = [];

        this.booking.filteredJourneys.forEach(j => {
            j.segments.forEach(s => result.push(this._createSegmentSeats(s)));
        });

        return result;
    }

    private _createSegmentSeats(segment: SegmentModel): ISegmentSeatViewModel {
        const passengersSeats: IPassengerSegmentSeatViewModel[] = [];

        segment.passengers.forEach(p => {
            if(p.assignedSeat) {
                passengersSeats.push({
                    passengerName: p.passengerFullName,
                    seatNumber: p.assignedSeat.seatNumber
                })
            }
        })
        return {
            designator: segment.designator,
            passengersSeats: passengersSeats
        };
    }
    protected get services(): IServiceFactory {
        return this.booking.services;
    }
    async edit(): Promise<void> {
        await this.services.dialogFactory.showSeatsSelection();
    }
}

