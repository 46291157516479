import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IBookingSsrsAggregatorViewModel} from "../../../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../../../components/designator/designator.component";
import {InfoMessageComponent} from "../../../../../../components/info-message/info-message.component";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {CardBox} from "../../../../../../basic-components/card/card.box";
import {computeExtrasLargeScreenSummaryGridColumns} from "./compute-extras-large-screen-summary-grid-columns";
import {SsrModificationBlockingReasonComponent} from "../../../../common-components/ssr-modification-blocking-reason.component";
import {IJourneySsrsBucketViewModel} from "../../../../../../services/booking/models/ssrs/journey-ssrs-bucket-view-model.interface";



const ExtrasSsrsSectionPurchaseSummaryLargeScreenBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
    min-height: 100px;
    gap: ${props => props.theme.spacing.large};
    
`

const GridBox = styled.div<{columnsCount: number}>`
  display: grid;
  grid-template-columns: repeat(${props => props.columnsCount}, 1fr);
  gap: ${props => props.theme.spacing.large};
  width: 100%;
  
`

const JourneySsrCountBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
    gap: ${props => props.theme.spacing.large};
`

const SsrCountBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
`

const DesignatorWrapper = styled(DesignatorComponent)`
    justify-content: flex-start;
`

const NoPurchaseInCategoryBox = styled(InfoMessageComponent)`
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.fontSize.large};
  width: 100%;
  height: 100%;
`

const NoPurchaseOnFlightBox = styled(NoPurchaseInCategoryBox)`
  justify-content: flex-start;
`


const JourneySsrCountComponent: React.FC<{journeySsrsBucket: IJourneySsrsBucketViewModel}> = observer((props) => {
    const services = useServices();

    const purchasedSsrs = props.journeySsrsBucket.getAllSsrsInTheBucket().filter(ssr => ssr.totalQuantity > 0);


    const renderPurchasedSsrsCount = () => {
        if(purchasedSsrs.length > 0) {
            return (
                <>
                    {purchasedSsrs.map(ssr => (<SsrCountBox key={ssr.ssrType.ssrCode}>{`${ssr.totalQuantity} x ${ssr.ssrType.name}`}</SsrCountBox>))}
                    <SsrModificationBlockingReasonComponent reason={props.journeySsrsBucket.modificationBlockingReason}/>
                </>
            )

        } else {
            if(props.journeySsrsBucket.modificationBlockingReason) {
                return (
                    <SsrModificationBlockingReasonComponent reason={props.journeySsrsBucket.modificationBlockingReason}/>
                );
            } else {
                return (<NoPurchaseOnFlightBox>
                    {services.language.translate('No purchases in this category for this flight')}
                </NoPurchaseOnFlightBox>)
            }

        }

    }

    return (
        <JourneySsrCountBox>
            <DesignatorWrapper displayMode={DesignatorDisplayMode.stationsNamesOnly} designator={props.journeySsrsBucket.parentJourney.designator}/>
            {renderPurchasedSsrsCount()}
        </JourneySsrCountBox>
    )
})



export const ExtrasSsrsSectionPurchaseSummaryLargeScreenComponent: React.FC<{ssrsAggregator: IBookingSsrsAggregatorViewModel}> = observer((props) => {
    const services = useServices();

    const hasAnyPurchase = props.ssrsAggregator.journeysSsrsBuckets.some(b => b.getAllSsrsInTheBucket().some(ssr => ssr.totalQuantity > 0));

    const renderPurchases = () => {
        if(!hasAnyPurchase) {
            return null;
        }

        let gridColumns = 1;
        if(hasAnyPurchase) {
            gridColumns = Math.max(1, computeExtrasLargeScreenSummaryGridColumns(services, props.ssrsAggregator.journeysSsrsBuckets.length));
        }

        return (
            <GridBox columnsCount={gridColumns}>
                {props.ssrsAggregator.journeysSsrsBuckets.map(journeyBucket => <JourneySsrCountComponent key={journeyBucket.uniqueKey} journeySsrsBucket={journeyBucket}/>)}
            </GridBox>
        );
    }

    const renderMessage = () => {
        /*
        if(hasAnyPurchase) {
            return (
                <SsrModificationBlockingReasonComponent reason={props.ssrsAggregator.modificationBlockingReason}/>
            );
        }
         */
        if(!hasAnyPurchase && props.ssrsAggregator.canBeModified) {
            return (
                <NoPurchaseInCategoryBox>{services.language.translate('No purchases in this category')}</NoPurchaseInCategoryBox>
            );
        }
    }



    return (
        <ExtrasSsrsSectionPurchaseSummaryLargeScreenBox>
            {renderPurchases()}
            {renderMessage()}
        </ExtrasSsrsSectionPurchaseSummaryLargeScreenBox>
    )
});
