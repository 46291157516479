import {BlueBenefitsSubscriptionStatus, IBlueBenefitsSubscription} from "./blue-benefits-subscription.interface";
import {IBlueBenefitDiscountInfo, IBlueBenefitsPackage} from "../packs/blue-benefits-package.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {Price} from "../../../currency/price";
import {BLUE_BENEFITS_BOOKING_SUBSCRIPTION_COMMENT_HEADER} from "../../blue-benefits.service.interface";

export abstract class BlueBenefitsSubscriptionBase implements IBlueBenefitsSubscription {
    constructor(private readonly _package: IBlueBenefitsPackage,
                public readonly expirationDate: Date,
                private readonly services: IServiceFactory) {

    }

    abstract shouldLockFirstPassengerOnBooking(): boolean;

    get packageName(): string {
        return this._package.name;
    }
    get isValidSubscription(): boolean {
        return this.subscriptionStatus === BlueBenefitsSubscriptionStatus.Valid && this.services.blueBenefits.isEnabled;
    }

    get maxNumberOfPassengers(): number {
        if(this.isValidSubscription) {
            return this._package.maxNumberOfPassengers;
        }

        return this.services.configuration.data.maxAllowedPassengersNumberOnBooking;
    }

    get subscriptionStatus(): BlueBenefitsSubscriptionStatus {
        if(this.expirationDate.getTime() >= this.services.time.currentDate.getTime()) {
            return BlueBenefitsSubscriptionStatus.Valid;
        }

        return BlueBenefitsSubscriptionStatus.Expired;
    }

    get partnersCountDescription(): string {
        return this._package.partnersCountDescription;
    }

    get isExpired() {
        return this.subscriptionStatus === BlueBenefitsSubscriptionStatus.Expired
    }

    getBenefits(): IBlueBenefitDiscountInfo[] {
        return this._package.getDiscountsInfo();
    }
    computeSsrStandardPrice(ssrType: ISsrType, discountedPrice: Price): Price {
        return this._package.computeSsrStandardPrice(ssrType, discountedPrice);
    }

    getFormattedExpirationDate(): string {
        return this.services.time.formatUserFriendlyDate(this.expirationDate);
    }

    createBookingCommentText(): string {
        return `${BLUE_BENEFITS_BOOKING_SUBSCRIPTION_COMMENT_HEADER}|${this._package.packageCode}|${this.services.time.formatUtc(this.expirationDate)}|${this.services.user.profile.customerNumber}`;
    }
}
