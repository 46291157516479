import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../modal/footer/standard-dialog-footer.component";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";


const BodyTextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px 0;
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1.2;
    height: 100%;
    
`

const ParagraphBox = styled.div`
    text-align: justify;
    margin-bottom: 10px;
`

interface InvalidPromoCodeDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}

export const InvalidPromoCodeDialogComponent: React.FC<InvalidPromoCodeDialogComponentProps> = observer((props) => {
    const services = useServices();

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler} title={services.language.translate('OOOPS!')}/>
            <StandardDialogContentComponent>
                <BodyTextBox>
                    <ParagraphBox>
                        {services.language.translate(`Unfortunately this promo code is not valid. Click on 'Continue without promo' to  continue the booking flow with the full price.`)}
                    </ParagraphBox>
                    <ParagraphBox>
                        {services.language.translate(`Use the back button instead to try another promotional code.`)}
                    </ParagraphBox>
                </BodyTextBox>

            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={() => props.dialogHandler.accept()}>
                    {services.language.translate('Continue without promo')}
                </PrimaryButton>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
