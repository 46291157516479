import styled from "styled-components";
import {IonHeader, IonToolbar} from "@ionic/react";
import React from "react";
import {observer} from "mobx-react";
import {PageBackButtonComponent} from "../page-back-button/page-back-button.component";
import {PageLogoButtonSmallScreenComponent} from "../page-logo-button/page-logo-button.small-screen.component";
import {HeaderTopSectionContentBox} from "../../header/top-section-content/header-top-section-content.box";
import {HeaderTitleContainerBox} from "../../header/title/header-title-container.box";
import {StandardPageHeaderComponentProps} from "./standard-page-header.component.props";
import {TestModeMenuComponent} from "../../test-mode/test-mode-menu.component";
import {ZIndex} from "../../../types/z-index";

const HeaderContainerBox = styled(IonHeader)`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    z-index: ${ZIndex.MainHeader};
`

export const TopSectionBox = styled(IonToolbar)`
  --border-style: none;
  --padding-top: ${props => props.theme.spacing.pageDefaultPaddingPx};
  --padding-bottom: ${props => props.theme.spacing.pageDefaultPaddingPx};
  --padding-start: ${props => props.theme.spacing.pageDefaultPaddingPx};
  --padding-end: ${props => props.theme.spacing.pageDefaultPaddingPx};
`

const BottomSectionBox = styled.div`
    width: 100%;
`


export const StandardPageHeaderSmallScreenComponent: React.FC<StandardPageHeaderComponentProps> = observer((props) => {
    const hideBackButton = Boolean(props.hideBackButton);
    const hideLogoButton = Boolean(props.hideLogoButton);

    //If back button or logo button should be displayed then we render both in order for the title to be centered

    const renderBackButton = () => {
        if(hideBackButton && hideLogoButton) {
            return null;
        }

        return (<PageBackButtonComponent isHidden={hideBackButton}
                                         onClick={props.onBackButtonClick}
                                         useCloseButtonInsteadOfBackButton={props.useCloseButtonInsteadOfBackButton}/>);
    }

    const renderLogoButton = () => {
        if(hideBackButton && hideLogoButton) {
            return null;
        }

        return (<PageLogoButtonSmallScreenComponent isHidden={hideLogoButton} onClick={props.onLogoButtonClick}/>);
    }

    const renderTopSectionContent = () => {
        if(hideBackButton && hideLogoButton && !props.title) {
            return null;
        }
        return (
            <HeaderTopSectionContentBox>
                {renderBackButton()}
                <HeaderTitleContainerBox>
                    {props.title}
                </HeaderTitleContainerBox>
                {renderLogoButton()}
                <TestModeMenuComponent/>
            </HeaderTopSectionContentBox>
        );
    }

    return (
        <HeaderContainerBox>
            <TopSectionBox>
                {renderTopSectionContent()}
            </TopSectionBox>
            <BottomSectionBox>
                {props.children}
            </BottomSectionBox>
        </HeaderContainerBox>
    )
});
