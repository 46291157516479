import React from "react";
import {observer} from "mobx-react";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";


interface EditButtonComponentProps {
    text: string;
    onClick: () => void;
}

export const EditButtonComponent: React.FC<EditButtonComponentProps> = observer((props) => {
    return (
        <PrimaryButton onClick={props.onClick}>
            {props.text}
        </PrimaryButton>
    )

});
