import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IPassengerTypeSelector} from "../../../../../../../services/passenger-types/passengers-types.service.interface";
import {MinusCircleIcon} from "../../../../../../../assets/svg/minus-circle.icon";
import {PlusCircleIcon} from "../../../../../../../assets/svg/plus-circle.icon";
import {useServices} from "../../../../../../../hooks/use-services.hook";

const PassengerTypeSelectorBox = styled.div<{isMobileView: boolean}>`
    padding: ${props => props.theme.spacing.small} 0;
    border-bottom: 1px solid ${props => props.theme.border.mainColor};
    &:hover {
      background-color: ${props => props.theme.colors.light};
    }
`

const PassengerTypeRowBox = styled.div<{isMobileView: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PassengerTypeInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1;
    flex-grow: 1;
    
`

const PassengerTypeNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    width: 100%;
`

const PassengerTypeAgeLimitsBox = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.mediumShade};
    width: 100%;
`

const PassengerTypeCountBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0 ${props => props.theme.spacing.large} 0 ${props => props.theme.spacing.large}; 
`

const ButtonBox = styled.div<{isEnabled: boolean}>`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    border-radius: 50%;
    cursor: ${props => props.isEnabled ? 'pointer' : 'not-allowed'};
    opacity: ${props => props.isEnabled ? '1' : '0.5'};
    
`

interface PasserTypeCountModifyButtonComponentProps extends PropsWithChildren {
    onClick: () => void;
    isEnabled: boolean;
}

const PasserTypeCountModifyButtonComponent: React.FC<PasserTypeCountModifyButtonComponentProps> = observer((props) => {
    const onClickHandler = () => {
        if(props.isEnabled) {
            props.onClick();
        }
    }
    return (
        <ButtonBox onClick={onClickHandler} isEnabled={props.isEnabled}>
            {props.children}
        </ButtonBox>
    )
});

interface PassengerTypeSelectorComponentProps {
    passengerTypeSelector: IPassengerTypeSelector;
}

export const PassengerTypeDropDownComponent: React.FC<PassengerTypeSelectorComponentProps> = observer((props) => {
    const services = useServices();
    const increment = () => {
        props.passengerTypeSelector.count++;
    }

    const decrement = () => {
        props.passengerTypeSelector.count--;
    }

    return (
        <PassengerTypeSelectorBox isMobileView={services.layout.isInSmallScreenView}>
            <PassengerTypeRowBox isMobileView={services.layout.isInSmallScreenView}>
                <PassengerTypeInfoBox>
                    <PassengerTypeNameBox>
                        {props.passengerTypeSelector.description}
                    </PassengerTypeNameBox>
                    <PassengerTypeAgeLimitsBox>
                        {props.passengerTypeSelector.ageLimitsDescription}
                    </PassengerTypeAgeLimitsBox>
                </PassengerTypeInfoBox>

                <PasserTypeCountModifyButtonComponent isEnabled={props.passengerTypeSelector.allowDecrement}
                                                      onClick={decrement}>
                    <MinusCircleIcon size={3}/>
                </PasserTypeCountModifyButtonComponent>

                <PassengerTypeCountBox>
                    {props.passengerTypeSelector.count}
                </PassengerTypeCountBox>

                <PasserTypeCountModifyButtonComponent isEnabled={props.passengerTypeSelector.allowIncrement}
                                                      onClick={increment}>
                    <PlusCircleIcon size={3}/>
                </PasserTypeCountModifyButtonComponent>
            </PassengerTypeRowBox>

        </PassengerTypeSelectorBox>
    )
});
