import React, {PropsWithChildren} from "react";
import {IStandardDialogRenderer} from "../../services/dialog/dialog.service.interface";
import {observer} from "mobx-react";
import {IonModal} from "@ionic/react";
import styled, {css} from "styled-components";


const IonModalWrapper = styled(IonModal)<{forceFullScreen: boolean}>`
  
  @media ${(props) => props.theme.mediaQuery.sAndBelow} {
    --height: 100%;
    --width: 100%;
  }
  
  ${
    props => props.forceFullScreen 
            ? css`
              --height: 100%;
              --width: 100%;
            `
            : ''
  }

  @media ${(props) => props.theme.mediaQuery.sAndAbove} {
    --border-style: solid;
    --border-width: 1px;
    --border-color: ${props => props.theme.colors.medium};
    --border-radius: ${props => props.theme.border.defaultRadius};
  }

  --backdrop-opacity: 0.7;
  &::part(backdrop) {
    background-color: ${props => props.theme.colors.medium};
  }
  
`


interface StandardModalDialogComponentProps extends PropsWithChildren {
    renderer: IStandardDialogRenderer;
}

export const StandardDialogComponent: React.FC<StandardModalDialogComponentProps> = observer((props) => {

    const captureElementRef = (element: any) => {
        props.renderer.attachElementRef(element)
    }

    return (
        <IonModalWrapper key={props.renderer.id}
                         forceFullScreen={props.renderer.forceFullScreenOnLargeScreens}
                         id={props.renderer.id}
                         ref={captureElementRef}
                         isOpen={props.renderer.isOpen}
                         keyboardClose={false}
                         backdropDismiss={false}
                         showBackdrop={false}
                         onDidPresent={() => props.renderer.onDidPresent()}>
            {props.children}
        </IonModalWrapper>
    )
});
