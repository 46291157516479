import React, {CSSProperties} from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {AztecBarcodeComponent} from "../../../../components/aztec-barcode/aztec-barcode.component";

export const BoardingPassBarcodeComponent: React.FC<{barcode: string}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        textAlign: 'center',
        padding: `${theme.spacing.large} 0`,
        backgroundColor: theme.colors.primaryContrast,
        borderRadius: services.theme.currentTheme.border.defaultRadius,
        marginBottom: theme.spacing.small
    };

    return (
        <div style={styles}>
            <AztecBarcodeComponent barcode={props.barcode}/>
        </div>
    )
}

