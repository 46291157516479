import {DotRezGraphQLMutationBuilder} from "../dot-rez-query-builder";
import {IDotRezRemoveSeatRequest} from "../../data-contracts/requests/booking/remove-seat.request";


export function removeSeatsGraphqlMutation(seatsToRemove: IDotRezRemoveSeatRequest[], queryBuilder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    for(let i = 0; i < seatsToRemove.length; i++) {
        const passengerKeyVarName = `passengerKey${i}`;
        const seatKeyVarName = `seatKey${i}`;

        queryBuilder.addMutation(`
           mutation deleteSeat${i}($${passengerKeyVarName}: String 
                                   $${seatKeyVarName}: String) {
                seatDelete(passengerKey: $${passengerKeyVarName}
                           unitKey: $${seatKeyVarName})
                }
        `, {
            name: passengerKeyVarName,
            value: seatsToRemove[i].passengerKey
        }, {
            name: seatKeyVarName,
            value: seatsToRemove[i].seatKey
        });
    }

    return queryBuilder;
}
