import styled from "styled-components";
import {IonSegment} from "@ionic/react";
import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react-lite";
import {NullableUndefinedNumber, NullableUndefinedString} from "../../../types/nullable-types";

const PrimarySegmentBox = styled(IonSegment)`
  --background: ${props => props.theme.colors.medium};
`

interface PrimarySegmentComponentProps extends PropsWithChildren {
    className?: string;
    value?: NullableUndefinedString;
    onChange?: (value: NullableUndefinedString | NullableUndefinedNumber) => void;
}
export const PrimarySegmentComponent: React.FC<PrimarySegmentComponentProps> = observer((props) => {
    return (
        <PrimarySegmentBox value={props.value ?? undefined}
                           className={props.className}
                           mode="ios"
                           onIonChange={(e) => props.onChange && props.onChange(e.detail.value)}>
            {props.children}
        </PrimarySegmentBox>
    )
});


