import React from "react";
import {
    IBundleUpgradeBenefit
} from "../../../../../services/bundle-types/bundle-types.service.interface";
import {IDialogConfirmationHandler} from "../../../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {IBundleType} from "../../../../../services/bundle-types/bundle-type.interface";
import {CheckedIcon} from "../../../../../assets/svg/checked.icon";
import {NotCheckedIcon} from "../../../../../assets/svg/not-checked.icon";
import styled from "styled-components";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {StandardDialogPageComponent} from "../../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogFooterComponent} from "../../../../../components/modal/footer/standard-dialog-footer.component";
import {StandardDialogContentComponent} from "../../../../../components/modal/content/standard-dialog-content.component";
import {PrimaryButton} from "../../../../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../../../../basic-components/buttons/primary-button-inverted";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";

const MessagesBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    text-align: justify;
    line-height: 1;
`

const GridBox = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    width: 100%;
    line-height: 1;
    font-size: ${props => props.theme.fontSize.large};
    gap: ${props => props.theme.spacing.large};
`

const ColumnBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ColumnHeaderBox = styled(ColumnBox)`
    font-weight: ${props => props.theme.fontWeight.bold};
    text-transform: uppercase;
`

const BenefitsColumnHeaderBox = styled(ColumnHeaderBox)`
  align-items: flex-start;
`


const BenefitIconBox = styled(ColumnBox)`
    width: 20px;
    height: 20px;
`

const BenefitDetailsColumnBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    
`

const BenefitDescriptionBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
`

const BenefitMoreInfoBox = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.medium};
`


const HeaderRowComponent: React.FC<{bundleFrom: IBundleType; bundleTo: IBundleType}> = observer((props) => {
    const services = useServices();
    return (
        <>
            <BenefitIconBox/>
            <BenefitsColumnHeaderBox>
                {services.language.translate('Benefits')}
            </BenefitsColumnHeaderBox>

            <ColumnHeaderBox>
                {props.bundleFrom.shortName}
            </ColumnHeaderBox>

            <ColumnHeaderBox>
                {props.bundleTo.shortName}
            </ColumnHeaderBox>
        </>
    )
})

const BenefitDetailsComponent: React.FC<{bundleUpgradeBenefit: IBundleUpgradeBenefit}> = observer((props) => {
    return (
        <BenefitDetailsColumnBox>
            <BenefitDescriptionBox>
                {props.bundleUpgradeBenefit.description}
            </BenefitDescriptionBox>
            <BenefitMoreInfoBox>
                {props.bundleUpgradeBenefit.details}
            </BenefitMoreInfoBox>
        </BenefitDetailsColumnBox>
    )
});


const RowComponent: React.FC<{bundleUpgradeBenefit: IBundleUpgradeBenefit}> = observer((props) => {

    const fromIcon = (props.bundleUpgradeBenefit.from
        ? <CheckedIcon />
        : <NotCheckedIcon />);

    const toIcon = (props.bundleUpgradeBenefit.to
        ? <CheckedIcon />
        : <NotCheckedIcon />);


    return (
        <>
            <BenefitIconBox>
                {props.bundleUpgradeBenefit.icon}
            </BenefitIconBox>
            <BenefitDetailsComponent bundleUpgradeBenefit={props.bundleUpgradeBenefit}/>
            <ColumnBox>{fromIcon}</ColumnBox>
            <ColumnBox>{toIcon}</ColumnBox>
        </>
    );
})


export interface BundleUpgradeSuggestionComponentProps {
    upgradeFrom: IBundleViewModel;
    upgradeTo: IBundleViewModel;
    benefits: IBundleUpgradeBenefit[]
    dialogHandler: IDialogConfirmationHandler;
}

export const BundleUpgradeSuggestionDialogComponent: React.FC<BundleUpgradeSuggestionComponentProps> = observer((props) => {
    const services = useServices();
    const upgradeFromBundle = props.upgradeFrom;
    const upgradeToBundle = props.upgradeTo;
    const priceDifference = upgradeToBundle.pricePerPassenger.subtract(upgradeFromBundle.pricePerPassenger);

    const dialogTitle = services.language.translationFor('{bundle} is ideal for your trip!')
                                         .withParams({
                                             bundle: upgradeToBundle.bundleType.fullName
                                         });

    return (        
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={dialogTitle}
                                           dialogHandler={props.dialogHandler}/>

            <StandardDialogContentComponent>
                <VerticalSpacer/>
                <MessagesBox>
                    {upgradeFromBundle.bundleType.getUpgradeMessage(upgradeToBundle.bundleType)}
                </MessagesBox>
                <VerticalSpacer size={"extraLarge"}/>
                <GridBox>
                    <HeaderRowComponent key="header_row" bundleFrom={upgradeFromBundle.bundleType} bundleTo={upgradeToBundle.bundleType}/>
                    {props.benefits.map(benefit => <RowComponent key={benefit.description} bundleUpgradeBenefit={benefit}/>)}
                </GridBox>
                <VerticalSpacer/>
                <MessagesBox>
                    {services.language.translationFor('Upgrade to {bundle} for only {price} more per person!').withParams({
                        bundle: upgradeToBundle.bundleType.fullName,
                        price: priceDifference.toString()
                    })}
                </MessagesBox>
            </StandardDialogContentComponent>

            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton className={'tst-upgrade-to-bundle'} onClick={() => props.dialogHandler.accept(upgradeToBundle)}>
                    {services.language.translationFor('Upgrade to {bundle}').withParams({bundle: upgradeToBundle.bundleType.fullName})}
                </PrimaryButton>
                <VerticalSpacer/>
                <PrimaryButtonInverted className={'tst-continue-with-bundle'} onClick={() => props.dialogHandler.accept(upgradeFromBundle)}>
                    {services.language.translationFor('Continue with {bundle}').withParams({bundle: upgradeFromBundle.bundleType.fullName})}
                </PrimaryButtonInverted>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
