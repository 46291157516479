import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../dialog-factory/yes-no-dialog.enums";
import {SpecialPriceMarketUserOptionWithSeasonDialogComponent} from "../../../pages/flight-search/special-price-market/components/special-price-market-user-option-with-season-dialog.component";
import {FlightSearchStepBase} from "./flight-search-step.base";
import {
    IFlightSearchControllerViewModel,
    SpecialPriceMarketUserOptions
} from "../flight-search-controller/flight-search-controller-view-model.interface";

export class DestinationStationSelectionStep extends FlightSearchStepBase {

    get title(): string {
        return this.services.language.translate('Destination');
    }

    async next(): Promise<void> {
        const searchController = this.services.flightSearch.searchController
        const specialPriceMarket = searchController.getSpecialPriceMarket();
        if(specialPriceMarket) {
           await this._showSpecialPriceConfirmationDialog(searchController);
        } else {
            this._activateStepByRoute(this.routes.flightDates)
        }

    }

    private async _showSpecialPriceConfirmationDialog(searchController: IFlightSearchControllerViewModel): Promise<void> {
        const specialPriceMarket = searchController.getSpecialPriceMarket();
        if(!specialPriceMarket) {
            return;
        }


        if(specialPriceMarket.activeSeason) {
            await this.services.dialog.showPopupDialog<SpecialPriceMarketUserOptions>({
                render: dialogHandler => (<SpecialPriceMarketUserOptionWithSeasonDialogComponent dialogHandler={dialogHandler} searchController={searchController}/>),
                onAccept: async (userSelection) => {
                    if(userSelection === SpecialPriceMarketUserOptions.None) {
                        this._activateStepByRoute(this.routes.flightDates);
                    } else {
                        this._activateStepByRoute(this.routes.specialPriceMarketUserAgreement);
                    }
                }
            })
        } else {
            const dialogResult = await this.services.dialogFactory.showYesNoDialog({
                title: this.services.language.translate('Resident and equivalent'),
                message: this.services.language.translationFor('Are you a resident in {market}?').withParams({market: specialPriceMarket.getMarketNameTranslation()}),
                primaryButton: YesNoDialogPrimaryButton.PrimaryButtonYes
            });

            if(dialogResult === YesNoDialogResult.Yes) {
                searchController.specialPriceMarketUserOption = SpecialPriceMarketUserOptions.Regular;
                this._activateStepByRoute(this.routes.specialPriceMarketUserAgreement);
            } else {
                searchController.specialPriceMarketUserOption = SpecialPriceMarketUserOptions.None;
                this._activateStepByRoute(this.routes.flightDates);
            }
        }

    }

    get route() {
        return this.routes.destinationStation;
    }


}
