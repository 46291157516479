import React, {useRef} from 'react';
import {DropDownInputContainerComponent} from "./components/input-container/drop-down-input-container.component";
import {useServices} from "../../../hooks/use-services.hook";
import {IDropDownController} from "./models/drop-down-controller.interface";
import {NullableString} from "../../../types/nullable-types";
import {observer} from "mobx-react";
import {IDropDownDialogOptions} from "./components/dialog/drop-down-dialog-options";
import {DropDownWithPopperComponent} from "./components/popper/drop-down-with-popper.component";
import {DropDownWithDialogComponent} from "./components/dialog/drop-down-with-dialog.component";
import {IDropDownPopperOptions} from "./components/popper/drop-down-popper-options";
import {DropDownControllerModel} from "./models/drop-down-controller.model";


interface DropDownComponentProps {
    renderDropDownInput: (controller: IDropDownController) => React.ReactElement;
    renderDropDownContent: (controller: IDropDownController) => React.ReactElement;
    dropDownContentIsInActionSheet?: boolean;
    label?: string | React.ReactElement;
    errorMessage: NullableString;
    hideErrorText?: boolean;
    icon?: React.ReactElement;
    captureInputContainerElementRef?: (element: HTMLDivElement) => void;
    modalDialogOptions?: IDropDownDialogOptions;
    popperOptions?: IDropDownPopperOptions;
    showBorders?: boolean;
}



export const DropDownComponent: React.FC<DropDownComponentProps> = observer((props) => {
    const services = useServices();

    const dropDownInputElementRef = useRef<HTMLDivElement | null>(null)

    const dropDownControllerRef = useRef(new DropDownControllerModel(services))


    const controller = dropDownControllerRef.current;


    const captureDropDownInputElementRef = (element: HTMLDivElement) => {
        dropDownInputElementRef.current = element;
    }

    const captureDropDownInputContainerElementRef = (element: HTMLDivElement) => {
        if(props.captureInputContainerElementRef) {
            props.captureInputContainerElementRef(element);
        }
    }

    const onInputClickHandler = () => {
        if(controller.isOpen) {
            controller.closeDropDown();
        } else {
            controller.openDropDown();
        }
    }


    const renderDropDownContent = () => {
        return props.renderDropDownContent(controller);
    }

    const renderDropDown = () => {
        if(!controller.isOpen) {
            return null;
        }

        if(props.dropDownContentIsInActionSheet) {
            return renderDropDownContent();
        }

        if(controller.isInsideModalDialog) {

            return (
                <DropDownWithDialogComponent key={`DROP_DOWN_WITH_DIALOG_${controller.isOpen}`}
                                             dropDownController={controller}
                                             renderContent={renderDropDownContent}
                                             dialogOptions={props.modalDialogOptions}
                                             fieldLabel={props.label}/>
            );
        }

        if(dropDownInputElementRef) {
            return (
                <DropDownWithPopperComponent key={`DROP_DOWN_WITH_POPPER_${controller.isOpen}`}
                                             dropDownController={controller}
                                             dropDownInputElementRef={dropDownInputElementRef}
                                             popperOptions={props.popperOptions}
                                             renderContent={renderDropDownContent}/>
            );
        } else {
            return null;
        }
    }

    return (
        <>
            <DropDownInputContainerComponent onClick={onInputClickHandler}
                                             label={props.label}
                                             errorMessage={props.errorMessage}
                                             hideErrorText={props.hideErrorText}
                                             icon={props.icon}
                                             captureInputRef={captureDropDownInputElementRef}
                                             captureInputContainerRef={captureDropDownInputContainerElementRef}
                                             showBorders={props.showBorders}>

                {props.renderDropDownInput(controller)}
            </DropDownInputContainerComponent>
            {renderDropDown()}
        </>

    );
})
