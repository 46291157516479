export class DisruptionType {

    constructor(public readonly bookingQueue: string) {
    }

    static readonly Cancellation = new DisruptionType('CXL');
    static readonly ScheduledChange = new DisruptionType('SCHO3H');
    static readonly ScheduleChangeMissConnect = new DisruptionType('SCHMIS');
    static readonly CancellationWithAccommodation = new DisruptionType('REAO3H');
}
