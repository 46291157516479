import React from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../../hooks/use-services.hook";
import {InputContainerComponent} from "../../../../../components/forms/input-container.component";
import styled from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {SearchMaskSpecialPriceMarketUserOptionsComponent} from "../common/search-mask-special-price-market-user-options.component";

const OptionsContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.large};
    min-height: 79px;
    width: 100%;
`

export const SearchMaskSpecialPriceMarketHybridComponent: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;

    if(!searchController.getSpecialPriceMarket()) {
        return null;
    }


    const renderInput = () => {
        return (
            <OptionsContainerBox>
                <SearchMaskSpecialPriceMarketUserOptionsComponent searchController={searchController}/>
            </OptionsContainerBox>
        )
    }

    const errorMessage = searchController.fields.specialPriceMarketUserOption.error
        || searchController.fields.specialPriceMarketUserAgreement.error;

    return (
        <InputContainerComponent renderInput={renderInput} showBorders={false} errorMessage={errorMessage}/>
    );
})
