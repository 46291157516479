import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {IonToggle} from "@ionic/react";
import styled from "styled-components";

const SaveAsCompanionToggleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: ${props => props.theme.fontSize.large};
    @media ${(props) => props.theme.mediaQuery.xs} {
      font-size: ${props => props.theme.fontSize.medium};
    }

    ion-toggle {
        flex-shrink: 0;
    }
`

const IonToggleBox = styled(IonToggle)`
    height: 20px;
    
`

const SaveAsCompanionLabelBox = styled.span`
    flex-grow: 1;
    line-height: 1;
    padding-right: 10px;
`

export const SaveAsCompanionToggleComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const services = useServices();
    const companionHandler = props.passenger.companion;

    if(!companionHandler.canBeSavedAsCompanion) {
        return null;
    }

    return (
        <SaveAsCompanionToggleBox>
            <SaveAsCompanionLabelBox>
                {services.language.translate('Save passenger to my Aeroitalia account')}
            </SaveAsCompanionLabelBox>
            <IonToggleBox checked={companionHandler.shouldSaveAsCompanion}
                       className={'tst-save-as-companion-toggle'}
                       onIonChange={(e) => companionHandler.shouldSaveAsCompanion = e.detail.checked}/>
        </SaveAsCompanionToggleBox>
    )
});
