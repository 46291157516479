import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react-lite";
import {IFlightDesignatorViewModel} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {NullableString} from "../../../../types/nullable-types";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../components/designator/designator.component";
import {FlightChangeBlockingReasonComponent} from "./flight-change-blocking-reason.component";
import {CheckboxComponent} from "../../../../basic-components/checkbox/checkbox.component";
import {CardBox} from "../../../../basic-components/card/card.box";

const JourneyToModifyContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.medium};
    cursor: pointer;
`


export const JourneyToModifySelectorBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const DesignatorBox = styled(DesignatorComponent)`
    flex-grow: 1;
`

const FlightStatusBox = styled.div`
    color: ${props => props.theme.colors.danger};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: ${props => props.theme.spacing.small} 0 0 0;
    letter-spacing: 1px;
    line-height: 1;
`

interface JourneyToModifySelectorComponentProps extends PropsWithChildren {
    canBeUnselected: boolean;
    designator: IFlightDesignatorViewModel;
    changeBlockingReason: NullableString;
    changeBlockingReasonTitle: string;
    isJourneyCanceled: boolean;
    onSelect: () => void;
    isSelected: () => boolean;
}

export const JourneySelectorComponent: React.FC<JourneyToModifySelectorComponentProps> = observer((props) => {
    const services = useServices();

    const renderFlightStatus = () => {
        if(props.changeBlockingReason || !props.isJourneyCanceled) {
            return null;
        }
        return (
            <FlightStatusBox>
                {services.language.translate("Flight Canceled")}
            </FlightStatusBox>
        );
    }



    return (
        <JourneyToModifyContainerBox>
            <JourneyToModifySelectorBox onClick={(e) => {
                e.preventDefault(); //for some reason without preventDefault this handler is called twice
                props.onSelect();
            }}>
                <DesignatorBox
                    displayMode={DesignatorDisplayMode.stationsNamesAndDateAndFlightStops}
                    designator={props.designator}/>
                {
                    !props.changeBlockingReason && <CheckboxComponent checked={props.isSelected()}
                                                                      disabled={!props.canBeUnselected}/>
                }

            </JourneyToModifySelectorBox>
            {renderFlightStatus()}
            <FlightChangeBlockingReasonComponent blockingReason={props.changeBlockingReason}
                                                 title={props.changeBlockingReasonTitle}/>
            {props.children}
        </JourneyToModifyContainerBox>
    );
})
