import React from "react";
import {observer} from "mobx-react";
import {NullableGender} from "../../../services/dot-rez-api/data-contracts/enums/gender.enum";
import {useServices} from "../../../hooks/use-services.hook";
import {NullableString} from "../../../types/nullable-types";
import {FieldViewComponent} from "../field-view.component";

export const GenderValueViewComponent: React.FC<{value: NullableGender; label?: string}> = observer((props) => {
    const services = useServices();
    const label = props.label || services.language.translate('Gender');
    let value: NullableString = "";
    switch (props.value) {
        case "Male":
        case 1:
            value = services.language.translate('Male');
            break;
        case "Female":
        case 2:
            value = services.language.translate('Female');
            break;
    }

    return (
        <FieldViewComponent label={label} value={value}/>
    );
});
