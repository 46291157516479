import {PersonTravelDocumentModel} from "../person/person-travel-document.model";
import {CompanionModel} from "./companion.model";
import {IDotRezPersonTravelDocument} from "../../../dot-rez-api/data-contracts/user/person.data-contracts";

export class CompanionTravelDocumentModel extends PersonTravelDocumentModel<CompanionModel> {

    protected async _addTravelDocument(): Promise<IDotRezPersonTravelDocument> {
        const session = await this.owner.getSession();
        return await this.services.airlineWebapi.addCompanionTravelDocument({
            dotRezToken: session.token,
            affiliateKey: this.owner.affiliateKey,
            default: true,
            ...this._getUpdatedTravelDocumentFields()
        });
    }

    protected async _updateTravelDocument(): Promise<IDotRezPersonTravelDocument> {
        const session = await this.owner.getSession();
        return await this.services.airlineWebapi.updateCompanionTravelDocument({
            dotRezToken: session.token,
            affiliateKey: this.owner.affiliateKey,
            travelDocumentKey: this.travelDocumentKey,
            default: this.documentData.default,
            ...this._getUpdatedTravelDocumentFields()
        });
    }

    protected async _deleteTravelDocument(): Promise<void> {
        const session = await this.owner.getSession();
        await this.services.airlineWebapi.deleteCompanionTravelDocument({
            dotRezToken: session.token,
            affiliateKey: this.owner.affiliateKey,
            travelDocumentKey: this.travelDocumentKey
        });
    }

    protected _getUniqueDocumentErrorMessage(): string {
        return this.services.language.translationFor('This companion already has a {documentType} issued by {country}').withParams({
            documentType: this.services.travelDocumentType.tryGetDocumentTypeName(this.fields.documentTypeCode.value) || '',
            country: this.services.country.tryGetCountry(this.fields.issuedByCode.value || '')?.name || ''
        });
    }
}
