import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PersonIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2} {...props} viewBox="0 0 26 26" ratio={26/26}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(-362 -146.639)"><path d="M176.276,1615.747c-.135-2.813-1.66-4.739-4.06-5.163a3.549,3.549,0,1,0-4.176,0c-2.4.424-3.925,2.35-4.06,5.163h-.03v.788h12.355v-.788Z" transform="translate(204.836 -1450.394)"/><path d="M94-810.361a12.914,12.914,0,0,1-9.192-3.808A12.916,12.916,0,0,1,81-823.361a12.915,12.915,0,0,1,3.808-9.192A12.915,12.915,0,0,1,94-836.361a12.916,12.916,0,0,1,9.193,3.808A12.914,12.914,0,0,1,107-823.361a12.915,12.915,0,0,1-3.808,9.193A12.915,12.915,0,0,1,94-810.361Zm0-25a12.013,12.013,0,0,0-12,12,12.014,12.014,0,0,0,12,12,12.014,12.014,0,0,0,12-12A12.014,12.014,0,0,0,94-835.361Z" transform="translate(281 983)"/></g>
        </SvgIcon>
    )
}
