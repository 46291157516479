import React, {ChangeEvent, useState} from 'react';
import styled from "styled-components";
import {IonIcon} from "@ionic/react";
import {observer} from "mobx-react-lite";
import {closeOutline, searchOutline} from "ionicons/icons";
import {InputBaseBox} from "../input/input-base.box";
import {Check} from "../../types/type-checking";

const SearchbarContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.small};
    background: ${props => props.theme.colors.primaryContrast};
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.large};
    border-radius: ${props => props.theme.border.defaultRadius};
    border: 1px solid ${props => props.theme.colors.primary};
    padding: ${props => props.theme.spacing.small};
    
`

const SearchbarIconBox = styled.div`
    display: flex;
    align-items: center;
`

const SearchbarInputBox = styled(InputBaseBox)`
    flex-grow: 1;
`

const SearchbarClearButtonBox = styled.div<{isVisible: boolean}>`
    display: flex;
    align-items: center;
    cursor: pointer;
    visibility: ${props => props.isVisible ? "visible" : "hidden"};
`

interface SearchbarComponentProps {
    onChange?: (text: string) => void;
    placeholder?: string;
    className?: string;
    captureInputElementRef?: (input: HTMLInputElement | null) => void;
}

export const SearchbarComponent: React.FC<SearchbarComponentProps> = observer((props) => {
    const [currentValue, setCurrentValue] = useState("");
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
        if(props.onChange) {
            props.onChange(currentValue);
        }
    }

    const onClear = () => {
        setCurrentValue("");
        if(props.onChange) {
            props.onChange("");
        }
    }

    return (
        <SearchbarContainerBox className={props.className}>
            <SearchbarIconBox>
                <IonIcon icon={searchOutline}/>
            </SearchbarIconBox>

            <SearchbarInputBox onChange={onChangeHandler}
                               value={currentValue}
                               ref={props.captureInputElementRef}
                               placeholder={props.placeholder}/>

            <SearchbarClearButtonBox isVisible={!Check.isEmpty(currentValue)} onClick={onClear}>
                <IonIcon icon={closeOutline}/>
            </SearchbarClearButtonBox>

        </SearchbarContainerBox>
    )
});
