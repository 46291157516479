import {IFeeModel} from "../fees/fee-model.interface";
import {NullablePrice, Price} from "../../../../currency/price";
import {NullableString} from "../../../../../types/nullable-types";
import {FeeTypeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {ShoppingCartModeEnum} from "../../../booking-strategies/booking-strategy.interface";

export class FeeDecorator implements IFeeModel {
    constructor(private readonly originalFee: IFeeModel,
                private readonly shoppingCartMode: ShoppingCartModeEnum) {
    }

    get createdDate(): Date {
        return this.originalFee.createdDate;
    }

    get description(): string {
        return this.originalFee.description;
    }

    get details(): NullableString {
        return this.originalFee.details;
    }
    get feeCode(): NullableString {
        return this.originalFee.feeCode;
    }
    get feeGroupKey(): string {
        return this.originalFee.feeGroupKey;
    }
    get feeType(): FeeTypeEnum {
        return this.originalFee.feeType;
    }

    get quantity(): number {
        return this.originalFee.quantity;
    }

    get initialPrice(): NullablePrice {
        return this.originalFee.initialPrice;
    }

    get currentPrice(): Price {
        return this.originalFee.currentPrice;
    }

    get priceToDisplay(): Price {
        if(this.shoppingCartMode === ShoppingCartModeEnum.ShowAllPurchases) {
            return this.originalFee.currentPrice;
        } else {
            return this.originalFee.priceToDisplay;
        }
    }

    get totalToDisplay(): Price {
        return this.priceToDisplay.multiply(this.quantity);
    }

    get isCommitted(): boolean {
        return this.originalFee.isCommitted;
    }
    get isPurchasedOnCurrentSession(): boolean {
        return this.originalFee.isPurchasedOnCurrentSession;
    }

    get preferredOrderInLists(): number {
        return this.originalFee.preferredOrderInLists;
    }

    get ssrNumber(): number {
        return this.originalFee.ssrNumber;
    }
    get ssrType(): ISsrType | null {
        return this.originalFee.ssrType;
    }

    get shouldBeDisplayedInShoppingCart(): boolean {
        return this.originalFee.shouldBeDisplayedInShoppingCart;
    }
}
