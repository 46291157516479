import { FlightDesignatorModel } from "../../booking/models/designator/flight-designator.model";
import {IDotRezAvailableSegment} from "../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import { IServiceFactory } from "../../service-factory.interface";
import {AvailabilityLegModel} from "./availability-leg.model";
import {IAvailableSegmentViewModel} from "./available-segment-view-model.interface";
import {FlightIdentifierModel} from "../../booking/models/identifier/flight-identifier.model";

/**
 * A segment is a part from a journey that has multiple connections
 */
export class AvailableSegmentModel implements IAvailableSegmentViewModel {
    constructor(private readonly services: IServiceFactory,
                private readonly _segment: IDotRezAvailableSegment) {
        this.legs = _segment.legs.map(leg => new AvailabilityLegModel(services, leg));
    }

    get key(): string {
        return this._segment.segmentKey;
    }

    get originStation(): string {
        return this._segment.designator.origin;
    }

    get destinationStation(): string {
        return this._segment.designator.destination;
    }


    get identifier(): FlightIdentifierModel{
        return new FlightIdentifierModel(
            this._segment.identifier.carrierCode,
            this._segment.identifier.identifier,
            this.services
        );
    }

    get isDomesticFlight(): boolean {
        return !this._segment.international;
    }

    get designator(): FlightDesignatorModel {
        const legs = this._segment.legs;
        return new FlightDesignatorModel(
            this.services,
            this.services.stations.getStation(this._segment.designator.origin),
            this.services.stations.getStation(this._segment.designator.destination),
            this._segment.designator.departure,
            this._segment.designator.arrival,
            legs[0].legInfo.departureTimeUtc,
            legs[legs.length - 1].legInfo.arrivalTimeUtc,
            this.legs.map(l => l.designator),
            null);
    }

    readonly legs: AvailabilityLegModel[];

}
