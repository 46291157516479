import React from "react";
import { observer } from "mobx-react";
import { useServices } from "../../../../hooks/use-services.hook";
import { IDialogConfirmationHandler } from "../../../../services/dialog/dialog.service.interface";
import { StandardDialogPageComponent } from "../../../../components/modal/page/standard-dialog-page.component";
import { StandardPageContentComponent } from "../../../../basic-components/page/standard-page-content/standard-page-content.component";
import { StandardDialogFooterComponent } from "../../../../components/modal/footer/standard-dialog-footer.component";
import { StandardDialogHeaderComponent } from "../../../../components/modal/header/standard-dialog-header.component";
import { UserProfileEditComponent } from "./user-profile-edit.component";
import { ValidationResultEnum } from "../../../../types/validation-result.enum";
import {IUserProfileEditorViewModel} from "../../../../services/user/models/profile/user-profile-editor-view-model.interface";
import {SaveButtonBox} from "../../../../components/forms/buttons/save-button.box";

interface ProfileDialogComponentProps {
    userProfileEditor: IUserProfileEditorViewModel;
    dialogHandler: IDialogConfirmationHandler;
}

export const UserProfileEditDialogComponent: React.FC<ProfileDialogComponentProps> = observer((props) => {
    const services = useServices();

    const onSaveAccount = async () => {
        const validationResult = await props.userProfileEditor.save();
        if(validationResult === ValidationResultEnum.Success) {
            props.dialogHandler.accept();
        }
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Edit profile')}
                                           dialogHandler={props.dialogHandler}>

            </StandardDialogHeaderComponent>
            <StandardPageContentComponent>
                <UserProfileEditComponent userProfileEditor={props.userProfileEditor} />
            </StandardPageContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <SaveButtonBox onClick={onSaveAccount}>
                    {services.language.translate('Save')}
                </SaveButtonBox>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
