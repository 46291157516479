import {CreditCardPaymentModel} from "./credit-card-payment.model";
import React from "react";
import {PaymentMethodCategoryEnum} from "../payment-method-category.enum";

export class PaypalPaymentMethodModel extends CreditCardPaymentModel {
    get name(): string {
        return "PayPal";
    }

    renderIcon(): React.ReactElement | null {
        return null;
    }

    get category(): PaymentMethodCategoryEnum {
        return PaymentMethodCategoryEnum.MobileWallet;
    }

    get hasCustomRenderer(): boolean {
        return true;
    }

}