import React from "react";
import {ISvgIconProps, SvgIcon} from "../../../../assets/svg/svg-icon.component";

export const BcbgSsrSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.9} sizeIsHeight {...props} viewBox="0 0 11.083 19">
            <g fill={props.color? props.color: "currentColor"} transform="translate(-34 -735.529)"><g transform="translate(34 735.529)"><path d="M9.975,4.872h-.95V1.414A1.458,1.458,0,0,0,7.442,0H3.483A1.558,1.558,0,0,0,1.9,1.414V4.872H1.108A1.078,1.078,0,0,0,0,5.972V8.015H11.083V5.972A1.078,1.078,0,0,0,9.975,4.872ZM2.85,1.414A.679.679,0,0,1,3.483.786H7.442a.679.679,0,0,1,.633.629V4.872H2.85Z" /><path d="M0,13.615c0,.629.475.943,1.108.943H1.9V15.5a.155.155,0,0,0,.158.157h.95c.158,0,.158,0,.158-.157v-.943H7.758V15.5c0,.157.158.157.317.157h.95c.158,0,.158,0,.158-.157v-.943h.792a1.056,1.056,0,0,0,1.108-.943V5.6H0Z" transform="translate(0 3.342)"/></g></g>
        </SvgIcon>
    )
}
