import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../hooks/use-services.hook";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import styled from "styled-components";
import {SpinnerComponent} from "../../components/spinner/spinner.component";

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

export const FinalizePaymentPage: React.FC = observer(() => {
    const services = useServices();

    const renderSpinner = () => {
        if(services.booking.isBookingRestoreInProgress) {
            return (<SpinnerComponent/>)
        }

        return null;
    }

    return (
        <RoutedPageComponent>
            <StandardPageContentComponent>
                <ContentBox>
                    {renderSpinner()}
                </ContentBox>
            </StandardPageContentComponent>
        </RoutedPageComponent>);
})
