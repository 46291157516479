import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const ChildFaceIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.6} {...props} viewBox="0 0 46.315 46.315">
            <g fill={props.color? props.color: "currentColor"}>
                <g>
                    <ellipse cx="23.157" cy="29.327" rx="2.737" ry="1.094"/>
                    <path d="M43.695,20.983c-0.234-2.332-0.752-5.16-1.66-7.157C41.811,13.341,36.518,2.11,23.157,2.11
			c-3.459,0-6.364,0.744-8.782,1.855c-4.074,1.539-7.213,4.387-9.158,8.258c-0.558,0.914-0.842,1.542-0.887,1.647
			c-0.888,1.947-1.398,4.736-1.637,7.056C1.063,22.162,0,24.179,0,26.452c0,3.139,2.025,5.787,4.771,6.57
			c3.479,6.789,10.56,11.183,18.422,11.183c7.867,0,14.955-4.403,18.434-11.206c2.704-0.812,4.688-3.439,4.688-6.547
			C46.312,24.214,45.283,22.223,43.695,20.983z M39.859,29.259c-0.316,0-0.618-0.074-0.896-0.199
			c-2.23,6.479-8.44,11.146-15.771,11.146c-7.338,0-13.557-4.679-15.779-11.167c-0.296,0.141-0.618,0.221-0.958,0.221
			C5.094,29.259,4,28.002,4,26.452c0-1.549,1.094-2.805,2.455-2.805c0.025,0,0.049,0.007,0.075,0.008
			c0.007-0.862,0.084-1.928,0.222-3.026c0.195,0.009,0.389,0.023,0.588,0.023c3.457,0,7.356-1.395,10.758-3.837
			c1.751,1.456,4.193,3.066,6.429,3.066h0.627l-0.141-0.611c-0.01-0.039-0.755-3.34-0.923-6.282
			c1.059,1.273,2.271,2.432,3.615,3.455c3.523,2.68,7.633,4.216,11.273,4.216c0.223,0,0.438-0.014,0.652-0.025
			c0.137,1.095,0.213,2.154,0.221,3.014c0.004,0,0.006-0.001,0.008-0.001c1.361,0,2.454,1.256,2.454,2.805
			C42.312,28.002,41.221,29.259,39.859,29.259z"/>
                    <circle cx="15.721" cy="22.613" r="2.416"/>
                    <circle cx="30.592" cy="22.613" r="2.416"/>
                </g>
            </g>
        </SvgIcon>
    )
}
