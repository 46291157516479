import {IServiceFactory} from "../../../service-factory.interface";
import {OnlineCheckInBlockerSsr} from "../online-check-in-blocker-ssr";
import {TimeSpan} from "../../../../types/time-span";

export default class PoxySsrType extends OnlineCheckInBlockerSsr {
    constructor(services: IServiceFactory) {
        super('POXY', services);
    }

    get shouldBlockOnlineCheckIn(): boolean {
        return false;
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(48);
    }
}
