import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";

const DisclaimerBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.small};
    line-height: 1.1;
    text-align: center;
`

export const CabinBagsDisclaimerForAtrAircraftComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <DisclaimerBox>
            {services.language.translate('On flights operated with ATR type aircraft, only one cabin bag measuring 40x30x20 cm is accepted.')}
        </DisclaimerBox>
    )
});
