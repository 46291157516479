import styled from "styled-components";
import {fieldInputCommonCssProps} from "./field-common-css-props";

export const FieldInputWrapperBox = styled.div<{hasError: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    ${props => fieldInputCommonCssProps(props.theme, props.hasError)}   
`
