import {FareDiscountTypeBase} from "./fare-discount-type-base";
import {IFareDiscountType} from "./fare-discount-type.interface";
import {IDotRezSellTripPassengerType} from "../../../dot-rez-api/data-contracts/requests/booking/sell-trip.request";
import {IDotRezAvailabilitySimpleRequestPassengers} from "../../../dot-rez-api/data-contracts/requests/booking/search-simple.request";
import {ServiceChargeTypeDotRezApiEnum} from "../../../dot-rez-api/data-contracts/enums/service-charge-type.enum";
import {IPassengerTypeSelectorsList} from "../../../passenger-types/passengers-types.service.interface";
import {DiscountCodeEnum} from "./discount-code-enum";
import {NullableString} from "../../../../types/nullable-types";





export class BogoFareDiscount extends FareDiscountTypeBase implements IFareDiscountType {
    get discountTypeName(): string {
        if(this.fareAdapter.bundleNameToShowOnFare) {
            return this.fareAdapter.bundleNameToShowOnFare;
        }
        return this.services.language.translate('Standard');
    }
    get promotionalMessage(): NullableString {
        return this.services.language.translate('Buy 1 get 1 for free');
    }

    get isApplicable(): boolean {
        return this.passengerDotRezFares.some(passengerFare =>
                        passengerFare.passengerDiscountCode === DiscountCodeEnum.BOGO
                        && passengerFare.revenueFare <= 0
                        && this._hasServiceCharge(ServiceChargeTypeDotRezApiEnum.Discount));
    }

    createPassengerTypesForTripSell(passengerTypesSelectors: IPassengerTypeSelectorsList): IDotRezSellTripPassengerType[] {

        const passengerTypes: IDotRezAvailabilitySimpleRequestPassengers = passengerTypesSelectors.createPassengerTypesForRegularSell();

        const totalPassengersCount = passengerTypes.sum(passengers => passengers.count);

        let remainingBogoCount = Math.floor(totalPassengersCount / 2);
        if(remainingBogoCount === 0) {
            return passengerTypes
        }

        const result: IDotRezSellTripPassengerType[] = [];

        for(let pType of passengerTypes) {
            const bogoToConsume = Math.min(pType.count, remainingBogoCount);
            if(bogoToConsume === 0) {
                result.push(pType);
            } else {
                result.push({
                    type: pType.type,
                    count: bogoToConsume,
                    discountCode: DiscountCodeEnum.BOGO
                });

                if(bogoToConsume < pType.count) {
                    result.push({
                        type: pType.type,
                        count: pType.count - bogoToConsume,
                    });
                }

                remainingBogoCount -= bogoToConsume;
            }
        }


        return result;
    }
}

