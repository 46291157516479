import {IServiceFactory} from "../../../service-factory.interface";
import {IUserModel} from "../user-model.interface";
import {IDotRezUserSession} from "../../../dot-rez-api/session/user-session/dot-rez-user.session.interface";
import {DotRezUserSession} from "../../../dot-rez-api/session/user-session/dot-rez-user.session";
import {makeObservable, observable, runInAction} from "mobx";
import {IDotRezBookingSession} from "../../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {AnonymousUserModel} from "../anonymous-user/anonymous-user.model";
import {ILoginOptions} from "../../user.service.interface";
import {AirlineWebapiErrorCodesEnum} from "../../../airline-webapi/airline-webapi-error-codes.enum";
import { AuthorizedUserProfileModel } from "./profile/authorized-user-profile.model";

export class AuthorizedUserModel implements IUserModel {
    constructor(public readonly services: IServiceFactory,
                private readonly refreshToken: string,
                dotRezToken?: string) {
        if(dotRezToken) {
            this._getSessionPromise = Promise.resolve(new DotRezUserSession(dotRezToken, services));
        } else {
            this._getSessionPromise = this._createUserSession();
        }

        makeObservable<this, '_getSessionPromise'>(this, {
            _getSessionPromise: observable.ref
        });

        this.profile = new AuthorizedUserProfileModel(this);
    }

    readonly profile: AuthorizedUserProfileModel;


    get isAuthorized(): boolean {
        return true;
    }

    private _getSessionPromise: Promise<IDotRezUserSession>;
    getSession(): Promise<IDotRezUserSession> {
        return this._getSessionPromise;
    }

    async login(options: ILoginOptions): Promise<IUserModel> {
        return this;
    }

    async logout(): Promise<IUserModel> {
        this.services.localStorage.removeItem("user.refreshToken");
        return new AnonymousUserModel(this.services);
    }

    private async _createDotRezToken(): Promise<string> {
        const response = await this.services.airlineWebapi.createDotRezToken({
            refreshToken: this.refreshToken
        });

        if(response.errorCode === AirlineWebapiErrorCodesEnum.InvalidCredentials) {
            await this.services.user.logout();
            //TODO - maybe I should call login also ?
        }

        if(!response.data.dotRezToken) {
            throw new Error('Failed to get dotRez token');
        }

        return response.data.dotRezToken;
    }

    private async _createUserSession(): Promise<IDotRezUserSession> {
        const token = await this._createDotRezToken();

        return new DotRezUserSession(token, this.services)
    }

    reCreateSession(): Promise<IDotRezUserSession> {
        const promise = this._createUserSession();
        runInAction(() => {
            this._getSessionPromise = promise;
            promise.then(() => {
                if(!this.profile.isProfileInitialized) {
                    this.profile.loadProfile();
                }
            });
        });



        return promise;
    }

    async createBookingSession(): Promise<IDotRezBookingSession> {
        const token = await this._createDotRezToken();

        return this.services.dotRezApi.createBookingSession(token);
    }

    async createFlightSearchSession(): Promise<IDotRezBookingSession> {
        const token = await this._createDotRezToken();

        return this.services.dotRezApi.createFlightSearchSession(token);
    }

    async createTransientBookingSession(): Promise<IDotRezBookingSession> {
        const token = await this._createDotRezToken();
        return this.services.dotRezApi.createTransientBookingSession(token);
    }

    dispose(): void {
        this.profile.dispose();
    }

    registrationConfirmed(): void {
        //nothing to do here
    }

}
