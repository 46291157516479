import React from "react";
import {observer} from "mobx-react";
import {PassengerBoardingPassesSelector} from "../models/passenger-boarding-passes-selector";
import styled from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";
import {CheckboxComponent} from "../../../../basic-components/checkbox/checkbox.component";

const PassengerSelectorBox = styled(CardBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: ${props => props.theme.spacing.medium};
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
`

interface PassengerBoardingPassSelectorComponentProps {
    passengerSelector: PassengerBoardingPassesSelector;
}

export const PassengerBoardingPassesSelectorComponent: React.FC<PassengerBoardingPassSelectorComponentProps> = observer((props) => {

    const onClick = () => {
        props.passengerSelector.isSelected = !props.passengerSelector.isSelected;
    }

    return (
        <PassengerSelectorBox onClick={onClick}>
            <CheckboxComponent checked={props.passengerSelector.isSelected} isReadOnly={true} />
            <div>
                {props.passengerSelector.passengerName}
            </div>

        </PassengerSelectorBox>
    )
})