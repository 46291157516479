import {ICryptoService} from "./crypto.service.interface";
import {ClientEkFactory} from "./client-encryption-key";



export class CryptoService implements ICryptoService {

    async decryptMessage(token: string): Promise<string> {
        const iv = new Uint8Array(16); // IV length for AES

        const importedKey = await this._createImportedKey("decrypt");

        const decryptedArrayBuffer = await crypto.subtle.decrypt(
            {
                name: "AES-CBC",
                iv: iv
            },
            importedKey,
            this._base64ToArrayBuffer(token)
        );

        return new TextDecoder().decode(decryptedArrayBuffer);
    }

    async encryptMessage(message: string): Promise<string> {
        const importedKey = await this._createImportedKey("encrypt");
        const iv = crypto.getRandomValues(new Uint8Array(16));
        const encodedText = new TextEncoder().encode(message);
        const encrypted = await crypto.subtle.encrypt(
            { name: 'AES-CBC', iv: iv },
            importedKey,
            encodedText
        );

        // Concatenate IV and encrypted data
        const buffer = new Uint8Array(iv.length + encrypted.byteLength);
        buffer.set(iv);
        buffer.set(new Uint8Array(encrypted), iv.length);

        return this._arrayBufferToBase64(buffer);
    }

    private async _createImportedKey(keyUsage: KeyUsage) {
        const keyBytes = this._base64ToArrayBuffer(ClientEkFactory.getEk());


        return await crypto.subtle.importKey(
            "raw",
            keyBytes,
            { name: 'AES-CBC', length: 256 },
            false,
            [keyUsage]
        );
    }
    private _base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }


    private _arrayBufferToBase64(arrayBuffer: Iterable<number>) {
        const binary = [];
        const bytes = new Uint8Array(arrayBuffer);
        for (let i = 0; i < bytes.byteLength; i++) {
            binary.push(String.fromCharCode(bytes[i]));
        }
        return window.btoa(binary.join(''));
    }

}
