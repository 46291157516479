import {IDotRezInfantFee} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {BookingModel} from "../../booking.model";
import {Price} from "../../../../currency/price";
import {InfantPassengerModel} from "../../passenger/infant-passenger.model";
import {InfantFeeBaseModel} from "../../base-models/fees/infant/infant-fee-base.model";

export class InfantFeeModel extends InfantFeeBaseModel {
    constructor(feeData: IDotRezInfantFee,
                private readonly infant: InfantPassengerModel) {
        super(feeData, infant.booking.services);
    }


    private get booking(): BookingModel {
        return this.infant.booking;
    }

    createPrice(amount: number): Price {
        return this.booking.createPrice(amount);
    }
}
