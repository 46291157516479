import {
    IDotRezAvailableFare,
    IDotRezAvailableJourney,
    IDotRezAvailableTrip
} from "../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import {IServiceFactory} from "../../service-factory.interface";
import {
    AvailableDepartureJourneyModel,
    AvailableJourneyModel,
    AvailableReturnJourneyModel
} from "./available-journey.model";
import {AvailableFareWithDiscountModel} from "./fares/available-fare-with-discount.model";
import {AvailabilityModel} from "./availability.model";
import {IAvailableTripViewModel} from "./available-trip-view-model.interface";
import {AvailableFareModel} from "./fares/available-fare.model";
import {IJourneyFareReducer} from "./fare-reducer.interface";
import {Price} from "../../currency/price";
import {ValidationError} from "../../../types/errors/validation-error";
import {NullableString} from "../../../types/nullable-types";
import {IFlightSearchControllerViewModel} from "../flight-search-controller/flight-search-controller-view-model.interface";
import {PreventJourneysOverlapError} from "../../../types/errors/prevent-journeys-overlap-error";

/**
 * A trip is a collection of flights from a specific date.
 * The flights in a trip are named journeys
 */
export abstract class AvailableTripModel implements IAvailableTripViewModel, IJourneyFareReducer {
    constructor(
        protected readonly availability: AvailabilityModel,
        protected readonly tripData: IDotRezAvailableTrip,
        protected readonly services: IServiceFactory,
        protected faresAvailable: Record<string, IDotRezAvailableFare>) {

            
        let tempJourneys: AvailableJourneyModel[] = [];
        Object.keys(this.tripData.journeysAvailableByMarket).forEach(tripKey => {
            this.tripData.journeysAvailableByMarket[tripKey].forEach(journey => {
                const availableJourneyModel = this._createAvailableJourneyModel(journey);
                if(availableJourneyModel.fares.length > 0) {
                    tempJourneys.push(availableJourneyModel);
                }
            });
        });

        this._journeys = tempJourneys.sort((t1, t2) => t1.departureDate.getTime() - t2.departureDate.getTime());
    }

    get searchController(): IFlightSearchControllerViewModel {
        return this.availability.searchController;
    }

    protected abstract _createAvailableJourneyModel(availableDotRezJourney: IDotRezAvailableJourney): AvailableJourneyModel;
    abstract reduceFare(price: Price): Price;
    abstract get bundleNameToShowOnFare(): NullableString;
    protected abstract _executeSell(fare: AvailableFareModel): Promise<void>;

    get isAvailabilitySearchWithBlueBenefits(): boolean {
        return this.availability.isAvailabilitySearchWithBlueBenefits;
    }

    get showStandardAndBlueBenefitsPrice(): boolean {
        return this.availability.showStandardAndBlueBenefitsPrice;
    }



    get journeys(): AvailableJourneyModel[] {
        return this._journeys;
    }

    private _findCurrentSelectedFare(): AvailableFareModel | null {
        for(let j of this.journeys) {
            for(let f of j.fares) {
                if(f.isSelected) {
                    return f;
                }
            }
        }

        return null;
    }

    async sell(fare: AvailableFareModel): Promise<void> {
        let currentSelectedFare = this._findCurrentSelectedFare();

        if(currentSelectedFare) {
            currentSelectedFare.isSelected = false;
        }

        fare.isSelected = true;

        try {
            await this._executeSell(fare);
        } catch (err) {
            fare.isSelected = false;
            if(currentSelectedFare) {
                currentSelectedFare.isSelected = true;
            }

            if((err instanceof ValidationError)) {
                this.services.alert.showError((err as ValidationError).message);
            } else if(err instanceof PreventJourneysOverlapError) {
                // nothing to do here. If this error was thrown it means that there was a message displayed to the user.
            } else {
                this.services.logger.error('Failed to select fare', err);
                this.services.alert.showError(this.services.language.translate('Sorry! There was an error trying to select this journey. Please try again.'));
            }


        }

    }


    get date(): Date {
        return this.services.time.parseIsoDate(this.tripData.date);
    }

    protected readonly _journeys: AvailableJourneyModel[] = [];

    get hasFareSelected(): boolean {
        return this.journeys.some(j => j.hasFareSelected);
    }
}

export class AvailableDepartureTripModel extends AvailableTripModel {
    protected _createAvailableJourneyModel(availableDotRezJourney: IDotRezAvailableJourney): AvailableJourneyModel {
        return new AvailableDepartureJourneyModel(this, availableDotRezJourney, this.services, this.faresAvailable);
    }

    protected async _executeSell(fare: AvailableFareWithDiscountModel): Promise<void> {
        await this.availability.sellDepartureJourney(fare);
    }

    reduceFare(price: Price): Price {
        return this.availability.reduceDepartureFarePrice(price);
    }

    get bundleNameToShowOnFare(): NullableString {
        return this.availability.departureBundleNameToShowOnFare;
    }

}

export class AvailableReturnTripModel extends AvailableTripModel {
    protected _createAvailableJourneyModel(availableDotRezJourney: IDotRezAvailableJourney): AvailableJourneyModel {
        return new AvailableReturnJourneyModel(this, availableDotRezJourney, this.services, this.faresAvailable);
    }


    protected async _executeSell(fare: AvailableFareWithDiscountModel): Promise<void> {
        await this.availability.sellReturnJourney(fare);
    }

    reduceFare(price: Price): Price {
        return this.availability.reduceReturnFarePrice(price);
    }

    get bundleNameToShowOnFare(): NullableString {
        return this.availability.returnBundleNameToShowOnFare;
    }
}
