import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const LogoVisaIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.7} sizeIsHeight {...props} viewBox="0 0 52.645 17" ratio={52.645/17}>
            <g fill={props.color? props.color: "currentColor"}>
                <path d="M81.889,82.7,75,99.145H70.5L67.107,86.022A1.8,1.8,0,0,0,66.1,84.577a17.9,17.9,0,0,0-4.2-1.4L62,82.7H69.24A1.981,1.981,0,0,1,71.2,84.38l1.79,9.515L77.419,82.7ZM99.506,93.776c.019-4.34-6-4.579-5.961-6.518.014-.59.576-1.217,1.8-1.376a8.039,8.039,0,0,1,4.192.735l.746-3.488a11.462,11.462,0,0,0-3.979-.729c-4.2,0-7.165,2.235-7.189,5.435-.027,2.366,2.111,3.688,3.724,4.475,1.659.806,2.215,1.324,2.207,2.043-.011,1.1-1.322,1.59-2.547,1.61a8.9,8.9,0,0,1-4.368-1.039l-.77,3.6a12.953,12.953,0,0,0,4.73.875c4.475,0,7.4-2.2,7.411-5.624m11.1,5.369h3.935L111.11,82.7h-3.63a1.932,1.932,0,0,0-1.81,1.206L99.286,99.145h4.467l.886-2.457H110.1Zm-4.746-5.827,2.24-6.175,1.289,6.175ZM87.965,82.7,84.447,99.145H80.194L83.712,82.7Z" transform="translate(-61.9 -82.4)" fill="#1434cb"/>
            </g>
        </SvgIcon>
    )
}
