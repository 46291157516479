import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {FlightSearchStationSelectionPage} from "./flight-search-station-selection.page";
import {PlainLandingLineIcon} from "../../../assets/svg/plane-landing-line";

export const FlightSearchDestinationSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;
    return (
        <FlightSearchStationSelectionPage titleText={services.language.translate('Going To')}
                                          plainIcon={<PlainLandingLineIcon />}
                                          stations={searchController.availableReturnStations}
                                          recentlySearchedStations={searchController.recentlySearchedDestinations}
                                          onStationSelected={async (station) => {
                                  searchController.departureDestination = station;
                                  await services.flightSearch.steps.nextStep();
                              }} />
    );
});
