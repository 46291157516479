import {NullableString} from "../../../../types/nullable-types";
import {IDotRezGraphQLQuery} from "../dot-rez-graph-ql-query.interface";

export function searchCurrentUserBookingsQuery(startDate: NullableString, endDate: NullableString): IDotRezGraphQLQuery {
    const queryDefinition =
        `
        query bookingsByPassenger($bookingsByPassengerRequest: Input_UserTripsRequest) {
            userBookingsByPassenger(request: $bookingsByPassengerRequest) {
                recordLocator,
                firstName,
                lastName,
                segments {
                    designator {
                        origin,
                        destination,
                        departure,
                        arrival
                    },
                    identifier {
                        opSuffix,
                        carrierCode,
                        identifier
                    }
                }
       }
   }
    `

    return {
        queryName: "getCurrentUserBookings",
        request: {
            query: queryDefinition,
            variables: {
                bookingsByPassengerRequest: {
                    startDate: startDate,
                    endDate: endDate
                }
            }
        }
    };
}
