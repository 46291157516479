import {
    BLUE_BENEFITS_BOOKING_SUBSCRIPTION_COMMENT_HEADER,
    IBlueBenefitsService
} from "./blue-benefits.service.interface";
import {ServiceBase} from "../service-base";
import {IBlueBenefitsSubscription} from "./models/subscriptions/blue-benefits-subscription.interface";
import {IDotRezPersonComment} from "../dot-rez-api/data-contracts/user/person.data-contracts";
import {IBlueBenefitsPackage} from "./models/packs/blue-benefits-package.interface";
import {UserBlueBenefitsSubscriptionModel} from "./models/subscriptions/user-blue-benefits-subscription.model";
import {IServiceFactory} from "../service-factory.interface";
import {NoBlueBenefitsSubscriptionModel} from "./models/subscriptions/no-blue-benefits-subscription.model";
import {BookingBlueBenefitsSubscriptionModel} from "./models/subscriptions/booking-blue-benefits-subscription.model";
import {BlueBenefitsPackage} from "./models/packs/blue-benefits-package";
import {computed, makeObservable} from "mobx";
import {IDotRezBookingComment} from "../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";

// "path": "Web\\dotREZ\\SsrPromotionSettings"
export class BlueBenefitsService extends ServiceBase implements IBlueBenefitsService {
    constructor(services: IServiceFactory) {
        super(services);
        this.noSubscription = new NoBlueBenefitsSubscriptionModel(services);
        makeObservable(this, {
            availableBlueBenefitsPackages: computed
        });
    }
    get isEnabled(): boolean {
        return false;
    }

    get availableBlueBenefitsPackages(): IBlueBenefitsPackage[] {
        const bbPackagesConfigs = this.services.configuration.data.blueBenefits.packages;

        return Object.keys(bbPackagesConfigs)
                     .map(packageCode => new BlueBenefitsPackage(packageCode, bbPackagesConfigs[packageCode], this.services))
                     .sort((p1, p2) => p1.maxNumberOfPassengers - p2.maxNumberOfPassengers);
    }

    readonly noSubscription: IBlueBenefitsSubscription;

    //promo|rainbow1and1|2023-01-18|160334353307||fc1dd551dde55cc1419a3a0ed2bb16603f7a1a8374e83aa091bb229adbe2581b

    async createSubscriptionsFromPersonComments(comments: IDotRezPersonComment[]): Promise<IBlueBenefitsSubscription[]> {

        let commentsTexts = comments.filter(c => c.text?.startsWith('promo|'))
                                    .map(c => c.text)

        if(commentsTexts.length === 0) {
            return [];
        }

        const subscriptions: Record<string, IBlueBenefitsSubscription> = {};

        for(let comment of commentsTexts) {
            const subscription = this._createUserSubscriptionFromComment(comment);
            if(subscription) {
                subscriptions[comment] = subscription;
            }
        }

        const activeSubscriptionsComments = Object.keys(subscriptions).filter(comment => subscriptions[comment].isValidSubscription)

        if(activeSubscriptionsComments.length > 0) {
            try {
                const validSubscriptionComments = (await this.services.airlineWebapi.getValidBlueBenefitsSubscriptions({
                    subscriptions: activeSubscriptionsComments
                })).subscriptions.toDictionary(comment => comment);

                activeSubscriptionsComments.forEach(comment => {
                    if(!validSubscriptionComments[comment]) {
                        delete subscriptions[comment];
                    }
                });
            } catch (err) {
                this.services.logger.error(`Failed to validate Blue Benefits subscriptions: ${activeSubscriptionsComments.join(" ; ")}`, err);
            }
        }

        return Object.values(subscriptions);
    }

    private _createUserSubscriptionFromComment(comment: string): UserBlueBenefitsSubscriptionModel | null {
        const parts = comment.split('|');

        const expirationDateAsString = parts[2]?.trim()
        if(!expirationDateAsString) {
            return null;
        }

        const bbPackage = this._tryGetPackageByCode(parts[1]);

        if(!bbPackage) {
            return null;
        }

        const time = this.services.time;
        let expirationDate = time.parseIsoDate(expirationDateAsString);
        expirationDate = time.addSeconds(time.addDays(expirationDate, 1), -1);
        return new UserBlueBenefitsSubscriptionModel(bbPackage, expirationDate, this.services);
    }

    private _tryGetPackageByCode(packageCode: string): IBlueBenefitsPackage | null {
        return this.availableBlueBenefitsPackages.find(p => p.packageCode === packageCode) || null;
    }


    private _getPackageByPassengersCount(passengersCount: number): IBlueBenefitsPackage {

        const firstMatchedPackage = this.availableBlueBenefitsPackages.find(p => p.maxNumberOfPassengers >= passengersCount);

        if(!firstMatchedPackage) {
            throw new Error(`There is no Blue Benefits package that can match ${passengersCount} passengers`);
        }

        return firstMatchedPackage;
    }

    createSubscriptionFromPackage(bbPackage: IBlueBenefitsPackage, expirationDate: Date): IBlueBenefitsSubscription {

        return new BookingBlueBenefitsSubscriptionModel(bbPackage, expirationDate, this.services);
    }

    createSubscriptionFromPassengersCount(passengersCount: number, expirationDate: Date): IBlueBenefitsSubscription {
        return new BookingBlueBenefitsSubscriptionModel(this._getPackageByPassengersCount(passengersCount),  expirationDate, this.services);
    }

    createSubscriptionFromBookingComments(bookingComments: IDotRezBookingComment[]): IBlueBenefitsSubscription | null {
        const bbSubscriptionComment = bookingComments.find(c => c.text.startsWith(BLUE_BENEFITS_BOOKING_SUBSCRIPTION_COMMENT_HEADER));
        if(!bbSubscriptionComment) {
            return null;
        }
        const parts = bbSubscriptionComment.text.split('|');
        const packageCode = parts[1];
        const expirationDate = this.services.time.parseIsoDate(parts[2]);
        const bbPackage = this.availableBlueBenefitsPackages.find(p => p.packageCode === packageCode);
        if(!bbPackage) {
            return this.noSubscription;
        }

        return new BookingBlueBenefitsSubscriptionModel(bbPackage, expirationDate, this.services);
    }

    getPackageBySsrCode(ssrCode: string): IBlueBenefitsPackage {
        const bbPackage = this.availableBlueBenefitsPackages.find(p => p.ssrType.ssrCode === ssrCode);
        if(!bbPackage) {
            throw new Error(`There is no Blue Benefits package for SSR ${ssrCode}`);
        }
        return bbPackage;
    }

    getActivationUrl(bookingNumericRecordLocator: string): string {
        let baseUrl = this.services.configuration.data.blueBenefits.activationUrl;
        if(!baseUrl.endsWith('/')) {
            baseUrl += '/';
        }

        return baseUrl + bookingNumericRecordLocator;
    }
}


