import React from 'react';
import {ISvgIconProps, SvgIcon} from './svg-icon.component';

export const NotCheckedIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 24 24" ratio={24/24}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(0)"><path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Z" fill="#f44336"/><path d="M9.131,7.717A1,1,0,1,1,7.717,9.131l-3-3.005-3,3.005A1,1,0,0,1,.293,7.717l3.005-3-3.005-3A1,1,0,0,1,1.707.293l3,3.005,3-3.005A1,1,0,0,1,9.131,1.707l-3.005,3Z" transform="translate(7.288 7.288)" fill="#fafafa"/></g>
        </SvgIcon>
    )
}
