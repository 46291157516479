import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {IonGrid} from "@ionic/react";
import {useServices} from "../../../../hooks/use-services.hook";
import {ISegmentViewModel} from "../../../../services/booking/models/segment/segment-view-model.interface";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../components/designator/designator.component";
import {CheckedIcon} from "../../../../assets/svg/checked.icon";
import {NotCheckedIcon} from "../../../../assets/svg/not-checked.icon";
import styled from "styled-components";
import {FootNoteComponent} from "../../../../components/foot-note/foot-note.component";
import { CardBox } from "../../../../basic-components/card/card.box";
import {
    ICheckInRestriction,
    ICheckInRestrictionAction
} from "../../../../services/booking/models/check-in-restrictions/check-in-restriction.interface";
import {OnlineCheckInValidationResultEnum} from "../../../../services/dialog-factory/dialog-factory.service.interface";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {VerticalSpacer} from "../../../../basic-components/spacing/vertical-spacer";

const PassengerInformationBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding-left: ${props => props.theme.spacing.large};
`

const PassengerNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
`

const RestrictionsContainerBox = styled.ul`
    padding-left: ${props => props.theme.spacing.extraLarge};
    margin: 0;
    width: 100%;
    >li {
        font-size: ${props => props.theme.fontSize.medium};
        line-height: 1;
        margin: 0;
        padding: 0;
      
    }
`

const OnlineCheckInSegmentValidationBox = styled(CardBox)`
    
`


const OnlineCheckPassengerValidationBox = styled.div`
    display: flex;
`

interface OnlineCheckPassengerValidationComponentProps {
    passengerFullName: string;
    restrictions: ICheckInRestriction[];
    onBeforeRestrictionActionClick: () => void;
}

const OnlineCheckPassengerValidationComponent: React.FC<OnlineCheckPassengerValidationComponentProps> = observer((props) => {
    const services = useServices();
    const isRestrictedForOnlineCheckIn = props.restrictions.length > 0;
    const renderIcon = () => {
        if(isRestrictedForOnlineCheckIn) {
            return (<NotCheckedIcon/>);
        } else {
            return (<CheckedIcon/>);
        }
    }

    const renderCheckInEligibilityStatus = () => {
        let text;
        if(isRestrictedForOnlineCheckIn) {
            text = services.language.translate('Not eligible for online check-in');
        } else {
            text = services.language.translate('Is eligible for online check-in');
        }

        return (
            <FootNoteComponent>
                {text}
            </FootNoteComponent>
        )
    }

    const renderRestrictionAction = (action: ICheckInRestrictionAction | undefined) => {
        if(!action) {
            return null;
        }

        const onRestrictionActionClick = async () => {
            props.onBeforeRestrictionActionClick();
            await action.execute();
        }

        return (
            <>
                <VerticalSpacer size={"small"}/>
                <PrimaryButton onClick={onRestrictionActionClick} fontSize={"medium"}>
                    {action.description}
                </PrimaryButton>
            </>
        )
    }

    const renderRestrictions = () => {
        if(!isRestrictedForOnlineCheckIn) {
            return null;
        }

        return (
            <RestrictionsContainerBox>
                {props.restrictions.map((restriction, index) => {
                    return (
                        <React.Fragment key={restriction.description}>
                            <li>
                                <div>
                                    {restriction.description}
                                </div>
                                {renderRestrictionAction(restriction.action)}
                            </li>
                            <VerticalSpacer size={"small"} isVisible={index < props.restrictions.length - 1}/>
                        </React.Fragment>
                    );
                })}
            </RestrictionsContainerBox>
        )
    }

    return (
        <OnlineCheckPassengerValidationBox>
            {renderIcon()}
            <PassengerInformationBox>
                <PassengerNameBox>
                    {props.passengerFullName}
                </PassengerNameBox>
                {renderCheckInEligibilityStatus()}
                <VerticalSpacer size={"medium"}/>
                {renderRestrictions()}
            </PassengerInformationBox>
        </OnlineCheckPassengerValidationBox>
    );
});


const OnlineCheckInSegmentValidationComponent: React.FC<{segment: ISegmentViewModel; onBeforeRestrictionActionClick: () => void;}> = observer((props) => {
    const eligiblePassengersForOnlineCheckIn = props.segment.passengers.filter(passengerSegment => passengerSegment.isValidForOnlineCheckIn);
    const restrictedPassengersForOnlineCheckIn = props.segment.passengers.filter(passengerSegment => !passengerSegment.isValidForOnlineCheckIn);

    return (
        <OnlineCheckInSegmentValidationBox>
            <DesignatorComponent displayMode={DesignatorDisplayMode.stationsNamesAndDate} designator={props.segment.designator}/>
            <VerticalSpacer size={"medium"}/>
            <div>
                {eligiblePassengersForOnlineCheckIn.map((passengerSegment, index) => {
                    return(
                        <React.Fragment key={passengerSegment.passengerKey}>
                            <OnlineCheckPassengerValidationComponent passengerFullName={passengerSegment.passengerFullName}
                                                                     restrictions={passengerSegment.getAllOnlineCheckInRestrictions()}
                                                                     onBeforeRestrictionActionClick={props.onBeforeRestrictionActionClick}/>
                            <VerticalSpacer size={"medium"}
                                            isVisible={index < eligiblePassengersForOnlineCheckIn.length - 1 || restrictedPassengersForOnlineCheckIn.length > 0}/>
                        </React.Fragment>
                    );
                })}
                {restrictedPassengersForOnlineCheckIn.map((passengerSegment, index) => {
                    return (
                        <React.Fragment key={passengerSegment.passengerKey}>
                            <OnlineCheckPassengerValidationComponent passengerFullName={passengerSegment.passengerFullName}
                                                                     restrictions={passengerSegment.getAllOnlineCheckInRestrictions()}
                                                                     onBeforeRestrictionActionClick={props.onBeforeRestrictionActionClick}/>
                            <VerticalSpacer size={"medium"}
                                            isVisible={index < restrictedPassengersForOnlineCheckIn.length - 1}/>
                        </React.Fragment>
                    );
                })}
            </div>

        </OnlineCheckInSegmentValidationBox>
    )
});


export const OnlinePreCheckInRestrictionsComponent: React.FC<{segment: ISegmentViewModel; onBeforeRestrictionActionClick: () => void;}> = observer((props) => {
    const restrictedPassengersForOnlineCheckIn = props.segment.passengers.filter(passengerSegment => passengerSegment.getPreCheckInRestrictions().length > 0);
    if(restrictedPassengersForOnlineCheckIn.length === 0) {
        return null;
    }
    return (
        <OnlineCheckInSegmentValidationBox>
            <DesignatorComponent displayMode={DesignatorDisplayMode.stationsNamesAndDate} designator={props.segment.designator}/>
            <IonGrid>
                {restrictedPassengersForOnlineCheckIn.map(passengerSegment => <OnlineCheckPassengerValidationComponent
                    key={passengerSegment.passengerKey}
                    passengerFullName={passengerSegment.passengerFullName}
                    restrictions={passengerSegment.getPreCheckInRestrictions()}
                    onBeforeRestrictionActionClick={props.onBeforeRestrictionActionClick}/>)}
            </IonGrid>

        </OnlineCheckInSegmentValidationBox>
    )
});

interface IncompletePassengersDetailsDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}

export const OnlineCheckInValidationDialogComponent: React.FC<IncompletePassengersDetailsDialogComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;
    const segments = booking.getAllSegments();

    const renderContinueButton = () => {
        const passengersSegments = booking.getAllPassengersSegments();
        if(passengersSegments.all(p => !p.isValidForOnlineCheckIn)) { // if all passengers have online check-in restriction
            if(passengersSegments.all(p => p.getAllOnlineCheckInRestrictions().all(p => !p.shouldBlockSavingTravelDocuments))) {
                //if none of the restrictions are because of document validation
                return (
                    <PrimaryButton onClick={() => props.dialogHandler.accept(OnlineCheckInValidationResultEnum.OnlySaveDocuments)}>
                        {services.language.translate(`Just save my travel documents`)}
                    </PrimaryButton>
                )
            }
            //if all passengers segments are restricted for online check-in
            return (
                <PrimaryButton onClick={() => props.dialogHandler.accept(OnlineCheckInValidationResultEnum.GoToHomePage)}>
                    {services.language.translate(`I will do the check-in later`)}
                </PrimaryButton>
            )
        }

        return (
            <PrimaryButton onClick={() => props.dialogHandler.accept(OnlineCheckInValidationResultEnum.ContinueWithEligiblePassengers)}>
                {services.language.translate(`Continue with passengers eligible for check-in`)}
            </PrimaryButton>
        )
    }

    const onBeforeRestrictionActionClick = () => {
        props.dialogHandler.reject();
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Online check-in validation')}
                                           dialogHandler={props.dialogHandler}/>


            <StandardDialogContentComponent>
                {segments.map(segment => {
                    return (
                        <React.Fragment key={segment.segmentKey}>
                            <OnlineCheckInSegmentValidationComponent segment={segment} onBeforeRestrictionActionClick={onBeforeRestrictionActionClick}/>
                            <VerticalSpacer/>
                        </React.Fragment>
                    )
                })}
            </StandardDialogContentComponent>


            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                {renderContinueButton()}
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
});
