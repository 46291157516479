import {ISsrFeeModel} from "./ssr-fee-model.interface";
import {ISsrType} from "../../../../../ssr-types/ssr-types.service.interface";
import {Price} from "../../../../../currency/price";
import {NullableString} from "../../../../../../types/nullable-types";
import {FeeTypeEnum} from "../../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {ShoppingCartFeeBaseModel} from "../shopping-cart-fee-base.model";

export class SsrFeeModel extends ShoppingCartFeeBaseModel implements ISsrFeeModel {
    constructor(public readonly ssrIndex: number,
                public readonly id: string,
                public readonly ssrType: ISsrType,
                public readonly feeCode: NullableString,
                public readonly ssrNumber: number,
                public readonly createdDate: Date,
                public readonly initialPrice: Price | null,
                public readonly currentPrice: Price,
                private readonly _quantity: number = 1) {
        super();
    }

    get feeGroupKey(): string {
        return `${this.ssrType.ssrCode}_${this.initialPrice?.toString()}_${this.currentPrice.toString()}`;
    }

    get feeType(): FeeTypeEnum {
        return FeeTypeEnum.SsrFee;
    }

    get description(): string {
        return this.ssrType.name;
    }

    get details(): NullableString {
        return this.ssrType.details;
    }

    get quantity(): number {
        return this._quantity;
    }

    get preferredOrderInLists(): number {
        return this.ssrType.preferredOrderInLists;
    }

    get shouldBeDisplayedInShoppingCart(): boolean {
        return true;
    }
}
