import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {IDropDownDialogOptions} from "./drop-down-dialog-options";
import {IDialogConfirmationHandler} from "../../../../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../../../../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../modal/content/standard-dialog-content.component";
import {useServices} from "../../../../../hooks/use-services.hook";
import {IDropDownController} from "../../models/drop-down-controller.interface";
import {IServiceFactory} from "../../../../../services/service-factory.interface";
import {StandardDialogFooterComponent} from "../../../../modal/footer/standard-dialog-footer.component";

interface DropDownModalDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    title?: string | React.ReactElement;
    renderContent: () => React.ReactElement;
    renderHeader: () => (React.ReactElement | null);
    renderFooter: () => (React.ReactElement | null);

}

const DropDownModalDialogComponent: React.FC<DropDownModalDialogComponentProps> = observer((props) => {

    const renderFooter = () => {
        const footer = props.renderFooter();
        if(!footer) {
            return null;
        }

        return (
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                {footer}
            </StandardDialogFooterComponent>
        )
    }
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={props.title} dialogHandler={props.dialogHandler}>
                {props.renderHeader()}
            </StandardDialogHeaderComponent>
            <StandardDialogContentComponent>
                {props.renderContent()}
            </StandardDialogContentComponent>
            {renderFooter()}
        </StandardDialogPageComponent>
    );
})

enum DropDownDialogStatus {
    NotOpened,
    Opened,
}


interface DropDownWithDialogComponentProps {
    dropDownController: IDropDownController;
    renderContent: () => React.ReactElement;
    fieldLabel?: string | React.ReactElement;
    dialogOptions?: IDropDownDialogOptions;

}

class DialogHandler {
    constructor(private readonly props: DropDownWithDialogComponentProps,
                private readonly services: IServiceFactory) {
    }

    private _dialogStatus: DropDownDialogStatus = DropDownDialogStatus.NotOpened;
    private _dialog: IDialogConfirmationHandler | null = null;

    public openDialog(): void {
        if(this._dialogStatus === DropDownDialogStatus.Opened) {
            return;
        }

        this._dialogStatus = DropDownDialogStatus.Opened;

        if(this.props.dialogOptions?.breakpoints) {
            this.services.dialog.showSheetModalDialog({
                breakpoints: this.props.dialogOptions.breakpoints,
                ...this._getDialogCommonProps()
            });
        } else {
            this.services.dialog.showStandardDialog({
                ...this._getDialogCommonProps()
            });
        }
    }

    public closeDialog(): void {
        this._dialog?.accept();
    }

    private _renderDialogHeader = () => {
        if(this.props.dialogOptions?.renderHeader) {
            return this.props.dialogOptions.renderHeader();
        }
        return null;
    }

    private _renderDialogContent = () => {
        return this.props.renderContent();
    }

    private _renderDialogFooter = () => {
        if(this.props.dialogOptions?.renderFooter) {
            return this.props.dialogOptions.renderFooter();
        }
        return null;
    }


    private _getDialogCommonProps = () => {
        return {
            render: (dlg: IDialogConfirmationHandler) => {
                this._dialog = dlg;
                return (<DropDownModalDialogComponent dialogHandler={dlg}
                                                      title={this.props.dialogOptions?.preferredTitle ?? this.props.fieldLabel}
                                                      renderContent={this._renderDialogContent}
                                                      renderHeader={this._renderDialogHeader}
                                                      renderFooter={this._renderDialogFooter}/>)
            },

            onReject: async () => {
                this.props.dropDownController.closeDropDown();
            }
        };
    }
}


export const DropDownWithDialogComponent: React.FC<DropDownWithDialogComponentProps> = observer((props) => {
    const services = useServices();
    const [dialogHandler] = useState(new DialogHandler(props, services));

    useEffect(() => {
        dialogHandler.openDialog();
        return () => {
            dialogHandler.closeDialog();
        }
    }, [dialogHandler]);

    return (
        <></>
    );
});
