import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const SkiingIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} ratio={24.003/20.334} {...props} viewBox="0 0 24.003 20.334">
            <g fill={props.color? props.color: "currentColor"} id="Group_1703" data-name="Group 1703" transform="translate(-442 -440.033)">
                <path id="Path_931" data-name="Path 931" d="M465.471,524.324a.532.532,0,0,0-.532.532,2.157,2.157,0,0,1-2.155,2.155H451.769l2.733-3.87a.964.964,0,0,0,.008-.993l-1.861-2.886.96-1.142h-4.466a1.416,1.416,0,0,0,.249.893l2.822,3.754-2.733,4.243h-6.948a.532.532,0,0,0,0,1.063h20.253A3.222,3.222,0,0,0,466,524.855.532.532,0,0,0,465.471,524.324Z" transform="translate(0 -67.707)" />
                <circle id="Ellipse_78" data-name="Ellipse 78" cx="2.126" cy="2.126" r="2.126" transform="translate(455.77 440.033)" />
                <path id="Path_932" data-name="Path 932" d="M448.118,473.329l.881.606a.532.532,0,0,0,.833-.437v-.081h9.6l.908-1.079v.629c0,.6.146,1.022.989,1.022h3.936a1.021,1.021,0,1,0,0-2.042H462.4l.065-2.009a1.659,1.659,0,0,0-.689-1.254l-.962-.824a1.252,1.252,0,0,0-1.765.086l-3.791,4.42h-5.422v-.081a.532.532,0,0,0-.833-.437l-.881.606a.532.532,0,0,0,0,.876Z" transform="translate(-5.105 -23.849)" />
            </g>
        </SvgIcon>
    )
}
