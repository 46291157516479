import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";

const ManageMyBookingSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large}
`

export const ManageMyBookingSectionTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.xxxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    text-transform: uppercase;
    @media ${props => props.theme.mediaQuery.sAndBelow} {
      font-size: ${props => props.theme.fontSize.xLarge};
    }
`

interface ManageMyBookingSectionComponentProps extends PropsWithChildren {
    title: string | React.ReactElement;
}

export const ManageMyBookingSectionComponent: React.FC<ManageMyBookingSectionComponentProps> = observer((props) => {
    return (
        <ManageMyBookingSectionBox>
            <ManageMyBookingSectionTitleBox>
                {props.title}
            </ManageMyBookingSectionTitleBox>
            {props.children}
        </ManageMyBookingSectionBox>
    );
});
