import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const MinusCircleIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.6} {...props} viewBox="0 0 16 16" ratio={1}>
            <g fill={props.color? props.color: "currentColor"}>
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </g>
        </SvgIcon>
    )
}
