import {Price} from "../../../../currency/price";
import {IRoundTripLowFareReader} from "../../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {IFareToSell} from "../../booking-view-model.interface";
import {IDotRezPartialBookingSessionData} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {MoveDepartureJourneyMutation} from "../../mutation-actions/sell-trip/manage-my-booking/disruption-flow/move-departure-journey.mutation";
import {MoveReturnJourneyMutation} from "../../mutation-actions/sell-trip/manage-my-booking/disruption-flow/move-return-journey.mutation";
import {DisruptedFlightsChangeLowFareReaderDecorator} from "./disrupted-flights-change-low-fare-reader.decorator";
import {BookingModel} from "../../booking.model";
import {TimeSpan} from "../../../../../types/time-span";
import {IFlightsChangeStrategy} from "./flights-change-strategy.interface";
import {IServiceFactory} from "../../../../service-factory.interface";

export class DisruptedFlightsChangeStrategy implements IFlightsChangeStrategy {

    constructor(private readonly booking: BookingModel) {
        const searchController = this.booking.flightSearchController;
        searchController.departureFlightScheduleFilter = {
            shouldFilterOutDate: (date: Date) => this._shouldFilterOutDepartureScheduleDate(date)
        };

        searchController.returnFlightScheduleFilter = {
            shouldFilterOutDate: (date: Date) => this._shouldFilterOutReturnScheduleDate(date)
        }
    }

    get services(): IServiceFactory {
        return this.booking.services;
    }

    private _shouldFilterOutScheduleDate(scheduleDate: Date, flightDate: Date): boolean {
        flightDate = this.services.time.makeShortDate(flightDate);
        const config = this.services.configuration.data.moveFlight.scheduleRestrictions;

        if(scheduleDate.getTime() <  flightDate.getTime() - TimeSpan.fromDays(config.daysBeforeFlight).totalMilliseconds) {
            return true;
        }

        return scheduleDate.getTime() > flightDate.getTime() + TimeSpan.fromDays(config.daysAfterFlight + 1).totalMilliseconds;


    }

    private _shouldFilterOutDepartureScheduleDate(scheduleDate: Date): boolean {
        const designator = this.booking.initialBookingSnapshot.departureJourney?.designator;
        if(!designator) {
            return false;
        }

        return this._shouldFilterOutScheduleDate(scheduleDate, designator.departureDate);
    }

    private _shouldFilterOutReturnScheduleDate(scheduleDate: Date): boolean {
        const designator = this.booking.initialBookingSnapshot.returnJourney?.designator;
        if(!designator) {
            return false;
        }

        return this._shouldFilterOutScheduleDate(scheduleDate, designator.departureDate);
    }

    reduceDepartureFare(price: Price): Price {
        return this.services.currency.createPrice(0, price.currency);
    }

    reduceReturnFare(price: Price): Price {
        return this.services.currency.createPrice(0, price.currency);
    }

    applyLowFareReaderDecorator(getOriginalLowFareReader: () => IRoundTripLowFareReader): IRoundTripLowFareReader {
        return new DisruptedFlightsChangeLowFareReaderDecorator(getOriginalLowFareReader,
                              price => this.reduceDepartureFare(price),
                                price => this.reduceReturnFare(price));
    }

    async sellDepartureJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        const mutation = new MoveDepartureJourneyMutation(this.booking, fareToSell, onAfterSell);
        this.booking.mutationsManager.startSellDepartureJourneyMutation(mutation);
        await mutation.waitForMutation();
    }

    async sellReturnJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        const mutation = new MoveReturnJourneyMutation(this.booking, fareToSell, onAfterSell);
        this.booking.mutationsManager.startSellReturnJourneyMutation(mutation);
        await mutation.waitForMutation();
    }
}
