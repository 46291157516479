import React from "react";
import {observer} from "mobx-react-lite";
import {IAeroitaliaInvoiceFields} from "../../../../services/booking/models/invoice/aeroitalia/aeroitalia.invoice-fields.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {ItalyCustomerTypeEnum} from "../../../../services/booking/models/invoice/aeroitalia/italy-customer-type.enum";
import {StringFieldEditorComponent} from "../../../../components/forms/string-field/string-field-editor.component";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {StandardDropDownListItemModel} from "../../../../components/forms/standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";
import {StandardDropDownComponent} from "../../../../components/forms/standard-drop-down/standard-drop-down.component";

export const AeroitaliaInvoiceCitySelectorComponent: React.FC<{fields: FormFields<IAeroitaliaInvoiceFields>}> = observer((props) => {
    const services = useServices();

    if(props.fields.customerType.value === ItalyCustomerTypeEnum.NonItalianCustomer) {
        return (
            <StringFieldEditorComponent field={props.fields.city}/>
        );
    }

    let items: StandardDropDownListItemModel<string>[] = [];
    if(props.fields.region.value) {
       items = services.regions.getItalianRegionCities(props.fields.region.value).map(city => new StandardDropDownListItemModel<string>(city, city));
    }

    return (
        <StandardDropDownComponent field={props.fields.city}
                                   items={items}/>
    );
});
