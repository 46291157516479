import {ILayoutService} from "./layout.service.interface";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {LayoutDetection} from "./layout-detection";

export class LayoutService extends ServiceBase implements ILayoutService {

    constructor(services: IServiceFactory) {
        super(services);
        this._layoutDetection = new LayoutDetection();
    }

    private readonly _layoutDetection: LayoutDetection;

    get shouldUseWebappLayout(): boolean {
        return this._layoutDetection.shouldUseWebappLayout;
    }

    get isInSmallScreenView(): boolean {
        return this.services.mediaQuery.sAndBelow;
    }

    switchLayoutMode(): void {
        if(this.shouldUseWebappLayout) {
            localStorage.setItem('application.forceHybridLayout', 'true');
        } else {
            localStorage.setItem('application.forceHybridLayout', 'false');
        }

        this.services.window.reload();
    }



}
