import React from "react";
import {StringFieldEditorComponent} from "../string-field/string-field-editor.component";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableString} from "../../../types/nullable-types";
import {observer} from "mobx-react";
import {FieldEditorCommonProps} from "../field-editor-common.props";

interface EmailFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<NullableString>;
    className?: string;
}
export const EmailFieldEditorComponent: React.FC<EmailFieldEditorComponentProps> = observer((props) => {
    return (
        <StringFieldEditorComponent field={props.field} inputMode="email" className={props.className}/>
    );
});
