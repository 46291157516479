import {IBundleIncludedSsrViewModel} from "./bundle-included-ssr-view-model.interface";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";

export class BundleIncludedSsrModel implements IBundleIncludedSsrViewModel {
    constructor(public readonly ssrType: ISsrType,
                private readonly ssrAliasesInBundle: ISsrType[]) {


    }

    get quantityToDisplay(): number {
        if(this.ssrType.aliasFor) {
            return 0;
        }
        return 1 + this.ssrAliasesInBundle.length;
    }
}

