import styled from "styled-components";
import React from "react";
import {observer} from "mobx-react";
import {BundleComponent} from "./bundle.component";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {IFlightDesignatorViewModel} from "../../../../../services/booking/models/designator/flight-designator-view-model.interface";

const JourneyBundlesBox = styled.div`
    display: flex;
    flex-direction: row;
      
    gap: ${props => props.theme.spacing.large};
    width: 100%;
    @media ${(props) => props.theme.mediaQuery.lAndBelow} {
      flex-direction: column;
      align-items: center;
    }
`

interface JourneyBundlesComponentProps {
    journeyDesignator: IFlightDesignatorViewModel;
    availableBundles: IBundleViewModel[];
    disableNotSelected: boolean;
    onSellBundleClick?: (bundle: IBundleViewModel) => void;
}
export const JourneyBundlesComponent: React.FC<JourneyBundlesComponentProps> = observer( (props) => {

    return (
        <JourneyBundlesBox>
            {props.availableBundles.map(bundle => <BundleComponent key={bundle.bundleCode}
                                                                   bundle={bundle}
                                                                   onSellBundleClick={props.onSellBundleClick}
                                                                   disableNotSelected={props.disableNotSelected}/>)}
        </JourneyBundlesBox>
    );
});
