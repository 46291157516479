import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const DiscountDiagonalTagIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.3} {...props} viewBox="0 0 10 10">
            <g fill={props.color? props.color: "currentColor"} transform="translate(0)"><path d="M8.958,0H6.315A1.863,1.863,0,0,0,4.989.549L.355,5.183a1.214,1.214,0,0,0,0,1.716L3.1,9.645a1.214,1.214,0,0,0,1.716,0L9.451,5.011A1.888,1.888,0,0,0,10,3.685V1.042A1.042,1.042,0,0,0,8.958,0Z" transform="translate(0)"/><path d="M.208,3.75A.208.208,0,0,1,0,3.542V.208a.208.208,0,0,1,.417,0V3.542A.208.208,0,0,1,.208,3.75Z" transform="translate(4.167 3.751)" fill="#fafafa"/><path d="M0,.625a.625.625,0,1,1,.625.626A.625.625,0,0,1,0,.625Z" transform="translate(2.5 4.584)" fill="#fafafa"/><path d="M0,.625a.625.625,0,1,1,.625.626A.625.625,0,0,1,0,.625Z" transform="translate(5 5.417)" fill="#fafafa"/></g>
        </SvgIcon>
    )
}
