import { observer } from "mobx-react";
import React from "react";
import styled from 'styled-components';
import { PlaneDepartNoLineIcon } from "../../../../assets/svg/plane-depart-no-line.icon";
import { useServices } from "../../../../hooks/use-services.hook";
import { IJourneyHistoryViewModel } from "../../../../services/booking-history/models/journey-history-view-model.interface";

const NextFlightHeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${props => props.theme.spacing.large};
`
const FlightDetailsBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    line-height: 1;
`

const FlightDepartureBox = styled.div`
    display: flex;
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.medium};
`

const FlightDepartureDateBox = styled.div`
    
`

const FlightDepartureTimeBox = styled.div`
    margin-left: ${props => props.theme.spacing.small};
    display: flex;
    align-items: center;
`
const RecordLocatorBox = styled.div`
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const StationsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    svg {
        margin: 0 ${props => props.theme.spacing.large};
    }
`
interface FutureFlightHeaderComponentProps {
    futureFlight: IJourneyHistoryViewModel
}
export const NextFlightHeaderComponent: React.FC<FutureFlightHeaderComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <NextFlightHeaderBox>
            <FlightDetailsBox>
                <FlightDepartureBox>
                    <FlightDepartureDateBox>
                        {services.time.formatUserFriendlyDate(props.futureFlight.designator.departureDate)}
                    </FlightDepartureDateBox>

                    <FlightDepartureTimeBox>
                        {services.time.formatHHmm(props.futureFlight.designator.departureDate)}
                    </FlightDepartureTimeBox>
                </FlightDepartureBox>
                <RecordLocatorBox>
                    {props.futureFlight.recordLocator}
                </RecordLocatorBox>
            </FlightDetailsBox>

            <StationsBox>
                {props.futureFlight.designator.origin.name}
                <PlaneDepartNoLineIcon size={0.8} />
                {props.futureFlight.designator.destination.name}
            </StationsBox>
        </NextFlightHeaderBox>
    )
})
