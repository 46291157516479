import styled, {css} from "styled-components";
import {PrimaryLabelBox} from "../../basic-components/label/primary-label.box";

export const FieldLabelBox = styled(PrimaryLabelBox)<{hasError: boolean}>`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    margin-bottom: ${props => props.theme.spacing.small};
    ${
        props => props.hasError 
                    ? css`
                      color: ${props => props.theme.colors.danger};
                    `
                    : ''
    }
`
