import {ParentRouteModel} from "../models/parent-route.model";
import {FlightSearchRoutes} from "./flight-search/flight-search.routes";
import {BookingRoutes} from "./booking/booking.routes";
import {RouteBaseModel} from "../models/route-base.model";
import React from "react";
import {CheckInRoutes} from "./check-in/check-in.routes";
import {HomepageRoute} from "./home/homepage.route";
import {RetrieveBookingHybridPage} from "../../../pages/retrieve-booking/retrieve-booking.hybrid.page";
import {RootRouteModel} from "../models/root-route.model";
import {FlightItineraryPage} from "../../../pages/booking-flow/flight-itinerary/flight-itinerary.page";
import {MyAccountDashboardPage} from "../../../pages/my-account/my-account-dashboard.page";
import {SettingsPage} from "../../../pages/settings/settings.page";
import {SampleRouteWithParamsPage} from "../../../pages/sample-route-with-params.page";
import {ISampleRouteParams} from "./sample-route-with-params/sample-route-with-params";
import {UserRoutes} from "./user/user.routes";
import {ManageMyBookingRoutes} from "./manage-my-booking/manage-my-booking.routes";
import {IApplicationRoutes} from "./application-routes.interface";
import {IBookingRoutes} from "./booking/booking.routes.interface";
import {DeepLinkRoutes} from "./deeplink/deep-link.routes";
import {CheckInSearchPage} from "../../../pages/retrieve-booking/check-in-search.page";
import {ManageMyBookingSearchPage} from "../../../pages/retrieve-booking/mmb-search.page";

export class ApplicationRoutes extends ParentRouteModel implements IApplicationRoutes {
    home = new HomepageRoute(this.services);
    flightSearch = new FlightSearchRoutes(this.services);
    booking: IBookingRoutes = new BookingRoutes(this.services);
    manageMyBooking = new ManageMyBookingRoutes(this.services);
    checkIn = new CheckInRoutes(this.services);
    bookingRetrieve = new RootRouteModel('/booking-retrieve', this.services, () => <RetrieveBookingHybridPage/>);
    flightItinerary = new RootRouteModel('/flight-itinerary', this.services, () => <FlightItineraryPage/>);
    myAccount = new RootRouteModel('/my-account', this.services, () => <MyAccountDashboardPage/>);
    settings =  new RootRouteModel('/settings', this.services, () => <SettingsPage/>);
    user = new UserRoutes(this.services);
    deepLink = new DeepLinkRoutes(this.services);
    checkInSearch = new RootRouteModel('/check-in-search', this.services, () => (<CheckInSearchPage/>));
    mmbSearch = new RootRouteModel('/mmb-search', this.services, () => (<ManageMyBookingSearchPage/>));
    /**
     * This route is for demonstrating the usage of routes with params
     */
    sampleRouteWithParams =  new RootRouteModel<ISampleRouteParams>('/test/:id', this.services, (routeParams) => <SampleRouteWithParamsPage paramFromRoute={routeParams.id}/>);

    protected _getDefaultChildRoute(): RouteBaseModel | null {
        return null;
    }
}



