import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";


const MessageContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 100px;
    font-size: ${props => props.theme.fontSize.large};
`


const TextBox = styled.div`
    margin-bottom: ${props => props.theme.spacing.large};
`

interface EmptyDropDownMessageComponentProps {
    message: string | React.ReactElement;
    className?: string;
}

export const EmptyDropDownMessageComponent: React.FC<EmptyDropDownMessageComponentProps> = observer((props) => {

    return (
        <MessageContainerBox className={props.className}>
            <TextBox>
                {props.message}
            </TextBox>
        </MessageContainerBox>
    )
});
