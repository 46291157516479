import {PaymentMethodBaseModel} from "../payment-method-base.model";
import {Price} from "../../../../../currency/price";
import {PaymentMethodCategoryEnum} from "../payment-method-category.enum";

export abstract class CreditCardPaymentModel extends PaymentMethodBaseModel {
    get isSupportedOnCurrentPlatform(): boolean {
        return true;
    }

    get category(): PaymentMethodCategoryEnum {
        return PaymentMethodCategoryEnum.CreditCard;
    }


    get availableAmountInBookingCurrency(): Price {
        return this.booking.balanceDue;
    }
}