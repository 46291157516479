import React from "react";
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const GearIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2} {...props} viewBox="0 0 26 26" ratio={26/26}>
            <g fill={props.color? props.color: "currentColor"}>
            <path d="M13.1,26a2.367,2.367,0,0,1-2.364-2.363V23.53a1.938,1.938,0,0,0-1.276-1.785,1.95,1.95,0,0,0-2.151.39l-.071.071A2.378,2.378,0,0,1,5.56,22.9,2.366,2.366,0,0,1,3.2,20.534a2.378,2.378,0,0,1,.693-1.672l.071-.071a1.966,1.966,0,0,0-1.394-3.333h-.2a2.364,2.364,0,0,1,0-4.728H2.47A1.938,1.938,0,0,0,4.254,9.454,1.941,1.941,0,0,0,3.865,7.3l-.071-.07A2.383,2.383,0,0,1,3.1,5.559,2.366,2.366,0,0,1,5.466,3.194a2.378,2.378,0,0,1,1.672.693l.071.071a1.95,1.95,0,0,0,2.151.39h.094a1.947,1.947,0,0,0,1.182-1.784v-.2a2.364,2.364,0,0,1,4.728,0V2.47A1.964,1.964,0,0,0,18.7,3.864l.071-.071A2.378,2.378,0,0,1,20.44,3.1a2.366,2.366,0,0,1,2.365,2.365,2.378,2.378,0,0,1-.693,1.672l-.071.071a1.94,1.94,0,0,0-.39,2.151v.094a1.948,1.948,0,0,0,1.785,1.182h.2a2.364,2.364,0,0,1,0,4.728h-.106a1.966,1.966,0,0,0-1.4,3.333l.071.071a2.378,2.378,0,0,1,.693,1.672A2.366,2.366,0,0,1,20.534,22.8a2.378,2.378,0,0,1-1.672-.693l-.071-.071a1.966,1.966,0,0,0-3.333,1.4v.2A2.366,2.366,0,0,1,13.1,26ZM13,8a5,5,0,1,0,5,5A5.006,5.006,0,0,0,13,8Z" transform="translate(0 0.001)"/>
            </g>
        </SvgIcon>
    )
}
