import React from "react";
import styled from "styled-components";
import {observer} from "mobx-react";

const FormTitleContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
`

const FormTitleTextBox =  styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.xxxLarge};
    line-height: 1;
    margin-bottom: 10px;
`

interface FormTitleComponentProps {
    title: string;
}
export const FormTitleComponent: React.FC<FormTitleComponentProps> = observer((props) => {
    return (
        <FormTitleContainerBox>
            <FormTitleTextBox>
                {props.title}
            </FormTitleTextBox>
        </FormTitleContainerBox>
    )
});
