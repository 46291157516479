import React from 'react';
import {IServiceFactory} from "../../../service-factory.interface";
import { SeatExtraLegroomLargeIcon } from "./seat-extra-legroom-ssr.large-icon";
import { SeatExtraLegroomSmallIcon } from "./seat-extra-legroom-ssr.small-icon";
import {SeatSsrTypeBase} from "./seat-ssr-type-base";

export default class StexSsrType extends SeatSsrTypeBase {
    constructor(services: IServiceFactory) {
        super('STEX', services);
    }
    get smallIcon(): React.ReactElement | null {
        return (<SeatExtraLegroomSmallIcon />);
    }

    get largeIcon(): React.ReactElement | null {
        return (<SeatExtraLegroomLargeIcon />);
    }
}
