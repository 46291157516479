import {NullableNumber} from "../../../../types/nullable-types";
import {IServiceFactory} from "../../../service-factory.interface";
import {TimeSpan} from "../../../../types/time-span";

export class SsrConfigurationPeriod {
    constructor(minHoursToUseBeforeFlight: number,
                maxHoursToUseBeforeFlight: NullableNumber,
                protected services: IServiceFactory) {
        this.minTimeToUseBeforeFlight = TimeSpan.fromMinutes(minHoursToUseBeforeFlight * 60);
        this.maxTimeToUseBeforeFlight = maxHoursToUseBeforeFlight ? TimeSpan.fromMinutes(maxHoursToUseBeforeFlight * 60) : null;
    }

    public readonly minTimeToUseBeforeFlight: TimeSpan;
    public readonly maxTimeToUseBeforeFlight: TimeSpan | null;

    canBeUsed(flightDate: Date) {
        const maxDateBeforeFlight = flightDate.getTime() - this.minTimeToUseBeforeFlight.totalMilliseconds;
        const currentDate = this.services.time.currentDate.getTime();
        if(this.maxTimeToUseBeforeFlight) {
            const minDateBeforeFlight = flightDate.getTime() - this.maxTimeToUseBeforeFlight.totalMilliseconds;
            return minDateBeforeFlight <= currentDate && currentDate <= maxDateBeforeFlight;
        } else {
            return currentDate <= maxDateBeforeFlight;
        }

    }
}
