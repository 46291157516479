import {Price} from "../../../currency/price";
import {NullableString} from "../../../../types/nullable-types";
import {BundleIncludedSsrModel} from "./bundle-included-ssr.model";
import {BookingModel} from "../booking.model";
import {Check} from "../../../../types/type-checking";

export class BundlePriceModel {
    constructor(public readonly price: Price,
                public readonly passengerType: NullableString,
                public readonly includedSsrs: BundleIncludedSsrModel[]) {
    }

    computePriceForAllPassengers(booking: BookingModel): Price {
        let passengersCount;
        if(Check.isNullOrUndefined(this.passengerType)) {
            passengersCount = booking.passengers.countAllPassengers()
        } else {
            passengersCount = booking.passengers.countPassengerType(this.passengerType);
        }

        return this.price.multiply(passengersCount);
    }
}
