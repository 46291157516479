import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IonModal} from "@ionic/react";
import {
    IPopupDialogRenderer,
} from "../../services/dialog/dialog.service.interface";

interface ModalBoxProps {
    maxHeight?: string;
    height?: string;
}

const IonModalBox = styled(IonModal)<ModalBoxProps>`
    
    --max-height: ${props => props.maxHeight || '50%'};
    ${props => props.height ? css`height: ${props.height}` : ''}
    --max-width: calc(100% - ${props => props.theme.spacing.pageDefaultPaddingPx} - ${props => props.theme.spacing.pageDefaultPaddingPx});
    --border-style: solid;
    --border-width: 1px;
    --border-color: ${props => props.theme.colors.medium};
    --border-radius: ${props => props.theme.border.defaultRadius};
    --backdrop-opacity: 0.7;
    &::part(backdrop){
      background-color: ${props => props.theme.colors.medium};
      
    }
`

interface PopUpDialogComponentProps extends PropsWithChildren {
    renderer: IPopupDialogRenderer;
    maxHeight?: string;
    height?: string;
    /**
     * true if the popup can be closed by clicking on the backdrop overlay
     */
    backdropDismiss?: boolean;
}

export const PopUpDialogComponent: React.FC<PopUpDialogComponentProps> = observer((props) => {



    const captureElementRef = (element: any) => {
        props.renderer.attachElementRef(element)
    }

    const onDismissHandler = (event: any) => {
        if(event?.detail?.role === 'backdrop') {
            props.renderer.onBackdropDismiss();
        }
    }


    return (
        <IonModalBox key={props.renderer.id} id={props.renderer.id}
                             maxHeight={props.maxHeight}
                             height={props.height}
                             isOpen={props.renderer.isOpen}
                             keyboardClose={false}
                             showBackdrop={true}
                             backdropDismiss={props.backdropDismiss ?? false}
                             ref={captureElementRef}
                             onDidDismiss={onDismissHandler}>
            {props.children}
        </IonModalBox>
    );
});
