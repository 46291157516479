import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';

export const PbrdSsrSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.2} {...props} viewBox="0 0 22 19">
            <g fill={props.color ? props.color : "currentColor"} transform="translate(0)"><circle cx="1.706" cy="1.706" r="1.706" transform="translate(16.269 0)"/><path d="M7.035,11.818a.912.912,0,0,0,.379,1.137.845.845,0,0,0,1.137-.569h0L9.879,8.406,8.552,6.7Z" transform="translate(6.212 5.973)"/><path d="M20.353,7.386,17.889,5.111l-1.137-1.9a1.182,1.182,0,0,0-.948-.569l-1.137-.379a2.563,2.563,0,0,0-1.706,0L10.875,3.594a.186.186,0,0,0-.19.19L8.6,6.627a1.209,1.209,0,0,0,0,1.137l-.948,2.275a.662.662,0,0,0-.379-.19L6.135,9.281a1.27,1.27,0,0,0-1.517.569h0L2.534,14.4A.979.979,0,0,0,3.1,15.727h0l.758.379a1.465,1.465,0,0,1,1.517-1.327c.19,0,.569.19.758.19l.379-.758h0L9.548,7.954c.19,0,.379-.19.569-.379l1.9-2.654,1.327-.758-.948,4.171a1.751,1.751,0,0,0,.569,1.706l2.085,2.654,1.137,3.6a.9.9,0,0,0,1.706-.569h0l-1.137-3.791c0-.19,0-.19-.19-.19l-1.327-1.9.948-3.981.19.379a.186.186,0,0,0,.19.19l2.654,2.275a.856.856,0,0,0,1.137,0c.379-.379.19-.948,0-1.327Z" transform="translate(2.172 1.875)"/><circle cx="0.758" cy="0.758" r="0.758" transform="translate(6.624 17.412)"/><path d="M.779,3.358H5.708a.408.408,0,0,0,.379-.379A.408.408,0,0,0,5.708,2.6H.779A.408.408,0,0,0,.4,2.979.408.408,0,0,0,.779,3.358Z" transform="translate(0.358 2.321)"/><path d="M5.308,5.7H.379a.379.379,0,0,0,0,.758H5.308a.379.379,0,0,0,0-.758Z" transform="translate(0 5.089)"/><path d="M1.5,4.579a.408.408,0,0,0,.379.379H6.808a.408.408,0,0,0,.379-.379c0-.19,0-.379-.19-.379H1.879A.408.408,0,0,0,1.5,4.579Z" transform="translate(1.344 3.75)"/></g>
        </SvgIcon>
    )
}
