import styled, {css} from "styled-components";

export const BagContainerBox = styled.div<{isSelected: boolean}>`
    display: flex;
    flex-direction: column;
    border-radius: ${props => props.theme.border.defaultRadius};
    align-items: center;
    flex-basis: 100%;
    margin-right: ${props => props.theme.spacing.large};
    padding: ${props => props.theme.spacing.small};
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.secondary};
    background-color: transparent;
    cursor: ${props => props.isSelected ? "default" : "pointer"};

    &:last-of-type {
      margin-right: 0;
    }
    
    ${
    props => props.isSelected
        ? css`
                  border: solid 2px ${props => props.theme.colors.success};
            `
        : css`
                  border: solid 1px rgba(${props => props.theme.colors.mediumRgb}, 0.75);
            `

    }
`
