import styled from "styled-components";

export const IncludedBagsBox = styled.div`
    background-color: ${props => props.theme.colors.warning};
    color: ${props => props.theme.colors.warningContrast};
    text-align: center;
    font-size:  ${props => props.theme.fontSize.xxxSmall};
    font-weight:  ${props => props.theme.fontWeight.bold};
    line-height: 1;
    padding: 4px;
    border-radius: 2px;
    text-transform: uppercase;
`
