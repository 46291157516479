import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {VerticalSpacer} from "../../../basic-components/spacing/vertical-spacer";
import {NullableString} from "../../../types/nullable-types";
import {IExtrasSectionRenderer} from "./base-renderers/extras-section-renderer.interface";
import {IExtrasSectionsRenderersFactory} from "./base-renderers/extras-sections-renderers-factory.interface";
import {useServices} from "../../../hooks/use-services.hook";

const PageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const ExtrasSelectionPageLargeScreenContent: React.FC<{sectionsFactory: IExtrasSectionsRenderersFactory}> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;
    const [currentExpandedSection, setCurrentExpandedSection] = useState<NullableString>(null);
    const sectionsFactory = props.sectionsFactory;

    const sections = sectionsFactory.getExtrasSections().filter(s => s.shouldRender());

    const onExpandHandler = (id: string, section: IExtrasSectionRenderer) => {
        setCurrentExpandedSection(id);
        services.analytics.extrasSelectionEvents.logSelectItem(booking, section.ssrTypes.map( s => s.ssrCode));
    }

    const renderSection = (section: IExtrasSectionRenderer) => {
        return (
          <React.Fragment key={section.id}>
              {section.renderForLargeScreen({
                  currentExpandedSection: currentExpandedSection,
                  onExpand: (id: string) => onExpandHandler(id, section)
              })}
              <VerticalSpacer/>
          </React.Fragment>
        );
    }

    return (
        <PageContentBox>
            {sections.map(renderSection)}
        </PageContentBox>

    );
});
