import {SsrTypeBase} from "../../ssr-type-base";
import {IServiceFactory} from "../../../service-factory.interface";
import { Ins1SsrLargeIcon } from "./ins1.ssr.large-icon";

export default class Ins1Ssr extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('INS1', services);
    }

    get largeIcon() {
        return (<Ins1SsrLargeIcon/>);
    }
}
