import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const XIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.3} {...props} viewBox="0 0 13.185 13.185" ratio={13.185/13.185}>
            <g fill={props.color? props.color: "currentColor"}><path d="M12.892.3a1,1,0,0,0-1.41,0l-4.89,4.88L1.7.292A1,1,0,0,0,.292,1.7l4.89,4.89-4.89,4.89a1,1,0,1,0,1.41,1.41L6.592,8l4.89,4.89a1,1,0,0,0,1.41-1.41L8,6.592l4.89-4.89a1,1,0,0,0,0-1.4Z"/></g>
        </SvgIcon>
    )
}
