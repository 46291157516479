import React from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled from "styled-components";
import { CheckedPaymentSuccessfulIcon } from "../../../assets/svg/checked-payment-successful.icon";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../components/modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../../../components/continue-button/continue-button.component";

const PaymentSuccessfulContent = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0 5px 40px;
`

const CongratulationsText = styled.div`
    font-size: ${props => props.theme.fontSize.xxxxxLarge};
    color: ${props => props.theme.colors.secondary};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    padding: 18px 0 24px;
`;

const PaymentSuccessfulText = styled.div`
    font-size: ${props => props.theme.fontSize.xxLarge};
    color: ${props => props.theme.colors.primaryTint};
    font-weight: ${props => props.theme.fontWeight.normal};
    line-height: 1;
`;

interface PaymentSuccessfulPageProps {
    dialogHandler: IDialogConfirmationHandler;
    customMessage?: string;
}

export const PaymentSuccessfulPage: React.FC<PaymentSuccessfulPageProps> = observer((props) => {
    const services = useServices();

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Payment Successful')}
                                           dialogHandler={props.dialogHandler}/>

            <StandardDialogContentComponent>
                <PaymentSuccessfulContent>
                    <CheckedPaymentSuccessfulIcon color={services.theme.currentTheme.colors.secondary}/>
                    <CongratulationsText>
                        {services.language.translate('Congratulations!')}
                    </CongratulationsText>
                    <PaymentSuccessfulText>
                        {props.customMessage || services.language.translate(`Your payment was successful! We can't wait to have you onboard of our aircraft.`)}
                    </PaymentSuccessfulText>
                </PaymentSuccessfulContent>
            </StandardDialogContentComponent>

            <StandardDialogFooterComponent dialogHandler={props.dialogHandler} horizontallyCentered={true}>
                <ContinueButtonComponent onClick={() => props.dialogHandler.accept()} fullWidth={true}/>
            </StandardDialogFooterComponent>

        </StandardDialogPageComponent>
    )
});
