import {JourneySnapshotModel} from "./journey-snapshot.model";
import {DisruptionType} from "../../base-models/disruption-type";
import {TimeSpan} from "../../../../../types/time-span";
import {ChangeReasonCodeEnum} from "../../../../dot-rez-api/data-contracts/enums/change-reason-code.enum";
import {IServiceFactory} from "../../../../service-factory.interface";

export class JourneySnapshotDisruptionDetector {
    constructor(private readonly journey: JourneySnapshotModel) {
    }

    private get services(): IServiceFactory {
        return this.journey.services;
    }
/*
    private get _isDomesticJourney(): boolean {
        return this.journey.designator.origin.countryCode === this.journey.designator.destination.countryCode;
    }
 */

    private _getMinimumConnectionTime(): TimeSpan {
        try {
            return TimeSpan.parse(this.services.configuration.data.moveFlight.minimumFlightConnectionTime);
        } catch (err) {
            this.services.logger.error(`Failed to parse minimumFlightConnectionTime: ${this.services.configuration.data.moveFlight.minimumFlightConnectionTime}`, err);
            return TimeSpan.fromMinutes(45);
        }

        /*
        if(this._isDomesticJourney) {
            return TimeSpan.fromMinutes(40); //TODO - extract these values in configs
        } else {
            return TimeSpan.fromMinutes(40);
        }
         */
    }

    private get _disruptionMinDelayTime(): TimeSpan {
        try {
            return TimeSpan.parse(this.services.configuration.data.moveFlight.disruptionMinDelayTime);
        } catch (err) {
            this.services.logger.error(`Failed to parse disruptionMinDelayTime: ${this.services.configuration.data.moveFlight.disruptionMinDelayTime}`, err);
            return TimeSpan.fromHours(3);
        }

    }

    private get _hasMissConnection(): boolean {
        const segments = this.journey.segments;
        if(segments.length <= 1) {
            return false;
        }

        const minimumFlightConnectionTime = this._getMinimumConnectionTime().totalMilliseconds;

        for (let i = 0; i < segments.length - 1; i++) {
            const currentSegment = segments[i];
            const nextSegment = segments[i + 1];

            const connectionTime = TimeSpan.fromMilliseconds(nextSegment.designator.departureDate.getTime() - currentSegment.designator.arrivalDate.getTime());

            if(connectionTime.totalMilliseconds <= minimumFlightConnectionTime) {
                return true;
            }
        }

        return false;
    }


    private get _hasDisruptionDelay(): boolean {
        return this.journey.segments.some(s => Math.abs(s.delayTime.totalMilliseconds) >= this._disruptionMinDelayTime.totalMilliseconds);
    }

    get disruptionType(): DisruptionType | null {
        if(this.journey.isCanceled) {
            return DisruptionType.Cancellation;
        }

        if(this.journey.segments.some(s => s.hasBoardedPassengers)) {
            return null;
        }

        if(this._hasMissConnection) {
            return DisruptionType.ScheduleChangeMissConnect;
        }

        if(!this._hasDisruptionDelay) {
            return null;
        }

        if(this.journey.segments.some(s => s.changeReasonCode === ChangeReasonCodeEnum.ScheduleChange)) {
            return DisruptionType.CancellationWithAccommodation;
        }

        return DisruptionType.ScheduledChange;
    }
}
