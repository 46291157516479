import {IFareDiscountType} from "./fare-discount-type.interface";
import {Price} from "../../../currency/price";
import {IDotRezSellTripPassengerType} from "../../../dot-rez-api/data-contracts/requests/booking/sell-trip.request";
import {FareDiscountTypeBase} from "./fare-discount-type-base";
import {IPassengerTypeSelectorsList} from "../../../passenger-types/passengers-types.service.interface";
import {IAvailableFareToDiscountAdapter} from "../fares/available-fare-to-discount-adater.interface";
import {DiscountCodeEnum} from "./discount-code-enum";
import {ServiceChargeTypeDotRezApiEnum} from "../../../dot-rez-api/data-contracts/enums/service-charge-type.enum";
import {BogoFareDiscount} from "./bogo-fare-discount";
import {NullableString} from "../../../../types/nullable-types";

export class BlueBenefitsDiscount extends FareDiscountTypeBase implements IFareDiscountType {
    constructor(fareAdapter: IAvailableFareToDiscountAdapter,
                private _bogoDiscount: BogoFareDiscount){
        super(fareAdapter);
    }

    get discountTypeName(): string {
        return 'Blue Benefits';
    }

    get isApplicable(): boolean {
        return this._hasServiceCharge(ServiceChargeTypeDotRezApiEnum.Discount)
            && this.passengerDotRezFares.some(f => f.fareDiscountCode === DiscountCodeEnum.BlueBenefits)
            && !this._bogoDiscount.isApplicable;
    }
    get promotionalMessage(): NullableString {
        return null;
    }

    get discountedPrice(): Price {
        return this._computePrice(passengerFare => passengerFare.fareAmount);
    }
    /**
     * totalPrice and discountedPrice for blue benefits have the same value
     * and both are equal with the passengerFare.fareAmount which represents the Blue Benefits price
     */
    get totalPrice(): Price {
        return this.discountedPrice;
    }

    createPassengerTypesForTripSell(passengerTypesSelectors: IPassengerTypeSelectorsList): IDotRezSellTripPassengerType[] {
        return passengerTypesSelectors.createPassengerTypesForBlueBenefitsSell();
    }

}
