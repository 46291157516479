import {SsrTypeBase} from "../../ssr-type-base";
import {IBlueBenefitsPackage} from "../../../blue-benefits/models/packs/blue-benefits-package.interface";
import {NullableString} from "../../../../types/nullable-types";
import {SsrCategoryEnum} from "../../ssr-category.enum";
import {IBookingViewModel} from "../../../booking/models/booking-view-model.interface";

export abstract class BlueBenefitsSsr extends SsrTypeBase {

    protected getBlueBenefitsPackage(): IBlueBenefitsPackage {
        return this.services.blueBenefits.getPackageBySsrCode(this.ssrCode);
    }

    get name(): string {
        return this.getBlueBenefitsPackage().name;
    }

    getBookingNote(booking: IBookingViewModel): NullableString {
        return 'BlueBenefitsCanBeActivated';
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.BlueBenefits;
    }

    get canBeRefunded(): boolean {
        return false;
    }
}
