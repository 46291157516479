import {IServiceFactory} from "../service-factory.interface";
import {makeObservable, observable, reaction, runInAction} from "mobx";
import {NullableUndefinedString} from "../../types/nullable-types";
import {Check} from "../../types/type-checking";
import {DeeplinkSearchQueryParamsEnum} from "../deep-links/deeplink-search-query-params.enum";
import {
    IFlightSearchControllerViewModel,
    SpecialPriceMarketUserAgreement,
    SpecialPriceMarketUserOptions
} from "./flight-search-controller/flight-search-controller-view-model.interface";
import {IFlightSearchService} from "./flight-search.service.interface";

export class FlightSearchDeepLinkHandler {
    constructor(private readonly flightSearchService: IFlightSearchService,
                private readonly services: IServiceFactory) {
        makeObservable<this, '_hasDeepLinkErrors'>(this, {
            _hasDeepLinkErrors: observable.ref
        });

        reaction(() => this.services.navigator.currentRoute,
            () => this._handleDeepLinkSearch(),
            {
                fireImmediately: true
            });
    }

    private get searchController(): IFlightSearchControllerViewModel {
        return this.flightSearchService.searchController;
    }


    private _hasDeepLinkErrors: boolean = false;
    get hasDeepLinkErrors(): boolean {
        return this._hasDeepLinkErrors;
    }

    set hasDeepLinkErrors(value: boolean) {
        runInAction(() => {
            this._hasDeepLinkErrors = value;
        })
    }

    private _setLanguageAndCurrency(language: NullableUndefinedString, currency: NullableUndefinedString){
        if(!Check.isNullOrUndefined(language)) {
            this.services.language.changeCurrentLanguage(language);
        }

        if(!Check.isNullOrUndefined(currency)) {
            this.services.currency.setCurrentCurrency(currency);
        }

    }

    private _getDeepLinkSearchParams(): Record<string, string> {
        const passengersQueryParams = this.searchController.passengers.getAll().selectMany(pt => pt.getAllCodes())

        const queryParamsNames = [
            DeeplinkSearchQueryParamsEnum.Agent,
            DeeplinkSearchQueryParamsEnum.Culture,
            DeeplinkSearchQueryParamsEnum.Currency,
            DeeplinkSearchQueryParamsEnum.IsRoundTrip,
            DeeplinkSearchQueryParamsEnum.Origin1,
            DeeplinkSearchQueryParamsEnum.Destination1,
            DeeplinkSearchQueryParamsEnum.DepartureDate1,
            DeeplinkSearchQueryParamsEnum.ReturnDate1,
            DeeplinkSearchQueryParamsEnum.SpecialPriceUserOption,
            DeeplinkSearchQueryParamsEnum.SpecialPriceUserAgreement,
            ...passengersQueryParams
        ]

        return this.services.navigator.getQueryParamsValues(...queryParamsNames);
    }

    private _getSpecialPriceUserOptionQueryParam(queryParamsValues: Record<string, string>): SpecialPriceMarketUserOptions {
        const value = queryParamsValues[DeeplinkSearchQueryParamsEnum.SpecialPriceUserOption];
        if(Check.isNullOrUndefined(value)) {
            return SpecialPriceMarketUserOptions.None;
        }

        const parsedValue = parseInt(value);
        if(Check.isNumber(parsedValue)) {
            return parsedValue;
        }

        return SpecialPriceMarketUserOptions.None;
    }

    private _getSpecialPriceUserAgreementQueryParam(queryParamsValues: Record<string, string>): SpecialPriceMarketUserAgreement {
        const value = queryParamsValues[DeeplinkSearchQueryParamsEnum.SpecialPriceUserAgreement];
        if(Check.isNullOrUndefined(value)) {
            return SpecialPriceMarketUserAgreement.None;
        }

        const parsedValue = parseInt(value);
        if(Check.isNumber(parsedValue)) {
            return parsedValue;
        }

        return SpecialPriceMarketUserAgreement.None;
    }

    private async _handleDeepLinkSearch(): Promise<void> {


        if(!this.services.navigator.currentRoute.equals(this.services.navigator.routes.deepLink.search)) {
            return;
        }

        const queryParamsValues = this._getDeepLinkSearchParams();

        //const agent = queryParams[DeeplinkSearchQueryParamsEnum.Agent];
        const culture = queryParamsValues[DeeplinkSearchQueryParamsEnum.Culture];
        const currency = queryParamsValues[DeeplinkSearchQueryParamsEnum.Currency];

        this._setLanguageAndCurrency(culture, currency);

        this.searchController.isOneWayDepartureTrip = queryParamsValues[DeeplinkSearchQueryParamsEnum.IsRoundTrip] !== 'true';
        this.searchController.departureOrigin = this.services.stations.tryGetStation(queryParamsValues[DeeplinkSearchQueryParamsEnum.Origin1]);
        this.searchController.departureDestination = this.services.stations.tryGetStation(queryParamsValues[DeeplinkSearchQueryParamsEnum.Destination1]);
        this.searchController.departureDate = this.services.time.tryConvertToDate(queryParamsValues[DeeplinkSearchQueryParamsEnum.DepartureDate1]);
        if(!this.searchController.isOneWayDepartureTrip) {
            this.searchController.returnDate = this.services.time.tryConvertToDate(queryParamsValues[DeeplinkSearchQueryParamsEnum.ReturnDate1]);
        }
        this.searchController.specialPriceMarketUserOption = this._getSpecialPriceUserOptionQueryParam(queryParamsValues);
        this.searchController.specialPriceMarketUserAgreement = this._getSpecialPriceUserAgreementQueryParam(queryParamsValues);
        this.searchController.agent = queryParamsValues[DeeplinkSearchQueryParamsEnum.Agent] ?? null;

        for(let pt of this.searchController.passengers.getAll()) {
            for(let passengerCode of pt.getAllCodes()) {
                const ptValue = queryParamsValues[passengerCode];
                if(ptValue) {
                    pt.count = parseInt(ptValue);
                    break;
                }
            }
        }

        await this.flightSearchService.startBooking({
            useRouteReplace: true
        });
    }
}
