import React from "react";
import {observer} from "mobx-react";
import {IFormField} from "../../../../models/forms/form-field.interface";
import {StandardDropDownComponent} from "../../standard-drop-down/standard-drop-down.component";
import {StandardDropDownListItemModel} from "../../standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";
import {NumericFieldEditorComponent} from "../../numeric-field/numeric-field-editor.component";
import {extractFieldEditorCommonProps, FieldEditorCommonProps} from "../../field-editor-common.props";
import {useServices} from "../../../../hooks/use-services.hook";

interface YearFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<number>;
    years?: number[];
    minYear?: number;
    maxYear?: number;
    placeholder?: string;
    className?: string;
}

export const YearFieldEditorComponent: React.FC<YearFieldEditorComponentProps> = observer((props) => {
    const services = useServices();
    let years = props.years;
    if(!years) {
        if(props.minYear && props.maxYear) {
            years = [];
            for(let a = props.minYear; a <= props.maxYear; a++) {
                years.push(a)
            }
        }
    }

    if(!years) {
        return (<NumericFieldEditorComponent field={props.field}
                                             inputMode="numeric"
                                             minValue={props.minYear}
                                             maxValue={props.maxYear}
                                             placeholder={props.placeholder}
                                             className={props.className}
                                             {...extractFieldEditorCommonProps(props)}/>);
    }

    if(props.minYear) {
        years = years.filter(y => y >= props.minYear!);
    }

    if(props.maxYear) {
        years = years.filter(y => y <= props.maxYear!);
    }

    const items = years.map(y => new StandardDropDownListItemModel(y, y.toString()));

    return (
        <StandardDropDownComponent field={props.field}
                                   items={items}
                                   placeholder={props.placeholder}
                                   className={props.className}
                                   modalDialogOptions={{
                                       title: services.language.translate('Year')
                                   }}

                                   {...extractFieldEditorCommonProps(props)}/>
    )
});
