import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IAvailableTripViewModel} from "../../../services/flight-search/models/available-trip-view-model.interface";
import {AvailableTripsListComponent} from "./components/availability/available-trips-list.component";
import {SpinnerComponent} from "../../../components/spinner/spinner.component";
import {useServices} from "../../../hooks/use-services.hook";
import {scrollElementIntoViewSmooth} from "../../../utils/scroll-element-into-view";
import {IJourneyViewModel} from "../../../services/booking/models/journey/journey-view-model.interface";
import {NullableString} from "../../../types/nullable-types";


const PageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: ${props => props.theme.spacing.small};
`

export const BundlesSelectionTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxxxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: ${props => props.theme.spacing.small};
    width: 100%;
    line-height: 1;
    @media ${props => props.theme.mediaQuery.sAndBelow} {
      font-size: ${props => props.theme.fontSize.xxxLarge};
    }
`

const BundlesContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const SpinnerBox = styled(SpinnerComponent)`
  font-size: ${props => props.theme.fontSize.small};
`

interface DepartingFlightSelectionPageContentLargeScreenComponentProps {
    trips: IAvailableTripViewModel[];
    pricingInformationMessage: string;
    bundlesSectionTitle?: NullableString;
    renderBundles?: () => React.ReactElement;
    journey: IJourneyViewModel | null;
}

export const FlightSelectionPageContentComponent: React.FC<DepartingFlightSelectionPageContentLargeScreenComponentProps> = observer((props) => {
    const services = useServices();
    const bundlesContainerRef = useRef<HTMLDivElement | null>(null);

    const isFareSelected = props.trips.some(t => t.journeys.some(j => j.fares.some(f => f.isSelected)));
    const allowBundleSelection = Boolean(props.renderBundles);
    const hasBundles = (props.journey?.bundlesAvailability?.length ?? 0) > 0;



    useEffect(() => {
        if(allowBundleSelection && bundlesContainerRef.current && isFareSelected && !props.journey?.selectedBundle) {
            scrollElementIntoViewSmooth(bundlesContainerRef.current, {
                block: "start",
                inline: "start"
            });
        }
    })

    const renderBundles = () => {
        if(!props.renderBundles) {
            return null;
        }

        if(!isFareSelected) {
            return null;
        }

        if(!hasBundles) {
            return (
                <SpinnerBox innerMessage={services.language.translate("Loading bundles...")} size={21}/>

            )
        }

        return (
            <>
                <BundlesSelectionTitleBox>
                    {props.bundlesSectionTitle}
                </BundlesSelectionTitleBox>
                {props.renderBundles()}
            </>
        );

    }

    return (
        <PageContentBox>
            <AvailableTripsListComponent pricingInformationMessage={props.pricingInformationMessage}
                                         trips={props.trips}/>
            <BundlesContainerBox ref={bundlesContainerRef}>
                {renderBundles()}
            </BundlesContainerBox>

        </PageContentBox>

    );
});
