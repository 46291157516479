import {
    IAnalyticsEvent, IAnalyticsSeatSelectionEvents, IAnalyticsSeatSelectionPromotion
} from "../../../booking/models/analytics/google-analytics.intefaces";
import {BookingModel} from "../../../booking/models/booking.model";
import {AnalyticsBaseEvents} from "./analytics-base.events";


export class AnalyticsSeatSelectionEvents extends AnalyticsBaseEvents implements IAnalyticsSeatSelectionEvents {

    logAddToCart(
        booking: BookingModel,
        journeyKey: string,
        passengerKey: string,
        addedSeatKey: string): void {
        try {
            this.logRemoveFromCart(booking, journeyKey, passengerKey);
            const seatUniqueId = this._composeSeatUniqueId(journeyKey, passengerKey);
            const items = this._getAnalyticsSeatFeeItems(booking, addedSeatKey);
            const data:  IAnalyticsEvent = {
                event: 'add_to_cart',
                ecommerce: {
                    currency: booking.currency,
                    value: items.sum(item => item.price * (item.quantity??1)),
                    coupon: booking.promoCode.code.value ?? "",
                    customer_type: this._getCustomerTypeFromBooking(booking),
                    items: [
                       ...items
                    ],
                }
            }

            this.analyticsActionHandler.sendEvent(data)
            this._saveAddToCartItemsForKey(seatUniqueId, items);
        } catch (err) {
            this.services.logger.error('logAddToCart failed', err);
        }
    }

    logRemoveFromCart(
        booking: BookingModel,
        journeyKey: string,
        passengerKey: string
    ): void {
        try {
            const seatUniqueId = this._composeSeatUniqueId(journeyKey, passengerKey);
            const items = this._getAddToCartItemsForKey(seatUniqueId);
            if(items.length === 0) return;
            const data:  IAnalyticsEvent = {
                event: 'remove_from_cart',
                ecommerce: {
                    currency: booking.currency,
                    value: items.sum( item => item.price * (item.quantity?? 1)),
                    coupon: booking.promoCode.code.value ?? "",
                    customer_type: this._getCustomerTypeFromBooking(booking),
                    items: [
                        ...items
                    ]
                }
            }
            this.analyticsActionHandler.sendEvent(data)
            this._removeAddToCartItemsForKey(seatUniqueId);
        } catch (err) {
            this.services.logger.error('logRemoveFromCart failed', err);
        }
    }

    // logSelectItem(booking: BookingModel, addedSeatKey: string): void {
    //     try {
    //         const data:  unknown = {
    //             event: 'select_item',
    //             ecommerce: {
    //                 customer_type: this._getCustomerTypeFromBooking(booking),
    //                 items: [
    //                     ...this._getAnalyticsSeatFeeItems(booking, addedSeatKey)
    //                 ]
    //             }
    //         }
    //         this.analyticsActionHandler.sendEvent(data)
    //     } catch (err) {
    //         this.services.logger.error('logAddToCart failed', err);
    //     }
    // }

    logSelectPromotion(booking: BookingModel, acceptedPromotion: boolean): void {
        try {

            const data:  unknown = {
                event: 'select_promotion',
                ecommerce: {
                    ...this._getSeatSelectionPromotionData(booking, acceptedPromotion ? 'Accepted' : 'Declined')
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('logAddToCart failed', err);
        }
    }

    logViewPromotion(booking: BookingModel): void {
        try {

            const data:  unknown = {
                event: 'view_promotion',
                ecommerce: {
                    ...this._getSeatSelectionPromotionData(booking, 'Proposal')
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('logAddToCart failed', err);
        }
    }

    private _getSeatSelectionPromotionData(booking: BookingModel, itemVariant: string): IAnalyticsSeatSelectionPromotion {
        return {
            creative_slot: 'seat_selection',
            promotion_id: `SelectSeat`,
            promotion_name: 'Promote Seat Selection',
            items: [
                ...booking.filteredJourneys.map(
                    journey => {
                        return {
                            item_name: 'SeatSelection',
                            item_id: `SeatMap|${journey.analyticsFormattedStationCodes}`,
                            item_variant: `SeatMap|${itemVariant}`
                        }
                    }
                )
            ]
        } as IAnalyticsSeatSelectionPromotion
    }


    private _composeSeatUniqueId(journeKey: string, passengerKey: string): string {
        return `${journeKey}_${passengerKey}`;
    }
}

