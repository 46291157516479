import React from "react";
import {IonIcon} from "@ionic/react";
import {shareOutline, shareSocialOutline} from "ionicons/icons";
import {
    BoardingPassExportButtonComponent,
    BoardingPassExportButtonComponentProps
} from "../export-button/boarding-pass-export-button.component";
import {observer} from "mobx-react-lite";

export const BoardingPassShareButtonComponent: React.FC<BoardingPassExportButtonComponentProps> = observer((props) => {
    return (
        <BoardingPassExportButtonComponent {...props}>
            <IonIcon ios={shareOutline} md={shareSocialOutline}/>
        </BoardingPassExportButtonComponent>
    );
})
