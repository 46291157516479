import { FormModel } from "../../models/forms/form.model";
import { NullableString } from "../../types/nullable-types";
import {FormFields} from "../../models/forms/form-field.interface";

export interface IUserSettingsFormFields {
    readonly language:NullableString;
    readonly currency:NullableString;
    readonly enableTestModePassword: boolean;
    readonly testModePassword: NullableString;
}

export class UserSettingsFormModel extends FormModel<IUserSettingsFormFields> {
    protected _createFields(): FormFields<IUserSettingsFormFields> {
        return {
            currency: this._createField({
                fieldName: () => this.services.language.translate('Currency'),
                initialValue: () => this.services.currency.current

            }),
            language: this._createField({
                fieldName: () => this.services.language.translate('Language'),
                initialValue: () => this.services.language.currentLanguage
            }),
            enableTestModePassword: this._createField<boolean>({
                fieldName: () => "",
                isRequired: false,
                defaultValue: false
            }),
            testModePassword: this._createField<NullableString>({
                fieldName: () => 'Test mode password',
                isRequired: false,
                isHidden: () => !Boolean(this.fields.enableTestModePassword.value)
            })
        }
    }

    enableTestMode(): boolean {
        return this.services.configuration.enableTestMode(this.fields.testModePassword.value ?? "");
    }
}
