import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const MoneyCircleFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 24 24">
            <g fill={props.color? props.color: "currentColor"}>
                <circle id="Ellipse_63" data-name="Ellipse 63" cx="12" cy="12" r="12" />
                <path id="currency" d="M400.963,422.642a2.036,2.036,0,0,0-2.09-1.168,1.138,1.138,0,0,0-.877,1.662,2.02,2.02,0,0,0,2.205,1.144A1.134,1.134,0,0,0,400.963,422.642Zm1.919-2.672,1.785,3.9c-2.576,2-6-.018-8.51,2.115l-1.785-3.9c2.344-2.265,5.582-.213,8.51-2.113Zm1.8,5.7c-3.252,1.866-5.878-.675-9.244,2.838l-2.133-4.724.665-.693,1.8,3.982v0c3.529-2.651,5.307-.557,8.583-2.162C404.5,425.218,404.63,425.541,404.686,425.665Z" transform="translate(-386.81 -411.97)" fill="#fff"/>
            </g>
        </SvgIcon>
    )
}
