import React from "react";
import styled from "styled-components";

const PaymentAccordionHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const IconBox = styled.div`
    border: 1px solid ${props => props.theme.colors.dark};
    padding: 10px;
    border-radius: 8px;
`

const PaymentAccordionHeaderText = styled.div`
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.dark};
    margin-left: ${props => props.theme.spacing.large};
`;

interface PaymentAccordionHeaderComponentProps {
    renderIcon: () => React.ReactElement | null;
    title: string | React.ReactElement;
}

export const PaymentAccordionHeaderComponent: React.FC<PaymentAccordionHeaderComponentProps> = (props) => {
    return(
        <PaymentAccordionHeaderBox>
            <IconBox>
                {props.renderIcon()}
            </IconBox>
            <PaymentAccordionHeaderText>
                   {props.title}
            </PaymentAccordionHeaderText>
        </PaymentAccordionHeaderBox>
    )
}
