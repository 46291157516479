import React from "react";
import { MoneyCircleFilledIcon } from "../../../../../assets/svg/money-circle-filled.icon";
import { ShoppingCartComponent } from "../../../../../components/shopping-cart/shopping-cart.component";
import { useServices } from "../../../../../hooks/use-services.hook";
import { FlightItineraryAccordionHeaderComponent } from "../flight-itinerary-accordion-header.component";
import { FlightItineraryAccordionComponent } from "../flight-itinerary-accordion.component";
import styled from "styled-components";

const ACCORDION_HEADER_CLASS_NAME = 'payments-accordion-header';

const AccordionBox = styled(FlightItineraryAccordionComponent)`
    padding: 0;
    .${ACCORDION_HEADER_CLASS_NAME} {
      padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
    }
`


export const FlightItineraryPaymentComponent: React.FC = () => {
    const services = useServices();


    const renderAccordionHeader = () => {
        return(
            <FlightItineraryAccordionHeaderComponent renderIcon={() => <MoneyCircleFilledIcon/>}
                                title={services.language.translate('Payment')} />
        );
    }

    const renderAccordionContent = () => {
        return (
            <ShoppingCartComponent hideBorders={true}/>
        );
    }

    return(
        <AccordionBox renderHeader={renderAccordionHeader}
                      renderContent={renderAccordionContent}
                      headerClassName={ACCORDION_HEADER_CLASS_NAME}/>
    )
}
