import styled from "styled-components"
import { useServices } from "../../../../hooks/use-services.hook"
import { IDialogConfirmationHandler } from "../../../../services/dialog/dialog.service.interface"
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import React from "react";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {ExternalLinkComponent} from "../../../../components/external-link/external-link.component";

import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";
import {observer} from "mobx-react-lite";

const PinchContainerBox = styled.div`
    display: flex;
    height: 100%;
    .react-transform-wrapper {
      height: auto;
    }
`

const TermsAndConditionsBox = styled(ExternalLinkComponent)`
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      font-size: ${props => props.theme.fontSize.medium};
    }
`

const DangerousGoodsImgForSmallScreenBox = styled.img`
`

const DangerousGoodsImgForLargeScreenBox = styled.img`
    width: 100%;
    height: auto;
`

const FooterContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.large};
    width: 100%;
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      gap: ${props => props.theme.spacing.medium};
    }
`

const FooterButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.large};
    width: 100%;
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      flex-direction: column;
      gap: ${props => props.theme.spacing.medium};
    }
`

const AcceptButton = styled(PrimaryButton)`
    min-width: 200px;
`

const RejectButton = styled(PrimaryButtonInverted)`
    min-width: 200px;
`


interface DangerousGoodsComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}
export const DangerousGoodsDialogComponent: React.FC<DangerousGoodsComponentProps> = observer((props) => {
    const services = useServices();

    const onAgree = () => {
        props.dialogHandler.accept();
    }

    const onDisagree = async () => {
        props.dialogHandler.reject();
    }

    const renderImage = () => {
        if(services.mediaQuery.sAndBelow) {
            return (
                <PinchContainerBox>
                    <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
                        <TransformComponent>
                            <DangerousGoodsImgForSmallScreenBox src={services.theme.currentTheme.assets.images.dangerous_goods_jpg.toString()}/>
                        </TransformComponent>
                    </TransformWrapper>
                </PinchContainerBox>
            );

        } else {
            return (
                <DangerousGoodsImgForLargeScreenBox src={services.theme.currentTheme.assets.images.dangerous_goods_jpg.toString()}/>
            );
        }
    }

    return(
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Dangerous Goods')} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                {renderImage()}
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <FooterContentBox>
                    <TermsAndConditionsBox innerHtml={services.externalLinks.dangerousGoods}/>
                    <FooterButtonsContainerBox>
                        <AcceptButton onClick={onAgree}  fullWidth={services.mediaQuery.sAndBelow}>
                            {services.language.translate('I acknowledge and accept')}
                        </AcceptButton>
                        <RejectButton onClick={onDisagree} fullWidth={services.mediaQuery.sAndBelow}>
                            {services.language.translate('I do not accept')}
                        </RejectButton>
                    </FooterButtonsContainerBox>

                </FooterContentBox>

            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
})
