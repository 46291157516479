import React from "react";
import {observer} from "mobx-react";
import {ISegmentViewModel} from "../../../services/booking/models/segment/segment-view-model.interface";
import {TicketSegmentPassengerComponent} from "./ticket-segment-passenger.component";

export const TicketSegmentPassengersComponent: React.FC<{segment: ISegmentViewModel}> = observer((props) => {

    let passengers = props.segment.passengers;
    /*
    passengers = [
        ...passengers,
        ...passengers,
        passengers[0]
    ]
     */

    return (
        <div>
            {passengers.map(ps => (<TicketSegmentPassengerComponent key={ps.passengerKey} passengerSegment={ps}/>))}
        </div>
    )
})