import React from 'react';
import {observer} from "mobx-react";
import {IFlightDesignatorViewModel} from "../../../services/booking/models/designator/flight-designator-view-model.interface";
import styled from "styled-components";
import {useServices} from "../../../hooks/use-services.hook";
import {AirlineSmallLogoIcon} from "../../../assets/svg/airline-small-logo.icon";
import {DesignatorPlaneIconComponent} from "../common/components/designator-plane-icon.component";

const JourneyDesignatorBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  font-size: ${props => props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.bold};
`

const AirlineLogoBox = styled.div`
  padding-right: ${props => props.theme.spacing.small};
`

interface JourneyDesignatorComponentProps {
    designator: IFlightDesignatorViewModel;
    className?: string
}

export const JourneyDesignatorComponent: React.FC<JourneyDesignatorComponentProps> = observer((props) => {
    const designator = props.designator;
    const services = useServices();
    return (
        <JourneyDesignatorBox className={props.className}>
            <AirlineLogoBox>
                <AirlineSmallLogoIcon color={services.theme.currentTheme.colors.tertiary} size={1.6} />
            </AirlineLogoBox>

            {designator.origin.stationName} ({designator.origin.stationCode})

            <DesignatorPlaneIconComponent/>

            {designator.destination.stationName} ({designator.destination.stationCode})

        </JourneyDesignatorBox>

    );
});
