import styled from "styled-components";

export const HeaderTitleContainerBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    flex-grow: 1;
    line-height: 1.1;
`
