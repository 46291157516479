import React from "react";
import {observer} from "mobx-react";
import {IMyAccountDashboardItem} from "../my-account-dashboard-item.interface";
import styled from "styled-components";
import {DashboardItemComponent} from "./dashboard-item.component";

const DashboardRowBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10px;
`

interface DashboardRowComponentProps {
    items: IMyAccountDashboardItem[];
}

export const DashboardRowComponent: React.FC<DashboardRowComponentProps> = observer((props) => {
    const items: Array<IMyAccountDashboardItem | null>  = props.items;
    if(items.length === 1) {
        items.push(null);
    }
    return (
        <DashboardRowBox>
            {props.items.map(item => <DashboardItemComponent key={item?.text || 'no_item'} item={item}/>)}
        </DashboardRowBox>
    )
});
