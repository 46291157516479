import React, {KeyboardEventHandler} from "react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {observer} from "mobx-react";
import {NullableString} from "../../../types/nullable-types";
import styled from "styled-components";
import {InputBaseBox} from "../../../basic-components/input/input-base.box";
import {Check} from "../../../types/type-checking";
import {FieldEditorContainerComponent} from "../field-editor-container.component";
import {extractFieldEditorCommonProps, FieldEditorCommonProps} from "../field-editor-common.props";
import { FieldInputWrapperBox } from "../field-input-wrapper.box";

const InputBox = styled(InputBaseBox)`
`

interface StringFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<NullableString>;
    placeholder?: NullableString;
    onKeyPress?: KeyboardEventHandler;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    type?: 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local';
    iconRight?: React.ReactElement;
    className?: string;
}


export const StringFieldEditorComponent: React.FC<StringFieldEditorComponentProps> = observer((props) => {

    const placeholder = props.placeholder ? props.placeholder : "";

    let autoCapitalize = 'off';
    if(props.field.autoCapitalize) {
        autoCapitalize = "characters";
    }

    const setValue = (value: string | number | null | undefined) => {
        if(Check.isNullOrUndefined(value) || Check.isEmpty(value)) {
            props.field.setValue(null);
        } else {
            props.field.setValue(value.toString());
        }
    }

    const renderInput = (hasError: boolean) => {
        return (
            <FieldInputWrapperBox hasError={hasError}>
                <InputBox  type={props.type}
                                      inputMode={props.inputMode}
                                      autoComplete="off"
                                      autoCorrect="off"
                                      autoCapitalize={autoCapitalize}
                                      spellCheck={false}
                                      ref={props.field.attachInputDOMElement}
                                      onKeyPress={props.onKeyPress}
                                      placeholder={placeholder}
                                      value={props.field.value ?? ""}
                                      maxLength={props.field.maxLength}
                                      readOnly={props.field.isReadOnly}
                                      onChange={(e) => setValue(e.target.value)}
                                      className={props.className}/>
                {props.iconRight}
            </FieldInputWrapperBox>
        );
    }


    return (
        <FieldEditorContainerComponent renderInput={renderInput}
                                       field={props.field}
                                       {...extractFieldEditorCommonProps(props)} />
    );
});
