import React from "react";
import {observer} from "mobx-react";
import {PassengerBoardingPassesGroupSelector} from "../models/passengers-boarding-passes-group-selector";
import {PassengerBoardingPassesSelectorComponent} from "./passenger-boarding-passes-selector.component";
import {
    IPassengerSegmentBoardingPassViewModel
} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import styled from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";

const GroupBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large};
    padding: 0;
    
`

const GroupHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${props => props.theme.fontSize.xxxLarge};
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    padding: ${props => props.theme.spacing.medium};
    border-top-left-radius: ${props => props.theme.border.defaultRadius};
    border-top-right-radius: ${props => props.theme.border.defaultRadius};
`

const GroupContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.medium};
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
`



interface PassengersBoardingPassesGroupSelectorComponentProps {
    group: PassengerBoardingPassesGroupSelector;
    renderAddToWalletButton: (selectedBoardingPasses: IPassengerSegmentBoardingPassViewModel[]) => React.ReactElement;
}
export const PassengersBoardingPassesGroupSelectorComponent: React.FC<PassengersBoardingPassesGroupSelectorComponentProps> = observer((props) => {

    return (
        <GroupBox>
            <GroupHeaderBox>{props.group.title}</GroupHeaderBox>
            <GroupContentBox>
                <div>
                    {props.group.selectors.map(s => <PassengerBoardingPassesSelectorComponent key={s.uniqueKey} passengerSelector={s}/>)}
                </div>
                {props.renderAddToWalletButton(props.group.getSelectedBoardingPasses())}
            </GroupContentBox>

        </GroupBox>
    )
})