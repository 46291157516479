import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../hooks/use-services.hook";
import {IJourneyViewModel} from "../../../services/booking/models/journey/journey-view-model.interface";
import {SameForAllFlightsToggleComponent} from "../common-components/same-for-all-flights-toggle.component";
import {BagsSelectionUISynchronizer} from "./bags-selection.helpers";
import {SynchronizedSwipersComponent} from "../../../components/synchronized-swipers/synchronized-swipers.component";
import {PassengersBagsComponent} from "./components/passengers-bags.component";
import {JourneySsrBucketDesignatorComponent} from "../common-components/ssrs/journey-ssrs-bucket-designator.component";
import {SwiperRef} from "swiper/react";
import {reaction} from "mobx";

export const BagsSelectionPageContentComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    const [uiSynchronizer] = useState(new BagsSelectionUISynchronizer(services));
    const [ssrsAggregator] = useState(booking.createBagsSsrsAggregator());
    let headerSwiper: SwiperRef | null = null;
    let contentSwiper: SwiperRef | null = null;

    useEffect(() => {
        const r = reaction(() => booking.filteredJourneys.map(j => j.allBags.validationsActivationTime), () => {
            const index = booking.filteredJourneys.findIndex(j => j.allBags.hasErrors);
            if(index >= 0) {
                headerSwiper?.swiper.slideTo(index);
                contentSwiper?.swiper.slideTo(index);
            }
        });

        return () => {
            r();
        }
    }, [booking, headerSwiper, contentSwiper]);

    const journeys = ssrsAggregator.useSameOptionsForAllFlights
                     ? [services.booking.current.filteredJourneys[0]]
                     : services.booking.current.filteredJourneys;

    const renderSameForAllFlightsToggle = () => {
        if(!ssrsAggregator.allowUseSameOptionsForAllFlights) {
            return (<div/>);
        }


        return (
            <>
                <div/>
                <SameForAllFlightsToggleComponent ssrsBucket={ssrsAggregator}/>
            </>
        );
    }


    const renderHeaderItem = () => {
        if(ssrsAggregator.useSameOptionsForAllFlights) {
            return undefined;
        }
        return (journey: IJourneyViewModel) => {
            return (
                <JourneySsrBucketDesignatorComponent designator={journey.designator}/>
            );
        };


    }

    const renderContentItem = (journey: IJourneyViewModel) => {
        return (
            <PassengersBagsComponent journey={journey} uiSynchronizer={uiSynchronizer}/>
        );
    }

    const captureHeaderSwiper = (swiperRef: SwiperRef | null) => {
        headerSwiper = swiperRef;
    }

    const captureContentSwiper = (swiperRef: SwiperRef | null) => {
        contentSwiper = swiperRef;
    }

    const SyncSwipers = SynchronizedSwipersComponent<IJourneyViewModel>;


    return (
        <SyncSwipers items={journeys}
                     getItemKey={(journey) => journey.journeyKey}
                     renderHeaderItem={renderHeaderItem()}
                     renderContentItem={renderContentItem}
                     renderExtraHeader={renderSameForAllFlightsToggle}
                     captureHeaderSwiper={captureHeaderSwiper}
                     captureContentSwiper={captureContentSwiper} />
    );

})
