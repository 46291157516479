import {IHomePageTabViewModel} from "../../../services/home-page/tabs/home-page.tab.interface";
import {CheckInTabComponent} from "../components/check-in/check-in-tab.component";
import React from "react";
import {HomePageTabBase} from "../../../services/home-page/tabs/home-page-tab-base";
import {HomePageHeaderComponent} from "../components/home-page-header/home-page-header.component";
import { PhoneCheckmarkIcon } from "../../../assets/svg/phone-checkmark.icon";

export class CheckInTab extends HomePageTabBase implements IHomePageTabViewModel {
    get text(): string {
        return this.services.language.translate('Check-in');
    }

    renderIcon(): React.ReactElement {
        return (
            <PhoneCheckmarkIcon/>
        );
    }


    renderContent(): React.ReactElement {
        return (<CheckInTabComponent/>);
    }

    renderHeader(): React.ReactElement | null {
        return (
            <HomePageHeaderComponent title={this.services.language.translate('Check-in')}/>
        );
    }

}
