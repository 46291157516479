export enum PaymentMethodCodesEnum {
    AirlineWallet = 'WT',
    VisaPaymentHub = 'VI',
    VisaNewsky = 'V3',
    MasterCardPaymentHub = 'MC',
    MastercardNewsky = 'M3',
    MaestroPaymentHub = 'MA',
    GooglePay = 'GP',
    ApplePay = 'AP',
    PayPal = 'PP'
}

export const PaymentMethodsSortOrder: Record<string, number> = {
    [PaymentMethodCodesEnum.AirlineWallet]: 100,
    [PaymentMethodCodesEnum.VisaPaymentHub]: 200,
    [PaymentMethodCodesEnum.VisaNewsky]: 250,
    [PaymentMethodCodesEnum.MasterCardPaymentHub]: 300,
    [PaymentMethodCodesEnum.MastercardNewsky]: 350,
    [PaymentMethodCodesEnum.MaestroPaymentHub]: 400,
    [PaymentMethodCodesEnum.ApplePay]: 500,
    [PaymentMethodCodesEnum.GooglePay]: 600,
}
