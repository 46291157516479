import {
    IDotRezJourney,
    IDotRezJourneyBreakdown
} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IServiceFactory} from "../../../../service-factory.interface";
import {SegmentSnapshotModel} from "../segment/segment-snapshot.model";
import {IJourneySnapshotViewModel} from "./journey-snapshot-view-model.interface";
import {JourneyBaseModel} from "../../base-models/journey/journey-base.model";
import {LegSnapshotModel} from "../leg/leg-snapshot.model";
import {BookingSnapshotModel} from "../booking/booking-snapshot.model";
import {DisruptionType} from "../../base-models/disruption-type";
import {NullableString} from "../../../../../types/nullable-types";
import {JourneySnapshotDisruptionDetector} from "./journey-snapshot-disruption-detector";
import {TimeSpan} from "../../../../../types/time-span";
import {JourneySnapshotShoppingCartModel} from "../shopping-cart/journey-snapshot-shopping-cart.model";
import {Price} from "../../../../currency/price";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {makeObservable, observable, runInAction} from "mobx";


export class JourneySnapshotModel extends JourneyBaseModel<LegSnapshotModel, SegmentSnapshotModel> implements IJourneySnapshotViewModel {
    constructor(private readonly journeyIndex: number,
                public readonly booking: BookingSnapshotModel) {
        super();
        this.segments = this.journeyDotRezData.segments.map((s, index) => new SegmentSnapshotModel(index, this));
        this._disruptionDetector = new JourneySnapshotDisruptionDetector(this);
        makeObservable<this, '_isSelectedForRefund'>(this, {
            _isSelectedForRefund: observable.ref
        });
    }

    private readonly _disruptionDetector: JourneySnapshotDisruptionDetector;

    get journeyDotRezData(): IDotRezJourney {
        return this.booking.bookingData.journeys[this.journeyIndex];
    }

    get journeyKey(): string {
        return this.journeyDotRezData.journeyKey;
    }

    get services(): IServiceFactory {
        return this.booking.services;
    }

    createPrice(amount: number): Price {
        return this.booking.createPrice(amount);
    }

    getSegmentDelay(segmentIndex: number): TimeSpan {
        return this.booking.getSegmentDelay(this.journeyIndex, segmentIndex);
    }

    private _isSelectedForRefund: boolean = false;
    get isSelectedForRefund(): boolean {

        if(!this.canBeRefunded) {
            return false;
        }

        if(this.hasDisruption) {
            return true;
        }

        return this._isSelectedForRefund;

    }

    set isSelectedForRefund(value: boolean) {
        runInAction(() => {
            this._isSelectedForRefund = value;
        })
    }


    readonly segments: SegmentSnapshotModel[];

    get hasBundle(): boolean {
        return Boolean(this.currentBundleCode);
    }

    private _getCurrentBundleCodeOrDefault(): string {
        return this.currentBundleCode || this.services.bundleTypes.getLowestBundleType().code;
    }

    get hasFlex(): boolean {
        return this.segments.some(s => s.hasFlex);
    }

    get canUseFlex(): boolean {
        return  this.services.configuration.ssrs.flex.canBeUsed(this.booking.bookingCreationDate, this.designator.departureDate, this._getCurrentBundleCodeOrDefault());
    }

    get shouldWaveFeeOnDateChange(): boolean {
        return this.hasFlex && this.canUseFlex;
    }

    get flexUsageBlockingReason(): NullableString {

        if(this.booking.canMoveDisruptedFlights) {
            return null;
        }

        if(!this.hasFlex || this.canUseFlex) {
            return null;
        }

        const bundleCode = this._getCurrentBundleCodeOrDefault();
        const bookingDate = this.booking.bookingCreationDate;
        const minTimeToUse = this.services.configuration.ssrs.flex.getMinimumTimeToUseBeforeFlight(bookingDate, this._getCurrentBundleCodeOrDefault());
        if(!minTimeToUse) {
            this.services.logger.error(`There is no configuration for minimum time to use for FLEX for date ${bookingDate.toISOString()} and bundle ${bundleCode}`);
            return null;
        }

        return this.services.language.translationFor('FLEX cannot be used because this flight is within less than {time}')
            .withParams({
                time: minTimeToUse.toUserFriendlyString(this.services.language)
            })
    }

    get journeyDotRezBreakdown(): IDotRezJourneyBreakdown {
        return this.booking.bookingData.breakdown.journeys.filter(jbd => jbd.key === this.journeyKey)[0].value;
    }

    get minTimeToChangeDateBeforeFlight(): TimeSpan {
        const bundleCode = this.currentBundleCode;
        if(!bundleCode) {
            return TimeSpan.parse(this.services.configuration.data.minTimeToChangeDateBeforeFlight);
        }

        return this.services.bundleTypes.getBundleType(bundleCode).minTimeToChangeDateBeforeFlight;
    }

    get departureIsInLessThanMinTimeToAllowDateChange(): boolean {

        return this.services.time.currentDate.getTime()
                >
                (this.designator.departureDate.getTime() - this.minTimeToChangeDateBeforeFlight.totalMilliseconds);
    }

    get canMoveDisruptedFlight(): boolean {
        return !this.departureIsInLessThanMinTimeToAllowDateChange && this.hasDisruption;
    }

    get canBeRefunded(): boolean {
        // if a journey allows move then also allows refund
        if(this.canMoveDisruptedFlight) {
            return true;
        }
        const countOtherJourneysThatHaveDisruption = this.booking.journeys.filter(j => j.journeyKey !== this.journeyKey && j.hasDisruption).length;
        // If this journey doesn't have disruption but the other journey on the booking has
        // then this journey also becomes eligible for refund
        // as long as departure time is NOT in less than MinTimeToAllowChange (4 hours)
        return (!this.departureIsInLessThanMinTimeToAllowDateChange && countOtherJourneysThatHaveDisruption > 0);
    }

    get hasDisruption(): boolean {
        return Boolean(this.disruptionType);
    }

    get disruptionType(): DisruptionType | null {
        return this._disruptionDetector.disruptionType;
    }

    get dateChangeBlockingReason(): NullableString {

        if(this.booking.flightsChangeBlockingReason) {
            return this.booking.flightsChangeBlockingReason;
        }


        if(!this.isFutureJourney) {
            return this.services.language.translate('You cannot change the departure date because this flight has already departed.');
        }

        if(this.departureIsInLessThanMinTimeToAllowDateChange && !this.canMoveDisruptedFlight) {
            return this.services.language.translationFor('You cannot change the departure date because this flight is departing in less than {time}')
                .withParams({
                    time: this.minTimeToChangeDateBeforeFlight.toUserFriendlyString(this.services.language)
                });
        }

        if(this.hasCheckedInPassengers && !this.canMoveDisruptedFlight) {
            return this.services.externalLinks.notAllowFlightChangeForCheckedInPassengers;
        }

        return null;
    }

    get refundBlockingReason(): NullableString {
        if(this.booking.flightsChangeBlockingReason) {
            return this.booking.flightsChangeBlockingReason;
        }

        if(!this.isFutureJourney) {
            return this.services.language.translate('The amount for this flight cannot be refunded because this flight has already departed.');
        }

        if(this.departureIsInLessThanMinTimeToAllowDateChange) {
            return this.services.language.translationFor('The amount for this flight cannot be refunded because this flight is departing in less than {time}')
                .withParams({
                    time: this.minTimeToChangeDateBeforeFlight.toUserFriendlyString(this.services.language)
                });
        }

        if(!this.canBeRefunded) {
            return this.services.language.translate('Only booking with disrupted flights can be refunded');
        }

        return null;
    }

    get allowsDateChange(): boolean {
        return !Boolean(this.dateChangeBlockingReason);
    }

    private _shoppingCart: JourneySnapshotShoppingCartModel | null = null;
    get shoppingCart(): JourneySnapshotShoppingCartModel {
        if(!this._shoppingCart) {
            this._shoppingCart = new JourneySnapshotShoppingCartModel(this);
        }

        return this._shoppingCart;
    }

    countPassengerType(passengerTypeCode: string, discountCode: NullableString) {
        return this.booking.countPassengerType(passengerTypeCode, discountCode);
    }

    getTotalSsrFees(flightReference: string, passengerKey: string, ssrType: ISsrType) {
        const passenger = this.booking.passengers.find(p => p.passengerKey === passengerKey);
        if(!passenger) {
            throw new Error(`There is no passenger with key ${passengerKey} on the booking`);
        }

        return passenger.getTotalSsrFees(flightReference, ssrType);
    }

    get refundableAmount(): Price {
        return Price.sumAll(this.segments.map(s => s.refundableAmount), this.createPrice(0));
    }
}
