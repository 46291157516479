import {IFlightsScheduleViewModel} from "../flights-schedule/flights-schedule-view-model.interface";
import {NullableStation, SpecialPriceMarket, Station} from "../../stations/station.service.interface";
import {ValidationResultEnum} from "../../../types/validation-result.enum";
import {IPassengerTypeSelectorsList} from "../../passenger-types/passengers-types.service.interface";
import {NullableDate, NullableNumber, NullableString} from "../../../types/nullable-types";
import {
    IDotRezAvailableFare,
    IDotRezAvailableTrip
} from "../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import {SessionStorageKeys} from "../../storage/session-storage-keys";
import {IFlightScheduleFilter} from "../flights-schedule/flight-schedule-filter.interface";
import {IDotRezBookingSession} from "../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {FormFields, IFormField} from "../../../models/forms/form-field.interface";
import {FlightsDatesSelector} from "../dates-selection/flights-dates-selector";

export enum SpecialPriceMarketUserOptions {
    None,
    Regular,
    Season
}

export enum SpecialPriceMarketUserAgreement {
    None,
    Agree,
    DontAgree
}

export interface IFlightSearchFields {
    readonly isOneWayDepartureTrip: boolean;
    readonly isOneWayReturnTrip: boolean;
    readonly departureOrigin: Station;
    readonly departureDestination: Station;
    readonly returnOrigin: Station;
    readonly returnDestination: Station;
    readonly specialPriceMarketUserOption: SpecialPriceMarketUserOptions;
    readonly specialPriceMarketUserAgreement: SpecialPriceMarketUserAgreement;
    readonly agent: NullableString;
    readonly departureDate: Date;
    readonly returnDate: NullableDate;
    readonly passengers: IPassengerTypeSelectorsList;
    readonly withBlueBenefits: boolean;
    readonly departureClassOfServices: string[];
    readonly returnClassOfServices: string[];
    readonly departureMinPrice: NullableNumber;
    readonly returnMinPrice: NullableNumber;
    readonly allowBogo: boolean;
    readonly departureFlightScheduleFilter: IFlightScheduleFilter | null;
    readonly returnFlightScheduleFilter: IFlightScheduleFilter | null;

}

export interface IFlightSearchControllerCloneOptions {
    storageKey: SessionStorageKeys;
    bookingSession: IDotRezBookingSession;
}

export interface IAvailabilityOneWaySearchResult {
    trips: IDotRezAvailableTrip[];
    faresAvailable: Record<string, IDotRezAvailableFare>;
}

export interface IFlightSearchControllerViewModel {
    readonly fields: FormFields<IFlightSearchFields>
    readonly isOneWay: boolean;
    readonly availableDepartureStations: Station[];
    readonly availableReturnStations: Station[];
    readonly recentlySearchedOrigins: Station[];
    readonly recentlySearchedDestinations: Station[];
    isOneWayDepartureTrip: boolean;
    isOneWayReturnTrip: boolean;
    departureOrigin: NullableStation;
    departureDestination: NullableStation;
    returnOrigin: NullableStation;
    returnDestination: NullableStation;
    specialPriceMarketUserOption: SpecialPriceMarketUserOptions;
    specialPriceMarketUserAgreement: SpecialPriceMarketUserAgreement;
    agent: NullableString;
    departureDate: NullableDate;
    readonly departureDateError: NullableString;
    returnDate: NullableDate;
    readonly  returnDateError: NullableString;
    withBlueBenefits: boolean;
    allowBogo: boolean;
    passengers: IPassengerTypeSelectorsList
    departureClassOfServices: string[];
    returnClassOfServices: string[];
    departureMinPrice: NullableNumber,
    returnMinPrice: NullableNumber,
    departureFlightScheduleFilter: IFlightScheduleFilter | null;
    returnFlightScheduleFilter: IFlightScheduleFilter | null;
    readonly departureFlightSchedule: IFlightsScheduleViewModel;
    readonly returnFlightSchedule: IFlightsScheduleViewModel;
    readonly departureSearchResult: IAvailabilityOneWaySearchResult;
    readonly returnSearchResult: IAvailabilityOneWaySearchResult;
    readonly datesSelector: FlightsDatesSelector;
    getFlightSearchSession(): Promise<IDotRezBookingSession>;
    hasErrors(): boolean;
    activateErrorsValidation(): IFormField[];
    applySearch(): Promise<void>;
    applySearchForDepartureOnly(): Promise<void>;
    applySearchForReturnOnly(): Promise<void>;
    validateFlightDatesSelection(): ValidationResultEnum
    clone(options: IFlightSearchControllerCloneOptions): IFlightSearchControllerViewModel;
    dispose(): void;
    getSpecialPriceMarket(): SpecialPriceMarket | null
    getAnalyticsAgentName(): string
}
