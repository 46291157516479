import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const SeatCircleFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 24 24">
            <g fill={props.color? props.color: "currentColor"}>
                <circle id="Ellipse_63" data-name="Ellipse 63" cx="12" cy="12" r="12" />
                <path id="Path_329" data-name="Path 329" d="M451.184,441.052l-.148-1.253a.5.5,0,0,0-.567-.362l-7.2.582a.228.228,0,0,0-.022.005l-.388-1.487,5.311-.327a.491.491,0,0,0,.471-.513.5.5,0,0,0-.539-.448l-5.492.343-.632-2.417-.517-4.461a.5.5,0,0,0-.558-.425.487.487,0,0,0-.447.531l.521,4.491a.448.448,0,0,0,.012.065l1.759,6.727a.5.5,0,0,0,.491.365h.036l7.708-.517a.515.515,0,0,0,.346-.164.466.466,0,0,0,.123-.349A.473.473,0,0,0,451.184,441.052Z" transform="translate(-433.454 -425.284)" fill="#fefefe"/>
            </g>
        </SvgIcon>
    )
}
