import React, { useState } from "react";
import {observer} from "mobx-react";
import {StringFieldEditorComponent} from "../string-field/string-field-editor.component";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableString} from "../../../types/nullable-types";
import { EyeSlashIcon } from "../../../assets/svg/eye-slash.icon";
import { useServices } from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {ZIndex} from "../../../types/z-index";

const EyeSlashBox = styled.div<{hasError: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${ZIndex.PasswordFieldIcon};
`


interface PasswordFieldEditorComponentProps {
    field: IFormField<NullableString>;
}

export const PasswordFieldEditorComponent: React.FC<PasswordFieldEditorComponentProps> = observer((props) => {
    const services = useServices();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const icon = (
        <EyeSlashBox hasError={props.field.hasError} onClick={togglePasswordVisibility}>
            <EyeSlashIcon color={services.theme.currentTheme.colors.medium} size={2.5} />
        </EyeSlashBox>
    );

    return (
        <StringFieldEditorComponent field={props.field} type={isPasswordVisible ? "text" : "password"} iconRight={icon}/>
    );
});
