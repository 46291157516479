import {IFeeModel} from "../fee-model.interface";
import {NullablePrice, Price} from "../../../../../currency/price";
import {NullableString} from "../../../../../../types/nullable-types";
import {FeeTypeEnum} from "../../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {ISsrType} from "../../../../../ssr-types/ssr-types.service.interface";
import {ShoppingCartFeeBaseModel} from "../shopping-cart-fee-base.model";

export class OtherFeeModel extends ShoppingCartFeeBaseModel implements IFeeModel {
    constructor(private readonly fee: IFeeModel,
                public readonly initialPrice: NullablePrice) {
        super();
    }

    get createdDate(): Date {
        return this.fee.createdDate;
    }
    get description(): string {
        return this.fee.description;
    }

    get details(): NullableString {
        return this.fee.details;
    }
    get feeGroupKey(): string {
        return `${this.feeType}_${this.feeCode}_${this.initialPrice?.toString()}_${this.currentPrice.toString()}`;
    }

    get feeType(): FeeTypeEnum {
        return this.fee.feeType;
    }

    get feeCode(): NullableString {
        return this.fee.feeCode;
    }
    
    get quantity(): number {
        return this.fee.quantity;
    }

    get currentPrice(): Price {
        return this.fee.currentPrice;
    }

    get ssrNumber(): number {
        return this.fee.ssrNumber;
    }

    get preferredOrderInLists(): number {
        return this.fee.preferredOrderInLists;
    }

    get ssrType(): ISsrType | null {
        return this.fee.ssrType;
    }

    get isCommitted(): boolean {
        return this.fee.isCommitted;
    }

    get shouldBeDisplayedInShoppingCart(): boolean {
        return this.fee.shouldBeDisplayedInShoppingCart;
    }
}
