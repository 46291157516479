import React, {useState} from "react";
import {Station} from "../../../../services/stations/station.service.interface";
import {observer} from "mobx-react";
import {StationsListComponent} from "../../../../components/stations-list/stations-list.component";
import {FlightSearchTitleComponent} from "../../../flight-search/components/flight-search-title.component";
import {StationsSearchComponent} from "../../../../components/stations-search/stations-search.component";
import {BookingFlowPage} from "../../../booking-flow/booking-flow.page";


interface ManageMyBookingStationSelectionPageProps {
    titleText: string;
    plainIcon: React.ReactElement;
    stations: Station[];
    recentlySearchedStations: Station[];
    onStationSelected: (station: Station) => void;
}

export const ManageMyBookingStationSelectionPage: React.FC<ManageMyBookingStationSelectionPageProps> = observer((props) => {
    const [filteredStations, setFilteredStations] = useState<Station[]>(props.stations);
    const [recentlySearchStation, setRecentlySearchStation] = useState<Station[]>(props.recentlySearchedStations);

    const renderContent = () => {
        return (
            <StationsListComponent stations={filteredStations}
                                   onSelect={(station) => props.onStationSelected(station)}
                                   recentlySearchedStations={recentlySearchStation}/>
        );
    }

    const onSearch = (stations: Station[]) => {
        setFilteredStations(stations);
        if (stations.length !== props.stations.length) {
            setRecentlySearchStation([]);
        } else {
            setRecentlySearchStation(props.recentlySearchedStations)
        }
    }


    return (
        <BookingFlowPage title={<FlightSearchTitleComponent title={props.titleText} icon={props.plainIcon}/>}
                                    renderExtraHeaderContent={() => <StationsSearchComponent stations={props.stations} onSearch={onSearch}/>}
                                    renderContent={renderContent}
                                    showPromotionCode={false}
                                    hideFooter={true}>

        </BookingFlowPage>
    );

});
