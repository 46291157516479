import {IRoute} from "../../../navigation/models/route.interface";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {ManageMyBookingStepBase} from "../common/manage-my-booking-step.base";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {DialogResult} from "../../../dialog/dialog-enums";
import {IOnBeforeDeactivationContext} from "../../../../models/wizard/wizard.step.interface";

export class ManageMyBookingSelectNewReturnFlightStep extends ManageMyBookingStepBase {

    get title(): string {
        return this.services.language.translate('Return');
    }

    get route(): IRoute {
        return this.routes.selectNewReturnFlight;
    }

    protected _shouldBeDisplayed(): boolean {
        return super._shouldBeDisplayed() && !this.booking.flightSearchController.isOneWayDepartureTrip;
    }

    async validate(): Promise<ValidationResultEnum> {
        if(!this.services.booking.availability.hasReturnFareSelected) {
            this.services.alert.showError(this.services.language.translate('You must select a flight'));
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    protected async _onBeforeForwardDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        const result =  await super._onBeforeForwardDeactivation(context);
        if(result !== DialogResult.Accepted) {
            return result;
        }

        await waitForBookingMutations(this.booking);

        return DialogResult.Accepted;

    }

    async next(): Promise<void> {

        if(ValidationResultEnum.Success !== await this.validate()) {
            return;
        }

        if(this.booking.manageMyBooking.getUnresolvedServices().hasUnresolvedServices) {
            this._activateStepByRoute(this.routes.unresolvedServices);
        } else {
            this._activateStepByRoute(this.routes.selectSeats);
        }
    }


}
