import {ICheckInRestriction} from "./check-in-restriction.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {Station} from "../../../stations/station.service.interface";

export class AirportPreventsOnlineCheckInRestriction implements ICheckInRestriction {
    constructor(private readonly station: Station, private readonly services: IServiceFactory) {
    }

    get description(): string {
        return this.services.language.translationFor('Online check-in not available for {airportName} airport').withParams({airportName: this.station.stationName});
    }
    get shouldBlockSavingTravelDocuments(): boolean {
        return false;
    }

}
