import {VoluntaryJourneyChangeMutation} from "./voluntary-journey-change.mutation";
import {JourneyModel} from "../../../../journey/journey.model";
import {JourneySnapshotModel} from "../../../../snapshots/journey/journey-snapshot.model";

export class VoluntaryReturnJourneyChangeMutation extends VoluntaryJourneyChangeMutation {

    protected get currentJourneyModel(): JourneyModel | null {
        return this.booking.unfilteredJourneys[1] || null;
    }


    protected get initialJourneySnapshot(): JourneySnapshotModel | null {
        return this.booking.initialBookingSnapshot.returnJourney
    }
}
