import React from 'react';
import styled, {css} from "styled-components";
import {observer} from "mobx-react";
import {IStandardDropDownListViewModel} from "../../models/list/standard-drop-down-list-view-model.interface";
import {Check} from "../../../../../../types/type-checking";
import {FIELD_MIN_HEIGHT, fieldDefaultPadding} from "../../../../field-common-css-props";

const TextBox = styled.span<{isEmpty: boolean}>`
    padding: ${props => props.theme.spacing.small} 2px;
    
    ${
        props => props.isEmpty ?
                css`
                    width: 100%;
                    min-height: calc(${FIELD_MIN_HEIGHT} - 2 * ${props => fieldDefaultPadding(props.theme)});
                `
                : ``
    }
`

const ButtonBox = styled.button<{isEmpty: boolean}>`
    display: flex;
    justify-content: flex-start;
    border: none;
    width: 100%;
    background: transparent;
    outline: none;
    font-size: inherit;
    font-weight: inherit;
    color: ${props => props.isEmpty ? props.theme.colors.mediumTint : "inherit"};
    height: 100%;
    :focus > ${TextBox} {
        background-color: Highlight;
        color: HighlightText;
    }
`



interface StandardDropDownInputSmallScreenComponentProps<TFieldValue> {
    captureInputRef: (element: HTMLButtonElement) => void;
    listViewModel: IStandardDropDownListViewModel<TFieldValue>;
    placeHolder?: string;
}

export const StandardDropDownInputSmallScreenComponent = observer(<TFieldValue extends any>(props: StandardDropDownInputSmallScreenComponentProps<TFieldValue>) => {

    const selectedItemText = props.listViewModel.selectedItem?.getDisplayText();
    const textToDisplay = selectedItemText ?? props.placeHolder ?? "";


    return (
        <ButtonBox ref={props.captureInputRef}
                   type={"button"}
                   isEmpty={Check.isEmpty(selectedItemText)}>
            <TextBox isEmpty={Check.isEmpty(textToDisplay)}>
                {textToDisplay}
            </TextBox>

        </ButtonBox>
    )
})