import {Asset} from "./asset";
import {IAssetResolver} from "../asset-resolver.interface";
export function makeAssets(assetsResolver: IAssetResolver) {
	return {
		fonts: {
			Jaldi_Bold_eot: new Asset('fonts/Jaldi-Bold.eot', assetsResolver),
			Jaldi_Bold_ttf: new Asset('fonts/Jaldi-Bold.ttf', assetsResolver),
			Jaldi_Bold_woff: new Asset('fonts/Jaldi-Bold.woff', assetsResolver),
			Jaldi_Regular_eot: new Asset('fonts/Jaldi-Regular.eot', assetsResolver),
			Jaldi_Regular_ttf: new Asset('fonts/Jaldi-Regular.ttf', assetsResolver),
			Jaldi_Regular_woff: new Asset('fonts/Jaldi-Regular.woff', assetsResolver),
		},
		icons: {
			favicon_png: new Asset('icons/favicon.png', assetsResolver),
			icon_png: new Asset('icons/icon.png', assetsResolver),
		},
		images: {
			add_to_apple_wallet_png: new Asset('images/add-to-apple-wallet.png', assetsResolver),
			airplane_window_view_png: new Asset('images/airplane_window_view.png', assetsResolver),
			booking_search_background_jpg: new Asset('images/booking_search_background.jpg', assetsResolver),
			dangerous_goods_jpg: new Asset('images/dangerous_goods.jpg', assetsResolver),
			Feed_1080x1350_LHR_EN_png: new Asset('images/Feed_1080x1350_LHR_EN.png', assetsResolver),
			Flight_Itinerary_BG_png: new Asset('images/Flight_Itinerary_BG.png', assetsResolver),
			home_page_header_background_image_png: new Asset('images/home_page_header_background_image.png', assetsResolver),
			home_page_header_background_image_1024x170_png: new Asset('images/home_page_header_background_image_1024x170.png', assetsResolver),
			home_page_header_background_image_1024x240_png: new Asset('images/home_page_header_background_image_1024x240.png', assetsResolver),
			home_page_header_background_image_1920x170_png: new Asset('images/home_page_header_background_image_1920x170.png', assetsResolver),
			img1_png: new Asset('images/img1.png', assetsResolver, ["ro-ro"]),
			splash_png: new Asset('images/splash.png', assetsResolver),
			under_chair_bag_animation_gif: new Asset('images/under_chair_bag_animation.gif', assetsResolver),
		},
	}
}