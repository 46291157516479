import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {IDotRezPartialBookingSessionData} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";

export class LoadPaymentMethodsMutation extends BookingMutationActionBase {

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        await this.booking.paymentHandler.loadPaymentMethods({
            showLoadingIndicator: false
        });
        return {};
    }

    get willAffectTotal(): boolean {
        return false;
    }
}
