import {FlightSearchStepBase} from "./flight-search-step.base";

export class OriginStationSelectionStep extends FlightSearchStepBase {

    get title(): string {
        return this.services.language.translate('Departure');
    }

    async next(): Promise<void> {
        this._activateStepByRoute(this.routes.destinationStation);
    }
    get route() {
        return this.routes.originStation;
    }
}
