import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {InfantPassengerModel} from "../../passenger/infant-passenger.model";

export class InfantNameChangeMutation extends BookingMutationActionBase {
    constructor(protected readonly infant: InfantPassengerModel) {
        super(infant.booking);
    }

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        try {
            this.infant.companion.detachCompanion();
            await this.infant.saveInfantDetails();
        } catch (err) {
            this.services.logger.error('Infant name change failed', err);
            this.infant.cancelChanges();
            this.services.alert.showError(this.services.language.translate('Infant name change failed'));
        }

        return {};
    }
}
