import {PaymentStepBase} from "../common/payment-step-base";
import {ICheckInRoutes} from "../../../navigation/routes/check-in/check-in.routes.interface";

export class CheckInFlowPaymentStep extends PaymentStepBase<ICheckInRoutes> {

    get route() {
        return this.routes.payment;
    }

    protected _shouldBeDisplayed(): boolean {
        return super._shouldBeDisplayed() && this.booking.balanceDue.amount > 0;
    }

    protected get routes(): ICheckInRoutes {
        return this.services.navigator.routes.checkIn;
    }
}
