import styled from "styled-components";
import {SegmentButtonBaseBox} from "../segment-button-base.box";

export const PrimarySegmentButtonBox = styled(SegmentButtonBaseBox)`
  --background: ${props => props.theme.colors.medium};
  --color: ${props => props.theme.colors.mediumContrast};
  --background-checked: ${props => props.theme.colors.primary};
  --color-checked: ${props => props.theme.colors.primaryContrast};
  --indicator-color: ${props => props.theme.colors.primary};
`
