import {
    IDotRezSegment,
    IDotRezPassengerSegment, IDotRezLeg
} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {PassengerSegmentModel} from "../passenger-segment/passenger-segment.model";
import {NullablePrice, Price} from "../../../currency/price";
import {ISegmentViewModel} from "./segment-view-model.interface";
import {JourneyModel} from "../journey/journey.model";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {BundleModel} from "../bundle/bundle.model";
import {MaturePassengerModel} from "../passenger/mature-passenger.model";
import {ICheckInRestriction} from "../check-in-restrictions/check-in-restriction.interface";
import {AirportPreventsOnlineCheckInRestriction} from "../check-in-restrictions/airport-prevents-online-check-in-restriction";
import {LegModel} from "./leg.model";
import {ISsrAvailability} from "../ssrs/availability/ssr-availability.iterface";
import {SegmentSsrsAvailabilityModel} from "../ssrs/availability/segment-ssrs-availability.model";
import {SegmentSeatMapEditorModel} from "../seat-maps/segment-seat-map-editor.model";
import {SegmentBaseModel} from "../base-models/segment/segment-base.model";
import {IServiceFactory} from "../../../service-factory.interface";
import {FeeTypeEnum} from "../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {FareClassOfServiceEnum} from "../../../dot-rez-api/data-contracts/enums/fare-class-of-service.enum";
import {GroupBookingOnlineCheckInRestriction} from "../check-in-restrictions/group-booking-online-check-in-restriction";
import {NullableString} from "../../../../types/nullable-types";


export class SegmentModel extends SegmentBaseModel<LegModel> implements ISegmentViewModel {
    constructor(public readonly journey: JourneyModel,
                private readonly _segmentKey: string) {
        super();
        this.passengers = this.segmentDotRezData.passengerSegment.map(item => new PassengerSegmentModel(this, item.key));
        this.legs = this.segmentDotRezData.legs.map(leg => new LegModel(this, leg.legKey));
        this.ssrsAvailability = new SegmentSsrsAvailabilityModel(this);
        this.seatMapEditor = new SegmentSeatMapEditorModel(this);
    }


    get services(): IServiceFactory {
        return this.journey.services;
    }

    get segmentIndex(): number {
        return this.journey.journeyDotRezData.segments.findIndex(s => s.segmentKey === this.segmentKey);
    }

    get segmentKey(): string {
        return this._segmentKey;
    }
    get segmentDotRezData(): IDotRezSegment {
        return this.journey.getSegmentDotRezData(this._segmentKey);
    }

    readonly legs: LegModel[];
    getLegData(legKey: string): IDotRezLeg {
        return this.segmentDotRezData.legs.filter(l => l.legKey === legKey)[0];
    }

    readonly passengers: PassengerSegmentModel[];

    get minSeatsFee(): Price {
        return this.seatMapEditor.minSeatPrice;
    }

    getPassenger(passengerKey: string): MaturePassengerModel {
        return this.journey.getPassenger(passengerKey);
    }

    getPassengerSegmentDotRezData(passengerKey: string): IDotRezPassengerSegment {
        for(let p of this.segmentDotRezData.passengerSegment) {
            if(p.key === passengerKey) {
                return p.value;
            }
        }
        throw new Error(`There is no passenger segment with key ${passengerKey}`);
    }

    readonly seatMapEditor: SegmentSeatMapEditorModel;

    translate(key: string): string {
        return this.journey.translate(key);
    }

    createPrice(amount: number): Price {
        return this.journey.createPrice(amount)
    }

    ssrsAvailability: SegmentSsrsAvailabilityModel;

    getSsrAvailability(ssrType: ISsrType): ISsrAvailability {
        return this.ssrsAvailability.getSsrAvailability(ssrType);

    }

    getAllSoldSsrKeysWithoutBundleIncluded(): string[] {
        let result: string[] = [];

        this.passengers.forEach(passenger => {
            result = [...result, ...passenger.getAllSoldSsrKeysWithoutBundleIncluded()]
        });

        return result;
    }

    getSsrKeysToRemoveOnBundleChange(newBundle: BundleModel): string[] {
        let ssrKeys: string[] = [];

        this.passengers.forEach(passenger => {
            ssrKeys = [...ssrKeys, ...passenger.getSsrKeysToRemoveOnBundleChange(newBundle)]
        });

        return ssrKeys;
    }

    onBundleUpdated() {
        this.passengers.forEach(p => p.onBundleUpdated());
    }

    private get isGroupBooking(): boolean {
        return this.segmentDotRezData.fares.some(f => f.classOfService === FareClassOfServiceEnum.GroupBooking);
    }

    get isOnlineCheckInAllowed(): boolean {
        return this.getUserIndependentCheckInBlockingRestrictions().length === 0;
    }

    //Restrictions that cannot be solved by the user input
    getUserIndependentCheckInBlockingRestrictions(): ICheckInRestriction[] {

        if(!this.designator.allowsOnlineCheckIn) {
            return [new AirportPreventsOnlineCheckInRestriction(this.designator.origin, this.services)];
        }

        if(this.isGroupBooking) {
            return [new GroupBookingOnlineCheckInRestriction(this.services)];
        }

        return [];
    }

    //Restrictions that can be solved by the user input
    getUserDependentCheckInBlockingRestrictions(): ICheckInRestriction[] {
        return [];
    }

    getEligiblePassengersForOnlineCheckIn(): PassengerSegmentModel[] {
        const checkInInfo = this.journey.booking.checkInRequirements;
        if(!checkInInfo) {
            return [];
        }

        if(!this.isOnlineCheckInAllowed) {
            return [];
        }

        const eligiblePassengers: PassengerSegmentModel[] = [];

        for(let passengerSegment of this.passengers) {
            if(passengerSegment.isValidForOnlineCheckIn) {
                eligiblePassengers.push(passengerSegment);
            }
        }
        return eligiblePassengers;
    }

    markBundleAsSelected(bundleCode: string): void {
        this.passengers.forEach(p => p.markBundleAsSelected(bundleCode));
    }

    getAnalyticsUniqueId(): string {
        return `${this.identifier.carrierCode} ${this.identifier.identifier} ${this.journey.services.time.formatDD_MM_YYY(this.designator.departureDate)} ${this.journey.services.time.formatHHmm(this.designator.departureDate)}`;
    }

    getInitialFare(passengerKey: string): NullablePrice {
        return this.journey.getInitialFare(this.segmentIndex, passengerKey);
    }
    
    getInitialSeatPrice(passengerKey: string): NullablePrice {
        return this.journey.getInitialSeatPrice(this.segmentIndex, passengerKey);
    }

    getInitialSeatNumber(passengerKey: string): NullableString {
        return this.journey.getInitialSeatNumber(this.segmentIndex, passengerKey);
    }


    getInitialPurchasedSsrPrice(passengerKey: string, ssrType: ISsrType, ssrIndex: number): NullablePrice {
        return this.journey.getInitialPurchasedSsrPrice(this.segmentIndex, passengerKey, ssrType, ssrIndex)
    }

    getInitialInBundleSsrPrice(passengerKey: string, ssrType: ISsrType, ssrIndex: number): NullablePrice {
        return this.journey.getInitialInBundleSsrPrice(this.segmentIndex, passengerKey, ssrType, ssrIndex)
    }

    getInitialOtherFeePrice(passengerKey: string, feeType: FeeTypeEnum, feeIndex: number) {
        return this.journey.getInitialOtherFeePrice(this.segmentIndex, passengerKey, feeType, feeIndex)
    }

    getInitialInfantPrice(passengerKey: string): NullablePrice {
        return this.journey.getInitialInfantPrice(this.segmentIndex, passengerKey);
    }
}
