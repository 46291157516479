import React, {useRef} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {
    BookingSearchStrategyEnum, ISearchBookingParamsEditor,
    SearchBookingParamsEditor
} from "../../../../services/booking/search-booking-params.editor";
import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";
import {FormComponent} from "../../../../components/forms/form.component";
import {StringFieldEditorComponent} from "../../../../components/forms/string-field/string-field-editor.component";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {EmailFieldEditorComponent} from "../../../../components/forms/contact/email-field-editor.component";
import {IFormField} from "../../../../models/forms/form-field.interface";


const FindBookingByBox = styled.div`
    line-height: 1;
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colors.medium};
    display: flex;
    align-items: center;
    margin-right: ${props => props.theme.spacing.small};
`

const SwitchSearchModeButton = styled(PrimaryButtonInverted)`
  padding-bottom: ${props => props.theme.spacing.space(1.5)};
  padding-top: ${props => props.theme.spacing.space(1.5)};
`

const BookingSearchStrategySelectionBox = styled.div`
    display: flex;
    align-content: center;

`



const BookingSearchHeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${props => props.theme.fontSize.xxxxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
        font-size: ${props => props.theme.fontSize.xxxLarge};
    }
    
`

const BookingSearchComponentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.extraLarge};
    width: 100%;
`

const BookingSearchStrategyToggleComponent: React.FC<{paramsEditor: SearchBookingParamsEditor}> = observer((props) => {
    const services = useServices();

    const setFocusOnField = (field: IFormField) => {
        setTimeout(() => field.setFocus(), 300);
    }

    const onToggleStrategy = () => {
        if(props.paramsEditor.fields.strategy.value === BookingSearchStrategyEnum.byName) {
            props.paramsEditor.fields.strategy.setValue(BookingSearchStrategyEnum.byEmail);
            setFocusOnField(props.paramsEditor.fields.emailAddress);

        } else {
            props.paramsEditor.fields.strategy.setValue(BookingSearchStrategyEnum.byName);
            setFocusOnField(props.paramsEditor.fields.lastName);
        }
    }

    const renderText = () => {
        if(props.paramsEditor.fields.strategy.value === BookingSearchStrategyEnum.byName) {
            return services.language.translate('E-mail');
        } else {
            return services.language.translate('Last name');
        }
    }



    return (
        <BookingSearchStrategySelectionBox>

            <FindBookingByBox>
                {services.language.translate('Find booking by:')}
            </FindBookingByBox>
            <SwitchSearchModeButton fullWidth={false}
                                    fontSize={"small"}
                                    onClick={onToggleStrategy}>
                {renderText()}
            </SwitchSearchModeButton>
        </BookingSearchStrategySelectionBox>
    );
})

export interface BookingSearchComponentProps {
    title?: string;
    retrieveBookingButtonText: string;
    onRetrieveBookingButtonClick: (paramsEditor: ISearchBookingParamsEditor) => void;
}

export const BookingSearchComponent: React.FC<BookingSearchComponentProps> = observer((props) => {
    const services = useServices();
    const searchParamsEditorRef = useRef(new SearchBookingParamsEditor(services));
    const searchParamsEditor = searchParamsEditorRef.current;

    const onSubmitHandler = () => {
        props.onRetrieveBookingButtonClick(searchParamsEditor);
    }

    const renderHeader = () => {
        let titleText = services.language.translate(`Let's find your booking`);
        if(services.layout.shouldUseWebappLayout) {
            titleText = props.title ?? titleText;
        }

        return (
            <BookingSearchHeaderBox>
                {titleText}
            </BookingSearchHeaderBox>
        )
    }


    return (
        <BookingSearchComponentBox>

            {renderHeader()}

            <FormComponent>
                <StringFieldEditorComponent field={searchParamsEditor.fields.recordLocator}/>
                <StringFieldEditorComponent field={searchParamsEditor.fields.lastName}/>
                <EmailFieldEditorComponent field={searchParamsEditor.fields.emailAddress}/>
                <BookingSearchStrategyToggleComponent paramsEditor={searchParamsEditor}/>
                <PrimaryButton  onClick={onSubmitHandler}>
                    {props.retrieveBookingButtonText}
                </PrimaryButton>
            </FormComponent>

        </BookingSearchComponentBox>
    )
});
