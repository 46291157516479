import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import { SsrModificationBlockingReasonComponent } from "../../../common-components/ssr-modification-blocking-reason.component";
import {ISsrViewModel} from "../../../../../services/booking/models/ssrs/ssr-view-model.interface";
import {PriceWithDiscountComponent} from "../../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../../components/price/standard-price-label.component";
import {CheckboxComponent} from "../../../../../basic-components/checkbox/checkbox.component";
import {FootNoteComponent} from "../../../../../components/foot-note/foot-note.component";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";


const FlexEditorComponentBox = styled.div<{isBlockedForModifications: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    cursor: ${props => props.isBlockedForModifications ? "default": "pointer"};
    line-height: 1;
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
    
`

const SsrDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: ${props => props.theme.spacing.medium};
`

const SsrNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.primary};
`;

const PriceComponentBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
`


export interface FlexCardEditorComponentProps {
    ssr: ISsrViewModel
}

export const FlexSsrEditorComponent: React.FC<FlexCardEditorComponentProps> = observer((props) => {
    const blockingReason = props.ssr.modificationBlockingReason;

    const onClickHandler = async () => {
        if(blockingReason) {
            return;
        }

        if(props.ssr.totalQuantity > 0) {
            await props.ssr.removeAll();
        } else {
            await props.ssr.sellOne();
        }
    }

    const renderPrice = () => {
        if (blockingReason) {
            return (
                <SsrModificationBlockingReasonComponent reason={blockingReason}/>
            );
        }

        return (
            <PriceComponentBox>
                <PriceWithDiscountComponent discountedPrice={props.ssr.discountedMinPrice}
                                            standardPrice={props.ssr.standardMinPrice}
                                            label= {<StandardPriceLabelComponent/>}/>
            </PriceComponentBox>
        );
    }

    const renderCheckBox = () => {
        if(blockingReason) {
            return null;
        }

        return (
            <CheckboxComponent checked={props.ssr.totalQuantity > 0}
                               isReadOnly={true}/>
        );
    }

    return (
        <FlexEditorComponentBox onClick={onClickHandler} isBlockedForModifications={Boolean(blockingReason)}>
            <SsrDetailsBox>
                <SsrNameBox>{'FLEX'}</SsrNameBox>
                {renderPrice()}
                <VerticalSpacer size={"small"}/>
                <FootNoteComponent>
                    {props.ssr.ssrType.details}
                </FootNoteComponent>
            </SsrDetailsBox>
            {renderCheckBox()}
        </FlexEditorComponentBox>
    );
});
