import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {AvailableBookingCompanionComponent} from "./available-booking-companion.component";
import {CreateAnimation} from "@ionic/react";
import {DEFAULT_ANIMATION_TIME} from "../../../../global-constants";

const CompanionsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    flex: 1 0 auto;
    margin-bottom: -8px;

    &::-webkit-scrollbar {
        height: 0;
        width: 0;
    }

    &::-webkit-scrollbar-thumb {
        height: 0;
        width: 0;
    }

    &::-webkit-scrollbar-track {
        height: 0;
        width: 0;
    }
`;

const CompanionsInnerBox = styled.div`
    height: 0;
    
`

interface AvailableBookingCompanionsComponentProps {
    passenger: IPassengerViewModel;
}
export const AvailableBookingCompanionsComponent: React.FC<AvailableBookingCompanionsComponentProps> = observer((props) => {

    const companionHandler = props.passenger.companion;
    if (!companionHandler.showAvailableCompanions) {
        return null;
    }

    if (companionHandler.isAttached) {
        return null;
    }

    const companions = companionHandler.availableCompanions;
    if (companions.length === 0) {
        return null;
    }

    return (
        <CreateAnimation key={props.passenger.passengerKey}
                         duration={DEFAULT_ANIMATION_TIME}
                         iterations={1}
                         play={true}
                         fromTo={{
                             property: 'transform',
                             fromValue: 'translateX(100%)',
                             toValue: 'translateX(0)'
                         }}>
            <CompanionsBox className={'tst-available-companions'}>
                <CompanionsInnerBox>
                    {companions.map(comp => <AvailableBookingCompanionComponent key={comp.customerNumber}
                                                                                passenger={props.passenger}
                                                                                companion={comp}/>)}
                </CompanionsInnerBox>
            </CompanionsBox>
        </CreateAnimation>
    )
});
