import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {DialogCloseButtonBehavior} from "../../../../../services/dialog/dialog-enums";
import {ManageMyBookingPassengersDialogComponent} from "./manage-my-booking-passengers-dialog.component";
import styled from "styled-components";
import {IPassengerViewModel} from "../../../../../services/booking/models/passenger/passenger-view-model.interface";
import {PersonIcon} from "../../../../../assets/svg/person.icon";
import {ManageMyBookingEditButtonBox} from "../../boxes/manage-my-booking-edit-button.box";
import {EditPencilIcon} from "../../../../../assets/svg/edit-pencil.icon";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";

const PassengersListBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const PassengerBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
`

const IconBox = styled.div`
    flex-shrink: 0;
`

const PassengerNameBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.primaryTint};
    margin-left: ${props => props.theme.spacing.large};
    flex-grow: 1;
    line-height: 1;
`

const ManageMyBookingPassengerComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const services = useServices();
    const onClickHandler = async () => {
        const blockingReason = props.passenger.nameChangeBlockingReason;
        if(blockingReason) {
            services.alert.showError(blockingReason);
            return;
        }

        await services.dialog.showStandardDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            render: (dialogHandler) => <ManageMyBookingPassengersDialogComponent dialogHandler={dialogHandler} passenger={props.passenger} />,
            onReject: async () => {
                props.passenger.cancelChanges();
            }
        });
    }

    const renderEditButton = () => {
        return (
            <ManageMyBookingEditButtonBox onClick={onClickHandler}>
                {services.language.translate('Edit')} <EditPencilIcon size={1.2}/>
            </ManageMyBookingEditButtonBox>
        );
    }

    return (
        <PassengerBox>
            <IconBox>
                <PersonIcon size={2.4} color={services.theme.currentTheme.colors.primary}/>
            </IconBox>
            <PassengerNameBox>
                {props.passenger.getFullName()}
            </PassengerNameBox>
            {renderEditButton()}
        </PassengerBox>
    )
});

export const ManageMyBookingPassengersComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    const allPersonsInTheBooking = booking.passengers.getAllPersonsInTheBooking();


    return (
        <PassengersListBox>

                {allPersonsInTheBooking.map((passenger, index) => {
                    return (
                        <React.Fragment key={passenger.passengerKey}>
                            <ManageMyBookingPassengerComponent passenger={passenger}/>
                            <VerticalSpacer size="medium" isVisible={index < allPersonsInTheBooking.length - 1}/>
                        </React.Fragment>
                    )
                })}

        </PassengersListBox>

    )
});
