import {
    BundleCodes,
    IBundleTypesConfiguration,
    IBundleTypesConfigurationService
} from "./bundle-types-configuration.service.interface";
import {IBundleType} from "../bundle-types/bundle-type.interface";
import {TimeSpan} from "../../types/time-span";
import {IServiceFactory} from "../service-factory.interface";
import {ServiceBase} from "../service-base";


abstract class BundleTypeBase implements IBundleType {
    constructor(protected readonly services: IServiceFactory) {
    }

    abstract get code(): string;
    abstract readonly fullName: string;
    abstract readonly prefix: string;
    abstract readonly shortName: string;
    abstract readonly isDefaultBundle: boolean;
    abstract readonly earliestCheckIn: TimeSpan;

    public readonly earliestCheckInWhenHasSeat = TimeSpan.fromDays(30);
    abstract getBackgroundColor(): string;
    abstract getTextColor(): string;
    getUpgradeMessage(toBundle: IBundleType): string {
        return '';
    }

    get requireBookingImpersonation(): boolean {
        return false;
    }

    appendVirtualSsrs(realSsrsIncludedInTheBundle: string[]): string[] {
        const result: string[] = [...realSsrsIncludedInTheBundle];
        for(let virtualSsrCode of this.services.configuration.getBundleVirtualSsrs(this.code)) {
            const ssrType = this.services.ssrTypes.getSsrType(virtualSsrCode);
            if(ssrType.aliasFor) {
                if(realSsrsIncludedInTheBundle.includes(ssrType.aliasFor.ssrCode)
                    && !realSsrsIncludedInTheBundle.includes(virtualSsrCode)) {
                    result.push(virtualSsrCode)
                }
            }

        }

        return result;
    }

    get minTimeToChangeDateBeforeFlight(): TimeSpan {
        let bundleSpecificMinTime = this.services.configuration.data.bundles[this.code]?.minTimeToChangeDateBeforeFlight;
        if(bundleSpecificMinTime) {
            return TimeSpan.parse(bundleSpecificMinTime);
        }

        return TimeSpan.parse(this.services.configuration.data.minTimeToChangeDateBeforeFlight);

    }
}

class ClassicBundleType extends BundleTypeBase implements IBundleType {
    public readonly code = BundleCodes.BPLU;
    public readonly fullName = 'CLASSIC';
    public readonly prefix = '';
    public readonly shortName = 'CLASSIC';
    public readonly isDefaultBundle = true;
    public readonly earliestCheckIn = TimeSpan.fromDays(30);
    public getBackgroundColor(): string {
        return `rgba(${this.services.theme.currentTheme.colors.secondaryRgb},0.7)`;
    }

    public getTextColor(): string {
        return this.services.theme.currentTheme.colors.secondaryContrast;
    }
    getUpgradeMessage(toBundle: IBundleType): string {
        return this.services.language.translationFor('Upgrade to {bundle} to benefit of premium seats, extra 23kg check-in bag, large cabin bag and priority boarding.').withParams({bundle: toBundle.fullName});
    }
}

class BizBundleType extends BundleTypeBase implements IBundleType {
    public get code(): string  {
        return BundleCodes.CBIZ;
    }
    public readonly fullName = 'BIZ';
    public readonly prefix = '';
    public readonly shortName = 'BIZ';
    public readonly isDefaultBundle = false;
    public readonly earliestCheckIn = TimeSpan.fromDays(30);
    public getBackgroundColor() {
        return this.services.theme.currentTheme.colors.primary;
    }

    public getTextColor() {
        return this.services.theme.currentTheme.colors.primaryContrast;
    }
}

class CbcmBundleType extends BizBundleType {
    public get code(): string {
        return BundleCodes.CBCM;
    }
}

class CorporateBundleType extends BundleTypeBase implements IBundleType {
    public get code(): string {
        return BundleCodes.CORR;
    }

    public readonly fullName = 'Corporate';
    public readonly prefix = '';
    public readonly shortName = 'Corporate';
    public readonly isDefaultBundle = false;
    public readonly earliestCheckIn = TimeSpan.fromDays(30);
    public getBackgroundColor() {
        return this.services.theme.currentTheme.colors.primary;
    }

    public getTextColor() {
        return this.services.theme.currentTheme.colors.primaryContrast;
    }

}

class BasicBundleType extends BundleTypeBase implements IBundleType{
    public get code(): string {
        return BundleCodes.ABAS;
    }

    public readonly fullName = 'BASIC';
    public readonly prefix = '';
    public readonly shortName = 'BASIC';
    public readonly isDefaultBundle = false;
    public readonly isBasicBundle = true;
    public readonly earliestCheckIn = TimeSpan.fromHours(24);
    public getBackgroundColor() {
        return this.services.theme.currentTheme.colors.primaryContrast;
    }

    public getTextColor() {
        return this.services.theme.currentTheme.colors.primary;
    }
    getUpgradeMessage(toBundle: IBundleType) {
        return this.services.language.translationFor('Upgrade to {bundle} to benefit of free standard seat, 23kg check-in bag and possibility to check-in at the airport for free.').withParams({bundle: toBundle.fullName});
    }
}

abstract class TerritorialContinuityBundle extends BundleTypeBase implements IBundleType {
    public abstract get code(): string;
    public readonly fullName = this.services.language.translate('TERRITORIAL CONTINUITY');
    public readonly prefix = '';
    public readonly shortName = this.services.language.translate('TERRITORIAL CONTINUITY');
    public readonly isDefaultBundle = true;
    public readonly earliestCheckIn = TimeSpan.fromDays(30);
    public getBackgroundColor(): string {
        return `rgba(${this.services.theme.currentTheme.colors.secondaryRgb},0.7)`;
    }

    public getTextColor(): string {
        return this.services.theme.currentTheme.colors.secondaryContrast;
    }

    get requireBookingImpersonation(): boolean {
        return true;
    }
}

//Bundle for Sardinia
class AbpsBundleType extends TerritorialContinuityBundle {
    public get code(): string {
        return BundleCodes.ABPS;
    }
}

//Bundle for Sardinia workers
class AbwkBundleType extends TerritorialContinuityBundle {
    public get code(): string {
        return BundleCodes.ABWK;
    }
}

//bundle for marche
class AbmrBundleType extends TerritorialContinuityBundle {

    public get code(): string {
        return BundleCodes.ABMR;
    }

    public readonly earliestCheckIn = TimeSpan.fromHours(24);
}

export class BundleTypesConfigurationService extends ServiceBase implements IBundleTypesConfigurationService {
    getConfiguration(): IBundleTypesConfiguration {
        const bundleTypesDisplayOrder: IBundleType[] = [
            new ClassicBundleType(this.services),
            new BizBundleType(this.services),
            new CbcmBundleType(this.services),
            new BasicBundleType(this.services),
            new AbpsBundleType(this.services),
            new AbwkBundleType(this.services),
            new AbmrBundleType(this.services),
            new CorporateBundleType(this.services)
        ];

        return {
            bundlesDisplayOrder: bundleTypesDisplayOrder,
        }
    }
}
