import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const PlainLandingLineIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.2} {...props} viewBox="0 0 22 19.462" ratio={22/19.462}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(0 0)"><path d="M0,1H22V0H0Z" transform="translate(0 18.462)" /><path d="M.553,8.466s3.126,3.016,4.207,3.117l12.549,1.137a2.288,2.288,0,0,0,2.3-1.531,1.932,1.932,0,0,0-1.778-1.907l-5.2-.477L7.18.124a.317.317,0,0,0-.367-.1L5.732.546a.32.32,0,0,0-.174.348l1.549,7.4L3.6,7.98,1.231,4.341a.272.272,0,0,0-.339-.092l-.706.339A.353.353,0,0,0,0,4.937,22.974,22.974,0,0,0,.553,8.466Z" transform="translate(0.254)" /></g>
        </SvgIcon>
    )
}
