import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {IUnresolvedPassengerSegmentSeat} from "../../../../services/booking/models/journey/unresolved-passenger-segment-seat.interface";
import styled from "styled-components";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../components/designator/designator.component";
import {IUnresolvedSsrs} from "../../../../services/booking/models/journey/unresolved-ssrs";
import { ExclamationCircle } from "../../../../assets/svg/exclamation-circle.icon";
import {BookingFlowPage} from "../../../booking-flow/booking-flow.page";


const UnresolvedSeatsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 24px 16px 32px;
`


const UnresolvedSeatInfoBox = styled.div`
    display: flex;
    flex-direction: row;
    font-size: ${props => props.theme.fontSize.medium};
    gap: 4px;
    margin-top: 4px;
`

const UnresolvedSeatComponent: React.FC<{unresolvedSeat: IUnresolvedPassengerSegmentSeat}> = observer((props) => {
    return (
        <UnresolvedSeatInfoBox>
            <div>{props.unresolvedSeat.seatNumber}</div>
            <div>{props.unresolvedSeat.passengerSegment.passengerFirstName}</div>
        </UnresolvedSeatInfoBox>
    );
});

const UnresolvedFlightsSeatsBox = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1;
    margin-bottom: 16px;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const MessageBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    margin-bottom: 16px;
    line-height: 1;
`

const DesignatorComponentBox = styled(DesignatorComponent)`
    padding-bottom: 8px;
`

const UnresolvedFlightSeats: React.FC<{unresolvedSeats: IUnresolvedPassengerSegmentSeat[]}> = observer((props) => {
    const designator = props.unresolvedSeats[0].passengerSegment.flightDesignator;
    return (
        <UnresolvedFlightsSeatsBox>
            <DesignatorComponentBox displayMode={DesignatorDisplayMode.stationsNamesOnly} designator={designator}/>
            {
                props.unresolvedSeats.map(unresolvedSeat => <UnresolvedSeatComponent key={unresolvedSeat.seatNumber + unresolvedSeat.passengerSegment.passengerSegmentUniqueKey} unresolvedSeat={unresolvedSeat}/>)
            }
        </UnresolvedFlightsSeatsBox>
    )
});

const UnresolvedSeatsComponent: React.FC<{unresolvedSeats: IUnresolvedPassengerSegmentSeat[]}> = observer((props) => {
    const services = useServices();
    if(props.unresolvedSeats.length === 0) {
        return null;
    }

    const unresolvedSeatsGroupedByDesignator = props.unresolvedSeats.groupByKey(unresolvedSeat => unresolvedSeat.passengerSegment.flightDesignator.uniqueKey);

    return (
        <UnresolvedSeatsContainerBox>
            <MessageBox>{services.language.translate('The following seats could not be transferred')}</MessageBox>
            {
                Object.keys(unresolvedSeatsGroupedByDesignator).map(designatorKey => <UnresolvedFlightSeats key={designatorKey} unresolvedSeats={unresolvedSeatsGroupedByDesignator[designatorKey]}/>)
            }
        </UnresolvedSeatsContainerBox>
    )
});

const UnresolvedSsrsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 24px 16px 36px;
`

const UnresolvedFlightSsrBox = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1;
    margin-bottom: 16px;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const UnresolvedFlightSsrPassengerBox = styled.div`
    margin-top: 4px;
`

const AlertBox = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.medium};
    padding: 12px;
    background-color: ${props => props.theme.colors.warning}30;
    margin: 0 0 8px;
    line-height: 1.2;

    svg {
        flex-shrink: 0;
        margin-right: 8px;
        margin-top: 2px;
    }
`

const UnresolvedFlightSsrsComponent: React.FC<{unresolvedSsrs: IUnresolvedSsrs}> = observer((props) => {
    return (
        <UnresolvedFlightSsrBox>
            <DesignatorComponentBox displayMode={DesignatorDisplayMode.stationsNamesOnly} designator={props.unresolvedSsrs.designator}/>
            {
                props.unresolvedSsrs.ssrs.map(ssr => <UnresolvedFlightSsrPassengerBox key={ssr.ssrType.ssrCode}>{`${ssr.count} x ${ssr.ssrType.name}`}</UnresolvedFlightSsrPassengerBox>)
            }
        </UnresolvedFlightSsrBox>
    )
});

const UnresolvedSrsComponent: React.FC<{unresolvedSsrs: IUnresolvedSsrs[]}> = observer((props) => {
    const services = useServices();
    if(props.unresolvedSsrs.length === 0) {
        return null;
    }
    return (
        <UnresolvedSsrsContainerBox>
            <MessageBox>{services.language.translate('The following services could not be transferred:')}</MessageBox>
            {
                props.unresolvedSsrs.map(flightUnresolvedSsrs => <UnresolvedFlightSsrsComponent key={flightUnresolvedSsrs.designator.uniqueKey} unresolvedSsrs={flightUnresolvedSsrs} />)
            }
        </UnresolvedSsrsContainerBox>
    );
});

const AllServicesTransferredMessageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontSize.large};
  
`

const ManageMyBookingUnresolvedServicesPageContentComponent: React.FC = observer(() => {
    const services = useServices();

    const unresolvedServices = services.booking.current.manageMyBooking.getUnresolvedServices();
    const hasDisruption = services.booking.current.manageMyBooking.canMoveDisruptedFlights;

    if(!unresolvedServices.hasUnresolvedServices) {
        return (
            <AllServicesTransferredMessageBox>
                {services.language.translate('All services are transferred')}
            </AllServicesTransferredMessageBox>
        )
    }

    return (
        <>
            <AlertBox>
                <ExclamationCircle color={services.theme.currentTheme.colors.primary} size={1.6} />
                {services.language.translate(`Some of the services originally booked are no longer available but the system will create a credit in your favor exclusively to be used for adding missing services within the limit of this credit. Kindly check the value of this credit displayed as Spoilage fee on your cart and feel free to consume it and replace any of the missing services with new ones.`)}
                &nbsp;
                {hasDisruption ? services.language.translate(`Attention: this credit won't be available after closing this booking change. Feel free to contact our Customer Service Team if you are not able to replace all the missing services.`) : services.language.translate(`Attention: this credit won't be available after closing this booking change and it is non-refundable.`) }
            </AlertBox>
            <UnresolvedSeatsComponent unresolvedSeats={unresolvedServices.seats}/>
            <UnresolvedSrsComponent unresolvedSsrs={unresolvedServices.ssrs}/>
        </>
    )
});

export const ManageMyBookingUnresolvedServicesPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowPage title={services.language.translate('Services not transferred')}
                                    renderContent={() => (<ManageMyBookingUnresolvedServicesPageContentComponent/>)}
                                    showPromotionCode={false}
        >

        </BookingFlowPage>
    );
});
