import React from "react";
import {IFormField} from "../../../../models/forms/form-field.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {StandardDropDownComponent} from "../../standard-drop-down/standard-drop-down.component";
import {StandardDropDownListItemModel} from "../../standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";
import {Check} from "../../../../types/type-checking";
import {extractFieldEditorCommonProps, FieldEditorCommonProps} from "../../field-editor-common.props";

interface MonthFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<number>;
    displayStyle: "monthName" | "monthNumber"
    minMonth?: number;
    maxMonth?: number;
    placeholder?: string;
}
export const MonthFieldEditorComponent: React.FC<MonthFieldEditorComponentProps> = observer(props => {
    const services = useServices();

    let months: number[] = [];
    for(let i = 1; i <= 12; i++) {
        months.push(i);
    }

    if(!Check.isNullOrUndefined(props.minMonth)) {
       months = months.filter(m => m >= props.minMonth!);
    }

    if(!Check.isNullOrUndefined(props.maxMonth)) {
        months = months.filter(m => m <= props.maxMonth!);
    }

    const items = months.map(m => {
        return new StandardDropDownListItemModel(
            m,
            props.displayStyle === "monthName"
                    ? services.time.getMonthFullNameFromMonthNumber(m)
                    : m.toString().padStart(2, '0')
        );
    });

    return (
        <StandardDropDownComponent field={props.field}
                                   items={items}
                                   placeholder={props.placeholder}
                                   modalDialogOptions={{
                                       title: services.language.translate('Month'),
                                       hideSearchBar: true
                                   }}
                                   {...extractFieldEditorCommonProps(props)}/>
    )
});
