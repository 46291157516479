import {IWizard} from "./wizard.interface";
import {IWizardStepViewModel} from "./wizard-step.view-model.interface";
import {IRoute} from "../../services/navigation/models/route.interface";

export class EmptyWizard implements IWizard {
    readonly currentStep: IWizardStepViewModel | null = null;
    readonly currentStepIndex: number = -1;
    readonly steps: IWizardStepViewModel[] = [];
    readonly stepsCount: number = 0;

    async nextStep(): Promise<void> {
    }


    canGoToStep(stepIndex: number): boolean {
        return false;
    }

    async goToStepByIndex(stepIndex: number): Promise<void> {
    }

    start(): void {
    }

    dispose() {
    }

    async goToStepByRoute(route: IRoute): Promise<void> {
    }

    activateStepByRoute(route: IRoute): void {
    }

}
