import {ServiceFactoryBase} from "./service-factory-base";
import {Lazy} from "../utils/lazy";
import {IFlightSearchService} from "./flight-search/flight-search.service.interface";
import {FlightSearchHybridService} from "./flight-search/flight-search.hybrid.service";

export class HybridServiceFactory extends ServiceFactoryBase {
    protected async _onInitialize(): Promise<void> {
        await super._onInitialize();
        this._flightSearch.forceInit();
    }

    protected async _waitForMaintenance(): Promise<void> {
        await this.maintenance.waitForMaintenance();
    }

    protected async _showCookiesPolicy(): Promise<void> {
        await this.application.showCookiesPolicy();
    }

    private _flightSearch: Lazy<IFlightSearchService> = new Lazy<IFlightSearchService>(() => new FlightSearchHybridService(this));
    get flightSearch(): IFlightSearchService {
        return this._flightSearch.value;
    }

}
