import {MutableRefObject, useEffect, useState} from "react";
import {DEFAULT_ANIMATION_TIME} from "../global-constants";
import {ResizeObserver} from "@juggle/resize-observer";
import {useServices} from "./use-services.hook";



export default function useIsInViewPort(ref: MutableRefObject<any>) {
    const services = useServices();
    const [isIntersecting, setIntersecting] = useState(false);


    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting)
        );

        const resizeObserver = new ResizeObserver(() => {
            const element = (ref.current as HTMLElement);
            if(!element) {
                return;
            }
            const rect = element.getBoundingClientRect();
            const windowHeight = services.window.innerHeight;
            const windowWidth = services.window.innerWidth;

            const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
            const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);


            setIntersecting(vertInView && horInView);

        });

        let timeout: any = null;
        if(ref.current) {
            //set timeout here is to give any animation the chance to finish otherwise the element might not be yet in the viewport
            timeout =  setTimeout(() => {
                intersectionObserver.observe(ref.current);
                resizeObserver.observe(services.document.documentElement);
            }, DEFAULT_ANIMATION_TIME + 50);

        }
        return () => {
            if(timeout) {
                clearTimeout(timeout);
            }

            try {
                intersectionObserver?.disconnect();
            } catch (err) {
                services.logger.error('Failed to disconnect intersection observer', err);
            }

            try {
                resizeObserver?.disconnect();
            } catch (err) {
                services.logger.error('Failed to disconnect resize observer', err);
            }
        }
    }, [ref, services.document.documentElement, services.logger, services.window.innerHeight, services.window.innerWidth]);


    return isIntersecting;
}
