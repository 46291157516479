import {
    IFlightSsrsPurchasesViewModel,
    IManageSsrsViewModel
} from "./manage-ssrs-view-model.interface";
import {BookingModel} from "../../booking.model";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {JourneyModel} from "../../journey/journey.model";
import {ISsrTypeCount} from "../../ssrs/ssr-type-count.interface";
import {IServiceFactory} from "../../../../service-factory.interface";
import {SegmentModel} from "../../segment/segment.model";
import { NullableString } from "../../../../../types/nullable-types";

export abstract class ManageSsrsBaseModel implements IManageSsrsViewModel {
    constructor(public readonly title: string,
                protected readonly booking: BookingModel) {
    }

    abstract get ssrTypes(): ISsrType[];

    protected get services(): IServiceFactory {
        return this.booking.services;
    }

    abstract get allowEdit(): boolean;
    abstract get modificationBlockingReason(): NullableString;

    get flightsSsrsPurchases(): IFlightSsrsPurchasesViewModel[] {
        const result: IFlightSsrsPurchasesViewModel[] = [];
        this.booking.filteredJourneys.forEach(j => {
            result.push({
                designator: j.designator,
                ...this._getJourneySsrsPurchases(j)
            });
        });

        return result;
    }

    get hasFlightsSsrsPurchases(): boolean {
        return this.flightsSsrsPurchases.some(
            f => {
                return f.oldPurchases.length > 0 || f.newPurchases.length > 0
            }
        )
    }

    protected _createEmptySsrTypesCountDictionary(): Record<string, ISsrTypeCount> {
        return this.ssrTypes.map(ssrType => {return {ssrType: ssrType, count: 0 };})
                            .toDictionary(ssrTypeCount => ssrTypeCount.ssrType.ssrCode);
    }

    protected _getJourneySsrsPurchases(journey: JourneyModel): {oldPurchases: ISsrTypeCount[]; newPurchases: ISsrTypeCount[]} {
        //For most SSRs is enough to count SSRs on the first segment.
        //There are some exception like Meals.
        return this._getSegmentSsrsPurchases(journey.segments[0]);
    }

    protected _getSegmentSsrsPurchases(segment: SegmentModel): {oldPurchases: ISsrTypeCount[]; newPurchases: ISsrTypeCount[]} {
        const oldPurchases: Record<string, ISsrTypeCount> = {...this._createEmptySsrTypesCountDictionary()};
        const newPurchases: Record<string, ISsrTypeCount> = {...this._createEmptySsrTypesCountDictionary()};
        this.ssrTypes.forEach(ssrType => {
            segment.passengers.forEach(p => {
                if(!oldPurchases[ssrType.ssrCode]) {
                    oldPurchases[ssrType.ssrCode] = {
                        ssrType: ssrType,
                        count: 0
                    }
                }

                if(!newPurchases[ssrType.ssrCode]) {
                    newPurchases[ssrType.ssrCode] = {
                        ssrType: ssrType,
                        count: 0
                    }
                }

                const ssrEditor = p.getSsr(ssrType);

                oldPurchases[ssrType.ssrCode] = {
                    ssrType: ssrType,
                    count: oldPurchases[ssrType.ssrCode].count + ssrEditor.quantityPurchasedOnPreviousSession
                }

                newPurchases[ssrType.ssrCode] = {
                    ssrType: ssrType,
                    count: newPurchases[ssrType.ssrCode].count + ssrEditor.quantityPurchasedOnCurrentSession
                }
            });
        });

        return {
            oldPurchases: Object.values(oldPurchases).filter(p => p.count > 0),
            newPurchases: Object.values(newPurchases).filter(p => p.count > 0)
        };
    }
}
