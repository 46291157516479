import {HttpVerb} from "../../../types/http-verb";
import {IDotRezGraphQLQuery} from "../graph-ql/dot-rez-graph-ql-query.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {IDotRezSessionTimerModel} from "./session-timer/dot-rez-session-timer.interface";
import {isHttpErrorStatusCode} from "../../http/http-helpers";
import {DotRezResponse} from "./dot-rez-response";
import {IDotRezSession, IDotRezSessionRefreshResult} from "./dot-rez-session.interface";
import {DotRezSessionExpiredException, isDotRezSessionExpiredError} from "./dot-rez-exception";

interface IFetchOptions {
    body?: any;
    doNotThrowOnError?: boolean;
    doNotThrowOnSessionExpired?: boolean;
}

export abstract class DotRezSessionBase implements IDotRezSession {
    constructor(public readonly token: string,
                public readonly services: IServiceFactory,
                sessionTimer: IDotRezSessionTimerModel) {
        this._sessionTimer = sessionTimer;
    }

    private _buildUrl(path: string): string {
        return this.services.dotRezApi.buildDotRezUrl(path);
    }

    protected _sessionTimer: IDotRezSessionTimerModel;

    dispose(): void {
        this._sessionTimer.dispose();
    }

    protected async _fetchData<TData>(method: HttpVerb, path: string, options?: IFetchOptions): Promise<DotRezResponse<TData>> {


        const url = this._buildUrl(path);
        const fetchResponse = await fetch(url, {
            method: method,
            body: options?.body && JSON.stringify(options.body),
            headers: this.services.dotRezApi.getDotRezApiRequestHeaders(this.token)
        });

        const json = await fetchResponse.json();
        const dotRezResponse = new DotRezResponse<TData>(fetchResponse.status, json.data, fetchResponse.headers, json.messages, json.errors);

        if(isHttpErrorStatusCode(fetchResponse.status)) {
            const errorMsg = `${method} ${path} failed with status ${fetchResponse.status} - ${fetchResponse.statusText}`;

            if(dotRezResponse.hasDotRezError('nsk:Booking:NoBookingInState')) {
                this.services.logger.info(errorMsg, {
                    errors: json.errors,
                    request: options?.body
                });
            } else {
                this.services.logger.error(errorMsg, {
                    errors: json.errors,
                    request: options?.body
                });
            }
        }

        if(dotRezResponse.isSessionExpired) {
            this._setExpired();
            throw new DotRezSessionExpiredException();
        } else {
            this._sessionTimer.extendSessionLifetime();
        }


        if(!options?.doNotThrowOnError) {
            dotRezResponse.throwIfError();
        }

        return dotRezResponse;
    }

    async tryRefreshSession(): Promise<IDotRezSessionRefreshResult> {
        try {
            await this.services.dotRezApi.tryRefreshToken(this.token);
            return {
                isExpired: false
            }
        } catch (err) {
            if(isDotRezSessionExpiredError(err)) {
                this._setExpired();
                return {
                    isExpired: true
                }
            } else {
                throw err;
            }
        }
    }
    private _setExpired(): void {
        this._sessionTimer.setExpired();
    }

    pauseSessionTimer(): void {
        this._sessionTimer.pause();
    }

    async resumeSessionTimer(): Promise<void> {
        await this._sessionTimer.resume();
    }

    protected async get<TData>(path: string, options?: IFetchOptions): Promise<DotRezResponse<TData>> {
        return await this._fetchData('GET', path, options);
    }

    protected async post<TData>(path: string, options?: IFetchOptions): Promise<DotRezResponse<TData>> {
        return await this._fetchData('POST', path, options);
    }

    protected async put<TData>(path: string, options?: IFetchOptions): Promise<DotRezResponse<TData>> {
        return await this._fetchData('PUT', path, options);
    }

    protected async patch<TData>(path: string, options?: IFetchOptions): Promise<DotRezResponse<TData>> {
        return await this._fetchData('PATCH', path, options);
    }

    protected async delete<TData>(path: string, options?: IFetchOptions): Promise<DotRezResponse<TData>> {
        return await this._fetchData('DELETE', path, options);
    }


    protected async executeGraphQLMutations<TData = unknown>(mutations: IDotRezGraphQLQuery[]): Promise<TData[]> {
        const result: TData[] = [];
        for(let mutation of mutations) {
            const graphQLResponse = await this._graphQL<TData>(mutation);
            result.push(graphQLResponse.data);
        }
        return result;
    }

    async executeGraphQLQuery<TData = any>(query: IDotRezGraphQLQuery): Promise<DotRezResponse<TData>> {
        return await this._graphQL<TData>(query);
    }

    protected async _graphQL<TData>(query: IDotRezGraphQLQuery, options?: Omit<IFetchOptions, 'body'>): Promise<DotRezResponse<TData>> {
        return await this.post<TData>(`/api/v2/graph/${query.queryName}`, {
            body: query.request,
            ...options
        });
    }

}
