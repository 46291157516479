import React from 'react';
import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {ISsrType} from "../../../../services/ssr-types/ssr-types.service.interface";
import {ExtrasSsrsSectionRenderer} from "./extras-ssrs-section.renderer";
import {NullableUndefinedString} from "../../../../types/nullable-types";

export class ExtrasSingleSsrSectionRenderer extends ExtrasSsrsSectionRenderer {
    constructor(ssrType: ISsrType,
                booking: IBookingViewModel) {
        super([ssrType], booking);
    }

    protected get ssrType(): ISsrType {
        return this.ssrTypes[0];
    }


    protected _renderSectionTitle(): string {
        return this.ssrType.name;
    }

    protected _renderLargeScreenIcon(): React.ReactElement | null {
        return this.ssrType.iconInExtras;
    }

    protected _renderSmallScreenIcon(): React.ReactElement | null {
        return this.ssrType.iconInExtras;
    }

    protected _renderSectionDetails(): NullableUndefinedString | React.ReactElement {
        return this.ssrType.details;
    }

}
