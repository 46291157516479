import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const DocumentIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.1} sizeIsHeight {...props} viewBox="0 0 18.094 16.554" ratio={18.094/16.554}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(-3763.951 -1973.172)">
                <path d="M3767.031,1973.172v6.16h-3.08v8.277a2.121,2.121,0,0,0,2.118,2.117h13.859a2.12,2.12,0,0,0,2.117-2.117v-14.437Zm0,13.667h-1.155v-6.352h1.155Zm3.08-10.587h4.235v4.235h-4.235Zm0,6.16h2.695v1.155h-2.695Zm8.854,4.235h-8.854v-1.155h8.854Zm0-3.08h-4.235v-1.155h4.235Zm0-3.08h-2.7v-1.155h2.7Zm0-3.08h-2.7v-1.155h2.7Z" transform="translate(0 0)"/>
            </g>
        </SvgIcon>
    )
}
