import {IBundleViewModel} from "../../models/bundle/bundle-view-model.interface";
import {IBundleUpgradeInfo} from "../../../bundle-types/bundle-types.service.interface";
import {BookingFlowStepBase} from "../common/booking-flow-step.base";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {IOnBeforeDeactivationContext, IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import {DialogResult} from "../../../dialog/dialog-enums";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../../dialog-factory/yes-no-dialog.enums";
import {AvailabilityModel} from "../../../flight-search/models/availability.model";
import {
    FlightSearchControllerModel
} from "../../../flight-search/flight-search-controller/flight-search-controller.model";

export class BookingFlowDepartingFlightSelectionStep extends BookingFlowStepBase {

    get title(): string {
        return this.services.language.translate('Departure');
    }

    get route() {
        return this.routes.departingFlight;
    }

    async validate(): Promise<ValidationResultEnum> {
        const language = this.services.language;
        if(!this.services.booking.availability.hasDepartureFareSelected) {
            this.services.alert.showError(language.translate('You need to select a departure flight'));

            this.services.analytics.flightSelectionEvents.logMandatoryNotice();
            return ValidationResultEnum.Failure;

        }

        if(!this.booking.departureJourney?.selectedBundle) {
            this.services.alert.showError(language.translate('You need to select a bundle'));

            this.services.analytics.flightSelectionEvents.logMandatoryNotice();
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    protected async _onBeforeDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        if(context.routingGuardContext.isBackwardNavigation) {
            const language = this.services.language;
            const dialogResult = await this.services.dialogFactory.showYesNoDialog({
                title: language.translate('Confirmation'),
                message: language.translate('Are you sure you want to give up on this booking?'),
                primaryButton: YesNoDialogPrimaryButton.PrimaryButtonNo,
            });

            if(dialogResult === YesNoDialogResult.No) {
                return DialogResult.Rejected;
            }
        }

        return DialogResult.Accepted
    }

    async next(): Promise<void> {

        if(ValidationResultEnum.Success !== await this.validate()) {
            return;
        }

        await this._waitForSellDepartureJourneyMutation();

        const selectedBundle = this.booking.departureJourney?.selectedBundle;

        if(selectedBundle) {
            const firstSelectedBundle = selectedBundle as IBundleViewModel;
            await this._suggestBundleUpgrade(selectedBundle);
            if(firstSelectedBundle.bundleCode !== selectedBundle.bundleCode) {
                this.services.analytics.flightSelectionEvents.logAddToCartForDeparture(this.booking);
            }
        }
        if(this.booking.flightSearchController.isOneWay)
        {
            this.services.analytics.flightSelectionEvents.logAdsRemarketingSearchResults(this.booking);
        }
    }

    private _goToNextStep(): void {
        if(this.services.booking.availability.isOneWayTrip) {
            this._activateStepByRoute(this.routes.passengersDetails);
        } else {
            this._activateStepByRoute(this.routes.returnFlight);
        }
    }

    private async _waitForSellDepartureJourneyMutation(): Promise<void> {
        const booking = this.booking;
        if(booking.mutationsManager.sellDepartureJourneyMutation.isMutationInProgress) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    await booking.mutationsManager.sellDepartureJourneyMutation.waitForMutation();
                }
            });
        }
    }

    private async _suggestBundleUpgrade(selectedBundle: IBundleViewModel): Promise<void> {
        const departureJourney = this.booking.departureJourney;
        if(!departureJourney) {
            return;
        }
        const upgradeInfo = this.services.bundleTypes.getBundleUpgradeInfo(selectedBundle.bundleType, departureJourney.bundlesAvailability.map((b => b.bundleType)));

        if(!upgradeInfo) {
            this._goToNextStep();
            return;
        }

        const availableBundles = departureJourney.bundlesAvailability;
        const upgradeToBundle = availableBundles.find(bundle => bundle.bundleType.code === upgradeInfo.upgradeTo.code);

        if(upgradeToBundle) {
            await this._showBundleUpgradeSuggestionDialog(selectedBundle, upgradeToBundle, upgradeInfo);
        } else {
            await this._goToNextStep();
        }
    }

    private async _showBundleUpgradeSuggestionDialog(fromBundle: IBundleViewModel,
                                        toBundle: IBundleViewModel,
                                        upgradeInfo: IBundleUpgradeInfo): Promise<void> {

        this.services.analytics.flightSelectionEvents.logViewPromotion(this.booking, fromBundle, toBundle);

        await this.services.dialogFactory.showBundleUpgradeSuggestion({
            upgradeFrom: fromBundle,
            upgradeTo: toBundle,
            benefits: upgradeInfo.benefits,
            onAccept: async (selectedBundle) => {
                this._goToNextStep();
                if (selectedBundle && fromBundle?.bundleCode !== selectedBundle?.bundleCode) {
                    await this.booking.sellDepartureJourneyBundle(selectedBundle.bundleCode);
                    setTimeout(()=>{
                        this.services.analytics.flightSelectionEvents.logSelectPromotion(this.booking, fromBundle, toBundle);
                    })
                }
            }
        });
    }

    protected async _onActivated(previousStep: IWizardStep | null): Promise<void> {

        this.services.analytics.flightSelectionEvents.logFlightSearchEvent(this.booking.flightSearchController as FlightSearchControllerModel);
        this.services.analytics.flightSelectionEvents.logAdsRemarketingHome(this.booking.flightSearchController as FlightSearchControllerModel)

        await super._onActivated(previousStep);
        
        this.services.analytics.flightSelectionEvents.logViewItemListForDeparture(this.services.booking.availability as AvailabilityModel);
    }

}
