import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {ISupportsSameOptionsForAllFlightsToggle} from "../../../services/booking/models/ssrs/supports-same-options-for-all-flights-toggle.interface";
import {SameForAllComponent} from "./same-for-all.component";


interface SameForAllFlightToggleComponentProps {
    ssrsBucket: ISupportsSameOptionsForAllFlightsToggle;
    className?: string;
}

export const SameForAllFlightsToggleComponent: React.FC<SameForAllFlightToggleComponentProps> = observer((props) => {
    const services = useServices();

    const onChange = async (checked: boolean) => {
        if(checked === props.ssrsBucket.useSameOptionsForAllFlights) {
            return;
        }

        props.ssrsBucket.useSameOptionsForAllFlights = checked;
    }

    return (
        <SameForAllComponent label={services.language.translate('Same for all flights')}
                             checked={() => Boolean(props.ssrsBucket.useSameOptionsForAllFlights)}
                             onChanged={checked => onChange(checked)}
                             className={props.className}/>
    );
});

