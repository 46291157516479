import {FormFields, IFormField} from "./form-field.interface";
import {IFieldOptions} from "./form-field-options.interface";
import {IServiceFactory} from "../../services/service-factory.interface";
import {IActivateErrorsValidationOptions, IFormModel} from "./form-model.interface";
import {FormFieldModel} from "./form-field.model";
import {EMailValidator} from "./field-validators/e-mail.validator";
import {PersonNameValidator} from "./field-validators/person-name.validator";
import {IFieldValidator} from "./field-validator.interface";
import {PasswordValidator} from "./field-validators/password.validator";
import {Gender} from "../../services/dot-rez-api/data-contracts/enums/gender.enum";
import {PhoneValidator} from "./field-validators/phone.validator";
import {computed, makeObservable} from "mobx";

export interface ConcreteFieldOptions<TValue> extends Partial<IFieldOptions<TValue>> {
    moreValidators?: IFieldValidator[]
}

export abstract class FormModel<TFields> implements IFormModel<TFields> {

    constructor(public readonly services: IServiceFactory) {
        makeObservable<this, '_fieldsList'>(this, {
            _fieldsList: computed
        });
    }

    protected abstract _createFields(): FormFields<TFields>;

    private _fields: FormFields<TFields> | null = null;
    public get fields(): FormFields<TFields> {
        if(!this._fields) {
            this._fields = this._createFields();
            this._onFieldsCreated(this._fields);
            Object.keys(this._fields).forEach(fieldName => {
                const formField = (this._fields as any)[fieldName] as FormFieldModel;
                formField.onChange(() => this._onFieldChanged(formField));
            })
        }
        return this._fields;
    }

    protected _onFieldsCreated(fields: FormFields<TFields>): void {

    }

    protected _onFieldChanged(field: IFormField): void {
        this._fieldsList.forEach(f => f.refreshValidationError());
    }

    private get _fieldsList(): FormFieldModel[]{
        return Object.keys(this.fields).map(fieldName => (this.fields as any)[fieldName] as FormFieldModel);
    }

    /**
     * returns the list of fields that have validation errors
     */
    activateErrorsValidation(options?: IActivateErrorsValidationOptions): IFormField[] {
        options = {
            setFocusOnFirstError: true,
            ...options
        }
        const fieldsWithErrors: IFormField[] = [];
        this._fieldsList.forEach(f => {
            f.activateErrorsValidation();
            if(f.hasError) {
                fieldsWithErrors.push(f);
            }
        });

        if(options.setFocusOnFirstError) {
            if(fieldsWithErrors.length > 0) {
                fieldsWithErrors[0].setFocus();
            }
        }

        return fieldsWithErrors;
    }

    resetErrorsValidation(): void {
        this._fieldsList.forEach(f => f.resetErrorsValidation())
    }

    hasErrors(): boolean {
        return this._fieldsList.some(f => f.getValidationError());
    }

    hasChanges(): boolean {
        return this._fieldsList.some(f => f.hasChanges);
    }

    protected _createField<TFieldType>(fieldOptions: IFieldOptions<TFieldType>): IFormField<TFieldType> {
        return new FormFieldModel<TFieldType>(fieldOptions, this.services);
    }

    protected _createFirstNameField(fieldOptions?: ConcreteFieldOptions<string>): IFormField<string> {
        return this._createField<string>({
            fieldName: () => this.services.language.translate('First name'),
            autoCapitalize: true,
            maxLength: 32,
            validators: [
                new PersonNameValidator(this.services.language.translate('Not a valid first name')),
                ...(fieldOptions?.moreValidators || [])
            ],
            ...fieldOptions
        })
    }

    protected _createLastNameField(fieldOptions?: ConcreteFieldOptions<string>): IFormField<string> {
        return this._createField<string>({
            fieldName: () => this.services.language.translate('Last name'),
            autoCapitalize: true,
            maxLength: 32,
            validators: [
                new PersonNameValidator(this.services.language.translate('Not a valid last name')),
                ...(fieldOptions?.moreValidators || [])
            ],
            ...fieldOptions
        })
    }

    protected _createGenderField(fieldOptions?: Partial<IFieldOptions<Gender>>): IFormField<Gender> {
        return this._createField<Gender>({
            fieldName: () => this.services.language.translate('Gender'),
            ...fieldOptions
        })
    }

    protected _createEmailField(fieldOptions?: ConcreteFieldOptions<string>): IFormField<string> {
        return this._createField<string>({
            fieldName: () => 'E-mail',
            maxLength: 64,
            validators: [
                new EMailValidator(this.services),
                ...(fieldOptions?.moreValidators || [])
            ],
            ...fieldOptions
        });
    }

    protected _createPasswordField(fieldOptions?: ConcreteFieldOptions<string>): IFormField<string> {
        return this._createField<string>({
            fieldName: () => this.services.language.translate('Password'),
            maxLength: 16,
            validators: [
                new PasswordValidator(this.services),
                ...(fieldOptions?.moreValidators ||[])
            ],
            ...fieldOptions
        })
    }

    protected _createPhoneField(fieldOptions?: ConcreteFieldOptions<string>): IFormField<string> {
        return this._createField<string>({
            fieldName: () => this.services.language.translate('Phone'),
            maxLength: 20,
            validators: [
                new PhoneValidator(this.services),
                ...(fieldOptions?.moreValidators || [])
            ],
            ...fieldOptions
        });
    }


    commitChanges(): void {
        if(this.hasErrors()) {
            throw new Error('There are validation errors');
        }

        this._fieldsList.forEach(f => f.commitChanges());
    }

    cancelChanges(): void {
        this._fieldsList.forEach(f => f.cancelChanges());
    }

    hasChangesOnCurrentSession(): boolean {
        return this._fieldsList.some(f => f.hasChangesOnCurrentSession());
    }
}
