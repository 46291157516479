import styled from "styled-components";

export const SeatsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding: 2px 0;
  align-items: flex-end;
`
