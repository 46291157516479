import { ActionSheetButton, IonActionSheet} from "@ionic/react";
import { observer } from "mobx-react";
import React, { useState } from "react";
import styled from "styled-components";
import { ArrowDownIcon } from "../../../../assets/svg/arrow-down.icon";
import { ExclamationCircle } from "../../../../assets/svg/exclamation-circle.icon";
import { PlaneDepartNoLineIcon } from "../../../../assets/svg/plane-depart-no-line.icon";
import { PlaneTicketsIcon } from "../../../../assets/svg/plane-tickets.icon";
import { CardBox } from "../../../../basic-components/card/card.box";
import { useServices } from "../../../../hooks/use-services.hook";
import {
    IJourneyHistoryViewModel
} from "../../../../services/booking-history/models/journey-history-view-model.interface";

import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";
import {VerticalSpacer} from "../../../../basic-components/spacing/vertical-spacer";

const MyBookingFlightComponentBox = styled(CardBox)`
    border: none;
    color: ${props => props.theme.colors.secondaryContrast};
    background-color: ${props => props.theme.colors.secondary};
    line-height: 1;
    margin: ${props => props.theme.spacing.extraLarge} 0;
`

const StationsBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing.large};

`

const FlightDateBox = styled.div`
    
`

const FlightTimeBox = styled.div`
    margin-left: 6px;
    display: flex;
    align-items: center;
    &:before {
        content: '';
        position: relative;
        width: 1px;
        height: calc(100% - 6px);
        background-color: ${props => props.theme.colors.secondaryContrast};
        margin-right: 6px;
    }
`

const FlightDateTimeBox = styled.div`
    display: flex;
    font-size: ${props => props.theme.fontSize.large};
    margin-bottom: ${props => props.theme.spacing.large};
`

const RecordLocatorBox = styled.div`
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    svg {
        margin-right: ${props => props.theme.spacing.medium};
    }
`

const MyBookingFlightButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const FlightDetailsButton = styled(PrimaryButtonInverted)`
    text-transform: capitalize;
    svg {
        margin-left: ${props => props.theme.spacing.medium};
    }
`

const MyBookingFlightFooterBox = styled.div`
    display: flex;
    justify-content: space-between;
`

const NoFLightsMessageBox = styled.div`
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colors.medium};
    padding: ${props => props.theme.spacing.large};
    background-color: ${props => props.theme.colors.light};
    margin: ${props => props.theme.spacing.large} 0;
    border-radius: ${props => props.theme.border.defaultRadius};
    line-height: 1;

    svg {
        margin-right: ${props => props.theme.spacing.medium};
    }
`

const FlightStatusBox = styled.div`
    display: flex;
    flex-direction: column;
`

const FlightCanceledBox = styled.div`
    color: ${props => props.theme.colors.danger};
    font-weight: bold;
    font-size: ${props => props.theme.fontSize.medium};
`

const PlaneIconBox = styled.div`
    padding: 0 ${props => props.theme.spacing.small};
`

interface MyTripFlightComponentProps {
    flight: IJourneyHistoryViewModel;
}

const MyTripFlightComponent: React.FC<MyTripFlightComponentProps> = observer((props) => {
    const services = useServices();
    const [actions, setActions] = useState<ActionSheetButton[]>([]);
    const originStation = props.flight.designator.origin;
    const destinationStation = props.flight.designator.destination;

    const onDetailsButtonClick = async () => {
        const actions = await props.flight.getActions();
        const actionSheetButtons = actions.map(action => {
            const button: ActionSheetButton = {
                text: action.text,
                handler: action.execute
            }
            return button;
        });

        actionSheetButtons.push({
            text: services.language.translate('Cancel'),
            role: "cancel"
        });

        setActions(actionSheetButtons);
    }

    return (
        <MyBookingFlightComponentBox>
            <StationsBox>
                {originStation.name}
                <PlaneIconBox>
                    <PlaneDepartNoLineIcon size={0.8}/>
                </PlaneIconBox>

                {destinationStation.name}
            </StationsBox>
            <FlightDateTimeBox>
                <FlightDateBox>
                    {services.time.formatUserFriendlyDate(props.flight.designator.departureDate)}
                </FlightDateBox>

                <FlightTimeBox>
                    {services.time.formatHHmm(props.flight.designator.departureDate)}
                </FlightTimeBox>
            </FlightDateTimeBox>
            
            <MyBookingFlightFooterBox>
                <FlightStatusBox>
                    <RecordLocatorBox>
                        <PlaneTicketsIcon size={1.4}/>
                        {props.flight.recordLocator}
                    </RecordLocatorBox>
                    <VerticalSpacer/>
                    {props.flight.isCanceled && <FlightCanceledBox>{services.language.translate('Flight Canceled')}</FlightCanceledBox>}
                </FlightStatusBox>

                <MyBookingFlightButtonsContainer>
                    <FlightDetailsButton onClick={onDetailsButtonClick} fontSize={"small"}>
                        {services.language.translate('More details')}
                        <ArrowDownIcon size={0.4}/>
                    </FlightDetailsButton>
                    <IonActionSheet isOpen={actions.length > 0}
                                    mode="ios"
                                    onDidDismiss={() => setActions([])}
                                    buttons={actions}>

                    </IonActionSheet>
                </MyBookingFlightButtonsContainer>
            </MyBookingFlightFooterBox>
            
            

        </MyBookingFlightComponentBox>
    )
});

interface TripsSectionComponentProps {
    noFlightsMessage?: string;
    flights: IJourneyHistoryViewModel[];
}

export const TripsSectionComponent: React.FC<TripsSectionComponentProps> = observer((props) => {

    if(props.flights.length === 0) {
        return (
            <NoFLightsMessageBox><ExclamationCircle size={1.8}/> {props.noFlightsMessage}</NoFLightsMessageBox>
        );
    }
    return (
        <>
            {props.flights.map(myFlight => <MyTripFlightComponent key={myFlight.uniqueKey} flight={myFlight}/>)}
        </>
    );
});
