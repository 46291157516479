import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {IPassengerSegmentViewModel} from "../../../../services/booking/models/passenger-segment/passenger-segment-view-model.interface";
import {
    DesignatorComponent,
    DesignatorDisplayMode
} from "../../../../components/designator/designator.component";
import { PriceComponent } from "../../../../components/price/price.component";
import {ISegmentSeatMapEditorViewModel} from "../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {VerticalSpacer} from "../../../../basic-components/spacing/vertical-spacer";
import {IServiceFactory} from "../../../../services/service-factory.interface";

const SeatMapHeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    line-height: 1;
    
`

const SelectedSeatBox = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
`

const PassengerNameBox = styled.div`
    
`

const SeatNotSelectedBox = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.medium};
`


const PassengersContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    flex-wrap: wrap;
    width: 100%;
    align-self: flex-end;
`

const PassengerBox = styled.div<{isSelected: boolean; width: string;}>`
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    align-items: center;
    justify-content: space-between;
    font-weight: ${props => props.theme.fontWeight.bold};
    border-radius: ${props => props.theme.border.defaultRadius} ${props => props.theme.border.defaultRadius} 0 0;
    cursor: pointer;
    margin: ${props => props.theme.spacing.small};
    font-size: ${props => props.theme.fontSize.medium};
    border: 1px solid ${props => props.theme.colors.lightShade};
    padding: ${props => props.theme.spacing.small};
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      font-size: ${props => props.theme.fontSize.small};
    }

    ${
        props => props.isSelected 
            ? css`
                  
                  background-color: ${props => props.theme.colors.primary};
                  color: ${props => props.theme.colors.primaryContrast};
                `
            : ``
    }
    
`

const PassengerSeatDetailsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
`

const SeatPriceSeparatorBox = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0 ${props => props.theme.spacing.medium};
  
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      margin: 0 ${props => props.theme.spacing.small};
    }
`

export function composePassengerSegmentSeatMapHeaderDOMElementId(passengerSegment: IPassengerSegmentViewModel): string {
    return `PassengerSegmentSeatMapHeader_${passengerSegment.passengerSegmentUniqueKey}`;
}

const SegmentDesignatorComponent = styled(DesignatorComponent)`
  padding: ${props => props.theme.spacing.small};
  flex-grow: 1;
  justify-content: center;
`

function computePassengerBoxWidth(services: IServiceFactory, numberOfPassengers: number): string {
    const mediaQuery = services.mediaQuery;
    const theme = services.theme.currentTheme;

    const spaceBetween = `${2 * theme.spacing.space()}px`;

    if(numberOfPassengers <= 4) {
        if(numberOfPassengers >= 3 && mediaQuery.xs) {
            return "45%";
        }

        if(numberOfPassengers === 4 && !mediaQuery.sAndBelow && mediaQuery.mAndBelow) {
            return "30%";
        }

        return `calc(${Math.round(100 / numberOfPassengers) + "%"} - ${spaceBetween})`;
    }

    if(mediaQuery.xs) {
        return "45%";
    } else if(mediaQuery.sAndBelow) {
        return `calc(25% - ${spaceBetween})`;
    } else if(mediaQuery.mAndBelow) {
        return "30%";
    }

    return "90px";
}

const PassengerSegmentComponent: React.FC<{passengerSegment: IPassengerSegmentViewModel; numberOfPassengers: number}> = observer((props) => {
    const services = useServices();
    const seatsMapsEditors = services.booking.current.seatsMapsEditors;
    const isSelected = props.passengerSegment.passengerSegmentUniqueKey === seatsMapsEditors.currentSeatMap?.currentPassengerSegment.passengerSegmentUniqueKey;


    const renderSeat = () => {
        if(props.passengerSegment.assignedSeat) {
            return (<PassengerSeatDetailsBox>
                <SelectedSeatBox>{props.passengerSegment.assignedSeat.seatNumber}</SelectedSeatBox>
                <SeatPriceSeparatorBox>|</SeatPriceSeparatorBox>
                <PriceComponent price={props.passengerSegment.assignedSeat.price}/>
            </PassengerSeatDetailsBox>)
        }
        return (<SeatNotSelectedBox>N/A</SeatNotSelectedBox>);
    }

    const passengerName = props.passengerSegment.passengerFirstName.split(' ')[0];

    return (
        <PassengerBox id={composePassengerSegmentSeatMapHeaderDOMElementId(props.passengerSegment)}
                      width={computePassengerBoxWidth(services, props.numberOfPassengers)}
                      isSelected={isSelected}
                      onClick={() => seatsMapsEditors.setCurrentPassengerSegment(props.passengerSegment)}>
            <PassengerNameBox>
                {passengerName}
            </PassengerNameBox>
            {renderSeat()}
        </PassengerBox>
    );
});


interface SeatMapHeaderComponentProps {
    seatMap: ISegmentSeatMapEditorViewModel;
}

export const SeatMapHeaderComponent: React.FC<SeatMapHeaderComponentProps> = observer((props) => {
    const passengers = props.seatMap.segment.passengers;

    //passengers = passengers.filter((p, index) => index < 7);

    const renderOnePassenger = (passenger: IPassengerSegmentViewModel) => {
        return (
            <PassengerSegmentComponent key={passenger.passengerSegmentUniqueKey}  passengerSegment={passenger} numberOfPassengers={passengers.length}/>
        );
    }

    return (
        <SeatMapHeaderBox>
            <SegmentDesignatorComponent displayMode={DesignatorDisplayMode.stationsNamesAndDateAndFlightStops}
                                        designator={props.seatMap.segment.designator}/>
            <VerticalSpacer size={"small"}/>
            <PassengersContainerBox>
                {passengers.map(renderOnePassenger)}
            </PassengersContainerBox>
            <VerticalSpacer size={"medium"}/>
        </SeatMapHeaderBox>
    )
});
