import { IPassengerSegmentBoardingPassViewModel } from "../../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {IMobileWalletStrategy} from "../mobile-wallet.strategy.interface";
import React from "react";

export class NoMobileWalletStrategy implements IMobileWalletStrategy {

    readonly providerName: string = '';

    async addBoardingPassesToWallet(bookingKey: string, boardingPasses: IPassengerSegmentBoardingPassViewModel[]): Promise<void> {

    }
    canAddBoardingPassToAppleWallet(boardingPass: IPassengerSegmentBoardingPassViewModel): boolean {
        return false;
    }

    renderAddToWalletButton(onClickHandler: () => Promise<void>): React.ReactElement {
        return (
            <></>
        );
    }

}