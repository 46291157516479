//dotREZ token encryption key
import {NullableString} from "../../types/nullable-types";

export const ek = ['=', 'U', 'x', 'J', '/', 'B', 'N', 'R', 'W', 'H', 'M', 'o', 'W', 'E', 'V', 'V', 'O', 'q', '4', 'M', '2', 'i', 'l', 'L', 'w', 'P', '8', 'C', 'I', '/', 'A', 'm', 'G', 'I', 'q', 'D', 'F', 'L', 'I', 'i', 'Q', 'j', '4', 'S'];

export class ClientEkFactory {
    private static _ek: NullableString = null;

    public static getEk(): string {
        if(!ClientEkFactory._ek) {
            ClientEkFactory._ek = ek.reverse().join('');
        }

        return ClientEkFactory._ek;
    }
}

