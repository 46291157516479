import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const BaggageIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.6} {...props} viewBox="0 0 13.726 21.886">
              <g fill={props.color? props.color: "currentColor"} transform="translate(-430.5 -399.38)">
                <path d="M442.2,402.209h-1.337v-.89a1.941,1.941,0,0,0-1.939-1.939H435.8a1.941,1.941,0,0,0-1.939,1.939v.89h-1.338a2.028,2.028,0,0,0-2.026,2.026v12.851a2.028,2.028,0,0,0,2.026,2.026H442.2a2.028,2.028,0,0,0,2.026-2.026V404.236A2.028,2.028,0,0,0,442.2,402.209Zm-6.657-.89a.263.263,0,0,1,.259-.259h3.123a.263.263,0,0,1,.259.259v.89h-3.642Zm7,15.769a.351.351,0,0,1-.346.346h-9.676a.351.351,0,0,1-.346-.346V404.238a.351.351,0,0,1,.346-.348H442.2a.351.351,0,0,1,.346.346Z" />
                <path d="M456.528,603.79h-.558a.84.84,0,0,0,0,1.68h.558a.84.84,0,0,0,0-1.68Z" transform="translate(-22.195 -184.204)" />
                <path d="M523.528,603.79h-.557a.84.84,0,0,0,0,1.68h.557a.84.84,0,1,0,0-1.68Z" transform="translate(-82.572 -184.204)" />
                <path d="M469.4,474.36a.4.4,0,0,0-.4.4V481.7a.4.4,0,0,0,.791,0v-6.945A.4.4,0,0,0,469.4,474.36Z" transform="translate(-34.694 -67.568)" />
                <path d="M523.255,474.36a.4.4,0,0,0-.4.4V481.7a.4.4,0,0,0,.791,0v-6.945A.4.4,0,0,0,523.255,474.36Z" transform="translate(-83.23 -67.568)" />
            </g>
        </SvgIcon>
    )
}
