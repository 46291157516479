import {IPassengerSsrEditorViewModel} from "../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import React from "react";
import {observer} from "mobx-react";
import {PassengerSsrQuantityEditorComponent} from "./passenger-ssr-quantity-editor.component";
import styled from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";
import {IPassengerCustomSsrEditorRenderer, PassengerSsrsRenderingFlexDirection} from "./ssrs-selection.component.props";

const PassengerSsrsBox = styled(CardBox)`
    padding: 0;
    width: 100%;
`

const PassengerSsrsHeaderBox = styled.div `
    background-color: ${props => props.theme.colors.lightShade};
    color: ${props => props.theme.colors.primaryShade};
    padding: ${props => props.theme.spacing.medium};
    border-radius: ${props => props.theme.border.defaultRadius} ${props => props.theme.border.defaultRadius} 0 0; 
    width: 100%;
`

const PassengerSsrsContentBox = styled.div<{flexDirection: PassengerSsrsRenderingFlexDirection}>`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  width: 100%;
  padding: ${props => props.theme.spacing.medium};
  gap: ${props => props.theme.spacing.large};
  
`

const PassengerNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-transform: uppercase;
`

interface PassengerSsrsSelectionComponentProps {
    passengerSsrsEditors: IPassengerSsrEditorViewModel[];
    passengerCustomSsrEditorRenderer?: IPassengerCustomSsrEditorRenderer;
}

export const PassengerSsrsSelectionComponent: React.FC<PassengerSsrsSelectionComponentProps> = observer((props) => {
    const passengerAllSsrsEditors = props.passengerSsrsEditors.filter(p => p.shouldBeDisplayedInSsrSelection);
    const passengerName = props.passengerSsrsEditors[0].passengerFullName;

    const renderPassengerSsrEditor = (passengerSsrEditor: IPassengerSsrEditorViewModel) => {

        if(props.passengerCustomSsrEditorRenderer) {
            return props.passengerCustomSsrEditorRenderer.render(passengerSsrEditor);
        }

        return (
            <PassengerSsrQuantityEditorComponent passengerSsrEditor={passengerSsrEditor}/>
        )
    }

    return (
        <PassengerSsrsBox>
            <PassengerSsrsHeaderBox>
                <PassengerNameBox>{passengerName}</PassengerNameBox>
            </PassengerSsrsHeaderBox>
            <PassengerSsrsContentBox flexDirection={props.passengerCustomSsrEditorRenderer?.flexDirection ?? PassengerSsrsRenderingFlexDirection.Column}>
                {
                    passengerAllSsrsEditors.map((passengerSsrEditor) => {
                        return (
                            <React.Fragment key={passengerSsrEditor.ssrType.ssrCode + passengerSsrEditor.passengerKey}>
                                {renderPassengerSsrEditor(passengerSsrEditor)}
                            </React.Fragment>
                        )
                    })
                }
            </PassengerSsrsContentBox>

        </PassengerSsrsBox>
    );
});

