import React from "react";
import {observer} from "mobx-react";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {BookingSearchComponent} from "../home/components/booking-search/booking-search.component";
import {useServices} from "../../hooks/use-services.hook";
import {StandardPageHeaderComponent} from "../../basic-components/page/standard-page-header/standard-page-header.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";

export const RetrieveBookingHybridPage: React.FC = observer(() => {
    const services = useServices();
    return (
        <RoutedPageComponent>
            <StandardPageHeaderComponent title={services.language.translate('Retrieve booking')} hideLogoButton={true}/>
            <StandardPageContentComponent>
                <BookingSearchComponent retrieveBookingButtonText={services.language.translate('Retrieve my booking')}
                                        onRetrieveBookingButtonClick={async (searchParams) => {
                                            const result = await searchParams.retrieveBookingToHistory();
                                            if(result.success) {
                                                services.navigator.goBack();
                                            }
                                        }}/>
            </StandardPageContentComponent>
        </RoutedPageComponent>
    )
});
