import React, {useRef, useState} from "react";
import {IPassengerViewModel} from "../../../../../services/booking/models/passenger/passenger-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {IDotRezUpdateSsrsRequest} from "../../../../../services/dot-rez-api/data-contracts/requests/booking/update-ssrs.request";
import {IonIcon} from "@ionic/react";
import {WheelchairIcon} from "../../../../../assets/svg/wheelchair.icon";
import styled from "styled-components";
import {ISsrType} from "../../../../../services/ssr-types/ssr-types.service.interface";
import {checkmark, checkmarkOutline, chevronForwardOutline, chevronDownOutline, chevronUpOutline} from "ionicons/icons";
import {DialogCloseButtonBehavior} from "../../../../../services/dialog/dialog-enums";
import {SsrsSelectionDialogComponent} from "../../../common-components/ssrs/ssrs-selection-dialog.component";
import {SsrsSelectionPresentationMode} from "../../../common-components/ssrs/ssrs-selection.component.props";
import {SsrsSelectionComponent} from "../../../common-components/ssrs/ssrs-selection.component";
import {
    scrollElementIntoViewLazySmooth
} from "../../../../../utils/scroll-element-into-view";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.large};
`

const SelectedSsrsGridBox = styled.div`
    display: grid;
    grid-template-columns: 24px auto;
    width: 100%;
    cursor: pointer;
    gap: 2px;
    line-height: 1;
`

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    background-color: ${props => props.theme.colors.lightShade};   
    padding: ${props => props.theme.spacing.large};
    border-radius: ${props => props.theme.border.defaultRadius} ${props => props.theme.border.defaultRadius} 0 0;
`


const IconBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 24px;
`

const SpecialAssistanceSsrsSelectionContainerBox = styled.div`
    
`

const ChevronBox = styled(IconBox)`
    font-size: 20px;
`

const DescriptionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
`

const RequestSpecialAssistanceSsrsBox = styled(DescriptionBox)`
  font-weight: ${props => props.theme.fontWeight.bold};
`

const SelectedSsrComponent: React.FC<{ssrType: ISsrType}> = observer((props) => {
    return (
        <>
            <IconBox>
                <IonIcon ios={checkmarkOutline} md={checkmark}/>
            </IconBox>
            <DescriptionBox>
                {props.ssrType.name}
            </DescriptionBox>
        </>

    );
});




export const PassengerSelectedSpecialAssistanceSsrsComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;
    const [isExpanded, setIsExpanded] = useState(false);
    const ssrsSelectionContainerRef = useRef<HTMLDivElement | null>(null);
    const [aggregator] = useState(booking.createSsrsAggregator({
        ssrTypes: services.ssrTypes.getSpecialAssistanceSsrTypes(),
        onlyForPassengerKeys: [props.passenger.passengerKey]
    }));

    if(!props.passenger.canHaveSpecialAssistance) {
        return null;
    }


    const openSpecialAssistanceSelector = async () => {

        if(services.layout.isInSmallScreenView) {
            if(props.passenger.fields)
                await services.dialog.showStandardDialog<IDotRezUpdateSsrsRequest>({
                    closeButtonBehavior: DialogCloseButtonBehavior.Accept,
                    render: modalHandler => (<SsrsSelectionDialogComponent dialogHandler={modalHandler}
                                                                           presentationMode={SsrsSelectionPresentationMode.UseSlides}
                                                                           title={services.language.translate('Request Special Assistance')}
                                                                           ssrsAggregator={aggregator}/>),
                    onAccept: async () => {
                        await services.booking.current.sellSsrs();
                    }
                });
        } else {
            setIsExpanded(!isExpanded);
            scrollElementIntoViewLazySmooth(ssrsSelectionContainerRef.current, {
                block: "center"
            });
        }
    }

    const renderCollapsedSsrs = () => {
        if(isExpanded) {
            return null;
        }
        const selectedSsrs = aggregator.countAllSsrsPerBooking;
        if(selectedSsrs.length === 0) {
            return null;
        }
        return (
            <SelectedSsrsGridBox onClick={openSpecialAssistanceSelector}>
                {selectedSsrs.map(ssrTypeCount => <SelectedSsrComponent key={ssrTypeCount.ssrType.ssrCode} ssrType={ssrTypeCount.ssrType}/>)}
            </SelectedSsrsGridBox>
        );
    }

    const renderExpandedSsrs = () => {
        if(isExpanded) {
            return (
                <SsrsSelectionComponent ssrsAggregator={aggregator} presentationMode={SsrsSelectionPresentationMode.UseSlides} />
            );
        }

        return null;
    }

    const renderChevron = () => {
        if(services.layout.isInSmallScreenView) {
            return (<IonIcon icon={chevronForwardOutline} />);
        } else {
            if(isExpanded) {
                return (<IonIcon icon={chevronUpOutline} />);
            } else {
                return (<IonIcon icon={chevronDownOutline} />);
            }
        }
    }

    return (
        <ContainerBox>
            <HeaderBox onClick={openSpecialAssistanceSelector}>
                <IconBox>
                    <WheelchairIcon size={2}/>
                </IconBox>
                <RequestSpecialAssistanceSsrsBox>
                    {services.language.translate('Request Special Assistance')}
                </RequestSpecialAssistanceSsrsBox>
                <ChevronBox>
                    {renderChevron()}
                </ChevronBox>
            </HeaderBox>
            {renderCollapsedSsrs()}
            <SpecialAssistanceSsrsSelectionContainerBox ref={ssrsSelectionContainerRef}>
                {renderExpandedSsrs()}
            </SpecialAssistanceSsrsSelectionContainerBox>

        </ContainerBox>
    );
});
