import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import styled from "styled-components";
import {ContinueButtonComponent} from "../continue-button/continue-button.component";

const ForgotPasswordInstructionDialogTextBox = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1.4;
    margin: 5px 0 20px;
`

export const ForgotPasswordInstructionDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Forgot password?')}
                                           dialogHandler={props.dialogHandler}/>

            <StandardPageContentComponent>
                <ForgotPasswordInstructionDialogTextBox>
                    {services.language.translate('Please follow the instructions that were sent to the provided email address in order to reset your password')}
                </ForgotPasswordInstructionDialogTextBox>
            </StandardPageContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ContinueButtonComponent onClick={() => props.dialogHandler.accept()}/>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
});
