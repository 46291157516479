import {NullableDate, NullableString} from "../../../../types/nullable-types";
import {IDotRezContact} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IBookingContactViewModel} from "./booking-contact-view-model.interface";
import {IBookingContactFields} from "./booking-contact-fields.interface";
import {FormModel} from "../../../../models/forms/form.model";
import {BookingModel} from "../booking.model";
import {MaturePassengerModel} from "../passenger/mature-passenger.model";
import {IReactionDisposer, reaction, runInAction} from "mobx";
import {IPersistedBookingContact} from "./persisted-booking-contact.interface";
import {EMailValidator} from "../../../../models/forms/field-validators/e-mail.validator";
import {PersonNameValidator} from "../../../../models/forms/field-validators/person-name.validator";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {PhoneTypeEnum} from "../../../dot-rez-api/data-contracts/enums/phone-type.enum";
import {DistributionOptionsEnum} from "../../../dot-rez-api/data-contracts/enums/distribution-options.enum";
import {NotificationPreferenceEnum} from "../../../dot-rez-api/data-contracts/enums/notification-preference.enum";


export const PRIMARY_CONTACT_KEY = 'P';

export class BookingContactModel extends FormModel<IBookingContactFields> implements IBookingContactViewModel {
    constructor(private readonly booking: BookingModel) {
        super(booking.services);

        if(this.booking.shouldSyncContactWithPrimaryPassenger) {
            this._reactions.push(reaction(() => [...booking.passengers.map(p => p.isPrimaryContact)],
                () => {
                    this._subscribeToPrimaryPassengerChanges();
                    this._syncNameWithPrimaryPassenger();
                },
                {
                    fireImmediately: true
                }));
        }
    }

    private _reactions: IReactionDisposer[] = [];

    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }

    private _getPersistedBookingContact(): IPersistedBookingContact | null | undefined  {
        if(this.services.user.isAuthorized || !this.booking.isNewBooking) {
            return null;
        }

        return this.booking.services.localStorage.getJson<IPersistedBookingContact>('userProfile.bookingContact');
    }

    protected _createFields(): FormFields<IBookingContactFields> {
        const persistedContact = this._getPersistedBookingContact();
        const language = this.services.language;

        return {
            firstName: this._createField<NullableString>({
                fieldName: () => language.translate('First name'),
                initialValue: () => this.data.name?.first,
                defaultValue: persistedContact?.firstName,
                autoCapitalize: true,
                maxLength: 32,
                validators: [
                    new PersonNameValidator(language.translate('Not a valid first name'))
                ]
            }),
            lastName: this._createField<NullableString>({
                fieldName: () => language.translate('Last name'),
                initialValue: () => this.data.name?.last,
                defaultValue: persistedContact?.lastName,
                autoCapitalize: true,
                maxLength: 32,
                validators: [
                    new PersonNameValidator(language.translate('Not a valid last name'))
                ]
            }),
            emailAddress: this._createField<NullableString>({
                fieldName: () => language.translate('E-mail'),
                initialValue: () => this.data.emailAddress,
                defaultValue: persistedContact?.emailAddress,
                maxLength: 250,
                validators: [
                    new EMailValidator(this.services)
                ]
            }),
            countryCode: this._createField<NullableString>({
                fieldName: () => language.translate('Country of residence'),
                initialValue: () => this.data.address?.countryCode,
                defaultValue: persistedContact?.countryCode,
                maxLength: 2
            }),
            phoneNumber: this._createPhoneField({
                initialValue: () =>  this.data.phoneNumbers && this.data.phoneNumbers[0]?.number,
                defaultValue: persistedContact?.phoneNumber
            }),
            customerNumber: this._createField<string>({
                fieldName: () => language.translate('Customer number'),
                isRequired: false,
                isHidden: () => true,
                defaultValue: this.services.user.profile.customerNumber
            }),
            dateOfBirth: this._createField<NullableDate>({
                fieldName: () => language.translate('Date of birth'),
                defaultValue: this.services.time.tryConvertToDate(persistedContact?.dateOfBirth),
                isRequired: false,
                isHidden: () => true
            })
        };
    }


    get fullContactName(): NullableString {
        if(this.fields.firstName.value && this.fields.lastName.value) {
            return `${this.fields.firstName.value} ${this.fields.lastName.value}`;
        }

        return null;
    }

    private _primaryPassengerChangesSubscription: IReactionDisposer | null = null;
    private _subscribeToPrimaryPassengerChanges(): void {
        const primaryContact = this.getPrimaryContactPassenger();
        if(!primaryContact) {
            return;
        }

        if(this._primaryPassengerChangesSubscription) {
            this._primaryPassengerChangesSubscription();
        }

        this._primaryPassengerChangesSubscription = reaction(() => [
            primaryContact.fields.firstName.value,
            primaryContact.fields.lastName.value
        ], () => {
            this._syncNameWithPrimaryPassenger();
        });
    }

    private _syncNameWithPrimaryPassenger(): void {
        const primaryContact = this.getPrimaryContactPassenger();
        if(primaryContact) {
            this.fields.firstName.setValue(primaryContact.fields.firstName.value?.toUpperCase()?.trim() || null);
            this.fields.lastName.setValue(primaryContact.fields.lastName.value?.toUpperCase().trim() || null);
        }
    }

    private _getPrimaryContact(): IDotRezContact | null {
        return this.booking.bookingData.contacts.filter(item => item.key === PRIMARY_CONTACT_KEY)[0]?.value;
    }

    private get _isNew(): boolean {
        return !Boolean(this._getPrimaryContact()?.name?.first);
    }

    private get data(): IDotRezContact {

        let dotRezContact = this._getPrimaryContact();
        if(!dotRezContact) {
            dotRezContact = {
                contactTypeCode: PRIMARY_CONTACT_KEY,
                customerNumber: this.services.user.profile.customerNumber,
                cultureCode: this.services.language.currentLanguage,
                distributionOption: DistributionOptionsEnum.Email,
                companyName: null,
                notificationPreference: NotificationPreferenceEnum.None,
                sourceOrganization: null,
                address: {
                    city: null,
                    countryCode: null,
                    lineOne: null,
                    postalCode: null,
                    provinceState: null
                },
                name: {
                    first: null,
                    last: null,
                    title: null,
                },
                emailAddress: null,
                phoneNumbers: [],
            }
            runInAction(() => {
                this.booking.bookingData.contacts.push({
                    key: PRIMARY_CONTACT_KEY,
                    value: dotRezContact!
                });
            });

        }
        return dotRezContact;
    }

    private _getUpdatedContactContactData(): IDotRezContact {
        return {
            ...this.data,
            name: {
                ...this.data.name,
                first: this.fields.firstName.value?.toUpperCase()?.trim() || null,
                last: this.fields.lastName.value?.toUpperCase()?.trim() || null
            },
            customerNumber: this.fields.customerNumber.value || '',
            emailAddress: this.fields.emailAddress.value,
            cultureCode: this.services.language.currentLanguage,
            distributionOption: DistributionOptionsEnum.Email,
            address: {
                ...this.data.address,
                countryCode: this.fields.countryCode.value,
            },
            phoneNumbers: [
                {
                    number: this.fields.phoneNumber.value,
                    type: PhoneTypeEnum.Home
                }
            ]
        };
    }

    async save(): Promise<void> {
        if(!this.hasChanges()) {
            return;
        }

        let updatedContactData: IDotRezContact = this._getUpdatedContactContactData();

        if(this._isNew) {
            await this.booking.addContact(updatedContactData);
        } else {
            await this.booking.updateContact(updatedContactData);
        }

        runInAction(() => {
            const index = this.booking.bookingData.contacts.findIndex(c => c.key === PRIMARY_CONTACT_KEY);
            this.booking.bookingData.contacts[index].value = updatedContactData;
        });

        this.commitChanges();

        this._persistBookingContact();
    }

    private _persistBookingContact(): void {
        const persistedContact: IPersistedBookingContact = {
            firstName: this.fields.firstName.value?.toUpperCase()?.trim() || null,
            lastName: this.fields.lastName.value?.toUpperCase()?.trim() || null,
            emailAddress: this.fields.emailAddress.value,
            phoneNumber: this.fields.phoneNumber.value,
            countryCode: this.fields.countryCode.value,
            dateOfBirth: this.services.time.formatYYY_MM_DD(this.fields.dateOfBirth.value)
        };

        this.booking.services.localStorage.setJson('userProfile.bookingContact', persistedContact);
    }


    private getPrimaryContactPassenger(): MaturePassengerModel | null {
        return this.booking.passengers.find(p => p.isPrimaryContact) ?? null;
    }
}
