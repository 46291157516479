import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const ArrowBackIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} sizeIsHeight viewBox="0 0 10.834 17.602" ratio={10.834/17.602}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(-0.926 -1.145)">
            <path d="M2.006,10.87c.684.684,1.55.936,1.925.561l7.655-7.655c.375-.375.122-1.241-.562-1.925S9.539.98,9.1,1.29L1.444,8.945C1.075,9.326,1.327,10.191,2.006,10.87Z"/><path d="M11.025,18.025c.684-.684.924-1.562.536-1.95L3.59,8.1c-.39-.39-.7-.008-1.722.458A1.422,1.422,0,0,0,1.1,10.591l7.971,7.97C9.465,18.951,10.341,18.709,11.025,18.025Z"/>
            </g>
        </SvgIcon>
    )
}
