export enum FeeCodeEnum {
    FLX= 'FLX',
    DateChangeFee = 'DCH',
    Insurance = 'XCOVT1',
    SCLY = "SCLY"
}
export enum FeeTypeEnum {
    All = "All",
    Tax = "Tax",
    TravelFee = "TravelFee",
    ServiceFee = "ServiceFee",
    PaymentFee = "PaymentFee",
    PenaltyFee = "PenaltyFee",
    SsrFee = "SsrFee",
    NonFlightServiceFee = "NonFlightServiceFee",
    UpgradeFee = "UpgradeFee",
    SeatFee = "SeatFee",
    BaseFare = "BaseFare",
    SpoilageFee = "SpoilageFee",
    NameChangeFee = "NameChangeFee",
    ConvenienceFee = "ConvenienceFee",
    BaggageFee = "BaggageFee",
    FareSurcharge = "FareSurcharge",
    PromotionDiscount = "PromotionDiscount",
    ServiceBundle = "ServiceBundle",
    ExtraBagFee = "ExtraBagFee"
}
