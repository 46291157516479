import {IServiceFactory} from "../../services/service-factory.interface";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../services/dialog-factory/yes-no-dialog.enums";

export async function handleBookingFlowLogoClick(services: IServiceFactory, dontAskForConfirmation: boolean | undefined): Promise<void> {

    if(dontAskForConfirmation) {
        await services.navigator.goHome();
        return;
    }

    const result = await services.dialogFactory.showYesNoDialog({
        title: services.language.translate('Confirmation'),
        message: services.language.translate('Are you sure you want to give up?'),
        primaryButton: YesNoDialogPrimaryButton.PrimaryButtonNo
    });
    if(result === YesNoDialogResult.Yes) {
        await services.navigator.goHome();
    }
}
