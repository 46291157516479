import {IDotRezPassengerSegmentFare} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {Price} from "../../../currency/price";
import {
    isDiscountServiceCharge,
    sumOfNonTaxesServiceCharges,
    sumOfTaxesServiceCharges,
} from "../base-models/fees/service-charges.helpers";
import {computed, makeObservable} from "mobx";
import {IPassengerSegmentShoppingCartAdapter} from "../base-models/shopping-cart/passenger-segment-shopping-cart-adapter.interface";

export class PassengerSegmentFareModel {
    constructor(private readonly passengerSegment: IPassengerSegmentShoppingCartAdapter) {
        makeObservable(this, {
            dotRezFares: computed
        });
    }

    get dotRezFares(): IDotRezPassengerSegmentFare[] {
        let fares: IDotRezPassengerSegmentFare[] = [];
        this.passengerSegment.segmentFares.forEach(segmentFare => {
            fares = [
                ...fares,
                ...segmentFare.passengerFares.filter(pFare => this.passengerSegment.passengerType.code === pFare.passengerType  && pFare.discountCode === this.passengerSegment.passengerDiscountCode)
            ];
        });

        return fares;
    }

    get flightPrice(): Price {
        return this.passengerSegment.createPrice(this.dotRezFares.sum(fare => sumOfNonTaxesServiceCharges(fare.serviceCharges)));
    }

    get taxes(): Price {
        return this.passengerSegment.createPrice(this.dotRezFares.sum(fare => sumOfTaxesServiceCharges(fare.serviceCharges)));
    }

    get hasPromotionApplied(): boolean {
        return this.dotRezFares.some(f => f.serviceCharges.some(sc => isDiscountServiceCharge(sc.type) && sc.amount > 0));
    }
}
