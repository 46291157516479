import React from 'react';
import {ISvgIconProps, SvgIcon } from '../../../../assets/svg/svg-icon.component';

export const SeatExtraLegroomLargeIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={3} sizeIsHeight {...props} viewBox="0 0 15 19">
            <g fill={props.color? props.color: "currentColor"} transform="translate(0)"><g transform="translate(0 7.193)"><path d="M3.034,13.684H9.1a.948.948,0,0,0,0-1.9H3.793L1.9,4.582A.836.836,0,0,0,.759,3.823C.19,3.823,0,4.392,0,4.961l1.9,7.965A1.04,1.04,0,0,0,3.034,13.684Z" transform="translate(0 -3.774)" /></g><g transform="translate(2.992 4.748)"><path d="M12.632,16.736a.7.7,0,0,0,.569-.19.993.993,0,0,0,.379-1.517l-2.086-4.551a1.352,1.352,0,0,0-1.138-.569H5.237L4.478,6.5,6,7.444a.7.7,0,0,0,.569.19L9.788,8.2a1.033,1.033,0,0,0,1.327-.948,1.113,1.113,0,0,0-.948-1.327L7.133,5.358,3.53,2.7c-.379-.19-.948-.379-1.327,0-.569.379-.759.759-.569,1.327l1.517,7.206a1.062,1.062,0,0,0,1.138.948H9.6l1.9,3.793A1.04,1.04,0,0,0,12.632,16.736Z" transform="translate(-1.563 -2.485)"/></g><circle cx="2.086" cy="2.086" r="2.086" transform="translate(1.707 0)"/></g>
        </SvgIcon>
    )
}
