import {IServiceFactory} from "../../../../service-factory.interface";
import {PetsSsrTypeBase} from "../pets-ssr-type-base";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../ssrs-preferred-orders-in-shopping-cart";
import {ISeatViewModel} from "../../../../booking/models/seat-maps/seat-view-model.interface";
import {TimeSpan} from "../../../../../types/time-span";

export default class PetcSsrType extends PetsSsrTypeBase {
    constructor(services: IServiceFactory) {
        super('PETC', services);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.PET_IN_CABIN;
    }

    isSeatRestricted(seat: ISeatViewModel): boolean {
        return !seat.allowsPet;
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(3);
    }
}
