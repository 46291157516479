import {NullableString} from "../../../../../types/nullable-types";

export interface IDotRezSsrToAdd {
    ssrKey: string;
    count: number;
    note: NullableString;
}

export interface IDotRezUpdateSsrsRequest {
    readonly ssrKeysToRemove: string[];
    readonly ssrsToAdd: IDotRezSsrToAdd[];
}

export class DotRezUpdateSsrsRequestBuilder {
    private _finalRequest: IDotRezUpdateSsrsRequest = {
        ssrKeysToRemove: [],
        ssrsToAdd: []
    }

    append(newRequest: IDotRezUpdateSsrsRequest) {
        this._finalRequest = {
            ssrsToAdd: [...this._finalRequest.ssrsToAdd, ...newRequest.ssrsToAdd],
            ssrKeysToRemove: [...this._finalRequest.ssrKeysToRemove, ...newRequest.ssrKeysToRemove]
        }
    }

    build(): IDotRezUpdateSsrsRequest {
        return this._finalRequest;
    }

}
