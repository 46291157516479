import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {IPersonViewModel} from "../../../../services/user/models/person/person-view-model.interface";
import styled from "styled-components";

const AvailableCompanionBox = styled.div`
    background-color: ${props => props.theme.colors.lightTint};
    width: 70px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -webkit-align-items: center;
    text-align: center;
    padding: 5px;
    position: relative;
    flex-basis: 80px;
    border-left: 1px solid rgba(${props => props.theme.colors.mediumRgb}, 0.75);

    &:last-of-type {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(${props => props.theme.colors.mediumRgb}, 0.75);
        border-bottom-left-radius: 8px;
    }
`

const CompanionInitialsBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    font-size: ${props => props.theme.fontSize.xxxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 5px 5px 8px;
    width: 50px;
    height: 50px;
    background-color: ${props => props.theme.colors.tertiaryTint};
    color: ${props => props.theme.colors.secondary};
`

const CompanionFirstNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.colors.secondary};
    text-align: center;
    line-height: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -webkit-align-items: flex-start;
`

interface AvailableBookingCompanionComponentProps {
    passenger: IPassengerViewModel;
    companion: IPersonViewModel;
}

export const AvailableBookingCompanionComponent: React.FC<AvailableBookingCompanionComponentProps> = observer((props) => {
    const onClickHandler = () => {
        props.passenger.companion.attachCompanion(props.companion);
    }
    return (
        <AvailableCompanionBox onClick={onClickHandler} className={'tst-available-companion'}>
            <CompanionInitialsBox>
                {props.companion.getInitials()}
            </CompanionInitialsBox>
            <CompanionFirstNameBox>
                {props.companion.fields.firstName.value}
            </CompanionFirstNameBox>
        </AvailableCompanionBox>
    );
});
