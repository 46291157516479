import {IBookingSsrsAggregatorViewModel} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {IJourneySsrsBucketViewModel} from "../../../../services/booking/models/ssrs/journey-ssrs-bucket-view-model.interface";
import {IPassengerSsrEditorViewModel} from "../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import React from "react";


export enum SsrsSelectionPresentationMode {
    UseSlides,
    SingleScreen
}

export interface IJourneyCustomSsrsEditorRenderer {
    render: (journeyBucket: IJourneySsrsBucketViewModel) => React.ReactElement;
    presentationMode?: SsrsSelectionPresentationMode;
}

export enum PassengerSsrsRenderingFlexDirection {
    Column = "column",
    Row = "row"
}

export interface IPassengerCustomSsrEditorRenderer {
    flexDirection?: PassengerSsrsRenderingFlexDirection;
    render: (passengerSsrEditor: IPassengerSsrEditorViewModel) => React.ReactElement;
}

export interface SsrsSelectionComponentProps {
    ssrsAggregator: IBookingSsrsAggregatorViewModel;
    disclaimer?: string | React.ReactElement;
    journeyCustomSsrsEditorRenderer?: IJourneyCustomSsrsEditorRenderer;
    passengerCustomSsrEditorRenderer?: IPassengerCustomSsrEditorRenderer;
    presentationMode?: SsrsSelectionPresentationMode;
    stickyTopRef?: React.MutableRefObject<HTMLDivElement | null>;
}
