import React from "react";
import styled from "styled-components";
import { CheckedSelectedIcon } from "../../../../../assets/svg/checked-selected.icon";
import { useServices } from "../../../../../hooks/use-services.hook";
import { IBundleViewModel } from "../../../../../services/booking/models/bundle/bundle-view-model.interface";

const BundleFooterBox = styled.div<{backgroundColor: string, textColor: string}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
    background-color: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.tertiaryContrast};
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-transform: uppercase;
    border-bottom-left-radius: ${props => props.theme.border.defaultRadius};
    border-bottom-right-radius: ${props => props.theme.border.defaultRadius};
`;

const CheckedSelectedBundleIconBox = styled.div`
    padding-right: 6px;
`;

export const BundleFooterComponent: React.FC<{ bundle: IBundleViewModel, backgroundColor: string, textColor: string}> = (props) => {
    const services = useServices();

    
    return (
        <BundleFooterBox backgroundColor={props.backgroundColor}
                         textColor={props.textColor}>
            <CheckedSelectedBundleIconBox>
                <CheckedSelectedIcon color={props.textColor} />
            </CheckedSelectedBundleIconBox>
            {services.language.translate('Selected')}
        </BundleFooterBox>
    )
};
