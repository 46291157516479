import styled from "styled-components";

export const SlideDeleteButtonBox = styled.button`
    background-color: ${props => props.theme.colors.danger};
    color: ${props => props.theme.colors.dangerContrast};
    font-size: ${props => props.theme.fontSize.large};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 5px;
    line-height: 1;

    svg {
        margin-left: 5px;
    }
`
