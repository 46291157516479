import {IPassengerSegmentShoppingCartAdapter} from "./passenger-segment-shopping-cart-adapter.interface";
import {computed, makeObservable} from "mobx";
import {PassengerSegmentFareModel} from "../../passenger-segment/passenger-segment-fare.model";
import {IServiceFactory} from "../../../../service-factory.interface";
import {NullablePrice, Price} from "../../../../currency/price";
import {ISeatFeeModel} from "../fees/seat-fees/seat-fee-model.interface";
import {FeeTypeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {SeatFeeModel} from "../fees/seat-fees/seat-fee-model";
import {NullableUndefinedString} from "../../../../../types/nullable-types";
import {IDotRezPassengerSegmentSsr} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {ISsrFeeModel} from "../fees/ssr-fees/ssr-fee-model.interface";
import {SsrFeeModel} from "../fees/ssr-fees/ssr-fee-model";
import {IFeeModel} from "../fees/fee-model.interface";
import {OtherFeeModel} from "../fees/other/other-fee.model";
import {IPassengerSegmentShoppingCartModel} from "./passenger-segment-shopping-cart-model.interface";
import {ShoppingCartModeEnum} from "../../../booking-strategies/booking-strategy.interface";
import {PassengerSegmentShoppingCartDecorator} from "./passenger-segment-shopping-cart-decorator";
import {ISeatFeeAnalyticsData} from "../../analytics/google-analytics.intefaces";

export abstract class PassengerSegmentShoppingCartBaseModel<TPassengerSegment extends IPassengerSegmentShoppingCartAdapter> implements IPassengerSegmentShoppingCartModel {
    constructor(protected readonly passengerSegment: TPassengerSegment) {
        makeObservable(this, {
            purchasedSsrsFees: computed,
            inBundleSsrsFees: computed,
            inBundleSsrsFeeCurrentPricesMap: computed,
            purchasedSsrsFeeCurrentPricesMap: computed,
            otherFeesMap: computed,
            seatFees: computed,
            ssrFees: computed,
            otherFees: computed
        });

        this.fare = new PassengerSegmentFareModel(this.passengerSegment);
    }

    abstract getSeatFeeAnalyticsData(): ISeatFeeAnalyticsData | null;
    abstract getSsrsFeesGroupedByAnalyticsName(): Record<string, IFeeModel[]> | null;
    abstract getSsrsFeesForId(ids: string[]): IFeeModel[];

    createDecorator(shoppingCartMode: ShoppingCartModeEnum): IPassengerSegmentShoppingCartModel {
        return new PassengerSegmentShoppingCartDecorator(this, shoppingCartMode);
    }

    private readonly fare: PassengerSegmentFareModel;

    get hasPromotionApplied(): boolean {
        return this.fare.hasPromotionApplied;
    }

    get services(): IServiceFactory {
        return this.passengerSegment.services;
    }

    get description(): string {
        return this.passengerSegment.passengerFullName;
    }

    get initialFare(): NullablePrice {
        return this.passengerSegment.getInitialFare();
    }

    get currentFare(): Price {
        return this.fare.flightPrice;
    }

    get inBundleSsrsFeeCurrentPricesMap(): Record<string, Price[]> {
        return this.inBundleSsrsFees.groupByKeyAndMapToType(ssrFee => ssrFee.ssrType.ssrCode, ssrFee => ssrFee.currentPrice);
    }

    get purchasedSsrsFeeCurrentPricesMap(): Record<string, Price[]> {
        return this.purchasedSsrsFees.groupByKeyAndMapToType(ssrFee => ssrFee.ssrType.ssrCode, ssrFee => ssrFee.currentPrice);
    }

    get ssrsFeesMapByCodeAndNumber(): Record<string, ISsrFeeModel[]> {
        return this.ssrFees.groupByKey(fee => this._ssrCodeAndNumber(fee.ssrType.ssrCode!, fee.ssrNumber))
    }


    get otherFeesMap(): Record<string, IFeeModel[]> {
        return this.otherFees.groupByKey(f => f.feeType.toString())
    }

    getSsrFeeByCodeAndNumber(ssrCode: string, ssrNumber: number): ISsrFeeModel | null {
        const feesForThisSsr = this.ssrsFeesMapByCodeAndNumber[this._ssrCodeAndNumber(ssrCode, ssrNumber)] || [];
        return feesForThisSsr.find(fee => fee.ssrNumber === ssrNumber) || null;
    }

    extractSsrCurrentPriceFromMap(ssrCode: string, ssrIndex: number, pricesMap: Record<string, Price[]>): Price | null {
        const prices = pricesMap[ssrCode];
        if(!prices) {
            return null;
        }

        if(ssrIndex > prices.length - 1) {
            return null;
        }

        return prices[ssrIndex];
    }

    getInBundleSsrCurrentPrice(ssrCode: string, ssrIndex: number): Price | null {
       return this.extractSsrCurrentPriceFromMap(ssrCode, ssrIndex, this.inBundleSsrsFeeCurrentPricesMap);
    }

    getPurchasedSsrCurrentPrice(ssrCode: string, ssrIndex: number): Price | null {
        return this.extractSsrCurrentPriceFromMap(ssrCode, ssrIndex, this.purchasedSsrsFeeCurrentPricesMap);
    }

    getSeatCurrentPrice(): Price | null {
        if(this.seatFees.length === 0) {
            return null;
        }

        return this.seatFees[0].currentPrice;
    }

    getOtherFeeCurrentPrice(feeType: FeeTypeEnum, feeIndex: number): Price | null {
        const otherFees = this.otherFeesMap[feeType.toString()];
        if(!otherFees) {
            return null;
        }

        if(feeIndex > otherFees.length - 1) {
            return null;
        }

        return otherFees[feeIndex].currentPrice;

    }

    get seatFees(): ISeatFeeModel[] {

        if(!this.passengerSegment.seatNumber) {
            return [];
        }

        const fees: ISeatFeeModel[] = [];

        const seatFees = this.passengerSegment.allServiceFees.filter(fee => fee.feeType === FeeTypeEnum.SeatFee)
            // we need to sort here because if the seats are added/removed multiple times in different sessions we will have multiple seats fees for that passenger segment so we need to take the last one
            .sort((f1, f2) => f2.createdDate.getTime() - f1.createdDate.getTime());

        let seatFee = seatFees[0];

        const initialSeatFee = this.passengerSegment.getInitialSeatPrice();
        if(seatFee) {
            fees.push(new SeatFeeModel(this.passengerSegment.seatNumber,
                                        this.passengerSegment,
                                        seatFee.feeCode,
                                        seatFee.createdDate,
                                        initialSeatFee,
                                        seatFee.priceToDisplay));
        } else {
            fees.push(new SeatFeeModel(this.passengerSegment.seatNumber,
                                        this.passengerSegment,
                                        null,
                                        this.passengerSegment.bookingCreateDate,
                                        initialSeatFee,
                                        this.passengerSegment.createPrice(0)));
        }

        return fees;
    }


    private _ssrCodeAndNumber(ssrCode: NullableUndefinedString, ssrNumber: number): string {
        return `${ssrCode || ''}_${ssrNumber}`
    }

    private _getSsrsChunksByCode(predicate: (ssr: IDotRezPassengerSegmentSsr) => boolean): Array<IDotRezPassengerSegmentSsr[]> {
        return Object.values(this.passengerSegment.ssrs.filter(predicate).groupByKey(ssr => ssr.ssrCode));
    }

    get inBundleSsrsFees(): ISsrFeeModel[] {
        const result: ISsrFeeModel[] = [];
        const inBundleSsrsChunks = this._getSsrsChunksByCode(ssr => ssr.inBundle);

        const inBundleSsrAliasesCount: Record<string, number> = {};

        for(let ssrType of this.passengerSegment.getVirtualSsrsIncludedInBundle()) {
            if(ssrType.aliasFor) {
                if(!inBundleSsrAliasesCount[ssrType.aliasFor.ssrCode]) {
                    inBundleSsrAliasesCount[ssrType.aliasFor.ssrCode] = 0;
                }

                inBundleSsrAliasesCount[ssrType.aliasFor.ssrCode] += 1;
            }
        }

        for(let chunk of inBundleSsrsChunks) {
            const ssrType = this.services.ssrTypes.getSsrType(chunk[0].ssrCode);
            if(ssrType.shouldShowInShoppingCart && ssrType.shouldShowBundleIncludedInShoppingCart && !ssrType.aliasFor) {
                chunk.forEach((ssr, index) => {
                    const initialPrice = this.passengerSegment.getInitialInBundleSsrPrice(ssrType, index);
                    result.push(new SsrFeeModel(index,
                                                ssr.ssrKey,
                                                ssrType,
                                                null,
                                                ssr.ssrNumber,
                                                this.services.time.currentDate,
                                                initialPrice,
                                                this.passengerSegment.createPrice(0),
                                                1 + (inBundleSsrAliasesCount[ssrType.ssrCode] ?? 0)));
                });
            }
        }

        return result;
    }

    private _getPassengerSsrsFeesGroupedByCodeAndNumber(): Record<string, IFeeModel> {
        return this.passengerSegment.allServiceFees
            .filter(fee => fee.ssrType && fee.feeType === FeeTypeEnum.SsrFee)
            .toDictionary(fee => this._ssrCodeAndNumber(fee.ssrType?.ssrCode , fee.ssrNumber));
    }

    protected createPrice = (amount: number): Price => {
        return this.passengerSegment.createPrice(amount);
    }

    get purchasedSsrsFees(): ISsrFeeModel[] {
        const result: ISsrFeeModel[] = [];
        const purchasedSsrsChunks = this._getSsrsChunksByCode(ssr => !ssr.inBundle);

        const passengerSsrFeesGroupedByCodeAndNumber = this._getPassengerSsrsFeesGroupedByCodeAndNumber();
        for(let chunk of purchasedSsrsChunks) {
            const ssrType = this.services.ssrTypes.getSsrType(chunk[0].ssrCode);
            if(!ssrType.shouldShowInShoppingCart) {
                continue;
            }
            chunk.forEach((ssr, index) => {
                const fee = passengerSsrFeesGroupedByCodeAndNumber[this._ssrCodeAndNumber(ssr.ssrCode, ssr.ssrNumber)];
                const initialPrice = this.passengerSegment.getInitialPurchasedSsPrice(ssrType, index);
                if(fee) {
                    result.push(new SsrFeeModel(index,
                                                ssr.ssrKey,
                                                ssrType,
                                                fee.feeCode,
                                                ssr.ssrNumber,
                                                fee.createdDate,
                                                initialPrice,
                                                fee.priceToDisplay));
                } else {
                    // it means it is a free of charge SSR like special assistance
                    result.push(new SsrFeeModel(index,
                                                ssr.ssrKey,
                                                ssrType,
                                                null,
                                                ssr.ssrNumber,
                                                this.services.time.currentDate,
                                                initialPrice,
                                                this.passengerSegment.createPrice(0)));
                }

            })
        }

        return result;
    }

    get ssrFees(): ISsrFeeModel[] {
        const result: ISsrFeeModel[] = [
            ...this.inBundleSsrsFees,
            ...this.purchasedSsrsFees
        ];
        return this.services.ssrTypes.filterVisibleSsrs(result);
    }

    get otherFees(): IFeeModel[] {
        const excludedFees = [FeeTypeEnum.SeatFee, FeeTypeEnum.SsrFee, FeeTypeEnum.ServiceBundle];
        return this.passengerSegment.allServiceFees
            .filter(fee => fee.totalToDisplay.amount !== 0 && !excludedFees.includes(fee.feeType))
            .map((fee, index) => new OtherFeeModel(fee, this.passengerSegment.getInitialOtherFeePrice(fee.feeType, index)));
    }


}
