import React, {useRef} from "react";
import {IonSpinner} from "@ionic/react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {PriceComponent} from "../../../../../components/price/price.component";
import {PlaneNotAvailableIcon} from "../../../../../assets/svg/plane-not-available.icon";
import useIsInViewPort from "../../../../../hooks/use-is-in-view-port.hook";
import {
    ILowFareResult,
    LowFareStatusEnum
} from "../../../../../services/low-fare/low-fare-readers/low-fare-reader.interface";
import {IFlightsScheduleViewModel} from "../../../../../services/flight-search/flights-schedule/flights-schedule-view-model.interface";
import {SwiperComponent} from "../../../../../basic-components/swiper/swiper.component";
import {SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {
    SWIPER_NAV_BUTTON_CSS_CLASS, SWIPER_NAV_BUTTON_NEXT_CSS_CLASS,
    SWIPER_NAV_BUTTON_PREV_CSS_CLASS
} from "../../../../../basic-components/swiper/swiper-consts";
import {Swiper as SwiperClass} from "swiper/types";

const FlightDatesContainerBox = styled.div<{isInSmallScreenView: boolean}>`
  --container-height: 65px;
  --date-content-height: calc(var(--container-height) - ${props => props.theme.spacing.large});
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .${SWIPER_NAV_BUTTON_CSS_CLASS} {
    background-color: ${props => props.theme.colors.lightShade};
    height: var(--date-content-height);
    align-self: flex-end;
  }
  
  .${SWIPER_NAV_BUTTON_PREV_CSS_CLASS} {
    border-radius: ${props => props.theme.border.defaultRadius} 0 0 ${props => props.theme.border.defaultRadius};
    ${
            props => props.isInSmallScreenView
                    ? css`
                      border-radius: revert;
                    `
                    : ``
    }
  }

  .${SWIPER_NAV_BUTTON_NEXT_CSS_CLASS} {
    border-radius: 0 ${props => props.theme.border.defaultRadius} ${props => props.theme.border.defaultRadius} 0;
    
    ${
        props => props.isInSmallScreenView
                    ? css`
                      border-radius: revert;
                    `
                    : ``
    }
  }
`

const DateButtonWrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: var(--container-height);
`

interface DateButtonBoxProps {
    isSelected: boolean;
    disabled: boolean;
    isInSmallScreenView: boolean;
}


const DateButtonBox = styled.div<DateButtonBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  line-height: 0;
  cursor: ${props => props.disabled ? "not-allowed" : (props.isSelected ? "default" : "pointer")};
  color: ${props => props.theme.colors.secondary};
  background-color: ${props => props.theme.colors.lightShade};
  border-left: 1px solid ${props => props.theme.colors.light};
  border-right: 1px solid ${props => props.theme.colors.light};
  height: var(--date-content-height);
  padding: ${props => props.theme.spacing.medium} 0;
  ${
          props => props.isSelected
                  ? css`
                    background-color: ${props => props.theme.colors.secondaryContrast};
                    border-top: 4px solid ${props => props.theme.colors.secondary};
                    transition: height 300ms;
                    height: var(--container-height);
                    
                  `
                  : ``
  }

  ${
          props => props.isSelected && props.isInSmallScreenView
                  ? css`
                    border-top: revert;
                    border-bottom: 4px solid ${props => props.theme.colors.secondary};
                    height: var(--date-content-height);
                  `
                  : ``
  }
  

`;


const PriceBox = styled.div<{isSelected: boolean}>`
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 1;
  margin: 0;
  font-size: ${props => props.theme.fontSize.medium};
  color: ${props => props.theme.colors.secondary};
  ${
    props => props.isSelected 
                ? css`
                  font-size: ${p => p.theme.fontSize.xLarge};
                  color: ${p => p.theme.colors.primary};
                `
                : ``
  }

`;

const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  line-height: 1;
  margin: 0 0 ${props => props.theme.spacing.small};
`;


const FormattedDateBox = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${props => props.theme.fontSize.small};
`

interface AvailabilityDateComponentProps {
    date: Date;
    isAvailable: boolean;
    isSelected: boolean;
    getLowFare: () => ILowFareResult;
    onClick: (date: Date) => void;
}


const AvailableFlightDateComponent: React.FC<AvailabilityDateComponentProps> = observer((props) => {
    const services = useServices();
    const elementRef = useRef<HTMLDivElement | null>(null);
    const isInViewPort = useIsInViewPort(elementRef);
    const formattedDisplayDate = services.time.formatUserFriendlyDayNameDayMonth(props.date);


    const renderPriceNotAvailable = () => {
        return (<PlaneNotAvailableIcon color={services.theme.currentTheme.colors.medium}/>);
    }

    const renderPriceBox = () => {
        if (!props.isAvailable) {
            return renderPriceNotAvailable();
        }

        let lowFareResult: ILowFareResult = {
            status: LowFareStatusEnum.NoFare
        };

        if (isInViewPort) {
            lowFareResult = props.getLowFare();
        }

        if (lowFareResult.price) {
            return (
                <PriceComponent price={lowFareResult.price}/>
            )
        } else if (lowFareResult.status === LowFareStatusEnum.Reading) {
            return (
                <IonSpinner/>
            );
        } else if (lowFareResult.status === LowFareStatusEnum.SoldOut) {
            return services.language.translate('Sold Out');
        } else {
            return null;
        }
    }

    const onClick = () => {
        if(props.isAvailable) {
            props.onClick(props.date);
        }

    }

    return (
        <DateButtonWrapperBox>
            <DateButtonBox ref={elementRef}
                           isSelected={props.isSelected}
                           disabled={!props.isAvailable}
                           isInSmallScreenView={services.layout.isInSmallScreenView}
                           onClick={onClick}>

                <DateBox>
                    <FormattedDateBox>{formattedDisplayDate}</FormattedDateBox>
                </DateBox>
                <PriceBox isSelected={props.isSelected}>
                    {renderPriceBox()}
                </PriceBox>
            </DateButtonBox>
        </DateButtonWrapperBox>

    )
});

interface FlightDatesAvailabilityComponentProps {
    flightSchedule: IFlightsScheduleViewModel;
    currentScheduleSelectedDate: Date;
    onDateSelected: (date: Date) => void;
    getLowFare: (date: Date) => ILowFareResult;
}

export const FlightDatesAvailabilityComponent: React.FC<FlightDatesAvailabilityComponentProps> = observer((props) => {
    const services = useServices();

    let dateRange = props.flightSchedule.availableDates;
    if (dateRange.length > 0) {
        dateRange = services.time.getDateRange(dateRange[0], dateRange[dateRange.length - 1]);
    }

    const getPrice = (date: Date, isAvailable: boolean): ILowFareResult => {
        if (isAvailable) {
            return props.getLowFare(date);
        }

        return {
            status: LowFareStatusEnum.NoFare
        };
    }


    let numberOfSlides = 7;
    if (services.mediaQuery.sAndBelow) {
        numberOfSlides = 3;
    } else if (services.mediaQuery.mAndBelow) {
        numberOfSlides = 4;
    }

    const onDateSelected = (date: Date) => {
        props.onDateSelected(date);
    }

    const getInitialSlide = () => {
        const index = dateRange.findIndex(d => services.time.areDatesEqual(d, props.currentScheduleSelectedDate));
        if (index >= 0) {
            return index;
        }

        return Math.floor(Math.min(numberOfSlides, dateRange.length) / 2)
    }

    const isCurrentSelectedDateInCurrentSwiperGroup = (swiper: SwiperClass) => {
        const toIndex = swiper.activeIndex + numberOfSlides - 1;
        for(let i = swiper.activeIndex; i <= toIndex; i++) {
            if(services.time.areDatesEqual(dateRange[i], props.currentScheduleSelectedDate)) {
                return true;
            }
        }
        return false;
    }

    const onSlideNavigation = (swiper: SwiperClass) => {
        if(isCurrentSelectedDateInCurrentSwiperGroup(swiper)) {
            return;
        }

        const date = dateRange[swiper.activeIndex + Math.floor(numberOfSlides / 2)];
        if(date) {
            props.onDateSelected(date);
        }

    }

    return (
        <FlightDatesContainerBox isInSmallScreenView={services.layout.isInSmallScreenView}>
                <SwiperComponent modules={[Navigation]}
                                 navigationButtons={{size: "medium"}}
                                 spaceBetween={0}
                                 speed={500}
                                 initialSlide={getInitialSlide()}
                                 slidesPerView={numberOfSlides}
                                 slidesPerGroup={numberOfSlides}
                                 onSlideChange={onSlideNavigation}>

                    {dateRange.map(date => {

                        const isSelected = services.time.areDatesEqual(props.currentScheduleSelectedDate, date);
                        const isAvailable = props.flightSchedule.isDateAvailable(date);

                        return (
                            <SwiperSlide key={date.getTime()}>
                                <AvailableFlightDateComponent date={date}
                                                              isSelected={isSelected}
                                                              isAvailable={isAvailable}
                                                              onClick={onDateSelected}
                                                              getLowFare={() => getPrice(date, isAvailable)}/>
                            </SwiperSlide>
                        );
                    })}
                </SwiperComponent>
        </FlightDatesContainerBox>
    )
});
