import {FlightSearchBaseService} from "./flight-search.base.service";
import {Wizard} from "../../models/wizard/wizard";
import {OriginStationSelectionStep} from "./steps/origin-station-selection.step";
import {DestinationStationSelectionStep} from "./steps/destination-station-selection.step";
import {SpecialPriceMarketUserAgreementStep} from "./steps/special-price-market-user-agreement.step";
import {FlightDatesSelectionStep} from "./steps/flight-dates-selection.step";
import {PassengersSelectionStep} from "./steps/passengers-selection.step";

export class FlightSearchHybridService extends FlightSearchBaseService {

    get hasDeepLinkErrors(): boolean {
        return false;
    }

    protected async _goToFlightSelection(): Promise<void> {
        await this.services.navigator.goHome();
        await this.services.booking.startNewBooking(this.searchController);
    }

    protected _onSearchMaskErrors(): void {
        this.steps.start();
    }

    protected _createSteps(): Wizard {
        const wizard = new Wizard(this.services, 'flightSearch');
        wizard.addStep(new OriginStationSelectionStep(this.services, wizard));
        wizard.addStep(new DestinationStationSelectionStep(this.services, wizard));
        wizard.addStep(new SpecialPriceMarketUserAgreementStep(this.services, wizard));
        wizard.addStep(new FlightDatesSelectionStep(this.services, wizard));
        wizard.addStep(new PassengersSelectionStep(this.services, wizard));
        return wizard;
    }
}
