import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const CopyIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.55} {...props} viewBox="0 0 15.5 15.5" sizeIsHeight>
            <g transform="translate(-1.25 -1.25)" fill={props.color? props.color: "currentColor"}>
                <path d="M2-.75H7A2.753,2.753,0,0,1,9.75,2V7A2.753,2.753,0,0,1,7,9.75H2A2.753,2.753,0,0,1-.75,7V2A2.753,2.753,0,0,1,2-.75Zm5,9A1.251,1.251,0,0,0,8.25,7V2A1.251,1.251,0,0,0,7,.75H2A1.251,1.251,0,0,0,.75,2V7A1.251,1.251,0,0,0,2,8.25Z" transform="translate(7 7)" />
                <path d="M4.112,11.9h-.7A2.16,2.16,0,0,1,1.25,9.743V3.408A2.16,2.16,0,0,1,3.408,1.25H9.743A2.16,2.16,0,0,1,11.9,3.408v.7a.75.75,0,0,1-1.5,0v-.7a.659.659,0,0,0-.658-.658H3.408a.659.659,0,0,0-.658.658V9.743a.659.659,0,0,0,.658.658h.7a.75.75,0,0,1,0,1.5Z" />
            </g>
        </SvgIcon>
    )
}
