import {BookingModel} from "../booking.model";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import { NullableString } from "../../../../types/nullable-types";


export class BookingAnalyticsHandlerModel implements IBookingAnalyticsHandlerViewModel{


    constructor(private readonly booking: BookingModel) {
        this._mappedIdsWithAnalyticsName = this.booking.storage.getJson(BookingSessionStorageKeys.mappedIdsWithAnalyticsModel) ?? {};
        this._addToCartItemsData = this.booking.storage.getJson(BookingSessionStorageKeys.analyticsDataInSession) ?? {};
    }

    private readonly _mappedIdsWithAnalyticsName: Record<string, string> =  {}
    private readonly _addToCartItemsData: Record<string, any> = {}


    private get services(): IServiceFactory {
        return this.booking.services;
    }

    get currentAnalyticsName(): string {
        return this.services.navigator.currentRoute.analyticsName ?? ""
    }

    getAnalyticsNameById(id: string): NullableString {
        return  this._mappedIdsWithAnalyticsName[id];
    }

    addKeyMappings(keys: string[]): void {
        keys.forEach(key => {
            this._mappedIdsWithAnalyticsName[key] = this.currentAnalyticsName;
        })

        this._saveKeyMappingsToStorage();
    }

    removeKeyMappings(keys: string[]):  void {

        keys.forEach(ssrKey => {
            delete this._mappedIdsWithAnalyticsName[ssrKey];
        });

        this._saveKeyMappingsToStorage();
    }

    saveDataToSession(key: string, data: any): void {
        this._addToCartItemsData[key] = data;
        this._saveAddToCartItemsToStorage();
    }

    removeItemsFromCart(key: string): void {
        if(this._addToCartItemsData[key]) {
            delete this._addToCartItemsData[key];
        }
        this._saveAddToCartItemsToStorage();
    }

    getDataForKey(key: string): any {
        return this._addToCartItemsData[key];
    }

    getAnalyticsNameByKey(key: string): string {
        return this._mappedIdsWithAnalyticsName[key] ?? "NotSet";
    }

    dispose() {}

    private _saveKeyMappingsToStorage(): void {
        this.booking.storage.setJson(BookingSessionStorageKeys.mappedIdsWithAnalyticsModel, this._mappedIdsWithAnalyticsName);
    }

    private _saveAddToCartItemsToStorage(): void {
        this.booking.storage.setJson(BookingSessionStorageKeys.analyticsDataInSession, this._addToCartItemsData);
    }
}

export interface IBookingAnalyticsHandlerViewModel {
    get currentAnalyticsName(): string;
    addKeyMappings(keys: string[]):  void;
    removeKeyMappings(keys: string[]):  void;
    getAnalyticsNameByKey(key: string): string;
    saveDataToSession(key: string, data: any): void;
    removeItemsFromCart(key: string): void;
    getDataForKey(key: string): any;
}