import React from 'react';
import {CreateAnimation} from "@ionic/react";
import {HomePageNavbarComponent} from "./components/navbar/home-page-navbar.component";
import { useServices } from '../../hooks/use-services.hook';
import {observer} from "mobx-react";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import styled from 'styled-components';
import {DEFAULT_ANIMATION_TIME} from "../../global-constants";
import {StandardPageFooterComponent} from "../../basic-components/page/standard-page-footer/standard-page-footer.component";
import {breakpoints} from "../../services/media-query/breakpoints";


const HomePageContentComponent = styled(StandardPageContentComponent)`
  &::part(scroll){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`


/**
 * TabContentBox is needed for the <CreateAnimation> to work properly
 */
const TabContentBox = styled.div`
    height: 100%;
    width: min(${breakpoints.sMax}px, 100%);   
`

export const HomeHybridPage: React.FC = observer(() => {
    const services = useServices();
    const currentTab = services.homePage.currentTab;

    return (
        <RoutedPageComponent disableSwipeNavigation={true}>
            {currentTab.renderHeader()}
            <HomePageContentComponent>
                <CreateAnimation key={currentTab.text}
                                 duration={DEFAULT_ANIMATION_TIME}
                                 iterations={1}
                                 play={true}
                                 fromTo={{
                                     property: 'transform',
                                     fromValue: 'translateX(100%)',
                                     toValue: 'translateX(0)'
                                 }}>
                    <TabContentBox>
                        {currentTab.renderContent()}
                    </TabContentBox>

                </CreateAnimation>
            </HomePageContentComponent>
            <StandardPageFooterComponent>
                {currentTab.renderFooter()}
                <HomePageNavbarComponent/>
                {
                    /**
                     * Commented line below shows how to use routes with parameters
                     */
                    //<IonButton onClick={() => services.navigator.hybridRoutes.sampleRouteWithParams.activate({routeParams: {id: "xxx"}})}>Click</IonButton>
                }
            </StandardPageFooterComponent>
        </RoutedPageComponent>
    );
})
