import {IFieldValidator} from "../field-validator.interface";
import {NullableString} from "../../../types/nullable-types";

export class DocumentNumberValidator implements IFieldValidator {
    constructor(private readonly errorMessage: string) {
    }

    static regularExpression = /^[a-zA-Z0-9]*$/;

    validate(fieldValue: any): NullableString {
        if(!fieldValue) {
            return null;
        }

        if(DocumentNumberValidator.regularExpression.test(fieldValue)) {
            return null;
        }

        return this.errorMessage;
    }
}
