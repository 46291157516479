import React from "react";
import styled from "styled-components";
import { useServices } from "../../../../hooks/use-services.hook";
import { CopyIcon } from "../../../../assets/svg/copy.icon";
import { Clipboard } from "@ionic-native/clipboard";
import { IonToast } from "@ionic/react";
import { useState } from "react";
import {observer} from "mobx-react-lite";
import {PrintTicketButtonComponent} from "../../../../components/print-ticket/print-ticket-button.component";

const FlightItineraryBookingReferenceBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 1;
`;

const LeftSectionBox = styled.div`
    
`

const RightSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
        font-size: ${props => props.theme.fontSize.medium};
    }
`

const FlightItineraryBookingReferenceText = styled.div`
    font-size: ${props => props.theme.fontSize.large};
`;

const FlightItineraryRecordLocatorBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: ${props => props.theme.spacing.large};
`;

const FlightItineraryRecordLocatorText = styled.div`
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const FlightItineraryCopyCodeBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${props => props.theme.spacing.large};
`;

const FlightItineraryCopyCode: React.FC = observer(() => {
    const services = useServices();

    const [showToast, setShowToast] = useState(false);

    const onClick = () => {
        const recordLocator = services.booking.current.recordLocator?.toString();
        if(recordLocator){
            Clipboard.copy(recordLocator);
            setShowToast(true);
        }
    }

    return(
        <FlightItineraryCopyCodeBox onClick={onClick}>
            <CopyIcon color={services.theme.currentTheme.colors.secondary}/>
            <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={services.language.translate('Code copied.')} duration={400} position="top"/>
        </FlightItineraryCopyCodeBox>
    )
});

export const FlightItineraryBookingReferenceComponent: React.FC = observer(() => {
    const services = useServices();

    return(
        <FlightItineraryBookingReferenceBox>
            <LeftSectionBox>
                <FlightItineraryBookingReferenceText>
                    {services.language.translate('Booking code')}
                </FlightItineraryBookingReferenceText>
                <FlightItineraryRecordLocatorBox>
                    <FlightItineraryRecordLocatorText>
                        {services.booking.current.recordLocator}
                    </FlightItineraryRecordLocatorText>
                    <FlightItineraryCopyCode/>
                </FlightItineraryRecordLocatorBox>
            </LeftSectionBox>
            <RightSectionBox>
                <PrintTicketButtonComponent booking={services.booking.current} hideLabel={false}/>
            </RightSectionBox>
        </FlightItineraryBookingReferenceBox>
    )
});
