import {IServiceFactory} from "../../../service-factory.interface";
import {CknSsrBase} from "./ckn-ssr-base";
import {NullableString} from "../../../../types/nullable-types";
import {BookingModel} from "../../../booking/models/booking.model";
import {SsrConfiguration} from "../../../configuration/objects/ssrs/ssr.configuration";
import {IBookingViewModel} from "../../../booking/models/booking-view-model.interface";
import {IJourneyViewModel} from "../../../booking/models/journey/journey-view-model.interface";
import React from "react";
import {CheckInOnlineSmallIcon} from "./check-in-online.small-icon";
import {SSR_STANDARD_LARGE_ICON_SIZE} from "../../ssr-types.service.interface";


export default class EcknSsrType extends CknSsrBase {
    constructor(services: IServiceFactory) {
        super('ECKN', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>);
    }

    private get ecknConfig(): SsrConfiguration {
        return this.services.configuration.ssrs.eckn;
    }

    private _getBookingBundleCode(booking: IBookingViewModel): NullableString {
        const bundleCode = booking.unfilteredJourneys.filter(j => j.selectedBundle)[0]?.selectedBundle?.bundleCode;
        if(!bundleCode) {
            return null;
        }

        return bundleCode;
    }

    getPurchaseBlockingMessage(journey: IJourneyViewModel): NullableString {
        const bundleCode = journey.selectedBundle?.bundleCode;
        if(!bundleCode) {
            return null;
        }

        if(this.ecknConfig.canBePurchasedSeparately(journey.booking.bookingCreationDate, journey.designator.departureDate, bundleCode)) {
            return null;
        }

        const minTimeBeforeFlight = this.ecknConfig.getMinimumTimeToPurchaseBeforeFlight(journey.booking.bookingCreationDate, bundleCode);
        if(minTimeBeforeFlight) {
            return this._getCannotPurchaseMessage(minTimeBeforeFlight);
        }

        return null;
    }

    private _canBeShown(booking: BookingModel): boolean {
        if(!this.services.configuration.data.enableEckn) {
            return false;
        }
        // This is for the case when the user chooses ABAS bundle and then latter in the Extras page he buys ECKN.
        // Then he goes back to bundle selection and switch to CBIZ for example.
        // Now when he reach again the Extras page we don't want to hide the Early Online Check-in box
        // because we must give him the chance to remove the acquired ECKN since the CBIZ bundle has included the early check-in
        if(booking.getSoldSsrsCount(this) > 0) {
            return true;
        }

        return booking.filteredJourneys.some(j => this.ecknConfig.isInTheCutOffPeriod(booking.bookingCreationDate, j.selectedBundle?.bundleCode));
    }

    canBeShownOnCheckInFlow(booking: BookingModel): boolean {
        return false;
    }

    canBeShownOnBookingFlow(booking: BookingModel): boolean {
        return this._canBeShown(booking);
    }

    canBeShownOnManageMyBookingFlow(booking: BookingModel): boolean {
        return this._canBeShown(booking);
    }

    get details(): NullableString {

        if(!this.services.booking.isThereABookingInProgress) {
            return null;
        }

        const booking = this.services.booking.current;

        const bundleCode =  this._getBookingBundleCode(booking);
        if(!bundleCode) {
            return null;
        }

        let earliestCheckInBeforeFlight = this.ecknConfig.getMaximumTimeToUseBeforeFlight(booking.bookingCreationDate, bundleCode);

        if(earliestCheckInBeforeFlight) {
            return this.services.ssrTypes.translationForSsr(this.detailsTranslationKey).withParams({time: earliestCheckInBeforeFlight.toUserFriendlyString(this.services.language)});
        }

        return null;
    }
}
