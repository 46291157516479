import {DotRezGraphQLMutationBuilder} from "../dot-rez-query-builder";

export function cancelJourneyGraphqlMutation(journeyKeys: string[], builder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    journeyKeys.forEach((journeyKey, index) => {
           let variableName = "journeyKey" + index.toString();
            builder.addMutation(`
                mutation cancelJourney${index}($${variableName}: String) {
                    journeyDelete(journeyKey: $${variableName})
                }`,
        {
            name: variableName,
            value: journeyKey
        });
    });

    return builder;
}
