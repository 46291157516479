import {IServiceFactory} from "../../../service-factory.interface";
import {IBlueBenefitDiscountInfo, IBlueBenefitsPackage} from "./blue-benefits-package.interface";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {Price} from "../../../currency/price";
import {
    IBlueBenefitsPackageConfiguration,
    IBlueBenefitsSsrDiscountPeriodConfiguration
} from "../../../configuration/objects/blue-benefits-configuration.interface";
import {TimePeriod} from "../../../../types/time-period";
import {computed, makeObservable} from "mobx";

export class BlueBenefitsPackage implements IBlueBenefitsPackage {
    constructor(public readonly packageCode: string,
                private readonly _packageConfiguration: IBlueBenefitsPackageConfiguration,
                protected services: IServiceFactory) {
        makeObservable(this, {
            ssrType: computed
        });
    }

    get ssrType(): ISsrType {
        return this.services.ssrTypes.getSsrType(this._packageConfiguration.ssrCode);
    }

    get name(): string {
        //TODO - the names should come from configs but for the moment in config the name is the same as the package id
        const packageName = this._packageConfiguration.name;
        switch (packageName) {
            case 'rainbow1and1':
                return "Blue Benefits Together";
            case 'rainbow1and3':
                return "Blue Benefits Family";
            case 'rainbow1and8':
                return "Blue Benefits Friends";
        }

        return packageName;
    }

    get shortName(): string {
        return this.name.replace('Blue Benefits', '').trim();
    }

    get price(): Price {
         return this.services.currency.createPrice(this._packageConfiguration.price, this._packageConfiguration.priceCurrency);
    }

    get maxNumberOfPassengers(): number {
        return this._packageConfiguration.maxNumberOfPassengers;
    }

    get partnersCountDescription(): string {
        const numberOfTravelPartners = this.maxNumberOfPassengers - 1;
        if(numberOfTravelPartners > 1) {
            return this.services.language.translationFor("1 holder + {numberOfPartners} travel partners").withParams({
                numberOfPartners: numberOfTravelPartners
            });
        } else {
            return this.services.language.translate("1 holder + 1 travel partner");
        }
    }
    getDiscountsInfo(): IBlueBenefitDiscountInfo[] {

        const discounts: IBlueBenefitDiscountInfo[] = [];

        discounts.push({
            value: this.services.currency.createPrice(12, 'EUR').toString(),
            description: this.services.language.translate('Minimum discount on ticket fares')
        });

        return [
            ...discounts,
            ...this._getSsrBenefits()
        ];
    }

    private _getSsrBenefits(): IBlueBenefitDiscountInfo[] {
        const benefits: IBlueBenefitDiscountInfo[] = [];
        for(let ssrCode of Object.keys(this._packageConfiguration.ssrsDiscountPeriods)) {
            const discountPeriod = this._getSsrDiscountPeriod(ssrCode);

            if(discountPeriod) {
                let value = "";
                switch (discountPeriod?.discountType) {
                    case "amount":
                        value = this.services.currency.createPrice(discountPeriod.discountValue, discountPeriod.currency).toString();
                        break;
                    case "percent":
                        value = `${discountPeriod.discountValue}%`;
                        break;
                }

                if(value) {
                    benefits.push({
                        value: value,
                        description: this.services.language.translationFor('Discount on {ssrName}').withParams({
                            ssrName: this.services.ssrTypes.getSsrType(ssrCode).name
                        })
                    });
                }
            }
        }
        return benefits;
    }

    private _getSsrDiscountPeriod(ssrCode: string): IBlueBenefitsSsrDiscountPeriodConfiguration | null | undefined {
         const periods = this._packageConfiguration.ssrsDiscountPeriods[ssrCode];
         if(!periods) {
             return null;
         }
         const time = this.services.time;

         return periods.find(p => new TimePeriod( time.parseIsoDate(p.startDate), time.parseIsoDate(p.endDate)).contains(time.currentDate));
    }


    private _computeSsrStandardPriceForAmountStrategy(discountedPrice: Price, discountValue: number, currency: string): Price {
        const discountAmountInPriceCurrency = this.services.currency.convertAmount(currency, discountedPrice.currency, discountValue);
        return discountedPrice.sum(discountAmountInPriceCurrency);
    }

    private _computeSsrStandardPriceForPercentStrategy(discountedPrice: Price, percentage: number): Price {
        const amount = Math.round((discountedPrice.amount / (1 - percentage/100)) * 100)/100;

        return this.services.currency.createPrice(amount, discountedPrice.currency);
    }


    computeSsrStandardPrice(ssrType: ISsrType, discountedPrice: Price): Price {
        try {

            const discountPeriod = this._getSsrDiscountPeriod(ssrType.ssrCode);
            if(!discountPeriod) {
                return discountedPrice;
            }

            switch (discountPeriod.discountType) {
                case "amount":
                    return this._computeSsrStandardPriceForAmountStrategy(discountedPrice, discountPeriod.discountValue, discountPeriod.currency);
                case "percent":
                    return this._computeSsrStandardPriceForPercentStrategy(discountedPrice, discountPeriod.discountValue);
                default:
                    this.services.logger.error(`There is no discountType for ${ssrType.ssrCode} in ${this.packageCode}`);
                    return discountedPrice;

            }
        } catch (err) {
            this.services.logger.error(`Failed to computed blue benefits standard price for ${ssrType.ssrCode}`, err);
            return discountedPrice;
        }

    }


}
