import {LocalStorageKeys} from "../../../storage/local-storage-keys";
import {BookingHistoryModel} from "../../models/booking-history.model";
import {IPersistedBookingHistory} from "../../models/persisted-booking-history.interface";
import {BookingHistoryStrategyBase} from "../booking-history-strategy-base";
import {IBookingHistoryStrategy} from "../booking-history-strategy.interface";
import {BoardingPassesStorage} from "../../models/boarding-passes-storage";
import {
    IDotRezBookingData,
    IDotRezContact
} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IDotRezBookingSession} from "../../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {CustomerContactTypes} from "../../../booking/models/contacts/customer-contact-types";
import {NotificationPreferenceEnum} from "../../../dot-rez-api/data-contracts/enums/notification-preference.enum";
import {DistributionOptionsEnum} from "../../../dot-rez-api/data-contracts/enums/distribution-options.enum";
import {PhoneTypeEnum} from "../../../dot-rez-api/data-contracts/enums/phone-type.enum";
import {runInAction} from "mobx";
const MY_TRIPS_STORAGE_KEY_PREFIX: LocalStorageKeys = 'authorizedUser.myTrips';
const MY_BOARDING_PASSES_STORAGE_KEY_PREFIX: LocalStorageKeys = 'authorizedUser.myBoardingPasses';
const ATTACHED_BOOKINGS_STORAGE_KEY_PREFIX: LocalStorageKeys = 'authorizedUser.attachedBookings';


export class AuthorizedUserBookingHistoryStrategy extends BookingHistoryStrategyBase implements IBookingHistoryStrategy {

    protected _getTripsStorageKey(): string {
        return `${MY_TRIPS_STORAGE_KEY_PREFIX}.${this.services.user.profile.customerNumber}`;
    }

    private _getAttachedBookingsStorageKey(): string {
        return `${ATTACHED_BOOKINGS_STORAGE_KEY_PREFIX}.${this.services.user.profile.customerNumber}`;
    }

    protected _createBoardingPassesStorage(): BoardingPassesStorage {
        return new BoardingPassesStorage(this.services,`${MY_BOARDING_PASSES_STORAGE_KEY_PREFIX}.${this.services.user.profile.customerNumber}`);
    }

    protected _loadMyTripsFromLocalStorage() {
        this.services.user.profile.waitForProfileInitialization().then(() => {

            let persistedTrips = this.services.localStorage.getJson<IPersistedBookingHistory[]>(this._getTripsStorageKey()) || [];
            let bookingHistoryRecords = persistedTrips.map(bookingInfo => new BookingHistoryModel(bookingInfo, this.services));

            bookingHistoryRecords = [
                ...bookingHistoryRecords,
                ...this._readAttachedBookingsModels()
            ];

            this._setMyTrips(bookingHistoryRecords);
        });
    }

    protected async _syncTrips(): Promise<void> {
        try {
            await this.services.user.profile.waitForProfileInitialization();
            const userSession = await this.services.user.getSession();
            const bookings = (await userSession.getBookingsHistory()).map(booking => new BookingHistoryModel(booking, this.services));
            const attachedBookings = this._readAttachedBookingsModels();

            this._setMyTrips([
                ...bookings,
                ...attachedBookings
            ]);
        } finally {
            this._addAnonymousBookingsToMyAccount().catch(err => this.services.logger.error(`Failed to add anonymous bookings to user account ${this.services.user.profile.customerNumber}`, err));
        }
    }

    private _readAttachedBookingsModels(): BookingHistoryModel[] {
        return this._readAttachedBookings().map(tripInfo => new BookingHistoryModel(tripInfo, this.services))
    }

    protected async _onAfterBookingRetrieved(bookingData: IDotRezBookingData, session: IDotRezBookingSession): Promise<void> {
        await super._onAfterBookingRetrieved(bookingData, session);
        await this._addBookingToMyAccount(bookingData, session);
    }

    private async _addBookingToMyAccount(bookingData: IDotRezBookingData, session: IDotRezBookingSession): Promise<void> {
        if(this._bookingBelongsToCurrentUser(bookingData)) {
            return;
        }

        const fields = this.services.user.profile.getEditor().fields;

        const existingContactBookingWithoutCustomerNumber = bookingData.contacts.find(c => !c.value.customerNumber
                                                                                           && c.value.name.first === fields.firstName.value
                                                                                           && c.value.name.last === fields.lastName.value )?.value;
        if(existingContactBookingWithoutCustomerNumber) {
            try {
                existingContactBookingWithoutCustomerNumber.customerNumber = this.services.user.profile.customerNumber;
                await session.updateBookingContact(existingContactBookingWithoutCustomerNumber);
                await this._commitBooking(session);
                return;
            } catch (err) {
                this.services.logger.error(`Failed to update booking contact customer number ${bookingData.recordLocator} | ${existingContactBookingWithoutCustomerNumber.customerNumber}`);
            }
        }

        const existingBookingContacts = bookingData.contacts.toDictionaryOfType(c => c.key, c => c.value);
        const availableContactType = CustomerContactTypes.find(ct => !existingBookingContacts[ct]);
        if(availableContactType) {
            try {
                const contactData = await this._addNewContactToBooking(availableContactType, session);
                runInAction(() => {
                    bookingData.contacts.push({key: contactData.contactTypeCode, value: contactData});
                });

            } catch (err) {
                this.services.logger.error(`Failed to user to booking contacts ${bookingData.recordLocator} | ${this.services.user.profile.customerNumber}`);
                this._attachBookingToHistory(bookingData);
            }
        } else {
            this._attachBookingToHistory(bookingData);
        }


    }

    private _bookingBelongsToCurrentUser(bookingData: IDotRezBookingData): boolean {
        const customerNumber = this.services.user.profile.customerNumber;
        if(bookingData.contacts.some(c => c.value.customerNumber === customerNumber)) {
            return true;
        }

        if(bookingData.passengers.some(p => p.value.customerNumber === customerNumber)) {
            return true;
        }

        return this._readAttachedBookings().some(b => b.recordLocator === bookingData.recordLocator);
    }

    private async _addNewContactToBooking(contactTypeCode: string, session: IDotRezBookingSession): Promise<IDotRezContact> {
        const userProfileEditor = this.services.user.profile.getEditor();
        const newContactData: IDotRezContact = {
            contactTypeCode: contactTypeCode,
            customerNumber: userProfileEditor.customerNumber,
            sourceOrganization: null,
            notificationPreference: NotificationPreferenceEnum.None,
            companyName: null,
            distributionOption: DistributionOptionsEnum.Email,
            cultureCode: this.services.language.currentLanguage,
            emailAddress: userProfileEditor.emailAddress,
            name: {
                first: userProfileEditor.fields.firstName.value,
                last: userProfileEditor.fields.lastName.value,
                title: this.services.user.profile.title
            }
        };

        if(userProfileEditor.fields.phoneNumber.value) {
            newContactData.phoneNumbers = [
                {
                    type: PhoneTypeEnum.Home,
                    number: userProfileEditor.fields.phoneNumber.value
                }
            ];
        }

        await session.addBookingContact(newContactData);
        await this._commitBooking(session);
        return newContactData;
    }

    private _readAttachedBookings(): IPersistedBookingHistory[] {
        return this.services.localStorage.getJson<IPersistedBookingHistory[]>(this._getAttachedBookingsStorageKey()) || [];
    }

    private _saveAttachedBookings(attachedBookings: IPersistedBookingHistory[]): void  {
        this.services.localStorage.setJson(this._getAttachedBookingsStorageKey(), attachedBookings);
    }

    private _attachBookingToHistory(bookingData: IDotRezBookingData): void {
        const tripInfo = this._convertBookingDataToPersistedHistoryData(bookingData);
        const attachedBookings = this._readAttachedBookings();
        attachedBookings.push(tripInfo);
        this._saveAttachedBookings(attachedBookings);
    }

    private async _addAnonymousBookingsToMyAccount(): Promise<void> {
        const anonymousTripsInfo = this.services.localStorage.getJson<IPersistedBookingHistory[]>(this._getAnonymousTripsStorageKey()) || [];
        if(anonymousTripsInfo.length === 0) {
            return;
        }

        const promises: Promise<BookingHistoryModel | null>[] = [];

        for(let tripInfo of anonymousTripsInfo) {
            promises.push(this._addOneAnonymousBookingToMyAccount(tripInfo));
        }

        const addedBookings: BookingHistoryModel[] = [];

        for(let b of (await Promise.all(promises))) {
            if(b) {
                addedBookings.push(b);
            }
        }

        this._appendToMyTrips(addedBookings);

        this.services.localStorage.removeItem(this._getAnonymousTripsStorageKey());
    }

    private async _addOneAnonymousBookingToMyAccount(tripInfo: IPersistedBookingHistory): Promise<BookingHistoryModel | null> {
        try {
            const session = await this.services.user.createTransientBookingSession();
            const bookingData = await this._bringBookingIntoState(tripInfo, session);
            if(this._bookingBelongsToCurrentUser(bookingData)) {
                return null;
            }
            await this._addBookingToMyAccount(bookingData, session);
            const bookingHistoryModel = new BookingHistoryModel(this._convertBookingDataToPersistedHistoryData(bookingData), this.services);

            if(bookingHistoryModel.hasFutureFlights) {
                //no need to await here
                bookingHistoryModel.refreshBookingData()
                                   .catch(err => this.services.logger.error(`Failed to refresh anonymous booking added added to user account ${bookingData.recordLocator}`, err));
            }
            return bookingHistoryModel;
        } catch (err) {
            return null;
        }
    }

    private async _bringBookingIntoState(tripInfo: IPersistedBookingHistory, session: IDotRezBookingSession): Promise<IDotRezBookingData> {
        if(tripInfo.email) {
            try {
                return await session.bringBookingInStateByEmail({
                    recordLocator: tripInfo.recordLocator,
                    emailAddress: tripInfo.email
                });
            } catch (err) {
                return await session.bringBookingInStateByLastName({
                    recordLocator: tripInfo.recordLocator,
                    lastName: tripInfo.lastName
                });
            }
        } else {
            return await session.bringBookingInStateByLastName({
                recordLocator: tripInfo.recordLocator,
                lastName: tripInfo.lastName
            });
        }
    }

    private async _commitBooking(session: IDotRezBookingSession): Promise<void> {
        await session.commitBooking({
            notifyContacts: false,
            channel: this.services.booking.getCommitBookingChannel()
        });
    }
}
