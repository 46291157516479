import {NullableUndefinedPrice} from "../../currency/price";

export interface IRoundTripLowFareReader {
    getDepartureLowFare(date: Date): ILowFareResult;
    getReturnLowFare(date: Date): ILowFareResult;
    getDepartureLowFareAsync(date: Date): Promise<ILowFareResult>
    getReturnLowFareAsync(date: Date): Promise<ILowFareResult>;
}

export interface IOneWayLowFareReader {
    getLowFare(date: Date): ILowFareResult;
    getLowFareAsync(date: Date): Promise<ILowFareResult>
}

export enum LowFareStatusEnum {
    NoFare,
    Reading,
    Loaded,
    SoldOut,
    Error,
    Disabled
}

export interface ILowFareResult {
    status: LowFareStatusEnum;
    price?: NullableUndefinedPrice;
}
