import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import { IJourneyViewModel } from "../../../../../services/booking/models/journey/journey-view-model.interface";
import { FlightItineraryAccordionHeaderComponent } from "../flight-itinerary-accordion-header.component";
import { StarCircleFilledIcon } from "../../../../../assets/svg/star-circle-filled.icon";
import styled from "styled-components";

const FlightItineraryExtrasHeaderComponentBox = styled.div`
    display: flex;
`;

interface FlightItineraryExtrasHeaderComponentProps extends PropsWithChildren {
    journey: IJourneyViewModel;
    title: string | React.ReactElement;
}

export const FlightItineraryExtrasHeaderComponent: React.FC<FlightItineraryExtrasHeaderComponentProps> = observer((props) => {
    return (
        <FlightItineraryExtrasHeaderComponentBox>
            <FlightItineraryAccordionHeaderComponent 
            renderIcon={() => <StarCircleFilledIcon />}
            title={props.title} />
        </FlightItineraryExtrasHeaderComponentBox>
    )
});
