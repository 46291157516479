import React from "react";
import {observer} from "mobx-react";
import {NullableString} from "../../types/nullable-types";
import styled from "styled-components";
import { ExclamationTriangle } from "../../assets/svg/exclamation-triangle.icon";
import { useServices } from "../../hooks/use-services.hook";

const ErrorMessageBox = styled.div`
    color: ${props => props.theme.colors.danger};
    font-size: ${props => props.theme.fontSize.medium};
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 8px 0 12px;
    svg {
        margin-right: 4px;
    }
`


const ErrorMessageTitleBox = styled.div`
    line-height: 1;
`

interface ErrorMessageTextBoxComponentProps {
    errorMessage: NullableString;
}

export const ErrorMessageTextBoxComponent: React.FC<ErrorMessageTextBoxComponentProps> = observer((props) => {
    const services = useServices();
    if(!props.errorMessage) {
        return null;
    }
    return (
        <ErrorMessageBox>
            <ExclamationTriangle size={2} color={services.theme.currentTheme.colors.danger}/>
            <ErrorMessageTitleBox>{props.errorMessage}</ErrorMessageTitleBox>
        </ErrorMessageBox>
    )
});
