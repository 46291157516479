import {
    ILowFareResult,
    IRoundTripLowFareReader
} from "../../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {Price} from "../../../../currency/price";

export class DisruptedFlightsChangeLowFareReaderDecorator implements IRoundTripLowFareReader {
    constructor(private readonly getInnerLowFareReader: () => IRoundTripLowFareReader,
                private readonly computeDeparturePriceDifference: (price: Price) => Price,
                private readonly computeReturnPriceDifference: (price: Price) => Price) {
    }


    getDepartureLowFare(date: Date): ILowFareResult {
        const result = this.getInnerLowFareReader().getDepartureLowFare(date);
        if(result.price) {
            return {
                price: this.computeDeparturePriceDifference(result.price),
                status: result.status
            };
        }

        return result;
    }

    getReturnLowFare(date: Date): ILowFareResult {
        const result = this.getInnerLowFareReader().getReturnLowFare(date);
        if(result.price) {

            return {
                price: this.computeReturnPriceDifference(result.price),
                status: result.status
            };
        }

        return result;
    }


    async getDepartureLowFareAsync(date: Date): Promise<ILowFareResult> {
        const result = await this.getInnerLowFareReader().getDepartureLowFareAsync(date);
        if(result.price) {
            return {
                price: this.computeDeparturePriceDifference(result.price),
                status: result.status
            };
        }

        return result;
    }
    async getReturnLowFareAsync(date: Date): Promise<ILowFareResult> {
        const result = await this.getInnerLowFareReader().getReturnLowFareAsync(date);
        if(result.price) {

            return {
                price: this.computeReturnPriceDifference(result.price),
                status: result.status
            };
        }

        return result;
    }
}
