import React from "react";
import {observer} from "mobx-react";
import {SearchMaskWebappComponent} from "./components/search-mask/webapp/search-mask.webapp.component";
import {StandardPageHeaderLargeScreenComponent} from "../../basic-components/page/standard-page-header/standard-page-header.large-screen.component";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import styled from "styled-components";
import {CardBox} from "../../basic-components/card/card.box";
import {useServices} from "../../hooks/use-services.hook";
import {AirlineCompanyInfoComponent} from "../../components/airline-company-info/airline-company-info.component";
import {
    StandardPageFooterComponent
} from "../../basic-components/page/standard-page-footer/standard-page-footer.component";

const StandardPageContentBox = styled(StandardPageContentComponent)`
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
`

const SearchMaskCardBox = styled(CardBox)`
    margin-top: ${props => props.theme.spacing.extraLarge};
    width: 100%;
`

const PageContentBox = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    
    background-image: url("${props => props.theme.assets.images.booking_search_background_jpg.toString()}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
   
    padding: 0 15%;
    @media ${(props) => props.theme.mediaQuery.lAndBelow} {
        padding: 0 7%;
    }
    
    @media ${(props) => props.theme.mediaQuery.mAndBelow} {
        padding: 0 3%;
    }
    
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
        padding: var(--page-content-padding);
    }
`


export const HomeWebappPage: React.FC = observer(() => {
    const services = useServices();
    const renderContent = () => {
        if(services.mediaQuery.sAndBelow) {
            return (
                <StandardPageContentComponent>
                    <SearchMaskWebappComponent/>
                </StandardPageContentComponent>
            )
        } else {
            return (
                <StandardPageContentBox>
                    <PageContentBox>
                        <SearchMaskCardBox>
                            <SearchMaskWebappComponent/>
                        </SearchMaskCardBox>
                    </PageContentBox>
                </StandardPageContentBox>

            );
        }
    }

    return (
        <RoutedPageComponent disableSwipeNavigation={true}>
            <StandardPageHeaderLargeScreenComponent hideBackButton={true}/>
            {renderContent()}
            <StandardPageFooterComponent>
                <AirlineCompanyInfoComponent/>
            </StandardPageFooterComponent>
        </RoutedPageComponent>
    );
});
