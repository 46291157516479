import styled from "styled-components";

export const ManageMyBookingEditButtonBox = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fontSize.large};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 ${props => props.theme.spacing.medium};

  svg {
      margin-left: ${props => props.theme.spacing.small};
  }
`
