import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IonHeader, IonToolbar} from "@ionic/react";
import {useServices} from "../../../../hooks/use-services.hook";
import {UserAccountIconComponent} from "../user-account-icon/user-account-icon.component";
import {AirlineHomePageSmallLogoIcon} from "../../../../assets/svg/airline-home-page-small-logo.icon";
import {TestModeMenuComponent} from "../../../../basic-components/test-mode/test-mode-menu.component";
import {CallCenterPhoneComponent} from "../../../../components/call-center-phone/call-center-phone.component";

const HomePageHeaderBox = styled(IonHeader)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("${props => props.theme.assets.images.home_page_header_background_image_1920x170_png.toString()}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 320px;
    width: 100%;
    @media ${(props) => props.theme.mediaQuery.lAndBelow} {
      background-image: url("${props => props.theme.assets.images.home_page_header_background_image_1024x240_png.toString()}");
      min-height: 240px;
    }

    @media ${(props) => props.theme.mediaQuery.mAndBelow} {
      background-image: url("${props => props.theme.assets.images.home_page_header_background_image_png.toString()}");
      min-height: 240px;
    }
  
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      background-image: url("${props => props.theme.assets.images.home_page_header_background_image_png.toString()}");
      min-height: 180px;
    }
    
`

const TopSectionBox = styled(IonToolbar)`
    --padding-top: ${props => props.theme.spacing.large};
    --padding-bottom: ${props => props.theme.spacing.large};
    --padding-start: ${props => props.theme.spacing.extraLarge};
    --padding-end: ${props => props.theme.spacing.extraLarge};
    --border-style: none;
    --background: transparent;
    
`

const TopSectionContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    line-height: 1;    
`

const HomePageHeaderTitleBox = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: ${props => props.theme.spacing.spacePx(11)} 0 0 0;
    padding: 0 ${props => props.theme.spacing.small} ${props => props.theme.spacing.small} ${props => props.theme.spacing.small};
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-align: center;
    line-height: 1;
    min-height: 50px;
`

const TopLeftSectionContainerBox = styled.div`
`

const TopRightSectionContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: ${props => props.theme.spacing.medium};
`


const TopRightButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

interface HomePageHeaderComponentProps extends PropsWithChildren {
    title: string | React.ReactElement;
}
export const HomePageHeaderComponent: React.FC<HomePageHeaderComponentProps> = observer((props) => {
    const services = useServices();


    return (
        <HomePageHeaderBox>
            <TopSectionBox>
                <TopSectionContentBox>
                    <TopLeftSectionContainerBox onClick={() => services.homePage.activateBookTab()}>
                        <AirlineHomePageSmallLogoIcon/>
                    </TopLeftSectionContainerBox>
                    <TopRightSectionContainerBox>
                        <TopRightButtonsContainerBox>
                            <UserAccountIconComponent/>
                            <TestModeMenuComponent/>
                        </TopRightButtonsContainerBox>
                        <CallCenterPhoneComponent/>
                    </TopRightSectionContainerBox>
                </TopSectionContentBox>
            </TopSectionBox>
            <HomePageHeaderTitleBox>
                {props.title}
            </HomePageHeaderTitleBox>
            {props.children}
        </HomePageHeaderBox>
    )
});
