import {IServiceFactory} from "../service-factory.interface";
import {IDeepLinksService} from "./deep-links.service.interface";
import {reaction} from "mobx";
import {ServiceBase} from "../service-base";
import { App } from '@capacitor/app';
import {IDeepLinkHandler} from "./deep-link-handler.interface";
import {ConfirmRegistrationDeepLinkHandler} from "./handlers/confirm-registration-deep-link.handler";
import {ResetPasswordDeepLinkHandler} from "./handlers/reset-password-deep-link.handler";

export class DeepLinksService extends ServiceBase implements IDeepLinksService {

    private readonly _linkHandlers: IDeepLinkHandler[];

    constructor(services: IServiceFactory) {
        super(services);

        this._linkHandlers = [
            new ConfirmRegistrationDeepLinkHandler(services),
            new ResetPasswordDeepLinkHandler(services)
        ];

        App.addListener('appUrlOpen', data => {
            return this._handleDeepLink(data.url);
        });

        reaction(() => this.services.isLoading,
            async (isLoading) => {
                if (!isLoading && this._pendingDeepLinkHandler) {
                    // it means the application finish loading
                    // so we execute the pending deep link
                    await this._executeDeepLink(this._pendingDeepLinkHandler.handler, this._pendingDeepLinkHandler.link);
                }
            }, {
                fireImmediately: true
            });
    }


    get hasPendingDeepLink(): boolean {
        return Boolean(this._pendingDeepLinkHandler);
    }

    private _executeDeepLink(handler: IDeepLinkHandler, link: string): Promise<void> {
        return handler.execute(link).then(() => {
            this._pendingDeepLinkHandler = null
        }).catch((err) => {
            this._pendingDeepLinkHandler = null
            throw err;
        });
    }

    private _pendingDeepLinkHandler: {handler: IDeepLinkHandler; link: string} | null = null;
    private _handleDeepLink(link: string): Promise<void> {

        for(let handler of this._linkHandlers) {
            if(handler.canHandler(link)) {
                if(this.services.isLoading) {
                    //it means that the app is loading so we will wait for the application to finish loading
                    //then we execute the handler
                    this._pendingDeepLinkHandler = {
                        handler: handler,
                        link: link
                    };
                    return Promise.resolve();
                } else {
                    return this._executeDeepLink(handler, link);
                }

            }
        }

        return Promise.resolve();
    }
}
