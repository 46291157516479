import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const CalendarIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.6} {...props} viewBox="0 0 17.756 17.756" ratio={17.756/17.756}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(-113.75 -65.122)"><path d="M15.676,1.387h-.832V0H13.456V1.387H4.3V0H2.913V1.387H2.081A2.083,2.083,0,0,0,0,3.468V15.676a2.083,2.083,0,0,0,2.081,2.081H15.676a2.083,2.083,0,0,0,2.081-2.081V3.468A2.083,2.083,0,0,0,15.676,1.387Zm.694,14.288a.694.694,0,0,1-.694.694H2.081a.694.694,0,0,1-.694-.694V6.52H16.369Zm0-10.543H1.387V3.468a.694.694,0,0,1,.694-.694h.832V4.162H4.3V2.774h9.156V4.162h1.387V2.774h.832a.694.694,0,0,1,.694.694Z" transform="translate(113.75 65.122)" /><rect width="1.313" height="1.313" transform="translate(116.719 73.344)" /><rect width="1.313" height="1.313" transform="translate(119.345 73.344)" /><rect width="1.313" height="1.313" transform="translate(121.972 73.344)" /><rect width="1.313" height="1.313" transform="translate(124.598 73.344)" /><rect width="1.313" height="1.313" transform="translate(127.224 73.344)" /><rect width="1.313" height="1.313" transform="translate(116.719 75.97)" /><rect width="1.313" height="1.313" transform="translate(119.345 75.97)" /><rect width="1.313" height="1.313" transform="translate(121.972 75.97)" /><rect width="1.313" height="1.313" transform="translate(124.598 75.97)" /><rect width="1.313" height="1.313" transform="translate(116.719 78.596)" /><rect width="1.313" height="1.313" transform="translate(119.345 78.596)" /><rect width="1.313" height="1.313" transform="translate(121.972 78.596)" /><rect width="1.313" height="1.313" transform="translate(124.598 78.596)" /><rect width="1.313" height="1.313" transform="translate(127.224 75.97)" /></g>
        </SvgIcon>
    )
}
