import {makeObservable, observable, runInAction} from "mobx";
import {IBookingMutationAction} from "./booking-mutation-action.interface";
import {BookingModel} from "../booking.model";
import {
    IDotRezPartialBookingSessionData
} from "../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";

export abstract class MutationsActionsAggregator {
    constructor(protected readonly booking: BookingModel) {
        makeObservable<this, '_inProgressMutations'>(this, {
            _inProgressMutations: observable
        });
    }

    protected abstract _executeMutation(mutation: IBookingMutationAction): Promise<IDotRezPartialBookingSessionData>;

    protected _inProgressMutations: IBookingMutationAction[] = [];
    private _waitForMutationsPromise = Promise.resolve({});
    private _waitForMutationsPromiseResolver: ((value: IDotRezPartialBookingSessionData | PromiseLike<IDotRezPartialBookingSessionData>) => void) = () => {};

    get isMutationInProgress(): boolean {
        return this._inProgressMutations.length > 0;
    }

    waitForMutations(): Promise<IDotRezPartialBookingSessionData> {
        return this._waitForMutationsPromise;
    }

    startMutation(actions: IBookingMutationAction): void {
        runInAction(() => {
            this._inProgressMutations.push(actions);
        });

        if(!this._mutationsTimer) {
            this._accumulatedBookingSessionData = {};
            this._waitForMutationsPromise = new Promise<IDotRezPartialBookingSessionData>((resolve) => {
                this._waitForMutationsPromiseResolver = resolve;
            });
            this._triggerSelling();
        }
    }

    private _accumulatedBookingSessionData: IDotRezPartialBookingSessionData = {};
    private _mutationsTimer: any = null;
    private _triggerSelling(): void {
        this._mutationsTimer = setTimeout(async () => {
            const bookingSessionData = await this._executeCurrentMutation();
            this._accumulatedBookingSessionData = {
                ...this._accumulatedBookingSessionData,
                ...bookingSessionData
            };
            if(this._inProgressMutations.length > 0) {
                this._triggerSelling();
            } else {
                this._mutationsTimer = null;
                this._waitForMutationsPromiseResolver(this._accumulatedBookingSessionData);
            }
        });
    }

    private async _executeCurrentMutation(): Promise<IDotRezPartialBookingSessionData> {
        const mutation = this._inProgressMutations[0];
        try {

            return await this._executeMutation(mutation);

        } catch (err) {
            this.booking.services.logger.error(`Selling action failed ${mutation.constructor.name}`);
            mutation.onError(err);
        } finally {
            runInAction(() => {
                this._inProgressMutations.splice(0, 1);
            });
        }
        return {};
    }
}
