import {BookingModel} from "../../models/booking.model";

export async function applySpecialPriceMarketDiscount(booking: BookingModel): Promise<void> {
    if(booking.needsToApplySpecialPriceMarketDiscount) {
        await booking.services.loadingIndicator.execute({
            action: async () => {
                await booking.applySpecialPriceMarketDiscount();
            }
        });
    }
}
