import React from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {BookingSearchComponent} from "../booking-search/booking-search.component";
import styled from 'styled-components';

const CheckInTabComponentBox = styled.div`
`

export const CheckInTabComponent: React.FC = observer(() => {
    const services = useServices();


    return (
        <CheckInTabComponentBox>
            <BookingSearchComponent retrieveBookingButtonText={services.language.translate('Start check-in')}
                                    onRetrieveBookingButtonClick={(searchParams) => searchParams.startCheckIn()}/>
        </CheckInTabComponentBox>
    );
});
