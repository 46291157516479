import React from "react";
import {useServices} from "../../hooks/use-services.hook";
import {IonIcon, IonSpinner} from "@ionic/react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PriceComponent} from "../price/price.component";
import {ShoppingCartDialogComponent} from "./shopping-cart-dialog.component";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { IDialogConfirmationHandler } from "../../services/dialog/dialog.service.interface";
import {ShoppingCartNotificationsComponent} from "./shopping-cart-notifications.component";


const ShoppingCartButtonContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
`

const PriceButtonBox = styled.div<{isDisplayedInHeader?: boolean}>`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0;
    height: auto;
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.isDisplayedInHeader ? props.theme.colors.primaryContrast : props.theme.colors.primary};
    
`

const ArrowIcon = styled(IonIcon)`
    width: 20px;
    height: 20px;
    font-size: 18px;
    margin-right: ${props => props.theme.spacing.small};
`

const SpinnerBox = styled(IonSpinner)`
    width: 20px;
    height: 20px;
    margin-right: ${props => props.theme.spacing.small};
`


interface ShoppingCartButtonComponentProps {
    isDisplayedInHeader?: boolean;
    modalHandler?: IDialogConfirmationHandler;
    hideExpandButton?: boolean;
}

export const ShoppingCartButtonComponent: React.FC<ShoppingCartButtonComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;



    const shouldAllowExpand = !Boolean(props.hideExpandButton);

    const openShoppingCartDialog = async () => {
        if(!shouldAllowExpand || booking.mutationsManager.isBookingTotalUpdateInProgress){
            return;
        }

        if(props.isDisplayedInHeader && props.modalHandler){
            props.modalHandler.reject();
        }else{
            await services.dialog.showStandardDialog({
                render: (modalHandler) => <ShoppingCartDialogComponent dialogHandler={modalHandler}/>
            });
        }
    }

    const renderArrow = () => {
        if(booking.mutationsManager.isBookingTotalUpdateInProgress) { // this one must be before shouldAllowExpand otherwise the component will react to isMutationInProgress only if shouldAllowExpand is true
            return (<SpinnerBox/>);
        }

        if(!shouldAllowExpand){
            return null;
        }

        return props.isDisplayedInHeader
            ? <ArrowIcon icon={chevronDownOutline}/>
            : <ArrowIcon icon={chevronUpOutline}/>
    }


    return (
        <ShoppingCartButtonContainerBox>
            <ShoppingCartNotificationsComponent/>
            <PriceButtonBox isDisplayedInHeader={props.isDisplayedInHeader}
                              onClick={openShoppingCartDialog}
                              className={'tst-shopping-cart-button'}>
                {renderArrow()}
                <PriceComponent price={booking.balanceDue}
                                label={services.language.translate('Total')}/>
            </PriceButtonBox>
        </ShoppingCartButtonContainerBox>
    );
});
