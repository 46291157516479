import styled, {css} from "styled-components";
import {IonIcon} from "@ionic/react";
import React from "react";
import {addCircleOutline, removeCircleOutline} from "ionicons/icons";
import {observer} from "mobx-react-lite";

const ButtonBox = styled.div<{disabled: boolean}>`
    display: flex;
    align-items: center;
  
    ${
        props => props.disabled 
                ? css`
                  cursor: not-allowed;
                  opacity: 0.5;
                  color: ${props => props.theme.colors.medium};
                `
                : css`
                  cursor: pointer;
                  opacity: 1;
                  color: ${props => props.theme.colors.primary};
                `
    }
`

const IconBox = styled(IonIcon)`
  width: 26px;
  height: 26px;
`

interface SsrChangeButtonComponentProps {
    icon: string;
    disabled: boolean;
    onClick: () => void;
    className?: string;
}

const SsrChangeButtonComponent: React.FC<SsrChangeButtonComponentProps> = observer((props) => {
    return (
        <ButtonBox className={props.className}
                   onClick={props.onClick}
                   disabled={props.disabled}>
            <IconBox icon={props.icon}/>
        </ButtonBox>
    );
})

interface SsrChangeConcreteButtonComponentProps extends Omit<SsrChangeButtonComponentProps, 'icon'> {
}

export const SsrDecrementButtonComponent: React.FC<SsrChangeConcreteButtonComponentProps> = observer((props) => {
    return (
        <SsrChangeButtonComponent {...props} icon={removeCircleOutline}/>
    );
})


export const SsrIncrementButtonComponent: React.FC<SsrChangeConcreteButtonComponentProps> = observer((props) => {

    return (
        <SsrChangeButtonComponent {...props} icon={addCircleOutline}/>
    );

})
