import React from "react";
import {BlueBenefitsSsr} from "./blue-benefits-ssr";
import {IServiceFactory} from "../../../service-factory.interface";
import {Pas1SsrLargeIcon} from "./pas1-ssr.large-icon";

export default class Pas1Ssr extends BlueBenefitsSsr {
    constructor(services: IServiceFactory) {
        super('PAS1', services);
    }

    get largeIcon(): React.ReactElement | null {
        return <Pas1SsrLargeIcon/>
    }
}
