import React from "react";
import { useServices } from "../../../../hooks/use-services.hook";
import styled from 'styled-components';
import { observer } from "mobx-react";
import { SwiperSlide } from 'swiper/react'
import { NextFlightComponent } from "./next-flight.component";
import { PlaneFilledIcon } from "../../../../assets/svg/plane-filled.icon";
import {SearchMaskBookNowButtonComponent} from "../../../../components/common/search-mask/search-mask-book-now-button.component";
import {SwiperComponent} from "../../../../basic-components/swiper/swiper.component";
import {Pagination} from "swiper";

export const NextFlightsBox = styled.div`
    padding-bottom: ${props => props.theme.spacing.small};
`


export const NextFlightsHeaderTitleBox = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.xxLarge};
    text-transform: uppercase;
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0 ${props => props.theme.spacing.medium};
    line-height: 1;
`

export const NextFlightIconBox = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.secondary};
`

const NextFlightDetailsBox = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 ${props => props.theme.spacing.large};
    justify-content: flex-start;
    align-items: center;
`


export const NextFlightsComponent: React.FC = observer(() => {
    const services = useServices();
    const nextFlights = services.bookingHistory.getNextFlights();

    if (nextFlights.length === 0) {
        return null;
    }

    const onBookNowClick = () => {
        services.flightSearch.steps.start();
    }

    const renderSearchButton = () => {
        if (services.maintenance.data.enableMaintenancePage) {
            return null;
        } else {
            return (<SearchMaskBookNowButtonComponent onClick={onBookNowClick}/>);
        }
    }

    return (<NextFlightsBox>
                <NextFlightDetailsBox>
                    <NextFlightIconBox>
                        <PlaneFilledIcon/>
                    </NextFlightIconBox>
                    <NextFlightsHeaderTitleBox>
                        {services.language.translate('Upcoming trips')}
                    </NextFlightsHeaderTitleBox>
                </NextFlightDetailsBox>
                <SwiperComponent className="tst-next-flights-swiper"
                                 modules={[Pagination]}
                                 pagination={true}
                                 slidesPerView={1}
                                 autoHeight={true}>
                    {nextFlights.map(nextFlight =>
                        <SwiperSlide key={nextFlight.uniqueKey} className="my-swiper-slide-box">
                            <NextFlightComponent key={nextFlight.uniqueKey} futureFlight={nextFlight}/>
                        </SwiperSlide>)}
                </SwiperComponent>
                {renderSearchButton()}
            </NextFlightsBox>);
})
