import {ISsrViewModel} from "./ssr-view-model.interface";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {NullablePrice, Price} from "../../../currency/price";
import {PassengerJourneySsrEditorModel} from "./passenger-journey-ssr-editor.model";
import {IJourneySsrsBucket} from "./journey-ssrs-bucket.interface";
import {NullableString} from "../../../../types/nullable-types";
import {computed, makeObservable} from "mobx";

/**
 * Implements operations for managing a specific SSR in a specific SSRs bucket
 */
export class SsrModel implements ISsrViewModel {
    constructor(public readonly ssrType: ISsrType,
                public readonly ssrsBucket: IJourneySsrsBucket) {
        makeObservable(this, {
            modificationBlockingReason: computed,
            isCompletelyIncludedInBundle: computed,
            quantityIncludedInBundle: computed,
            //totalQuantity: computed,
            quantityPurchasedOnCurrentSession: computed,
            allowUseSameOptionsForAllFlights: computed,
            allowUseSameOptionsForAllPassengers: computed
        })
    }

    get quantityIncludedInBundle(): number {
        return this.passengersSsrEditors.sum(editor => editor.quantityIncludedInBundle);
    }

    get isIncludedInBundle(): boolean {
        return this.quantityIncludedInBundle > 0;
    }

    get  totalQuantity(): number {
        return this.passengersSsrEditors.sum(editor => editor.newQuantityToDisplay);
    }

    get quantityPurchasedOnCurrentSession(): number {
        return this.passengersSsrEditors.sum(editor => editor.quantityPurchasedOnCurrentSession);
    }


    private _passengersSsrsEditor: PassengerJourneySsrEditorModel[] | null = null;
    get passengersSsrEditors(): PassengerJourneySsrEditorModel[] {
        if(!this._passengersSsrsEditor) {
            this._passengersSsrsEditor = this.ssrsBucket.getPassengers().map(passenger => new PassengerJourneySsrEditorModel(passenger, this));
        }

        return this._passengersSsrsEditor;
    }

    applySameQuantityForAllPassengers(newQuantity: number): void {
        this.passengersSsrEditors.forEach(p => {
            p.newQuantity = newQuantity;
        })
    }

    public get discountedMinPrice(): NullablePrice {
        return Price.minOrNull(this.passengersSsrEditors.map(pssr => pssr.discountedMinPrice));
    }

    public get standardMinPrice(): NullablePrice {
        return Price.minOrNull(this.passengersSsrEditors.map(pssr => pssr.standardMinPrice));
    }

    get allowUseSameOptionsForAllFlights(): boolean {
        return this.passengersSsrEditors.all(p => p.allowUseSameOptionsForAllFlights);
    }

    get allowUseSameOptionsForAllPassengers(): boolean {
        const allPassengersHaveTheSameMinAllowedQuantity = (1 === this.passengersSsrEditors.distinct(pj => pj.minAllowedQuantity.toString()).length);
        const allPassengersHaveTheSameModificationStatus = (1 === this.passengersSsrEditors.selectMany(pj => pj.passengerSegmentSsrs).distinct(ps => ps.canBeModified.toString()).length);
        return allPassengersHaveTheSameMinAllowedQuantity && allPassengersHaveTheSameModificationStatus;
    }

    get passengersHaveTheSameQuantityOnAllFlights(): boolean {
        return this.passengersSsrEditors.all(p => p.hasTheSameQuantityOnAllFlights);
    }

    get canBeModifiedForAtLeastOnePassenger(): boolean {
        return this.passengersSsrEditors.some(p => p.canBeModifiedForAtLeastOneSegment);
    }

    get modificationBlockingReason(): NullableString {
        if(this.canBeModifiedForAtLeastOnePassenger) {
            return null;
        }

        return this.passengersSsrEditors[0].modificationBlockingReason;
    }

    get isCompletelyIncludedInBundle(): boolean {
        return this.passengersSsrEditors.all(p => p.isCompletelyIncludedInBundle);
    }

    async sellOne(): Promise<void> {
        this.applySameQuantityForAllPassengers(1);
    }


    async removeAll(): Promise<void> {
        this.applySameQuantityForAllPassengers(0);
    }
}
