import {BookingModel} from "../../booking.model";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {SegmentModel} from "../../segment/segment.model";
import {RealSsrAvailabilityModel} from "./real-ssr-availability.model";
import {ISsrAvailability} from "./ssr-availability.iterface";
import {MissingSsrAvailabilityModel} from "./missing-ssr-availability.model";

export class SegmentSsrsAvailabilityModel {
    constructor(private readonly segment: SegmentModel) {

    }

    private get booking(): BookingModel {
        return this.segment.journey.booking;
    }

    getSsrAvailability(ssrType: ISsrType): ISsrAvailability {
        const segmentSsrsAvailability = this.booking.ssrsAvailability.segmentSsrs.find(segment => segment.segmentKey === this.segment.segmentKey)?.ssrs || [];
        for(let ssrAvailability of segmentSsrsAvailability) {
            if(ssrAvailability.ssrCode === ssrType.ssrCode) {
                return new RealSsrAvailabilityModel(ssrType, this.segment, ssrAvailability);
            }
        }

        return new MissingSsrAvailabilityModel(ssrType, this.segment);
    }


}

