import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {FlightSelectionPageContentComponent} from "../flight-selection-page-content.component";
import {DepartingFlightBundlesComponent} from "./departing-flight-bundles.component";

interface DepartingFlightSelectionPageContentProps {
    allowBundleSelection: boolean;
}

export const DepartingFlightSelectionPageContent: React.FC<DepartingFlightSelectionPageContentProps> = observer((props) => {

    const services = useServices();
    const booking = services.booking.current;

    const availability = services.booking.availability;

    const countBundles = () => {
        return booking.departureJourney?.bundlesAvailability.length ?? 0;
    }

    const getBundlesSelectionTitle = () => {
        if(!props.allowBundleSelection) {
            return null;
        }
        if(countBundles() === 1) {
            return services.language.translate('Your bundle');
        } else {
            return services.language.translate('Select your bundle');
        }
    }

    const getBundleRenderer = () => {
        if(!props.allowBundleSelection) {
            return undefined;
        }

        return () => {
            return (
                <DepartingFlightBundlesComponent/>
            );
        }
    }

    return (
        <FlightSelectionPageContentComponent bundlesSectionTitle={getBundlesSelectionTitle()}
                                             journey={services.booking.current.departureJourney}
                                             pricingInformationMessage={availability.pricingInformationMessage}
                                             trips={availability.departureTrips}
                                             renderBundles={getBundleRenderer()}/>
    );
});
