import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {FlightsDatesSelector} from "../../../../../../../services/flight-search/dates-selection/flights-dates-selector";
import {FlightsDatesSelectionHeaderComponent} from "../../../../../../flight-search/flight-dates-selection/components/flights-dates-selection-header.component";
import {FlightDatesSelectionPageContent} from "../../../../../../flight-search/flight-dates-selection/components/flight-dates-selection-page-content.component";
import {FlightDatesDropDownLowFareInfoComponent} from "../flight-dates-drop-down-low-fare-info.component";

const SwiperViewBox = styled.div`
    display: flex;
    flex-direction: column;
`

interface FlightDatesDropDownSwiperViewComponentProps {
    datesSelector: FlightsDatesSelector;
    onDateSelected: (date: Date) => void;
    captureSelectedDateElementRef: (date: Date, element: HTMLElement | null) => void;
}

export const FlightDatesDropDownSwiperViewComponent: React.FC<FlightDatesDropDownSwiperViewComponentProps> = observer((props) => {

    return (
        <SwiperViewBox>
            <FlightsDatesSelectionHeaderComponent flightsDatesSelector={props.datesSelector}/>
            <FlightDatesSelectionPageContent useSwiper={true}
                                             flightsDatesSelector={props.datesSelector}
                                             onDateSelected={props.onDateSelected}
                                             captureSelectedDateElementRef={props.captureSelectedDateElementRef}/>
            <FlightDatesDropDownLowFareInfoComponent datesSelector={props.datesSelector}/>
        </SwiperViewBox>

    )
});
