import React from "react";
import {observer} from "mobx-react";
import {IonIcon} from "@ionic/react";
import {call, callOutline} from "ionicons/icons";
import {useServices} from "../../hooks/use-services.hook";
import styled from "styled-components";

const CallCenterPhoneNumberBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    gap: 2px;
`

const PhoneBox = styled.a`
    color: inherit;
`

export const CallCenterPhoneComponent: React.FC = observer(() => {
    const services = useServices();
    const phoneNumber = services.configuration.data.callCenterPhoneNumber;
    if(!phoneNumber) {
        return null;
    }
    return (
        <CallCenterPhoneNumberBox>
            <IonIcon md={call} ios={callOutline}/>
            <PhoneBox href={`tel:${phoneNumber}`}>{phoneNumber}</PhoneBox>
        </CallCenterPhoneNumberBox>
    )
})