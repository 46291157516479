import {IDotRezSegment} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {LegSnapshotModel} from "../leg/leg-snapshot.model";
import {IServiceFactory} from "../../../../service-factory.interface";
import {SegmentBaseModel} from "../../base-models/segment/segment-base.model";
import {JourneySnapshotModel} from "../journey/journey-snapshot.model";
import {PassengerSegmentCheckInStatusEnum} from "../../../../dot-rez-api/data-contracts/enums/passenger-segment-check-in-status.enum";
import {ChangeReasonCodeEnum} from "../../../../dot-rez-api/data-contracts/enums/change-reason-code.enum";
import {TimeSpan} from "../../../../../types/time-span";
import {PassengerSegmentSnapshotModel} from "../passenger-segment/passenger-segment-snapshot.model";
import {sumOfNonTaxesServiceCharges} from "../../base-models/fees/service-charges.helpers";
import {Price} from "../../../../currency/price";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";


export class SegmentSnapshotModel extends SegmentBaseModel<LegSnapshotModel> {
    constructor(private readonly segmentIndex: number,
                public readonly journey: JourneySnapshotModel) {
        super();
        this.passengers = this.segmentDotRezData.passengerSegment.map(ps => new PassengerSegmentSnapshotModel(ps.key, ps.value, this));
        this.legs = this.segmentDotRezData.legs.map(l => new LegSnapshotModel(l, this));
    }

    readonly passengers: PassengerSegmentSnapshotModel[]

    get segmentDotRezData(): IDotRezSegment {
        return this.journey.journeyDotRezData.segments[this.segmentIndex];
    }

    get segmentKey(): string {
        return this.segmentDotRezData.segmentKey;
    }

    get services(): IServiceFactory {
        return this.journey.services;
    }

    readonly legs: LegSnapshotModel[];

    get hasBoardedPassengers(): boolean {
        return this.segmentDotRezData.passengerSegment.some(ps => ps.value.liftStatus === PassengerSegmentCheckInStatusEnum.Boarded);
    }

    get changeReasonCode(): ChangeReasonCodeEnum {
        return this.segmentDotRezData.changeReasonCode;
    }

    get delayTime(): TimeSpan {
        return this.journey.getSegmentDelay(this.segmentIndex);
    }

    createPrice(amount: number): Price {
        return this.journey.createPrice(amount);
    }

    get totalFares(): Price {
        const fares = this.segmentDotRezData.fares;
        if(!fares) {
            return this.createPrice(0);
        }

        let totalFares = 0;

        for(let fare of fares) {
            for(let passengerTypeFare of fare.passengerFares) {
                const fareAmount = sumOfNonTaxesServiceCharges(passengerTypeFare.serviceCharges);
                const passengerTypesCount = this.journey.countPassengerType(passengerTypeFare.passengerType, passengerTypeFare.discountCode);
                totalFares += fareAmount * passengerTypesCount;
            }
        }

        return this.journey.booking.createPrice(totalFares);
    }

    get totalFees(): Price {
        return Price.sumAll(this.journey.booking.passengers.map(passenger => passenger.getFlightTotalFees(this.flightReference)), this.createPrice(0));
    }


    get totalToCollect(): Price {
        return this.totalFares.sum(this.totalFees);
    }

    get unrefundableAmount(): Price {
        return Price.sumAll(this.passengers.map(ps => ps.unrefundableAmount), this.createPrice(0));
    }

    get refundableAmount(): Price {
        return this.totalToCollect.subtract(this.unrefundableAmount);
    }

    getTotalSsrFees(passengerKey: string, ssrType: ISsrType): Price {
        return this.journey.getTotalSsrFees(this.flightReference, passengerKey, ssrType);
    }

}
