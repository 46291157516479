export const SSRS_AVAILABILITY_QUERY = `
    ssrAvailabilityv2 {
        segmentSsrs {
          segmentKey,
          segmentDetails {
            origin
            destination
          }
          ssrs {
            ssrCode,
            ssrType
            limitPerPassenger,
            available,
            passengersAvailability {
              key,
              value {
                ssrKey,
                price
              }
            }
          }
        }
    }
`
