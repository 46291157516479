import { IFlightIdentifierViewModel } from "./flight-identifier-view-model.interface";
import {IDotRezIdentifier} from "../../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import {IServiceFactory} from "../../../service-factory.interface";

export class FlightIdentifierModel implements IFlightIdentifierViewModel{
    constructor(public readonly carrierCode: string,
                public readonly identifier: string,
                private readonly services: IServiceFactory) {
    
        }
    fullIdentifierCode(): string {
        return `${this.carrierCode}${this.identifier}`
    }

    isMatch(dotRezIdentifier: IDotRezIdentifier): boolean {
        return this.carrierCode === dotRezIdentifier.carrierCode
                && this.identifier === dotRezIdentifier.identifier;
    }
}
