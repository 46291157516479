import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import styled from "styled-components";

const FlightItineraryContactDetailsContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const FlightItineraryContactDetailsContentBoxRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const FlightItineraryContactDetailsContentBoxFieldLabel = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
`;

const FlightItineraryContactDetailsContentBoxFieldValue = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
`;


export const FlightItineraryContactDetailsContentComponent: React.FC = observer(() => {
    const services = useServices();

    const contactDetails = services.booking.current.contact?.fields;

    if (!contactDetails) {
        return (
            <></>
        );
    }

    return (
        <FlightItineraryContactDetailsContentBox>
            <FlightItineraryContactDetailsContentBoxRow>
                <FlightItineraryContactDetailsContentBoxFieldLabel>
                    {services.language.translate('Name')}
                </FlightItineraryContactDetailsContentBoxFieldLabel>
                <FlightItineraryContactDetailsContentBoxFieldValue>
                    {`${contactDetails.firstName.value} ${contactDetails.lastName.value}`}
                </FlightItineraryContactDetailsContentBoxFieldValue>
            </FlightItineraryContactDetailsContentBoxRow>
            <FlightItineraryContactDetailsContentBoxRow>
                <FlightItineraryContactDetailsContentBoxFieldLabel>
                    {contactDetails.emailAddress.fieldName}
                </FlightItineraryContactDetailsContentBoxFieldLabel>
                <FlightItineraryContactDetailsContentBoxFieldValue>
                    {contactDetails.emailAddress.value}
                </FlightItineraryContactDetailsContentBoxFieldValue>
            </FlightItineraryContactDetailsContentBoxRow>
            <FlightItineraryContactDetailsContentBoxRow>
                <FlightItineraryContactDetailsContentBoxFieldLabel>
                    {contactDetails.phoneNumber.fieldName}
                </FlightItineraryContactDetailsContentBoxFieldLabel>
                <FlightItineraryContactDetailsContentBoxFieldValue>
                    {contactDetails.phoneNumber.value}
                </FlightItineraryContactDetailsContentBoxFieldValue>
            </FlightItineraryContactDetailsContentBoxRow>
            <FlightItineraryContactDetailsContentBoxRow>
                <FlightItineraryContactDetailsContentBoxFieldLabel>
                    {contactDetails.countryCode.fieldName}
                </FlightItineraryContactDetailsContentBoxFieldLabel>
                <FlightItineraryContactDetailsContentBoxFieldValue>
                    {contactDetails.countryCode.value && services.country.getCountry(contactDetails.countryCode.value).name}
                </FlightItineraryContactDetailsContentBoxFieldValue>
            </FlightItineraryContactDetailsContentBoxRow>
        </FlightItineraryContactDetailsContentBox>
    );
});
