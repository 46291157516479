import {IAdvancedInAppBrowserOptions, IWindowService} from "./window.service.interface";
import {WindowEventListenerRegistration} from "./window-event-listener-registration";
import {IBrowserTab, IInAppBrowser} from "./browser-tab.interface";
import {SimpleInAppBrowserTab} from "./simple-in-app-browser-tab";
import {ExternalBrowserTab} from "./external-browser-tab";
import { Browser } from "@capacitor/browser";
import {AdvancedInAppBrowser} from "./advanced-in-app-browser";

export class WindowService implements IWindowService {
    constructor() {
        this.subscribeTo = new WindowEventListenerRegistration(window);
    }
    getGlobalProperty<T=any>(name: string): T {
        return (window as any)[name] as T;
    }

    readonly subscribeTo: WindowEventListenerRegistration;

    get innerHeight(): number {
        return window.innerHeight;
    }

    get innerWidth(): number {
        return window.innerWidth;
    }

    setGlobalProperty(name: string, value: any): void {
        (window as any)[name] = value;
    }

    getOrigin(): string {
        return window.location.origin;
    }


    private open(url?: string | undefined, target?: string | undefined, features?: string | undefined): Window | null {
        return window.open(url, target, features);
    }

    openExternalBrowserTab(url: string): IBrowserTab {
        return new ExternalBrowserTab(this.open(url, 'location=yes'))
    }

    async openSimpleInAppBrowser(url: string): Promise<IInAppBrowser> {
        await Browser.open({
            url: url
        });
        return new SimpleInAppBrowserTab();
    }

    async openAdvancedInAppBrowser(options: IAdvancedInAppBrowserOptions): Promise<IInAppBrowser> {
        return new AdvancedInAppBrowser(options);
    }

    postMessage(message: any): void {
        if(window.top) {
            window.top.postMessage(message, "*");
        } else {
            window.postMessage(message, "*");
        }
    }

    reload(): void {
        window.location.reload();
    }
}
