import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const PetIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.6} {...props} viewBox="0 0 26 22.527">
            <g fill={props.color? props.color: "currentColor"}>
                <path
                    d="M18.867,13.88,16.338,4.634l1.573-2.4a1,1,0,0,0-.031-1.14L17.374.4A.988.988,0,0,0,16.012.178L13.73,1.772H5.782L3.468,3.6H14.484c2.271-2.278-.175,1.121-.175,1.121l2.408,8.809-.09.035L13.156,8.228a.99.99,0,0,0-.829-.453H9.389a.986.986,0,0,0-.192.019l-5.036,1a.993.993,0,0,0-.748.662L2.08,13.463l.553-4.786a1,1,0,0,0,0-.264L2.051,4.57.257,5.92l.4,2.659L.007,14.2a1,1,0,0,0,.164.674.992.992,0,0,0,.561.4l1.7.469a.99.99,0,0,0,1.2-.646l1.475-4.469L9.485,9.76H11.79L15.4,15.317a.988.988,0,0,0,1.178.388l1.681-.635a1,1,0,0,0,.606-1.19Z"
                    transform="translate(7.099 6.747)"/>
                <path
                    d="M7.38,9.435a1,1,0,0,0-.233-.707.99.99,0,0,0-.655-.347L2.668,8,2.26,7.118,5.881,4.932a1,1,0,0,0,.481-.84l.02-1.419L7.4,4.548,9.217,8.064l1.606-1.209L9.151,3.624l-.009-.017L7.465.519A.991.991,0,0,0,6.6,0H5.415a.988.988,0,0,0-.7.287,1,1,0,0,0-.3.7L4.387,3.515.474,5.872A1,1,0,0,0,.092,7.141L1.115,9.346a.992.992,0,0,0,.8.569l4.26.429Z"/>
                <path
                    d="M.466,11.089A.463.463,0,0,0,.745,11L14.218.856a.47.47,0,0,0-.029-.793.464.464,0,0,0-.529.043L.187,10.248A.47.47,0,0,0,.194,11a.464.464,0,0,0,.273.088Z"
                    transform="translate(6.651 0.63)"/>
            </g>
        </SvgIcon>
    )
}
