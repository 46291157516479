import {ICurrencyService} from "./currency.service.interface";
import {Check} from "../../types/type-checking";

export class Price {
    constructor(public readonly amount: number, public readonly currency: string, private readonly _currencyService: ICurrencyService) {
        this.amount = Math.round(amount * 100) / 100;
    }

    toString() {
        return this._currencyService.formatMoneyForCurrency(this.amount, this.currency);
    }

    equals(otherPrice: Price | null | undefined): boolean {
        if(!otherPrice) {
            return false;
        }

        return this.amount === otherPrice.amount && this.currency === otherPrice.currency;
    }

    getFormattedAmount(): string {
        return this._currencyService.formatNumber(this.amount);
    }

    sum(value: number | Price): Price {
        if(Check.isNumber(value)) {
            return new Price(this.amount + value, this.currency, this._currencyService);
        } else {
            if(value.currency !== this.currency) {
                throw new Error('You cannot sum two prices with different currencies');
            }

            return new Price(this.amount + value.amount, this.currency, this._currencyService);
        }
    }

    multiply(multiplier: number): Price {
        return new Price(this.amount * multiplier, this.currency, this._currencyService);
    }

    subtract(value: number | Price): Price {
        if(Check.isNumber(value)) {
            return new Price(this.amount - value, this.currency, this._currencyService);
        } else {
            if(value.currency !== this.currency) {
                throw new Error('You cannot subtract two prices with different currencies');
            }

            return new Price(this.amount - value.amount, this.currency, this._currencyService);
        }
    }

    isGreaterThan(otherPrice: Price): boolean {
        if(otherPrice.currency !== this.currency) {
            throw new Error('You cannot compare two prices in different currencies');
        }

        return this.amount > otherPrice.amount;
    }

    private static _filterNonNullablePrices(prices: Array<Price | null | undefined>): Price[] {
        const nonNullablePrices: Price[] = [];

        prices.forEach(p => {
            if(p) {
                nonNullablePrices.push(p);
            }
        })
        return nonNullablePrices;
    }

    static minOrNull(prices: Array<Price | null | undefined>): NullablePrice {
        prices = prices.filter(p => Boolean(p));
        if(prices.length === 0) {
            return null;
        }
        return Price.min(prices);
    }

    static min(prices: Array<Price | null | undefined>, fallbackPrice?: Price): Price {
        const nonNullablePrices: Price[] = Price._filterNonNullablePrices(prices);

        if(nonNullablePrices.length === 0) {
            if(fallbackPrice) {
                return fallbackPrice;
            } else {
                throw new Error('prices argument cannot be empty if no fallbackPrice is provided');
            }

        }

        if(nonNullablePrices.length === 1) {
            return nonNullablePrices[0];
        }

        let minPrice = nonNullablePrices[0];
        for(let i = 1; i < nonNullablePrices.length; i++) {
            if(minPrice.isGreaterThan(nonNullablePrices[i])) {
                minPrice = nonNullablePrices[i];
            }
        }

        return minPrice;
    }

    static sumAll(prices: Array<Price | null | undefined>, fallBackPrice: Price): Price {
        const nonNullablePrices: Price[] = Price._filterNonNullablePrices(prices);
        if(nonNullablePrices.length === 0) {
            return fallBackPrice;
        }

        let total = nonNullablePrices[0];

        for(let i = 1; i < nonNullablePrices.length; i++) {
            total = total.sum(nonNullablePrices[i]);
        }
        return total;
    }
}

export type NullablePrice = Price | null;
export type NullableUndefinedPrice = Price | null | undefined;
