import {BookingStepBase} from "./booking-step.base";
import {IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";

export abstract class PaymentStepBase<TRoutes> extends BookingStepBase {
    protected abstract get routes(): TRoutes;

    get title(): string {
        return this.services.language.translate('Payment');
    }

    get allowSwipeForward(): boolean {
        return false;
    }

    async next(): Promise<void> {

        this.services.analytics.paymentEvents.logAddPaymentInfo(this.booking)
        await this.booking.paymentHandler.payBooking();
    }

    get isVisible(): boolean {
        return this.booking.balanceDue.amount > 0;
    }

    acceptActivation(): boolean {
        return super.acceptActivation() && this.booking.travelConditionsAccepted;
    }

    protected async _onActivated(previousStep: IWizardStep | null): Promise<void> {
        await waitForBookingMutations(this.booking);


        return super._onActivated(previousStep);
    }
}
