import { IDotRezBookingSessionData } from "../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import { IDotRezBookingData } from "../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import { DotRezResponseMessages } from "../../dot-rez-api/session/dot-rez-response";

export function createEmptyBookingSessionData(currencyCode: string): IDotRezBookingSessionData {
    return {
        bookingData: createEmptyBookingData(currencyCode),
        seatMaps: [],
        bundlesAvailability: [],
        messages: new DotRezResponseMessages([]),
        ssrsAvailability: {
            segmentSsrs: []
        },
        segmentsDelays: []
    }
}

function createEmptyBookingData(currencyCode: string): IDotRezBookingData {
    return {
        bookingKey: null,
        recordLocator: null,
        locators: {
            numericRecordLocator: null,
            recordLocators: []
        },
        journeys: [],
        passengers: [],
        currencyCode: currencyCode,
        contacts: [],
        typeOfSale: {
            promotionCode: ""
        },
        breakdown: {
            balanceDue: 0,
            authorizedBalanceDue: 0,
            totalToCollect: 0,
            journeys: [],
            journeyTotals: {
                totalTax: 0
            }
        },
        payments: [],
        comments: [],
        info: {
            bookedDate: new Date().toISOString(),
            createdDate: null
        },
        queues: []
    };
}
