import {IDotRezApiService} from "./dot-rez-api.service.interface";
import {ServiceBase} from "../service-base";
import {IDotRezBookingSession} from "./session/booking-session/dot-rez-booking.session.interface";
import {DotRezBookingSession} from "./session/booking-session/dot-rez-booking.session";
import {buildFullUrl} from "../http/http-helpers";
import {DotRezBookingSessionTimerModel} from "./session/session-timer/dot-rez-booking-session-timer.model";
import {DeeplinkAgentTokenRequest} from "../airline-webapi/requests/deeplink-agent-token.request";
import {DotRezResponse} from "./session/dot-rez-response";
import {DotRezSessionExpiredException} from "./session/dot-rez-exception";
import {DotRezTransientSessionTimer} from "./session/session-timer/dot-rez-transient-session-timer";
//import {ApplicationName} from "../../global-constants";


export class DotRezApiService extends ServiceBase implements IDotRezApiService {

    buildDotRezUrl(path: string ) {
        if(!this.services.configuration.dotRezApiUrl) {
            throw new Error('dotRezApiUrl was not initialized');
        }
        return buildFullUrl(this.services.configuration.dotRezApiUrl, path);
    }

    getDotRezApiRequestHeaders(token: string): Record<string, string> {
        return {
            'Content-Type': 'application/json',
            'Authorization': token,
            ...this.services.configuration.dotRezApiSubscriptionKeyHeader
        }
    }


    async createAnonymousToken(): Promise<string> {
        let {token} = await this.services.airlineWebapi.createDotRezAnonymousToken();
        token = await this.services.crypto.decryptMessage(token);
        return token;
    }

    async getDeepLinkAgentToken(request: DeeplinkAgentTokenRequest): Promise<string> {
        let {dotRezToken} = await this.services.airlineWebapi.getDeepLinkAgentToken(request);
        dotRezToken = await this.services.crypto.decryptMessage(dotRezToken);
        return dotRezToken;
    }

    async tryRefreshToken(token: string): Promise<void> {
        const fetchResponse = await fetch(this.buildDotRezUrl('/api/nsk/v1/token'), {
            method: 'GET',
            headers: this.getDotRezApiRequestHeaders(token)
        });

        const json = await fetchResponse.json();

        const dotRezResponse = new DotRezResponse<any>(fetchResponse.status, json.data, fetchResponse.headers, json.messages, json.errors);

        if(dotRezResponse.isSessionExpired) {
            throw new DotRezSessionExpiredException();
        } else {
            dotRezResponse.throwIfError();
        }

    }

    async loadBookingSession(token: string): Promise<IDotRezBookingSession> {
        await this.tryRefreshToken(token);
        return new DotRezBookingSession(token, this.services, new DotRezBookingSessionTimerModel(token, this.services));
    }


    createBookingSession(token: string): IDotRezBookingSession {
        return new DotRezBookingSession(token, this.services, new DotRezBookingSessionTimerModel(token, this.services, {disableCountDownDialog: false}));
    }

    createFlightSearchSession(token: string): IDotRezBookingSession {
        return new DotRezBookingSession(token, this.services, new DotRezBookingSessionTimerModel(token, this.services, {disableCountDownDialog: true}));
    }

    createTransientBookingSession(token: string): IDotRezBookingSession {
        return new DotRezBookingSession(token, this.services, new DotRezTransientSessionTimer());
    }

}
