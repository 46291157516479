import {IDotRezGraphQLQuery} from "../dot-rez-graph-ql-query.interface";
import {IDotRezRetrieveBookingByEmailRequest} from "../../data-contracts/requests/booking/retrieve-booking-by-email.request";
import {IDotRezRetrieveBookingByLastNameRequest} from "../../data-contracts/requests/booking/retrieve-booking-by-last-name.request";

const BOOKING_DATA_QUERY_FIELDS = `
    currencyCode,
    recordLocator,
    bookingKey,
    locators {
        recordLocators {
            owningSystemCode
        }
    }
    typeOfSale {
      promotionCode
    }
    payments {
      paymentKey,  
      code,
      type,
      status,
      authorizationStatus,
      details {
        accountNumber,
        fields {
            key,
            value
        }
      },
      amounts {
        amount
      }
    }
    breakdown {
      totalToCollect,
      balanceDue,
      authorizedBalanceDue,
      passengerTotals {
        specialServices {
          total,
          taxes,
          adjustments,
          charges {
            amount,
            code,
            currencyCode
          }
        },
        seats {
          total
        },
        infant {
          total
        }
      },
      journeyTotals {
        totalAmount,
        totalTax
      },
      journeys {
        key,
        value {
            totalAmount,
            totalTax,
            totalDiscount
        }
      }
    }
    journeys {
      journeyKey,
      designator {
        origin,
        destination,
        departure,
        arrival
      }
      segments {
        segmentKey,
        international,
        changeReasonCode,
        flightReference,
        designator {
             origin,
             destination,
             departure,
             arrival
        },
        identifier {
            identifier,
            carrierCode
        }
        legs {
            legKey,
            flightReference,
            designator {
                origin,
                destination,
                departure,
                arrival
            },
            legInfo {
                departureTimeUtc,
                arrivalTimeUtc,
                status,
                departureTime,
                operatingCarrier
            }
        }
        passengerSegment {
          key,
          value {
            bundleCode,
            liftStatus,
            timeChanged,
            boardingSequence,
            seats {
              unitKey
              unitDesignator
            }
            ssrs {
              passengerKey,
              ssrCode,
              ssrKey,
              count,
              inBundle,
              ssrNumber,
              note
            }
          }
        }
        fares {
          fareKey,
          classOfService,
          passengerFares {
            passengerType,
            discountCode,
            serviceCharges {
              amount,
              type
            }
          }
        }
        
      }
    }
    passengers {
      key,
      value {
        passengerKey,
        passengerTypeCode,
        discountCode,
        customerNumber,
        name {
          first,
          last,
          middle,
          title
        }
        info {
          dateOfBirth,
          gender,
          nationality
        },
         travelDocuments {
            passengerTravelDocumentKey,
            documentTypeCode,
            number,
            name {
              first,
              last,
              middle,
              title
            },
            issuedByCode,
            issuedDate,
            expirationDate,
            nationality,
            gender,
            dateOfBirth
        },
        addresses {
            passengerAddressKey,
            status,
            companyName,
            phone,
            emailAddress,
            countryCode,
            provinceState,
            city,
            postalCode,
            cultureCode,
            lineOne,
            lineTwo,
            lineThree,
            stationCode
        },
        infant {
          name {
            first,
            last,
            middle,
            title
          },
          dateOfBirth,
          nationality,
          gender,
          travelDocuments {
            passengerTravelDocumentKey,
            documentTypeCode,
            number,
            name {
              first,
              last,
              middle
            },
            issuedByCode,
            issuedDate,
            expirationDate,
            nationality,
            gender,
            dateOfBirth,
            },
          fees {
             code,
             isConfirmed,
             flightReference,
             createdDate,
             serviceCharges {
                type,
                amount
            }
          }
        },
        fees {
            type,
            code,
            ssrCode,
            ssrNumber,
            isConfirmed,
            flightReference,
            passengerFeeKey,
            createdDate,
            note,
            serviceCharges {
                type,
                amount
            }
        }
      }
    }
    contacts {
        key,
        value {
            contactTypeCode,
            phoneNumbers {
                number,
                type
            },
            cultureCode,
            address {
                city
                countryCode,
                lineOne,
                lineTwo,
                lineThree,
                postalCode,
                provinceState
            },
            emailAddress,
            customerNumber,
            sourceOrganization,
            distributionOption,
            notificationPreference,
            companyName,
            name {
                first,
                middle,
                last,
                suffix,
                title
            }
            
        }
    },
    comments {
        type,
        text
    },
    info {
        createdDate,
        bookedDate
    },
    locators {
        numericRecordLocator,
        recordLocators {
            owningSystemCode
        }
    },
    queues {
        code
    }
`

export const BOOKING_IN_STATE_DATA_QUERY = `
    booking {
        ${BOOKING_DATA_QUERY_FIELDS}           
    }
`


function bringBookingInStateQuery(queryName: string, request: any): IDotRezGraphQLQuery {
    return {
        queryName: queryName,
        request: {
            query: `
                query bookingInfo($retrieveBookingRequest: Input_RetrieveBookingv2) {
                        bookingRetrievev2(request: $retrieveBookingRequest) {
                            ${BOOKING_DATA_QUERY_FIELDS}
                        }
                }
            `,
            variables: {
                retrieveBookingRequest: request
            }
        }
    }
}

/**
 * Finds a booking by e-mail and loads it in the current session
 * @param request
 */
export function bringBookingInStateByEmailQuery(request: IDotRezRetrieveBookingByEmailRequest): IDotRezGraphQLQuery {
    return bringBookingInStateQuery('retrieveBookingByEmail', {
        recordLocator: request.recordLocator,
        emailAddress: request.emailAddress
    });
}

/**
 * Find a booking by last name and loads it in the current session
 * @param request
 */
export function bringBookingInStateByLastNameQuery(request: IDotRezRetrieveBookingByLastNameRequest): IDotRezGraphQLQuery {
    return bringBookingInStateQuery('retrieveBookingByLastName', {
        recordLocator: request.recordLocator,
        lastName: request.lastName
    });
}

function findBookingQuery(queryName: string, findBookingRequest: any): IDotRezGraphQLQuery {
    return {
        queryName: queryName,
        request: {
            query: `
                query bookingInfo($retrieveBookingRequest: Input_RetrieveBookingv2) {
                        bookingsv2(request: $retrieveBookingRequest) {
                            ${BOOKING_DATA_QUERY_FIELDS}
                        }
                }
            `,
            variables: {
                retrieveBookingRequest: findBookingRequest
            }
        }
    }
}

/**
 * Finds a booking by e-mail but doesn't load it in the current session state
 * @param request
 */
export function findBookingByEmailQuery(request: IDotRezRetrieveBookingByEmailRequest): IDotRezGraphQLQuery {
    return findBookingQuery('findBookingByEmail', {
        recordLocator: request.recordLocator,
        emailAddress: request.emailAddress
    });
}

/**
 * Find a booking by last name  but doesn't load it in the current session state
 * @param request
 */
export function findBookingByLastNameQuery(request: IDotRezRetrieveBookingByLastNameRequest): IDotRezGraphQLQuery {
    return findBookingQuery('findBookingByLastName', {
        recordLocator: request.recordLocator,
        lastName: request.lastName
    });
}
