import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {ManageMyBookingStationSelectionPage} from "./manage-my-booking-station-selection.page";
import {PlaneDepartLineIcon} from "../../../../assets/svg/plane-depart-line.icon";


export const ManageMyBookingOriginSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.booking.searchController;

    return (<ManageMyBookingStationSelectionPage titleText={services.language.translate('Departing From')}
                                                 plainIcon={<PlaneDepartLineIcon color={services.theme.currentTheme.colors.secondary}/>}
                                                 stations={searchController.availableDepartureStations}
                                                 recentlySearchedStations={searchController.recentlySearchedOrigins}
                                                 onStationSelected={async (station) => {
                                                        searchController.returnDate = null;
                                                        searchController.departureDate = null;
                                                        searchController.departureOrigin = null;
                                                        searchController.departureDestination = null;
                                                        searchController.returnOrigin = null;
                                                        searchController.returnDestination = null;

                                                        if(searchController.isOneWayReturnTrip) {
                                                            searchController.returnOrigin = station;
                                                        } else {
                                                            searchController.departureOrigin = station;
                                                        }

                                                        await services.booking.steps.nextStep();
                                                    }
                                                 } /> );
});
