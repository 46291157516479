import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PhoneCheckmarkIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.1} sizeIsHeight {...props} viewBox="0 0 13.941 19.192" ratio={13.941/19.192}>
            <g fill={props.color? props.color: "currentColor"}>
            <path d="M111.941,21.315V6.086A1.989,1.989,0,0,0,109.955,4.1H99.986A1.989,1.989,0,0,0,98,6.086v15.22a1.989,1.989,0,0,0,1.986,1.986h9.969A1.98,1.98,0,0,0,111.941,21.315Zm-11.955.339a.343.343,0,0,1-.339-.339V19.009a.391.391,0,0,0,.2-.513.416.416,0,0,0-.2-.2V6.086a.343.343,0,0,1,.339-.339h9.969a.343.343,0,0,1,.339.339V18.254h-.339a.388.388,0,1,0,0,.775h.339v2.277a.343.343,0,0,1-.339.339l-9.969.01Z" transform="translate(-98 -4.1)" /><path d="M106.1,150.6v3.865h12.371V150.6Zm6.19,3.313a1.376,1.376,0,1,1,1.376-1.376A1.375,1.375,0,0,1,112.29,153.913Z" transform="translate(-105.315 -136.407)" /><path d="M4123.868,925.91l-3.418-3.418,1-1,2.42,2.419,3.628-3.629,1,1Z" transform="translate(-4117.216 -914.74)" />
            </g>
        </SvgIcon>
    )
}
