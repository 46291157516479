export type LocalStorageKeys = 'flightSearch.recentlySearchedOriginStations'
    | 'flightSearch.recentlySearchedDestinationStations'
    | 'booking.myTrips'
    | 'booking.boardingPasses'
    | 'booking.invoice'
    | 'booking.cardInfo'
    | 'userProfile.bookingContact'
    | 'app.cookiesPolicyAccepted'
    | 'user.refreshToken'
    | 'authorizedUser.personData'
    | 'authorizedUser.myTrips'
    | 'authorizedUser.myBoardingPasses'
    | 'authorizedUser.attachedBookings'
    | 'authorizedUser.lastLoggedInUser'
    | 'theme.preferredColorScheme'
    | 'application.forceHybridLayout';

/**
 * Keys that are not stored per environment type
 */
export enum NonEnvLocalStorageKeys {
    userProfileCurrency = 'userProfile.currency',
    userProfileLanguage= "userProfile.language",
    testModeEnabled = "userProfile.testModeEnabled",
    envConfigsPreferredApiOrigin = "envConfigs.preferredApiOrigin",

}


