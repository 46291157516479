import {IDotRezPartialBookingSessionData} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {FeeCodeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {BookingMutationActionBase} from "../booking-mutation-action-base";


export class RemoveInsuranceMutation extends BookingMutationActionBase {

    private _getSsrsToRemove(): string[] {
        let ssrsToRemove: string[] = [];

        for(let passengerSegment of this.booking.getAllPassengersSegments()) {
            const ssrEditor = passengerSegment.getSsr(this.services.ssrTypes.INS1);
            ssrEditor.newQuantity = 0;
            ssrsToRemove = [
                ...ssrsToRemove,
                ...ssrEditor.soldSsrs.map(ssr => ssr.ssrKey)
            ]
        }
        
        return ssrsToRemove;
    }

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        for(let passenger of this.booking.passengers) {
            for(let insuranceFee of passenger.fees.filter(f => f.feeCode === FeeCodeEnum.Insurance)) {
                await this.services.airlineWebapi.deleteBookingFee({
                    dotRezToken: this.session.token,
                    feeKey: insuranceFee.feeKey
                });
            }
        }

        await this.booking.session.removeSsrs(this._getSsrsToRemove());

        return await this.booking.session.bookingStateQueryBuilder().useBookingData().getBookingState();
    }

}
