import React from "react";
import { observer } from "mobx-react";
import { IonContent, IonPage } from "@ionic/react";
import styled from "styled-components";
import { AirlineLogoMonocolorIcon } from "../../assets/svg/airline-logo-monocolor.icon";
import { NextFlightIconBox, NextFlightsBox, NextFlightsComponent, NextFlightsHeaderTitleBox } from "../../pages/home/components/next-flights/next-flights.component";
import { useServices } from "../../hooks/use-services.hook";

const IonContentBox = styled(IonContent)`
    --background: linear-gradient( rgba(${props => props.theme.colors.secondaryRgb}, 0.79), rgba(${props => props.theme.colors.secondaryRgb}, 0.79) ), url("${props => props.theme.assets.images.airplane_window_view_png.toString()}") no-repeat center/cover fixed; 
`

const CenteredVerticalBox = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 20px;
`
const InfoMessageTitleBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.xLarge};
    text-align: center;
    line-height: 1;
    color: ${props => props.theme.colors.secondaryContrast};
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 44px;
`

const InfoMessageDetailsBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    text-align: justify;
    line-height: 1;
    color: ${props => props.theme.colors.secondaryContrast};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
`

const AirlineLogoMonocolorIconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 68px;
`

const NextFlightsComponentBox =  styled.div`
    ${NextFlightIconBox} {
        color: ${props => props.theme.colors.secondaryContrast};
    }

    ${NextFlightsHeaderTitleBox} {
        color: ${props => props.theme.colors.secondaryContrast};
    }

    ${NextFlightsBox} {
        .swiper-slide > div {
            min-height: auto;
        }
    }
`

export const MaintenanceComponent: React.FC = observer(() => {
    const services = useServices()
    return (
        <IonPage>
            <IonContentBox>
                <CenteredVerticalBox>
                    <AirlineLogoMonocolorIconBox>
                        <AirlineLogoMonocolorIcon size={5.5} />
                    </AirlineLogoMonocolorIconBox>
                    <InfoMessageTitleBox> {services.language.translate(`IMPROVEMENTS IN PROGRESS`)}</InfoMessageTitleBox>
                    <InfoMessageDetailsBox>{services.language.translate(`Planning to book a flight? Our services are unavailable at the moment. We have a scheduled maintenance and we will be back up and running again before long, so please try again soon. Thanks for your patience.`)}
                    </InfoMessageDetailsBox>
                    <NextFlightsComponentBox>
                        <NextFlightsComponent />
                    </NextFlightsComponentBox>
                </CenteredVerticalBox>

            </IonContentBox>
        </IonPage>
    );
});
