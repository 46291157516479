import {BookingFlowStepBase} from "../common/booking-flow-step.base";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {IOnBeforeDeactivationContext, IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import {DialogResult} from "../../../dialog/dialog-enums";
import {AvailabilityModel} from "../../../flight-search/models/availability.model";

export class BookingFlowReturnFlightSelectionStep extends BookingFlowStepBase {
    get title(): string {
        return this.services.language.translate('Return');
    }

    protected _shouldBeDisplayed(): boolean {
        return super._shouldBeDisplayed() && !this.booking.flightSearchController.isOneWay;
    }

    get route() {
        return this.routes.returnFlight;
    }


    async validate(): Promise<ValidationResultEnum> {
        const availability = this.services.booking.availability;
        const language = this.services.language;
        if(!availability.hasReturnFareSelected) {
            this.services.alert.showError(language.translate('You need to select a return flight'));
            this.services.analytics.flightSelectionEvents.logMandatoryNotice();
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    protected async _onBeforeDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        if(context.routingGuardContext.isForwardNavigation) {
            await this._waitForSellReturnJourneyMutation();
        }

        return DialogResult.Accepted;
    }

    async next(): Promise<void> {
        if(ValidationResultEnum.Success === await this.validate()) {
            this._activateStepByRoute(this.routes.passengersDetails);

            if(!this.booking.flightSearchController.isOneWay){//not needed
                this.services.analytics.flightSelectionEvents.logAdsRemarketingSearchResults(this.booking!);
            }
        }
    }

    private async _waitForSellReturnJourneyMutation(): Promise<void> {
        const booking = this.booking;
        if(booking.mutationsManager.sellReturnJourneyMutation.isMutationInProgress) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    await booking.mutationsManager.sellReturnJourneyMutation.waitForMutation();
                }
            });
        }
    }

    protected async _onActivated(previousStep: IWizardStep | null): Promise<void> {
        await super._onActivated(previousStep);
        this.services.analytics.flightSelectionEvents.logViewItemListForReturn(this.services.booking.availability as AvailabilityModel);
    }
}
