import {IFeeModel} from "../fee-model.interface";
import {IDotRezInfantFee} from "../../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {Price} from "../../../../../currency/price";
import {ISsrType} from "../../../../../ssr-types/ssr-types.service.interface";
import {FeeTypeEnum} from "../../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {NullableString} from "../../../../../../types/nullable-types";
import {sumOfNonTaxesServiceCharges} from "../service-charges.helpers";
import {IServiceFactory} from "../../../../../service-factory.interface";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../../../ssr-types/ssrs-preferred-orders-in-shopping-cart";

export abstract class InfantFeeBaseModel implements IFeeModel {
    protected constructor(protected readonly feeData: IDotRezInfantFee,
                          protected readonly services: IServiceFactory) {
    }


    abstract createPrice(amount: number): Price;

    readonly ssrType: ISsrType | null = null;
    readonly ssrNumber: number = 0;

    get quantity(): number {
        return 1;
    }

    readonly initialPrice: Price | null = null;

    get currentPrice(): Price {
        return this.createPrice(sumOfNonTaxesServiceCharges(this.feeData.serviceCharges));
    }

    get priceToDisplay(): Price {
        return this.currentPrice;
    }

    get totalToDisplay(): Price {
        return this.priceToDisplay.multiply(this.quantity);
    }

    get isCommitted(): boolean {
        return this.feeData.isConfirmed;
    }

    get isPurchasedOnCurrentSession(): boolean {
        return !this.isCommitted;
    }

    get createdDate(): Date {
        return this.services.time.parseIsoDate(this.feeData.createdDate);
    }

    get feeType(): FeeTypeEnum {
        return FeeTypeEnum.SsrFee;
    }

    get description(): string {
        return this.feeData.code;
    }

    get feeCode(): NullableString {
        return this.feeData.code;
    }

    get details(): NullableString {
        return null;
    }

    get feeGroupKey(): string {
        return `${this.feeData.code}_${this.currentPrice.toString()}`;
    }

    get flightReference(): string {
        return this.feeData.flightReference;
    }

    readonly preferredOrderInLists = SSRS_PREFERRED_ORDER_IN_LISTS.INFANT;

    readonly shouldBeDisplayedInShoppingCart = true;
}
