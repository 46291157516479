import React from "react";
import {SsrsSelectionComponentProps} from "./ssrs-selection.component.props";
import {observer} from "mobx-react";
import {IJourneySsrsBucketViewModel} from "../../../../services/booking/models/ssrs/journey-ssrs-bucket-view-model.interface";
import {JourneySsrBucketDesignatorComponent} from "./journey-ssrs-bucket-designator.component";
import {SynchronizedSwipersComponent} from "../../../../components/synchronized-swipers/synchronized-swipers.component";
import {renderDisclaimerAndSameForAllFlights} from "./disclaimer-and-same-for-all-flights-renderer";
import {JourneySsrsSelectionComponent} from "./journey-ssrs-selection.component";
import {useServices} from "../../../../hooks/use-services.hook";

export const SsrsSelectionWithSlidesComponent: React.FC<SsrsSelectionComponentProps> = observer((props) => {
    const services = useServices();
    const journeysBuckets = props.ssrsAggregator.useSameOptionsForAllFlights
                            ? [props.ssrsAggregator.journeysSsrsBuckets[0]]
                            : props.ssrsAggregator.journeysSsrsBuckets;

    const renderExtraHeader = () => {
        return renderDisclaimerAndSameForAllFlights(props.ssrsAggregator, props.disclaimer);
    }

    const renderHeaderItem = () => {
        if(props.ssrsAggregator.useSameOptionsForAllFlights) {
            return undefined;
        }
        return (bucket: IJourneySsrsBucketViewModel) => {
            return (
                <JourneySsrBucketDesignatorComponent designator={bucket.parentJourney.designator}/>
            )
        };
    }

    const renderContentItem = (bucket: IJourneySsrsBucketViewModel) => {
        return (
            <JourneySsrsSelectionComponent journeyBucket={bucket}
                                           journeyCustomSsrsEditorRenderer={props.journeyCustomSsrsEditorRenderer}
                                           passengerCustomSsrEditorRenderer={props.passengerCustomSsrEditorRenderer}/>
        );
    }

    const SyncSwipers = SynchronizedSwipersComponent<IJourneySsrsBucketViewModel>;

    return (
        <SyncSwipers items={journeysBuckets}
                     getItemKey={bucket => bucket.uniqueKey}
                     renderHeaderItem={renderHeaderItem()}
                     renderContentItem={renderContentItem}
                     renderExtraHeader={renderExtraHeader}
                     stickyTopRef={props.stickyTopRef}
                     headerBackgroundColor={services.theme.currentTheme.colors.lightTint}/>
    )
})
