import React from "react";
import {Station} from "../../../../services/stations/station.service.interface";
import styled from "styled-components";

const DesignatorStationNameBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`


export interface DesignatorStationNameComponentProps {
    station: Station;
    useMac?: boolean;
    className?: string;
}
export const DesignatorStationNameComponent: React.FC<DesignatorStationNameComponentProps> = (props) => {
    const getStationName = () => {
        if(props.useMac) {
            return props.station.stationMacName;
        } else {
            return props.station.stationName;
        }
    }

    return (<DesignatorStationNameBox className={props.className}>
        {getStationName()}
    </DesignatorStationNameBox>);

}
