import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IonContent} from "@ionic/react";
import classNames from "classnames";

const IonContentBox = styled(IonContent)`
  --page-content-padding: ${props => props.theme.spacing.pageDefaultPaddingPx};
  --padding-top: var(--page-content-padding);
  --padding-bottom: var(--page-content-padding);
  --padding-start: var(--page-content-padding);
  --padding-end: var(--page-content-padding);
  --background: ${props => props.theme.colors.primaryContrast};
  --color: ${props => props.theme.colors.primary};
  --width: 100%;
  position: relative; /* this is for drop down popper that uses strategy: absolute*/
`

interface StandardPageContentComponentProps extends PropsWithChildren{
    className?: string;
}
export const StandardPageContentComponent: React.FC<StandardPageContentComponentProps> = observer((props) => {

    const className = classNames(props.className, 'tst-standard-page-content');

    return (<IonContentBox {...props} className={className}>
        {props.children}
    </IonContentBox>);
});
