import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

//fill={props.color ? props.color : "currentColor"

export const BlueBenefitsDiscountIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={3.87} {...props} viewBox="0 0 38.754 29.069">
            <g fill={props.color ? props.color : "currentColor"}><path d="M26.068,9.1a6.937,6.937,0,0,0-7.119-3.977,3.877,3.877,0,0,0-2.987,5.661,6.879,6.879,0,0,0,7.511,3.9,3.864,3.864,0,0,0,2.6-5.581M32.606,0l6.082,13.3c-8.774,6.819-20.435-.062-28.989,7.2L3.618,7.2C11.6-.512,22.632,6.478,32.608.005Zm6.148,19.4c-11.078,6.356-20.023-2.3-31.489,9.667L0,12.978l2.265-2.361L8.412,24.183V24.2c12.023-9.03,18.078-1.9,29.239-7.366.472,1.046.91,2.148,1.1,2.572" fill="#002f9d"/></g>
        </SvgIcon>
    )
}
