import {Check} from "../../../types/type-checking";
import {isHttpErrorStatusCode} from "../../http/http-helpers";
import {
    DotRezResponseMessageValue,
    IDotRezResponseMessage
} from "./booking-session/dot-rez-booking.session.interface";
import {NullableString} from "../../../types/nullable-types";
import {DotRezErrorCode} from "./dot-rez-error-code";
import {DotRezException} from "./dot-rez-exception";

export class DotRezError {
    constructor(private readonly rawError: any) {
    }

    get code(): DotRezErrorCode {
        return this.rawError.code || this.rawError.Code;
    }

    get message(): NullableString {
        return (this.rawError.message || this.rawError.Message) ?? null;
    }

    get type(): NullableString {
        return (this.rawError.type || this.rawError.Type) ?? null;
    }

    /*
    get details(): NullableString {
        return (this.rawError.details || this.rawError.Details) ?? null;
    }
     */
}

export class DotRezResponseMessages {
    constructor(private readonly _rawMessages: IDotRezResponseMessage[]) {
    }

    /*
    hasMessageType(value: DotRezResponseMessageValue): boolean {
        return this._rawMessages.some(msg => msg.value === value);
    }
     */

    countMessageType(value: DotRezResponseMessageValue): number {
        return this._rawMessages.filter(msg => msg.value === value).length;
    }

    join(otherMessages: DotRezResponseMessages | null | undefined): DotRezResponseMessages {
        if(!otherMessages) {
            return this;
        }
        return new DotRezResponseMessages([
            ...this._rawMessages,
            ...otherMessages._rawMessages
        ]);
    }
}

export class DotRezResponse<TData> {
    constructor(public readonly status: number,
                private readonly _data: TData,
                private readonly _headers: Headers,
                messages: any,
                rawErrors: any[] | null) {

        this._errors = (rawErrors ?? []).map(e => new DotRezError(e));

        if(Check.isArray(messages)) {
            if(messages.length === 0) {
                this.messages = new DotRezResponseMessages([]);
            } else {
                this.messages = new DotRezResponseMessages(messages.filter((msg: any) => !Check.isUndefined(msg?.type)).map(msg => msg as IDotRezResponseMessage));
            }
        } else {
            this.messages = new DotRezResponseMessages([]);
        }
    }

    getLocationHeader(): NullableString {
        return this._headers.get('location');
    }

    getEntityIDFromLocationHeader(): string {
        const locationHeader = this.getLocationHeader();
        if(!locationHeader) {
            return '';
        }

        const parts = locationHeader.split('/');
        return parts[parts.length - 1];
    }

    get data(): TData {
        if(this.hasErrors) {
            this.throwIfError();
        }
        return this._data;
    }

    public readonly messages: DotRezResponseMessages;
    private readonly _errors: DotRezError[];
    private get errors(): DotRezError[] {
        return this._errors;
    }

    hasDotRezError(errorCode: DotRezErrorCode): boolean {
        return this.errors.some(err => err.code === errorCode);
    }

    get isSessionExpired(): boolean {
        return this.hasDotRezError('core:Token:Timeout');
    }


    get hasErrors(): boolean {
        return isHttpErrorStatusCode(this.status) || this.errors.length > 0;
    }

    throwIfError(): TData {
        if(this.hasErrors) {
            throw new DotRezException(this.errors)
        }

        return this.data;
    }
}
