import { SsrCategoryEnum } from "../../ssr-category.enum";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {OnlineCheckInBlockerSsr} from "../online-check-in-blocker-ssr";
import {TimeSpan} from "../../../../types/time-span";
import React from "react";
import {SkiingIcon} from "../../../../assets/svg/skiing.icon";
import {SSR_STANDARD_LARGE_ICON_SIZE} from "../../ssr-types.service.interface";


export abstract class SpecialEquipmentSsrTypeBase extends OnlineCheckInBlockerSsr {
    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SPECIAL_EQUIPMENT;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.SpecialEquipment;
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(3);
    }

    protected get _iconInExtrasForLargeScreen(): React.ReactElement | null {
        return (<SkiingIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>);
    }

    protected get _iconInExtrasSmallScreen(): React.ReactElement | null {
        return (<SkiingIcon size={SSR_STANDARD_LARGE_ICON_SIZE}/>);
    }
}
