import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const ArrowUpIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} sizeIsHeight viewBox="0 0 17.602 10.834" ratio={17.602/10.834}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(-0.52 -0.533)"><path d="M8.4,1.613c-.684.684-.936,1.55-.561,1.925l7.655,7.655c.375.376,1.241.122,1.925-.561s.871-1.485.562-1.925L10.323,1.051C9.942.682,9.077.934,8.4,1.613Z"/><path d="M1.243,10.632c.684.684,1.562.924,1.95.536L11.164,3.2c.39-.39.009-.7-.457-1.722A1.422,1.422,0,0,0,8.677.711L.707,8.682C.317,9.072.559,9.948,1.243,10.632Z"/></g>
        </SvgIcon>
    )
}
