import React, {PropsWithChildren} from "react";
import {FlightSearchPageHeaderComponent} from "./components/flight-search-page-header.component";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {StandardPageFooterComponent} from "../../basic-components/page/standard-page-footer/standard-page-footer.component";


interface FlightSearchPageProps extends PropsWithChildren {
    title: string | React.ReactElement;
    renderToolbar?: () => React.ReactElement;
    renderFooter?: () => React.ReactElement;
}
export const FlightSearchPage: React.FC<FlightSearchPageProps> = (props) => {
    const renderFooter = () => {
        if(props.renderFooter) {
            return (
                <StandardPageFooterComponent>
                    {props.renderFooter()}
                </StandardPageFooterComponent>
            );
        }

        return null;
    }
    return (
        <RoutedPageComponent disableSwipeForwardNavigation={true}>
            <FlightSearchPageHeaderComponent title={props.title}>
                {props.renderToolbar && props.renderToolbar()}
            </FlightSearchPageHeaderComponent>

            <StandardPageContentComponent>
                {props.children}
            </StandardPageContentComponent>

            {renderFooter()}

        </RoutedPageComponent>
    )
}
