import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../hooks/use-services.hook";

interface PlatformBasedComponentProps {
    renderForWebApp: () => React.ReactElement;
    renderForHybrid: () => React.ReactElement;
}
export const PlatformBasedComponent: React.FC<PlatformBasedComponentProps> = observer((props) => {
    const services = useServices();
    if(services.layout.shouldUseWebappLayout) {
        return props.renderForWebApp();
    } else {
        return props.renderForHybrid();
    }
});
