import {ExtrasSingleSsrSectionRenderer} from "../../base-renderers/extras-single-ssr-section-renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {FlexSsrEditorComponent} from "./flex-ssr-editor.component";
import React from "react";
import {IBookingSsrsAggregatorViewModel} from "../../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {NullableString} from "../../../../../types/nullable-types";
import {
    ExtrasSectionBannerSmallScreenBox,
    ExtrasTileBannerType
} from "../../base-component/small-screen/summary/extras-section-banner.small-screen.component";
import {
    IJourneyCustomSsrsEditorRenderer,
    SsrsSelectionPresentationMode
} from "../../../common-components/ssrs/ssrs-selection.component.props";

export class FlexExtrasSectionRenderer extends ExtrasSingleSsrSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.FLX, booking);
    }

    protected _createSsrsAggregator(): IBookingSsrsAggregatorViewModel {
        return this.booking.createSsrsAggregator({
            ssrTypes: [this.ssrType],
            useSameOptionsForAllPassengersDefaultValue: true
        })
    }

    protected _getJourneyCustomSsrsEditorRenderer(): IJourneyCustomSsrsEditorRenderer | undefined {
        return {
            presentationMode: SsrsSelectionPresentationMode.SingleScreen,
            render: journeyBucket => (<FlexSsrEditorComponent ssr={journeyBucket.getAllSsrsInTheBucket()[0]}/>)
        }
    }

    protected _renderTopBanner(): React.ReactElement | NullableString {
        if (!this._ssrsAggregator.canBeModified)
            return null;

        return (
            <ExtrasSectionBannerSmallScreenBox type={ExtrasTileBannerType.Important}>
                {this.services.language.translate('Plan for the unexpected')}
            </ExtrasSectionBannerSmallScreenBox>
        );
    }


}
