import {IDotRezSellTripRequest} from "../../data-contracts/requests/booking/sell-trip.request";
import {DotRezGraphQLMutationBuilder} from "../dot-rez-query-builder";

export function sellJourneysGraphqlMutation(request: IDotRezSellTripRequest, builder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    builder.addMutation(`mutation sellTrip($sellTripRequest: Input_TripSellRequest) {
                        tripAddv4(request: $sellTripRequest) {
                            journeys {
                                journeyKey
                            }
                        }
                    }
                `,
        {
            name: 'sellTripRequest',
            value: request
        });

    return builder;
}
