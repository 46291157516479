import {SsrTypeBase} from "../../ssr-type-base";
import {IServiceFactory} from "../../../service-factory.interface";
import React from "react";
import {BcbgSsrSmallIcon} from "./bcbg.ssr.small-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import { SsrCategoryEnum } from "../../ssr-category.enum";


export default class BcbgSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('BCBG', services);
    }

    get smallIcon() {
        return (<BcbgSsrSmallIcon/>);
    }
    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.BCBG
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.Baggage;
    }
}
