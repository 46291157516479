import {IFareDiscountType} from "./fare-discount-type.interface";
import {FareDiscountTypeBase} from "./fare-discount-type-base";
import {Price} from "../../../currency/price";
import {NullableString} from "../../../../types/nullable-types";

export class NoFareDiscount extends FareDiscountTypeBase implements IFareDiscountType {
    get discountTypeName(): string {
        if(this.fareAdapter.bundleNameToShowOnFare) {
            return this.fareAdapter.bundleNameToShowOnFare;
        }
        return this.services.language.translate('Standard');
    }

    get promotionalMessage(): NullableString {
        return null;
    }

    get isApplicable(): boolean {
        return true;
    }

    get totalPrice(): Price {
        return this._computePrice(passengerFare => passengerFare.revenueFare);
    }

    get discountedPrice(): Price {
        return this._computePrice(passengerFare => passengerFare.revenueFare);
    }

}
