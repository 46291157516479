import {IPassengerType} from "../../../passenger-types/passengers-types.service.interface";
import {
    IDotRezBookingInfant,
    IDotRezBookingPassenger, IDotRezPassengerAddress,
    IDotRezPassengerInfo,
    IDotRezPassengerName,
    IDotRezPassengerTravelDocument
} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";

import {MaturePassengerModel} from "./mature-passenger.model";
import {IInfantPassengerViewModel} from "./infant-passenger-view-model.interface";
import {PassengerModelBase} from "./passenger-model-base";
import {InfantFeeModel} from "../fees/infant/infant-fee.model";
import {NullableString} from "../../../../types/nullable-types";
import {InfantSessionStorage} from "./infant-session-storage";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {IPassengerInfoFields} from "./passenger-info-fields.interface";
import {computed, makeObservable, runInAction} from "mobx";
import {InfantNameChangeMutation} from "../mutation-actions/name-change/infant-name-change.mutation";
import {IDotRezUpdateInfantDetailsRequest} from "../../../dot-rez-api/data-contracts/requests/booking/update-infant-details.request";

export class InfantPassengerModel extends PassengerModelBase implements IInfantPassengerViewModel {
    constructor(public readonly parent: MaturePassengerModel,
                private readonly getParentData: () => IDotRezBookingPassenger) {
        super(`${parent.passengerKey}_infant`, parent.booking);

        this.storage = new InfantSessionStorage(this);
        makeObservable(this, {
            fees: computed,
            passengerTypeIndex: computed
        });
    }

    private readonly storage: InfantSessionStorage;

    get parentKey(): string {
        return this.parent.passengerKey;
    }

    get passengerType(): IPassengerType {
        return this.parent.booking.services.passengerTypes.getInfantPassengerType();
    }

    get passengerTypeIndex(): number {
        return this.parent.booking.passengers
            .filter(p => p.infant !== null)
            .findIndex(p => p.passengerKey === this.parent.passengerKey);
    }

    get isPrimaryContact(): boolean {
        return false;
    }

    set isPrimaryContact(value: boolean) {
        throw new Error('Infant cannot be primary contact');
    }

    private get data(): IDotRezBookingInfant {
        const parentData = this.getParentData();
        if(!parentData.infant) {
            throw new Error(`You created an infant for a passenger that doesn't have an infant`);
        }
        return parentData.infant;
    }

    get name(): IDotRezPassengerName {
        if(!this.data.name) {
            this.data.name = {
                title: null,
                first: null,
                last: null
            }
        }
        return this.data.name;
    }

    get info(): IDotRezPassengerInfo {
        return this.data;
    }

    get travelDocuments(): IDotRezPassengerTravelDocument[] {
        if(!this.data.travelDocuments) {
            runInAction(() => {
                this.data.travelDocuments = [];
            });
        }
        return this.data.travelDocuments;
    }

    get addresses(): IDotRezPassengerAddress[] {
        return [];
    }

    get canHaveSpecialAssistance(): boolean {
        return false;
    }

    commitChanges() {
        super.commitChanges();
        runInAction(() => {
            this.data.name.first = this.fields.firstName.value?.toUpperCase() || null;
            this.data.name.last = this.fields.lastName.value?.toUpperCase() || null;
            this.data.name.title = this.computeTitle();
            this.data.dateOfBirth = this.services.time.formatBirthDate(this.fields.dateOfBirth.value) || null;
            this.data.gender = this.fields.gender.value;
            this.data.nationality = this.fields.nationality.value;
        });
    }

    async saveInfantDetails(): Promise<void> {
        if(!this.hasChanges()) {
            return;
        }
        const request: IDotRezUpdateInfantDetailsRequest = {
            name: {
                ...this.name,
                first: this.fields.firstName.value?.toUpperCase()?.trim() || null,
                last: this.fields.lastName.value?.toUpperCase()?.trim() || null,
                title: this.computeTitle()
            },
            gender: this.fields.gender.value,
            dateOfBirth: this.services.time.formatBirthDate(this.fields.dateOfBirth.value) || null, //empty string is not good for dotREZ api
            nationality: this.fields.nationality.value
        }
        await this.booking.session.updateInfantDetails(this.parent.passengerKey, request);

        this.commitChanges();
    }

    async saveTravelDocument(travelDocument: IDotRezPassengerTravelDocument): Promise<NullableString> {
        await this.saveInfantDetails();

        if(this.isOnDomesticFlight) {
            return null;
        }

        const {passengerTravelDocumentKey} = await this.booking.session.saveInfantTravelDocument(this.parent.passengerKey, travelDocument);

        return passengerTravelDocumentKey;
    }


    get hasPerformedCheckInOnAnySegment(): boolean {
        return this.parent.hasPerformedCheckInOnAnySegment;
    }

    get fees(): InfantFeeModel[] {
        return this.data.fees.map(f => new InfantFeeModel(f, this));
    }

    protected _getInitialCustomerNumber(): NullableString {
        return this.storage.getItem(BookingSessionStorageKeys.infantCustomerNumber);
    }

    protected _onFieldsCreated(fields: FormFields<IPassengerInfoFields>) {
        super._onFieldsCreated(fields);
        fields.customerNumber.onChange((value) => {
            if(value) {
                this.storage.setItem(BookingSessionStorageKeys.infantCustomerNumber, value);
            } else {
                this.storage.removeItem(BookingSessionStorageKeys.infantCustomerNumber);
            }
        });
    }

    get nameChangeBlockingReason(): NullableString {
        return null;
    }

    protected _startNameChangeMutation() {
        this.booking.mutationsManager.startMutation(new InfantNameChangeMutation(this));
    }

    get hasSpecialPriceMarketDiscount(): boolean {
        return false;
    }
}
