import {LegBaseModel} from "../leg/leg-base.model";
import {
    IDotRezSegment
} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {computed, makeObservable} from "mobx";
import {FlightDesignatorModel} from "../../designator/flight-designator.model";
import {IServiceFactory} from "../../../../service-factory.interface";
import {FlightIdentifierModel} from "../../identifier/flight-identifier.model";
import {sumOfDiscountServiceCharges, sumOfNonTaxesServiceCharges} from "../fees/service-charges.helpers";
import {
    PassengerSegmentCheckInStatusEnum
} from "../../../../dot-rez-api/data-contracts/enums/passenger-segment-check-in-status.enum";

export abstract class SegmentBaseModel<TLeg extends LegBaseModel> {
    constructor() {
        makeObservable(this, {
            designator: computed,
            fareAmount: computed
        });
    }
    abstract get services(): IServiceFactory;
    abstract get legs(): TLeg[];
    abstract get segmentDotRezData(): IDotRezSegment;


    get designator(): FlightDesignatorModel {
        return FlightDesignatorModel.createSegmentDesignator(this.segmentDotRezData, this.services);
    }

    get standardDepartureTime(): Date {
        return this.legs[0].standardDepartureTime;
    }

    get isCanceled(): boolean {
        return this.legs.some(l => l.isCanceled);
    }

    get hasCheckedInPassengers(): boolean {
        return this.segmentDotRezData.passengerSegment.some(p => p.value.liftStatus === PassengerSegmentCheckInStatusEnum.CheckedIn);
    }

    get classOfService(): string {
        return this.segmentDotRezData.fares[0].classOfService;
    }

    isDomesticFlight(): boolean {
        return !this.segmentDotRezData.international;
    }

    get hasFlex(): boolean {
        return this.segmentDotRezData.passengerSegment.some(ps => ps.value.ssrs?.some(ssr => ssr.ssrCode === this.services.ssrTypes.FLX.ssrCode));
    }

    get flightReference(): string {
        return this.segmentDotRezData.flightReference;
    }

    get identifier(): FlightIdentifierModel{
        return new FlightIdentifierModel(
            this.segmentDotRezData.identifier.carrierCode,
            this.segmentDotRezData.identifier.identifier,
            this.services
        )
    }

    get fareAmount(): number {
        return this.segmentDotRezData.fares.sum(fare => fare.passengerFares.sum(pFare => sumOfNonTaxesServiceCharges(pFare.serviceCharges)))
    }

    get discountAmount(): number {
        return this.segmentDotRezData.fares.sum( fare =>
            fare.passengerFares.map( pFare => sumOfDiscountServiceCharges(pFare.serviceCharges))
                .filter( priceItem => priceItem<0)
                .sum( priceItem => priceItem));
    }
}
