import React from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {CheckboxComponent} from "../../../../basic-components/checkbox/checkbox.component";
import styled from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";
import {
    IFlightSearchControllerViewModel, SpecialPriceMarketUserOptions,
} from "../../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";

const CheckBoxContainer = styled(CardBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: ${props => props.theme.spacing.large};
`

const CheckBoxLabel = styled.div`
    flex-grow: 1;
    
`


interface SpecialPriceMarketUserOptionComponentProps {
    optionValue: SpecialPriceMarketUserOptions;
    searchController: IFlightSearchControllerViewModel;
    className?: string;
}

export const SpecialPriceMarketUserOptionComponent: React.FC<SpecialPriceMarketUserOptionComponentProps> = observer((props) => {
    const services = useServices();
    const searchController = props.searchController;
    const specialPriceMarket = searchController.getSpecialPriceMarket();
    if(!specialPriceMarket) {
        return null;
    }

    const onClick = () => {
        if(props.optionValue === searchController.specialPriceMarketUserOption) {
            searchController.specialPriceMarketUserOption = SpecialPriceMarketUserOptions.None;
        } else {
            searchController.specialPriceMarketUserOption = props.optionValue;
        }
    }

    const getLabel = () => {
        if(props.optionValue === SpecialPriceMarketUserOptions.Regular) {
            return services.language.translationFor('Are you a resident in {market}?').withParams({market: specialPriceMarket.getMarketNameTranslation()});
        } else {
            return services.language.translationFor('Are you a worker in {market}?').withParams({market: specialPriceMarket.getMarketNameTranslation()});
        }

    }

    return (
        <CheckBoxContainer className={props.className} onClick={onClick}>
            <CheckboxComponent checked={searchController.specialPriceMarketUserOption === props.optionValue} isReadOnly={true}/>
            <CheckBoxLabel>
                {getLabel()}
            </CheckBoxLabel>
        </CheckBoxContainer>
    )
});
