import {IDeepLinkHandler} from "../deep-link-handler.interface";
import {IServiceFactory} from "../../service-factory.interface";

export class ConfirmRegistrationDeepLinkHandler implements IDeepLinkHandler {
    constructor(private readonly services: IServiceFactory) {
    }
    canHandler(link: string): boolean {
        return 0 <= link.toLowerCase().indexOf('/api/user/v1/confirmregistration');
    }

    async execute(link: string): Promise<void> {
        const url = new URL(link);
        const customerNumber = url.searchParams.get('customerNumber');
        if(!customerNumber) {
            return;
        }

        await this.services.user.confirmRegistration(customerNumber);
    }

}
