import {FormModel} from "../../../models/forms/form.model";
import {IServiceFactory} from "../../../services/service-factory.interface";
import {FormFields, IFormField} from "../../../models/forms/form-field.interface";
import {NullableDate, NullableUndefinedDate, UndefinedNumber} from "../../../types/nullable-types";

interface IDateParts {
    year: number;
    month: number;
    day: number
}

export class DateFieldEditorModel extends FormModel<IDateParts> {
    constructor(private readonly dateField: IFormField<NullableDate>,
                private readonly minimumValue: NullableUndefinedDate,
                private readonly maximumValue: NullableUndefinedDate,
                services: IServiceFactory) {
        super(services);
    }


    protected _createFields(): FormFields<IDateParts> {
        const language = this.services.language;
        return {
            year: this._createField<number>({
                fieldName: () => language.translate('Year'),
                defaultValue: this.dateField.value?.getFullYear() ?? null,
                isRequired: false,
                maxLength: 4,
                validate: () => {
                    return this.dateField.error;
                }
            }),
            month: this._createField<number>({
                fieldName: () => language.translate('Month'),
                defaultValue: this.dateField.value?.getMonth() ? this.dateField.value.getMonth() + 1 : null,
                isRequired: false,
                maxLength: 2,
                validate: () => {
                    return this.dateField.error;
                }
            }),
            day: this._createField<number>({
                fieldName: () => language.translate('Day'),
                defaultValue: this.dateField.value?.getDate() ?? null,
                isRequired: false,
                maxLength: 2,
                validate: () => {
                    return this.dateField.error;
                }
            })
        };
    }

    private _clearExceedingField(field: IFormField<number>, minValue: UndefinedNumber, maxValue: UndefinedNumber): void {
        if(field.value) {
            if(minValue && field.value < minValue) {
                field.setValue(null);
            } else if(maxValue && field.value > maxValue) {
                field.setValue(null);
            }
        }
    }

    protected _onFieldsCreated(fields: FormFields<IDateParts>) {
        super._onFieldsCreated(fields);
        fields.year.onChange(newYearValue => {
            if(newYearValue) {
                this._clearExceedingField(this.fields.month, this.minMonth, this.maxMonth);
                if(this.fields.month.value) {
                    this._clearExceedingField(this.fields.day, this.minDay, this.maxDay);
                }
            }
        });

        fields.month.onChange(newMonthValue => {
            if(newMonthValue) {
                this._clearExceedingField(this.fields.day, this.minDay, this.maxDay);
            }
        })
    }

    protected _onFieldChanged(field: IFormField) {
        super._onFieldChanged(field);

        const year = this.fields.year.value;
        const month = this.fields.month.value;
        const day = this.fields.day.value;

        if(year && month && day) {
            this.dateField.setValue(new Date(year, month - 1, day));
        } else {
            this.dateField.setValue(null);
        }

    }

    get minYear(): UndefinedNumber {
        if(this.minimumValue) {
            return this.minimumValue.getFullYear();
        }

        return undefined;
    }

    get maxYear(): UndefinedNumber {
        if(this.maximumValue) {
            return this.maximumValue.getFullYear();
        }
        return undefined;
    }

    get minMonth(): UndefinedNumber {
        if(!this.minimumValue) {
            return undefined;
        }

        if(this.fields.year.value === this.minimumValue.getFullYear()) {
            return this.minimumValue.getMonth() + 1;
        }

        return undefined;
    }

    get maxMonth(): UndefinedNumber {
        if(!this.maximumValue) {
            return undefined;

        }
        if(this.fields.year.value === this.maximumValue.getFullYear()) {
            return this.maximumValue.getMonth() + 1;
        }
        return undefined;
    }

    get minDay(): UndefinedNumber {
        if(!this.minimumValue) {
            return undefined;
        }
        if(this.fields.year.value === this.minimumValue.getFullYear() && this.fields.month.value === (this.minimumValue.getMonth() + 1)) {
            return this.minimumValue.getDate();
        }

        return undefined;

    }

    get maxDay(): UndefinedNumber {
        if(!this.maximumValue) {
            return undefined;
        }

        if(this.fields.year.value === this.maximumValue.getFullYear() && this.fields.month.value === (this.maximumValue.getMonth() + 1)) {
            return this.maximumValue.getDate();
        }

        return undefined;
    }
}
