import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IonSkeletonText} from "@ionic/react";

const CalendarSkeletonBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const MonthNameSkeletonBox = styled(IonSkeletonText)`
    width: 100px;
    height: 34px;
    margin-top: 15px;
    margin-bottom: 5px;
`

const CalendarDaysNamesSkeleton = styled(IonSkeletonText)`
  width: 95%;
  height: 48px;
  margin: 20px;
`

const CalendarDaysSkeletonBox = styled(IonSkeletonText)`
    width: 95%;
    height: 290px;
`

const CalendarSkeletonComponent: React.FC = () => {
    return (
        <CalendarSkeletonBox>
            <MonthNameSkeletonBox animated/>
            <CalendarDaysNamesSkeleton/>
            <CalendarDaysSkeletonBox animated/>
        </CalendarSkeletonBox>
    );
}

export const FlightDatesSelectionSkeletonsComponent: React.FC = observer(() => {
    return (
        <>
            <CalendarSkeletonComponent/>
            <CalendarSkeletonComponent/>
        </>
    )
});
