import {IFormField} from "../../../models/forms/form-field.interface";
import React from "react";
import {observer} from "mobx-react";
import {FieldViewComponent} from "../field-view.component";

interface StringFieldViewComponentProps {
    field: IFormField;
}

export const StringFieldViewComponent: React.FC<StringFieldViewComponentProps> = observer((props) => {
    return (
        <FieldViewComponent label={props.field.fieldName} value={props.field.value}/>
    );
});

