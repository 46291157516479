import {ICheckInRestriction, ICheckInRestrictionAction} from "./check-in-restriction.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {TimeSpan} from "../../../../types/time-span";
import {PassengerSegmentModel} from "../passenger-segment/passenger-segment.model";

export class NoSeatPurchasedCheckInRestriction implements ICheckInRestriction {
    constructor(private readonly bundleEarliestCheckIn: TimeSpan,
                private readonly passengerSegment: PassengerSegmentModel,
                private readonly services: IServiceFactory) {
    }

    get description(): string {
        return this.services.language.translationFor('Your bundle allows online check-in within {time} before departure, however if you purchase a seat you can perform check-in earlier.').withParams({time: this.bundleEarliestCheckIn.toUserFriendlyString(this.services.language, {ignoreSeconds: true, convertDaysToHoursIfLessThan: 2})});
    }
    get shouldBlockSavingTravelDocuments(): boolean {
        return false;
    }

    get action(): ICheckInRestrictionAction {
        return {
            description: this.services.language.translate('Purchase a seat'),
            execute: async () => {

                const booking = this.services.booking.current;
                booking.seatsMapsEditors.setCurrentPassengerSegment(this.passengerSegment);
                await this.services.dialogFactory.showSeatsSelection();
            }
        };
    }
}
