import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {StringFieldEditorComponent} from "../../../../components/forms/string-field/string-field-editor.component";
import {GenderFieldEditorComponent} from "../../../../components/forms/gender/gender-field-editor.component";
import {DateFieldEditorComponent} from "../../../../components/forms/date-time/date-field-editor.component";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {CountryPickerComponent} from "../../../../components/country-picker/country-picker.component";
import {TravelDocumentTypeFieldEditorComponent} from "../../../../components/forms/travel-document-type/travel-document-type-field-editor.component";
import {PassengerAvailableTravelDocumentsComponent} from "./passenger-available-travel-documents.component";
import {CardBox} from "../../../../basic-components/card/card.box";

const PassengerNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    text-transform: uppercase;
`

const PassengerTravelDocumentBox = styled(CardBox)`
  display: flex;
  flex-direction: row;
`

const PassengerFieldsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large};
    width: 100%;
`

interface PassengerTravelDocumentComponentProps {
    passenger: IPassengerViewModel;
}
export const PassengerTravelDocumentComponent: React.FC<PassengerTravelDocumentComponentProps> = observer((props) => {
    const services = useServices();
    const travelDocument = props.passenger.travelDocument;
    const fields = travelDocument.fields;
    const minimumBirthDate = props.passenger.computeMinimumBirthDate();
    const maximumBirthDate = props.passenger.computeMaximumBirthDate();
    let minimumTravelDocumentIssuedDate = fields.dateOfBirth.value;
    let maximumTravelDocumentIssuedDate = travelDocument.computeMaximumTravelDocumentIssuedDate();
    let minimumTravelDocumentExpiration = travelDocument.computeMinimumTravelDocumentExpirationDate();
    let maximumTravelDocumentExpiration = services.time.addYears(services.time.currentDate, 100);


    const renderPassengerFields = () => {
        return (
            <>
                <GenderFieldEditorComponent key={fields.gender.fieldName}
                                            field={fields.gender}
                                            useGenderAsNumber={false}/>
                <DateFieldEditorComponent key={fields.dateOfBirth.fieldName}
                                          field={fields.dateOfBirth}
                                          minimumValue={minimumBirthDate}
                                          maximumValue={maximumBirthDate}/>
            </>
        );
    }

    return (
        <PassengerTravelDocumentBox>
            <PassengerFieldsBox>
                <PassengerNameBox>
                    {props.passenger.getFullName()}
                </PassengerNameBox>
                {renderPassengerFields()}
                <TravelDocumentTypeFieldEditorComponent key={fields.documentTypeCode.fieldName}
                                                        field={fields.documentTypeCode}/>
                <StringFieldEditorComponent key={fields.number.fieldName}
                                            field={fields.number}/>
                <CountryPickerComponent key={fields.issuedByCode.fieldName}
                                        field={fields.issuedByCode}/>
                <DateFieldEditorComponent key={fields.issuedDate.fieldName}
                                          field={fields.issuedDate}
                                          minimumValue={minimumTravelDocumentIssuedDate}
                                          maximumValue={maximumTravelDocumentIssuedDate}/>
                <DateFieldEditorComponent key={fields.expirationDate.fieldName}
                                          field={fields.expirationDate}
                                          minimumValue={minimumTravelDocumentExpiration}
                                          maximumValue={maximumTravelDocumentExpiration}/>
                <CountryPickerComponent key={fields.nationality.fieldName}
                                        field={fields.nationality}
                                        popupHeaderTitle={services.language.translate('Search citizenship')}/>
            </PassengerFieldsBox>
            <PassengerAvailableTravelDocumentsComponent passenger={props.passenger}/>
        </PassengerTravelDocumentBox>
    );
});
