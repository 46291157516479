import styled from "styled-components";
import {IBookingShoppingCartViewModel} from "../../services/booking/models/shopping-cart/booking/booking-shopping-cart-view-model.interface";
import React, {useState} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import {ShoppingCartModeEnum} from "../../services/booking/booking-strategies/booking-strategy.interface";
import {CheckboxComponent} from "../../basic-components/checkbox/checkbox.component";
import {CardBox} from "../../basic-components/card/card.box";

const ShoppingCartModeToggleBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${props => props.theme.spacing.medium};
`

const ShoppingCartModesBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

const TitleBox = styled.div`
    width: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: ${props => props.theme.spacing.medium};
`

const DisplayModeOptionBox = styled(CheckboxComponent)`
    font-size: ${props => props.theme.fontSize.medium};
`

interface ShoppingCartModeToggleComponentProps {
    shoppingCart: IBookingShoppingCartViewModel;
}

export const ShoppingCartModeToggleComponent: React.FC<ShoppingCartModeToggleComponentProps> = observer((props) => {
    const services = useServices();
    const [rerenderComponent, setRerenderComponent] = useState(false);
    if(props.shoppingCart.allowedShoppingCartModes.length <= 1) {
        return null;
    }

    const getOptionText = (mode: ShoppingCartModeEnum) => {
        if(mode === ShoppingCartModeEnum.ShowAllPurchases) {
            return services.language.translate('All purchases');
        } else {
            return services.language.translate('Only differences');
        }
    }

    const renderOption = (mode: ShoppingCartModeEnum) => {
        return (
            <DisplayModeOptionBox key={mode}
                                  label={getOptionText(mode)}
                                  labelPlacement={'end'}
                                  checked={props.shoppingCart.currentShoppingCartMode === mode}
                                  onClick={() => props.shoppingCart.currentShoppingCartMode = mode}
                // Doing this to prevent the user to uncheck the box.
                // If the checkbox is already checked, when the user clicks on it then it will be unchecked
                // because the top level click handler above will set props.shoppingCart.currentShoppingCartMode to the same existing value
                // so there will be no rerender of the component. With this trick we force the component to rerender and the checkbox will recalculate again the ckecked state
                               onChange={() => setRerenderComponent(!rerenderComponent)}
            />
        )
    }

    return (
        <ShoppingCartModeToggleBox>
            <TitleBox>
                {services.language.translate('Display')}
            </TitleBox>
            <ShoppingCartModesBox>
                {props.shoppingCart.allowedShoppingCartModes.map(mode => renderOption(mode))}
            </ShoppingCartModesBox>

        </ShoppingCartModeToggleBox>
    );
});
