import {SsrTypeBase} from "../../ssr-type-base";
import {ISsrType} from "../../ssr-types.service.interface";
import { SsrCategoryEnum } from "../../ssr-category.enum";
import {NullableNumber, NullableString} from "../../../../types/nullable-types";
import {TimeSpan} from "../../../../types/time-span";

export abstract class CheckInBagsSsrBase extends SsrTypeBase {
    abstract get bagWeightKg(): number;

    getRelatedSsrsThatShareTheSameMaxQuantity(): ISsrType[] {
        return this.services.ssrTypes.getAllCheckInBags()
            .filter(b => b.ssrCode !== this.ssrCode)
            .sort((s1, s2) => s1.preferredOrderInLists - s2.preferredOrderInLists);
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.Baggage;
    }

    get sharedMaxQuantity(): NullableNumber {
        return this.services.configuration.data.maximumBagsQuantityPerPassenger;
    }

    get shouldWriteQuantityChangeStatusInSessionStorage(): boolean {
        return true;
    }

    getCustomMessageForMaxLimitPerPassengerReached(): NullableString {
        return this.services.language.translate('Maximum number of bags per passenger has been reached');
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(3);
    }


}
