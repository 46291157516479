import {IPassengerSegmentBoardingPassViewModel} from "../../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {action, makeObservable, observable} from "mobx";

export class BoardingPassesStorage {
    constructor(private readonly services: IServiceFactory,
                private readonly storageKey: string) {
        this.boardingPasses = services.localStorage.getJson<Record<string, IPassengerSegmentBoardingPassViewModel[]>>(storageKey) || {};

        this._fixPassengerKey(); //TODO - this should be removed at some point

        makeObservable(this, {
            boardingPasses: observable,
            saveBoardingPasses: action.bound,
            removeBoardingPasses: action.bound
        });
    }

    readonly boardingPasses: Record<string, IPassengerSegmentBoardingPassViewModel[]>;

    private _fixPassengerKey(): void {
        Object.keys(this.boardingPasses).forEach(key => {
            const tripBoardingPasses = this.boardingPasses[key];
            for(let i = 0; i < tripBoardingPasses.length; i++) {
                let bp = tripBoardingPasses[i];
                if(bp.isInfant) {
                    bp = {
                        ...bp,
                        passengerKey: bp.passengerKey.replaceAll('_infant', '')
                    };
                }
            }
        })
        this._persistBoardingPasses();
    }

    private _persistBoardingPasses(): void {
        if(!this.services.layout.shouldUseWebappLayout) {
            this.services.localStorage.setJson(this.storageKey, this.boardingPasses);
        }
    }

    saveBoardingPasses(newBoardingPasses: IPassengerSegmentBoardingPassViewModel[]): IPassengerSegmentBoardingPassViewModel[] {
        const newBoardingPassesGroupedByTripReferenceKey = newBoardingPasses.groupByKey(bPass => bPass.tripReferenceKey);

        Object.keys(newBoardingPassesGroupedByTripReferenceKey).forEach(key => {
            this.boardingPasses[key] = newBoardingPassesGroupedByTripReferenceKey[key];
        });

        this._persistBoardingPasses();

        return newBoardingPasses;
    }

    getBoardingPasses(tripReferenceKey: string): IPassengerSegmentBoardingPassViewModel[] {
        return this.boardingPasses[tripReferenceKey] || [];
    }

    removeBoardingPasses(tripReferenceKeys: string[]): void {
        tripReferenceKeys.forEach(key => {
            if(this.boardingPasses[key]) {
                delete this.boardingPasses[key];
            }
        });

        this._persistBoardingPasses();
    }
}
