import {IBookingSsrsAggregatorViewModel} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import React from "react";
import {SameForAllFlightsToggleComponent} from "../same-for-all-flights-toggle.component";

function renderDisclaimer(disclaimer: string | React.ReactElement | undefined) {
    if(disclaimer) {
        return <>{disclaimer}</>;
    }

    return null;
}

function renderSameForAllFlights(ssrsAggregator: IBookingSsrsAggregatorViewModel) {
    if(!ssrsAggregator.allowUseSameOptionsForAllFlights) {
        return null;
    }

    return (
        <SameForAllFlightsToggleComponent ssrsBucket={ssrsAggregator}/>
    );
}


export function renderDisclaimerAndSameForAllFlights(ssrsAggregator: IBookingSsrsAggregatorViewModel, disclaimer: string | React.ReactElement | undefined) {

    return (
        <>
            {renderDisclaimer(disclaimer)}
            {renderSameForAllFlights(ssrsAggregator)}
        </>
    )

}
