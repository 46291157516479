import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {IDotRezPartialBookingSessionData} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {BookingModel} from "../../booking.model";

export class ResellDepartureJourneyBundleMutation extends BookingMutationActionBase {
    constructor(booking: BookingModel, private bundleCodeToResell: string) {
        super(booking)
    }

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {

        const journeyKey = this.booking.unfilteredJourneys[0]?.journeyKey;
        if(!journeyKey) {
            throw new Error('There is no departure journey');
        }

        await this.session.sellBundle([journeyKey] , [], {
            bundleCode: this.bundleCodeToResell
        });

        return await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();
    }

}
