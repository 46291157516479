import {SellJourneyMutation} from "./sell-journey.mutation";
import {
    IDotRezPartialBookingSessionData
} from "../../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {NullableUndefinedString} from "../../../../../../types/nullable-types";


export class SellDepartureJourneyMutation extends SellJourneyMutation {

    private _existingBundleBeforeSell: NullableUndefinedString = undefined;
    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {

        this._existingBundleBeforeSell = this.booking.departureJourney?.selectedBundle?.bundleCode;
        let journeysToCancel: string[] = [];
        if(this.services.booking.searchController.withBlueBenefits) {
            // When using blue benefits we need to reset the booking when switching between standard and blue benefits price otherwise dotREZ is not able to update the passenger type (ADT vs ADTA)
            // When we reset the booking the passenger details are lost if the user filled in some passenger details.
            // For this reason we do this reset only when BlueBenefits is active.
            if (this.departureJourney) {
                await this.booking.reset();
            }
        } else {
            if(this.departureJourney) {
                journeysToCancel.push(this.departureJourney.journeyKey);
            }

            if(this.returnJourney) {
                journeysToCancel.push(this.returnJourney.journeyKey);
            }
        }

        await this._sellTrip(journeysToCancel, []);


        return {};
    }

    protected async onAfterSell(bookingSessionData: IDotRezPartialBookingSessionData): Promise<void> {

        await super.onAfterSell(bookingSessionData);

        if(this.fareToSell.withBlueBenefits && this.booking.blueBenefits.shouldLockFirstPassengerOnBooking) {
            this.booking.passengers[0].markAsBlueBenefitsPassenger();
        }

        const availableBundles = this.booking.departureJourney?.bundlesAvailability ?? [];
        if(availableBundles.length === 1) {
            await this.booking.sellDepartureJourneyBundle(availableBundles[0].bundleCode);
        } else if(this._existingBundleBeforeSell) {
            if(availableBundles.find(b => b.bundleCode === this._existingBundleBeforeSell)) {
                await this.booking.sellDepartureJourneyBundle(this._existingBundleBeforeSell);
            }
        }

    }

}
