import React from 'react';
import {observer} from "mobx-react-lite";
import {IAeroitaliaInvoiceViewModel} from "../../../../services/booking/models/invoice/aeroitalia/aeroitalia.invoice-view-model.interface";
import {ItalyCompanyTypeDropDownComponent} from "./italy-company-type.drop-down.component";
import {StringFieldEditorComponent} from "../../../../components/forms/string-field/string-field-editor.component";
import {EmailFieldEditorComponent} from "../../../../components/forms/contact/email-field-editor.component";
import {AeroitaliaInvoiceRegionSelectorComponent} from "./aeroitalia-invoice-region-selector.component";
import {AeroitaliaInvoiceCitySelectorComponent} from "./aeroitalia-invoice-city-selector.component";
import {FormComponent} from "../../../../components/forms/form.component";
import {AeroitaliaInvoiceCountryPickerComponent} from "./aeroitalia-invoice-country-picker.component";

interface AeroitaliaInvoiceFormComponentProps {
    invoice: IAeroitaliaInvoiceViewModel;
    onSubmit?: () => void;
    title?: string;
}
export const AeroitaliaInvoiceFormComponent: React.FC<AeroitaliaInvoiceFormComponentProps> = observer((props) => {
    const fields = props.invoice.fields;
    return (
        <FormComponent onSubmit={props.onSubmit} title={props.title}>
            <ItalyCompanyTypeDropDownComponent key={fields.customerType.fieldName} field={fields.customerType}/>
            <StringFieldEditorComponent key={fields.customerName.fieldName} field={fields.customerName}/>
            <StringFieldEditorComponent key={fields.firstName.fieldName} field={fields.firstName}/>
            <StringFieldEditorComponent key={fields.lastName.fieldName} field={fields.lastName}/>
            <StringFieldEditorComponent key={fields.fiscalCode.fieldName} field={fields.fiscalCode}/>
            <StringFieldEditorComponent key={fields.vat.fieldName} field={fields.vat}/>
            <StringFieldEditorComponent key={fields.recipientCode.fieldName} field={fields.recipientCode}/>
            <EmailFieldEditorComponent key={fields.emailPec.fieldName} field={fields.emailPec}/>
            <AeroitaliaInvoiceCountryPickerComponent key={fields.countryCode.fieldName} invoice={props.invoice}/>
            <AeroitaliaInvoiceRegionSelectorComponent key={fields.region.fieldName} field={fields.region}/>
            <AeroitaliaInvoiceCitySelectorComponent key={fields.city.fieldName} fields={fields}/>
            <StringFieldEditorComponent key={fields.address.fieldName} field={fields.address}/>
            <StringFieldEditorComponent key={fields.addressNumber.fieldName} field={fields.addressNumber}/>
            <StringFieldEditorComponent key={fields.zipCode.fieldName} field={fields.zipCode}/>
        </FormComponent>
    );
});
