import {IAssetResolver} from "../asset-resolver.interface";
import {DefaultTheme} from "styled-components";
import {AirlineThemeColors, IThemeBorders, IThemeSpacing} from "../interfaces/airline.theme";
import {GlobalStyles} from "../global-styles";
import {makeAssets} from "../models/make-assets";
import {mediaQueries} from "../../media-query/media-queries";

const Colors: AirlineThemeColors = {

    secondWebView: {
        toolbar: {
            backgroundColor: "#338141",
            color: "#ffffff"
        }
    },

    /** primary **/
    primary:'var(--ion-color-primary)',
    primaryRgb:'var(--ion-color-primary-rgb)',
    primaryContrast:'var(--ion-color-primary-contrast)',
    primaryContrastRgb:'var(--ion-color-primary-contrast-rgb)',
    primaryShade:'var(--ion-color-primary-shade)',
    primaryTint:'var(--ion-color-primary-tint)',

    /** secondary **/
    secondary:'var(--ion-color-secondary)',
    secondaryRgb:'var(--ion-color-secondary-rgb)',
    secondaryContrast:'var(--ion-color-secondary-contrast)',
    secondaryContrastRgb:'var(--ion-color-secondary-contrast-rgb)',
    secondaryShade:'var(--ion-color-secondary-shade)',
    secondaryTint:'var(--ion-color-secondary-tint)',

    /** tertiary **/
    tertiary:'var(--ion-color-tertiary)',
    tertiaryRgb:'var(--ion-color-tertiary-rgb)',
    tertiaryContrast:'var(--ion-color-tertiary-contrast)',
    tertiaryContrastRgb:'var(--ion-color-tertiary-contrast-rgb)',
    tertiaryShade:'var(--ion-color-tertiary-shade)',
    tertiaryTint:'var(--ion-color-tertiary-tint)',

    /** success **/
    success:'var(--ion-color-success)',
    successRgb:'var(--ion-color-success-rgb)',
    successContrast:'var(--ion-color-success-contrast)',
    successContrastRgb:'var(--ion-color-success-contrast-rgb)',
    successShade:'var(--ion-color-success-shade)',
    successTint:'var(--ion-color-success-tint)',

    /** warning **/
    warning:'var(--ion-color-warning)',
    warningRgb:'var(--ion-color-warning-rgb)',
    warningContrast:'var(--ion-color-warning-contrast)',
    warningContrastRgb:'var(--ion-color-warning-contrast-rgb)',
    warningShade:'var(--ion-color-warning-shade)',
    warningTint:'var(--ion-color-warning-tint)',

    /** danger **/
    danger:'var(--ion-color-danger)',
    dangerRgb:'var(--ion-color-danger-rgb)',
    dangerContrast:'var(--ion-color-danger-contrast)',
    dangerContrastRgb:'var(--ion-color-danger-contrast-rgb)',
    dangerShade:'var(--ion-color-danger-shade)',
    dangerTint:'var(--ion-color-danger-tint)',

    /** dark **/
    dark:'var(--ion-color-dark)',
    darkRgb:'var(--ion-color-dark-rgb)',
    darkContrast:'var(--ion-color-dark-contrast)',
    darkContrastRgb:'var(--ion-color-dark-contrast-rgb)',
    darkShade:'var(--ion-color-dark-shade)',
    darkTint:'var(--ion-color-dark-tint)',

    /** medium **/
    medium:'var(--ion-color-medium)',
    mediumRgb:'var(--ion-color-medium-rgb)',
    mediumContrast:'var(--ion-color-medium-contrast)',
    mediumContrastRgb:'var(--ion-color-medium-contrast-rgb)',
    mediumShade:'var(--ion-color-medium-shade)',
    mediumTint:'var(--ion-color-medium-tint)',

    /** light **/
    light:'var(--ion-color-light)',
    lightRgb:'var(--ion-color-light-rgb)',
    lightContrast:'var(--ion-color-light-contrast)',
    lightContrastRgb:'var(--ion-color-light-contrast-rgb)',
    lightShade:'var(--ion-color-light-shade)',
    lightTint:'var(--ion-color-light-tint)',
}

function makeSpacing(): IThemeSpacing {
    const spacing = (multiplier: number = 1) => {
        return 4 * multiplier;
    }

    const spacingPx = (multiplier: number = 1) => {
        return spacing(multiplier) + 'px';
    }

    return {
        space: spacing,
        spacePx: spacingPx,
        small: spacingPx(1),
        medium:  spacingPx(2),
        large: spacingPx(3),
        extraLarge: spacingPx(5),
        pageDefaultPaddingPx: spacingPx(3),
        cardDefaultPadding: spacing(3),
        cardDefaultPaddingPx: spacingPx(3)
    };
}

function makeThemeBorder(): IThemeBorders {
    const borderRadius = (multiplier: number = 1) => {
        return 8 * multiplier;
    }

    const borderRadiusPx = (multiplier: number = 1) => {
        return borderRadius(multiplier) + 'px';
    }

    return {
        radius: borderRadius,
        radiusPx: borderRadiusPx,
        defaultRadius: borderRadiusPx(1),
        mainColor: Colors.lightShade
    };
}

export function makeTheme(assetsResolver: IAssetResolver): DefaultTheme {


    return {
        globalStyles: (<GlobalStyles/>),
        assets: makeAssets(assetsResolver),
        colors: Colors,
        fontWeight: {
            normal: 'normal',
            bold: 'bold'
        },
        fontSize: {
            xxxxSmall: '0.9rem',
            xxxSmall: '1rem',
            xxSmall: '1.2rem',
            xSmall: '1.3rem',
            small: '1.4rem',
            medium: '1.6rem',
            mediumLarge: '1.7rem',
            large: '1.8rem',
            xLarge: '1.9rem',
            xxLarge: '2rem',
            xxxLarge: '2.4rem',
            xxxxLarge: '2.6rem',
            xxxxxLarge: '3rem',
            xxxxxxLarge: '3.4rem',
            xxxxxxxLarge: '3.6rem',
        },
        spacing: makeSpacing(),
        border: makeThemeBorder(),
        mediaQuery: mediaQueries
    }
}
