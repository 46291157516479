import {FinalPriceBaseStep} from "../common/final-price-base-step";
import {IRoute} from "../../../navigation/models/route.interface";
import {IManageMyBookingRoutes} from "../../../navigation/routes/manage-my-booking/manage-my-booking.routes.interface";
import {IOnBeforeDeactivationContext} from "../../../../models/wizard/wizard.step.interface";
import {DialogResult} from "../../../dialog/dialog-enums";

export class ManageMyBookingFinalPriceStep extends FinalPriceBaseStep<IManageMyBookingRoutes> {

    protected get routes(): IManageMyBookingRoutes {
        return this.services.navigator.routes.manageMyBooking;
    }

    get route(): IRoute {
        return this.routes.finalPrice;
    }

    protected async _goToPaymentStep(): Promise<void> {
        this._activateStepByRoute(this.routes.payment);
    }

    protected async _onBeforeForwardDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        //On manage my booking if there are no purchases the booking is saved in the final price step
        //and after that we do BookingService.switchToManageMyBooking which in turn it will do steps[0].activate which navigates to MMB dashboard,
        //but this will trigger the validation of this FinalPriceStep which will show the message "Please accept the Travel Conditions"
        if(context.routingGuardContext.targetLocation.pathname !== this.wizard.steps[0].route.path) {
            return super._onBeforeForwardDeactivation(context);
        } else {
            return DialogResult.Accepted;
        }

    }
}
