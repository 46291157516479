import React from "react";
import {observer} from "mobx-react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableGender} from "../../../services/dot-rez-api/data-contracts/enums/gender.enum";
import {GenderValueViewComponent} from "./gender-value-view.component";

interface GenderFieldViewComponentProps {
    field: IFormField<NullableGender>;
}
export const GenderFieldViewComponent: React.FC<GenderFieldViewComponentProps> = observer((props) => {

    return (
        <GenderValueViewComponent label={props.field.fieldName} value={props.field.value}/>
    );
});
