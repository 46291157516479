import {IPdfExporterStrategy} from "./pdf-exporter.strategy.interface";
import {IPrintPdfOptions, ISharePdfOptions} from "../pdf-exporter.service.interface";
import {PDFGenerator} from "@ionic-native/pdf-generator";
import {SocialSharing} from "@ionic-native/social-sharing";
import {IServiceFactory} from "../../service-factory.interface";

export class PdfExporterHybridStrategy implements IPdfExporterStrategy {
    constructor(private readonly services: IServiceFactory) {
    }
    readonly supportsSharing = true;
    async printPdf(options: IPrintPdfOptions): Promise<void> {
        try {
            await PDFGenerator.fromData(options.element.outerHTML, {
                documentSize: 'A4',
                type: 'share',
                fileName: options.fileName
            });
        } catch (err) {
            this.services.logger.error('Failed to print PDF', err);
        }
    }

    async sharePdf(options: ISharePdfOptions): Promise<void> {

        try {
            const fileContent = await PDFGenerator.fromData(options.element.outerHTML, {
                documentSize: 'A4',
                type: 'base64'
            });

            const url = 'data:application/pdf;base64,' + fileContent;

            await SocialSharing.share(options.message, options.subject, url);
        } catch (err) {
            this.services.logger.error('Failed to share boarding pass', err);
        }
    }
}
