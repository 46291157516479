import React from 'react';
import {ISeatViewModel} from "../../../../services/booking/models/seat-maps/seat-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import classNames from "classnames";
import {PersonNoCircleIcon} from "../../../../assets/svg/person-no-circle.icon";
import {XIcon} from "../../../../assets/svg/x.icon";
import {SeatsRowGroupType} from "../../../../services/booking/models/seat-maps/seats-row-group-type.enum";
import styled from "styled-components";
import {CssRules} from "../../../../services/theme/helpers/css-rules";

const IconBox = styled.div`
    margin-top: 1px;
    margin-bottom: 3px;
`

export const PassengerInitialsBox = styled.div`
    line-height: 0.8;
    margin-top: 2px;
`


export const SeatBox = styled.div`
  display: flex;
  margin: 0 6% 0 0;
  padding: 1px 1px 8px 1px;
  height: 41px;
  width: 36px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.primaryContrast};
  border-radius: 5px;
  line-height: 1;
  font-size: ${props => props.theme.fontSize.medium};
  flex-direction: column;
  position: relative;
  cursor: pointer;
  
  &:last-of-type {
      margin-right: 0;
  }

  &:after {
      content: '';
      position: absolute;
      bottom: 7px;
      left: -5px;
      width: calc(100% + 2px);
      height: calc(100% - 12px);
      border-left: 4px solid ${props => `rgba(${props.theme.colors.secondaryRgb}, 0.4)`};
      border-right: 4px solid ${props => `rgba(${props.theme.colors.secondaryRgb}, 0.4)`};
      background: linear-gradient(to bottom, transparent 20%, transparent 20% calc(100% - 2px), ${props => props.theme.colors.primaryContrast} calc(100% - 2px) calc(100% - 1px), transparent 1px);
  }
`

const MissingSeatBox = styled.div`
  display: flex;
  margin: 0 6% 0 0;
  padding: 1px 1px 8px 1px;
  height: 41px;
  width: 36px;
`

export const SeatNotAvailableBox = styled(SeatBox)`
    color: ${props => props.theme.colors.mediumContrast};
    background-color: ${props => props.theme.colors.medium};
    cursor: not-allowed;
    &:after {
        border-color: ${props => props.theme.colors.medium};
    }
`

export const SeatSelectedBox = styled(SeatBox)`
    color: ${props => props.theme.colors.warningContrast};
    background-color: ${props => props.theme.colors.warning}; 
    font-size: ${props => props.theme.fontSize.medium};
    &:after {
        border-color: ${props => props.theme.colors.warning};
    }
`

export const RearSeatBox = styled(SeatBox)`
  background-color: ${props => props.theme.colors.tertiaryTint};
  &:after {
    border-color: ${props => props.theme.colors.tertiaryTint};
  }
`

export const FrontSeatBox = styled(SeatBox)`
    background-color: ${props => props.theme.colors.secondary};
    &:after {
        border-color: ${props => props.theme.colors.secondary};
    }
`

export const ExtraLegSeatBox = styled(SeatBox)`
    margin-top: 27px;
    flex-direction: column;
    background-color: ${props => props.theme.colors.primaryShade};
    &:after {
        border-color: ${props => props.theme.colors.primaryShade};
    }
    &:before {
        content: '';
        position: absolute;
        top: -27px;
        left: 0;
        width: calc(100% - 2px);
        height: 33px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="20.011" xml:space="preserve"><path d="M3.292,7.707A1,1,0,0,0,4.707,6.293L3.413,5H16.6L15.3,6.293a1,1,0,0,0,1.414,1.414l3-3a1,1,0,0,0,0-1.416l-3-3A1,1,0,1,0,15.3,1.708L16.6,3H3.413L4.707,1.708A1,1,0,1,0,3.292.294l-3,3a1,1,0,0,0,0,1.414Z" transform="translate(0 20.011) rotate(-90)" fill="rgb(7,33,88)"/></svg>');
        background-size: auto;
        background-position: center calc(50% - 4px);
        background-repeat: no-repeat;
        border: 1px dotted ${props => props.theme.colors.primaryShade};
        border-radius: 7px;
        border-bottom: 0;

      ${CssRules.DarkMode} &{
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="20.011" xml:space="preserve"><path d="M3.292,7.707A1,1,0,0,0,4.707,6.293L3.413,5H16.6L15.3,6.293a1,1,0,0,0,1.414,1.414l3-3a1,1,0,0,0,0-1.416l-3-3A1,1,0,1,0,15.3,1.708L16.6,3H3.413L4.707,1.708A1,1,0,1,0,3.292.294l-3,3a1,1,0,0,0,0,1.414Z" transform="translate(0 20.011) rotate(-90)" fill="rgb(166,195,253)"/></svg>');
      }
    }
    
    
`

const ExtraLegSelectedSeatBox = styled(SeatSelectedBox)`
    margin-top: 27px; 
    &:before {
        content: '';
        position: absolute;
        top: -27px;
        left: 0;
        width: calc(100% - 2px);
        height: 33px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="20.011" xml:space="preserve"><path d="M3.292,7.707A1,1,0,0,0,4.707,6.293L3.413,5H16.6L15.3,6.293a1,1,0,0,0,1.414,1.414l3-3a1,1,0,0,0,0-1.416l-3-3A1,1,0,1,0,15.3,1.708L16.6,3H3.413L4.707,1.708A1,1,0,1,0,3.292.294l-3,3a1,1,0,0,0,0,1.414Z" transform="translate(0 20.011) rotate(-90)" fill="rgb(255,193,7)"/></svg>');
        background-size: auto;
        background-position: center calc(50% - 4px);
        background-repeat: no-repeat;
        border: 1px dotted ${props => props.theme.colors.warning};
        border-radius: 7px;
        border-bottom: 0;
    }
`
const ExtraLegNotAvailableSeatBox = styled(SeatNotAvailableBox)`
    margin-top: 27px; 
    &:before {
        content: '';
        position: absolute;
        top: -27px;
        left: 0;
        width: calc(100% - 2px);
        height: 33px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="20.011" xml:space="preserve"><path d="M3.292,7.707A1,1,0,0,0,4.707,6.293L3.413,5H16.6L15.3,6.293a1,1,0,0,0,1.414,1.414l3-3a1,1,0,0,0,0-1.416l-3-3A1,1,0,1,0,15.3,1.708L16.6,3H3.413L4.707,1.708A1,1,0,1,0,3.292.294l-3,3a1,1,0,0,0,0,1.414Z" transform="translate(0 20.011) rotate(-90)" fill="rgb(146,153,167)"/></svg>');
        background-size: auto;
        background-position: center calc(50% - 4px);
        background-repeat: no-repeat;
        border: 1px dotted ${props => props.theme.colors.lightShade};
        border-radius: 7px;
        border-bottom: 0;
    }
`

function getSeatBox(seat: ISeatViewModel | null) {
    if(!seat) {
        return MissingSeatBox;
    }
    if (seat.hasExtraLegroom) {
        if (seat.assignedTo) {
            return ExtraLegSelectedSeatBox;
        } else if (!seat.isAvailable) {
            return ExtraLegNotAvailableSeatBox;
        } else {
            return ExtraLegSeatBox;
        }
    }
    if (seat.assignedTo) {
        return SeatSelectedBox;
    } else if (!seat.isAvailable) {
        return SeatNotAvailableBox;
    }

    switch (seat.location) {
        case SeatsRowGroupType.first:
            return FrontSeatBox;
        case SeatsRowGroupType.front:
            return FrontSeatBox;
        case SeatsRowGroupType.rear:
            return RearSeatBox;
        default:
            return SeatBox;
    }
}

interface SeatComponentProps {
    seat: ISeatViewModel | null;
}

export const SeatComponent: React.FC<SeatComponentProps> = observer((props) => {
    const services = useServices();

    const Box = getSeatBox(props.seat);

    if(!props.seat) {
        return <Box/>;
    }

    const assignedTo = props.seat.assignedTo;

    const className = classNames('tst-seat-box', {
        'tst-seat-assigned': Boolean(assignedTo),
        'tst-seat-available': props.seat.isAvailable,
        'tst-seat-extra': props.seat.hasExtraLegroom,
    });


    const onClick = async () => {
        if (assignedTo) {
            await assignedTo.removeSeat();
        } else {
            await props.seat?.sellSeat();
        }

    }

    if (assignedTo) {
        return (<Box key={props.seat.seatKey}  onClick={onClick} className={className}>
            <PersonNoCircleIcon size={1.2} />
            <PassengerInitialsBox>
                {assignedTo.passengerInitials}
            </PassengerInitialsBox>
        </Box>);
    }

    if (!props.seat.isAvailable) {
        return (
            <Box key={props.seat.seatKey} className={className} onClick={() => {
                /*
                The reason I added this empty click handler is because if I don't add it then
                for some strange reason when you click on an unavailable seat the next available seat next to it
                gets the click. So the result is that you click on an unavailable seat and the next seat is selected.
                */
            }}>
                <XIcon color={services.theme.currentTheme.colors.primaryContrast} />
            </Box>);
    }



    const renderExtraLegArrow = () => {
        if (props.seat?.hasExtraLegroom) {
            return (
                <IconBox className={className} />
            )
        }
        return null;
    }

    return (
        <Box key={props.seat.seatKey} onClick={onClick} className={className}>
            {renderExtraLegArrow()}
        </Box>
    );
});

