import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {PersonGroupIcon} from "../../../../../../assets/svg/person-group.icon";
import {PassengersTypesDropDownInputComponent} from "./input/passengers-types-drop-down-input.component";
import {PassengersTypesDropDownContentComponent} from "./content/passengers-types-drop-down-content.component";
import {IDropDownController} from "../../../../../../components/forms/drop-down/models/drop-down-controller.interface";
import {DropDownComponent} from "../../../../../../components/forms/drop-down/drop-down.component";

export const SearchMaskPassengersTypesDropDownWebappComponent: React.FC = observer(() => {
    const services = useServices();
    const fields = services.flightSearch.searchController.fields;


    const renderDropDownInput = () => {
        return (
            <PassengersTypesDropDownInputComponent/>
        );
    }

    const renderDropDownContent = (dropDownController: IDropDownController) => {
        return (
            <PassengersTypesDropDownContentComponent dropDownController={dropDownController}/>
        );
    }
    return (
        <DropDownComponent label={fields.passengers.fieldName}
                           icon={<PersonGroupIcon size={1.5}/>}
                           errorMessage={fields.passengers.error}
                           renderDropDownInput={renderDropDownInput}
                           renderDropDownContent={renderDropDownContent}
                           showBorders={true}
                           modalDialogOptions={{
                               breakpoints: [0.5, 0.7]
                           }}/>
    )
})
