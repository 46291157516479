import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';

export const CheckInOnlineSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.5143} sizeIsHeight {...props} viewBox="0 0 11 15.143" ratio={11/15.143}>
            <g fill={props.color? props.color: "currentColor"}>
                <path d="M11,13.584V1.567A1.569,1.569,0,0,0,9.433,0H1.567A1.569,1.569,0,0,0,0,1.567V13.576a1.569,1.569,0,0,0,1.567,1.567H9.433A1.563,1.563,0,0,0,11,13.584Zm-9.433.268a.271.271,0,0,1-.268-.268v-1.82a.309.309,0,0,0,.161-.4A.328.328,0,0,0,1.3,11.2V1.567A.271.271,0,0,1,1.568,1.3H9.434a.271.271,0,0,1,.268.268v9.6H9.434a.306.306,0,1,0,0,.612H9.7v1.8a.271.271,0,0,1-.268.268l-7.866.008Z" /><path d="M.621,11.191v3.056H10.38V11.191ZM5.5,13.81a1.086,1.086,0,1,1,.416-.083A1.088,1.088,0,0,1,5.5,13.81Z" /><path d="M3.339,5.18,5.46,7.3l-.707.707L2.632,5.887Z" /><path d="M8.874,5.085,5.338,8.62l-.707-.707L8.167,4.378Z" />
            </g>
        </SvgIcon>
    )
}
