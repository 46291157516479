export enum ServiceChargeTypeDotRezApiEnum {
    FarePrice = 0,
    Discount = 1,
    IncludedTravelFee = 2,
    IncludedTax = 3,
    TravelFee = 4,
    Tax = 5,
    ServiceCharge = 6,
    PromotionDiscount = 7,
    ConnectionAdjustmentAmount = 8,
    AddOnsPrice = 9,
    FarePoints = 10,
    DiscountPoints = 11,
    IncludedAddOnsFee = 12,
    AddOnsFee = 13,
    AddOnsMarkup = 14,
    FareSurcharge = 15,
    Loyalty = 16,
    AddOnsCancelFee = 17,
    Calculated = 18,
    Note = 19
}
export enum ServiceChargeTypeGraphQLEnum {
    FarePrice = "FarePrice",
    Discount = "Discount",
    IncludedTravelFee = "IncludedTravelFee",
    IncludedTax = "IncludedTax",
    TravelFee = "TravelFee",
    Tax = "Tax",
    ServiceCharge = "ServiceCharge",
    PromotionDiscount = "PromotionDiscount",
    ConnectionAdjustmentAmount = "ConnectionAdjustmentAmount",
    AddOnsPrice = "AddOnsPrice",
    FarePoints = "FarePoints",
    DiscountPoints = "DiscountPoints",
    IncludedAddOnsFee = "IncludedAddOnsFee",
    AddOnsFee = "AddOnsFee",
    AddOnsMarkup = "AddOnsMarkup",
    FareSurcharge = "FareSurcharge",
    Loyalty = "Loyalty",
    AddOnsCancelFee = "AddOnsCancelFee",
    Calculated = "Calculated",
    Note = "Note"
}
