import {FeeCodeBase} from "../fee-code-base";
import {FeeCodeEnum} from "../../../dot-rez-api/data-contracts/enums/fee-type.enum";

export class FlexFeeCode extends FeeCodeBase {
    constructor() {
        super(FeeCodeEnum.FLX);
    }

    get isRefundable(): boolean {
        return false;
    }
}
