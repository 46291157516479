import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import { IPassengerSegmentBoardingPassViewModel } from "../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";


interface AddBoardingPassesToWalletButtonComponentProps {
    bookingKey: string;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
}

export const AddBoardingPassesToWalletButtonComponent: React.FC<AddBoardingPassesToWalletButtonComponentProps> = observer((props) => {
    const services = useServices();

    const eligibleBoardingPasses = services.mobileWallet.filterEligiblePasses(props.boardingPasses);

    if(eligibleBoardingPasses.length === 0) {
        return null;
    }

    const onClickHandler = async () => {

        await services.mobileWallet.addBoardingPassesToWallet(props.bookingKey, eligibleBoardingPasses);

    }

    return services.mobileWallet.renderAddToWalletButton(onClickHandler);
});
