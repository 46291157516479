import React, {PropsWithChildren} from "react";

export enum SvgSizeUnit {
    Rem = "rem",
    Em = "em",
    Px = "px",
    Percent = "%"
}

export interface ISvgIconProps extends PropsWithChildren {
    size?: number;
    sizeIsHeight?: boolean;
    sizeUnit?: SvgSizeUnit;
    style?: React.CSSProperties;
    color?: string;
}

interface ISvgIconPrivateProps {
    ratio?: number;
    viewBox?: string;
}

export const SvgIcon: React.FC<ISvgIconProps & ISvgIconPrivateProps> = (props) => {
    const sizeUnit = props.sizeUnit ? props.sizeUnit : SvgSizeUnit.Rem;
    const defaultSize = sizeUnit === SvgSizeUnit.Px ? 16 : (sizeUnit === SvgSizeUnit.Percent ? 100 : 1.6);
    const size = props.size ? props.size : defaultSize;
    const ratio = props.ratio ? props.ratio : 1;

    const width = props.sizeIsHeight ? (size * ratio) : size;
    const height = props.sizeIsHeight ? size : (size * ratio);

    const viewBox = props.viewBox ?? "0 0 16 16";
    const style = {
        display: "block",
        ...props.style
    };

    return (
        <svg
            width={width + sizeUnit} height={height + sizeUnit}
            style={style}
            viewBox={viewBox}
            version="1.1" xmlns="http://www.w3.org/2000/svg"
        >
            {props.children}
        </svg>
    );
}
