import React, {MouseEvent} from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IonIcon} from "@ionic/react";
import {checkmarkCircleOutline, ellipseOutline} from "ionicons/icons";


type LabelPlacement = 'start' | 'end';

const CheckBoxContainer = styled.div<{labelPlacement?: LabelPlacement}>`
    display: flex;
    flex-direction: ${props => props.labelPlacement === "start" ? "row-reverse" : "row"};
    align-items: center;
    gap: ${props => props.theme.spacing.medium};
    cursor: pointer;
    
`

const CheckMarkWrapperBox = styled(IonIcon)<{isChecked: boolean}>`
    padding: 0;
    margin: 0;
    border-radius: 50%;
    font-size: 24px;
    ${
            props => props.isChecked
                    ? css`
                        background-color: ${props => props.theme.colors.primary};
                        color: ${props => props.theme.colors.primaryContrast};
                    `
                    : css`
                        background-color: ${props => props.theme.colors.primaryContrast};
                        color: ${props => props.theme.colors.primary};
                    `
    }
`

const LabelBox = styled.div`
`


interface ReadonlyCheckboxComponentProps {
    checked: boolean;
    onClick: (event: MouseEvent<HTMLElement>) => void;
    label?: string | React.ReactElement;
    labelPlacement?: LabelPlacement;
    className?: string;
}
export const ReadonlyCheckboxComponent: React.FC<ReadonlyCheckboxComponentProps> = observer((props) => {
    const renderLabel = () => {
        if(!props.label) {
            return null;
        }

        return (
            <LabelBox>
                {props.label}
            </LabelBox>
        );
    }

    const iconName = props.checked ? checkmarkCircleOutline : ellipseOutline

    return (
        <CheckBoxContainer labelPlacement={props.labelPlacement} className={props.className} onClick={props.onClick}>
            <CheckMarkWrapperBox isChecked={props.checked} ios={iconName} md={iconName}>

            </CheckMarkWrapperBox>
            {renderLabel()}

        </CheckBoxContainer>
    );
})