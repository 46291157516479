import React, {useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PassengerNameComponent} from "./passenger-name.component";
import {scrollElementIntoViewLazySmooth} from "../../../../utils/scroll-element-into-view";
import {PassengerBagsSelectionComponent} from "./passenger-bags-selection.component";
import {PassengerBagsSummaryComponent} from "./passenger-bags-summary.component";
import {IPassengerAllBagsViewModel} from "../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";
import {CardBox} from "../../../../basic-components/card/card.box";

export const PassengerBagsBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    
`


interface PassengerBagsComponentProps {
    passengerBags: IPassengerAllBagsViewModel;
    onClick: () => void;
    isExpandable: boolean;
    isExpanded: boolean;
}

export const PassengerBagsComponent: React.FC<PassengerBagsComponentProps> = observer((props) => {
    const [elementRef, setElementRef] = useState(null);


    const handleClick = () => {
        if(!props.isExpandable) {
            return;
        }
        props.onClick();

        if(!props.isExpanded) {
            scrollElementIntoViewLazySmooth(elementRef, {
                inline: "nearest",
                block: "nearest"
            });
        }

    }

    const captureElementRef = (el: any) => {
        setElementRef(el);
    }

    return (
        <PassengerBagsBox ref={captureElementRef} onClick={() => !props.isExpanded && handleClick()} className={`tst-passenger-bags tst-passenger-bags-${props.passengerBags.passenger.passengerIndex}`}>
            <PassengerNameComponent passengerName={props.passengerBags.passenger.getFullName()}
                                    showChevron={props.isExpandable}
                                    isSelected={props.isExpanded} className='tst-passenger-name'
                                    onClick={() => props.isExpanded && handleClick()}/>
            {
                props.isExpanded
                    ? (<PassengerBagsSelectionComponent passengerBags={props.passengerBags} />)
                    : (<PassengerBagsSummaryComponent passengerBags={props.passengerBags}/>)
            }
        </PassengerBagsBox>
    );

});
