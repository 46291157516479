import {Check} from "../../../../types/type-checking";
import {IBookingMutationsManagerViewModel} from "./booking-mutations-manager-view-model.interface";
import {MutationsActionsAggregator} from "./mutations-actions-aggregator";
import {IBookingMutationAction} from "./booking-mutation-action.interface";
import {
    IDotRezPartialBookingSessionData
} from "../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {SeatsMutationsAggregator} from "./seats/seats-mutations-aggregator";
import {BookingModel} from "../booking.model";
import {SeatMutationBase} from "./seats/seat-mutation-base";
import {RetrieveSeatsMapMutation} from "./seat-maps/retrieve-seats-map.mutation";
import {RetrieveSsrsAvailabilityMutation} from "./ssrs-availability/retrieve-ssrs-availability.mutation";
import {IBookingMutationWaiter} from "./booking-mutation-waiter.interface";
import {BookingMutationVoidWaiter} from "./booking-mutation-void-waiter";

export class BookingMutationsManagerModel extends MutationsActionsAggregator implements IBookingMutationsManagerViewModel {

    constructor(booking: BookingModel) {
        super(booking);
        this._seatsMutations = new SeatsMutationsAggregator(booking);
    }
    protected async _executeMutation(mutation: IBookingMutationAction): Promise<IDotRezPartialBookingSessionData> {
        const bookingSessionData = await mutation.execute();
        if(!Check.isEmpty(bookingSessionData)) {
            this.booking.updateBookingSessionData(bookingSessionData);
            await mutation.onAfterBookingSessionDataUpdated();
        }
        return bookingSessionData;
    }

    get isBookingTotalUpdateInProgress(): boolean {
        return this._inProgressMutations.filter(m => m.willAffectTotal).length > 0;
    }

    readonly _seatsMutations: SeatsMutationsAggregator;

    startSeatMutation(mutation: SeatMutationBase): void {
        if(this._seatsMutations.isMutationInProgress) {
            this._seatsMutations.startMutation(mutation);
        } else {
            this._seatsMutations.startMutation(mutation);
            this.startMutation(this._seatsMutations);
        }
    }

    retrieveSeatsMapMutation: IBookingMutationWaiter = new BookingMutationVoidWaiter();
    startRetrieveSeatsMap(): void {
        const m = new RetrieveSeatsMapMutation(this.booking);
        this.retrieveSeatsMapMutation = m;
        this.startMutation(m);
    }

    retrieveSsrsAvailabilityMutation: IBookingMutationWaiter = new BookingMutationVoidWaiter();
    startRetrieveSsrsAvailability(): void {
        const m = new RetrieveSsrsAvailabilityMutation(this.booking);
        this.retrieveSsrsAvailabilityMutation = m;
        this.startMutation(m);
    }

    sellDepartureJourneyMutation: IBookingMutationWaiter = new BookingMutationVoidWaiter();
    startSellDepartureJourneyMutation(mutation: IBookingMutationAction): void {
        this.sellDepartureJourneyMutation = mutation;
        this.startMutation(mutation);
    }

    sellReturnJourneyMutation: IBookingMutationWaiter = new BookingMutationVoidWaiter();
    startSellReturnJourneyMutation(mutation: IBookingMutationAction): void {
        this.sellReturnJourneyMutation = mutation;
        this.startMutation(mutation);
    }
}
