import React from "react";
import styled, {css} from "styled-components";
import { IPaymentMethodViewModel } from "../../../../../services/booking/models/payment/payment-methods/payment-method.view-model.interface";
import {observer} from "mobx-react-lite";
import {IonIcon} from "@ionic/react";
import {checkmarkOutline} from "ionicons/icons";

const CreditCardPaymentMethodBox = styled.div<{isSelected: boolean}>`
    --credit-card-logo-size: 98px;
    --credit-card-horizontal-padding: ${props => props.theme.spacing.medium};
    --credit-card-vertical-padding: ${props => props.theme.spacing.small};

    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
        --credit-card-logo-size: 78px;
    }

    @media ${(props) => props.theme.mediaQuery.xs} {
        --credit-card-logo-size: 48px;
    }
    
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.xLarge};
    width: calc(var(--credit-card-logo-size) + 2 * var(--credit-card-horizontal-padding));
    height: calc(var(--credit-card-logo-size) + 2 * var(--credit-card-vertical-padding));
    border: 1px solid ${props => props.theme.border.mainColor};
    border-radius: ${props => props.theme.border.defaultRadius};
    padding:  var(--credit-card-vertical-padding)  var(--credit-card-horizontal-padding);
    background-color: ${props => props.theme.colors.primaryContrast};
    cursor: pointer;
    ${
          props => props.isSelected
                  ? css`
                      border: 2px solid ${props => props.theme.colors.primary};
                `
                  : ``
    
    }
`

const SelectedCardCheckBox = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    right: -10px;
    top: -10px;
    line-height: 1;
    font-weight: ${props => props.theme.fontWeight.bold};
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    border-radius: 50%;
`

interface CreditCardMethodDetailsComponentProps {
    paymentMethod: IPaymentMethodViewModel;
}
export const CreditCardPaymentMethodComponent: React.FC<CreditCardMethodDetailsComponentProps> = observer((props) => {
    const onClickHandler = async () => {
        props.paymentMethod.isSelected = !props.paymentMethod.isSelected
    }

    const renderCheckMark = () => {
        if(props.paymentMethod.isSelected) {
            return (
                <SelectedCardCheckBox>
                    <IonIcon ios={checkmarkOutline} md={checkmarkOutline}/>
                </SelectedCardCheckBox>
            );
        }

        return null;
    }

    return (
        <CreditCardPaymentMethodBox onClick={onClickHandler} isSelected={props.paymentMethod.isSelected}>
            {props.paymentMethod.renderIcon()}
            {renderCheckMark()}
        </CreditCardPaymentMethodBox>
    )
});
