import {IDotRezLeg} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IServiceFactory} from "../../../../service-factory.interface";
import {LegBaseModel} from "../../base-models/leg/leg-base.model";
import {SegmentSnapshotModel} from "../segment/segment-snapshot.model";
import {TimeSpan} from "../../../../../types/time-span";

export class LegSnapshotModel extends LegBaseModel {
    constructor(protected readonly legDotRezData: IDotRezLeg,
                public readonly segment: SegmentSnapshotModel) {
        super();
    }

    get services(): IServiceFactory {
        return this.segment.services;
    }

    get delayTime(): TimeSpan {
        return TimeSpan.Zero;
    }

}
