import React from "react";
import {observer} from "mobx-react";
import {IFormField} from "../../../../models/forms/form-field.interface";
import {NullableString} from "../../../../types/nullable-types";
import {useServices} from "../../../../hooks/use-services.hook";
import {StandardDropDownListItemModel} from "../../../../components/forms/standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";
import {StandardDropDownComponent} from "../../../../components/forms/standard-drop-down/standard-drop-down.component";

export const AeroitaliaInvoiceRegionSelectorComponent: React.FC<{field: IFormField<NullableString>}> = observer((props) => {
    const services = useServices();
    const items = services.regions.getItalianRegions().map(c => new StandardDropDownListItemModel<NullableString>(c.code, c.code));
    return (
        <StandardDropDownComponent field={props.field}
                                   items={items}/>
    );
});
