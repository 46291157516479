import {IBlueBenefitsSubscription} from "./models/subscriptions/blue-benefits-subscription.interface";
import {IDotRezPersonComment} from "../dot-rez-api/data-contracts/user/person.data-contracts";
import {IBlueBenefitsPackage} from "./models/packs/blue-benefits-package.interface";
import {IDotRezBookingComment} from "../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";

export const BLUE_BENEFITS_BOOKING_SUBSCRIPTION_COMMENT_HEADER = 'bb-subscription';

export interface IBlueBenefitsService {
    readonly isEnabled: boolean;
    readonly availableBlueBenefitsPackages: IBlueBenefitsPackage[];
    getActivationUrl(bookingNumericRecordLocator: string): string;
    createSubscriptionsFromPersonComments(comments: IDotRezPersonComment[]): Promise<IBlueBenefitsSubscription[]>;
    createSubscriptionFromPackage(ssrType: IBlueBenefitsPackage, expirationDate: Date): IBlueBenefitsSubscription;
    createSubscriptionFromPassengersCount(passengersCount: number, expirationDate: Date): IBlueBenefitsSubscription;
    createSubscriptionFromBookingComments(bookingComments: IDotRezBookingComment[]): IBlueBenefitsSubscription | null;
    readonly noSubscription: IBlueBenefitsSubscription;
    getPackageBySsrCode(ssrCode: string): IBlueBenefitsPackage;


}
