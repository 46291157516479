import React from "react";
import {Station} from "../../../../../../../services/stations/station.service.interface";
import {observer} from "mobx-react";
import styled from "styled-components";

const StationContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    padding: 2px;
`

const StationNameBox = styled.div`
    width: 100%;
    font-weight: ${props => props.theme.fontWeight.bold};
`

const StationCountryBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.small};
`

const StationCodeBox = styled.div`
    text-align: center;
    width: 40px;
    font-weight: ${props => props.theme.fontWeight.bold};
`

const StationDetailsBox = styled.div`
  flex-grow: 1;
  line-height: 1;
`

interface StationDropDownItemComponentProps {
    station: Station;

}
export const StationDropDownItemComponent: React.FC<StationDropDownItemComponentProps> = observer((props) => {

    return (
        <StationContainerBox key={props.station.stationCode}>
            <StationDetailsBox>
                <StationNameBox>
                    {props.station.stationName}
                </StationNameBox>
                <StationCountryBox>
                    {props.station.countryName}
                </StationCountryBox>
            </StationDetailsBox>

            <StationCodeBox>
                {props.station.stationCode}
            </StationCodeBox>
        </StationContainerBox>
    )
});
