import {AirlineTheme} from "../../services/theme/interfaces/airline.theme";

export type SpacerSize = 'small' | 'medium' | 'large' | 'extraLarge';

export function computeSpacerSize(size: SpacerSize | undefined, theme: AirlineTheme): string {
    switch (size) {
        case "small":
            return theme.spacing.small;
        case "medium":
            return theme.spacing.medium;
        case "extraLarge":
            return theme.spacing.extraLarge;
        default:
            return theme.spacing.large;
    }
}
