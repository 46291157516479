import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {IBookingContactViewModel} from "../../../services/booking/models/contacts/booking-contact-view-model.interface";
import {useServices} from "../../../hooks/use-services.hook";

export const TicketBookingContactComponent: React.FC<{bookingContact: IBookingContactViewModel}> = observer((props) => {
    const services = useServices();

    const rootStyles: CSSProperties = {
        display: "flex",
        flexDirection: 'column',
        gap: services.theme.currentTheme.spacing.medium,
        lineHeight: 1,
        width: '100%',
        border: `1px solid ${services.theme.currentTheme.border.mainColor}`,
        borderRadius: services.theme.currentTheme.border.defaultRadius,
        padding: services.theme.currentTheme.spacing.cardDefaultPaddingPx,
        marginBottom: services.theme.currentTheme.spacing.large
    }

    const fieldsContainerStyles: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: services.theme.currentTheme.spacing.small,
        width: "fit-content"
    }

    const titleStyles: CSSProperties = {
        fontSize: services.theme.currentTheme.fontSize.xxLarge,
        fontWeight: services.theme.currentTheme.fontWeight.bold,
    }

    return (
        <div style={rootStyles}>
            <div style={titleStyles}>{services.language.translate('Booking contact information')}</div>
            <div style={fieldsContainerStyles}>
                <div>{services.language.translate('Contact name:')}</div>
                <div>{props.bookingContact.fullContactName ?? "N/A"}</div>
                <div>E-mail:</div>
                <div>{props.bookingContact.fields.emailAddress.value ?? "N/A"}</div>
                <div>{services.language.translate('Phone number:')}</div>
                <div>{props.bookingContact.fields.phoneNumber.value ?? "N/A"}</div>
            </div>
        </div>
    )
})