import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import { SadFaceIcon } from "../../../../assets/svg/sad-face.icon";
import { useServices } from "../../../../hooks/use-services.hook";
import { SeatSsrSmallIcon } from "../../../../services/ssr-types/ssrs/seats/seat-ssr.small-icon";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {observer} from "mobx-react";

import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";
import {VerticalSpacer} from "../../../../basic-components/spacing/vertical-spacer";

const RandomSeatAllocationBox = styled.div`
    padding: ${props => props.theme.spacing.large};
    display: flex;
    flex-direction: column;
    align-items: center;
`

const RandomSeatAllocationHeaderBox = styled.div`
    padding: 0 ${props => props.theme.spacing.extraLarge} ${props => props.theme.spacing.extraLarge};
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${ props => props.theme.colors.secondary};
`

const RandomSeatAllocationIconBox = styled.div`
    padding-bottom: ${props => props.theme.spacing.extraLarge};
`

const RandomSeatAllocationTitleBox = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-align: center;
    line-height: 1;
`

const RandomSeatAllocationSubtitleBox = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    opacity: 70%;
    text-align: center;
    padding-top: ${props => props.theme.spacing.large};
    line-height: 1;
`

const DescriptionListBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const DescriptionListItemBox = styled.div`
    display: flex;
    padding-bottom: 15px;
    align-items: center;
    &:last-of-type{
        padding-bottom: 0;
    }
`

const DescriptionListIconBox = styled.div`

`

const DescriptionListTitleBox = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.medium};
    padding-left: 15px;
    line-height: 1;
`

const RandomSeatAllocationHeaderComponent: React.FC<PropsWithChildren> = observer((props) => {
    return (
        <RandomSeatAllocationHeaderBox>
            <RandomSeatAllocationIconBox>
                <SeatSsrSmallIcon size={4}/>
            </RandomSeatAllocationIconBox>
            {props.children}
        </RandomSeatAllocationHeaderBox>
    )
});

const RandomSeatAllocationForFamiliesWithChildrenComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <StandardDialogContentComponent>
            <RandomSeatAllocationBox>
                <RandomSeatAllocationHeaderComponent>
                    <RandomSeatAllocationTitleBox>
                        {services.language.translate('You have not selected your seats')}
                    </RandomSeatAllocationTitleBox>
                </RandomSeatAllocationHeaderComponent>
            </RandomSeatAllocationBox>
            <DescriptionListBox>
                <DescriptionListItemBox>
                    <DescriptionListIconBox><SadFaceIcon color={services.theme.currentTheme.colors.primary}/></DescriptionListIconBox>
                    <DescriptionListTitleBox>
                        {services.language.translate(`Because you are travelling with a child, please make seats reservation in order to enable online check-in. In case you select 'I prefer a random seat', you will be assigned seats for free at the check-in desk, based on availability.`)}
                    </DescriptionListTitleBox>
                </DescriptionListItemBox>
            </DescriptionListBox>
        </StandardDialogContentComponent>
    );
});

const RandomSeatAllocationForAdultsOnlyComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <StandardDialogContentComponent>
            <RandomSeatAllocationBox>
                <RandomSeatAllocationHeaderComponent>
                    <RandomSeatAllocationTitleBox>
                        {services.language.translate('No seat selected, you will get a random seat')}
                    </RandomSeatAllocationTitleBox>
                    <RandomSeatAllocationSubtitleBox>
                        {services.language.translate('Random seat selection means:')}
                    </RandomSeatAllocationSubtitleBox>
                </RandomSeatAllocationHeaderComponent>
                <DescriptionListBox>
                    <DescriptionListItemBox>
                        <DescriptionListIconBox><SadFaceIcon color={services.theme.currentTheme.colors.primary}/></DescriptionListIconBox>
                        <DescriptionListTitleBox>{services.language.translate(`High chance you'll be in the middle seat`)}</DescriptionListTitleBox>
                    </DescriptionListItemBox>
                    <DescriptionListItemBox>
                        <DescriptionListIconBox><SadFaceIcon color={services.theme.currentTheme.colors.primary}/></DescriptionListIconBox>
                        <DescriptionListTitleBox>{services.language.translate('Pick your seat only 24 hours prior to departure')}</DescriptionListTitleBox>
                    </DescriptionListItemBox>
                    <DescriptionListItemBox>
                        <DescriptionListIconBox><SadFaceIcon color={services.theme.currentTheme.colors.primary}/></DescriptionListIconBox>
                        <DescriptionListTitleBox>{services.language.translate('Unlikely to be in the front')}</DescriptionListTitleBox>
                    </DescriptionListItemBox>
                    <DescriptionListItemBox>
                        <DescriptionListIconBox><SadFaceIcon color={services.theme.currentTheme.colors.primary}/></DescriptionListIconBox>
                        <DescriptionListTitleBox>{services.language.translate('Your party seats separately')}</DescriptionListTitleBox>
                    </DescriptionListItemBox>
                </DescriptionListBox>
            </RandomSeatAllocationBox>
        </StandardDialogContentComponent>
    );
});

export const RandomSeatAllocationDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = (props) => {
    const services = useServices();

    const renderBody = () => {
        if(services.booking.current.passengers.length === 1
            || services.booking.current.passengers.all(p => p.passengerType.isAdult && !p.infant)
            || !services.configuration.data.enableRandomSeatAllocation) {
            return (
                <RandomSeatAllocationForAdultsOnlyComponent/>
            );
        } else {
            return (
                <RandomSeatAllocationForFamiliesWithChildrenComponent/>
            );
        }
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Random Seat Allocation')} dialogHandler={props.dialogHandler}/>
            {renderBody()}
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={() => props.dialogHandler.accept()}>
                    {services.language.translate('I prefer to reserve a seat')}
                </PrimaryButton>
                <VerticalSpacer/>
                <PrimaryButtonInverted onClick={() => props.dialogHandler.reject()}>
                    {services.language.translate('I prefer a random seat')}
                </PrimaryButtonInverted>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
};
