import {IServiceFactory} from "./service-factory.interface";
import {Lazy} from "../utils/lazy";
import {IBookingService} from "./booking/booking.service.interface";
import {IStationService} from "./stations/station.service.interface";
import {StationService} from "./stations/station.service";
import {IFlightSearchService} from "./flight-search/flight-search.service.interface";
import {NavigatorService} from "./navigation/navigator.service";
import {ILanguageService} from "./language/language.service.interface";
import {LanguageService} from "./language/language.service";
import {IDotRezApiService} from "./dot-rez-api/dot-rez-api.service.interface";
import {DotRezApiService} from "./dot-rez-api/dot-rez-api.service";
import {ILowFareService} from "./low-fare/low-fare.service.interface";
import {LowFareService} from "./low-fare/low-fare.service";
import {ICurrencyService} from "./currency/currency.service.interface";
import {CurrencyService} from "./currency/currency.service";
import {IFlightsScheduleService} from "./flights-schedule/flights-schedule.service.interface";
import {FlightsScheduleService} from "./flights-schedule/flights-schedule.service";
import {LoadingIndicatorService} from "./loading-indicator/loading-indicator.service";
import {ILoadingIndicatorService} from "./loading-indicator/loading-indicator.service.interface";
import {ITimeService} from "./time/time.service.interface";
import {TimeService} from "./time/time.service";
import {IThemeService} from "./theme/theme.service.interface";
import {ThemeService} from "./theme/theme.service";
import {IAlertService} from "./alert/alert.service.interface";
import {AlertService} from "./alert/alert.service";
import {ILoggerService} from "./logger/logger.service.interface";
import {ConsoleLoggerService} from "./logger/console-logger.service";
import {ISsrTypesService} from "./ssr-types/ssr-types.service.interface";
import {SsrTypesService} from "./ssr-types/ssr-types.service";
import {IDialogService} from "./dialog/dialog.service.interface";
import {DialogService} from "./dialog/dialog.service";
import {IPassengersTypesService} from "./passenger-types/passengers-types.service.interface";
import {PassengersTypesService} from "./passenger-types/passengers-types.service";
import {ISessionStorageService} from "./storage/session-storage.service.interface";
import {SessionStorageService} from "./storage/session-storage.service";
import {ILocalStorageService} from "./storage/local-storage.service.interface";
import {LocalStorageService} from "./storage/local-storage.service";
import {IDocumentService} from "./document/document.service.interface";
import {DocumentService} from "./document/document.service";
import {IWindowService} from "./window/window.service.interface";
import {WindowService} from "./window/window.service";
import {IBundleTypesService} from "./bundle-types/bundle-types.service.interface";
import {BundleTypesService} from "./bundle-types/bundle-types.service";
import {
    IBundleTypesConfigurationService
} from "./bundle-types-configuration/bundle-types-configuration.service.interface";
import {BundleTypesConfigurationService} from "./bundle-types-configuration/bundle-types-configuration.service";
import {IHomePageService} from "./home-page/home-page.service.interface";
import {HomePageService} from "./home-page/home-page.service";
import { IPdfExporterService } from "./pdf-exporter/pdf-exporter.service.interface";
import { PdfExporterService } from "./pdf-exporter/pdf-exporter.service";
import {ConfigurationService} from "./configuration/configuration.service";
import {ICountryService} from "./country/country.service.interface";
import {CountryService} from "./country/country.service";
import {IAirlineWebapiService} from "./airline-webapi/airline-webapi.service.interface";
import {AirlineWebapiService} from "./airline-webapi/airline-webapi.service";
import {IDeviceService} from "./device/device.service.interface";
import {DeviceService} from "./device/device.service";
import {ITravelDocumentTypeService} from "./travel-document-type/travel-document-type.service.interface";
import {TravelDocumentTypeService} from "./travel-document-type/travel-document-type.service";
import {IDeepLinksService} from "./deep-links/deep-links.service.interface";
import {DeepLinksService} from "./deep-links/deep-links.service";
import {IApplicationService} from "./application/application.service.interface";
import {ApplicationService} from "./application/application.service";
import {IUserService} from "./user/user.service.interface";
import {UserService} from "./user/user.service";
import {IBookingHistoryService} from "./booking-history/booking-history.service.interface";
import {BookingHistoryService} from "./booking-history/booking-history.service";
import {IDialogFactoryService} from "./dialog-factory/dialog-factory.service.interface";
import {DialogFactoryService} from "./dialog-factory/dialog-factory.service";
import { IAnalyticsService } from "./analytics/analytics.service.interface";
import { AnalyticsService } from "./analytics/analytics.service";
import {makeObservable, observable, runInAction} from "mobx";
import {IExternalLinksService} from "./external-links/external-links.service.interface";
import {ExternalLinksService} from "./external-links/external-links.service";
import {IPersonTitleService} from "./person-title/person-title.service.interface";
import {PersonTitleService} from "./person-title/person-title.service";
import {IBlueBenefitsService} from "./blue-benefits/blue-benefits.service.interface";
import {BlueBenefitsService} from "./blue-benefits/blue-benefits.service";
import {ICryptoService} from "./crypto/crypto.service.interface";
import {CryptoService} from "./crypto/crypto.service";
import { IMobileWalletService } from "./mobile-wallet/mobile-wallet.service.interface";
import { MaintenanceService } from "./maintenance/maintenance.service";
import {IFeeCodesService} from "./fee-codes/fee-codes.service.interface";
import {FeeCodesService} from "./fee-codes/fee-codes.service";
import { SplashScreen } from '@capacitor/splash-screen';
import {IMediaChecks} from "./media-query/media-queries.interface";
import {MediaQueryService} from "./media-query/media-query.service";
import {IRegionsService} from "./regions/regions.service.interface";
import {RegionsService} from "./regions/regions.service";
import {ICarriersService} from "./carriers/carriers.service.interface";
import {CarriersService} from "./carriers/carriers.service";
import {ICmsService} from "./cms/cms.service.interface";
import {CmsService} from "./cms/cms.service";
import {ILayoutService} from "./layout/layout.service.interface";
import {LayoutService} from "./layout/layout.service";
import {IExternalLinksConfiguration} from "./external-links/external-links-configuration.interface";
import {ExternalLinksConfiguration} from "./external-links/external-links-configuration";
import {BookingService} from "./booking/booking.service";
import {MobileWalletService} from "./mobile-wallet/mobile-wallet.service";

export abstract class ServiceFactoryBase implements IServiceFactory {
    constructor() {

        makeObservable(this, {
            isLoading: observable.ref
        });

        SplashScreen.show();

        this._deepLinks.forceInit();
        this.navigator = new NavigatorService(this);
        this.configuration = new ConfigurationService(this);
        this.maintenance = new MaintenanceService(this);
        this._initialize();
    }

    abstract get flightSearch(): IFlightSearchService;
    protected abstract _waitForMaintenance(): Promise<void>;
    protected abstract _showCookiesPolicy(): Promise<void>;

    private async _initialize(): Promise<void> {
        try {
            await this._onInitialize();
            await this._executeAppInitActions();
        } finally {
            await this._onLoadingFinish();
        }
    }



    protected async _onInitialize(): Promise<void> {
        await this._waitForMaintenance();
        await this.configuration.loadConfigurations();
        this._application.forceInit();
        this._analytics.forceInit();
        this._dialog.forceInit();
        this._country.forceInit();
        this._stations.forceInit();
        this._bookingHistory.forceInit();
        this._carriers.forceInit();
        this._booking.forceInit();
    }

    protected async _executeAppInitActions(): Promise<void> {
        if(!this.application.cookiesPolicyAccepted) {
            //If cookies policy wasn't accepted meaning this is the first time the user opens the app
            //then we hide the splash otherwise it will cover the cookies policy dialog.
            await SplashScreen.hide();
        }

        await this._showCookiesPolicy();

        await this.user.profile.waitForProfileInitialization();
    }



    isLoading: boolean = true;
    protected async _onLoadingFinish(): Promise<void> {
        runInAction(() => {
            this.isLoading = false;
        });
        setTimeout(async () => {
            // after setting above this.isLoading = false
            // the app will rerender so we give the chance to react to do its job
            // before hiding the splash
            try {
                await SplashScreen.hide();
            } catch (err) {
                this.logger.error(`Failed to hide splash`, err);
            }

        }, 500);


    }
    readonly maintenance: MaintenanceService;
    readonly configuration: ConfigurationService;
    readonly logger: ILoggerService = new ConsoleLoggerService();
    readonly navigator: NavigatorService;

    private _application: Lazy<IApplicationService> = new Lazy<IApplicationService>(() => new ApplicationService(this));
    get application(): IApplicationService {
        return this._application.value;
    }

    private _analytics: Lazy<IAnalyticsService> = new Lazy<IAnalyticsService>(() => new AnalyticsService(this));
    get analytics(): IAnalyticsService {
        return this._analytics.value;
    }

    private _dotRezApi: Lazy<IDotRezApiService> = new Lazy<IDotRezApiService>(() => new DotRezApiService(this));
    get dotRezApi(): IDotRezApiService {
        return this._dotRezApi.value;
    }


    private _stations: Lazy<IStationService> = new Lazy<IStationService>(() => new StationService(this));
    get stations(): IStationService {
        return this._stations.value;
    }

    private _booking: Lazy<IBookingService> = new Lazy<IBookingService>(() => new BookingService(this));
    get booking(): IBookingService {
        return this._booking.value;
    }

    private _language: Lazy<ILanguageService> = new Lazy<ILanguageService>(() => new LanguageService(this));
    get language(): ILanguageService {
        return this._language.value;
    }
    private _lowFare: Lazy<ILowFareService> = new Lazy<ILowFareService>(() => new LowFareService(this));
    get lowFare(): ILowFareService {
        return this._lowFare.value;
    }

    private _currency: Lazy<ICurrencyService> = new Lazy<ICurrencyService>(() => new CurrencyService(this));
    get currency(): ICurrencyService {
        return this._currency.value;
    }

    private _flightsSchedule: Lazy<IFlightsScheduleService> = new Lazy<IFlightsScheduleService>(() => new FlightsScheduleService(this));
    get flightsSchedule(): IFlightsScheduleService {
        return this._flightsSchedule.value;
    }

    private _loadingIndicator: Lazy<ILoadingIndicatorService> = new Lazy<ILoadingIndicatorService>(() => new LoadingIndicatorService(this));
    get loadingIndicator(): ILoadingIndicatorService {
        return this._loadingIndicator.value;
    }

    private _time: Lazy<ITimeService> = new Lazy<ITimeService>(() => new TimeService(this));
    get time(): ITimeService {
        return this._time.value;
    }

    private _theme: Lazy<IThemeService> = new Lazy<IThemeService>(() => new ThemeService(this));
    get theme(): IThemeService {
        return this._theme.value;
    }

    private _alert: Lazy<IAlertService> = new Lazy<IAlertService>(() => new AlertService(this));
    get alert(): IAlertService {
        return this._alert.value;
    }

    private _ssrTypes: Lazy<ISsrTypesService> = new Lazy<ISsrTypesService>(() => new SsrTypesService(this));
    get ssrTypes(): ISsrTypesService {
        return this._ssrTypes.value;
    }

    private _dialog: Lazy<IDialogService> = new Lazy<IDialogService>(() => new DialogService(this));
    get dialog(): IDialogService {
        return this._dialog.value;
    }

    private _dialogFactory: Lazy<IDialogFactoryService> = new Lazy<IDialogFactoryService>(() => new DialogFactoryService(this));
    get dialogFactory(): IDialogFactoryService {
        return this._dialogFactory.value;
    }

    private _passengerTypes: Lazy<IPassengersTypesService> = new Lazy<IPassengersTypesService>(() => new PassengersTypesService(this));
    get passengerTypes(): IPassengersTypesService {
        return this._passengerTypes.value;
    }

    private _sessionStorage: Lazy<ISessionStorageService> = new Lazy<ISessionStorageService>(() => new SessionStorageService());
    get sessionStorage(): ISessionStorageService {
        return this._sessionStorage.value;
    }

    private _localStorage: Lazy<ILocalStorageService> = new Lazy<ILocalStorageService>(() => new LocalStorageService(this));
    get localStorage(): ILocalStorageService {
        return this._localStorage.value;
    }    

    private _document: Lazy<IDocumentService> = new Lazy<IDocumentService>(() => new DocumentService());
    get document(): IDocumentService {
        return this._document.value;
    }

    private _window: Lazy<IWindowService> = new Lazy<IWindowService>(() => new WindowService());
    get window(): IWindowService {
        return this._window.value;
    }

    private _bundleTypes: Lazy<IBundleTypesService> = new Lazy<IBundleTypesService>(() => new BundleTypesService(this));
    get bundleTypes(): IBundleTypesService {
        return this._bundleTypes.value;
    }


    private _bundleTypesConfiguration: Lazy<IBundleTypesConfigurationService> = new Lazy<IBundleTypesConfigurationService>(() => new BundleTypesConfigurationService(this));
    get bundleTypesConfiguration(): IBundleTypesConfigurationService {
        return this._bundleTypesConfiguration.value;
    }

    private _homePage: Lazy<IHomePageService> = new Lazy<IHomePageService>(() => new HomePageService(this));
    get homePage(): IHomePageService {
        return this._homePage.value;
    }

    private _pdfExporter: Lazy<IPdfExporterService> = new Lazy<IPdfExporterService>(() => new PdfExporterService(this));
    get pdfExporter(): IPdfExporterService {
        return this._pdfExporter.value;
    }

    private _country: Lazy<ICountryService> = new Lazy<ICountryService>(() => new CountryService(this));
    get country(): ICountryService {
        return this._country.value;
    }

    private _airlineWebapi: Lazy<IAirlineWebapiService> = new Lazy<IAirlineWebapiService>(() => new AirlineWebapiService(this));
    get airlineWebapi(): IAirlineWebapiService {
        return this._airlineWebapi.value;
    }

    private _device: Lazy<IDeviceService> = new Lazy<IDeviceService>(() => new DeviceService());
    get device(): IDeviceService {
        return this._device.value;
    }

    private _travelDocumentType: Lazy<ITravelDocumentTypeService> = new Lazy<ITravelDocumentTypeService>(() => new TravelDocumentTypeService(this));
    get travelDocumentType(): ITravelDocumentTypeService {
        return this._travelDocumentType.value;
    }
    
    private _deepLinks: Lazy<IDeepLinksService> = new Lazy<IDeepLinksService>(() => new DeepLinksService(this));
    get deepLinks(): IDeepLinksService {
        return this._deepLinks.value;
    }

    private _user: Lazy<IUserService> = new Lazy<IUserService>(() => new UserService(this));
    get user(): IUserService {
        return this._user.value;
    }

    private _bookingHistory: Lazy<IBookingHistoryService> = new Lazy<IBookingHistoryService>(() => new BookingHistoryService(this));
    get bookingHistory(): IBookingHistoryService {
        return this._bookingHistory.value;
    }

    private _externalLinks: Lazy<IExternalLinksService> = new Lazy<IExternalLinksService>(() => new ExternalLinksService(this));
    get externalLinks(): IExternalLinksService {
        return this._externalLinks.value;
    }

    private _personTitle: Lazy<IPersonTitleService> = new Lazy<IPersonTitleService>(() => new PersonTitleService(this));
    get personTitle(): IPersonTitleService {
        return this._personTitle.value;
    }

    private _blueBenefits: Lazy<IBlueBenefitsService> = new Lazy<IBlueBenefitsService>(() => new BlueBenefitsService(this));
    get blueBenefits(): IBlueBenefitsService {
        return this._blueBenefits.value;
    }

    private _crypto: Lazy<ICryptoService> = new Lazy<ICryptoService>(() => new CryptoService());
    get crypto(): ICryptoService {
        return this._crypto.value;
    }

    private _mobileWallet: Lazy<IMobileWalletService> = new Lazy<IMobileWalletService>(() => new MobileWalletService(this));
    get mobileWallet(): IMobileWalletService {
        return this._mobileWallet.value;
    }

    private _feeCodes: Lazy<IFeeCodesService> = new Lazy<IFeeCodesService>(() => new FeeCodesService());
    get feeCodes(): IFeeCodesService {
        return this._feeCodes.value;
    }

    private _mediaQuery: Lazy<IMediaChecks> = new Lazy<MediaQueryService>(() => new MediaQueryService(this));
    get mediaQuery(): IMediaChecks {
        return this._mediaQuery.value;
    }

    private _regions: Lazy<IRegionsService> = new Lazy<IRegionsService>(() => new RegionsService(this));
    get regions(): IRegionsService {
        return this._regions.value;
    }

    private _layout: Lazy<ILayoutService> = new Lazy<ILayoutService>(() => new LayoutService(this));
    get layout(): ILayoutService {
        return this._layout.value;
    }

    private _externalLinksConfiguration: Lazy<IExternalLinksConfiguration> = new Lazy<IExternalLinksConfiguration>(() => new ExternalLinksConfiguration(this));
    get externalLinksConfiguration(): IExternalLinksConfiguration {
        return this._externalLinksConfiguration.value;
    }

    private _carriers: Lazy<ICarriersService> = new Lazy<ICarriersService>(() => new CarriersService(this));
    get carriers(): ICarriersService {
        return this._carriers.value;
    }

    private _cms: Lazy<ICmsService> = new Lazy<ICmsService>(() => new CmsService(this));
    get cms(): ICmsService {
        return this._cms.value;
    }

    // private _maintenance: Lazy<IMaintenanceService> = new Lazy<IMaintenanceService>(() => new MaintenanceService(this));
    // get maintenance(): IMaintenanceService {
    //     return this._maintenance.value;
    // }
}
