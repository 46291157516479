import React, {useEffect} from "react";
import {IStandardDropDownItemViewModel} from "../../models/item/standard-drop-down-list-item-view-model.interface";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IStandardDropDownListViewModel} from "../../models/list/standard-drop-down-list-view-model.interface";
import {reaction} from "mobx";
import {useServices} from "../../../../../../hooks/use-services.hook";

const DropDownItemContainerBox = styled.div<{isCurrent: boolean}>`
    width: 100%;
    cursor: pointer;
    padding: ${props => props.theme.spacing.small};
    border-bottom: 1px solid ${props => props.theme.border.mainColor};
    
    ${
    props => props.isCurrent
        ? css`
                      background-color: ${props => props.theme.colors.primary};
                      color: ${props => props.theme.colors.primaryContrast};
                    `
        : ''
      
}
`

interface StandardDropDownItemComponentProps<TFieldValue> {
    item: IStandardDropDownItemViewModel<TFieldValue>;
    index: number;
    listViewModel: IStandardDropDownListViewModel<TFieldValue>;
}

export const StandardDropDownListItemComponent = observer(<TFieldValue extends any>(props: StandardDropDownItemComponentProps<TFieldValue>) => {
    const services = useServices();
    const boxRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        const disposeReaction = reaction(() => props.listViewModel.currentFocusedItemIndex,
            () => {
                if(props.index === props.listViewModel.currentFocusedItemIndex) {
                    boxRef.current?.scrollIntoView({
                        block: "nearest",
                        inline: "nearest"
                    });
                }
            });
        return () => {
            disposeReaction();
        };
    }, [boxRef, props.index, props.listViewModel.currentFocusedItemIndex]);

    const onClickHandler = () => {
        props.listViewModel.setSelectedItem(props.item);
    }

    const onMouseEnterHandler = () => {
        props.listViewModel.currentFocusedItemIndex = props.index;
    }


    //For mobile devices and devices that don't have pointer (mouse) doesn't make sense to highlight the current item
    const isCurrent = (props.index === props.listViewModel.currentFocusedItemIndex) && !services.layout.isInSmallScreenView && services.mediaQuery.pointer;

    return (
        <DropDownItemContainerBox onClick={onClickHandler}
                                  isCurrent={isCurrent}
                                  ref={boxRef}
                                  onMouseEnter={onMouseEnterHandler}>
            {props.item.renderItem({isRenderedInModalDialog: props.listViewModel.isInsideModalDialog})}
        </DropDownItemContainerBox>
    )
});

