import {IAvailableFareViewModel} from "./available-fare-view-model.interface";
import {IFareDiscountType} from "../discount-types/fare-discount-type.interface";
import {AvailableJourneyModel} from "../available-journey.model";
import {IDotRezAvailableFare} from "../../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import {IServiceFactory} from "../../../service-factory.interface";
import {NoFareDiscount} from "../discount-types/no-fare-discount";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {Check} from "../../../../types/type-checking";
import {Price} from "../../../currency/price";
import {FlightDesignatorModel} from "../../../booking/models/designator/flight-designator.model";
import {IAvailableFareToDiscountAdapter} from "./available-fare-to-discount-adater.interface";
import {NullableString} from "../../../../types/nullable-types";
import {BlueBenefitsDiscount} from "../discount-types/blue-benefits-discount";

export abstract class AvailableFareModel implements IAvailableFareViewModel, IAvailableFareToDiscountAdapter {

    constructor(protected readonly _journey: AvailableJourneyModel,
                public readonly dotRezFare: IDotRezAvailableFare,
                public readonly services: IServiceFactory) {
        makeObservable<this, '_isSelected'>(this, {
            discount: computed,
            _isSelected: observable.ref,
            isSelected: computed
        });
    }

    get designator(): FlightDesignatorModel {
        return this._journey.designator;
    }
    private _selectCurrentApplicableDiscount(): IFareDiscountType {

        const discountTypes = this._getAvailableDiscountTypes();

        for (let disc of discountTypes) {
            if(disc.isApplicable) {
                return disc;
            }
        }

        return new NoFareDiscount(this);
    }

    protected abstract _getAvailableDiscountTypes(): IFareDiscountType[];

    get bundleNameToShowOnFare(): NullableString {
        return this._journey.bundleNameToShowOnFare;
    }

    get fareKey(): string {
        return this.dotRezFare.fareAvailabilityKey;
    }

    get productClass(): string {
        return this.dotRezFare.fares[0].productClass;
    }
    get classOfService(): string {
        return this.dotRezFare.fares[0].classOfService;
    }
    get fareUniqueId(): string {
        return `${this.journeyKey}_${this.fareKey}_${this.hasBlueBenefits}`;
    }

    get journeyKey(): string {
        return this._journey.journeyKey;
    }

    get discount(): IFareDiscountType {
        return this._selectCurrentApplicableDiscount();
    }

    get discountTypeName(): string {
        return this.discount.discountTypeName;
    }

    get promotionalMessage(): NullableString {
        if(this.isPriceReduced) {
            /*
              On manage my booking flow we show the fare difference between the initial journey and the new one
              so there is no point to display the promotion message here because it will look strange.
              We would how something like +10EUR and under the fare "Promo 25% off".
              This is the reason we return here null when props.fare.isPriceReduced is true.
           */
            return null;
        }
        return this.discount.promotionalMessage;
    }

    get hasBlueBenefits(): boolean {
        return this.discount instanceof BlueBenefitsDiscount;
    }

    get isPriceReduced(): boolean {
        return this.discount.totalPrice.amount !== this.totalPrice.amount;
    }

    get totalPrice(): Price {
        return this._journey.reduceFare(this.discount.totalPrice);
    }

    get discountedPrice(): Price {
        return this._journey.reduceFare(this.discount.discountedPrice);
    }


    async sell(): Promise<void> {
        if(this.isSelected) {
            return;
        }
        await this._journey.sell(this);
    }

    private _isSelected: boolean | null = null;
    get isSelected(): boolean {
        if(Check.isNullOrUndefined(this._isSelected)) {
            const currentBooking = this.services.booking.current;
            const journey = currentBooking.unfilteredJourneys.find(j => j.journeyKey === this.journeyKey);
            if(!journey) {
                return false;
            }

            if(this._journey.fares.length > 1) {
                // if there is more than one fare in the parent journey then it means that we have available both standard and blue benefits price
                // so this fare is the selected one if both journey key and the hasBlueBenefits matches
                return this.hasBlueBenefits === journey.hasBlueBenefits;
            } else {
                return true;
            }


        }

        return this._isSelected;
    }

    set isSelected(value: boolean) {
        runInAction(() => {
            this._isSelected = value;
        });
    }

}
