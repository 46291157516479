import React from "react";
import {observer} from "mobx-react";
import {
    BoardingPassExportButtonComponent,
    BoardingPassExportButtonComponentProps
} from "../export-button/boarding-pass-export-button.component";
import {printOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";

export const BoardingPassPrintButtonComponent: React.FC<BoardingPassExportButtonComponentProps> = observer((props) => {
    return (
        <BoardingPassExportButtonComponent {...props}>
            <IonIcon ios={printOutline} md={printOutline}/>
        </BoardingPassExportButtonComponent>
    );


});
