import {NullableString} from "../../../../../types/nullable-types";
import {Price} from "../../../../currency/price";
import {IFeeModel} from "./fee-model.interface";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {FeeTypeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";

export class FeesAggregatorModel implements IFeeModel  {
    constructor(public readonly fees: IFeeModel[]) {
    }

    private get firstFee(): IFeeModel {
        return this.fees[0];
    }

    get createdDate(): Date {
        return this.firstFee.createdDate;
    }

    get ssrNumber(): number {
        return this.firstFee.ssrNumber;
    }

    get feeGroupKey(): string {
        return this.firstFee.feeGroupKey;
    }

    get feeType(): FeeTypeEnum {
        return this.firstFee.feeType;
    }

    get feeCode(): NullableString {
        return this.firstFee.feeCode;
    }

    get ssrType(): ISsrType | null {
        return this.firstFee.ssrType;
    }

    get description(): string {
        return this.firstFee.description;
    }
    get details(): NullableString {
        return this.firstFee.details;
    }

    get quantity(): number {
        return this.fees.sum(f => f.quantity);
    }

    get initialPrice(): Price | null {
        return this.firstFee.initialPrice;
    }

    get currentPrice(): Price{
        return this.firstFee.currentPrice;
    }

    get priceToDisplay(): Price {
        return this.firstFee.priceToDisplay;
    }

    get totalToDisplay(): Price {
        return this.priceToDisplay.multiply(this.quantity);
    }

    get preferredOrderInLists(): number {
        return this.firstFee.preferredOrderInLists;
    }

    get isPurchasedOnCurrentSession(): boolean {
        return this.fees.some(f => f.isPurchasedOnCurrentSession);
    }

    get isCommitted(): boolean {
        return this.fees.all(f => f.isCommitted);
    }

    get shouldBeDisplayedInShoppingCart(): boolean {
        return this.fees.all(f => f.shouldBeDisplayedInShoppingCart);
    }

}
