import {DateSelectionStrategyBase} from "./date-selection-strategy-base";
import {IFlightsScheduleViewModel} from "../../flights-schedule/flights-schedule-view-model.interface";
import {NullableDate} from "../../../../types/nullable-types";
import {ILowFareResult} from "../../../low-fare/low-fare-readers/low-fare-reader.interface";

export class ReturnDateSelectionStrategy extends DateSelectionStrategyBase {

    protected get flightSchedule(): IFlightsScheduleViewModel {
        return this.flightSearchController.returnFlightSchedule;
    }

    getCurrentDate(): NullableDate {
        return this.flightSearchController.returnDate;
    }

    setCurrentDate(date: Date): void {
        this.flightSearchController.returnDate = date;
    }

    getLowFare(date: Date): ILowFareResult {
        return this.lowFareReader.getReturnLowFare(date);
    }

    isDateAvailable(date: Date): boolean {
        const departureTime = this.flightSearchController.departureDate?.getTime() || 0;
        return date.getTime() >= departureTime
               && super.isDateAvailable(date);
    }


}
