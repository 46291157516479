import {IServiceFactory} from "../../service-factory.interface";
import {computed, IReactionDisposer, makeObservable, observable, reaction, runInAction} from "mobx";
import {IDotRezBundleAvailability} from "../../dot-rez-api/data-contracts/booking/bundles-availability/bundles-availability.data-contracts";
import {NullableString, NullableUndefinedString} from "../../../types/nullable-types";
import {MaturePassengerModel} from "./passenger/mature-passenger.model";
import {BookingContactModel} from "./contacts/booking-contact.model";
import {PassengersListModel} from "./passenger/passengers-list.model";
import {Check} from "../../../types/type-checking";
import {Price} from "../../currency/price";
import {ISsrType} from "../../ssr-types/ssr-types.service.interface";
import {
    IDotRezBookingSessionData,
    IDotRezPartialBookingSessionData
} from "../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {
    IDotRezBookingComment,
    IDotRezBookingData,
    IDotRezContact
} from "../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IDotRezBookingSession} from "../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {IDotRezUpdatePassengerDetailsRequest} from "../../dot-rez-api/data-contracts/requests/booking/update-passenger-details.request";
import {BookingSessionStorageKeys, IBookingStorage} from "./storage/booking-storage.interface";
import {IBookingViewModel, IFareToSell} from "./booking-view-model.interface";
import {IDotRezSsrAvailability} from "../../dot-rez-api/data-contracts/booking/ssrs-availability/ssr-availability.data-contracts";
import {PassengerSegmentModel} from "./passenger-segment/passenger-segment.model";
import {SegmentModel} from "./segment/segment.model";
import {JourneyModel} from "./journey/journey.model";
import {IDotRezCheckInRequirements} from "../../dot-rez-api/data-contracts/booking/check-in/check-in.data-contracts";
import {IDotRezCheckInSegmentPassengersRequest} from "../../dot-rez-api/data-contracts/requests/booking/check-in-segment-passengers.request";
import {ISegmentViewModel} from "./segment/segment-view-model.interface";
import {IBookingStrategyToBookingAdapter} from "../booking-strategies/booking-strategy-to-booking.adapter.interface";
import {IPassengerSegmentBoardingPassViewModel} from "../boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {PaymentHandlerModel} from "./payment/payment-handler.model";
import {BoardingPassesReader} from "../boarding-pass/boarding-passes-reader";
import {VoucherModel} from "./promotions/voucher/voucher.model";
import {BookingMutationsManagerModel} from "./mutation-actions/booking-mutations-manager.model";
import {SellSsrsMutation} from "./mutation-actions/sell-ssrs/sell-ssrs.mutation";
import {PromoCodeModel} from "./promotions/promo-code/promo-code.model";
import {BookingSeatsMapsEditorModel} from "./seat-maps/booking-seats-maps-editor.model";
import {IPersonViewModel} from "../../user/models/person/person-view-model.interface";
import {BookingBlueBenefitsModel} from "./blue-benefits/booking-blue-benefits.model";
import {IBookingAnalyticsConfiguration} from "./analytics/booking-analytics-configuration.inteface";
import {ManageMyBookingModel} from "./manage-my-booking/manage-my-booking.model";
import {BookingSnapshotModel} from "./snapshots/booking/booking-snapshot.model";
import {Lazy} from "../../../utils/lazy";
import {IFlightSearchControllerViewModel} from "../../flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {BookingBaseModel} from "./base-models/booking/booking-base.model";
import {BookingCommentTypeEnum} from "../../dot-rez-api/data-contracts/enums/booking-comment-type.enum";
import {FlexConsumptionHandler} from "./manage-my-booking/flex-consumption-handler";
import {BookingShoppingCartModel} from "./shopping-cart/booking/booking-shopping-cart.model";
import {ShoppingCartModeEnum} from "../booking-strategies/booking-strategy.interface";
import {PassengerFeeModel} from "./fees/passenger/passenger-fee.model";
import {BookingInsuranceModel} from "./insurance/booking-insurance.model";
import {AirlineCompanyName} from "../../../global-constants";
import {IInvoiceModel} from "./invoice/invoice.model.interface";
import {AeroitaliaInvoiceModel} from "./invoice/aeroitalia/aeroitalia.invoice.model";
import {SpecialPriceMarketDiscountOptions} from "../../stations/station.service.interface";
import {
    IBookingSsrsAggregatorOptions,
    IBookingSsrsAggregatorViewModel
} from "./ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {BookingSsrsAggregator} from "./ssrs/aggregator/booking-ssrs-aggregator";
import {SameForAllFlightsStorage} from "./storage/same-for-all-flights-storage";
import {IWizard} from "../../../models/wizard/wizard.interface";
import {composeDesignatorUniqueKey} from "./designator/flight-designator.model";
import {ValidationResultEnum} from "../../../types/validation-result.enum";
import {
    BookingAnalyticsHandlerModel
} from "./analytics/booking-analytics-handler.model";
import {BookingErrorHandlingModel} from "./booking-error-handling.model";


export class BookingModel extends BookingBaseModel implements IBookingViewModel {
    constructor(public readonly services: IServiceFactory,
                public readonly session: IDotRezBookingSession,
                bookingSessionData: IDotRezBookingSessionData,
                public readonly bookingStrategyAdapter: IBookingStrategyToBookingAdapter) {
        super();
        this.storage = bookingStrategyAdapter.createBookingStorage(session.token);
        this.sameForAllFlightsStorage = new SameForAllFlightsStorage(this);
        this.bookingTimestamp = this._createTimeStamp();
        this._bookingSessionData = bookingSessionData;
        this.mutationsManager = new BookingMutationsManagerModel(this);
        this.errorHandling = new BookingErrorHandlingModel(this);

        const storedDepartureJourneyKey = this.storage.getItem(BookingSessionStorageKeys.departureJourneyKey);
        if(!storedDepartureJourneyKey && this.bookingData.journeys[0]?.journeyKey) {
            this.storage.setItem(BookingSessionStorageKeys.departureJourneyKey, this.bookingData.journeys[0].journeyKey);
        }

        const storedReturnJourneyKey = this.storage.getItem(BookingSessionStorageKeys.returnJourneyKey);
        if(!storedReturnJourneyKey && this.bookingData.journeys[1]?.journeyKey) {
            this.storage.setItem(BookingSessionStorageKeys.returnJourneyKey, this.bookingData.journeys[1].journeyKey);
        }

        this._createJourneysModels();

        this.paymentHandler = new PaymentHandlerModel(this);
        this.analyticsHandler = new BookingAnalyticsHandlerModel(this);
        this.blueBenefits = new BookingBlueBenefitsModel(this);
        this.shoppingCart = new BookingShoppingCartModel(this);
        this.insurance = new BookingInsuranceModel(this);

        //This will prevent the user to see the balance due jumping up and down when multiple mutations are running and updates the balance due multiple times
        this._reactions.push(reaction(() => this.mutationsManager.isBookingTotalUpdateInProgress,
                (isTotalUpdateInProgress) => {
                    if(isTotalUpdateInProgress) {
                        this._freezeBalanceDueUpdates();
                    } else {
                        this._unFreezeBalanceDueUpdates();
                    }
        }));

        makeObservable<this, '_bookingSessionData'
                             | '_departureJourney'
                             | '_returnJourney'
                             | '_frozenBalanceDue'
                             | '_bookingContactModel'
                             | '_isBookingDiscardChangesInProgress'
                             | '_travelConditionsAccepted'
                             >(this, {
            _bookingSessionData: observable,
            _departureJourney: observable.ref,
            _returnJourney: observable.ref,
            allAvailableSsrsCodes: computed,
            filteredJourneys: computed,
            unfilteredJourneys: computed,
            _frozenBalanceDue: observable.ref,
            _bookingContactModel: observable.ref,
            _isBookingDiscardChangesInProgress: observable.ref,
            _travelConditionsAccepted: observable.ref
        });
    }

    readonly sameForAllFlightsStorage: SameForAllFlightsStorage;
    readonly storage: IBookingStorage;
    readonly mutationsManager: BookingMutationsManagerModel;
    private _bookingSessionData: IDotRezBookingSessionData;
    readonly paymentHandler: PaymentHandlerModel;
    readonly analyticsHandler: BookingAnalyticsHandlerModel;
    readonly errorHandling: BookingErrorHandlingModel;
    private readonly _invoice: Lazy<IInvoiceModel> = new Lazy<IInvoiceModel>(() => {
        return new AeroitaliaInvoiceModel(this);
        //return new BlueAirInvoiceModel(this);
    });

    get invoice(): IInvoiceModel {
        return this._invoice.value;
    }
    readonly blueBenefits: BookingBlueBenefitsModel;
    readonly shoppingCart: BookingShoppingCartModel;
    readonly insurance: BookingInsuranceModel;


    readonly bookingTimestamp: number;
    private _reactions: IReactionDisposer[] = [];

    private _createTimeStamp(): number {
        const storedTimestamp = this.storage.getItem(BookingSessionStorageKeys.bookingTimestamp);
        if(storedTimestamp) {
            return parseInt(storedTimestamp);
        } else {
            const timeStamp = this.services.time.currentDate.getTime();
            this.storage.setItem(BookingSessionStorageKeys.bookingTimestamp, timeStamp.toString());
            return timeStamp;
        }
    }

    private readonly _seatsMapsEditors: Lazy<BookingSeatsMapsEditorModel> = new Lazy<BookingSeatsMapsEditorModel>(() => new BookingSeatsMapsEditorModel(this))
    get seatsMapsEditors(): BookingSeatsMapsEditorModel {
        return this._seatsMapsEditors.value;
    }

    private readonly _manageMyBooking: Lazy<ManageMyBookingModel> = new Lazy<ManageMyBookingModel>(() => new ManageMyBookingModel(this));
    get manageMyBooking(): ManageMyBookingModel {
        return this._manageMyBooking.value;
    }

    private readonly _voucherModel: Lazy<VoucherModel> = new Lazy<VoucherModel>(() => new VoucherModel(this));
    get voucher(): VoucherModel {
        return this._voucherModel.value;
    }
 
    get token(): string {
        return this.session.token;
    }

    get bookingSessionData(): IDotRezBookingSessionData {
        return this._bookingSessionData;
    }

    get hasPromotionApplied(): boolean {
        return this.promoCode.isApplied || this.getAllPassengersSegments().some(p => p.shoppingCart.hasPromotionApplied);
    }


    get bookingData(): IDotRezBookingData {
        return this.bookingSessionData.bookingData;
    }



    get bundlesAvailability(): IDotRezBundleAvailability[] {
        return this.bookingSessionData.bundlesAvailability;
    }

    get ssrsAvailability(): IDotRezSsrAvailability {
        return this.bookingSessionData.ssrsAvailability;
    }

    get checkInRequirements(): IDotRezCheckInRequirements | null | undefined {
        return this.bookingSessionData.checkInRequirements;
    }

    get shouldSyncContactWithPrimaryPassenger(): boolean {
        return this.bookingStrategyAdapter.shouldSyncContactWithPrimaryPassenger;
    }
    private _bookingContactModel: Lazy<BookingContactModel> = new Lazy<BookingContactModel>(() => new BookingContactModel(this));
    get contact(): BookingContactModel {
        return this._bookingContactModel.value;
    }


    get bookingKey(): NullableString {
        return this.bookingData.bookingKey;
    }
    get recordLocator(): NullableString {
        return this.bookingData.recordLocator;
    }

    get isCommitted(): boolean {
        return Boolean(this.recordLocator);
    }


    private _passengers: PassengersListModel | null = null;
    get passengers(): PassengersListModel {
        if(this.bookingData.passengers.length === 0) {
            return new PassengersListModel();
        }

        if(!this._passengers) {
            this._passengers = new PassengersListModel(this
                .bookingSessionData
                .bookingData
                .passengers.map((item, index) => new MaturePassengerModel(item.key, index, this)));

            if(Check.isNullOrUndefined(this.storage.getItem(BookingSessionStorageKeys.primaryContact))) {
                const defaultContactPassenger =  this._passengers.find(p => p.passengerType.canBeMadePrimaryContact);
                if(defaultContactPassenger) {
                    defaultContactPassenger.isPrimaryContact = true;
                }
            }
        }
        return this._passengers;
    }

    private _journeyKeyExistsInBooking(journeyKey: string): boolean {
        return Boolean(this.bookingData.journeys.find(j => j.journeyKey === journeyKey));
    }

    private _tryFindJourneyKey(index: number, storageKey: string): NullableUndefinedString {
        let journeyKey = this.storage.getItem(storageKey);
        if(!journeyKey || !this._journeyKeyExistsInBooking(journeyKey)) {
            return this.bookingData.journeys[index]?.journeyKey;
        }

        return journeyKey;
    }

    private _createDepartureJourneyModel(): JourneyModel | null {
        let journeyKey = this._tryFindJourneyKey(0, BookingSessionStorageKeys.departureJourneyKey);
        if(journeyKey) {
            return new JourneyModel(journeyKey, this, this.initialBookingSnapshot.departureJourney);
        } else {
            return null;
        }
    }

    private _createReturnJourneyModel(): JourneyModel | null {
        let journeyKey = this._tryFindJourneyKey(1, BookingSessionStorageKeys.returnJourneyKey);

        if(journeyKey) {
            return new JourneyModel(journeyKey, this, this.initialBookingSnapshot.returnJourney);
        } else {
            return null;
        }
    }


    private _createJourneysModels(): void {
        runInAction(() => {
            this._departureJourney = this._createDepartureJourneyModel();
            this._returnJourney = this._createReturnJourneyModel();
        });
    }

    private _departureJourney: JourneyModel | null = null;
    get departureJourney(): JourneyModel | null {

        if(this._departureJourney && !this.bookingStrategyAdapter.shouldFilterOutJourney(this._departureJourney)) {
            return this._departureJourney;
        }

        if(this._returnJourney && !this.bookingStrategyAdapter.shouldFilterOutJourney(this._returnJourney)) {
            return this._returnJourney;
        }

        return null;
    }

    private _returnJourney: JourneyModel | null = null;
    get returnJourney(): JourneyModel | null {
        if(this.departureJourney?.journeyKey === this._returnJourney?.journeyKey) {
            return null;
        }

        if(this._returnJourney && !this.bookingStrategyAdapter.shouldFilterOutJourney(this._returnJourney)) {
            return this._returnJourney;
        }

        return null;
    }

    get unfilteredJourneys(): JourneyModel[] {

        let result: JourneyModel[] = [];

        if(this._departureJourney) {
            result.push(this._departureJourney);
        }

        if(this._returnJourney) {
            result.push(this._returnJourney);
        }

        return result;
    }

    get filteredJourneys(): JourneyModel[] {
        let journeys: JourneyModel[] = [];
        if(this.departureJourney) {
            journeys.push(this.departureJourney);
        }

        if(this.returnJourney) {
            journeys.push(this.returnJourney);
        }

        return journeys;
    }


    get isOneWayTrip(): boolean {
        return !Boolean(this.returnJourney);
    }

    get minSeatsFee(): Price {
        return Price.min(this.filteredJourneys.map(journey => journey.minSeatsFee),
                         this.createPrice(0));
    }

    get allAvailableSsrsCodes(): Record<string, string> {
        const ssrsCodes: Record<string, string> = {};
        this.ssrsAvailability.segmentSsrs.forEach(segmentSsrs => {
            segmentSsrs.ssrs.forEach(ssr => ssrsCodes[ssr.ssrCode] = ssr.ssrCode);
        });
        return ssrsCodes;
    }

    hasAvailabilityForSSR(ssrType: ISsrType): boolean {
        for(let j of this.filteredJourneys) {
            for(let s of j.segments) {
                if(s.ssrsAvailability.getSsrAvailability(ssrType).availableQuantity > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    hasPurchasesForSSR(ssrType: ISsrType): boolean {
        return this.getAllPassengersSegments().some(p => p.getSsr(ssrType).currentQuantity > 0);
    }

    private async _onAfterSellDepartureJourney(fareToSell: IFareToSell, bookingSessionData: IDotRezPartialBookingSessionData): Promise<void>{
        runInAction(() => {
            this.unfilteredJourneys.forEach(j => j.markQuantityAsUnsetForAllSoldSsrs());
            this.storage.setItem(BookingSessionStorageKeys.departureJourneyKey, fareToSell.journeyKey);
            this._passengers = null;
            this.updateBookingSessionData(bookingSessionData);
            this._createJourneysModels();
            // Razvan - call for view_item (I have also access to bundlelist)
            if(this.departureJourney){
                this.services.analytics.flightSelectionEvents.logSelectItemForDeparture(this.departureJourney);
            }
        });
    }


    async sellDepartureJourney(fareToSell: IFareToSell): Promise<void> {
        await this.bookingStrategyAdapter.sellDepartureJourney(fareToSell, (bookingSessionData) => this._onAfterSellDepartureJourney(fareToSell, bookingSessionData));
    }

    async sellDepartureJourneyBundle(bundleCode: string): Promise<void> {
        await this.bookingStrategyAdapter.sellDepartureJourneyBundle(bundleCode);
    }

    private async _onAfterSellReturnJourney(fareToSell: IFareToSell, bookingSessionData: IDotRezPartialBookingSessionData): Promise<void> {
        runInAction(() => {
            this.unfilteredJourneys.forEach(j => j.markQuantityAsUnsetForAllSoldSsrs());
            this.storage.setItem(BookingSessionStorageKeys.returnJourneyKey, fareToSell.journeyKey);
            this.updateBookingSessionData(bookingSessionData);
            this._createJourneysModels()

            if(this.returnJourney) {
                this.services.analytics.flightSelectionEvents.logSelectItemForReturn(this.returnJourney!);
            }
        });
    }

    async sellReturnJourney(fareToSell: IFareToSell): Promise<void> {
        await this.bookingStrategyAdapter.sellReturnJourney(fareToSell, bookingSessionData => this._onAfterSellReturnJourney(fareToSell, bookingSessionData));
    }

    async sellReturnJourneyBundle(bundleCode: string): Promise<void> {
        await this.bookingStrategyAdapter.sellReturnJourneyBundle(bundleCode);
    }

    get allJourneysAreCanceled(): boolean {
        return this.unfilteredJourneys.all(j => j.isCanceled);
    }

    get initialBookingSnapshot(): BookingSnapshotModel {
        return this.bookingStrategyAdapter.initialBookingSnapshot;
    }

    getAllSegments(): SegmentModel[] {
        return this.filteredJourneys.selectMany(j => j.segments);
    }

    getAllPassengersSegments(): PassengerSegmentModel[] {
        return this.getAllSegments().selectMany(s => s.passengers);
    }

    getSSR(ssrCode: string): ISsrType {
        return this.services.ssrTypes.getSsrType(ssrCode);
    }

    private _sellSsrDebounceTimer: any = null;
    async sellSsrs(options?: {skipDebounce: boolean}): Promise<void> {

        options = {
            skipDebounce: false,
            ...options
        };

        clearTimeout(this._sellSsrDebounceTimer);

        if(options.skipDebounce) {
            this.mutationsManager.startMutation(new SellSsrsMutation(this));
        } else {
            this._sellSsrDebounceTimer = setTimeout(() => {
                this.mutationsManager.startMutation(new SellSsrsMutation(this));
            }, 350);
        }

    }

    updateBookingSessionData(bookingSessionData: IDotRezPartialBookingSessionData): void {
        runInAction(() => {
            this._bookingSessionData = {
                ...this.bookingSessionData,
                ...bookingSessionData
            }
        });
    }

    async reset(): Promise<void> {
        await this.session.bookingReset();
    }


    async addContact(contactData: IDotRezContact): Promise<void> {
        await this.session.addBookingContact(contactData);
    }

    async updateContact(contactData: IDotRezContact): Promise<void> {
        await this.session.updateBookingContact(contactData);
    }

    async updatePassengerInfo(passengerKey: string,  request: IDotRezUpdatePassengerDetailsRequest): Promise<void> {
        await this.session.updatePassengerDetails(passengerKey, request);
    }

    getSoldSsrsCount(ssrType: ISsrType): number {
        return this.getAllPassengersSegments().sum(ps => ps.getSsr(ssrType).currentQuantity);
    }

    getSoldMeals(segment?: ISegmentViewModel): Record<string, {ssrType: ISsrType, count: number}> {
        const soldMeals: Record<string, {ssrType: ISsrType, count: number}> = {};

        let passengerSegments = this.getAllPassengersSegments();

        if(segment){
            passengerSegments = passengerSegments.filter(ps => ps.segmentKey === segment.segmentKey);
        }

        for(let mealSsrType of this.services.ssrTypes.getMealsSsrTypes()) {
            for(let passengerSegment of passengerSegments) {
                const ssr = passengerSegment.getSsr(mealSsrType);
                if(ssr.newQuantity > 0) {
                    if(!soldMeals[mealSsrType.ssrCode]) {
                        soldMeals[mealSsrType.ssrCode] = {
                            ssrType: mealSsrType,
                            count: 0
                        };
                    }
    
                    soldMeals[mealSsrType.ssrCode].count += ssr.newQuantity;
                }
            }
        }

        return soldMeals;
    }

    dispose() {
        this.paymentHandler.dispose();
        this.analyticsHandler.dispose();
        this.contact.dispose();
        this.passengers.forEach(p => p.dispose());
        this.insurance.dispose();
        this.session.dispose();
        this.storage.dispose();
        if(this._manageMyBooking.isInitialized) {
            this._manageMyBooking.value.dispose();
        }

        this._reactions.forEach(r => r());
        this._reactions = [];
    }

    async executeCheckIn(options: {saveTravelDocuments: boolean}): Promise<ValidationResultEnum> {
        return await this.services.loadingIndicator.execute({
            action: async () => {
                try {
                    if(options.saveTravelDocuments) {
                        await this.passengers.saveTravelDocuments();
                    }

                    await this._executeOnlineCheckInForEligiblePassengers();

                    return ValidationResultEnum.Success;
                } catch (err) {
                    this.services.logger.error('Failed to execute checkin', err);
                    await this.services.alert.showErrorAsync(this.services.language.translate('There was an unexpected error trying to execute the check-in operation.'));
                    return ValidationResultEnum.Failure;

                }

            }
        });
    }

    private async _executeOnlineCheckInForEligiblePassengers(): Promise<void> {

        let request: IDotRezCheckInSegmentPassengersRequest[] = [];
        this.filteredJourneys.filter(j => j.isOpenForCheckIn)
                                  .forEach(journey => {
                                      journey.segments.forEach(segment => {
                                          const eligiblePassengers = segment.getEligiblePassengersForOnlineCheckIn()
                                              .filter(passengerSegment => !passengerSegment.isCheckedIn)
                                              .map(passengerSegment => {
                                                  return {passengerKey: passengerSegment.passengerKey}
                                              });

                                          if (eligiblePassengers.length > 0) {
                                              request.push({
                                                  segmentKey: segment.segmentKey,
                                                  shouldAssignSeats: segment.passengers.some(p => Check.isNullOrUndefined(p.assignedSeat)),
                                                  request: {
                                                      passengers: eligiblePassengers
                                                  }
                                              });
                                          }
                                      });
                                  });

        if(request.length === 0) {
            await this.commitBookingAndUpdateBookingState();
            return;
        }

        await this.session.checkInPassengers(request);

        const bookingStateData = await this.session.bookingStateQueryBuilder().useBookingData().useCheckInRequirements().getBookingState();

        this.updateBookingSessionData(bookingStateData);
    }


    async generateBoardingPasses(): Promise<IPassengerSegmentBoardingPassViewModel[]> {
        const boardingPassesReader = new BoardingPassesReader(this.bookingData, this.session, this.services);
        const boardingPasses = await boardingPassesReader.getBoardingPasses();
        return this.services.bookingHistory.saveBoardingPasses(boardingPasses);
    }

    saveToMyTrips() {
        if(!this.recordLocator) {
            return;
        }

        if(this.services.layout.shouldUseWebappLayout) {
            return;
        }

        try {
            this.services.bookingHistory.saveToMyTrips(this);
            this.generateBoardingPasses().catch(err => {
                this.services.logger.error('Failed to generate boarding passes after saveToMyTrips', err);
            });
        } catch (err) {
            this.services.logger.error(`Failed to save booking ${this.recordLocator} in local storage`, err);
        }
    }


    async getBoardingPassesToShow(): Promise<IPassengerSegmentBoardingPassViewModel[]> {
        let segments = this.getAllSegments().map(segment => segment.designator.uniqueKey);
        const bPasses = await this.generateBoardingPasses();
        return bPasses.filter(bp => segments.includes(composeDesignatorUniqueKey(bp.designator.origin.code, bp.designator.destination.code)));
    }


    async showBoardingPasses(): Promise<void> {
        const boardingPasses = await this.services.loadingIndicator.execute({
            action: async () => {
                this.services.bookingHistory.saveToMyTrips(this);
                return await this.getBoardingPassesToShow();
            }
        });

        await this.services.dialogFactory.showBoardingPasses({
            bookingKey: this.bookingData.bookingKey!,
            boardingPasses: boardingPasses
        });
    }


    private _frozenBalanceDue: Price | null = null;

    private _freezeBalanceDueUpdates(): void {
        runInAction(() => {
            this._frozenBalanceDue = this.balanceDue;
        })
    }

    private _unFreezeBalanceDueUpdates(): void {
        runInAction(() => {
            this._frozenBalanceDue = null;
        })
    }

    get balanceDue(): Price {
        if(this._frozenBalanceDue) {
            return this._frozenBalanceDue;
        }

        return this.createPrice(this.bookingData.breakdown.authorizedBalanceDue - this.voucher.amount);
    }

    get hasPurchasesOnCurrentSession(): boolean {
        return this.balanceDue.amount > 0
                || this.shoppingCart.hasPurchaseOnCurrentSession
                || this.passengers.some(p => p.hasChangesOnCurrentSession() || Boolean(p.infant?.hasChangesOnCurrentSession()))
                || this.contact.hasChangesOnCurrentSession();
    }

    get hasChangedSegments(): boolean {
        return this.unfilteredJourneys.some(j => j.hasChangedSegments);
    }

    get isDomesticFlight(): boolean {
        const allSegments = this.getAllSegments();
        if(allSegments.length === 0) {
            return false;
        }

        return allSegments.all(seg => seg.isDomesticFlight());

    }


    private _promoCode: PromoCodeModel | null = null;
    get promoCode(): PromoCodeModel {
        if(!this._promoCode) {
            this._promoCode = new PromoCodeModel(this);
        }
        return this._promoCode;
    }

    get allowPromoCode(): boolean {
        return this.bookingStrategyAdapter.allowPromoCode;
    }

    private _getBlueBenefitsComment(): IDotRezBookingComment[] {
        const blueBenefitsComment = this.blueBenefits.createBookingCommentText();
        if(blueBenefitsComment) {
            return [this._createComment(blueBenefitsComment)];
        }

        return [];
    }

    async commitBooking(): Promise<void> {
        await this.session.commitBooking({
            notifyContacts: true,
            channel: this.services.booking.getCommitBookingChannel(),
            comments: this._getBlueBenefitsComment()
        });
    }

    async commitBookingAndUpdateBookingState(): Promise<void> {

        await this.commitBooking();

        const bookingSessionData =  await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();

        this.updateBookingSessionData(bookingSessionData);

    }

    shouldShowSsrOnCurrentFlow(ssrType: ISsrType): boolean {
        return this.bookingStrategyAdapter.canShowSsr(ssrType);
    }

    getAvailableCompanions(): IPersonViewModel[] {
        let usedCompanions = this.passengers.filter(p => p.customerNumber).map(p => p.customerNumber);
        usedCompanions = [
            ...usedCompanions,
            ...this.passengers.filter(p => p.infant?.customerNumber)
                              .map(p => p.infant?.customerNumber!)
        ]
        return this.services.user.profile.getCompanionsForBooking().filter(companion => !usedCompanions.includes(companion.customerNumber));
    }



    get analyticsConfiguration(): IBookingAnalyticsConfiguration {
        return {
            ...this.bookingStrategyAdapter.analyticsConfiguration,
            itemBrand: AirlineCompanyName,
            itemVariant: this.isOneWayTrip ? "OneWay" : "RoundTrip"
        };
    }

    async upgradeToAuthorizedUser(userName: string, password: string): Promise<void> {
        await this.session.upgradeToAuthorizedUser(userName, password);
    }

    sendAnalyticsPurchaseEvent(): void {
        if(!this.recordLocator) {
            this.services.logger.error('Cannot send purchase event for uncommitted booking');
            return;
        }

        try {
            this.services.analytics.paymentEvents.logPurchase(this);
        } catch (err) {
            this.services.logger.error(`Failed to send purchase event for booking ${this.recordLocator}`, err);
        }
    }

    get flightSearchController(): IFlightSearchControllerViewModel {
        return this.bookingStrategyAdapter.flightSearchController;
    }

    private async _moveBetweenQueues(fromQueue: string, toQueue: string): Promise<boolean> {
        if(!this.isInQueue(fromQueue)) {
            return false;
        }
        try {
            await this.session.putBookingInQueue({
                queueCode: toQueue,
                authorizedBy: 'MobileApp',
                notes: `Moved from ${fromQueue}`
            });

            try {
                await this.session.removeBookingFromQueue({
                    queueCode: fromQueue,
                    authorizedBy: 'MobileApp',
                    notes: `Moved to ${toQueue}`
                });
            } catch (errRemove) {
                this.services.logger.error(`Failed to remove booking ${this.recordLocator} from queue ${fromQueue}`, errRemove);
            }

            return true;
        } catch (err) {
            this.services.logger.error(`Failed to move booking ${this.recordLocator} from queue ${fromQueue} to queue ${toQueue}`, err);
            return false;
        }
    }

    private _createComment(text: string): IDotRezBookingComment {
        return {
            type: BookingCommentTypeEnum.Default,
            text: text
        };
    }

    async moveBetweenDisruptionQueues(): Promise<void> {

        if(!this.initialBookingSnapshot.canMoveDisruptedFlights) {
            return;
        }

        if(!this.hasChangedSegments) {
            return;
        }

        const results: boolean[] = [];
        results.push(await this._moveBetweenQueues('CXL', 'CXLSM'))
        results.push(await this._moveBetweenQueues('REAO3H', 'RMO3SM'));
        results.push(await this._moveBetweenQueues('SCHO3H', 'SCO3SM'))


        if(results.some(moved => moved)) {

            const date = this.services.time.formatYYY_MM_DD(this.services.time.currentDate);
            const time = this.services.time.formatHHmm(this.services.time.currentDate);
            const email = this.contact.fields.emailAddress.value;
            try {
                await this.session.commitBooking({
                    comments: [
                        this._createComment(`Move; ${email}; ${date}; ${time}; mobile application`)
                    ],
                    notifyContacts: false,
                    channel: this.services.booking.getCommitBookingChannel()
                });
            } catch (err) {
                this.services.logger.error('Failed to add move comment', err);
            }

        }
    }


    async consumeFlex(bookingData: IDotRezBookingData | null | undefined): Promise<IDotRezPartialBookingSessionData> {
        const handler = new FlexConsumptionHandler(this);
        return await handler.consumeFlex(bookingData);
    }

    get passengersNamesChangeBlockingReason(): NullableString {
        return this.initialBookingSnapshot.passengersNamesChangeBlockingReason;
    }

    private _isBookingDiscardChangesInProgress: boolean = false;
    get isBookingDiscardChangesInProgress(): boolean {
        return this._isBookingDiscardChangesInProgress;
    }

    private set isBookingDiscardChangesInProgress(value: boolean) {
        runInAction(() => {
            this._isBookingDiscardChangesInProgress = value;
        })
    }

    async discardChanges(): Promise<void> {
        const email = this.initialBookingSnapshot.getAnyContactEmail();
        const recordLocator = this.initialBookingSnapshot.recordLocator;

        if(!recordLocator || !email) {
            throw new Error('discardChanges is not possible for a booking without e-mail and record locator');
        }

        this.isBookingDiscardChangesInProgress = true;

        try {
            await this.reset();

            const bookingData = await this.session.bringBookingInStateByEmail({
                recordLocator: recordLocator,
                emailAddress: email
            });

            const otherBookingStateInfo = await this.session.bookingStateQueryBuilder(bookingData).useSsrsAvailability().useBundlesAvailability().useSeatMaps().useCheckInRequirements().getBookingState();

            runInAction(() => {
                this.updateBookingSessionData({
                    bookingData: bookingData,
                    ...otherBookingStateInfo
                });

                this.storage.reset();
                this.sameForAllFlightsStorage.reset();

                if(this.bookingData.journeys[0]?.journeyKey) {
                    this.storage.setItem(BookingSessionStorageKeys.departureJourneyKey, this.bookingData.journeys[0].journeyKey);
                }

                if(this.bookingData.journeys[1]?.journeyKey) {
                    this.storage.setItem(BookingSessionStorageKeys.returnJourneyKey, this.bookingData.journeys[1].journeyKey);
                }

                this._createJourneysModels();
            });

            this.shoppingCart.notifications.clear();
        } finally {
            this.isBookingDiscardChangesInProgress = false;
        }

    }

    /**
     *  !!!!!!! ONLY FOR TESTING PURPOSE !!!!!!!
     */
    async payCash(): Promise<void> {
        await this.session.payCash(this.balanceDue);

        try {
            await this.session.commitBooking({
                notifyContacts: true,
                channel: this.services.booking.getCommitBookingChannel(),
                comments: this._getBlueBenefitsComment()
            });
        } finally {
            const bookingSessionData = await this.session.bookingStateQueryBuilder().useBookingData().useCheckInRequirements().getBookingState();
            this.updateBookingSessionData(bookingSessionData);
        }


        this.saveToMyTrips();

        this.sendAnalyticsPurchaseEvent();

        await this.services.booking.steps.nextStep();
    }

    get allowedShoppingCartModes(): ShoppingCartModeEnum[] {
        return this.bookingStrategyAdapter.allowedShoppingCartModes;
    }

    get isGDSBooking(): boolean {
        return this.initialBookingSnapshot.isGDSBooking;
    }

    protected _getAllPassengersFees(): PassengerFeeModel[] {
        return this.passengers.selectMany(p => p.fees);
    }

    getBookedDate(): Date {

        if(this.bookingData.info.bookedDate) {
            return this.services.time.parseIsoDate(this.bookingData.info.bookedDate);
        } else {
            return this.services.time.currentDate;
        }
    }

    private get specialMarketDiscountOptions(): SpecialPriceMarketDiscountOptions | null {
        const journey = this.departureJourney;
        if(!journey) {
            return null;
        }

        if(this.getBookedDate().getFullYear() !== journey.designator.departureDate.getFullYear()) {
            return null;
        }


        const specialPriceMarket = this.services.stations.getSpecialPriceMarket(journey.designator.origin, journey.designator.destination);

        if(!specialPriceMarket?.discountOptions) {
            return null;
        }


        return specialPriceMarket.discountOptions;
    }

    get hasSpecialPriceMarketDiscount(): boolean {
        return this.bookingStrategyAdapter.allowSpecialPriceMarketDiscount && Boolean(this.specialMarketDiscountOptions);
    }

    get needsToApplySpecialPriceMarketDiscount(): boolean {
        if(!this.hasSpecialPriceMarketDiscount) {
            return false;
        }

        return Boolean(this.specialMarketDiscountOptions?.needsToApplyDiscount(this));
    }

    async applySpecialPriceMarketDiscount(): Promise<void> {

        const discountOptions = this.specialMarketDiscountOptions;

        if(this.hasSpecialPriceMarketDiscount && discountOptions) {
            await discountOptions.applyDiscount(this);
        }
    }



    createSsrsAggregator(options: IBookingSsrsAggregatorOptions): IBookingSsrsAggregatorViewModel {
        return new BookingSsrsAggregator(this, options);
    }

    createBagsSsrsAggregator(): IBookingSsrsAggregatorViewModel {
        return new BookingSsrsAggregator(this, {
            journeysSsrsBuckets: this.filteredJourneys.map(j => j.allBags)
        });
    }

    private _travelConditionsAccepted: boolean = false;
    get travelConditionsAccepted(): boolean {
        return this._travelConditionsAccepted;
    }

    set travelConditionsAccepted(value: boolean) {
        runInAction(() => {
            this._travelConditionsAccepted = value
        });
    }

    get steps(): IWizard {
        return this.bookingStrategyAdapter.steps;
    }

    revertSsrsQuantity(): void {
        for(let ps of this.getAllPassengersSegments()) {
            ps.revertSsrsQuantity();
        }
    }

    get isNewBooking(): boolean {
        return !Boolean(this.recordLocator);
    }
}
