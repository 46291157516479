import {OnlineCheckInBlockerSsr} from "../online-check-in-blocker-ssr";
import {IServiceFactory} from "../../../service-factory.interface";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {SsrCategoryEnum} from "../../ssr-category.enum";

export default class CbbgSsrType extends OnlineCheckInBlockerSsr {
    constructor(services: IServiceFactory) {
        super('CBBG', services);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.CBBG
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.Baggage;
    }


}
