import {IServiceFactory} from "../../../../service-factory.interface";
import {PetsSsrTypeBase} from "../pets-ssr-type-base";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../ssrs-preferred-orders-in-shopping-cart";
import {TimeSpan} from "../../../../../types/time-span";


export default class AvihSsrType extends PetsSsrTypeBase {
    constructor(services: IServiceFactory) {
        super('AVIH', services);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.PET_IN_HOLD;
    }


    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(3);
    }
}
