import {ParentRouteModel} from "../../models/parent-route.model";
import {IUserRoutes} from "./user.routes.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {ChildRouteModel} from "../../models/child-route.model";
import {UserProfilePage} from "../../../../pages/user/user-profile/user-profile.page";
import {RouteBaseModel} from "../../models/route-base.model";
import {CompanionsPage} from "../../../../pages/companions/companions.page";
import { UserTravelDocumentsPage } from "../../../../pages/user/travel-documents/user-travel-documents.page";
import {BlueBenefitsPage} from "../../../../pages/blue-benefits/blue-benefits.page";

export class UserRoutes extends ParentRouteModel implements IUserRoutes {

    constructor(services: IServiceFactory) {
        super('/user', services);
    }

    protected _getDefaultChildRoute(): RouteBaseModel | null {
        return null;
    }

    profile = new ChildRouteModel('/user-profile', this,() => <UserProfilePage/>);
    companions = new ChildRouteModel('/companions', this, () => <CompanionsPage/>);
    travelDocuments = new ChildRouteModel('/user-travel-documents', this,() => <UserTravelDocumentsPage/>);
    blueBenefits = new ChildRouteModel('/blue-benefits', this, () => <BlueBenefitsPage/>);
}
