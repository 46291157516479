import React from "react";
import {observer} from "mobx-react";
import {SpinnerIcon} from "../../assets/svg/spinner.icon";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";

const SpinnerContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  font-size: ${props => props.theme.fontSize.xxSmall};
  font-weight: ${props => props.theme.fontWeight.bold};
  border-radius: 50%;
`

const SpinnerBox = styled.div`
    position: relative;
`

const SpinnerInnerText = styled.div`
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    text-align: center;
`

const TextBox = styled.div`
    width: fit-content;
    height: fit-content;
    border-radius: 2px;
`

export enum SpinnerStyle {
    LoadingIndicator,
    Spinner
}
interface SpinnerComponentProps {
    innerMessage?: string;
    promotionalMessageTitle?: string | React.ReactElement | null;
    promotionalMessage?: string | React.ReactElement | null;
    style?: SpinnerStyle
    size?: number
    className?: string;
}
export const SpinnerComponent: React.FC<SpinnerComponentProps> = observer((props) => {
    const services = useServices();
    //const style = Check.isNullOrUndefined(props.style) ? SpinnerStyle.Spinner : props.style;


    return (
        <SpinnerContainerBox className={props.className}>
            <SpinnerBox >
                <SpinnerIcon size={props.size ?? 11}/>
                <SpinnerInnerText>
                    <TextBox>
                        {props.innerMessage ?? services.language.translate('Loading...')}
                    </TextBox>
                </SpinnerInnerText>
            </SpinnerBox>
            {props.promotionalMessageTitle}
            {props.promotionalMessage}
        </SpinnerContainerBox>
    );
});
