import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const ArrowForwardIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} sizeIsHeight viewBox="0 0 11.875 18.661" ratio={11.875/18.661}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(0 18.93) rotate(-90)"><path d="M1.758,0C.791,0,0,.434,0,.964V11.79c0,.531.791.964,1.758.964s1.666-.434,1.758-.964V.964C3.508.434,2.718,0,1.758,0Z" transform="translate(11.775 9.019) rotate(135)"/><path d="M1.758,0C.791,0,0,.451,0,1V12.272c0,.552.488.5,1.541.894a1.422,1.422,0,0,0,1.975-.894V1c0-.552-.791-1-1.758-1Z" transform="translate(16.444 0) rotate(45)"/></g>
        </SvgIcon>
    )
}
