import React from 'react';
import {ISvgIconProps, SvgIcon} from './svg-icon.component';

export const CheckedIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 24 24" ratio={1}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(0)"><path d="M0,12A12,12,0,1,1,12,24,12.014,12.014,0,0,1,0,12Z" fill="#219653"/></g>
        </SvgIcon>
    )
}
