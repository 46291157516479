import {FormModel} from "../../../../models/forms/form.model";
import {IPasswordResetFormFields} from "./password-reset-form-fields.interface";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {IPasswordResetFormViewModel} from "./password-reset-form-view-model.interface";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {runInAction} from "mobx";
import {AuthorizedUserModel} from "../authorized-user/authorized-user.model";
import {IServiceFactory} from "../../../service-factory.interface";

export class PasswordResetFormModel extends FormModel<IPasswordResetFormFields> implements IPasswordResetFormViewModel {

    constructor(private readonly token: string, services: IServiceFactory) {
        super(services);
    }

    protected _createFields(): FormFields<IPasswordResetFormFields> {
        const language = this.services.language;
        return {
            emailAddress: this._createEmailField(),
            newPassword: this._createPasswordField({
                fieldName: () => language.translate('New password')
            })
        };
    }

    async executeResetPassword(): Promise<ValidationResultEnum> {
        this.activateErrorsValidation();
        if(this.hasErrors()) {
            return ValidationResultEnum.Failure;
        }

        const fields = this.fields;

        return await this.services.loadingIndicator.execute({
            action: async () => {
                const response = await this.services.airlineWebapi.resetPassword(this.token, fields.emailAddress.value!, fields.newPassword.value!);

                if(response.hasError) {
                    this.services.alert.showError(this.services.language.translateApiError(response.errorCode));
                    return ValidationResultEnum.Failure;
                }

                runInAction(() => {
                    this.services.user.switchUser(new AuthorizedUserModel(this.services, response.data.refreshToken, response.data.dotRezToken));
                });

                return ValidationResultEnum.Success;
            }
        })

    }

}

