import React from "react";
import {IJourneyViewModel} from "../../../../services/booking/models/journey/journey-view-model.interface";
import styled from "styled-components";
import {IMaturePassengerViewModel} from "../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {observer} from "mobx-react";
import {PassengerBagsComponent} from "./passenger-bags.component";
import {IPassengerAllBagsViewModel} from "../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";
import {BagsSelectionUISynchronizer} from "../bags-selection.helpers";
import {SameForAllPassengersToggleComponent} from "../../common-components/same-for-all-passengers-toggle.component";

const PassengersBagsSelection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large};
`

interface PassengersBagsComponentProps {
    journey: IJourneyViewModel;
    uiSynchronizer: BagsSelectionUISynchronizer;
}

export const PassengersBagsComponent: React.FC<PassengersBagsComponentProps> = observer((props) => {

    const useSameOptionsForAllPassengers = props.journey.allBags.useSameOptionsForAllPassengers;

    const currentPassengerBags = props.journey.allBags.passengersBags.find(pb => pb.passenger.passengerKey === props.uiSynchronizer.currentPassengerKey);



    const onClickHandler = (passengerBags: IPassengerAllBagsViewModel) => {
        if(useSameOptionsForAllPassengers && passengerBags.passenger.passengerKey !== currentPassengerBags?.passenger.passengerKey) {
            return;
        }

        if(passengerBags.passenger.passengerKey === props.uiSynchronizer.currentPassengerKey) {
            props.uiSynchronizer.currentPassengerIsExpanded = !props.uiSynchronizer.currentPassengerIsExpanded;
        } else {
            props.uiSynchronizer.currentPassengerKey = passengerBags.passenger.passengerKey;
            props.uiSynchronizer.currentPassengerIsExpanded = true;
        }
    }

    const onUseSameOptionsForAllPassengers = () => {
        props.uiSynchronizer.currentPassengerKey = props.journey.allBags.passengersBags[0].passenger.passengerKey;
    }

    const renderSameForAllPassengersToggle = () => {
        if(!props.journey.allBags.allowUseSameOptionsForAllPassengers) {
            return null;
        }
        return (
            <SameForAllPassengersToggleComponent ssrsBucket={props.journey.allBags}
                                                 onChecked={onUseSameOptionsForAllPassengers}/>
        )
    }

    const renderPassengerBags = (passengerBags: IPassengerAllBagsViewModel) => {
        const passenger: IMaturePassengerViewModel = passengerBags.passenger;
        let isExpanded = (passenger.passengerKey === props.uiSynchronizer.currentPassengerKey) && props.uiSynchronizer.currentPassengerIsExpanded;

        if(useSameOptionsForAllPassengers) {
            if(passengerBags.passenger.passengerKey !== props.journey.allBags.passengersBags[0].passenger.passengerKey) {
                isExpanded = false;
            }
        }

        if(passenger.passengerIndex === 0) {
            return (
                <React.Fragment key={passenger.passengerKey}>
                    <PassengerBagsComponent passengerBags={passengerBags}
                                            onClick={() => onClickHandler(passengerBags)}
                                            isExpandable={true}
                                            isExpanded={isExpanded}/>

                    {renderSameForAllPassengersToggle()}

                </React.Fragment>

            )
        }

        return (
            <PassengerBagsComponent key={passengerBags.passenger.passengerKey}
                                    passengerBags={passengerBags}
                                    onClick={() => onClickHandler(passengerBags)}
                                    isExpandable={!useSameOptionsForAllPassengers}
                                    isExpanded={isExpanded}/>

        );
    }

    return (
        <PassengersBagsSelection key={props.journey.allBags.useSameOptionsForAllPassengers.toString()}>
            {props.journey.allBags.passengersBags.map((p) => renderPassengerBags(p))}
        </PassengersBagsSelection>
    )
});
