import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";

export class RemovePromoCodeMutation extends BookingMutationActionBase {
    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        await this.session.removePromotionCode();
        return await this.session.bookingStateQueryBuilder().useBookingData().getBookingState()
    }

}
