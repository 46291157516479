import {ITimeService} from "./time.service.interface";
import {lastDayOfMonth} from "date-fns";

export class MonthModel {
    constructor(public readonly month: number, public readonly year: number, private readonly time: ITimeService) {
    }

    get key(): string {
        return `${this.year}_${this.month}`;
    }

    get firstDate(): Date {
        return new Date(this.year, this.month, 1);
    }

    get lastDate(): Date {
        return lastDayOfMonth(this.firstDate);
    }

    containsDate(date: Date): boolean {
        return this.firstDate.getTime() <= date.getTime()
                && date.getTime() <= this.lastDate.getTime();
    }

    getAllDates(): Date[] {
        return this.time.getDateRange(this.firstDate, this.lastDate);
    }
}
