import React from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {ManageMyBookingPassengersComponent} from "./components/passengers/manage-my-booking-passengers.component";
import {ManageMyBookingSectionComponent} from "./components/manage-my-booking-section.component";
import {ManageMyBookingContactSectionComponent} from "./components/contact/manage-my-booking-contact-section.component";
import {
    ManageMyBookingFlightsComponent
} from "./components/manage-my-booking-flights/manage-my-booking-flights.component";
import {BookingCodeComponent} from "../../../components/booking-code/booking-code.component";
import {BookingFlowPage} from "../../booking-flow/booking-flow.page";
import {ManageMyBookingServicesComponent} from "./services/manage-my-booking-services.component";
import {PrintTicketButtonComponent} from "../../../components/print-ticket/print-ticket-button.component";
import {IBookingViewModel} from "../../../services/booking/models/booking-view-model.interface";
import {ShowTicketDialogButtonComponent} from "../../../components/print-ticket/show-ticket-dialog-button.component";
import {ApplicationEnvironmentTypeEnum} from "../../../services/configuration/configuration-object.interface";

const ManageMyBookingDashboardPageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.extraLarge};
`

const NonModifiableBookingMessageBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: ${props => props.theme.fontSize.large};
    text-align: center;
    justify-content: center;
`

const BookingCodeAndPrintTicketBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
        font-size: ${props => props.theme.fontSize.medium};
    }
`

const BookingCodeBox = styled(BookingCodeComponent)`
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
`

const BookingCodeAndPrintTicketComponent: React.FC<{booking: IBookingViewModel}> = observer((props) => {
    const services = useServices();

    const renderShowTicketButton = () => {
        if(services.configuration.envConfigs.currentEnvironmentType === ApplicationEnvironmentTypeEnum.Development) {
            return (<ShowTicketDialogButtonComponent booking={props.booking}/>);
        }
        return null;
    }

    return (
        <BookingCodeAndPrintTicketBox>
            <BookingCodeBox recordLocator={props.booking.recordLocator}/>
            <div>
                <PrintTicketButtonComponent booking={props.booking} hideLabel={services.mediaQuery.xs}/>
                {renderShowTicketButton()}
            </div>

        </BookingCodeAndPrintTicketBox>
    );
})

export const ManageMyBookingDashboardPageContent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    if(!booking.manageMyBooking.canBeModified) {
        return (
            <NonModifiableBookingMessageBox>
                {services.language.translate('This booking cannot be modified')}
            </NonModifiableBookingMessageBox>
        )
    }



    return (
        <ManageMyBookingDashboardPageContentBox>
            <BookingCodeAndPrintTicketComponent booking={booking}/>
            <ManageMyBookingSectionComponent title={services.language.translate('Flights')}>
                <ManageMyBookingFlightsComponent/>
            </ManageMyBookingSectionComponent>


            <ManageMyBookingSectionComponent title={services.language.translate('Services')}>
                <ManageMyBookingServicesComponent/>
            </ManageMyBookingSectionComponent>

            

            <ManageMyBookingSectionComponent title={services.language.translate('Passengers')}>
                <ManageMyBookingPassengersComponent/>
            </ManageMyBookingSectionComponent>

            

            <ManageMyBookingSectionComponent title={services.language.translate('Booking Contact')}>
                <ManageMyBookingContactSectionComponent/>
            </ManageMyBookingSectionComponent>
        </ManageMyBookingDashboardPageContentBox>
    );
});


export const ManageMyBookingDashboardPage: React.FC = observer(() => {
    const services = useServices();
    return (
        <BookingFlowPage title={services.language.translate('Manage my booking')}
                         renderContent={() => <ManageMyBookingDashboardPageContent />}
                         showPromotionCode={false}
                         hideWizardProgressIndicator={true}
                         hideFooter={services.booking.isBookingRestoreInProgress || !services.booking.current.manageMyBooking.canBeModified}/>
    );
});
