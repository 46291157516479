import {PlatformDetection} from "../device/platform-detection";

export class LayoutDetection {
    constructor() {
        this._platformDetection = new PlatformDetection();
    }

    private readonly _platformDetection: PlatformDetection;

    private get shouldForceHybridLayout(): boolean {
        return localStorage.getItem('application.forceHybridLayout') === 'true';
    }

    get shouldUseWebappLayout(): boolean {
        return this._platformDetection.isWeb && !this.shouldForceHybridLayout;
    }
}
