import React, {useState} from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {useServices} from "../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {LoginFormComponent} from "./login-form.component";
import {LoginAndRegistrationDialogTabEnum} from "../../services/user/models/login-and-registration-dialog-tab.enum";
import styled, { css } from "styled-components";
import {RegistrationFormComponent} from "./registration-form.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {IonButton} from "@ionic/react";
import {ValidationResultEnum} from "../../types/validation-result.enum";
import {ILoginAndRegistrationHandlerViewModel} from "../../services/user/models/login-and-registration-handler/login-and-registration-handler-view-model.interface";

const TabHeaderItemBox = styled.div<{isSelected: boolean}>`
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
    text-align: center;
    width: 50%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${props => props.theme.fontWeight.bold};

    ${props => props.isSelected ? css`
        background: ${props.theme.colors.secondary};
        color: ${props.theme.colors.secondaryContrast};
    ` : `
        background: ${props.theme.colors.medium};
        color: ${props.theme.colors.primaryContrast};
    `}
`

const TabHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: ${props => props.theme.fontSize.xxLarge};
    border-bottom: 4px solid ${props => props.theme.colors.secondary};
`

const TabContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-top-style: none;
`

const RegistrationFormButtonBox = styled(IonButton)`
    font-size: ${props => props.theme.fontSize.large};
    margin: 0 10px;
    height: 2.8em;
    border-radius: 10px;  
    --border-radius: 10px; 
`

interface LoginAndRegistrationDialogComponentProps {
    tab: LoginAndRegistrationDialogTabEnum;
    hideRegistrationTab: boolean;
    dialogHandler: IDialogConfirmationHandler;
    loginAndRegistrationHandler: ILoginAndRegistrationHandlerViewModel;
}

export const LoginAndRegistrationDialogComponent: React.FC<LoginAndRegistrationDialogComponentProps> = observer((props) => {
    const services = useServices();
    const [currentTab, setCurrentTab] = useState(props.hideRegistrationTab ? LoginAndRegistrationDialogTabEnum.Login : props.tab);

    const loginText = services.language.translate('Login');
    const signUpText = services.language.translate('Sign up');

    const doLogin = async () => {

        const loginResult = await props.loginAndRegistrationHandler.executeLogin();
        if (ValidationResultEnum.Success === loginResult) {
            props.dialogHandler.accept();
        }

    }

    const doRegistration = async () => {
        const registrationResult = await props.loginAndRegistrationHandler.executeRegistration();
        if (registrationResult === ValidationResultEnum.Success) {
            props.dialogHandler.accept();
        }
    }

    const getHeaderTitle = () => {
        if (currentTab === LoginAndRegistrationDialogTabEnum.Login) {
            return loginText;
        } else {
            return signUpText;
        }
    }

    const onContinueAsAGuest = () => {
        props.dialogHandler.accept();
    }

    const renderTabContent = () => {
        if (currentTab === LoginAndRegistrationDialogTabEnum.Login) {
            return (
                <LoginFormComponent loginFormFields={props.loginAndRegistrationHandler.loginFormFields}
                                    onSubmit={doLogin}
                                    onContinueAsAGuest={props.loginAndRegistrationHandler.shouldShowContinueAsAGuestButton ? onContinueAsAGuest : undefined}/>
            );
        }
        return (
            <RegistrationFormComponent
                registrationFormFields={props.loginAndRegistrationHandler.registrationFormFields}/>
        );
    }

    const changeCurrentTab = (tab: LoginAndRegistrationDialogTabEnum) => {
        setCurrentTab(tab);
    }

    const renderFooter = () => {
        if (currentTab === LoginAndRegistrationDialogTabEnum.Registration) {
            return (
                <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                    <RegistrationFormButtonBox onClick={doRegistration}>
                        {services.language.translate('Create account')}
                    </RegistrationFormButtonBox>
                </StandardDialogFooterComponent>
            );
        }
        return null;
    }

    const renderTabHeaders = () => {
        if (props.hideRegistrationTab) {
            return null;
        }

        return (
            <TabHeader>
                <TabHeaderItemBox isSelected={currentTab === LoginAndRegistrationDialogTabEnum.Login}
                                  onClick={() => changeCurrentTab(LoginAndRegistrationDialogTabEnum.Login)}>
                    {loginText}
                </TabHeaderItemBox>
                <TabHeaderItemBox isSelected={currentTab === LoginAndRegistrationDialogTabEnum.Registration}
                                  onClick={() => changeCurrentTab(LoginAndRegistrationDialogTabEnum.Registration)}>
                    {signUpText}
                </TabHeaderItemBox>
            </TabHeader>
        );
    }


    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={getHeaderTitle()} dialogHandler={props.dialogHandler}>
                {renderTabHeaders()}
            </StandardDialogHeaderComponent>
            <StandardPageContentComponent>
                <TabContent>
                    {renderTabContent()}
                </TabContent>
            </StandardPageContentComponent>
            {renderFooter()}
        </StandardDialogPageComponent>
    );
});
