import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";


const PriceDisclaimerBox = styled.div`
    display: flex;
    flex-direction: column;
    text-align: justify;
    vertical-align: center;
    width: 100%;
    height: 100%;
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1.2;
    
`

export const FlightSearchPricingInformationDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Pricing information')} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <PriceDisclaimerBox>
                    {services.language.translate('The price shown is the lowest available price for 1 person. It may differ if the booking is done for more passengers, based on availability.')}
                </PriceDisclaimerBox>
            </StandardDialogContentComponent>
        </StandardDialogPageComponent>
    )
});
