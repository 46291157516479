import {ViewBookingDetailsStrategy} from "./view-booking-details.strategy";
import {IDotRezInitialBookingData} from "../booking-initial-data.interface";

export class TransientBookingStrategy extends ViewBookingDetailsStrategy {
    protected _saveTokenInSessionStorage(): void {
        //Don't do anything. TransientBookingStrategy should not save token in session storage
        //because if multiple TransientBookingStrategy will be created they will override each other token in the storage.
    }

    protected _persistInitialBookingDataInSessionStorage(initialBookingData: IDotRezInitialBookingData | null): void {
        //Don't do anything. TransientBookingStrategy initial data should not be saved in session storage
        //because if multiple TransientBookingStrategy will be created they will override each other initial data.
    }
}
