import React from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {DepartingFlightSelectionPageContent} from "./departing-flight-selection-page-content.component";
import {DepartingFlightSelectionExtraHeaderComponent} from "../components/extra-header/departing-flight-selection-extra-header.component";
import {BookingFlowPage} from "../../booking-flow.page";


export const DepartingFlightSelectionPage: React.FC = observer(() => {
    const services = useServices();

    const renderExtraHeader = () => {
        return (
            <DepartingFlightSelectionExtraHeaderComponent/>
        )
    }

    return (
        <BookingFlowPage title={services.language.translate('Departure Flight')}
                               renderContent={() => (<DepartingFlightSelectionPageContent allowBundleSelection={true}/>)}
                               renderExtraHeaderContent={renderExtraHeader}
                               showPromotionCode={false}/>
    );
});
