import React, { useState } from "react";
import styled from "styled-components";
import { ArrowDownIcon } from "../../assets/svg/arrow-down.icon";
import { ArrowUpIcon } from "../../assets/svg/arrow-up.icon";
import { CardBox } from "../../basic-components/card/card.box";
import {observer} from "mobx-react";
import {Check} from "../../types/type-checking";
import {scrollElementIntoViewLazySmooth} from "../../utils/scroll-element-into-view";

export const AccordionBox = styled(CardBox)`
`

export const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
`

export const AccordionHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  cursor: pointer;
  
`

const AccordionHeaderContentBox = styled.div`
    flex-grow: 1;
`

export const AccordionContentBox = styled.div`
    padding-top: ${props => props.theme.spacing.medium};
`

const ArrowBox = styled.div`
    flex-shrink: 0;
`

export interface IAccordionController {
    expand: () => void;
    collapse: () => void;
}

interface AccordionComponentProps {
    isExpanded?: boolean;
    renderHeader: () => React.ReactElement | string;
    renderContent: () => React.ReactElement;
    renderArrowUp?: () => React.ReactElement;
    renderArrowDown?: () => React.ReactElement;
    expandOnArrowClick?: boolean;
    className?: string;
    headerClassName?: string;
    provideController?: (controller: IAccordionController) => void;
    onChange?: (isExpanded: boolean) => void;
    scrollIntoViewOnExpand?: boolean;

}


export const AccordionComponent: React.FC<AccordionComponentProps> = observer((props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(props.isExpanded || false);
    const rootElementRef = React.useRef<any>(null);

    let expandOnArrowClick: boolean = Check.isNullOrUndefined(props.expandOnArrowClick) ? true : props.expandOnArrowClick;
    const updateExpandedState = (value: boolean) => {
        if(value === isExpanded) {
            return;
        }
        setIsExpanded(value);
        props.onChange && props.onChange(value);
        if(value && props.scrollIntoViewOnExpand) {
            scrollElementIntoViewLazySmooth(rootElementRef.current);
        }
    }

    props.provideController && props.provideController({
        expand: () => updateExpandedState(true),
        collapse: () => updateExpandedState(false),
    });

    const renderCardContent = () => {

        if(!isExpanded){
            return null;
        }

        return(
            <AccordionContentBox>
                {props.renderContent()}
            </AccordionContentBox>
        )
    }

    const renderArrow = () => {
        if(!isExpanded){
            if(props.renderArrowDown){
                return props.renderArrowDown();
            }else{
                return <ArrowDownIcon size={1}/>
            }

        }
        if(props.renderArrowUp){
            return props.renderArrowUp();
        }else{
            return  <ArrowUpIcon size={1} />
        }
    }

    const onHeaderClick = () => {
        updateExpandedState(!isExpanded);
    }

    return (
        <AccordionBox className={props.className}
                      ref={rootElementRef}>
            <AccordionHeaderBox className={props.headerClassName}>
                <AccordionHeaderContentBox onClick={onHeaderClick}>
                    {props.renderHeader()}
                </AccordionHeaderContentBox>
                <ArrowBox onClick={() => expandOnArrowClick && onHeaderClick()}>
                    {renderArrow()}
                </ArrowBox>
            </AccordionHeaderBox>
            {renderCardContent()}
        </AccordionBox>
    );
});
