import React from "react";
import {observer} from "mobx-react";
import {StandardPageHeaderSmallScreenComponent} from "./standard-page-header.small-screen.component";
import {StandardPageHeaderComponentProps} from "./standard-page-header.component.props";
import {ScreenSizeBasedComponent} from "../../layout/screen-size-based.component";
import {StandardPageHeaderLargeScreenComponent} from "./standard-page-header.large-screen.component";


export const StandardPageHeaderComponent: React.FC<StandardPageHeaderComponentProps> = observer((props) => {
    return (
        <ScreenSizeBasedComponent renderForSmallScreen={() => (<StandardPageHeaderSmallScreenComponent {...props}/>)}
                                  renderForLargeScreen={() => (<StandardPageHeaderLargeScreenComponent {...props}/>)}/>
    );
});
