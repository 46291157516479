import React from "react";
import {IFlightDesignatorViewModel} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {observer} from "mobx-react";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../components/designator/designator.component";
import styled from "styled-components";

const JourneySsrBucketDesignatorBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.spacing.medium} 0;
`


export const JourneySsrBucketDesignatorComponent: React.FC<{designator: IFlightDesignatorViewModel; className?: string}> = observer((props) => {
    return (
        <JourneySsrBucketDesignatorBox className={props.className}>
            <DesignatorComponent displayMode={DesignatorDisplayMode.stationsNamesAndDateAndFlightStops} className={"the-designator"}
                                 designator={props.designator}/>
        </JourneySsrBucketDesignatorBox>
    );
})
