import React, {CSSProperties} from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {AirlineLogoMulticolorIcon} from "../../../../assets/svg/airline-logo-multicolor.icon";


export const BoardingPassCarrierLogoComponent: React.FC = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: services.theme.currentTheme.spacing.extraLarge
    };

    return (
        <div style={styles}>
            <AirlineLogoMulticolorIcon/>
        </div>
    )
};
