import {IJourneyHistoryViewModel} from "./models/journey-history-view-model.interface";
import {IPassengerSegmentBoardingPassViewModel} from "../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {BookingModel} from "../booking/models/booking.model";
import {ISearchBookingByEmailParams, ISearchBookingByLastNameParams} from "../booking/booking.service.interface";

export const MAXIMUM_NUMBER_OF_FLIGHTS_TO_SHOW_ON_HOME_PAGE = 3;

export interface IBookingHistoryService {
    readonly isLoadingHistoryInProgress: boolean;
    readonly myFutureFlights: IJourneyHistoryViewModel[];
    readonly myPastFlights: IJourneyHistoryViewModel[];

    saveToMyTrips(bookingModel: BookingModel): void;

    saveBoardingPasses(boardingPasses: IPassengerSegmentBoardingPassViewModel[]): IPassengerSegmentBoardingPassViewModel[];
    getSavedBoardingPasses(tripReferenceKey: string): IPassengerSegmentBoardingPassViewModel[];
    retrieveBookingToHistoryByEmail(findByEmailRequest: ISearchBookingByEmailParams): Promise<void>;
    retrieveBookingToHistoryByLastName(findByLastNameRequest: ISearchBookingByLastNameParams): Promise<void>;
    getNextFlights(): IJourneyHistoryViewModel[];
    removeBooking(recordLocator: string): void;
}
