export const SSRS_PREFERRED_ORDER_IN_LISTS = {
    INFANT: 5,
    SEATS: 25,
    SCBG: 50,
    BCBG: 75,
    CBBG: 85,
    XSBG: 90,
    BIZB: 95,
    SBAG: 100,
    BAG: 200,
    PBRD: 300,
    CKN: 500,
    FLEX: 600,
    MEALS: 700,
    SPECIAL_EQUIPMENT: 800,
    PET_IN_CABIN:  850,
    PET_IN_HOLD:  875,
    PETS: 900,
    SPECIAL_ASSISTANCE: 1000,
    OTHER: 999999999
}
