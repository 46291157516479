import {IRoute} from "../../../navigation/models/route.interface";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {ManageMyBookingStepBase} from "../common/manage-my-booking-step.base";
import {IOnBeforeDeactivationContext} from "../../../../models/wizard/wizard.step.interface";
import {DialogResult} from "../../../dialog/dialog-enums";

export class ManageMyBookingSelectNewFlightsDatesStep extends ManageMyBookingStepBase {

    get title(): string {
        return this.services.language.translate('Dates');
    }

    get route(): IRoute {
        return this.routes.selectNewFlightsDates;
    }

    protected async _onBeforeForwardDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {

        const result =  await super._onBeforeForwardDeactivation(context);
        if(result !== DialogResult.Accepted) {
            return result;
        }

        await this.services.loadingIndicator.execute({
            action: async () => {
                await this.booking.manageMyBooking.searchForNewAvailability();
            }
        });

        return DialogResult.Accepted;
    }

    async next(): Promise<void> {

        if(ValidationResultEnum.Failure === this.services.booking.searchController.validateFlightDatesSelection()) {
            return;
        }

        if(this.booking.manageMyBooking.onlyTheReturnJourneyIsSelected) {
            this._activateStepByRoute(this.routes.selectNewReturnFlight);
        } else {
            this._activateStepByRoute(this.routes.selectNewDepartingFlight);
        }

    }

}
