import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import {
    IonCol,
    IonGrid,
    IonRow
} from "@ionic/react";
import {IFeeViewModel} from "../../services/booking/models/base-models/fees/fee-view-model.interface";
import {NullablePrice, Price} from "../../services/currency/price";
import styled, {css} from "styled-components";
import {DepartureAndArrivalTimeComponent} from "../departure-and-arrival-time/departure-and-arival-time.component";
import {FootNoteComponent} from "../foot-note/foot-note.component";
import {IJourneyShoppingCartViewModel} from "../../services/booking/models/shopping-cart/journey/journey-shopping-cart-view-model.interface";
import {ShoppingCartModeToggleComponent} from "./shopping-cart-mode-toggle.component";
import {ShoppingCartPassengerTypeFareDetailsButtonComponent} from "./price-details/passenger-type-fare/shopping-cart-passenger-type-fare-details-button.component";
import {ShoppingCartFeeDetailsButtonComponent} from "./price-details/fee-details/shopping-cart-fee-details-button.component";
import {IServiceFactory} from "../../services/service-factory.interface";
import {PriceComponent} from "../price/price.component";

const SectionTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: ${props => props.theme.fontSize.xxxxLarge};
    color: ${props => props.theme.colors.secondaryContrast};
    background: ${props => props.theme.colors.secondary};
    line-height: 1;
    font-weight: ${props => props.theme.fontWeight.bold};
    border-radius: ${props => props.theme.border.radius() - 1}px  ${props => props.theme.border.radius() - 1}px 0 0;
    margin: 0 0 ${props => props.theme.spacing.small} 0;
    padding: ${props => props.theme.spacing.medium};
    gap: ${props => props.theme.spacing.small};
`;

const DepartureAndArrivalTimeBox = styled(DepartureAndArrivalTimeComponent)`
    padding: 0 ${props => props.theme.spacing.small};
    margin-top: ${props => props.theme.spacing.medium};
`;

const SummaryGridBox = styled(IonGrid)`
    padding: 10px 0 10px 7%;
`;

const IonColBox = styled(IonCol)`
    line-height: 1;
    padding-left: 4px;
    padding-right: 0;
`;

const SummaryDescriptionColBox = styled(IonColBox)`
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.primary};
`;

const SummaryDetailsColBox = styled(IonColBox)`
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.colors.dark};
    text-align: end;
`;



const TotalGridBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: 0;
    margin: 5px 7%;
    border-top: 1px solid ${props => props.theme.colors.lightShade};
    &:first-of-type {
      border: none;
      margin-top: 0;
      margin-bottom: 0;
    }
`;

const TotalDescriptionColBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    color: ${props => props.theme.colors.primary};
    flex-grow: 1;
`;

const TotalDetailsColBox = styled.div`
    font-size: ${props => props.theme.fontSize.xLarge};
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

function getSummaryDescriptionColumnSize(services: IServiceFactory): string {
    if(services.booking.current.shoppingCart.shouldShowPriceDifferences) {
        return "5"
    } else {
        return "6";
    }
}

interface GrandTotalComponentProps {
    description: string;
    total: Price;
}
const GrandTotalComponent: React.FC<GrandTotalComponentProps> = observer((props) => {
    return (
        <TotalGridBox>
            <TotalDescriptionColBox>{props.description}</TotalDescriptionColBox>
            <TotalDetailsColBox>
                <PriceComponent price={props.total}/>
            </TotalDetailsColBox>
        </TotalGridBox>
    )
});

const FeeRowComponent: React.FC<{fee: IFeeViewModel}> = observer((props) => {
    const services = useServices();
    if(!props.fee.shouldBeDisplayedInShoppingCart) {
        return null;
    }

    const renderFeeDetailsButton = () => {
        if(!services.booking.current.shoppingCart.shouldShowPriceDifferences) {
            return null;
        }

        return (
            <SummaryDetailsColBox size="1">
                <ShoppingCartFeeDetailsButtonComponent description={props.fee.description}
                                                       initialPrice={props.fee.initialPrice}
                                                       currentPrice={props.fee.currentPrice}/>
            </SummaryDetailsColBox>
        );
    }

    return (
        <IonRow>
            <SummaryDescriptionColBox size={getSummaryDescriptionColumnSize(services)}>
                <div>{props.fee.description}</div>
                <FootNoteComponent>{props.fee.details}</FootNoteComponent>
            </SummaryDescriptionColBox>

            <SummaryDetailsColBox size="3">
                {`${props.fee.quantity} x ${props.fee.priceToDisplay.toString()}`}
            </SummaryDetailsColBox>

            {renderFeeDetailsButton()}

            <SummaryDetailsColBox size="2">
                {props.fee.totalToDisplay.toString()}
            </SummaryDetailsColBox>

        </IonRow>
    )
})


const FeesCollectionComponent: React.FC<{fees: IFeeViewModel[]}> = observer((props) => {
    return (
        <>
            {props.fees.map(fee => <FeeRowComponent key={fee.feeGroupKey} fee={fee}/>)}
        </>
    )
});

interface PassengerTypeFareComponentProps {
    description: string;
    quantity: number;
    initialBundlePrice?: NullablePrice;
    currentBundlePrice?: Price;
    initialFare: NullablePrice;
    currentFare: Price;
    fareToDisplay: Price;
    totalToDisplay: Price;
}


const PassengerTypeFareComponent: React.FC<PassengerTypeFareComponentProps> = observer((props) => {
    const services = useServices();

    const renderFareDetailsButton = () => {
        if(!services.booking.current.shoppingCart.shouldShowPriceDifferences) {
            return null;
        }
        return (
            <SummaryDetailsColBox size="1">
                <ShoppingCartPassengerTypeFareDetailsButtonComponent description={props.description}
                                                                     initialBundlePrice={props.initialBundlePrice}
                                                                     currentBundlePrice={props.currentBundlePrice}
                                                                     initialFare={props.initialFare}
                                                                     currentFare={props.currentFare}/>
            </SummaryDetailsColBox>
        )
    }
    return (
        <IonRow>
            <SummaryDescriptionColBox size={getSummaryDescriptionColumnSize(services)}>
                {props.description}
            </SummaryDescriptionColBox>
            <SummaryDetailsColBox size="3">
                {`${props.quantity} x ${props.fareToDisplay.toString()}`}
            </SummaryDetailsColBox>
            {renderFareDetailsButton()}
            <SummaryDetailsColBox size="2">
                {props.totalToDisplay.toString()}
            </SummaryDetailsColBox>
        </IonRow>

    )
});

const JourneyInfantShoppingCartComponent: React.FC<{journeyShoppingCart: IJourneyShoppingCartViewModel}> = observer((props) => {
    const infantShoppingCart = props.journeyShoppingCart.journeyInfantsShoppingCartModel;
    if(!infantShoppingCart) {
        return null;
    }
    return (
        <PassengerTypeFareComponent description={infantShoppingCart.description}
                                    quantity={infantShoppingCart.fareQuantity}
                                    initialFare={infantShoppingCart.initialFare}
                                    currentFare={infantShoppingCart.currentFare}
                                    fareToDisplay={infantShoppingCart.fareToDisplay}
                                    totalToDisplay={infantShoppingCart.totalFareToDisplay}/>
    );
});

const JourneyFaresComponent: React.FC<{journeyShoppingCart: IJourneyShoppingCartViewModel}> = observer((props) => {
    const services = useServices();

    const passengerTypesShoppingCarts = props.journeyShoppingCart.passengerTypesShoppingCarts.filter(sc => sc.fareQuantity > 0);

    if(passengerTypesShoppingCarts.length === 0) {
        return null;
    }

    return (
        <div>
            <DepartureAndArrivalTimeBox designator={props.journeyShoppingCart.designator}
                                        isStationCodeFirst={true}
                                        displayDate={true}
                                        displayNumberOfStops={true}/>

            <SummaryGridBox>
                {
                    passengerTypesShoppingCarts.map(sc => <PassengerTypeFareComponent key={sc.uniqueKey}
                                                                                      description={sc.description}
                                                                                      quantity={sc.fareQuantity}
                                                                                      initialBundlePrice={sc.initialBundlePrice}
                                                                                      currentBundlePrice={sc.currentBundlePrice}
                                                                                      initialFare={sc.initialFare}
                                                                                      currentFare={sc.currentFare}
                                                                                      fareToDisplay={sc.fareToDisplay}
                                                                                      totalToDisplay={sc.totalFareToDisplay}/>)
                }
                <JourneyInfantShoppingCartComponent journeyShoppingCart={props.journeyShoppingCart}/>
            </SummaryGridBox>
            <GrandTotalComponent description={services.language.translate('Flight total')} total={props.journeyShoppingCart.faresTotalToDisplay}/>

        </div>
    )
});

const JourneyExtrasComponent: React.FC<{journeyShoppingCart: IJourneyShoppingCartViewModel}> = observer((props) => {
    const services = useServices();
    const shoppingCart = props.journeyShoppingCart;
    const allFees = [
        ...shoppingCart.seatsFees,
        ...shoppingCart.ssrsFees,
        ...shoppingCart.otherFees
    ]

    return (
        <div>
            <DepartureAndArrivalTimeBox designator={props.journeyShoppingCart.designator}
                                        isStationCodeFirst={true}
                                        displayDate={true}
                                        displayNumberOfStops={true}/>

            <SummaryGridBox>
                <FeesCollectionComponent fees={allFees}/>
            </SummaryGridBox>

            <GrandTotalComponent description={services.language.translate('Extras total')} total={shoppingCart.extrasTotalToDisplay}/>

        </div>
    )
})



const BookingLevelFeesComponent: React.FC<{bookingLevelFees: IFeeViewModel[]}> = observer((props) => {
    const services = useServices();
    const feesToDisplay = props.bookingLevelFees.filter(f => f.shouldBeDisplayedInShoppingCart);
    if(feesToDisplay.length === 0) {
        return null;
    }

    const total = Price.sumAll(feesToDisplay.map(f => f.totalToDisplay), services.booking.current.createPrice(0));

    return (
        <ShoppingCartSectionComponent title={services.language.translate('Other services')} total={total}>
            <FeesCollectionComponent fees={feesToDisplay}/>
        </ShoppingCartSectionComponent>
    )
});

const ShoppingCartSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.theme.border.mainColor};
    width: 100%;
    border-radius: ${props => props.theme.border.defaultRadius};
    margin-bottom: ${props => props.theme.spacing.large};
`

const ShoppingCartSectionTitleBox = styled.div`
    flex-grow: 1;
`

interface ShoppingCartSectionComponentProps extends PropsWithChildren {
    title: string;
    total?: Price;
}

const ShoppingCartSectionComponent: React.FC<ShoppingCartSectionComponentProps> = observer((props) => {
    const services = useServices();
    const renderTotal = () => {
        if(props.total) {
            return (<PriceComponent price={props.total} decimalPartFontSize={services.theme.currentTheme.fontSize.large}/>)
        }
        return null;
    }
    return (
        <ShoppingCartSectionBox>
            <SectionTitleBox>
                <ShoppingCartSectionTitleBox>
                    {props.title}
                </ShoppingCartSectionTitleBox>
                {renderTotal()}
            </SectionTitleBox>
            {props.children}
        </ShoppingCartSectionBox>
    );
});


const ShoppingCartContainerBox = styled.div<{hideBorders?: boolean}>`
    ${
        props => props.hideBorders 
                ? css`
                    ${ShoppingCartSectionBox} {
                      border: none;
                    }
                `
                : ''
    }
`

interface ShoppingCartComponentProps {
    hideBorders?: boolean;
}

export const ShoppingCartComponent: React.FC<ShoppingCartComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const journeysShoppingCarts = booking.shoppingCart.journeysShoppingCartsToDisplay;

    const renderFlightsFares = () => {
        const journeysWithFareChanges = journeysShoppingCarts.filter(j => j.shouldDisplayFares);
        if(journeysWithFareChanges.length > 0) {
            const total = Price.sumAll(journeysWithFareChanges.map(j => j.faresTotalToDisplay), booking.createPrice(0));
            return (
                <ShoppingCartSectionComponent title={services.language.translate('Flights')} total={total}>
                    {journeysWithFareChanges.map(j => <JourneyFaresComponent key={`Flights_${j.designator.uniqueKey}`} journeyShoppingCart={j}/>)}
                </ShoppingCartSectionComponent>
            )
        }

        return null;

    }


    const renderExtras = () => {
        const journeysWithExtrasChanges = journeysShoppingCarts.filter(j => j.shouldDisplayExtras);
        if(journeysWithExtrasChanges.length > 0) {
            const total = Price.sumAll(journeysWithExtrasChanges.map(j => j.extrasTotalToDisplay), booking.createPrice(0));
            return (
                <ShoppingCartSectionComponent title={services.language.translate('Extras')} total={total}>
                    {journeysWithExtrasChanges.map(jsc => <JourneyExtrasComponent key={`Extras_${jsc.designator.uniqueKey}`} journeyShoppingCart={jsc}/>)}
                </ShoppingCartSectionComponent>
            );
        }

        return null;
    }

    return (
        <ShoppingCartContainerBox hideBorders={props.hideBorders}>
            <ShoppingCartModeToggleComponent shoppingCart={booking.shoppingCart}/>
            {renderFlightsFares()}
            {renderExtras()}

            <BookingLevelFeesComponent bookingLevelFees={booking.shoppingCart.bookingLevelFeesToDisplay}/>

            <ShoppingCartSectionComponent title={services.language.translate('Final Price')}>
                <GrandTotalComponent description={services.language.translate('Journey total')}
                                     total={booking.shoppingCart.computedTotalToDisplay}/>
                <GrandTotalComponent description={services.language.translate('Balance due')}
                                     total={booking.shoppingCart.balanceDue}/>
            </ShoppingCartSectionComponent>
        </ShoppingCartContainerBox>
    );
});
