import React from 'react';
import {observer} from "mobx-react-lite";
import {IFlightDesignatorViewModel} from "../../../services/booking/models/designator/flight-designator-view-model.interface";
import styled from "styled-components";
import {DesignatorStationNameComponent} from "../common/components/designator-station-name.component";
import {DesignatorPlaneIconComponent} from "../common/components/designator-plane-icon.component";
import {CarriersComponent} from "../carriers.component";
import {IonIcon} from "@ionic/react";
import {radioButtonOn} from "ionicons/icons";
import {DesignatorBox} from "../common/boxes/designator.box";


const DesignatorStationsNamesDateAndStopsBox = styled(DesignatorBox)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StationsNamesBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const DetailsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.normal};
`

const DepartureDateBox = styled.div`
    margin-right: ${props => props.theme.spacing.small};
`

const FlightTimeBox = styled.div`
    
`

const TimeSeparatorBox = styled.span`
    padding: 0 ${props => props.theme.spacing.small};
`

export const NumberOfStopsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const NumberOfStopsBulletBox = styled(IonIcon)`
    margin: 0 ${props => props.theme.spacing.small};
`

const NumberOfStopsComponent: React.FC<{numberOfStops: string}> = observer((props) => {
    return (
        <NumberOfStopsBox>
            <NumberOfStopsBulletBox ios={radioButtonOn} md={radioButtonOn}/>
            {props.numberOfStops}
        </NumberOfStopsBox>
    )
});


interface DesignatorStationsNamesDateAndStopsComponentProps {
    designator: IFlightDesignatorViewModel;
    useMac?: boolean;
    className?: string;
}

export const DesignatorStationsNamesDateAndStopsComponent: React.FC<DesignatorStationsNamesDateAndStopsComponentProps> = observer((props) => {
    const designator = props.designator;
    return (
        <DesignatorStationsNamesDateAndStopsBox className={props.className}>
            <StationsNamesBox>
                <DesignatorStationNameComponent station={designator.origin} useMac={props.useMac}/>

                <DesignatorPlaneIconComponent/>

                <DesignatorStationNameComponent station={designator.destination} useMac={props.useMac}/>

            </StationsNamesBox>
            <DetailsBox>
                <DepartureDateBox>
                    {designator.formatDepartureDate()}
                </DepartureDateBox>
                <FlightTimeBox>
                    {designator.formatDepartureTime()}
                    <TimeSeparatorBox>-</TimeSeparatorBox>
                    {designator.formatArrivalTime()}
                </FlightTimeBox>
                <NumberOfStopsComponent numberOfStops={designator.formatNumberOfStops()}/>
            </DetailsBox>
            <CarriersComponent carriers={props.designator.operatingCarriers}/>
        </DesignatorStationsNamesDateAndStopsBox>

    );
});
