import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {EXTERNAL_LINK_CSS_CLASS_NAME} from "../../services/external-links/external-links.service.interface";

const ExternalLinkBox = styled.div`
    > a.${EXTERNAL_LINK_CSS_CLASS_NAME} {
      color: ${props => props.theme.colors.primary};
      font-weight: ${props => props.theme.fontWeight.bold};
      width: 100%;
      height: 100%;
    }
`

interface ExternalLinkComponentProps {
    innerHtml: string;
    className?: string;
}
export const ExternalLinkComponent: React.FC<ExternalLinkComponentProps> = observer((props) => {
    return (
        <ExternalLinkBox className={props.className} dangerouslySetInnerHTML={{
            __html: props.innerHtml
        }}/>
    )
});
