import {IDotRezBookingSession} from "../../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {
    IDotRezBookingData,
    IDotRezPassengerFee
} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {FeeCodeEnum, FeeTypeEnum} from "../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {sumOfNonTaxesServiceCharges} from "../base-models/fees/service-charges.helpers";
import {BookingModel} from "../booking.model";
import {IDotRezPartialBookingSessionData} from "../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {JourneyModel} from "../journey/journey.model";
import {IServiceFactory} from "../../../service-factory.interface";

export class FlexConsumptionHandler {
    constructor(private readonly booking: BookingModel) {

    }

    private get session(): IDotRezBookingSession {
        return this.booking.session;
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }


    private _getFlexSsrsKeysToRemove(): string[] {
        let flexSsrKeys: string[] = [];

        this._journeysThatShouldConsumeFlex.forEach(j => {
            flexSsrKeys = [
                ...flexSsrKeys,
                ...j.getFlexSsrKeysToRemove()
            ];
        });


        return flexSsrKeys;
    }

    private _getTotalFlexFeeAmountToConsume(): number {
        return this._journeysThatShouldConsumeFlex.sum(j => j.getFlexFeeAmountToConsume());
    }

    private async _getBookingData(): Promise<IDotRezBookingData> {
        const {bookingData} = await this.booking.session.bookingStateQueryBuilder().useBookingData().getBookingState();

        if(bookingData) {
            return bookingData;
        }

        throw new Error('FlexConsumptionHandler: Received undefined booking data. It should never reach here!!!');
    }

    private _getFlexAdjustmentFeeNote(): string {
        return this.booking.bookingTimestamp.toString();
    }

    private _findFlexAdjustmentFee(bookingData: IDotRezBookingData): IDotRezPassengerFee | null {
        return bookingData.passengers[0].value.fees.find(f =>
                f.type === FeeTypeEnum.ServiceFee
                && f.code === FeeCodeEnum.FLX
                && f.note === this._getFlexAdjustmentFeeNote()) || null;
    }

    private async _addFlexAdjustmentFee(totalFlexFeeAmount: number): Promise<void> {
        let feeKey = await this.services.airlineWebapi.createBookingFee({
            dotRezToken: this.session.token,
            passengerKey: this.booking.passengers[0].passengerKey,
            feeCode: FeeCodeEnum.FLX,
            note: this._getFlexAdjustmentFeeNote()
        });

        if(!feeKey) {
            const bookingData = await this._getBookingData();
            const flexFee = this._findFlexAdjustmentFee(bookingData);
            if(flexFee) {
                feeKey = flexFee.passengerFeeKey;
            }
        }

        if(feeKey) {
            await this.services.airlineWebapi.overrideBookingFee({
                dotRezToken: this.session.token,
                feeKey: feeKey,
                amount: totalFlexFeeAmount
            });
        }
    }

    private _findSpoilageFee(bookingData: IDotRezBookingData): IDotRezPassengerFee | null {
        return bookingData.passengers[0].value.fees.find(f => f.type === FeeTypeEnum.SpoilageFee) || null;
    }

    private async _overrideSpoilageFee(flexFeeAmount: number, bookingData: IDotRezBookingData): Promise<void> {
        const spoilageFee = this._findSpoilageFee(bookingData);
        if(!spoilageFee) {
            return;
        }

        const spoilageAmount = sumOfNonTaxesServiceCharges(spoilageFee.serviceCharges);

        const newSpoilageAmount = spoilageAmount - flexFeeAmount;

        if(newSpoilageAmount <= 0) {
            await this.services.airlineWebapi.deleteBookingFee({
                feeKey: spoilageFee.passengerFeeKey,
                dotRezToken: this.session.token
            });
        } else {
            await this.services.airlineWebapi.overrideBookingFee({
                dotRezToken: this.session.token,
                feeKey: spoilageFee.passengerFeeKey,
                amount: newSpoilageAmount
            });
        }
    }

    private get _journeysThatShouldConsumeFlex(): JourneyModel[] {
        return this.booking.unfilteredJourneys.filter(j => j.shouldConsumeFlex);
    }

    private get _shouldConsumeFlex(): boolean {
        return this._journeysThatShouldConsumeFlex.length > 0;
    }

    async consumeFlex(bookingData: IDotRezBookingData | null | undefined): Promise<IDotRezPartialBookingSessionData> {
        if(!this._shouldConsumeFlex) {
            return {};
        }

        if(!bookingData) {
            bookingData = await this._getBookingData();
        }

        this.booking.updateBookingSessionData({
            bookingData: bookingData
        });

        const flexSsrsKeys = this._getFlexSsrsKeysToRemove();
        if(flexSsrsKeys.length > 0) {
            await this.session.removeSsrs(flexSsrsKeys);

            bookingData = await this._getBookingData();
        }

        const totalFlexFeeAmount = this._getTotalFlexFeeAmountToConsume();

        if(totalFlexFeeAmount === 0) {
            return {
                bookingData: bookingData
            };
        }

        const flexAdjustmentFee = this._findFlexAdjustmentFee(bookingData);


        if(flexAdjustmentFee) {
            if(totalFlexFeeAmount !== sumOfNonTaxesServiceCharges(flexAdjustmentFee.serviceCharges)) {
                await this.services.airlineWebapi.overrideBookingFee({
                    dotRezToken: this.session.token,
                    feeKey: flexAdjustmentFee.passengerFeeKey,
                    amount: totalFlexFeeAmount
                });
            }
        } else {
            await this._addFlexAdjustmentFee(totalFlexFeeAmount);
        }

        await this._overrideSpoilageFee(totalFlexFeeAmount, bookingData);

        bookingData = await this._getBookingData();

        return {
            bookingData: bookingData
        };
    }
}
