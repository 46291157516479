export const BUNDLES_AVAILABILITY_QUERY = `
    bookingBundleAvailability {
        bundleCode,
        pricesByJourney {
            key,
            value {
                journeyKey,
                prices {
                    passengerType,
                    totalPrice,
                    includedSsrs {
                        ssrCode
                    }
                    upgradableSsrs {
                        ssrCode,
                        upgradeFromSsrCode,
                        ssrNumber,
                        amounts {
                            currencyCode,
                            amount,
                            ssrNumber
                        }
                    }
                }
            }
        }
    }
`
