import React from "react";
import {observer} from "mobx-react";
import {IMyAccountDashboardItem} from "../my-account-dashboard-item.interface";
import styled, {css} from "styled-components";

const DashboardItemBox = styled.div<{isEmpty: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${
        props => props.isEmpty 
                ? '' 
                : css`
                    background-color: ${props => props.theme.colors.lightTint};
                    color: ${props => props.theme.colors.medium};
                `
    }
    
    line-height: 1;
    font-size: ${props => props.theme.fontSize.large};
    border-radius: 5px;
    height: calc(50vw - 22px);
    width: 50%;
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
    

    svg {
        width: 25px;
        height: 25px;
        margin-bottom: 10px;

        g {
            fill: ${props => props.theme.colors.secondary};
        }
    }
`

const DashboardItemTextBox = styled.div`
    text-align: center;
`

interface DashboardItemComponentProps {
    item: IMyAccountDashboardItem | null;
}
export const DashboardItemComponent: React.FC<DashboardItemComponentProps> = observer((props) => {
    const onClickHandler = async () => {
        await props.item?.execute();
    }
    return (
        <DashboardItemBox onClick={onClickHandler} isEmpty={!Boolean(props.item)}>
            {props.item?.renderIcon()}
            <DashboardItemTextBox>
                {props.item?.text}
            </DashboardItemTextBox>
        </DashboardItemBox>
    );
});
