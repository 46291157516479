import {IBookingHistoryStrategy} from "../booking-history-strategy.interface";
import {IJourneyHistoryViewModel} from "../../models/journey-history-view-model.interface";
import {IPassengerSegmentBoardingPassViewModel} from "../../../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {BookingModel} from "../../../booking/models/booking.model";
import {ISearchBookingByEmailParams, ISearchBookingByLastNameParams} from "../../../booking/booking.service.interface";

//const DEFAULT_ERROR_MESSAGE = 'Booking history strategy was not initialized';

export class VoidBookingHistoryStrategy implements IBookingHistoryStrategy {
    readonly isLoadingHistoryInProgress: boolean = false;
    readonly myFutureFlights: IJourneyHistoryViewModel[] = [];
    readonly myPastFlights: IJourneyHistoryViewModel[] = [];

    dispose(): void {
    }

    getSavedBoardingPasses(tripReferenceKey: string): IPassengerSegmentBoardingPassViewModel[] {
        return [];
    }

    async retrieveBookingToHistoryByEmail(findByEmailRequest: ISearchBookingByEmailParams): Promise<void> {
    }

    async retrieveBookingToHistoryByLastName(findByLastNameRequest: ISearchBookingByLastNameParams): Promise<void> {
    }

    saveBoardingPasses(boardingPasses: IPassengerSegmentBoardingPassViewModel[]): IPassengerSegmentBoardingPassViewModel[] {
        return []
    }

    saveToMyTrips(bookingModel: BookingModel): void {
    }

    getNextFlights(): IJourneyHistoryViewModel[] {
        return [];
    }

    removeBooking(recordLocator: string): void {

    }


}
