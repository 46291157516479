import {Check} from "../../../types/type-checking";
import {IDotRezGraphQLQuery} from "./dot-rez-graph-ql-query.interface";
import {IGraphQLVariable} from "./graph-ql-variable.interface";

const MAX_ALLOWED_GRAPHQL_OPERATIONS = 12;

abstract class DotRezGraphQLOperationBuilder {

    private _queryNameParts: string[] = [];
    addQueryNamePart(part: string): void {
        this._queryNameParts.push(part);
    }

    protected _buildQueryName(): string {
        return this._queryNameParts.join('_') || 'unknown';
    }

    protected abstract _buildBody(operations: GraphQLOperation[]): string;

    private readonly _operations: GraphQLOperation[] = [];

    private _variables: Record<string, any> = {};

    protected _addOperation(operation: GraphQLOperation) {
        operation.variables.forEach(variable => {
            if(this._variables[variable.name]) {
                throw new Error(`Variable ${variable.name} already exists`);
            }
        })
        this._operations.push(operation);
    }

    private _buildVariables(operations: GraphQLOperation[]): Record<string, any> {
        const variables: Record<string, any> = {};
        operations.forEach(op => {
            op.variables.forEach(v => {
                variables[v.name] = v.value;
            })
        });

        return variables;
    }

    build(): IDotRezGraphQLQuery[] {
        const result: IDotRezGraphQLQuery[] = [];
        const chunks = this._operations.splitToChunks(MAX_ALLOWED_GRAPHQL_OPERATIONS);
        for(let i = 0; i < chunks.length; i++) {
            let operations = chunks[i];

            const operationDefinition: IDotRezGraphQLQuery = {
                queryName: this._buildQueryName() + (chunks.length > 1 ? (i + 1).toString() : ''),
                request: {
                    query: this._buildBody(operations)
                }
            };

            const variables = this._buildVariables(operations);
            if(!Check.isEmpty(variables)) {
                operationDefinition.request.variables = variables;
            }
            result.push(operationDefinition);
        }

        return result;
    }
}

export class DotRezGraphQLMutationBuilder extends DotRezGraphQLOperationBuilder {
    addMutation(mutationDefinition: string, ...variables: IGraphQLVariable[]): this {
        this._addOperation(new GraphQLOperation("", mutationDefinition, variables));
        return this;
    }

    protected _buildBody(operations: GraphQLOperation[]): string {
        return operations.map(op => op.queryDefinition).join('\n');
    }
}

export class DotRezGraphQLQueryBuilder extends DotRezGraphQLOperationBuilder {

    protected _buildBody(operations: GraphQLOperation[]): string {
        let queries = '';
        if(operations.length > 0) {
            queries = `query ${this._buildQueryName()} {
                ${operations.map(op=> `${op.alias}: ${op.queryDefinition}`).join('\n')}
            }
            `;
        }
        return  queries;
    }

    addQuery(alias: string, queryDefinition: string, ...variables: IGraphQLVariable[]): this {
        this.addQueryNamePart(alias);
        this._addOperation(new GraphQLOperation(alias, queryDefinition, variables));
        return this;
    }
}

class GraphQLOperation {
    constructor(public readonly alias: string,
                public readonly queryDefinition: string,
                public readonly variables: IGraphQLVariable[] = []) {
    }


}

