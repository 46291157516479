import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';


export const SwapIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={3.6} {...props} viewBox="0 0 36 36" ratio={36/36}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(0 0)"><path d="M-359.25-1013a17.882,17.882,0,0,1-12.728-5.272A17.882,17.882,0,0,1-377.25-1031a17.883,17.883,0,0,1,5.272-12.728A17.882,17.882,0,0,1-359.25-1049a17.882,17.882,0,0,1,12.728,5.272A17.883,17.883,0,0,1-341.25-1031a17.882,17.882,0,0,1-5.272,12.728A17.882,17.882,0,0,1-359.25-1013Zm0-35a17.019,17.019,0,0,0-17,17,17.019,17.019,0,0,0,17,17,17.019,17.019,0,0,0,17-17A17.019,17.019,0,0,0-359.25-1048Z" transform="translate(377.25 1049)"/><path d="M3.259,6.915a.563.563,0,0,0,.8,0l.727-.727a.563.563,0,0,0,0-.8l-.727-.727H14.063A1.689,1.689,0,0,1,15.75,6.352a.845.845,0,0,0,.844.844h.562A.845.845,0,0,0,18,6.352a3.942,3.942,0,0,0-3.937-3.938H4.054l.727-.727a.563.563,0,0,0,0-.8L4.054.165a.563.563,0,0,0-.8,0L.082,3.341a.282.282,0,0,0,0,.4Z" transform="translate(27 26.321) rotate(180)"/><path d="M14.741.281a.563.563,0,0,0-.8,0l-.727.727a.562.562,0,0,0,0,.8l.727.727H3.938A1.689,1.689,0,0,1,2.25.844.845.845,0,0,0,1.406,0H.844A.845.845,0,0,0,0,.844,3.942,3.942,0,0,0,3.938,4.781H13.946l-.727.727a.562.562,0,0,0,0,.8l.727.727a.563.563,0,0,0,.8,0l3.176-3.176a.282.282,0,0,0,0-.4Z" transform="translate(27 16.875) rotate(180)"/></g>
        </SvgIcon>
    )
}
