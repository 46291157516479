import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import { ExclamationTriangle } from "../../../assets/svg/exclamation-triangle.icon";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../components/modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../../../components/continue-button/continue-button.component";
import {CallCenterPhoneComponent} from "../../../components/call-center-phone/call-center-phone.component";
import {WebContactFormComponent} from "../../../components/web-contact-form/web-contact-form.component";
import {IPspError} from "../../../services/airline-webapi/responses/psp-error";
import {PspErrorDetailsComponent} from "./psp-error-details.component";

const PaymentFailedFatalPageContent = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${props => props.theme.spacing.large};
`

const ErrorMessageTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    text-align: center;
`;

const ContactUsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.xLarge};
    line-height: 1;
    gap: ${props => props.theme.spacing.large};
    
`


export const PaymentFailedFatalPage: React.FC<{dialogHandler: IDialogConfirmationHandler; pspErrorDetails: IPspError[] | null}> = observer((props) => {
    const services = useServices();

    const onContinueClick = async () => {
        props.dialogHandler.accept();
        await services.navigator.goHome();
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Payment failed!')} dialogHandler={props.dialogHandler}/>

            <StandardDialogContentComponent>
                <PaymentFailedFatalPageContent>
                    <ExclamationTriangle size={5} color={services.theme.currentTheme.colors.danger}/>
                    <ErrorMessageTitleBox>
                        {services.language.translate('A critical error has occurred while processing your payment.')}
                    </ErrorMessageTitleBox>
                    <ContactUsBox>
                        <div>{services.language.translate('Please contact us')}</div>
                        <CallCenterPhoneComponent/>
                        <WebContactFormComponent/>
                    </ContactUsBox>
                    <PspErrorDetailsComponent pspErrorDetails={props.pspErrorDetails}/>
                </PaymentFailedFatalPageContent>
            </StandardDialogContentComponent>

            <StandardDialogFooterComponent dialogHandler={props.dialogHandler} horizontallyCentered={true}>
                <ContinueButtonComponent onClick={onContinueClick} fullWidth={true} />
            </StandardDialogFooterComponent>

        </StandardDialogPageComponent>
    )
});
