import {ServiceBase} from "../service-base";
import {IPersonTitleService} from "./person-title.service.interface";
import {IServiceFactory} from "../service-factory.interface";
import {PersonTitleModel} from "./person-title.model";
import {NullableGender} from "../dot-rez-api/data-contracts/enums/gender.enum";
import {NullableString} from "../../types/nullable-types";
import {Check} from "../../types/type-checking";

export class PersonTitleService extends ServiceBase implements IPersonTitleService {
    constructor(services: IServiceFactory) {
        super(services);
        this._allPersonTitles = [
            new PersonTitleModel('MR', () => services.language.translate('Mr')),
            new PersonTitleModel('MS', () => services.language.translate('Ms')),
        ];
    }

    private readonly _allPersonTitles: PersonTitleModel[];
    getAllTitles(): PersonTitleModel[] {
        return this._allPersonTitles;
    }

    personTitleFromGender(gender: NullableGender): NullableString {
        if(Check.isNullOrUndefined(gender)) {
            return null;
        }

        switch (gender) {
            case 1:
            case "Male":
                return 'MR';
            case 2:
            case "Female":
                return 'MS';
            default:
                throw new Error(`Cannot infer person title from gender ${gender}`);
        }
    }
}
