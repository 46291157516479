import {IDotRezPassengerFee} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {BookingModel} from "../../booking.model";
import {Price} from "../../../../currency/price";
import {MaturePassengerModel} from "../../passenger/mature-passenger.model";
import {PassengerFeeBaseModel} from "../../base-models/fees/passenger/passenger-fee-base.model";

export class PassengerFeeModel extends PassengerFeeBaseModel {
    constructor(feeData: IDotRezPassengerFee,
                private readonly passenger: MaturePassengerModel) {
        super(feeData, passenger.booking.services, passenger)
    }

    private get booking(): BookingModel {
        return this.passenger.booking;
    }


    createPrice(amount: number): Price {
        return this.booking.createPrice(amount);
    }



}
