import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {IUserProfileEditorViewModel} from "../../../../services/user/models/profile/user-profile-editor-view-model.interface";
import {
    FieldViewComponent,
} from "../../../../components/forms-view/field-view.component";
import {GenderFieldViewComponent} from "../../../../components/forms-view/gender-field/gender-field-view.component";
import {StringFieldViewComponent} from "../../../../components/forms-view/string-field/string-field-view.component";
import {DateFieldViewComponent} from "../../../../components/forms-view/date-field/date-field-view.component";
import {FieldsSectionComponent} from "../../../../components/forms-view/fields-section.component";
import {FormViewComponent} from "../../../../components/forms-view/form-view.component";
import {CountryFieldViewComponent} from "../../../../components/forms-view/country-field/country-field-view.component";


interface UserProfileViewComponentProps {
    userProfileEditor: IUserProfileEditorViewModel;
}

export const UserProfileViewComponent: React.FC<UserProfileViewComponentProps> = observer((props) => {
    const services = useServices();
    const fields = props.userProfileEditor.fields;
    const language = services.language;
    return (
        <FormViewComponent title={props.userProfileEditor.getFullName()}>
            <FieldsSectionComponent title={language.translate(`Account details`)}>
                <FieldViewComponent label={language.translate('E-mail')}
                                    value={props.userProfileEditor.emailAddress}/>
                <FieldViewComponent label={language.translate('Customer number')}
                                    value={props.userProfileEditor.customerNumber}/>
            </FieldsSectionComponent>

            <FieldsSectionComponent title={language.translate(`Personal details`)}>
                <DateFieldViewComponent field={fields.dateOfBirth}/>
                <GenderFieldViewComponent field={fields.gender}/>
                <CountryFieldViewComponent field={fields.nationality}/>
            </FieldsSectionComponent>

            <FieldsSectionComponent title={language.translate(`Contact details`)}>
                <StringFieldViewComponent field={fields.phoneNumber}/>
            </FieldsSectionComponent>
        </FormViewComponent>
    )
});
