import {BlueBenefitsSubscriptionStatus, IBlueBenefitsSubscription} from "./blue-benefits-subscription.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {Price} from "../../../currency/price";
import {IBlueBenefitDiscountInfo} from "../packs/blue-benefits-package.interface";

export class NoBlueBenefitsSubscriptionModel implements IBlueBenefitsSubscription {
    constructor(private readonly services: IServiceFactory) {
    }

    get packageName(): string {
        return "";
    }

    get isValidSubscription(): boolean {
        return false;
    }

    get isExpired(): boolean {
        return false;
    }

    get expirationDate(): Date {
        return new Date(1970, 1, 1);
    }

    getFormattedExpirationDate(): string {
        return "";
    }

    get subscriptionStatus(): BlueBenefitsSubscriptionStatus {
        return BlueBenefitsSubscriptionStatus.noSubscription;
    }

    get maxNumberOfPassengers(): number {
        return this.services.configuration.data.maxAllowedPassengersNumberOnBooking;
    }

    get partnersCountDescription(): string {
        return "";
    }

    getBenefits(): IBlueBenefitDiscountInfo[] {
        return [];
    }
    computeSsrStandardPrice(ssrType: ISsrType, discountedPrice: Price): Price {
        return discountedPrice;
    }

    shouldLockFirstPassengerOnBooking(): boolean {
        return false;
    }

    createBookingCommentText(): string {
        throw new Error('getBookingCommentText cannot be called on NoBlueBenefitsSubscriptionModel');
    }
}
