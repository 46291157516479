import {DotRezErrorCode} from "./dot-rez-error-code";
import {DotRezError} from "./dot-rez-response";

export function isDotRezSessionExpiredError(exception: any): boolean {
    return exception instanceof DotRezSessionExpiredException;
}

export class DotRezSessionExpiredException extends Error {
    constructor() {
        super("Booking session is expired");
    }
}

export class DotRezException extends Error {
    constructor(private readonly dotRezErrors: DotRezError[]) {
        super(dotRezErrors.map(err => err.message).join('\n'));

    }

    hasErrorCode(errorCode: DotRezErrorCode): boolean {
        return Boolean(this.dotRezErrors.find(err => err.code === errorCode));
    }

    /*
    private get hasTokenError(): boolean {
        return this.dotRezErrors.some(err => err.code.startsWith('core:Token'));
    }
     */
}
