import React, {CSSProperties} from "react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {BoardingPassFieldValueComponent} from "../../value/boarding-pass-field-value.component";

export const BoardingPassBagsSectionTitleComponent: React.FC<{title: string}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        fontSize: theme.fontSize.medium,
        flexGrow: 1,
        textAlign: 'center',
        paddingBottom: theme.spacing.small
    };

    return (
        <BoardingPassFieldValueComponent styles={styles}>
            {props.title}
        </BoardingPassFieldValueComponent>
    );
}
