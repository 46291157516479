import React from "react";
import styled from "styled-components";
import { PlaneCircleFilledIcon } from "../../../../../assets/svg/plane-circle-filled.icon";
import { DesignatorDisplayMode } from "../../../../../components/designator/designator.component";
import { DesignatorsComponent } from "../../../../../components/designator/designators.component";
import { useServices } from "../../../../../hooks/use-services.hook";
import { FlightItineraryAccordionHeaderComponent } from "../flight-itinerary-accordion-header.component";
import { FlightItineraryAccordionComponent } from "../flight-itinerary-accordion.component";
import {IJourneyViewModel} from "../../../../../services/booking/models/journey/journey-view-model.interface";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";

const FlightItineraryFlightsContentBox = styled.div`
    
`

export const FlightItineraryFlightsComponent: React.FC = () => {
    const services = useServices();

    const renderAccordionHeader = () => {
        return(
            <FlightItineraryAccordionHeaderComponent renderIcon={() => <PlaneCircleFilledIcon/>}
                                                     title={services.language.translate('Flights')} />
        )
    }


    const renderDesignators = (journey: IJourneyViewModel | null) => {

        if(!journey){
            return null;
        }

        let designators = journey.segments.map(s => s.designator);
        let identifiers = journey.segments.map(s => s.identifier);

        return(
            <>
                <DesignatorsComponent designators={[journey.designator]} displayMode={DesignatorDisplayMode.journeyDesignator}/>
                <DesignatorsComponent designators={designators} identifiers={identifiers} displayMode={DesignatorDisplayMode.segmentDesignator}/>
            </>
        )
    }

    const renderDepartureJourneyDesignators = () => {
        return renderDesignators(services.booking.current.departureJourney);
    }


    const renderReturnJourneyDesignators = () => {
        const designators = renderDesignators(services.booking.current.returnJourney);

        if(designators) {
            return (
                <>
                    <VerticalSpacer size={"medium"}/>
                    {designators}
                </>
            )
        }

        return null;
    }


    const renderAccordionContent = () => {


        return(
            <FlightItineraryFlightsContentBox>
                <VerticalSpacer size={"medium"}/>
                {renderDepartureJourneyDesignators()}
                {renderReturnJourneyDesignators()}
            </FlightItineraryFlightsContentBox>

        )
    }

    return(
        <FlightItineraryAccordionComponent renderHeader={renderAccordionHeader}
                                           renderContent={renderAccordionContent}/>
    )
}
