import {
    ILowFareResult,
    IOneWayLowFareReader,
    IRoundTripLowFareReader
} from "./low-fare-reader.interface";

export class RoundTripLowFareReader implements IRoundTripLowFareReader {
    constructor(private readonly _departureLowFareReader: IOneWayLowFareReader,
                private readonly _returnLowFareReader: IOneWayLowFareReader,) {

    }

    getDepartureLowFare(date: Date): ILowFareResult {
       /* const returnLowFare = this._returnLowFareReader.getLowFare(date);

        if(returnLowFare.status === LowFareStatusEnum.Reading ) {
           return returnLowFare;
        }
       */
        return this._departureLowFareReader.getLowFare(date);
    }
    
    getReturnLowFare(date: Date): ILowFareResult {
        /*
        const departureLowFare = this._departureLowFareReader.getLowFare(date);

        if(departureLowFare.status === LowFareStatusEnum.Reading) {
            return departureLowFare;
        }
*/
        return this._returnLowFareReader.getLowFare(date);
    }

    getDepartureLowFareAsync(date: Date): Promise<ILowFareResult> {
        return this._departureLowFareReader.getLowFareAsync(date);
    }
    getReturnLowFareAsync(date: Date): Promise<ILowFareResult> {
        return this._returnLowFareReader.getLowFareAsync(date);
    }
}
