import {IServiceFactory} from "../../../service-factory.interface";
import {ISsrRawConfiguration} from "./ssrs-raw-configuration.interface";
import {SsrBundlesCutOffPeriod} from "./ssr-bundles-cut-off.period";
import {TimeSpan} from "../../../../types/time-span";
import {SsrInAllBundlesPeriod} from "./ssr-in-all-bundles-period";

export class SsrConfiguration {
    constructor(rawConfiguration: ISsrRawConfiguration, services: IServiceFactory) {
        this.bundlesCutOffPeriods = rawConfiguration.bundlesCutOffPeriods.map(p => new SsrBundlesCutOffPeriod(p.startDate, p.endDate, p.bundles, services));
        this.inAllBundlesPeriods = rawConfiguration.inAllBundlesPeriods.map(p => new SsrInAllBundlesPeriod(p.startDate, p.endDate, p.bundles, services));
    }

    private readonly bundlesCutOffPeriods: SsrBundlesCutOffPeriod[];
    private readonly inAllBundlesPeriods: SsrInAllBundlesPeriod[];

    canBePurchasedSeparately(bookingDate: Date, flightDate: Date, bundleCode: string): boolean {
        const cutOffPeriod = this.bundlesCutOffPeriods.find(p => p.isInTheCutOfPeriod(bookingDate));
        if(!cutOffPeriod) {
            return false;
        }

        return cutOffPeriod.canBePurchasedSeparately(flightDate, bundleCode);
    }

    isInTheCutOffPeriod(bookingDate: Date, bundleCode?: string): boolean {
        return this.bundlesCutOffPeriods.some(p => p.isInTheCutOfPeriod(bookingDate, bundleCode));
    }

    canBeUsed(bookingDate: Date, flightDate: Date, bundleCode: string): boolean {
        const cutOffPeriod = this.bundlesCutOffPeriods.find(b => b.isInTheCutOfPeriod(bookingDate));
        if(cutOffPeriod) {
            return cutOffPeriod.canBeUsed(flightDate, bundleCode);
        }

        const inBundlePeriod = this.inAllBundlesPeriods.find(p => p.containsDate(bookingDate));
        if(inBundlePeriod) {
            return inBundlePeriod.canBeUsed(flightDate, bundleCode);
        }

        return false;
    }

    getMinimumTimeToPurchaseBeforeFlight(bookingDate: Date, bundleCode: string): TimeSpan | null {
        const cutOffPeriod = this.bundlesCutOffPeriods.find(b => b.isInTheCutOfPeriod(bookingDate))
        if(cutOffPeriod) {
            return cutOffPeriod.getMinimumTimeToPurchaseBeforeFlight(bundleCode);
        }

        return null;
    }

    getMinimumTimeToUseBeforeFlight(bookingDate: Date, bundleCode: string): TimeSpan | null {
        const minTimeToUseBeforeFlight = this.bundlesCutOffPeriods.find(b => b.isInTheCutOfPeriod(bookingDate))?.getMinimumTimeToUseBeforeFlight(bundleCode)
        if(minTimeToUseBeforeFlight) {
            return minTimeToUseBeforeFlight;
        }

        const period = this.inAllBundlesPeriods.find(b => b.containsDate(bookingDate))
        if(period) {
            return period.getMinTimeToUseBeforeFlight(bundleCode)
        }

        return null;
    }


    getMaximumTimeToUseBeforeFlight(bookingDate: Date, bundleCode: string): TimeSpan | null {
        const maxTimeToUseBeforeFlight = this.bundlesCutOffPeriods.find(b => b.isInTheCutOfPeriod(bookingDate))?.getMaximumTimeToUseBeforeFlight(bundleCode)
        if(maxTimeToUseBeforeFlight) {
            return maxTimeToUseBeforeFlight;
        }

        const period = this.inAllBundlesPeriods.find(b => b.containsDate(bookingDate))
        if(period) {
            return period.getMaxTimeToUseBeforeFlight(bundleCode)
        }

        return null;
    }

}
