import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import {BookingFlowPageHeaderLargeScreenComponent} from "./booking-flow-page-header.large-screen.component";
import styled, {css} from "styled-components";
import {ShoppingCartComponent} from "../../components/shopping-cart/shopping-cart.component";
import {BookingFlowPageProps} from "./booking-flow-page.props";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {BookingFlowContinueButtonComponent} from "./common-components/continue-button/booking-flow-continue-button.component";
import {BookingFlowPageTitleLargeScreenBox} from "./common-components/booking-flow-page-title.large-screen.box";
import {PromoCodeComponent} from "../../components/promotionals/promo-code/promo-code.component";
import {WizardProgressIndicatorComponent} from "../../components/wizard-progress-indicator/wizard-progress-indicator.component";
import {StandardPageFooterComponent} from "../../basic-components/page/standard-page-footer/standard-page-footer.component";
import {PriceComponent} from "../../components/price/price.component";
import {SCROLLBAR_WIDTH} from "../../services/theme/global-styles";
import {IonSpinner} from "@ionic/react";
import {BookingPageContentCentralizationComponent} from "./common-components/booking-page-content-centralization.component";
import {AirlineCompanyInfoComponent} from "../../components/airline-company-info/airline-company-info.component";
import {VerticalSpacer} from "../../basic-components/spacing/vertical-spacer";
import {ShoppingCartNotificationsComponent} from "../../components/shopping-cart/shopping-cart-notifications.component";

const SHOPPING_CART_WIDTH = "400px";

const PageContentBox = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
`

const CurrentPageHeaderBox = styled.div`
`

const StandardFooterComponent = styled(StandardPageFooterComponent)<{hideTopPadding?: boolean}>`
    padding-left: 0;
    padding-right: 0;
    padding-bottom: env(safe-area-inset-bottom);
    ${props => props.hideTopPadding 
            ? css`
              padding-top: 0;
            `
            : ``
    }
`

const LeftSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
`

const RightSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: ${SHOPPING_CART_WIDTH};
    max-width: ${SHOPPING_CART_WIDTH};
    height: 100%;
    overflow: hidden;
    padding: 0 ${props => props.theme.spacing.large};
`

const ShoppingCartContentBox = styled.div`
    overflow-y: auto;
    width: 100%;
    flex-grow: 1;
    padding-right: ${props => props.theme.spacing.large};
`

const BalanceDueBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 35px;
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.small} 0 ${props => props.theme.spacing.small};
    line-height: 1;
    border-top: 2px solid ${props => props.theme.colors.primary};
    width: 100%;
    
`

const BalanceDueLabelBox = styled.div`
    flex-grow: 1;
    text-transform: uppercase;
`

const BalanceDueValueBox = styled.div`
    display: flex;
    flex-direction: column;
`

const ShoppingCartFooterBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.medium};
    width: 100%;
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.primaryContrast};
    padding-top: ${props => props.theme.spacing.small};
    padding-right: ${props => `calc(${props.theme.spacing.large} + ${SCROLLBAR_WIDTH})`};
`

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 19px;
`


export const BookingFlowLargeScreenPage: React.FC<BookingFlowPageProps> = observer((props) => {
    const services = useServices();

    const booking = services.booking.current;

    const shouldShowPromoCode = props.showPromotionCode && booking.allowPromoCode;

    const renderWizardSteps = () => {
        if(props.hideWizardProgressIndicator) {
            return null;
        }
        return (
            <WizardProgressIndicatorComponent getWizard={() => services.booking.steps}/>
        );
    }

    const renderPromotionCode = () => {
        if(!shouldShowPromoCode){
            return null;
        }

        return (
            <PromoCodeComponent promoCode={booking.promoCode}/>
        );
    }

    const renderLeftSection = () => {
        return (
            <LeftSectionBox>
                <BookingPageContentCentralizationComponent shoppingCartIsHidden={Boolean(props.hideShoppingCart)}
                                                           useSmallerMarginsWhenShoppingCartIsHidden={props.useSmallerMarginsWhenShoppingCartIsHidden}>
                    <CurrentPageHeaderBox>
                        <BookingFlowPageTitleLargeScreenBox>
                            {props.title}
                        </BookingFlowPageTitleLargeScreenBox>
                        {props.renderExtraHeaderContent && props.renderExtraHeaderContent()}
                    </CurrentPageHeaderBox>
                    {props.renderContent()}
                </BookingPageContentCentralizationComponent>

            </LeftSectionBox>
        );
    }

    const renderBalanceDueValue = () => {
        if(booking.mutationsManager.isBookingTotalUpdateInProgress) {
            return (<SpinnerContainer>
                <IonSpinner/>
            </SpinnerContainer>);
        }
        return (
            <BalanceDueValueBox>
                <ShoppingCartNotificationsComponent/>
                <PriceComponent price={booking.balanceDue}/>
            </BalanceDueValueBox>

        );
    }

    const renderRightSection = () => {
        if(props.hideShoppingCart) {
            return null;
        }
        return (
            <RightSectionBox>
                <ShoppingCartContentBox>
                    <ShoppingCartComponent/>
                </ShoppingCartContentBox>
                <ShoppingCartFooterBox>
                    <BalanceDueBox>
                        <BalanceDueLabelBox>
                            {services.language.translate('Total')}
                        </BalanceDueLabelBox>
                        {renderBalanceDueValue()}
                    </BalanceDueBox>
                    {renderPromotionCode()}
                    <BookingFlowContinueButtonComponent isPromotionAllowed={shouldShowPromoCode}/>
                </ShoppingCartFooterBox>

            </RightSectionBox>
        );
    }

    const renderFooter = () => {
        if(props.renderExtraFooterContent) {
            return (
                <StandardFooterComponent>
                    {props.renderExtraFooterContent()}
                    <VerticalSpacer/>
                    <AirlineCompanyInfoComponent/>
                </StandardFooterComponent>
            )
        }

        if(props.hideShoppingCart && !props.hideContinueButton) {
            return (
                <StandardFooterComponent>
                    <BookingPageContentCentralizationComponent shoppingCartIsHidden={Boolean(props.hideShoppingCart)}>
                        <BookingFlowContinueButtonComponent/>
                    </BookingPageContentCentralizationComponent>
                    <VerticalSpacer/>
                    <AirlineCompanyInfoComponent/>
                </StandardFooterComponent>
            )
        }

        if(services.device.isHybrid) {
            //because on some devices like iPad pro we need to preserver safe area on the bottom
            return (
                <StandardFooterComponent/>
            )
        }

        return (
            <StandardFooterComponent hideTopPadding={true}>
                <AirlineCompanyInfoComponent/>
            </StandardFooterComponent>
        )
    }

    return (
        <RoutedPageComponent disableSwipeForwardNavigation={props.disableSwipeForwardNavigation}
                             disableSwipeNavigation={props.disableSwipeNavigation}>
            <BookingFlowPageHeaderLargeScreenComponent renderExtraHeader={renderWizardSteps}
                                                       dontAsForConfirmationOnLogoClick={props.dontAskForConfirmationOnLogoClick}/>
            <StandardPageContentComponent>
                <PageContentBox>
                    {renderLeftSection()}
                    {renderRightSection()}
                </PageContentBox>
            </StandardPageContentComponent>
            {renderFooter()}
        </RoutedPageComponent>
    );
});
