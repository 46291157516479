import {IServiceFactory} from "../../service-factory.interface";
import {AvailableDepartureTripModel, AvailableReturnTripModel, AvailableTripModel} from "./available-trip.model";
import {AvailableFareWithDiscountModel} from "./fares/available-fare-with-discount.model";
import {computed, makeObservable} from "mobx";
import {NullableDate, NullableString} from "../../../types/nullable-types";
import {ILowFareResult} from "../../low-fare/low-fare-readers/low-fare-reader.interface";
import {IFareToSell} from "../../booking/models/booking-view-model.interface";
import {IFareDiscountType} from "./discount-types/fare-discount-type.interface";
import {BogoFareDiscount} from "./discount-types/bogo-fare-discount";
import {NullableStation} from "../../stations/station.service.interface";
import {IAvailabilityViewModel} from "./availability-view-model.interface";
import {IFlightsScheduleViewModel} from "../flights-schedule/flights-schedule-view-model.interface";
import {IFlightSearchControllerViewModel} from "../flight-search-controller/flight-search-controller-view-model.interface";
import {Price} from "../../currency/price";
import {IAvailabilityFareReducer} from "./fare-reducer.interface";


export class AvailabilityModel implements IAvailabilityViewModel{
    constructor(public readonly searchController: IFlightSearchControllerViewModel,
                private readonly farePriceReducer: IAvailabilityFareReducer,
                private readonly services: IServiceFactory) {

        makeObservable(this, {
            departureTrips: computed,
            returnTrips: computed
        });
    }

    get pricingInformationMessage(): string {
        return this.farePriceReducer.availabilityPricingInformationMessage;
    }

    get departureTrips(): AvailableDepartureTripModel[] {
        const searchResult = this.searchController.departureSearchResult;

        return searchResult.trips.map(trip => new AvailableDepartureTripModel(this,
                                                                            trip,
                                                                            this.services,
                                                                            searchResult.faresAvailable))
                                 .filter(trip => trip.journeys.length > 0);
    }

    get returnTrips(): AvailableReturnTripModel[] {

        const searchResult = this.searchController.returnSearchResult;

        return searchResult.trips.map(trip => new AvailableReturnTripModel(this,
                                                                            trip,
                                                                            this.services,
                                                                            searchResult.faresAvailable))
                                .filter(trip => trip.journeys.length > 0);



    }

    get isAvailabilitySearchWithBlueBenefits(): boolean {
        return this.searchController.withBlueBenefits;
    }

    get showStandardAndBlueBenefitsPrice(): boolean {
        return this.isAvailabilitySearchWithBlueBenefits && this.farePriceReducer.showStandardAndBlueBenefitsPrice;
    }

    get departureOrigin(): NullableStation {
        return this.searchController.departureOrigin;
    }

    get departureDestination(): NullableStation {
        return this.searchController.departureDestination;
    }

    get returnOrigin(): NullableStation {
        return this.searchController.returnOrigin;
    }

    get returnDestination(): NullableStation {
        return this.searchController.returnDestination;
    }

    get departureDate(): NullableDate {
        return this.searchController.departureDate;
    }

    get returnDate(): NullableDate {
       return this.searchController.returnDate;
    }

    get departureFlightSchedule(): IFlightsScheduleViewModel {
        return this.searchController.departureFlightSchedule;
    }

    get returnFlightSchedule(): IFlightsScheduleViewModel {
        return this.searchController.returnFlightSchedule;
    }

    getDepartureLowFare(date: Date): ILowFareResult {
        return this.services.booking.getLowFareReader().getDepartureLowFare(date);
    }
    getReturnLowFare(date: Date): ILowFareResult {
        return this.services.booking.getLowFareReader().getReturnLowFare(date);
    }

    get isOneWayTrip(): boolean {
        return this.searchController.isOneWayDepartureTrip;
    }

    private get allTrips(): AvailableTripModel[] {
        return [
            ...this.departureTrips,
            ...this.returnTrips
        ]
    }

    get hasDepartureFareSelected(): boolean {
        return this.departureTrips.some(t => t.hasFareSelected)
    }


    get hasReturnFareSelected(): boolean {
        return this.returnTrips.some(t => t.hasFareSelected);
    }

    private _createFareToSell(fare: AvailableFareWithDiscountModel): IFareToSell {
        // if any of the journeys have BOGO available then we will use BOGO to make the search,
        // otherwise if BOGO is available only on return journey and we sell departure journey without BOGO
        // then we won't be able to sell return journey with BOGO.
        const discount = this._overrideWithBogo(fare.discount);
        return {
            journeyKey: fare.journeyKey,
            fareAvailabilityKey: fare.fareKey,
            infantCount: this.getNumberOfInfants(),
            passengerTypes: discount.createPassengerTypesForTripSell(this.searchController.passengers),
            withBlueBenefits: fare.hasBlueBenefits,
            designator: fare.designator
        };
    }

    reduceDepartureFarePrice(price: Price): Price {
        return this.farePriceReducer.reduceDepartureFare(price);
    }

    async sellDepartureJourney(fare: AvailableFareWithDiscountModel): Promise<void> {
        await this.services.booking.current.sellDepartureJourney(this._createFareToSell(fare));

    }

    reduceReturnFarePrice(price: Price): Price {
        return this.farePriceReducer.reduceReturnFare(price);
    }

    async sellReturnJourney(fare: AvailableFareWithDiscountModel): Promise<void> {
        await this.services.booking.current.sellReturnJourney(this._createFareToSell(fare));

    }

    get departureBundleNameToShowOnFare(): NullableString {
        return this.farePriceReducer.departureBundleNameToShowOnFare;
    }

    get returnBundleNameToShowOnFare(): NullableString {
        return this.farePriceReducer.returnBundleNameToShowOnFare;
    }

    getNumberOfInfants(): number {
        return this.searchController.passengers.countInfants();
    }

    private _overrideWithBogo(discount: IFareDiscountType): IFareDiscountType {
        if(discount instanceof BogoFareDiscount) {
            return discount;
        }

        for(let trip of this.allTrips) {
            for(let journey of trip.journeys) {
                for(let fare of journey.fares) {

                    if(fare.discount instanceof BogoFareDiscount) {
                        return fare.discount;
                    }
                }
            }
        }

        return discount;
    }

    async changeDepartingDate(newDepartureDate: Date): Promise<void> {
        if (newDepartureDate.getTime() === this.departureDate?.getTime()) {
            return;
        }

        this.searchController.departureDate = newDepartureDate;

        if(this.searchController.returnDate) {
            if(newDepartureDate.getTime() > this.searchController.returnDate.getTime()) {
                this.searchController.returnDate = this.returnFlightSchedule.availableDates.find(d => d.getTime() >= newDepartureDate.getTime()) || newDepartureDate;
                await this.searchController.applySearch();
                return;
            }
        }

        await this.searchController.applySearchForDepartureOnly()
        setTimeout(()=> {
            this.services.analytics.flightSelectionEvents.logViewItemListForDeparture(this);
        })
    }

    async changeReturnDate(newReturnDate: Date): Promise<void> {
        if (newReturnDate.getTime() === this.searchController.returnDate?.getTime()) {
            return;
        }

        this.searchController.returnDate = newReturnDate;

        await this.searchController.applySearchForReturnOnly();
        setTimeout(()=> {
            this.services.analytics.flightSelectionEvents.logViewItemListForReturn(this);
        })
    }



    dispose(): void {
    }
}
