import React from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {StandardDropDownComponent} from "../standard-drop-down/standard-drop-down.component";
import {IFormField} from "../../../models/forms/form-field.interface";
import {observer} from "mobx-react";
import {Gender, NullableGender} from "../../../services/dot-rez-api/data-contracts/enums/gender.enum";
import {StandardDropDownListItemModel} from "../standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";
import {IStandardDropDownItemViewModel} from "../standard-drop-down/list-view/models/item/standard-drop-down-list-item-view-model.interface";

interface GenderFieldEditorComponentProps {
    field: IFormField<NullableGender>;
    useGenderAsNumber: boolean;
}

export const GenderFieldEditorComponent: React.FC<GenderFieldEditorComponentProps> = observer((props) => {
    const services = useServices();
    let items: IStandardDropDownItemViewModel<Gender>[];
    if(props.useGenderAsNumber) {
        items = [
            new StandardDropDownListItemModel(1, services.language.translate('Male')),
            new StandardDropDownListItemModel(1, services.language.translate('Female'))
        ]
    } else {
        items = [
            new StandardDropDownListItemModel('Male', services.language.translate('Male')),
            new StandardDropDownListItemModel('Female', services.language.translate('Female')),
        ]
    }
    return (
        <StandardDropDownComponent field={props.field} items={items}/>
    )
})
