import React, {CSSProperties, PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassFieldLabelComponent} from "../label/boarding-pass-field-label.component";
import {BoardingPassFieldValueComponent} from "../value/boarding-pass-field-value.component";

const BookingReferenceBox: React.FC<PropsWithChildren> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: '1',
        marginBottom: services.theme.currentTheme.spacing.large
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
});

export const BoardingPassRecordLocatorComponent: React.FC<{recordLocator: string}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const labelStyle: CSSProperties = {
        textAlign: 'center'
    }

    const valueStyles: CSSProperties = {
        fontSize: theme.fontSize.xxxxxLarge,
        textAlign: 'center'
    }

    return (
        <BookingReferenceBox>
            <BoardingPassFieldLabelComponent styles={labelStyle}>
                {services.language.translate('Booking code')}
            </BoardingPassFieldLabelComponent>
            <BoardingPassFieldValueComponent styles={valueStyles}>
                {props.recordLocator}
            </BoardingPassFieldValueComponent>
        </BookingReferenceBox>
    );
}
