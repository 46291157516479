import React from "react";
import {observer} from "mobx-react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableString} from "../../../types/nullable-types";
import {StringFieldEditorComponent} from "../string-field/string-field-editor.component";
import {FieldEditorCommonProps} from "../field-editor-common.props";

interface DocumentNumberEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<NullableString>;
}
export const DocumentNumberEditorComponent: React.FC<DocumentNumberEditorComponentProps> = observer((props) => {
    return (
        <StringFieldEditorComponent {...props}/>
    )
});
