import {BookingModel} from "../booking.model";
import {makeObservable, observable, runInAction} from "mobx";
import {BookingSessionStorageKeys} from "./booking-storage.interface";
import {Check} from "../../../../types/type-checking";

export class SameForAllFlightsStorage {
    constructor(private readonly booking: BookingModel) {

        this._sameForAllFlightsMap = booking.storage.getJson(BookingSessionStorageKeys.sameForAllFlights) ?? {};

        makeObservable<this, '_sameForAllFlightsMap'>(this, {
            _sameForAllFlightsMap: observable
        });
    }

    private _sameForAllFlightsMap: Record<string, boolean> = {};

    private _persistInStorage(): void {
        this.booking.storage.setJson(BookingSessionStorageKeys.sameForAllFlights, this._sameForAllFlightsMap);
    }

    isSet(bucketIdentifier: string): boolean {
        return !Check.isNullOrUndefined(this._sameForAllFlightsMap[bucketIdentifier]);
    }

    setValue(bucketIdentifier: string, value: boolean): void {
        runInAction(() => {
            this._sameForAllFlightsMap[bucketIdentifier] = value;
            this._persistInStorage();
        });
    }

    getValue(bucketIdentifier: string): boolean {
        return Boolean(this._sameForAllFlightsMap[bucketIdentifier]);
    }

    setAll(value: boolean): void {
        runInAction(() => {
            Object.keys(this._sameForAllFlightsMap).forEach(key => {
                this._sameForAllFlightsMap[key] = value;
            });

            this._persistInStorage();
        });
    }

    reset(): void {
        runInAction(() => {
           this._sameForAllFlightsMap = {};
           this._persistInStorage();
        });
    }
}
