import React from 'react';
import {observer} from "mobx-react-lite";
import {Station} from "../../../../services/stations/station.service.interface";
import styled from "styled-components";

const DesignatorStationCodeBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`


interface DesignatorStationCodeComponentProps {
    station: Station;
    useMac?: boolean;
    className?: string;
}
export const DesignatorStationCodeComponent: React.FC<DesignatorStationCodeComponentProps> = observer((props) => {
 const getStationCode = () => {
        if(props.useMac) {
            return props.station.stationMacCode;
        } else {
            return props.station.stationCode;
        }
    }

    return (
        <DesignatorStationCodeBox className={props.className}>
            {getStationCode()}
        </DesignatorStationCodeBox>
    )
});
