import {Price} from "../../../../currency/price";
import {IRoundTripLowFareReader} from "../../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {IFareToSell} from "../../booking-view-model.interface";
import {IDotRezPartialBookingSessionData} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {VoluntaryDepartureJourneyChangeMutation} from "../../mutation-actions/sell-trip/manage-my-booking/voluntary-change-flow/voluntary-departure-journey-change.mutation";
import {VoluntaryReturnJourneyChangeMutation} from "../../mutation-actions/sell-trip/manage-my-booking/voluntary-change-flow/voluntary-return-journey-change.mutation";
import {BookingModel} from "../../booking.model";
import {BookingSnapshotModel} from "../../snapshots/booking/booking-snapshot.model";
import {IFlightSearchControllerViewModel} from "../../../../flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {VoluntaryFlightsChangeLowFareReaderDecorator} from "./voluntary-flights-change-low-fare-reader.decorator";
import {IFlightsChangeStrategy} from "./flights-change-strategy.interface";
import {IServiceFactory} from "../../../../service-factory.interface";

export class VoluntaryFlightsChangeStrategy implements IFlightsChangeStrategy {

    constructor(private readonly booking: BookingModel) {
        if(this.initialBooking.departureJourney) {
            booking.flightSearchController.departureMinPrice = this.initialBooking.departureJourney.fareAmount + this.initialBooking.departureJourney.totalDiscount;
        }

        if(this.initialBooking.returnJourney) {
            booking.flightSearchController.returnMinPrice = this.initialBooking.returnJourney.fareAmount + this.initialBooking.returnJourney.totalDiscount;
        }
    }

    get services(): IServiceFactory {
        return this.booking.services;
    }

    get initialBooking(): BookingSnapshotModel {
        return this.booking.initialBookingSnapshot;
    }

    private _createPriceDifference(initialFareAmount: number, currentPrice: Price): Price {
        return this.services.currency.createPrice(Math.max(0, currentPrice.amount - initialFareAmount) , currentPrice.currency);
    }

    get hasFreeDepartureJourneyChange(): boolean {
        return Boolean(this.initialBooking.departureJourney?.shouldWaveFeeOnDateChange);
    }

    get hasFreeReturnJourneyChange(): boolean {
        return Boolean(this.initialBooking.returnJourney?.shouldWaveFeeOnDateChange);
    }

    get searchController(): IFlightSearchControllerViewModel {
        return this.booking.flightSearchController;
    }

    get isDepartureChangeOnTheSameRoute(): boolean {
        const departureDesignator = this.initialBooking.departureJourney?.designator;
        if(!departureDesignator) {
            return false;
        }

        return departureDesignator.origin.stationCode === this.searchController.departureOrigin?.stationCode
                && departureDesignator.destination.stationCode === this.searchController.departureDestination?.stationCode;
    }

    get isReturnChangeOnTheSameRoute(): boolean {
        const returnDesignator = this.initialBooking.returnJourney?.designator;
        if(!returnDesignator) {
            return false;
        }

        return returnDesignator.origin.stationCode === this.searchController.returnOrigin?.stationCode
            && returnDesignator.destination.stationCode === this.searchController.returnDestination?.stationCode;
    }

    reduceDepartureFare(price: Price): Price {
        if(this.isDepartureChangeOnTheSameRoute) {
            return this._createPriceDifference(this.initialBooking.departureJourney?.fareAmount ?? 0, price);
        } else {
            return price;
        }
    }

    reduceReturnFare(price: Price): Price {
        if(this.isReturnChangeOnTheSameRoute) {
            return this._createPriceDifference(this.initialBooking.returnJourney?.fareAmount ?? 0, price);
        } else {
            return price;
        }
    }

    applyLowFareReaderDecorator(getOriginalLowFareReader: () => IRoundTripLowFareReader): IRoundTripLowFareReader {
        return new VoluntaryFlightsChangeLowFareReaderDecorator();
    }

    async sellDepartureJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        const mutation = new VoluntaryDepartureJourneyChangeMutation(this.booking, fareToSell, this.hasFreeDepartureJourneyChange,  onAfterSell);
        this.booking.mutationsManager.startSellDepartureJourneyMutation(mutation);
        await mutation.waitForMutation();
    }

    async sellReturnJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        const mutation = new VoluntaryReturnJourneyChangeMutation(this.booking, fareToSell, this.hasFreeReturnJourneyChange, onAfterSell);
        this.booking.mutationsManager.startSellReturnJourneyMutation(mutation);
        await mutation.waitForMutation();
    }
}
