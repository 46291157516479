import React from 'react';
import {ISvgIconProps, SvgIcon} from './svg-icon.component';

export const CheckedSelectedIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 15.436 11.875" ratio={15.436/11.875}>
            <g fill={props.color? props.color: "currentColor"} id="Close" transform="translate(-3.494)"><g id="Capa_1" transform="translate(0 0)"><g id="Group"><path id="Shape" d="M1.758,0C.791,0,0,.279,0,.62V7.574c0,.341.791.62,1.758.62s1.666-.279,1.758-.62V.62C3.508.279,2.718,0,1.758,0Z" transform="translate(11.775 9.019) rotate(135)"/><path id="Shape-2" data-name="Shape" d="M1.758,0C.791,0,0,.451,0,1V12.272c0,.552.488.5,1.541.894a1.422,1.422,0,0,0,1.975-.894V1c0-.552-.791-1-1.758-1Z" transform="translate(16.444 0) rotate(45)"/></g></g></g>
        </SvgIcon>
    )
}
