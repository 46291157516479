import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {
    IPassengerSegmentBoardingPassViewModel
} from "../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {BoardingPassesContainerComponent} from "./boarding-passes-container/boarding-passes-container.component";
import {BoardingPassExportAllButtonComponent} from "./export-all-buttons/boarding-pass-export-all-button.component";
import styled from "styled-components";
import {useServices} from "../../../hooks/use-services.hook";
import {BookingPageContentCentralizationComponent} from "../../booking-flow/common-components/booking-page-content-centralization.component";
import {StandardDialogFooterComponent} from "../../../components/modal/footer/standard-dialog-footer.component";
import {
    AddAllBoardingPassesToWalletButtonComponent
} from "../../../components/add-to-wallet-buttons/add-all-boarding-passes-to-wallet-button.component";

const TitleTextBox = styled.div`
    flex-grow: 1;
`

const BoardingPassPageTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
`

const BoardingPassPageTitleComponent: React.FC<PropsWithChildren> = observer((props) => {
    const services = useServices();
    return (
        <BoardingPassPageTitleBox>
            <TitleTextBox>
                {services.language.translate('Boarding pass')}
            </TitleTextBox>
            {props.children}
        </BoardingPassPageTitleBox>
    );
});

const ExportButtonsForSmallScreenContainerBox = styled.div<{supportsSharing: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: ${props => props.theme.spacing.small};
    padding-right: ${props => props.supportsSharing ? props.theme.spacing.cardDefaultPaddingPx : "0"};
`


interface BoardingPassesPageProps {
    modalHandler: IDialogConfirmationHandler;
    bookingKey: string;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
}

export const BoardingPassDialogComponent: React.FC<BoardingPassesPageProps> = observer((props) => {
    const services = useServices();
    const boardingPasses = props.boardingPasses;

    const renderTitle = () => {
        if(services.layout.isInSmallScreenView) {
            return services.language.translate('Boarding pass');
        }

        return (
            <BoardingPassPageTitleComponent>
                <BoardingPassExportAllButtonComponent bookingKey={props.bookingKey} boardingPasses={boardingPasses}/>
            </BoardingPassPageTitleComponent>
        );

    }

    const renderExportButtonsForSmallScreen = () => {
        if(!services.layout.isInSmallScreenView) {
            return null;
        }

        return (
            <ExportButtonsForSmallScreenContainerBox supportsSharing={services.pdfExporter.supportsSharing}>
                <BoardingPassExportAllButtonComponent bookingKey={props.bookingKey} boardingPasses={boardingPasses}/>
            </ExportButtonsForSmallScreenContainerBox>
        );

    }

    const renderAddToWalletButton = () => {
        const eligibleBoardingPasses = services.mobileWallet.filterEligiblePasses(props.boardingPasses);
        if(eligibleBoardingPasses.length === 0) {
            return null;
        }

        return (
            <AddAllBoardingPassesToWalletButtonComponent bookingKey={props.bookingKey} boardingPasses={eligibleBoardingPasses}/>
        );
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={renderTitle()}
                                           dialogHandler={props.modalHandler}
                                           dontMirrorCloseButton={!services.layout.isInSmallScreenView}/>
            <StandardDialogContentComponent>
                <BookingPageContentCentralizationComponent shoppingCartIsHidden={true}
                                                           useSmallerMarginsWhenShoppingCartIsHidden={true}>
                    {renderExportButtonsForSmallScreen()}
                    <BoardingPassesContainerComponent bookingKey={props.bookingKey} boardingPasses={boardingPasses}/>
                </BookingPageContentCentralizationComponent>

            </StandardDialogContentComponent>

            <StandardDialogFooterComponent dialogHandler={props.modalHandler}>
                {renderAddToWalletButton()}
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
