import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {IonSpinner} from "@ionic/react";
import {PriceComponent} from "../../../../components/price/price.component";
import styled, {css} from "styled-components";
import {AirlineTheme} from "../../../../services/theme/interfaces/airline.theme";
import classNames from "classnames";
import {FlightsDatesSelector} from "../../../../services/flight-search/dates-selection/flights-dates-selector";
import {
    ILowFareResult,
    LowFareStatusEnum
} from "../../../../services/low-fare/low-fare-readers/low-fare-reader.interface";

function computeDayContentBorders(theme: AirlineTheme, isSelected: boolean, isInsideSelection: boolean) {
    if(isSelected) {
        return css`
          border: 2px solid ${props => props.theme.colors.secondary};
        `
    }if (isInsideSelection) {
        return css`
          margin: 2px 0;
          border-radius: 0;
        `;
    } else {
        return css`
          border: 1px solid ${props => props.theme.colors.light};
        `
    }
    
    
}

function computeDayContentBackgroundColor(theme: AirlineTheme, isSelected: boolean, isInsideSelection: boolean) {
    if(isInsideSelection || isSelected) {
        return css`
          background-color: ${props => props.theme.colors.lightShade};
        `;
    } else {
       return '';
    }
}

function computeSameDayDepartureAndReturnBorders(theme: AirlineTheme, isSelected: boolean, isSameDayDepartureAndReturn: boolean) {
    if(isSameDayDepartureAndReturn && isSelected) {
        return css`
          position: relative;
          &:before {
            content: '';
            top: -3px;
            left: calc(50% - 2px);
            height: 4px;
            width: 8px;
            position: absolute;
            background-image: linear-gradient(${props => props.theme.colors.secondaryContrast} 50%, ${props => props.theme.colors.lightShade} 50%);
            
          }
          &:after {
            content: '';
            bottom: -5px;
            left: 50%;
            height: 6px;
            width: 6px;
            position: absolute;
            border-bottom: 2px solid ${props => props.theme.colors.secondary};
            border-right: 2px solid ${props => props.theme.colors.secondary};
            transform: rotate(135deg) skew(4deg, 4deg);
          }
        `;
    } else {
       return '';
    }
}

function computeDayNumberBoxSameDayDepartureAndReturnBorders (theme: AirlineTheme, isSelected: boolean, isSameDayDepartureAndReturn: boolean) {
    if(isSameDayDepartureAndReturn && isSelected) {
        return css`
          position: relative;
          &:before {
            content: '';
            top: -9px;
            left: calc(50% - 10px);
            height: 6px;
            width: 6px;
            position: absolute;
            border-bottom: 2px solid ${props => props.theme.colors.secondary};
            border-right: 2px solid ${props => props.theme.colors.secondary};
            transform: rotate(-45deg) skew(4deg, 4deg);
            
          }
        `;
    } else {
       return '';
    }
}

function computeDayPriceBoxSameDayDepartureAndReturnBorders (theme: AirlineTheme, isSelected: boolean, isSameDayDepartureAndReturn: boolean) {
    if(isSameDayDepartureAndReturn && isSelected) {
        return css`
          position: relative;
          &:after {
            content: '';
            bottom: -6px;
            left: calc(50% - 8px);
            height: 4px;
            width: 8px;
            position: absolute;
            background-image: linear-gradient(${props => props.theme.colors.lightShade} 50%, ${props => props.theme.colors.secondaryContrast} 50%);
          }
        `;
    } else {
       return '';
    }
}


export const DayContentBox = styled.div<{isAvailable: boolean; isSelected: boolean; isInsideSelection: boolean; isSameDayDepartureAndReturn: boolean}>`
    margin: 2px;
    opacity: ${props => props.isAvailable ? 1 : 0.4};
    padding: 4px 2px 3px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-radius: 5px;
    ${props => computeDayContentBorders(props.theme, props.isSelected, props.isInsideSelection)}
    ${props => computeDayContentBackgroundColor(props.theme, props.isSelected, props.isInsideSelection)}
    ${props => computeSameDayDepartureAndReturnBorders(props.theme, props.isSelected, props.isSameDayDepartureAndReturn)}
    line-height: 12px;
    height: 100%;
    width: 100%;
    white-space: normal;
    min-height: 40px;
    cursor: ${props => props.isAvailable ? "cell" : "not-allowed"};
`;

export const DayNumberBox = styled.span<{isSelected: boolean; isSameDayDepartureAndReturn: boolean}>`
    display: flex;
    justify-content: end;
    margin: 0;
    padding: 0 4px 0;
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.primaryShade};
    line-height: 10px;
    ${props => computeDayNumberBoxSameDayDepartureAndReturnBorders(props.theme, props.isSelected, props.isSameDayDepartureAndReturn)}
  
    font-size: ${props => props.theme.fontSize.medium};
  
    @media ${(props) => props.theme.mediaQuery.lAndBelow} {
      font-size: ${props => props.theme.fontSize.small};
    }
  
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      font-size: ${props => props.theme.fontSize.xSmall};    
    }
`;


function computeDayPriceColor(theme: AirlineTheme, isSelected: boolean) {
    if(isSelected) {
        return css`
          color: ${props => props.theme.colors.secondaryShade};
          font-weight: ${props => props.theme.fontWeight.bold};
        `;
    } else {
        return css`
          color: ${props => props.theme.colors.mediumShade};
          font-weight: ${props => props.theme.fontWeight.bold};
        `;
    }
}


export const DayPricesBox = styled.span<{isSelected: boolean, isSameDayDepartureAndReturn: boolean}>`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
    padding: 0;
    ${props => computeDayPriceColor(props.theme, props.isSelected)}
    justify-content: center;
    align-items: center;
    ${props => computeDayPriceBoxSameDayDepartureAndReturnBorders(props.theme, props.isSelected, props.isSameDayDepartureAndReturn)}
`;

const PriceComponentBox = styled(PriceComponent)`
    text-align: center;
    font-size: ${props => props.theme.fontSize.medium};
  
    @media ${(props) => props.theme.mediaQuery.lAndBelow} {
      font-size: ${props => props.theme.fontSize.small};
    }
  
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {
      font-size: ${props => props.theme.fontSize.xxSmall};    
    }
`

const PriceDirectionArrowBox = styled.div<{isDeparturePrice: boolean}>`
    display: flex;
    flex-direction: row;
    position: relative;
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.colors.secondaryTint};
    margin: 2px 0 3px 0;
    &:after {
      content: "";
      position: absolute;
      top: -2px;
      ${
        props => props.isDeparturePrice
                 ? css`
                      right: -1px;
                      transform: rotate(-45deg);
                      -webkit-transform: rotate(-45deg);
                  `
                : css`
                      left: 0;
                      transform: rotate(135deg);
                      -webkit-transform: rotate(135deg);
                  `
     }
      border: solid ${props => props.theme.colors.secondaryTint};
      border-width: 0 1px 1px 0;
      padding: 2px;
    }
`

const DayPriceBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

interface DayPriceComponentProps {
    lowFareResult: ILowFareResult
    isDeparturePrice: boolean;
    showPriceDirectionArrow: boolean;
}

const DayPriceComponent: React.FC<DayPriceComponentProps> = observer((props) => {
    const services = useServices();
    if(!props.lowFareResult.price) {
        return null;
    }

    const computePriceDecimalPartFontSize = () => {
        if(services.mediaQuery.sAndBelow) {
            return services.theme.currentTheme.fontSize.xxxxSmall;
        }

        if(services.mediaQuery.lAndBelow) {
            return services.theme.currentTheme.fontSize.xxxSmall;
        }

        return services.theme.currentTheme.fontSize.xxSmall;
    }

    return(
        <DayPriceBox>
            <PriceComponentBox price={props.lowFareResult.price} decimalPartFontSize={computePriceDecimalPartFontSize()}/>
            {props.showPriceDirectionArrow && (<PriceDirectionArrowBox className={'tst-arrow-box'} isDeparturePrice={props.isDeparturePrice}/>)}
        </DayPriceBox>
    )
});

interface DayComponentProps {
    date: Date;
    captureSelectedDateElementRef?: (date: Date, element: HTMLElement | null) => void;
    shouldRenderPrice: boolean;
    flightsDatesSelector: FlightsDatesSelector;
}

export const FlightDayContentComponent: React.FC<DayComponentProps> = observer((props) => {

    const isSelected =  props.flightsDatesSelector.isDateSelected(props.date);
    const isDepartureDate = props.flightsDatesSelector.isDepartureDate(props.date);
    const isSameDayAsDepartureAndReturn = props.flightsDatesSelector.isSameDateForDepartureAndReturn(props.date);

    const captureDayContentBoxElement = (el: any) => {
        if(isSelected && props.captureSelectedDateElementRef) {
            props.captureSelectedDateElementRef(props.date, el);
        }
    }



    let departureLowFareResult: ILowFareResult = {
        status: LowFareStatusEnum.NoFare
    };

    let returnLowFareResult: ILowFareResult = {
        status: LowFareStatusEnum.NoFare
    };

    if(props.shouldRenderPrice) {
        departureLowFareResult = props.flightsDatesSelector.getDepartureLowFare(props.date);
        returnLowFareResult = props.flightsDatesSelector.getReturnLowFare(props.date);
    }

    const isAvailable = props.flightsDatesSelector.isDateAvailable(props.date);

    const renderPrice = () => {

        if(departureLowFareResult.status === LowFareStatusEnum.Reading || returnLowFareResult.status === LowFareStatusEnum.Reading) {
            return (
                <DayPricesBox isSelected={isSelected} isSameDayDepartureAndReturn={isSameDayAsDepartureAndReturn}>
                    <IonSpinner/>
                </DayPricesBox>
            )
        }

        if(isSameDayAsDepartureAndReturn) {
            return (
                <DayPricesBox isSelected={isSelected}
                              isSameDayDepartureAndReturn={isSameDayAsDepartureAndReturn}>
                    <DayPriceComponent lowFareResult={departureLowFareResult}
                                       isDeparturePrice={true}
                                       showPriceDirectionArrow={true}/>
                    <DayPriceComponent lowFareResult={returnLowFareResult}
                                       isDeparturePrice={false}
                                       showPriceDirectionArrow={true}/>
                </DayPricesBox>
            )
        }

        const renderOneDayPrice = (dayPriceProps: DayPriceComponentProps) => {
            if(!dayPriceProps.lowFareResult.price) {
                return null;
            }
            return (
                <DayPricesBox isSelected={isSelected}
                              isSameDayDepartureAndReturn={isSameDayAsDepartureAndReturn}>
                    <DayPriceComponent {...dayPriceProps}/>

                </DayPricesBox>
            )
        }


        const renderOneDayDeparturePrice = () => {
            return renderOneDayPrice({
                showPriceDirectionArrow: false,
                lowFareResult: departureLowFareResult,
                isDeparturePrice: true
            });
        }

        const renderOneDayReturnPrice = () => {
            return renderOneDayPrice({
                showPriceDirectionArrow: false,
                lowFareResult: returnLowFareResult,
                isDeparturePrice: false
            });
        }

        if(isSelected) {
            if(isDepartureDate) {
                return renderOneDayDeparturePrice();
            } else {
                return renderOneDayReturnPrice();
            }
        }


        if(props.flightsDatesSelector.isOnDepartureDateStrategy) {
            return renderOneDayDeparturePrice();
        } else {
            return renderOneDayReturnPrice();
        }
    }


    return (
        <DayContentBox isAvailable={isAvailable}
                       isSelected={isSelected}
                       isInsideSelection={props.flightsDatesSelector.isDateInsideSelection(props.date)}
                       isSameDayDepartureAndReturn={isSameDayAsDepartureAndReturn}
                       ref={captureDayContentBoxElement}
                       className={classNames('tst-day-box', {'tst-day-box-available': Boolean(isAvailable && departureLowFareResult)})}>
            <DayNumberBox isSelected={isSelected} isSameDayDepartureAndReturn={isSameDayAsDepartureAndReturn}>{props.date.getDate()}</DayNumberBox>
            {renderPrice()}
        </DayContentBox>
    );
})
