import {BookingModel} from "../../booking.model";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {DialogResult} from "../../../../dialog/dialog-enums";
import {ManageSsrsBaseModel} from "./manage-ssrs-base.model";
import {IManageMultiSsrsViewModel} from "./manage-ssrs-view-model.interface";
import { NullableString } from "../../../../../types/nullable-types";
import {IBookingSsrsAggregatorViewModel} from "../../ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";

export class ManageMultiSsrsModel extends ManageSsrsBaseModel implements IManageMultiSsrsViewModel {

    constructor(title: string,
                booking: BookingModel,
                protected readonly ssrsAggregator: IBookingSsrsAggregatorViewModel) {
        super(title, booking)
    }

    get ssrTypes(): ISsrType[] {
        return this.ssrsAggregator.ssrTypes;
    }

    get allowEdit(): boolean {
        return this.ssrsAggregator.canBeModified;
    }

    get modificationBlockingReason(): NullableString {
        return this.ssrsAggregator.modificationBlockingReason;
    }

    async edit(): Promise<void> {
        const dialogResult = await this.services.dialogFactory.showMultipleSsrsSelector({
            title: this.title,
            aggregator: this.ssrsAggregator
        });

        if(dialogResult === DialogResult.Accepted) {
            await this.booking.sellSsrs();
        }
    }
}
