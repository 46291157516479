import {IAdvancedInAppBrowserOptions} from "./window.service.interface";
import {IInAppBrowser} from "./browser-tab.interface";
import {AirlineCompanyName} from "../../global-constants";
import {CapacitorWebview, UrlChangedEvent} from "@jackobo/capacitor-webview";


export class AdvancedInAppBrowser implements IInAppBrowser {
    constructor(private readonly options: IAdvancedInAppBrowserOptions) {
        this._waitPromise = new Promise<void>(resolve => {
            this._waitPromiseResolver = resolve;
        });

        this._openWebView().finally(() => {
            this._dispose();
            if(this._waitPromiseResolver) {
                this._waitPromiseResolver();
            }
        })
    }



    private async _openWebView(): Promise<void> {

        if(this.options.onUrlChanged) {
            await CapacitorWebview.addListener('urlChanged', this._onUrlChanged);
        }

        await CapacitorWebview.openWebView({
            url: this.options.url,
            headers: this.options.ngrokHeaders,
            enableDebug: this.options.enableDebug,
            ignoreSslErrors: true,
            urlPatternsToOpenInExternalBrowser: this.options.urlPatternsToOpenInExternalBrowser,
            screenOrientation: 'portrait',
            toolbar: {
                title: AirlineCompanyName,
                backgroundColor: this.options.theme.colors.secondWebView.toolbar.backgroundColor,
                color: this.options.theme.colors.secondWebView.toolbar.color
            }
        });


    }

    private async _dispose(): Promise<void> {
        await CapacitorWebview.removeAllListeners();
    }

    async close(): Promise<void> {
        await CapacitorWebview.closeWebView();
    }

    private _onUrlChanged = async (event: UrlChangedEvent) => {
        //console.log(`_onUrlChanged: ${state.url}`)
        if(this.options.onUrlChanged) {
            await this.options.onUrlChanged(event.url);
        }
    }

    private _waitPromiseResolver: null | ((value: void | PromiseLike<void>) => void) = null;
    private readonly _waitPromise: Promise<void>;
    wait(): Promise<void> {
        return this._waitPromise;
    }
}
