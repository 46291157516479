import React from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";
import {BookingContactFieldsComponent} from "../../contact/components/booking-contact-fields.component";

const DescriptionBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.xLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
`

const BookingContactBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.extraLarge};
`

export const BookingContactComponent: React.FC = observer(() => {
    const services = useServices();
    if(services.booking.current.passengers.some(p => p.isPrimaryContact)) {
        return null;
    }

    return (
        <BookingContactBox>
            <DescriptionBox>
                {services.language.translate('Contact details')}
            </DescriptionBox>
            <BookingContactFieldsComponent isInHeadlessMode={false}/>
        </BookingContactBox>
    );
});
