import {IHomePageService} from "./home-page.service.interface";
import {action, makeObservable, observable} from "mobx";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {IHomePageTab} from "./tabs/home-page.tab.interface";
import {BookTab} from "../../pages/home/tabs/book.tab";
import {MyTripsTab} from "../../pages/home/tabs/my-trips.tab";
import {CheckInTab} from "../../pages/home/tabs/check-in.tab";
import {MoreOptionsTab} from "../../pages/home/tabs/more-options.tab";

export class HomePageService extends ServiceBase implements IHomePageService {
    constructor(services: IServiceFactory) {
        super(services);
        this.tabs = [];

        const envConfigs = this.services.configuration.envConfigs;

        if(envConfigs.enableCheckin) {
            this.tabs.push(new CheckInTab(this.services, this));
        }
        this.tabs.push(new BookTab(this.services, this));
        this.tabs.push(new MyTripsTab(this.services, this));

        if(envConfigs.enableLogin) {
            this.tabs.push(new MoreOptionsTab(this.services, this));
        }


        this.currentTab = this.tabs.find(t => t instanceof BookTab) || this.tabs[0];

        makeObservable(this, {
            currentTab: observable.ref,
            setCurrentTab: action.bound
        });
    }

    currentTab: IHomePageTab;
    readonly tabs: IHomePageTab[];
    setCurrentTab(newTab: IHomePageTab): void {
        if(newTab !== this.currentTab) {
            this.currentTab.deactivate();
            this.currentTab = newTab;
        }
    }

    activateBookTab(): void {
        this._activateTabByType(BookTab);
    }

    activateCheckInTab(): void {
        this._activateTabByType(CheckInTab);
    }
    activateMyBookingsTab(): void {
        this._activateTabByType(MyTripsTab);
    }

    activateMoreOptionsTab(): void {
        this._activateTabByType(MoreOptionsTab);
    }

    private _activateTabByType(tabPrototype: any): void {
        this.services.navigator.goHome().then(() => {
            const tab = this.tabs.find(t => t instanceof tabPrototype)
            if(tab) {
                tab.open();
            }
        });

    }
}
