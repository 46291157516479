import { ISvgIconProps, SvgIcon } from "./svg-icon.component";


export const InformationCircleIcon:React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.7} {...props} viewBox="0 0 27 27" ratio={1}>
            <g fill={props.color? props.color: "currentColor"}>
            <path d="M23.5,10A13.5,13.5,0,1,0,37,23.5,13.5,13.5,0,0,0,23.5,10Zm0,25.314A11.814,11.814,0,1,1,35.311,23.5,11.829,11.829,0,0,1,23.5,35.314Zm0-15.189a1.688,1.688,0,0,0-1.689,1.686V30.25a1.688,1.688,0,1,0,3.375,0V21.811A1.684,1.684,0,0,0,23.5,20.125Zm-1.689-3.347a1.688,1.688,0,1,1,.5,1.193,1.711,1.711,0,0,1-.5-1.193Z" transform="translate(-10 -10)"/>
            </g>
        </SvgIcon>
    )
}