import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {SameForAllComponent} from "./same-for-all.component";
import {ISupportsSameOptionsForAllPassengersToggle} from "../../../services/booking/models/ssrs/supports-same-options-for-all-passengers-toggle.interface";


interface SameForAllPassengersToggleComponentProps {
    ssrsBucket: ISupportsSameOptionsForAllPassengersToggle;
    className?: string;
    onChecked?: () => void;
}

export const SameForAllPassengersToggleComponent: React.FC<SameForAllPassengersToggleComponentProps> = observer((props) => {
    const services = useServices();

    const onChange = async (checked: boolean) => {
        if(checked === props.ssrsBucket.useSameOptionsForAllPassengers) {
            return;
        }

        props.ssrsBucket.useSameOptionsForAllPassengers = checked;

        if(checked && props.onChecked) {
            props.onChecked();
        }

    }

    const passengerFirstName = services.booking.current.passengers[0].getPassengerFirstName().split(' ')[0];
    return (
        <SameForAllComponent label={services.language.translationFor('Same options as {passengerName} for all passengers').withParams({passengerName: passengerFirstName})}
                             checked={() => Boolean(props.ssrsBucket.useSameOptionsForAllPassengers)}
                             onChanged={checked => onChange(checked)}
                             className={props.className}/>
    );
});

