import {
    IBookingService,
    IShowBookingDetailsParams,
    IRetrieveBookingParams,
    ISearchBookingByEmailParams,
    ISearchBookingByLastNameParams} from "./booking.service.interface";
import {makeObservable, observable, reaction, runInAction} from "mobx";
import {IServiceFactory} from "../service-factory.interface";
import {AvailabilityModel} from "../flight-search/models/availability.model";
import {ServiceBase} from "../service-base";
import {IBookingStrategy} from "./booking-strategies/booking-strategy.interface";
import {NoBookingStrategy} from "./booking-strategies/no-strategy/no-booking.strategy";
import {IDotRezBookingSession} from "../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";

import {IDotRezBookingData} from "../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IRoundTripLowFareReader} from "../low-fare/low-fare-readers/low-fare-reader.interface";
import {
    IFlightSearchControllerViewModel,
    SpecialPriceMarketUserAgreement,
    SpecialPriceMarketUserOptions
} from "../flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {BookingModel} from "./models/booking.model";
import {BookingStrategyFactory} from "./booking-strategies/booking-strategy.factory";
import {IWizard} from "../../models/wizard/wizard.interface";
import {IRouteActivationOptions} from "../navigation/navigator.service.interface";
import {DialogCloseButtonBehavior} from "../dialog/dialog-enums";
import {SpecialPriceMarketUserAgreementDialogComponent} from "../../pages/flight-search/special-price-market/special-price-market-user-agreement-dialog.component";

interface IRetrieveBookingStrategyOptions {
    retrieveBookingState: (bookingSession: IDotRezBookingSession) => Promise<IDotRezBookingData>;
    createBookingStrategy: (bookingSession: IDotRezBookingSession, bookingData: IDotRezBookingData) => Promise<IBookingStrategy>;
    useTransientBookingSession?: boolean;
}

export class BookingService extends ServiceBase implements IBookingService {
    constructor(services: IServiceFactory) {
        super(services);

        this._strategyFactory = new BookingStrategyFactory(services);

        this._currentStrategy = new NoBookingStrategy(services);
        makeObservable(this, {
            _currentStrategy: observable.ref,
            _isBookingRestoreInProgress: observable.ref
        });

        reaction(() => this.services.navigator.currentRoute,
            async () => {
                if(this.services.navigator.routes.home.isActive) {
                    await this._setCurrentStrategy(new NoBookingStrategy(this.services));
                }
            });

        this._tryRestore()
            .catch(err => {
                this.services.logger.debug('Failed to restore booking strategy', err)
            })
            .finally(() => {
                runInAction(() => {
                    this._isBookingRestoreInProgress = false;
                });
            });
    }

    private readonly _strategyFactory: BookingStrategyFactory;

    private async _tryRestore(): Promise<void> {
        if(this.services.navigator.routes.booking.hasActiveChildRoute) {
            await this._tryRestoreStrategy(() => this._strategyFactory.tryRestoreBookingStrategy());
        } else if(this.services.navigator.routes.manageMyBooking.hasActiveChildRoute) {
            await this._tryRestoreStrategy(() => this._strategyFactory.tryRestoreManageMyBookingStrategy());
        } else if(this.services.navigator.routes.checkIn.hasActiveChildRoute) {
            await this._tryRestoreStrategy(() => this._strategyFactory.tryRestoreCheckInStrategy());
        } else if(this.services.navigator.routes.flightItinerary.isActive) {
            await this._tryRestoreStrategy(() => this._strategyFactory.tryRestoreViewBookingDetailsStrategy());
        }
    }

    private async _tryRestoreStrategy(restoreAction: () => Promise<IBookingStrategy | null>): Promise<void> {

        await this.services.loadingIndicator.execute({
            action: async () => {
                const strategy = await restoreAction();
                if(strategy !== null) {
                    await this._setCurrentStrategy(strategy)
                } else {
                    await this.services.navigator.goHome();
                }
            },
            displayExceptionHandler: () => {
                //no need to display anything to the user
                //in case the restore booking state fails when the user refresh the page we don't want to show him anything
            }
        });

    }

    get isThereABookingInProgress(): boolean {
        return Boolean(this._currentStrategy)
            && !(this._currentStrategy instanceof NoBookingStrategy);
    }

    _isBookingRestoreInProgress: boolean = true;
    get isBookingRestoreInProgress(): boolean {
        if(this._isBookingRestoreInProgress) {
            return true;
        }

        if(this.isThereABookingInProgress) {
            return this.current.isBookingDiscardChangesInProgress;
        }

        return false;
    }



    get steps(): IWizard {
        return this._currentStrategy.steps;
    }

    get searchController(): IFlightSearchControllerViewModel {
        return this._currentStrategy.flightSearchController;
    }

    _currentStrategy: IBookingStrategy;
    get current(): BookingModel {
        return this._currentStrategy.booking;
    }

    get activeCurrency(): string {
        return this._currentStrategy.activeCurrency;
    }

    get availability(): AvailabilityModel {
        return this._currentStrategy.availability;
    }

    private async _setCurrentStrategy(newStrategy: IBookingStrategy): Promise<void> {
        try {
            await this._currentStrategy.dispose();
        } catch (err) {
            this.services.logger.warning('Failed to reset the booking', err);
        }

        runInAction(() => {
            this._currentStrategy = newStrategy;
        });
    }

    async startNewBooking(searchController: IFlightSearchControllerViewModel, activateOptions?: IRouteActivationOptions): Promise<void> {

        if(searchController.specialPriceMarketUserOption !== SpecialPriceMarketUserOptions.None
            && searchController.specialPriceMarketUserAgreement === SpecialPriceMarketUserAgreement.None) {
            await this.services.dialog.showStandardDialog({
                closeButtonBehavior: DialogCloseButtonBehavior.None,
                forceFullScreenOnLargeScreens: true,
                render: (dialogHandler => <SpecialPriceMarketUserAgreementDialogComponent searchController={searchController} dialogHandler={dialogHandler}/>)
            });
        }

        await this.services.loadingIndicator.execute({
            showPromotionalMessage: true,
            action: async () => {

                const flightSearchSession = await searchController.getFlightSearchSession();
                
                searchController = searchController.clone({
                    storageKey: 'activeBooking.searchControllerStorageKey',
                    bookingSession: this.services.dotRezApi.createBookingSession(flightSearchSession.token)
                });

                await searchController.applySearch();
                const strategy = await this._strategyFactory.startNewBookingStrategy(flightSearchSession, searchController);
                await this._setCurrentStrategy(strategy);
            }
        });


        this.steps.start(activateOptions);
    }

    private async _startCheckIn(retrieveBookingState: (bookingSession: IDotRezBookingSession) => Promise<IDotRezBookingData>): Promise<void> {

        await this.services.loadingIndicator.execute({
            action: async () => {
                const strategy = await this._retrieveBookingStrategy({
                    retrieveBookingState: retrieveBookingState,
                    createBookingStrategy: (bookingSession, bookingData) => this._strategyFactory.createCheckInStrategy(bookingSession, bookingData)
                });

                if(strategy) {
                    await this._setCurrentStrategy(strategy);
                    this.steps.start();
                }
            }
        });
    }

    private async _retrieveBookingStrategy(options: IRetrieveBookingStrategyOptions): Promise<IBookingStrategy | null> {

        let bookingSession:IDotRezBookingSession;
        if(options.useTransientBookingSession) {
            bookingSession = await this.services.user.createTransientBookingSession();
        } else {
            bookingSession = await this.services.user.createBookingSession();
        }
        let bookingData: IDotRezBookingData;
        try {
            bookingData = await options.retrieveBookingState(bookingSession);
        } catch (err) {
            this.services.alert.showError(this.services.language.translate(`We couldn't find any booking for the specified criteria. Please try again`));
            return null;
        }

        return await options.createBookingStrategy(bookingSession, bookingData);
    }


    async startCheckInByLastName(searchParams: ISearchBookingByLastNameParams): Promise<void> {
        await this._startCheckIn(session => session.bringBookingInStateByLastName(searchParams));
    }

    async startCheckInByEmail(searchParams: ISearchBookingByEmailParams): Promise<void> {
        await this._startCheckIn(session => session.bringBookingInStateByEmail(searchParams));
    }

    async startCheckInWithFallback(searchParams: IRetrieveBookingParams): Promise<void> {
        await this._startCheckIn(session => this._retrieveBookingWithFallBack(searchParams, session));
    }

    private async _startManageMyBooking(retrieveBookingState: (bookingSession: IDotRezBookingSession) => Promise<IDotRezBookingData>,
                                        activateOptions?: IRouteActivationOptions): Promise<void> {
        await this.services.loadingIndicator.execute({
            action: async () => {
                const strategy = await this._retrieveBookingStrategy({
                    retrieveBookingState: retrieveBookingState,
                    createBookingStrategy: (bookingSession, bookingData) => this._strategyFactory.createManageMyBookingStrategy(bookingSession, bookingData)
                });

                if(strategy) {
                    await this._setCurrentStrategy(strategy);
                    this.steps.start(activateOptions);
                }
            }
        });
    }

    async startManageMyBookingByLastName(searchParams: ISearchBookingByLastNameParams): Promise<void> {
        await this._startManageMyBooking(session => session.bringBookingInStateByLastName(searchParams));
    }

    async startManageMyBookingByEmail(searchParams: ISearchBookingByEmailParams): Promise<void> {
        await this._startManageMyBooking(session => session.bringBookingInStateByEmail(searchParams));
    }

    async startManageMyBookingWithFallback(searchParams: IRetrieveBookingParams): Promise<void> {
        await this._startManageMyBooking(session => this._retrieveBookingWithFallBack(searchParams, session));
    }

    private async _retrieveBookingWithFallBack(searchParams: IRetrieveBookingParams, session: IDotRezBookingSession): Promise<IDotRezBookingData> {
        try {
            return await session.bringBookingInStateByEmail(searchParams);
        } catch (err) {
            this.services.logger.error(`Failed to retrieve booking by email = ${searchParams.emailAddress} | recordLocator = ${searchParams.recordLocator}`)
            return await session.bringBookingInStateByLastName(searchParams);
        }
    }

    async showFlightItinerary(searchParams: IShowBookingDetailsParams): Promise<void> {
        const token = await this.services.loadingIndicator.execute({
            action: async () => {
                let retrieveBookingState: (bookingSession: IDotRezBookingSession) => Promise<IDotRezBookingData>;
                if(searchParams.emailAddress) {
                    const emailAddress = searchParams.emailAddress;
                    retrieveBookingState = session => this._retrieveBookingWithFallBack({
                        ...searchParams,
                        emailAddress: emailAddress
                    }, session)
                } else {
                    retrieveBookingState = session => session.bringBookingInStateByLastName(searchParams)
                }
                const strategy = await this._retrieveBookingStrategy({
                        useTransientBookingSession: true,
                        retrieveBookingState: retrieveBookingState,
                        createBookingStrategy: (bookingSession, bookingData) => this._strategyFactory.createViewBookingDetailsStrategy(bookingSession, bookingData)
                    }
                );

                if(strategy) {
                    await this._setCurrentStrategy(strategy);
                }

                return strategy?.booking.token || null;
            }
        });

        if(token) {
            this.services.navigator.routes.flightItinerary.activate();
        }
    }

    async upgradeCurrentBookingSessionToAuthorizedUser(userName: string, password: string): Promise<void> {
        await this._currentStrategy.upgradeCurrentBookingSessionToAuthorizedUser(userName, password);
    }

    getLowFareReader(): IRoundTripLowFareReader {
        return this._currentStrategy.getLowFaresReader();
    }

    async createTransientBookingStrategy(bookingSession: IDotRezBookingSession, bookingData: IDotRezBookingData): Promise<IBookingStrategy> {
        return this._strategyFactory.createTransientBookingStrategy(bookingSession, bookingData);
    }

    async switchToManageMyBooking(): Promise<void> {
        if(!this.isThereABookingInProgress) {
            return;
        }

        const searchParams: ISearchBookingByLastNameParams = {
            lastName: this.current.passengers[0].name.last!,
            recordLocator: this.current.recordLocator!
        }

        await this._startManageMyBooking(session => session.bringBookingInStateByLastName(searchParams), {
            allowBack: false
        });
    }

    async switchToFlightItinerary(): Promise<void> {
        if(!this.isThereABookingInProgress) {
            return;
        }

        const searchParams: IShowBookingDetailsParams = {
            emailAddress: this.current.contact.fields.emailAddress.value!,
            lastName: this.current.passengers[0].name.last!,
            recordLocator: this.current.recordLocator!
        }

        await this.showFlightItinerary(searchParams)
    }

    getCommitBookingChannel(): string {
        return `${this.getPaymentChannel()}_${this.services.device.getOperatingSystemName()}`;
    }

    getPaymentChannel(): string {
        if(this.services.device.isHybrid) {
            return "MobileApp";
        } else {
            return "Website";
        }
    }
}
