import React, {KeyboardEventHandler} from "react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableString} from "../../../types/nullable-types";
import {observer} from "mobx-react";
import {StringFieldEditorComponent} from "../string-field/string-field-editor.component";
import {FieldEditorCommonProps} from "../field-editor-common.props";

interface PhoneFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<NullableString>;
    className?: string;
}

export const PhoneFieldEditorComponent: React.FC<PhoneFieldEditorComponentProps> = observer((props) => {
    const onKeyPressHandler: KeyboardEventHandler = (event) => {
        if(! /^[\+\-\(\)0-9]$/.test(event.key)) {
            event.preventDefault();
        }
    }

    return (
        <StringFieldEditorComponent {...props} onKeyPress={onKeyPressHandler} inputMode="tel" className={props.className}/>
    )
});

