import React, {CSSProperties, PropsWithChildren} from "react";
import {
    IBoardingPassSsr
} from "../../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {useServices} from "../../../../../hooks/use-services.hook";
import {BoardingPassFieldValueComponent} from "../../value/boarding-pass-field-value.component";
import {BoardingPassBagsSectionComponent} from "../common/boarding-pass-bags-section.component";
import {BoardingPassBagsSectionTitleComponent} from "../common/boarding-pass-bags-section-title.component";
import {HorizontalSpacer} from "../../../../../basic-components/spacing/horizontal-spacer";

const CheckInBagCountBox: React.FC<PropsWithChildren> = (props) => {
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}

const BagCountComponent: React.FC<{bagSsrCount: IBoardingPassSsr; numberOfBagsTypes: number}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;

    if(props.bagSsrCount.count === 0) {
        return null;
    }

    const ssrType = services.ssrTypes.getSsrType(props.bagSsrCount.ssrCode);

    const textStyles: CSSProperties = {
        paddingRight: theme.spacing.small,
        fontWeight: 'bold',
        fontSize: theme.fontSize.medium,
    }

    const iconStyles: CSSProperties = {
        display: 'flex',
        width: props.numberOfBagsTypes > 3 ? '20px' : '25px',
    }

    return (
        <CheckInBagCountBox>
            <BoardingPassFieldValueComponent styles={textStyles}>
                {`${props.bagSsrCount.count} x`}
            </BoardingPassFieldValueComponent>
            <div style={iconStyles}>
                {ssrType.largeIcon}
            </div>
        </CheckInBagCountBox>
    );
}

const CheckInBagsBox: React.FC<{bags: IBoardingPassSsr[]}> = (props) => {
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "row"
    }

    return (
        <div style={styles}>
            {props.bags.map((b, index) => {
                return (
                    <React.Fragment key={b.ssrCode}>
                        <BagCountComponent bagSsrCount={b} numberOfBagsTypes={props.bags.length}/>
                        <HorizontalSpacer size={"medium"} isVisible={index < props.bags.length - 1}/>
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export const BoardingPassCheckinBagsComponent: React.FC<{bags: IBoardingPassSsr[]}> = (props) => {
    const services = useServices();

    if(props.bags.length === 0) {
        return null;
    }

    return (
        <BoardingPassBagsSectionComponent>
            <BoardingPassBagsSectionTitleComponent title={services.language.translate('Checked in bags')}/>
            <CheckInBagsBox bags={props.bags}/>
        </BoardingPassBagsSectionComponent>
    )
}
