import {IInAppBrowser} from "./browser-tab.interface";
import { Browser } from "@capacitor/browser";
//import {PluginListenerHandle} from "@capacitor/core";
import {PluginListenerHandlerSubscription} from "./plugin-listener-handler-subscription";

//https://capacitorjs.com/docs/apis/browser

//https://www.youtube.com/watch?v=YlUIHYsRGY4
//https://www.youtube.com/watch?v=CkFCLyOaWKs
//https://github.com/google/cordova-plugin-browsertab/issues/18


//https://github.com/ionic-team/capacitor/issues/1414
//https://lonare.medium.com/window-close-not-working-how-to-redirect-the-parent-window-i-have-got-you-covered-solution-56c63a40f169


export class SimpleInAppBrowserTab implements IInAppBrowser {
    constructor () {


        this._waitPromise = new Promise<void>(resolve => {
            this._waitPromiseResolver = resolve;
        })

        let subscription = Browser.addListener('browserFinished', () => {
            this._unsubscribeFromBrowserFinish();

        });
        this._browserFinishedSubscription = new PluginListenerHandlerSubscription(subscription);
    }

    private _waitPromiseResolver: null | ((value: void | PromiseLike<void>) => void) = null;
    private readonly _waitPromise: Promise<void>;

    wait(): Promise<void> {
        return this._waitPromise;
    }

    public isOpen:boolean = false;
    private _browserFinishedSubscription: PluginListenerHandlerSubscription | null = null;

    //This doesn't work for Android
    async close(): Promise<void> {
        await Browser.close();
        this._unsubscribeFromBrowserFinish();
    }


    private _unsubscribeFromBrowserFinish(): void {
        this._browserFinishedSubscription?.remove();
        this._browserFinishedSubscription = null;
        if(this._waitPromiseResolver) {
            this._waitPromiseResolver();
        }
    }

}
