import {BookingModel} from "../booking.model";
import {IManageMyBookingViewModel} from "./manage-my-booking-view-model.interface";
import {ManageSeatsModel} from "./seats/manage-seats.model";
import {IManageMultiSsrsViewModel, IManageSingleSsrViewModel,} from "./ssrs/manage-ssrs-view-model.interface";
import {ManageMultiSsrsModel} from "./ssrs/manage-multi-ssrs.model";
import {ManageSingleSsrModel} from "./ssrs/manage-single-ssr.model";
import {ManageMyBookingPriorityBoarding} from "./ssrs/manage-my-booking-priority-boarding.model";
import {IServiceFactory} from "../../../service-factory.interface";
import {JourneyModel} from "../journey/journey.model";
import {IBookingFaresReducer} from "../../../flight-search/models/fare-reducer.interface";
import {Price} from "../../../currency/price";
import {IReactionDisposer, reaction} from "mobx";
import {Lazy} from "../../../../utils/lazy";
import {IFlightsChangeStrategy} from "./flights-modifier/flights-change-strategy.interface";
import {DisruptedFlightsChangeStrategy} from "./flights-modifier/disrupted-flights-change.strategy";
import {VoluntaryFlightsChangeStrategy} from "./flights-modifier/voluntary-flights-change.strategy";
import {IRoundTripLowFareReader} from "../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {IFareToSell} from "../booking-view-model.interface";
import {IDotRezPartialBookingSessionData} from "../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {IPassengerTypeSelectorsList} from "../../../passenger-types/passengers-types.service.interface";
import {IUnresolvedServices} from "./unresolved-services";
import {FlightDesignatorModel} from "../designator/flight-designator.model";
import {PreventJourneysOverlapError} from "../../../../types/errors/prevent-journeys-overlap-error";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../../dialog-factory/yes-no-dialog.enums";
import {IJourneySnapshotViewModel} from "../snapshots/journey/journey-snapshot-view-model.interface";


export class ManageMyBookingModel implements IManageMyBookingViewModel, IBookingFaresReducer {
    constructor(private readonly booking: BookingModel) {
        this.seats = new ManageSeatsModel(booking);
        this.checkInBags =  new ManageMultiSsrsModel(booking.services.language.translate('Baggage'),
                                                    booking,
                                                    booking.createSsrsAggregator({
                                                        ssrTypes: booking.services.ssrTypes.getCheckInBagsThatCanBeSoldIndividually()
                                                    }));
        this.priorityBoarding = new ManageMyBookingPriorityBoarding(booking, booking.createSsrsAggregator({
            ssrTypes: [booking.services.ssrTypes.PBRD]
        }));
                                                         
        this.airportCheckIn = new ManageSingleSsrModel(booking, booking.createSsrsAggregator({
            ssrTypes: [booking.services.ssrTypes.CKN]
        }));
        this.earlyCheckIn = new ManageSingleSsrModel(booking, booking.createSsrsAggregator({
            ssrTypes: [booking.services.ssrTypes.ECKN]
        }));

        this.fastTrack = new ManageSingleSsrModel(booking, booking.createSsrsAggregator({
            ssrTypes: [booking.services.ssrTypes.FAST]
        }));
        this.businessLounge = new ManageSingleSsrModel(booking, booking.createSsrsAggregator({
            ssrTypes: [booking.services.ssrTypes.LOU]
        }));
        this.busTransfer = new ManageSingleSsrModel(booking, booking.createSsrsAggregator({
            ssrTypes: [booking.services.ssrTypes.SBUS]
        }));

        this.pets = new ManageMultiSsrsModel(booking.services.language.translate('Pets'),
                                            booking,
                                            booking.createSsrsAggregator({
                                                ssrTypes: booking.services.ssrTypes.getPetsSsrTypes()
                                            }));

        this.specialEquipment = new ManageMultiSsrsModel(booking.services.language.translate('Special Equipment'),
                                            booking,
                                            booking.createSsrsAggregator({
                                                ssrTypes: booking.services.ssrTypes.getSpecialEquipmentSsrTypes()
                                            }));

        this.specialAssistance = new ManageMultiSsrsModel(booking.services.language.translate('Special Assistance'),
                                            booking,
                                            booking.createSsrsAggregator({
                                                ssrTypes: booking.services.ssrTypes.getSpecialAssistanceSsrTypes()
                                            }));



        this._initSearchParams();

        this._reactions.push(reaction(() => this.availableJourneysForDateChange.map(j => j.isJourneySelected),
            () => {
                const searchController = this.services.booking.searchController;
                searchController.isOneWayDepartureTrip = (1 === this.selectedJourneysForDateChange.length) && !this.onlyTheReturnJourneyIsSelected;
                searchController.isOneWayReturnTrip = this.onlyTheReturnJourneyIsSelected;
            }, {
                fireImmediately: true
            }));


    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    readonly seats: ManageSeatsModel;
    readonly checkInBags: IManageMultiSsrsViewModel;
    readonly priorityBoarding: IManageSingleSsrViewModel;
    readonly airportCheckIn: IManageSingleSsrViewModel;
    readonly earlyCheckIn: IManageSingleSsrViewModel;
    readonly fastTrack: IManageSingleSsrViewModel;
    readonly businessLounge: IManageSingleSsrViewModel;
    readonly busTransfer: IManageSingleSsrViewModel;
    readonly pets: IManageMultiSsrsViewModel;
    readonly specialEquipment: IManageMultiSsrsViewModel;
    readonly specialAssistance: IManageMultiSsrsViewModel;

    private _initSearchParams(): void {
        const unfilteredJourneys = this.booking.unfilteredJourneys;
        if(unfilteredJourneys.length === 0) {
            return;
        }
        const searchController = this.services.booking.searchController;
        searchController.allowBogo = false;
        searchController.withBlueBenefits = this.booking.blueBenefits.isBookingWithBlueBenefits;
        searchController.passengers = this._getPassengersTypesSelectors();
        const departureDesignator = unfilteredJourneys[0].designator;
        searchController.departureOrigin = departureDesignator.origin;
        searchController.departureDestination = departureDesignator.destination;

        const returnDesignator = unfilteredJourneys[1]?.designator;
        searchController.returnOrigin = returnDesignator?.origin || null;
        searchController.returnDestination = returnDesignator?.destination || null;
    }

    private _getPassengersTypesSelectors(): IPassengerTypeSelectorsList {
        const passengersSelectors = this.services.passengerTypes.getPassengersSelectors(() => this.booking.blueBenefits.isBookingWithBlueBenefits);

        const bookingPassengerTypes = this.booking.passengers.groupByKey(p => p.passengerType.code);

        for(let passengerTypeCode of Object.keys(bookingPassengerTypes)) {
            passengersSelectors.findByCode(passengerTypeCode).count = bookingPassengerTypes[passengerTypeCode].length;
        }

        passengersSelectors.findByCode(this.services.passengerTypes.getInfantPassengerType().code).count = this.booking.passengers.countInfants();

        return passengersSelectors;
    }

    // For the moment we show all the journeys in the booking but this might change in the future.
    // This is the reason I created this property. To have a level of indirection that I can use to change the available journeys for date change.
    get availableJourneysForDateChange(): JourneyModel[] {
        return this.booking.unfilteredJourneys;
    }

    get selectedJourneysForDateChange(): JourneyModel[] {
        return this.availableJourneysForDateChange.filter(j => j.isJourneySelected);
    }

    get hasJourneysThatAllowDateChange(): boolean {
        return this.availableJourneysForDateChange.some(j => j.initialJourneyAllowsDateChange);
    }

    get hasSelectedJourneysForDateChange(): boolean {
        return this.selectedJourneysForDateChange.length > 0;
    }

    get canBeModified(): boolean {
        return this.booking.filteredJourneys.length > 0;
    }

    get onlyTheDepartureJourneyIsSelected(): boolean {
        return this.selectedJourneysForDateChange.length === 1 && !this.onlyTheReturnJourneyIsSelected;
    }

    get onlyTheReturnJourneyIsSelected(): boolean {
        const unfilteredJourneys = this.booking.unfilteredJourneys;
        if(unfilteredJourneys.length <= 1) {
            return false;
        }

        if(unfilteredJourneys.length === this.selectedJourneysForDateChange.length) {
            return false;
        }

        return unfilteredJourneys[1].isJourneySelected;
    }

    async searchForNewAvailability(): Promise<void> {
        await this.services.booking.searchController.applySearch();
    }

    get canMoveDisruptedFlights(): boolean {
        return this.booking.initialBookingSnapshot.canMoveDisruptedFlights;
    }

    private _createFlightsModifier(): IFlightsChangeStrategy {
        if(this.canMoveDisruptedFlights) {
            return new DisruptedFlightsChangeStrategy(this.booking);
        }

        return new VoluntaryFlightsChangeStrategy(this.booking);
    }

    private _flightsChanger: Lazy<IFlightsChangeStrategy> = new Lazy<IFlightsChangeStrategy>(() => this._createFlightsModifier());
    private get flightsChanger(): IFlightsChangeStrategy {
        return this._flightsChanger.value;
    }

    reduceDepartureFare(price: Price): Price {
        return this.flightsChanger.reduceDepartureFare(price);
    }

    reduceReturnFare(price: Price): Price {
        return this.flightsChanger.reduceReturnFare(price);
    }

    applyLowFareReaderDecorator(getOriginalLowFareReader: () => IRoundTripLowFareReader): IRoundTripLowFareReader {
        return this.flightsChanger.applyLowFareReaderDecorator(getOriginalLowFareReader)
    }

    private _reactions: IReactionDisposer[] = [];
    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }

    private async _validateJourneysOverlap(designator: FlightDesignatorModel, journeyModel: JourneyModel | null): Promise<void> {
        if(!journeyModel) {
            return;
        }

        if(journeyModel.hasChangedSegments) {
            return;
        }

        if(journeyModel.designator.overlaps(designator)) {
            const msg = this.services.language.translationFor('The flight you selected overlaps this flight: {journey}. You can still choose this flight but make sure that you change the other one too.')
                .withParams({journey: designator.toUserFriendlyString()});

            const dialogResult = await this.services.dialogFactory.showYesNoDialog({
                title: this.services.language.translate('Flights overlaps'),
                message: msg,
                yesButtonText: this.services.language.translate('I want to choose a different flight'),
                noButtonText: this.services.language.translate('I will change the other flight too'),
                primaryButton: YesNoDialogPrimaryButton.PrimaryButtonYes
            });

            if(dialogResult === YesNoDialogResult.Yes) {
                throw new PreventJourneysOverlapError();
            }
        }

    }

    async sellDepartureJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        await this._validateJourneysOverlap(fareToSell.designator, this.booking.returnJourney);
        await this.flightsChanger.sellDepartureJourney(fareToSell, onAfterSell);
    }

    async resellDepartureJourneyBundle(): Promise<void> {
        const initialDepartureJourneyBundle = this.booking.initialBookingSnapshot.departureJourney?.currentBundleCode;
        if(!initialDepartureJourneyBundle) {
            throw new Error('You cannot resell departure journey bundle because there is no initial departure journey');
        }

        await this.booking.sellDepartureJourneyBundle(initialDepartureJourneyBundle);
    }

    async sellReturnJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        await this._validateJourneysOverlap(fareToSell.designator, this.booking.departureJourney);
        await this.flightsChanger.sellReturnJourney(fareToSell, onAfterSell);
    }

    async resellReturnJourneyBundle(): Promise<void> {
        const initialReturnJourneyBundle = this.booking.initialBookingSnapshot.returnJourney?.currentBundleCode;
        if(!initialReturnJourneyBundle) {
            throw new Error('You cannot resell return journey bundle because there is no initial return journey');
        }

        await this.booking.sellReturnJourneyBundle(initialReturnJourneyBundle);
    }

    getUnresolvedServices(): IUnresolvedServices {
        const result: IUnresolvedServices = {
            hasUnresolvedServices: false,
            seats: [],
            ssrs: []
        }
        this.selectedJourneysForDateChange.forEach(journey => {
            result.seats = [
                ...result.seats,
                ...journey.getUnresolvedSeats()
            ];

            const journeyUnresolvedSsrs = journey.getUnresolvedSsrs();
            if(journeyUnresolvedSsrs.ssrs.length > 0) {
                result.ssrs.push(journeyUnresolvedSsrs);
            }
        });

        result.hasUnresolvedServices = result.seats.length > 0 || result.ssrs.length > 0;

        return result;
    }

    async discardChanges(): Promise<void> {
        await this.booking.discardChanges();
    }

    get hasJourneysThatAllowRefund(): boolean {
        return this.booking.initialBookingSnapshot.journeys.some(j => j.canBeRefunded);
    }

    get availableJourneysForRefund(): IJourneySnapshotViewModel[] {
        return this.booking.initialBookingSnapshot.journeys;
    }

    get totalToRefund(): Price {
        return this.booking.initialBookingSnapshot.totalToRefund;
    }


    async startModifyFlightsChange(): Promise<void> {
        if(this.booking.initialBookingSnapshot.flightsChangeBlockingReason) {
            this.services.alert.showError(this.booking.initialBookingSnapshot.flightsChangeBlockingReason);
            return;
        }
        this.booking.steps.activateStepByRoute(this.services.navigator.routes.manageMyBooking.selectNewFlightsDates);
    }

    async startRefundFlights(): Promise<void> {
        if(this.booking.initialBookingSnapshot.flightsChangeBlockingReason) {
            this.services.alert.showError(this.booking.initialBookingSnapshot.flightsChangeBlockingReason);
            return;
        }

        this.services.navigator.routes.manageMyBooking.selectFlightsToRefund.activate();
    }

    get selectedJourneysForRefund(): IJourneySnapshotViewModel[] {
        return this.availableJourneysForRefund.filter(j => j.isSelectedForRefund);
    }

    get hasJourneysSelectedForRefund(): boolean {
        return this.selectedJourneysForRefund.length > 0;
    }

    async refundToWallet(): Promise<void> {
        /*
        await this.services.airlineWebapi.refundToCustomerWallet({
            dotRezToken: this.booking.session.token,
            journeysToRefund: this.selectedJourneysForRefund.map(j => j.journeyKey)
        });
         */
    }
}
