import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const ArrowCircularIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.3} sizeIsHeight {...props} viewBox="0 0 32.68 23.74" ratio={32.68/23.74}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(-171.16 -529.586)">
                <path d="M3.206,7.113a.537.537,0,0,0,.782,0L4.7,6.365a.6.6,0,0,0,0-.818L3.988,4.8h9.847A1.7,1.7,0,0,1,15.5,6.535a.851.851,0,0,0,.83.868h.553a.851.851,0,0,0,.83-.868,3.97,3.97,0,0,0-3.874-4.051H3.988L4.7,1.736a.6.6,0,0,0,0-.818L3.988.169a.537.537,0,0,0-.782,0L.081,3.437a.3.3,0,0,0,0,.409Z" transform="translate(196.355 548.007) rotate(180)" /><path d="M13.877.285a.5.5,0,0,0-.749,0l-.685.738a.6.6,0,0,0,0,.806l.685.738H3.707A1.655,1.655,0,0,1,2.118.856.828.828,0,0,0,1.324,0H.794A.828.828,0,0,0,0,.856,3.862,3.862,0,0,0,3.707,4.848h9.422l-.685.738a.6.6,0,0,0,0,.806l.685.738a.5.5,0,0,0,.749,0l2.99-3.22a.3.3,0,0,0,0-.4Z" transform="translate(195.59 540.915) rotate(180)" /><path d="M29.3,0H3.381A3.385,3.385,0,0,0,0,3.381V20.359A3.385,3.385,0,0,0,3.381,23.74H29.3a3.385,3.385,0,0,0,3.381-3.381V3.381A3.385,3.385,0,0,0,29.3,0m.577,20.359a.586.586,0,0,1-.577.577H3.381a.586.586,0,0,1-.577-.577V3.381A.586.586,0,0,1,3.381,2.8H29.3a.586.586,0,0,1,.577.577Z" transform="translate(171.16 529.586)" />
            </g>
        </SvgIcon>
    )
}
