import React from "react";
import {IonToggle} from "@ionic/react";
import styled from "styled-components";
import { ShoppingCartComponent } from "../../../components/shopping-cart/shopping-cart.component";
import { useServices } from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {ExternalLinkComponent} from "../../../components/external-link/external-link.component";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import {BookingFlowPage} from "../booking-flow.page";
import {BookingPageContentCentralizationComponent} from "../common-components/booking-page-content-centralization.component";
import {PriceComponent} from "../../../components/price/price.component";

const PageTitleContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;   
`

const TitleBox = styled.div`
    line-height: 1;
`;



const ContentBox = styled.div`
  
`

const FooterBox = styled(BookingPageContentCentralizationComponent)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const TermsAndConditionsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    margin: 0 0 ${props => props.theme.spacing.large};
    @media ${props => props.theme.mediaQuery.sAndBelow} {
      font-size: ${props => props.theme.fontSize.small};
    }
   
`

const TermsAndConditionsTextBox = styled(ExternalLinkComponent)`
    flex-grow: 1;
    margin-left: ${props => props.theme.spacing.large};
`

export const FinalPricePage: React.FC = observer(() => {
    const services = useServices();

    if(services.booking.isBookingRestoreInProgress) {
        return null;
    }

    const booking = services.booking.current;

    const onClick = async () => {
        await services.booking.steps.nextStep();
    }

    const getPageTitle = () => {
        if(services.booking.isBookingRestoreInProgress) {
            return "";
        }

        return(
            <PageTitleContainerBox>
                <TitleBox>{services.language.translate('Final Price')}</TitleBox>
                <TitleBox>
                    <PriceComponent price={booking.balanceDue} decimalPartFontSize={services.theme.currentTheme.fontSize.xLarge}/>
                </TitleBox>
            </PageTitleContainerBox>
        )
    }

    const renderContent = () => {
        return (
            <ContentBox>
                <ShoppingCartComponent/>
            </ContentBox>
        );
    }

    const renderFooter = () => {
        const travelConditionsAccepted = services.booking.current.travelConditionsAccepted;
        let buttonText = services.language.translate('Continue with payment');
        if(booking.balanceDue.amount === 0) {
            buttonText = services.language.translate('Save changes');
        }

        return (
            <FooterBox shoppingCartIsHidden={true}>
                <TermsAndConditionsContainerBox>
                    <IonToggle checked={travelConditionsAccepted} onIonChange={(e) => services.booking.current.travelConditionsAccepted = e.detail.checked}/>
                    <TermsAndConditionsTextBox innerHtml={services.externalLinks.travelConditions}/>
                </TermsAndConditionsContainerBox>
                <PrimaryButton disabled={!travelConditionsAccepted}
                                   onClick={onClick}>
                    {buttonText}
                </PrimaryButton>
            </FooterBox>
        );
    }

    return (
        <BookingFlowPage title={getPageTitle()}
                               renderContent={renderContent}
                               hideShoppingCart={true}
                               hideWizardProgressIndicator={services.layout.isInSmallScreenView}
                               renderExtraFooterContent={renderFooter}
                               hideContinueButton={true}
                               showPromotionCode={false}>

        </BookingFlowPage>
    );
});
