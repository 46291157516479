import React from 'react';
import {PassengerTypeSelectorComponent} from "../../../components/passenger-type-selector/passenger-type-selector.component";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {IPassengerTypeSelector} from "../../../services/passenger-types/passengers-types.service.interface";
import styled from 'styled-components';
import {FlightSearchPage} from "../flight-search.page";
import {FlightSearchTitleComponent} from "../components/flight-search-title.component";
import {ExternalLinkComponent} from "../../../components/external-link/external-link.component";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import {PersonGroupIcon} from "../../../assets/svg/person-group.icon";

const PageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.large};
`

const PassengerTypeSubHeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: ${props => props.theme.fontSize.xLarge};
    line-height: 1;
    text-shadow: 0 6px 6px rgb(0 0 0 / 5%);
    text-align: center;
    gap: ${props => props.theme.spacing.large};
`


const OverMaxPassengersNumberText = styled(ExternalLinkComponent)`
    display: inline;
    color: ${props => props.theme.colors.medium};
`;

const ChildTravelConditionsTextBox = styled(ExternalLinkComponent)`
    color: ${props => props.theme.colors.medium};
`

const PassengerSelectPageFooter: React.FC = observer(() => {
    const services = useServices();
    const onStartBookingClick = async () => {
        await services.flightSearch.startBooking();
    }
    return (
        <PrimaryButton className={'tst-search-continue'} onClick={onStartBookingClick}>
            {services.language.translate('Continue')}
        </PrimaryButton>

    )
})


export const FlightSearchPassengersSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const passengerTypesSelector = services.flightSearch.searchController.passengers.getAll();

    const title = <FlightSearchTitleComponent icon={<PersonGroupIcon size={1.4}/>} title={services.language.translate('Passengers')}/>


    return (
        <FlightSearchPage title={title}
                          renderFooter={() => <PassengerSelectPageFooter/>}>
            <PageContentBox>
                <PassengerTypeSubHeaderBox>
                    {services.language.translate('Are you flying with someone else?')}
                </PassengerTypeSubHeaderBox>
                {passengerTypesSelector.map((pSelector: IPassengerTypeSelector) => <PassengerTypeSelectorComponent key={pSelector.description} passengerType={pSelector}/>)}
                <PassengerTypeSubHeaderBox>
                    <OverMaxPassengersNumberText innerHtml={services.externalLinks.groupBooking}/>
                    <ChildTravelConditionsTextBox innerHtml={services.externalLinks.childTravelConditions}/>
                </PassengerTypeSubHeaderBox>
            </PageContentBox>

        </FlightSearchPage>
    );
})
