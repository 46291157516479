import React from 'react';
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {NullableStation} from "../../../../../services/stations/station.service.interface";
import styled from 'styled-components';
import { PlaneDepartLineIcon } from '../../../../../assets/svg/plane-depart-line.icon';
import { PlainLandingLineIcon } from '../../../../../assets/svg/plane-landing-line';
import { SwapIcon } from '../../../../../assets/svg/swap.icon';
import {MediumLabelBox} from "../../../../../basic-components/label/medium-label.box";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {VerticalLine} from "../../../../../basic-components/spacing/vertical-line";
import {searchMaskIconSpacing} from "./search-mask-hybrid-css-constants";

const DEPARTURE_CLASS_NAME = 'departure';
const DESTINATION_CLASS_NAME = 'destination';

const StationsContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: row;
    padding: 0;
`

const StationContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    
    &.${DEPARTURE_CLASS_NAME}{
        align-items: flex-start;
        text-align: left;
    }

    &.${DESTINATION_CLASS_NAME} {
        align-items: flex-end;
        text-align: right;
    }
`

const StationNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
`

const StationNameSelectedBox = styled(StationNameBox)`
    font-weight: ${props => props.theme.fontWeight.bold};
`

const CountryNameBox = styled(MediumLabelBox)`
    font-size: ${props => props.theme.fontSize.small};
    margin: 0;
`

const StationWithIconBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
`

const PlaneDepartingIconBox = styled.div`
    padding-right: ${props => searchMaskIconSpacing(props.theme)};
`

const PlaneLandingIconBox = styled.div`
    padding-left: ${props => searchMaskIconSpacing(props.theme)};
`

const StationsSeparatorBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
`

const VerticalLineBox = styled(VerticalLine)`
    flex-basis: 50%;
`

const StationsSwapIconContainerBox = styled.div`
`

const StationsSeparatorComponent: React.FC = observer(() => {
    const services = useServices();

    const onClick = () => {
        services.flightSearch.switchStations();
    }
    return (
        <StationsSeparatorBox>
            <VerticalLineBox/>
            <StationsSwapIconContainerBox onClick={onClick}>
                <SwapIcon/>
            </StationsSwapIconContainerBox>
            <VerticalLineBox/>
        </StationsSeparatorBox>
    )
});

interface StationProps {
    direction: string;
    station: NullableStation;
    onClick: () => void;
    className: string;
}

const StationPlaceholder: React.FC<Omit<StationProps, 'station'>> = observer((props) => {
    const services = useServices();

    return(
        <StationContainerBox className={props.className} onClick={props.onClick}>
            <StationNameBox>
                {props.direction}
            </StationNameBox>
            <CountryNameBox>
                {services.language.translate('City, Airport')}
            </CountryNameBox>
        </StationContainerBox>
    );
});

const StationComponent: React.FC<StationProps> = observer((props) => {
    if(props.station) {
        return (
            <StationContainerBox className={props.className} onClick={props.onClick}>
                <StationNameSelectedBox>
                    {props.station.stationName}
                </StationNameSelectedBox>
                <CountryNameBox>
                    {props.station.countryName}
                </CountryNameBox>
            </StationContainerBox>
        );
    }
    return (
        <StationPlaceholder direction={props.direction}
                            onClick={props.onClick}
                            className={props.className}/>
    );
});

const OriginStationComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <StationWithIconBox onClick={() => services.navigator.routes.flightSearch.originStation.activate()}
                            className={'tst-origin'}>

            <PlaneDepartingIconBox>
                <PlaneDepartLineIcon/>
            </PlaneDepartingIconBox>

            <StationComponent direction={services.language.translate('From')}
                            station={services.flightSearch.searchController.departureOrigin}
                            onClick={() => services.navigator.routes.flightSearch.originStation.activate()}
                            className={DEPARTURE_CLASS_NAME}/>
        </StationWithIconBox>
    )
});

const DestinationStationComponent: React.FC = observer(() => {
    const services = useServices();

    return (
        <StationWithIconBox onClick={() => services.navigator.routes.flightSearch.destinationStation.activate()} className={'tst-destination'}>
            <StationComponent direction={services.language.translate('To')}
                            station={services.flightSearch.searchController.departureDestination}
                            onClick={() => services.navigator.routes.flightSearch.destinationStation.activate()}
                            className={DESTINATION_CLASS_NAME}/>
            <PlaneLandingIconBox>
                <PlainLandingLineIcon/>
            </PlaneLandingIconBox>
        </StationWithIconBox>
    );
});

export const SearchMaskStationsHybridComponent: React.FC = observer(() => {
    return (
        <StationsContainerBox>
            <OriginStationComponent/>
            <StationsSeparatorComponent/>
            <DestinationStationComponent/>
        </StationsContainerBox>
    );
});
