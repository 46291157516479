import React from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../hooks/use-services.hook";
import {SearchBookingBasePage} from "./search-booking-base.page";

export const ManageMyBookingSearchPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <SearchBookingBasePage title={services.language.translate('Manage your booking')}
                               retrieveBookingButtonText={services.language.translate('Find my booking')}
                               onRetrieveBookingButtonClick={async (searchParams) => {
                                   await searchParams.startManageMyBooking();
                               }}/>
    );

});
