import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../../../services/dialog/dialog.service.interface";
import {SsrsSelectionDialogComponent} from "../../../common-components/ssrs/ssrs-selection-dialog.component";
import {ExtrasSectionSmallScreenComponent} from "./extras-section.small-screen.component";
import {ExtrasSsrsSectionPurchaseSummarySmallScreenComponent} from "./summary/extras-ssrs-section-purchase-summary.small-screen.component";
import {IBookingSsrsAggregatorViewModel} from "../../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {NullableString} from "../../../../../types/nullable-types";
import {
    IJourneyCustomSsrsEditorRenderer,
    IPassengerCustomSsrEditorRenderer
} from "../../../common-components/ssrs/ssrs-selection.component.props";

interface ExtrasSsrsSectionSmallScreenComponentProps {
    ssrsAggregator: IBookingSsrsAggregatorViewModel;
    sectionTitle: string | React.ReactElement;
    sectionDetails?: NullableString | React.ReactElement;
    dialogTitle?: string | React.ReactElement;
    renderIcon: () => React.ReactElement | null;
    renderTopBanner?: () => React.ReactElement | NullableString;
    journeyCustomSsrsEditorRenderer?: IJourneyCustomSsrsEditorRenderer
    passengerCustomSsrEditorRenderer?: IPassengerCustomSsrEditorRenderer;
    disclaimer?: string | React.ReactElement;
}
export const ExtrasSsrsSectionSmallScreenComponent: React.FC<ExtrasSsrsSectionSmallScreenComponentProps> = observer((props) => {
    const ssrsAggregator = props.ssrsAggregator;

    const dialogTitle = props.dialogTitle ?? props.sectionTitle;

    const renderSelectionDialog = (dialogHandler: IDialogConfirmationHandler) => {

        return (
            <SsrsSelectionDialogComponent title={dialogTitle}
                                          dialogHandler={dialogHandler}
                                          ssrsAggregator={ssrsAggregator}
                                          disclaimer={props.disclaimer}
                                          journeyCustomSsrsEditorRenderer={props.journeyCustomSsrsEditorRenderer}
                                          passengerCustomSsrEditorRenderer={props.passengerCustomSsrEditorRenderer}
            />
        );
    }

    const renderTopBanner = () => {
        if(props.renderTopBanner) {
            return props.renderTopBanner();
        }

        return null;
    }

    return (
        <ExtrasSectionSmallScreenComponent sectionTitle={props.sectionTitle}
                                           sectionDetails={props.sectionDetails}
                                           topBanner={renderTopBanner()}
                                           minDiscountedPrice={ssrsAggregator.canBeModified ? ssrsAggregator.discountedMinPrice : null}
                                           minStandardPrice={ssrsAggregator.canBeModified ? ssrsAggregator.standardMinPrice : null}
                                           hasPurchases={ssrsAggregator.hasPurchases}
                                           showButton={ssrsAggregator.canBeModified}
                                           modificationBlockingReason={ssrsAggregator.modificationBlockingReason}
                                           renderIcon={props.renderIcon}
                                           renderDialog={renderSelectionDialog}
                                           renderPurchaseSummary={() => <ExtrasSsrsSectionPurchaseSummarySmallScreenComponent ssrsAggregator={ssrsAggregator}/>}
        />
    );
});
