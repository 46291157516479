import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import styled, {css} from "styled-components";
import {IBookingInsuranceViewModel} from "../../../../services/booking/models/insurance/booking-insurance-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {CheckboxComponent} from "../../../../basic-components/checkbox/checkbox.component";

const InsuranceRemovalBox = styled.div<{isSelected: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${props => props.theme.spacing.medium};
    padding: ${props => props.theme.spacing.medium};
    border-radius: ${props => props.theme.border.defaultRadius};

  ${
          props => props.isSelected
                  ? css`
              border: solid 2px ${props => props.theme.colors.success};
            `
                  : css`
              border: none;
            `
  }
`


const RemoveInsuranceCheckBox = styled(CheckboxComponent)`
    font-size: ${props => props.theme.fontSize.medium};
`
export const InsuranceRemovalComponent: React.FC<{insurance: IBookingInsuranceViewModel}> = observer((props) => {
    const services = useServices();
    const [rerenderComponent, setRerenderComponent] = useState(false);
    const onClickHandler = async () => {
        await props.insurance.removeInsurance();
    }

    const isSelected = (props.insurance.hasSelectedInsurance === false);

    return (
        <InsuranceRemovalBox onClick={onClickHandler} isSelected={isSelected}>
            <RemoveInsuranceCheckBox checked={isSelected}
                                     className={'tst-remove-insurance'}
                                     label={services.language.translate('No, thanks. I will take the risk.')}
                                     labelPlacement={'end'}
                                     onChange={() => setRerenderComponent(!rerenderComponent) /*to force component to rerender*/} />
        </InsuranceRemovalBox>
    );
});
