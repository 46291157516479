import React, {useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IonIcon, IonPopover} from "@ionic/react";
import {informationCircle, informationCircleOutline, close, closeOutline} from "ionicons/icons";
import {ISsrType} from "../../../../services/ssr-types/ssr-types.service.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {NullableString} from "../../../../types/nullable-types";
import {CabinBagsDisclaimerForAtrAircraftComponent} from "./cabin-bags-disclaimer-for-atr-aircraft.component";

const BagsSectionTitleContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: ${props => props.theme.spacing.small};
`

const BagsSectionTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.primary};
    text-align: left;
    flex-grow: 1;
    line-height: 1;
`

const InfoIconBox = styled(IonIcon)`
  min-width: 25px;
  min-height: 25px;
  margin-left: ${props => props.theme.spacing.small};
  cursor: pointer;
`

const SsrsInfoPopoverBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
`

const SsrInfoBox = styled.div`
    display: flex;
    font-size: ${props => props.theme.fontSize.medium};
    margin-bottom: ${props => props.theme.spacing.large}; 
    align-items: center;
    line-height: 1;
    flex-flow: row wrap;

    &:after { 
        content:'';
        width: 100%;
        height: 1px;
        margin-top: ${props => props.theme.spacing.large};
        background-color: rgba(${props => props.theme.colors.mediumRgb}, 0.75);
    }

    &:last-of-type {
        margin-bottom: 0; 

        &:after {
            display: none;
        }
    }
`

const SsrIconBox = styled.div`
    margin-right: ${props => props.theme.spacing.medium};
`

const SsrNameAndDescriptionBox = styled.div`
    display: flex;
    flex-direction: column;
`

const SsrNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.primary};
    margin-bottom: ${props => props.theme.spacing.small};
`

const SsrDescriptionBox = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.small};
`

const SsrInfoComponent: React.FC<{ssrType: ISsrType}> = observer((props) => {

    return (
        <SsrInfoBox>
            <SsrIconBox>
                {props.ssrType.largeIcon}
            </SsrIconBox>
            <SsrNameAndDescriptionBox>
                <SsrNameBox>{props.ssrType.name}</SsrNameBox>
                <SsrDescriptionBox>{props.ssrType.details}</SsrDescriptionBox>
            </SsrNameAndDescriptionBox>
        </SsrInfoBox>
    )
});

const PopoverHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    padding: 3px 5px 3px 10px;
    background-color: ${props => props.theme.colors.secondaryTint};
    color: ${props => props.theme.colors.secondaryContrast};
`

const PopoverHeaderTextBox = styled.div`
    text-align: left;
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const ClosePopoverIconBox = styled(IonIcon)`
    width: 25px;
    height: 25px;
    cursor: pointer;
`

const PopoverContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px;
  width: 100%;
`

const PromotionalMessageBox = styled.div`
    background-color: ${props => props.theme.colors.warning};
    color: ${props => props.theme.colors.warningContrast};
    vertical-align: center;
    height: 100%;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
    border-radius: ${props => props.theme.border.defaultRadius};
    text-align: center;
`

interface SsrsInfoPopoverComponentProps {
    onClose: () => void;
    ssrsTypes: ISsrType[];
}

const SsrsInfoPopoverContentComponent: React.FC<SsrsInfoPopoverComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <SsrsInfoPopoverBox onClick={props.onClose}>
            <PopoverHeaderBox>
                <PopoverHeaderTextBox>
                    {services.language.translate('Baggage info')}
                </PopoverHeaderTextBox>
                <ClosePopoverIconBox ios={closeOutline} md={close} onClick={props.onClose}/>
            </PopoverHeaderBox>
            <PopoverContentBox>
                {props.ssrsTypes.map(ssrType => <SsrInfoComponent key={ssrType.ssrCode} ssrType={ssrType}/>)}
            </PopoverContentBox>

        </SsrsInfoPopoverBox>
    );
});




interface BagsSectionTitleComponentProps {
    title: string;
    ssrsTypes: ISsrType[];
    promotionalMessage: NullableString;
    showDisclaimer: boolean;
}

export const BagsSectionTitleComponent: React.FC<BagsSectionTitleComponentProps> = observer((props) => {
    const [popoverState, setPopoverState] = useState({
        showPopover: false,
        event: undefined
    });

    return (
        <BagsSectionTitleContainerBox>
            <IonPopover event={popoverState.event}
                        className={'bags-info-popover'}
                        isOpen={popoverState.showPopover}
                        onDidDismiss={() => setPopoverState({showPopover: false, event: undefined})}>
                <SsrsInfoPopoverContentComponent onClose={() => setPopoverState({showPopover: false, event: undefined})} ssrsTypes={props.ssrsTypes}/>
            </IonPopover>

            <BagsSectionTitleBox>
                <span>
                    {props.title}
                </span>
                <InfoIconBox ios={informationCircleOutline} md={informationCircle} onClick={(e: any) => {
                    e.persist();
                    setPopoverState({showPopover: true, event: e})
                }}/>
            </BagsSectionTitleBox>
            {props.showDisclaimer && <CabinBagsDisclaimerForAtrAircraftComponent/>}
            {
                props.promotionalMessage && (
                    <PromotionalMessageBox>
                        {props.promotionalMessage}
                    </PromotionalMessageBox>
                )
            }


        </BagsSectionTitleContainerBox>

    )
});
