import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {useServices} from "../../../../../../hooks/use-services.hook";

const SearchMaskTripTypeContainerBox = styled.div<{isMobileView: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 38px;
`

const TripTypeButton = styled.button<{isSelected: boolean}>`
    width: 50%;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    border: 1px solid ${props => props.theme.colors.mediumTint};
    font-size: ${props => props.theme.fontSize.medium};
    cursor: pointer;
    ${
        props => props.isSelected 
                 ? css`
                    background-color: ${props => props.theme.colors.primary};
                    color: ${props => props.theme.colors.primaryContrast};
                 `
                : css`
                    background-color: ${props => props.theme.colors.mediumTint};
                    color: ${props => props.theme.colors.primaryContrast};
                `
    }
`

const RoundTripButton = styled(TripTypeButton)`
    border-radius: ${props => props.theme.border.defaultRadius} 0 0 ${props => props.theme.border.defaultRadius};
`

const OneWayButton = styled(TripTypeButton)`
    border-radius: 0 ${props => props.theme.border.defaultRadius} ${props => props.theme.border.defaultRadius} 0;
`

export const SearchMaskTripTypeWebappComponent: React.FC = observer(() => {
    const services = useServices();
    const onRoundTripClick = () => {
        services.flightSearch.searchController.isOneWayDepartureTrip = false;
    }

    const onOneWayClick = () => {
        services.flightSearch.searchController.isOneWayDepartureTrip = true;
    }

    return (
       <SearchMaskTripTypeContainerBox isMobileView={services.layout.isInSmallScreenView}>
          <RoundTripButton isSelected={!services.flightSearch.searchController.isOneWay} onClick={onRoundTripClick}>
              {services.language.translate('Round Trip')}
          </RoundTripButton>
           <OneWayButton isSelected={services.flightSearch.searchController.isOneWay} onClick={onOneWayClick}>
               {services.language.translate('One Way')}
           </OneWayButton>
       </SearchMaskTripTypeContainerBox>
    );
});
