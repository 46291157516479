import {IDotRezBookingInfant} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {InfantFeeSnapshotModel} from "../fees/infant-fee-snapshot.model";
import {BookingSnapshotModel} from "../booking/booking-snapshot.model";

export class InfantSnapshotModel {
    constructor(private readonly infantDotRezData: IDotRezBookingInfant,
                private readonly booking: BookingSnapshotModel) {
    }

    getFullName(): string {
        if(!this.infantDotRezData.name) {
            return '';
        }

        return `${this.infantDotRezData.name.first} ${this.infantDotRezData.name.last}`;
    }

    get fees(): InfantFeeSnapshotModel[] {
        return this.infantDotRezData.fees.map(f => new InfantFeeSnapshotModel(f, this.booking));
    }
}
