import {IMaturePassengerViewModel} from "./mature-passenger-view-model.interface";
import {Check} from "../../../../types/type-checking";
import {IPassengerViewModel} from "./passenger-view-model.interface";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";

export abstract class PassengersListViewModel<TPassenger extends IMaturePassengerViewModel> extends Array<TPassenger> {
    constructor(passengers: TPassenger[] = []) {
        super();
        if(Check.isArray(passengers)) {
            passengers.forEach(p => this.push(p));
        }
    }

    abstract saveTravelDocuments(): Promise<void>;
    abstract savePassengersDetails(): Promise<ValidationResultEnum>;
    abstract validateDuplicatedPassengersNames(): ValidationResultEnum;

    /**
     * Returns a list of passengers including the infants
     */
    getAllPersonsInTheBooking(): IPassengerViewModel[] {
        const passengers: IPassengerViewModel[] = this.map(p => p);
        this.forEach(p => {
            if(p.infant) {
                passengers.push(p.infant);
            }
        });

        return passengers;
    }



}
