import React, {useRef, useEffect} from 'react';
import { observer } from "mobx-react";
import bwipjs from 'bwip-js';

interface AztecBarcodeComponentProps {
    barcode: string;
}

export const AztecBarcodeComponent: React.FC<AztecBarcodeComponentProps> = observer((props) => {
    const imgRef = useRef<HTMLImageElement | null>(null);
    const canvasRef =  useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        if (!imgRef.current) {
            return;
        }
        if (!canvasRef.current) {
            return;
        }

        const canvas = bwipjs.toCanvas(canvasRef.current, {
            bcid: 'azteccode',       // Barcode type
            text: props.barcode,    // Text to encode
            scale: 3,               // 3x scaling factor
            height: 25,              // Bar height, in millimeters
            includetext: false,            // Show human-readable text
            textxalign: 'center',        // Always good to set this
        });
        imgRef.current.src = canvas.toDataURL('image/png');
    });


    return (
        <>
            <canvas ref={canvasRef} hidden={true}/>
            <img src="" ref={imgRef} alt="barcode"/>
        </>
    )
});
