import styled, {css} from "styled-components";
import React, {PropsWithChildren, useState} from "react";
import {useServices} from "../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {AirlineTheme} from "../../services/theme/interfaces/airline.theme";

export type ButtonFontSize = "small" | "medium" | "large" | "extraLarge";

export interface ButtonColors {
    background: string;
    color: string;
    border: string;
    backgroundActivated: string;
    colorActivated: string;
    borderActivated: string;
}

interface ButtonBaseBoxProps extends PropsWithChildren {
    fontSize: string;
    horizontalPadding: string;
    verticalPadding: string;
    fullWidth: boolean;
    colors: ButtonColors;
    isPressed: boolean;
    disabled?: boolean;
}

function computeButtonFontSize(buttonFontSize: ButtonFontSize | undefined, theme: AirlineTheme): string {
    switch (buttonFontSize) {
        case "small":
            return theme.fontSize.small;
        case "medium":
            return theme.fontSize.medium;
        case "extraLarge":
            return theme.fontSize.xxLarge;
        default:
            return theme.fontSize.large;
    }
}

function computeButtonPadding(buttonFontSize: ButtonFontSize | undefined, theme: AirlineTheme): {horizontalPadding: string; verticalPadding: string} {
    const spacing = theme.spacing;
    switch (buttonFontSize) {
        case "small":
            return {
                horizontalPadding: spacing.medium,
                verticalPadding: spacing.small
            };
        case "medium":
            return {
                horizontalPadding: spacing.medium,
                verticalPadding: spacing.medium
            };
        default:
            return {
                horizontalPadding: spacing.medium,
                verticalPadding: spacing.large
            };
    }
}

function computeButtonColors(props: ButtonBaseBoxProps) {
    if(props.disabled) {
        return css`
                      background-color: ${props.colors.background};
                      color: ${props.colors.color};
                      border-color: ${props.colors.border};
                      opacity: 0.5;
                  `;
    }

    if(props.isPressed) {
        return  css`
                      background-color: ${props.colors.backgroundActivated};
                      color: ${props.colors.colorActivated};
                      border-color: ${props.colors.borderActivated};
                    `;
    }

    return css`
              background-color: ${props.colors.background};
              color: ${props.colors.color};
              border-color: ${props.colors.border};
            `
}


const ButtonBaseBox = styled.button<ButtonBaseBoxProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: ${props => `${props.verticalPadding} ${props.horizontalPadding} ${props.verticalPadding} ${props.horizontalPadding}`};
    border-width: 2px;
    border-style: solid;
    border-radius: ${props => props.theme.border.defaultRadius};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    height: auto;
    text-transform: none;
    white-space: normal;
    cursor: pointer;
    ${
          props => props.fullWidth
                  ? css`
                      width: 100%;
                    `
                  : ''
    };
    ${ props => computeButtonColors(props)}
`

export interface ButtonBaseProps extends PropsWithChildren {
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    fontSize?: ButtonFontSize;
    fullWidth?: boolean;
    colors: ButtonColors;
}

export interface ConcreteButtonProps extends Omit<ButtonBaseProps, 'colors'> {

}

export const ButtonBase: React.FC<ButtonBaseProps> = observer((props) => {
    const services = useServices();
    const [isPressed, setIsPressed] = useState(false);
    const theme = services.theme.currentTheme;
    const fullWidth = props.fullWidth ?? true;

    const onTouchStart = () => {
        setIsPressed(true);
    }

    const onTouchEnd = () => {
        setIsPressed(false)
    }

    const onClick = () => {
        if(props.disabled) {
            return;
        }

        if(props.onClick) {
            props.onClick();
        }
    }

    return (
        <ButtonBaseBox fullWidth={fullWidth}
                       disabled={props.disabled}
                       className={props.className}
                       fontSize={computeButtonFontSize(props.fontSize, theme)}
                       {...computeButtonPadding(props.fontSize, theme)}
                       onClick={onClick}
                       onMouseDown={onTouchStart}
                       onTouchStart={onTouchStart}
                       onTouchEnd={onTouchEnd}
                       onMouseUp={onTouchEnd}
                       isPressed={isPressed}
                       colors={props.colors}>
            {props.children}
        </ButtonBaseBox>
    )
})
