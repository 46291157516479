import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import {PassengerTypeDropDownComponent} from "./passenger-type-drop-down.component";
import {IDropDownController} from "../../../../../../../components/forms/drop-down/models/drop-down-controller.interface";
import {PrimaryButton} from "../../../../../../../basic-components/buttons/primary-button";

const DropDownContentBox = styled.div<{isSmallScreenView: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${
        props => props.isSmallScreenView 
                ? css`
                  padding: 0;
                `
                : css`
                  padding: ${props => props.theme.spacing.large};
                `
    }   
`


const MaximumPassengersLimitReachedMessage = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    margin: ${props => props.theme.spacing.medium} 0;
    color: ${props => props.theme.colors.danger};
    line-height: 1;
    
`


interface PassengersTypesDropDownContentComponentProps {
    dropDownController: IDropDownController;
}

export const PassengersTypesDropDownContentComponent: React.FC<PassengersTypesDropDownContentComponentProps> = observer((props) => {
    const services = useServices();
    const passengerTypes = services.flightSearch.searchController.passengers;

    const renderErrorMessage = () => {
        if(passengerTypes.passengersMaximumLimitReachedErrorMessage) {
            return (
                <MaximumPassengersLimitReachedMessage>
                    {passengerTypes.passengersMaximumLimitReachedErrorMessage}
                </MaximumPassengersLimitReachedMessage>
            )
        }

        return null;
    }

    const onCloseClick = () => {
        props.dropDownController.closeDropDown();
    }


    return (
        <DropDownContentBox isSmallScreenView={services.layout.isInSmallScreenView}>
            {passengerTypes.getAll().map(pt => <PassengerTypeDropDownComponent key={pt.description} passengerTypeSelector={pt}/>)}
            {renderErrorMessage()}
            <PrimaryButton fullWidth={false} onClick={onCloseClick}>
                {services.language.translate('Done')}
            </PrimaryButton>
        </DropDownContentBox>
    );
});
