import { ISvgIconProps, SvgIcon } from "./svg-icon.component";


export const RecicleBinIcon:React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.6} {...props} viewBox="0 0 15.964 16.401" ratio={15.964/16.401}>
            <defs><clipPath id="a"><rect width="15.964" height="16.401" /></clipPath></defs>
            <g fill={props.color? props.color: "currentColor"} clipPath="url(#a)"><path d="M1.334,4.25H0V3.356a.866.866,0,0,1,.883-.888.89.89,0,0,1,.89-.89h12.4a.891.891,0,0,1,.891.89.875.875,0,0,1,.9.888V4.25H1.334ZM11.523,1.334V.89A.892.892,0,0,0,10.632,0H5.315a.893.893,0,0,0-.893.89v.445Zm3.55,3.541-1.33,10.636a.892.892,0,0,1-.891.89H3.1a.89.89,0,0,1-.89-.89L.883,4.875ZM5.86,13.389,5.339,7.441a.887.887,0,0,0-.863-.793.715.715,0,0,0-.723.793l.521,5.948a.883.883,0,0,0,.861.793.719.719,0,0,0,.725-.793M8.772,7.441a.794.794,0,0,0-1.588,0v5.948a.794.794,0,0,0,1.588,0Zm3.432,0a.718.718,0,0,0-.725-.793.887.887,0,0,0-.863.793L10.1,13.389a.719.719,0,0,0,.725.793.883.883,0,0,0,.861-.793Z" transform="translate(0 0)" />
            </g>
        </SvgIcon>
    )
}
