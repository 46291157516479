import {FareDiscountTypeBase} from "./fare-discount-type-base";
import {IFareDiscountType} from "./fare-discount-type.interface";
import {BogoFareDiscount} from "./bogo-fare-discount";
import {ServiceChargeTypeDotRezApiEnum} from "../../../dot-rez-api/data-contracts/enums/service-charge-type.enum";
import {IAvailableFareToDiscountAdapter} from "../fares/available-fare-to-discount-adater.interface";
import {DiscountCodeEnum} from "./discount-code-enum";
import {NullableString} from "../../../../types/nullable-types";

export abstract class PercentageFareDiscount extends FareDiscountTypeBase implements IFareDiscountType {

    constructor(fareAdapter: IAvailableFareToDiscountAdapter,
                private _bogoDiscount: BogoFareDiscount) {
        super(fareAdapter)
    }

    abstract getExpectedServiceCharge(): ServiceChargeTypeDotRezApiEnum;
    get isApplicable(): boolean {
        return this._hasServiceCharge(this.getExpectedServiceCharge())
                && this.passengerDotRezFares.all(f => f.fareDiscountCode !== DiscountCodeEnum.BlueBenefits)
                && !this._bogoDiscount.isApplicable
                && this.discountedPrice.amount < this.totalPrice.amount;
    }

    get discountTypeName(): string {
        if(this.fareAdapter.isPriceReduced) {
            /*
             On manage my booking flow we show the fare difference between the initial journey and the new one
             so there is no point to show Discount label above the fare.
          */
            return this.services.language.translate('Standard');
        }
        return this.services.language.translate('Discount');
    }


    get promotionalMessage(): NullableString {
        return this.services.language.translationFor('{percentage} off PROMO PRICE').withParams({percentage: this.percentage});
    }

    private get percentage(): string {
        return Math.round((this.totalPrice.amount - this.discountedPrice.amount)/this.totalPrice.amount * 100) + '%';
    }
}
