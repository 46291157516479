import {IFeeModel} from "../base-models/fees/fee-model.interface";
import {FeesAggregatorModel} from "../base-models/fees/fees-aggregator.model";

export function mergeFeesCollections(collections: Array<IFeeModel[]>): IFeeModel[] {
    const feesGroups: Record<string, IFeeModel[]> = {};
    for(let collection of collections) {
        for(let fee of collection) {
            if(!feesGroups[fee.feeGroupKey]) {
                feesGroups[fee.feeGroupKey] = [];
            }

            feesGroups[fee.feeGroupKey].push(fee);
        }
    }

    return Object.values(feesGroups)
                  .map(group => new FeesAggregatorModel(group))
                  .sort((item1, item2) => {
                      const x = item1.preferredOrderInLists - item2.preferredOrderInLists;
                      if(x !== 0) {
                          return x;
                      }

                      return item1.priceToDisplay.amount - item2.priceToDisplay.amount;
                  });
}
