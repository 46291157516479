import {Price} from "../../../../currency/price";

export abstract class ShoppingCartFeeBaseModel {
    abstract get initialPrice(): Price | null;
    abstract get currentPrice(): Price;
    abstract get quantity(): number;

    public get isCommitted(): boolean {
        return Boolean(this.initialPrice);
    }

    get priceToDisplay(): Price {
        if(this.initialPrice) {
            return this.currentPrice.subtract(this.initialPrice);
        } else {
            return this.currentPrice;
        }
    }

    get totalToDisplay(): Price {
        return this.priceToDisplay.multiply(this.quantity);
    }

    protected _isPurchasedOnCurrentSession(): boolean {
        if(!this.initialPrice) {
            return true;
        }

        return this.initialPrice.amount !== this.currentPrice.amount;
    }

    get isPurchasedOnCurrentSession(): boolean {
        return this._isPurchasedOnCurrentSession();
    }
}
