import React from "react";
import styled from "styled-components";

export const TitleContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const TitleTextBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-left: 12px;
`

interface FlightSearchTitleComponentProps {
    title: string;
    icon?: React.ReactNode;
}
export const FlightSearchTitleComponent: React.FC<FlightSearchTitleComponentProps> = (props) => {
    return (
        <TitleContainerBox>
            {props.icon}
            <TitleTextBox>
                {props.title}
            </TitleTextBox>
        </TitleContainerBox>
    );
}
