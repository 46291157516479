import { PersonTravelDocumentModel } from "../person/person-travel-document.model";
import { IDotRezPersonTravelDocument } from "../../../dot-rez-api/data-contracts/user/person.data-contracts";
import { AuthorizedUserProfileModel } from "../authorized-user/profile/authorized-user-profile.model";

export class UserTravelDocumentModel extends PersonTravelDocumentModel<AuthorizedUserProfileModel> {
    protected async _addTravelDocument(): Promise<IDotRezPersonTravelDocument> {
        const session = await this.owner.getSession();
        const { lastName, firstName, title,  ...body } = this._getUpdatedTravelDocumentFields();
        return await session.addTravelDocument({
            default: true,
            name: {
                first: firstName,
                last: lastName,
                title: title
            },
            ...body
        });
    }

    protected async _updateTravelDocument(): Promise<IDotRezPersonTravelDocument> {
        const session = await this.owner.getSession();
        const { lastName, firstName, title,  ...body } = this._getUpdatedTravelDocumentFields();
        return await session.updateTravelDocument({
            default: this.documentData.default,
            travelDocumentKey: this.travelDocumentKey,
            name: {
                first: firstName,
                last: lastName,
                title: title
            },
            ...body
        });
    }

    protected async _deleteTravelDocument(): Promise<void> {
        const session = await this.owner.getSession();
        return await session.deleteTravelDocument(this.travelDocumentKey);
    }

    protected _getUniqueDocumentErrorMessage(): string {
        return this.services.language.translationFor('You already have a {documentType} issued by {country}').withParams({
            documentType: this.services.travelDocumentType.tryGetDocumentTypeName(this.fields.documentTypeCode.value) || '',
            country: this.services.country.tryGetCountry(this.fields.issuedByCode.value || '')?.name || ''
        });
    }

    async addTravelDocument(): Promise<void> {
        this._addTravelDocument();
    }
}
