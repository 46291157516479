import {DateSelectionStrategyBase} from "./date-selection-strategy-base";
import {IFlightsScheduleViewModel} from "../../flights-schedule/flights-schedule-view-model.interface";
import {NullableDate} from "../../../../types/nullable-types";
import {ILowFareResult} from "../../../low-fare/low-fare-readers/low-fare-reader.interface";

export class DepartureDateSelectionStrategy extends DateSelectionStrategyBase{

    protected get flightSchedule(): IFlightsScheduleViewModel {
        return this.flightSearchController.departureFlightSchedule;
    }

    getLowFare(date: Date): ILowFareResult {
        return this.lowFareReader.getDepartureLowFare(date);
    }

    getCurrentDate(): NullableDate {
        return this.flightSearchController.departureDate;
    }

    setCurrentDate(date: Date): void {
        const returnTime = this.flightSearchController.returnDate?.getTime() || 0;
        if(date.getTime() > returnTime) {
            this.flightSearchController.returnDate = null;
        }

        this.flightSearchController.departureDate = date;
    }
}
