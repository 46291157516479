import React from 'react';
import {observer} from "mobx-react-lite";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import {XCloseSmallIcon} from "../../../assets/svg/x-close-small,icon";

const CloseButtonBox = styled.div`
    cursor: pointer;
`

interface DialogCloseButtonComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    onClick?: () => void;
    isHidden: boolean;
}
export const DialogCloseButtonComponent: React.FC<DialogCloseButtonComponentProps> = observer((props) => {

    if(props.isHidden) {
        return null;
    }

    const onClickHandler = () => {
        if(props.onClick) {
            props.onClick();
        } else {
            props.dialogHandler.onBackButtonClick();
        }
    }

    return (
        <CloseButtonBox onClick={onClickHandler}>
            <XCloseSmallIcon size={3.1}/>
        </CloseButtonBox>
    )
});
