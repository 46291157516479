import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const XCoverLogoIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={4.84} {...props} viewBox="0 0 88 33" ratio={88/33}>
            <path d="M14.9806 32.2665L11.3257 25.8164L14.807 19.3754C14.8433 19.3017 14.8622 19.2206 14.8622 19.1385C14.8622 19.0564 14.8433 18.9754 14.807 18.9016C14.7758 18.8303 14.7249 18.7692 14.6602 18.7257C14.5954 18.6821 14.5196 18.6578 14.4415 18.6557H9.87287C9.79723 18.6581 9.72358 18.6805 9.6594 18.7205C9.59521 18.7605 9.54277 18.8167 9.50737 18.8834L8.20074 21.3523L10.4302 25.3062C10.5271 25.4788 10.5779 25.6733 10.5779 25.8711C10.5779 26.0688 10.5271 26.2633 10.4302 26.4359L8.34693 30.3078L9.75409 32.7767C9.78915 32.841 9.84051 32.8951 9.90303 32.9334C9.96555 32.9718 10.0371 32.9931 10.1104 32.9954H14.67C14.7486 32.9909 14.8246 32.9657 14.8904 32.9225C14.9562 32.8793 15.0094 32.8196 15.0446 32.7494C15.0831 32.6747 15.1031 32.5919 15.1031 32.508C15.1031 32.424 15.0831 32.3412 15.0446 32.2665" fill="#FFE600"/>
<path d="M9.27899 25.6342L7.24138 22.0356L5.47787 18.8652C5.44131 18.8002 5.38834 18.7458 5.32425 18.7075C5.26014 18.6691 5.18711 18.6481 5.11236 18.6465H0.607669C0.529874 18.6498 0.454507 18.6745 0.389971 18.7179C0.325435 18.7613 0.274268 18.8218 0.242175 18.8925C0.203695 18.9672 0.183624 19.05 0.183624 19.1339C0.183624 19.2179 0.203695 19.3007 0.242175 19.3754L3.8971 25.8802L0.406647 32.2574C0.368224 32.3305 0.348153 32.4118 0.348153 32.4943C0.348153 32.5768 0.368224 32.6581 0.406647 32.7312C0.440018 32.8027 0.490162 32.8652 0.552844 32.9134C0.583169 32.9093 0.613893 32.9093 0.644217 32.9134C0.686763 32.9174 0.729594 32.9174 0.77214 32.9134H5.3408C5.41788 32.912 5.493 32.8891 5.55762 32.8472C5.62225 32.8053 5.67376 32.7462 5.70629 32.6765L7.36016 29.6427L9.27899 26.1535C9.32139 26.0767 9.34363 25.9906 9.34363 25.9029C9.34363 25.8153 9.32139 25.7292 9.27899 25.6524" fill="#FFE600"/>
<path d="M21.3853 25.6286L19.4601 22.4431C19.4417 22.4142 19.4316 22.3802 19.4309 22.3452C19.4303 22.3103 19.4391 22.2758 19.4565 22.2461C19.4738 22.2165 19.4989 22.1928 19.5286 22.1779C19.5584 22.1631 19.5916 22.1578 19.624 22.1626H21.0167C21.047 22.1652 21.0764 22.1749 21.1027 22.1911C21.1291 22.2073 21.1516 22.2295 21.1688 22.2561L22.2631 24.1263L23.3749 22.2561C23.3916 22.2288 23.4141 22.2061 23.4405 22.1899C23.4669 22.1736 23.4967 22.1643 23.527 22.1626H24.9197C25.0836 22.1626 25.1715 22.3122 25.0836 22.4431L23.1468 25.6286L25.148 28.9886C25.1673 29.0167 25.1786 29.0501 25.1804 29.0848C25.1823 29.1195 25.1748 29.154 25.1587 29.1843C25.1428 29.2145 25.1188 29.2392 25.09 29.2554C25.061 29.2715 25.0284 29.2785 24.996 29.2754H23.6851C23.6531 29.2737 23.6216 29.2645 23.5933 29.2483C23.5648 29.2321 23.5403 29.2094 23.5212 29.1819L22.2748 27.1309L21.0343 29.1819C21.0148 29.2091 20.9903 29.2316 20.962 29.2477C20.9337 29.2639 20.9025 29.2733 20.8704 29.2754H19.5479C19.3841 29.2754 19.2962 29.1195 19.3841 28.9886L21.3853 25.6286Z" fill="black"/>
<path d="M29.7647 22.176C30.178 22.1622 30.5899 22.236 30.9761 22.3931C31.3623 22.5503 31.7152 22.7876 32.0141 23.0912C32.0324 23.1088 32.047 23.1303 32.0572 23.1543C32.0671 23.1783 32.0723 23.2043 32.0723 23.2306C32.0723 23.2569 32.0671 23.2828 32.0572 23.3068C32.047 23.3309 32.0324 23.3524 32.0141 23.37L31.2889 24.17C31.2572 24.2015 31.2154 24.2191 31.172 24.2191C31.1285 24.2191 31.0866 24.2015 31.0549 24.17C30.7083 23.8448 30.2616 23.6658 29.7989 23.667C29.2947 23.667 28.8112 23.8796 28.4547 24.2581C28.0981 24.6366 27.8979 25.1499 27.8979 25.6851C27.8979 26.2204 28.0981 26.7338 28.4547 27.1122C28.8112 27.4907 29.2947 27.7034 29.7989 27.7034C30.2524 27.7012 30.6916 27.5344 31.0435 27.2306C31.0761 27.1987 31.119 27.1809 31.1634 27.1809C31.2078 27.1809 31.2507 27.1987 31.2832 27.2306L32.0141 28.0488C32.0439 28.0861 32.0601 28.1333 32.0601 28.1821C32.0601 28.231 32.0439 28.2782 32.0141 28.3155C31.4026 28.9435 30.5811 29.2879 29.7304 29.2731C29.279 29.2928 28.8286 29.2156 28.4062 29.0458C27.9837 28.8761 27.5979 28.6175 27.272 28.2855C26.9461 27.9535 26.6867 27.5549 26.5095 27.1138C26.3323 26.6726 26.2409 26.198 26.2409 25.7185C26.2409 25.2389 26.3323 24.7643 26.5095 24.3232C26.6867 23.8821 26.9461 23.4835 27.272 23.1515C27.5979 22.8195 27.9837 22.5608 28.4062 22.3911C28.8286 22.2214 29.279 22.1441 29.7304 22.1639L29.7647 22.176Z" fill="black"/>
<path d="M36.8316 22.1609C37.5134 22.1585 38.1805 22.365 38.7486 22.7542C39.3167 23.1433 39.7602 23.6978 40.023 24.3473C40.2857 24.9968 40.356 25.7121 40.2249 26.4029C40.0936 27.0936 39.7669 27.7287 39.2861 28.2277C38.8054 28.7266 38.192 29.0672 37.5235 29.2061C36.8552 29.345 36.1619 29.2762 35.5315 29.0082C34.9012 28.7402 34.3618 28.2852 33.982 27.7007C33.6021 27.1162 33.3987 26.4284 33.3976 25.7246C33.3961 25.2577 33.4838 24.795 33.6555 24.3631C33.8273 23.9312 34.0799 23.5385 34.3989 23.2075C34.7178 22.8765 35.0969 22.6137 35.5144 22.4341C35.9317 22.2545 36.3793 22.1617 36.8316 22.1609ZM36.8316 27.6882C37.2111 27.6906 37.5827 27.5765 37.8992 27.3603C38.2158 27.1441 38.4628 26.8357 38.6091 26.4741C38.7554 26.1126 38.7943 25.7144 38.7209 25.33C38.6473 24.9456 38.4649 24.5924 38.1965 24.3154C37.9283 24.0384 37.5861 23.8499 37.2137 23.7741C36.8416 23.6982 36.4557 23.7384 36.1057 23.8894C35.7555 24.0405 35.4567 24.2956 35.2473 24.6223C35.0379 24.9491 34.9274 25.3328 34.9297 25.7246C34.9329 26.2444 35.1342 26.742 35.4902 27.1096C35.8461 27.4771 36.3282 27.685 36.8316 27.6882Z" fill="black"/>
<path d="M41.3681 22.4201C41.3542 22.392 41.3479 22.3607 41.3497 22.3292C41.3516 22.2978 41.3615 22.2675 41.3788 22.2413C41.3958 22.2151 41.4196 22.194 41.4473 22.1802C41.4752 22.1663 41.5061 22.1603 41.537 22.1626H42.8453C42.8799 22.1639 42.9133 22.1749 42.9421 22.1944C42.9708 22.2138 42.9936 22.241 43.0082 22.2729L44.63 25.9523H44.6903L46.3123 22.2729C46.3278 22.2406 46.3516 22.2131 46.3814 22.1937C46.4112 22.1742 46.4457 22.1634 46.4811 22.1626H47.7835C47.815 22.158 47.847 22.1627 47.876 22.1759C47.9051 22.1891 47.9298 22.2104 47.9476 22.2373C47.9652 22.2643 47.9749 22.2957 47.9759 22.328C47.9766 22.3604 47.9685 22.3923 47.9523 22.4201L44.8653 29.1657C44.852 29.1985 44.8295 29.2265 44.8005 29.2462C44.7714 29.2659 44.7373 29.2763 44.7025 29.2761H44.6059C44.5703 29.2767 44.5351 29.2665 44.5051 29.2469C44.4751 29.2272 44.4513 29.199 44.4371 29.1657L41.3681 22.4201Z" fill="black"/>
<path d="M49.0361 22.3547C49.0376 22.3038 49.0581 22.2554 49.0933 22.2194C49.1285 22.1834 49.1759 22.1624 49.2257 22.1609H53.3527C53.4029 22.1609 53.451 22.1813 53.4866 22.2176C53.5222 22.254 53.5421 22.3033 53.5421 22.3547V23.4551C53.5421 23.5065 53.5222 23.5558 53.4866 23.5922C53.451 23.6285 53.4029 23.6489 53.3527 23.6489H50.5952V24.8994H52.8635C52.9133 24.901 52.9605 24.9219 52.9957 24.9579C53.0311 24.9939 53.0515 25.0423 53.053 25.0932V26.2062C53.053 26.2576 53.033 26.3069 52.9976 26.3432C52.962 26.3796 52.9137 26.4 52.8635 26.4H50.5952V27.7755H53.3527C53.4029 27.7755 53.451 27.7959 53.4866 27.8323C53.5222 27.8686 53.5421 27.9179 53.5421 27.9693V29.0823C53.5421 29.1337 53.5222 29.183 53.4866 29.2193C53.451 29.2557 53.4029 29.2761 53.3527 29.2761H49.2257C49.1759 29.2745 49.1285 29.2536 49.0933 29.2176C49.0581 29.1816 49.0376 29.1332 49.0361 29.0823V22.3547Z" fill="black"/>
<path d="M55.3976 22.3547C55.3976 22.3034 55.4175 22.2543 55.4527 22.218C55.4879 22.1818 55.5359 22.1614 55.5857 22.1614H58.5401C59.0469 22.1497 59.541 22.3258 59.9319 22.6576C60.3227 22.9894 60.5846 23.4546 60.6692 23.9683C60.7539 24.4821 60.6557 25.01 60.393 25.4555C60.1302 25.9011 59.72 26.2346 59.2377 26.395L60.5906 28.9764C60.6099 29.0064 60.6207 29.0415 60.6217 29.0775C60.6226 29.1135 60.6138 29.1491 60.596 29.1802C60.5784 29.2113 60.5527 29.2368 60.5216 29.2536C60.4907 29.2705 60.4557 29.2781 60.4207 29.2756H59.1044C59.0725 29.2745 59.0416 29.2654 59.0141 29.2491C58.9866 29.2328 58.9634 29.2098 58.9465 29.1821L57.6362 26.4886H56.9507V29.0636C56.9477 29.114 56.9271 29.1614 56.8924 29.197C56.8578 29.2327 56.8115 29.254 56.7626 29.2569H55.5857C55.5359 29.2569 55.4879 29.2366 55.4527 29.2003C55.4175 29.1641 55.3976 29.1149 55.3976 29.0636V22.3547ZM58.4309 25.1979C58.6332 25.1868 58.8236 25.0967 58.9635 24.9461C59.1033 24.7954 59.1819 24.5955 59.1832 24.3874C59.1816 24.1828 59.1018 23.9871 58.9611 23.8424C58.8203 23.6978 58.63 23.6158 58.4309 23.6142H56.9507V25.1979H58.4309Z" fill="black"/>
<path d="M62.9548 27.6341C63.2044 27.6423 63.4404 27.7345 63.6131 27.8912C63.7856 28.0479 63.8811 28.2567 63.8794 28.4735C63.8731 28.6874 63.7712 28.8907 63.5953 29.0405C63.4194 29.1903 63.1831 29.2748 62.9368 29.2761C62.6946 29.2707 62.4642 29.1841 62.2942 29.0344C62.1239 28.8848 62.0273 28.6837 62.0243 28.4735C62.0219 28.3653 62.044 28.2578 62.0895 28.1571C62.135 28.0564 62.2028 27.9644 62.2893 27.8864C62.3757 27.8085 62.4789 27.7461 62.5931 27.7028C62.7074 27.6595 62.8302 27.6362 62.9548 27.6341Z" fill="black"/>
<path d="M68.3526 22.176C68.7856 22.1621 69.2171 22.2358 69.6219 22.3929C70.0266 22.55 70.3965 22.7874 70.7101 23.0912C70.7294 23.1088 70.7447 23.1303 70.7552 23.1543C70.7657 23.1783 70.7711 23.2043 70.7711 23.2306C70.7711 23.2569 70.7657 23.2828 70.7552 23.3068C70.7447 23.3309 70.7294 23.3524 70.7101 23.37L69.9461 24.17C69.9305 24.1861 69.912 24.1989 69.8916 24.2077C69.871 24.2164 69.8491 24.2209 69.8268 24.2209C69.8045 24.2209 69.7826 24.2164 69.762 24.2077C69.7416 24.1989 69.723 24.1861 69.7075 24.17C69.3445 23.8457 68.8778 23.6669 68.3944 23.667C67.8673 23.667 67.3618 23.8796 66.9891 24.2581C66.6162 24.6366 66.4068 25.1499 66.4068 25.6851C66.4068 26.2204 66.6162 26.7338 66.9891 27.1122C67.3618 27.4907 67.8673 27.7034 68.3944 27.7034C68.8705 27.7021 69.3318 27.5353 69.7015 27.2306C69.7356 27.1987 69.7803 27.1809 69.8268 27.1809C69.8732 27.1809 69.918 27.1987 69.9521 27.2306L70.7101 28.0488C70.7438 28.0848 70.7623 28.1325 70.7623 28.1821C70.7623 28.2317 70.7438 28.2795 70.7101 28.3155C70.0705 28.9429 69.2119 29.2873 68.3228 29.2731C67.8508 29.2928 67.38 29.2156 66.9383 29.0458C66.4965 28.8761 66.0933 28.6175 65.7526 28.2855C65.4117 27.9535 65.1407 27.5549 64.9554 27.1138C64.7702 26.6726 64.6747 26.198 64.6747 25.7185C64.6747 25.2389 64.7702 24.7643 64.9554 24.3232C65.1407 23.8821 65.4117 23.4835 65.7526 23.1515C66.0933 22.8195 66.4965 22.5608 66.9383 22.3911C67.38 22.2214 67.8508 22.1441 68.3228 22.1639L68.3526 22.176Z" fill="black"/>
<path d="M75.5305 22.1609C76.2123 22.1585 76.8794 22.365 77.4475 22.7542C78.0155 23.1433 78.459 23.6978 78.7218 24.3473C78.9846 24.9968 79.0548 25.7121 78.9237 26.4029C78.7924 27.0936 78.4658 27.7287 77.985 28.2277C77.5042 28.7266 76.8908 29.0672 76.2224 29.2061C75.5541 29.345 74.8607 29.2762 74.2305 29.0082C73.6001 28.7402 73.0606 28.2852 72.6808 27.7007C72.301 27.1162 72.0976 26.4284 72.0965 25.7246C72.095 25.2577 72.1826 24.795 72.3544 24.3631C72.5263 23.9312 72.7788 23.5385 73.0977 23.2075C73.4167 22.8765 73.7958 22.6137 74.2133 22.4341C74.6307 22.2545 75.0784 22.1617 75.5305 22.1609ZM75.5305 27.6882C75.9098 27.6894 76.2808 27.5742 76.5966 27.3573C76.9122 27.1404 77.1585 26.8316 77.3038 26.4699C77.4491 26.1083 77.4872 25.7102 77.4132 25.3262C77.339 24.9422 77.156 24.5896 76.8874 24.3132C76.6189 24.0368 76.2769 23.849 75.9047 23.7736C75.5326 23.6983 75.1471 23.7388 74.7972 23.89C74.4474 24.0412 74.149 24.2963 73.94 24.623C73.7308 24.9496 73.6205 25.333 73.6227 25.7246C73.6274 26.2449 73.8301 26.7425 74.187 27.1099C74.5438 27.4774 75.0265 27.685 75.5305 27.6882Z" fill="black"/>
<path d="M81.3962 22.3174C81.4018 22.2748 81.4222 22.2356 81.454 22.2069C81.4857 22.1783 81.5265 22.1619 81.569 22.1609H81.7239C81.7573 22.1627 81.7899 22.1724 81.8187 22.1891C81.8477 22.2058 81.8724 22.2291 81.891 22.2572L84.1447 26.4709L86.3984 22.2572C86.417 22.2291 86.4417 22.2058 86.4707 22.1891C86.4996 22.1724 86.532 22.1627 86.5653 22.1609H86.7204C86.7629 22.1619 86.8037 22.1783 86.8354 22.2069C86.867 22.2356 86.8876 22.2748 86.8933 22.3174L87.9964 28.939C88.0019 28.966 88.0011 28.994 87.9942 29.0208C87.9874 29.0475 87.9745 29.0723 87.9567 29.0932C87.9387 29.1141 87.9164 29.1307 87.8913 29.1415C87.8661 29.1523 87.8389 29.1572 87.8116 29.1557H86.6608C86.6174 29.153 86.576 29.1364 86.5428 29.1082C86.5095 29.08 86.4861 29.0418 86.476 28.9992L86.0407 25.9894L84.4071 29.1677C84.3889 29.1971 84.3644 29.2221 84.3356 29.2408C84.3067 29.2595 84.2741 29.2716 84.24 29.2761H84.0672C84.0327 29.2734 83.9994 29.2622 83.9701 29.2432C83.9409 29.2243 83.917 29.1984 83.9003 29.1677L82.2607 25.9292L81.8312 28.939C81.823 28.9826 81.8002 29.022 81.7664 29.0505C81.7329 29.079 81.6904 29.0949 81.6466 29.0955H80.5018C80.4745 29.097 80.4471 29.0921 80.422 29.0813C80.3968 29.0705 80.3745 29.0539 80.3565 29.033C80.3387 29.0121 80.3258 28.9873 80.319 28.9605C80.3121 28.9338 80.3113 28.9059 80.317 28.8788L81.3962 22.3174Z" fill="black"/>
<path d="M2.64392 5.49456H1.63205L1.82789 5.33135V7.89911L1.67557 7.71414L3.02473 7.93175V8.3996H0V7.93175L1.09891 7.70326L0.935707 7.89911V1.31652L1.12067 1.52324L0 1.3274V0.859545L1.84965 0.805143H3.07913C3.63765 0.805143 4.09825 0.899439 4.46093 1.08803C4.82361 1.27662 5.09561 1.53775 5.27695 1.87141C5.45829 2.20508 5.54896 2.59314 5.54896 3.03561C5.54896 3.5216 5.44016 3.94955 5.22255 4.31949C5.00495 4.68942 4.67854 4.97956 4.24332 5.18991C3.81536 5.39301 3.28223 5.49456 2.64392 5.49456ZM2.94857 1.46884H1.66469L1.82789 1.30564V4.99407L1.63205 4.83086H2.64392C3.28223 4.83086 3.77184 4.67128 4.11276 4.35213C4.46093 4.03297 4.63501 3.60138 4.63501 3.05737C4.63501 2.56413 4.48994 2.17606 4.1998 1.89317C3.91691 1.61029 3.49984 1.46884 2.94857 1.46884Z" fill="#73777C"/>
<path d="M8.96649 2.55687C9.76438 2.55687 10.3882 2.80712 10.8379 3.30762C11.2949 3.80811 11.5234 4.51533 11.5234 5.42928C11.5234 6.04583 11.4109 6.58622 11.1861 7.05044C10.9685 7.50742 10.6566 7.86284 10.2504 8.11672C9.84416 8.37059 9.35455 8.49753 8.78152 8.49753C7.99814 8.49753 7.38521 8.25453 6.94275 7.76855C6.50028 7.27531 6.27905 6.56808 6.27905 5.64688C6.27905 5.00857 6.38422 4.46093 6.59458 4.00396C6.81218 3.53973 7.12046 3.18431 7.5194 2.93769C7.9256 2.68381 8.40796 2.55687 8.96649 2.55687ZM8.92296 3.19881C8.36444 3.19881 7.93648 3.40917 7.63909 3.82987C7.34169 4.25058 7.19299 4.84174 7.19299 5.60336C7.19299 6.35048 7.33444 6.91263 7.61733 7.28981C7.90021 7.667 8.30641 7.85559 8.83592 7.85559C9.22036 7.85559 9.54314 7.76129 9.80427 7.5727C10.0727 7.37685 10.2721 7.10122 10.4027 6.7458C10.5405 6.39037 10.6094 5.96604 10.6094 5.4728C10.6094 4.73294 10.4607 4.17079 10.1633 3.78635C9.87318 3.39466 9.45973 3.19881 8.92296 3.19881Z" fill="#73777C"/>
<path d="M18.3637 2.68744L20.5506 2.61128V3.09001L19.789 3.27498L19.9413 3.14441L18.4507 8.42136H17.4933L16.0788 3.28586H16.2529L14.7514 8.42136H13.7939L12.3686 3.16617L12.4992 3.29674L11.7484 3.15529V2.68744L14.0986 2.61128V3.09001L13.1085 3.26409L13.2064 3.15529L14.4141 7.88823H14.2727L15.7306 2.67656L16.6772 2.61128L18.0808 7.92087H17.9393L19.2123 3.17705L19.3103 3.29674L18.3637 3.15529V2.68744Z" fill="#73777C"/>
<path d="M23.2742 8.49753C22.7882 8.49753 22.3566 8.38872 21.9794 8.17112C21.6095 7.94626 21.3194 7.61985 21.109 7.19189C20.8987 6.76393 20.7935 6.24167 20.7935 5.62512C20.7935 4.99406 20.9023 4.45005 21.1199 3.99308C21.3375 3.5361 21.6458 3.18431 22.0447 2.93769C22.4437 2.68381 22.9115 2.55687 23.4483 2.55687C23.956 2.55687 24.3731 2.66568 24.6995 2.88328C25.0332 3.09364 25.2798 3.3874 25.4394 3.76459C25.6062 4.14177 25.6896 4.57336 25.6896 5.05935C25.6896 5.13914 25.686 5.21892 25.6787 5.29871C25.6715 5.3785 25.6606 5.47643 25.6461 5.59248H21.4572V4.9723H24.8845L24.7865 5.03759C24.7938 4.71118 24.7539 4.41015 24.6669 4.13452C24.5798 3.85889 24.4311 3.63765 24.2208 3.47082C24.0104 3.30399 23.7312 3.22057 23.383 3.22057C23.0131 3.22057 22.7012 3.3185 22.4473 3.51434C22.1934 3.71019 22.0048 3.97857 21.8815 4.31949C21.7582 4.6604 21.6965 5.05572 21.6965 5.50544C21.6965 5.98417 21.7654 6.39763 21.9033 6.7458C22.0411 7.08671 22.2406 7.35147 22.5017 7.54006C22.7701 7.72865 23.0965 7.82295 23.4809 7.82295C23.7493 7.82295 24.0431 7.77217 24.3622 7.67062C24.6814 7.56182 25.0114 7.39861 25.3523 7.18101L25.6679 7.67062C25.2617 7.94626 24.8555 8.15298 24.4493 8.2908C24.0431 8.42862 23.6514 8.49753 23.2742 8.49753Z" fill="#73777C"/>
<path d="M26.6348 8.3996V7.93175L27.5923 7.72502L27.4835 7.88823V3.19881L27.6032 3.36202L26.6348 3.15529V2.68744L28.1581 2.61128L28.2778 3.67755L28.1581 3.63403C28.4845 3.29311 28.8037 3.02835 29.1156 2.83976C29.4275 2.65117 29.7684 2.55687 30.1383 2.55687C30.3704 2.55687 30.6134 2.59314 30.8673 2.66568L30.802 4.2542H30.258L30.1274 3.11177L30.2253 3.30762C30.1746 3.29311 30.1202 3.28223 30.0621 3.27498C30.0041 3.26772 29.9461 3.26409 29.8881 3.26409C29.5979 3.26409 29.315 3.35114 29.0394 3.52522C28.771 3.69931 28.5135 3.92417 28.2669 4.1998L28.3539 3.93867V7.88823L28.2234 7.72502L29.4093 7.93175V8.3996H26.6348Z" fill="#73777C"/>
<path d="M33.7826 8.49753C33.2966 8.49753 32.865 8.38872 32.4879 8.17112C32.1179 7.94626 31.8278 7.61985 31.6174 7.19189C31.4071 6.76393 31.3019 6.24167 31.3019 5.62512C31.3019 4.99406 31.4107 4.45005 31.6283 3.99308C31.8459 3.5361 32.1542 3.18431 32.5531 2.93769C32.9521 2.68381 33.4199 2.55687 33.9567 2.55687C34.4644 2.55687 34.8815 2.66568 35.2079 2.88328C35.5416 3.09364 35.7882 3.3874 35.9478 3.76459C36.1146 4.14177 36.198 4.57336 36.198 5.05935C36.198 5.13914 36.1944 5.21892 36.1872 5.29871C36.1799 5.3785 36.169 5.47643 36.1545 5.59248H31.9656V4.9723H35.3929L35.295 5.03759C35.3022 4.71118 35.2623 4.41015 35.1753 4.13452C35.0883 3.85889 34.9396 3.63765 34.7292 3.47082C34.5189 3.30399 34.2396 3.22057 33.8914 3.22057C33.5215 3.22057 33.2096 3.3185 32.9557 3.51434C32.7018 3.71019 32.5132 3.97857 32.3899 4.31949C32.2666 4.6604 32.205 5.05572 32.205 5.50544C32.205 5.98417 32.2739 6.39763 32.4117 6.7458C32.5495 7.08671 32.749 7.35147 33.0101 7.54006C33.2785 7.72865 33.6049 7.82295 33.9893 7.82295C34.2577 7.82295 34.5515 7.77217 34.8706 7.67062C35.1898 7.56182 35.5198 7.39861 35.8608 7.18101L36.1763 7.67062C35.7701 7.94626 35.3639 8.15298 34.9577 8.2908C34.5515 8.42862 34.1598 8.49753 33.7826 8.49753Z" fill="#73777C"/>
<path d="M39.3955 8.49753C38.9966 8.49753 38.6339 8.38872 38.3075 8.17112C37.9811 7.94626 37.7236 7.61985 37.535 7.19189C37.3464 6.75668 37.2521 6.23079 37.2521 5.61424C37.2521 4.606 37.5096 3.84438 38.0246 3.32938C38.5396 2.81438 39.2468 2.55687 40.1462 2.55687C40.3348 2.55687 40.5307 2.57138 40.7338 2.6004C40.9441 2.62941 41.1581 2.67293 41.3757 2.73096L41.2016 2.82888V0.641938L41.2996 0.761622L40.2986 0.544015V0.0761619L42.0721 0V7.86647L41.9415 7.71414L42.9207 7.90999V8.38872L41.3975 8.45401L41.2669 7.5183L41.3757 7.56182C41.0493 7.87372 40.7193 8.10946 40.3856 8.26904C40.0592 8.42136 39.7292 8.49753 39.3955 8.49753ZM39.7001 7.83383C39.954 7.83383 40.2151 7.76855 40.4835 7.63798C40.7592 7.50016 41.0348 7.29707 41.3104 7.02868L41.2016 7.32245V3.19881L41.3213 3.41642C40.8788 3.27135 40.4763 3.19881 40.1136 3.19881C39.4753 3.19881 38.9893 3.40554 38.6556 3.81899C38.322 4.23244 38.1551 4.80547 38.1551 5.53808C38.1551 6.01681 38.2204 6.43027 38.351 6.77844C38.4888 7.11935 38.6738 7.38048 38.9059 7.56182C39.1453 7.74316 39.41 7.83383 39.7001 7.83383Z" fill="#73777C"/>
<path d="M48.0777 8.49753C47.8165 8.49753 47.5373 8.47939 47.2399 8.44313C46.9497 8.40686 46.6414 8.35246 46.315 8.27992V0.511375L46.4891 0.761622L45.4664 0.544015V0.0761619L47.1855 0V3.57962L47.0658 3.44906C47.3704 3.15892 47.686 2.93769 48.0124 2.78536C48.3388 2.63304 48.6652 2.55687 48.9916 2.55687C49.3978 2.55687 49.7605 2.6693 50.0796 2.89416C50.406 3.11902 50.6635 3.44543 50.8521 3.87339C51.0407 4.30135 51.135 4.82723 51.135 5.45104C51.135 5.92252 51.0734 6.34685 50.9501 6.72404C50.834 7.10122 50.6527 7.42038 50.406 7.6815C50.1594 7.94263 49.8439 8.14573 49.4595 8.2908C49.075 8.42862 48.6144 8.49753 48.0777 8.49753ZM48.2409 7.85559C48.8864 7.85559 49.3797 7.65612 49.7206 7.25717C50.0615 6.85823 50.232 6.27794 50.232 5.51632C50.232 5.03759 50.163 4.62776 50.0252 4.28684C49.8947 3.94593 49.7133 3.6848 49.4812 3.50346C49.2491 3.31487 48.9843 3.22057 48.6869 3.22057C48.4331 3.22057 48.1683 3.28948 47.8927 3.4273C47.6243 3.55786 47.3523 3.76096 47.0767 4.0366L47.1855 3.72107V7.92087L47.0658 7.6815C47.5082 7.79756 47.8999 7.85559 48.2409 7.85559Z" fill="#73777C"/>
<path d="M51.5985 10.9456L51.6746 10.3037C51.7617 10.3182 51.8414 10.329 51.914 10.3363C51.9938 10.3436 52.0663 10.3472 52.1316 10.3472C52.487 10.3472 52.7735 10.2638 52.9911 10.0969C53.216 9.93736 53.4046 9.69436 53.5569 9.36795C53.7092 9.04154 53.8652 8.63534 54.0248 8.14936L53.9921 8.47577L51.9575 3.17705L52.0772 3.27498L51.37 3.15529V2.68744L53.7528 2.61128V3.09001L52.7735 3.25321L52.8388 3.15529L54.46 7.77943L54.2641 7.7359L55.82 3.17705L55.8853 3.27498L54.9278 3.15529V2.68744L57.2345 2.61128V3.09001L56.4728 3.26409L56.6252 3.16617L54.7102 8.42136C54.5797 8.78404 54.4418 9.12133 54.2968 9.43323C54.159 9.74514 53.9994 10.0171 53.818 10.2493C53.6367 10.4886 53.4155 10.6736 53.1543 10.8042C52.8932 10.9347 52.5777 11 52.2078 11C52.0409 11 51.8378 10.9819 51.5985 10.9456Z" fill="#73777C"/>
        </SvgIcon>
    )
}
