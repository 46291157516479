import React from "react";
import {observer} from "mobx-react-lite";
import {ReturnFlightSelectionPageContent} from "../../../booking-flow/fare-selection/return/return-flight-selection-page-content.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {ReturnFlightSelectionExtraHeaderComponent} from "../../../booking-flow/fare-selection/components/extra-header/return-flight-selection-extra-header.component";
import {BookingFlowPage} from "../../../booking-flow/booking-flow.page";


const ManageMyBookingSelectNewReturnFlightPageContent: React.FC = observer(() => {
    return (
        <ReturnFlightSelectionPageContent allowBundleSelection={false}/>
    );
});

export const ManageMyBookingSelectNewReturnFlightPage: React.FC = observer(() => {
    const services = useServices();

    const renderExtraHeader = () => {
        return (
            <ReturnFlightSelectionExtraHeaderComponent/>
        );
    }
    return (
        <BookingFlowPage title={services.language.translate('Return Flight')}
                                    renderContent={() => <ManageMyBookingSelectNewReturnFlightPageContent/>}
                                    renderExtraHeaderContent={renderExtraHeader}
                                    showPromotionCode={false}
        />
    );
});
