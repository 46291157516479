import {ExtrasSsrsSectionRenderer} from "../../base-renderers/extras-ssrs-section.renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";

export class SpecialAssistanceExtrasSectionRenderer extends ExtrasSsrsSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.getSpecialAssistanceSsrTypes(), booking);
    }

    protected _renderSectionTitle(): string {
        return this.services.language.translate(`Special assistance`);
    }
}
