import {BookingModel} from "../../booking.model";
import {Price} from "../../../../currency/price";
import {PaymentMethodsSortOrder} from "./payment-method-codes.enum";
import React from "react";
import {IPaymentMethodModel} from "./payment-method.model.interface";
import {IServiceFactory} from "../../../../service-factory.interface";
import {PaymentMethodCategoryEnum} from "./payment-method-category.enum";

export abstract class PaymentMethodBaseModel implements IPaymentMethodModel {
    constructor(protected readonly booking: BookingModel,
                public readonly code: string) {
    }

    abstract get name(): string;
    abstract renderIcon(): React.ReactElement | null;
    abstract get isSupportedOnCurrentPlatform(): boolean;
    abstract get availableAmountInBookingCurrency(): Price;
    abstract get category(): PaymentMethodCategoryEnum;

    get hasCustomRenderer(): boolean {
        return false;
    }

    protected get services(): IServiceFactory {
        return this.booking.services;
    }


    get isSelected(): boolean {
        return this.booking.paymentHandler.isPaymentMethodSelected(this.code);
    }

    set isSelected(value: boolean) {
        if(value) {
            this.booking.paymentHandler.selectPaymentMethod(this.code);
        } else {
            this.booking.paymentHandler.unselectPaymentMethod(this.code);
        }
    }

    get sortOrder(): number {
        return PaymentMethodsSortOrder[this.code] || 99999;
    }


}
