import {IAppDomainConfig} from "./env.configs.interfaces";
import {ApplicationEnvironmentTypeEnum} from "./configuration-object.interface";
import {UndefinedString} from "../../types/nullable-types";
import {NonEnvLocalStorageKeys} from "../storage/local-storage-keys";


function getProductionApiOrigin(): string {
    return 'https://aeroitalia-gateway.azure-api.net/mobileappprod-api';
}

function getStageApiOrigin(): string {
    return 'https://aeroitalia-gateway.azure-api.net/stage-ibe-api';
}

function getTestApiOrigin(): string {
    return 'https://aeroitalia-gateway.azure-api.net/mobileapptest-api';
}

function getDevApiOrigin(): string {
    return 'https://localhost:5001';
}

function createProductionEnvironment(domainOrigin: string): IAppDomainConfig {
    return  {
        environmentType: ApplicationEnvironmentTypeEnum.Production,
        domainOrigin: domainOrigin,
        apiOrigin: getProductionApiOrigin()
    };
}

function createStageEnvironment(domainOrigin: string): IAppDomainConfig {
    return  {
        environmentType: ApplicationEnvironmentTypeEnum.Stage,
        domainOrigin: domainOrigin,
        apiOrigin: getStageApiOrigin()
    };
}

function createTestEnvironment(domainOrigin: string): IAppDomainConfig {
    return   {
        environmentType: ApplicationEnvironmentTypeEnum.Test,
        domainOrigin: domainOrigin,
        apiOrigin: getTestApiOrigin()
    };
}


function createDevEnvironment(options: {domainOrigin: string; apiOrigin: string}): IAppDomainConfig {
    return   {
        environmentType: ApplicationEnvironmentTypeEnum.Development,
        domainOrigin: options.domainOrigin,
        apiOrigin: options.apiOrigin
    };
}

export class EnvConfigs {

    constructor() {
        this._currentDomain = this._loadCurrentDomain();
    }

    private readonly _currentDomain: IAppDomainConfig;

    private _getNgrokHeaders(): Record<string, string> {
        return {
            'ngrok-skip-browser-warning': 'true'
        }
    }

    get ngrokHeadersForWebApi(): Record<string, string> {
        if(process.env.REACT_APP_USE_NGROK_HEADERS_FOR_WEBAPI === 'true') {
            return this._getNgrokHeaders();
        }
        return {};
    }


    public readonly appDomains = [
        createProductionEnvironment('http://localhost'),
        createProductionEnvironment('capacitor://localhost'),
        createProductionEnvironment('https://flights.aeroitalia.com/'),
        createProductionEnvironment('https://book.aeroitalia.com'),
        createProductionEnvironment('https://white-desert-0dfbc4103.2.azurestaticapps.net'),
        createStageEnvironment('https://stage-ibe.aeroitalia.com/'),
        createTestEnvironment('https://nice-water-0705a5003.2.azurestaticapps.net'),
        createTestEnvironment('https://stage-mobile-a8f3hdhferhjfteu.z03.azurefd.net'),
        createTestEnvironment('https://test-ibe.aeroitalia.com/'),
        createDevEnvironment({domainOrigin: 'http://localhost:8100', apiOrigin: getDevApiOrigin()}),
        createDevEnvironment({domainOrigin: 'http://localhost:8101', apiOrigin: getDevApiOrigin()}),
        createDevEnvironment({domainOrigin: 'http://localhost:9034', apiOrigin: getDevApiOrigin()}),
        createDevEnvironment({domainOrigin: 'https://acb7e80b325c.ngrok.app/', apiOrigin: getDevApiOrigin()}),
        createDevEnvironment({domainOrigin: 'http://localhost:3000', apiOrigin: getTestApiOrigin()}) // this is for DEV mode on Mac
    ];


    get airlineWebapiOrigin(): string {
        return this._currentDomain.apiOrigin;
    }

    readonly enablePayCashButton = process.env.REACT_APP_ENABLE_PAY_CASH_BUTTON === 'true';
    readonly enableCheckin = true; //process.env.REACT_APP_ENABLE_CHECKIN === 'true';
    readonly enableManageMyBooking = true; //process.env.REACT_APP_ENABLE_MANAGE_MY_BOOKING === 'true';
    readonly enableLogin = process.env.REACT_APP_ENABLE_LOGIN === 'true';
    readonly enableInsurance = process.env.REACT_APP_ENABLE_INSURANCE === 'true';
    readonly byPassCheckInRestrictions = false;
    private readonly _preferredApiOrigin: UndefinedString = process.env.REACT_APP_NGROK_WEBAPI_URL || process.env.REACT_APP_PREFERRED_API;

    private _findDomainByCurrentLocation(): IAppDomainConfig {
        let currentLocation = new URL(window.location.href);

        for(let domain of this.appDomains) {
            const domainOrigin = new URL(domain.domainOrigin);
            if(currentLocation.origin.toLowerCase() === domainOrigin.origin.toLowerCase()) {
                return domain;
            }
        }

        const firstProdDomain = this.appDomains.find(d => d.environmentType === ApplicationEnvironmentTypeEnum.Production);
        if(firstProdDomain) {
            return firstProdDomain;
        }

        throw new Error('No domain configuration found for current domain');
    }

    private _loadCurrentDomain(): IAppDomainConfig {
        const po = localStorage.getItem(NonEnvLocalStorageKeys.envConfigsPreferredApiOrigin)?.toLowerCase();
        if(po) {
            const preferredDomain = this.appDomains.find(d => d.apiOrigin.toLowerCase() === po);
            if(preferredDomain) {
                return preferredDomain;
            }
        }



        let d = this._findDomainByCurrentLocation();

        if(this._preferredApiOrigin) {
            d = {
                ...d,
                apiOrigin: this._preferredApiOrigin,
                environmentType: ApplicationEnvironmentTypeEnum.Development
            }
        }

        return d;
    }

    getInitialApiOrigin(): string {
        return this._findDomainByCurrentLocation().apiOrigin;
    }

    get isProduction(): boolean {
        return this._currentDomain.environmentType === ApplicationEnvironmentTypeEnum.Production;
    }

    get currentEnvironmentType(): ApplicationEnvironmentTypeEnum {
        return this._currentDomain.environmentType;
    }
}


