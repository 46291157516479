import React, {useState} from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {ForgotPasswordFormModel} from "../../services/user/models/forgot-password/forgot-password-form.model";
import {EmailFieldEditorComponent} from "../forms/contact/email-field-editor.component";
import {ValidationResultEnum} from "../../types/validation-result.enum";
import styled from "styled-components";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";

const ForgotPasswordDialogTextBox = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1.4;
    margin: 10px 0 20px;;
`


export const ForgotPasswordDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    const [form] = useState(new ForgotPasswordFormModel(services));
    const onClickHandler = async () => {

        if(form.activateErrorsValidation().length > 0) {
            return;
        }

        await services.loadingIndicator.execute({
            action: async () => {
                if(ValidationResultEnum.Success === await services.user.sendResetPasswordLink(form.fields.emailAddress.value!)) {
                    props.dialogHandler.accept();
                }
            }
        });
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Forgot password?')} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <ForgotPasswordDialogTextBox>
                    {services.language.translate('Please add the email address associated with your account in order to receive the reset link')}
                </ForgotPasswordDialogTextBox>
                <EmailFieldEditorComponent field={form.fields.emailAddress}/>
                <PrimaryButton onClick={onClickHandler}>
                    {services.language.translate('Reset password')}
                </PrimaryButton>
            </StandardDialogContentComponent>
        </StandardDialogPageComponent>
    )
});
