import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {
    ShoppingCartPassengerTypeFareDetailsDialogComponent,
    ShoppingCartPassengerTypeFareDetailsDialogComponentProps
} from "./shopping-cart-passenger-type-fare-details-dialog.component";
import {NullablePrice, Price} from "../../../../services/currency/price";
import {ShoppingCartPriceDetailsButtonComponent} from "../details-button/shopping-cart-price-details-button.component";
import {DialogCloseButtonBehavior} from "../../../../services/dialog/dialog-enums";

export interface ShoppingCartPassengerTypeFareDetailsButtonComponentProps {
    description: string;
    initialBundlePrice?: NullablePrice;
    currentBundlePrice?: Price;
    initialFare: NullablePrice;
    currentFare: Price;
}

export const ShoppingCartPassengerTypeFareDetailsButtonComponent: React.FC<ShoppingCartPassengerTypeFareDetailsButtonComponentProps> = observer((props) => {
    const services = useServices();

    if(!services.booking.current.shoppingCart.shouldShowPriceDifferences) {
        return null;
    }

    if(!props.initialFare) {
        return null;
    }

    const onClickHandler = async () => {

        await services.dialog.showSheetModalDialog({
            breakpoints: [0.4],
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            render: dialogHandler => {
                const dialogProps: ShoppingCartPassengerTypeFareDetailsDialogComponentProps = {
                    ...props,
                    dialogHandler: dialogHandler
                }
                return (<ShoppingCartPassengerTypeFareDetailsDialogComponent {...dialogProps}/>)
            }
        })
    }

    return (
        <ShoppingCartPriceDetailsButtonComponent onClick={onClickHandler}/>
    );
});
