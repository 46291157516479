import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import styled from "styled-components";
import {PassengerAvailableTravelDocumentComponent} from "./passenger-available-travel-document.component";
import {useServices} from "../../../../hooks/use-services.hook";

const AvailableTravelDocumentsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5px 5px;
    border-radius: 8px;
    margin-left: 5px;
    background-color: ${props => props.theme.colors.lightTint};
`

interface PassengerAvailableTravelDocumentsComponentProps {
    passenger: IPassengerViewModel;
}
export const PassengerAvailableTravelDocumentsComponent: React.FC<PassengerAvailableTravelDocumentsComponentProps> = observer((props) => {
    const services = useServices();
    if(services.booking.current.isDomesticFlight) {
        return null;
    }

    const travelDocuments = props.passenger.companion.availableTravelDocuments;
    if(travelDocuments.length === 0) {
        return null;
    }
    return (
        <AvailableTravelDocumentsBox>
            {travelDocuments.map(td => <PassengerAvailableTravelDocumentComponent key={td.travelDocumentKey} passenger={props.passenger} travelDocument={td}/>)}
        </AvailableTravelDocumentsBox>
    );
});
