import React from "react";
import {observer} from "mobx-react-lite";
import { useServices } from "../../../../hooks/use-services.hook";
import { IDialogConfirmationHandler } from "../../../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import { StandardDialogPageComponent } from "../../../../components/modal/page/standard-dialog-page.component";
import { StandardDialogHeaderComponent } from "../../../../components/modal/header/standard-dialog-header.component";
import { StandardDialogContentComponent } from "../../../../components/modal/content/standard-dialog-content.component";
import { RearSeatBox, FrontSeatBox, SeatNotAvailableBox, SeatSelectedBox, ExtraLegSeatBox, PassengerInitialsBox } from "../components/seat.component";
import { SeatsRowGroupType } from "../../../../services/booking/models/seat-maps/seats-row-group-type.enum";
import { CheckedSelectedIcon } from "../../../../assets/svg/checked-selected.icon";
import { XIcon } from "../../../../assets/svg/x.icon";
import { PersonNoCircleIcon } from "../../../../assets/svg/person-no-circle.icon";

const SeatsLegendComponentBox = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid ${props => props.theme.colors.primary};
    overflow: auto;
    margin-bottom: 16px;
    gap: 4px;
`

const SeatsLegendColBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
`

const SeatsLegendColTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.small};
    line-height: 1;
    text-align: center;
    margin-bottom: 12px;
    color: ${props => props.theme.colors.primary};
`

const SeatsDescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 9px 0 14px;
`

const SeatsDescriptionIcon = styled.div`
    flex-shrink: 0;
`

const SeatsDescriptionText = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1;
    margin-left: 12px;
    color: ${props => props.theme.colors.primary};
`

interface ISeatsDetailsComponent {
    title: string;
    seatDescriptions: string[];
    dialogHandler: IDialogConfirmationHandler;
}

const SeatsDetailsComponent:React.FC<ISeatsDetailsComponent> = observer((props) => {
    const services = useServices();

    return (<>
        <StandardDialogHeaderComponent title={props.title} dialogHandler={props.dialogHandler}/>
        <StandardDialogContentComponent>
            <div>
                {props.seatDescriptions.map(seat => <SeatsDescriptionContainer>
                    <SeatsDescriptionIcon>
                        <CheckedSelectedIcon size={1.4} color={services.theme.currentTheme.colors.primary}/>
                    </SeatsDescriptionIcon>
                    <SeatsDescriptionText>
                        {seat}
                    </SeatsDescriptionText>
                </SeatsDescriptionContainer>)}
            </div>
            <SeatsLegendComponent/>
        </StandardDialogContentComponent>
    </>)
});

const SeatsLegendComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <SeatsLegendComponentBox>
            <SeatsLegendColBox>
                <SeatsLegendColTitleBox>
                    {services.language.translate('Selected Seat')}
                </SeatsLegendColTitleBox>
                <SeatSelectedBox>
                    <PersonNoCircleIcon size={1.2} />
                    <PassengerInitialsBox>NS</PassengerInitialsBox>
                </SeatSelectedBox>
            </SeatsLegendColBox>
            <SeatsLegendColBox>
                <SeatsLegendColTitleBox>
                    {services.language.translate('Not Available')}
                </SeatsLegendColTitleBox>
                <SeatNotAvailableBox>
                    <XIcon color={services.theme.currentTheme.colors.primaryContrast} />
                </SeatNotAvailableBox>
            </SeatsLegendColBox>
            <SeatsLegendColBox>
                <SeatsLegendColTitleBox>
                    {services.language.translate('Front Seats')}
                </SeatsLegendColTitleBox>
                <FrontSeatBox/>
            </SeatsLegendColBox>
            <SeatsLegendColBox>
                <SeatsLegendColTitleBox>
                    {services.language.translate('Extra Legroom')}
                </SeatsLegendColTitleBox>
                <ExtraLegSeatBox/>
            </SeatsLegendColBox>
            <SeatsLegendColBox>
                <SeatsLegendColTitleBox>
                    {services.language.translate('Back Seats')}
                </SeatsLegendColTitleBox>
                <RearSeatBox/>
            </SeatsLegendColBox>
        </SeatsLegendComponentBox>
    )
});

interface SeatMapGroupDetailsDialogComponentProps {
    seatsRowGroupType: SeatsRowGroupType;
    dialogHandler: IDialogConfirmationHandler;
}


export const SeatMapGroupDetailsDialogComponent: React.FC<SeatMapGroupDetailsDialogComponentProps> = observer((props) => {
    const services = useServices();
    let titleText;

    const renderSeatsPageContent = () => {
        if (props.seatsRowGroupType === SeatsRowGroupType.first) {
            titleText = services.language.translate('First row seats');
            const translationKeys = [services.language.translate('Be one of the first off the plane'),
                    services.language.translate('More Space - Extra legroom seats'),
                    services.language.translate('This area is close to exit')]
            return <SeatsDetailsComponent title={titleText} seatDescriptions={translationKeys} dialogHandler={props.dialogHandler}/>
        }else if (props.seatsRowGroupType === SeatsRowGroupType.front) {
            titleText = services.language.translate('Front seats');
            const translationKeys = [services.language.translate('Be one of the first off the plane'),
                    services.language.translate('This area is close to exit'),
                    services.language.translate('These are the best seats on the plane')]
            return <SeatsDetailsComponent title={titleText} seatDescriptions={translationKeys} dialogHandler={props.dialogHandler}/>
        }else if (props.seatsRowGroupType === SeatsRowGroupType.emergency) {
            titleText = services.language.translate('Emergency seats');
            const translationKeys = [services.language.translate('More Space - Extra legroom seats'),
                    services.language.translate('Additional restrictions apply')]
            return <SeatsDetailsComponent title={titleText} seatDescriptions={translationKeys} dialogHandler={props.dialogHandler}/>
        }else if (props.seatsRowGroupType === SeatsRowGroupType.rear) {
            titleText = services.language.translate('Back seats');
            const translationKeys = [services.language.translate('Pick the best seats for you'),
                    services.language.translate('Pick middle, window or isle'),
                    services.language.translate('Great area for families')]
            return <SeatsDetailsComponent title={titleText} seatDescriptions={translationKeys} dialogHandler={props.dialogHandler}/>
        }
    }


    return (
        <StandardDialogPageComponent>
            {renderSeatsPageContent()}
        </StandardDialogPageComponent>
    )
});
