import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const ChevronExpandIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} viewBox="0 0 512 512" ratio={512/512}>
            <path d="M136 208l120-104 120 104M136 304l120 104 120-104" stroke="currentColor" fill="none" strokeWidth="48" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    )
}
