import {ServiceBase} from "../service-base";
import {IRegionsService} from "./regions.service.interface";
import {IServiceFactory} from "../service-factory.interface";
import {makeObservable, observable, reaction} from "mobx";
import {IItalianRegionConfiguration} from "../configuration/objects/italian-region-configuration.interface";

export class RegionsService extends ServiceBase implements IRegionsService {
    constructor(services: IServiceFactory) {
        super(services);

        reaction(() => this.services.configuration.data.italianRegions,
            regions => {
                this._regionsMap = regions.toDictionary(r => r.code);
            }, {
                fireImmediately: true
            });
        makeObservable(this, {
            _regionsMap: observable.ref
        });
    }

    _regionsMap: Record<string, IItalianRegionConfiguration> = {};
    getItalianRegions(): IItalianRegionConfiguration[] {
        return this.services.configuration.data.italianRegions;
    }

    getItalianRegionCities(regionCode: string): string[] {
        const region = this._regionsMap[regionCode];
        if(region) {
            return region.cities;
        }

        return [];
    }

}
