import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {ISsrType} from "../../services/ssr-types/ssr-types.service.interface";
import {useServices} from "../../hooks/use-services.hook";
import styled from "styled-components";
import { ExclamationCircle } from "../../assets/svg/exclamation-circle.icon";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {ISeatViewModel} from "../../services/booking/models/seat-maps/seat-view-model.interface";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../basic-components/buttons/primary-button-inverted";
import {VerticalSpacer} from "../../basic-components/spacing/vertical-spacer";

const HeaderTitleBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-top: ${props => props.theme.spacing.large};
`    

const HeaderIconBox = styled.div`
    margin-bottom: ${props => props.theme.spacing.small};
`

const MessageBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colors.primary};
    line-height: 1;
`


interface SeatSsrValidationDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    seats: ISeatViewModel[];
    ssrType: ISsrType;
}
export const SeatSsrValidationDialogComponent: React.FC<SeatSsrValidationDialogComponentProps> = observer((props) => {
    const services = useServices();
    const removeSeat = async () => {
        props.dialogHandler.accept();
    }

    const keepSeat = () => {
        props.dialogHandler.reject();
    }

    const distinctSeats = props.seats.distinct(seat => seat.seatNumber, seat => seat.seatNumber);

    const getMessage = () => {

        if(distinctSeats.length === 1) {
            return services.language.translationFor(`Your seat {seatNumber} doesn't allow {ssrName}`)
                                    .withParams({seatNumber: distinctSeats[0], ssrName: props.ssrType.name});
        } else {

            return services.language.translationFor(`Your seats {seatsNumbers} don't allow {ssrName}`)
                                    .withParams({seatsNumbers: distinctSeats.join(', '), ssrName: props.ssrType.name});
        }
    }

    const renderTitle = () => {
        return (
            <HeaderTitleBox>
                <HeaderIconBox>
                    <ExclamationCircle />
                </HeaderIconBox>
                {services.language.translate('Warning')}
            </HeaderTitleBox>
        );
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={renderTitle()} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <VerticalSpacer/>
                <MessageBox>
                    {getMessage()}
                </MessageBox>
                <VerticalSpacer size={"extraLarge"}/>
                <PrimaryButton onClick={keepSeat}>
                    {
                        distinctSeats.length === 1
                            ? services.language.translate('I want to keep my seat')
                            : services.language.translate('I want to keep my seats')
                    }
                </PrimaryButton>
                <VerticalSpacer/>
                <PrimaryButtonInverted onClick={removeSeat}>
                    {
                        distinctSeats.length === 1
                            ? services.language.translate('I will choose a different seat')
                            : services.language.translate('I will choose different seats')
                    }
                </PrimaryButtonInverted>
            </StandardDialogContentComponent>

        </StandardDialogPageComponent>
    );
});

