import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useServices} from "../../../hooks/use-services.hook";
import {AirlineSmallLogoIcon} from "../../../assets/svg/airline-small-logo.icon";

const LogoBox = styled.div<{isHidden: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    visibility: ${props => props.isHidden ? "hidden" : "visible"};
    background-color: ${props => props.theme.colors.primaryContrast};
    border-radius: 50%;
    cursor: pointer;
`

interface PageLogoButtonSmallScreenComponentProps {
    isHidden: boolean;
    onClick?: () => void;
}
export const PageLogoButtonSmallScreenComponent: React.FC<PageLogoButtonSmallScreenComponentProps> = observer((props) => {
    const services = useServices();
    const onClickHandler = async () => {
        if(props.onClick) {
            props.onClick();
        } else {
            await services.navigator.goHome();
        }
    }
    return (
        <LogoBox isHidden={Boolean(props.isHidden)} onClick={onClickHandler}>
            <AirlineSmallLogoIcon size={1.5} sizeIsHeight={true}/>
        </LogoBox>
    )
});
