import {IServiceFactory} from "../../service-factory.interface";
/**
 * import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
 * import {initializeApp} from "firebase/app";
 * import {getAnalytics} from "firebase/analytics";
 */
import {IAnalyticsPurchaseEvent} from "../../booking/models/analytics/google-analytics-purchase.interface";
import {
    IAnalyticsExtrasSelectionEvents,
    IAnalyticsFlightSelectionEvents,
    IAnalyticsPassengerDetailsEvents, IAnalyticsPaymentEvents, IAnalyticsSeatSelectionEvents
} from "../../booking/models/analytics/google-analytics.intefaces";
import {IAnalyticsStrategy} from "../analytics.service.interface";
import {AnalyticsFlightSelectionEvents} from "./google-analytics/analytics-flight-selection.events";
import {AnalyticsPassengerDetailsEvents} from "./google-analytics/analytics-passenger-details.events";
import {AnalyticsSeatSelectionEvents} from "./google-analytics/analytics-seat-selection.events";
import {AnalyticsExtrasSelectionEvents} from "./google-analytics/analytics-extras-section.events";
import {AnalyticsPaymentsEvents} from "./google-analytics/analytics-payments.events";

interface IGaDataLayer {
    push(data: any): void;
}

export class GoogleAnalyticsStrategy implements IAnalyticsStrategy {
    constructor(private readonly services: IServiceFactory) {
        if (this.services.device.isWeb) {
            this._initWebGoogleAnalytics();
        }
        this.flightSelectionEvents = new AnalyticsFlightSelectionEvents(services, this);
        this.passengerDetailsEvents = new AnalyticsPassengerDetailsEvents(services, this);
        this.seatSelectionEvents = new AnalyticsSeatSelectionEvents(services, this);
        // this.extrasSelectionEvents = new AnalyticsExtrasSelectionEvents(services, { sendEvent: () =>{}});
        this.extrasSelectionEvents = new AnalyticsExtrasSelectionEvents(services, this);
        this.paymentEvents = new AnalyticsPaymentsEvents(services, this);
    }

    private _initWebGoogleAnalytics() {
        /*const firebaseConfig = {
            apiKey: "AIzaSyCHdgqli9at875cXrxlQEvgehkp7aLXRzE",
            authDomain: "aeroitalia-c90ab.firebaseapp.com",
            databaseURL: "https://aeroitalia-c90ab-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "aeroitalia-c90ab",
            storageBucket: "aeroitalia-c90ab.appspot.com",
            messagingSenderId: "692637314042",
            appId: "1:692637314042:web:2a75a068d2d6b4407f9243",
            measurementId: "G-M166PDRQ9Z"
        }
        FirebaseAnalytics.initializeFirebase(firebaseConfig).then(() => {
            const app = initializeApp(firebaseConfig);
            getAnalytics(app);
        });*/
    }

    logSignUpEvent() {
        /*
        FirebaseAnalytics.logEvent({
            name: "sign_up",
            params: {
                method: "Mobile App"
            }
        });*/
    }

    logLoginEvent() {
        /*
        FirebaseAnalytics.logEvent({
            name: "login",
            params: {
                method: "Mobile App"
            }
        });

         */
    }
    private get dataLayer(): IGaDataLayer {
        return (window as any).dataLayer;
    }

    logPurchaseEvent(recordLocator: string, event: IAnalyticsPurchaseEvent) {
        let {items, ...otherParams} = event;

        const data = {
            name: "purchase",
            params: {
                ...otherParams,
                transaction_id: `${recordLocator}_${this.services.time.currentDate.getTime()}`,
                items: items.map((item, index) => {
                    return {
                        ...item,
                        index: index,
                        item_category3: this.services.device.isIOS ? "IOS App Mobile" : 'Android App Mobile'
                    }
                })
            }
        };
        // console.log('GA purchase event:');
         console.log(data);

        //FirebaseAnalytics.logEvent(data);
    }

    flightSelectionEvents: IAnalyticsFlightSelectionEvents;
    passengerDetailsEvents: IAnalyticsPassengerDetailsEvents;
    seatSelectionEvents: IAnalyticsSeatSelectionEvents;
    extrasSelectionEvents: IAnalyticsExtrasSelectionEvents;
    paymentEvents: IAnalyticsPaymentEvents;

    sendEvent(event: any): void {
        if(!this.dataLayer) {
            this.services.logger.warning("GA: datalayer was not defined ");
            return;
        }
        // console.log('GA search event:');
        this.dataLayer.push(event);
    }
}

