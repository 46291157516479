import React from "react";
import {observer} from "mobx-react";
import {IBookingSsrsAggregatorViewModel} from "../../../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import styled, {css} from "styled-components";
import {IJourneySsrsBucketViewModel} from "../../../../../../services/booking/models/ssrs/journey-ssrs-bucket-view-model.interface";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../../../components/designator/designator.component";
import {ISsrViewModel} from "../../../../../../services/booking/models/ssrs/ssr-view-model.interface";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {InfoMessageComponent} from "../../../../../../components/info-message/info-message.component";

const ExtrasSsrsSectionPurchaseSummarySmallScreenBox = styled.div<{hasSomeSsrs: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.spacing.medium};
  line-height: 1.1;
  height: 100%;
  
}
`

const JourneySsrCountBox = styled.div<{hasAnyPurchases: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    min-height: 50%;
    font-size: ${props => props.theme.fontSize.xSmall};
    border-radius: ${props => props.theme.border.defaultRadius};
    padding: ${props => props.theme.spacing.medium};
    width: 100%;
    color: ${props => props.theme.colors.primaryShade};
    
  
    ${
      props => props.hasAnyPurchases 
                ? css`
                    background-color: ${props => props.theme.colors.tertiaryTint};
                    font-weight: ${props => props.theme.fontWeight.bold};
                `
                : css`
                    background-color: initial;
                    border: 1px solid ${props => props.theme.colors.tertiary};
                `
    }
`

const DesignatorWrapper = styled(DesignatorComponent)`
    justify-content: flex-start;
    margin-bottom: ${props => props.theme.spacing.small};
`

const SsrTypeCountBox = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -webkit-align-items: flex-start;
    text-align: left;
`

const SsrTypeCountComponent: React.FC<{ssr: ISsrViewModel}> = observer((props) => {
    return <SsrTypeCountBox>
        {`${props.ssr.totalQuantity} x ${props.ssr.ssrType.name}`}
    </SsrTypeCountBox>
});


const JourneySsrsCountComponent: React.FC<{journeySsrsBucket: IJourneySsrsBucketViewModel}> = observer((props) => {
    const services = useServices();
    const purchasedSsrs = props.journeySsrsBucket.getAllSsrsInTheBucket().filter(ssr => ssr.totalQuantity > 0);

    const renderJourneyPurchases = () => {
        if(purchasedSsrs.length === 0) {
            return null;
        }
        return purchasedSsrs.map(ssr => (<SsrTypeCountComponent key={ssr.ssrType.ssrCode} ssr={ssr}/> ));
    }

    const renderNoPurchasesMessage = () => {
        if(purchasedSsrs.length > 0) {
            return null;
        }

        return (
            <NoPurchaseInCategoryBox>
                {services.language.translate('No purchases in this category for this flight')}
            </NoPurchaseInCategoryBox>
        );
    }

    return (
        <JourneySsrCountBox hasAnyPurchases={purchasedSsrs.length > 0}>
            <DesignatorWrapper designator={props.journeySsrsBucket.parentJourney.designator} displayMode={DesignatorDisplayMode.stationsCodeOnly}/>
            {renderJourneyPurchases()}
            {renderNoPurchasesMessage()}
        </JourneySsrCountBox>
    )
});

const NoPurchaseInCategoryBox = styled(InfoMessageComponent)`
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.fontSize.small};
  width: 100%;
  height: 100%;
`

export const ExtrasSsrsSectionPurchaseSummarySmallScreenComponent: React.FC<{ssrsAggregator: IBookingSsrsAggregatorViewModel}> = observer((props) => {
    const services = useServices();

    const hasAnyPurchase = props.ssrsAggregator.journeysSsrsBuckets.some(b => b.getAllSsrsInTheBucket().some(ssr => ssr.totalQuantity > 0));

    const renderPurchases = () => {
        if(!hasAnyPurchase) {
            return null;
        }
        return props.ssrsAggregator.journeysSsrsBuckets.map(journeyBucket => {
            return (
                <JourneySsrsCountComponent key={journeyBucket.uniqueKey} journeySsrsBucket={journeyBucket}/>
            )
        });
    }

    const renderNoPurchasesMessage = () => {
        if(hasAnyPurchase) {
            return null;
        }

        return (
            <NoPurchaseInCategoryBox>
                {services.language.translate('No purchases in this category')}
            </NoPurchaseInCategoryBox>
        )

    }

    return (
        <ExtrasSsrsSectionPurchaseSummarySmallScreenBox hasSomeSsrs={props.ssrsAggregator.hasPurchases}>
            {renderPurchases()}
            {renderNoPurchasesMessage()}
        </ExtrasSsrsSectionPurchaseSummarySmallScreenBox>
    );
})
