import React, {useState} from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled, { css } from 'styled-components';
import {
    IJourneyHistoryViewModel
} from "../../../../services/booking-history/models/journey-history-view-model.interface";
import { MyTripsDialogTabEnum } from './my-trips-dialog-tab.enum';
import { TripsSectionComponent } from './trips-section.component';

const TabHeader = styled.div`
    font-size: ${props => props.theme.fontSize.xxLarge};
    margin: ${props => props.theme.spacing.large} 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2px;
    position: relative;
    border-bottom: 4px solid ${props => props.theme.colors.secondary};
`

const TabHeaderItemBox = styled.div<{isSelected: boolean}>`
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1;
    text-align: center;
    min-height: 40px;
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${props => props.theme.fontWeight.bold};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;

    ${props => props.isSelected ? css`
        background: ${props.theme.colors.secondary};
        color: ${props.theme.colors.secondaryContrast};
    ` : `
        background: ${props.theme.colors.medium};
        color: ${props.theme.colors.primaryContrast};
    `}
`

interface MyTripsComponentProps {
    futureFlights: IJourneyHistoryViewModel[];
    pastFlights: IJourneyHistoryViewModel[];
}
export const MyTripsComponent: React.FC<MyTripsComponentProps> = observer((props) => {
    const services = useServices();
    const [currentTab, setCurrentTab] = useState(MyTripsDialogTabEnum.Future);
    const myFutureFlights = props.futureFlights;
    const myPastFlights = props.pastFlights;


    const renderTabContent = () => {
        if(currentTab === MyTripsDialogTabEnum.Future) {
            return (
                <TripsSectionComponent key={"MY_FUTURE_TRIPS"}
                                       flights={myFutureFlights}
                                       noFlightsMessage={services.language.translate('No upcoming trips')}/>
            );
        }
        return (
            <TripsSectionComponent key={"MY_PAST_TRIPS"}
                                   flights={myPastFlights}
                                   noFlightsMessage={services.language.translate('No past trips')}/>
        );
    }

    const changeCurrentTab = (tab: MyTripsDialogTabEnum) => {
        setCurrentTab(tab);
    }

    return (
      <>
        <TabHeader>
            <TabHeaderItemBox key={"FUTURE_TRIPS_TAB_HEADER"} isSelected={currentTab === MyTripsDialogTabEnum.Future}
                                onClick={() => changeCurrentTab(MyTripsDialogTabEnum.Future)}>
                {services.language.translate('Future trips')}
            </TabHeaderItemBox>
            <TabHeaderItemBox key={"PAST_TRIPS_TAB_HEADER"} isSelected={currentTab === MyTripsDialogTabEnum.Past}
                                onClick={() => changeCurrentTab(MyTripsDialogTabEnum.Past)}>
                {services.language.translate('Past trips')}
            </TabHeaderItemBox>
        </TabHeader>
        {renderTabContent()}        
      </>
    );
});

