import React from "react";
import {IMyAccountDashboardItem} from "./my-account-dashboard-item.interface";
import {IServiceFactory} from "../../services/service-factory.interface";

export abstract class MyAccountDashboardItemBase implements IMyAccountDashboardItem {
    constructor(protected readonly services: IServiceFactory) {
    }
    abstract get text(): string;
    abstract renderIcon(): React.ReactElement;
    protected abstract _onExecute(): Promise<void>;
    protected _requiresLogin(): boolean {
        return true;
    }

    async execute(): Promise<void> {
        if(this._requiresLogin()) {
            await this.services.user.login();
            if(this.services.user.isAuthorized) {
                await this._onExecute();
            }
        } else {
            await this._onExecute();
        }

    }
}
