import React from "react";
import {NullableNumber, NullableString} from "../../types/nullable-types";
import {ICheckInRestriction} from "../booking/models/check-in-restrictions/check-in-restriction.interface";
import {PassengerSegmentSsrEditorModel} from "../booking/models/ssrs/passenger-segment-ssr-editor.model";
import {ISeatViewModel} from "../booking/models/seat-maps/seat-view-model.interface";
import {SsrCategoryEnum} from "./ssr-category.enum";
import {TranslationWithParams} from "../language/translation-with-params";
import {IBookingViewModel} from "../booking/models/booking-view-model.interface";
import {IJourneyViewModel} from "../booking/models/journey/journey-view-model.interface";

export const SSR_STANDARD_LARGE_ICON_SIZE = 3;

export enum SsrQuantityEditorStyle {
    default,
    checkBox,
    plusMinusButtons
}

export interface ISsrType {
    readonly ssrCode: string;
    readonly name: string;
    readonly isAvailable: boolean;
    readonly inBundleName: string;
    readonly details: NullableString;
    readonly inBundleDetails: NullableString;
    readonly smallIcon: React.ReactElement | null;
    readonly largeIcon: React.ReactElement | null;
    readonly largeIconDisabled: React.ReactElement | null;
    readonly iconInExtras: React.ReactElement | null;
    readonly shouldBlockOnlineCheckIn: boolean;
    readonly shouldBeSoldPerSegment: boolean;
    readonly preferredOrderInLists: number;
    readonly shouldLockCommittedQuantity: boolean;
    readonly preferredQuantityEditorStyle: SsrQuantityEditorStyle;
    readonly category: SsrCategoryEnum;
    readonly isSoldOnlyInBundle: boolean;
    /**
     * Another SSR that this SSR is an alias for
     */
    readonly aliasFor: ISsrType | null;
    /**
     * Maximum quantity allowed for a group of SSRs
     */
    readonly sharedMaxQuantity: NullableNumber;
    /**
     * If false the SSR will not be shown in the Extras section of the flight itinerary page
     */
    readonly shouldShowInFlightItineraryExtras: boolean;
    /**
     * If false the bundle included SSR will not be shown in shopping cart extras section
     */
    readonly shouldShowBundleIncludedInShoppingCart: boolean;
    readonly shouldShowInShoppingCart: boolean;
    readonly shouldWriteQuantityChangeStatusInSessionStorage: boolean;
    readonly canBeRefunded: boolean;
    getOnlineCheckInRestrictions(): ICheckInRestriction[];
    getBookingNote(booking: IBookingViewModel): NullableString;
    getRelatedSsrsThatShareTheSameMaxQuantity(): ISsrType[];
    getCustomMessageForMaxLimitPerPassengerReached(): NullableString;
    renderInBundle(quantity: number): React.ReactElement;
    isSeatRestricted(seat: ISeatViewModel): boolean;
    computeLimitPerPassengerQuantity(passengerSegment: PassengerSegmentSsrEditorModel): number;
    getPurchaseBlockingMessage(journey: IJourneyViewModel): NullableString;
    canBeShownOnBookingFlow(booking: IBookingViewModel): boolean;
    canBeShownOnCheckInFlow(booking: IBookingViewModel): boolean;
    canBeShownOnManageMyBookingFlow(booking: IBookingViewModel): boolean;
}

export interface ISsrTypesService {
    getSsrType(ssrCode: string): ISsrType;
    getAllCheckInBags(): ISsrType[];
    getCheckInBagsThatCanBeSoldIndividually(): ISsrType[];
    getSpecialAssistanceSsrTypes(): ISsrType[];
    getSpecialEquipmentSsrTypes(): ISsrType[]
    getMealsSsrTypes(): ISsrType[];
    getPetsSsrTypes(): ISsrType[];
    translateSsr(key: string): string;
    translationForSsr(key: string): TranslationWithParams;
    isSeat(ssrType: ISsrType): boolean;
    getAliases(ssrType: ISsrType): ISsrType[];

    /**
     * Some SSRS should not be shown together.
     * For example SCBG should not be shown if the passenger has also PBRD
     * @param ssrTypes
     */
    filterVisibleSsrs<TSsrWrapper extends {ssrType: ISsrType} | ISsrType>(ssrTypes: TSsrWrapper[]): TSsrWrapper[];

    /**
     * Priority Boarding
     */
    readonly PBRD: ISsrType;

    /**
     * Bag 10kg
     */
    readonly XSBG: ISsrType;

    /**
     * Bag 23kg
     */
    readonly SBAG: ISsrType;
    /**
     * Bag 32kg
     */
    readonly BAG: ISsrType;

    /**
     * Small cabin bag
     */
    readonly SCBG: ISsrType

    /**
     * Big cabin bag
     */
    readonly BCBG: ISsrType

    /**
     * Seat
     */
     readonly STST: ISsrType

    /**
     * Extra legroom seat
     */
    readonly STEX: ISsrType


    /**
     * Flex
     */
    readonly FLX: ISsrType;

    /**
     * ACheck-in
     */
    readonly CKN: ISsrType;

    /**
     * Early check-in
     */
    readonly ECKN: ISsrType;


    /**
     * Pet in cabin
     */
    readonly PETC: ISsrType;

    /**
     * Pet in hold
     */
    readonly AVIH: ISsrType;

    /**
     * Minor neinsotit
     */
    readonly UM: ISsrType;

    /**
     * Online check-in
     */
     readonly LCKN: ISsrType;

    /**
     * Insurance
     */
    readonly INS1: ISsrType;
    readonly SBUS: ISsrType;
    readonly LOU: ISsrType;
    readonly FAST: ISsrType;
    readonly PSO: ISsrType;
}
