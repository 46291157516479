import styled, { css } from "styled-components";

export enum ExtrasTileBannerType {
    Important,
    NewProduct
}

export const ExtrasSectionBannerSmallScreenBox = styled.div<{type: ExtrasTileBannerType}>`
    --r: 12px; /* control the ribbon shape */
    
    align-self: flex-start;
    min-width: 70%;
    text-align: left;
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.small};
    margin-top: ${props => `-${props.theme.spacing.medium}`};
    margin-left: ${props => `-${props.theme.spacing.medium}`};
    line-height: 1;
    clip-path: polygon(0% 0%, 100% 0%, calc(100% - var(--r)) 50%, 100% 100%, 0% 100%);
    padding: 4px calc(4px + var(--r)) 4px 4px;

    ${props => props.type === ExtrasTileBannerType.Important && css`
            background-color: ${props => props.theme.colors.warning};
            color: ${props => props.theme.colors.warningContrast};
        `
    }

    ${props => props.type === ExtrasTileBannerType.NewProduct && css`
            background-color: ${props => props.theme.colors.success};
            color: ${props => props.theme.colors.successContrast};
        `
    }
`
