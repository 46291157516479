import React from "react";
import {observer} from "mobx-react";
import {IBookingViewModel} from "../../services/booking/models/booking-view-model.interface";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {TicketComponent} from "./ticket.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";

export const TicketDialogComponent: React.FC<{booking: IBookingViewModel; dialogHandler: IDialogConfirmationHandler;}> = observer((props) => {
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler} title={"Ticket"}/>
            <StandardDialogContentComponent>
                <TicketComponent booking={props.booking}/>
            </StandardDialogContentComponent>
        </StandardDialogPageComponent>
    )
})