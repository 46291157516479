import React from 'react';
import {SeatFilterBaseModel} from "./seat-filter-base.model";
import {SeatModel} from "../seat.model";
import {WheelchairIcon} from "../../../../../assets/svg/wheelchair.icon";
import {IServiceFactory} from "../../../../service-factory.interface";

export class SpecialAssistanceSeatFilterModel extends SeatFilterBaseModel {

    constructor(services: IServiceFactory) {
        super(services.language.translate('Seats for special assistance'), services);
    }

    renderIcon(): React.ReactElement {
        return (<WheelchairIcon size={2}/>);
    }

    shouldAllowSeat(seat: SeatModel): boolean {
        return seat.allowsSpecialAssistance;
    }

}
