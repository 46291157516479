import {computed, makeObservable, observable, runInAction} from "mobx";
import {NullableString} from "../../../../../types/nullable-types";
import {IServiceFactory} from "../../../../service-factory.interface";
import {MaturePassengerModel} from "../../passenger/mature-passenger.model";
import {scrollElementIntoViewSmooth} from "../../../../../utils/scroll-element-into-view";
import {PassengerJourneySsrEditorModel} from "../passenger-journey-ssr-editor.model";
import {IPassengerAllBagsViewModel} from "./passenger-all-bags-view-model.interface";
import {JourneyAllBagsBucketModel} from "./journey-all-bags-bucket.model";

export class PassengerAllBagsModel implements IPassengerAllBagsViewModel {
    constructor(public readonly passenger: MaturePassengerModel,
                private readonly allBags: JourneyAllBagsBucketModel) {

        makeObservable(this, {
            _isValidationEnabled: observable.ref,
            _skipCheckInBags: observable.ref,
            checkInBags: computed
        });
    }

    get services(): IServiceFactory {
        return this.allBags.parentJourney.services;
    }

    private _cabinBagsContainerDOMElement: any = null;
    attachCabinBagsContainerDOMElement = (el: any): void => {
        this._cabinBagsContainerDOMElement = el;
    }

    private _checkInBagsContainerDOMElement: any = null;
    attachCheckInBagsContainerDOMElement = (el: any): void => {
        this._checkInBagsContainerDOMElement = el;
    }

    _skipCheckInBags: boolean = false;
    get skipCheckInBags(): boolean {
        return this._skipCheckInBags;
    }

    set skipCheckInBags(value: boolean) {
        this.setSkipCheckInBags(value);

        if(this.allBags.useSameOptionsForAllPassengers) {
            this.allBags.passengersBags.forEach(pBags => {
                if(pBags.passenger.passengerKey !== this.passenger.passengerKey) {
                    pBags.setSkipCheckInBags(value);
                }
            });

            if(this.allBags.useSameOptionsForAllFlights) {
                for(let bucket of this.allBags.getOtherJourneysBuckets()) {
                    const otherJourneyBags = bucket as JourneyAllBagsBucketModel;
                    otherJourneyBags.passengersBags.forEach(pBags => {
                        pBags.setSkipCheckInBags(value);
                    })
                }
            }
        } else if(this.allBags.useSameOptionsForAllFlights) {
            for(let bucket of this.allBags.getOtherJourneysBuckets()) {
                const otherJourneyBags = bucket as JourneyAllBagsBucketModel;
                const samePassengerBagsOnOtherJourney = otherJourneyBags.passengersBags.find(pBags => pBags.passenger.passengerKey === this.passenger.passengerKey)
                samePassengerBagsOnOtherJourney?.setSkipCheckInBags(value);
            }
        }


    }

    setSkipCheckInBags(value: boolean) {
        runInAction(() => {
            this._skipCheckInBags = value;
        });
    }

    get cabinBag(): PassengerJourneySsrEditorModel {
        const ssr = this.allBags.getAllSsrsInTheBucket().find(ssr => ssr.ssrType.ssrCode === this.services.ssrTypes.PBRD.ssrCode);

        if(!ssr) {
            throw new Error('Cant find PBRD for this journey');
        }

        return ssr.passengersSsrEditors.filter(p => p.passengerKey === this.passenger.passengerKey)[0];
    }

    get checkInBags(): PassengerJourneySsrEditorModel[] {
        let checkInBags: PassengerJourneySsrEditorModel[] = [];
        this.allBags.getAllSsrsInTheBucket()
            .filter(ssr => ssr.ssrType.ssrCode !== this.cabinBag.ssrType.ssrCode)
            .forEach(ssr => {
                const passengerSsrEditor = ssr.passengersSsrEditors.find(p => p.passengerKey === this.passenger.passengerKey)
                if(passengerSsrEditor) {
                    checkInBags.push(passengerSsrEditor);
                }
            });

        return checkInBags.sort((b1, b2) => b1.ssrType.preferredOrderInLists - b2.ssrType.preferredOrderInLists);
    }

    get checkInBagsErrorMessage(): NullableString {

        if(!this._isValidationEnabled) {
            return null;
        }

        if(!this.checkInBags.some(b => b.canBeModifiedForAtLeastOneSegment)) {
            return null;
        }

        if(this.skipCheckInBags || this.checkInBags.some(b => b.newQuantity > 0)) {
            return null;
        }

        if(this.allBags.useSameOptionsForAllPassengers && this.passenger.passengerIndex > 0) {
            return null;
        }

        return this.services.language.translate('Please select check-in baggage');
    }

    get hasErrors(): boolean {
        return Boolean(this.cabinBagErrorMessage)
                || Boolean(this.checkInBagsErrorMessage);
    }

    _isValidationEnabled: boolean = false;
    get isValidationEnabled(): boolean {
        return this._isValidationEnabled;
    }
    enableValidations(): void {
        runInAction(() => {
            this._isValidationEnabled = true;
        });
    }

    scrollIntoView(): void {
        if(this.cabinBagErrorMessage) {
            scrollElementIntoViewSmooth(this._cabinBagsContainerDOMElement, {
                inline: "nearest",
                block: "nearest"
            });
        } else if(this.checkInBagsErrorMessage) {
            scrollElementIntoViewSmooth(this._checkInBagsContainerDOMElement, {
                inline: "nearest",
                block: "nearest"
            });
        }

    }

    get cabinBagErrorMessage(): NullableString {

        if(!this._isValidationEnabled) {
            return null;
        }

        if(!this.cabinBag.canBeModifiedForAtLeastOneSegment) {
            return null;
        }

        if(this.cabinBag.newQuantity > 0) {
            return null;
        }

        if(this.cabinBag.newQuantityWasSet) {
            return null;
        }

        if(this.allBags.useSameOptionsForAllPassengers && this.passenger.passengerIndex > 0) {
            return null;
        }

        return this.services.language.translate('Please select cabin baggage');
    }
}
