export enum DotRezPaymentTypeGraphqlEnum {
    ExternalAccount = "ExternalAccount",
    PrePaid = "PrePaid",
    AgencyAccount = "AgencyAccount",
    CustomerAccount = "CustomerAccount",
    Voucher = "Voucher",
    Loyalty = "Loyalty"
}

export enum DotRezPaymentTypeApiEnum {
    ExternalAccount = 0,
    PrePaid = 1,
    AgencyAccount = 2,
    CustomerAccount = 3,
    Voucher = 4,
    Loyalty = 5
}


export enum DotRezPaymentCode {
    Voucher = "VO"
}

export enum DotRezPaymentStatusGraphqlEnum {
    New ="New",
    Received = "Received",
    Pending = "Pending",
    Approved = "Approved",
    Declined = "Declined",
    Unknown = "Unknown",
    PendingCustomerAction = "PendingCustomerAction"
}

export enum DotRezPaymentStatusApiEnum {
    New = 0,
    Received = 1,
    Pending = 2,
    Approved = 3,
    Declined = 4,
    Unknown = 5,
    PendingCustomerAction = 6
}

export function isPendingPaymentStatus(status: DotRezPaymentStatusApiEnum): boolean {
    return status === DotRezPaymentStatusApiEnum.PendingCustomerAction || status === DotRezPaymentStatusApiEnum.Pending;
}

export enum DotRezPaymentAuthorizationStatusGraphqlEnum {
    Unknown = "Unknown", 
    Acknowledged = "Acknowledged", 
    Pending = "Pending", 
    InProcess = "InProcess", 
    Approved = "Approved", 
    Declined = "Declined", 
    Referral = "Referral", 
    PickUpCard = "PickUpCard", 
    HotCard = "HotCard", 
    Voided = "Voided", 
    Retrieval = "Retrieval", 
    ChargedBack = "ChargedBack", 
    Error = "Error", 
    ValidationFailed = "ValidationFailed", 
    Address = "Address", 
    VerificationCode = "VerificationCode", 
    FraudPrevention = "FraudPrevention", 
    ForcedApproval = "ForcedApproval", 
    ForcedDecline = "ForcedDecline"
}

export enum PaymentFieldsNamesEnum {
    WebapiPaymentToken = "WEBAPI_PAYMENT_TOKEN",
    NavitaireRedirectUrl = "NAVITAIRE$REDIRECTURL"

}
