import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {PersonIcon} from "../../../../assets/svg/person.icon";
import styled from "styled-components";
import {GearIcon} from "../../../../assets/svg/gear.icon";

const LoggedInUserInitialsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: solid 1px ${props => props.theme.colors.secondaryContrast};
    color: ${props => props.theme.colors.secondaryContrast};
    font-size: ${props => props.theme.fontSize.small};
    font-weight: bold;
    border-radius: 50%;
    letter-spacing: 2px;
`

const UserAccountIconBox = styled.div`
    cursor: pointer;
`

export const UserAccountIconComponent: React.FC = observer(() => {
    const services = useServices();
    const onIconClick = async () => {
        if(services.configuration.envConfigs.enableLogin) {
            if(services.user.isAuthorized) {
                services.navigator.routes.myAccount.activate();
            } else {
                await services.user.login();
            }
        } else {
            services.navigator.routes.settings.activate();
        }


    };
    const renderIcon = () => {
        if(services.configuration.envConfigs.enableLogin) {
            if(services.user.isAuthorized) {
                return (
                    <LoggedInUserInitialsBox>
                        {services.user.profile.asCompanion().getInitials()}
                    </LoggedInUserInitialsBox>
                )
            }
            return (
                <PersonIcon color={services.theme.currentTheme.colors.secondaryContrast} size={2.6}/>
            );
        } else {
            return (
                <GearIcon />
            );
        }



    }

    return (
        <UserAccountIconBox onClick={onIconClick} className={'tst-user-profile-icon'}>
            {renderIcon()}
        </UserAccountIconBox>
    );
});
