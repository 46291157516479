import {IPassengerSegmentBoardingPassViewModel} from "../../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {CapacitorPassToWallet} from "@jackobo/capacitor-pass-to-wallet";
import {IServiceFactory} from "../../service-factory.interface";
import {IMobileWalletStrategy} from "../mobile-wallet.strategy.interface";
import React from "react";
import {
    AddToAppleWalletButtonComponent
} from "../../../components/add-to-wallet-buttons/apple/add-to-apple-wallet-button.component";

export class AppleWalletStrategy implements IMobileWalletStrategy {
    constructor(private readonly services: IServiceFactory) {
    }

    readonly providerName: string = 'Apple Wallet';
    canAddBoardingPassToAppleWallet(boardingPass: IPassengerSegmentBoardingPassViewModel): boolean {
        return this.services.device.isIOS;
    }

    async addBoardingPassesToWallet(bookingKey: string, boardingPasses: IPassengerSegmentBoardingPassViewModel[]): Promise<void> {
        await this.services.loadingIndicator.execute({
            action: async () => {
                if(this.services.device.isHybrid) {
                    await this._addBoardingPassesToWalletForMobileApp(bookingKey, boardingPasses);
                } else {
                    await this._addBoardingPassesToWalletForWebsite(bookingKey, boardingPasses);
                }
            }
        });

    }

    renderAddToWalletButton(onClickHandler: () => Promise<void>): React.ReactElement {
        return (
            <AddToAppleWalletButtonComponent onClick={onClickHandler}/>
        )
    }


    private async _addBoardingPassesToWalletForMobileApp(bookingKey: string, boardingPasses: IPassengerSegmentBoardingPassViewModel[]): Promise<void> {
        const response = await this.services.airlineWebapi.createAppleBoardingPasses({
            includePassesContent: true,
            bookingKey: bookingKey,
            passengers: boardingPasses.map(bp => {
                return {
                    passengerKey: bp.passengerKey,
                    segmentKey: bp.segmentKey,
                    isInfant: bp.isInfant
                }
            })
        });

        const passesContents = response.passes.filter(p => Boolean(p.passContent)).map(s => s.passContent ?? ""); // the (?? "") here is to make the compiler happy

        if(passesContents.length === 0) {
            this.services.alert.showError(this.services.language.translate('No boarding passes were received from server'));
            return;
        }

        await CapacitorPassToWallet.addToWallet({
            base64PassesContent: passesContents!
        })


    }

    private async _addBoardingPassesToWalletForWebsite(bookingKey: string, boardingPasses: IPassengerSegmentBoardingPassViewModel[]): Promise<void> {
        const response = await this.services.airlineWebapi.createAppleBoardingPassesBundle({
            includePassContent: false,
            bookingKey: bookingKey,
            passengers: boardingPasses.map(bp => {
                return {
                    passengerKey: bp.passengerKey,
                    segmentKey: bp.segmentKey,
                    isInfant: bp.isInfant
                }
            })
        });

        // I gave up downloading the boarding pass content using blob because in this case browser asks every time the user to allow add to wallet
        // Using the URL, browser will ask only first time
        this._downloadPassesBundleByUrl(response.downloadUrl);

        /*
        if(response.bundleContent) {
            try {
                this._downloadPassesBundleByContent(response.bundleContent, response.containsMultiple);
            } catch (err) {
                this.services.logger.error('Failed to download passes bundle by content', err);
                this._downloadPassesBundleByUrl(response.downloadUrl);
            }

        } else {
            this._downloadPassesBundleByUrl(response.downloadUrl);
        }
         */
    }

    private _downloadPassesBundleByUrl(url: string) {
        const a = document.createElement('a');
        a.href = url;
        a.click();
    }

    /*
    private _downloadPassesBundleByContent(bundleContent: string, containsMultiple: boolean): void {
        const blob = this._base64ToBlob(bundleContent, containsMultiple);
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        if(containsMultiple) {
            a.download = 'tickets.pkpasses';
        } else {
            a.download = 'ticket.pkpass';
        }
        a.click();
    }


    private _base64ToBlob(base64: string, containsMultiple: boolean) {
        let mimeType: string;
        if(containsMultiple) {
            mimeType = 'application/vnd.apple.pkpasses';
        } else {
            mimeType = 'application/vnd.apple.pkpass';
        }
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }
    */

}
