export enum SsrCategoryEnum {
    Seat = "Seat",
    Baggage = "Baggage",
    Meals = "Meals",
    SpecialEquipment = "Special equipment",
    Pets = "Pets",
    SpecialAssistance = "Special Assistance",
    BlueBenefits = "Blue Benefits",
    Other = "Other"
}
