import {ValidationResultEnum} from "../../../types/validation-result.enum";
import {FlightSearchStepBase} from "./flight-search-step.base";

export class FlightDatesSelectionStep extends FlightSearchStepBase {

    get title(): string {
        return this.services.language.translate('Dates');
    }

    get route() {
        return this.routes.flightDates;
    }

    async validate(): Promise<ValidationResultEnum> {
        return this.services.flightSearch.searchController.validateFlightDatesSelection();
    }

    async next(): Promise<void> {
        if(ValidationResultEnum.Success === await this.validate()) {
            this._activateStepByRoute(this.routes.passengers);
        }
    }
}
