import {PassengerSegmentSsrEditorModel} from "../ssrs/passenger-segment-ssr-editor.model";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {BundleModel} from "../bundle/bundle.model";

export class SharedQuantitySsrsEditors {
    constructor(...ssrEditors: PassengerSegmentSsrEditorModel[]) {
        ssrEditors.forEach(ssr => this.addRelatedSsr(ssr));
    }
    private _relatedSsrs: Record<string, PassengerSegmentSsrEditorModel> = {};

    addRelatedSsr(ssrEditor: PassengerSegmentSsrEditorModel): void {
        this._relatedSsrs[ssrEditor.ssrType.ssrCode] = ssrEditor;
    }

    containsSsrType(ssrType: ISsrType): boolean {
        return Boolean(this._relatedSsrs[ssrType.ssrCode]);
    }

    getRelatedSsrs(): PassengerSegmentSsrEditorModel[] {
        return Object.keys(this._relatedSsrs).map(key => this._relatedSsrs[key]);
    }

    getSsrKeysToRemoveOnBundleChange(newBundle: BundleModel): string[] {
        let ssrKeysToRemove: string[] = [];

        const relatedSsrs = this.getRelatedSsrs();


        const maxAllowedQuantity = relatedSsrs[0].ssrType.sharedMaxQuantity || relatedSsrs[0].ssrAvailability.limitPerPassenger;
        let quantityToRemove =
            relatedSsrs.sum(ssr => {
                const quantityAddedByTheNewBundle = newBundle.includedSsrs.some(ssrType => ssrType.ssrType.ssrCode === ssr.ssrType.ssrCode) ? 1 : 0;
                const quantityRemovedBecauseOfReplacingTheCurrentBundle = ssr.quantityIncludedInBundle; //basically the quantity included in the current bundle
                return ssr.currentQuantity
                    + quantityAddedByTheNewBundle
                    - quantityRemovedBecauseOfReplacingTheCurrentBundle;
            }) - maxAllowedQuantity;


        if(quantityToRemove <= 0) {
            return [];
        }

        for(let ssr of relatedSsrs) {
            const ssrKeys = ssr.getSsrKeysToRemove(quantityToRemove);
            ssrKeysToRemove = [
                ...ssrKeysToRemove,
                ...ssrKeys
            ];

            quantityToRemove -= ssrKeys.length;
            if(quantityToRemove <= 0) {
                break;
            }
        }

        return ssrKeysToRemove;
    }

}
