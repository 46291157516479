import styled from "styled-components";

export const InfoMessageBox = styled.div`
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colors.medium};
    background-color: ${props => props.theme.colors.light};
    border-radius: ${props => props.theme.border.defaultRadius};
    line-height: 1;

    svg {
        margin-right: ${props => props.theme.spacing.medium};
    }
`
