import React from "react";
import {ButtonBase, ButtonColors, ConcreteButtonProps} from "./button-base";
import {useServices} from "../../hooks/use-services.hook";
import {observer} from "mobx-react-lite";

interface SecondaryButtonProps extends ConcreteButtonProps {
}
export const SecondaryButton: React.FC<SecondaryButtonProps> = observer((props) => {
    const services = useServices();
    const themeColors = services.theme.currentTheme.colors;

    const buttonColors: ButtonColors = {
        background: themeColors.tertiaryTint,
        color: themeColors.primary,
        border: themeColors.tertiaryTint,
        backgroundActivated: themeColors.primaryContrast,
        colorActivated: themeColors.primary,
        borderActivated: themeColors.tertiaryTint
    }
    return (
        <ButtonBase {...props} colors={buttonColors}>
            {props.children}
        </ButtonBase>
    );
});
