import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {IPassengerSsrEditorViewModel} from "../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {SeatSsrValidationDialogComponent} from "../../../../components/seat-ssr-validation-dialog/seat-ssr-validation-dialog.component";
import {FootNoteComponent} from "../../../../components/foot-note/foot-note.component";
import {IServiceFactory} from "../../../../services/service-factory.interface";
import {PriceWithDiscountComponent} from "../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../components/price/standard-price-label.component";
import {SsrDecrementButtonComponent, SsrIncrementButtonComponent} from "./ssr-quantity-edit-buttons";
import {SsrModificationBlockingReasonComponent} from "../ssr-modification-blocking-reason.component";

const PassengerSsrQuantityEditorBox = styled.div<{isBlockedForModifications: boolean}>`
    display: flex;
    flex-direction: column;
`

const DetailsBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SsrBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    line-height: 1.2;
`

const SsrNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const PriceBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.bold};
`


const SsrQuantityBox = styled.div`
    width: 44px;
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-align: center;
`

const QuantityPlusMinusButtonsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`


async function incrementQuantity(passengerSsrEditor: IPassengerSsrEditorViewModel, services: IServiceFactory, onIncrement: () => void): Promise<void> {
    const restrictedSeats = passengerSsrEditor.getRestrictedSeats();
    if(restrictedSeats.length > 0) {
        await services.dialog.showSheetModalDialog({
            backdropDismiss: false,
            render: modalHandler => (<SeatSsrValidationDialogComponent dialogHandler={modalHandler}
                                                                       seats={restrictedSeats}
                                                                       ssrType={passengerSsrEditor.ssrType}/>),
            onAccept: async () => {
                onIncrement();
                await services.loadingIndicator.execute({
                    action: async () => {
                        for(let seat of restrictedSeats) {
                            if(seat.assignedTo) {
                                await seat.assignedTo.removeSeat();
                            }
                        }
                        await services.booking.current.sellSsrs({skipDebounce: true});
                        await services.booking.current.mutationsManager.waitForMutations();
                    }
                });

                const passengerSegment = services.booking.current.getAllPassengersSegments().find(p => p.passengerKey === passengerSsrEditor.passengerKey && !p.hasSeat);

                if(passengerSegment) {
                    services.booking.current.seatsMapsEditors.setCurrentPassengerSegment(passengerSegment);
                }

                await services.dialogFactory.showSeatsSelection();
            }
        });
    } else {
        onIncrement();
    }
}



interface QuantityPlusMinusButtonsComponentProps {
    passengerSsrEditor: IPassengerSsrEditorViewModel;
}

const QuantityPlusMinusButtonsComponent: React.FC<QuantityPlusMinusButtonsComponentProps> = observer((props) => {
    const services = useServices();

    const canIncrement = props.passengerSsrEditor.canBeModifiedForAtLeastOneSegment && props.passengerSsrEditor.newQuantity < props.passengerSsrEditor.maxAllowedQuantity;
    const canDecrement = props.passengerSsrEditor.canBeModifiedForAtLeastOneSegment && props.passengerSsrEditor.newQuantity > props.passengerSsrEditor.minAllowedQuantity;




    const onIncrementQuantity = async () => {
        if(!canIncrement) {
            return;
        }
        await incrementQuantity(props.passengerSsrEditor,
            services,
            () => {
                props.passengerSsrEditor.newQuantity++;
            });
    }

    const onDecrementQuantity = () => {
        if(!canDecrement) {
            return;
        }
        props.passengerSsrEditor.newQuantity--;
    }

    return(
        <QuantityPlusMinusButtonsBox>
            <SsrDecrementButtonComponent disabled={!canDecrement}
                                         onClick={onDecrementQuantity}/>
            <SsrQuantityBox>
                {props.passengerSsrEditor.newQuantityToDisplay}
            </SsrQuantityBox>
            <SsrIncrementButtonComponent disabled={!canIncrement}
                                         onClick={onIncrementQuantity}/>
        </QuantityPlusMinusButtonsBox>
    )
});


interface PassengerSsrQuantitySelectionComponentProps {
    passengerSsrEditor: IPassengerSsrEditorViewModel;
}

export const PassengerSsrQuantityEditorComponent: React.FC<PassengerSsrQuantitySelectionComponentProps> = observer((props) => {

    const renderSsrDetails = () => {
        if (!props.passengerSsrEditor.ssrType.details) {
            return null;
        }
        return (<FootNoteComponent>{props.passengerSsrEditor.ssrType.details}</FootNoteComponent>);
    }

    const isBlockedForModifications = !props.passengerSsrEditor.canBeModifiedForAtLeastOneSegment;

    return (
        <PassengerSsrQuantityEditorBox isBlockedForModifications={isBlockedForModifications}>
            <DetailsBox>
                <SsrBox>
                    <SsrNameBox>
                        {props.passengerSsrEditor.ssrType.name}
                    </SsrNameBox>
                    {renderSsrDetails()}
                    <PriceBox>
                        <PriceWithDiscountComponent discountedPrice={props.passengerSsrEditor.discountedMinPrice}
                                                    standardPrice={props.passengerSsrEditor.standardMinPrice}
                                                    label={<StandardPriceLabelComponent/>}/>
                    </PriceBox>
                </SsrBox>

                <QuantityPlusMinusButtonsComponent passengerSsrEditor={props.passengerSsrEditor}/>
            </DetailsBox>
            <SsrModificationBlockingReasonComponent reason={props.passengerSsrEditor.modificationBlockingReason}/>
        </PassengerSsrQuantityEditorBox>
    )
});
