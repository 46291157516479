import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";

export class UpdateBookingDataMutation extends BookingMutationActionBase {

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        return  await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();
    }

    get willAffectTotal(): boolean {
        return true;
    }
}
