import {BOOKING_NOT_INITIALIZED_MSG, IBookingStrategy} from "../booking-strategy.interface";
import {AvailabilityModel} from "../../../flight-search/models/availability.model";
import {BookingModel} from "../../models/booking.model";
import {IServiceFactory} from "../../../service-factory.interface";
import {IFlightSearchControllerViewModel} from "../../../flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {RoundTripLowFareReaderFactory} from "../../../low-fare/round-trip-low-fare-reader-factory";
import {IRoundTripLowFareReader} from "../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {IWizard} from "../../../../models/wizard/wizard.interface";
import {EmptyWizard} from "../../../../models/wizard/empty-wizard";

export class NoBookingStrategy implements IBookingStrategy {

    constructor(private readonly services: IServiceFactory) {

    }

    get flightSearchController(): IFlightSearchControllerViewModel {
        return this.services.flightSearch.searchController;
    }


    get activeCurrency(): string {
        return this.services.currency.current;
    }

    setNewAvailability(availability: AvailabilityModel): void {

    }

    get availability(): AvailabilityModel {
        throw new Error(BOOKING_NOT_INITIALIZED_MSG);
    }
    get booking(): BookingModel {
        throw new Error(BOOKING_NOT_INITIALIZED_MSG);
    }

    readonly steps: IWizard = new EmptyWizard();

    async dispose(): Promise<void> {
        this._lowFaresReaderFactory?.dispose();
    }


    async upgradeCurrentBookingSessionToAuthorizedUser(userName:string, password:string): Promise<void> {

    }

    private _lowFaresReaderFactory: RoundTripLowFareReaderFactory | null = null;
    getLowFaresReader(): IRoundTripLowFareReader {
        if(!this._lowFaresReaderFactory) {
            this._lowFaresReaderFactory = new RoundTripLowFareReaderFactory(
                this.services,
                this.flightSearchController,
                false,
                () => this.activeCurrency);
        }
        return this._lowFaresReaderFactory.lowFareReader;
    }
}
