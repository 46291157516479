import React from "react";
import {observer} from "mobx-react-lite";
import {
    ExtrasSectionLargeScreenComponent
} from "../../../base-component/large-screen/extras-section.large-screen.component";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {SeatMapsComponent} from "../../../../seat-selection/components/seat-maps.component";
import {ExtrasSectionLargeScreenComponentCommonProps} from "../../../base-component/large-screen/extras-section.large-screen-component-common.props";
import {ExtrasSeatsSummaryLargeScreenComponent} from "./extras-seats-summary.large-screen.component";


export const ExtrasSeatsLargeScreenComponent: React.FC<ExtrasSectionLargeScreenComponentCommonProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const hasSomeSeats = services.booking.current.getAllPassengersSegments().some(s => s.hasSeat);

    return (
        <ExtrasSectionLargeScreenComponent sectionTitle={services.language.translate('Seats')}
                                           renderIcon={() => services.ssrTypes.STST.iconInExtras}
                                           renderContent={(headerElementRef) => <SeatMapsComponent stickyTopRef={headerElementRef} headerBackgroundColor={services.theme.currentTheme.colors.lightTint}/>}
                                           modificationBlockingReason={null}
                                           hasPurchases={hasSomeSeats}
                                           minDiscountedPrice={booking.minSeatsFee}
                                           minStandardPrice={booking.minSeatsFee}
                                           renderPurchaseSummary={() => <ExtrasSeatsSummaryLargeScreenComponent/>}
                                           {...props}/>
    )
});
