import {createGlobalStyle} from "styled-components";
import {CssRules} from "./helpers/css-rules";
export const SCROLLBAR_WIDTH = '7px';

export const GlobalStyles = createGlobalStyle`

    @font-face {
        font-family: 'Jaldi';
        src: url('${props => props.theme.assets.fonts.Jaldi_Bold_eot.toString()}');
        src:  url('${props => props.theme.assets.fonts.Jaldi_Bold_woff.toString()}') format('woff'),
                url('${props => props.theme.assets.fonts.Jaldi_Bold_ttf.toString()}') format('truetype');
        font-weight: bold;
        font-style: normal;
    } 

    @font-face {
        font-family: 'Jaldi';
        src: url('${props => props.theme.assets.fonts.Jaldi_Regular_eot.toString()}');
        src:  url('${props => props.theme.assets.fonts.Jaldi_Regular_woff.toString()}') format('woff'),
                url('${props => props.theme.assets.fonts.Jaldi_Regular_ttf.toString()}') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

   
    // Chrome and Safari scrollbar
    *::-webkit-scrollbar {
      width: ${SCROLLBAR_WIDTH};
    }

    *::-webkit-scrollbar-track {
      background-color: rgba(${props => props.theme.colors.mediumRgb}, 0.1);
    }

    *::-webkit-scrollbar-thumb {
      background-color: rgba(${props => props.theme.colors.mediumRgb}, 0.5);
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: rgba(${props => props.theme.colors.mediumRgb}, 0.8);
    }
    
    body {
        --ion-font-family: 'Jaldi', sans-serif;
        font-weight: ${props => props.theme.fontWeight.normal};
    }

    ion-item-option {
      background-color: transparent;
    }
    

    ion-toggle {
        --background-checked: ${props => props.theme.colors.secondary};
    }
    
    ion-header {
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.primaryContrast};
        &:after {
            display: none;
        }
    }

    ion-toolbar {
      --background: ${props => props.theme.colors.primary};
      --color: ${props => props.theme.colors.primaryContrast};
    }

    html.ios ion-modal ion-toolbar {
        padding-right: var(--ion-safe-area-right);
        padding-left: var(--ion-safe-area-left);
    }

    ion-footer {
        &:before {
            display: none;
        }
    }

    ion-card {
        margin: 24px 16px;
        border-radius: 8px;
    }

    .bags-info-popover .popover-content {
      width: 90%;
    }
    .shopping-cart-notifications-popover .popover-content {
      border: solid 1px rgba(${props => props.theme.colors.mediumRgb}, 0.75);
      background-color: ${props => props.theme.colors.light};
    }

    ${CssRules.DarkMode} {
        ion-button {
            --color: ${props => props.theme.colors.secondaryContrast};
        }
      
        ion-card {
            background-color: ${props => props.theme.colors.lightTint};
            --color: ${props => props.theme.colors.lightContrast};
        }

        ion-footer {
            background-color: ${props => props.theme.colors.lightTint};
        }

        .list-ios,
        .list-md {
            background: none;
        }
     }

`
