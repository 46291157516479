import {IBookingMutationAction} from "../booking-mutation-action.interface";
import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {MutationsActionsAggregator} from "../mutations-actions-aggregator";

export class SeatsMutationsAggregator extends MutationsActionsAggregator implements IBookingMutationAction {
    get willAffectTotal(): boolean {
        return true;
    }

    async execute(): Promise<IDotRezPartialBookingSessionData> {
        return this.waitForMutations();
    }

    async waitForMutation(): Promise<void> {
        try {
            await this.waitForMutations();
        } catch (err) {
            this.booking.services.logger.error('waitForMutation in SeatsMutationsAggregator failed', err);
        }
    }

    async onAfterBookingSessionDataUpdated(): Promise<void> {

    }

    onError(err: any): void {
    }

    protected async _executeMutation(mutation: IBookingMutationAction): Promise<IDotRezPartialBookingSessionData> {
        const mutationResult = await mutation.execute({shouldConsumeFlex: false});

        if(this._inProgressMutations.length === 1) {
            const bookingDataResponse = await this.booking.session.bookingStateQueryBuilder().useBookingData().getBookingState();
            const flexConsumptionResponse = await this.booking.consumeFlex(bookingDataResponse.bookingData);
            return {
                ...mutationResult,
                ...bookingDataResponse,
                ...flexConsumptionResponse
            };
        }
        return mutationResult;
    }
}
