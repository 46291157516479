import {JourneySnapshotModel} from "../journey/journey-snapshot.model";
import {NullablePrice} from "../../../../currency/price";
import {PassengerSegmentSnapshotModel} from "../passenger-segment/passenger-segment-snapshot.model";
import {FeeTypeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {NullableString} from "../../../../../types/nullable-types";

export class JourneySnapshotShoppingCartModel {
    constructor(private readonly journey: JourneySnapshotModel) {
        this._passengersSegmentsMap = journey.segments.toDictionaryOfType((segment, index) => index.toString(),
                                                                          segment => segment.passengers.toDictionary(ps => ps.passengerKey))
    }

    private readonly _passengersSegmentsMap: Record<number, Record<string, PassengerSegmentSnapshotModel>>;

    private _findPassengerSegment(segmentIndex: number, passengerKey: string): PassengerSegmentSnapshotModel | null | undefined {
        const segment = this._passengersSegmentsMap[segmentIndex];
        return segment && segment[passengerKey];
    }

    getInitialFare(segmentIndex: number, passengerKey: string): NullablePrice {
        return this._findPassengerSegment(segmentIndex, passengerKey)?.shoppingCart.currentFare || null;
    }

    getInitialSeatNumber(segmentIndex: number, passengerKey: string): NullableString {
        return this._findPassengerSegment(segmentIndex, passengerKey)?.seatNumber || null;
    }

    getInitialSeatPrice(segmentIndex: number, passengerKey: string): NullablePrice {
        return this._findPassengerSegment(segmentIndex, passengerKey)?.shoppingCart.getSeatCurrentPrice() || null;
    }

    getPurchasedSsrPrice(segmentIndex: number, passengerKey: string, ssrCode: string, ssrIndex: number): NullablePrice {

        return this._findPassengerSegment(segmentIndex, passengerKey)?.shoppingCart.getPurchasedSsrCurrentPrice(ssrCode, ssrIndex) || null;
    }

    getInBundleSsrPrice(segmentIndex: number, passengerKey: string, ssrCode: string, ssrIndex: number): NullablePrice {
        return this._findPassengerSegment(segmentIndex, passengerKey)?.shoppingCart.getInBundleSsrCurrentPrice(ssrCode, ssrIndex) || null;
    }

    getOtherFeePrice(segmentIndex: number, passengerKey: string, feeType: FeeTypeEnum, feeIndex: number): NullablePrice {
        return this._findPassengerSegment(segmentIndex, passengerKey)?.shoppingCart.getOtherFeeCurrentPrice(feeType, feeIndex) || null;
    }

    getInfantPrice(segmentIndex: number, passengerKey: string): NullablePrice {
        return this._findPassengerSegment(segmentIndex, passengerKey)?.infantShoppingCart?.currentFare || null;
    }
}
