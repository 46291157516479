import React from "react";
import {ServiceBase} from "../service-base";
import {
    IAppVersionUpdateSuggestionDialogOptions,
    IBoardingPassesDialogOptions,
    IBundleUpgradeSuggestionDialogOptions,
    IDialogFactoryService,
    IEditCompanionDialogOptions,
    IEditPersonTravelDocumentDialogOptions,
    ILoginAndRegistrationDialogOptions,
    IMultipleSsrsSelectorDialogOptions,
    IOnlineCheckInValidationDialogOptions,
    IPasswordResetDialogOptions,
    IPriorityBoardingSuggestionDialogOptions,
    ISeatDescriptionDialog,
    IShowRegistrationInstructionsDialogOptions,
    ISimpleMessageDialogOptions,
    ISsrsSelectionDialogOptions,
    IVoucherCodeConfirmationDialogOptions,
    IYesNoDialogOptions,
    OnlineCheckInValidationResultEnum
} from "./dialog-factory.service.interface";
import {DialogCloseButtonBehavior, DialogResult} from "../dialog/dialog-enums";
import {BoardingPassDialogComponent} from "../../pages/check-in-flow/boarding-passes/boarding-pass-dialog.component";
import {
    PriorityBoardingSuggestionChoice,
    PriorityBoardingSuggestionDialogComponent
} from "../../pages/booking-flow/bags-selection/components/priority-boarding-suggestion-dialog.component";
import {IBundleViewModel} from "../booking/models/bundle/bundle-view-model.interface";
import {BundleUpgradeSuggestionDialogComponent} from "../../pages/booking-flow/fare-selection/components/bundle/bundle-upgrade-suggestion-dialog.component";
import {OnlineCheckInValidationDialogComponent} from "../../pages/check-in-flow/passengers/components/online-check-in-validation-dialog.component";
import {DangerousGoodsDialogComponent} from "../../pages/check-in-flow/passengers/components/dangerous-goods-dialog.component";
import {InvalidPromoCodeDialogComponent} from "../../components/promotionals/promo-code/invalid-promo-code-dialog.component";
import {VoucherCodeConfirmationDialogComponent} from "../../components/promotionals/voucher-code/voucher-code-confirmation-dialog.component";
import {AppVersionUpdateSuggestionDialogComponent} from "../../components/app-version-update-suggestion-dialog/app-version-update-suggestion-dialog.component";
import {LoginAndRegistrationDialogComponent} from "../../components/login/login-and-registration-dialog.component";
import {ILoginResponse} from "../airline-webapi/responses/login.response";
import {RegistrationInstructionsDialogComponent} from "../../components/login/registration-instructions-dialog.component";
import {TermsAndConditionsDialogComponent} from "../../components/terms-and-conditions/terms-and-conditions-dialog.component";
import {UserProfileEditDialogComponent} from "../../pages/user/user-profile/components/user-profile-edit-dialog.component";
import {ConfirmRegistrationDialogComponent} from "../../components/login/confirm-registration-dialog.component";
import {ResetPasswordDialogComponent} from "../../components/login/reset-password-dialog.component";
import {IPasswordResetFormFields} from "../user/models/password-reset/password-reset-form-fields.interface";
import {IUserProfileEditorViewModel} from "../user/models/profile/user-profile-editor-view-model.interface";
import {CompanionEditorDialogComponent} from "../../pages/companions/components/companion-editor-dialog.component";
import {PersonTravelDocumentEditorDialogComponent} from "../../components/person/person-travel-document-editor-dialog.component";
import {YesNoDialogResult} from "./yes-no-dialog.enums";
import {YesNoDialogComponent} from "../../components/common-dialogs/yes-no-dialog.component";
import {InvalidBlueBenefitsSubscriptionDialogComponent} from "../../pages/booking-flow/blue-benefits-purchase/components/invalid-blue-benefits-subscription-dialog.component";
import {SsrsSelectionDialogComponent} from "../../pages/booking-flow/common-components/ssrs/ssrs-selection-dialog.component";
import {SeatMapGroupDetailsDialogComponent} from "../../pages/booking-flow/seat-selection/dialogs/seat-map-group-details-dialog.component";
import {SimpleMessageDialogComponent} from "../../components/common-dialogs/simple-message-dialog.component";
import {SeatSelectionDialogComponent} from "../../pages/booking-flow/seat-selection/dialogs/seat-selection-dialog.component";


export class DialogFactoryService extends ServiceBase implements IDialogFactoryService {
    async showBoardingPasses(options: IBoardingPassesDialogOptions): Promise<DialogResult> {
        return await this.services.dialog.showStandardDialog({
            forceFullScreenOnLargeScreens: true,
            render: (modalHandler) => <BoardingPassDialogComponent modalHandler={modalHandler}
                                                                   bookingKey={options.bookingKey}
                                                                   boardingPasses={options.boardingPasses}/>
        });
    }

    async showPriorityBoardingSuggestion(options: IPriorityBoardingSuggestionDialogOptions): Promise<DialogResult> {
        return await this.services.dialog.showStandardDialog<PriorityBoardingSuggestionChoice>({
            render: (modalHandler) => (<PriorityBoardingSuggestionDialogComponent dialogHandler={modalHandler}/>),
            onAccept: async (choice) => {
                await options.onAccept(choice);
            }
        });
    }

    async showBundleUpgradeSuggestion(options: IBundleUpgradeSuggestionDialogOptions): Promise<void> {
        await this.services.dialog.showStandardDialog<IBundleViewModel>({
            render: (modalHandler => <BundleUpgradeSuggestionDialogComponent dialogHandler={modalHandler}
                                                                             upgradeFrom={options.upgradeFrom}
                                                                             upgradeTo={options.upgradeTo}
                                                                             benefits={options.benefits}/>),
            onAccept: async (selectedBundle) => {
                await options.onAccept(selectedBundle);
            }
        });
    }

    async showOnlineCheckInValidation(options: IOnlineCheckInValidationDialogOptions): Promise<void> {
        await this.services.dialog.showStandardDialog<OnlineCheckInValidationResultEnum>({
            render: (dialogHandler) => (<OnlineCheckInValidationDialogComponent dialogHandler={dialogHandler}/>),
            onAccept: async (result: OnlineCheckInValidationResultEnum | undefined) => {
                await options.onAccept(result);
            }
        });
    }

    async showDangerousGoods(): Promise<DialogResult> {
        return await this.services.dialog.showStandardDialog({
            forceFullScreenOnLargeScreens: true,
            render: (modalHandler) => <DangerousGoodsDialogComponent dialogHandler={modalHandler} />
        });
    }

    async showInvalidPromotionCode(): Promise<DialogResult> {
        return await this.services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            render: (modalHandler) => <InvalidPromoCodeDialogComponent dialogHandler={modalHandler} />
        });
    }

    async showVoucherConfirmation(options: IVoucherCodeConfirmationDialogOptions): Promise<void> {
        await this.services.dialog.showPopupDialog({
            render: modalHandler => {
                return (<VoucherCodeConfirmationDialogComponent
                    headerTitle={options.headerTitle}
                    bodyText={options.bodyText}
                    confirmButtonText={options.confirmButtonText}
                    applyVoucherResult = {options.applyVoucherResult}
                    dialogHandler={modalHandler} />);
            },
            onReject: async () => {
                await options.onReject();
            }
        });
    }

    async showAppVersionUpdateSuggestion(options: IAppVersionUpdateSuggestionDialogOptions): Promise<void> {
        await this.services.dialog.showStandardDialog({
            render: (dialogHandler) => <AppVersionUpdateSuggestionDialogComponent dialogHandler={dialogHandler}
                                                                                  appVersionBlockingMode={options.appVersionUpgradeMode}/>
        });
    }

    async showLoginAndRegistration(options: ILoginAndRegistrationDialogOptions): Promise<void> {
        await this.services.dialog.showStandardDialog<ILoginResponse>({
            render:dialogHandler => (<LoginAndRegistrationDialogComponent dialogHandler={dialogHandler}
                                                                          tab={options.defaultTab}
                                                                          hideRegistrationTab={options.hideRegistrationTab}
                                                                          loginAndRegistrationHandler={options.loginAndRegistrationHandler}/>)
        });
    }

    async showAuthorizedUserProfileEditor(profileEditor: IUserProfileEditorViewModel): Promise<DialogResult> {
        return await this.services.dialog.showStandardDialog({
            render:dialogHandler => (<UserProfileEditDialogComponent dialogHandler={dialogHandler}
                                                                     userProfileEditor={profileEditor}/>)
        });
    }

    async showRegistrationInstructions(options: IShowRegistrationInstructionsDialogOptions): Promise<void> {
        await this.services.dialog.showPopupDialog({
            render: dialogHandler => {
                options.captureDialogHandler(dialogHandler);
                return (<RegistrationInstructionsDialogComponent dialogHandler={dialogHandler} />)
            }
        })
    }

    async showCookiesPolicy(): Promise<void> {
        await this.services.dialog.showStandardDialog({
            render: dialogHandler => <TermsAndConditionsDialogComponent dialogHandler={dialogHandler}/>
        });
    }

    async showRegistrationConfirmation(): Promise<void> {
        await this.services.dialog.showPopupDialog({
            render: dialogHandler => (<ConfirmRegistrationDialogComponent dialogHandler={dialogHandler}/>)
        });
    }

    async showPasswordReset(options: IPasswordResetDialogOptions): Promise<void> {
        await this.services.dialog.showStandardDialog<IPasswordResetFormFields>({
            render: dialogHandler => (<ResetPasswordDialogComponent dialogHandler={dialogHandler} form={options.form}/>),
        })
    }

    async showCompanionEditor(options: IEditCompanionDialogOptions): Promise<DialogResult> {
        return await this.services.dialog.showStandardDialog({
            render: dialogHandler => (<CompanionEditorDialogComponent dialogHandler={dialogHandler} companion={options.companion}/>)
        });
    }

    async showPersonTravelDocumentEditor(options: IEditPersonTravelDocumentDialogOptions): Promise<DialogResult> {
        return await this.services.dialog.showStandardDialog({
            render: dialogHandler => (<PersonTravelDocumentEditorDialogComponent dialogHandler={dialogHandler} travelDocument={options.travelDocument}/>)
        });
    }

    async showYesNoDialog(options: IYesNoDialogOptions): Promise<YesNoDialogResult> {
        const dialogResult = await this.services.dialog.showPopupDialog({
            render: dialogHandler => (<YesNoDialogComponent dialogHandler={dialogHandler} {...options}/>)
        });
        if(dialogResult === DialogResult.Accepted) {
            return YesNoDialogResult.Yes;
        }
        return YesNoDialogResult.No;
    }

    async showInvalidBlueBenefitsSubscription(): Promise<void> {
        await this.services.dialog.showPopupDialog({
            render: (modalHandler) => <InvalidBlueBenefitsSubscriptionDialogComponent dialogHandler={modalHandler}/>
        });
    }

    async showMultipleSsrsSelector(options: IMultipleSsrsSelectorDialogOptions): Promise<DialogResult> {
        return await this.services.dialog.showStandardDialog({
            closeButtonBehavior: options.backButtonBehavior || DialogCloseButtonBehavior.Accept,
            render: dialogHandler => (
                <SsrsSelectionDialogComponent title={options.title}
                                              dialogHandler={dialogHandler}
                                              ssrsAggregator={options.aggregator}/>
            )
        });
    }

    async showSsrsSelector(options: ISsrsSelectionDialogOptions): Promise<DialogResult> {
        let {backButtonBehavior, ...dialogProps} = options;

        return await this.services.dialog.showStandardDialog({
            closeButtonBehavior: backButtonBehavior || DialogCloseButtonBehavior.Accept,
            render: dialogHandler => (
                <SsrsSelectionDialogComponent dialogHandler={dialogHandler} {...dialogProps}/>
            )
        });
    }

    async showSeatDescriptionDialog(options: ISeatDescriptionDialog): Promise<DialogResult> {
        return await this.services.dialog.showSheetModalDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            render: dialogHandler => (
                <SeatMapGroupDetailsDialogComponent seatsRowGroupType={options.groupType}
                                                    dialogHandler={dialogHandler}/>
            )
        });
    }

    async showSimpleMessageDialog(options: ISimpleMessageDialogOptions): Promise<void> {
        await this.services.dialog.showPopupDialog({
            maxHeight: '50%',
            render: dialogHandler => (
                <SimpleMessageDialogComponent title={options.title} message={options.message} buttonText={options.buttonText} dialogHandler={dialogHandler}/>
            )
        });
    }

    async showSeatsSelection(): Promise<void> {
        await this.services.dialog.showStandardDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            forceFullScreenOnLargeScreens: true,
            render: dialogHandler => (<SeatSelectionDialogComponent dialogHandler={dialogHandler} />)
        });
    }

}
