import React from 'react';
import {observer} from "mobx-react";
import {IPspError} from "../../../services/airline-webapi/responses/psp-error";
import styled from "styled-components";

const PspErrorDetailsBox = styled.ul`
    width: 100%;
    list-style-type: none;
`

const PspErrorBox = styled.li`
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
`


export const PspErrorDetailsComponent: React.FC<{pspErrorDetails: IPspError[] | null}> = observer((props) => {
    const errors = props.pspErrorDetails ?? [];
    if(errors.length === 0) {
        return null;
    }

    return (
        <PspErrorDetailsBox>
            {errors.map(err => (<PspErrorBox key={err.code}>{err.message}</PspErrorBox>))}
        </PspErrorDetailsBox>
    )
})