import React from "react";
import {observer} from "mobx-react-lite";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../../components/designator/designator.component";
import {ArrowCircularIcon} from "../../../../../assets/svg/arrow-circular.icon";
import styled from "styled-components";
import {PrimaryButtonInverted} from "../../../../../basic-components/buttons/primary-button-inverted";
import {useServices} from "../../../../../hooks/use-services.hook";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {ManageMyBookingFlightsToModifySelectionDialog} from "../../../change-flight/flights-to-modify-selection/manage-my-booking-flights-to-modify-selection.dialog";
import {DialogCloseButtonBehavior} from "../../../../../services/dialog/dialog-enums";


const FlightsBox = styled(CardBox)`
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: ${props => props.theme.spacing.large};
`

const ChangeActionButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    
`

const ManageMyBookingSectionButtonBox = styled(PrimaryButtonInverted)`
    flex: 1;
    height: 100%;
    display: flex;
    margin: 0;
    svg {
        margin-bottom: ${props => props.theme.spacing.medium};
    }
`
const ManageMyBookingSectionButtonRowsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const DesignatorComponentBox = styled.div`
    margin: 0;
    flex: 1 0 100%;
    padding: ${props => props.theme.spacing.small};
    &:first-of-type {
        padding-top: ${props => props.theme.spacing.large};
    }
    &:last-of-type {
        border-bottom: 1px solid ${props => props.theme.colors.tertiaryTint};
        padding-bottom: ${props => props.theme.spacing.large};
    }
`

const DesignatorStatusBox = styled.div`
    color: ${props => props.theme.colors.danger};
    padding: ${props => props.theme.spacing.small} 0;
    line-height: 1;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    
`

interface ActionButtonComponentProps {
    label: string;
    onClick: () => void;
    renderIcon: () => React.ReactElement;
}

const ChangeActionButtonComponent: React.FC<ActionButtonComponentProps> = observer((props) => {
    return (
        <ManageMyBookingSectionButtonBox onClick={props.onClick} fontSize={"medium"}>
            <ManageMyBookingSectionButtonRowsBox>
                {props.renderIcon()}
                {props.label}
            </ManageMyBookingSectionButtonRowsBox>
        </ManageMyBookingSectionButtonBox>
    )
});

export const ManageMyBookingFlightsComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    const onChangeFlightsClick = async () => {
        await services.dialog.showStandardDialog({
            render: dialogHandler => (<ManageMyBookingFlightsToModifySelectionDialog dialogHandler={dialogHandler}/>),
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            onAccept: async () => {
                if(booking.manageMyBooking.hasJourneysThatAllowDateChange) {
                    await booking.manageMyBooking.startModifyFlightsChange();
                }
            }
        })
    }


    return (
        <FlightsBox>
            {booking.unfilteredJourneys.map(j => <DesignatorComponentBox key={j.designator.uniqueKey}>
                <DesignatorComponent displayMode={DesignatorDisplayMode.stationsNamesAndDateAndFlightStops}
                                     designator={j.designator}/>
                {j.isCanceled ? <DesignatorStatusBox>{services.language.translate("Flight Canceled")}</DesignatorStatusBox> : ''}
            </DesignatorComponentBox>)}

            <ChangeActionButtonsContainerBox>
                <ChangeActionButtonComponent label={services.language.translate('Modify')}
                                             onClick={onChangeFlightsClick}
                                             renderIcon={() => <ArrowCircularIcon/>}/>
            </ChangeActionButtonsContainerBox>


        </FlightsBox>
    );
});
