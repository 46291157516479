import {
    ILowFareResult,
    IRoundTripLowFareReader,
    LowFareStatusEnum
} from "../../../../low-fare/low-fare-readers/low-fare-reader.interface";


export class VoluntaryFlightsChangeLowFareReaderDecorator implements IRoundTripLowFareReader {

    getDepartureLowFare(date: Date): ILowFareResult {
        return {
            status: LowFareStatusEnum.Disabled
        };
    }

    getReturnLowFare(date: Date): ILowFareResult {
        return {
            status: LowFareStatusEnum.Disabled
        };
    }

    async getDepartureLowFareAsync(date: Date): Promise<ILowFareResult> {
        return this.getDepartureLowFare(date);
    }

    async getReturnLowFareAsync(date: Date): Promise<ILowFareResult> {
        return this.getReturnLowFare(date);
    }
}
