/**
 * Used to introduce a delay in case we want to slow down a specific operation
 * @param executionStartTime - when the specific operation started
 * @param totalDelayInMS - the total amount of time we want for the operation to execute
 */
export function delay(executionStartTime: number, totalDelayInMS: number): Promise<void> {
    const executionTime = Date.now() - executionStartTime;
    if (executionTime < totalDelayInMS) {
        return new Promise<void>((resolve => setTimeout(() => resolve(), totalDelayInMS - executionTime)));
    } else {
        return Promise.resolve();
    }
}

export function  mapToPascalKeys(obj: {[p: string]: any}): any {
    const toPascalCase = (str:string) => str.charAt(0).toUpperCase() + str.slice(1);
    if (Array.isArray(obj)) {
        return obj.map((v) => mapToPascalKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [toPascalCase(key)]: mapToPascalKeys(obj[key]),
            }),
            {}
        );
    }
    return obj;
}