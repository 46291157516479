import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';

export const FlexSsrSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} viewBox="0 0 18 11">
            <g fill={props.color? props.color: "currentColor"} transform="translate(0)"><g><path d="M14.262,0,18,2.346,14.262,4.692V3.519A8.647,8.647,0,0,0,7.892,6.3,11.128,11.128,0,0,1,0,9.677v-2.2A8.548,8.548,0,0,0,6.369,4.692a10.36,10.36,0,0,1,7.892-3.519Z" /></g><g transform="translate(0 1.4)"><path d="M14.262,11.077,18,8.731,14.262,6.385V7.558A8.647,8.647,0,0,1,7.892,4.772,11.128,11.128,0,0,0,0,1.4V3.6A8.548,8.548,0,0,1,6.369,6.385,10.36,10.36,0,0,0,14.262,9.9Z"/></g></g>       
        </SvgIcon>
    )
}
