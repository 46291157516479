import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {CheckboxComponent} from "../../../basic-components/checkbox/checkbox.component";
import {CardBox} from "../../../basic-components/card/card.box";

const ContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const CheckBox = styled(CheckboxComponent)`
    width: 100%;
`

interface SameForAllComponentProps {
    label: string;
    checked: () => boolean;
    onChanged: (checked: boolean) => void;
    className?: string;
    checkBoxClassName?: string;
}

export const SameForAllComponent: React.FC<SameForAllComponentProps> = observer((props) => {
    return (
        <ContainerBox className={props.className}>
            <CheckBox checked={props.checked()}
                               className={props.checkBoxClassName}
                               label={props.label}
                               labelPlacement={'start'}
                               onChange={isChecked => props.onChanged(isChecked)}/>
        </ContainerBox>
    )
});



