import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PrinterIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} sizeIsHeight viewBox="0 0 24 24" ratio={1}>
            <g fill={props.color? props.color: "currentColor"}><path d="M18,3H6V7H18M19,12C18.45,12 18,11.55 18,11C18,10.45 18.45,10 19,10C19.55,10 20,10.45 20,11C20,11.55 19.55,12 19,12M16,19H8V14H16M19,8H5C3.34,8 2,9.34 2,11V17H6V21H18V17H22V11C22,9.34 20.66,8 19,8Z" /></g>
        </SvgIcon>
    )
}
