import React from "react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import styled from "styled-components";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../continue-button/continue-button.component";

const ConfirmRegistrationDialogTextBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1.4;
    margin: 10px 0 20px;;
    width: 100%;
    height: 100%;
`

export const ConfirmRegistrationDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
   return (
       <StandardDialogPageComponent>
           <StandardDialogHeaderComponent title={services.language.translate('Registration confirmed')} dialogHandler={props.dialogHandler}/>
           <StandardDialogContentComponent>
               <ConfirmRegistrationDialogTextBox>
                   {services.language.translate('Your registration was successful. Click on Continue button in order to login')}
               </ConfirmRegistrationDialogTextBox>
           </StandardDialogContentComponent>
           <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
               <ContinueButtonComponent onClick={() => props.dialogHandler.accept()}/>
           </StandardDialogFooterComponent>
       </StandardDialogPageComponent>
   )
});
