import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {ellipsisVertical} from "ionicons/icons";
import {ActionSheetButton, IonActionSheet, IonIcon} from "@ionic/react";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";
import {SwitchEnvironmentDialogComponent} from "./switch-environment-dialog.component";
import {DialogCloseButtonBehavior} from "../../services/dialog/dialog-enums";

const TestModeMenuBox = styled.div<{color?: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 26px;
  font-size: ${props => props.theme.fontSize.xxxLarge};
  margin-left: ${props => props.theme.spacing.medium};
  cursor: pointer;
  color: ${props => props.color ?? "inherit"};
`


class TestModeMenuGlobals {
    static isHiddenOnCurrentSession = false;
}

export const TestModeMenuComponent: React.FC = observer(() => {
    const services = useServices();
    const [isOpen, setIsOpen] = useState(false);
    const [isHidden, setIsHidden] = useState(TestModeMenuGlobals.isHiddenOnCurrentSession);



    const shouldRender = () => {
        if(isHidden) {
            return false;
        }

        if(services.device.isHybrid) {
            return services.configuration.isTestModeEnabled;
        }

        return !services.configuration.isProduction;
    }


    if(!shouldRender()) {
        return null;
    }

    const switchLayoutModeHandler = () => {
        services.layout.switchLayoutMode();
    }

    const switchEnvironmentHandler = async () => {
        await services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            render: dialogHandler => (<SwitchEnvironmentDialogComponent dialogHandler={dialogHandler}/>)
        })
    }

    const buttons: ActionSheetButton[] = []

    if(services.device.isWeb) {
        buttons.push( {
            text: services.layout.shouldUseWebappLayout ? "Switch to mobile app layout" : "Switch to web app layout",
            handler: switchLayoutModeHandler
        });
    }

    if(services.device.isHybrid && services.navigator.currentRoute.isHome) {
        buttons.push({
            text: "Switch environment",
            handler: switchEnvironmentHandler
        });
    }

    if(buttons.length > 0) {
        buttons.push( {
            text: 'Hide this menu',
            handler: () => {
                TestModeMenuGlobals.isHiddenOnCurrentSession = true;
                setIsHidden(TestModeMenuGlobals.isHiddenOnCurrentSession);
            }
        });
    }

    if(buttons.length === 0) {
        return null;
    }

    const onClickHandler = () => {
        setIsOpen(true);
    }

    return (
        <TestModeMenuBox onClick={onClickHandler} color={!services.configuration.isProduction ? services.theme.currentTheme.colors.danger : undefined}>
            <IonIcon icon={ellipsisVertical}/>
            <IonActionSheet mode={"ios"} isOpen={isOpen} buttons={buttons} onDidDismiss={() => setIsOpen(false)}/>
        </TestModeMenuBox>
    )
});
