import React from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {BookingFlowPage} from "../booking-flow.page";
import {ExtrasSelectionPageContentComponent} from "./extras-selection-page-content.component";

export const ExtrasSelectionPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowPage title={services.language.translate('Extras')}
                         renderContent={() => <ExtrasSelectionPageContentComponent/>}
                         showPromotionCode={false}>

        </BookingFlowPage>
    );
})
