import React from "react";
import {IStandardDropDownItemViewModel} from "../../models/item/standard-drop-down-list-item-view-model.interface";
import {IStandardDropDownListViewModel} from "../../models/list/standard-drop-down-list-view-model.interface";
import styled, {css} from "styled-components";
import {StandardDropDownListItemComponent} from "../item/standard-drop-down-list-item.component";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {EmptyDropDownMessageComponent} from "../../../../drop-down/empty-drop-down-message.component";


const DropDownListContainerBox = styled.div<{isInSmallScreenView: boolean}>`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 100px;
    max-height: 300px;
    background-color: ${props => props.theme.colors.primaryContrast};
    font-size: ${props => props.theme.fontSize.large};
    color: ${props => props.theme.colors.primary};
  ${
          props => props.isInSmallScreenView
                  ? css`
                          max-height: unset;
                          height: 100%;
                          width: 100%;
                    `
                  : ''
  }
`

const DropDownRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`


interface StandardDropDownListComponentProps<TFieldValue> {
    listViewModel: IStandardDropDownListViewModel<TFieldValue>;
    renderEmptyListMessage?: () => React.ReactElement | string;
}


export const  StandardDropDownListComponent = observer(<TFieldValue extends any>(props: StandardDropDownListComponentProps<TFieldValue>) => {
    const services = useServices();
    const DropDownItem = StandardDropDownListItemComponent<TFieldValue>;


    const renderItem = (item: IStandardDropDownItemViewModel<TFieldValue>, index: number) => {
        return (
            <DropDownItem key={item.key}
                          index={index}
                          item={item}
                          listViewModel={props.listViewModel}/>
        );
    }


    const renderItems = () => {
        if(props.listViewModel.unfilteredItemsCount === 0) {
            if(props.renderEmptyListMessage) {
                return (
                    <EmptyDropDownMessageComponent message={props.renderEmptyListMessage()}/>
                );
            } else {
                return null;
            }
        }

        return props.listViewModel.filteredItems.map(renderItem);
    }

    return (
        <DropDownListContainerBox isInSmallScreenView={services.layout.isInSmallScreenView}>
            <DropDownRowsContainer>
                {renderItems()}
            </DropDownRowsContainer>
        </DropDownListContainerBox>
    )
});



