import React from "react";
import {observer} from "mobx-react-lite";
import {PlatformBasedComponent} from "../../basic-components/layout/platform-based.component";
import {HomeWebappPage} from "./home.webapp.page";
import {HomeHybridPage} from "./home.hybrid.page";

export const HomePage: React.FC = observer(() => {
    return (
        <PlatformBasedComponent renderForWebApp={() => (<HomeWebappPage/>)}
                                renderForHybrid={() => (<HomeHybridPage/>)}/>
    );
});
