import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";

const AirlineCompanyInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${props => props.theme.fontSize.xLarge};
    background-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: ${props => props.theme.spacing.pageDefaultPaddingPx};
    line-height: 1.3;
    @media ${(props) => props.theme.mediaQuery.sAndBelow} {  
        font-size: ${props => props.theme.fontSize.medium};
        border-radius: ${props => props.theme.border.defaultRadius};
    }
`


const AnchorBox = styled.a`
  color: inherit;
  text-decoration: underline;
  display: inline;
`

const AddressBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
`

export const AirlineCompanyInfoComponent: React.FC<{className?: string}> = observer((props) => {
    const services = useServices();

    if(!services.layout.shouldUseWebappLayout) {
        return null;
    }

    return (
        <AirlineCompanyInfoBox className={props.className}>
            <AddressBox>{services.configuration.data.companyAddress}</AddressBox>
            <AnchorBox href={services.externalLinksConfiguration.privacyPolicy} rel={"noreferrer"} target={"_blank"}>Privacy policy</AnchorBox>
        </AirlineCompanyInfoBox>
    )
})
