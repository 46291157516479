import React from "react";
import styled from "styled-components";
import { ArrowDownIcon } from "../../../../assets/svg/arrow-down.icon";
import { ArrowUpIcon } from "../../../../assets/svg/arrow-up.icon";
import { AccordionComponent } from "../../../../components/accordion/accordion.component";
import { useServices } from "../../../../hooks/use-services.hook";

const AccordionArrowDownBox = styled.div`
    display: flex;
    align-items: center;
`;

const AccordionArrowDownLabelBox = styled.div`
    font-size: ${props => props.theme.fontSize.xSmall};
    color: ${props => props.theme.colors.secondary};
    margin-right: ${props => props.theme.spacing.small};
`;


interface FlightItineraryAccordionComponentProps {
    renderHeader: () => React.ReactElement;
    renderContent: () => React.ReactElement;
    isExpanded?: boolean;
    className?: string;
    headerClassName?: string;
}

export const FlightItineraryAccordionComponent: React.FC<FlightItineraryAccordionComponentProps> = (props) => {
    const services = useServices();

    const isExpanded = props.isExpanded ?? false;

    const renderArrowDown = () => {
        return(
            <AccordionArrowDownBox>
                <AccordionArrowDownLabelBox>
                    {services.language.translate('See Details')}
                </AccordionArrowDownLabelBox>
                <ArrowDownIcon color={services.theme.currentTheme.colors.secondary} size={0.6}/>
            </AccordionArrowDownBox>
        )
    }

    const renderArrowUp = () => {
        return(
            <ArrowUpIcon color={services.theme.currentTheme.colors.secondary} size={0.6}/>
        )
    }


    return(
        <AccordionComponent renderHeader={props.renderHeader}
                            renderContent={props.renderContent}
                            renderArrowDown={renderArrowDown}
                            renderArrowUp={renderArrowUp}
                            isExpanded={isExpanded}
                            className={props.className}
                            headerClassName={props.headerClassName}/>
    )
}



export const FlightItineraryInnerAccordionComponent = styled(FlightItineraryAccordionComponent)`
  border: none;
  padding: 0;
`
