import {
    IDotRezPassengerSegment,
    IDotRezPassengerSegmentSeat,
    IDotRezPassengerSegmentSsr, IDotRezSegment, IDotRezSegmentFare
} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {NullableNumber, NullableString} from "../../../../types/nullable-types";
import {NullablePrice, Price} from "../../../currency/price";
import {IPassengerSegmentViewModel} from "./passenger-segment-view-model.interface";
import {SegmentModel} from "../segment/segment.model";
import {FlightDesignatorModel} from "../designator/flight-designator.model";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {PassengerSegmentSsrEditorModel} from "../ssrs/passenger-segment-ssr-editor.model";
import {BundleModel} from "../bundle/bundle.model";
import {MaturePassengerModel} from "../passenger/mature-passenger.model";
import {Check} from "../../../../types/type-checking";
import {ICheckInRestriction} from "../check-in-restrictions/check-in-restriction.interface";
import {TravelDocumentValidationRestriction} from "../check-in-restrictions/travel-document-validation-restriction";
import {PassengerSegmentShoppingCartModel} from "../shopping-cart/passenger-segment/passenger-segment-shopping-cart.model";
import {InfantSegmentShoppingCartModel} from "../base-models/shopping-cart/infant-segment-shopping-cart.model";
import {UnaccompaniedMinorCheckInRestriction} from "../check-in-restrictions/unaccompanied-minor-check-in-restriction";
import {IServiceFactory} from "../../../service-factory.interface";
import {ISsrAvailability} from "../ssrs/availability/ssr-availability.iterface";
import {SeatModel} from "../seat-maps/seat.model";
import {BookingModel} from "../booking.model";
import {SellSeatMutation} from "../mutation-actions/seats/sell-seat.mutation";
import {RemoveSeatMutation} from "../mutation-actions/seats/remove-seat.mutation";
import {PassengerSegmentCheckInStatusEnum} from "../../../dot-rez-api/data-contracts/enums/passenger-segment-check-in-status.enum";
import {PassengerFeeModel} from "../fees/passenger/passenger-fee.model";
import {NotAllPassengersHaveSeatsRestriction} from "../check-in-restrictions/not-all-passengers-have-seats-restriction";
import {SpecialSsrsAndNotAllPassengersHaveSeatsRestriction} from "../check-in-restrictions/special-ssrs-and-not-all-passengers-have-seats-restriction";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../../dialog-factory/yes-no-dialog.enums";
import {CheckedInSeatChangeConfirmationComponent} from "./checked-in-seat-change-confirmation.component";
import {SharedQuantitySsrsEditors} from "./shared-quantity-ssr-editors";
import {SsrOnlineCheckInRestriction} from "../check-in-restrictions/ssr-online-check-in-restriction";
import {ISeatModel} from "../seat-maps/seat.model.interface";
import {AssignedSeatSurrogateModel} from "./assigned-seat-surogate.model";
import {ISeatFeeModel} from "../base-models/fees/seat-fees/seat-fee-model.interface";
import {IPassengerSegmentShoppingCartAdapter} from "../base-models/shopping-cart/passenger-segment-shopping-cart-adapter.interface";
import {IPassengerType} from "../../../passenger-types/passengers-types.service.interface";
import {InfantFeeModel} from "../fees/infant/infant-fee.model";
import {FeeTypeEnum} from "../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {NoSeatPurchasedCheckInRestriction} from "../check-in-restrictions/no-seat-purchased-check-in-restriction";
import {TimeSpan} from "../../../../types/time-span";


export class PassengerSegmentModel implements IPassengerSegmentShoppingCartAdapter, IPassengerSegmentViewModel {
    constructor(public readonly segment: SegmentModel,
                public readonly passengerKey: string) {

        makeObservable<this, '_ssrModels'>(this, {
            _ssrModels: observable.ref,
            allServiceFees: computed,
            assignedSeat: computed,
            infantShoppingCart: computed
        });

        this.shoppingCart = new PassengerSegmentShoppingCartModel(this)
    }

    readonly shoppingCart: PassengerSegmentShoppingCartModel;

    get bookingCreateDate(): Date {
        return this.segment.journey.booking.bookingCreationDate;
    }
    get infantShoppingCart(): InfantSegmentShoppingCartModel | null {
        if(!this.passenger.infant) {
            return null
        }

        return new InfantSegmentShoppingCartModel(this);
    }

    get flightDesignator(): FlightDesignatorModel {
        return this.segment.designator;
    }

    get passenger(): MaturePassengerModel {
        return this.segment.getPassenger(this.passengerKey);
    }

    get passengerInitials(): string {
        return this.passenger.getInitials();
    }

    get booking(): BookingModel {
        return this.segment.journey.booking;
    }

    get segmentDotRezData(): IDotRezSegment {
        return this.segment.segmentDotRezData;
    }

    get segmentFares(): IDotRezSegmentFare[] {
        return this.segmentDotRezData.fares || [];
    }

    get passengerType(): IPassengerType {
        return this.passenger.passengerType;
    }

    get passengerDiscountCode(): string {
        return this.passenger.discountCode;
    }

    isSsrCommitted(ssrCode: string, ssrNumber: number): boolean {
        const ssrFee = this.shoppingCart.getSsrFeeByCodeAndNumber(ssrCode, ssrNumber);
        if(ssrFee) {
            return ssrFee.isCommitted
        } else {
            return false;
        }
    }

    isSsrPurchasedOnPreviousSession(ssrCode: string, ssrNumber: number): boolean {
        const ssrFee = this.shoppingCart.getSsrFeeByCodeAndNumber(ssrCode, ssrNumber);
        if(ssrFee) {
            return !ssrFee.isPurchasedOnCurrentSession
        } else {
            return false;
        }
    }

    get passengerFirstName(): string {
        if(this.passenger.fields.firstName.value) {
            return this.passenger.fields.firstName.value;
        } else {
            return this.passenger.getFullName();
        }
    }

    get passengerFullName(): string {
        return this.passenger.getFullName();
    }

    get passengerIndex(): number {
        return this.passenger.passengerIndex;
    }

    get infantFullName(): NullableString {
        return this.passenger.infant?.getFullName() || null;
    }

    get passengerSegmentDotRezData(): IDotRezPassengerSegment {
        return this.segment.getPassengerSegmentDotRezData(this.passengerKey);
    }

    get ssrs(): IDotRezPassengerSegmentSsr[] {
        return this.passengerSegmentDotRezData.ssrs || [];
    }

    get bundleCode(): NullableString {
        return this.passengerSegmentDotRezData.bundleCode;
    }


    get passengerSegmentUniqueKey(): string {
        return `${this.passengerKey}_${this.segment.segmentKey}`;
    }

    get segmentKey():string {
        return this.segment.segmentKey;
    }

    isSeatRestrictedBySelectedSsrs(seat: SeatModel): boolean {
        for(let ssr of this.ssrs) {
            const ssrType = this.getSsrType(ssr.ssrCode);
            if(ssrType.isSeatRestricted(seat)) {
                return true;
            }
        }

        return false;
    }

    private _getAssignedDotRezSeat():  IDotRezPassengerSegmentSeat | null {
        return this.passengerSegmentDotRezData.seats && this.passengerSegmentDotRezData.seats[0];
    }

    get hasSeat(): boolean {
        return Boolean(this._getAssignedDotRezSeat());
    }

    get assignedSeat(): ISeatModel | null {

        const thisPassengerSeat = this._getAssignedDotRezSeat();
        if(!thisPassengerSeat) {
            return null;
        }

        if(this.segment.seatMapEditor.isLoaded) {
            return this.segment.seatMapEditor.getSeat(thisPassengerSeat.unitKey);
        } else {
            return new AssignedSeatSurrogateModel(thisPassengerSeat, this);
        }
    }

    get seatNumber(): NullableString {
        return this.assignedSeat?.seatNumber || null;
    }

    get rowNumber(): NullableNumber {
        return this.assignedSeat?.rowNumber || null;
    }

    get seatKey(): NullableString {
        return this.assignedSeat?.seatKey || null;
    }

    private _changeSeatDebounceTimer: any = null;
    private _debounceSeatChange(action: () => void): void {
        clearTimeout(this._changeSeatDebounceTimer);
        this._changeSeatDebounceTimer = setTimeout(action, 300);
    }


    async sellSeat(seatToSell: SeatModel): Promise<void> {
        const assignedSeat = this.assignedSeat;
        if(assignedSeat?.seatKey === seatToSell.seatKey) {
            return
        }

        if(assignedSeat?.isCheckedIn) {
            const messageResult = await this.services.dialogFactory.showYesNoDialog({
                title: "Confirm seat change",
                primaryButton: YesNoDialogPrimaryButton.PrimaryButtonYes,
                message: <CheckedInSeatChangeConfirmationComponent seatNumber={assignedSeat.seatNumber}/>
            });

            if(messageResult !== YesNoDialogResult.Yes) {
                return;
            }
        }

        seatToSell.isAssigmentInProgress = true;

        runInAction(() => {
            if(assignedSeat && !assignedSeat.isCheckedIn) {
                assignedSeat.makeItAvailable();
            }

            seatToSell.holdItForThisSession();

            this.passengerSegmentDotRezData.seats = [{
                unitKey: seatToSell.seatKey,
                unitDesignator: seatToSell.seatNumber
            }];
        });


        this._debounceSeatChange(() => {
            this.booking.mutationsManager.startSeatMutation(new SellSeatMutation(this.booking,
                                                                             this.segment.journey.journeyKey,
                                                                             seatToSell,
                                                                             this.passenger));

            seatToSell.isAssigmentInProgress = false;
        });

        this.booking.seatsMapsEditors.moveToNextPassenger();

    }
    async removeSeat(): Promise<void> {
        const seatToRemove = this.assignedSeat;
        if(!seatToRemove) {
            return;
        }

        if(seatToRemove.isCheckedIn) {
            return;
        }

        this.booking.seatsMapsEditors.setCurrentPassengerSegment(this);

        runInAction(() => {
            seatToRemove.makeItAvailable();
            this.passengerSegmentDotRezData.seats = [];
        });


        this._debounceSeatChange(() => {
            if(seatToRemove.isAssigmentInProgress) {
                seatToRemove.isAssigmentInProgress = false;
            } else {
                this.booking.mutationsManager.startSeatMutation(new RemoveSeatMutation(this.booking,
                                                                                this.segment.journey.journeyKey,
                                                                                this.passenger,
                                                                                seatToRemove));
            }
        });
    }

    translate(key: string): string {
        return this.segment.translate(key);
    }

    createPrice(amount: number): Price {
        return this.segment.createPrice(amount);
    }

    onBundleUpdated() {
        runInAction(() => {
            this._ssrModels = {};
        });

    }
    private _ssrModels: Record<string, PassengerSegmentSsrEditorModel> = {};
    getSsr(ssrType: ISsrType): PassengerSegmentSsrEditorModel {
        if(!this._ssrModels[ssrType.ssrCode]) {
            this._ssrModels[ssrType.ssrCode] = new PassengerSegmentSsrEditorModel(ssrType, this);
        }
        return this._ssrModels[ssrType.ssrCode];
    }

    getSsrAvailability(ssrType: ISsrType): ISsrAvailability {
        return this.segment.getSsrAvailability(ssrType);
    }

    getSoldUnitsForSsrType(ssrType: ISsrType): IDotRezPassengerSegmentSsr[] {
        return this.ssrs.filter(ssr => ssr.ssrCode === ssrType.ssrCode);
    }

    get services(): IServiceFactory {
        return this.segment.journey.services;
    }
    getSsrType(ssrCode: string): ISsrType {
        return this.services.ssrTypes.getSsrType(ssrCode);
    }
    getAllSsrsEditors(): PassengerSegmentSsrEditorModel[] {
        return this.ssrs.map(ssr => ssr.ssrCode).concat(Object.keys(this._ssrModels))
                        .distinct(ssrCode => ssrCode, ssrCode => ssrCode)
                        .map(ssrCode => this.getSsr(this.getSsrType(ssrCode)));
    }

    /**
     * Returns all ssrs with quantity greater than zero
     */
    getSoldSsrsEditors(): PassengerSegmentSsrEditorModel[] {
        return this.getAllSsrsEditors().filter(ssr => ssr.newQuantity > 0);
    }

    getAllSoldSsrKeysWithoutBundleIncluded(): string[] {
        return this.ssrs.filter(ssr => !ssr.inBundle).map(ssr => ssr.ssrKey);
    }


    private _groupSharedQuantitySsrsEditors(ssrTypes: ISsrType[]): SharedQuantitySsrsEditors[] {
        const relatedSsrsEditorsGroups: SharedQuantitySsrsEditors[] = [];
        for(let ssrType of ssrTypes) {
            if (relatedSsrsEditorsGroups.some(g => g.containsSsrType(ssrType))) {
                continue;
            }

            const group = new SharedQuantitySsrsEditors(this.getSsr(ssrType));
            ssrType.getRelatedSsrsThatShareTheSameMaxQuantity().forEach(relatedSsrType => {
                group.addRelatedSsr(this.getSsr(relatedSsrType));
            });

            relatedSsrsEditorsGroups.push(group);

        }
        return relatedSsrsEditorsGroups;
    }

    getSsrKeysToRemoveOnBundleChange(newBundle: BundleModel): string[] {
        let ssrKeys: string[] = [];

        const sharedQuantitySsrsEditorGroups = this._groupSharedQuantitySsrsEditors(newBundle.includedSsrs.map(includesSsr => includesSsr.ssrType));

        sharedQuantitySsrsEditorGroups.forEach(sharedSsrs => {
            ssrKeys = [
                ...ssrKeys,
                ...sharedSsrs.getSsrKeysToRemoveOnBundleChange(newBundle)
            ];
        });

        return ssrKeys;
    }

    get isCheckedIn(): boolean {
        return this.passengerSegmentDotRezData.liftStatus !== PassengerSegmentCheckInStatusEnum.Default;
    }

    private _getOtherPassengersAges(): number[] {
        const ages: number[] = [];
        this.segment.passengers.filter(passengerSegment => passengerSegment.passengerKey !== this.passengerKey)
            .forEach(passengerSegment => {
                const age = passengerSegment.passenger.computePassengerAge(this.segment.journey.designator.departureDate);
                if(!Check.isNullOrUndefined(age)) {
                    ages.push(age);
                }
            });

        return ages;
    }

    private _getCheckInRestrictionsForRandomSeatAllocation(): ICheckInRestriction[] {

        if(!this.services.configuration.data.enableRandomSeatAllocation) {
            return [];
        }

        // if there is only one passenger on the booking .
        if(this.booking.passengers.length === 1) {
            return [];
        }

        // if all passengers have seats no need for this restriction
        if(this.segment.passengers.all(p => p.hasSeat)) {
            return [];
        }

        // it means we have passengers with special SSRS and not all passengers have seats. So restriction!
        if(this.segment.passengers.some(p => p.getSoldSsrsEditors().some(ssr => ssr.ssrType.shouldBlockOnlineCheckIn))) {
            return [new SpecialSsrsAndNotAllPassengersHaveSeatsRestriction(this.services)]
        }

        // if all passengers are adults and none of them have infants then we don't have any restriction.
        if(this.booking.passengers.all(p => p.passengerType.isAdult && !p.infant)) {
            return [];
        }

        // it means we have children on the booking and not all passengers have seats. So restriction!
        return [new NotAllPassengersHaveSeatsRestriction(this.services)]

    }

    private _getPassengersCheckInRestrictedSsrs(): Record<string, string> {
        const journeyCheckInRequirements = this.segment.journey.getCheckInRequirements();
        if(!journeyCheckInRequirements) {
            return {};
        }

        const passengerCheckInRequirements = journeyCheckInRequirements.passengers[this.passengerKey];
        if(!passengerCheckInRequirements) {
            return {};
        }

        return (passengerCheckInRequirements.invalidSsrs || []).toDictionary(ssrCode => ssrCode);
    }

    private _getCheckInRestrictionsBySsrs(): ICheckInRestriction[] {
        let ssrsRestrictions: ICheckInRestriction[] = [];

        const restrictedSsrs = this._getPassengersCheckInRestrictedSsrs();

        this.getSoldSsrsEditors().forEach(ssr => {
            if(ssr.ssrType.shouldBlockOnlineCheckIn) {
                ssrsRestrictions = [
                    ...ssrsRestrictions,
                    ...ssr.ssrType.getOnlineCheckInRestrictions()
                ]
            } else if(restrictedSsrs[ssr.ssrType.ssrCode]) {
                ssrsRestrictions.push(new SsrOnlineCheckInRestriction(ssr.ssrType, this.services))
            }
        });

        return ssrsRestrictions;
    }

    private _getOnlineCheckInAgeRestrictions(): ICheckInRestriction[] {

        const otherPassengersAges = this._getOtherPassengersAges();

        //if any other passenger is over 18 then he is NOT restricted for online check-in
        if(otherPassengersAges.some(age => age >= 18)) {
            return [];
        }

        const thisPassengerAge = this.passenger.computePassengerAge(this.segment.journey.designator.departureDate);
        //if he didn't provide the birth date then he is restricted for online check-in
        if(Check.isNullOrUndefined(thisPassengerAge)) {
            return [new TravelDocumentValidationRestriction(this.segment.translate('Date of birth is required'))];
        }

        //if himself is over 18 then he is NOT restricted for online check-in
        if(thisPassengerAge >= 18) {
            return [];
        }

        //if he is under 14 and there is no adult over 18 he is restricted for online check-in
        if(thisPassengerAge < 14) {
            return [new UnaccompaniedMinorCheckInRestriction(this.segment.journey.services)];
        }

        if(Check.isEmpty(this.passenger.fields.nationality.value)) {
            return [new TravelDocumentValidationRestriction(this.segment.translate('Citizenship is required'))];
        }

        //TODO - extract "RO" in a constant
        if(thisPassengerAge >= 14 && this.passenger.fields.nationality.value === "RO") {
            return [new UnaccompaniedMinorCheckInRestriction(this.segment.journey.services)];
        }

        //any other is NOT restricted for online check-in
        return [];
    }

    //Restrictions that cannot be solved by the user input
    getUserIndependentCheckInBlockingRestrictions(): ICheckInRestriction[] {
        return [
            ...this.segment.getUserIndependentCheckInBlockingRestrictions(),
            ...this._getCheckInRestrictionsBySsrs(),
            ...this.passenger.getUserIndependentCheckInBlockingRestrictions()
        ];
    }

    //Restrictions that can be solved by the user input (for example by filling documents information)
    getUserDependentCheckInBlockingRestrictions(): ICheckInRestriction[] {
        const restrictions = [
            ...this._getOnlineCheckInAgeRestrictions(),
            ...this.segment.getUserDependentCheckInBlockingRestrictions(),
            ...this._getCheckInRestrictionsForRandomSeatAllocation(),
            ...this.passenger.getUserDependentCheckInBlockingRestrictions(),
        ]

        if(restrictions.length > 0) {
            return restrictions;
        }

        return this._getOnlineCheckInRestrictionsByBundle();
    }

    private get bundleEarliestOnlineCheckingWhenHasSeat(): TimeSpan {
        if (this.segment.journey.selectedBundle) {
            return this.segment.journey.selectedBundle.bundleType.earliestCheckInWhenHasSeat;
        } else {
            return this.services.bundleTypes.getLowestBundleType().earliestCheckInWhenHasSeat;
        }
    }

    private get bundleEarliestOnlineCheckingWhenHasNoSeat(): TimeSpan {
        if (this.segment.journey.selectedBundle) {
            return this.segment.journey.selectedBundle.bundleType.earliestCheckIn;
        } else {
            return this.services.configuration.noBundleEarliestCheckIn;
        }
    }

    private get bundleEarliestOnlineChecking(): TimeSpan {
        if(this.hasSeat) {
            return this.bundleEarliestOnlineCheckingWhenHasSeat;
        } else {
            return this.bundleEarliestOnlineCheckingWhenHasNoSeat;
        }
    }

    private get bundleAllowsOnlineCheckIn(): boolean {
        const time = this.services.time;
        return time.currentDate.getTime() >= time.addMilliseconds(this.segment.journey.designator.departureDate, -this.bundleEarliestOnlineChecking.totalMilliseconds).getTime();
    }

    private _getOnlineCheckInRestrictionsByBundle(): ICheckInRestriction[] {

        if(this.bundleAllowsOnlineCheckIn) {
            return [];
        }

        const earlyCheckInSsr = this.getSsr(this.services.ssrTypes.ECKN);
        if(earlyCheckInSsr.currentQuantity > 0 && this.bundleCode) {
            if(this.services.configuration.ssrs.eckn.canBeUsed(this.segment.journey.booking.bookingCreationDate, this.segment.journey.designator.departureDate, this.bundleCode)) {
                return [];
            }
        }

        if(this.hasSeat) {
            return [];
        }

        return [new NoSeatPurchasedCheckInRestriction(this.bundleEarliestOnlineCheckingWhenHasNoSeat, this, this.services)];

    }

    getAllOnlineCheckInRestrictions(): ICheckInRestriction[] {
        return [
            ...this.getUserIndependentCheckInBlockingRestrictions(),
            ...this.getUserDependentCheckInBlockingRestrictions(),
        ];
    }

    getPreCheckInRestrictions(): ICheckInRestriction[] {
        return [
            ...this._getCheckInRestrictionsBySsrs()
        ];
    }

    get isValidForOnlineCheckIn(): boolean {
        return this.getAllOnlineCheckInRestrictions().length === 0;
    }

    get hasUserIndependentOnlineCheckInBlockingRestrictions(): boolean {
        return this.getUserIndependentCheckInBlockingRestrictions().length > 0;
    }

    getBundleFee(): Price {
        return this.passenger.getBundleFee(this.segment);
    }

    get flightReference(): string {
        return this.segment.flightReference;
    }


    get allServiceFees(): PassengerFeeModel[] {
        return this.passenger.fees.filter(fee => fee.flightReference === this.flightReference);
    }

    get infantFees(): InfantFeeModel[] {
        return (this.passenger?.infant?.fees || []).filter(f => f.flightReference === this.flightReference);
    }

    get seatFee(): ISeatFeeModel | null {
        if(this.shoppingCart.seatFees.length === 0) {
            return null;
        }

        return this.shoppingCart.seatFees[0];
    }

    markBundleAsSelected(bundleCode: string): void {
        runInAction(() => {
            this.passengerSegmentDotRezData.bundleCode = bundleCode;
        });

    }

    getInitialFare(): NullablePrice {
        return this.segment.getInitialFare(this.passengerKey);
    }
    getInitialSeatPrice(): Price | null {
        return this.segment.getInitialSeatPrice(this.passengerKey);
    }

    getInitialSeatNumber(): NullableString {
        return this.segment.getInitialSeatNumber(this.passengerKey);
    }

    getInitialPurchasedSsPrice(ssrType: ISsrType, ssrIndex: number): Price | null {
        return this.segment.getInitialPurchasedSsrPrice(this.passengerKey, ssrType, ssrIndex);
    }

    getInitialInBundleSsrPrice(ssrType: ISsrType, ssrIndex: number): Price | null {
        return this.segment.getInitialInBundleSsrPrice(this.passengerKey, ssrType, ssrIndex);
    }

    getInitialOtherFeePrice(feeType: FeeTypeEnum, feeIndex: number): Price | null {
        return this.segment.getInitialOtherFeePrice(this.passengerKey, feeType, feeIndex);
    }

    getInitialInfantPrice(): NullablePrice {
        return this.segment.getInitialInfantPrice(this.passengerKey);
    }

    getVirtualSsrsIncludedInBundle(): ISsrType[] {
        const selectedBundle = this.segment.journey.selectedBundle;
        if(!selectedBundle) {
            return [];
        }

        return selectedBundle.includedSsrs.filter(ssr => Boolean(ssr.ssrType.aliasFor))
                                          .map(ssr => ssr.ssrType);
    }

    revertSsrsQuantity(): void {
        for(let soldSsr of this.getSoldSsrsEditors()) {
            soldSsr.revertSssQuantity()
        }
    }


}
