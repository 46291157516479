import React from 'react';
import {AvailableJourneyComponent} from "./available-journey.component";
import {observer} from "mobx-react";
import {IAvailableTripViewModel} from "../../../../../services/flight-search/models/available-trip-view-model.interface";

interface TripComponentProps {
    trip: IAvailableTripViewModel;
}

export const AvailableTripComponent: React.FC<TripComponentProps> = observer((props) => {
    return (
        <>
            {props.trip.journeys.map(journey => {
                return (
                    <React.Fragment key={journey.journeyKey}>
                        <AvailableJourneyComponent journey={journey}/>
                    </React.Fragment>
                )
            })}
        </>

    )
});
