import {PassengerFeeBaseModel} from "../../base-models/fees/passenger/passenger-fee-base.model";
import {IDotRezPassengerFee} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {BookingSnapshotModel} from "../booking/booking-snapshot.model";
import {Price} from "../../../../currency/price";
import {PassengerSnapshotModel} from "../passenger/passenger-snapshot.model";

export class PassengerFeeSnapshotModel extends PassengerFeeBaseModel {
    constructor(feeData: IDotRezPassengerFee,
                passenger: PassengerSnapshotModel,
                private booking: BookingSnapshotModel) {
        super(feeData, booking.services, passenger);
    }


    createPrice(amount: number): Price {
        return this.booking.createPrice(amount);
    }
}

