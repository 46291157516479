import React, {CSSProperties, PropsWithChildren} from "react";

interface BoardingPassBagsSectionComponentProps extends PropsWithChildren {
    styles?: CSSProperties;
}

export const BoardingPassBagsSectionComponent: React.FC<BoardingPassBagsSectionComponentProps> = (props) => {
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '50%',
        ...props.styles
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
}
