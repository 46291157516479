import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {AirlineLogoMulticolorIcon} from "../../../assets/svg/airline-logo-multicolor.icon";
import {useServices} from "../../../hooks/use-services.hook";
export const TicketAirlineLogoComponent: React.FC = observer(() => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: services.theme.currentTheme.spacing.large
    };

    return (
        <div style={styles}>
            <AirlineLogoMulticolorIcon/>
        </div>
    );
})