import {IFieldValidator} from "../field-validator.interface";
import {IServiceFactory} from "../../../services/service-factory.interface";
import {NullableString} from "../../../types/nullable-types";

export class EMailValidator implements IFieldValidator {
    constructor(private readonly services: IServiceFactory) {
    }
    
    validate(fieldValue: any): NullableString {
        if(!fieldValue) {
            return null;
        }

        const emailRegularExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(emailRegularExpression.test(fieldValue)) {
            return null;
        }

        return this.services.language.translate('Is not a valid e-mail address');
    }
}
