import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {FlightDayContentComponent} from "./flight-day-content.component";
import {CalendarComponent} from "../../../../components/calendar/calendar.component";
import {computed, makeObservable} from "mobx";
import {MonthModel} from "../../../../services/time/month.model";
import {FlightsDatesSelector} from "../../../../services/flight-search/dates-selection/flights-dates-selector";
import {FlightDatesSelectionSkeletonsComponent} from "./flight-dates-selection-skeletons.component";
import styled from "styled-components";
import {SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {useServices} from "../../../../hooks/use-services.hook";
import {ResizeObserver} from "@juggle/resize-observer";
import {breakpoints} from "../../../../services/media-query/breakpoints";
import {SwiperComponent} from "../../../../basic-components/swiper/swiper.component";

class PriceRenderController {
    constructor(private readonly flightsDatesSelector: FlightsDatesSelector) {
        makeObservable(this, {
            months: computed
        });
    }

    get months(): MonthModel[] {
        return this.flightsDatesSelector.getFlightsScheduledMonths();
    }

}

const ScheduleLoadingErrorBox = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontSize.large};
    padding: ${props => props.theme.spacing.large};
`

const SwiperContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`



interface FlightDatesSelectionPageContentProps {
    captureSelectedDateElementRef?: (date: Date, element: HTMLElement | null) => void;
    onDateSelected?: (date: Date) => void;
    flightsDatesSelector: FlightsDatesSelector;
    useSwiper: boolean;
}

export const FlightDatesSelectionPageContent: React.FC<FlightDatesSelectionPageContentProps> = observer((props) => {
    const services = useServices();
    const [priceRenderController] = useState(new PriceRenderController(props.flightsDatesSelector));
    const swiperContainerBoxRef = React.createRef<HTMLDivElement>();
    const [swiperBoxWidth, setSwiperBoxWidth] = useState(0);


    useEffect(() => {
        if(swiperContainerBoxRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                setSwiperBoxWidth(entries[0].target.getBoundingClientRect().width);
            });
            resizeObserver.observe(swiperContainerBoxRef.current);
            return () => {
                resizeObserver.disconnect();
            }
        }
        return () => {}
    }, [swiperContainerBoxRef]);

    if(props.flightsDatesSelector.scheduleLoadingError) {
        return (
            <ScheduleLoadingErrorBox>
                {props.flightsDatesSelector.scheduleLoadingError}
            </ScheduleLoadingErrorBox>
        );
    }

    if(props.flightsDatesSelector.isLoadingSchedule) {
        return (<FlightDatesSelectionSkeletonsComponent/>)
    }

    const months = priceRenderController.months;
    if(months.length === 0) {
        return null;
    }


    const renderDate = (date: Date, isInViewPort: boolean) => {
        return (
            <FlightDayContentComponent date={date}
                                       captureSelectedDateElementRef={props.captureSelectedDateElementRef}
                                       shouldRenderPrice={isInViewPort}
                                       flightsDatesSelector={props.flightsDatesSelector}/>
        )
    }

    const onClickHandler = (date: Date) => {
        if(!props.flightsDatesSelector.isDateAvailable(date)) {
            return;
        }

        props.flightsDatesSelector.selectDate(date);
        if(props.onDateSelected) {
            props.onDateSelected(date);
        }
    }

    const renderOneCalendar = (month: MonthModel) => {
        return (
            <CalendarComponent key={`${month.year}_${month.month}`}
                               month={month.month}
                               year={month.year}
                               renderDate={(date, isInViewPort) => renderDate(date, isInViewPort)}
                               onClick={onClickHandler}/>
        );
    }

    if(props.useSwiper) {

        const renderOneSlide = (month: MonthModel) => {
            return (
                <SwiperSlide key={month.key}>
                    {renderOneCalendar(month)}
                </SwiperSlide>
            );
        }

        const getNumberOfSlides = () => {

            if(swiperBoxWidth <= breakpoints.mMax) {
                return 1;
            }

            if(swiperBoxWidth <= breakpoints.lMax) {
                return 2;
            }

            return 3;

        }

        const numberOfSlides = getNumberOfSlides();
        let initialSlide = 0;
        const months = props.flightsDatesSelector.getFlightsScheduledMonths();

        if(props.flightsDatesSelector.departureDate) {
            let monthIndex = months.findIndex(m => m.containsDate(props.flightsDatesSelector.departureDate!));
            if(monthIndex >= 0) {
                initialSlide = monthIndex;
            }
        }

        return (
            <SwiperContainerBox ref={swiperContainerBoxRef}>

                <SwiperComponent modules={[Navigation]}
                                 navigationButtons={{size: "large"}}
                                 initialSlide={initialSlide}
                                 spaceBetween={services.theme.currentTheme.spacing.large}
                                 slidesPerView={numberOfSlides}>
                    {months.map(renderOneSlide)}
                </SwiperComponent>

            </SwiperContainerBox>
        );
    }


    return (<>
        {
            months.map(renderOneCalendar)
        }
    </>);
});
