import React from "react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import { CardBox } from "../../../../../basic-components/card/card.box";
import styled from "styled-components";
import { CalendarIcon } from "../../../../../assets/svg/calendar.icon";
import {NullableDate} from "../../../../../types/nullable-types";

import {VerticalLine} from "../../../../../basic-components/spacing/vertical-line";
import {searchMaskIconSpacing} from "./search-mask-hybrid-css-constants";

const DEPARTURE_DATE_CLASS_NAME = 'departure';
const RETURN_DATE_CLASS_NAME = 'return';

const FlightDatesContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: row;
    min-height: 60px;
    padding: 0;
`

const FlightDateWithIconContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    padding: ${props => props.theme.spacing.cardDefaultPaddingPx};
`

const FlightDateValueBox = styled.div`
    display: flex;
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1;
    flex: 1;
    
    &.${DEPARTURE_DATE_CLASS_NAME} {
        justify-content: flex-start;
        text-align: left;
    }
    &.${RETURN_DATE_CLASS_NAME} {
        justify-content: flex-end;
        text-align: right;
    }
`

const FlightDateUnselectedValueBox = styled.div`
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.normal};
    line-height: 1;
`

const FlightDateSelectedValueBox = styled(FlightDateUnselectedValueBox)`
    font-weight: ${props => props.theme.fontWeight.bold};
`

const DepartureDateCalendarIconBox = styled.div`
    padding-right: ${props => searchMaskIconSpacing(props.theme)};
`

const ReturnDateCalendarIconBox = styled.div`
    padding-left: ${props => searchMaskIconSpacing(props.theme)};
`


interface FlightDateComponentProps {
    label: string;
    date: NullableDate;
    className: string;
}

const FlightDateValueComponent: React.FC<FlightDateComponentProps> = observer((props) => {
    const services = useServices();

    const renderDate = () => {
        if(props.date) {
            return (
                <FlightDateSelectedValueBox>
                    {services.time.shortDateInCurrentLocale(props.date)}
                </FlightDateSelectedValueBox>
            );
        }
        return (<FlightDateUnselectedValueBox>
            {props.label}
        </FlightDateUnselectedValueBox>);
    }

    return (
        <FlightDateValueBox className={props.className} >
            {renderDate()}
        </FlightDateValueBox>
    );
});

const DepartureDateComponent: React.FC = observer( () => {
    const services = useServices();
    const onClick = () => services.navigator.routes.flightSearch.flightDates.activate();
    return (
        <FlightDateWithIconContainerBox onClick={onClick}>
            <DepartureDateCalendarIconBox>
                <CalendarIcon size={2}/>
            </DepartureDateCalendarIconBox>
            <FlightDateValueComponent label={services.language.translate('Departure date')}
                                 date={services.flightSearch.searchController.departureDate}
                                 className={DEPARTURE_DATE_CLASS_NAME}/>
        </FlightDateWithIconContainerBox>

    );
});

const ReturnDateComponent: React.FC = observer(() => {
    const services = useServices();
    if(services.flightSearch.searchController.isOneWayDepartureTrip) {
        return null;
    }

    const onClick = () => {
        services.navigator.routes.flightSearch.flightDates.activate();
    };

    return (

        <FlightDateWithIconContainerBox onClick={onClick}>

            <FlightDateValueComponent label={services.language.translate('Return date')}
                                      date={services.flightSearch.searchController.returnDate}
                                      className={RETURN_DATE_CLASS_NAME}/>
            <ReturnDateCalendarIconBox>
                <CalendarIcon size={2}/>
            </ReturnDateCalendarIconBox>
        </FlightDateWithIconContainerBox>
    );
});

export const SearchMaskFlightDatesHybridComponent: React.FC = observer(() => {
    return (
        <FlightDatesContainerBox>
            <DepartureDateComponent/>
            <VerticalLine/>
            <ReturnDateComponent/>
        </FlightDatesContainerBox>
    );
});
