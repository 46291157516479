import React, {CSSProperties} from "react";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {BoardingPassFieldLabelComponent} from "../label/boarding-pass-field-label.component";
import {BoardingPassFieldValueComponent} from "../value/boarding-pass-field-value.component";
import {useServices} from "../../../../hooks/use-services.hook";

export const BoardingPassPassengerFiledComponent: React.FC<{label: string; value: string;}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: '100%',
        marginBottom: theme.spacing.small
    }

    return (
        <div style={styles}>
            <BoardingPassFieldLabelComponent styles={{paddingRight: theme.spacing.small, width: '47%'}}>
                {props.label}
            </BoardingPassFieldLabelComponent>
            <BoardingPassFieldValueComponent styles={{paddingTop: '0', flexGrow: 1, justifyContent: 'flex-start'}}>
                {props.value}
            </BoardingPassFieldValueComponent>
        </div>
    )
}

const PassengerNameContainerBox: React.FC<{passengerName: string}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: '100%',
        marginBottom: theme.spacing.medium
    }

    return (
        <div style={styles}>
            <BoardingPassFieldLabelComponent>
                {services.language.translate('Passenger')}
            </BoardingPassFieldLabelComponent>
            <BoardingPassFieldValueComponent styles={{fontSize: theme.fontSize.xxLarge}}>
                {props.passengerName}
            </BoardingPassFieldValueComponent>
        </div>
    )
}

export const BoardingPassPassengerComponent: React.FC<{boardingPass: IPassengerSegmentBoardingPassViewModel}> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        lineHeight: 1
    }

    const renderDocument = () => {
        if(!(props.boardingPass.documentTypeCode || props.boardingPass.documentNumber || props.boardingPass.documentExpirationDate)) {
            return null;
        }
        return (
            <>
                <BoardingPassPassengerFiledComponent label={services.language.translate('Document type:')}
                                                     value={services.travelDocumentType.tryGetDocumentTypeName(props.boardingPass.documentTypeCode) ?? ""}/>

                <BoardingPassPassengerFiledComponent label={services.language.translate('Document Number:')}
                                                     value={props.boardingPass.documentNumber ?? ""}/>

                <BoardingPassPassengerFiledComponent label={services.language.translate('Expiry date:')}
                                                     value={services.time.formatYYY_MM_DD(props.boardingPass.documentExpirationDate)}/>
            </>
        )
    }

    return (
        <div style={styles}>
            <PassengerNameContainerBox passengerName={props.boardingPass.fullName}/>

            <BoardingPassPassengerFiledComponent label={services.language.translate('Date of birth:')}
                                                 value={props.boardingPass.dateOfBirth}/>

            <BoardingPassPassengerFiledComponent label={services.language.translate('Citizenship:')}
                                                 value={props.boardingPass.nationality}/>

            {renderDocument()}
        </div>
    )
}
