import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {SellBundleMutation} from "./sell-bundle.mutation";

export class SellDepartureJourneyBundleMutation extends SellBundleMutation {
     
    
    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        const bundleToSell = this.bundleToSell;

        const journeysToSellTheBundleFor = [this.departureJourney];
        let ssrKeysToRemoveOnBundleChange = this.departureJourney.getSsrKeysToRemoveOnBundleChange(this.bundleToSell);

        if(this.returnJourney?.selectedBundle) {// if there is already a bundle selected for the return journey we want to keep it in sync with the departure journey bundle
            journeysToSellTheBundleFor.push(this.returnJourney);
            ssrKeysToRemoveOnBundleChange = [
                ...ssrKeysToRemoveOnBundleChange,
                ...this.returnJourney.getSsrKeysToRemoveOnBundleChange(this.bundleToSell)
            ];
        }

        await this.session.sellBundle(journeysToSellTheBundleFor.map(j => j.journeyKey), ssrKeysToRemoveOnBundleChange, {
            bundleCode: bundleToSell.bundleCode
        });

        return await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();
    }

    async onAfterBookingSessionDataUpdated(): Promise<void> {
        await super.onAfterBookingSessionDataUpdated();
        setTimeout(  ()=> {
            this.services.analytics.flightSelectionEvents.logAddToCartForDeparture(this.booking);
        })

    }
}
