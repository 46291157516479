import {IPdfExporterStrategy} from "./pdf-exporter.strategy.interface";
import {IPrintPdfOptions, ISharePdfOptions} from "../pdf-exporter.service.interface";
import {IServiceFactory} from "../../service-factory.interface";
const html2pdf: any = require('html2pdf.js');

export class PdfExporterWebappStrategy implements IPdfExporterStrategy {
    constructor(private readonly services: IServiceFactory) {

    }

    readonly supportsSharing = false;

    async printPdf(options: IPrintPdfOptions): Promise<void> {
        try {
            await html2pdf().set({
                filename: options.fileName,
                margin: 5,
                jsPDF: {format: 'a4'}
            }).from(options.element).save();
        } catch (err) {
            this.services.logger.error('Failed to print pdf', err);
        }


    }

    async sharePdf(options: ISharePdfOptions): Promise<void> {
        throw new Error('sharePdf not supported');
    }

}
