import {IAnalyticsStrategy} from "../analytics.service.interface";
import {IAnalyticsPurchaseEvent} from "../../booking/models/analytics/google-analytics-purchase.interface";
import {
    IAnalyticsExtrasSelectionEvents,
    IAnalyticsFlightSelectionEvents,
    IAnalyticsPassengerDetailsEvents,
    IAnalyticsPaymentEvents,
    IAnalyticsSeatSelectionEvents
} from "../../booking/models/analytics/google-analytics.intefaces";

export class NoAnalyticsStrategy implements IAnalyticsStrategy {

    logLoginEvent(): void {
    }

    logPurchaseEvent(recordLocator: string, event: IAnalyticsPurchaseEvent): void {
    }

    logSignUpEvent(): void {
    }

    async sendEvent(event: any): Promise<void>{
    }

    get extrasSelectionEvents(): IAnalyticsExtrasSelectionEvents {
        return new Proxy({}, handler);
    }
    get flightSelectionEvents(): IAnalyticsFlightSelectionEvents{
        return  new Proxy({}, handler);
    }
    get passengerDetailsEvents(): IAnalyticsPassengerDetailsEvents{
        return new Proxy({}, handler);
    }
    get paymentEvents(): IAnalyticsPaymentEvents{
        return new Proxy({}, handler);
    }
    get seatSelectionEvents(): IAnalyticsSeatSelectionEvents {
        return new Proxy({}, handler);
    }
}

const handler = {
    get: (target:any, prop: any) => {
        // Always return 'success' for any method call
        return () => 'success';
    },
};

