import {ICheckInRestriction} from "./check-in-restriction.interface";
import {IServiceFactory} from "../../../service-factory.interface";

export class NotAllPassengersHaveSeatsRestriction implements ICheckInRestriction {
    constructor(private readonly services: IServiceFactory) {
    }

    get description(): string {
        return this.services.language.translate(`Cannot perform online check-in because for this reservation random seat allocation is not possible. Please attach seats to your booking or report to the check-in desk 2 hours before your flight. You will be assigned seats for free at the check-in desk based on availability`);
    }
    get shouldBlockSavingTravelDocuments(): boolean {
        return false;
    }
}
