import React from 'react';
import {SearchMaskStationsHybridComponent} from "./search-mask-stations.hybrid.component";
import {SearchMaskFlightDatesHybridComponent} from "./search-mask-flight-dates.hybrid.component";
import {SearchMaskPassengersHybridComponent} from "./search-mask-passengers.hybrid.component";
import {SearchMaskTripTypeHybridComponent} from "./search-mask-trip-type.hybrid.component";
import styled from 'styled-components';
import {observer} from "mobx-react";
import {SearchMaskBookNowButtonComponent} from "../../../../../components/common/search-mask/search-mask-book-now-button.component";
import {useServices} from "../../../../../hooks/use-services.hook";
import {SearchMaskSpecialPriceMarketHybridComponent} from "./search-mask-special-price-market.hybrid.component";

const SearchMaskContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.large};
  width: 100%;
`

export const SearchMaskHybridComponent: React.FC = observer(() => {
    const services = useServices();

    const onBookNowClick = async () => {
        await services.flightSearch.startBooking();
    }

    return (
        <SearchMaskContainerBox>
            <SearchMaskTripTypeHybridComponent/>
            <SearchMaskStationsHybridComponent/>
            <SearchMaskSpecialPriceMarketHybridComponent/>
            <SearchMaskFlightDatesHybridComponent/>
            <SearchMaskPassengersHybridComponent/>
            <SearchMaskBookNowButtonComponent onClick={onBookNowClick}/>
        </SearchMaskContainerBox>
    );
});
