import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import {YesNoDialogPrimaryButton} from "../../services/dialog-factory/yes-no-dialog.enums";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../basic-components/buttons/primary-button-inverted";
import {VerticalSpacer} from "../../basic-components/spacing/vertical-spacer";

const DialogTextBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1.4;
`


interface YesNoDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    title?: string;
    message: string | React.ReactElement;
    primaryButton: YesNoDialogPrimaryButton;
    yesButtonText?: string;
    noButtonText?: string;

}
export const YesNoDialogComponent: React.FC<YesNoDialogComponentProps> = observer((props) => {
    const services = useServices();
    const yesButtonText = props.yesButtonText || services.language.translate('Yes');
    const noButtonText = props.noButtonText || services.language.translate('No');

    const onYesClick = async () => {
        props.dialogHandler.accept();
    }

    const onNoClick = async () => {
        props.dialogHandler.reject();
    }

    const renderPrimaryButton = () => {
        if(props.primaryButton === YesNoDialogPrimaryButton.PrimaryButtonNo) {
            return (
                <PrimaryButton onClick={onNoClick}>
                    {noButtonText}
                </PrimaryButton>
            )
        } else {
            return (<PrimaryButton onClick={onYesClick}>
                {yesButtonText}
            </PrimaryButton>);
        }
    }

    const renderSecondaryButton = () => {
        if(props.primaryButton === YesNoDialogPrimaryButton.PrimaryButtonYes) {
            return (
                <PrimaryButtonInverted onClick={onNoClick}>
                    {noButtonText}
                </PrimaryButtonInverted>
            )
        } else {
            return (<PrimaryButtonInverted onClick={onYesClick}>
                {yesButtonText}
            </PrimaryButtonInverted>);
        }
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={props.title} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <DialogTextBox>
                    {props.message}
                </DialogTextBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                {renderPrimaryButton()}
                <VerticalSpacer/>
                {renderSecondaryButton()}
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
