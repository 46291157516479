import {PassengerSegmentShoppingCartBaseModel} from "../../base-models/shopping-cart/passenger-segment-shopping-cart-base.model";
import {PassengerSegmentSnapshotModel} from "../passenger-segment/passenger-segment-snapshot.model";
import {ISeatFeeAnalyticsData} from "../../analytics/google-analytics.intefaces";
import {ISsrFeeModel} from "../../base-models/fees/ssr-fees/ssr-fee-model.interface";
import { IFeeModel } from "../../base-models/fees/fee-model.interface";

export class PassengerSegmentShoppingCartSnapshotModel extends PassengerSegmentShoppingCartBaseModel<PassengerSegmentSnapshotModel> {
    getSsrsFeesForId(ids: string[]): IFeeModel[] {
        return [];
    }


    getSeatFeeAnalyticsData(): ISeatFeeAnalyticsData | null {
        return null;
    }

    getSsrsFeesGroupedByAnalyticsName(): Record<string, ISsrFeeModel[]> | null {
        return null;
    }
}
