import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {WizardProgressIndicatorComponentProps} from "./wizard-progress-indicator.component.props";
import {IWizardStepViewModel} from "../../models/wizard/wizard-step.view-model.interface";

const StepLineBox = styled.span<{stepsCount: number}>`
    border-radius: 4px;
    width: ${props => props.stepsCount > 15 ? '5px' : (props.stepsCount > 10 ? '8px' : '13px')};
    height: 5px;
    margin-left: 4px;
    margin-right: 4px;
`;

const PreviousStepLineBox = styled(StepLineBox)`
  background-color: ${props => props.theme.colors.medium};
`;

const CurrentStepLineBox = styled(StepLineBox)`
    background-color: ${props => props.theme.colors.primaryContrast};
    width: ${props => props.stepsCount > 15 ? '10px' : (props.stepsCount > 10 ? '16px' : '26px')};
`;

const NextStepLineBox = styled(StepLineBox)`
    background-color: ${props => props.theme.colors.primaryContrast};
`;


const WizardProgressIndicatorBox = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const WizardProgressIndicatorSmallScreenComponent: React.FC<WizardProgressIndicatorComponentProps> = observer((props) => {
    const wizard = props.getWizard();
    const stepsCount = wizard.steps.length;
    const currentStepIndex = wizard.currentStep?.index ?? -1;

    const renderOneStep = (step: IWizardStepViewModel) => {
        if(!step.isVisible) {
            return null;
        }
        const stepIndex = step.index;
        if(stepIndex === currentStepIndex) {
            return (<CurrentStepLineBox key={step.title} stepsCount={stepsCount}/>);
        } else if (stepIndex < currentStepIndex) {
            return (<PreviousStepLineBox key={step.title} stepsCount={stepsCount}/>);
        } else {
            return (<NextStepLineBox key={step.title} stepsCount={stepsCount}/>);
        }
    }

    return (
        <WizardProgressIndicatorBox>
            {wizard.steps.map(renderOneStep)}
        </WizardProgressIndicatorBox>
    );
});
