import React from "react";
import { useServices } from "../../../../../hooks/use-services.hook";
import { observer } from "mobx-react-lite";
import { PaymentAccordionHeaderComponent } from "../payment-accordion/payment-accordion-header.component";
import { PaymentAccordionComponent } from "../payment-accordion/payment-accordion.component";
import { PaymentCreditCardIcon } from "../../../../../assets/svg/payment-credit-card.icon";
import { IPaymentMethodViewModel } from "../../../../../services/booking/models/payment/payment-methods/payment-method.view-model.interface";
import { CreditCardPaymentMethodComponent } from "./credit-card-payment-method.component";
import styled from "styled-components";
import {IPaymentHandlerViewModel} from "../../../../../services/booking/models/payment/payment-handler-view-model.interface";

const PaymentCreditCardMethodsContentBox = styled.div`
    width: 100%;
`

const PaymentCreditCardMethodsDescriptionBox = styled.div`
    line-height: 1;
    margin-bottom: ${props => props.theme.spacing.large};
    color: ${props => props.theme.colors.dark};
    font-size: ${props => props.theme.fontSize.large};
`

const PaymentCreditCardsBox = styled.div`
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    width: 100%;
    flex-flow: row wrap;
    gap: 15px;
`

interface PaymentCreditCardMethodsComponentProps {
    paymentMethods: IPaymentMethodViewModel[],
    paymentHandler: IPaymentHandlerViewModel;
}
export const CreditCardPaymentMethodsComponent: React.FC<PaymentCreditCardMethodsComponentProps> = observer((props) => {
    const services = useServices();

    const PaymentCreditCardMethodsHeader = () => {
        return (
            <PaymentAccordionHeaderComponent renderIcon={() => <PaymentCreditCardIcon color={services.theme.currentTheme.colors.secondary} />} title={services.language.translate('Cards')} />
        )
    }

    const PaymentCreditCardMethodsContent = () => {
        return (
            <PaymentCreditCardMethodsContentBox>
                <PaymentCreditCardMethodsDescriptionBox>
                    {services.language.translate("You can choose to pay the booking online using a credit card")}
                </PaymentCreditCardMethodsDescriptionBox>
                <PaymentCreditCardsBox>
                    {props.paymentMethods.filter(pm => !pm.hasCustomRenderer).map(method => <CreditCardPaymentMethodComponent key={method.code} paymentMethod={method} />)}
                </PaymentCreditCardsBox>
            </PaymentCreditCardMethodsContentBox>
        )
    }

    return (
        <PaymentAccordionComponent renderHeader={PaymentCreditCardMethodsHeader}
                                   renderContent={PaymentCreditCardMethodsContent}
                                   isExpanded={true}/>
    )
});
