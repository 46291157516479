import React from 'react';
import {ISvgIconProps, SvgIcon} from './svg-icon.component';

export const CheckedPaymentSuccessfulIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={7.2} {...props} viewBox="0 0 72 72" ratio={1}>
            <g transform="translate(-144.14 -314.14)" fill={props.color? props.color: "currentColor"}>
                <path d="M36,4A32.009,32.009,0,0,0,23.545,65.487,32.009,32.009,0,0,0,48.455,6.513,31.8,31.8,0,0,0,36,4m0-4A36,36,0,1,1,0,36,36,36,0,0,1,36,0Z" transform="translate(144.14 314.14)" />
                <path d="M326.308,431.314a2.779,2.779,0,0,1-1.915-.749l-10.657-10.657a2.649,2.649,0,1,1,3.746-3.746l8.825,8.825,17.65-17.65a2.649,2.649,0,0,1,3.746,3.746L328.14,430.648A2.744,2.744,0,0,1,326.308,431.314Z" transform="translate(-149.246 -66.945)" />
            </g>
        </SvgIcon>
    )
}
