import {SeatFilterBaseModel} from "./seat-filter-base.model";
import {SeatModel} from "../seat.model";
import React from "react";
import {PetIcon} from "../../../../../assets/svg/pet.icon";
import {IServiceFactory} from "../../../../service-factory.interface";

export class PetSeatFilterModel extends SeatFilterBaseModel {
    constructor(services: IServiceFactory) {
        super(services.language.translate('Seats for pets'), services);
    }

    shouldAllowSeat(seat: SeatModel): boolean {
        return seat.allowsPet;
    }

    renderIcon(): React.ReactElement {
        return (<PetIcon size={2}/>);
    }
}
