import {LocalStorageKeys} from "../../../storage/local-storage-keys";
import {BookingHistoryModel} from "../../models/booking-history.model";
import {
    IPersistedBookingHistory
} from "../../models/persisted-booking-history.interface";
import {BookingHistoryStrategyBase} from "../booking-history-strategy-base";
import {IBookingHistoryStrategy} from "../booking-history-strategy.interface";
import {BoardingPassesStorage} from "../../models/boarding-passes-storage";


const BOARDING_PASSES_STORAGE_KEY: LocalStorageKeys = 'booking.boardingPasses';

export class AnonymousUserBookingHistoryStrategy extends BookingHistoryStrategyBase implements IBookingHistoryStrategy {

    protected _getTripsStorageKey(): string {
       return this._getAnonymousTripsStorageKey();
    }


    protected _createBoardingPassesStorage(): BoardingPassesStorage {
        return new BoardingPassesStorage(this.services, BOARDING_PASSES_STORAGE_KEY);
    }

    protected _loadMyTripsFromLocalStorage() {
        const trips = (this.services.localStorage.getJson<IPersistedBookingHistory[]>(this._getTripsStorageKey()) || [])
            .map(bookingInfo => new BookingHistoryModel(bookingInfo, this.services))

        this._setMyTrips(trips, false);
    }

    protected async _syncTrips(): Promise<void> {
        this._loadMyTripsFromLocalStorage();
    }

}
