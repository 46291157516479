import React from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import {QuestionMarkIcon} from "../../../../../../../assets/svg/question-mark,icon";
import {ArrowsRightLeftIcon} from "../../../../../../../assets/svg/arrows-right-left.icon";
import styled from "styled-components";
import {IFlightSearchControllerViewModel} from "../../../../../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";

const DropDownInputBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`

const ArrowsBox = styled.div`
    margin: 0 ${props => props.theme.spacing.large} 0 ${props => props.theme.spacing.large};
`

interface FlightDatesDropDownInputComponentProps {
    searchController: IFlightSearchControllerViewModel;
}

export const FlightDatesDropDownInputComponent: React.FC<FlightDatesDropDownInputComponentProps> = observer((props) => {
    const services = useServices();

    const departureDate = services.time.formatUserFriendlyDate(props.searchController.departureDate)
    const returnDate = services.time.formatUserFriendlyDate(props.searchController.returnDate)
    if(props.searchController.isOneWay) {
        if(departureDate) {
            return (
                <DropDownInputBox>
                    {departureDate}
                </DropDownInputBox>
            );
        } else {
            return (
                <DropDownInputBox>
                    {services.language.translate('Select the departure date')}
                </DropDownInputBox>
            );
        }
    }


    if(!departureDate && !returnDate) {
        return (
            <DropDownInputBox>
                {services.language.translate('Select the flights dates')}
            </DropDownInputBox>
        );

    }

    return (
        <DropDownInputBox>
            <div>
                {departureDate || <QuestionMarkIcon/>}
            </div>
            <ArrowsBox>
                <ArrowsRightLeftIcon/>
            </ArrowsBox>
            <div>
                {returnDate || <QuestionMarkIcon/>}
            </div>
        </DropDownInputBox>
    )
});
