import {IServiceFactory} from "../../../../service-factory.interface";
import React from "react";
import {BagSsrLargeIcon} from "./bag.ssr.large-icon";
import {BagSsrSmallIcon} from "./bag.ssr.small-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../ssrs-preferred-orders-in-shopping-cart";
import {CheckInBagsSsrBase} from "../check-in-bags-ssr-base";
import { BagSsrLargeIconDisabled } from "./bag.ssr.large-icon-disabled";


export default class BagSsrType extends CheckInBagsSsrBase {
    constructor(services: IServiceFactory) {
        super('BAG', services);
    }

    get isAvailable(): boolean {
        return this.services.configuration.data.enable32KgBag;
    }

    get bagWeightKg(): number {
        return 32;
    }

    get smallIcon() {
        return (<BagSsrSmallIcon/>);
    }
    get largeIcon() {
        return <BagSsrLargeIcon/>
    }

    get largeIconDisabled() {
        return (<BagSsrLargeIconDisabled/>);
    }

    protected get _iconInExtrasSmallScreen(): React.ReactElement | null {
        return (<BagSsrLargeIcon size={2.5}/>);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.BAG
    }
}


