import {IServiceFactory} from "../../../../service-factory.interface";
import {SpecialAssistanceSsrBase} from "../special-assistance-ssr-base";

//Wheelchair required for transfer to/from AC - can use steps
export default class WchrSsrType extends SpecialAssistanceSsrBase {
    constructor(services: IServiceFactory) {
        super('WCHR', services);
    }

}
