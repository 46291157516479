import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {StandardDialogPageComponent} from "../../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../modal/footer/standard-dialog-footer.component";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {ValidateVoucherResultEnum} from "../../../services/dot-rez-api/data-contracts/enums/validate-voucher-result.enum";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../../basic-components/buttons/primary-button-inverted";
import {VerticalSpacer} from "../../../basic-components/spacing/vertical-spacer";

const BodyTextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    font-size: ${props => props.theme.fontSize.small};
    padding: 10px;
    height: 100%;
`

const ButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
`

interface VoucherCodeDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    headerTitle: string;
    bodyText: string;
    confirmButtonText: string;
    applyVoucherResult: ValidateVoucherResultEnum;
}

export const VoucherCodeConfirmationDialogComponent: React.FC<VoucherCodeDialogComponentProps> = observer((props) => {
    const services = useServices();


    const renderPayWithoutVoucherButton = () => {
        if(props.applyVoucherResult !== ValidateVoucherResultEnum.CoverEntireAmount) {
            return null;
        }
        return (
            <>
                <VerticalSpacer/>
                <PrimaryButtonInverted onClick={() => props.dialogHandler.reject()} >
                    {services.language.translate('Pay without using the voucher')}
                </PrimaryButtonInverted>
            </>
        )
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler} title={props.headerTitle}/>

            <StandardDialogContentComponent>
                <BodyTextBox>
                    { props.bodyText }
                </BodyTextBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ButtonsContainerBox>
                    <PrimaryButton onClick={() => props.dialogHandler.accept()}>
                        {props.confirmButtonText}
                    </PrimaryButton>
                    { renderPayWithoutVoucherButton() }
                </ButtonsContainerBox>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
