import React from 'react';
import {FormModel} from "../../../../../models/forms/form.model";
import {IAeroitaliaInvoiceFields} from "./aeroitalia.invoice-fields.interface";
import {IInvoiceModel} from "../invoice.model.interface";
import {IAeroitaliaInvoiceViewModel} from "./aeroitalia.invoice-view-model.interface";
import {BookingModel} from "../../booking.model";
import {FormFields} from "../../../../../models/forms/form-field.interface";
import {makeObservable, observable, runInAction} from "mobx";
import {ValidationResultEnum} from "../../../../../types/validation-result.enum";
import {DialogResult} from "../../../../dialog/dialog-enums";
import {ItalyCustomerTypeEnum} from "./italy-customer-type.enum";
import {AeroitaliaInvoiceDialogComponent} from "../../../../../pages/booking-flow/invoice/aeroitalia/aeroitalia.invoice-dialog.component";
import {NullableString} from "../../../../../types/nullable-types";
import {Check} from "../../../../../types/type-checking";
import {EMailValidator} from "../../../../../models/forms/field-validators/e-mail.validator";
import {IAeroitaliaInvoiceRequestData} from "./aeroitalia-invoice-request-data.interface";
import {IAeroitaliaPersistedInvoice} from "./aeroitalia-persisted-invoice.interface";
import {
    IBeginPaymentInvoiceRequest
} from "../../../../airline-webapi/requests/begin-payment-base.request";
import {AeroitaliaInvoiceFormComponent} from "../../../../../pages/booking-flow/invoice/aeroitalia/aeroitalia-invoice-form.component";
import {BookingSessionStorageKeys} from "../../storage/booking-storage.interface";


//const FALLBACK_COUNTRY_CODE = 'BC';
export class AeroitaliaInvoiceModel extends FormModel<IAeroitaliaInvoiceFields> implements IInvoiceModel, IAeroitaliaInvoiceViewModel{
    constructor(private readonly booking: BookingModel) {
        super(booking.services);
        this._invoiceRequested = "true" === this.booking.storage.getItem(BookingSessionStorageKeys.invoiceRequested);
        makeObservable(this, {
            _invoiceRequested: observable.ref
        });
    }

    _invoiceRequested: boolean = false;
    get invoiceRequested(): boolean {
        return this._invoiceRequested && this.isInvoiceRequestAllowed;
    }

    set invoiceRequested(value: boolean) {
        runInAction(() => {
            this._invoiceRequested = value;
            this.booking.storage.setItem(BookingSessionStorageKeys.invoiceRequested, value.toString());
        })
    }

    get isInvoiceRequestAllowed(): boolean {
        return this.booking.balanceDue.amount > 0;
    }

    protected _createFields(): FormFields<IAeroitaliaInvoiceFields> {
        const language = this.services.language;
        const persistedInvoice = this.booking.services.localStorage.getJson<IAeroitaliaPersistedInvoice>( 'booking.invoice');
        return {
            customerType: this._createField<ItalyCustomerTypeEnum>({
                fieldName: () => language.translate('Customer Type'),
                defaultValue: persistedInvoice?.customerType ?? ItalyCustomerTypeEnum.ItalianCompany,
                isRequired: true
            }),
            customerName: this._createField<string>({
                fieldName: () => {
                    if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianCompany) {
                        return language.translate('Company name');
                    } else {
                        return language.translate('Customer name');
                    }
                },
                defaultValue: persistedInvoice?.customerName,
                isHidden: () => this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianPhysicalPerson,
                isRequired: () => this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianPhysicalPerson,
                maxLength: () => {
                    return 64;
                },
                validate: field => this._validateCompanyName(field.value)
            }),
            firstName: this._createField<string>({
                fieldName: () => language.translate('First name'),
                defaultValue: persistedInvoice?.firstName,
                isRequired: () => this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianPhysicalPerson,
                isHidden: () => this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianPhysicalPerson,
                maxLength: 32,
                validate: field => this._validateFirstName(field.value)
            }),
            lastName: this._createField<string>({
                fieldName: () => language.translate('Last name'),
                defaultValue: persistedInvoice?.lastName,
                isRequired: () => this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianPhysicalPerson,
                isHidden: () => this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianPhysicalPerson,
                maxLength: 32,
                validate: field => this._validateLastName(field.value)
            }),
            fiscalCode: this._createField<string>({
                fieldName: () => language.translate('Fiscal Code'),
                defaultValue: persistedInvoice?.fiscalCode,
                isRequired: () => this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianCompany,
                maxLength: () => {
                    if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
                        return 16;
                    } else if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianCompany) {
                        if(this.fields.fiscalCode.value && this._isOnlyNumbers(this.fields.fiscalCode.value[0])) {
                            return 11;
                        } else {
                            return 16
                        }
                    }
                    return 16;
                },
                validate: field => this._validateFiscalCode(field.value)
            }),
            vat: this._createField<string>({
                fieldName: () => language.translate('VAT Number'),
                defaultValue: persistedInvoice?.vat,
                isRequired: false,
                isHidden: () => this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianCompany,
                maxLength: 11,
                validate: field => this._validateVAT(field.value)
            }),
            recipientCode: this._createField<string>({
                fieldName: () => language.translate('Recipient Code'),
                defaultValue: persistedInvoice?.recipientCode,
                isRequired: false,
                isHidden: () => this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianCompany,
                maxLength: 7,
                validate: field => this._validateRecipientCode(field.value)
            }),
            countryCode: this._createField<string>({
                fieldName: () => language.translate('Country'),
                defaultValue: persistedInvoice?.countryCode ?? this.services.configuration.defaultCountryCode,
                isRequired: true,
                isReadOnly: () => this.fields.customerType.value !== ItalyCustomerTypeEnum.NonItalianCustomer,
                maxLength: 2
            }),
            region: this._createField<string>({
                fieldName: () => language.translate('Region'),
                defaultValue: persistedInvoice?.region,
                isRequired: () => this.fields.customerType.value !== ItalyCustomerTypeEnum.NonItalianCustomer,
                isHidden: () => this.fields.customerType.value === ItalyCustomerTypeEnum.NonItalianCustomer,
                maxLength: 3
            }),
            emailPec: this._createField<string>({
                fieldName: () => 'Email PEC',
                defaultValue: persistedInvoice?.emailPec,
                isRequired: false,
                isHidden: () => this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianCompany,
                maxLength: 250,
                validate: (field) => this._validateEmailPec(field.value)
            }),
            city: this._createField<string>({
                fieldName: () => language.translate('Headquarter City'),
                defaultValue: persistedInvoice?.city,
                isRequired: true,
                maxLength: 32
            }),
            address: this._createField<string>({
                fieldName: () => language.translate('Headquarter Address'),
                defaultValue: persistedInvoice?.address,
                isRequired: true,
                maxLength: 30,
                validate: field => this._validateAddress(field.value)
            }),
            addressNumber: this._createField<string>({
                fieldName: () => language.translate('Address Number'),
                defaultValue: persistedInvoice?.addressNumber,
                isRequired: false,
                maxLength: 6,
                validate: field => this._validateAddressNumber(field.value)
            }),
            zipCode: this._createField<string>({
                fieldName: () => language.translate('Headquarter Zip Code'),
                defaultValue: persistedInvoice?.zipCode,
                isRequired: true,
                maxLength: () => {
                    if(this.fields.customerType.value === ItalyCustomerTypeEnum.NonItalianCustomer) {
                        return 10;
                    } else {
                        return 5;
                    }
                },
                validate: field => this._validateZipCode(field.value)
            })
        }
    }

    protected _onFieldsCreated(fields: FormFields<IAeroitaliaInvoiceFields>) {
        super._onFieldsCreated(fields);

        fields.customerType.onChange((newValue) => {
            this.resetErrorsValidation();
            if(newValue === ItalyCustomerTypeEnum.NonItalianCustomer) {
                fields.firstName.setValue(null);
                fields.lastName.setValue(null);
                fields.countryCode.setValue(null);
                fields.region.setValue(null);
                fields.city.setValue(null);
                fields.emailPec.setValue(null);
                fields.vat.setValue(null);
                fields.recipientCode.setValue(null);
            } else {
                fields.countryCode.setValue(this.services.configuration.defaultCountryCode);
                if(newValue === ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
                    fields.customerName.setValue(null);
                    fields.emailPec.setValue(null);
                    fields.recipientCode.setValue(null);
                } else {
                    fields.firstName.setValue(null);
                    fields.lastName.setValue(null);
                }
            }
        });

        fields.region.onChange(() => {
            fields.city.setValue(null);
        });
    }

    private _isValidStringLatinType(value: string): boolean {
        const pattern = /^[a-zA-Z0-9\s.&'-]+$/;
        return pattern.test(value);
    }

    private _isOnlyLettersAndNumbers(value: string): boolean {
        const pattern = /^[a-zA-Z0-9]+$/;
        return pattern.test(value);
    }

    private _isOnlyNumbers(value: string): boolean {
        const pattern = /^[0-9]+$/;
        return pattern.test(value);
    }

    private _validateCompanyName(value: NullableString): NullableString {
        if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
            return null;
        }

        if(value && !this._isValidStringLatinType(value)) {
            return this.services.language.translate('Not a valid company name');
        }

        return null;
    }

    private _validateFirstName(value: NullableString): NullableString {
        if(this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
            return null;
        }

        if(value && !this._isValidStringLatinType(value)) {
            return this.services.language.translate('Not a valid first name');
        }

        return null;
    }

    private _validateLastName(value: NullableString): NullableString {
        if(this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
            return null;
        }

        if(value && !this._isValidStringLatinType(value)) {
            return this.services.language.translate('Not a valid last name');
        }

        return null;
    }

    private _validateAddress(value: NullableString): NullableString {
        if(value && !this._isValidStringLatinType(value)) {
            return this.services.language.translate('Not a valid address');
        }

        return null;
    }

    private _validateAddressNumber(value: NullableString): NullableString {
        if(value && !this._isValidStringLatinType(value)) {
            return this.services.language.translate('Not a valid address number');
        }

        return null;
    }



    private _validateEmailPec(value: NullableString): NullableString {

        if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianCompany) {
            if(!this._recipientCodeFieldValue && !value) {
                return this._eitherFieldsRequiredErrorMessage(this.fields.emailPec.fieldName, this.fields.recipientCode.fieldName);
            }
        }

        if(!value) {
            return null;
        }

        return new EMailValidator(this.services).validate(value);
    }

    private _validateFiscalCode(value: NullableString): NullableString {
        const customerType = this.fields.customerType.value;
        if(Check.isNullOrUndefined(customerType)) {
            return null;
        }



        if(customerType === ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
            if(value?.length !== 16) {
                return this.services.language.translate('Must have 16 characters');
            }

            if(value) {
                if(!this._isOnlyLettersAndNumbers(value)) {
                    return this.services.language.translate('Not a valid fiscal code');
                }
            }
        } else if(customerType === ItalyCustomerTypeEnum.ItalianCompany) {
            if(!this._vatFieldValue && !value) {
                return this._eitherFieldsRequiredErrorMessage(this.fields.fiscalCode.fieldName, this.fields.vat.fieldName);
            }

            if(value) {
                if(Check.isNumber(parseInt(value[0]))) {
                    if(value.length !== 11 || !this._isOnlyNumbers(value)) {
                        return this.services.language.translate('Must have 11 digits');
                    }
                } else if(value.length !== 16) {
                    return this.services.language.translate('Must have 16 characters');
                }

                if(!this._isOnlyLettersAndNumbers(value)) {
                    return this.services.language.translate('Not a valid fiscal code');
                }
            }
        } else {
            if(value) {
                if(!this._isOnlyLettersAndNumbers(value)) {
                    return this.services.language.translate('Not a valid fiscal code');
                }

                if(value.length < 8 || value.length > 16) {
                    return this.services.language.translate('Must have between 8 and 16 characters');
                }
            }
        }

        return null;
    }

    private _eitherFieldsRequiredErrorMessage(field1Name: string, field2Name: string): string {
        return this.services.language.translationFor('{field1} or {field2} are required.').withParams({
            field1: field1Name,
            field2: field2Name
        });
    }

    private _validateVAT(value: NullableString): NullableString {
        const customerType = this.fields.customerType.value;
        if(Check.isNullOrUndefined(customerType)) {
            return null;
        }

        if(customerType === ItalyCustomerTypeEnum.ItalianCompany) {
            if(!this.fields.fiscalCode.value && !value) {
                return this._eitherFieldsRequiredErrorMessage(this.fields.vat.fieldName, this.fields.fiscalCode.fieldName);
            }

            if(value && (value.length !== 11 || !this._isOnlyNumbers(value))) {
                return this.services.language.translate('Must have 11 digits');
            }
        }

        return null;
    }

    private _validateRecipientCode(value: NullableString): NullableString {
        const customerType = this.fields.customerType.value;
        if(Check.isNullOrUndefined(customerType)) {
            return null;
        }

        if(customerType !== ItalyCustomerTypeEnum.ItalianCompany) {
           return null;
        }

        if(!this._emailPecFieldValue && !value) {
            return this._eitherFieldsRequiredErrorMessage(this.fields.recipientCode.fieldName, this.fields.emailPec.fieldName);
        }

        if(value) {
            if(value.length !== 7) {
                return this.services.language.translate('Must have 7 characters');
            }

            if(!this._isOnlyLettersAndNumbers(value)) {
                return this.services.language.translate('Not a valid recipient code');
            }
        }

        return null;
    }

    private _validateZipCode(value: NullableString): NullableString {
        const customerType = this.fields.customerType.value;
        if(Check.isNullOrUndefined(customerType)) {
            return null;
        }

        if(customerType !== ItalyCustomerTypeEnum.NonItalianCustomer) {
            if(value && (value.length !== 5 || !this._isOnlyNumbers(value))) {
                return this.services.language.translate('Must have 5 digits');
            }
        }

        return null;
    }

    async collectInvoiceFields(): Promise<DialogResult> {
        return await this.services.dialog.showStandardDialog({
            render: (dialogHandler) => <AeroitaliaInvoiceDialogComponent dialogHandler={dialogHandler} invoice={this}/>
        });
    }

    renderInvoiceForm(): React.ReactElement {
        return (
            <AeroitaliaInvoiceFormComponent invoice={this} title={this.services.language.translate("Invoice details")}/>
        );
    }

    private get _customerTypeShortIdentifier(): string {
        if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianCompany) {
            return 'PGIT';
        } else if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
            return 'PFIT';
        } else {
            return 'NONIT';
        }
    }

    private get _companyNameFieldValue(): string {
        if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
            return "";
        }
        return this.fields.customerName.value ?? "";
    }

    private get _firstNameFieldValue(): string {
        if(this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
            return "";
        }
        return this.fields.firstName.value ?? "";
    }

    private get _lastNameFieldValue(): string {
        if(this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
            return "";
        }
        return this.fields.lastName.value ?? "";
    }

    private get _emailPecFieldValue(): string {
        if(this.fields.customerType.value !== ItalyCustomerTypeEnum.ItalianCompany) {
            return "";
        }

        return this.fields.emailPec.value ?? "";
    }

    private get _recipientCodeFieldValue(): string {
        if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianCompany) {
            return this.fields.recipientCode.value ?? "";
        } else if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
            return '0000000';
        } else {
            return 'XXXXXXX';
        }

    }

    private get _vatFieldValue(): string {
        if(this.fields.customerType.value === ItalyCustomerTypeEnum.ItalianCompany) {
            return this.fields.vat.value ?? "";
        } else {
            return "";
        }
    }

    private get _countryCodeFieldValue(): string {
        if(this.fields.customerType.value === ItalyCustomerTypeEnum.NonItalianCustomer) {
            return this.fields.countryCode.value ?? this.services.configuration.defaultCountryCode;
        }

        return this.services.configuration.defaultCountryCode;
    }

    async validate(): Promise<ValidationResultEnum> {
        if(this.activateErrorsValidation().length > 0) {
            return ValidationResultEnum.Failure;
        }
        this._persistInvoice();
        return ValidationResultEnum.Success;
    }

    async onBeginPayment<TBeginRequest extends IBeginPaymentInvoiceRequest>(beginPaymentRequest: TBeginRequest): Promise<TBeginRequest> {
        if(!this.invoiceRequested) {
            return beginPaymentRequest;
        }

        beginPaymentRequest.invoiceJson = JSON.stringify(this._createInvoiceRequestData());

        return beginPaymentRequest;
    }



    private _createInvoiceRequestData(): IAeroitaliaInvoiceRequestData {
        return {
            customerType: this._customerTypeShortIdentifier,
            companyName: this._companyNameFieldValue,
            firstName: this._firstNameFieldValue,
            lastName: this._lastNameFieldValue,
            countryCode: this._countryCodeFieldValue,
            emailPec: this._emailPecFieldValue,
            fiscalCode: this.fields.fiscalCode.value ?? "",
            vat: this._vatFieldValue,
            headquarterAddress: this.fields.address.value ?? "",
            headquarterCity: this.fields.city.value ?? "",
            addressNumber: this.fields.addressNumber.value ?? "",
            headquarterZipCode: this.fields.zipCode.value ?? "",
            recipientCode: this._recipientCodeFieldValue,
            region: this.fields.region.value ?? ""
        };
    }


    private _persistInvoice(): void {
        const persistedInvoice: IAeroitaliaPersistedInvoice = {
            customerType: this.fields.customerType.value,
            customerName: this.fields.customerName.value,
            firstName: this.fields.firstName.value,
            lastName: this.fields.lastName.value,
            fiscalCode: this.fields.fiscalCode.value,
            vat: this.fields.vat.value,
            recipientCode: this.fields.recipientCode.value,
            countryCode: this.fields.countryCode.value,
            city: this.fields.city.value,
            region: this.fields.region.value,
            address: this.fields.address.value,
            addressNumber: this.fields.addressNumber.value,
            zipCode: this.fields.zipCode.value,
            emailPec: this.fields.emailPec.value
        };

        this.booking.services.localStorage.setJson('booking.invoice', persistedInvoice);
    }


}



