import React from "react";
import {IServiceFactory} from "../../service-factory.interface";
import {Check} from "../../../types/type-checking";
import {IRouteActivationOptions} from "../navigator.service.interface";
import {matchPath} from "react-router";
import {IRoute} from "./route.interface";
import {NullableString} from "../../../types/nullable-types";

export abstract class RouteBaseModel<TRouteParams extends Record<string, string> = {}> implements IRoute {
    constructor(private readonly _path: string,
                public readonly services: IServiceFactory,
                private readonly _parent?: RouteBaseModel | null,
                private readonly _activateOptions?: IRouteActivationOptions<TRouteParams>) {

    }

    abstract render(): React.ReactElement | null;

    get path(): string {
        if(this._parent) {
            return this._parent.path + this._path;
        } else {
            return this._path;
        }
    }

    get analyticsName(): NullableString | undefined {
        return  this.path.split("/").pop();
    }

    protected currentRouteParams: TRouteParams = ({} as any);

    activate(activateOptions?: IRouteActivationOptions<TRouteParams>): void {
        activateOptions = {
            ...this._activateOptions,
            ...activateOptions
        };

        if(activateOptions?.routeParams) {
            this.currentRouteParams = activateOptions.routeParams;
        } else {
            this.currentRouteParams = ({} as any);
        }

        let path = this.path;
        Object.keys(this.currentRouteParams).forEach(paramName => {
            path = path.replace(`/:${paramName}`, '/' + this.currentRouteParams[paramName].toString());
        });

        let allowBack = true;
        if(activateOptions && !Check.isNullOrUndefined(activateOptions.allowBack)) {
            allowBack = activateOptions.allowBack;
        }

        if(activateOptions?.useHistoryReplace) {
            this.services.navigator.replace(path, {
                allowBack: allowBack
            });
        } else {
            this.services.navigator.push(path, {
                allowBack: allowBack
            });
        }

    }

    get isActive(): boolean {
        return this.services.navigator.currentRoute.equals(this);
    }

    get isHome(): boolean {
        return false;
    }

    get hasActiveChildRoute(): boolean {
        return false;
    }

    equals(otherRoute: IRoute): boolean {
        if(Check.isNullOrUndefined(otherRoute)) {
            return false;
        }

        return this.path.toLowerCase() === otherRoute.path.toLowerCase();
    }

    matchLocation(pathName: string): IRoute | null  {
        const match = matchPath<TRouteParams>(pathName, {
            path: this.path,
            exact: true,
            strict: false
        });

        if(match) {
            this.currentRouteParams = match.params;
            return this;
        }

        return null;
    }
}
