import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const AirlineSmallLogoIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={3.6} {...props} sizeIsHeight viewBox="0 0 870.962 686.524" ratio={870.962/686.524}>
            <g>
                <polygon points="768.805 686.524 616.98 686.524 368.354 0 520.179 0 768.805 686.524" fill="#d92f32"/>
                <polygon points="151.825 686.524 0 686.524 368.354 0 520.179 0 151.825 686.524" fill="#338141"/>
                <path d="M327.341,361.476q-.75.358-1.5.723A2234.014,2234.014,0,0,0,110.149,481.234L0,686.524H151.825l9.727-18.13a475.58,475.58,0,0,1,86.862-115.5l.752-.733c63.563-61.968,122.015-118.959,219.81-183.556,93.484-61.749,172.454-97.122,224.68-120.237,110.832-49.058,178.1-65.1,177.3-67.761C869.4,175.449,612.323,224.37,327.341,361.476Z" fill="#4ea046"/>
            </g>
        </SvgIcon>
    )
}
