import React from "react";
import { IHomePageTabViewModel } from "../../../services/home-page/tabs/home-page.tab.interface";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useServices } from "../../../hooks/use-services.hook";
import { SearchMaskHybridComponent } from "../components/search-mask/hybrid/search-mask.hybrid.component";
import { HomePageTabBase } from "../../../services/home-page/tabs/home-page-tab-base";
import { PlaneOutlinedIcon } from "../../../assets/svg/plane-outlined.icon";
import { HomePageHeaderComponent } from "../components/home-page-header/home-page-header.component";
import { IJourneyHistoryViewModel } from "../../../services/booking-history/models/journey-history-view-model.interface";
import { NextFlightsComponent } from "../components/next-flights/next-flights.component";
import { PromotionalBannersFeedsComponent } from "../components/booking/promotional-banners-feeds.component";
import { MaintenanceAnnouncementComponent } from "../../../components/maintenance/maintenance-announcement.component";
import {VerticalSpacer} from "../../../basic-components/spacing/vertical-spacer";


const GreetingTitleBox = styled.div`
    padding-top: ${props => props.theme.spacing.extraLarge};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const GreetingSubtitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.xxxLarge};
    font-weight: ${props => props.theme.fontWeight.normal};
`

const BookTabGreetingsComponent = observer(() => {
    const services = useServices();

    return (
        <>
            <GreetingTitleBox>
                {services.user.profile.getGreetingMessage()}
            </GreetingTitleBox>
            <GreetingSubtitleBox>{services.language.translate('Fly with Us')}</GreetingSubtitleBox>
        </>
    )
});

export class BookTab extends HomePageTabBase implements IHomePageTabViewModel {

    get nextFlights(): IJourneyHistoryViewModel[] {
        return this.services.bookingHistory.getNextFlights();
    }

    get text(): string {
        return this.services.language.translate('Book');
    }

    renderIcon(): React.ReactElement {
        return (<PlaneOutlinedIcon/>);
    }

    renderMaintenance(): React.ReactElement {
        if (!this.services.maintenance.data.enableMaintenanceAnnouncement) {
            return <></>;
        }

        const startIsoDate = this.services.time.parseIsoDate(this.services.maintenance.data.startDateMaintenanceAnnouncement!)
        const endIsoDate = this.services.time.parseIsoDate(this.services.maintenance.data.endDateMaintenanceAnnouncement!)

        const startDate = this.services.time.formatUserFriendlyDate(startIsoDate);
        const startHour = this.services.time.formatHHmm(startIsoDate);
        const endHour = this.services.time.formatHHmm(endIsoDate);
        const weekDayAbbreviation = this.services.time.getDayFullName(startIsoDate.getDay());
        return (
            <MaintenanceAnnouncementComponent>
                {this.services.language.translationFor(`Our mobile app is undergoing scheduled maintenance on {weekDayAbbreviation} {startDate}, from {startHour} to {endHour}. During this period booking, check-in and manage my booking functions will be unavailable.`)
                    .withParams({
                        startDate: startDate,
                        weekDayAbbreviation: weekDayAbbreviation,
                        startHour: startHour,
                        endHour: endHour,
                    })
                }
            </MaintenanceAnnouncementComponent>
        )
    }


    renderContent(): React.ReactElement {
        return <>
            {this.renderMaintenance()}
            {
                this.nextFlights.length > 0 && this.services.configuration.envConfigs.enableManageMyBooking
                    ? <NextFlightsComponent />
                    : <SearchMaskHybridComponent />
            }
            <VerticalSpacer size={"extraLarge"}/>
            <PromotionalBannersFeedsComponent />
        </>;
    }

    renderHeader(): React.ReactElement | null {
        return (
            <HomePageHeaderComponent title={<BookTabGreetingsComponent />} />
        );
    }

}
