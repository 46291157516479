import {IThemeService, PreferredColorScheme} from "./theme.service.interface";
import {DefaultTheme} from "styled-components";
import {makeObservable, observable, runInAction} from "mobx";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {IAssetResolver} from "./asset-resolver.interface";
import {makeTheme} from "./helpers/make-theme";
import {Check} from "../../types/type-checking";

//https://ionicframework.com/docs/theming/dark-mode
export class ThemeService extends ServiceBase implements IThemeService, IAssetResolver {

    constructor(services: IServiceFactory) {
        super(services);
        this._userPreferredColorScheme = (this.services.localStorage.getItem('theme.preferredColorScheme') as PreferredColorScheme.System) ?? PreferredColorScheme.System;
        const colorSchemeDarkMatchMedia = window.matchMedia('(prefers-color-scheme: dark)');
        this._setCurrentSystemColorScheme(colorSchemeDarkMatchMedia.matches);
        this.currentTheme = makeTheme(this);

        if(colorSchemeDarkMatchMedia.addEventListener){
            colorSchemeDarkMatchMedia.addEventListener('change', this._systemColorSchemeChangeHandler);
        }
        else{
            colorSchemeDarkMatchMedia.addListener(this._systemColorSchemeChangeHandler); //for old ios (<= 12) versions
        }

        makeObservable(this, {
            _systemColorScheme: observable.ref,
            _userPreferredColorScheme: observable.ref
        })
    }

    _systemColorScheme: PreferredColorScheme = PreferredColorScheme.Light;
    _userPreferredColorScheme: PreferredColorScheme = PreferredColorScheme.System;

    currentTheme: DefaultTheme;

    get isInDarkMode(): boolean {
        return this.appColorScheme === PreferredColorScheme.Dark;
    }

    setColorScheme(colorScheme:PreferredColorScheme): void {
        if(colorScheme === this.appColorScheme) {
            return;
        }
        runInAction(() => {
            this._userPreferredColorScheme = colorScheme;
            this.services.localStorage.setItem('theme.preferredColorScheme', this._userPreferredColorScheme);
            this._updateAppColorScheme();
        });
    }

    private get appColorScheme(): PreferredColorScheme {
        if(Check.isNullOrUndefined(this._userPreferredColorScheme)
            || this._userPreferredColorScheme === PreferredColorScheme.System) {
            return this._systemColorScheme;
        }

        return this._userPreferredColorScheme;
    }

    private _systemColorSchemeChangeHandler = (event: MediaQueryListEvent) => {
        runInAction(() => {
            this._setCurrentSystemColorScheme(event.matches);
        });
    }

    private _setCurrentSystemColorScheme(useDarkTheme: boolean): void {
        runInAction(() => {
            if(useDarkTheme) {
                this._systemColorScheme = PreferredColorScheme.Dark;
            } else {
                this._systemColorScheme = PreferredColorScheme.Light;
            }

            this._updateAppColorScheme();
        });

    }


    private _updateAppColorScheme(): void {
        this.services.document.body.setAttribute('color-scheme', this.appColorScheme);
    }

    resolveAssetPath(relativePath: string, supportedLocalizations: string[]): string {
        const assetUrl = new URL(this.services.window.getOrigin());
        const currentLanguage = this.services.language.currentLanguage;
        if(0 <= supportedLocalizations.findIndex(l => l === currentLanguage)) {
            assetUrl.pathname = `/assets/localized/${currentLanguage}/${relativePath}`;
        } else {
            assetUrl.pathname = `/assets/defaults/${relativePath}`;
        }

        return assetUrl.href;
    }
}
