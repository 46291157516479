import React from 'react';
import {observer} from "mobx-react-lite";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {IFlightSearchControllerViewModel} from "../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {useServices} from "../../../hooks/use-services.hook";
import {StandardDialogFooterComponent} from "../../../components/modal/footer/standard-dialog-footer.component";
import {SpecialPriceMarketUserAgreementFooterComponent} from "./components/special-price-market-user-agreement-footer.component";
import {SpecialPriceUserAgreementPageContentComponent} from "./components/special-price-user-agreement-page-content.component";

interface SpecialPriceMarketUserAgreementDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    searchController: IFlightSearchControllerViewModel;
}

export const SpecialPriceMarketUserAgreementDialogComponent: React.FC<SpecialPriceMarketUserAgreementDialogComponentProps> = observer((props) => {
    const services = useServices();

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}
                                           title={services.language.translate('TERRITORIAL CONTINUITY REGIME')}
                                           hideCloseButton={true}/>
            <StandardDialogContentComponent>
                <SpecialPriceUserAgreementPageContentComponent searchController={props.searchController}/>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <SpecialPriceMarketUserAgreementFooterComponent searchController={props.searchController} dialogHandler={props.dialogHandler}/>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
})
