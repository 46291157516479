import React from 'react';
import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {IExtrasSectionRenderer} from "./extras-section-renderer.interface";
import {ExtrasSectionLargeScreenComponentCommonProps} from "../base-component/large-screen/extras-section.large-screen-component-common.props";
import {IServiceFactory} from "../../../../services/service-factory.interface";
import {v4 as uuidv4} from "uuid";
import {ISsrType} from "../../../../services/ssr-types/ssr-types.service.interface";


export abstract class ExtrasSectionRendererBase implements IExtrasSectionRenderer {
    constructor(protected readonly booking: IBookingViewModel) {
        this.id = uuidv4();
    }

    readonly id: string;
    abstract ssrTypes: ISsrType[];
    abstract shouldRender(): boolean;
    abstract renderForLargeScreen(props: ExtrasSectionLargeScreenComponentCommonProps): React.ReactElement;
    abstract renderForSmallScreen(): React.ReactElement;

    protected get services(): IServiceFactory {
        return this.booking.services;
    }

    get useWideCellForSmallScreen(): boolean {
        return false;
    }
}
