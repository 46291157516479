import {ButtonBase, ButtonColors, ConcreteButtonProps} from "./button-base";
import React from "react";
import {useServices} from "../../hooks/use-services.hook";
import {observer} from "mobx-react-lite";



interface PrimaryButtonInvertedProps extends ConcreteButtonProps {
}

export const PrimaryButtonInverted: React.FC<PrimaryButtonInvertedProps> = observer((props) => {
    const services = useServices();
    const themeColors = services.theme.currentTheme.colors;

    const buttonColors: ButtonColors = {
        background: themeColors.primaryContrast,
        color: themeColors.primary,
        border: themeColors.primary,
        backgroundActivated: themeColors.primary,
        colorActivated: themeColors.primaryContrast,
        borderActivated: themeColors.primary
    }

    return (
        <ButtonBase {...props} colors={buttonColors}>
            {props.children}
        </ButtonBase>
    );
});
