import React from "react";
import {observer} from "mobx-react";
import {ISsrTypeCount} from "../../../../services/booking/models/ssrs/ssr-type-count.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {checkmark, checkmarkOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {ErrorMessageTextBoxComponent} from "../../../../components/error-message/error-message-text-box.component";
import {IPassengerAllBagsViewModel} from "../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";

const PassengerBagsSummaryBox = styled.div`
    width: 100%;
`

const SelectedBagsListBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`

const SelectedBagBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.medium};
    line-height: 1;
    padding: 0 12px;

    &:first-of-type {
        padding-top: 12px;
    }

    &:last-of-type {
        padding-bottom: 12px;
    }
`

const BagNameAndQuantityBox = styled.span`
    flex-grow: 1;
    margin-left: ${props => props.theme.spacing.small};
    color: ${props => props.theme.colors.primary};
`

const SelectedBagComponent: React.FC<{bagCount: ISsrTypeCount}> = observer((props) => {
  return (
      <SelectedBagBox>
          <IonIcon ios={checkmarkOutline} md={checkmark}/>
          <BagNameAndQuantityBox>
              {`${props.bagCount.count} x ${props.bagCount.ssrType.name}`}
            </BagNameAndQuantityBox>
        </SelectedBagBox>
    )
});


interface PassengerBagsSummaryComponentProps {
    passengerBags: IPassengerAllBagsViewModel;
}
export const PassengerBagsSummaryComponent: React.FC<PassengerBagsSummaryComponentProps> = observer((props) => {
    const services = useServices();
    const cabinBag = props.passengerBags.cabinBag;
    const checkinBags = props.passengerBags.checkInBags;

    const cabinBagSsrCount: ISsrTypeCount[] = [];
    if(cabinBag.newQuantityWasSet) {
        if(cabinBag.newQuantity === 0) {
            cabinBagSsrCount.push({
                ssrType: services.ssrTypes.SCBG,
                count: 1
            })
        } else {
            cabinBagSsrCount.push({
                ssrType: cabinBag.ssrType,
                count: cabinBag.newQuantityToDisplay
            });
        }
    }

    const checkInBagsSsrCount = checkinBags.filter(ssrEditor => ssrEditor.newQuantity > 0)
        .map(ssrEditor => {
            return {
                ssrType: ssrEditor.ssrType,
                count: ssrEditor.newQuantityToDisplay
            };
        });

    const allBagsCount = [
        ...cabinBagSsrCount,
        ...checkInBagsSsrCount
    ];

    const captureElementRef = (el: any) => {
        props.passengerBags.attachCabinBagsContainerDOMElement(el);
        props.passengerBags.attachCheckInBagsContainerDOMElement(el);
    }

    return (
        <PassengerBagsSummaryBox ref={captureElementRef}>
            <SelectedBagsListBox className='tst-selected-bags-summary'>
                {allBagsCount.map(bag => <SelectedBagComponent key={bag.ssrType.ssrCode} bagCount={bag}/>)}
            </SelectedBagsListBox>
            <ErrorMessageTextBoxComponent errorMessage={props.passengerBags.cabinBagErrorMessage}/>
            <ErrorMessageTextBoxComponent errorMessage={props.passengerBags.checkInBagsErrorMessage}/>
        </PassengerBagsSummaryBox>

    );
});
