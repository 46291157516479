import React, {useState} from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {PassengerDetailsEditorComponent} from "./components/passenger-details.component";
import {BookingContactComponent} from "./components/booking-contact.component";
import {FormComponent, FormComponentType} from "../../../components/forms/form.component";
import {IPassengerViewModel} from "../../../services/booking/models/passenger/passenger-view-model.interface";
import {BookingFlowPage} from "../booking-flow.page";

const PassengersDetailsPageContent: React.FC = observer(() => {
    const services = useServices();
    const [currentEditedPassenger, setCurrentEditedPassenger] = useState<IPassengerViewModel | null>(null);
    const passengers = services.booking.current.passengers.getAllPersonsInTheBooking();
    const onEnterPassengerDetailsHandler = (passenger: IPassengerViewModel) => {
        if(currentEditedPassenger && currentEditedPassenger.passengerKey !== passenger.passengerKey) {
            currentEditedPassenger.companion.showAvailableCompanions = false;
        }
        passenger.companion.showAvailableCompanions = true;
        setCurrentEditedPassenger(passenger);
    }

    const onSubmit = async () => {
        await services.booking.steps.nextStep();
    }

    return (
        <FormComponent type={FormComponentType.Flat} onSubmit={onSubmit}>
            {
                passengers.map((p) => {
                    return (
                        <PassengerDetailsEditorComponent key={p.passengerKey}
                                                         passenger={p}
                                                         onEnterPassengerDetails={onEnterPassengerDetailsHandler}/>
                    );
                })
            }
            <BookingContactComponent/>
        </FormComponent>
    );
});

export const BookingPassengersDetailsPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowPage title={services.language.translate('Passengers details')}
                               renderContent={() => <PassengersDetailsPageContent/>}
                               showPromotionCode={true}>
        </BookingFlowPage>
    );
});
