import React from "react";
import styled from "styled-components";
import {useServices} from "../../../hooks/use-services.hook";


const AppleWalletButtonBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

interface AddToAppleWalletButtonComponentProps {
    onClick: () => Promise<void>;
    className?: string;
}

export const AddToAppleWalletButtonComponent: React.FC<AddToAppleWalletButtonComponentProps> = (props) => {
    const services = useServices();
    return (
        <AppleWalletButtonBox onClick={props.onClick} className={props.className}>
            <img src={services.theme.currentTheme.assets.images.add_to_apple_wallet_png.toString()}
                 alt={"Add to Apple Wallet"} width={356} height={61}/>
        </AppleWalletButtonBox>
    )
}