import {IBookingBlueBenefitsViewModel} from "./booking-blue-benefits-view-model.interface";
import {BookingModel} from "../booking.model";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {IBlueBenefitsSubscription} from "../../../blue-benefits/models/subscriptions/blue-benefits-subscription.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {computed, makeObservable, observable} from "mobx";
import {Price} from "../../../currency/price";
import {NullableString} from "../../../../types/nullable-types";
import {BlueBenefitsPackageToPurchaseModel} from "./blue-benefits-package-to-purchase.model";
import {IBlueBenefitsPackage} from "../../../blue-benefits/models/packs/blue-benefits-package.interface";

export class BookingBlueBenefitsModel implements IBookingBlueBenefitsViewModel {
    constructor(private readonly booking: BookingModel) {
        makeObservable(this, {
            inProgressPurchasePackage: observable.ref,
            currentPurchasedPackage: computed,
            subscription: computed,
            availablePackagesToPurchase: computed
        });
    }

    get services(): IServiceFactory {
        return this.booking.services;
    }

    get currentPurchasedPackage(): IBlueBenefitsPackage | null {
        const packagesGroupedBySsrCode = this.services.blueBenefits.availableBlueBenefitsPackages.toDictionary(p => p.ssrType.ssrCode);
        for(let passengerSegment of this.booking.getAllPassengersSegments()) {
            for(let soldSsr of passengerSegment.getSoldSsrsEditors()) {
                if(soldSsr.newQuantity > 0) {
                    const purchasedPackage = packagesGroupedBySsrCode[soldSsr.ssrType.ssrCode];
                    if(purchasedPackage) {
                        return purchasedPackage;
                    }
                }
            }
        }

        return null;
    }

    inProgressPurchasePackage: IBlueBenefitsPackage | null = null;
    async purchase(bbPackage: IBlueBenefitsPackage): Promise<void> {
        this.inProgressPurchasePackage = bbPackage;
        try {
            const firstPassengerSegment = this.booking.getAllPassengersSegments()[0];
            if(this.currentPurchasedPackage) {
                if(this.currentPurchasedPackage.ssrType.ssrCode === bbPackage.ssrType.ssrCode) {
                    return;
                }

                firstPassengerSegment.getSsr(this.currentPurchasedPackage.ssrType).newQuantity = 0;
            }

            firstPassengerSegment.getSsr(bbPackage.ssrType).newQuantity = 1;

            await this.booking.sellSsrs();
        } finally {
            this.inProgressPurchasePackage = null;
        }
    }

    createBookingCommentText(): NullableString {
        if(!this.isValidSubscription || this.isAcquisition) {
            return null;
        }

        if(this._getSubscriptionFromBookingComments()) {
            // it means the comment is already in the booking
            return null;
        }

        return this.subscription.createBookingCommentText();
    }

    get isAcquisition(): boolean {
        return Boolean(this.currentPurchasedPackage);
    }

    get isActivatedAcquisition(): boolean {
        return this.booking.bookingData.comments.some(c => c.text.indexOf("BlueBenefitsActivatedFor") >= 0);
    }

    private _getSubscriptionFromBookingComments(): IBlueBenefitsSubscription | null {
        return this.services.blueBenefits.createSubscriptionFromBookingComments(this.booking.bookingData.comments);
    }

    get isBookingWithBlueBenefits(): boolean {
        return this.booking.passengers.some(p => p.passengerType.isBlueBenefits);
    }

    getActivationUrl(): NullableString {
        if(this.isAcquisition
            && !this.isActivatedAcquisition
            && this.booking.bookingData.locators?.numericRecordLocator
            && this.booking.balanceDue.amount === 0) {
            return this.services.blueBenefits.getActivationUrl(this.booking.bookingData.locators.numericRecordLocator)
        }

        return null;
    }

    private _createSubscriptionExpirationDateFromBookedDate(): Date {
        const time = this.services.time;
        return time.makeShortDate(time.addYears(this.booking.bookingCreationDate, 1));
    }

    get subscription(): IBlueBenefitsSubscription {
        if(!this.isBookingWithBlueBenefits) {
            return this.services.blueBenefits.noSubscription;
        }

        let subscription = this._getSubscriptionFromBookingComments();
        if(subscription) {
            return subscription;
        }

        if(this.currentPurchasedPackage) {
            return this.services.blueBenefits.createSubscriptionFromPackage(this.currentPurchasedPackage, this._createSubscriptionExpirationDateFromBookedDate());
        }

        if(this.services.user.profile.blueBenefitsSubscription.isValidSubscription) {
            return this.services.user.profile.blueBenefitsSubscription;
        }

        return this.services.blueBenefits.createSubscriptionFromPassengersCount(this.booking.passengers.countAllPassengers(), this._createSubscriptionExpirationDateFromBookedDate());
    }

    get availablePackagesToPurchase(): BlueBenefitsPackageToPurchaseModel[] {
        if(!this.isBookingWithBlueBenefits) {
            return [];
        }

        if(this.services.user.profile.blueBenefitsSubscription.isValidSubscription) {
            return []
        }

        return this.services.blueBenefits.availableBlueBenefitsPackages
                    .filter(p => p.maxNumberOfPassengers >= this.booking.passengers.countAllPassengers())
                    .map(p => new BlueBenefitsPackageToPurchaseModel(p, this));


    }

    get isValidSubscription(): boolean {
        return this.subscription.isValidSubscription;
    }

    get shouldLockFirstPassengerOnBooking(): boolean {
        return this.subscription.shouldLockFirstPassengerOnBooking();
    }

    computeSsrStandardPrice(ssrType: ISsrType, discountedPrice: Price): Price {
        return this.subscription.computeSsrStandardPrice(ssrType, discountedPrice);
    }

}
