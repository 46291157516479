import React from "react";
import {observer} from "mobx-react";
import {Station} from "../../../../../../../services/stations/station.service.interface";
import {StationDropDownItemModel} from "./station-drop-down-item.model";
import {IFormField} from "../../../../../../../models/forms/form-field.interface";
import {StandardDropDownComponent} from "../../../../../../../components/forms/standard-drop-down/standard-drop-down.component";
import {FieldEditorCommonProps} from "../../../../../../../components/forms/field-editor-common.props";



interface StationDropDownComponentProps extends FieldEditorCommonProps {
    field: IFormField<Station>;
    customLabel: string;
    icon: React.ReactElement;
    placeholder: string;
    getStations: () => Station[];
    noStationsMessage?: () => React.ReactElement | string;
}



export const StationDropDownComponent: React.FC<StationDropDownComponentProps> = observer((props) => {
    const items = props.getStations().map(s => new StationDropDownItemModel(s));

    return (
        <StandardDropDownComponent field={props.field}
                                   customLabel={props.customLabel}
                                   hideLabel={props.hideLabel}
                                   showBorders={props.showBorders}
                                   onClick={props.onClick}
                                   placeholder={props.placeholder}
                                   icon={props.icon}
                                   items={items}
                                   noItemsMessage={props.noStationsMessage}
                           />
    );
});
