import React from "react";
import {observer} from "mobx-react";
import {RoutedPageComponent} from "../../../basic-components/page/routed-page/routed-page.component";
import {StandardPageHeaderComponent} from "../../../basic-components/page/standard-page-header/standard-page-header.component";
import {useServices} from "../../../hooks/use-services.hook";
import {StandardPageContentComponent} from "../../../basic-components/page/standard-page-content/standard-page-content.component";
import {SpinnerComponent} from "../../../components/spinner/spinner.component";
import { UserTravelDocumentListComponent } from "./components/user-travel-document-list.component";
import { StandardPageFooterComponent } from "../../../basic-components/page/standard-page-footer/standard-page-footer.component";

import {PrimaryButton} from "../../../basic-components/buttons/primary-button";

export const UserTravelDocumentsPage: React.FC = observer(() => {
    const services = useServices();
    if(!services.user.profile.isProfileInitialized) {
        return (<SpinnerComponent/>);
    }

    const onAddTravelDocument = async () => {
        await services.user.profile.addTravelDocument();
    }

    return (
        <RoutedPageComponent>
            <StandardPageHeaderComponent title={services.language.translate('My Documents')} hideLogoButton={true}/>
            <StandardPageContentComponent>
                <UserTravelDocumentListComponent/>
            </StandardPageContentComponent>
            <StandardPageFooterComponent>
                <PrimaryButton onClick={onAddTravelDocument}>
                    {services.language.translate('Add travel document')}
                </PrimaryButton>
            </StandardPageFooterComponent>
        </RoutedPageComponent>
    )
});
