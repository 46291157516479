import React from "react";
import {observer} from "mobx-react";
import {PayPalButtons} from "@paypal/react-paypal-js";
import {OnApproveData, PayPalButtonStyle} from "@paypal/paypal-js/types/components/buttons";
import {useServices} from "../../../../hooks/use-services.hook";
import {
    PaymentMethodCodesEnum
} from "../../../../services/booking/models/payment/payment-methods/payment-method-codes.enum";
import styled from "styled-components";

const ButtonContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

export const PaypalButtonComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    if(!services.configuration.data.payments.payPal?.clientId) {
        return null;
    }
    const createOrderHandler = () => {
        return booking.paymentHandler.createPspOrder(PaymentMethodCodesEnum.PayPal);
    }

    const onApproveHandler = (data: OnApproveData) => {
        return booking.paymentHandler.completePspOrder(data.orderID);
    }

    const onCancelHandler = () => {
        //we don't wait for the Promise because we don't want the PayPal window to hang around until the promise is resolved
        booking.paymentHandler.onPspOrderCanceled();
    }


    const style: PayPalButtonStyle = {
        shape: 'pill',
        layout: "vertical",
        label: 'paypal',
        color: 'white',
        disableMaxWidth: true
    }

    return (
        <ButtonContainerBox>
            <PayPalButtons style={style}
                           createOrder={createOrderHandler}
                           onApprove={onApproveHandler}
                           onCancel={onCancelHandler}
                           fundingSource={"paypal"}/>
        </ButtonContainerBox>

    );
})