import styled from "styled-components";
import {IonSegmentButton} from "@ionic/react";

export const SegmentButtonBaseBox = styled(IonSegmentButton)`
    --margin-bottom: ${props => props.theme.spacing.medium};
    --margin-top: ${props => props.theme.spacing.medium};
    margin-top: 0;
    margin-bottom: 0;
    --border-radius: ${props => props.theme.border.defaultRadius};
`
