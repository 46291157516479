import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IExtrasSectionRenderer} from "./base-renderers/extras-section-renderer.interface";
import {IExtrasSectionsRenderersFactory} from "./base-renderers/extras-sections-renderers-factory.interface";

const GridBox = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${props => props.theme.spacing.cardDefaultPaddingPx};
    grid-auto-rows: 1fr;
`

const RegularGridCellBox = styled.div`
    
`

const WideGridCellBox = styled.div`
    grid-column: 1/-1;
`



export const ExtrasSelectionPageSmallScreenContent: React.FC<{sectionsFactory: IExtrasSectionsRenderersFactory}> = observer((props) => {

    const sectionsFactory = props.sectionsFactory;

    const sections = sectionsFactory.getExtrasSections().filter(s => s.shouldRender());
    const numberOfWideCells = sections.filter(s => s.useWideCellForSmallScreen).length;
    const numberOfSingleCellsIsOdd = (sections.length - numberOfWideCells) % 2 !== 0;

    const renderCell = (section: IExtrasSectionRenderer, index: number) => {

        if(section.useWideCellForSmallScreen || (numberOfSingleCellsIsOdd && index === sections.length - 1)) {
            return (<WideGridCellBox key={section.id}>
                {section.renderForSmallScreen()}
            </WideGridCellBox>);
        }
        return (
            <RegularGridCellBox key={section.id}>
                {section.renderForSmallScreen()}
            </RegularGridCellBox>
        )
    }

    return (
        <GridBox>
            {sections.map(renderCell)}
        </GridBox>
    )
})
