import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const DocumentRowsCircleFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 24 24">
            <g fill={props.color? props.color: "currentColor"}>
                <circle id="Ellipse_63" data-name="Ellipse 63" cx="12" cy="12" r="12" />
                <g id="Group_1447" data-name="Group 1447" transform="translate(-32 -1021)">
                    <circle id="Ellipse_70" data-name="Ellipse 70" cx="1.5" cy="1.5" r="1.5" transform="translate(39 1027)" fill="#fff"/>
                    <circle id="Ellipse_71" data-name="Ellipse 71" cx="1.5" cy="1.5" r="1.5" transform="translate(39 1031)" fill="#fff"/>
                    <circle id="Ellipse_72" data-name="Ellipse 72" cx="1.5" cy="1.5" r="1.5" transform="translate(39 1035)" fill="#fff"/>
                    <line id="Line_81" data-name="Line 81" x2="4.236" transform="translate(44.5 1028.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                    <line id="Line_82" data-name="Line 82" x2="4.236" transform="translate(44.5 1032.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                    <line id="Line_83" data-name="Line 83" x2="4.236" transform="translate(44.5 1036.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                </g>
            </g>
        </SvgIcon>
    )
}
