import {BookingMutationActionBase} from "../../booking-mutation-action-base";
import {IFareToSell} from "../../../booking-view-model.interface";
import {IDotRezSellTripRequest} from "../../../../../dot-rez-api/data-contracts/requests/booking/sell-trip.request";
import {BookingModel} from "../../../booking.model";
import {WaitForMutationBehaviorEnum} from "../../booking-mutation-waiter.interface";
import {IDotRezPartialBookingSessionData} from "../../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {JourneyModel} from "../../../journey/journey.model";

/*
errors: [
{
"code": "nsk-server:ClassNotAvailable",
"message": "The requested class of service is sold out.",
"type": "Error",
"number": null,
"details": null
}
]
 */

export abstract class SellJourneyMutation extends BookingMutationActionBase {

    constructor(protected readonly booking: BookingModel,
                protected fareToSell: IFareToSell,
                private _onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>) {
        super(booking, {
            waitForMutationBehavior: WaitForMutationBehaviorEnum.ThrowOnError
        });
    }


    protected get departureJourney(): JourneyModel | null {
        return this.booking.departureJourney;
    }

    protected get returnJourney(): JourneyModel | null {
        return this.booking.returnJourney;
    }

    private _createSellTripRequest(): IDotRezSellTripRequest {

        return {
            preventOverlap: true,
            keys: [{
                journeyKey: this.fareToSell.journeyKey,
                fareAvailabilityKey: this.fareToSell.fareAvailabilityKey
            }],
            currencyCode: this.booking.currency,
            passengers: {
                types:  this.fareToSell.passengerTypes
            },
            infantCount: this.fareToSell.infantCount
        }
    }

    protected async _sellTrip(journeysToCancel: string[], ssrsToRemove: string[]): Promise<void> {
        await this.session.sellTrip({
            journeysToCancel: journeysToCancel,
            ssrsToRemove: ssrsToRemove,
            sellTripRequest: this._createSellTripRequest()
        });

        const bookingSessionData = await this.session.bookingStateQueryBuilder().useBookingData().useBundlesAvailability().getBookingState();

        await this.onAfterSell(bookingSessionData);
    }

    protected async onAfterSell(bookingSessionData: IDotRezPartialBookingSessionData): Promise<void> {
        await this._onAfterSell(bookingSessionData);

        if(this.services.configuration.data.vouchersRestrictedByPromotions && this.booking.hasPromotionApplied) {
            await this.booking.voucher.remove();
        }

        this.booking.seatsMapsEditors.moveToNextPassenger();
    }

}
