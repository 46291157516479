import {DotRezGraphQLMutationBuilder} from "../dot-rez-query-builder";

export interface IDotRezSsrsRemoveMutationResponseData {
    [key: string]: {
        ssrsDelete: boolean;
    }
}

export function removeSsrsGraphQLMutations(ssrKeysToRemove: string[], queryBuilder: DotRezGraphQLMutationBuilder): DotRezGraphQLMutationBuilder {
    for(let i = 0; i < ssrKeysToRemove.length; i++) {
        const variableName = `ssrKeyToDelete${i}`;

        queryBuilder.addMutation(`
                mutation deleteSsr${i}($${variableName}: String) {
                    ssrsDelete(ssrKey: $${variableName})
                }
            `, {
            name: variableName,
            value: ssrKeysToRemove[i]
        });
    }

    return queryBuilder;
}

