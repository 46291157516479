import {FormModel} from "../../models/forms/form.model";
import {EMailValidator} from "../../models/forms/field-validators/e-mail.validator";
import {FormFields, IFormField} from "../../models/forms/form-field.interface";
import {NullableString} from "../../types/nullable-types";
import {BookingStatusDeeplinkQueryParamsEnum} from "../deep-links/booking-status-deeplink-query-params.enum";

export enum BookingSearchStrategyEnum {
    byName,
    byEmail
}

export interface ISearchBookingParamsEditor {
    startCheckIn(): Promise<void>;
    startManageMyBooking(): Promise<void>;
    retrieveBookingToHistory(): Promise<{success: boolean}>;
}

export interface IBookingSearchEditorFields {
    strategy: BookingSearchStrategyEnum;
    recordLocator: string;
    lastName: string;
    emailAddress: string;
}

export class SearchBookingParamsEditor extends FormModel<IBookingSearchEditorFields> implements ISearchBookingParamsEditor {

    protected _createFields(): FormFields<IBookingSearchEditorFields> {
        const language = this.services.language;

        const queryParams = this.services.navigator.getQueryParamsValues(BookingStatusDeeplinkQueryParamsEnum.RecordLocator,
                                                                                            BookingStatusDeeplinkQueryParamsEnum.Email);

        const recordLocatorFromQueryParams = queryParams[BookingStatusDeeplinkQueryParamsEnum.RecordLocator] ?? null;
        const emailFromQueryParams = queryParams[BookingStatusDeeplinkQueryParamsEnum.Email] ?? null;

        return {
            strategy: this._createField<BookingSearchStrategyEnum>({
                fieldName: () => language.translate('Find booking by'),
                defaultValue: Boolean(emailFromQueryParams) ? BookingSearchStrategyEnum.byEmail : BookingSearchStrategyEnum.byName
            }),
            lastName: this._createField({
                fieldName: () => language.translate('Last name'),
                isHidden: () => this.fields.strategy.value !== BookingSearchStrategyEnum.byName,
                isRequired: () => this.fields.strategy.value === BookingSearchStrategyEnum.byName,
                autoCapitalize: true
            }),
            emailAddress: this._createField({
                fieldName: () => language.translate('E-mail'),
                validate: field => this._validateEmailAddress(field),
                isHidden: () => this.fields.strategy.value !== BookingSearchStrategyEnum.byEmail,
                isRequired: () => this.fields.strategy.value === BookingSearchStrategyEnum.byEmail,
                defaultValue: emailFromQueryParams
            }),
            recordLocator: this._createField<string>({
                fieldName: () => language.translate('Booking code'),
                isRequired: true,
                defaultValue: recordLocatorFromQueryParams,
                autoCapitalize: true
            })
        }
    }

    private _validateEmailAddress(field: IFormField<string>): NullableString {

        if(this.fields.strategy.value !== BookingSearchStrategyEnum.byEmail) {
            return null;
        }

        return new EMailValidator(this.services).validate(field.value)

    }

    private _validate(): void {
        if(this.hasErrors()) {
            this.activateErrorsValidation();
        } else {
            this.commitChanges();
        }
    }

    private async _executeCheckIn(): Promise<void> {
        if(this.fields.strategy.value === BookingSearchStrategyEnum.byName) {
            await this.services.booking.startCheckInByLastName({
                lastName: this.fields.lastName.value!.trim(),
                recordLocator: this.fields.recordLocator.value!.trim()
            });
        } else {
            await this.services.booking.startCheckInByEmail({
                emailAddress: this.fields.emailAddress.value!.trim(),
                recordLocator: this.fields.recordLocator.value!.trim()
            });
        }
    }

    async startCheckIn(): Promise<void> {
        this._validate();
        if (this.hasErrors()) {
            return;
        }

        await this._executeCheckIn();
    }

    private async _executeStartManageMyBooking(): Promise<void> {
        if(this.fields.strategy.value === BookingSearchStrategyEnum.byName) {
            await this.services.booking.startManageMyBookingByLastName({
                lastName: this.fields.lastName.value!.trim(),
                recordLocator: this.fields.recordLocator.value!.trim()
            });
        } else {
            await this.services.booking.startManageMyBookingByEmail({
                emailAddress: this.fields.emailAddress.value!.trim(),
                recordLocator: this.fields.recordLocator.value!.trim()
            });
        }
    }

    async startManageMyBooking(): Promise<void> {
        this._validate();
        if (this.hasErrors()) {
            return;
        }

        await this._executeStartManageMyBooking();
    }

    protected async _executeRetrieveBookingToHistory(): Promise<void> {
        if(this.fields.strategy.value === BookingSearchStrategyEnum.byName) {
            await this.services.bookingHistory.retrieveBookingToHistoryByLastName({
                lastName: this.fields.lastName.value!,
                recordLocator: this.fields.recordLocator.value!
            });
        } else {
            await this.services.bookingHistory.retrieveBookingToHistoryByEmail({
                emailAddress: this.fields.emailAddress.value!,
                recordLocator: this.fields.recordLocator.value!
            });
        }
    }

    async retrieveBookingToHistory(): Promise<{success: boolean}> {
        this._validate();
        if(!this.hasErrors()) {
            return await this.services.loadingIndicator.execute({
                action: async () => {
                    await this._executeRetrieveBookingToHistory();
                    return {
                        success: true
                    }
                },
                displayExceptionHandler: () => {
                    this.services.alert.showError("We couldn't find any booking for the provided criteria");
                }
            });
        }

        return {
            success: false
        };
    }
}
