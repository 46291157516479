import React, {CSSProperties, PropsWithChildren} from "react";
import {useServices} from "../../../../hooks/use-services.hook";

interface BoardingPassFieldValueComponentProps extends PropsWithChildren {
    styles?: CSSProperties;
}
export const BoardingPassFieldValueComponent: React.FC<BoardingPassFieldValueComponentProps> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        display: "flex",
        fontSize: theme.fontSize.small,
        color: theme.colors.primary,
        fontWeight: 'bold',
        paddingTop: theme.spacing.small,
        ...props.styles
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}
