import {ValidateVoucherResultEnum} from "../../../../dot-rez-api/data-contracts/enums/validate-voucher-result.enum";
import {BookingModel} from "../../booking.model";
import {FormModel} from "../../../../../models/forms/form.model";
import {NullableString} from "../../../../../types/nullable-types";
import {FormFields, IFormField} from "../../../../../models/forms/form-field.interface";
import {ApplyPromotionalResultEnum} from "../apply-promotional-result.enum";
import {IPromotionalFields} from "../promotional-fields.interface";
import {BookingSessionStorageKeys} from "../../storage/booking-storage.interface";
import {makeObservable, observable, runInAction} from "mobx";
import {IDotRezValidateVoucherResponse} from "../../../../dot-rez-api/data-contracts/responses/booking/validate-voucher.response";
import {IVoucherViewModel} from "./voucher-view-model.interface";
import {ValidationResultEnum} from "../../../../../types/validation-result.enum";
import {IVoucherPaymentRequest} from "../../../../airline-webapi/requests/voucher-payment.request";

export class VoucherModel extends FormModel<IPromotionalFields> implements IVoucherViewModel {
    constructor(private readonly booking: BookingModel) {
        super(booking.services);

        this.amount = parseInt(this.booking.storage.getItem(BookingSessionStorageKeys.voucherAmount) || 0);

        makeObservable(this, {
            amount: observable.ref
        });

        this.fields.code.onChange(() => {
            if(this.code.value) {
                this.booking.storage.setItem(BookingSessionStorageKeys.voucherCode, this.code.value.toUpperCase().trim());
            } else {
                this.booking.storage.removeItem(BookingSessionStorageKeys.voucherCode);
            }
        })
    }

    amount: number = 0;

    private _setAmount(amount: number): void {
        runInAction(() => {
            this.amount = amount;
        });

        this.booking.storage.setItem(BookingSessionStorageKeys.voucherAmount, amount.toString());
    }

    protected _createFields(): FormFields<IPromotionalFields> {
        return {
            code: this._createField<NullableString>({
                fieldName: () => this.services.language.translate('Voucher code'),
                initialValue: () => this.booking.storage.getItem(BookingSessionStorageKeys.voucherCode) || null,
                autoCapitalize: true,
                maxLength: 34
            })
        };
    }

    get isApplied(): boolean {
        if(!this.code.value) {
            return false;
        }

        return this.amount > 0;
    }

    getAppliedVoucherForBeginPayment(): IVoucherPaymentRequest[] {
        if(!this.isApplied) {
            return [];
        }

        return [
            {
                code: this.code.value!,
                amount: this.amount,
            }
        ]
    }

    private get _isPromoCodeAppliedOnBooking(): boolean {
        return Boolean(this.booking.bookingData.typeOfSale.promotionCode);
    }

    getVoucherNotAllowedMessage(): NullableString {
        if(this.isAllowed) {
            return null;
        }
        if(this._isPromoCodeAppliedOnBooking) {
            return this.services.language.translate('Voucher cannot be applied because you already used a promo code for this booking');
        }

        return this.services.language.translate('Voucher cannot be applied because today it is an ongoing promo campaign');
    }

    get code(): IFormField<NullableString> {
        return this.fields.code;
    }

    private _isSuccessfulApplyVoucherResult(applyVoucherResult: ValidateVoucherResultEnum): boolean {
        return applyVoucherResult === ValidateVoucherResultEnum.CoverEntireAmount
            || applyVoucherResult === ValidateVoucherResultEnum.PartialPayment;
    }

    private async _confirmVoucher(applyVoucherResult: ValidateVoucherResultEnum): Promise<ValidateVoucherResultEnum> {
        let headerTitle = this._isSuccessfulApplyVoucherResult(applyVoucherResult)
                                    ? this.services.language.translate(`Voucher confirmed`)
                                    : this.services.language.translate(`OOOPS!`);
        let confirmButtonText = this.services.language.translate(`Continue`);
        let bodyText: string;

        switch (applyVoucherResult) {
            case ValidateVoucherResultEnum.PartialPayment:
                bodyText = this.services.language.translate(`Your voucher has been applied and the cart has been updated. You now need to finalize the payment for the remaining balance using one of the other available payment methods`);
                confirmButtonText = this.services.language.translate('OK');
                break;
            case ValidateVoucherResultEnum.Expired:
                bodyText = this.services.language.translate(`Unfortunately the voucher has expired. Continue the booking flow with another voucher or with the full price.`);
                break;
            case ValidateVoucherResultEnum.NotEnoughMoney:
                bodyText = this.services.language.translate(`Unfortunately this voucher has been used. Continue the booking flow with another voucher or with the full price.`);
                break;
            case ValidateVoucherResultEnum.CoverEntireAmount:
                bodyText = this.services.language.translate(`The voucher value is covering the entire balance due. Please click continue if you want to finalize the payment using the voucher or click pay without the voucher in case you don't want to use it now. Note: eInvoice is not available in case you continue with the voucher payment method.`);
                break;
            case ValidateVoucherResultEnum.CannotBeAppliedForClassOfService:
                bodyText = this.services.language.translate(`Unfortunately this voucher class of service cannot be applied on your journey. Continue the booking flow with another voucher or with the full price.`);
                break;
            default:
                bodyText = this.services.language.translate(`Unfortunately the voucher is not valid anymore. Continue the booking flow with another voucher or with the full price.`);
        }

        await this.services.dialogFactory.showVoucherConfirmation({
            applyVoucherResult: applyVoucherResult,
            headerTitle: headerTitle,
            bodyText: bodyText,
            confirmButtonText: confirmButtonText,
            onReject: async () => {
                if(this._isSuccessfulApplyVoucherResult(applyVoucherResult)) {
                    applyVoucherResult = ValidateVoucherResultEnum.CancelAppliedVoucher;
                }
            }
        })

        return applyVoucherResult;
    }


    private async _validateVoucher(): Promise<IDotRezValidateVoucherResponse> {
        return await this.services.loadingIndicator.execute({
            action: async () => {

                return await this.booking.session.validateVoucher({
                    voucherCode: this.code.value!.toUpperCase().trim(),
                    isOneWayTrip: this.booking.isOneWayTrip,
                    balanceDue: this.booking.balanceDue
                });
            }
        });
    }


    async apply(): Promise<ApplyPromotionalResultEnum> {
        if(!this.code.value) {
            return ApplyPromotionalResultEnum.NoNeed;
        }

        let validateVoucherResponse = await this._validateVoucher();

        const validateVoucherResult = await this._confirmVoucher(validateVoucherResponse.result);

        switch (validateVoucherResult) {
            case ValidateVoucherResultEnum.CancelAppliedVoucher:
                return ApplyPromotionalResultEnum.NoNeed;
            case ValidateVoucherResultEnum.PartialPayment:
                await this._applyPartialCover(validateVoucherResponse.amount);
                return ApplyPromotionalResultEnum.Success;
            case ValidateVoucherResultEnum.CoverEntireAmount:
                await this._applyFullCover(validateVoucherResponse.amount);
                return ApplyPromotionalResultEnum.Success;
            default:
                return ApplyPromotionalResultEnum.Failed;
        }
    }

    private async _addVoucherToBooking(): Promise<ValidationResultEnum> {
        if(!this.isApplied) {
            throw new Error('You must first apply the voucher in order to add it to the booking');
        }
        const result = await this.services.loadingIndicator.execute({
            action: async () => {
                try {
                    await this.booking.session.addVoucherPaymentToBooking({
                        voucherCode: this.code.value!.toUpperCase().trim(),
                        amount: this.amount,
                        currencyCode: this.booking.currency
                    });
                    return ValidationResultEnum.Success;
                } catch (err) {
                    this.services.logger.error(`Payment failed for voucher ${this.booking.voucher.code.value}`, err);

                    await this.remove();

                    return ValidationResultEnum.Failure;

                }

            }
        });

        if(result !== ValidationResultEnum.Success) {
            this.services.alert.showError(this.services.language.translate('Failed to apply the voucher'));
        }

        return result;
    }

    private async _reloadPaymentMethods(): Promise<void> {
        await this.booking.paymentHandler.loadPaymentMethods({
            showLoadingIndicator: true
        });
    }

    private async _applyPartialCover(amount: number): Promise<void> {
        this._setAmount(amount);
        await this._reloadPaymentMethods();
    }

    private async _applyFullCover(amount: number): Promise<void> {
        this._setAmount(amount);
        if(ValidationResultEnum.Success === await this._addVoucherToBooking()) {
            await this.booking.paymentHandler.payBooking();
        }
    }

    async remove(): Promise<void>  {
        const shouldReloadPaymentMethods = this.isApplied;
        this.clear();
        if(shouldReloadPaymentMethods) {
            await this._reloadPaymentMethods();
        }
    }

    /**
     * This is called after the voucher is added to the booking and the booking is committed.
     * When the voucher is added to the booking and committed we don't need to artificially compute the balance due based on the in memory voucher amount.
     */
    clear(): void {
        this._setAmount(0);
        this.code.setValue(null);
    }

    get isAllowed(): boolean {
        return  !(this.services.configuration.data.vouchersRestrictedByPromotions && this.booking.hasPromotionApplied)
                || this.booking.blueBenefits.isBookingWithBlueBenefits;
    }

    get shouldBeVisible(): boolean {
        return !this.booking.insurance.hasSelectedInsurance;
    }
}
