import {IBookingMutationAction, IExecuteMutationOptions} from "./booking-mutation-action.interface";
import {
    IDotRezPartialBookingSessionData
} from "../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {BookingModel} from "../booking.model";
import {IServiceFactory} from "../../../service-factory.interface";
import {IDotRezBookingSession} from "../../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {IMutationOptions} from "./mutation-options.interface";
import {makeObservable, observable, runInAction} from "mobx";
import {WaitForMutationBehaviorEnum} from "./booking-mutation-waiter.interface";

export abstract class BookingMutationActionBase implements IBookingMutationAction {
    constructor(protected readonly booking: BookingModel,
                private readonly options?: IMutationOptions) {
        this._waitForMutationBehavior = options?.waitForMutationBehavior || WaitForMutationBehaviorEnum.SilentOnError;
        makeObservable(this, {
            isMutationInProgress: observable.ref
        });
    }

    abstract _runMutation(): Promise<IDotRezPartialBookingSessionData>;

    private readonly _waitForMutationBehavior: WaitForMutationBehaviorEnum;
    isMutationInProgress: boolean = true;

    private _setIsMutationInProgress(value: boolean) {
        runInAction(() => {
            this.isMutationInProgress = value;
        });
    }

    private _mutationPromise: Promise<IDotRezPartialBookingSessionData> | null = null;
    execute(options?: IExecuteMutationOptions): Promise<IDotRezPartialBookingSessionData> {


        this._mutationPromise = this._runMutation().then(data1 => {
            options = {
                shouldConsumeFlex: true,
                ...options
            };

            if(!this.willAffectTotal || !options.shouldConsumeFlex) {
                return data1;
            }

            return this.booking.consumeFlex(data1.bookingData).then(data2 => {
                this._setIsMutationInProgress(false);
                return {
                    ...data1,
                    ...data2
                }
            });

        }).catch(err => {
            this._setIsMutationInProgress(false);
            throw err;
        });
        return this._mutationPromise;
    }

    waitForMutation(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const waitForMutationPromise = () => {
                if(this._mutationPromise) {
                    this._mutationPromise
                        .then(() => resolve())
                        .catch(err => {
                            this.services.logger.error(`Wait for mutation ${this.constructor?.name} failed`, err);
                            if(this._waitForMutationBehavior === WaitForMutationBehaviorEnum.ThrowOnError) {
                                reject(err)
                            } else {
                                resolve();
                            }
                        })
                } else {
                    //it means that the execute method wasn't called yet so we wait a little bit for execute to be called
                    setTimeout(() => {
                        waitForMutationPromise();
                    });
                }
            }

            waitForMutationPromise();
        });
    }


    get willAffectTotal(): boolean {
        return true;
    }
    async onAfterBookingSessionDataUpdated(): Promise<void> {
        if(this.options?.onCompleted) {
            this.options.onCompleted();
        }
    }

    protected get services(): IServiceFactory {
        return this.booking.services;
    }

    protected get session(): IDotRezBookingSession {
        return this.booking.session;
    }

    onError(err: any): void {
        if(this.options?.onError) {
            this.options.onError(err);
        }
    }

}
