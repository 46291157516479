import {
    ILoginOptions,
    IUserService
} from "./user.service.interface";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {IDotRezUserSession} from "../dot-rez-api/session/user-session/dot-rez-user.session.interface";
import {TimeSpan} from "../../types/time-span";
import {makeObservable, observable, reaction, runInAction} from "mobx";
import {IUserModel} from "./models/user-model.interface";
import {AnonymousUserModel} from "./models/anonymous-user/anonymous-user.model";
import {AuthorizedUserModel} from "./models/authorized-user/authorized-user.model";
import {IDotRezBookingSession} from "../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {LoginAndRegistrationDialogTabEnum} from "./models/login-and-registration-dialog-tab.enum";
import {ValidationResultEnum} from "../../types/validation-result.enum";
import {PasswordResetFormModel} from "./models/password-reset/password-reset-form.model";
import {IUserProfileViewModel} from "./models/profile/user-profile-view-model.interface";
import {NullableNumber, NullableString} from "../../types/nullable-types";
import {Check} from "../../types/type-checking";

const RECREATE_SESSION_INTERVAL = TimeSpan.fromMinutes(13);
const ACCELERATED_RECREATE_SESSION_INTERVAL = TimeSpan.fromSeconds(6);

export class UserService extends ServiceBase implements IUserService {
    constructor(services: IServiceFactory) {
        super(services);

        this._currentUser = this._createInitialUser();

        this._currentUser.getSession().then(() => {
            this._lastSuccessfulRecreateSession = Date.now();
            this._startRecreateSessionTimer(RECREATE_SESSION_INTERVAL);
        }).catch(() => {
            this._startRecreateSessionTimer(ACCELERATED_RECREATE_SESSION_INTERVAL);
        });

        reaction(() => this.services.application.isActive,
            async (isActive) => {
                if(!isActive) {
                    return;
                }

                // if _lastSuccessfulRecreateSession was not set yet it means that there is a pending create session
                if(Check.isNullOrUndefined(this._lastSuccessfulRecreateSession)) {
                    return;
                }

                const now = this.services.time.currentDate.getTime();
                const diff = now - this._lastSuccessfulRecreateSession;
                if (diff >= RECREATE_SESSION_INTERVAL.totalMilliseconds) {
                    await this._tryRecreateSession();
                } else {
                    this._startRecreateSessionTimer(TimeSpan.fromMilliseconds(RECREATE_SESSION_INTERVAL.totalMilliseconds - diff));
                }
            });

        makeObservable(this, {
            _currentUser: observable.ref
        });
    }

    private _recreateSessionTimer: any = null;
    private _lastSuccessfulRecreateSession: NullableNumber = null;
    _currentUser: IUserModel;



    private _createInitialUser(): IUserModel {
        const refreshToken = this.services.localStorage.getItem('user.refreshToken');
        if(refreshToken) {
            return new AuthorizedUserModel(this.services, refreshToken);
        } else {
            return new AnonymousUserModel(this.services);
        }
    }

    private _setCurrentUser(user: IUserModel) {
        if(this._currentUser === user) {
            return;
        }

        if(this._currentUser) {
            this._currentUser.dispose();
        }
        runInAction(() => {
            this._currentUser = user;
        });

        if(user.isAuthorized) {
            this.services.localStorage.setItem('authorizedUser.lastLoggedInUser', user.profile.emailAddress);
        }
    }

    get lastLoggedInUser(): NullableString {
        return this.services.localStorage.getItem('authorizedUser.lastLoggedInUser');
    }

    get isAuthorized(): boolean {
        return this._currentUser.isAuthorized;
    }
    
    getSession(): Promise<IDotRezUserSession> {
        return this._currentUser.getSession();
    }

    private async _tryRecreateSession(): Promise<void> {
        clearTimeout(this._recreateSessionTimer);

        try {
            await this._currentUser.reCreateSession();
            this._lastSuccessfulRecreateSession = this.services.time.currentDate.getTime();
            this._startRecreateSessionTimer(RECREATE_SESSION_INTERVAL);
        } catch (err) {
            this.services.logger.error('Failed to recreate user session', err);
            this._startRecreateSessionTimer(ACCELERATED_RECREATE_SESSION_INTERVAL);
        }
    }

    private _startRecreateSessionTimer(timeInterval: TimeSpan): void {
        clearTimeout(this._recreateSessionTimer);
        this._recreateSessionTimer = setTimeout(async () => {
            await this._tryRecreateSession();
        }, timeInterval.totalMilliseconds);
    }


    private _loginInProgressPromise: Promise<void> | null = null;
    login(options?: ILoginOptions): Promise<void> {

        if(this._loginInProgressPromise) {
            return this._loginInProgressPromise;
        }


        options = {
            defaultTab: LoginAndRegistrationDialogTabEnum.Login,
            ...options
        };

        this._loginInProgressPromise = this._currentUser.login(options).then(user => {
            this._setCurrentUser(user);
            this._loginInProgressPromise = null;
        }).catch((err) => {
            this._loginInProgressPromise = null;
            throw err;
        });

        return this._loginInProgressPromise;

    }

    async logout(): Promise<void> {
        await this.services.navigator.goHome();
        this.services.homePage.activateBookTab();
        const user = await this._currentUser.logout();
        this._setCurrentUser(user);
    }

    async createTransientBookingSession(): Promise<IDotRezBookingSession> {
        return await this._currentUser.createTransientBookingSession();
    }

    async createBookingSession(): Promise<IDotRezBookingSession> {
        return await this._currentUser.createBookingSession();
    }

    async createFlightSearchSession(): Promise<IDotRezBookingSession> {
        return await this._currentUser.createFlightSearchSession();
    }


    async sendResetPasswordLink(emailAddress: string): Promise<ValidationResultEnum> {
        const response = await this.services.airlineWebapi.sendResetPasswordLink(emailAddress);
        if(response.hasError) {
            this.services.alert.showError(this.services.language.translateApiError(response.errorCode));
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    async confirmRegistration(customerNumber: string): Promise<void> {
        await this.services.loadingIndicator.execute({
            action: async () => {
                await this.services.airlineWebapi.confirmRegistration(customerNumber);
            }
        });


        await this.services.dialogFactory.showRegistrationConfirmation();

        this._currentUser.registrationConfirmed();

        if(this.isAuthorized) {
            await this.logout();
        }

        await this.login();
    }

    async resetPassword(token: string): Promise<void> {
        await this.services.dialogFactory.showPasswordReset({
           form: new PasswordResetFormModel(token, this.services)
        });
    }

    async switchUser(user: IUserModel): Promise<void> {
        await this.logout()
        this._setCurrentUser(user);
    }

    private async _executeAuthorized(action: () => Promise<void>): Promise<void> {
        await this.login();
        if(this.isAuthorized) {
            await action();
        }
    }

    async showProfile(): Promise<void> {
        await this._executeAuthorized(async () => {
            this.services.navigator.routes.user.profile.activate();
        });
    }

    get profile(): IUserProfileViewModel {
        return this._currentUser.profile;
    }
}
