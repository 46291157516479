import {PaymentMethodBaseModel} from "../payment-method-base.model";
import {Price} from "../../../../../currency/price";
import {PaymentMethodCategoryEnum} from "../payment-method-category.enum";

export abstract class MobileWalletPaymentMethodModel extends PaymentMethodBaseModel {
    get availableAmountInBookingCurrency(): Price {
        return this.booking.balanceDue;
    }

    get category(): PaymentMethodCategoryEnum {
        return PaymentMethodCategoryEnum.MobileWallet;
    }
}