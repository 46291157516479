import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IPassengerSsrEditorViewModel} from "../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {PriceWithDiscountComponent} from "../../../../components/price/price-with-discount.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {StandardPriceLabelComponent} from "../../../../components/price/standard-price-label.component";
import {BagContainerBox} from "./bag-container.box";
import {
    SsrDecrementButtonComponent,
    SsrIncrementButtonComponent
} from "../../common-components/ssrs/ssr-quantity-edit-buttons";
import {BagIconComponent} from "./bag-icon.component";
import {translateIncludedBags} from "../bags-selection.helpers";
import {IncludedBagsBox} from "./included-bags.box";
import {BagsSectionTitleComponent} from "./bags-section-title.component";
import {ErrorMessageTextBoxComponent} from "../../../../components/error-message/error-message-text-box.component";
import {IPassengerAllBagsViewModel} from "../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";
import {SsrModificationBlockingReasonComponent} from "../../common-components/ssr-modification-blocking-reason.component";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";

const QuantityEditButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const DecrementButtonComponent = styled(SsrDecrementButtonComponent)`
    margin-left: ${props => props.theme.spacing.small};
`

const IncrementButtonComponent = styled(SsrIncrementButtonComponent)`
    margin-right: ${props => props.theme.spacing.small};
`



const BagQuantityBox = styled.div`
    text-align: center;
    font-size: ${props => props.theme.fontSize.xxLarge};
    color: ${props => props.theme.colors.primary};
    margin: 0 ${props => props.theme.spacing.large} 0 ${props => props.theme.spacing.large};
    
`

const BagQuantityButtonsComponent: React.FC<{ssrEditor: IPassengerSsrEditorViewModel;}> = observer((props) => {
    const onDecrement = async () => {
        props.ssrEditor.newQuantity--;
    }

    const onIncrement = async () => {
        props.ssrEditor.newQuantity++;
    }

    const canIncrement = props.ssrEditor.canBeModifiedForAtLeastOneSegment && props.ssrEditor.newQuantity < props.ssrEditor.maxAllowedQuantity;
    const canDecrement = props.ssrEditor.canBeModifiedForAtLeastOneSegment && props.ssrEditor.newQuantity > props.ssrEditor.minAllowedQuantity;

    return (
        <QuantityEditButtonsContainerBox>
            <DecrementButtonComponent disabled={!canDecrement}
                                      onClick={onDecrement}/>
            <BagQuantityBox className='tst-bag-quantity'>
                {props.ssrEditor.newQuantityToDisplay}
            </BagQuantityBox>

            <IncrementButtonComponent disabled={!canIncrement}
                                      onClick={onIncrement}/>

        </QuantityEditButtonsContainerBox>
    )
});

interface BagContainerComponentProps {
    bagSsrEditor: IPassengerSsrEditorViewModel;
}

export const CheckInBagSelectionComponent: React.FC<BagContainerComponentProps> = observer((props) => {
    const services = useServices();
    const isSelected = props.bagSsrEditor.newQuantity > 0

    const renderIncludedBags = () => {
        if(props.bagSsrEditor.quantityIncludedInBundle === 0) {
            return null;
        }
        return (
            <IncludedBagsBox>
                {translateIncludedBags(services.language, props.bagSsrEditor.quantityIncludedInBundleToDisplay)}
            </IncludedBagsBox>
        )
    }

    return (
        <BagContainerBox isSelected={isSelected} className={`tst-check-in-bag tst-check-in-bag-${props.bagSsrEditor.ssrType.ssrCode.toLowerCase()}`}>
            <PriceWithDiscountComponent discountedPrice={props.bagSsrEditor.discountedMinPrice}
                                        standardPrice={props.bagSsrEditor.standardMinPrice}
                                        label={<StandardPriceLabelComponent/>}
                                        className='tst-price-box'/>
            <BagIconComponent ssrType={props.bagSsrEditor.ssrType} isSelected={isSelected} />
            {renderIncludedBags()}
            <BagQuantityButtonsComponent ssrEditor={props.bagSsrEditor}/>
            <SsrModificationBlockingReasonComponent reason={props.bagSsrEditor.modificationBlockingReason}/>
        </BagContainerBox>
    )
});


const CheckInBagsBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`

const PassengerCheckInBagsBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.medium};
`

const NoCheckInBagsButtonContainerBox = styled.div`
    padding-top: ${props => props.theme.spacing.large};
    width: 50%;
`

const NoCheckInBagsButtonComponent: React.FC<{passengerBags: IPassengerAllBagsViewModel}> = observer((props) => {
    const services = useServices();
    if(props.passengerBags.skipCheckInBags) {
        return (
            <NoCheckInBagsButtonContainerBox>
                <PrimaryButton>
                    {services.language.translate('None')}
                </PrimaryButton>
            </NoCheckInBagsButtonContainerBox>

        )
    } else {
        return (
            <NoCheckInBagsButtonContainerBox>
                <PrimaryButtonInverted onClick={() => props.passengerBags.skipCheckInBags = true}>
                    {services.language.translate('None')}
                </PrimaryButtonInverted>
            </NoCheckInBagsButtonContainerBox>

        )
    }
});


const NoCheckInBagsSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -12px;
    margin-right: -12px;
`

const NoCheckInBagsSectionDelimiterBox = styled.div`
    text-align: center;
    font-size: ${props => props.theme.fontSize.medium};
    color: ${props => props.theme.colors.medium};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: ${props => props.theme.spacing.large};

    &:after { 
        content: '';
        height: 1px;
        flex: 1;
        background-color: rgba(${props => props.theme.colors.mediumRgb}, 0.75);
        margin-left: ${props => props.theme.spacing.large};
    }

    &:before { 
        content: '';
        height: 1px;
        flex: 1;
        background-color: rgba(${props => props.theme.colors.mediumRgb}, 0.75);
        margin-right: ${props => props.theme.spacing.large};
    }
`


interface PassengerCheckInBagsSelectionComponentProps {
    passengerBags: IPassengerAllBagsViewModel;
}

export const PassengerCheckInBagsSelectionComponent: React.FC<PassengerCheckInBagsSelectionComponentProps> = observer((props) => {
    const services = useServices();
    const checkInBags = props.passengerBags.checkInBags;

    const renderNoCheckInBagsSection = () => {

        if(checkInBags.some(b => b.newQuantity > 0)) {
            return null;
        }

        if(!checkInBags.some(b => b.canBeModifiedForAtLeastOneSegment)) {
            return null;
        }

        return (
            <NoCheckInBagsSectionBox>
                <NoCheckInBagsSectionDelimiterBox>
                    {services.language.translate('OR')}
                </NoCheckInBagsSectionDelimiterBox>
                <NoCheckInBagsButtonComponent passengerBags={props.passengerBags}/>
            </NoCheckInBagsSectionBox>
        );
    }

    const captureElementRef = (el: any) => {
        props.passengerBags.attachCheckInBagsContainerDOMElement(el);
    }

    return (
        <PassengerCheckInBagsBox className='tst-checkin-bags' ref={captureElementRef}>
            <BagsSectionTitleComponent title={services.language.translate('Checked-in baggage')}
                                       promotionalMessage={services.language.translate('Cheapest time to buy')}
                                       showDisclaimer={false}
                                       ssrsTypes={checkInBags.map(b => b.ssrType)}/>
            <CheckInBagsBox>
                {checkInBags.map(bag => <CheckInBagSelectionComponent key={bag.ssrType.ssrCode}
                                                                      bagSsrEditor={bag}/>)}
            </CheckInBagsBox>
            <ErrorMessageTextBoxComponent errorMessage={props.passengerBags.checkInBagsErrorMessage}/>
            {renderNoCheckInBagsSection()}
        </PassengerCheckInBagsBox>
    );
});
