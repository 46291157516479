import React from "react";
import {useServices} from "../../../hooks/use-services.hook";
import { ArrowBackIcon } from "../../../assets/svg/arrow-back.icon";
import {observer} from "mobx-react";
import styled from "styled-components";
import {XCloseSmallIcon} from "../../../assets/svg/x-close-small,icon";

const BackButtonBox = styled.div<{isHidden: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.small};
    visibility: ${props => props.isHidden ? "hidden" : "visible"};
    cursor: pointer;
`

const BackButtonText = styled.div`
    font-size: ${props => props.theme.fontSize.xxLarge};
`

interface PageBackButtonComponentProps {
    isHidden?: boolean;
    onClick?: () => void;
    useCloseButtonInsteadOfBackButton?: boolean;
}
export const PageBackButtonComponent: React.FC<PageBackButtonComponentProps> = observer((props) => {
    const services = useServices();

    if(!props.onClick && !services.navigator.canGoBack) {
        return null;
    }

    const isHidden = Boolean(props.isHidden) || (!props.onClick && !services.navigator.canGoBack);

    const onClickHandler = () => {
        if(props.onClick) {
            props.onClick();
        } else if(services.navigator.canGoBack){
            services.navigator.goBack();
        }
    }

    const renderBackText = () => {
        if(services.layout.isInSmallScreenView) {
            return null;
        }
        return (
            <BackButtonText>
                {services.language.translate('Back')}
            </BackButtonText>
        )
    }

    const renderBackButton = () => {
        if(props.useCloseButtonInsteadOfBackButton) {
            return (<XCloseSmallIcon size={3.3}/>);
        } else {
            return (<ArrowBackIcon/>);
        }
    }

    return (
        <BackButtonBox onClick={onClickHandler} isHidden={isHidden}>
            {renderBackButton()}
            {renderBackText()}
        </BackButtonBox>
    );
})
