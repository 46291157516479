import {Station} from "../../stations/station.service.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {NullableDate, NullableString} from "../../../types/nullable-types";
import {MonthModel} from "../../time/month.model";
import {IFlightsScheduleViewModel} from "./flights-schedule-view-model.interface";
import {IFlightScheduleFilter} from "./flight-schedule-filter.interface";

export class FlightScheduleModel implements IFlightsScheduleViewModel {
    constructor(private services: IServiceFactory,
                public readonly origin: Station,
                public  readonly destination: Station,
                private readonly _getMinAllowedDate: () => NullableDate,
                private readonly filter: IFlightScheduleFilter | null) {
        makeObservable<this, '_availableDates'>(this, {
            isLoading: observable.ref,
            errorMessage: observable.ref,
            _availableDates: observable.ref,
            availableDates: computed,
            availableDatesMap: computed
        });

        this._waitForSchedulePromise = this._loadFlightSchedule();
    }

    private readonly _waitForSchedulePromise: Promise<void>;

    waitForSchedule(): Promise<void> {
        return this._waitForSchedulePromise;
    }

    isLoading: boolean = true;
    private _setIsLoading(value: boolean): void {
        runInAction(() => {
            this.isLoading = value;
        });
    }
    errorMessage: NullableString = null;


    private async _loadFlightSchedule(): Promise<void> {

        this._setIsLoading(true);

        try {
            const currentDate = this.services.time.currentDate.getTime();
            let availableDates = await this.services.flightsSchedule.getFlightSchedule(this.origin, this.destination);
            availableDates = availableDates.filter(d => d.getTime() > currentDate);
            if (this.filter) {
                availableDates = availableDates.filter(d => !this.filter?.shouldFilterOutDate(d));
            }

            runInAction(() => {
                this._availableDates = availableDates;
                if (this._availableDates.length === 0) {
                    this.errorMessage = this.services.language.translationFor(`There are no flights available for {route}`)
                        .withParams({
                            route: `${this.origin.stationMacName} - ${this.destination.stationMacName}`
                        });
                }
            });

        }
        catch (err) {
            this.services.logger.error(`Failed to load schedule for flight ${this.origin.stationMacCode} - ${this.destination.stationMacCode}`);
            this.errorMessage = this.services.language.translationFor('There was an error trying to load flights schedule for {route}')
                .withParams({
                    route: `${this.origin.stationMacName} - ${this.destination.stationMacName}`
                });
        }
        finally {
            this._setIsLoading(false);
        }
    }

    private _availableDates: Date[] = [];
    get availableDates(): Date[] {
        const minAllowedDate = this._getMinAllowedDate()?.getTime();
        if(minAllowedDate) {
            return this._availableDates.filter(d => d.getTime() >= minAllowedDate);
        }

        return this._availableDates;

    }

    get availableDatesMap(): Record<string, Date> {
        return this.availableDates.toDictionary(d => this.services.time.formatYYY_MM_DD(d));
    }

    getFlightsScheduledMonths(): MonthModel[] {
        if(this.availableDates.length === 0) {
            return [];
        }

        return this.services.time.getMonthsInRange(this.availableDates[0], this.availableDates[this.availableDates.length - 1]);
    }

    isDateAvailable(date: Date): boolean {
        if(this.availableDates.length === 0) {
            return false;
        }

        const formattedDate = this.services.time.formatYYY_MM_DD(date);
        return Boolean(this.availableDatesMap[formattedDate]);
    }

    /*
    private _getNoAvailableFlightsErrorMessage(availableDepartureDates: Date[], availableReturnDates: Date[]): NullableString {
        const noFlights = this.services.language.translate('There are no flights available for your selection');
        if(this.isOneWayTrip) {
            if(Check.isEmpty(availableDepartureDates)) {
                return noFlights;
            }
        } else {
            if(Check.isEmpty(availableDepartureDates) && Check.isEmpty(availableReturnDates)) {
                return noFlights;
            } else if(Check.isEmpty(availableDepartureDates)) {
                return this.services.language.translate('There are no departing flights available for your selection');
            }  else if(Check.isEmpty(availableReturnDates)) {
                return this.services.language.translate('There are no return flights available for your selection');
            }
        }

        return null;
    }
    */
}
