import {IFlightsScheduleService} from "./flights-schedule.service.interface";
import {Station} from "../stations/station.service.interface";
import {makeObservable, observable, reaction} from "mobx";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {IDotRezUserSession} from "../dot-rez-api/session/user-session/dot-rez-user.session.interface";


export class FlightsScheduleService extends ServiceBase implements IFlightsScheduleService {
    constructor(services: IServiceFactory) {
        super(services);
        makeObservable(this, {
            _flightsScheduleCache: observable
        });

        reaction(() => this.services.application.isActive,
            (isActive) => {
                if(isActive) {
                    this._flightsScheduleCache = {};
                }
            });
    }
    _flightsScheduleCache: Record<string, FlightScheduleCache> = {};

    getFlightSchedule(originStation: Station, destinationStation: Station): Promise<Date[]> {
        const flightKey = originStation.stationMacCode + destinationStation.stationMacCode;

        if(!this._flightsScheduleCache[flightKey]) {
            this._flightsScheduleCache[flightKey] = new FlightScheduleCache(originStation, destinationStation, this.services);
        }

        return this._flightsScheduleCache[flightKey].getFlightSchedule();
    }
}

class FlightScheduleCache {
    constructor(private readonly _originStation: Station,
                private readonly _destinationStation: Station,
                private readonly _services: IServiceFactory) {

    }


    private _getFlightSchedulePromise: Promise<Date[]> | null = null;

    private async _readOneSchedule(session: IDotRezUserSession, formattedBeginDate: string, formattedEndDate: string, origin: string, destination: string): Promise<Date[]> {
        try {
            const tripScheduleResponse = await session.tripSchedule({
                beginDate: formattedBeginDate,
                endDate: formattedEndDate,
                origin: origin,
                destination: destination
            });

            return tripScheduleResponse.map(schedule => this._services.time.parseIsoDate(schedule.latest));

        } catch (err) {
            this._services.logger.error(`Failed to read schedule for ${origin} -> ${destination}`, err);
            return [];
        }
    }

    private async _readAllSchedules(beginDate: Date, endDate: Date, origins: string[], destinations: string[]): Promise<Date[]> {
        const time = this._services.time;
        const formattedBeginDate = time.formatYYY_MM_DD(beginDate);
        const formattedEndDate = time.formatYYY_MM_DD(endDate);

        const session = await this._services.user.getSession();

        const promises: Promise<Date[]>[] = [];

        for(let origin of origins) {
            for(let destination of destinations) {
                promises.push(this._readOneSchedule(session, formattedBeginDate, formattedEndDate, origin, destination));
            }
        }

        const allSchedules = await Promise.all(promises);
        const allDates = allSchedules.reduce((previousValue, currentValue) => {
            return [
                ...previousValue,
                ...currentValue
            ];
        }, []);

        return allDates.distinct(date => this._services.time.formatYYY_MM_DD(date), date => date)
                        .sort((d1, d2) => d1.getTime() - d2.getTime());


    }

    public getFlightSchedule(): Promise<Date[]> {
        if (this._getFlightSchedulePromise) {
            return this._getFlightSchedulePromise;
        }

        const time = this._services.time;
        const beginDate = this._services.time.currentDate;
        const endDate = time.addYears(beginDate, 2);
        let origins = [this._originStation.stationCode];
        let destinations = [this._destinationStation.stationCode];
        if(this._originStation.allMacStations.length > 0) {
            origins = this._originStation.allMacStations;
        }

        if(this._destinationStation.allMacStations.length > 0) {
            destinations = this._destinationStation.allMacStations;
        }

        this._getFlightSchedulePromise = this._readAllSchedules(beginDate, endDate, origins, destinations);


        return this._getFlightSchedulePromise;

    }

}
