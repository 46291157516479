import React from "react";
import {observer} from "mobx-react";
import {IFormField} from "../../models/forms/form-field.interface";
import {NullableString} from "../../types/nullable-types";
import {useServices} from "../../hooks/use-services.hook";
import {StandardDropDownListItemModel} from "../forms/standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";
import {StandardDropDownComponent} from "../forms/standard-drop-down/standard-drop-down.component";

interface CountryPickerComponentProps {
    field: IFormField<NullableString>;
    popupHeaderTitle?: string;
    className?: string;
    excludeCountries?: string[];
}
export const CountryPickerComponent: React.FC<CountryPickerComponentProps> = observer((props) => {
    const services = useServices();

    const excludeCountries = props.excludeCountries ?? [];

    let countries = services.country.countries;

    if(excludeCountries.length > 0) {
        countries = countries.filter(c => !excludeCountries.includes(c.code));
    }

    const items = countries.map(c => new StandardDropDownListItemModel(c.code, c.name));
    return (
        <StandardDropDownComponent field={props.field}
                                   items={items}/>
    );
});
