import {IServiceFactory} from "../../service-factory.interface";
import {
    IDotRezBookingData, IDotRezBookingPassenger,
    IDotRezJourney, IDotRezPassengerName, IDotRezPassengerSegment, IDotRezSegment, IDotRezPassengerTravelDocument
} from "../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IDotRezBookingSession} from "../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {
    IBoardingPassDesignator,
    IBoardingPassSsr,
    IPassengerSegmentBoardingPassViewModel
} from "./passenger-segment-boarding-pass-view-model.interface";
import {IDotRezJourneyBoardingPassesRequest} from "../../dot-rez-api/data-contracts/requests/booking/boarding-passes.request";
import {
    IDotRezBoardingPassLeg,
    IDotRezPassengerBoardingPass,
    IDotRezPassengerSegmentBoardingPassInfo
} from "../../dot-rez-api/data-contracts/booking/boarding-pass/boarding-passe.data-contracts";
import {IDotRezIdentifier} from "../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import {composeTripReferenceKey} from "./boarding-pass.utils";
import {NullableUndefinedString} from "../../../types/nullable-types";
import {IDotRezDesignator} from "../../dot-rez-api/data-contracts/common/designator";
import {PassengerSegmentCheckInStatusEnum} from "../../dot-rez-api/data-contracts/enums/passenger-segment-check-in-status.enum";
import {getFirstValidTravelDocument} from "../models/passenger/passenger-travel-document.model";

export class BoardingPassesReader {
    constructor(private bookingData: IDotRezBookingData,
                private readonly bookingSession: IDotRezBookingSession,
                private readonly services: IServiceFactory) {
    }

    async getBoardingPasses(): Promise<IPassengerSegmentBoardingPassViewModel[]> {
        const request = this._createBoardingPassesRequest();
        if(request.length === 0) {
            return [];
        }

        const passengersBoardingPasses = await this.bookingSession.getBoardingPasses(request);
        return this._buildBoardingPasses(passengersBoardingPasses);

    }

    private _createBoardingPassesRequest(): IDotRezJourneyBoardingPassesRequest[] {

        return this.bookingData.journeys
                    .map(journey => {
                        const journeyForBoardingPassRequest: IDotRezJourneyBoardingPassesRequest = {
                            journeyKey: journey.journeyKey,
                            passengersKeys: this._getCheckedInPassengersKeys(journey)
                        }
                        return journeyForBoardingPassRequest;
                    }).filter(j => j.passengersKeys.length > 0);
    }

    private _getCheckedInPassengersKeys(journey: IDotRezJourney): string[] {
        const passengersKeys: string[] = [];
        for(let segment of journey.segments) {
            for(let passengerSegment of segment.passengerSegment) {
                if(passengerSegment.value.liftStatus === PassengerSegmentCheckInStatusEnum.CheckedIn) {
                    passengersKeys.push(passengerSegment.key);
                }
            }
        }

        return passengersKeys;
    }

    private _buildBoardingPasses(passengersBoardingPasses: IDotRezPassengerBoardingPass[]): IPassengerSegmentBoardingPassViewModel[] {
        let result: IPassengerSegmentBoardingPassViewModel[] = [];
        for(let passengerBoardingPasses of passengersBoardingPasses) {
            if(this._isInfantBoardingPass(passengerBoardingPasses)) {
                result = [
                    ...result,
                    ...this._buildInfantPassengerBoardingPasses(passengerBoardingPasses)
                ];
            } else {
                result = [
                    ...result,
                    ...this._buildMaturePassengerBoardingPasses(passengerBoardingPasses)
                ];
            }


        }

        return result;
    }

    private _isInfantBoardingPass(passengerBoardingPass: IDotRezPassengerBoardingPass): boolean {
        if(!passengerBoardingPass.passenger.infant) {
            return false;
        }

        return passengerBoardingPass.passenger.name.last.toUpperCase() === passengerBoardingPass.passenger.infant.name.last.toUpperCase()
                && passengerBoardingPass.passenger.name.first.toUpperCase() === passengerBoardingPass.passenger.infant.name.first.toUpperCase()
    }

    private _getFirstValidTravelDocument(travelDocuments: IDotRezPassengerTravelDocument[] | null, flightDate: Date): IDotRezPassengerTravelDocument | undefined {
        return getFirstValidTravelDocument(this.services, travelDocuments, flightDate);
    }

    private _buildMaturePassengerBoardingPasses(passengerBoardingPasses: IDotRezPassengerBoardingPass): IPassengerSegmentBoardingPassViewModel[] {

        const passenger = this._findPassenger(passengerBoardingPasses.passenger.name.first, passengerBoardingPasses.passenger.name.last);
        if(!passenger) {
            return [];
        }

        let result: IPassengerSegmentBoardingPassViewModel[] = [];

        for(let passengerSegmentBoardingPass of passengerBoardingPasses.segments) {

            const passengerSegment = this._findPassengerSegment(passenger.passengerKey, passengerSegmentBoardingPass.identifier);

            if(passengerSegment) {

                let boardingPass: IPassengerSegmentBoardingPassViewModel = {
                    passengerKey: passenger.passengerKey,
                    segmentKey: passengerSegment.segment.segmentKey,
                    fullName: this._getFullPassengerName(passenger.name),
                    dateOfBirth: this._formatDateOfBirth(passenger.info.dateOfBirth),
                    isInfant: false,
                    ...this._getTravelDocumentFields(this._getFirstValidTravelDocument(passenger.travelDocuments, this.services.time.parseIsoDate(passengerSegment.journey.designator.departure))),
                    ...this._getCommonBoardingPassFields(passengerSegmentBoardingPass, passengerSegment.journey, passengerSegment.segment, passengerSegment.passengerSegment),
                    hasInfant: Boolean(passenger.infant),
                    priorityBoarding: {
                        ssrCode: this.services.ssrTypes.PBRD.ssrCode,
                        count: this._countSsr(this.services.ssrTypes.PBRD.ssrCode, passengerSegment.passengerSegment)
                    },
                    checkInBags: this._getPassengerSegmentCheckInBags(passengerSegment.passengerSegment),
                    otherSsrs: this._getOtherSsrs(passengerSegment.passengerSegment, passengerSegmentBoardingPass.legs)
                }
                if(!boardingPass.nationality) {
                    boardingPass = {
                        ...boardingPass,
                        nationality: this._getNationality(passenger.info?.nationality)
                    };
                }
                result.push(boardingPass);
            }
        }

        return result;
    }

    private _buildInfantPassengerBoardingPasses(passengerBoardingPasses: IDotRezPassengerBoardingPass): IPassengerSegmentBoardingPassViewModel[] {

        if(!passengerBoardingPasses.passenger.infant) {
            return [];
        }

        const passenger = this._findPassengerByInfant(passengerBoardingPasses.passenger.infant.name.first, passengerBoardingPasses.passenger.infant.name.last);
        if(!passenger?.infant) {
            return [];
        }


        let result: IPassengerSegmentBoardingPassViewModel[] = [];

        for(let passengerSegmentBoardingPass of passengerBoardingPasses.segments) {

            const passengerSegment = this._findPassengerSegment(passenger.passengerKey, passengerSegmentBoardingPass.identifier);

            if(passengerSegment) {
                let boardingPass: IPassengerSegmentBoardingPassViewModel = {
                    segmentKey: passengerSegment.segment.segmentKey,
                    passengerKey: passenger.passengerKey,
                    fullName: this._getFullPassengerName(passenger.infant.name),
                    dateOfBirth: this._formatDateOfBirth(passenger.infant.dateOfBirth),
                    isInfant: true,
                    ...this._getTravelDocumentFields(this._getFirstValidTravelDocument(passenger.infant.travelDocuments, this.services.time.parseIsoDate(passengerSegment.journey.designator.departure))),
                    ...this._getCommonBoardingPassFields(passengerSegmentBoardingPass, passengerSegment.journey, passengerSegment.segment, passengerSegment.passengerSegment),
                    hasInfant: false,
                    priorityBoarding: {
                        ssrCode: this.services.ssrTypes.PBRD.ssrCode,
                        count: 0
                    },
                    checkInBags: [],
                    otherSsrs: []
                }

                if(!boardingPass.nationality) {
                    boardingPass = {
                        ...boardingPass,
                        nationality: this._getNationality(passenger.infant.nationality)
                    };
                }

                result.push(boardingPass);
            }
        }

        return result;
    }

    private _getCommonBoardingPassFields(passengerSegmentBoardingPass: IDotRezPassengerSegmentBoardingPassInfo,
                                         journey: IDotRezJourney,
                                         segment: IDotRezSegment,
                                         passengerSegment: IDotRezPassengerSegment) {
        return {
            tripReferenceKey: this._getTripReferenceKey(journey),
            recordLocator: this.bookingData.recordLocator!,
            barCode: passengerSegmentBoardingPass.barCode,
            boardingTime: passengerSegmentBoardingPass.boardingTime,
            fullFlightIdentifier: this._getFullFlightIdentifier(passengerSegmentBoardingPass),
            designator: this._getDesignator(segment),
            international: passengerSegmentBoardingPass.international,
            boardingSequence: passengerSegment.boardingSequence || '',
            seat: this._getSeat(passengerSegment),
        }
    }

    private _getFullPassengerName(name: IDotRezPassengerName | null | undefined): string {
        if(!name) {
            return '';
        }

        return `${name.first} ${name.last}`;
    }

    private _getTripReferenceKey(journey: IDotRezJourney): string {
        return composeTripReferenceKey(this.bookingData.recordLocator!, journey.designator.origin, journey.designator.destination);
    }

    private _getFullFlightIdentifier(passengerSegmentBoardingPass: IDotRezPassengerSegmentBoardingPassInfo): string {
        return `${passengerSegmentBoardingPass.identifier.carrierCode}${passengerSegmentBoardingPass.identifier.identifier}`;
    }

    private _formatDateOfBirth(dateOfBirth: NullableUndefinedString): string {
        if(!dateOfBirth) {
            return '';
        }

        return this.services.time.formatBirthDate(dateOfBirth);
    }

    private _getDesignator(segment: IDotRezSegment): IBoardingPassDesignator {
        const designator: IDotRezDesignator = segment.designator;
        const originStation = this.services.stations.tryGetStation(designator.origin);
        const destinationStation = this.services.stations.tryGetStation(designator.destination);
        const carrier = this.services.carriers.tryGetCarrier(segment.legs[0]?.legInfo?.operatingCarrier)?.name ?? "";
        return {
            departureDate: designator.departure,
            carrier: carrier,
            origin: {
                code: designator.origin,
                name: originStation?.stationName || ''
            },
            destination: {
                code: designator.destination,
                name: destinationStation?.stationName || ''
            }
        };
    }

    private _getTravelDocumentFields(travelDocument: IDotRezPassengerTravelDocument | undefined) {
        return {
            documentTypeCode: travelDocument?.documentTypeCode || '',
            documentExpirationDate: travelDocument?.expirationDate || '',
            nationality: this._getNationality(travelDocument?.nationality),
            documentNumber: travelDocument?.number || ''
        }
    }

    private _getSeat(passengerSegment: IDotRezPassengerSegment): string {
        if(passengerSegment.seats && passengerSegment.seats.length > 0) {
            return passengerSegment.seats[0].unitDesignator;
        }

        return '';
    }

    private _getOtherSsrs(passengerSegment: IDotRezPassengerSegment, boardingPassLegs: IDotRezBoardingPassLeg[]): IBoardingPassSsr[] {
        if(!passengerSegment.ssrs) {
            return [];
        }

        const bagsSsrs = [
            this.services.ssrTypes.SCBG.ssrCode,
            this.services.ssrTypes.PBRD.ssrCode,
            ...this.services.ssrTypes.getAllCheckInBags().map(ssrType => ssrType.ssrCode)
        ];
        const allowedSsrsOnBoardingPass = this._getAllowedSsrsOnBoardingPass(boardingPassLegs);

        const otherSsrs = passengerSegment.ssrs.filter(ssr => !bagsSsrs.includes(ssr.ssrCode) && allowedSsrsOnBoardingPass.includes(ssr.ssrCode))
                                               .groupByKey(ssr => ssr.ssrCode);

        return Object.keys(otherSsrs).map(ssrCode => {
            return {
                ssrCode: ssrCode,
                count: otherSsrs[ssrCode].length
            };
        });
    }

    private _getAllowedSsrsOnBoardingPass(boardingPassLegs: IDotRezBoardingPassLeg[]): string[] {
        boardingPassLegs = boardingPassLegs || [];
        if(boardingPassLegs.length === 0) {
            return [];
        }

        const ssrsCodes: string[] = [];

        for(let leg of boardingPassLegs) {
            for(let ssr of leg.ssrs) {
                ssrsCodes.push(ssr.ssrCode);
            }
        }

        return ssrsCodes.distinct(ssrCode => ssrCode, ssrCode => ssrCode);
    }

    private _getNationality(code: NullableUndefinedString): string {
        if(!code) {
            return '';
        }
        return this.services.country.tryGetCountry(code)?.name || '';
    }

    private _countSsr(ssrCode: string, passengerSegment: IDotRezPassengerSegment): number {
        if(!passengerSegment.ssrs) {
            return 0;
        }
        return passengerSegment.ssrs.filter(ssr => ssr.ssrCode === ssrCode).length;
    }

    private _countCheckInBagsAliases(passengerSegment: IDotRezPassengerSegment): Record<string, number> {
        const result: Record<string, number> = {};
        if(!passengerSegment.bundleCode) {
            return result;
        }
        const virtualSsrsInBundle = this.services.configuration.getBundleVirtualSsrs(passengerSegment.bundleCode);
        const bagsAliases = this.services.ssrTypes.getAllCheckInBags().filter(b => Boolean(b.aliasFor));

        for(let bagAlias of bagsAliases) {
            if(bagAlias.aliasFor) {
                if(!result[bagAlias.aliasFor.ssrCode]) {
                    result[bagAlias.aliasFor.ssrCode] = 0;
                }

                if(virtualSsrsInBundle.includes(bagAlias.ssrCode)) {
                    result[bagAlias.aliasFor.ssrCode] += 1;
                } else {
                    result[bagAlias.aliasFor.ssrCode] += this._countSsr(bagAlias.ssrCode, passengerSegment);
                }
            }
        }

        return result;
    }

    private _getPassengerSegmentCheckInBags(passengerSegment: IDotRezPassengerSegment): IBoardingPassSsr[]  {
        const checkInBags: IBoardingPassSsr[] = [];

        const checkInBagsAliasesCount = this._countCheckInBagsAliases(passengerSegment);

        for(let checkInBagSsrType of this.services.ssrTypes.getAllCheckInBags()) {
            if(!checkInBagSsrType.aliasFor) {
                const bagCount = this._countSsr(checkInBagSsrType.ssrCode, passengerSegment)
                                 + (checkInBagsAliasesCount[checkInBagSsrType.ssrCode] ?? 0);
                if(bagCount > 0) {
                    checkInBags.push({
                        ssrCode: checkInBagSsrType.ssrCode,
                        count: bagCount
                    })
                }
            }
        }
        return checkInBags;
    }

    private _findPassenger(firstName: string, lastName: string): IDotRezBookingPassenger | null {
        firstName = firstName.toUpperCase();
        lastName = lastName.toUpperCase();
        for(let p of this.bookingData.passengers) {
            if(p.value.name?.first?.toUpperCase() === firstName
                && p.value.name?.last?.toUpperCase() === lastName) {
                return p.value;
            }
        }

        return null;
    }

    private _findPassengerByInfant(infantFirstName: string, infantLastName: string): IDotRezBookingPassenger | null {
        infantFirstName = infantFirstName.toUpperCase();
        infantLastName = infantLastName.toUpperCase();
        for(let p of this.bookingData.passengers) {
            if(!p.value.infant) {
                continue;
            }
            if(p.value.infant.name?.first?.toUpperCase() === infantFirstName
                && p.value.infant.name?.last?.toUpperCase() === infantLastName) {
                return p.value;
            }
        }

        return null;
    }

    private _findPassengerSegment(passengerKey: string, identifier: IDotRezIdentifier): {journey: IDotRezJourney; segment: IDotRezSegment; passengerSegment: IDotRezPassengerSegment} | null {
        for(let journey of this.bookingData.journeys) {
            for(let segment of journey.segments) {
                if(segment.identifier.carrierCode === identifier.carrierCode
                    && segment.identifier.identifier === identifier.identifier) {
                    for(let passengerSegment of segment.passengerSegment) {
                        if(passengerSegment.key === passengerKey) {
                            return {
                                journey: journey,
                                segment: segment,
                                passengerSegment: passengerSegment.value
                            };
                        }
                    }
                }
            }
        }

        return null;
    }
}
