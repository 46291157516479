import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";


const BodyBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify-all;
    font-size: ${props => props.theme.fontSize.large};
    line-height: 1.5;
    width: 100%;
    height: 100%;
`

export const InvalidBlueBenefitsSubscriptionDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;
    const subscription = services.user.profile.blueBenefitsSubscription;
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Invalid Blue Benefits Subscription')} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent>
                <BodyBox>
                    {services.language.translate('You already have a Blue Benefits subscription!')}
                    <br/>
                    {services.language.translationFor('Your Blue Benefits subscriptions allows maximum of {x} passengers but your current booking is for {y} passengers.')
                        .withParams({x: subscription.maxNumberOfPassengers, y: booking.passengers.length})}
                    <br/>
                    {services.language.translate('You will need to restart the booking and choose the number of passengers according to your subscription.')}
                </BodyBox>

            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={() => props.dialogHandler.accept()}>
                    {services.language.translate('Restart Booking')}
                </PrimaryButton>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
