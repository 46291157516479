import {CreditCardPaymentModel} from "./credit-card-payment.model";
import React from "react";
import {LogoMaestroIcon} from "../../../../../../assets/svg/logo-maestro.icon";

export class MaestroPaymentMethodModel extends CreditCardPaymentModel {

    get name(): string {
        return "Maestro";
    }

    renderIcon(): React.ReactElement | null {
        return (<LogoMaestroIcon />);
    }
}