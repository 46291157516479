import {ILegViewModel} from "./leg-view-model.interface";
import {SegmentModel} from "./segment.model";
import {IDotRezLeg} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IServiceFactory} from "../../../service-factory.interface";
import {LegBaseModel} from "../base-models/leg/leg-base.model";

export class LegModel extends LegBaseModel implements ILegViewModel {
    constructor(public readonly segment: SegmentModel,
                public readonly legKey: string) {
        super();
    }

    get legDotRezData(): IDotRezLeg {
        return this.segment.getLegData(this.legKey);
    }

    get services(): IServiceFactory {
        return this.segment.journey.services;
    }
}
