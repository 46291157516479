import React from "react";
import {ISvgIconProps, SvgIcon} from "../../../../assets/svg/svg-icon.component";

export const ScbgSsrSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.411} sizeIsHeight {...props} viewBox="0 0 14.11 14.11">
            <g fill={props.color? props.color: "currentColor"} transform="translate(-2876 -310)"><path d="M0,8.6v.433a1.617,1.617,0,0,0,1.694,1.586H12.561a1.584,1.584,0,0,0,1.552-1.586V8.6Z" transform="translate(2876 313.494)" /><path d="M12.561,3.3H1.694A1.617,1.617,0,0,0,0,4.886v5.192H14.113V4.886A1.584,1.584,0,0,0,12.561,3.3Zm-5.5,5.481a.921.921,0,0,1-.847-.865.8.8,0,0,1,.847-.721.921.921,0,0,1,.847.865A.8.8,0,0,1,7.057,8.781Z" transform="translate(2876 311.267)" /><path d="M6.349.721c2.374,0,2.793,3.317,2.793,3.317H11.1C9.561.144,6.349,0,6.349,0S3.136,0,1.6,4.038H3.555S4.114.721,6.349.721Z" transform="translate(2876.708 310)" /></g>
        </SvgIcon>
    )
}
