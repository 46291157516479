import {PersonModel} from "../person/person.model";
import {ICompanionViewModel} from "./companion-view-model.interface";
import {IDotRezPerson} from "../../../dot-rez-api/data-contracts/user/person.data-contracts";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {IDotRezCompanion} from "../../../dot-rez-api/data-contracts/user/companion-data-contracts";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {DialogResult} from "../../../dialog/dialog-enums";
import {AuthorizedUserProfileModel} from "../authorized-user/profile/authorized-user-profile.model";
import {IDotRezUserSession} from "../../../dot-rez-api/session/user-session/dot-rez-user.session.interface";
import {CompanionTravelDocumentModel} from "./companion-travel-document.model";
import {IPersonTravelDocumentViewModel} from "../person/person-travel-document-view-model.interface";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../../dialog-factory/yes-no-dialog.enums";
import {PersonTypeEnum} from "../../../dot-rez-api/data-contracts/enums/person-type.enum";
import {PersonStatusEnum} from "../../../dot-rez-api/data-contracts/enums/person-status.enum";

export class CompanionModel extends PersonModel implements ICompanionViewModel {
    constructor(private readonly userProfile: AuthorizedUserProfileModel, companionData?: IDotRezCompanion) {
        super(userProfile.services);
        if(companionData) {
            this.companionData = companionData;
        } else {
            this.companionData = {
                affiliateKey: "",
                person: {
                    personKey: "",
                    name: {
                        first: null,
                        last: null,
                        title: null,
                        personNameKey: ""
                    },
                    type: PersonTypeEnum.Customer,
                    customerNumber: "",
                    travelDocuments: [],
                    affiliates: [],
                    phoneNumbers:[],
                    emailAddresses:[],
                    addresses:[],
                    comments:[],
                    status: PersonStatusEnum.Active,
                    preferences: [],
                    details: {
                        gender: null,
                        dateOfBirth: null,
                        nationality: null,
                        preferredCurrencyCode: null,
                        preferredCultureCode: null
                    }
                }
            }
        }

        makeObservable(this, {
            companionData: observable,
            travelDocuments: computed
        });
    }

    companionData: IDotRezCompanion;

    get personData(): IDotRezPerson {
        return this.companionData.person;
    }

    get isNew(): boolean {
        return !Boolean(this.companionData.affiliateKey);
    }

    get affiliateKey(): string {
        return this.companionData.affiliateKey;
    }

    protected get isPhoneNumberRequired(): boolean {
        return false;
    }

    async getSession(): Promise<IDotRezUserSession> {
        return this.userProfile.getSession();
    }

    get travelDocuments(): CompanionTravelDocumentModel[] {
        return this.companionData.person.travelDocuments.map(td => new CompanionTravelDocumentModel(this, td.personTravelDocumentKey));
    }

    async edit(): Promise<void> {
        const dialogResult = await this.services.dialogFactory.showCompanionEditor({
            companion: this
        });

        if(dialogResult === DialogResult.Rejected) {
            this.cancelChanges();
        }
    }

    private _validateUniqueCompanionName(): ValidationResultEnum {

        const duplicatedCompanions = this.userProfile.companions.filter(c => c.affiliateKey !== this.affiliateKey
                    && c.getFullName() === this.getFullName());

        if(duplicatedCompanions.length > 0) {
            this.services.alert.showError(this.services.language.translationFor('You already have a companion named {companionName}').withParams({companionName: this.getFullName()}));
            return ValidationResultEnum.Failure;
        }

        if(this.getFullName() === this.userProfile.getFullName()) {
            this.services.alert.showError(this.services.language.translate('You cannot add a companion that has the same name as your name'));
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    async save(): Promise<ValidationResultEnum> {
        this.activateErrorsValidation();
        if(this.hasErrors()) {
            return ValidationResultEnum.Failure
        }

        if(ValidationResultEnum.Failure === this._validateUniqueCompanionName()) {
            return ValidationResultEnum.Failure;
        }

        await this.services.loadingIndicator.execute({
            action: async () => {
                if(this.isNew) {
                    await this._addCompanion();
                } else {
                    await this._updateCompanion();
                }

            }
        });

        this.commitChanges();

        return ValidationResultEnum.Success;
    }

    private async _commonCompanionRequestFields() {
        const session = await this.getSession();
        return {
            dotRezToken: session.token,
            title: this.services.personTitle.personTitleFromGender(this.fields.gender.value)!,
            firstName: this.fields.firstName.value!,
            lastName: this.fields.lastName.value!,
            dateOfBirth: this.services.time.formatBirthDate(this.fields.dateOfBirth.value!),
            gender: this.fields.gender.value!,
            nationality: this.fields.nationality.value!
        }
    }

    private async _addCompanion(): Promise<void> {
        const request = await this._commonCompanionRequestFields();
        const newCompanionData = await this.services.airlineWebapi.addCompanion(request);

        runInAction(() => {
            this.companionData = newCompanionData;
        });
    }

    private async _updateCompanion(): Promise<void> {

        const request = await this._commonCompanionRequestFields();
        const updatedCompanionData = await this.services.airlineWebapi.updateCompanion({
            affiliateKey: this.companionData.affiliateKey,
            ...request
        });

        runInAction(() => {
            this.companionData = updatedCompanionData
        });

        for(let travelDocument of this.travelDocuments) {
            try {
                //we update the travel document in case the companion name, gender or date of birth is changed
                await travelDocument.save();
            }
            catch (err) {
                this.services.logger.error(`Failed to save travel document ${travelDocument.fields.number} for person ${this.customerNumber}`);
            }
        }
    }

    async delete(): Promise<ValidationResultEnum> {
        const dialogResult = await this.services.dialogFactory.showYesNoDialog({
            title: this.services.language.translate('Confirmation'),
            message: this.services.language.translationFor(`Are you sure you want to delete {companionName} from your list of companions?`)
                .withParams({
                    companionName: this.getFullName()
                }),
            primaryButton: YesNoDialogPrimaryButton.PrimaryButtonNo
        });
        if(dialogResult !== YesNoDialogResult.Yes) {
            return ValidationResultEnum.Failure;
        }


        await this.services.loadingIndicator.execute({
            action: async () => {
                const session = await this.getSession();
                await this.services.airlineWebapi.deleteCompanion({
                    dotRezToken: session.token,
                    affiliateKey: this.affiliateKey
                });

                const index = this.userProfile.companions.findIndex(companion => companion.affiliateKey === this.affiliateKey);
                runInAction(() => {
                    this.userProfile.companions.splice(index, 1);
                });
            }
        });

        return ValidationResultEnum.Success;
    }


    protected _createNewTravelDocument(): IPersonTravelDocumentViewModel {
        return new CompanionTravelDocumentModel(this);
    }

    protected _getPersonDetailsNotCompletedErrorMessage(): string {
        return this.services.language.translationFor('Please fill in the personal details for {companionName} then you can add a travel document.')
            .withParams({
                companionName: this.getFullName()
            });
    }
}
