import {CheckInFlowStepBase} from "../common/check-in-flow-step.base";
import {IRoute} from "../../../navigation/models/route.interface";

export class CheckInFlowBoardingPassStep extends CheckInFlowStepBase {
    get title(): string {
        return "Boarding Pass";
    }

    get route(): IRoute {
        return this.routes.boardingPass;
    }

    async next(): Promise<void> {
        await this.services.navigator.goHome();
    }

}
