import React, {CSSProperties, MutableRefObject, PropsWithChildren, useState} from "react";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
//import {AddBoardingPassesToWalletButtonComponent} from "../../../../components/add-to-wallet-buttons/apple/add-to-mobile-wallet-button.component";
import {BoardingPassRecordLocatorComponent} from "../record-locator/boarding-pass-record-locator.component";
import {BoardingPassDesignatorComponent} from "../designator/boarding-pass-designator.component";
import {BoardingPassFlightDetailsComponent} from "../flight-details/boarding-pass-flight-details.component";
import {BoardingPassBagsComponent} from "../bags/boarding-pass-bags.component";
import {BoardingPassOtherSsrsComponent} from "../other-ssrs/boarding-pass-other-ssrs.component";
import {BoardingPassPassengerComponent} from "../passenger/boarding-pass-passenger.component";
import {BoardingPassCarrierLogoComponent} from "../carrier-logo/boarding-pass-carrier-logo.component";
import {BoardingPassBarcodeComponent} from "../bar-code/boarding-pass-bar-code.component";
import styled from "styled-components";
import {BoardingPassExportButtonsComponent} from "../export-buttons/boarding-pass-export-buttons.component";

const ExportBoardingPassButtonsBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

interface BoardingPassCardBoxProps extends PropsWithChildren {
    captureElementRef: MutableRefObject<HTMLDivElement | null>;
}
const BoardingPassCardBox: React.FC<BoardingPassCardBoxProps> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        border: `1px solid ${services.theme.currentTheme.border.mainColor}`,
        padding: services.theme.currentTheme.spacing.cardDefaultPaddingPx,
        borderRadius: services.theme.currentTheme.border.defaultRadius,
        backgroundColor: services.theme.currentTheme.colors.lightTint
    };

    return (
        <div style={styles} ref={props.captureElementRef}>
            {props.children}
        </div>
    )
});


interface BoardingPassComponentProps {
    bookingKey: string;
    currentBoardingPass: IPassengerSegmentBoardingPassViewModel;
    allBoardingPassesForCurrentPassenger: IPassengerSegmentBoardingPassViewModel[];
    hideExportButtons: boolean
}

export const BoardingPassComponent: React.FC<BoardingPassComponentProps> = (props) => {
    const services = useServices();
    const [isExportActionInProgress, setIsExportActionInProgress] = useState(false);
    const boardingPassContainerRef = React.useRef<HTMLDivElement | null>(null);
    const origin = props.currentBoardingPass.designator.origin.name;
    const destination = props.currentBoardingPass.designator.destination.name;

    const shouldRenderExportButtons = !(props.hideExportButtons || isExportActionInProgress)

    const renderExportButtons = () => {
        if (!shouldRenderExportButtons) {
            return null;
        }



        return (
            <ExportBoardingPassButtonsBox>
                <BoardingPassExportButtonsComponent bookingKey={props.bookingKey}
                                                    boardingPasses={[props.currentBoardingPass]}
                                                    onExportStatusChanged={(inProgress) => setIsExportActionInProgress(inProgress)}
                                                    fileName={`${props.currentBoardingPass.fullName} Boarding pass ${origin}_${destination}.pdf`}
                                                    emailSubject={services.language.translationFor('Boarding pass for {passengerName}').withParams({
                                                        passengerName: props.currentBoardingPass.fullName
                                                    })}
                                                    emailBody={services.language.translationFor('Boarding pass for flight {origin} - {destination} on {flightDate}').withParams({
                                                        origin: origin,
                                                        destination: destination,
                                                        flightDate: services.time.formatUserFriendlyDate(props.currentBoardingPass.boardingTime)
                                                    })}/>
            </ExportBoardingPassButtonsBox>
        );
    }

    /*
    const renderAppleWalletButton = () => {
        if (!shouldRenderExportButtons) {
            return null;
        }

        return (
            <AddBoardingPassesToWalletButtonComponent bookingKey={props.bookingKey} boardingPasses={props.allBoardingPassesForCurrentPassenger}/>
        );

    }
     */

    return (
        <BoardingPassCardBox captureElementRef={boardingPassContainerRef}>
            {renderExportButtons()}

            <BoardingPassCarrierLogoComponent/>

            <BoardingPassRecordLocatorComponent recordLocator={props.currentBoardingPass.recordLocator}/>

            <BoardingPassBarcodeComponent barcode={props.currentBoardingPass.barCode}/>

            <BoardingPassDesignatorComponent boardingPass={props.currentBoardingPass}/>

            <BoardingPassFlightDetailsComponent boardingPass={props.currentBoardingPass}/>

            <BoardingPassBagsComponent boardingPass={props.currentBoardingPass}/>

            <BoardingPassOtherSsrsComponent boardingPass={props.currentBoardingPass}/>

            <BoardingPassPassengerComponent boardingPass={props.currentBoardingPass}/>

            {/*renderAppleWalletButton()*/}
        </BoardingPassCardBox>
    )
}
