import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {ICompanionViewModel} from "../../../services/user/models/companion/companion-view-model.interface";
import {PersonTravelDocumentViewComponent} from "../../../components/person/person-travel-document-view.component";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";


const TravelDocumentsListBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

interface CompanionTravelDocumentsListViewComponentProps {
    companion: ICompanionViewModel;
}

export const CompanionTravelDocumentsListViewComponent: React.FC<CompanionTravelDocumentsListViewComponentProps> = observer((props) => {
    const services = useServices();
    const travelDocuments = props.companion.travelDocuments;

    const onAddTravelDocument = async () => {
        await props.companion.addTravelDocument();
    }


    return (
        <TravelDocumentsListBox>
            {travelDocuments.map(td => <PersonTravelDocumentViewComponent key={td.travelDocumentKey} travelDocument={td}/>)}
            <PrimaryButton onClick={onAddTravelDocument}>
                {services.language.translate('Add travel document')}
            </PrimaryButton>
        </TravelDocumentsListBox>
    )
});
