import {
    IPassengerSegmentBoardingPassViewModel
} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {makeObservable, observable, runInAction} from "mobx";

export class PassengerBoardingPassesSelector {
    constructor(public readonly groupNumber: number, public readonly passengerBoardingPasses: IPassengerSegmentBoardingPassViewModel[]) {
        makeObservable<this, '_isSelected'>(this, {
            _isSelected: observable.ref
        });
    }

    public get uniqueKey(): string {
        return this.groupNumber + this.passengerName;
    }

    public get passengerName(): string {
        return this.passengerBoardingPasses[0].fullName;
    }

    private _isSelected: boolean = true;
    get isSelected(): boolean {
        return this._isSelected;
    }

    set isSelected(value: boolean) {
        runInAction(() => {
            this._isSelected = value;
        })
    }

}