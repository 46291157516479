import React from "react";
import {DialogCloseButtonBehavior, DialogResult} from "../dialog/dialog-enums";
import {IPassengerSegmentBoardingPassViewModel} from "../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {IDialogConfirmationHandler} from "../dialog/dialog.service.interface";
import {PriorityBoardingSuggestionChoice} from "../../pages/booking-flow/bags-selection/components/priority-boarding-suggestion-dialog.component";
import {IBundleViewModel} from "../booking/models/bundle/bundle-view-model.interface";
import {IBundleUpgradeBenefit} from "../bundle-types/bundle-types.service.interface";
import {ValidateVoucherResultEnum} from "../dot-rez-api/data-contracts/enums/validate-voucher-result.enum";
import {AppVersionUpgradeModeEnum} from "../airline-webapi/responses/get-configurations.response";
import {LoginAndRegistrationDialogTabEnum} from "../user/models/login-and-registration-dialog-tab.enum";
import {ILoginAndRegistrationHandlerViewModel} from "../user/models/login-and-registration-handler/login-and-registration-handler-view-model.interface";
import {IPasswordResetFormViewModel} from "../user/models/password-reset/password-reset-form-view-model.interface";
import {IUserProfileEditorViewModel} from "../user/models/profile/user-profile-editor-view-model.interface";
import {ICompanionViewModel} from "../user/models/companion/companion-view-model.interface";
import {IPersonTravelDocumentViewModel} from "../user/models/person/person-travel-document-view-model.interface";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "./yes-no-dialog.enums";
import { SeatsRowGroupType } from "../booking/models/seat-maps/seats-row-group-type.enum";
import {IBookingSsrsAggregatorViewModel} from "../booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {SsrsSelectionDialogComponentProps} from "../../pages/booking-flow/common-components/ssrs/ssrs-selection-dialog.component";

export interface IBoardingPassesDialogOptions {
    bookingKey: string;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
}

export interface IPriorityBoardingSuggestionDialogOptions {
    onAccept: (choice: PriorityBoardingSuggestionChoice | undefined) => Promise<void>;
}

export interface IBundleUpgradeSuggestionDialogOptions {
    upgradeFrom: IBundleViewModel;
    upgradeTo: IBundleViewModel;
    benefits: IBundleUpgradeBenefit[];
    onAccept: (selectedBundle: IBundleViewModel | undefined) => Promise<void>;
}

export interface IOnlineCheckInValidationDialogOptions {
    onAccept: (validationResult: OnlineCheckInValidationResultEnum | undefined) => Promise<void>
}

export enum OnlineCheckInValidationResultEnum {
    OnlySaveDocuments,
    ContinueWithEligiblePassengers,
    GoToHomePage,
    CancelNextStep
}


export interface IVoucherCodeConfirmationDialogOptions {
    headerTitle: string;
    bodyText: string;
    confirmButtonText: string;
    applyVoucherResult: ValidateVoucherResultEnum;
    onReject: () => Promise<void>;
}

export interface IAppVersionUpdateSuggestionDialogOptions {
    appVersionUpgradeMode: AppVersionUpgradeModeEnum;
}

export interface ILoginAndRegistrationDialogOptions {
    defaultTab: LoginAndRegistrationDialogTabEnum;
    hideRegistrationTab: boolean;
    loginAndRegistrationHandler: ILoginAndRegistrationHandlerViewModel;
}


export interface IPasswordResetDialogOptions {
    form: IPasswordResetFormViewModel;
}

export interface IEditCompanionDialogOptions {
    companion: ICompanionViewModel;
}

export interface IEditPersonTravelDocumentDialogOptions {
    travelDocument: IPersonTravelDocumentViewModel;
}

export interface IYesNoDialogOptions {
    title?: string;
    message: string | React.ReactElement;
    primaryButton: YesNoDialogPrimaryButton;
    yesButtonText?: string;
    noButtonText?: string;
}

export interface IShowRegistrationInstructionsDialogOptions {
    captureDialogHandler: (dialogHandler: IDialogConfirmationHandler) => void;
}

export interface IMultipleSsrsSelectorDialogOptions {
    title: string;
    aggregator: IBookingSsrsAggregatorViewModel;
    backButtonBehavior?: DialogCloseButtonBehavior.Accept;
}

export interface ISsrsSelectionDialogOptions extends Omit<SsrsSelectionDialogComponentProps, 'dialogHandler'> {
    backButtonBehavior?: DialogCloseButtonBehavior.Accept;
}

export interface ISeatDescriptionDialog {
    readonly groupType: SeatsRowGroupType;
}

export interface ISimpleMessageDialogOptions {
    title?: string;
    message: string;
    buttonText: string;
}

export interface IDialogFactoryService {
    showBoardingPasses(options: IBoardingPassesDialogOptions): Promise<DialogResult>;
    showPriorityBoardingSuggestion(options: IPriorityBoardingSuggestionDialogOptions): Promise<DialogResult>;
    showBundleUpgradeSuggestion(options: IBundleUpgradeSuggestionDialogOptions): Promise<void>;
    showOnlineCheckInValidation(options: IOnlineCheckInValidationDialogOptions): Promise<void>;
    showDangerousGoods(): Promise<DialogResult>;
    showInvalidPromotionCode(): Promise<DialogResult>;
    showVoucherConfirmation(options: IVoucherCodeConfirmationDialogOptions): Promise<void>;
    showAppVersionUpdateSuggestion(options: IAppVersionUpdateSuggestionDialogOptions): Promise<void>;
    showLoginAndRegistration(options: ILoginAndRegistrationDialogOptions): Promise<void>;
    showRegistrationInstructions(options: IShowRegistrationInstructionsDialogOptions): Promise<void>;
    showCookiesPolicy(): Promise<void>;
    showAuthorizedUserProfileEditor(profileEditor: IUserProfileEditorViewModel): Promise<DialogResult>;
    showRegistrationConfirmation(): Promise<void>;
    showPasswordReset(options: IPasswordResetDialogOptions): Promise<void>;
    showCompanionEditor(options: IEditCompanionDialogOptions): Promise<DialogResult>;
    showPersonTravelDocumentEditor(options: IEditPersonTravelDocumentDialogOptions): Promise<DialogResult>;
    showYesNoDialog(options: IYesNoDialogOptions): Promise<YesNoDialogResult>;
    showInvalidBlueBenefitsSubscription(): Promise<void>;
    showMultipleSsrsSelector(options: IMultipleSsrsSelectorDialogOptions): Promise<DialogResult>;
    showSsrsSelector(options: ISsrsSelectionDialogOptions): Promise<DialogResult>;
    showSeatDescriptionDialog(options: ISeatDescriptionDialog): Promise<DialogResult>;
    showSimpleMessageDialog(options: ISimpleMessageDialogOptions): Promise<void>;
    showSeatsSelection(): Promise<void>;
}
