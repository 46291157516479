import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PlaneFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2} {...props} viewBox="0 0 19.998 15.511" ratio={19.998/15.511}>
            <g fill={props.color? props.color: "currentColor"}><path d="M265.509,283.8l-3.187,1.778-8.959-2.387c-2-.531-2.238,1.864-.7,2.617l5.142,2.292-5.618,3.14-1.215-.361c-2.412-.741-1.529.986-.793,2.238l.68,1.217c.721,1.3,2.112.55,2.811.183l5.85-3.251-.754,5.5c-.17,1.705,1.991,2.768,2.593.784l2.665-8.8,3.485-1.937C271.8,284.446,267.738,282.563,265.509,283.8Z" transform="translate(-249.399 -283.12)"/></g>
        </SvgIcon>
    )
}
